<template>
  <vx-navbar :responsiveOffset="20" class="vx-mb-6 sm:vx-mb-0">

      <template v-slot:left>
        <vx-navbar-action href="/" :active="isHome()" class="hide-action-icon">
          <navbar-logo></navbar-logo>
        </vx-navbar-action>

        <vx-button v-if="windowWidth > 768" class="vx-ml-6 vx-mr-4" href="/messaging/messagehub" color="primary" size="sm">
            <font-awesome-icon class="vx-text-sm" :icon="faMessages"></font-awesome-icon>
            Message Hub
        </vx-button>

        <vx-button v-if="windowWidth > 768" href="/messaging/quick-blast" color="info" size="sm">
            <font-awesome-icon class="vx-text-sm" :icon="faRocketLaunch"></font-awesome-icon>
            Quick Blast
        </vx-button>
      </template>

      <template v-if="windowWidth <= 768" v-slot:nested-before>
        <vx-button block href="/messaging/messagehub" color="primary" size="sm">
          <font-awesome-icon class="vx-text-sm" :icon="faMessages"></font-awesome-icon>
          Message Hub
        </vx-button>
        <vx-button block href="/messaging/quick-blast" color="info" size="sm">
          <font-awesome-icon class="vx-text-sm" :icon="faRocketLaunch"></font-awesome-icon>
          Quick Blast
        </vx-button>
      </template>

      <vx-navbar-action v-for="action in routes"
          :key="action.name"
          :icon="action.icon"
          :href="action.href"
          :target="action.target"
          :rel="action.target ? 'noopener noreferrer' : undefined"
          :active="isActive(action.pathRegExps)"
      >

          {{ action.name }}

          <template v-if="action.items?.length" v-slot:items>
              <vx-navbar-action-item v-for="item in action.items"
                  :key="(`${action.name}-${item.name}`)"
                  :href="item.href"
                  @click="item.onClick"
                  :target="item.target"
                  :rel="item.target ? 'noopener noreferrer' : undefined"
                  :active="isActive(item.pathRegExps, item.href)"
              >
                  {{ item.name }}
              </vx-navbar-action-item>
          </template>
      </vx-navbar-action>

      <template v-if="windowWidth <= 768" v-slot:nested-after>
        <navbar-settings :metadata="props.metadata"></navbar-settings>
      </template>

      <template v-slot:right>
          <vx-navbar-action v-if="windowWidth > 768">
              <vx-button color="secondary" size="sm" @click="showNotifications">
                  <font-awesome-icon class="vx-text-sm" :icon="faBell"></font-awesome-icon>
                  <span v-if="props.unreadNotificationsCount" class="vx-inline-block vx-rounded-full vx-h-4 vx-w-4 vx-bg-rose-400 vx-border-solid vx-border-slate-700 vx-border-4 vx-absolute -vx-right-1 -vx-top-1"></span>
              </vx-button>
          </vx-navbar-action>

          <vx-navbar-action v-if="windowWidth > 768" placement="right" data-test="navbar-settings">
              <vx-button color="secondary" size="sm">
                  <font-awesome-icon class="vx-text-sm" :icon="faGear"></font-awesome-icon>
              </vx-button>

              <template v-slot:items>
                  <navbar-settings :metadata="props.metadata"></navbar-settings>
              </template>
          </vx-navbar-action>
      </template>

  </vx-navbar>

  <div class="vx-hidden vx-pt-6 vx-px-6 sm:vx-block sm:vx-h-12 md:vx-px-8 sm:vx-mb-8">
      <vx-navigation v-if="activeActionItems.length">
          <vx-navigation-item
              v-for="item in activeActionItems"
              :key="`subnav-${item.name}`"
              :value="item.name"
              :href="item.href"
              :target="item.target"
              :rel="item.target ? 'noopener noreferrer' : undefined"
              :active="isActive(item.pathRegExps, item.href)"
          >
              {{ item.name }}
          </vx-navigation-item>
      </vx-navigation>
  </div>
</template>

<style>
.hide-action-icon > svg {
    display: none;
}
</style>

<script setup>
import { VxNavbar, VxNavbarAction, VxNavbarActionItem, VxButton, VxNavigation, VxNavigationItem } from '@voxie/frontend-components';
import { navbarRoutes } from './navbar-routes';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBell, faGear, faMessages, faRocketLaunch } from '@fortawesome/pro-solid-svg-icons';
import NavbarLogo from './NavbarLogo.vue';
import NavbarSettings from './NavbarSettings.vue';
import { computed, onMounted, onBeforeUnmount, ref, nextTick } from 'vue'

const props = defineProps({
  unreadNotificationsCount: {
      type: Number,
      default: 0,
  },
  metadata: {
      type: Object,
      default: () => ({
          isServiceRepresentative: false,
          showsTeamSwitcher: false,
          usesTeams: false,
          ownsCurrentTeam: false,
          isAdminRoleOnCurrentTeam: false,
          currentTeamOnTrial: false,
          isImpersonator: false,
          isDeveloper: false,
          hasSupportAddress: false,
          createsAdditionalTeams: false,
      }),
  },
});

const windowWidth = ref(window.innerWidth);

const routes = navbarRoutes(props.metadata);

const isHome = () => {
    return window.location.pathname === "/"
}

const isActive = (pathRegExps, href) => {
  if(!pathRegExps) {
      return href == window.location.pathname;
  }
  return Boolean(pathRegExps?.exec(window.location.pathname));
}

const activeActionItems = computed(
  () => routes.find(action => isActive(action.pathRegExps, action.href))?.items || []
)

const showNotifications = () => Bus.$emit('showNotifications');

const setWindowWidth = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  nextTick().then(setWindowWidth);
  window.addEventListener('resize', setWindowWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', setWindowWidth);
});
</script>
