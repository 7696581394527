<template>
    <page-container>
        <page-header>
            Snippets

            <template v-slot:actions>
                <vx-button @click="showOverview = !showOverview" data-test="toggle-overview" type="button"
                           color="muted-dark" size="lg">
                    <span class="vx-hidden sm:vx-inline-block">{{ showOverview ? 'Hide' : 'Show' }} Overview</span>
                    <font-awesome-icon class="vx-hidden sm:vx-inline-block"
                                       :icon="faQuestionCircle"></font-awesome-icon>
                    <font-awesome-icon class="vx-inline-block sm:vx-hidden"
                                       :icon="showOverview ? faCircleChevronUp : faCircleChevronDown"></font-awesome-icon>
                </vx-button>
            </template>
        </page-header>

        <snippets-overview v-show="showOverview" class="vx-mb-16"></snippets-overview>

        <snippet-list-controls v-model="fetchParams"
           @new="snippetModalShow = true"
           @apply="applyFilters"
           @reset="resetFilters"
           :loading="loading"
           class="vx-mb-12"></snippet-list-controls>

        <div v-if="loading && !list.length" class="vx-text-center vx-py-10">
            <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
        </div>

        <template v-else>
            <vx-box color="info" v-if="list.length === 0 && !isEqual(defaultFilters(), fetchParams)"
                    class="vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                <div class="vx-w-64 vx-mx-auto vx-text-center">
                    No results for current search, try adjusting your filters.
                </div>
                <vx-button type="button" color="secondary" @click="resetFilters">
                    Reset All <font-awesome-icon :icon="faCircleX" />
                </vx-button>
            </vx-box>
            <vx-box color="info" v-else-if="totalCount === 0"
                    class="vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                No snippets yet.
                <vx-button data-test="snippet-modal-list" @click="snippetModalShow = true" type="button">
                    New Snippet <font-awesome-icon :icon="faPlusCircle" />
                </vx-button>
            </vx-box>
            <template v-else>
                <vx-table v-if="list.length" class="xl:vx-grid-cols-[repeat(6,auto)]"
                          rowBreakpoint="xl">
                    <vx-table-row isHeader>
                        <div>
                            Name
                        </div>
                        <div class="xl:vx-col-span-2">
                            Body
                        </div>
                        <div>
                            Attachment
                        </div>
                        <div>
                            Updated
                        </div>
                    </vx-table-row>
                    <snippet-row v-for="(item, index) in list"
                        v-model="list[index]"
                        :key="item.id"
                        @edit="edit"
                        @deleted="removeFromList($event)"></snippet-row>
                </vx-table>

                <div v-if="list.length" class="vx-mt-8 vx-flex vx-justify-between vx-w-full vx-gap-10">
                    <vx-button v-if="list.length < totalCount" @click="loadData()" data-test="load-more-btn" color="muted-dark" size="xl"
                               class="vx-grow-0" :loading="loading">
                        Load More
                        <font-awesome-icon :icon="faSpinner"></font-awesome-icon>
                    </vx-button>
                    <div class="vx-text-lg vx-grow-0 vx-ml-auto vx-font-normal vx-text-slate-600">
                        {{ list.length }}/{{ totalCount }}
                    </div>
                </div>
            </template>
        </template>

        <modal-snippet v-model:visible="snippetModalShow" v-model="selectedSnippet" @saved="resourceCreatedOrUpdated($event)" />
    </page-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { faCircleChevronDown, faCircleChevronUp, faQuestionCircle, faSpinner, faCircleX, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBox, VxButton, VxIconLoading, VxTable, VxTableRow } from '@voxie/frontend-components';
import PageContainer from '~/components/general/PageContainer.vue';
import PageHeader from '~/components/general/PageHeader.vue';
import ModalSnippet from '../snippets/components/ModalSnippet.vue';
import SnippetsOverview from './components/SnippetsOverview.vue';
import SnippetListControls from './components/SnippetListControls.vue';
import SnippetRow from './components/SnippetRow.vue';
import { snippetsClient } from '~/services';
import { useToasted } from '~/composables/useToasted';
import { isEqual } from '~/utils/helpers';
import dayjs from '~/utils/dayjs';
import { useCursorPagination } from '../../composables/useCursorPagination';

const teamId = Spark.state.currentTeam.id;
const toasted = useToasted();
const showOverview = ref(true);
const totalCount = ref(0);
const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const defaultFilters = () => ({
    query: '',
    cursor: null,
    sort_field: 'id',
    sort_dir: 'desc',
});

const selectedSnippet = ref({
    id: null,
    title: '',
    body: '',
    media_url: '',
    media_content_type: '',
});
const snippetModalShow = ref(false);

const resetFilters = () => {
    fetchParams.value = defaultFilters();

    loadData();
};

const applyFilters = () => {
    fetchParams.value = {
        ...fetchParams.value,
        cursor: undefined,
    }

    loadData();
}

const fetchTotalCount = async () => {
    try {
        const response = await snippetsClient.getSnippetsCount(teamId, { ...fetchParams.value });
        totalCount.value = response.data?.count || 0;
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    }
}

const loadData = () => {
    fetchTotalCount();
    fetchRecords(snippetsClient.getSnippets(teamId, {...fetchParams.value}), 'id');
}

const removeFromList = (id) => {
    list.value = list.value.filter((item) => item.id !== id);
    totalCount.value = totalCount.value - 1;

    if (!list.value.length) {
        loadData();
    }
};

const resourceCreatedOrUpdated = (data) => {
    if (data.created_at && dayjs.utc(data.created_at).isAfter(dayjs().subtract(5, 'seconds'))) {
        list.value = [
            { ...data },
            ...list.value
        ];

        totalCount.value = totalCount.value + 1;
    } else {
        list.value = list.value.map(snippet => {

            if (snippet.id === data.id) {
                return {
                    ...snippet,
                    ...data,
                };
            }

            return snippet;
        })
    }
};

const edit = (snippet) => {
    selectedSnippet.value = {...snippet};
    snippetModalShow.value = true;
};

onMounted(() => {
    resetFilters();
})
</script>
