export const removeOldestThread = (threads, maxThreads) => {
    let minLastMessageId = threads?.length && Math.min(...threads.map((t) => t.last_message_id));
    let activeThread = threads.findIndex((t) => t.active && t.last_message_id === minLastMessageId);

    if (threads.length > maxThreads + (activeThread !== -1 ? 1 : 0)) {
        let minLastMessageId =
            threads?.length && Math.min(...threads.filter((t) => !t.active).map((t) => t.last_message_id));
        const oldestThreadIndex = threads.findIndex((t) => t.last_message_id === minLastMessageId);
        if (oldestThreadIndex !== -1) {
            threads = threads.filter((t) => t.last_message_id !== minLastMessageId);
        }
    }

    return threads;
};
