<template>
    <vx-expandable-card>
        <template v-slot:header> To </template>

        <template v-slot:headerSubtitle> Contacts that should receive this campaign. Segments are limited to the
            matching
            audience type selected for this campaign. </template>

        <template v-slot:content>
            <vx-tabs :disabled="props.disabled"
                data-test="audience-tabs"
                v-model="currentTab"
                size="lg">
                <vx-tab v-if="canChooseCampaign"
                    value="campaign">Campaign</vx-tab>
                <vx-tab value="segments">Segments</vx-tab>
                <vx-tab v-if="campaignDurationType === 'one_time'"
                    value="everyone">Everyone</vx-tab>
            </vx-tabs>

            <div v-if="currentTab === 'campaign' && canChooseCampaign"
                class="vx-mt-4 vx-max-w-full">
                <campaigns-selectable size="lg"
                    :modelValue="props.originalCampaignId"
                    @update:modelValue="emit('update:originalCampaignId', $event)"
                    :disabled="props.disabled"
                    :flowId="props.flowId"
                    clearable
                    :ids="props.originalCampaignId ? [props.originalCampaignId] : []">
                </campaigns-selectable>
            </div>

            <div v-if="currentTab === 'segments'"
                class="vx-mt-4 vx-max-w-full">
                <segments-selectable size="lg"
                    :audience-type="props.campaignType"
                    :modelValue="props.segmentIds"
                    :disabled="props.disabled"
                    :flowId="props.flowId"
                    @update:modelValue="emit('update:segmentIds', $event)">
                </segments-selectable>
            </div>

            <div class="vx-mt-8 vx-text-slate-700 vx-flex vx-flex-col vx-gap-2 vx-text-sm">
                <div class="vx-text-lg vx-font-extrabold vx-mb-4">Contact Settings</div>
                <div class="vx-p-6 vx-bg-white vx-items-center vx-rounded-2xl vx-justify-between vx-flex">
                    <div>
                        <strong>Remove</strong> contacts if they respond?
                    </div>
                    <div>
                        <vx-toggle size="sm" data-test="remove-on-response-toggle"
                            :modelValue="props.removeOnResponse"
                            @update:modelValue="emit('update:removeOnResponse', $event)"></vx-toggle>
                    </div>
                </div>
                <div class="vx-p-6 vx-bg-white vx-items-center vx-rounded-2xl vx-justify-between vx-flex">
                    <div>
                        <strong>Remove</strong> contacts if they follow a link?
                    </div>
                    <div>
                        <vx-toggle size="sm" data-test="remove-on-link-click-toggle"
                            :modelValue="props.removeOnLinkClick"
                            @update:modelValue="emit('update:removeOnLinkClick', $event)"></vx-toggle>
                    </div>
                </div>
                <div v-if="props.campaignDurationType !== 'one_time'"
                    class="vx-p-6 vx-bg-white vx-items-center vx-rounded-2xl">
                    <div class="vx-justify-between vx-flex">
                        <div>
                            Send to contacts <strong>more than once</strong>?
                        </div>
                        <div>
                            <vx-toggle size="sm" :disabled="props.disabled"
                                data-test="is-repeatable-toggle"
                                :modelValue="props.isRepeatable"
                                @update:modelValue="emit('update:isRepeatable', $event)"></vx-toggle>
                        </div>
                    </div>
                    <div v-if="props.isRepeatable">
                        <div class="vx-text-slate-500 vx-text-sm vx-mb-3">
                            How long before contacts can re-enter this campaign?
                        </div>
                        <div class="vx-flex vx-flex-col vx-gap-4 lg:vx-flex-row lg:vx-justify-between">
                            <div class="vx-grow">
                                <vx-selectable size="lg"
                                    :disabled="props.disabled"
                                    data-test="repeat-delay-value-selectable"
                                    :options="repeatDelayConfig.options"
                                    v-model="repeatDelayValue"
                                    @update:modelValue="emitRepeatDelay()"
                                    :getOptionLabel="option => option"
                                    :reduce="option => option"
                                    :clearable="false">
                                </vx-selectable>
                            </div>
                            <vx-tabs data-test="repeat-delay-unit-tabs"
                                class="lg:vx-shrink-0"
                                :disabled="props.disabled"
                                :size="isLg ? 'lg' : 'sm'"
                                v-model="repeatDelayUnit">
                                <vx-tab value="minutes">Minutes</vx-tab>
                                <vx-tab value="hours">Hours</vx-tab>
                                <vx-tab value="days">Days</vx-tab>
                                <vx-tab value="weeks">Weeks</vx-tab>
                            </vx-tabs>
                        </div>
                    </div>

                </div>

                <div v-if="FEATURES.campaign_filtering"
                    class="vx-p-6 vx-bg-white vx-items-center vx-rounded-2xl vx-justify-between vx-flex vx-flex-col lg:vx-flex-row">
                    <div>
                        <strong>Audience Percentage</strong>
                        <div class="vx-text-slate-500">
                            What percentage of the audience contacts that will receive messages from this campaign?
                        </div>
                    </div>
                    <div>
                        <vx-input class="lg:vx-w-24"
                            size="lg"
                            type="number"
                            step="0.01"
                            min="0"
                            max="100"
                            data-test="audience-percentage"
                            v-model="audiencePercentageModel"></vx-input>
                    </div>
                </div>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxExpandableCard, VxTab, VxTabs, VxToggle, VxSelectable, VxInput } from '@voxie/frontend-components';
import { onMounted, ref, watch, computed, nextTick, inject } from 'vue';
import {
    AUDIENCE_TYPE_ACQUISITION,
    AUDIENCE_TYPE_MARKETING,
    AUDIENCE_TYPE_RE_ACQUISITION,
    AUDIENCE_TYPE_TRANSACTIONAL
} from '~/constants/campaign';
import SegmentsSelectable from '~/components/general/segments/SegmentsSelectable.vue';
import CampaignsSelectable from '~/components/general/campaigns/CampaignsSelectable.vue';
import { useMediaQuery } from '@vueuse/core'

const FEATURES = inject('FEATURES');
const isLg = useMediaQuery('(min-width: 1024px)')

const props = defineProps({
    campaignId: {
        type: Number,
        default: undefined,
    },
    campaignType: {
        type: String,
        default: undefined,
        validator: (campaignType) => [AUDIENCE_TYPE_MARKETING, AUDIENCE_TYPE_TRANSACTIONAL, AUDIENCE_TYPE_ACQUISITION, AUDIENCE_TYPE_RE_ACQUISITION].includes(campaignType)
    },
    campaignDurationType: {
        type: String,
    },
    campaignStatus: {
        type: String,
    },
    segmentIds: {
        type: Array,
        default: () => ([]),
    },
    originalCampaignId: {
        type: Number,
        default: undefined,
    },
    everyone: {
        type: Boolean,
        default: undefined,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    removeOnResponse: {
        type: Boolean,
        default: false,
    },
    removeOnLinkClick: {
        type: Boolean,
        default: false,
    },
    isRepeatable: {
        type: Boolean,
        default: false,
    },
    repeatDelay: {
        type: Number,
        default: 10080,
    },
    audiencePercentage: {
        type: Number,
        default: 0,
    },
    flowId: {
        type: [String, undefined],
        default: undefined,
    },
});

const currentTab = ref('segments');
const mounted = ref(false);

const emit = defineEmits(["update:segmentIds", "update:originalCampaignId", "update:everyone", "update:removeOnResponse", "update:removeOnLinkClick", "update:isRepeatable", "update:repeatDelay", "update:audiencePercentage"]);

const canChooseCampaign = ref(false);

const audiencePercentageModel = computed({
    get: () => {
        if (props.audiencePercentage === null || props.audiencePercentage === undefined) {
            return null;
        }

        return String(parseFloat((props.audiencePercentage * 100).toFixed(2)));
    },

    // Setter converts the entered percentage back to a decimal for the backend
    set: (value) => {
        if (isNaN(parseFloat(value))) {
            emit('update:audiencePercentage', null);
        } else {
            if (value > 100) {
                value = 100;
            }

            if (value < 0) {
                value = 0;
            }

            const decimalValue = parseFloat(value) / 100;
            // Round to 4 decimal places
            emit('update:audiencePercentage', parseFloat(decimalValue.toFixed(4)))
        }
    }
})

onMounted(async () => {
    if (props.originalCampaignId) {
        currentTab.value = 'campaign';
        canChooseCampaign.value = true;
    } else if (props.segmentIds?.length) {
        currentTab.value = 'segments';
    } else if (props.everyone) {
        currentTab.value = 'everyone';
    }

    processRepeatDelayInMinutes();

    await nextTick();
    mounted.value = true;
});

watch(currentTab, () => {
    if (!mounted.value) {
        return;
    }

    if (currentTab.value === 'campaign') {
        emit('update:everyone', false);
        emit('update:segmentIds', [])
    } else if (currentTab.value === 'segments') {
        emit('update:everyone', false);
        emit('update:originalCampaignId', null);
    } else if (currentTab.value === 'everyone') {
        emit('update:everyone', true);
        emit('update:segmentIds', []);
        emit('update:originalCampaignId', null);
    }
});

const repeatDelayUnit = ref('minutes');
const getRepeatDelayConfig = (unit) => {
    if (unit === 'hours') {
        return {
            multiplier: 60,
            options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        };
    } else if (unit === 'days') {
        return {
            multiplier: 60 * 24,
            options: [1, 2, 3, 4, 5, 6]
        };
    } else if (unit === 'weeks') {
        return {
            multiplier: 60 * 24 * 7,
            options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        }
    } else {
        return {
            multiplier: 1,
            options: [15, 30, 45],
        };
    }
}
const repeatDelayConfig = computed(() => getRepeatDelayConfig(repeatDelayUnit.value));
const repeatDelayValue = ref(repeatDelayConfig.value.options[0]);

const emitRepeatDelay = () => {
    emit('update:repeatDelay', Number(repeatDelayValue.value) * Number(repeatDelayConfig.value.multiplier));
}

const processRepeatDelayInMinutes = () => {
    const repeatDelay = Number(props.repeatDelay);
    if (repeatDelay < 60) {
        repeatDelayUnit.value = 'minutes';
        repeatDelayValue.value = repeatDelay;
        return;
    }
    for (const unit of ['weeks', 'days', 'hours']) {
        const config = getRepeatDelayConfig(unit);

        const multiplier = config.multiplier;
        if (repeatDelay % multiplier === 0) {
            repeatDelayUnit.value = unit;
            repeatDelayValue.value = repeatDelay / multiplier;
            return;
        }
    }

    repeatDelayUnit.value = 'minutes';
    repeatDelayValue.value = repeatDelay;
}

watch(repeatDelayUnit, async () => {
    if (!mounted.value) {
        return;
    }

    repeatDelayValue.value = repeatDelayConfig.value.options[0];
    emitRepeatDelay()
});

</script>
