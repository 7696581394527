<template>
  <div
    class="vx-flex vx-flex-col vx-items-stretch vx-justify-between vx-gap-y-4 vx-gap-x-8 sm:vx-flex-row sm:vx-items-center lg:vx-gap-x-16 xl:vx-gap-32">
    <div class="vx-w-full vx-grow @3xl/controls:vx-max-w-md">
      <vx-filterable :active="active" @toggle="toggleFilterable">
        <template v-slot:placeholder>
          <span class="vx-mr-2">Find Campaigns</span>
          <vx-badge v-if="filtersCount > 0" :borders="false" size="xs" @click.stop="reset">
            {{ filtersCount }} filters
            <font-awesome-icon :icon="faXmarkCircle" />
          </vx-badge>
        </template>
        <template v-slot:form>
          <form @submit.prevent="apply" class="vx-space-y-4">
            <div class="vx-space-y-1">
              <vx-label>
                Keyword Search
              </vx-label>

              <vx-input autofocus
                type="text"
                data-test="campaign-controls-query"
                placeholder="Search"
                maxlength="180"
                v-model="filters.query">
                <template v-slot:right>
                  <font-awesome-icon class="vx-text-xs" :icon="faMagnifyingGlass" />
                </template>
              </vx-input>
            </div>

            <div class="vx-space-y-1">
              <vx-label class="vx-flex vx-flex-col">
                <span class="vx-grow">Type</span>

                <vx-button color="muted" size="xs" type="button" @click="filters.duration_types = []">
                  Reset Filter
                  <font-awesome-icon :icon="faUndo"></font-awesome-icon>
                </vx-button>
              </vx-label>

              <vx-selectable multiple placeholder="Select type" :options="typesOptions"
                v-model="filters.duration_types"
                :reduce="(option) => option.value">
              </vx-selectable>
            </div>

            <div class="vx-space-y-1">
              <vx-label class="vx-flex vx-flex-col">
                Status
                <vx-button color="muted" size="xs" type="button" @click="filters.ui_statuses = []">
                  Reset Filter
                  <font-awesome-icon :icon="faUndo"></font-awesome-icon>
                </vx-button>
              </vx-label>
              <vx-selectable multiple placeholder="Select status" :options="statusesOptions"
                v-model="filters.ui_statuses"
                :reduce="(option) => option.value">
              </vx-selectable>
            </div>

            <div>
              <vx-label class="vx-flex vx-flex-col">
                Date Created
                <vx-button color="muted" size="xs" type="button" @click="updateDates(undefined)">
                  Reset Filter
                  <font-awesome-icon :icon="faUndo"></font-awesome-icon>
                </vx-button>
              </vx-label>
              <vx-selectable placeholder="Select date" :options="datesRangeOptions" :modelValue="selectedDate"
                :reduce="(option) => option.value" @update:modelValue="updateDates" :clearable="false">
              </vx-selectable>
            </div>

            <category-filter-select
              v-model="filters.category"
            >
            </category-filter-select>

            <div v-if="FEATURES.time_zone_aware_campaigns">
              <vx-label class="vx-flex vx-flex-col">
                Time-Zone Aware
                <vx-button color="muted" size="xs" type="button" @click="filters.time_zone_aware = undefined">
                  Reset Filter
                  <font-awesome-icon :icon="faUndo"></font-awesome-icon>
                </vx-button>
              </vx-label>
              <vx-selectable placeholder="Filter by time-zone aware" :options="timeZoneAwareOptions" v-model="filters.time_zone_aware"
                :reduce="(option) => option.value" :clearable="false">
              </vx-selectable>
            </div>

            <in-flow-filterable-item v-if="FEATURES.flows" v-model="filters.flow">
            </in-flow-filterable-item>

            <div class="vx-flex vx-gap-2">
              <vx-button type="button" color="muted-light" @click="cancel">Cancel</vx-button>
              <vx-button data-test="campaign-controls-apply" type="submit" block @click="apply">
                Apply
                <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
              </vx-button>
            </div>
          </form>
        </template>
      </vx-filterable>
    </div>
    <div class="vx-flex vx-w-full vx-gap-2 @xl/controls:vx-w-auto">
      <vx-select v-model="filters.sort_field"
        @update:modelValue="emit('apply')" :options="[
          {
            value: 'name',
            label: 'Sort by: Name',
          },
          {
            value: 'id',
            label: 'Sort by: Created',
          },
        ]"></vx-select>
      <vx-button
        @click.prevent="
            filters.sort_dir = filters.sort_dir === 'asc' ? 'desc' : 'asc';
            apply();
        "
        type="button" color="white" size="lg" shadow>
        <font-awesome-icon
          :icon="filters.value === 'asc' ? faArrowUpWideShort : faArrowDownWideShort"></font-awesome-icon>
      </vx-button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, inject, nextTick } from 'vue';
import dayjs, { toUTCDateTimeFormat } from '~/utils/dayjs';
import { VxBadge, VxLabel, VxButton, VxFilterable, VxInput, VxSelect, VxSelectable } from '@voxie/frontend-components';
import { faCircleCheck, faMagnifyingGlass, faUndo, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowDownWideShort, faArrowUpWideShort } from '@fortawesome/pro-solid-svg-icons';
import { getClientTz } from '../../../utils/date';
import InFlowFilterableItem from '~/components/automation/flows/components/InFlowFilterableItem.vue';
import CategoryFilterSelect from '~/components/general/categories/CategoryFilterSelect.vue';

const FEATURES = inject('FEATURES');

const typesOptions = [
    {
        value: 'one_time',
        label: 'One-Time',
    },
    {
        value: 'predefined',
        label: 'Duration',
    },
    {
        value: 'evergreen',
        label: 'Evergreen',
    }
];

const statusesOptions = [
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Scheduled',
    value: 'scheduled',
  },
  {
    label: 'Live',
    value: 'live',
  },
  {
    label: 'Paused',
    value: 'paused',
  },
  {
    label: 'Complete',
    value: 'complete',
  },
];
const datesRangeOptions = [
  {
    label: 'Last 7 days',
    value: '7',
  },
  {
    label: 'Last 14 days',
    value: '14',
  },
  {
    label: 'Last 21 days',
    value: '21',
  },
  {
    label: 'Last 30 days',
    value: '30',
  },
];

const timeZoneAwareOptions = [
  {
    label: 'True',
    value: 1,
  },
  {
    label: 'False',
    value: 0,
  },
];


const filters = ref({});

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});
watch(() => props.modelValue, (modelValue) => {
    filters.value = {...modelValue};
}, {
    deep: true,
    immediate: true,
});

const emit = defineEmits(['update:modelValue', 'apply', 'reset']);

const selectedDate = ref();
const active = ref(false);

watch(
  () => props.modelValue.start_date,
  startDateValue => {
    if (startDateValue === undefined) {
      selectedDate.value = undefined;
    }
  }
);

const toggleFilterable = (event) => {
  active.value = event;
}

const isFlowFilterApplied = computed(() => {
    if (!props.modelValue.flow) {
        return false;
    }

    return props.modelValue.flow.in_flow !== undefined || !!(props.modelValue.flow.flow_id)
});
const filtersCount = computed(() => {
    let count = 0;

    count += props.modelValue.duration_types?.length ? 1 : 0;
    count += props.modelValue.ui_statuses?.length ? 1 : 0;
    count += props.modelValue.start_date ? 1 : 0;
    count += props.modelValue.query?.length ? 1 : 0;
    count += isFlowFilterApplied.value ? 1 : 0;
    count += (props.modelValue.time_zone_aware === undefined ? 0 : 1);
    count += props.modelValue.category?.length ? 1 : 0;

    return count;
});

const updateDates = async (days) => {
  selectedDate.value = days;
  filters.value.start_date = days ? toUTCDateTimeFormat(dayjs().tz(getClientTz()).utc().subtract(days, 'days')) : undefined;
  await nextTick();
  filters.value.end_date = days ? toUTCDateTimeFormat(dayjs().tz(getClientTz())) : undefined;
}

const reset = () => {
  active.value = false;
  emit('reset');
  selectedDate.value = undefined;
}

const cancel = async () => {
    filters.value = { ...props.modelValue }
    active.value = false;
}

const apply = async () => {
    active.value = false;
    emit('update:modelValue', { ...filters.value });
    await nextTick();
    emit('apply');
}

</script>
