<template>
  <div
    class="vx-font-sans vx-bg-white vx-shadow vx-border vx-border-solid vx-border-white vx-rounded-2xl vx-flex vx-flex-col vx-@container"
    data-test="campaign-row">
    <div
      class="vx-flex vx-min-h-14 vx-items-center vx-px-6 vx-border-b vx-border-x-0 vx-border-t-0 vx-border-solid vx-rounded-t-2xl vx-relative"
      :class="[statusColor.background]">
      <vx-badge class="vx-mr-4" size="xs" :color="statusColor.badge" data-test="campaign-status">{{
        generalStatusLabel(props.modelValue.status)
      }}</vx-badge>
      <campaign-card-actions :modelValue="props.modelValue" @update:modelValue="emit('update:modelValue', $event)"
        @launch="emit('launch', $event)">
      </campaign-card-actions>
    </div>

    <div
      class="vx-flex vx-flex-col vx-px-6 vx-py-6 lg:vx-py-4 vx-rounded-b-2xl vx-relative vx-gap-4 lg:vx-grid-cols-5 lg:vx-inline-grid vx-h-full">
      <div class="vx-space-y-1 vx-col-span-3">
        <div class="vx-grow vx-flex vx-items-baseline">
          <vx-badge class="vx-mr-2 vx-cursor-pointer" @click.prevent="copyId()" size="xs" color="slate">{{ props.modelValue.id }}</vx-badge>

          <h3 class="lg:vx-line-clamp-2 vx-text-base vx-font-bold vx-text-slate-800 vx-line-clamp-none vx-break-all vx-leading-normal vx-m-0 vx-cursor-pointer"
          data-test="campaign-name" @click.prevent="copyName()" :title="props.modelValue.name">
            {{ props.modelValue.name }}
          </h3>
        </div>

        <div class="vx-text-slate-400 vx-text-sm vx-mb-4 lg:vx-mb-0" v-if="props.modelValue.status !== 'draft'">
          <campaign-card-dates :modelValue="props.modelValue" />
        </div>
      </div>

      <div
        class="vx-text-slate-500 vx-text-base vx-flex vx-flex-row vx-gap-6 lg:vx-justify-center lg:vx-items-center">
        <vx-popover hover placement="top" arrow>
            <div class="vx-flex vx-items-center vx-gap-1 lg:vx-mb-0">
                <font-awesome-icon class="vx-text-xs" :icon="faUserGroup"></font-awesome-icon>
                <font-awesome-icon v-if="contactsCount === null" class="vx-text-xs" :icon="faSpinnerThird"
                    :spin="true"></font-awesome-icon>
                <template v-else>
                    {{ contactsCountFormatted }}
                </template>
            </div>
            <template v-slot:content>
                <strong>Contacts</strong><br />
                Currently in campaign: &nbsp; <strong>{{ contactsCountFormatted }}</strong>
            </template>
        </vx-popover>

        <vx-popover hover placement="top" arrow>
            <div class="vx-flex vx-items-center vx-gap-1 lg:vx-mb-0" data-test="published-messages-vs-total-messages">
                <font-awesome-icon class="vx-text-xs" :icon="faMessages"></font-awesome-icon>
                {{ props.modelValue.published_campaign_messages_count }} / {{ props.modelValue.total_campaign_messages_count }}
            </div>
            <template v-slot:content>
                <strong>Messages</strong><br />
                <div class="vx-flex vx-flex-col vx-min-w-52">
                    <div class="vx-flex vx-justify-between">
                        <span>Active:</span><strong data-test="published-messages-count">{{ props.modelValue.published_campaign_messages_count }}</strong>
                    </div>
                    <div class="vx-flex vx-justify-between">
                        <span>Total:</span><strong data-test="total-messages-count">{{ props.modelValue.total_campaign_messages_count }}</strong>
                    </div>
                </div>
            </template>
        </vx-popover>
      </div>

      <div class="vx-flex vx-gap-1 vx-items-end vx-justify-end vx-grow">
        <campaign-card-dropdown :campaign="props.modelValue" @deleted="emit('deleted', $event)">
        </campaign-card-dropdown>

        <vx-button color="muted" :outline="true" :href="`/messaging/campaigns/${props.modelValue.id}`" class="vx-grow lg:vx-grow-0">
          Edit
          <font-awesome-icon :icon="faFilePen"></font-awesome-icon>
        </vx-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  faFilePen,
  faSpinnerThird,
  faUserGroup,
  faMessages
} from "@fortawesome/pro-solid-svg-icons";
import { VxBadge, VxButton, VxPopover } from '@voxie/frontend-components';
import { generalStatusLabel } from '~/components/filters';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, ref } from 'vue';
import CampaignCardDates from './CampaignCardDates.vue';
import CampaignCardActions from './CampaignCardActions.vue';
import CampaignCardDropdown from './CampaignCardDropdown.vue';
import campaignsClient from '../../../services/campaignsClient';
import { useToasted } from "~/composables/useToasted";

const toasted = useToasted();

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue', 'deleted', 'launch']);

const teamId = Spark.state.currentTeam.id;

const copyId = () => {
  navigator.clipboard.writeText(props.modelValue.id);
  toasted.success(`${props.modelValue.id} copied to clipboard.`)
};

const copyName = () => {
  navigator.clipboard.writeText(props.modelValue.name);
  toasted.success(`${props.modelValue.name} copied to clipboard.`)
};

const contactsCount = ref(null);
campaignsClient
  .campaignsContactsCount(props.modelValue.id, teamId)
  .then(({ data }) => (contactsCount.value = data.count));

const contactsCountFormatted = computed(() =>
  contactsCount.value
    ? Intl.NumberFormat("en", { notation: "compact" }).format(
      Number(contactsCount.value)
    )
    : 0
);

const statusColor = computed(() => {
  const colors = {
    draft: {
      badge: "amber",
      background: "vx-bg-slate-50 vx-border-slate-100",
    },
    scheduled: {
      badge: "orange",
      background: "vx-bg-orange-50 vx-border-orange-100",
    },
    published: {
      badge: "teal",
      background: "vx-bg-teal-50 vx-border-teal-100",
    },
    completed: {
      badge: "neutral",
      background: "vx-bg-slate-50 vx-border-slate-100",
    },
    paused: {
      badge: "danger",
      background: "vx-bg-slate-50 vx-border-slate-100",
    },
  };

  return (
    colors[props.modelValue.status] || {
      badge: "primary",
      background: "vx-bg-sky-50 vx-border-sky-100",
    }
  );
});
</script>
