<template>
    <div class="vx-flex vx-flex-col vx-gap-6">
        <saml2-connection :team="props.team" @connected="isConnected = true"
            @disconnected="isConnected = false"></saml2-connection>

        <saml2-role-mapping :team="props.team" :connected="isConnected"></saml2-role-mapping>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import Saml2Connection from './Saml2Connection.vue';
import Saml2RoleMapping from './Saml2RoleMapping.vue';

const props = defineProps({
    team: {
        type: Object,
        required: true,
    },
});

const isConnected = ref(false);


</script>
