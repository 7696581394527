<template>
    <div :class="['search-wrapper', extraClass]">
        <input
            type="text"
            class="search-input pl-1"
            :placeholder="placeholder || 'Search'"
            aria-label="Search"
            :value="modelValue"
            :maxlength="maxlength"
            @input="$emit('update:modelValue', $event.target.value)"
            @keyup="$emit('keyup', $event)"
        />
        <span
            :class="['search-icon fa', modelValue ? 'fa-times cursor-pointer' : 'fa-search']"
            @click="handleIconClick"
        />
    </div>
</template>

<script>
export default {
    props: ['modelValue', 'extraClass', 'placeholder', 'maxlength'],
    emits: ['update:modelValue', 'keyup'],
    methods: {
        handleIconClick() {
            if (this.modelValue) {
                this.$emit('update:modelValue', '');
            }
        },
    },
};
</script>
