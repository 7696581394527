<template>
    <vx-table-row>
        <vx-table-row-title>
            <div class="vx-flex vx-items-center">
            <div class="vx-w-4 vx-h-4 vx-rounded-full vx-mr-2 vx-shrink-0"
                :class="status[props.item.status].color"></div>
            <span class="vx-font-normal">{{ status[props.item.status].label || 'Unknown' }}</span>
            </div>
        </vx-table-row-title>

        <vx-table-row-item>
            <template v-slot:label> Triggered: </template>

            <vx-popover hover
                :arrow="true"
                placement="top"
                :key="createdAtKey">
                {{ fromNow(props.item.created_at) }}
                <template v-slot:content>
                    {{ shortDateTime(props.item.created_at) }}
                </template>
            </vx-popover>

        </vx-table-row-item>
        <vx-table-row-item>
            <template v-slot:label> Delivery ID: </template>

            <vx-popover hover
                :arrow="true"
                placement="top">
                <vx-badge size="xs"
                    color="slate">{{ props.item.delivery_id?.slice(-6) }}</vx-badge>
                <template v-slot:content>
                    {{ props.item.delivery_id }}
                </template>
            </vx-popover>
        </vx-table-row-item>
        <vx-table-row-item>
            <template v-slot:label> Automation: </template>
            <div v-if="props.item.delivery_source?.startsWith('automated_action')">
                <vx-popover v-if="props.automationRule?.name"
                    hover
                    :arrow="true"
                    placement="top"
                    class="vx-max-w-full">
                    <a v-if="props.automationRule?.name"
                        :href="'/automation/event/' + automationRule?.id"
                        class="vx-block vx-truncate vx-underline vx-underline-offset-1 vx-decoration-slate-200 vx-text-slate-800 hover:vx-text-sky-500">
                        {{ props.automationRule?.name }}
                    </a>
                    <template v-slot:content>
                        <div class="vx-font-extrabold">Go to Automation:</div>
                        {{ props.automationRule?.name }}
                    </template>
                </vx-popover>

                <vx-icon-loading spin v-if="props.automationRule === undefined"></vx-icon-loading>
                <vx-popover v-if="props.automationRule === null"
                    hover
                    :arrow="true"
                    placement="top">
                    <span>
                        <font-awesome-icon :icon="faCircleInfo"></font-awesome-icon>
                        Not Found
                    </span>
                    <template v-slot:content>
                        An automation was used but the name couldn't be found.
                    </template>
                </vx-popover>
            </div>
            <div v-else> None </div>
        </vx-table-row-item>

        <vx-table-row-buttons>
            <vx-button class="vx-w-full md:vx-w-auto"
                :href="`/settings/integrations/api-destinations/${props.item.origin_id}/deliveries/${props.item.delivery_id}`"
                color="muted"
                outline>
                Details
                <font-awesome-icon :icon="faArrowRight" />
            </vx-button>
        </vx-table-row-buttons>

    </vx-table-row>
</template>

<script setup>
import {
    faCircleInfo,
    faArrowRight
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxButton,
    VxTableRow,
    VxTableRowButtons,
    VxTableRowItem,
    VxTableRowTitle,
    VxBadge,
    VxPopover,
    VxIconLoading
} from "@voxie/frontend-components";
import { useInterval } from '@vueuse/core';
import { fromNow, shortDateTime } from '../../../../../utils/date';

const status = {
    triggered: {
        color: 'vx-bg-slate-300',
        label: 'In Progress',
    },
    succeeded: {
        color: 'vx-bg-sky-200',
        label: 'Success',
    },
    failed: {
        color: 'vx-bg-rose-400',
        label: 'Failed',
    },
};

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    automationRule: {
        required: false,
    },
});

const createdAtKey = useInterval(60000);

</script>
