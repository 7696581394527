<template>
    <span :class="cssClasses">
        <slot></slot>
    </span>
</template>

<script>
    export default {

        props: ['type'],

        computed: {

            cssClasses() {
                return "c-badge c-badge--" + this.type;
            }
        }
    }
</script>


<style>
    .c-badge {
        margin: 0 2px 2px 2px;
    }
</style>
