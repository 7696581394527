<template>
    <div>
        <vx-selectable
            size="xl"
            :multiple="true"
            :no-tags="true"
            @search="search"
            :placeholder="'Choose a ' + props.resourceType"
            :options="options.filter(option => !isSelected(option))"
            :modelValue="props.modelValue"
            :clearable="true"
            :relativeDropdown="true"
            :closeOnSelect="false"
            @update:modelValue="emit('update:modelValue', $event)">
            <template v-slot:option="{ option }">

                <div class="vx-flex vx-w-full vx-items-center vx-justify-between vx-text-slate-800 vx-py-3 lg:vx-py-0">
                    <div class="vx-line-clamp-2"
                        :title="option.label">
                        {{ option.label }}
                    </div>
                    <div class="vx-flex vx-gap-4 lg:vx-gap-8">
                        <div>
                            <resource-selectable-info :color="false"
                                :resource="option"
                                :resourceType="props.resourceType"
                                :iconColor="appearance.icon"
                                :segmentContactsCount="segmentContactsCount[option.id]?.count"></resource-selectable-info>
                        </div>

                        <vx-button type="button"
                            color="muted"
                            size="xs">
                            Add
                            <font-awesome-icon class="vx-text-xs vx-ml-1"
                                :icon="faPlusCircle"></font-awesome-icon>
                        </vx-button>
                    </div>
                </div>
            </template>
        </vx-selectable>

        <div v-if="props.modelValue.length"
            class="vx-mt-6">
            <div data-test="resource-selected"
                v-for="resource in props.modelValue"
                :key="resource.id"
                tabindex="0"
                role="button"
                :class="appearance.row"
                class="vx-appearance-none vx-border vx-border-solid -vx-mt-px first:vx-rounded-t-lg last:vx-rounded-b-lg vx-flex vx-items-center vx-justify-between vx-px-4 vx-text-base lg:vx-min-h-14"
                @click="remove(resource)"
                @keydown.enter="remove(resource)"
                @keydown.space="remove(resource)">

                <div class="vx-flex vx-w-full vx-items-center vx-justify-between vx-py-3 lg:vx-py-0">
                    <div class="vx-line-clamp-2"
                        :title="resource.name">
                        {{ resource.name }}
                    </div>
                    <div class="vx-shrink-0 vx-flex vx-items-center vx-gap-4 lg:vx-gap-8">
                        <resource-selectable-info :resource="resource"
                            :resourceType="props.resourceType"
                            :color="true"
                            :iconColor="appearance.icon"
                            :segmentContactsCount="segmentContactsCount[resource.id]?.count"></resource-selectable-info>

                        <button :class="appearance.button"
                            class="vx-appearance-none vx-rounded vx-px-2 vx-text-xs vx-h-6 vx-text-black vx-font-semibold">
                            Remove
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxButton, VxSelectable } from '@voxie/frontend-components';
import { computed, onMounted, ref } from 'vue';
import automationRulesClient from '~/services/automationRulesClient';
import campaignsClient from '~/services/campaignsClient';
import segmentsClient from '~/services/segmentsClient';
import dayjs from '~/utils/dayjs';
import debounce from '~/utils/debounce';
import ResourceSelectableInfo from './ResourceSelectableInfo.vue';

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    modelValue: {
        type: Array,
        required: false,
        default: () => [],
    },
    resourceType: {
        type: String,
        validator: (resourceType) => ['automation', 'campaign', 'segment'].includes(resourceType)
    },
});
const emit = defineEmits(["update:modelValue"]);

const segmentContactsCount = ref({})

const options = ref([]);

const isSelected = (resource) => {
    return Boolean(props.modelValue.find((item) => Number(item.id) === Number(resource.id)));
};

const remove = async (resource) => {
    emit("update:modelValue", props.modelValue.filter((item) => Number(item.id) !== Number(resource.id)));
};

const fetchContactsCountFor = (segmentId) => {
    if (segmentContactsCount.value[segmentId] &&
        (segmentContactsCount.value[segmentId]?.expiresAt.isAfter(dayjs()) ||
            segmentContactsCount.value[segmentId]?.loading)
    ) {
        return;
    }

    segmentContactsCount.value = {
        ...segmentContactsCount.value,
        [segmentId]: {
            loading: true,
        },
    }

    segmentsClient.getSegmentContactsCount(segmentId, teamId)
        .then(({ data }) => {
            segmentContactsCount.value = {
                ...segmentContactsCount.value,
                [segmentId]: {
                    count: data.count,
                    expiresAt: dayjs().add(120, 'seconds'),
                    loading: false,
                }
            }
        }).catch(() => {
            segmentContactsCount.value = {
                ...segmentContactsCount.value,
                [segmentId]: {
                    loading: false,
                }
            }
        });
}

const search = debounce(async (search, loading) => {
    loading?.(true);

    const params = {
        sort_field: 'id',
        sort_dir: 'desc',
        per_page: 15,
        query: search,
        [props.resourceType === 'segment' ? 'filter[flow]' : 'flow']: {
            in_flow: 0,
        }
    };

    try {
        let client;

        if (props.resourceType === 'automation') {
            client = automationRulesClient.search(teamId, params)
        } else if (props.resourceType === 'campaign') {
            client = campaignsClient.campaignsGetRecords(teamId, params)
        } else if (props.resourceType === 'segment') {
            client = segmentsClient.getSegments(teamId, params);
        }

        const response = await client;
        options.value = response.data.data.map((resource) => {
            return {
                ...resource,
                label: `${resource.id} - ${resource.name}`,
            }
        });

        if (props.resourceType === 'segment') {
            options.value.forEach(segment => fetchContactsCountFor(segment.id));
        }
    } finally {
        loading?.(false);
    }
}, 300);

onMounted(() => search());

const appearance = computed(() => {
    if (props.resourceType === 'automation') {
        return {
            button: 'vx-bg-amber-200',
            row: 'vx-text-amber-800 vx-border-amber-300 vx-bg-amber-100',
            icon: 'vx-text-amber-500',
        };
    }
    if (props.resourceType === 'campaign') {
        return {
            button: 'vx-bg-sky-200',
            row: 'vx-text-sky-800 vx-border-sky-300 vx-bg-sky-100',
            icon: 'vx-text-sky-500',
        };
    }
    if (props.resourceType === 'segment') {
        return {
            button: 'vx-bg-violet-200',
            row: 'vx-text-violet-800 vx-border-violet-300 vx-bg-violet-100',
            icon: 'vx-text-violet-500',
        };
    }

    return {};
});
</script>
