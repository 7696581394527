<template>
    <div class="mx-4 vx-pb-4" :class="{ 'zendesk-spacer': zendeskLoaded, 'pt-2': !isContactSubscribed }">
        <message-builder
            v-model="messageBody"
            v-model:mediaUrl="media_url"
            v-model:mediaContentType="media_content_type"
            @selected:snippet="selectedSnippetBody = $event.body"
            @submit="sendMessage()"
            @schedule="isScheduledMessage = true; sendAt = $event; sendMessage({ isScheduling: true })"
        >
            <template v-slot:actions v-if="FEATURES.message_hub_ai">
                <vx-dropdown-item @click.prevent="aiModal = true">
                    <span class="vx-flex vx-items-center vx-gap-1">
                        <font-awesome-icon :icon="faWandMagicSparkles"
                            class="vx-text-sm"></font-awesome-icon>
                        <span>AI Message</span>
                        <experimental-pill class="vx-pointer-events-none" placement="right"></experimental-pill>
                    </span>
                </vx-dropdown-item>
            </template>
            <template v-if="!isContactSubscribed" v-slot:alert>
                <vx-alert color="info" :closeable="false">
                    <strong>Disabled:</strong> Contact has opted out of transactional messages.
                </vx-alert>
            </template>
        </message-builder>

        <message-builder-message-hub-generation v-model:visible="aiModal" v-if="FEATURES.message_hub_ai" :contact-id="contact.id" @selected="messageBody = $event" />
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Swal from 'sweetalert2';
import { HTTP_UNPROCESSABLE_ENTITY } from '../../../../support/HttpStatuses';
import { messagesClient, actionsClient, scheduledMessagesClient } from '../../../../services';

import { has } from '~/utils/helpers';
import { displayNotActiveModal, isNotActive } from '../../../../utils/team';
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import MessageBuilderMessageHubGeneration from '~/components/general/message-builder/modals/MessageBuilderMessageHubGeneration.vue';
import { VxDropdownItem, VxAlert } from '@voxie/frontend-components';
import {
    getClientTz,
    roundToNearest5,
    toServerDateTime
} from "../../../../utils/date";
import { ScheduleMessagesMinMaxDates } from '../../../../constants/scheduleMessages';
import { CONTACT_SUBSCRIPTION_STATUS_OPT_OUT, CONTACT_SUBSCRIPTION_TYPE_TRANSACTIONAL } from '../../../../constants/contactSubscription';
import { faWandMagicSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ExperimentalPill from "~/components/general/ExperimentalPill.vue";
import dayjs from '~/utils/dayjs';

const initialState = () => {
    return {
        messageBody: '',
        media_url: null,
        media_content_type: null,
        selectedSnippetBody: '',
        isScheduledMessage: false,
        isFileUploading: false,
        isSending: false,
        sendAt: roundToNearest5(toServerDateTime(dayjs().add(1, 'h'))),
        aiModal: false,
    };
};

export default {
    name: 'ThreadChatInput',
    components: {
        MessageBuilder,
        MessageBuilderMessageHubGeneration,
        FontAwesomeIcon,
        VxDropdownItem,
        VxAlert,
        ExperimentalPill,
    },
    props: {
        contact: {
            type: Object,
            default: null,
        }
    },
    inject: ['FEATURES'],
    data() {
        return initialState();
    },
    mounted() {
        Bus.$on('newTemplateModalSnippetCreated', this.onSnippetCreated);
    },
    computed: {
        faWandMagicSparkles: () => faWandMagicSparkles,
        ...mapState({
            contactSubscriptions: (state) => state.contacts_v3.selectedContact.subscriptions,
        }),
        minDate() {
            return ScheduleMessagesMinMaxDates.MIN_DATE;
        },
        maxDate() {
            return ScheduleMessagesMinMaxDates.MAX_DATE;
        },
        zendeskLoaded() {
            return (window.zE !== undefined);
        },

        countOfRows() {
            return this.messageBody.split("\n").length + 2;
        },
        timezone() {
            return dayjs().tz(getClientTz()).format('z');
        },
        isContactSubscribed() {
            return this.contactSubscriptions.find((s) => s.type === CONTACT_SUBSCRIPTION_TYPE_TRANSACTIONAL)?.status !== CONTACT_SUBSCRIPTION_STATUS_OPT_OUT;
        }
    },
    watch: {
        isScheduledMessage(v) {
            if (v) {
                this.sendAt = roundToNearest5(toServerDateTime(dayjs().add(1, 'h')));
            }
        }
    },
    methods: {
        ...mapMutations({
            'setThread': 'setThread',
        }),
        onCtrlEnter() {
            this.sendMessage({
                isScheduling: this.isScheduledMessage
            });
        },
        onMetaEnter() {
            this.sendMessage({
                isScheduling: this.isScheduledMessage
            });
        },
        captureMessageData() {
            let data = {
                contact_id: this.contact.id,
                body: this.messageBody,
            };
            if (this.media_url) {
                data.media_url = this.media_url;
                data.media_content_type = this.media_content_type;
            }
            if (this.isScheduledMessage) {
                data.send_at = this.sendAt;
            }
            return data;
        },

        sendMessage(event) {
            this.isScheduledMessage = event ? event.isScheduling : false;

            if (isNotActive()) {
                displayNotActiveModal()
                return
            }

            if (!has(this.contact, 'id')) {
                return Swal.fire({title: 'Whoops!', text: 'Please select a contact to send messages.', icon: 'error'});
            }

            if (!this.isContactSubscribed) {
                return Swal.fire({title: 'Whoops!', text: 'This contact is unsubscribed.', icon: 'error'});
            }

            let data = this.captureMessageData();

            // Guard against empty messages
            if (data.body.trim() === '') {
                return Swal.fire({title: 'Whoops!', text: 'The message cannot be empty.', icon: 'error'});
            }

            if (this.isScheduledMessage) {
                if (!this.sendAt) {
                    return Swal.fire({title: 'Whoops!', text: 'Send At is required.', icon: 'error'});
                }
            }

            if (this.isSending) return;

            const { selectedSnippetBody } = this;

            const teamId = Spark.state.currentTeam.id;

            this.isSending = true;
            (this.isScheduledMessage ? scheduledMessagesClient.createScheduledMessage(teamId, this.contact.id, data) : messagesClient.createV3(teamId, data))
                .then(() => {
                    actionsClient.send('message_sent');
                    if (selectedSnippetBody && data.body && data.body.includes(selectedSnippetBody)) {
                        actionsClient.send('snippet_used');
                    }
                    this.reset();
                })
                .catch((error) => {
                    if (error.response.status === HTTP_UNPROCESSABLE_ENTITY) {
                        if (error.response.data.message.includes('unsubscribed')) {
                            return Swal.fire({
                                title: 'Whoops!',
                                text: 'Looks like this contact has been unsubscribed, so we have blocked this message from going out.',
                                icon: 'error',
                            });
                        }

                        return Swal.fire({
                            title: 'Whoops!',
                            text: 'Looks like you just typed the same thing within the last few minutes - we have temporarily blocked this message from going out to prevent a duplicate.',
                            icon: 'error',
                        });
                    }
                })
                .finally(() => {
                    this.isSending = false;
                });
        },
        reset() {
            Object.assign(this.$data, initialState());
        },
        onSnippetCreated(event) {
            if (event.media_url) {
                this.media_url = event.media_url
                this.media_content_type = event.media_content_type
            }
            this.messageBody = event.body;
            this.selectedSnippetBody = event.body;
        },
    },
};
</script>

<style>
.zendesk-spacer {
    max-width: calc(100% - 120px);
}
.schedule-picker-wrapper {
    display: flex;
    justify-content: flex-end;
}
</style>
