<template>
    <div class="o-media__img u-mr-small">
        <div class="c-avatar c-avatar--small">
            <span class="profile-circle">
                <span v-if="contactInitials" class="initials">{{ contactInitials }}</span>
                <span v-if="!contactInitials" class="initials">
                    <i class="fa fa-user"></i>
                </span>
            </span>
        </div>
    </div>
</template>


<script>
export default {
    props: ['contact'],

    computed: {
        contactInitials(){
            if(this.contact.first_name && this.contact.last_name){
                return this.contact.first_name.charAt(0) + this.contact.last_name.charAt(0);
            } else if(this.contact.first_name){
                return this.contact.first_name.charAt(0);
            } else if(this.contact.last_name){
                return this.contact.last_name.charAt(0);
            } else {
                return false;
            }
        }
    }
}
</script>

