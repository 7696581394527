<template>
    <vx-expandable-card>
        <template v-slot:header>
            Phone Number
        </template>

        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-6">
                <vx-alert v-if="success"
                    :closeable="false"
                    color="success"
                    shadow>
                    Updated successfully
                </vx-alert>
                <vx-alert v-if="error"
                    :closeable="false"
                    color="danger"
                    shadow>
                    {{ error }}
                </vx-alert>

                <div>
                    <div class="vx-flex vx-items-center vx-justify-between">
                        <div>
                            <vx-label size="md">Groups use Corporate Number</vx-label>
                            <div class="vx-text-sm vx-text-slate-500">Changing this can cause a service outage for your
                                Team.</div>
                        </div>
                        <vx-toggle data-test="groups-use-corporate-numbers"
                            v-model="team.groups_use_corporate_numbers"
                            size="lg"></vx-toggle>
                    </div>
                    <div v-if="displayFirstError(errors.groups_use_corporate_numbers)"
                        class="vx-mt-2 vx-px-0.5 vx-text-xs vx-text-rose-800">
                        {{ displayFirstError(errors.groups_use_corporate_numbers) }}
                    </div>
                </div>

                <vx-button block
                    data-test="update"
                    size="lg"
                    @click.prevent="confirmModal = true"
                    :loading="submitting"
                    :disabled="initialValue === team.groups_use_corporate_numbers">
                    Update
                </vx-button>
            </div>

            <vx-modal v-model:visible="confirmModal"
                size="md"
                :padding="false">
                <template v-slot:header>
                    <div class="vx-p-6">
                        Confirmation
                    </div>
                </template>

                <div class="vx-p-6 lg:vx-p-10">
                    <vx-alert color="danger"
                        :closeable="false">
                        <div v-if="!team.groups_use_corporate_numbers">
                            Turning the <strong>Groups use Corporate Number</strong> setting <strong>OFF</strong> means
                            that:
                            <br />
                            <br />
                            - Groups that do not have a number set <strong>will lose the ability</strong> to send
                            messages
                            <br />
                            <br />
                            Please make sure you need to change this setting before proceeding.
                        </div>
                        <div v-else>
                            Turning the <strong>Groups use Corporate Number</strong> setting <strong>ON</strong> means
                            that:
                            <br />
                            <br />
                            - Group users may lose access to <strong>Message Hub</strong> <br />
                            - Groups must use the Corporate number for sending if they have number set on the group
                            <br />
                            <br />
                            Please make sure you need to change this setting before proceeding.
                        </div>
                    </vx-alert>
                </div>

                <template v-slot:footer>
                    <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                        <vx-button @click="cancel()"
                            type="button"
                            class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                            size="lg"
                            color="muted">
                            Cancel
                        </vx-button>
                        <vx-button @click="submit()"
                            data-test="confirm"
                            :loading="submitting"
                            type="button"
                            block
                            size="lg"
                            color="primary">
                            Confirm
                        </vx-button>
                    </div>
                </template>
            </vx-modal>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxButton, VxExpandableCard, VxLabel, VxAlert, VxToggle, VxModal } from '@voxie/frontend-components';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_UNPROCESSABLE_ENTITY } from '~/support/HttpStatuses';
import { ref } from 'vue';
import { displayFirstError } from '~/utils/validation';
import { teamsClient } from '~/services';

const team = defineModel('team', {
    type: Object,
    required: true,
});

const initialValue = ref(team.value.groups_use_corporate_numbers);

const submitting = ref(false);
const success = ref(false);
const error = ref('');
const errors = ref({});

const confirmModal = ref(false);

// rollback to previous value
const rollback = () => {
    team.value.groups_use_corporate_numbers = initialValue.value;
}

const cancel = () => {
    rollback();
    confirmModal.value = false;
}

const submit = () => {
    if (submitting.value) {
        return;
    }

    success.value = false;
    errors.value = {};
    error.value = ''
    submitting.value = true;

    teamsClient.updateDetails(
        team.value.id,
        {
            groups_use_corporate_numbers: team.value.groups_use_corporate_numbers,
        }
    ).then(() => {
        success.value = true;
        initialValue.value = team.value.groups_use_corporate_numbers;
    }).catch((e) => {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            error.value = 'Something went wrong.'
        } else {
            if (e.response.status === HTTP_UNPROCESSABLE_ENTITY) {
                errors.value = e.response.data.errors
            }
            error.value = e.response?.data?.message || 'Something went wrong.';
        }

        rollback();
    }).finally(() => {
        submitting.value = false;
        confirmModal.value = false;
    })
}

</script>
