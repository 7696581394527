<template>
    <div class="vx-flex vx-flex-col vx-items-stretch vx-justify-between vx-gap-y-4 vx-gap-x-8 sm:vx-flex-row sm:vx-items-center lg:vx-gap-x-16 xl:vx-gap-32">
      <page-header>
        Campaigns <span class="vx-mx-3 vx-font-sans vx-text-slate-400 vx-font-normal">{{ props.count }}</span>

        <template v-slot:actions>
            <div class="vx-flex vx-items-center vx-gap-2">
                <vx-button
                    class="vx-flex vx-justify-between"
                    color="muted-dark"
                    size="lg"
                    @click="informationModal = true"
                >
                    <font-awesome-icon :icon="faQuestionCircle" />
                </vx-button>
                <vx-button data-test="new-campaign" href="/messaging/campaigns/new" class="vx-flex vx-justify-between" size="lg">
                    New Campaign <font-awesome-icon :icon="faPlusCircle" />
                </vx-button>
            </div>
        </template>
      </page-header>
    
        <vx-modal v-model:visible="informationModal" class="vx-max-w-lg">
            <template v-slot:header>Campaign</template>
            <p class="vx-mb-2 vx-text-base">
                A Campaign is a text or series of texts sent to a contact or audience based on a specific criteria. Send Campaigns to Tagged audiences until they perform a specific desired action over a period of time.
            </p>

            <p class="vx-mb-2 vx-text-base">
                Ex: Send 4 texts over a 7 day period to my “New Leads” Tagged contacts of 500 people. If a contact responds to a Campaign text stop sending any remaining texts in Campaign to that contact.
            </p>
        </vx-modal>

    </div>
</template>
<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faQuestionCircle,
    faPlusCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { VxButton, VxModal } from '@voxie/frontend-components';
import { ref } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';

const props = defineProps({
    count: {
        type: Number,
        default: 0,
    },
});

const informationModal = ref(false)
</script>
