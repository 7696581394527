<template>
    <div class="tlt-chat">
        <div v-if="selectedThreads.length" class="tlt-chat-image-wrap">
            <div class="tlt-chat-not-open-image tlt-items-selected-image">
                <selected-thread-image></selected-thread-image>
            </div>
            <div class="tlt-items-selected">
                <span class="badge badge-multidigit tlt-items-selected-badge">{{ selectedThreads.length }}</span>
                <span class="tlt-chat-not-open-sub-title">Items selected.</span>
            </div>
        </div>
        <template v-else>
            <div v-if="!threads.length" class="tlt-chat-image-wrap">
                <template v-if="loadingThreads">
                    <div class="tlt-chat-not-open-image">
                        <abstract-message-sent-image></abstract-message-sent-image>
                    </div>
                    <h3 class="tlt-chat-not-open-title">Loading...</h3>
                </template>
                <template v-else-if="isSearching">
                    <div class="tlt-chat-not-open-image">
                        <abstract-message-sent-image></abstract-message-sent-image>
                    </div>
                    <h3 class="tlt-chat-not-open-title">No results found.</h3>
                </template>
                <template v-else>
                    <div class="tlt-chat-not-open-image">
                        <abstract-message-sent-image></abstract-message-sent-image>
                    </div>
                    <h3 class="tlt-chat-not-open-title">You're all caught up!</h3>
                    <span class="tlt-chat-not-open-sub-title">You have no new conversations.</span>
                    <div class="action-buttons-wrapper tlt-start-conversation">
                        <button class="action-button" @click="showNewConversationUi">
                        <span class="text">
                            START A CONVERSATION
                        </span>
                        </button>
                    </div>
                </template>
            </div>
            <template v-else-if="activeThread">
                <template v-if="deletedContacts.includes(activeThread.contact_id)">
                    <div class="tlt-chat-image-wrap">
                        <div class="tlt-chat-not-open-image">
                            <deleted-contact-placeholder-image></deleted-contact-placeholder-image>
                        </div>
                        <h3 class="tlt-chat-not-open-title">Oops! Looks like someone deleted this contact.</h3>
                        <span class="tlt-chat-not-open-sub-title"></span>
                        <div class="action-buttons-wrapper tlt-start-conversation">
                            <button class="action-button" @click="showNewConversationUi">
                        <span class="text">
                            START A CONVERSATION
                        </span>
                            </button>
                        </div>
                    </div>
                </template>
                <div v-else-if="selectedContact && selectedContact.id" class="tlt-chat-open">
                    <div class="thread-messages h-100 w-100" style="background: white;" @click="markAsRead">
                        <messages :key="`messages-${selectedContact.id}`" :showNewLine="showNewLine"></messages>

                        <vx-collapsible
                            v-show="showScheduledMessagesBox"
                            class="thread-messages-collapsible"
                            :toggle-msg="showScheduledMessages ? 'Hide Scheduled Messages' : 'Show Scheduled Messages'"
                            :height="210"
                            @toggle="showScheduledMessages = $event"
                        >
                            <scheduled-messages :key="`messages-${selectedContact.id}`" class="show"></scheduled-messages>
                        </vx-collapsible>

                        <thread-chat-input-v2 v-if="hasPermission"
                                              ref="chatInput"
                                              :key="`thread-chat-input-${activeThread.contact_id}`"
                                              :contact="selectedContact"></thread-chat-input-v2>
                        <div v-else-if="zendeskLoaded" class="zendesk-no-chat-input-spacer"></div>
                    </div>
                    <thread-chat-actions
                        @claimThread="onClaimThread"
                        v-if="shouldShowRespondActions">
                    </thread-chat-actions>
                </div>
                <contact-panel
                    v-if="selectedContact && isProfileSectionOpen"
                    @close="$emit('close')"
                >
                </contact-panel>
            </template>
        </template>
    </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import SelectedThreadImage from './misc/SelectedThreadImage.vue';
import AbstractMessageSentImage from './misc/AbstractMessageSentImage.vue';
import DeletedContactPlaceholderImage from './misc/DeletedContactPlaceholderImage.vue';
import ThreadChatInputV2 from './misc/ThreadChatInputV2.vue';
import ThreadChatActions from './ThreadChatActions.vue';
import Messages from './Messages.vue';
import ScheduledMessages from './ScheduledMessages.vue';
import {VxCollapsible} from '@voxie/frontend-components-v3';
import { CONTACT_SUBSCRIPTION_STATUS_OPT_OUT, CONTACT_SUBSCRIPTION_TYPE_TRANSACTIONAL } from '../../../constants/contactSubscription';
import ContactPanel from './ContactPanel.vue';

export default {
    name: 'ThreadChat',
    components: {
        VxCollapsible,
        Messages,
        ScheduledMessages,
        ThreadChatActions,
        ThreadChatInputV2,
        AbstractMessageSentImage,
        SelectedThreadImage,
        DeletedContactPlaceholderImage,
        ContactPanel,
    },
    inject: ['FEATURES'],
    props: {
        isProfileSectionOpen: {
            type: Boolean,
            default: false,
        },
        isSearching: {
            type: Boolean
        },
    },
    data() {
        return {
            showNewLine: false,
            teamId: Spark.state.currentTeam.id,
            showScheduledMessages: false,
        };
    },
    computed: {
        ...mapGetters({
            'threads': 'getThreads',
            'activeThread': 'getActiveThread',
        }),
        ...mapState({
            selectedContact: (state) => state.contacts_v3.selectedContact,
            deletedContacts: (state) => state.contacts_v3.deletedContacts,
            selectedThreads: (state) => state.threads.selectedThreads,
            activeThreadsInbox: (state) => state.threads.activeThreadsInbox,
            loadingThreads: (state) => state.threads.loadingThreads,
        }),
        hasPermission() {
            return this.FEATURES.simple_message_hub ||
                (this.activeThread && this.activeThread.assignee_id === Spark.state.user.id) || false;
        },
        shouldShowRespondActions() {
            return ['unassigned', 'all'].includes(this.activeThreadsInbox);
        },
        zendeskLoaded() {
            return (window.zE !== undefined);
        },
        showScheduledMessagesBox() {
            return !!(this.selectedContact.scheduled_messages.length)
        },
        isContactSubscribed() {
            return this.contactSubscriptions.find((s) => s.type === CONTACT_SUBSCRIPTION_TYPE_TRANSACTIONAL)?.status !== CONTACT_SUBSCRIPTION_STATUS_OPT_OUT;
        }
    },
    watch: {
        selectedContact() {
            if (this.hasPermission) {
                this.$nextTick(() => {
                    if (this.isContactSubscribed) {
                        this.focusTextArea()
                    }
                });
            }
        },
    },
    created() {
    },
    methods: {
        ...mapActions({
           'storeCheckpoint': 'storeCheckpoint',
        }),
        focusTextArea() {
            this.$refs.chatInput.$el.getElementsByTagName('textarea')[0].focus();
        },
        markAsRead() {
            this.showNewLine = this.activeThread.unread > 0;
            if(!this.hasPermission || this.activeThread.unread <= 0) return false;

            this.storeCheckpoint({thread: this.activeThread}).then(() => {
                this.$emit('markedAsRead')
            }).catch(e => {
                this.$toasted.global.platform_error();
                throw e;
            });
        },
        showNewConversationUi() {
            Bus.$emit('showNewConversationUi', true);
        },
        onClaimThread(thread, close) {
            this.$emit('claimThread', thread, close)
        }
    },
};
</script>

<style scoped>
.zendesk-no-chat-input-spacer {
    height: 90px;
}
</style>
