<template>
    <div>
        <playbook-audience :modelValue="props.modelValue.audience || {}"
            @update:modelValue="emitValue('audience', $event)"
            :type="AUDIENCE_TYPE_MARKETING"></playbook-audience>

            <playbook-initial-delay :modelValue="props.modelValue.initial_delay"
                @update:modelValue="emitValue('initial_delay', $event)"></playbook-initial-delay>

        <playbook-form-card :icon="faMessage">

            <template v-slot:header>Messages</template>

            <playbook-predefined-question :modelValue="{ message: props.modelValue.introduction_message }" @update:modelValue="emitValue('introduction_message', $event.message)" :maxlength="330">
                <template v-slot:label>
                    Introduction
                </template>
            </playbook-predefined-question>

            <playbook-predefined-question :modelValue="props.modelValue.name_question" @update:modelValue="emitValue('name_question', $event)" :maxlength="330">
                <template v-slot:label>
                    Ask Name
                </template>
            </playbook-predefined-question>

            <playbook-predefined-question :editable="true" :disableFieldType="true" :modelValue="props.modelValue.birthday_question" @update:modelValue="emitValue('birthday_question', $event)" :maxlength="330">
                <template v-slot:label>
                    Ask Birthday
                </template>
            </playbook-predefined-question>
        </playbook-form-card>

        <playbook-custom-questions :modelValue="props.modelValue.additional_questions || []"
            @update:modelValue="emitValue('additional_questions', $event)"></playbook-custom-questions>

        <playbook-final-message :modelValue="props.modelValue.final_message"
            @update:modelValue="emitValue('final_message', $event)"></playbook-final-message>
    </div>
</template>

<script setup>
import { AUDIENCE_TYPE_MARKETING } from '~/constants/campaign';
import PlaybookAudience from './audience/PlaybookAudience.vue';
import PlaybookCustomQuestions from './PlaybookCustomQuestions.vue';
import PlaybookFormCard from '../PlaybookFormCard.vue';
import { faMessage } from '@fortawesome/pro-solid-svg-icons';
import PlaybookPredefinedQuestion from './PlaybookPredefinedQuestion.vue';
import PlaybookInitialDelay from './PlaybookInitialDelay.vue';
import PlaybookFinalMessage from './PlaybookFinalMessage.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => ({})
    },
});

const emit = defineEmits(['update:modelValue']);

const emitValue = (key, value) => {
    emit('update:modelValue', {
        ...props.modelValue,
        [key]: value,
    })
}

</script>
