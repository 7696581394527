<template>
    <vx-selectable placeholder="operator"
        :modelValue="modelValueWithoutIgnoringYear"
        @update:modelValue="emit('update:modelValue', $event)"
        label="label"
        :getOptionLabel="(option) => option.label.toLowerCase()"
        :reduce="operator => operator.value"
        :options="operators"
        data-test="operator-selector"
        :clearable="false">
        </vx-selectable>
</template>

<script setup>
import { VxSelectable } from '@voxie/frontend-components';
import { computed } from 'vue';
import { OPERATORS_DATE } from './segmentFiltersHelpers'

const operator_text = [
    { value: "=", label: "Is" },
    { value: "!=", label: "Is Not" },
    { value: "is null", label: "Is Blank" },
    { value: "is not null", label: "Is Not Blank" },
    { value: "like", label: "Contains" },
    { value: "not like", label: "Does Not Contain" },
]

const operator_number = [
    { value: "=", label: "Is Equal to" },
    { value: "!=", label: "Is NOT Equal to" },
    { value: "is null", label: "Is Blank" },
    { value: "is not null", label: "Is Not Blank" },
    { value: "<", label: "Is Less Than" },
    { value: "<=", label: "Is Less Than Or Equal To" },
    { value: ">", label: "Is Greater Than" },
    { value: ">=", label: "Is Greater Than Or Equal To" },
]

const operator_contact_entry_point = [
    { value: "=", label: "Is Equal to" },
    { value: "!=", label: "Is NOT Equal to" },
    { value: "is null", label: "Is Blank" },
    { value: "is not null", label: "Is Not Blank" },
]

const operator_contact_phone_state = [
    { value: "=", label: "Is Equal to" },
    { value: "!=", label: "Is NOT Equal to" },
    { value: "is null", label: "Is Blank" },
    { value: "is not null", label: "Is Not Blank" },
]

const props = defineProps({
    filter: {
        type: Object,
        required: true,
    },
    modelValue: {
        required: true,
    },
})

const emit = defineEmits(['update:modelValue']);

const modelValueWithoutIgnoringYear = computed(() => {
    if (props.modelValue?.endsWith('_ignoring_year')) {
        return props.modelValue.slice(0, -14);
    }

    return props.modelValue;
});

const operators = computed(() => {
    switch (props.filter.data_type) {
        case 'number':
            return operator_number;
        case 'date':
            return OPERATORS_DATE;
        default:
            // Entry point has text data type
            if (props.filter.attribute_name === 'contacts.entry_point') {
                return operator_contact_entry_point;
            } else if (props.filter.attribute_name === 'contacts.phone_state') {
                return operator_contact_phone_state;
            }

            return operator_text;
    }
})
</script>
