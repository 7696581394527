<template>
    <div class="card card-default">
        <!-- Update Payment Method Heading -->
        <div class="card-header">
            <div class="float-left">Update Payment Method</div>

            <div class="float-right">
                <span v-if="team.card_last_four">
                    <i :class="['fa', 'fa-btn', cardIcon]"></i>
                    ************{{ team.card_last_four }}
                </span>
            </div>

            <div class="clearfix"></div>
        </div>

        <div class="card-body">
            <!-- Card Update Success Message -->
            <div class="alert alert-success" v-if="form.successful">Your card has been updated.</div>

            <!-- Generic 500 Level Error Message / Stripe Threw Exception -->
            <div class="alert alert-danger" v-if="form.errors.has('form')">
                We had trouble updating your card. It's possible your card provider is preventing us from charging the
                card. Please contact your card provider or customer support.
            </div>

            <form role="form">
                <!-- Cardholder's Name -->
                <div class="form-group row">
                    <label for="name" class="col-md-4 col-form-label text-md-right">Cardholder's Name</label>

                    <div class="col-md-6">
                        <input type="text" class="form-control" v-model="cardForm.name" />
                    </div>
                </div>

                <!-- Card Details -->
                <div class="form-group row">
                    <label for="payment-card-element" class="col-md-4 col-form-label text-md-right">Card</label>

                    <div class="col-md-6">
                        <div id="payment-card-element"></div>
                        <input
                            type="hidden"
                            class="form-control"
                            :class="{ 'is-invalid': cardForm.errors.has('card') }"
                        />
                        <span class="invalid-feedback" v-show="cardForm.errors.has('card')">
                            {{ cardForm.errors.get('card') }}
                        </span>
                    </div>
                </div>

                <!-- Billing Address Fields -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Address</label>

                    <div class="col-sm-6">
                        <input
                            type="text"
                            class="form-control"
                            v-model="form.address"
                            :class="{ 'is-invalid': form.errors.has('address') }"
                        />

                        <span class="invalid-feedback" v-show="form.errors.has('address')">{{
                            form.errors.get('address')
                        }}</span>
                    </div>
                </div>

                <!-- Address Line 2 -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Address Line 2</label>

                    <div class="col-sm-6">
                        <input
                            type="text"
                            class="form-control"
                            v-model="form.address_line_2"
                            :class="{ 'is-invalid': form.errors.has('address_line_2') }"
                        />

                        <span class="invalid-feedback" v-show="form.errors.has('address_line_2')">{{
                            form.errors.get('address_line_2')
                        }}</span>
                    </div>
                </div>

                <!-- City -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">City</label>

                    <div class="col-sm-6">
                        <input
                            type="text"
                            class="form-control"
                            v-model="form.city"
                            :class="{ 'is-invalid': form.errors.has('city') }"
                        />

                        <span class="invalid-feedback" v-show="form.errors.has('city')">{{
                            form.errors.get('city')
                        }}</span>
                    </div>
                </div>

                <!-- State & ZIP Code -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">State & ZIP / Postal Code</label>

                    <!-- State -->
                    <div class="col-sm-3">
                        <input
                            type="text"
                            class="form-control"
                            v-model="form.state"
                            placeholder="State"
                            :class="{ 'is-invalid': form.errors.has('state') }"
                        />

                        <span class="invalid-feedback" v-show="form.errors.has('state')">{{
                            form.errors.get('state')
                        }}</span>
                    </div>

                    <!-- Zip Code -->
                    <div class="col-sm-3">
                        <input
                            type="text"
                            class="form-control"
                            v-model="form.zip"
                            placeholder="Postal Code"
                            :class="{ 'is-invalid': form.errors.has('zip') }"
                        />

                        <span class="invalid-feedback" v-show="form.errors.has('zip')">{{
                            form.errors.get('zip')
                        }}</span>
                    </div>
                </div>

                <!-- Country -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Country</label>

                    <div class="col-sm-6">
                        <select
                            class="form-control"
                            v-model="form.country"
                            :class="{ 'is-invalid': form.errors.has('country') }"
                        >
                            <option v-for="country in countries" :key="country.code" :value="country.code">
                                {{ country.name }}
                            </option>
                        </select>

                        <span class="invalid-feedback" v-show="form.errors.has('country')">{{
                            form.errors.get('country')
                        }}</span>
                    </div>
                </div>

                <!-- Zip Code -->
                <div class="form-group row" v-if="!spark.collectsBillingAddress">
                    <label for="zip" class="col-md-4 col-form-label text-md-right">ZIP / Postal Code</label>

                    <div class="col-md-6">
                        <input type="text" class="form-control" v-model="form.zip" />
                    </div>
                </div>

                <!-- Update Button -->
                <div class="form-group row mb-0">
                    <div class="col-md-6 offset-md-4">
                        <button type="submit" class="btn btn-primary" @click.prevent="update" :disabled="form.busy">
                            <span v-if="form.busy"> <i class="fa fa-btn fa-spinner fa-spin"></i> Updating </span>

                            <span v-else> Update </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from '~/services/axios';

export default {
    props: {
        team: {
            required: true,
        },
        collectsBillingAddress: {
            required: true,
        },
        countries: {
            required: true,
        },
    },
    data() {
        return {
            cardElement: null,

            form: new SparkForm({
                stripe_payment_method: '',
                address: '',
                address_line_2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US',
            }),

            cardForm: new SparkForm({
                name: '',
            }),
            stripe: Spark.stripeKey
                ? Stripe(Spark.stripeKey, {
                      apiVersion: Spark.stripeApiVersion,
                  })
                : null,
        };
    },
    mounted() {
        this.cardElement = this.createCardElement('#payment-card-element');

        this.initializeBillingAddress();

        Bus.$on(`sparkFormUpdated.${this.form.sparkFormId}`, this.$forceUpdate);
        Bus.$on(`sparkFormUpdated.${this.cardForm.sparkFormId}`, this.$forceUpdate);
    },
    methods: {
        /**
         * Create a Stripe Card Element.
         */
        createCardElement(container) {
            if (!this.stripe) {
                throw 'Invalid Stripe Key/Secret';
            }

            var card = this.stripe.elements().create('card', {
                hideIcon: true,
                hidePostalCode: true,
                style: {
                    base: {
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                        fontFamily:
                            'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
                        color: '#495057',
                        fontSize: '15px',
                    },
                },
            });

            card.mount(container);

            return card;
        },

        generateToken(callback) {
            return axios.get('/stripe/token').then((response) => callback(response.data.clientSecret));
        },
        /**
         * Initialize the billing address form for the team.
         */
        initializeBillingAddress() {
            if (!Spark.collectsBillingAddress) {
                return;
            }

            this.form.address = this.team.billing_address;
            this.form.address_line_2 = this.team.billing_address_line_2;
            this.form.city = this.team.billing_city;
            this.form.state = this.team.billing_state;
            this.form.zip = this.team.billing_zip;
            this.form.country = this.team.billing_country || 'US';
        },

        /**
         * Update the team's card information.
         */
        update() {
            this.form.busy = true;
            this.form.errors.forget();
            this.form.successful = false;
            this.cardForm.errors.forget();

            const payload = {
                name: this.cardForm.name,
                address: {
                    line1: this.form.address || '',
                    line2: this.form.address_line_2 || '',
                    city: this.form.city || '',
                    state: this.form.state || '',
                    postal_code: this.form.zip || '',
                    country: this.form.country || '',
                },
            };

            this.generateToken((secret) => {
                this.stripe
                    .handleCardSetup(secret, this.cardElement, {
                        payment_method_data: {
                            billing_details: payload,
                        },
                    })
                    .then((response) => {
                        if (response.error) {
                            this.cardForm.errors.set({ card: [response.error.message] });

                            this.form.busy = false;
                        } else {
                            this.sendUpdateToServer(response.setupIntent.payment_method);
                        }
                    });
            });
        },

        /**
         * Send the credit card update information to the server.
         */
        sendUpdateToServer(paymentMethod) {
            this.form.stripe_payment_method = paymentMethod;

            Spark.put(this.urlForUpdate, this.form).then(() => {
                Bus.$emit('updateTeam');

                this.cardForm.name = '';
                this.cardForm.number = '';
                this.cardForm.cvc = '';
                this.cardForm.month = '';
                this.cardForm.year = '';

                if (!Spark.collectsBillingAddress) {
                    this.form.zip = '';
                }
            });
        },
    },
    computed: {
        spark() {
            return window.Spark;
        },

        /**
         * Get the URL for the payment method update.
         */
        urlForUpdate() {
            return `/settings/teams/${this.team.id}/payment-method`;
        },

        /**
         * Get the proper brand icon for the customer's credit card.
         */
        cardIcon() {
            if (!this.team.card_brand) {
                return 'fa-cc-stripe';
            }

            switch (this.team.card_brand) {
                case 'amex':
                    return 'fa-cc-amex';
                case 'diners':
                    return 'fa-cc-diners-club';
                case 'discover':
                    return 'fa-cc-discover';
                case 'jcb':
                    return 'fa-cc-jcb';
                case 'mastercard':
                    return 'fa-cc-mastercard';
                case 'visa':
                    return 'fa-cc-visa';
                default:
                    return 'fa-cc-stripe';
            }
        },

        /**
         * Get the placeholder for the team's credit card.
         */
        placeholder() {
            return this.team.card_last_four ? `************${this.team.card_last_four}` : '';
        },
    },
};
</script>
