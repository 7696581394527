import BaseClient from './BaseClient';

class AuthConfigsClient extends BaseClient {
    list(teamId, params) {
        return this.makeCoreApiRequest(`${this.apiBase}/teams/${teamId}/auth-configs`, 'get', null, params);
    }

    create(teamId, data) {
        return this.makeCoreApiRequest(`${this.apiBase}/teams/${teamId}/auth-configs`, 'post', data);
    }

    get(teamId, authConfigId) {
        return this.makeCoreApiRequest(`${this.apiBase}/teams/${teamId}/auth-configs/${authConfigId}`, 'get');
    }

    update(teamId, authConfigId, data) {
        return this.makeCoreApiRequest(`${this.apiBase}/teams/${teamId}/auth-configs/${authConfigId}`, 'patch', data);
    }

    delete(teamId, authConfigId) {
        return this.makeCoreApiRequest(`${this.apiBase}/teams/${teamId}/auth-configs/${authConfigId}`, 'delete');
    }
}

export default new AuthConfigsClient({
    apiBase: window.Spark.apiBase,
});
