<template>
    <vx-table-row>
        <vx-table-row-title>
            {{ model.email }}
        </vx-table-row-title>

        <vx-table-row-item>
            <template v-slot:label> Team Role: </template>
            {{ TEAM_USER_ROLES[model.role] || '-' }}
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Invited At: </template>
            <span :class="createdAtColor" :title="shortDateTime(model.created_at)" :key="createdAtKey">
                {{ fromNow(model.created_at) }}
            </span>
        </vx-table-row-item>

        <vx-table-row-buttons v-if="isAdminOrOwner">
            <vx-button data-test="delete"
                @click="deleteInvite()"
                type="button"
                color="muted"
                outline
                class="vx-w-[45px]">
                <span class="vx-hidden">Delete</span>
                <font-awesome-icon :icon="faTrash"></font-awesome-icon>
            </vx-button>

            <vx-button
                data-test="resend"
                @click="resendInvite()"
                color="muted"
                outline>
                Re-send <font-awesome-icon :icon="faPaperPlane" />
            </vx-button>
        </vx-table-row-buttons>
    </vx-table-row>
</template>

<script setup>
import {
    faPaperPlane,
    faTrash
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxButton,
    VxTableRow,
    VxTableRowButtons,
    VxTableRowItem,
    VxTableRowTitle,
} from "@voxie/frontend-components";
import { computed } from "vue";
import { useToasted } from '~/composables/useToasted';
import { TEAM_USER_ROLES } from "~/constants/roles";
import { HTTP_INTERNAL_SERVER_ERROR } from "~/support/HttpStatuses";
import dayjs from '~/utils/dayjs';
import { useInterval } from '@vueuse/core';
import { fromNow, shortDateTime } from "~/utils/date";
import invitationsClient from "~/services/invitationsClient";
import { ref } from "vue";

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;
const isAdminOrOwner = Spark.isAdminOrOwner;

const model = defineModel({
    type: Object,
    required: true,
});

const catchError = (e) => {
    console.error(e);
    if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
        toasted.global.platform_error();
    } else {
        toasted.error(e.response?.data?.message || 'Something went wrong.');
    }
}

const emit = defineEmits(['deleted']);

const deleteInvite = async (emitDeleted = true) => {
    if (!window.confirm('Are you sure? There is no undo.')) return;

    try {
        await invitationsClient.deleteInvite(teamId, model.value.id);
        if (emitDeleted) {
            emit('deleted', model.value.id);
        }
    } catch (e) {
        catchError(e);
    }
};

const resent = ref(false);
const resending = ref(false);

const deleteAndResend = async () => {
    if (resent.value) {
        emit('resent', model.value);
        return;
    }

    if (resending.value) {
        return;
    }

    resending.value = true;
    try {
        await deleteInvite(false);
        await invitationsClient.sendInvite(teamId, {
            email: model.value.email,
            role: model.value.role,
        });
        emit('resent', model.value)
        resent.value = true;
    } catch (e) {
        catchError(e);
    } finally {
        resending.value = false;
    }
}

const resendInvite = async () => {
    if (resent.value) {
        emit('resent', model.value);
        return;
    }

    if (resending.value) {
        return;
    }

    if (dayjs().diff(model.value.created_at, 'days') > 7) {
        deleteAndResend();
        return;
    }

    if (!window.confirm('Are you sure you want to re-send it?')) return;

    resending.value = true;

    try {
        await invitationsClient.resendInvite(teamId, model.value.id);
        emit('resent', model.value);
        resent.value = true;
    } catch (e) {
        catchError(e)
    } finally {
        resending.value = false;
    }
};


const createdAtKey = useInterval(60000)
const createdAtColor = computed(() => {
    if (!model.value.created_at) {
        return null;
    }

    const diffInDays = dayjs().diff(model.value.created_at, "days");

    if (diffInDays <= 1) {
        return "vx-text-emerald-700";
    }

    if (diffInDays <= 5) {
        return "vx-text-amber-600";
    }

    return "vx-text-rose-600";
});

</script>
