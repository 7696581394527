<template>
    <div class="card card-default">
        <div class="card-header">
            Sound Recordings
        </div>

        <div class="alert alert-info mb-0" v-if="loadingImport">
            Don't close this window. Your files are being imported.
        </div>
        <div class="alert alert-success mb-0" v-else-if="importedSuccessfully">
            Your files were successfully imported
        </div>
        <div class="alert alert-danger mb-0" v-else-if="importedError">
            {{ importedError }}
        </div>

        <div class="card-body">
            <div class="row my-0">
                <div class="col-md-3 mb-2 mb-lg-0 relative">
                    <input type="file" id="import-file" ref="importFile" accept=".zip" @change="uploadRecordings()">
                    <button class="btn btn-primary w-100" ref="importBtn" :disabled="loadingImport" @click="importRecordings()">
                        {{ loadingImport ? 'Importing...' : 'Import Recordings'}}
                    </button>
                </div>

                <div class="col-md-3 mb-2 mb-lg-0">
                    <button class="btn btn-primary w-100" ref="exportBtn" :disabled="loadingExport" @click="exportRecordings()">
                        {{ loadingExport ? 'Loading...' : 'Export Recordings' }}
                    </button>
                </div>

                <div class="col-md-6 text-right">
                    <button class="btn btn-primary" ref="templatesBtn" :disabled="loadingTemplate" @click="templateRecordings()">
                        {{ loadingTemplate ? 'Loading...' : 'Download Template Recordings' }}
                    </button>
                </div>
            </div>
        </div>

        <div class="p-4 text-center" v-if="loadingSounds && !sounds.length">
            <loading-resource />
        </div>
        <shift-to-text-sounds-table @soundUpdated="updateSound" :sounds="sounds"></shift-to-text-sounds-table>

    </div>
</template>

<script>
import { isAuthErrorEcho } from '../../../../utils/helpers';
import ShiftToTextSoundsTable from './ShiftToTextSoundsTable.vue';
import shiftToTextClient from '../../../../services/shiftToTextClient'
import LoadingResource from '../../../general/LoadingResource.vue';
export default {
  components: { ShiftToTextSoundsTable, LoadingResource },
    data() {
        return {
            sounds: [],
            loadingSounds: false,
            loadingTemplate: false,
            loadingExport: false,
            loadingImport: false,
            importedSuccessfully: false,
            importedError: null,
            echoErrors: [],
        };
    },
    mounted() {
        this.listenForEchoEvents()
        this.fetchSounds()
    },
    methods: {
        listenForEchoEvents() {
            const teamId = Spark.state.currentTeam.id;
            Echo.private(`shift_to_text_import.${teamId}`)
                .listen('ShiftToTextImportSuccessed', () => {
                    this.loadingImport = false;
                    this.importedSuccessfully = true
                    setTimeout(() => this.importedSuccessfully = false, 6000)
                    this.fetchSounds()
                })
                .listen('ShiftToTextImportFailed', event => {
                    this.loadingImport = false
                    this.importedError = event.shift_to_text_import.message
                }).error?.((e) => {
                    if (this.echoErrors.length <= 3 && !isAuthErrorEcho(e)) {
                        window?.Bugsnag?.notify(e instanceof Error ? e : new Error(JSON.stringify(e)));
                        this.echoErrors.push(e);
                    }
                });
        },
        importRecordings() {
            this.$refs.importFile.click()
        },
        importFile() {
            return this.$refs.importFile.files[0]
        },
        importFileSizeValid() {
            const file = this.importFile()
            if (!file) {
                return false;
            }
            if (!file?.size) {
                return false;
            }
            const fileSize = file.size / 1024 / 1024; // in MB
            if (fileSize > 20) {
                return false;
            }
            return true;
        },
        uploadRecordings() {
            if(this.loadingImport) {
                return
            }
            if(!this.importFileSizeValid()) {
                this.importedError = 'Your file is too large, the size limit of your zip file should not exceed 20MB'
                return
            }
            const file = this.importFile()
            const teamId = Spark.state.currentTeam.id;
            this.importedError = null;
            this.loadingImport = true;
            shiftToTextClient.import(teamId, file).catch((error) => {
                console.log(error);
                this.$toasted.global.platform_error();
                this.loadingImport = false;
            }).finally(() => {
                this.$refs.importFile.value = ''
            })
        },
        templateRecordings() {
            if(this.loadingTemplate) {
                return
            }
            this.loadingTemplate = true;
            const teamId = Spark.state.currentTeam.id;
            shiftToTextClient.templates(teamId).catch(() => {
                this.$toasted.global.platform_error()
            }).finally(() => {
                this.loadingTemplate = false
            })
        },
        exportRecordings() {
            if(this.loadingExport) {
                return
            }
            this.loadingExport = true;
            const teamId = Spark.state.currentTeam.id;
            shiftToTextClient.export(teamId).catch(() => {
                this.$toasted.global.platform_error()
            }).finally(() => {
                this.loadingExport = false
            })
        },
        updateSound(sound) {
            const soundIndex = this.sounds.findIndex((previousSound) => previousSound.type === sound.type)
            if (soundIndex !== -1) {
                this.sounds[soundIndex] = sound;
            }
        },
        fetchSounds() {
            const teamId = Spark.state.currentTeam.id;
            this.loadingSounds = true;
            shiftToTextClient.sounds(teamId).then((response) => {
                const sounds = response?.data?.data;
                this.sounds = sounds || [];
            }).catch(() => {
                this.$toasted.global.platform_error()
            }).finally(() => {
                this.loadingSounds = false;
            })
        }
    }
};
</script>
<style scoped>
#import-file {
    opacity: 0;
    height: 1px;
    width: 1px;
    position: absolute;
}
</style>
