<template>
    <div class="card card-default">
        <div class="card-header">Redeem Coupon</div>

        <div class="card-body">
            <div class="alert alert-success" v-if="form.successful">
                Coupon accepted! The discount will be applied to your next invoice.
            </div>

            <form role="form">
                <!-- Coupon Code -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Coupon Code</label>

                    <div class="col-md-6">
                        <input
                            type="text"
                            class="form-control"
                            name="coupon"
                            v-model="form.coupon"
                            :class="{ 'is-invalid': form.errors.has('coupon') }"
                        />

                        <span class="invalid-feedback" v-show="form.errors.has('coupon')">
                            {{ form.errors.get('coupon') }}
                        </span>
                    </div>
                </div>

                <!-- Redeem Button -->
                <div class="form-group row mb-0">
                    <div class="offset-md-4 col-md-6">
                        <button type="submit" class="btn btn-primary" @click.prevent="redeem" :disabled="form.busy">
                            <span v-if="form.busy"> <i class="fa fa-btn fa-spinner fa-spin"></i> Redeeming </span>

                            <span v-else> Redeem </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: ['team'],

    /**
     * The component's data.
     */
    data() {
        return {
            form: new SparkForm({
                coupon: '',
            }),
        };
    },

    mounted() {
        Bus.$on(`sparkFormUpdated.${this.form.sparkFormId}`, this.$forceUpdate);
    },

    methods: {
        /**
         * Redeem the given coupon code.
         */
        redeem() {
            Spark.post(this.urlForRedemption, this.form).then(() => {
                this.form.coupon = '';

                Bus.$emit('updateDiscount');
            });
        },
    },

    computed: {
        /**
         * Get the URL for redeeming a coupon.
         */
        urlForRedemption() {
            return `/settings/teams/${this.team.id}/payment-method/coupon`;
        },
    },
};
</script>
