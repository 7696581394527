<template>
    <tr class="c-table__row">
        <td class="c-table__cell center d-none d-sm-table-cell">
            {{ promotion.id }}
        </td>
        <td class="c-table__cell">
            {{ promotion.name }}
        </td>
        <td class="c-table__cell center d-none d-md-table-cell">
            <span class="u-block huge-text text-nowrap">
                {{ promotion.allocated_coupons_count }}/{{ promotion.coupons_count }}
            </span>
        </td>
        <td class="c-table__cell center">
            <dropdown-actions label="Actions">
                <dropdown-action-item event="promotionShow" :payload="promotion">View</dropdown-action-item>
                <dropdown-action-item event="promotionEdit" :payload="promotion">Edit</dropdown-action-item>
                <dropdown-action-item event="promotionDelete" :payload="promotion">Delete</dropdown-action-item>
            </dropdown-actions>
        </td>
    </tr>
</template>

<script>
import DropdownActionItem from '~/components/general/DropDownActionItem.vue';
import DropdownActions from '~/components/general/DropDownActions.vue';
import { promotionsClient } from '../../services';
import Swal from 'sweetalert2';


export default
{
    components: {
        DropdownActionItem,
        DropdownActions,
    },
    props: ['promotion'],
    data() {
        return {
            team_id: Spark.state.currentTeam.id,
        };
    },
    mounted() {
        Bus.$on('promotionDelete',(promotion) =>{
            this.deletePromotion(promotion);
        });
    },

    methods : {
        deletePromotion(promotion) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Deleting promotions is not a reversible action and takes effect immediately.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'I am aware, delete the promotion!',
            }).then(({ value }) => {
                if (!value) {
                    return;
                }

                promotionsClient.deletePromotion(promotion.team_id, promotion.id).then(() => {
                    Bus.$emit('promotionDeleted', promotion);
                }).catch((error) => {
                    this.$toasted.global.platform_error();
                    console.error(error.response.data);
                });
            });
        },
    },
}
</script>
