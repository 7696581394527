<template>
    <vx-expandable-card>
        <template v-slot:header> Search for a New Number </template>

        <template v-if="phoneNumber?.phone_number"
            v-slot:content>
            <selected-phone-number-alert :phone-number="phoneNumber"
                @edit="reset()"></selected-phone-number-alert>
        </template>

        <template v-else
            v-slot:content>
            <vx-tabs v-model="currentTab"
                size="lg"
                class="vx-mb-4">
                <vx-tab value="longCode">Long Code</vx-tab>
                <vx-tab value="tollFree">Toll Free</vx-tab>
            </vx-tabs>

            <div v-if="currentTab === 'longCode'"
                class="vx-flex vx-flex-col vx-gap-4">
                <div>
                    <vx-label size="md">Country</vx-label>

                    <vx-radio-selection v-model="state.country"
                        class="vx-grid vx-grid-cols-2 vx-gap-2"
                        size="sm">
                        <vx-radio-selection-item v-for="(country) in countries"
                            :key="country.id"
                            :value="country.id">
                            <span class="vx-font-normal">{{ country.label }}</span>
                        </vx-radio-selection-item>
                    </vx-radio-selection>
                </div>

                <div>
                    <vx-label size="md">Search By</vx-label>

                    <vx-radio-selection v-model="state.context"
                        class="vx-grid vx-gap-2 vx-grid-cols-2 lg:vx-grid-cols-4"
                        size="sm">
                        <vx-radio-selection-item v-for="(option) in contextOptions"
                            :key="option.id"
                            :value="option.id">
                            <template v-slot:header>
                                <span class="vx-font-normal">{{ option.label }}</span>
                            </template>
                        </vx-radio-selection-item>
                    </vx-radio-selection>
                </div>


                <div class="vx-flex vx-flex-col vx-gap-2 vx-mt-2 md:vx-grid md:vx-grid-cols-24">
                    <div v-if="state.context === 'contains'"
                        class="vx-col-span-7">
                        <vx-selectable placeholder="match"
                            size="lg"
                            :options="matchOptions"
                            v-model="state.matchTo"
                            :clearable="false"
                            :reduce="(option) => option.id">
                        </vx-selectable>
                    </div>

                    <div :class="{
                        'vx-col-span-12': state.context === 'contains',
                        'vx-col-span-18': state.context !== 'contains'
                    }">
                        <vx-input v-model="state.query"
                            data-test="search-input"
                            placeholder="Search"
                            @keypress.enter.prevent="search()"
                            maxlength="191"
                            size="lg"></vx-input>
                    </div>

                    <vx-button data-test="search-button"
                        :class="{
                            'vx-col-span-5': state.context === 'contains',
                            'vx-col-span-6': state.context !== 'contains'
                        }"
                        @click.prevent="search()"
                        :loading="searching"
                        size="lg"
                        color="primary">
                        Search <font-awesome-icon :icon="faSearch"></font-awesome-icon>
                    </vx-button>
                </div>

                <div v-if="searching"
                    class="vx-text-center vx-py-10">
                    <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
                </div>
                <vx-alert v-else-if="searched && error"
                    :closeable="false"
                    color="danger">
                    {{ error }}
                </vx-alert>
                <vx-alert v-else-if="searched && !results.length"
                    :closeable="false"
                    color="info">
                    No phone numbers found matching the search criteria.
                </vx-alert>
                <div v-else-if="results.length">
                    <search-phone-numbers-list @selected="selectedPhoneNumber($event)"
                        :list="results"></search-phone-numbers-list>
                </div>
            </div>

            <div v-if="currentTab === 'tollFree'">
                <vx-button data-test="submit-button"
                    @click.prevent="searchTollFree()"
                    :loading="searching"
                    size="lg"
                    color="primary">
                    Find a Toll Free Number <font-awesome-icon :icon="faSearch"></font-awesome-icon>
                </vx-button>
            </div>

        </template>
    </vx-expandable-card>
</template>

<script setup>
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxAlert,
    VxButton,
    VxExpandableCard,
    VxIconLoading,
    VxInput,
    VxLabel,
    VxRadioSelection,
    VxRadioSelectionItem,
    VxSelectable,
    VxTab,
    VxTabs,
} from '@voxie/frontend-components';
import { reactive, ref, watch } from 'vue';
import { adminPhoneNumbersClient } from '~/services';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_NOT_FOUND } from '~/support/HttpStatuses';
import SearchPhoneNumbersList from './SearchPhoneNumbersList.vue';
import SelectedPhoneNumberAlert from './SelectedPhoneNumberAlert.vue';

const teamId = Spark.state.currentTeam.id;

const searching = ref(false);
const searched = ref(false);
const error = ref('');

const state = reactive({
    country: 'US',
    context: 'postalCode',
    matchTo: 'any',
    query: '',
    vendor: 'twilio',
})

const results = ref([]);

watch(() => state.country, (context) => {
    if (context !== 'contains' && state.matchTo !== null) {
        state.matchTo = 'any';
    }
}, {
    deep: true,
});

const phoneNumber = defineModel({
    type: Object,
    default: () => { }
});

const clearSearch = () => {
    state.country = 'US';
    state.context = 'postalCode';
    state.matchTo = 'any';

    state.query = '';

    results.value = [];
    searched.value = false;
};
const reset = () => {
    phoneNumber.value = {};
    clearSearch();
};

const selectedPhoneNumber = (event) => {
    phoneNumber.value = event;
    clearSearch();
}

const currentTab = ref('longCode');

const countries = [
    { id: 'US', label: 'United States' },
    { id: 'CA', label: 'Canada' },
];

const contextOptions = [
    { id: 'postalCode', label: 'Postal' },
    { id: 'areaCode', label: 'Area Code' },
    { id: 'location', label: 'Location' },
    { id: 'contains', label: 'Number' },
];

const matchOptions = [
    { id: 'any', label: 'Any part' },
    { id: 'first', label: 'First part' },
    { id: 'last', label: 'Last part ' },
];

const catchError = (e) => {
    console.error(e);
    if (
        !e?.response?.status ||
        e?.response?.status >= HTTP_INTERNAL_SERVER_ERROR ||
        e?.response?.status === HTTP_NOT_FOUND
    ) {
        error.value = "Something went wrong. Please try again.";
    } else {
        error.value = e?.response?.data?.message || 'Something went wrong.';
    }
}

const search = async () => {
    if (searching.value) {
        return;
    }

    searched.value = true;
    searching.value = true;

    const leftPad = (s, c, n) => c.repeat(n - s.length) + s; //leftPad('foo', '*', 5);
    const rightPad = (s, c, n) => s + c.repeat(n - s.length);

    const payload = JSON.parse(JSON.stringify(state));

    if (payload.query && payload.matchTo !== null && payload.context === 'contains') {
        if (payload.matchTo === 'first') {
            payload.query = rightPad(state.query, '*', 10);
        } else if (payload.matchTo === 'last') {
            payload.query = leftPad(payload.query, '*', 10);
        }
    }

    try {
        const response = await adminPhoneNumbersClient.search(teamId, payload);
        results.value = response.data;
    } catch (e) {
        catchError(e);
    } finally {
        searching.value = false;
    }
}

const searchTollFree = async () => {
    if (searching.value) {
        return;
    }

    searching.value = true;

    try {
        const response = await adminPhoneNumbersClient.search(
            teamId,
            { context: 'tollFree', area_code: 833, vendor: 'twilio' }
        );
        if (!response.data?.length || !response.data?.[0]?.phone_number) {
            error.value = 'It was not possible to search a toll free number. Please try again.';
        } else {
            phoneNumber.value = response.data[0];
        }
    } catch (e) {
        catchError(e);
    } finally {
        searching.value = false;
    }
};

defineExpose({
    reset: reset,
})
</script>
