<template>
    <vx-expandable-card>
        <template v-slot:header>
            <div class="vx-flex vx-items-center">
                <span>SAML2 Role Mapping</span>
                <experimental-pill class="vx-ml-2"></experimental-pill>
            </div>
          </template>
        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-4">
                <div class="vx-flex vx-flex-col">
                    <vx-label class="vx-mb-1">Mode</vx-label>
                    <vx-select v-model="form.mode" :options="ROLE_MAPPING_MODES" data-test="mode"
                        :disabled="!props.connected"></vx-select>
                </div>

                <div v-if="[ROLE_MAPPING_ROOT_TEAM, ROLE_MAPPING_ALL_TEAMS].includes(form.mode)"
                    class="vx-flex vx-flex-col">
                    <vx-label class="vx-mb-1">Role</vx-label>
                    <vx-select v-model="form.role" :options="SSO_TEAM_USER_ROLES" data-test="role"
                        :disabled="!props.connected"></vx-select>
                </div>

                <template v-else-if="[ROLE_MAPPING_GROUP_ASSIGNMENT].includes(form.mode)">
                    <div class="vx-flex vx-flex-col">
                        <vx-label class="vx-mb-1">Groups Attribute</vx-label>
                        <vx-input v-model="form.groups_attribute" size="lg" type="url" placeholder="Groups Attribute"
                            :disabled="!props.connected" data-test="groups-attribute"></vx-input>
                    </div>

                    <div class="vx-flex vx-flex-col">
                        <vx-label class="vx-mb-1">Group Assignments</vx-label>
                        <div class="vx-relative">
                            <div class="vx-absolute vx-left-8 vx-top-0 vx-w-px vx-h-full vx-bg-slate-300 lg:vx-left-16">
                            </div>
                            <div v-for="groupAssignment in form.group_assignments" :key="`group-${groupAssignment.key}`"
                                class="vx-relative vx-mb-4 vx-flex vx-gap-2">
                                <vx-box class="vx-relative vx-p-3 vx-flex-grow">
                                    <div class="vx-flex vx-flex-col vx-mb-2 vx-gap-2">
                                        <vx-label>Group ID</vx-label>
                                        <vx-input v-model="groupAssignment.groupId" size="lg" type="text"
                                            placeholder="Group ID" :disabled="!props.connected"
                                            :data-test="`group-id`"></vx-input>
                                    </div>

                                    <div class="vx-flex vx-flex-col vx-gap-2">
                                        <vx-label class="vx-mb-1">Roles</vx-label>
                                        <div v-for="role in groupAssignment.roles" :key="`role-team-${role.key}`"
                                            class="vx-flex vx-items-center vx-gap-2 vx-mb-1">
                                            <div class="vx-w-full vx-max-w-xs">
                                                <vx-selectable
                                                    size="lg"
                                                    label="name"
                                                    v-model="role.team"
                                                    placeholder="Team ID"
                                                    :disabled="!props.connected"
                                                    :options="teams.filter((t) => !groupAssignment.roles.some((r) => r.team?.id === t.id))"
                                                    :data-test="`group-team-id`"
                                                ></vx-selectable>
                                            </div>
                                            <vx-select v-model="role.role" :options="SSO_TEAM_USER_ROLES"
                                                :data-test="`group-role`" :disabled="!props.connected"></vx-select>
                                            <vx-button type="button" size="lg" color="muted-light" class="vx-w-12"
                                                data-test="remove-group-role"
                                                @click="removeGroupRole(groupAssignment.key, role.key)"
                                                :disabled="!props.connected">
                                                <font-awesome-icon :icon="faTrash"></font-awesome-icon>
                                            </vx-button>
                                        </div>

                                        <div class="vx-flex vx-items-center vx-gap-2 vx-self-end">
                                            <vx-button type="button" size="lg" color="primary" data-test="add-group-role"
                                                @click="addGroupRole(groupAssignment.key)" :disabled="!props.connected">
                                                Add Role<font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                                            </vx-button>
                                            <vx-button type="button" size="lg" color="danger" data-test="remove-group"
                                                @click="removeGroup(groupAssignment.key)" :disabled="!props.connected">
                                                Delete Group<font-awesome-icon :icon="faCircleMinus"></font-awesome-icon>
                                            </vx-button>
                                        </div>

                                    </div>

                                </vx-box>
                            </div>
                            <vx-box class="vx-relative vx-inline-flex vx-p-3 vx-space-x-2">
                                <vx-button type="button" size="lg" color="primary" data-test="add-group" @click="addGroup"
                                    :disabled="!props.connected">
                                    Add Group<font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                                </vx-button>
                            </vx-box>
                        </div>
                    </div>
                </template>

                <div class="vx-flex">
                    <vx-button class="vx-grow" size="lg" :loading="loading" :disabled="!props.connected"
                        @click="updateRoleMapping" data-test="submit-button">
                        Apply
                    </vx-button>
                </div>
            </div>

            <vx-modal :visible="errors.length > 0" @update:visible="errors = []">
                <div class="vx-text-center vx-text-5xl vx-text-rose-500 vx-pb-6">
                    <font-awesome-icon :icon="faCircleExclamation"></font-awesome-icon>
                </div>
                <div class="vx-max-w-md">
                    <div v-for="error in errors" :key="error">{{ error }}</div>
                </div>
            </vx-modal>
        </template>
    </vx-expandable-card>
</template>
<script setup>
import { VxExpandableCard, VxLabel, VxSelect, VxButton, VxModal, VxInput, VxBox, VxSelectable } from '@voxie/frontend-components';
import { ref, reactive, onMounted, watch } from 'vue';
import { SSO_TEAM_USER_ROLES, ROLE_MAPPING_MODES, ROLE_MAPPING_ROOT_TEAM, ROLE_MAPPING_ALL_TEAMS, ROLE_MAPPING_GROUP_ASSIGNMENT } from '../../../../../constants/sso';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleExclamation, faCircleMinus, faCirclePlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { singleSignOnClient, organizationsClient } from '../../../../../services';
import { useToasted } from '../../../../../composables/useToasted';
import { HTTP_NOT_FOUND } from '../../../../../support/HttpStatuses';
import incrementingId from '../../../../../utils/incrementingId';
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';
import Swal from 'sweetalert2';

const $toasted = useToasted();

const props = defineProps({
    team: {
        type: Object,
        required: true,
    },
    connected: {
        type: Boolean,
        default: false,
    },
});

const loading = ref(false);
const errors = ref([]);

const makeEmptyGroupRole = () => {
    return {
        key: `${incrementingId()}`,
        team: null,
        role: SSO_TEAM_USER_ROLES.find((vr) => !!vr.value)?.value,
    }
}

const makeEmptyGroup = () => {
    return {
        key: `${incrementingId()}`,
        groupId: null,
        roles: [makeEmptyGroupRole()],
    }
}

const defaultForm = () => {
    return {
        mode: ROLE_MAPPING_MODES.find((rm) => !!rm.value)?.value,
        role: SSO_TEAM_USER_ROLES.find((vr) => !!vr.value)?.value,
        groups_attribute: undefined,
        group_assignments: undefined
    }
}

const form = reactive(defaultForm());

watch(() => form.mode, (newMode) => {
    if (newMode === ROLE_MAPPING_GROUP_ASSIGNMENT) {
        form.groups_attribute = form.groups_attribute || null;
        form.group_assignments = form.group_assignments || [makeEmptyGroup()];
    } else {
        form.role = form.role || SSO_TEAM_USER_ROLES.find((vr) => !!vr.value)?.value;
    }
});

watch(() => props.connected, (connected) => {
    if (!connected) {
        Object.assign(form, defaultForm());
    }
});

const addGroup = () => {
    form.group_assignments?.push(makeEmptyGroup());
}

const addGroupRole = (key) => {
    form.group_assignments?.find((g) => g.key === key)?.roles.push(makeEmptyGroupRole());
}

const removeGroup = (key) => {
    form.group_assignments = form.group_assignments?.filter((g) => g.key !== key);

    if (form.group_assignments.length < 1) {
        form.group_assignments = [makeEmptyGroup()];
    }
}

const removeGroupRole = (groupKey, roleKey) => {
    form.group_assignments = form.group_assignments?.map((g) => {
        if (g.key !== groupKey) {
            return g;
        }

        g.roles = g.roles.filter((r) => r.key !== roleKey);

        if (g.roles.length < 1) {
            g.roles = [makeEmptyGroupRole()];
        }

        return g;
    });
}

/**
 * This function transform a server response to an array of objects for using it in the UI.
 * Additionaly the function checks if there are teams exists for a brand
 * and if team does not exist we add dummy team to don't break UI.
 */
const transformGroupAssignmentsServerResponse = (groupAssignments) => {
    return Object.entries(groupAssignments).map(([groupId, roleMapping]) => ({
        groupId,
        key: `group-id-${incrementingId()}`,
        roles: Object.entries(roleMapping).map(([teamId, role]) => {
            teamId = Number(teamId); // Object.entries returns the key as string, so `t.id === teamId` won't be true.

            const team = teams.value.find((t) => t.id === teamId);
            const teamObj = {
                id: teamId,
                name: `Team ${teamId}`
            }

            if (team?.id && team?.name) {
                teamObj.id = team.id
                teamObj.name = team.name
            } else {
                teams.value.push(teamObj);
            }

            return ({ team: teamObj, role, key: `group-role-${incrementingId()}`, })
        })
    }))
}

/**
 * This function prepares payload for the server.
 */
const mapGroupAssignmentsServerPayload = (groupAssignments) => {
    return groupAssignments.reduce((result, item) => {
        const { groupId, roles } = item;
        const roleMapping = {};

        roles.forEach((roleObj) => {
            const { team, role } = roleObj;
            roleMapping[team.id] = role;
        });

        result[groupId] = roleMapping;
        return result;
    }, {});
}

const validate = () => {
    const messages = [];

    if (!form.mode) {
        messages.push('Mode field is required!');
    }

    if ([ROLE_MAPPING_ROOT_TEAM, ROLE_MAPPING_ALL_TEAMS].includes(form.mode)) {
        if (!form.role) {
            messages.push('Role field is required!');
        }
    } else if ([ROLE_MAPPING_GROUP_ASSIGNMENT].includes(form.mode)) {
        if (!form.groups_attribute?.startsWith('http://') && !form.groups_attribute?.startsWith('https://')) {
            messages.push('Groups Attribute field should be a valid url!');
        }

        if (!form.group_assignments || form.group_assignments.length < 1) {
            messages.push('Group Assignments should contain at least a one Group.');
        } else if (form.group_assignments.length > 1000) {
            messages.push('Group Assignments should not contain more than 1000 groups.');
        } else {
            let count = 0;
            form.group_assignments.forEach((ga) => {
                if (!ga?.groupId?.trim()?.length) {
                    messages.push('Group ID field is required!');
                } else if (ga.groupId.trim().length > 80) {
                    messages.push('Group ID field must not be greater than 80 characters.');
                }

                if (!ga?.roles?.length) {
                    messages.push('Group should contain at least a one Role.');
                } else if (ga.roles.length > 1000) {
                    messages.push('Group should not contain more than 1000 team-role pairings.');
                } else {
                    ga.roles.forEach((gar) => {
                        if (!gar?.team?.id) {
                            messages.push('Team ID field is required');
                        } else if (!gar?.role?.trim()?.length) {
                            messages.push('Role field is required');
                        }

                        count++;
                    })
                }
            });

            if (count > 25000) {
                messages.push('Group Assignments should not be greater than 25K groups and team-role pairings in total.');
            }
        }
    }

    return messages;
}

const teams = ref([]);
const getTeams = async () => {
    if (loading.value) {
        return;
    }

    loading.value = true;

    try {
        teams.value = (await organizationsClient.getTeams(props.team.id))?.data?.data || [];
    } catch {
        $toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
}

const getRoleMapping = async () => {
    if (loading.value) {
        return;
    }

    loading.value = true;

    try {
        const response = await singleSignOnClient.getRoleMapping(props.team.id);

        form.mode = response.data.mode;
        form.role = response.data?.role;
        form.groups_attribute = response.data?.groups_attribute;
        form.group_assignments = response.data?.group_assignments && transformGroupAssignmentsServerResponse(response.data.group_assignments) || undefined;
    } catch (e) {
        if (e?.response?.status !== HTTP_NOT_FOUND) {
            $toasted.global.platform_error();
        }
    } finally {
        loading.value = false;
    }
}

const updateRoleMapping = async () => {
    if (loading.value) {
        return;
    }

    loading.value = true;

    try {
        if (form.mode === ROLE_MAPPING_GROUP_ASSIGNMENT) {
            form.role = undefined;
        } else {
            form.groups_attribute = undefined;
            form.group_assignments = undefined;
        }

        errors.value = validate();

        if (errors.value.length) {
            loading.value = false;
            return;
        }

        await singleSignOnClient.updateRoleMapping(props.team.id, {
            ...form,
            groups_attribute: form.groups_attribute,
            group_assignments: form?.group_assignments && mapGroupAssignmentsServerPayload(form.group_assignments) || undefined
        });

        Swal.fire({
            title: 'Success!',
            text: 'SAML2 Role Mapping has successfully been applied',
            icon: 'success'
        });
    } catch (e) {
        const serverErrors = e?.response?.data?.errors;
        if (serverErrors) {
            errors.value = Object.values(serverErrors).flat();
        }

        $toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
};

onMounted(async () => {
    await getTeams();
    await getRoleMapping();
});
</script>
