<template>
    <div class="row">
        <div class="col-md-10 offset-md-1 u-mt-small">
            <nav class="c-pagination u-justify-center">
                <button ref="firstBtn" data-test="first-button" class="d-flex align-items-center c-pagination__control u-text-dark" @click="loadUrl(firstPage)" :disabled="!firstPage || !prevPage || isPageLinkDisabled(firstPage)">
                    <i v-if="pending(firstPage)" class="fa fa-circle-o-notch fa-spin"></i>
                    <span v-else class="u-text-large mr-1">&lsaquo;</span>
                    First
                </button>

                <button ref="prevBtn" data-test="prev-button" class="d-flex align-items-center c-pagination__control u-text-dark" @click="loadUrl(prevPage)" :disabled="!prevPage || isPageLinkDisabled(prevPage) || isPageLinkDisabled(firstPage)">
                    <i v-if="pending(prevPage)" class="fa fa-circle-o-notch fa-spin"></i>
                    <span v-else class="u-text-large mr-1">&lsaquo;</span>
                    Prev
                </button>

                <button ref="nextBtn" data-test="next-button" class="d-flex align-items-center c-pagination__control u-text-dark" @click="loadUrl(nextPage)" :disabled="!nextPage || isPageLinkDisabled(nextPage) || isPageLinkDisabled(lastPage)">
                    Next
                    <i v-if="pending(nextPage)" class="fa fa-circle-o-notch fa-spin"></i>
                    <span v-else class="u-text-large ml-1">&rsaquo;</span>
                </button>

                <button ref="lastBtn" data-test="last-button" class="d-flex align-items-center c-pagination__control u-text-dark" @click="loadUrl(lastPage)" :disabled="!lastPage || !nextPage || isPageLinkDisabled(lastPage)">
                    Last
                    <i v-if="pending(lastPage)" class="fa fa-circle-o-notch fa-spin"></i>
                    <span v-else class="u-text-large ml-1">&rsaquo;</span>
                </button>
            </nav>
        </div>
    </div>
</template>

<script>
import clientRoutes from './../../mixins/client-routes';
export default {
    mixins: [
        clientRoutes
    ],
    props: {
        cursor: String,
        firstPage: String,
        prevPage: String,
        nextPage: String,
        lastPage: String,
        loading: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['update:cursor', 'change'],
    data() {
        return {
            currentUrl: null
        }
    },
    methods: {
        loadUrl(url) {
            this.currentUrl = url;

            const cursor = (new URLSearchParams(
                (new URL(url)).search
            )).get('cursor');

            this.$emit('update:cursor', cursor);
            this.$emit('change', cursor);
        },
        pending(url) {
            return (this.loading && this.currentUrl === url);
        },
        isPageLinkDisabled(pageLink) {
            if (!pageLink) return false;

            const pageLinkCursor = (new URLSearchParams((new URL(pageLink)).search)).get('cursor');

            return this.getCursorFromHash('pages') === pageLinkCursor;
        },
    }
}
</script>
