export const phoneAreaCodes = [
    { internal: 'AL', state: 'Alabama', codes: [205, 251, 256, 334, 659, 938] },
    { internal: 'AK', state: 'Alaska', codes: [907] },
    { internal: 'AZ', state: 'Arizona', codes: [480, 520, 602, 623, 928] },
    { internal: 'AR', state: 'Arkansas', codes: [479, 501, 870] },
    {
        internal: 'CA',
        state: 'California',
        codes: [
            209, 213, 279, 310, 323, 341, 350, 408, 415, 424, 442, 510, 530, 559, 562, 619, 626, 628, 650, 657, 661,
            669, 707, 714, 747, 760, 805, 818, 820, 831, 840, 858, 909, 916, 925, 949, 951,
        ],
    },
    { internal: 'CO', state: 'Colorado', codes: [303, 719, 720, 970, 983] },
    { internal: 'CT', state: 'Connecticut', codes: [203, 475, 860, 959] },
    { internal: 'DE', state: 'Delaware', codes: [302] },
    {
        internal: 'FL',
        state: 'Florida',
        codes: [239, 305, 321, 352, 386, 407, 448, 561, 656, 689, 727, 754, 772, 786, 813, 850, 863, 904, 941, 954],
    },
    { internal: 'GA', state: 'Georgia', codes: [229, 404, 470, 478, 678, 706, 762, 770, 912, 943] },
    { internal: 'HI', state: 'Hawaii', codes: [808] },
    { internal: 'ID', state: 'Idaho', codes: [208, 986] },
    {
        internal: 'IL',
        state: 'Illinois',
        codes: [217, 224, 309, 312, 331, 447, 464, 618, 630, 708, 773, 779, 815, 847, 872],
    },
    { internal: 'IN', state: 'Indiana', codes: [219, 260, 317, 463, 574, 765, 812, 930] },
    { internal: 'IA', state: 'Iowa', codes: [319, 515, 563, 641, 712] },
    { internal: 'KS', state: 'Kansas', codes: [316, 620, 785, 913] },
    { internal: 'KY', state: 'Kentucky', codes: [270, 364, 502, 606, 859] },
    { internal: 'LA', state: 'Louisiana', codes: [225, 318, 337, 504, 985] },
    { internal: 'ME', state: 'Maine', codes: [207] },
    { internal: 'MD', state: 'Maryland', codes: [240, 301, 410, 443, 667] },
    { internal: 'MA', state: 'Massachusetts', codes: [339, 351, 413, 508, 617, 774, 781, 857, 978] },
    { internal: 'MI', state: 'Michigan', codes: [231, 248, 269, 313, 517, 586, 616, 734, 810, 906, 947, 989] },
    { internal: 'MN', state: 'Minnesota', codes: [218, 320, 507, 612, 651, 763, 952] },
    { internal: 'MS', state: 'Mississippi', codes: [228, 601, 662, 769] },
    { internal: 'MO', state: 'Missouri', codes: [314, 417, 557, 573, 636, 660, 816] },
    { internal: 'MT', state: 'Montana', codes: [406] },
    { internal: 'NE', state: 'Nebraska', codes: [308, 402, 531] },
    { internal: 'NV', state: 'Nevada', codes: [702, 725, 775] },
    { internal: 'NH', state: 'New Hampshire', codes: [603] },
    { internal: 'NJ', state: 'New Jersey', codes: [201, 551, 609, 640, 732, 848, 856, 862, 908, 973] },
    { internal: 'NM', state: 'New Mexico', codes: [505, 575] },
    {
        internal: 'NY',
        state: 'New York',
        codes: [212, 315, 332, 347, 363, 516, 518, 585, 607, 631, 646, 680, 716, 718, 838, 845, 914, 917, 929, 934],
    },
    { internal: 'NC', state: 'North Carolina', codes: [252, 336, 472, 704, 743, 828, 910, 919, 980, 984] },
    { internal: 'ND', state: 'North Dakota', codes: [701] },
    { internal: 'OH', state: 'Ohio', codes: [216, 220, 234, 326, 330, 380, 419, 440, 513, 567, 614, 740, 937] },
    { internal: 'OK', state: 'Oklahoma', codes: [405, 539, 572, 580, 918] },
    { internal: 'OR', state: 'Oregon', codes: [458, 503, 541, 971] },
    {
        internal: 'PA',
        state: 'Pennsylvania',
        codes: [215, 223, 267, 272, 412, 445, 484, 570, 582, 610, 717, 724, 814, 835, 878],
    },
    { internal: 'PR', state: 'Rhode Island', codes: [401] },
    { internal: 'SC', state: 'South Carolina', codes: [803, 839, 843, 854, 864] },
    { internal: 'SD', state: 'South Dakota', codes: [605] },
    { internal: 'TN', state: 'Tennessee', codes: [423, 615, 629, 731, 865, 901, 931] },
    {
        internal: 'TX',
        state: 'Texas',
        codes: [
            210, 214, 254, 281, 325, 346, 361, 409, 430, 432, 469, 512, 682, 713, 726, 737, 806, 817, 830, 832, 903,
            915, 936, 940, 945, 956, 972, 979,
        ],
    },
    { internal: 'UT', state: 'Utah', codes: [385, 435, 801] },
    { internal: 'VT', state: 'Vermont', codes: [802] },
    { internal: 'VA', state: 'Virginia', codes: [276, 434, 540, 571, 703, 757, 804, 826, 948] },
    { internal: 'WA', state: 'Washington', codes: [202, 206, 253, 360, 425, 509, 564, 771] },
    { internal: 'WV', state: 'West Virginia', codes: [304, 681] },
    { internal: 'WI', state: 'Wisconsin', codes: [262, 414, 534, 608, 715, 920] },
    { internal: 'WY', state: 'Wyoming', codes: [307] },
];
