export const MATCH_EQUALS_OPERATOR = { value: '==', label: 'Equals' };
export const MATCH_EQUALS_ANY_OPERATOR = { value: 'equals_any', label: 'Equals Any (comma separated)' };
export const MATCH_IS_NULL_OPERATOR = { value: 'is_null', label: 'Is Blank' };
export const MATCH_IS_NOT_NULL_OPERATOR = { value: 'is_not_null', label: 'Is Not Blank' };

export const MATCH_OPERATORS = {
    any: [
        MATCH_EQUALS_OPERATOR,
        MATCH_EQUALS_ANY_OPERATOR,
        { value: '!=', label: 'Does Not Equal' },
        { value: 'does_not_equal_any', label: 'Does Not Equal Any (comma separated)' },
        MATCH_IS_NULL_OPERATOR,
        MATCH_IS_NOT_NULL_OPERATOR,
    ],

    equals: [MATCH_EQUALS_OPERATOR, { value: '!=', label: 'Does Not Equal' }],

    equals_any: [
        MATCH_EQUALS_ANY_OPERATOR,
        { value: 'does_not_equal_any', label: 'Does Not Equal Any (comma separated)' },
    ],

    boolean: [
        { value: 'is_true', label: 'Is True' },
        { value: 'is_false', label: 'Is False' },
    ],

    string: [
        { value: 'contains', label: 'Contains' },
        { value: 'contains_any', label: 'Contains Any (comma separated)' },
        { value: 'does_not_contain', label: 'Does Not Contain' },
        { value: 'does_not_contain_any', label: 'Does Not Contain Any (comma separated)' },
    ],

    numeric: [
        { value: '<', label: 'Is Less Than' },
        { value: '>', label: 'Is Greater Than' },
        { value: '<=', label: 'Is Less Than Or Equal To' },
        { value: '>=', label: 'Is Greater Than Or Equal To' },
    ],

    /**
     * Special operator conditions
     */
    current_time: [{ value: 'in_range', label: 'Is In The Range' }],

    includes: [
        { value: 'includes', label: 'Includes' },
        { value: 'does_not_include', label: 'Does Not Include' },
    ],

    shopify: [MATCH_EQUALS_OPERATOR, { value: '>', label: 'Is Greater Than' }, { value: '<', label: 'Is Less Than' }],

    contact_entrypoint: [
        MATCH_EQUALS_OPERATOR,
        { value: '!=', label: 'Does Not Equal' },
        MATCH_IS_NULL_OPERATOR,
        MATCH_IS_NOT_NULL_OPERATOR,
    ],

    contact_phone_state: [
        MATCH_EQUALS_OPERATOR,
        { value: '!=', label: 'Does Not Equal' },
        MATCH_IS_NULL_OPERATOR,
        MATCH_IS_NOT_NULL_OPERATOR,
    ],

    text_data_type: [
        MATCH_EQUALS_OPERATOR,
        { value: '!=', label: 'Does Not Equal' },
        MATCH_IS_NULL_OPERATOR,
        MATCH_IS_NOT_NULL_OPERATOR,
        { value: 'contains', label: 'Contains' },
        { value: 'does_not_contain', label: 'Does Not Contain' },
    ],

    numeric_data_type: [
        MATCH_EQUALS_OPERATOR,
        { value: '!=', label: 'Does Not Equal' },
        MATCH_IS_NULL_OPERATOR,
        MATCH_IS_NOT_NULL_OPERATOR,
        { value: '<', label: 'Is Less Than' },
        { value: '<=', label: 'Is Less Than Or Equal To' },
        { value: '>', label: 'Is Greater Than' },
        { value: '>=', label: 'Is Greater Than Or Equal To' },
    ],
};
