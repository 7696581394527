<template>
    <vx-table-row>
        <vx-table-row-title>
            <div class="vx-line-clamp-none vx-break-all lg:vx-line-clamp-3">
                {{ props.modelValue.title }}
            </div>
        </vx-table-row-title>

        <vx-table-row-item class="xl:!vx-col-span-2">
            <template v-slot:label> Body: </template>
            <div class="vx-line-clamp-none vx-break-all lg:vx-line-clamp-5 lg:vx-w-[480px]">
                {{ props.modelValue.body }}
            </div>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label v-if="props.modelValue.media_url"> Attachment: </template>
            <font-awesome-icon v-if="props.modelValue.media_url" fixed-width class="vx-text-slate-500 vx-text-sm" :icon="getIcon">
            </font-awesome-icon>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Updated: </template>

            <vx-popover hover
                :arrow="true"
                placement="top"
                :key="updatedAtKey">
                {{ fromNow(props.modelValue.updated_at) }}
                <template v-slot:content>
                    {{ shortDateTime(props.modelValue.updated_at) }}
                </template>
            </vx-popover>
        </vx-table-row-item>

        <vx-table-row-buttons>
            <vx-dropdown placement="right">
                <vx-button data-test="rule-dropdown"
                    type="button"
                    color="muted"
                    outline
                    class="vx-w-[45px]">
                    <font-awesome-icon :icon="faEllipsis" />
                </vx-button>

                <template v-slot:items>
                    <vx-dropdown-item data-test="delete"
                        @click="deleteSnippet()">
                        Delete
                        <font-awesome-icon :icon="faTrash"></font-awesome-icon>
                    </vx-dropdown-item>
                </template>
            </vx-dropdown>

            <vx-button @click.prevent="emit('edit', props.modelValue)"
                :data-test="`edit-btn-${props.modelValue.id}`"
                color="muted"
                outline>
                Edit <font-awesome-icon :icon="faFilePen" />
            </vx-button>
        </vx-table-row-buttons>

    </vx-table-row>
</template>

<script setup>
import {
    faEllipsis,
    faFilePen,
    faFilePdf,
    faTrash,
    faGif,
    faImage,
    faFileCsv,
    faVideo,
    faAddressCard,
    faWaveformLines,
    faFile,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxButton,
    VxDropdown,
    VxDropdownItem,
    VxPopover,
    VxTableRow,
    VxTableRowButtons,
    VxTableRowItem,
    VxTableRowTitle,
} from "@voxie/frontend-components";
import { useInterval } from '@vueuse/core';
import { computed } from "vue";
import { useToasted } from '~/composables/useToasted';
import snippetsClient from '~/services/snippetsClient';
import { HTTP_INTERNAL_SERVER_ERROR } from "~/support/HttpStatuses";
import { fromNow, shortDateTime } from '~/utils/date';

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['deleted', 'edit']);

const deleteSnippet = async () => {
    if (!window.confirm('Are you sure? There is no undo.')) return;

    try {
        await snippetsClient.deleteSnippet(teamId, props.modelValue.id);
        emit('deleted', props.modelValue.id);
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
        } else {
            toasted.error(e.response?.data?.message || 'Something went wrong.');
        }
    }
};

const updatedAtKey = useInterval(60000);

const getIcon = computed(() => {
    const media = props.modelValue.media_url?.toLowerCase();

    if (!media) return;

    const mapping = [
        { pattern: /\.(png|jpg|jpeg)$/, icon: faImage },
        { pattern: /\.(gif)$/, icon: faGif },
        { pattern: /\.(mp4|webm)$/, icon: faVideo },
        { pattern: /\.(pdf)$/, icon: faFilePdf },
        { pattern: /\.(csv)$/, icon: faFileCsv },
        { pattern: /\.(vcard)$/, icon: faAddressCard },
        { pattern: /\.(mp3|mpga)$/, icon: faWaveformLines },
    ];

    let icon = faFile;

    for (const value of mapping) {
        if (media.match(value.pattern)) {
            icon = value.icon;
            break;
        }
    }

    return icon;
});
</script>
