<template>
    <vx-popover :hover="true" arrow placement="top" class="!vx-block">
        <vx-button v-bind="$attrs" :disabled="props.disabled">
            New Flow <font-awesome-icon :icon="faPlusCircle" />
        </vx-button>
        <template v-if="props.disabled" v-slot:content>
            <slot name="disabled">You have reached a limit.</slot>
        </template>
    </vx-popover>
</template>
<script setup>
import { VxButton, VxPopover } from '@voxie/frontend-components';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

defineOptions({
    inheritAttrs: false
});

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})
</script>
