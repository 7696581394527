<template>
    <vx-expandable-card>
        <template v-slot:header> When </template>
        <template v-slot:headerSubtitle> Tell us when you’d like your campaign to run. </template>

        <template v-slot:content>
            <campaign-step-form-when-duration-type class="vx-mt-4 vx-mb-8"
                :modelValue="props.durationType"
                @update:modelValue="emit('update:durationType', $event)"
                :isRepeatable="props.isRepeatable"
                @update:isRepeatable="emit('update:isRepeatable', $event)"></campaign-step-form-when-duration-type>
            <div class="">
                <div class="vx-text-sm vx-text-slate-800 vx-font-extrabold">
                    Start on
                </div>
                <div class="vx-text-sm vx-text-slate-500">
                    <span v-if="!props.disabled">
                        Times shown in your preferred time-zone ({{ timezone }}).
                    </span>
                </div>
                <div v-if="props.disabled"
                    class="vx-pt-6 vx-text-slate-400 vx-text-center">
                    <campaign-card-dates :modelValue="{
                        status: props.campaignStatus,
                        started_at: props.campaignStartedAt,
                        duration_type: props.durationType,
                        live_at: props.originalLiveAt,
                        complete_at: props.completeAt,
                        time_zone_aware: props.timeZoneAware,
                    }"></campaign-card-dates>
                </div>
                <div v-else class="vx-flex vx-flex-col vx-items-center vx-justify-center vx-mt-6">
                    <vx-date-picker v-if="props.durationType === 'predefined'"
                        attached
                        isRequired
                        v-model.range="dateRange"
                        @update:modelValue="updateRange()"
                        mode="datetime"
                        :timezone="getClientTz()"
                        :minDate="dayjs().tz(getClientTz()).add(4, 'minutes').toDate()"></vx-date-picker>
                    <vx-date-picker v-else
                        attached
                        setsToNow
                        isRequired
                        v-model="dateRange.start"
                        @update:modelValue="updateDate()"
                        mode="datetime"
                        :timezone="getClientTz()"
                        :minDate="dayjs().tz(getClientTz()).add(4, 'minutes').toDate()"></vx-date-picker>
                    <div v-if="displayFirstError(validation.$errors)"
                        class="vx-text-xs vx-text-rose-800 vx-mt-2">{{ displayFirstError(validation.$errors) }}</div>

                    <campaign-step-form-when-time-zone-aware
                        v-if="FEATURES.time_zone_aware_campaigns"
                        :disabled="props.disabled"
                        :liveAt="dateRange.start"
                        :timeZoneAware="props.timeZoneAware"
                        @update:timeZoneAware="emit('update:timeZoneAware', $event)">
                    </campaign-step-form-when-time-zone-aware>
                </div>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxExpandableCard, VxDatePicker } from "@voxie/frontend-components";
import { onMounted, ref, watch, inject } from "vue";
import { formatTz, getClientTz } from "~/utils/date";
import dayjs from '~/utils/dayjs'
import CampaignCardDates from '~/components/campaigns/campaign-card/CampaignCardDates.vue';
import useVuelidate from "@vuelidate/core";
import { requiredIf, helpers } from '@vuelidate/validators';
import { isAfterDate, displayFirstError } from "~/utils/validation";
import CampaignStepFormWhenTimeZoneAware from "./CampaignStepFormWhenTimeZoneAware.vue";
import CampaignStepFormWhenDurationType from "./CampaignStepFormWhenDurationType.vue";

const FEATURES = inject('FEATURES');
const props = defineProps({
    campaignStatus: {
        type: String,
    },
    campaignStartedAt: {
        type: String,
    },
    durationType: {
        type: String,
        required: true,
    },
    liveAt: {
        type: Date,
        required: true,
    },
    originalLiveAt: {
        type: String,
        required: true,
    },
    completeAt: {
        type: Date,
        required: false,
    },
    disabled: {
        type: Boolean,
    },
    timeZoneAware: {
        type: Boolean,
        default: false,
    },
    isRepeatable: {
        type: Boolean,
    },
});

const emit = defineEmits(['update:durationType', 'update:liveAt', 'update:completeAt', 'update:timeZoneAware', 'update:isRepeatable', 'update:repeatDelay']);

const timezone = formatTz(dayjs().tz(getClientTz()).format('z'));

const dateRange = ref({
    start: null,
    end: null,
});

const validation = useVuelidate({
    start: props.disabled ? {} : {
        isBefore5Hours: isAfterDate({
            message: 'Start date is not in the past 5 hours',
            date: () => dayjs().subtract('5', 'hours').toDate()
        }),
        $autoDirty: true,
    },
    end: props.disabled ? {} : {
        requiredIf: helpers.withMessage('Date is required', requiredIf(() => props.durationType === 'predefined')),
        isAfterStart: isAfterDate({ message: 'End date must be after start date', date: () => dateRange.value.start, required: false })
    }
}, dateRange);

watch([() => props.liveAt, () => props.completeAt], ([liveAt, completeAt]) => {
    dateRange.value = {
        start: liveAt,
        end: completeAt,
    }
}, {
    immediate: true,
});

watch(() => props.durationType, () => {
    if (props.durationType === 'predefined') {
        emit('update:liveAt', null);
        emit('update:completeAt', null);
    } else {
        emit('update:completeAt', null);
    }
}, {
    immediate: true
})

const updateRange = () => {
    emit('update:liveAt', dateRange.value.start);
    emit('update:completeAt', dateRange.value.end);
}

const updateDate = () => {
    emit('update:liveAt', dateRange.value.start);
    emit('update:completeAt', null);
}

onMounted(() => {
    if (!props.durationType) {
        emit('update:durationType', 'one_time');
    }
});

const validate = async () => {
    // force the reevaluation of the field validation, necessary
    // when the user doesn't change the value manually
    const previousValue = validation.value.start.$model;
    validation.value.start.$model = null;
    validation.value.start.$model = previousValue;
    return validation.value.$validate()
}

defineExpose({
    validate,
})
</script>
