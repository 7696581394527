<template>
    <div>
        <form role="form">
            <div class="form-group row">
                <label class="col-md-4 col-form-label text-md-right">Name</label>
                <div class="col-md-6">
                    <input
                        type="text"
                        class="form-control"
                        name="cloning-target-name"
                        placeholder="Name"
                        data-test="cloning-target-name-input"
                        v-model="cloningTargetName"
                    />
                    <small class="text-danger" v-show="errors.has('cloning_target_name')">{{ errors.get('cloning_target_name') }}</small>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-4 col-form-label text-md-right">Teams</label>
                <div class="col-md-6">
                    <v-select
                        id="teams"
                        :model-value="targetTeams"
                        @update:model-value="targetTeams = $event"
                        placeholder="Choose a team"
                        label="name"
                        data-test="cloning-target-teams-input"
                        :options="teams"
                        multiple
                        :deselect-from-dropdown="true"
                    />
                    <small class="text-danger" v-show="errors.has('teams')">{{ errors.get('teams') }}</small>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-4"></div>
                <div class="col-md-6 text-md-right">
                    <button class="btn btn-primary" @click.prevent="createCloningTarget" data-test="create-cloning-target-button">Create</button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import vSelect from 'vue-select';
import MessageBag from '../../../support/MessageBag';
import adminCloningClient from '../../../services/adminCloningClient';
import adminCloningTargetsClient from '../../../services/adminCloningTargetsClient';
import {HTTP_UNPROCESSABLE_ENTITY} from '../../../support/HttpStatuses';
import {escapeHtml} from '../../../utils/html';

export default {
    name: 'cloning-targets-new',

    components: {
        vSelect,
    },

    data() {
        return {
            cloningTargetName: '',
            errors: new MessageBag(),
            teams: [],
            targetTeams: [],
        };
    },

    methods: {
        createCloningTarget() {
            this.clearErrors();

            if(! this.dataIsValid()) {
                return;
            }

            const data = {
                name: this.cloningTargetName,
                teams:  this.targetTeams.map(team => team.id)
            }

            adminCloningTargetsClient.createCloningTarget(data).then(() => {
                this.clearErrors();
                this.cloningTargetName = '';
                this.targetTeams = [];

                this.$emit('cloningTargetCreated');
            }).catch(error => {
                if (error.response.status === HTTP_UNPROCESSABLE_ENTITY) {
                    const responseErrors = error.response.data.errors;
                    const errors = [];

                    Object.keys(responseErrors).forEach((key) => {
                        const keyErrors = responseErrors[key].map((x) => {
                            return `<p>${escapeHtml(x)}</p>`
                        }).join('\n');
                        errors.push(keyErrors);
                    });

                    const errorMessage =
                        '<p class="u-text-danger">We found the following errors:</p><br />' +
                        errors.join("\n<br />");

                    Swal.fire({
                        title: "Oops..",
                        html: errorMessage,
                        icon: "error",
                    });
                } else {
                    console.error(error);
                    console.error(error.response.data);
                    this.$toasted.global.platform_error();
                }
            })
        },

        dataIsValid() {
            if (this.cloningTargetName === '' || this.cloningTargetName === null) {
                this.errors.add('cloning_target_name', 'Name is required.');
            }

            if (!Array.isArray(this.targetTeams)) {
                this.errors.add('teams', 'Invalid teams.');
            }

            if (Array.isArray(this.targetTeams) && this.targetTeams.length <= 0) {
                this.errors.add('teams', 'Teams are required.')
            }

            return this.errors.isEmpty();
        },

        clearErrors() {
            this.errors.keys().forEach((error) => {
                this.errors.clear(error);
            })
        },
    },

    mounted() {
        adminCloningClient.getTeams().then((response) => (this.teams = response.data.data));
    },
}
</script>
