<template>
    <vx-expandable-card id="details">
        <template v-slot:header>Details</template>
        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-6">
                <div data-test="phone-wrapper">
                    <div>
                        <vx-badge v-if="!contact.id"
                            color="rose"
                            size="xs">
                            Required <font-awesome-icon class="vx-text-rose-500"
                                :icon="faAsterisk"></font-awesome-icon>
                        </vx-badge>
                    </div>
                    <vx-label>
                        Phone
                    </vx-label>
                    <vx-input size="lg"
                        v-model="contact.phone"
                        :readonly="contact.id"
                        @blur="formatPhoneNumber()"
                        :error="displayFirstError(validate.phone)"></vx-input>
                </div>

                <div data-test="first-name-wrapper">
                    <vx-label>
                        First Name
                    </vx-label>
                    <vx-input size="lg"
                        v-model="contact.first_name"
                        :error="displayFirstError(validate.first_name)"></vx-input>
                </div>

                <div data-test="last-name-wrapper">
                    <vx-label>
                        Last Name
                    </vx-label>
                    <vx-input size="lg"
                        v-model="contact.last_name"
                        :error="displayFirstError(validate.last_name)"></vx-input>
                </div>

                <div data-test="email-wrapper">
                    <vx-label>
                        Email
                    </vx-label>
                    <vx-input size="lg"
                        v-model="contact.email"
                        :error="displayFirstError(validate.email)"></vx-input>
                </div>

                <div data-test="time-zone-wrapper">
                    <vx-label>
                        Time-zone
                    </vx-label>
                    <vx-selectable size="lg"
                        v-model="contact.time_zone"
                        :options="timeZones"
                        :error="displayFirstError(validate.time_zone)"></vx-selectable>
                </div>
                <div v-if="contact.id" :class="gridClasses">
                    <div :class="gridKeyClasses">
                        Added:
                    </div>
                    <div :class="gridValueClasses">
                        {{ contact.created_at ? shortDateTime(contact.created_at) : '-' }}
                    </div>
                    <div :class="gridKeyClasses">
                        Entry Point:
                    </div>
                    <div :class="gridValueClasses">
                        {{ entryPoint(contact.entry_point) }}
                    </div>
                    <div :class="gridKeyClasses">
                        Sticky Phone Number:
                    </div>
                    <div :class="gridValueClasses">
                        <vx-icon-loading v-if="loadingStickyPhoneNumber"
                            spin></vx-icon-loading>
                        <div v-else>
                            {{ stickyPhoneNumber ? phoneNumberNational(stickyPhoneNumber.phone_number) : '-' }}
                        </div>
                    </div>
                </div>
            </div>

        </template>
    </vx-expandable-card>
</template>

<script setup>
import { faAsterisk } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    VxBadge,
    VxExpandableCard,
    VxIconLoading,
    VxSelectable,
    VxLabel,
    VxInput
} from "@voxie/frontend-components";
import useVuelidate from "@vuelidate/core";
import { email, maxLength, required } from "@vuelidate/validators";
import { entryPoint, phoneNumberNational } from "~/components/filters";
import { shortDateTime } from '~/utils/date';
import timeZones from "~/utils/timeZones";
import { csvInjection, displayFirstError, phoneNumber } from "~/utils/validation";
import { useContactDetails } from '../useContactDetails';
import libphonenumber from 'google-libphonenumber';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const PNF = libphonenumber.PhoneNumberFormat;

const gridClasses = 'vx-grid vx-grid-cols-12 vx-items-center vx-gap-y-2 vx-gap-x-1 vx-text-slate-800 vx-text-sm vx-mb-6';
const gridKeyClasses = 'vx-col-span-3 vx-break-all vx-text-xs vx-text-slate-500';
const gridValueClasses = 'vx-col-span-9 vx-break-all';

const props = defineProps({
    contactId: {
        type: Number,
        required: false,
    }
});

const {
    contact,
    loadingStickyPhoneNumber,
    stickyPhoneNumber,
} = useContactDetails();

const validate = useVuelidate({
    phone: !props.contactId ? {
        required: required,
        phoneNumber: phoneNumber
    } : {},
    first_name: {
        csvInjection: csvInjection,
        maxLength: maxLength(80),
    },
    last_name: {
        csvInjection: csvInjection,
        maxLength: maxLength(80),
    },
    email: {
        csvInjection: csvInjection,
        maxLength: maxLength(191),
        email: email,
    },
    time_zone: {
        maxLength: maxLength(30),
    }
}, contact, {
    $autoDirty: true,
});

const formatPhoneNumber = () => {
    try {
        const phone = phoneUtil.format(
            phoneUtil.parse(contact.value.phone, "US"),
            PNF.E164
        );

        contact.value.phone = phone;
    } catch (error) {
        console.error(error)
    }
}

</script>
