<template>
    <vx-expandable-card>
        <template v-slot:header>
            <div class="vx-flex vx-flex-col vx-gap-2">
                <vx-popover v-if="props.disabled" hover :arrow="true" placement="top" class="cursor-pointer">
                    <vx-badge size="xs" color="amber">
                        Locked
                        <font-awesome-icon :icon="faLockKeyhole"></font-awesome-icon>
                    </vx-badge>
                    <template v-slot:content>
                        <span>The category is managed by and inherited from the flow.</span>
                    </template>
                </vx-popover>
                <span>Category</span>
            </div>
        </template>
        <template v-slot:headerSubtitle>Optionally select the category that best describes this resource.</template>
        <template v-slot:content>
            <vx-selectable
                data-test="category-select"
                placeholder="Search"
                size="lg"
                :options="CATEGORIES"
                v-model="model"
                :clearable="false"
                :reduce="(option) => option.value"
                :disabled="props.disabled"
            >
                <template v-slot:option="{ option }">
                    <font-awesome-icon fixed-width class="vx-px-1.5 vx-mr-2 vx-text-slate-500 vx-text-xs" :icon="option.icon"></font-awesome-icon>
                    {{ option.label }}
                </template>
                <template v-slot:selected-option="{ option }">
                    <font-awesome-icon fixed-width class="vx-px-1.5 vx-mr-2 vx-text-slate-500 vx-text-xs" :icon="option.icon"></font-awesome-icon>
                    {{ option.label }}
                </template>
            </vx-selectable>
            <vx-button
                v-if="model && !props.disabled"
                color="muted"
                size="sm"
                type="button"
                class="vx-mt-2"
                @click.prevent="model = undefined"
            >
                Reset Category
                <font-awesome-icon :icon="faUndo"></font-awesome-icon>
            </vx-button>
        </template>
    </vx-expandable-card>
</template>
<script setup>
import {
    VxExpandableCard,
    VxPopover,
    VxBadge,
    VxSelectable, VxButton,
} from '@voxie/frontend-components';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faLockKeyhole, faUndo } from "@fortawesome/pro-solid-svg-icons";
import { CATEGORIES } from '~/constants/categories';

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
});

const model = defineModel({
    type: String,
    default: '',
})
</script>
