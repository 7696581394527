<template>
    <analytics-stat-card
        :loading="loading"
        :error="Boolean(error)"
        :value="Boolean(error) ? undefined : dataComputed.number">
            <template v-slot:title>
                {{ dataComputed.title }}
            </template>
            <template v-slot:subtitle>
                All Time
            </template>

            <span>{{ dataComputed.label }}</span>

            <template v-slot:footer>
                <vx-button href="/contacts" size="xs" outline color="muted-light">
                    Go to Contacts
                    <font-awesome-icon
                        :icon="faArrowRight"
                    ></font-awesome-icon>
                </vx-button>
            </template>
            <template v-slot:explainHeader>
                <template v-if="props.status === 'opt_in'">
                    Subscribers
                </template>
                <template v-else-if="props.status === 'opt_out'">
                    <!-- to be defined? -->
                </template>
                <template v-else-if="props.status === 'unknown'">
                    Potential Subscribers
                </template>
                <template v-else>
                    Contacts
                </template>
            </template>
            <template v-slot:explainContent>
                <p v-if="props.status === 'opt_in'">
                    <strong>Total Subscribers</strong><br />
                    Current Contacts Count subject to be targeted by Marketing Campaigns, existing in your Voxie instance.
                </p>
                <p v-else-if="props.status === 'opt_out'">
                    <!-- to be defined? -->
                </p>
                <p v-else-if="props.status === 'unknown'">
                    Quantity of Contacts that neither Opted in or out for Marketing Communications.
                </p>
                <p v-else>
                    <strong>Total Contacts</strong><br />
                    Current Contacts Count existing in your Voxie instance.
                </p>
            </template>
        </analytics-stat-card>
</template>
<script setup>
import AnalyticsStatCard from '../../partial/AnalyticsStatCard.vue';
import { VxButton, vxFormatCount } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { analyticsClient } from '~/services';
import { computed, watch } from 'vue';
import { useVersionedRequest } from '~/composables/useVersionedRequest';

const props = defineProps({
    status: {
        type: String,
        default: undefined,
        validator: (type) => ['opt_in', 'opt_out', 'unknown'].includes(type),
    },
});

const titleFor = (status) => {
    switch (status) {
        case 'opt_in':
            return 'Total Subscribers';
        case 'opt_out':
            return 'Total Unsubscribers';
        case 'unknown':
            return 'Potential Subscribers';
        default:
            return 'Total Contacts';
    }
};

const { loading, response, error, fetchData } = useVersionedRequest();

const dataComputed = computed(() => {
    const number = (response.value?.data?.data || [])
        .filter((group) => (props.status ? group.group_id === props.status : group.group_id !== undefined))
        .reduce((acc, curr) => acc + (curr.value || 0), 0);

    return {
        number: number,
        label: vxFormatCount(number),
        title: titleFor(props.status),
    };
});

watch(() => props.status, () => {
    fetchData(analyticsClient.marketingSubscriptionsTotalByStatus(Spark.state.currentTeam.id)).catch((e) => {
        console.error(e);
    });
}, { immediate: true });

</script>
