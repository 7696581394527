<template>
    <page-container>
        <page-header v-if="props.apiDestinationId" class="vx-mb-6 lg:vx-mb-12">
            <template v-slot:subtitle>
                <a href="/settings/integrations#/api-destinations"
                    class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                    <font-awesome-icon class="vx-mr-1" :icon="faArrowLeft"></font-awesome-icon>
                    All API Destinations
                </a>
            </template>

            {{ data.friendly_name }}
        </page-header>
        <div class="vx-grid vx-grid-cols-12">
            <api-destination-sidebar :apiDestinationId="props.apiDestinationId"></api-destination-sidebar>
            <div class="vx-col-span-12 lg:vx-col-span-10">
                <h2 class="vx-flex vx-items-center vx-font-extrabold vx-text-xl vx-mb-4">
                    API Deliveries
                    <experimental-pill type="alpha" class="vx-ml-4"></experimental-pill>
                </h2>

                <div v-if="loading && !list.length" class="vx-py-8 vx-flex vx-justify-center">
                    <vx-icon-loading class="vx-w-8 vx-h-8" spin></vx-icon-loading>
                </div>
                <vx-box color="info" v-else-if="!list.length" class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                    No deliveries yet.
                </vx-box>
                <vx-table v-else class="md:vx-grid-cols-5-auto" rowBreakpoint="md">
                    <vx-table-row isHeader>
                        <div>
                            Status
                        </div>
                        <div>
                            Triggered
                        </div>
                        <div>
                            Delivery ID
                        </div>
                        <div>
                            Automation
                        </div>
                    </vx-table-row>

                    <api-delivery-row v-for="(item, index) in list" :item="list[index]" :key="list[index].id" :automationRule="automationRuleForDelivery(list[index])"></api-delivery-row>

                </vx-table>

                <div v-if="fetchParams.cursor" class="vx-mt-8">
                    <vx-button
                        @click="loadData()"
                        color="muted-dark"
                        size="xl"
                        class="vx-grow-0"
                        data-test="load-more-btn"
                        :loading="loading"
                    >
                        Load More
                    </vx-button>
                </div>
            </div>
        </div>
    </page-container>
</template>

<script setup>
import { VxTable, VxTableRow, VxBox, VxButton, VxIconLoading } from '@voxie/frontend-components';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ExperimentalPill from "../../../../general/ExperimentalPill.vue";
import PageHeader from '../../../../general/PageHeader.vue';
import PageContainer from '../../../../general/PageContainer.vue';
import customDeliveriesClient from '../../../../../services/customDeliveriesClient';
import { useApiDestinationForm } from "../form/useApiDestinationForm";
import ApiDestinationSidebar from "../ApiDestinationSidebar.vue";
import { onMounted, ref } from 'vue';
import ApiDeliveryRow from './ApiDeliveryRow.vue';
import { useToasted } from '../../../../../composables/useToasted';
import automationRulesClient from '../../../../../services/automationRulesClient';
import { useCursorPagination } from '../../../../../composables/useCursorPagination';

const toasted = useToasted();

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    apiDestinationId: {
        type: String,
        required: false,
        default: undefined,
    }
});

const { data } = useApiDestinationForm();
const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const defaultFilters = () => ({
    limit: 15,
    cursor: null,
    forward: false,
});

const automationRules = ref([]);
const loadingAutomationRules = ref(false);

const automationRuleForDelivery = (delivery) => {
    // undefined = loading
    // null = not found

    const automatedActionId = delivery.delivery_source?.replace('automated_action:', '');
    if (!automatedActionId) {
        return null;
    }

    const automationRule = automationRules.value.find((automationRule) =>
        automationRule?.automated_actions?.find((automatedAction) => Number(automatedAction.id) === Number(automatedActionId))
    );

    if (!automationRule) {
        return loadingAutomationRules.value ? undefined : null;
    }

    return automationRule;
}

/**
 * @param {number[]} automatedActionIds
 */
const fetchAutomationRules = async (automatedActionIds) => {
    try {
        loadingAutomationRules.value = true;
        const response = await automationRulesClient.search(teamId, {
            automated_action_ids: automatedActionIds,
        })

        automationRules.value = [...automationRules.value, ...response.data.data]

    } catch (error) {
        console.error(error);
        toasted.global.platform_error();
    } finally {
        loadingAutomationRules.value = false;
    }
}

const loadData = async() => {
    const currentList = list.value;

    await fetchRecords(customDeliveriesClient.list(
        teamId,
        'api_destination',
        props.apiDestinationId,
        { ...fetchParams.value },
    ));

    const newList = list.value.filter((item) => {
        return !currentList.some((listItem) => listItem.id === item.id);
    });

    const automatedActionIds = newList
        .filter(delivery => delivery.delivery_source?.startsWith('automated_action:'))
        .map((delivery) => Number(delivery.delivery_source.replace('automated_action:', '')));

    await fetchAutomationRules(automatedActionIds);
};

onMounted(() => {
    data.value.id = props.apiDestinationId;
    fetchParams.value = defaultFilters();
    loadData()
})
</script>
