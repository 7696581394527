<template>
    <div class="card card-default" v-if="user">
        <div class="card-header">Profile Details</div>

        <div class="card-body">
            <!-- Success Message -->
            <div class="alert alert-success" v-if="form.successful">
                Your profile has been updated!
            </div>

            <form role="form">
                <!-- Name -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">
                        Time Zone
                    </label>

                    <div class="col-md-6">
                        <select
                            class="form-control"
                            name="time_zone"
                            v-model="form.time_zone"
                        >
                            <option value=""></option>
                            <option
                                v-for="zone in timeZonesList"
                                :key="zone"
                                :value="zone"
                            >
                                {{ formatZone(zone) }}
                            </option>
                        </select>

                        <span
                            class="invalid-feedback"
                            v-show="form.errors.has('name')"
                        >
                            {{ form.errors.get("time_zone") }}
                        </span>
                    </div>
                </div>

                <!-- Update Button -->
                <div class="form-group row mb-0">
                    <div class="col-md-6 offset-md-4">
                        <button
                            type="submit"
                            class="btn btn-primary"
                            @click.prevent="update"
                            :disabled="form.busy"
                        >
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import timeZones from '~/utils/timeZones';

export default {
    props: ["user"],

    data() {
        return {
            form: new SparkForm({
                time_zone: "",
            }),
            timeZonesList: timeZones,
        };
    },

    mounted() {
        this.form.time_zone = this.user.time_zone;

        Bus.$on(`sparkFormUpdated.${this.form.sparkFormId}`, this.$forceUpdate)
    },

    methods: {
        formatZone(zone) {
            return zone.split("_").join(" ");
        },
        update() {
            Spark.put("/settings/profile/details", this.form).then(
                () => {
                    Bus.$emit("updateUser");
                }
            );
        },
    },
};
</script>
