<template>
    <component class="vx-cursor-pointer" :is="props.href ? 'a' : 'span'" :href="props.href">
        <svg class="vx-transform vx-translate-y-[3px]" width="35" height="38" viewBox="0 0 35 37" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.9339 0.0102539H7.6467C6.64244 0.0102539 5.64802 0.208079 4.72023 0.592431C3.79243 0.976783 2.94943 1.54013 2.23938 2.25031C1.52932 2.96049 0.966115 3.80358 0.581921 4.73144C0.197727 5.6593 7.27602e-05 6.65376 0.000244252 7.65801V24.2815C0.000244252 26.3094 0.80585 28.2544 2.23984 29.6883C3.67383 31.1223 5.61873 31.9279 7.6467 31.9279V36.9211L14.1611 31.9279H26.93C28.9579 31.9279 30.9028 31.1223 32.3368 29.6883C33.7708 28.2544 34.5764 26.3094 34.5764 24.2815V7.65801C34.5768 5.6305 33.7719 3.68585 32.3387 2.2517C30.9055 0.817537 28.9614 0.0112926 26.9339 0.0102539Z"
                fill="#14ABE0" />
            <path
                d="M12.9993 9.99815C12.6426 9.3807 12.0554 8.93015 11.3666 8.74553C10.6778 8.56091 9.94396 8.65734 9.32627 9.01362L8.95152 9.23429C8.33429 9.5912 7.88401 10.1786 7.69964 10.8673C7.51528 11.5561 7.6119 12.2898 7.96829 12.9074L13.4054 22.3257C13.8664 23.1239 14.6256 23.7062 15.5159 23.9447C16.4062 24.1832 17.3549 24.0583 18.1531 23.5975L20.1822 22.4223L12.9993 9.99815Z"
                fill="white" />
            <path
                d="M25.6091 9.25113L25.2265 9.02915C24.609 8.67294 23.8753 8.57655 23.1867 8.76117C22.4982 8.9458 21.9112 9.39633 21.5547 10.0137L14.3796 22.4418L16.4088 23.617C16.8039 23.8451 17.2402 23.9932 17.6925 24.0527C18.1449 24.1123 18.6046 24.0822 19.0454 23.9641C19.4861 23.846 19.8993 23.6422 20.2613 23.3645C20.6233 23.0867 20.927 22.7403 21.1552 22.3452L26.5936 12.9268C26.7706 12.6209 26.8855 12.2831 26.9318 11.9327C26.9781 11.5823 26.9549 11.2262 26.8635 10.8848C26.772 10.5434 26.6142 10.2234 26.3989 9.94309C26.1837 9.66277 25.9153 9.42763 25.6091 9.25113Z"
                fill="url(#paint0_linear_910_59887)" />
            <path
                d="M23.9668 8.71558C23.3882 8.71635 22.8229 8.88854 22.3421 9.2104C21.8613 9.53226 21.4867 9.98936 21.2656 10.524C21.0444 11.0586 20.9866 11.6468 21.0995 12.2142C21.2123 12.7816 21.4908 13.3029 21.8997 13.7122C22.3087 14.1215 22.8297 14.4004 23.397 14.5138C23.9644 14.6272 24.5526 14.5699 25.0874 14.3492C25.6222 14.1285 26.0796 13.7543 26.4019 13.2738C26.7242 12.7933 26.8969 12.2281 26.8982 11.6496C26.899 11.2643 26.8238 10.8826 26.6768 10.5264C26.5299 10.1702 26.314 9.84652 26.0417 9.57394C25.7693 9.30135 25.4458 9.08521 25.0898 8.93792C24.7337 8.79062 24.3521 8.71506 23.9668 8.71558Z"
                fill="white" />
            <defs>
                <linearGradient id="paint0_linear_910_59887" x1="18.4614" y1="21.7811" x2="23.6818" y2="11.232"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="0.28" stop-color="white" stop-opacity="0.05" />
                    <stop offset="0.58" stop-color="white" stop-opacity="0.08" />
                    <stop offset="0.62" stop-color="white" stop-opacity="0.1" />
                    <stop offset="0.68" stop-color="white" stop-opacity="0.16" />
                    <stop offset="0.74" stop-color="white" stop-opacity="0.26" />
                    <stop offset="0.8" stop-color="white" stop-opacity="0.39" />
                    <stop offset="0.87" stop-color="white" stop-opacity="0.57" />
                    <stop offset="0.94" stop-color="white" stop-opacity="0.78" />
                    <stop offset="1" stop-color="white" />
                </linearGradient>
            </defs>
        </svg>
    </component>
</template>
<script setup>
const props = defineProps({
    href: {
        type: String,
        required: false,
    }
});
</script>
