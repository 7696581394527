<template>
  <div class="vx-@container/card vx-flex">
    <div
      class="vx-flex vx-grow vx-flex-col vx-items-stretch vx-rounded-2xl vx-shadow"
      :class="{
        '@xl/card:vx-shadow-xl @xl/card:vx-flex-row': !$slots.footer,
      }"
      >
      <div
        class="vx-flex vx-items-center vx-justify-between vx-rounded-t-2xl vx-border-solid vx-border vx-border-white  vx-transition"
        :class="{
          'vx-border-sky-50 vx-bg-sky-100': props.color === 'sky',
          'vx-border-white vx-bg-slate-50': props.color === 'slate',
          'vx-pl-6': $slots.toggle,
          'vx-opacity-50': props.disabled,
          '@xl/card:vx-flex-col @xl/card:vx-justify-center @xl/card:vx-rounded-r-none @xl/card:vx-rounded-l-2xl @xl/card:vx-pl-0': !$slots.footer,
        }
          "
        aria-hidden="true">
        <div
          class="vx-flex vx-items-center vx-gap-5 @sm/card:vx-gap-7"
          :class="
            {
              'vx-w-full vx-justify-evenly @xl/card:vx-justify-center': !$slots.footer && !$slots.toggle || $slots.footer && !$slots.toggle,
              'vx-justify-center': !$slots.footer && $slots.toggle,
              '@xl/card:vx-h-full @xl/card:vx-flex-col @xl/card:vx-gap-[12%]': !$slots.footer
            }
          "
          >
          <div v-for="index in 5"
            :key="index"
            class="-vx-translate-y-1/3 vx-flex-col vx-items-center -vx-space-y-1.5 "
            :class="[
              index === 5 ? 'vx-hidden @xl/card:vx-flex' : 'vx-flex',
              {
                '@xl/card:vx-translate-y-0 @xl/card:-vx-translate-x-1/3 @xl/card:vx-flex-row @xl/card:vx-space-y-0 @xl/card:-vx-space-x-1.5': !$slots.footer
              }
            ]">
            <div
              class="vx-relative vx-h-8 vx-w-[4px] vx-rounded-full vx-bg-gradient-to-b vx-from-slate-50 vx-to-slate-600 vx-shadow-[0_2px_5px_#00000022] "
              :class="{
                '@xl/card:vx-h-[5px] @xl/card:vx-w-8': !$slots.footer
              }">
            </div>
            <div class="vx-aspect-square vx-w-2.5 vx-rounded-full vx-bg-slate-500 vx-shadow-[0_1px_2px_#000000bb_inset]">
            </div>
          </div>
        </div>

        <div v-if="$slots.toggle"
          class="vx-flex vx-flex-grow vx-cursor-pointer vx-items-center vx-justify-end vx-gap-2 vx-py-3 vx-px-4 vx-text-sm"
          :class="{
            '@xl/card:vx-hidden': !$slots.footer
          }"
          >
          <slot name="toggle"></slot>
        </div>
      </div>
      <div aria-hidden="true"
        class="vx-h-px vx-bg-slate-300"
        :class="{
            'vx-opacity-50': props.disabled,
            '@xl/card:vx-h-auto @xl/card:vx-w-px': !$slots.footer
        }"></div>
      <div
        class="bg-dots vx-relative vx-flex vx-flex-grow vx-grid-cols-3 vx-flex-col vx-items-stretch vx-overflow-hidden vx-rounded-b-2xl vx-border vx-border-solid vx-border-white vx-bg-slate-100"
        :class="{
          '@xl/card:vx-grid @xl/card:vx-rounded-r-2xl @xl/card:vx-rounded-l-none': !$slots.footer
        }">
        <div
          class="vx-col-span-1 vx-flex vx-flex-col vx-items-start vx-justify-center vx-border vx-border-solid vx-border-white vx-bg-gradient-to-br vx-from-white/60 vx-to-white/90 vx-py-6 vx-px-6 vx-shadow-lg"
          :class="{
            'vx-opacity-50': props.disabled,
            '@4xl/card:vx-px-12': !$slots.footer
        }"
          >
          <vx-badge class="vx-mb-3"
            size="xs"
            color="slate">
            <slot name="type"></slot>
          </vx-badge>

          <h1
            class="vx-mb-1.5 vx-text-lg vx-text-slate-700 vx-font-extrabold vx-leading-tight"
            :class="{
              '@2xl/card:vx-text-2xl @2xl/card:vx-leading-none': !$slots.footer
            }"
          >
            <slot name="title"></slot>
          </h1>

          <p v-if="props.basedOn"
            class="vx-mb-2 vx-text-xs vx-text-slate-400">
            Based on: {{ props.basedOn }}
          </p>

          <div
            class="vx-text-sm vx-leading-snug vx-text-slate-500"
            :class="{
              '@2xl/card:vx-text-base': !$slots.footer
            }"
          >
            <slot name="description"></slot>
          </div>

          <div v-if="$slots.toggle" class="vx-mt-4 vx-hidden vx-flex-col vx-items-start"
            :class="{
              '@xl/card:vx-flex': !$slots.footer
            }"
          >
            <div class="-vx-ml-3 vx-flex vx-cursor-pointer vx-items-center vx-gap-2 vx-py-3 vx-px-3">
              <slot name="toggle"></slot>
            </div>
          </div>
        </div>
        <div class="vx-col-span-2 vx-flex vx-flex-grow vx-@container"
            :class="{
                'vx-opacity-50': props.disabled
            }">
          <div
            class="vx-flex vx-relative vx-flex-grow vx-flex-col vx-items-center vx-justify-center vx-px-6 vx-py-6"
            :class="{
              '@lg/card:vx-px-14 @xl/card:vx-min-h-[350px] @xl/card:vx-px-12 @xl/card:vx-py-20': !$slots.footer
            }"
            >
            <slot />
          </div>
        </div>
        <div v-if="$slots.footer"
          class="vx-p-4 vx-bg-white vx-backdrop-blur-sm"
          style="box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.08);">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { VxBadge } from '@voxie/frontend-components'

const props = defineProps({
  basedOn: {
    type: String,
  },
  color: {
    type: String,
    default: 'slate',
  },
  disabled: {
    type: Boolean,
    default: false,
  }
})

</script>

<style scoped>
.bg-dots {
  background-image: radial-gradient(rgba(0, 0, 0, 0.08) 1.5px, transparent 0);
  background-size: 24px 24px;
  background-position: 50% 50%;
}
</style>

