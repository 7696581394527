<template>
    <div>

        <div class="vx-mb-8">
            <strong class="vx-block vx-text-slate-900 vx-text-base vx-leading-6 vx-font-semibold vx-mb-2">
                Campaign Name
            </strong>
            <div v-if="!editing" class="vx-flex vx-gap-2">
                {{ props.name }}
                <vx-button @click="editing = true" type="button" size="xs" color="muted" data-test="launch-preview-edit-name">
                    Edit
                </vx-button>
            </div>
            <div v-else class="vx-flex vx-gap-2 [&>div>input]:vx-h-12">
                <vx-input :maxlength="84" v-model="campaignName" @input="emit('update:name', campaignName)"></vx-input>
                <vx-button @click="updateName()" type="button" size="lg" color="primary" data-test="launch-preview-edit-name-save">
                    Save
                    <font-awesome-icon :icon="faCheck"></font-awesome-icon>
                </vx-button>
            </div>
        </div>
        <div>
            <strong class="vx-block vx-text-slate-900 vx-text-base vx-leading-6 vx-font-semibold vx-mb-2">
                Message Preview
            </strong>
            <div
                class="vx-p-4 vx-whitespace-pre-line vx-bg-white vx-shadow vx-text-slate-700 vx-text-sm vx-font-normal vx-rounded-[16px] vx-rounded-bl"
            >
                {{ props.bodyMessage }}
            </div>
            <div
            v-if="props.mediaUrl"
            class="vx-mt-2 vx-p-4 vx-bg-white vx-shadow vx-rounded-[16px] vx-rounded-bl vx-max-w-[208px] vx-max-h-[500px]"
            >
                <img
                    :src="props.mediaUrl"
                    class="vx-w-full vx-block vx-object-cover vx-rounded vx-rounded-b-sm"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
    VxInput,
    VxButton
} from "@voxie/frontend-components";
import { ref } from 'vue';

const emit = defineEmits(['update:name']);

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    mediaUrl: {
        type: String,
        required: false,
    },
    bodyMessage: {
        type: String,
        required: true,
    },
});

const campaignName = ref(props.name);
const editing = ref(false);

const updateName = () => {
    editing.value = false;
    emit('update:name', campaignName.value)
}

</script>
