<template>
    <page-controls>
        <template v-slot:left>
            <div class="vx-grow @3xl/controls:vx-max-w-md">
                <vx-filterable :active="active" @toggle="toggleFilterable" data-test="contact-filters">
                    <template v-slot:placeholder>
                        <span class="vx-mr-2">Find Contacts</span>
                        <vx-badge
                            v-if="filtersCount > 0"
                            :borders="false"
                            size="xs"
                            @click.stop="reset"
                        >
                            {{ filtersCount }} filters
                            <font-awesome-icon :icon="faXmarkCircle" />
                        </vx-badge>
                    </template>
                    <template v-slot:form>
                        <form @submit.prevent="apply">
                            <vx-label class="vx-mb-1">
                                {{ isLargeTeam() ? 'Phone Number' : 'Keyword Search' }}
                            </vx-label>
                            <vx-input
                                autofocus
                                type="text"
                                data-test="filters-query"
                                placeholder="Search"
                                v-model="filters['filter[search]']"
                                maxlength="191"
                                class="vx-mb-4"
                            >
                                <template v-slot:right>
                                    <font-awesome-icon
                                        class="vx-text-xs"
                                        :icon="faMagnifyingGlass"
                                    />
                                </template>
                            </vx-input>

                            <vx-label class="vx-flex vx-flex-col">
                                Marketing Subscription
                                <vx-button
                                    color="muted"
                                    size="xs"
                                    type="button"
                                    @click="
                                        filters['filter[marketing_subscription_status]'] = undefined
                                    "
                                >
                                    Reset Filter
                                    <font-awesome-icon :icon="faUndo"></font-awesome-icon>
                                </vx-button>
                            </vx-label>
                            <vx-selectable
                                placeholder="Status"
                                :options="[
                                    {label: 'Opt-in', value: 'opt_in'},
                                    {label: 'Opt-out', value: 'opt_out'},
                                    {label: 'No preference', value: 'unknown'},
                                ]"
                                v-model="filters['filter[marketing_subscription_status]']"
                                :reduce="(option) => option.value"
                                class="vx-mb-4"
                            >
                            </vx-selectable>

                            <vx-label class="vx-flex vx-flex-col">
                                Tag
                                <vx-button
                                    color="muted"
                                    size="xs"
                                    type="button"
                                    @click="
                                        filters['filter[tags.tag_name]'] = undefined
                                    "
                                >
                                    Reset Filter
                                    <font-awesome-icon :icon="faUndo"></font-awesome-icon>
                                </vx-button>
                            </vx-label>
                            <vx-selectable
                                placeholder="Name"
                                :options="tags"
                                :loading="searchingTags"
                                :taggable="true"
                                @search="searchTags"
                                v-model="filters['filter[tags.tag_name]']"
                                class="vx-mb-4"
                            >
                            </vx-selectable>

                            <div class="vx-flex vx-gap-2">
                                <vx-button
                                    type="button"
                                    color="muted-light"
                                    @click="cancel"
                                >
                                    Cancel
                                </vx-button>
                                <vx-button
                                    data-test="filters-apply"
                                    type="submit"
                                    block
                                    @click="apply"
                                >
                                    Apply
                                    <font-awesome-icon
                                        :icon="faCircleCheck"
                                    ></font-awesome-icon>
                                </vx-button>
                            </div>
                        </form>
                    </template>
                </vx-filterable>
            </div>
            <div class="vx-flex vx-w-full vx-gap-2 @xl/controls:vx-w-auto">
                <vx-button-group size="lg" class="vx-w-full">
                    <vx-select
                        v-model="filters.sort_field"
                        @update:modelValue="apply"
                        :options="[
                            {
                                value: 'id',
                                label: 'Sort by: Created',
                            },
                            {
                                value: 'phone',
                                label: 'Sort by: Phone',
                            },
                        ]"
                    ></vx-select>
                    <vx-button
                        @click.prevent="
                            filters.sort_dir = filters.sort_dir === 'asc' ? 'desc' : 'asc';
                            apply();
                        "
                        type="button"
                        color="white"
                        shadow
                    >
                        <font-awesome-icon
                            :icon="
                                filters.sort_dir === 'asc'
                                    ? faArrowUpWideShort
                                    : faArrowDownWideShort
                            "
                        ></font-awesome-icon>
                    </vx-button>
                </vx-button-group>
            </div>
        </template>
        <template v-slot:right>
            <vx-button
                block
                href="/contacts/new"
                size="lg"
                color="primary"
            >
                New Contact
                <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
            </vx-button>
        </template>
    </page-controls>
</template>

<script setup>
import { computed, ref, watch, nextTick } from 'vue';
import {
    VxBadge,
    VxLabel,
    VxButton,
    VxButtonGroup,
    VxFilterable,
    VxInput,
    VxSelectable,
    VxSelect,
} from "@voxie/frontend-components";
import {
    faCircleCheck,
    faMagnifyingGlass,
    faXmarkCircle,
    faArrowDownWideShort,
    faArrowUpWideShort,
    faCirclePlus,
    faUndo,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import PageControls from '~/components/general/PageControls.vue';
import tagsClient from '~/services/tagsClient';
import debounce from '~/utils/debounce';
import { isLargeTeam } from '~/utils/team';

const teamId = Spark.state.currentTeam.id;

const filters = ref({});
const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});
watch(() => props.modelValue, (modelValue) => {
    filters.value = {...modelValue};
}, {
    deep: true,
    immediate: true,
})

const emit = defineEmits(["update:modelValue", "apply", "reset"]);

const active = ref(false);

const toggleFilterable = (event) => {
    if (!tags.value.length && event) {
        searchTags();
    }
    active.value = event;
};

const filtersCount = computed(
    () =>
        (props.modelValue['filter[marketing_subscription_status]'] ? 1 : 0) +
        (props.modelValue['filter[tags.tag_name]'] ? 1 : 0) +
        (props.modelValue['filter[search]'] ? 1 : 0)
);

const reset = () => {
    active.value = false;
    emit("reset");
};

const cancel = async () => {
    filters.value = { ...props.modelValue }
    active.value = false;
}

const apply = async () => {
    active.value = false;
    emit('update:modelValue', { ...filters.value });
    await nextTick();
    emit('apply');
}

const tags = ref([]);
const searchingTags = ref(false);
const searchTags = debounce((search) => {
    searchingTags.value = true;
    tagsClient.tagsAutocomplete(teamId, {
        query: search
    }).then((response) => {
        tags.value = response.data.data.map(tag => tag.value);
        searchingTags.value = false;
    })
}, 200)
</script>
