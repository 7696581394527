<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        size="lg"
        :padding="false">
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6">
                Remove Resources
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">
                Choose the resources you want to remove from your flow.
            </div>
        </template>

        <!-- v-if visible will toggle the mounted hook -->
        <div class="vx-p-6 lg:vx-px-12 lg:vx-py-10">
            <custom-flow-resource-dependencies v-if="props.visible" ref="dependenciesRef" action="remove" :resources="props.resources"></custom-flow-resource-dependencies>
        </div>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="emit('update:visible', false)"
                    type="button"
                    class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                    size="lg"
                    color="muted">
                    Cancel
                </vx-button>
                <vx-button @click="confirm()"
                    :disabled="dependenciesRef?.loading"
                    :loading="deleting"
                    data-test="confirm-resource-remove"
                    type="button"
                    class="vx-grow"
                    size="lg"
                    color="primary">
                    Confirm
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import { VxButton, VxModal } from '@voxie/frontend-components';
import { ref } from 'vue';
import { useToasted } from '~/composables/useToasted';
import CustomFlowResourceDependencies from './CustomFlowResourceDependencies.vue';
import { useCustomFlow } from './useCustomFlow';
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';

const toasted = useToasted();

const customFlow = useCustomFlow();
const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
    resources: {
        type: Array,
        default: () => ([])
    }
});

const deleting = ref(false);

const emit = defineEmits(['update:visible', 'removed']);

const dependenciesRef = ref();

const confirm = async () => {
    if (!dependenciesRef.value) {
        return;
    }

    if (deleting.value || dependenciesRef.value?.loading) {
        return;
    }

    try {
        deleting.value = true;
        await customFlow.removeResources([...props.resources, ...dependenciesRef.value.list]);
        emit('removed');
        emit('update:visible', false);
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
            return;
        }

        toasted.error(e.response?.data?.message || 'Something went wrong.');
    } finally {
        deleting.value = false;
    }
}

</script>
