import axios from 'axios';
import dayjs from 'dayjs';

axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
};

const showSessionExpiredPopup = () => {
    $('#modal-session-expired').modal('show');
};

const setPageLastReloadedAt = () => {
    localStorage.setItem('page_last_reloaded_at', Date.now());
};

const handlePageReload = () => {
    const now = dayjs();
    const pageLastReloadedAt = localStorage.getItem('page_last_reloaded_at');
    const showPopup = !pageLastReloadedAt ? true : dayjs(Number(pageLastReloadedAt)).diff(now, 'hour') <= 1;

    if (showPopup) {
        showSessionExpiredPopup();
    } else {
        setPageLastReloadedAt();
        window.location.reload();
    }
};

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response === undefined) {
            return Promise.reject(error);
        }

        switch (error.response.status) {
            case 401:
                if (!['get', 'head', 'options'].includes(error.response.config.method)) {
                    showSessionExpiredPopup();
                } else {
                    handlePageReload();
                }
                break;
        }

        return Promise.reject(error);
    }
);

export default axios;
