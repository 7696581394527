<template>
    <vx-table-row>
        <vx-table-row-item>
            <template v-slot:label> ID: </template>
            <div class="vx-break-all">
                <vx-badge class="vx-mr-2 vx-cursor-pointer" @click.prevent="copyId()" size="xs" color="slate">{{ props.modelValue.id }}</vx-badge>
            </div>

        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Name: </template>
            <div class="vx-font-bold vx-text-slate-700 vx-break-all xl:vx-py-2">
                {{ props.modelValue.name }}
            </div>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Audience Type: </template>
            {{ segmentAudienceType }}
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Refresh Type: </template>
            <font-awesome-icon
                fixedWidth
                :icon="modelValue.type === 'static' ? faMapPin : faLoader"
            />
            {{ segmentRefreshType }}
        </vx-table-row-item>
    </vx-table-row>
</template>

<script setup>
import {
    VxBadge,
    VxTableRow,
    VxTableRowItem,
} from "@voxie/frontend-components";
import { computed } from 'vue';
import { audienceTypeLabel } from '../../../../constants/campaign';
import { SegmentTypeToLabel } from '../../../../constants/segment';
import { faLoader, faMapPin } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useToasted } from '../../../../composables/useToasted';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

const toasted = useToasted();

const copyId = () => {
    navigator.clipboard.writeText(props.modelValue.id);
    toasted.success(`${props.modelValue.id} copied to clipboard.`)
};

const segmentAudienceType = computed(() =>
    audienceTypeLabel(props.modelValue.audience_type)
);

const segmentRefreshType = computed(
    () => SegmentTypeToLabel[props.modelValue.type]
);
</script>
