<template>
    <div>
        <div class="spark-screen container">
            <!-- Common Register Form Contents -->
            <register-common
                :teams="teams"
                :coupon="coupon"
                :only-team-plans="onlyTeamPlans"
                :invalid-coupon="invalidCoupon"
                :invitation="invitation"
                :invalid-invitation="invalidInvitation"
                :paid-plans="paidPlans"
                :register-form="registerForm"
                :has-monthly-and-yearly-plans="hasMonthlyAndYearlyPlans"
                :selected-plan="selectedPlan"
                :plans-for-active-interval="plansForActiveInterval"
                :showing-monthly-plans="showingMonthlyPlans"
                @show-monthly="showMonthlyPlans"
                :showing-yearly-plans="showingYearlyPlans"
                @show-yearly="showYearlyPlans"
                @select-plan="selectPlan"
                @register="register"
            />

            <!-- Billing Information -->
            <div class="row justify-content-center" v-if="selectedPlan && selectedPlan.price > 0">
                <div class="col-lg-8">
                    <div class="card card-default">
                        <div class="card-header">Billing Information</div>

                        <div class="card-body">
                            <!-- Generic 500 Level Error Message / Stripe Threw Exception -->
                            <div class="alert alert-danger" v-if="registerForm.errors.has('form')">
                                We had trouble validating your card. It's possible your card provider is preventing us from charging the card. Please contact your card provider or customer support.
                            </div>

                            <form role="form">
                                <!-- Cardholder's Name -->
                                <div class="form-group row">
                                    <label for="name" class="col-md-4 col-form-label text-md-right">Cardholder's Name</label>

                                    <div class="col-md-6">
                                        <input type="text" class="form-control" name="name" v-model="cardForm.name">
                                    </div>
                                </div>

                                <!-- Card Details -->
                                <div class="form-group row">
                                    <label for="name" class="col-md-4 col-form-label text-md-right">Card</label>

                                    <div class="col-md-6">
                                        <div id="card-element"></div>
                                        <span class="invalid-feedback" v-show="cardForm.errors.has('card')">
                                        {{ cardForm.errors.get('card') }}
                                    </span>
                                    </div>
                                </div>

                                <!-- Billing Address Fields -->
                                <register-address
                                    v-if="collectsBillingAddress"
                                    :register-form="registerForm"
                                    :countries-list="countriesList"
                                />

                                <!-- ZIP Code -->
                                <div class="form-group row" v-if="!collectsBillingAddress">
                                    <label class="col-md-4 col-form-label text-md-right">ZIP / Postal Code</label>

                                    <div class="col-md-6">
                                        <input type="text" class="form-control" name="zip" v-model="registerForm.zip" :class="{'is-invalid': registerForm.errors.has('zip')}">

                                        <span class="invalid-feedback" v-show="registerForm.errors.has('zip')">
                                        {{ registerForm.errors.get('zip') }}
                                    </span>
                                    </div>
                                </div>

                                <!-- Coupon Code -->
                                <div class="form-group row" v-if="query.coupon">
                                    <label class="col-md-4 col-form-label text-md-right">Coupon Code</label>

                                    <div class="col-md-6">
                                        <input type="text" class="form-control" name="coupon" v-model="registerForm.coupon" :class="{'is-invalid': registerForm.errors.has('coupon')}">

                                        <span class="invalid-feedback" v-show="registerForm.errors.has('coupon')">
                                        {{ registerForm.errors.get('coupon') }}
                                    </span>
                                    </div>
                                </div>

                                <!-- Terms And Conditions -->
                                <div class="form-group row">
                                    <div class="col-md-6 offset-md-4">
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" v-model="registerForm.terms">
                                                I Accept <a href="/terms" target="_blank">The Terms Of Service</a>
                                            </label>
                                            <span class="invalid-feedback" v-show="registerForm.errors.has('terms')">
                                            <strong>{{ registerForm.errors.get('terms') }}</strong>
                                        </span>
                                        </div>
                                    </div>
                                </div>

                                <!-- Tax / Price Information -->
                                <div class="form-group row" v-if="selectedPlan">
                                    <label class="col-md-4 col-form-label text-md-right">&nbsp;</label>

                                    <div class="col-md-6">
                                        <div class="alert alert-info" style="margin: 0;">
                                            <strong>Tax:</strong> {{ currencyFilter(taxAmount(selectedPlan)) }}
                                            <br><br>
                                            <strong>Total Price Including Tax:</strong>
                                            {{ currencyFilter(priceWithTax(selectedPlan)) }}
                                            {{ selectedPlan.type == 'user' && chargesUsersPerSeat ? '/ '+ seatName : '' }}
                                            {{ selectedPlan.type == 'user' && chargesUsersPerTeam ? '/ '+ teams.team : '' }}
                                            {{ selectedPlan.type == 'team' && chargesTeamsPerSeat ? '/ '+ teamSeatName : '' }}
                                            {{ selectedPlan.type == 'team' && chargesTeamsPerMember ? '/ '+ teams.member : '' }}
                                            / {{ capitalize(selectedPlan.interval) }}
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-md-6 offset-md-4">
                                        <div :class="{'is-invalid': registerForm.errors.has('recaptcha')}">
                                            <google-recaptcha v-model="registerForm.recaptcha" />
                                        </div>
                                        <div class="invalid-feedback" v-show="registerForm.errors.has('recaptcha')">
                                            {{ registerForm.errors.get('recaptcha') }}
                                        </div>
                                    </div>
                                </div>

                                <!-- Register Button -->
                                <div class="form-group row mb-0">
                                    <div class="col-md-6 offset-md-4">
                                        <button type="submit" class="btn btn-primary" @click.prevent="register" :disabled="registerForm.busy">
                                        <span v-if="registerForm.busy">
                                            <i class="fa fa-btn fa-spinner fa-spin"></i> Registering
                                        </span>

                                        <span v-else>
                                            <i class="fa fa-btn fa-check-circle"></i> Register
                                        </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GoogleRecaptcha from '../general/GoogleRecaptcha.vue';
import RegisterCommon from './RegisterCommon.vue';
import RegisterAddress from './RegisterAddress.vue';
import Dinero from 'dinero.js';
import axios from '~/services/axios';

export default {
    components: {
        GoogleRecaptcha,
        RegisterCommon,
        RegisterAddress,
    },

    props: [ 'teams', 'countriesList', 'onlyTeamPlans' ],

    /**
     * The component's data.
     */
    data() {
        return {
            query: null,

            cardElement: null,

            coupon: null,
            invalidCoupon: false,

            // country: null,
            taxRate: 0,

            registerForm: $.extend(true, new SparkForm({
                stripe_payment_method: '',
                plan: '',
                team: '',
                team_slug: '',
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                address: '',
                address_line_2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US',
                terms: false,
                recaptcha: '',
                coupon: null,
                invitation: null
            }), Spark.forms.register),

            cardForm: new SparkForm({
                name: '',
                number: '',
                cvc: '',
                month: '',
                year: '',
            }),

            // register
            plans: [],
            invitation: null,
            invalidInvitation: false,

            // plans
            selectedPlan: null,

            showingMonthlyPlans: true,
            showingYearlyPlans: false,

            // stripe
            stripe: Spark.stripeKey ? Stripe(Spark.stripeKey, {
                apiVersion: Spark.stripeApiVersion
            }) : null
        };
    },

    watch: {
        /**
         * Watch for changes on the entire billing address.
         */
        'currentBillingAddress': function () {
            this.refreshTaxRate(this.registerForm);
        },

        /**
         * Watch the team name for changes.
         */
        'registerForm.team': function (val, oldVal) {
            if (this.registerForm.team_slug === '' ||
                this.registerForm.team_slug === oldVal.toLowerCase().replace(/[\s\W-]+/g, '-')
            ) {
                this.registerForm.team_slug = val.toLowerCase().replace(/[\s\W-]+/g, '-');
            }
        },

        /**
         * Watch for changes on the selected plan.
         */
        selectedPlan(val){
            if (!val || val.price === 0) {
                this.cardElement = null;
                return;
            }

            if (!this.cardElement) {
                this.$nextTick(()=> {
                    this.cardElement = this.createCardElement('#card-element');
                });
            }
        }
    },

    /**
     * The component has been created by Vue.
     */
    created() {
        this.getPlans();

        this.guessCountry();

        this.query = window.URI(document.URL).query(true);

        if (this.query.coupon) {
            this.getCoupon();

            this.registerForm.coupon = this.query.coupon;
        }

        if (this.query.invitation) {
            this.getInvitation();

            this.registerForm.invitation = this.query.invitation;
        }

        Bus.$on(`sparkFormUpdated.${this.registerForm.sparkFormId}`, this.$forceUpdate)
        Bus.$on(`sparkFormUpdated.${this.cardForm.sparkFormId}`, this.$forceUpdate)
    },

    methods: {
        capitalize(value) {
            if (!value && value !== 0) {
                return '';
            }

            return value.toString().charAt(0).toUpperCase() + value.slice(1);
        },
        currencyFilter(value) {
            return Dinero({
                amount: Math.round(value * 100),
                currency: Spark.currency
            }).setLocale(Spark.currencyLocale).toFormat('$0,0.00');
        },
        /**
         * Attempt to guess the user's country.
         */
        guessCountry() {
            axios.get('/geocode/country')
                .then(response => {
                    if (response.data != 'ZZ' && response.data != '') {
                        this.registerForm.country = response.data;
                    }
                })
                .catch (e => {
                    console.error(e);
                });
        },
        /**
         * Get the coupon specified in the query string.
         */
        getCoupon() {
            axios.get('/coupon/' + this.query.coupon)
                .then(response => {
                    this.coupon = response.data;
                })
                .catch(() => {
                    this.invalidCoupon = true;
                });
        },
        /**
         * Attempt to register with the application.
         */
        register() {
            this.cardForm.errors.forget();

            this.registerForm.busy = true;
            this.registerForm.errors.forget();

            if (this.invitation != null) {
                return this.sendRegistration();
            }

            if (!Spark.cardUpFront || this.registerForm.invitation || this.selectedPlan.price === 0) {
                return this.sendRegistration();
            }

            const payload = {
                name: this.cardForm.name,
                address: {
                    line1: this.registerForm.address || '',
                    line2: this.registerForm.address_line_2 || '',
                    city: this.registerForm.city || '',
                    state: this.registerForm.state || '',
                    postal_code: this.registerForm.zip || '',
                    country: this.registerForm.country || '',
                },
            };

            this.generateToken((secret) => {
                this.stripe
                    .handleCardSetup(secret, this.cardElement, {
                        payment_method_data: {
                            billing_details: payload,
                        },
                    })
                    .then((response) => {
                        if (response.error) {
                            this.cardForm.errors.set({ card: [response.error.message] });

                            this.registerForm.busy = false;
                        } else {
                            this.sendRegistration(response.setupIntent.payment_method);
                        }
                    });
            });
        },
        /*
         * After obtaining the Stripe token, send the registration to Spark.
         */
        sendRegistration(paymentMethod) {
            this.registerForm.stripe_payment_method = paymentMethod;

            Spark.post('/register', this.registerForm)
                .then(response => {
                    window.location = response.redirect;
                })
                .catch(() => {
                    this.registerForm.recaptcha = '';
                });
        },
        // register
        /**
         * Get the active plans for the application.
         */
        getPlans() {
            if (!Spark.cardUpFront) {
                return;
            }

            axios.get('/spark/plans')
                .then(response => {
                    const plans = response.data;

                    this.plans = plans.some(plan => plan.type === 'user')
                        ? plans.filter(plan => plan.type === 'user')
                        : plans.filter(plan => plan.type === 'team')

                    this.selectAppropriateDefaultPlan();
                });
        },
        /**
         * Get the invitation specified in the query string.
         */
        getInvitation() {
            axios.get(`/invitations/${this.query.invitation}`)
                .then(response => {
                    this.invitation = response.data;
                })
                .catch(() => {
                    this.invalidInvitation = true;
                });
        },
        /**
         * Select the appropriate default plan for registration.
         */
        selectAppropriateDefaultPlan() {
            if (this.query.plan) {
                this.selectPlanById(this.query.plan) || this.selectPlanByName(this.query.plan);
            } else if (this.query.invitation) {
                this.selectFreePlan();
            } else if (this.paidPlansForActiveInterval.length > 0) {
                this.selectPlan(this.paidPlansForActiveInterval[0]);
            } else {
                this.selectFreePlan();
            }

            if (this.shouldShowYearlyPlans()) {
                this.showYearlyPlans();
            }
        },
        /**
         * Select the free plan.
         */
        selectFreePlan() {
            const plan = this.plans?.find(plan => plan.price === 0);

            if (typeof plan !== 'undefined') {
                this.selectPlan(plan);
            }
        },
        /**
         * Select the plan with the given id.
         */
        selectPlanById(id) {
            this.plans?.forEach(plan => {
                if (plan.id === id) {
                    this.selectPlan(plan);
                }
            });

            return this.selectedPlan;
        },
        /**
         * Select the plan with the given name.
         */
        selectPlanByName(name) {
            this.plans?.forEach(plan => {
                if (plan.name === name) {
                    this.selectPlan(plan);
                }
            });

            return this.selectedPlan;
        },
        /**
         * Select the given plan.
         */
        selectPlan(plan) {
            this.selectedPlan = plan;

            this.registerForm.plan = plan.id;
        },
        /**
         * Determine if we should show the yearly plans.
         */
        shouldShowYearlyPlans(){
            return (this.monthlyPlans.length === 0 && this.yearlyPlans.length > 0) ||
                this.selectedPlan.interval === 'yearly'
        },
        // plans
        /**
         * Switch to showing monthly plans.
         */
        showMonthlyPlans() {
            this.showingMonthlyPlans = true;

            this.showingYearlyPlans = false;
        },
        /**
         * Switch to showing yearly plans.
         */
        showYearlyPlans() {
            this.showingMonthlyPlans = false;

            this.showingYearlyPlans = true;
        },
        // stripe
        /**
         * Create a Stripe Card Element.
         */
        createCardElement(container){
            if (!this.stripe) {
                throw "Invalid Stripe Key/Secret";
            }

            const card = this.stripe.elements().create('card', {
                hideIcon: true,
                hidePostalCode: true,
                style: {
                    base: {
                        '::placeholder': {
                            color: '#aab7c4'
                        },
                        fontFamily: 'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
                        color: '#495057',
                        fontSize: '15px'
                    }
                }
            });

            card.mount(container);

            return card;
        },
        generateToken(callback){
            return axios.get('/stripe/token').then(response => callback(response.data.clientSecret));
        },
        /**
         * Refresh the tax rate using the given form input.
         */
        refreshTaxRate(form) {
            axios.post('/tax-rate', { ...form })
                .then(response => {
                    this.taxRate = response.data.rate;
                });
        },
        /**
         * Get the tax amount for the selected plan.
         */
        taxAmount(plan) {
            return plan.price * (this.taxRate / 100);
        },
        /**
         * Get the total plan price including the applicable tax.
         */
        priceWithTax(plan) {
            return plan.price + this.taxAmount(plan);
        },
    },
    computed: {
        collectsBillingAddress() {
            return Spark.collectsBillingAddress;
        },
        chargesUsersPerSeat() {
            return Spark.chargesUsersPerSeat;
        },
        chargesUsersPerTeam() {
            return Spark.chargesUsersPerTeam;
        },
        chargesTeamsPerSeat() {
            return Spark.chargesTeamsPerSeat;
        },
        chargesTeamsPerMember() {
            return Spark.chargesTeamsPerMember;
        },
        seatName() {
            return Spark.seatName;
        },
        teamSeatName() {
            return Spark.teamSeatName;
        },
        /**
         * Get the current billing address from the register form.
         *
         * This used primarily for watching.
         */
        currentBillingAddress() {
            return this.registerForm.address +
                   this.registerForm.address_line_2 +
                   this.registerForm.city +
                   this.registerForm.state +
                   this.registerForm.zip +
                   this.registerForm.country;
        },
        // plans
        /**
         * Get the active "interval" being displayed.
         */
        activeInterval() {
            return this.showingMonthlyPlans ? 'monthly' : 'yearly';
        },
        /**
         * Get all of the plans for the active interval.
         */
        plansForActiveInterval() {
            return this.plans?.filter(plan => plan.active && (plan.price === 0 || plan.interval === this.activeInterval));
        },
        /**
         * Get all of the paid plans.
         */
        paidPlans() {
            return this.plans?.filter(plan => plan.active && plan.price > 0);
        },
        /**
         * Get all of the paid plans for the active interval.
         */
        paidPlansForActiveInterval() {
            return this.plansForActiveInterval?.filter(plan => plan.active && plan.price > 0);
        },
        /**
         * Determine if both monthly and yearly plans are available.
         */
        hasMonthlyAndYearlyPlans() {
            return this.monthlyPlans.length > 0 && this.yearlyPlans.length > 0;
        },
        /**
         * Get all of the monthly plans.
         */
        monthlyPlans() {
            return this.plans?.filter(plan => plan.active && plan.interval === 'monthly');
        },
        /**
         * Get all of the yearly plans.
         */
        yearlyPlans() {
            return this.plans?.filter(plan => plan.active && plan.interval === 'yearly');
        },
    }
};
</script>
