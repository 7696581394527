<template>
    <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8 2xl:vx-max-w-screen-xl" data-test="segment-list">
        <page-header>
            Segments
            <template v-slot:actions>
              <vx-button @click="showOverview = ! showOverview" data-test="toggle-overview" type="button" color="muted-dark" size="lg">
                  <span class="vx-hidden sm:vx-inline-block">{{ showOverview ? 'Hide' : 'Show' }} Overview</span>
                  <font-awesome-icon
                      class="vx-hidden sm:vx-inline-block"
                      :icon="faQuestionCircle"
                  ></font-awesome-icon>
                  <font-awesome-icon
                      class="vx-inline-block sm:vx-hidden"
                      :icon="showOverview ? faCircleChevronUp : faCircleChevronDown"
                  ></font-awesome-icon>
              </vx-button>
            </template>
        </page-header>

        <segments-overview v-show="showOverview"></segments-overview>

        <segment-list-filters v-if="totalCount > 0 || !isEqual(defaultFilters(), fetchParams)"
            v-model="fetchParams"
            @reset="resetFilters"
            @apply="applyFilters"
        ></segment-list-filters>

        <template v-if="!loading">
            <vx-box color="info" v-if="totalCount === 0 && !isEqual(defaultFilters(), fetchParams)" class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                <div class="vx-w-64 vx-mx-auto vx-text-center">
                    No results for current search, try adjusting your filters.
                </div>
                <vx-button type="button" color="secondary" @click="resetFilters">
                    Reset All <font-awesome-icon :icon="faCircleX" />
                </vx-button>
            </vx-box>
            <vx-box color="info" v-else-if="totalCount === 0" class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                No segments yet.
                <vx-button href="/contacts/segments/new">
                    New Segment <font-awesome-icon :icon="faPlusCircle" />
                </vx-button>
            </vx-box>
        </template>

        <vx-table v-if="list.length" class="md:vx-grid-cols-5-auto lg:vx-grid-cols-6-auto" rowBreakpoint="md">
            <vx-table-row isHeader>
                <div class="vx-hidden lg:vx-block">
                    Name
                </div>
                <div>
                    Contacts
                </div>
                <div>
                    Audience <span class="vx-hidden lg:vx-inline-block">Type</span>
                </div>
                <div>
                    Refresh Type
                </div>
                <div>
                    Last Refreshed
                </div>
            </vx-table-row>

            <segment-row v-for="(segment, index) in list" :segment="list[index]" :key="list[index].id" @deleted="removeFromList($event)"></segment-row>

        </vx-table>

        <div
            v-if="list.length"
            class="vx-mt-8 vx-flex vx-justify-between vx-w-full vx-gap-10"
        >
            <vx-button
                v-if="fetchParams.cursor"
                @click="loadData()"
                color="muted-dark"
                size="xl"
                class="vx-grow-0"
                data-test="load-more-btn"
                :loading="loading"
            >
                Load More
                <font-awesome-icon
                    :icon="faSpinner"
                ></font-awesome-icon>
            </vx-button>
            <div
                class="vx-text-lg vx-grow-0 vx-ml-auto vx-font-normal vx-text-slate-600"
            >
                {{ list.length }}/{{ totalCount }}
            </div>
        </div>
    </div>
</template>
<script setup>
import PageHeader from '~/components/general/PageHeader.vue';
import {
    VxButton,
    VxBox,
    VxTable,
    VxTableRow,
} from '@voxie/frontend-components';
import { faCircleX, faQuestionCircle, faPlusCircle, faSpinner, faCircleChevronDown, faCircleChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import SegmentsOverview from './SegmentsOverview.vue';
import SegmentListFilters from './SegmentListFilters.vue';
import SegmentRow from './SegmentRow.vue';
import { ref, onMounted, inject } from 'vue';
import segmentsClient from '../../../services/segmentsClient';
import { useToasted } from '../../../composables/useToasted';
import { isEqual } from '~/utils/helpers';
import { isAuthErrorEcho } from '../../../utils/helpers';
import { useCursorPagination } from '../../../composables/useCursorPagination';

const FEATURES = inject('FEATURES');

const teamId = Spark.state.currentTeam.id;
const toasted = useToasted();

const showOverview = ref(true);

const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const defaultFilters = () => {
    const defaultFilters = {
        query: "",
        per_page: 10,
        cursor: null,
        'filter[audience_type]': undefined,
        'filter[refresh_type]': undefined,
        'filter[category]': undefined,
        sort_field: 'id',
        sort_dir: 'desc'
    }
    if (FEATURES.flows) {
        defaultFilters['filter[flow]'] = {
            in_flow: undefined,
            flow_id: null,
        }
    }

    return defaultFilters;
};

const totalCount = ref(0);

const resetFilters = () => {
    fetchParams.value = defaultFilters();

    loadData();
};

const applyFilters = () => {
    fetchParams.value = {
        ...fetchParams.value,
        cursor: null,
    }

    loadData();
}

onMounted(() => {
    resetFilters();
});

const fetchTotalCount = async () => {
    try {
        const response = await segmentsClient.getSegmentsCount(teamId, {...fetchParams.value});
        totalCount.value = response.data?.count || 0;
    } catch(e) {
        console.error(e);
        toasted.global.platform_error();
    }
}

const loadData = () => {
    fetchRecords(segmentsClient.getSegments(teamId, {...fetchParams.value}));
    fetchTotalCount();
};

const removeFromList = (segmentId) => {
    list.value = list.value.filter((item) => item.id != segmentId);
    totalCount.value = totalCount.value - 1;

    if (!list.value.length) {
        loadData();
    }
};

const updateSegment = (updatedSegment) => {
    list.value = list.value.map(segment => {

        if (segment.id == updatedSegment.id) {
            return {
                ...segment,
                ...updatedSegment,
            };
        }

        return segment;
    })
}

const echoErrors = ref([]);
Echo.private(`segments.${teamId}`)
    .listen('ContactSegmentationComplete', (event) => {
        updateSegment(event.segment);
    })
    .listen('ContactSegmentationTriggered', (event) => {
        updateSegment(event.segment);
    }).error?.((e) => {
        if (echoErrors.value.length <= 3 && !isAuthErrorEcho(e)) {
            window?.Bugsnag?.notify(e instanceof Error ? e : new Error(JSON.stringify(e)));
            echoErrors.value.push(e);
        }
    });
</script>
