<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        size="sm"
        :padding="false">
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6">
                Pause {{ props.flowName }}
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">
                Confirm that you intend to pause this flow.
            </div>
        </template>

        <div class="vx-p-10">
            <vx-alert v-if="error"
                color="danger"
                :closeable="false">
                {{ error }}
            </vx-alert>
            <vx-alert v-else
                color="info"
                :closeable="false">
                Pausing this flow will pause all related resources (campaigns, automations).
                It is also important to note that any contacts that would have gone this flow <strong>will not</strong> do so once the flow is made active again.
            </vx-alert>
        </div>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click.prevent="emit('update:visible', false)"
                    type="button"
                    class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                    size="lg"
                    color="muted">
                    Cancel
                </vx-button>
                <vx-button @click.prevent="pause()"
                    data-test="pause"
                    :disabled="error"
                    :loading="pausing"
                    type="button"
                    class="vx-grow"
                    size="lg"
                    color="info">
                    Pause
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import {
    VxAlert,
    VxButton,
    VxModal,
} from "@voxie/frontend-components";
import { ref } from "vue";
import flowsClient from "~/services/flowsClient";

const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
    flowId: {
        type: String,
    },
    flowName: {
        type: String,
    },
});

const emit = defineEmits(['update:visible', 'paused']);

const teamId = Spark.state.currentTeam.id;
const pausing = ref(false);
const error = ref(undefined);

const pause = async () => {
    if (pausing.value) {
        return;
    }

    try {
        await flowsClient.pause(teamId, props.flowId);
        emit('paused');
    } catch (e) {
        error.value = e.response?.data?.message;
        console.error(e);
    } finally {
        pausing.value = false;
    }
}

</script>
