<template>
    <card title="Discount Code Attribution">
        <div class="form-group row mb-4">
            <label class="col-md-4 col-form-label text-md-right">Enter coupon text</label>

            <div class="col-md-6">
                <input
                    type="text"
                    class="form-control"
                    name="coupon-text"
                    v-model="couponText"
                    maxlength="80"
                    :disabled="form.vx_coupons.length >= 50"
                    @keyup.enter="addCoupon">
            </div>
        </div>

        <table v-if="form.vx_coupons.length" class="coupons-attribution-card__table table table-valign-middle w-auto">
            <thead>
                <tr>
                    <th>Coupon</th>
                    <th class="coupons-attribution-card__actions-th text-center">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in form.vx_coupons" :key="index">
                    <td class="coupons-attribution-card__coupon-name">{{ item }}</td>
                    <td class="text-center">
                        <i
                            class="fa fa-trash-o fa-lg cursor-pointer text-danger"
                            data-testid="del-btn"
                            @click="form.vx_coupons.splice(index, 1)"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
        <alert v-else class="mb-0" text="There are no linked items" />
    </card>
</template>

<script>
import Alert from '../../../general/Alert.vue';
import Card from '../../../general/Card.vue';

export default {
    components: {
        Alert,
        Card,
    },
    props: {
        form: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            couponText: '',
        };
    },
    methods: {
        addCoupon() {
            if(!this.couponText) return;

            this.form.vx_coupons.push(this.couponText);

            this.couponText = '';
        }
    },
};
</script>

<style lang="postcss">
.coupons-attribution-card__table {
  margin: 0 -1.25rem;
}
.coupons-attribution-card__coupon-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 255px;
}
.coupons-attribution-card__actions-th {
  width: 1%;
}
</style>
