import { pathToRegexp } from 'path-to-regexp';
import { hasScope } from '../../utils/users';
import { faAddressBook, faMessages, faRobot, faChartSimple, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { inject } from 'vue';

const team = Spark.state.currentTeam;

export const navbarRoutes = (sparkSettings) => {
    const FEATURES = inject('FEATURES');

    const { isServiceRepresentative, ownsCurrentTeam, isAdminRoleOnCurrentTeam } = sparkSettings;

    const analytics = {
        name: 'Analytics',
        icon: faChartSimple,
        pathRegExps: pathToRegexp(['/analytics', '/analytics/(.*)', '/teams/(.*)/metabase-analytics']),
        enable:
            (Boolean(ownsCurrentTeam || isAdminRoleOnCurrentTeam) && team.dashboard_url) || hasScope('analytics:read'),
    };

    if (hasScope('analytics:read')) {
        analytics.target = null;
        analytics.items = [
            { name: 'Dashboard', href: '/analytics/dashboard', target: null, enable: true },
            { name: 'Contacts', href: '/analytics/contacts', target: null, enable: true },
            { name: 'Entry Points', href: '/analytics/entry-points', target: null, enable: true },
            { name: 'Marketing Campaigns', href: '/analytics/marketing-campaigns', target: null, enable: true },
        ];
        if (team.dashboard_url) {
            analytics.items.push({
                name: 'Custom',
                href: `/teams/${team.id}/metabase-analytics`,
                target: '_blank',
                enable: Boolean(ownsCurrentTeam || isAdminRoleOnCurrentTeam),
            });
        }
    } else if (team.dashboard_url && Boolean(ownsCurrentTeam || isAdminRoleOnCurrentTeam)) {
        analytics.href = `/teams/${team.id}/metabase-analytics`;
        analytics.target = '_blank';
        analytics.enable = Boolean(ownsCurrentTeam || isAdminRoleOnCurrentTeam);
    }

    const automations = FEATURES.flows
        ? {
              name: 'Automation',
              icon: faRobot,
              pathRegExps: pathToRegexp(['/automation', '/automation/(.*)']),
              items: [
                  {
                      name: 'Automations',
                      href: '/automation/event',
                      target: null,
                      pathRegExps: pathToRegexp(['/automation/event', '/automation/event/(.*)']),
                      enable: true,
                  },
                  {
                      name: 'Flows',
                      href: '/automation/flows',
                      target: null,
                      enable: true,
                      pathRegExps: pathToRegexp(['/automation/flows', '/automation/flows/(.*)']),
                  },
              ],
              enable: !isServiceRepresentative,
          }
        : {
              name: 'Automations',
              icon: faRobot,
              href: '/automation/event',
              pathRegExps: pathToRegexp(['/automation', '/automation/(.*)']),
              target: null,
              enable: !isServiceRepresentative,
          };

    const routes = [
        {
            name: 'Contacts',
            icon: faAddressBook,
            items: [
                {
                    name: 'All Contacts',
                    href: `/contacts`,
                    pathRegExps: pathToRegexp([
                        '/contacts',
                        '/contacts/:id(\\d+)',
                        '/contacts/:id(\\d+)/(.*)',
                        '/contacts/new',
                    ]),
                    target: null,
                    enable: true,
                },
                {
                    name: 'Segments',
                    href: `/contacts/segments`,
                    pathRegExps: pathToRegexp(['/contacts/segments', '/contacts/segments/(.*)']),
                    target: null,
                    enable: !isServiceRepresentative,
                },
                {
                    name: 'Tags',
                    href: `/contacts/tags`,
                    target: null,
                    enable: !isServiceRepresentative,
                },
                {
                    name: 'Custom Attributes',
                    href: `/contacts/custom-attributes`,
                    target: null,
                    enable: !isServiceRepresentative,
                },
                {
                    name: 'Imports',
                    href: `/contacts/import`,
                    pathRegExps: pathToRegexp(['/contacts/import', '/contacts/import/(.*)']),
                    target: null,
                    enable: !isServiceRepresentative,
                },
                {
                    name: 'Export',
                    href: `/contacts/export/new`,
                    pathRegExps: pathToRegexp(['/contacts/export', '/contacts/export/(.*)']),
                    target: null,
                    enable: true,
                },
            ],
            pathRegExps: pathToRegexp(['/contacts', '/contacts/(.*)']),
            target: null,
            enable: true,
        },
        {
            name: 'Messaging',
            icon: faMessages,
            items: [
                {
                    name: 'Message Hub',
                    href: `/messaging/messagehub`,
                    target: null,
                    enable: true,
                },
                {
                    name: 'Quick Blast',
                    href: `/messaging/quick-blast`,
                    target: null,
                    enable: true,
                },
                {
                    name: 'Campaigns',
                    href: `/messaging/campaigns`,
                    pathRegExps: pathToRegexp(['/messaging/campaigns', '/messaging/campaigns/(.*)']),
                    target: null,
                    enable: !isServiceRepresentative,
                },
                {
                    name: 'Scheduled Messages',
                    href: '/scheduled-messages',
                    target: null,
                    enable: true,
                },
                {
                    name: 'Snippets',
                    href: `/messaging/snippets`,
                    target: null,
                    enable: true,
                },
            ],
            pathRegExps: pathToRegexp(['/messaging', '/scheduled-messages', '/messaging/(.*)']),
            target: null,
            enable: true,
        },
        automations,
        analytics,
        {
            name: 'Support',
            icon: faCircleInfo,
            pathRegExps: pathToRegexp(['support']),
            enable: true,
            items: [
                {
                    name: 'Knowledge Base',
                    href: 'https://support.voxie.com/hc/en-us',
                    target: '_blank',
                    enable: true,
                },
                {
                    name: 'API Docs',
                    href: 'https://docs.voxie.com/reference/getting-started-with-your-api',
                    target: '_blank',
                    enable: true,
                },
                {
                    name: 'Chat',
                    onClick: () => {
                        window.zE('messenger', 'open');
                    },
                    enable: !!window.zE,
                },
                {
                    name: 'Support Request',
                    href: 'https://support.voxie.com/hc/en-us/requests/new',
                    target: '_blank',
                    enable: true,
                },
                {
                    name: 'Image Gallery',
                    href: 'https://www.voxie.com/resource-center/image-gallery/',
                    target: '_blank',
                    enable: true,
                },
            ],
        },
    ];

    return routes
        .filter((route) => {
            return route && route.enable && !route.items?.every((item) => !item.enable);
        })
        .map((route) => {
            route.items = route.items?.filter((item) => item.enable);
            return route;
        });
};
