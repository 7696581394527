<template>
    <tr class="c-table__row">


        <td class="c-table__cell center hidden-xs">
            <input v-model="phoneNumber.selected" id="checkbox1" name="checkboxes" type="checkbox">
        </td>

        <td class="c-table__cell">
            {{ phoneNumber.iso_country || '-' }}
        </td>

        <td class="c-table__cell hidden-xs">
            {{ phoneNumber.phone_number }}
        </td>


        <td class="c-table__cell hidden-xs center">
            <badge v-if="phoneNumber.phone_number_pool_id" type="success">Attached</badge>
            <badge v-if="!phoneNumber.phone_number_pool_id" type="secondary">Free</badge>
        </td>


        <td class="c-table__cell center">

            <a v-if="!phoneNumber.phone_number_pool_id && !pending && !error" @click.prevent="add" class="c-btn c-btn--success" href="#">Add</a>
            <a v-if="phoneNumber.phone_number_pool_id && !pending && !error" @click.prevent="remove" class="c-btn c-btn--warning" href="#">Remove</a>

            <a v-if="pending" class="c-btn c-btn--success is-disabled" href="#">
                Wait
                <i class="fa fa-spin fa-circle-o-notch"></i>
            </a>

            <button v-if="error" class="c-btn c-btn--danger"
                data-toggle="popover"
                data-placement="top"
                :data-content="errorMessage">ERROR</button>

        </td>
    </tr>
</template>


<script>
import Badge from '~/components/general/Badge.vue';

    export default
    {

        props: ['phoneNumber'],

        components: {
            Badge
        },

        data(){
            return {
                pending: false,
                error: false,
                errorMessage: null
            }
        },

        created(){
            Bus.$on(`pool_numbersPending-${this.phoneNumber.id}`, () => {
                this.pending = true;
            });

            Bus.$on(`pool_numbersNotPending-${this.phoneNumber.id}`, () => {
                this.pending = false;
            });

            Bus.$on(`pool_numbersError-${this.phoneNumber.id}`, (message) => {
                this.error = true;
                this.errorMessage = message;
                this.pending = false;
                $('[data-toggle="popover"]').popover({trigger: 'hover'});
            });
        },

        beforeUnmount() {
            Bus.$off(`pool_numbersPending-${this.phoneNumber.id}`);
            Bus.$off(`pool_numbersNotPending-${this.phoneNumber.id}`);
            Bus.$off(`pool_numbersError-${this.phoneNumber.id}`);
        },

        methods: {
            add(){
                this.pending = true;
                Bus.$emit("pool_numbersAdd", this.phoneNumber.id);
            },

            remove(){
                this.pending = true;
                Bus.$emit("pool_numbersRemove", this.phoneNumber.id);
            }
        }

    }
</script>
