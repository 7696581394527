<template>
    <div>
        <alert v-if="success" :text="success" type="success" />
        <alert v-if="error" :text="error" type="danger" />

        <form @submit.prevent="createInvite" class="row align-items-end my-0">
            <div class="offset-md-5"></div>
            <div class="col-md-4 pr-md-0">
                <label>
                    <small>
                        Subaccount Owner Email
                    </small>
                </label>
                <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    name="email"
                />
            </div>
            <div class="col-md-3">
                <button
                    :disabled="!email && creating"
                    class="c-btn c-btn--success w-100"
                    type="submit"
                >
                    <i
                        class="fa fa-plus u-text-small u-opacity-medium u-mr-xsmall"
                    ></i>
                    Invite
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { organizationsClient } from '../../../../services';
import { HTTP_BAD_REQUEST, HTTP_UNPROCESSABLE_ENTITY } from '../../../../support/HttpStatuses';
import Alert from '../../../general/Alert.vue';

export default {
    components: {
        Alert
    },
    props: {
        team: {
            required: true
        }
    },
    data() {
        return {
            email: "",
            error: "",
            success: "",
            creating: false
        };
    },
    methods: {
        createInvite() {
            this.creating = true;

            organizationsClient
                .createInvite(this.team.id, this.email)
                .then(response => {
                    if (response.data.message) {
                        this.setMessage(response.data.message, "success");
                    }
                    this.$emit("created", response.data.data);
                    this.email = ''
                })
                .catch(error => {
                    if (
                        error?.response?.status === HTTP_BAD_REQUEST &&
                        error?.response?.data?.message
                    ) {
                        this.setMessage(error?.response?.data.message, "error");
                    } else if (error?.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
                        const responseErrors = error.response.data.errors;

                        const allErrors = []

                        Object.keys(responseErrors).forEach(key => {
                            allErrors.push(responseErrors[key].join("<br />"));
                        });

                        this.error = allErrors.join("<br />")
                    } else {
                        console.log(error);
                        this.$toasted.global.platform_error();
                    }
                })
                .finally(() => {
                    this.creating = false;
                });
        },
        setMessage(message, type) {
            if (type !== "error" && type !== "success") {
                throw new Error(
                    'setMessage() 2nd argument must be "error" or "success"'
                );
            }
            this.clearMessagesNow();

            this[type] = message;

            this.clearMessages();
        },
        clearMessagesNow() {
            this.success = "";
            this.error = "";
        },
        clearMessages() {
            setTimeout(() => {
                this.clearMessagesNow();
            }, 5000);
        }
    }
};
</script>
