<template>
    <div>
        <vx-tabs v-model="currentTab"
            size="lg"
            class="vx-mb-6">
            <vx-tab value="recommended">Recommended</vx-tab>
            <vx-tab value="custom">Custom</vx-tab>
        </vx-tabs>

        <div v-if="currentTab === 'recommended'">
            <vx-radio-selection v-model="selectedDays" :disabled="props.submiting">
                <vx-radio-selection-item v-for="recommended in recommendedDates"
                    :value="recommended.days"
                    :key="recommended.days">
                    <template v-slot:header>
                        {{ recommended.title }}
                    </template>
                    <template v-slot:content>
                        {{ recommended.description }}
                    </template>
                </vx-radio-selection-item>
            </vx-radio-selection>

            <p class="vx-font-sans vx-text-sm vx-text-slate-500 vx-mt-4">
                Note that follow up message will send at the same time as your original message.
            </p>
        </div>

        <div v-if="currentTab === 'custom'"
            class="vx-flex vx-flex-col vx-items-center vx-space-y-4">
            <div>
                <div class="vx-text-center vx-mb-2">
                    {{ props.formattedModelValue }}
                </div>
                <vx-date-picker :modelValue="props.modelValue"
                    @update:modelValue="emit('update:modelValue', $event)"
                    :disabled-dates="props.submiting ? {} : undefined"
                    mode="datetime"
                    :timezone="getClientTz()"
                    :minDate="dayjs(props.originalScheduledAt).add(59, 'minutes').toDate()"></vx-date-picker>
            </div>
            <vx-alert color="info">
                Follow-up messages must wait at least 1 hour after the original send
            </vx-alert>
            <vx-alert color="info">
                You should respect safe sending hours (texting is allowed between 8am-9pm in the contact&apos;s timezone)
            </vx-alert>
        </div>

        <div class="vx-pt-4 vx-flex vx-gap-2">
            <vx-button @click.prevent="emit('cancel')"
                type="button"
                size="lg"
                color="muted">Cancel</vx-button>
            <vx-button @click.prevent="confirm()"
                type="button"
                size="lg"
                class="vx-flex-grow" :loading="props.submiting" :disabled="props.submiting">
                Continue
                <font-awesome-icon :icon="faArrowTurnDown"
                    class="vx-text-base"></font-awesome-icon>
            </vx-button>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { VxButton, VxTabs, VxTab, VxRadioSelection, VxRadioSelectionItem, VxDatePicker, VxAlert } from '@voxie/frontend-components';
import dayjs from '~/utils/dayjs';
import { pluralized } from '~/components/filters';
import { formatTz, getClientTz } from '../../utils/date';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowTurnDown } from '@fortawesome/pro-solid-svg-icons';
import { useToasted } from '../../composables/useToasted';

const emit = defineEmits(['cancel', 'continue', 'update:modelValue']);
const props = defineProps({
    originalScheduledAt: {
        type: Date,
        required: true,
    },
    modelValue: {
        type: Date,
    },
    formattedModelValue: {
        type: String,
    },
    submiting: {
        type: Boolean,
        default: false,
    }
});

const toasted = useToasted();

const currentTab = ref(props.modelValue ? 'custom' : 'recommended');

const recommendedDates = computed(() => {
    const dates = [];
    for (let i = 1; i <= 3; i++) {
        const dayjsDate = dayjs(props.originalScheduledAt).add(i, 'days')

        const tz = formatTz(dayjsDate.tz(getClientTz()).format('z'));

        dates.push({
            days: i.toString(),
            title: pluralized('day', i, true),
            date: dayjsDate.toDate(),
            description: dayjsDate.tz(getClientTz()).format('ddd MMMM Do, YYYY [at] h:mm a') + ' ' + tz,
        })
    }

    return dates;
});

const selectedDays = ref('1');
watch(selectedDays, () => {
    emit('update:modelValue', recommendedDates.value.find(date => date.days === selectedDays.value)?.date)
});

onMounted(() => {
    if (!props.modelValue) {
        emit('update:modelValue', recommendedDates.value.find(date => date.days === selectedDays.value).date)
    }
})

const confirm = () => {
    if (!props.modelValue) {
        toasted.error('Please choose a date and time.');
        return;
    }

    if (props.submiting) {
        return;
    }
    emit('continue');
}
</script>
