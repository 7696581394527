export const AUDIENCE_TYPE_MARKETING = 'marketing';
export const AUDIENCE_TYPE_TRANSACTIONAL = 'transactional';
export const AUDIENCE_TYPE_ACQUISITION = 'acquisition';
export const AUDIENCE_TYPE_RE_ACQUISITION = 're_acquisition';

export const statuses = {
    DRAFT: 'draft',
    SCHEDULED: 'scheduled',
    PUBLISHED: 'published',
    PAUSED: 'paused',
    COMPLETED: 'completed',
    ARCHIVED: 'archived',
};

export const durationType = {
    EVERGREEN: 'evergreen',
    ONE_TIME: 'one_time',
    PREDEFINED: 'predefined',
};

export const audienceTypes = () => [
    {
        name: AUDIENCE_TYPE_MARKETING,
        label: 'Marketing',
        description: `<li> Contacts have <strong class="vx-font-semibold">opted-in</strong> to <strong class="vx-font-semibold">marketing</strong> messages.</li>
        <li> Message is for promotional content such as discounts, coupons, offers, etc.</li>
        <li> You should have <strong class="vx-font-semibold">explicit permission</strong> to text these numbers or <strong class="vx-font-semibold">risk service disruption</strong>.</li>`,
    },
    {
        name: AUDIENCE_TYPE_TRANSACTIONAL,
        label: 'Transactional',
        description: `<li> Message is for support or in response to an explicit request from customer. </li>
        <li> Message <strong class="vx-font-semibold">cannot</strong> contain any type of promotional content or <strong class="vx-font-semibold">risk service disruption.</strong></li>`,
    },
    {
        name: AUDIENCE_TYPE_ACQUISITION,
        label: 'Acquisition',
        description: `<li> Contacts have not <strong class="vx-font-semibold">opted-in</strong> or <strong class="vx-font-semibold">opted-out</strong> yet. </li>
        <li> This is the first time you are messaging these contacts.</li>`,
    },
    {
        name: AUDIENCE_TYPE_RE_ACQUISITION,
        label: 'Re-acquisition',
        description: `<li> Contacts have <strong class="vx-font-semibold">opted-out</strong> of <strong class="vx-font-semibold">marketing</strong> messages. </li>
        <li> Contacts should have done something to prompt reaching back out to them.</li>`,
    },
];

export function audienceTypeLabel(audienceType) {
    const item = audienceTypes().find((type) => type.name === audienceType);

    return item?.label || 'Unknown';
}

export function audienceTypeDescription(audienceType) {
    const item = audienceTypes().find((type) => type.name === audienceType);

    return item?.description || 'Unknown';
}

export const isDynamicFieldTypeFirstClass = (type) => ['contact_name', 'email'].includes(type);
