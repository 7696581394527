<template>
    <div id="chat-container" :class="{'container-fluid': true, 'simple-messagehub': FEATURES.simple_message_hub}">
        <div class="row h-100">
            <div :class="leftPanelClasses">
                <threads-inboxes-v2 v-if="!FEATURES.simple_message_hub" @setActiveThreadsInbox="onSetActiveThreadsInbox"/>

                <threads-actions-v2
                    :isFilteringEnabled="isFilteringEnabled"
                    @applyFilters="applyFilters"
                />

                <threads-respond-to-next-v2
                    v-if="!FEATURES.simple_message_hub && isRespondToNextOpen && showRespondToNext"
                    :count="threadsInboxes.unassigned"
                    @dismissRespondToNext="setTimeToShowRespondNext"
                    @respondToNext="showRespondToNextModal"
                />

                <threads-table-v2
                    :forward="filters.forward"
                    :isSearching="isSearching"
                    @directionChanged="directionChanged"
                    @loadMoreThreads="loadMoreThreads"
                />
            </div>
            <div :class="middlePanelClasses">
                <contact-title
                    v-if="activeThread && selectedContact.id"
                    :selected-contact="selectedContact"
                    @showProfileSection="onShowProfileSection"
                ></contact-title>
                <thread-chat
                    :is-profile-section-open="isProfileSectionOpen"
                    :isSearching="isSearching"
                    @markedAsRead="onMarkedAsRead"
                    @claimThread="claimThread"
                    @close="isProfileSectionOpen = false"
                ></thread-chat>
            </div>
        </div>
        <new-conversation-group-send-modal></new-conversation-group-send-modal>
        <assign-conversation-modal></assign-conversation-modal>
        <respond-next-modal @respondToNext="claimThread"></respond-next-modal>
    </div>
</template>

<script>
import { isAuthErrorEcho } from '../../../utils/helpers';
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import ThreadChat from './ThreadChat.vue';
import NewConversationGroupSendModal from './misc/modals/NewConversationGroupSendModal.vue';
import AssignConversationModal from './misc/modals/AssignConversationModal.vue';
import RespondNextModal from './misc/modals/RespondNextModal.vue';
import { HTTP_NOT_FOUND } from '../../../support/HttpStatuses';
import ContactTitle from './ContactTitle.vue';
import ThreadsInboxesV2 from './ThreadsInboxes.vue';
import ThreadsActionsV2 from './ThreadsActions.vue';
import ThreadsRespondToNextV2 from './ThreadsRespondToNext.vue';
import ThreadsTableV2 from './ThreadsTable.vue';
import dayjs from '~/utils/dayjs';
import axios from 'axios';

export default {
    name: 'ThreadsContainer',
    components: {
        RespondNextModal,
        AssignConversationModal,
        NewConversationGroupSendModal,
        ThreadChat,
        ContactTitle,
        ThreadsInboxesV2,
        ThreadsActionsV2,
        ThreadsRespondToNextV2,
        ThreadsTableV2,
    },
    inject: ['FEATURES'],
    data() {
        return {
            route: 'unassigned',
            reloadAfterHashChanged: true,
            respondToNextAction: null,
            showRespondNextAfter: 3000,
            dismissRespondToNextFor: 48,
            isProfileSectionOpen: false,
            isRespondToNextOpen: false,
            timeToShowRespondNext: 0,
            maxPerPage: 15,
            shouldFetchSummary: false,
            threadsInFlight: 0,
            threadsQueue: [],
            echoErrors: [],
        };
    },
    computed: {
        ...mapGetters({
            threads: 'getThreads',
            activeThread: 'getActiveThread',
            isContactOnState: 'contacts_v3/isContactOnState',
            threadsInboxes: 'getThreadInboxes',
        }),
        ...mapState({
            selectedContact: (state) => state.contacts_v3.selectedContact,
            activeThreadsInbox: (state) => state.threads.activeThreadsInbox,
            showRespondToNextOn: (state) => state.threads.showRespondToNextOn,
            filters: (state) => state.threads.threadFilters,
        }),
        leftPanelClasses() {
            return {
                'd-xl-flex col-12 col-md-6 col-lg-5 col-xl-4 h-100 p-0 threads-left-panel': true,
                'empty-panel': this.threads.length === 0,
                'd-none d-md-flex': !this.isProfileSectionOpen && this.activeThread?.contact_id,
                'd-none': this.isProfileSectionOpen && this.activeThread?.contact_id,
                'd-lg-flex': !(this.isProfileSectionOpen && this.activeThread?.contact_id),
            };
        },
        middlePanelClasses() {
            return {
                'col-12 col-xl-8 h-100 p-0 threads-middle-panel': true,
                'empty-panel': this.threads.length === 0 || !this.activeThread?.contact_id,
                'col-md-12': this.isProfileSectionOpen && this.activeThread?.contact_id,
                'col-md-6 col-lg-7': !(this.isProfileSectionOpen && this.activeThread?.contact_id),
            };
        },
        showRespondToNext() {
            let end = dayjs(this.timeToShowRespondNext);
            let duration = dayjs.duration(end.diff(dayjs()));
            let hours = duration.asHours();

            return (
                parseInt(this.threadsInboxes.unassigned) > 0 &&
                hours <= 0 &&
                this.activeThreadsInbox &&
                this.showRespondToNextOn.includes(this.activeThreadsInbox)
            );
        },
        isFilteringEnabled() {
            const userId = Spark.state.user.id;
            if (this.filters.assignee !== null) {
                if (!['me'].includes(this.activeThreadsInbox)) {
                    return true;
                } else {
                    if (this.filters.assignee === userId) {
                        return false;
                    }
                }
            }

            return !!(
                this.filters.tag ||
                this.filters.campaign ||
                this.filters.segment ||
                this.filters.last_message_after ||
                (this.FEATURES.simple_message_hub && this.filters.status)
            );
        },
        isSearching() {
            return !!(this.filters.search && this.filters.search.trim());
        },
        isThreadEventsDisabled() {
            return this.isFilteringEnabled || this.isSearching;
        },
    },
    watch: {
        activeThread(val) {
            let numberOfMaxThreadsInList = this.filters.page * this.maxPerPage;
            this.setCurrentMaxThreadsNumber({ number: numberOfMaxThreadsInList });
            if (val) {
                this.removeOldestThread();
            } else {
                this.isProfileSectionOpen = false;
            }
        },
    },
    mounted() {
        this.setPageFromHash();
        window.addEventListener('hashchange', this.setPageFromHash);
        window.addEventListener('resize', this.handleThreadsTableWhitespace);

        setTimeout(() => {
            this.timeToShowRespondNext = localStorage.getItem('timeToShowRespondNext') || 0;
        }, 3000);

        setInterval(() => {
            this.processThreadsQueue();
        }, 10);

        setInterval(() => {
            if (this.shouldFetchSummary && !this.FEATURES.simple_message_hub) {
                this.shouldFetchSummary = false;
                this.fetchThreadsSummaryAndStartRespondToNextTimer();
            }
        }, 5000);

        this.fetchAgents().catch((e) => {
            e.response && e.response.status === HTTP_NOT_FOUND
                ? this.$toasted.show('Agents not found or closed')
                : this.$toasted.global.platform_error();
        });

        const teamId = Spark.state.currentTeam.id;
        Echo.private(`messages.${teamId}`)
            .listen('MessageCreated', this.messageCreatedListener)
            .listen('.ThreadUpdated', this.threadUpdated)
            .error?.((e) => {
                if (this.echoErrors.length <= 3 && !isAuthErrorEcho(e)) {
                    window?.Bugsnag?.notify(e instanceof Error ? e : new Error(JSON.stringify(e)));
                    this.echoErrors.push(e);
                }
            });

        Echo.private(`scheduled_messages.${teamId}`)
            .listen('ScheduledMessageCreated', this.scheduledMessageCreatedListener)
            .error?.((e) => {
                if (this.echoErrors.length <= 3 && !isAuthErrorEcho(e)) {
                    window?.Bugsnag?.notify(e instanceof Error ? e : new Error(JSON.stringify(e)));
                    this.echoErrors.push(e);
                }
            });

        Echo.private(`scheduled_messages.${teamId}`)
            .listen('ScheduledMessageEnqueued', this.scheduledMessageEnqueuedListener)
            .error?.((e) => {
                if (this.echoErrors.length <= 3 && !isAuthErrorEcho(e)) {
                    window?.Bugsnag?.notify(e instanceof Error ? e : new Error(JSON.stringify(e)));
                    this.echoErrors.push(e);
                }
            });

        Echo.private(`contacts.${teamId}`)
            .listen('ContactDetailsUpdated', ({ contact }) => {
                this.updateContactById({
                    contactId: contact.id,
                    payload: {
                        phone: contact.phone,
                        email: contact.email,
                        first_name: contact.first_name,
                        last_name: contact.last_name,
                    },
                });
            })
            .listen('CustomAttributeCreated', (payload) => {
                this.upsertCustomAttr(payload);
            })
            .listen('CustomAttributeUpdated', (payload) => {
                this.upsertCustomAttr(payload);
            })
            .listen('CustomAttributeDeleted', (payload) => {
                this.deleteCustomAttr(payload);
            })
            .listen('ContactTagRemoved', ({ contact, tag }) => {
                this.pullTag({ contact, tag });
            })
            .listen('ContactTagAdded', ({ contact, tag }) => {
                this.pushTag({ contact, tag });
            })
            .listen('ContactCouponAssigned', (payload) => {
                this.upsertPromotionCoupon(payload);
            })
            .listen('PromotionDetailsUpdated', (payload) => {
                this.updatePromotion(payload);
            })
            .listen('PromotionDeleted', ({ promotion }) => {
                this.deletePromotion(promotion.id);
            })
            .error?.((e) => {
                if (this.echoErrors.length <= 3 && !isAuthErrorEcho(e)) {
                    window?.Bugsnag?.notify(e instanceof Error ? e : new Error(JSON.stringify(e)));
                    this.echoErrors.push(e);
                }
            });
    },
    unmounted() {
        window.removeEventListener('hashchange', this.setPageFromHash);
        window.removeEventListener('resize', this.handleThreadsTableWhitespace);
    },
    methods: {
        ...mapMutations({
            setActiveThread: 'setActiveThread',
            setThreads: 'setThreads',
            unselectContact: 'contacts_v3/unselectContact',
            pushMessage: 'contacts_v3/pushMessage',
            pushScheduledMessage: 'contacts_v3/pushScheduledMessage',
            deleteScheduledMessage: 'contacts_v3/deleteScheduledMessage',
            setSelectedThreads: 'setSelectedThreads',
            incrementThreadUnread: 'incrementThreadUnread',
            setUnread: 'setUnread',
            setActiveThreadsInbox: 'setActiveThreadsInbox',
            updateContactById: 'contacts_v3/updateContactById',
            upsertCustomAttr: 'contacts_v3/upsertCustomAttr',
            deleteCustomAttr: 'contacts_v3/deleteCustomAttr',
            pullTag: 'contacts_v3/pullTag',
            pushTag: 'contacts_v3/pushTag',
            upsertContactCampaign: 'contacts_v3/upsertContactCampaign',
            deleteContactCampaign: 'contacts_v3/deleteContactCampaign',
            updateCampaign: 'contacts_v3/updateCampaign',
            deleteCampaign: 'contacts_v3/deleteCampaign',
            upsertPromotionCoupon: 'contacts_v3/upsertPromotionCoupon',
            updatePromotion: 'contacts_v3/updatePromotion',
            deletePromotion: 'contacts_v3/deletePromotion',
            closeThread: 'closeThread',
            upsertThread: 'upsertThread',
            deleteThread: 'deleteThread',
            pushThreads: 'pushThreads',
            setCurrentMaxThreadsNumber: 'setCurrentMaxThreadsNumber',
            removeOldestThread: 'removeOldestThread',
            setThreadFilters: 'setThreadFilters',
            resetThreadFilters: 'resetThreadFilters',
        }),
        ...mapActions({
            fetchThreadsInboxes: 'fetchThreadsInboxes',
            fetchThreads: 'fetchThreads',
            fetchShowRespondToNextOn: 'fetchShowRespondToNextOn',
            fetchAgents: 'fetchAgents',
            assignThread: 'assignThread',
            fetchMoreThreads: 'fetchMoreThreads',
            checkpointGet: 'checkpointGet',
            changeState: 'changeState',
        }),
        updateThreadsQueue({ thread, action, tabs }) {
            this.threadsQueue.push({ thread, action, tabs });
        },
        processThreadsQueue() {
            this.threadsQueue.forEach((queueItem) => {
                if (queueItem.tabs.includes(this.activeThreadsInbox)) {
                    this.shouldFetchSummary = true;
                }
            });

            while (this.threadsInFlight < 2 && this.threadsQueue.length) {
                this.threadsQueue.splice(0, 1).forEach((queueItem) => {
                    if (queueItem.tabs.includes(this.activeThreadsInbox)) {
                        switch (queueItem.action) {
                            case 'upsert':
                                this.threadsInFlight++;
                                this.upsertThread({ thread: queueItem.thread });
                                this.checkpointGet({ thread: queueItem.thread })
                                    .then(() => this.threadsInFlight--)
                                    .catch(() => this.$toasted.global.platform_error());
                                break;
                            case 'delete':
                                this.deleteThread({ thread: queueItem.thread });
                                this.resetConversation(queueItem.thread);
                                break;
                        }
                        this.shouldFetchSummary = true;
                    }
                });
            }
        },
        setPageFromHash() {
            if (this.FEATURES.simple_message_hub) {
                this.setActiveThreadsInbox('all');
                this.onSetActiveThreadsInbox('all');
            } else {
                let hash = window.location.hash;
                hash = window.location.hash.split('#');
                if (hash[1] !== undefined) {
                    this.route = hash[1];
                    if (this.reloadAfterHashChanged && ['unassigned', 'open', 'closed', 'me', 'all'].includes(this.route)) {
                        this.setActiveThreadsInbox(this.route);
                        this.onSetActiveThreadsInbox(this.route);
                    }
                } else {
                    this.setActiveThreadsInbox(this.route);
                    this.onSetActiveThreadsInbox(this.route);
                }
            }
        },
        resetConversation(thread) {
            if (!thread?.contact_id) return false;
            if (this.activeThread?.contact_id === thread.contact_id) {
                this.unselectContact();
                this.setActiveThread(null);
            }
        },
        onSetActiveThreadsInbox(val) {
            this.resetConversation(this.activeThread);
            this.setSelectedThreads([]);
            this.resetThreadFilters();
            this.threadsQueue = [];

            // Add user id if in "Me" inbox.
            if (val === 'me') {
                this.setThreadFilters({
                    status: 'open',
                    assignee: Spark.state.user.id,
                });
            }

            // Set status.
            if (['unassigned', 'open', 'closed'].includes(val)) {
                this.setThreadFilters({
                    status: val,
                });
            }

            // Remove status if want show all threads.
            if (['all'].includes(val)) {
                this.setThreadFilters({
                    status: null,
                });
            }

            this.fetchThreadsSummaryAndStartRespondToNextTimer();

            this.reloadAfterHashChanged = false;
            window.location.hash = val;

            return this.applyFilters(this.filters);
        },
        onShowProfileSection() {
            this.isProfileSectionOpen = !this.isProfileSectionOpen;
        },
        startRespondToNextTimer() {
            if (this.showRespondToNext) {
                setTimeout(() => {
                    this.isRespondToNextOpen = true;
                }, this.showRespondNextAfter);
            }
        },

        async loadThreads() {
            try {
                const res = await this.fetchThreads({ teamId: Spark.state.currentTeam.id, payload: this.filters });
                this.activateTooltip();
                this.reloadAfterHashChanged = true;
                this.handleThreadsTableWhitespace();

                return res;
            } catch (e) {
                if (!axios.isCancel(e)) {
                    console.error(e);
                    e.response && e.response.status === HTTP_NOT_FOUND
                    ? this.$toasted.show('Threads not found or closed')
                    : this.$toasted.global.platform_error();
                }
            }
        },

        messageCreatedListener({ contact, message }) {
            if (this.isContactOnState(contact.id)) {
                this.pushMessage({ contact, message });
            }

            if (message.direction === 'inbound') {
                // It is needed to prevent a wrong new message line position
                // if thread in queue is not processed yet but user wants to open it.
                const thread = this.threads.find((t) => t.contact_id === contact.id);
                if (thread?.contact_id) {
                    this.incrementThreadUnread({ thread });
                }
            }
        },

        scheduledMessageCreatedListener({ contact, scheduled_message }) {
            if (this.isContactOnState(contact.id)) {
                this.pushScheduledMessage({ contact, scheduled_message });
            }
        },

        scheduledMessageEnqueuedListener({ contact, scheduled_message }) {
            if (this.isContactOnState(contact.id)) {
                this.deleteScheduledMessage({ contact, scheduled_message });
            }
        },

        threadUpdated({ thread }) {
            // Disable event when someone respond to next thread.
            if (this.respondToNextAction === thread.contact_id) {
                this.respondToNextAction = null;
                return false;
            }

            if (this.isThreadEventsDisabled) return false;

            const userId = Spark.state.user.id;

            if (['all'].includes(this.activeThreadsInbox)) {
                this.updateThreadsQueue({ thread, action: 'upsert', tabs: ['all'] });
            } else if (['closed'].includes(this.activeThreadsInbox)) {
                if (thread.status === 'closed') {
                    this.updateThreadsQueue({ thread, action: 'upsert', tabs: ['closed'] });
                } else {
                    this.updateThreadsQueue({ thread, action: 'delete', tabs: ['closed'] });
                }
            } else if (['open'].includes(this.activeThreadsInbox)) {
                if (thread.status === 'open') {
                    this.updateThreadsQueue({ thread, action: 'upsert', tabs: ['open'] });
                } else {
                    this.updateThreadsQueue({ thread, action: 'delete', tabs: ['open'] });
                }
            } else if (['unassigned'].includes(this.activeThreadsInbox)) {
                if (thread.assignee_id === null && thread.status === 'open') {
                    this.updateThreadsQueue({ thread, action: 'upsert', tabs: ['unassigned'] });
                } else {
                    this.updateThreadsQueue({ thread, action: 'delete', tabs: ['unassigned'] });
                }
            } else if (['me'].includes(this.activeThreadsInbox)) {
                if (thread.assignee_id === userId && thread.status === 'open') {
                    this.updateThreadsQueue({ thread, action: 'upsert', tabs: ['me'] });
                } else {
                    this.updateThreadsQueue({ thread, action: 'delete', tabs: ['me'] });
                }
            }
        },

        setTimeToShowRespondNext() {
            this.isRespondToNextOpen = false;
            const time = dayjs().add(this.dismissRespondToNextFor, 'hours');
            localStorage.setItem('timeToShowRespondNext', time);
        },
        showRespondToNextModal() {
            Bus.$emit('showRespondNextModal');
        },
        async claimThread(thread, close) {
            this.respondToNextAction = thread.contact_id;
            const assigneeId = Spark.state.user.id;

            try {
                await this.assignThread({ thread: thread, assigneeId });
                if (close) {
                    await this.changeState({ thread: thread, state: 'closed' });
                } else {
                    this.setActiveThreadsInbox('me');
                    this.$nextTick(async () => {
                        await this.onSetActiveThreadsInbox('me');
                        this.activateTooltip();
                        thread.assignee_id = assigneeId;
                        this.pushThreads([thread]);
                        this.setActiveThread(thread);
                    });
                }
            } catch (e) {
                this.$toasted.global.platform_error();
            }
        },
        activateTooltip() {
            this.$nextTick(() => {
                window.$('#chat-container [data-toggle="tooltip"]').tooltip('dispose').tooltip();
            });
        },
        applyFilters(filters) {
            this.setThreadFilters({ ...filters });

            return this.loadThreads();
        },
        directionChanged(forward) {
            this.setThreadFilters({ forward });

            return this.loadThreads();
        },
        onMarkedAsRead() {
            this.shouldFetchSummary = true;
            this.setUnread({ thread: this.activeThread, unread: 0 });
        },

        async loadMoreThreads() {
            const teamId = Spark.state.currentTeam.id;

            try {
                const res = await this.fetchMoreThreads({ teamId, payload: this.filters });
                if (res.data?.data?.length) {
                    this.handleThreadsTableWhitespace();
                }
            } catch (e) {
                e.response && e.response.status === HTTP_NOT_FOUND
                    ? this.$toasted.show('Threads not found or closed')
                    : this.$toasted.global.platform_error();
            }
        },
        async fetchThreadsSummaryAndStartRespondToNextTimer() {
            if (this.FEATURES.simple_message_hub) return;

            try {
                await this.fetchThreadsInboxes({ teamId: Spark.state.currentTeam.id });
                await this.fetchShowRespondToNextOn();
                this.startRespondToNextTimer();
            } catch (e) {
                if (!axios.isCancel(e)) {
                    this.$toasted.global.platform_error();
                }
            }
        },
        isTableWhiteSpaceExists() {
            const bodyElem = window.$('#ttl-body');
            const bodyRows = window.$('.ttl-body-row').not('.ttl-body-row-is-loading');
            const height = bodyElem.outerHeight();
            if (!bodyRows[0]) {
                return false;
            }

            let rowsTotalHeight = 0;
            bodyRows.each(function () {
                rowsTotalHeight += window.$(this).outerHeight();
            });

            return rowsTotalHeight < height;
        },
        handleThreadsTableWhitespace() {
            this.$nextTick(() => {
                if (this.isTableWhiteSpaceExists()) {
                    this.loadMoreThreads();
                }
            });
        },
    },
};
</script>

<style scoped></style>
