<template>
    <vx-selectable v-model="model"
        placeholder="Search Contact"
        :reduce="option => option.value"
        :loading="loading"
        :options="options"
        @search="search"
        :clearable="false"
        data-test="contact-selectable" />
</template>

<script setup>
import { VxSelectable } from '@voxie/frontend-components';
import { ref } from 'vue';
import { useToasted } from '~/composables/useToasted';
import debounce from '~/utils/debounce';
import { contactsClient } from '../../services';
import { isLargeTeam } from '~/utils/team';

const teamId = Spark.state.currentTeam.id;
const toasted = useToasted();

const model = defineModel();
const options = ref([]);
const loading = ref();

const search = debounce((query) => {
    const params = {};
    if (!query && model.value) {
        params['filter[id]'] = model.value;
    } else if (isLargeTeam()) {
        params['filter[phone]'] = query;
    } else {
        params['filter[search]'] = query;
    }

    loading.value = true;
    contactsClient.searchContacts(teamId, params).then((response) => {
        options.value = response.data.data.map((contact) => ({
            value: contact.id,
            label: `${contact.phone} - ${contact.first_name ? ' ' + contact.first_name : ''}${contact.last_name ? ' ' + contact.last_name : ''}`
        }))
    }).catch((e) => {
        console.error(e)
        toasted.global.platform_error()
    }).finally(() => {
        loading.value = false
    })
}, 300);

search();
</script>

