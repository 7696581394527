<template>
    <span>Loading <i class="fa fa-spin fa-circle-o-notch"></i></span>
</template>

<script>
export default {
    name: 'LoadingResource',
};
</script>

