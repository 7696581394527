import { createGlobalState } from '@vueuse/core';
import { computed, inject, nextTick } from 'vue';
import { ref } from 'vue';
import { phoneNumberNational } from '~/components/filters';
import { useToasted } from '~/composables/useToasted';
import { contactsClient } from '~/services';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_NOT_FOUND } from '~/support/HttpStatuses';

const defaultContact = () => {
    return {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        entry_point: '',
        time_zone: '',
        created_at: undefined,
    };
};

export const useContactDetails = createGlobalState(() => {
    const FEATURES = inject('FEATURES');
    const toasted = useToasted();

    const catchRequestError = (error) => {
        console.error(error);
        if (
            !error?.response?.status ||
            error?.response?.status >= HTTP_INTERNAL_SERVER_ERROR ||
            error?.response.status == HTTP_NOT_FOUND
        ) {
            toasted?.global.platform_error();
        } else {
            toasted?.error(error.response?.data?.message || 'Something went wrong.');
        }
    };

    const teamId = Spark.state.currentTeam.id;

    const contact = ref(defaultContact());
    const loading = ref(false);
    const saving = ref(false);
    const error = ref('');

    let onMountedContactCallback = () => {};
    const onMountedContact = (callback) => {
        onMountedContactCallback = callback;
    };
    const mount = async (contactId) => {
        await nextTick();
        contact.value = defaultContact();
        contact.value.id = contactId;

        if (!contactId) {
            onMountedContactCallback?.();
            return;
        }

        loading.value = true;
        try {
            const response = await contactsClient.contactGet(teamId, contactId);
            contact.value = response.data;
            loading.value = false;
            onMountedContactCallback?.();
        } catch (e) {
            catchRequestError(e);
        }
    };

    const unmount = () => {
        contact.value = defaultContact();
        stickyPhoneNumber.value = null;

        subscriptions.value = [];
        marketingStatus.value = 'unknown';
        transactionalStatus.value = 'unknown';

        groups.value = [];
        tags.value = [];
        customAttributes.value = [];
    };

    const saveAll = async () => {
        if (saving.value) {
            return;
        }

        try {
            saving.value = true;
            error.value = '';
            const createOrUpdateResponse = await (contact.value.id
                ? contactsClient.contactUpdate(teamId, contact.value.id, contact.value)
                : contactsClient.contactSave(teamId, contact.value));

            contact.value.id = createOrUpdateResponse.data.id;

            await Promise.all([saveSubscriptions(), syncGroups(), syncCustomAttributes(), syncTags()]);

            return contact.value.id;
        } catch (e) {
            if (
                !e?.response?.status ||
                e?.response?.status >= HTTP_INTERNAL_SERVER_ERROR ||
                e?.response.status == HTTP_NOT_FOUND
            ) {
                toasted?.global.platform_error();
            } else {
                error.value = e.response?.data?.message || 'Something went wrong.';
                toasted?.error(error.value);
            }

            throw e;
        } finally {
            saving.value = false;
        }
    };

    const stickyPhoneNumber = ref(undefined);
    const loadingStickyPhoneNumber = ref(false);
    const getStickyPhoneNumber = async () => {
        loadingStickyPhoneNumber.value = true;
        try {
            const response = await contactsClient.getStickyPhoneNumber(teamId, contact.value.id);
            stickyPhoneNumber.value = response.data;
        } catch (e) {
            if (e?.response?.status == HTTP_NOT_FOUND) {
                stickyPhoneNumber.value = undefined;
            } else {
                catchRequestError(e);
            }
        } finally {
            loadingStickyPhoneNumber.value = false;
        }
    };

    const groups = ref([]);
    const loadingGroups = ref(false);
    const getGroups = async () => {
        if (!FEATURES.contact_groups) {
            return;
        }

        loadingGroups.value = true;
        try {
            const response = await contactsClient.listContactGroups(teamId, contact.value.id);
            groups.value = response.data.data.map((group) => ({
                group_id: group.group_id.toLowerCase(),
            }));
            loadingGroups.value = false;
        } catch (e) {
            catchRequestError(e);
        }
    };
    const syncGroups = async () => {
        if (!FEATURES.contact_groups) {
            return;
        }
        await contactsClient.contactsSyncGroups(teamId, contact.value.id, {
            group_ids: groups.value.map((group) => group.group_id),
        });
    };

    const subscriptions = ref([]);
    const loadingSubscriptions = ref(false);
    const marketingStatus = ref('unknown');
    const transactionalStatus = ref('unknown');

    const existingMarketingStatus = computed(
        () => subscriptions.value.find((subscription) => subscription.type === 'marketing')?.status || 'unknown'
    );
    const existingTransactionalStatus = computed(
        () => subscriptions.value.find((subscription) => subscription.type === 'transactional')?.status || 'unknown'
    );

    const getSubscriptions = async () => {
        loadingSubscriptions.value = true;
        try {
            const response = await contactsClient.getContactSubscriptionStatus(teamId, contact.value.id);
            subscriptions.value = response.data.data;

            marketingStatus.value = existingMarketingStatus.value;
            transactionalStatus.value = existingTransactionalStatus.value;
            loadingSubscriptions.value = false;
        } catch (e) {
            catchRequestError(e);
        }
    };
    const saveSubscriptions = async () => {
        // only sends request when not unknown and when it is different from the existing subscriptions

        if (
            marketingStatus.value &&
            marketingStatus.value !== 'unknown' &&
            existingMarketingStatus.value !== marketingStatus.value
        ) {
            await contactsClient.updateContactSubscription(
                teamId,
                contact.value.id,
                'marketing',
                marketingStatus.value
            );

            if (existingMarketingStatus.value === 'unknown') {
                subscriptions.value.push({
                    type: 'marketing',
                    status: marketingStatus.value,
                });
            } else {
                subscriptions.value = subscriptions.value.map((subscription) => {
                    if (subscription.type === 'marketing') {
                        subscription.status = marketingStatus.value;
                    }
                    return subscription;
                });
            }
        }

        if (
            transactionalStatus.value &&
            transactionalStatus.value !== 'unknown' &&
            existingTransactionalStatus.value !== transactionalStatus.value
        ) {
            await contactsClient.updateContactSubscription(
                teamId,
                contact.value.id,
                'transactional',
                transactionalStatus.value
            );

            if (existingTransactionalStatus.value === 'unknown') {
                subscriptions.value.push({
                    type: 'transactional',
                    status: transactionalStatus.value,
                });
            } else {
                subscriptions.value = subscriptions.value.map((subscription) => {
                    if (subscription.type === 'transactional') {
                        subscription.status = transactionalStatus.value;
                    }
                    return subscription;
                });
            }
        }
    };

    const tags = ref([]);
    const loadingTags = ref(false);
    const getTags = async () => {
        loadingTags.value = true;
        try {
            const response = await contactsClient.contactTagsList(teamId, contact.value.id);
            tags.value = response.data.data.map((tag) => tag.tag_name);
            loadingTags.value = false;
        } catch (e) {
            catchRequestError(e);
        }
    };
    const syncTags = async () => {
        await contactsClient.contactsSyncTags(teamId, contact.value.id, {
            tag_names: tags.value,
        });
    };

    const customAttributes = ref([]);
    const loadingCustomAttributes = ref(false);
    const getCustomAttributes = async () => {
        loadingCustomAttributes.value = true;
        try {
            const response = await contactsClient.contactCustomAttributesList(teamId, contact.value.id);
            customAttributes.value = response.data.data;
            loadingCustomAttributes.value = false;
        } catch (e) {
            catchRequestError(e);
        }
    };
    const syncCustomAttributes = async () => {
        await contactsClient.contactsSyncCustomAttributes(teamId, contact.value.id, {
            custom_attributes: customAttributes.value,
        });
    };

    const fullName = computed(() => {
        const fullName = `${contact.value.first_name || ''} ${contact.value.last_name || ''}`.trim();
        return fullName || phoneNumberNational(contact.value.phone);
    });

    return {
        contact,
        loading,
        error,
        fullName,
        mount,
        unmount,
        saveAll,
        onMountedContact,

        getStickyPhoneNumber,
        loadingStickyPhoneNumber,
        stickyPhoneNumber,

        getGroups,
        loadingGroups,
        groups,
        syncGroups,

        getSubscriptions,
        loadingSubscriptions,
        subscriptions,
        marketingStatus,
        existingMarketingStatus,
        transactionalStatus,
        existingTransactionalStatus,
        saveSubscriptions,

        getTags,
        loadingTags,
        tags,
        syncTags,

        getCustomAttributes,
        loadingCustomAttributes,
        customAttributes,
        syncCustomAttributes,
    };
});
