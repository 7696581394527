<template>

    <div>

        <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
                <a href="#" class="c-btn c-btn--secondary pull-right" @click="close">Close</a>
            </div>
        </div>



        <div class="list">

            <table class="c-table resource-table u-mt-medium">
                <caption class="c-table__title">
                    <span class="hidden-xs">Attached and available numbers</span>

                    <i v-if="bulkOperating.length > 0" class="pull-right u-mt-xsmall fa fa-spin fa-circle-o-notch hidden-xs"></i>
                    <a @click.prevent="bulkRemove" v-bind:class="[{ 'is-disabled': !showBulkOps }, 'pull-right c-btn c-btn--warning hidden-xs']" href="#">Remove Selected</a>
                    <a @click.prevent="bulkAdd" v-bind:class="[{ 'is-disabled': !showBulkOps }, 'u-mr-xsmall pull-right c-btn c-btn--success hidden-xs']" href="#">Add Selected</a>

                    <search
                        v-if="!loadingCollection"
                        placeholder="Filter"
                        identity="phone_number_pool_phone_numbers-search"
                        shouldUpdateState="false"
                        :searchUrl="resourceUrl"
                        method="get">
                    </search>

                </caption>

                <thead class="c-table__head c-table__head--slim">
                    <tr class="c-table__row">

                        <th class="c-table__cell c-table__cell--head center hidden-xs">
                            <input v-model="selectAll" id="checkbox1" name="checkboxes" type="checkbox">
                        </th>
                        <th class="c-table__cell c-table__cell--head">Country Code</th>
                        <th class="c-table__cell c-table__cell--head hidden-xs">Number</th>
                        <th class="c-table__cell c-table__cell--head center hidden-xs">Pool Status</th>
                        <th class="c-table__cell c-table__cell--head center">Add / Remove</th>

                    </tr>
                </thead>

                <tbody>

                    <tr v-if="loadingCollection">
                        <td class="c-table__cell center" colspan="5">
                            <loading-resource />
                        </td>
                    </tr>

                    <tr v-else-if="loadingCollectionError">
                        <td class="c-table__cell center" colspan="5">
                            <alert-error />
                        </td>
                    </tr>

                    <tr v-else-if="!collection.length">
                        <td class="c-table__cell center" colspan="5">
                            <alert-no-results />
                        </td>
                    </tr>

                    <template v-else>
                        <pool-add-remove-phone-number-row v-for="resource in collection" :phoneNumber="resource" :key="resource.id"></pool-add-remove-phone-number-row>
                    </template>

                </tbody>
            </table><!-- // .c-table -->

             <pagination
                    v-if="!loadingCollection && !(!prevPageUrl && !nextPageUrl)"
                    identity="phone_number_pool_phone_numbers-pages"
                    :totalPageCount="totalPages"
                    :nextPage="nextPageUrl"
                    :prevPage="prevPageUrl"
                    ></pagination>

        </div><!-- eo .list -->

    </div>

</template>

<script>
import Pagination from '~/components/general/Pagination.vue';
import PoolAddRemovePhoneNumberRow from '~/components/phone-number-pools/AddRemovePhoneNumberRow.vue';
import Search from '~/components/general/Search.vue';
import AlertNoResults from '../general/AlertNoResults.vue';
import AlertError from '../general/AlertError.vue';
import LoadingResource from '../general/LoadingResource.vue';
import { adminPhoneNumberPoolsClient, phoneNumberPoolsClient } from '../../services';
import resourceManagement from './../../mixins/resource-management';

export default {
    props: ['phoneNumberPoolId'],

    mixins: [
        resourceManagement
    ],

    components: {
        Pagination,
        PoolAddRemovePhoneNumberRow,
        Search,
        AlertNoResults,
        AlertError,
        LoadingResource,
    },

    data() {
        return {
            teamId: Spark.state.currentTeam.id,
            phoneNumberPool: null,
            resourceName: 'phone_number_pool_phone_numbers',
            collectionTitle: 'Pool Phone Numbers',
            resourceUrl: null,
            selectAll: false,
            bulkOperating: []
        };
    },

    mounted(){
        Bus.$on('phone_number_pool_phone_numbers-search-cancel', () => {
            this.loadCollectionAndUi();
            setTimeout(() => {this.collectionTitle = "APool Phone Numbers";}, 100);
        });

        this.resourceUrl = `/api/admin/teams/${this.teamId}/phone-number-pools/${this.phoneNumberPoolId}/filter-phone-numbers`;

        this.listenForResourceEvents();

        this.loadCollectionAndUi();

        this.loadNumberPoolData();

        Bus.$on('pool_numbersAdd', (id) => {
            this.addToPool([id]);
        });

        Bus.$on('pool_numbersRemove', (id) => {
            this.removeFromPool([id]);
        });
    },

    beforeUnmount() {
        Bus.$off('pool_numbersAdd');
        Bus.$off('pool_numbersRemove');
    },

    watch: {
        selectAll: function (newVal) {
            this.toggleSelectAll(newVal);
        }
    },

    computed: {
        showBulkOps(){
            return this.bulkOperating.length == 0
                && this.collection.filter(e => e.selected == true).length > 1;
        }
    },

    methods: {
        loadNumberPoolData(){
            phoneNumberPoolsClient.getById(this.teamId, this.phoneNumberPoolId)
                .then(response => {
                    this.phoneNumberPool = response.data;
                });
        },

        close(){
            Bus.$emit('phone_number_poolsCancelManageNumbers');
        },

        setCollection(data) {
            data.forEach((el) => {
                el.selected = false;
            });

            this.collection = data;
        },

        toggleSelectAll(checked) {
            this.collection.forEach((el) => {
                el.selected = checked;
            });
        },

        bulkAdd(){
            this.bulkOperating = [];

            this.collection.forEach((num) => {
                if(num.selected){
                    this.bulkOperating.push(num.id);
                }
            });

            this.addToPool(this.bulkOperating);
        },

        bulkRemove(){
            this.bulkOperating = [];

            this.collection.forEach((num) => {
                if(num.selected){
                    this.bulkOperating.push(num.id);
                }
            });

            this.removeFromPool(this.bulkOperating);
        },

        addToPool(ids){
            this.addRemovePoolNumbers('add', ids);
        },

        removeFromPool(ids){
            this.addRemovePoolNumbers('remove', ids);
        },

        addRemovePoolNumbers(action, ids){
            ids.forEach((id) => {
                Bus.$emit(`pool_numbersPending-${id}`);
            });

            adminPhoneNumberPoolsClient.addRemovePoolNumbers(this.teamId, action, this.phoneNumberPoolId, ids)
                .then(response => {

                    Bus.$emit("pool_numbersUpdated", this.phoneNumberPoolId, response.data);

                    this.collection.forEach((_resource) => {
                        this.bulkOperating = this.bulkOperating.filter(id => id != _resource.id);

                        Bus.$emit(`pool_numbersNotPending-${_resource.id}`);

                        let objIndex = this.collection.findIndex((obj => obj.id == _resource.id));

                        if(response.data[_resource.id] == true){
                            this.collection[objIndex].phone_number_pool_id = this.phoneNumberPoolId;
                        } else {
                            this.collection[objIndex].phone_number_pool_id = null;
                        }
                    });
                })
                .catch (error => {
                    this.$toasted.global.platform_error();
                    console.log(error.response);
                });
        }

    }
}
</script>
