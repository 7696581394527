<template>
    <div class="vx-w-full">
        <div class="vx-text-slate-700 vx-text-base vx-font-bold vx-mb-2">API Auth</div>

        <vx-alert v-model:visible="upsertedSuccessfully"
            color="success"
            class="vx-mb-4">
            <template v-slot:icon>
                <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
            </template>
            API Auth saved and selected.
        </vx-alert>

        <div v-if="emptyAuthConfigs">
            <vx-popover hover
                class="vx-w-full"
                :arrow="true"
                placement="top">
                <vx-button block
                    :disabled="!isAdminOrOwner"
                    @click.prevent="addNewAuthConfig()"
                    type="button"
                    color="muted"
                    size="lg">Add New <font-awesome-icon :icon="faPlus"></font-awesome-icon></vx-button>
                <template v-if="!isAdminOrOwner"
                    v-slot:content>
                    You do not have permission to create.
                </template>
            </vx-popover>
        </div>
        <div v-else
            class="vx-flex vx-flex-col vx-gap-2 lg:vx-flex-row">
            <vx-selectable size="lg"
                placeholder="Select an API Auth"
                :modelValue="props.modelValue"
                @update:modelValue="emit('update:modelValue', $event)"
                :error="props.error"
                :options="authConfigs"
                :reduce="option => option.auth_config_id"
                label="friendly_name"></vx-selectable>
            <vx-popover v-if="props.modelValue"
                class="vx-w-full lg:vx-w-auto"
                hover
                :arrow="true"
                placement="top">
                <vx-button block
                    :disabled="!isAdminOrOwner"
                    @click.prevent="authConfigModal = true;"
                    type="button"
                    color="muted"
                    size="lg"
                    outline>Edit <font-awesome-icon :icon="faPen"></font-awesome-icon></vx-button>
                <template v-if="!isAdminOrOwner"
                    v-slot:content>
                    You do not have permission to edit.
                </template>
            </vx-popover>
            <vx-popover hover
                class="vx-w-full lg:vx-w-auto"
                :arrow="true"
                placement="top">
                <vx-button block
                    :disabled="!isAdminOrOwner"
                    @click.prevent="addNewAuthConfig()"
                    type="button"
                    color="muted"
                    size="lg">Add New <font-awesome-icon :icon="faPlus"></font-awesome-icon></vx-button>
                <template v-if="!isAdminOrOwner"
                    v-slot:content>
                    You do not have permission to create.
                </template>
            </vx-popover>
        </div>

        <auth-config-modal-form v-model:visible="authConfigModal"
            :authConfigId="props.modelValue"
            @upserted="upsertAndSelect($event)"></auth-config-modal-form>
    </div>
</template>

<script setup>
import {
    VxSelectable, VxPopover, VxButton, VxAlert
} from "@voxie/frontend-components";
import { onMounted, ref, watch } from "vue";
import authConfigsClient from '../../../../../services/authConfigsClient'
import { useToasted } from "../../../../../composables/useToasted";
import { uniqBy } from "~/utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPlus, faPen, faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import AuthConfigModalForm from "../../auth-configs/form/AuthConfigModalForm.vue";

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
        default: null,
    },
    error: {
        type: String,
        default: undefined,
    },
    authModalVisible:  {
        type: Boolean,
        default: false,
    }
});

const isAdminOrOwner = Spark.isAdminOrOwner;
const teamId = Spark.state.currentTeam.id;
const toasted = useToasted();
const emit = defineEmits(['update:modelValue', 'update:authModalVisible']);

const upsertedSuccessfully = ref(false);

const authConfigModal = ref(false);

const loading = ref(false);
const emptyAuthConfigs = ref(false);
const authConfigs = ref([]);

const addNewAuthConfig = () => {
    authConfigModal.value = true;
    emit('update:modelValue', null);
}

watch(authConfigModal, (visible) => {
    emit('update:authModalVisible', visible)
});

const upsertAndSelect = (authConfig) => {
    emptyAuthConfigs.value = false;
    upsertedSuccessfully.value = true;

    authConfigs.value.unshift(authConfig);
    authConfigs.value = uniqBy(authConfigs.value, 'auth_config_id');

    emit('update:modelValue', authConfig.auth_config_id);
}

onMounted(async () => {
    loading.value = true;

    if (props.modelValue) {
        try {
            const authConfig = (await authConfigsClient.get(teamId, props.modelValue)).data;
            authConfigs.value = [authConfig]
        } catch (e) {
            console.error(e);
            toasted.global.platform_error()
        }
    }

    try {
        authConfigs.value = authConfigs.value.concat((await authConfigsClient.list(teamId, {
            limit: 50,
            forward: false,
        })).data.data)
        authConfigs.value = uniqBy(authConfigs.value, 'auth_config_id');
        emptyAuthConfigs.value = !authConfigs.value.length;
    } catch (e) {
        console.error(e)
        toasted.global.platform_error();
    } finally {
        loading.value = false
    }
});

</script>
