<template>
    <svg width="432px" height="212px" viewBox="0 0 432 212" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path id="path-1" d="M306.557,0 C314.289,0 320.557,6.268 320.557,14 L320.557,112 C320.557,119.732 314.289,126 306.557,126 L40.557,126 C32.8253,126 26.5573,119.732 26.5573,112 L26.5573,59.5 L1.0321,33.9749 C-1.1727,31.77 0.3889,28 3.507,28 L26.5573,28 L26.5573,14 C26.5573,6.268 32.8253,0 40.557,0 L306.557,0 Z"></path>
            <filter id="filter-2" x="-11.9%" y="-27.0%" width="123.7%" height="160.3%" filterUnits="objectBoundingBox">
                <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.11595826 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
            <path id="path-3" d="M308.578,34.232 C316.246,35.2215 321.661,42.2401 320.671,49.9085 L311.713,119.333 C310.723,127.001 303.705,132.416 296.036,131.426 L32.2236,97.385 C24.5552,96.395 19.1409,89.376 20.1304,81.708 L23.2658,57.4094 L1.2172,28.8276 C-0.6874,26.3587 1.3438,22.8195 4.4364,23.2186 L27.2971,26.1685 L29.0887,12.2836 C30.0782,4.6152 37.0969,-0.7992 44.765,0.1904 L308.578,34.232 Z"></path>
            <filter id="filter-4" x="-11.9%" y="-25.9%" width="123.7%" height="157.8%" filterUnits="objectBoundingBox">
                <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.11595826 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
            <path id="path-5" d="M301.439,0.6429 C309.118,-0.2644 316.078,5.2247 316.985,12.9033 L328.486,110.226 C329.393,117.905 323.904,124.865 316.225,125.772 L52.0635,156.988 C44.3849,157.895 37.4246,152.406 36.5173,144.728 L30.3563,92.591 L2.0121,70.2373 C-0.4363,68.3064 0.672,64.3792 3.7687,64.0132 L26.6597,61.3082 L25.0167,47.405 C24.1093,39.7264 29.5985,32.7661 37.277,31.8587 L301.439,0.6429 Z"></path>
            <filter id="filter-6" x="-11.6%" y="-21.7%" width="123.2%" height="148.5%" filterUnits="objectBoundingBox">
                <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.11595826 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
            <path id="path-7" d="M306.557,0.3836 C314.289,0.3836 320.557,6.6516 320.557,14.3836 L320.557,84.384 C320.557,92.116 314.289,98.384 306.557,98.384 L40.557,98.384 C32.825,98.384 26.557,92.116 26.557,84.384 L26.557,59.884 L1.0321,34.358 C-1.1727,32.154 0.3889,28.3836 3.507,28.3836 L26.557,28.3836 L26.557,14.3836 C26.557,6.6516 32.825,0.3836 40.557,0.3836 L306.557,0.3836 Z"></path>
            <filter id="filter-8" x="-11.9%" y="-34.7%" width="123.7%" height="177.6%" filterUnits="objectBoundingBox">
                <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.11595826 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-255.000000, -295.000000)">
                <g id="Selected-Threads-Illustration" transform="translate(279.000000, 315.000000)">
                    <g id="Group" transform="translate(34.000000, 0.000000)">
                        <g id="Path">
                            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                            <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-1"></use>
                        </g>
                        <rect id="Rectangle" fill="#F0F0F0" fill-rule="nonzero" x="54.557" y="56" width="238" height="14"></rect>
                        <rect id="Rectangle" fill="#F0F0F0" fill-rule="nonzero" x="54.557" y="84" width="238" height="14"></rect>
                        <rect id="Rectangle" fill="#F0F0F0" fill-rule="nonzero" x="54.557" y="28" width="143.5" height="14"></rect>
                    </g>
                    <g id="Group" transform="translate(32.000000, 14.000000)">
                        <g id="Path">
                            <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
                            <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-3"></use>
                        </g>
                        <rect id="Rectangle" fill="#F0F0F0" fill-rule="nonzero" transform="translate(99.297033, 42.517150) rotate(7.352650) translate(-99.297033, -42.517150) " x="53.7970333" y="35.5171499" width="91" height="14"></rect>
                        <rect id="Rectangle" fill="#F0F0F0" fill-rule="nonzero" transform="translate(130.425237, 74.766011) rotate(7.352650) translate(-130.425237, -74.766011) " x="49.9252374" y="67.7660106" width="161" height="14"></rect>
                        <rect id="Rectangle" fill="#F0F0F0" fill-rule="nonzero" transform="translate(219.054525, 57.970272) rotate(7.352650) translate(-219.054525, -57.970272) " x="157.804525" y="50.9702722" width="122.5" height="14"></rect>
                    </g>
                    <g id="Group" transform="translate(0.000000, 6.000000)">
                        <g id="Path">
                            <use fill="black" fill-opacity="1" filter="url(#filter-6)" xlink:href="#path-5"></use>
                            <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-5"></use>
                        </g>
                        <rect id="Rectangle" fill="#F0F0F0" fill-rule="nonzero" transform="translate(176.751215, 78.815669) rotate(-6.739340) translate(-176.751215, -78.815669) " x="57.7512151" y="71.8156688" width="238" height="14"></rect>
                        <rect id="Rectangle" fill="#F0F0F0" fill-rule="nonzero" transform="translate(180.037315, 106.621669) rotate(-6.739340) translate(-180.037315, -106.621669) " x="61.0373151" y="99.6216688" width="238" height="14"></rect>
                        <rect id="Rectangle" fill="#F0F0F0" fill-rule="nonzero" transform="translate(126.541998, 56.553881) rotate(-6.739340) translate(-126.541998, -56.553881) " x="54.791998" y="49.5538807" width="143.5" height="14"></rect>
                    </g>
                    <g id="Group" transform="translate(63.000000, 52.000000)">
                        <g id="Path">
                            <use fill="black" fill-opacity="1" filter="url(#filter-8)" xlink:href="#path-7"></use>
                            <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-7"></use>
                        </g>
                        <rect id="Rectangle" fill="#F0F0F0" fill-rule="nonzero" x="54.557" y="28.3836" width="91" height="14"></rect>
                        <rect id="Rectangle" fill="#F0F0F0" fill-rule="nonzero" x="54.557" y="56.384" width="161" height="14"></rect>
                        <rect id="Rectangle" fill="#F0F0F0" fill-rule="nonzero" x="159.557" y="28.3836" width="122.5" height="14"></rect>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'SelectedThreadImage',
};
</script>

<style scoped>

</style>
