<template>
    <vx-expandable-card>
        <template v-slot:header>
            URL Configuration
        </template>

        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-6">
                <vx-alert v-if="success"
                    :closeable="false"
                    color="success"
                    shadow>
                    Updated successfully
                </vx-alert>
                <vx-alert v-if="error"
                    :closeable="false"
                    color="danger"
                    shadow>
                    {{ error }}
                </vx-alert>
                <!-- Name -->
                <div>
                    <vx-label size="md">
                        ShortURL Domain
                        <template v-slot:description>
                            Update any appropriate DNS before adding or changing this domain.
                        </template>
                    </vx-label>

                    <vx-input size="lg"
                        name="domain"
                        v-model="team.domain"
                        :error="displayFirstError(errors.domain)"></vx-input>
                </div>

                <div>
                    <div class="vx-flex vx-items-center vx-justify-between">
                        <vx-label size="md">Enable Team Wide Global UTM Parameters</vx-label>
                        <vx-toggle data-test="use_global_utm_parameters"
                            v-model="team.use_global_utm_parameters"
                            size="lg"></vx-toggle>
                    </div>
                </div>
                <div>
                    <vx-label size="md">
                        Global UTM Parameters
                        <template v-slot:description>
                            Paste Global UTM Parameters here
                        </template>
                    </vx-label>

                    <vx-input size="lg"
                        :rows="3"
                        name="global_utm_parameters"
                        :disabled="!team.use_global_utm_parameters"
                        v-model="team.global_utm_parameters"
                        :error="displayFirstError(errors.global_utm_parameters)"></vx-input>
                </div>

                <vx-button block
                    size="lg"
                    @click.prevent="submit"
                    :loading="submitting"
                    :disabled="!dirty"
                    >
                    Update
                </vx-button>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxAlert, VxButton, VxExpandableCard, VxInput, VxLabel, VxToggle } from '@voxie/frontend-components';
import { onMounted, ref, watch } from 'vue';
import { teamsClient } from '~/services';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_UNPROCESSABLE_ENTITY } from '~/support/HttpStatuses';
import { displayFirstError } from '~/utils/validation';

const team = defineModel('team', {
    type: Object,
    required: true,
});


const previousDomain = ref('')

onMounted(() => {
    previousDomain.value = team.value.domain;
});

const dirty = ref(false);

watch(
    () => team.value.global_utm_parameters +
        team.value.use_global_utm_parameters +
        team.value.domain,
    () => dirty.value = true
);

const submitting = ref(false);
const success = ref(false);
const error = ref('');
const errors = ref({});

const submit = () => {
    if (submitting.value) {
        return;
    }

    if (previousDomain.value !== team.value.domain && !window.confirm('Are you sure? If you change the domain, any short URLs to the old domain will cease to function!')) {
        return;
    }

    team.value.domain = team.value.domain?.trim().toLowerCase().replace('http://', 'https://');

    if (team.value.domain && !team.value.domain.startsWith('https://')) {
        team.value.domain = 'https://' + team.value.domain;
    }

    success.value = false;
    errors.value = {};
    error.value = ''
    submitting.value = true;

    teamsClient.updateDetails(
        team.value.id,
        {
            domain: previousDomain.value !== team.value.domain ? team.value.domain : undefined,
            use_global_utm_parameters: team.value.use_global_utm_parameters,
            global_utm_parameters: team.value.global_utm_parameters,
        }
    ).then(() => {
        success.value = true;
        previousDomain.value = team.value.domain;
    }).catch((e) => {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            error.value = 'Something went wrong.'
        } else {
            if (e.response.status === HTTP_UNPROCESSABLE_ENTITY) {
                errors.value = e.response.data.errors
            }
            error.value = e.response?.data?.message || 'Something went wrong.';
        }
    }).finally(() => {
        submitting.value = false;
    })
}

</script>
