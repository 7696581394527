<template>
    <card-form
        title="Redirect number"
        noSaveBtn
    >
        <loading-spinner v-if="loading" />
        <failed-to-load-data v-else-if="failed" @reload="loadShiftToTextRedirectNumber" />
        <div v-else class="vx-flex vx-gap-3">
            <vx-input
                placeholder="Phone number"
                type="text"
                v-model="redirect"
            />
            <vx-button
                color="info"
                data-test="clear"
                :disabled="saving || redirect == ''"
                @click="redirect = ''; updateShiftToTextRedirect()"
            >
                Clear
            </vx-button>
            <vx-button
                data-test="update"
                :disabled="saving || redirect == ''"
                @click="updateShiftToTextRedirect"
            >
                Update
            </vx-button>
        </div>
    </card-form>
</template>

<script setup>
import { ref } from 'vue';
import CardForm from '../../../general/CardForm.vue';
import LoadingSpinner from '../../../general/LoadingSpinner.vue';
import FailedToLoadData from '../../../general/FailedToLoadData.vue';
import { teamsClient } from '../../../../services';
import { VxButton, VxInput } from '@voxie/frontend-components';
import { useToasted } from '../../../../composables/useToasted';
import Swal from 'sweetalert2';

const failed = ref(false);
const loading = ref(true);
const redirect = ref('');
const saving = ref(false);
const toasted = useToasted();

const loadShiftToTextRedirectNumber = () => {
    loading.value = true;
    teamsClient.getShiftToTextRedirect(Spark.state.currentTeam.id)
        .then((r) => {
            failed.value = false;
            redirect.value = r.data?.redirect || '';
        })
        .catch(() => {
            failed.value = true;
        })
        .finally(() => {
            loading.value = false;
        });
}

const updateShiftToTextRedirect = () => {
    saving.value = true;
    teamsClient.updateShiftToTextRedirect(Spark.state.currentTeam.id, redirect.value)
        .then(() => {
            Swal.fire({
                title: 'Success!',
                text: 'Shift to Text redirect number was updated.',
                icon: 'success'
            });
        })
        .catch((error) => {
            console.log(error);
            toasted.global.platform_error();
        })
        .finally(() => {
            saving.value = false;
        });
}

loadShiftToTextRedirectNumber();
</script>
