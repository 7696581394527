/**
 * SparkForm helper class. Used to set common properties on all forms.
 */
window.SparkForm = function (data) {
    const form = this;
    const initialData = data;

    this.sparkFormId = 'abcdefghijklmnopqrstuvxz0123456789'
        .split('')
        .sort(() => 0.5 - Math.random())
        .slice(0, 10)
        .join('');

    $.extend(this, data);

    /**
     * Create the form error helper instance.
     */
    this.errors = new SparkFormErrors();

    this.busy = false;
    this.successful = false;

    this.emitUpdated = () => {
        Bus.$emit(`sparkFormUpdated.${this.sparkFormId}`);
    };

    /**
     * Start processing the form.
     */
    this.startProcessing = function () {
        form.errors.forget();
        form.busy = true;
        form.successful = false;

        this.emitUpdated();
    };

    /**
     * Finish processing the form.
     */
    this.finishProcessing = function () {
        form.busy = false;
        form.successful = true;

        this.emitUpdated();
    };

    /**
     * Reset the form to its original state.
     */
    this.reset = function () {
        $.extend(form, initialData);
        form.resetStatus();

        this.emitUpdated();
    };

    /**
     * Reset the errors and other state for the form.
     */
    this.resetStatus = function () {
        form.errors.forget();
        form.busy = false;
        form.successful = false;

        this.emitUpdated();
    };

    /**
     * Set the errors on the form.
     */
    this.setErrors = function (errors) {
        form.busy = false;
        form.errors.set(errors);

        this.emitUpdated();
    };
};
