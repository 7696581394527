<template>
    <vx-table-row>
        <vx-table-row-title>
            <div class="vx-flex">
                <vx-badge class="vx-mr-2 vx-cursor-pointer"
                    @click.prevent="copyId()"
                    size="xs"
                    color="slate">{{ props.modelValue.id }}</vx-badge>
                <div class="vx-line-clamp-none vx-break-all lg:vx-line-clamp-2">
                    {{ props.modelValue.name }}
                </div>
            </div>
        </vx-table-row-title>

        <vx-table-row-item>
            <template v-slot:label> Event: </template>

            <vx-popover v-if="trigger"
                hover
                :arrow="true"
                placement="top">
                <vx-badge size="sm"
                    :color="trigger.color">
                    <font-awesome-icon :icon="trigger.icon"></font-awesome-icon>
                    {{ trigger.short_label || trigger.label }}
                </vx-badge>
                <template v-if="trigger"
                    v-slot:content>
                    <span class="vx-font-mono">{{ trigger.value }}</span>
                </template>
            </vx-popover>
            <template v-else>
                <vx-icon-loading spin></vx-icon-loading>
            </template>
        </vx-table-row-item>
        <vx-table-row-item contentClasses="vx-max-w-full vx-overflow-hidden">
            <template v-slot:label> Filters: </template>

            {{ props.modelValue.filters_count }}
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Actions: </template>

            <vx-popover hover
                :arrow="true"
                placement="top">
                {{ props.modelValue.live_actions_count }} / {{ props.modelValue.actions_count }}
                <template v-slot:content>
                    Automations: {{ props.modelValue.live_actions_count }} active / {{ props.modelValue.actions_count }}
                    total
                </template>
            </vx-popover>
        </vx-table-row-item>
        <vx-table-row-item>
            <template v-slot:label> Updated: </template>

            <vx-popover hover
                :arrow="true"
                placement="top"
                :key="updatedAtKey">
                {{ fromNow(props.modelValue.updated_at) }}
                <template v-slot:content>
                    {{ shortDateTime(props.modelValue.updated_at) }}
                </template>
            </vx-popover>
        </vx-table-row-item>

        <vx-table-row-buttons>
            <vx-dropdown placement="right">
                <vx-button data-test="rule-dropdown"
                    type="button"
                    color="muted"
                    outline
                    class="vx-w-[45px]">
                    <font-awesome-icon :icon="faEllipsis" />
                </vx-button>

                <template v-slot:items>
                    <vx-dropdown-item :href="`/automation/event/new?duplicate_from_id=${props.modelValue.id}`">
                        Duplicate
                        <font-awesome-icon :icon="faCopy"></font-awesome-icon>
                    </vx-dropdown-item>
                    <vx-dropdown-item data-test="delete"
                        @click="deleteAutomationRule()">
                        Delete
                        <font-awesome-icon :icon="faTrash"></font-awesome-icon>
                    </vx-dropdown-item>
                    <vx-dropdown-item data-test="turn-all-actions-on"
                        @click="updateActionsStatus('published')">
                        All Actions - On
                        <font-awesome-icon :icon="faToggleLargeOn"></font-awesome-icon>
                    </vx-dropdown-item>
                    <vx-dropdown-item data-test="turn-all-actions-off"
                        @click="updateActionsStatus('draft')">
                        All Actions - Off
                        <font-awesome-icon :icon="faToggleLargeOff"></font-awesome-icon>
                    </vx-dropdown-item>
                </template>
            </vx-dropdown>


            <vx-button :href="`/automation/event/${props.modelValue.id}`"
                color="muted"
                outline>
                Edit <font-awesome-icon :icon="faFilePen" />
            </vx-button>

        </vx-table-row-buttons>

    </vx-table-row>
</template>

<script setup>
import {
    faCopy,
    faEllipsis,
    faFilePen,
    faToggleLargeOff,
    faToggleLargeOn,
    faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxBadge,
    VxButton,
    VxDropdown,
    VxDropdownItem,
    VxIconLoading,
    VxPopover,
    VxTableRow,
    VxTableRowButtons,
    VxTableRowItem,
    VxTableRowTitle,
} from "@voxie/frontend-components";
import { useInterval } from '@vueuse/core';
import { computed } from "vue";
import { useToasted } from '~/composables/useToasted';
import automationRulesClient from '~/services/automationRulesClient';
import { HTTP_INTERNAL_SERVER_ERROR } from "~/support/HttpStatuses";
import { fromNow, shortDateTime } from '~/utils/date';
import { useAutomationRulesMetadata } from "./useAutomationRulesMetadata";

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

const { triggers } = useAutomationRulesMetadata();

const emit = defineEmits(["deleted", "update:modelValue"]);

const trigger = computed(() => {
    return triggers.value.find(trigger => trigger.value === props.modelValue.event)
})
const deleteAutomationRule = async () => {
    if (
        !window.confirm(
            "Are you sure? There is no undo."
        )
    ) {
        return;
    }

    try {
        await automationRulesClient.delete(teamId, props.modelValue.id);
        emit("deleted", props.modelValue.id);
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
        } else {
            toasted.error(e.response?.data?.message || 'Something went wrong.');
        }
    }
};

const updateActionsStatus = (status) => {
    if (!window.confirm(`Are you sure you want to turn ${status === 'published' ? 'on' : 'off'} all actions?`)) {
        return;
    }
    automationRulesClient.updateActionsStatus(teamId, props.modelValue.id, status)
        .then(() => {
            emit('update:modelValue', {
                ...props.modelValue,
                live_actions_count: status === 'published' ? props.modelValue.actions_count : 0,
            });
        }).catch((error) => {
            console.error(error);
            toasted.global.platform_error();
        })
}

const updatedAtKey = useInterval(60000);

const copyId = () => {
    navigator.clipboard.writeText(props.modelValue.id);
    toasted.success(`${props.modelValue.id} copied to clipboard.`)
};
</script>
