<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        size="md"
        :padding="false">
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6">
                Remove Flow
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">
                When a Flow is deleted all related resources will be deleted.
            </div>
        </template>

        <div class="vx-p-6">
            <vx-alert color="info"
                :closeable="false">Are you sure? When deleting this flow and it will also delete all relating resources.
                <strong>There is no undo</strong>.</vx-alert>
        </div>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="emit('update:visible', false)"
                    type="button"
                    class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                    size="lg"
                    color="muted">
                    Cancel
                </vx-button>
                <vx-button @click="confirm()"
                    data-test="confirm-delete"
                    :loading="deleting"
                    type="button"
                    class="vx-grow"
                    size="lg"
                    color="primary">
                    Confirm
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import { VxButton, VxModal, VxAlert } from '@voxie/frontend-components';
import { ref } from 'vue';
import flowsClient from '~/services/flowsClient';
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';
import { useToasted } from '~/composables/useToasted';

const teamId = Spark.state.currentTeam.id;
const toasted = useToasted();

const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
    flowId: {
        type: String,
        required: true,
    }
});

const emit = defineEmits(['update:visible', 'deleted']);
const deleting = ref(false);

const confirm = async () => {
    if (deleting.value) {
        return;
    }

    try {
        deleting.value = true;
        await flowsClient.delete(teamId, props.flowId);
        emit('update:visible', false);
        emit('deleted');
    } catch (e) {
        console.error(e);
        if (e?.response?.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
            return;
        }

        toasted.error(e.response?.data?.message || 'Something went wrong.');
    } finally {
        deleting.value = false;
    }
}

</script>
