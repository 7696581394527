export const NUMBER_TYPES = {
    local: 'Long Code',
    tollfree: 'Toll Free',
    shortcode: 'Short Code',
    hosted: 'Hosted',
};

export const numberTypesOptions = () =>
    Object.keys(NUMBER_TYPES).reduce(
        (acc, key) => [
            ...acc,
            {
                label: NUMBER_TYPES[key],
                value: key,
            },
        ],
        []
    );
