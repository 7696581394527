<template>
    <div class="
        vx-bg-slate-700
        vx-flex
        vx-items-center
        vx-px-4
        vx-py-2
        vx-max-w-full
        sm:vx-px-6
        md:vx-px-8">
        <slot></slot>
    </div>
</template>
<script setup>
</script>
