<template>
    <modal
        v-model:visible="visible"
        id="organizations-invite-delete-confirm"
        title="Warning!"
        submit-text="Delete"
        close-text="Cancel"
        :lazy="true"
        :z-index="1050"
        @submit="confirm"
    >
        <p class="organizations-invite-delete-confirm__text">By Deleting this invite, the following will happen:</p>

        <ul class="organizations-invite-delete-confirm__list">
            <li>An email will be sent to the Invitee explaining the invite is no longer valid</li>
            <li>The email account will be able to be invited again</li>
            <li>The record will be erased</li>
        </ul>
    </modal>
</template>
<script>
import { organizationsClient } from '../../../../services';
import Modal from '../../../general/Modal.vue';
export default {
    components: {
        Modal,
    },
    props: {
        invite: {
            type: Object,
            default: null,
        },
        team: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            visible: false,
        };
    },
    watch: {
        invite: function (invite) {
            this.visible = invite === null ? false : true;
        },
    },
    methods: {
        confirm() {
            organizationsClient
                .deleteInvite(this.team.id, this.invite.id)
                .then(() => {
                    this.$toasted.success('Invite deleted succesfully.');
                    this.$emit('deleted', this.invite);
                })
                .catch((err) => {
                    console.error(err);
                    this.$toasted.global.platform_error();
                });
        },
    },
};
</script>

<style lang="postcss" scoped>
.organizations-invite-delete-confirm__text {
    margin-bottom: 1rem;
}

.organizations-invite-delete-confirm__list {
    list-style-type: disc;
    padding-inline-start: 1rem;
    font-size: 0.875rem;
}

.organizations-invite-delete-confirm__list>li {
    margin-bottom: 1rem;
}
</style>
