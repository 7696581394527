<template>
    <div>
        <page-header>
            Phone Numbers
        </page-header>

        <team-phone-numbers-controls v-model="fetchParams"
            @apply="applyFilters"
            @reset="resetFilters"
            @show-host-number-modal="showHostNumberModal"
            :loading="loading"
            class="vx-mb-12"></team-phone-numbers-controls>

        <template v-if="!loading && !list.length">
            <vx-box color="info"
                class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                No Phone Numbers yet.
                <vx-button v-if="isImpersonatingOrDeveloper" :href="`/settings/teams/${teamId}/phone-numbers/new`">
                    Add Phone Number
                    <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
            </vx-box>
        </template>

        <vx-table v-else-if="list.length"
            :class="{
                'xl:vx-grid-cols-6-auto': !FEATURES.contact_groups,
                'xl:vx-grid-cols-7-auto': FEATURES.contact_groups,
            }"
            rowBreakpoint="xl">
            <vx-table-row isHeader>
                <div>
                    Number
                </div>
                <div>
                    Number Type
                </div>
                <div>
                    Country
                </div>
                <div>
                    Voice
                </div>
                <div>
                    Sending
                </div>
                <div v-if="FEATURES.contact_groups">
                    Group
                </div>
            </vx-table-row>

            <team-phone-number-row v-for="(item, index) in list"
                v-model="list[index]"
                :key="list[index].id"
                @deleted="removeFromList($event)"></team-phone-number-row>
        </vx-table>

        <div v-if="list.length"
            class="vx-mt-8 vx-flex vx-justify-between vx-w-full vx-gap-10">
            <vx-button v-if="fetchParams.cursor"
                @click="loadData()"
                color="muted-dark"
                size="xl"
                data-test="load-more-btn"
                class="vx-grow-0"
                :loading="loading">
                Load More
            </vx-button>
        </div>

        <host-phone-number-modal v-model:visible="visibleHostNumberModal" @save="onHostNumberSave"></host-phone-number-modal>
    </div>
</template>

<script setup>
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import PageHeader from '../../../general/PageHeader.vue';
import { VxBox, VxButton, VxTable, VxTableRow } from '@voxie/frontend-components';
import { onMounted, ref } from 'vue';
import { useCursorPagination } from '../../../../composables/useCursorPagination';
import TeamPhoneNumberRow from './TeamPhoneNumberRow.vue';
import TeamPhoneNumbersControls from './TeamPhoneNumbersControls.vue';
import phoneNumbersClient from '~/services/phoneNumbersClient';
import HostPhoneNumberModal from './HostPhoneNumberModal.vue';
import { inject } from 'vue';

const FEATURES = inject('FEATURES')

const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const teamId = Spark.state.currentTeam.id;
const isImpersonatingOrDeveloper = Spark.isImpersonating || Spark.isDeveloper;

const visibleHostNumberModal = ref(false);

const defaultFilters = () => ({
    per_page: 15,
    cursor: null,
    sort_field: 'id',
    sort_dir: 'desc'
});

const resetFilters = () => {
    fetchParams.value = defaultFilters();

    loadData();
};

const applyFilters = () => {
    fetchParams.value = {
        ...fetchParams.value,
        cursor: null,
    }

    loadData();
}

onMounted(() => {
    resetFilters();
});

const loadData = () => {
    fetchRecords(phoneNumbersClient.filterPhoneNumbers(teamId, { ...fetchParams.value }));
};

const removeFromList = (itemId) => {
    list.value = list.value.filter((item) => item.id != itemId);

    if (!list.value.length) {
        loadData();
    }
};

const showHostNumberModal = () => {
    visibleHostNumberModal.value = true;
};

const onHostNumberSave = () => {
    showHostNumberModal.value = false;
    fetchParams.value.cursor = null;

    loadData();
};

</script>
