<template>
    <div :class="['vx-font-sans vx-flex vx-flex-col', {
        'vx-items-end': !inbound,
        'vx-items-start': inbound,
    }]">

        <div v-if="props.message.media_url"
            :class="[bodyClasses, 'vx-mb-2']">
            <message-cloud-media v-if="props.message.media_url"
                :media-url="props.message.media_url"
                :media-content-type="props.message.media_content_type">
            </message-cloud-media>
        </div>
        <div v-if="props.message.body"
            :class="bodyClasses">
            {{ props.message.body }}
        </div>
        <div :class="['vx-text-slate-500 vx-text-xs vx-mt-1', {
            'vx-text-right': !inbound,
        }]">
            <slot name="info">
                <div v-if="inbound"
                    class="vx-text-slate-800">
                    {{ timeAmPm(message.created_at) }}
                </div>
                <template v-else-if="message.origin_meta?.type && message.origin_meta?.description">
                    <div>
                        {{ message.origin_meta?.type }} - {{ message.origin_meta?.description }}
                    </div>
                    <div class="vx-text-slate-800">
                        {{ timeAmPm(message.created_at) }}
                    </div>
                </template>
                <template v-else-if="message.origin_meta?.type">
                    {{ message.origin_meta?.type }} - {{ timeAmPm(message.created_at) }}
                </template>
                <div v-else
                    class="vx-text-slate-800">
                    {{ timeAmPm(message.created_at) }}
                </div>
            </slot>
        </div>
    </div>
</template>

<script setup>
import MessageCloudMedia from './MessageCloudMedia.vue';
import { computed } from 'vue';
import { timeAmPm } from '~/components/filters';

const props = defineProps({
    message: {
        type: Object,
        required: true,
    }
})


const inbound = computed(() => props.message.direction === 'inbound')

const bodyClasses = computed(() => ([
    'vx-max-w-screen-sm vx-rounded-lg vx-border vx-border-solid vx-py-3 vx-px-4 vx-text-sm vx-font-normal vx-text-slate-800',
    {
        'vx-rounded-br-[4px] vx-bg-sky-100 vx-border-sky-200': !inbound.value,
        'vx-rounded-bl-[4px] vx-bg-slate-100 vx-border-slate-200': inbound.value,
    }
]));

</script>
