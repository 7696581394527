<template>
    <div>
        <div class="vx-text-slate-700 vx-text-base vx-font-bold vx-mb-2">Name</div>

        <vx-input
            ref="inputRef"
            autofocus
            size="lg"
            :error="props.error"
            :modelValue="props.modelValue"
            @update:modelValue="emit('update:modelValue', $event)"></vx-input>
    </div>
</template>

<script setup>
import {
    VxInput,
} from "@voxie/frontend-components";
import { ref } from 'vue';

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    error: {
        type: String,
        default: undefined,
    },
});

const emit = defineEmits(['update:modelValue']);

const inputRef = ref();
const focus = () => inputRef.value?.field?.focus?.()

defineExpose({
    focus
})
</script>
