<template>
    <div>
        <div class="vx-hidden vx-ml-12 vx-mr-5 vx-pb-4 vx-grid-cols-12 vx-text-sm vx-font-semibold sm:vx-grid">
            <div class="vx-col-span-3">Type</div>
            <div class="vx-col-span-2">Sends</div>
            <div class="vx-col-span-3">Starts</div>
            <div class="vx-col-span-4">Ends</div>
        </div>
        <vx-radio-selection :disabled="props.disabled"
            :modelValue="props.modelValue"
            @update:modelValue="changeModelValue($event)">
            <vx-radio-selection-item value="one_time">
                <div :class="tableRow">
                    <div class="vx-col-span-3">One Time</div>
                    <div :class="[tableCell, 'vx-col-span-2']">
                        <span class="sm:vx-hidden">Sends:</span> Once
                    </div>
                    <div :class="[tableCell, 'vx-col-span-3']">
                        <span class="sm:vx-hidden">Starts:</span> On date selected
                    </div>
                    <div :class="[tableCell, 'vx-col-span-4']">
                        <span class="sm:vx-hidden">Ends:</span> After messages sends
                    </div>
                </div>
            </vx-radio-selection-item>
            <vx-radio-selection-item value="predefined">
                <div :class="tableRow">
                    <div class="vx-col-span-3">Duration</div>
                    <div :class="[tableCell, 'vx-col-span-2']">
                        <span class="sm:vx-hidden">Sends:</span> Continuously
                    </div>
                    <div :class="[tableCell, 'vx-col-span-3']">
                        <span class="sm:vx-hidden">Starts:</span> On date selected
                    </div>
                    <div :class="[tableCell, 'vx-col-span-4']">
                        <span class="sm:vx-hidden">Ends:</span> On date selected
                    </div>
                </div>
            </vx-radio-selection-item>
            <vx-radio-selection-item value="evergreen">
                <div :class="tableRow">
                    <div class="vx-col-span-3">Evergreen</div>
                    <div :class="[tableCell, 'vx-col-span-2']">
                        <span class="sm:vx-hidden">Sends:</span> Continuously
                    </div>
                    <div :class="[tableCell, 'vx-col-span-3']">
                        <span class="sm:vx-hidden">Starts:</span> On date selected
                    </div>
                    <div :class="[tableCell, 'vx-col-span-4']">
                        <span class="sm:vx-hidden">Ends:</span> Not until stopped
                    </div>
                </div>
            </vx-radio-selection-item>
        </vx-radio-selection>
        <vx-modal v-model:visible="confirmModal"
            :padding="false"
            size="md">

            <template v-slot:header>
                <div class="vx-py-6 vx-px-6">
                    Campaign Type
                </div>
            </template>
            <div class="vx-p-6">
                <vx-alert color="info" :closeable="false">
                    Are you sure? Changing the campaign type will mean contacts cannot be messaged more than once.
                </vx-alert>
            </div>

            <template v-slot:footer>
                <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                    <vx-button @click="cancelChange()"
                        type="button"
                        class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                        size="lg"
                        color="muted">
                        Cancel
                    </vx-button>
                    <vx-button @click="confirmChange()"
                        type="button"
                        class="vx-grow"
                        size="lg"
                        color="primary">
                        Confirm
                    </vx-button>
                </div>
            </template>

        </vx-modal>
    </div>
</template>
<script setup>
import { nextTick, ref } from 'vue'
import { VxAlert, VxButton, VxRadioSelection, VxRadioSelectionItem, VxModal } from '@voxie/frontend-components';

const props = defineProps({
    modelValue: String,
    isRepeatable: {
        type: Boolean,
        default: false,
    }
});

const tableCell = 'vx-text-xs vx-text-slate-500 vx-font-normal';
const tableRow = 'vx-block vx-grid-cols-12 vx-items-center vx-w-full sm:vx-grid';

const emit = defineEmits(['update:modelValue']);

const newModelValue = ref(props.modelValue);

const confirmModal = ref(false);

const changeModelValue = ($event) => {
    if (!props.isRepeatable || $event !== 'one_time') {
        emit('update:modelValue', $event);
        return;
    }
    newModelValue.value = $event;
    confirmModal.value = true;
}

const cancelChange = () => {
    confirmModal.value = false;
}

const confirmChange = async () => {
    emit('update:modelValue', newModelValue.value);
    await nextTick();
    emit('update:isRepeatable', false);
    confirmModal.value = false;
}

</script>
