<template>
    <vx-table-row data-test="segment-row">
        <vx-table-row-title class="md:!vx-col-span-full lg:!vx-col-auto">
            {{ segment.name }}
        </vx-table-row-title>

        <vx-table-row-item>
            <template v-slot:label> Contacts: </template>
            <font-awesome-icon
                v-if="contactsCount === null"
                :icon="faLoader"
                spin
            ></font-awesome-icon>
            <template v-else>
                {{ formatCount(contactsCount) }}
            </template>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Audience Type: </template>
            {{ segmentAudienceType }}
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Refresh Type: </template>
            <font-awesome-icon
                fixedWidth
                :icon="segment.type === 'static' ? faMapPin : faLoader"
            />
            {{ segmentRefreshType }}
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Last Refreshed: </template>

            <span :class="lastRefreshedColor" data-test="segment-last-refreshed">
                <font-awesome-icon
                    v-if="loading || segmentStatus === null"
                    :icon="faLoader"
                    spin
                ></font-awesome-icon>
                <template v-else>
                    <span v-if="isStatic">
                        <template v-if="segmentStatus === 'processing'">
                            Processing
                        </template>
                        <template v-else>
                            <span :title="shortDateTime(lastRefreshedAt)" :key="lastRefreshedKey">{{
                                fromNow(lastRefreshedAt)
                            }}
                        </span>
                        </template>
                    </span>
                    <span v-else>
                        {{
                            segmentStatus === "processing"
                                ? "Processing"
                                : "Now"
                        }}
                    </span>
                </template>
            </span>
        </vx-table-row-item>

        <vx-table-row-buttons>
            <segment-row-dropdown
                :segment="props.segment"
                @deleted="emit('deleted', $event)"
            ></segment-row-dropdown>
            <vx-button
                :href="`/contacts/segments/${props.segment.id}`"
                color="muted"
                outline
            >
                Edit <font-awesome-icon :icon="faFilePen" />
            </vx-button>
        </vx-table-row-buttons>
    </vx-table-row>
</template>

<script setup>
import {
    faFilePen,
    faLoader,
    faMapPin,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxButton,
    VxTableRow,
    VxTableRowItem,
    VxTableRowTitle,
    VxTableRowButtons,
} from "@voxie/frontend-components";
import dayjs from '~/utils/dayjs';
import { computed, onMounted, ref, watch } from 'vue';
import { audienceTypeLabel } from '../../../constants/campaign';
import { SegmentType, SegmentTypeToLabel } from '../../../constants/segment';
import segmentsClient from '../../../services/segmentsClient';
import { fromNow, shortDateTime } from '../../../utils/date';
import { formatCount } from '../../filters';
import SegmentRowDropdown from './SegmentRowDropdown.vue';
import { useInterval } from '@vueuse/core';

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    segment: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(["deleted"]);

const loading = ref(false);

const contactsCount = ref(null);
const segmentStatus = ref(null);

const lastRefreshedAt = ref(null);

const lastRefreshedColor = computed(() => {
    if (!lastRefreshedAt.value) {
        return null;
    }

    if (
        segmentStatus.value === "complete" &&
        props.segment.type === SegmentType.AUTO_REFRESH
    ) {
        return "vx-text-emerald-700";
    }

    const diffInDays = dayjs().diff(lastRefreshedAt.value, "days");

    if (diffInDays <= 1) {
        return "vx-text-emerald-700";
    }

    if (diffInDays <= 5) {
        return "vx-text-amber-600";
    }

    return "vx-text-rose-600";
});

const isStatic = computed(() => props.segment.type === SegmentType.STATIC);

const segmentRefreshType = computed(
    () => SegmentTypeToLabel[props.segment.type]
);

const segmentAudienceType = computed(() =>
    audienceTypeLabel(props.segment.audience_type)
);

// used when Laravel Echo updates in real time
watch(
    () => props.segment.contacts_count,
    (newCount) => {
        if (newCount === undefined) {
            return
        }
        contactsCount.value = newCount;
    }
, {
    immediate: true,
});

watch(
    () => props.segment.last_refreshed_at,
    (newLastRefreshedAt) => {
        lastRefreshedAt.value = newLastRefreshedAt;
        segmentStatus.value =
            !lastRefreshedAt.value ? "processing" : "complete";
    }
, {
    immediate: true,
});

const loadData = async () => {
    try {
        loading.value = true;
        const segmentContactsCountData = await segmentsClient.getSegmentContactsCount(props.segment.id, teamId);
        contactsCount.value = segmentContactsCountData.data.count;

        const segmentStatusData = await segmentsClient.getSegmentStatus(props.segment.id, teamId);
        lastRefreshedAt.value = segmentStatusData.data.last_refreshed_at;
        segmentStatus.value = segmentStatusData.data.status;
    } catch (error) {
        console.error(error);
    } finally {
        loading.value = false;
    }
}

const lastRefreshedKey = useInterval(60000);

onMounted(() => {
    loadData();
});
</script>
