import BaseClient from './BaseClient';

class AttributionClient extends BaseClient {
    getConfig(teamId) {
        const url = `/api/v3/teams/${teamId}/attribution/config`;
        return this.makeCoreApiRequest(url);
    }

    storeConfig(teamId, data) {
        const url = `/api/v3/teams/${teamId}/attribution/config`;
        return this.makeCoreApiRequest(url, 'post', data);
    }
}

export default new AttributionClient();
