<template>
    <div>
        <div class="card card-default">
            <div class="card-header">
                Team Members ({{ team.users.length }})
            </div>

            <div class="table-responsive">
                <table class="table table-valign-middle mb-0">
                    <thead>
                        <tr>
                            <th class="th-fit"></th>
                            <th>Name</th>
                            <th v-if="roles.length > 1">Role</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="member in team.users" :key="member.id">
                            <!-- Photo -->
                            <td>
                                <img :src="member.photo_url" class="spark-profile-photo" alt="Member Photo" />
                            </td>

                            <!-- Name -->
                            <td>
                                <span v-if="member.id === user.id">
                                    You
                                </span>

                                <span v-else>
                                    {{ member.name }}
                                </span>
                            </td>

                            <!-- Role -->
                            <td v-if="roles.length > 0">
                                {{ teamMemberRole(member) }}
                            </td>

                            <td class="td-fit">
                                <button class="btn btn-outline-primary" @click="editTeamMember(member)" v-if="roles.length > 1 && canEditTeamMember(member)">
                                    <i class="fa fa-cog"></i>
                                </button>

                                <button class="btn btn-outline-danger" @click="approveTeamMemberDelete(member)" v-if="canDeleteTeamMember(member)">
                                    <i class="fa fa-remove"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Update Team Member Modal -->
        <div class="modal" id="modal-update-team-member" tabindex="-1" role="dialog">
            <div class="modal-dialog" v-if="updatingTeamMember">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Edit Team Member ({{ updatingTeamMember.name }})
                        </h5>
                    </div>

                    <div class="modal-body">
                        <!-- Update Team Member Form -->
                        <form role="form">
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label text-md-right">
                                    Role
                                </label>

                                <div class="col-md-6">
                                    <select class="form-control" v-model="updateTeamMemberForm.role" :class="{'is-invalid': updateTeamMemberForm.errors.has('role')}">
                                        <option v-for="(role, index) in roles" :key="index" :value="role.value">
                                            {{ role.text }}
                                        </option>
                                    </select>

                                    <span class="invalid-feedback" v-if="updateTeamMemberForm.errors.has('role')">
                                        {{ updateTeamMemberForm.errors.get('role') }}
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>

                        <button type="button" class="btn btn-primary" @click="update" :disabled="updateTeamMemberForm.busy">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete Team Member Modal -->
        <div class="modal" id="modal-delete-member" tabindex="-1" role="dialog">
            <div class="modal-dialog" v-if="deletingTeamMember">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Remove Team Member ({{ deletingTeamMember.name }})
                        </h5>
                    </div>

                    <div class="modal-body">
                        Are you sure you want to remove this team member?
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">No, Go Back</button>

                        <button type="button" class="btn btn-danger" @click="deleteMember" :disabled="deleteTeamMemberForm.busy">
                            Yes, Remove
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '~/services/axios';

export default {
    props: ['user', 'team'],

    /**
     * The component's data.
     */
    data() {
        return {
            roles: [],

            updatingTeamMember: null,
            deletingTeamMember: null,

            updateTeamMemberForm: $.extend(true, new SparkForm({
                role: ''
            }), Spark.forms.updateTeamMember),

            deleteTeamMemberForm: new SparkForm({})
        }
    },


    /**
     * The component has been created by Vue.
     */
    created() {
        this.getRoles();

        Bus.$on(`sparkFormUpdated.${this.updateTeamMemberForm.sparkFormId}`, this.$forceUpdate)
        Bus.$on(`sparkFormUpdated.${this.deleteTeamMemberForm.sparkFormId}`, this.$forceUpdate)
    },


    computed: {
        /**
         * Get the URL for updating a team member.
         */
        urlForUpdating: function () {
            return `/settings/teams/${this.team.id}/members/${this.updatingTeamMember.id}`;
        },


        /**
         * Get the URL for deleting a team member.
         */
        urlForDeleting() {
            return `/settings/teams/${this.team.id}/members/${this.deletingTeamMember.id}`;
        },
    },


    methods: {
        /**
         * Get the available team member roles.
         */
        getRoles() {
            axios.get(`/settings/teams/roles`)
                .then(response => {
                    this.roles = response.data;
                });
        },


        /**
         * Edit the given team member.
         */
        editTeamMember(member) {
            this.updatingTeamMember = member;
            this.updateTeamMemberForm.role = member.pivot.role;

            $('#modal-update-team-member').modal('show');
        },


        /**
         * Update the team member.
         */
        update() {
            Spark.put(this.urlForUpdating, this.updateTeamMemberForm)
                .then(() => {
                    Bus.$emit('updateTeam');

                    $('#modal-update-team-member').modal('hide');
                });
        },


        /**
         * Display the approval modal for the deletion of a team member.
         */
        approveTeamMemberDelete(member) {
            this.deletingTeamMember = member;

            $('#modal-delete-member').modal('show');
        },


        /**
         * Delete the given team member.
         */
        deleteMember() {
            Spark.delete(this.urlForDeleting, this.deleteTeamMemberForm)
                .then(() => {
                    Bus.$emit('updateTeam');

                    $('#modal-delete-member').modal('hide');
                });
        },


        /**
         * Determine if the current user can edit a team member.
         */
        canEditTeamMember(member) {
            return this.user.id === this.team.owner.id && this.user.id !== member.id
        },


        /**
         * Determine if the current user can delete a team member.
         */
        canDeleteTeamMember(member) {
            return this.user.id === this.team.owner.id && this.user.id !== member.id
        },


        /**
         * Get the displayable role for the given team member.
         */
        teamMemberRole(member) {
            if (this.roles.length === 0) {
                return '';
            }

            if (member.pivot.role === 'owner') {
                return 'Owner';
            }

            const role = this.roles.find((role) => role.value == member.pivot.role);

            if (typeof role !== 'undefined') {
                return role.text;
            }
        }
    }
};
</script>
