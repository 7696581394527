<template>
    <vx-modal :visible="props.visible" @update:visible="updateVisible($event)" class="vx-max-w-lg">
        <template v-slot:header>Add Hosted Number</template>
        <div class="vx-text-sm vx-pb-4">Enter the Twilio phone number ID of the hosted number.</div>

        <div class="vx-p-1 vx-mb-3">
            <vx-input data-test="vendor-indentity" type="text" v-model="vendorId" size="lg"
                placeholder="PN5f1da2c3sedfabc1234ae12fv11fe12a">
            </vx-input>
            <span v-for="error in errors" :key="error" data-test="errors" class="vx-text-xs vx-text-rose-500">
                {{ error }}
            </span>
        </div>

        <div class="vx-flex vx-space-x-2 vx-justify-center">
            <vx-button @click="submit" data-test="submit-button" type="button" size="lg" color="primary"
                class="vx-flex-grow" :loading="loading">
                Save
            </vx-button>
        </div>
    </vx-modal>
</template>
<script setup>
import { ref } from 'vue';
import { useToasted } from '~/composables/useToasted';
import { VxButton, VxInput, VxModal } from '@voxie/frontend-components';
import { adminPhoneNumbersClient } from '~/services';
import { HTTP_BAD_REQUEST, HTTP_UNPROCESSABLE_ENTITY } from '~/support/HttpStatuses';

const $toasted = useToasted();

const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    }
});

const emit = defineEmits(['update:visible', 'save']);

const teamId = Spark.state.currentTeam.id;
const loading = ref(false);
const vendorId = ref(undefined);
const errors = ref([]);

const updateVisible = (isVisible) => {
    vendorId.value = undefined;
    errors.value = [];
    emit('update:visible', isVisible);
}

const submit = async () => {
    if (loading.value) {
        return;
    }

    errors.value = [];

    if (!vendorId.value?.startsWith('PN')) {
        errors.value.push('Vendor ID should start with PN');
        return;
    }

    loading.value = true;

    try {
        await adminPhoneNumbersClient.host(teamId, {
            vendor_identity: vendorId.value,
        });
        emit('save');
    } catch (error) {
        const statusCode = error?.response?.status;
        if (statusCode === HTTP_UNPROCESSABLE_ENTITY) {
            const serverErrors = error?.response?.data?.errors;
            if (serverErrors) {
                errors.value = Object.values(serverErrors).flat();
            }
        } else if (statusCode === HTTP_BAD_REQUEST) {
            const serverError = error?.response?.data?.message;
            if (serverError) {
                errors.value.push(serverError);
            }
        } else {
            console.error(error);
            $toasted.global.platform_error();
        }
    } finally {
        loading.value = false;
    }
}
</script>
