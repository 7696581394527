<script setup>
import {
  faMessages,
  faUsers,
  faCirclePlus,
  faRobot,
} from '@fortawesome/pro-solid-svg-icons'
import SvgNode from './SvgNode.vue'
import SvgNodeIconOnly from './SvgNodeIconOnly.vue'
import { vxSvg } from '@voxie/frontend-components'
import ArtNoodle from './ArtNoodle.vue'
import { svgWidthLg, svgWidthSm, classNameLg, classNameSm } from './artVars'

const pathLg = [
  { marker: 'M', values: { x: 80, y: 63 } },
  { marker: 'L', values: { x: svgWidthLg * 0.65, y: 63 } },
  { marker: 'L', values: { x: svgWidthLg * 0.65, y: 20 } },
  { marker: 'L', values: { x: svgWidthLg - 80, y: 20 } },
  { marker: 'M', values: { x: svgWidthLg * 0.5, y: 63 } },
  { marker: 'L', values: { x: svgWidthLg * 0.65, y: 63 } },
  { marker: 'L', values: { x: svgWidthLg * 0.65, y: 106 } },
  { marker: 'L', values: { x: svgWidthLg - 140, y: 106 } },
]
const pathLgRounded = () => {
  return vxSvg.roundCommands(pathLg, 20, 2)?.path
}

const pathSm = [
  { marker: 'M', values: { x: 40, y: 42 } },
  { marker: 'L', values: { x: svgWidthSm * 0.65, y: 42 } },
  { marker: 'L', values: { x: svgWidthSm * 0.65, y: 17 } },
  { marker: 'L', values: { x: svgWidthSm - 40, y: 17 } },
  { marker: 'M', values: { x: svgWidthSm * 0.5, y: 42 } },
  { marker: 'L', values: { x: svgWidthSm * 0.65, y: 42 } },
  { marker: 'L', values: { x: svgWidthSm * 0.65, y: 67 } },
  { marker: 'L', values: { x: svgWidthSm - 40, y: 67 } },
]
const pathSmRounded = () => {
  return vxSvg.roundCommands(pathSm, 12, 2)?.path
}
</script>

<template>
  <svg
    :viewBox="`0 0 ${svgWidthLg} 128`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    :width="svgWidthLg"
    :class="classNameLg"
  >
    <art-noodle :d="pathLgRounded()" animated />

    <!-- nodes -->

    <svg-node
      :x="34"
      :y="41"
      :icon="faUsers"
      label="Contact List"
      fill="vx-fill-violet-300"
    ></svg-node>
    <svg-node
      :x="235"
      :y="41"
      :icon="faMessages"
      label="Ask Question"
      fill="vx-fill-sky-300"
    ></svg-node>

    <svg-node
      :x="463"
      :y="0"
      :icon="faRobot"
      label="Save Answer"
      fill="vx-fill-amber-300"
    ></svg-node>

    <svg-node
      :x="463"
      :y="86"
      :icon="faRobot"
      fill="vx-fill-amber-300"
    ></svg-node>
  </svg>

  <svg
    :viewBox="`0 0 ${svgWidthSm} 84`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    :width="svgWidthSm"
    :class="classNameSm"
  >
    <art-noodle :d="pathSmRounded()" />

    <!-- nodes -->
    <svg-node-icon-only
      :x="24"
      :y="24"
      :icon="faUsers"
      fill="vx-fill-violet-300"
    ></svg-node-icon-only>
    <svg-node-icon-only
      :x="svgWidthSm * 0.38"
      :y="24"
      :icon="faMessages"
      fill="vx-fill-sky-300"
    ></svg-node-icon-only>

    <svg-node-icon-only
      :x="svgWidthSm - 48 - 24"
      :y="0"
      :icon="faRobot"
      fill="vx-fill-amber-300"
    ></svg-node-icon-only>

    <!-- row -->
    <svg-node-icon-only
      :x="svgWidthSm - 48 - 24"
      :y="50"
      :icon="faCirclePlus"
      fill="vx-fill-amber-300"
    ></svg-node-icon-only>
  </svg>
</template>
