<template>
    <div>
        <page-header>Teams</page-header>

        <vx-input ref="inputRef"
            class="vx-shadow"
            size="lg"
            placeholder="Search for a team"
            v-model="query"
            maxlength="191"
            @update:modelValue="search()"
            @keyup.enter="search()"
            ></vx-input>


        <vx-box color="info"
            v-if="!loading && !list.length"
            class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
            No teams found.
        </vx-box>
        <div>
            <vx-table class="xl:vx-grid-cols-[repeat(5,auto)]"
                rowBreakpoint="xl">
                <vx-table-row v-if="list.length || loading" isHeader>
                    <div>
                        Name
                    </div>
                    <div>
                        Type
                    </div>
                    <div>
                        Status
                    </div>
                    <div>
                        Parent
                    </div>
                </vx-table-row>
                <div v-if="loading"
                    class="vx-col-span-5 vx-py-10 vx-text-center">
                    <vx-icon-loading class="vx-w-9 vx-h-9"
                        spin></vx-icon-loading>
                </div>

                <vx-table-row v-show="!loading" v-for="team in list"
                    :key="team.id">
                    <vx-table-row-title>
                        <div class="vx-flex">
                            <vx-badge class="vx-mr-2 vx-cursor-pointer"
                                @click.prevent="copyText(team.id)"
                                size="xs"
                                color="slate">{{ team.id }}</vx-badge>
                            <div class="vx-line-clamp-none vx-break-all lg:vx-line-clamp-2">
                                {{ team.name }}
                            </div>
                        </div>
                    </vx-table-row-title>

                    <vx-table-row-item>
                        <template v-slot:label> Type: </template>
                        {{ organizationType(team.organization_type) }}
                    </vx-table-row-item>
                    <vx-table-row-item>
                        <template v-slot:label> Status: </template>
                        <span class="vx-capitalize">
                            {{ team.status }}
                        </span>
                    </vx-table-row-item>
                    <vx-table-row-item>
                        <template v-slot:label> Parent: </template>

                        <div v-if="team.parent_id" class="vx-flex">
                            <vx-badge class="vx-mr-2 vx-cursor-pointer"
                                @click.prevent="copyText(team.parent_id)"
                                size="xs"
                                color="slate">{{ team.parent_id }}</vx-badge>
                            <div class="vx-line-clamp-none vx-break-all lg:vx-line-clamp-2">
                                {{ team.parent_name }}
                            </div>
                        </div>
                        <div v-else>-</div>

                    </vx-table-row-item>

                    <vx-table-row-buttons>
                        <vx-button v-if="team.owner_id"
                            :href="`/spark/kiosk/users/impersonate/${team.owner_id}?team_id=${team.id}`"
                            block
                            color="muted"
                            :disabled="team.owner_id === currentUserId || team.status === 'closed'"
                            outline>
                            Impersonate Owner <font-awesome-icon :icon="faMasksTheater" />
                        </vx-button>
                    </vx-table-row-buttons>
                </vx-table-row>
            </vx-table>

        </div>
    </div>
</template>

<script setup>
import { faMasksTheater } from '@fortawesome/pro-solid-svg-icons';
import { VxBadge, VxBox, VxIconLoading, VxInput, VxTable, VxTableRow, VxTableRowButtons, VxTableRowItem, VxTableRowTitle, VxButton } from '@voxie/frontend-components';
import { onMounted, ref } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';
import { useToasted } from '~/composables/useToasted';
import axios from '~/services/axios';
import debounce from '~/utils/debounce';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { stringifyQueryString } from '~/utils/string';

const currentUserId = Spark.state.user.id;
const toasted = useToasted();
const query = ref('');
const loading = ref(false);
const list = ref([]);

const copyText = (text) => {
    navigator.clipboard.writeText(text);
    toasted.success(`${text} copied to clipboard.`)
};

const search = debounce(async () => {
    try {
        loading.value = true;
        const response = await axios.get('/spark/kiosk/teams/search?' + stringifyQueryString({ query: query.value }));
        list.value = response.data;
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }

}, 500);

const organizationType = (type) => {
    const types = {
        'brand': 'Brand',
        'online_store': 'Online Store',
        'retail_location': 'Retail Location',
    }

    return types[type] || 'Legacy';
}

const inputRef = ref(null);

onMounted(() => {
    inputRef.value.field.focus();
})
</script>
