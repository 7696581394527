<template>
    <tr class="c-table__row">

        <td class="c-table__cell">
            <span class="u-block">{{ phoneNumberPool.friendly_name }}</span>
            <span class="u-block u-text-mute u-text-xsmall">
                Created: {{ phoneNumberPoolCreatedAt }}
            </span>
        </td>

        <td class="c-table__cell center">
            <span class="u-block huge-text center">
                {{ phoneNumberPool.phone_numbers_count }}
            </span>
        </td>

        <td v-if="isImpersonatingOrDeveloper" class="c-table__cell center">

            <dropdown-actions label="Actions" :updateEvent="'phone_number_poolsPending-'+phoneNumberPool.id">

                <!--
                <dropdown-action-item :route="'#/show/' + phoneNumberPool.id" event="phone_number_poolsShow" :payload="phoneNumberPool.id">View</dropdown-action-item>
                -->

                <dropdown-action-item :route="'#/edit/' + phoneNumberPool.id" event="phone_number_poolsEdit" :payload="phoneNumberPool.id">Edit</dropdown-action-item>

                <dropdown-action-item :route="'#/phone_numbers/' + phoneNumberPool.id" event="phone_number_poolsManageNumbers" :payload="phoneNumberPool.id">
                    Manage Numbers
                </dropdown-action-item>

                <dropdown-action-item event="phone_number_poolsDelete" :payload="phoneNumberPool.id"
                                      message="Are you sure? This phone pool will be deleted and any associated messaging configurations will be deactivated. There is no undo.">
                    Delete
                </dropdown-action-item>

            </dropdown-actions>

        </td>
    </tr>
</template>

<script>
    import DropdownActionItem from '~/components/general/DropDownActionItem.vue';
    import DropdownActions from '~/components/general/DropDownActions.vue';
    import { shortDateTime } from '../../utils/date';

    export default {
        props: ['phoneNumberPool'],
        components: {
            DropdownActionItem,
            DropdownActions,
        },
        computed: {
            phoneNumberPoolCreatedAt() {
                return shortDateTime(this.phoneNumberPool.created_at);
            },
            isImpersonatingOrDeveloper() {
                return Spark.isImpersonating || Spark.isDeveloper;
            },
        },
    }
</script>
