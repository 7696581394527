<template>
    <vx-expandable-card>
        <template v-slot:header> Group </template>

        <template v-slot:content>
            <vx-input
                :modelValue="props.modelValue"
                @update:modelValue="emit('update:modelValue', $event)"
                size="lg"
                placeholder="Group"
                :disabled="props.disabled"
            ></vx-input>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxExpandableCard, VxInput } from '@voxie/frontend-components';

const props = defineProps({
    modelValue: {
        type: [String, null],
        required: true,
    },
    disabled: {
        type: Boolean,
    },
});

const emit = defineEmits(["update:modelValue"]);
</script>
