<template>
    <vx-table-row :class="{
        '!vx-border-rose-300 vx-bg-rose-50': setupRequired,
    }">
        <vx-table-row-title>
            <vx-badge v-if="setupRequired" color="rose" size="xs">Setup Required <font-awesome-icon :icon="faAsterisk" class="vx-text-rose-500" /></vx-badge>
            <template v-else>
                {{ props.modelValue.name }}
            </template>
        </vx-table-row-title>

        <vx-table-row-item>
            <template v-slot:label> External ID: </template>
            <span class="vx-break-all">{{ props.modelValue.group_id }}</span>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Users: </template>
            {{ usersCount }}
        </vx-table-row-item>

        <vx-table-row-buttons v-if="isAdminOrOwner">
            <vx-dropdown placement="right">
                <vx-button data-test="dropdown"
                    type="button"
                    :color="setupRequired ? 'danger' : 'muted'"
                    outline
                    class="vx-w-[45px]">
                    <font-awesome-icon :icon="faEllipsis" />
                </vx-button>

                <template v-slot:items>
                    <vx-dropdown-item data-test="delete"
                        @click="deleteGroup()">
                        Delete
                        <font-awesome-icon :icon="faTrash"></font-awesome-icon>
                    </vx-dropdown-item>
                </template>
            </vx-dropdown>

            <vx-button :href="`/settings/teams/${teamId}/groups/${props.modelValue.group_id}`"
                data-test="edit"
                :color="!props.modelValue.name ? 'danger' : 'muted'"
                :outline="!setupRequired">
                Edit <font-awesome-icon :icon="faFilePen" />
            </vx-button>
        </vx-table-row-buttons>

    </vx-table-row>
</template>

<script setup>
import {
    faAsterisk,
    faEllipsis,
    faFilePen,
    faTrash
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxBadge,
    VxButton,
    VxDropdown,
    VxDropdownItem,
    VxTableRow,
    VxTableRowButtons,
    VxTableRowItem,
    VxTableRowTitle
} from "@voxie/frontend-components";
import { computed } from "vue";
import { ref } from "vue";
import { useToasted } from '~/composables/useToasted';
import groupsClient from "~/services/groupsClient";
import { HTTP_INTERNAL_SERVER_ERROR } from "~/support/HttpStatuses";

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;

const isAdminOrOwner = Spark.isAdminOrOwner;

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});
const usersCount = ref('-')

const setupRequired = computed(() => !props.modelValue.name?.length)

const emit = defineEmits(['deleted']);

const deleteGroup = async () => {
    if (!window.confirm('Are you sure? There is no undo.')) return;

    try {
        await groupsClient.deleteGroup(teamId, props.modelValue.group_id);
        emit('deleted', props.modelValue.group_id);
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
        } else {
            toasted.error(e.response?.data?.message || 'Something went wrong.');
        }
    }
};

</script>
