<template>
    <vx-expandable-card>
        <template v-slot:header>
            <div v-if="requiredLabel" class="mb-2">
                <vx-badge color="rose" size="xs">
                    Required <font-awesome-icon class="vx-text-rose-500" :icon="faAsterisk"></font-awesome-icon>
                </vx-badge>
            </div>
            External ID </template>
        <template v-slot:content>
            <vx-input
                data-test="external-id-input"
                autofocus
                :disabled="disabled"
                :modelValue="props.modelValue"
                @update:modelValue="emit('update:modelValue', $event)"
                size="lg"
                placeholder="External ID"
                :error="props.error">
                <template v-if="locked" v-slot:left>
                    <font-awesome-icon size="sm" :icon="faLock" class="vx-mx-2"></font-awesome-icon>
                </template>
            </vx-input>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxBadge, VxExpandableCard, VxInput } from '@voxie/frontend-components';
import { faAsterisk, faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
        default: '',
    },
    error: {
        type: [String, Array],
    },
    requiredLabel: {
        type: Boolean,
        default: false,
        required: false,
    },
    locked: {
        type: Boolean,
        default: false,
        required: false,
    },
    disabled: {
        type: Boolean,
        default: false,
        required: false,
    }
});

const emit = defineEmits(['update:modelValue']);
</script>
