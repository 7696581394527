<template>
    <auth-box class="vx-max-w-[480px]">
        <div v-if="error" class="vx-flex vx-flex-col vx-gap-1 vx-pt-10 vx-text-center">
            <font-awesome-icon :icon="faWarning" class="vx-text-rose-500" size="2x"></font-awesome-icon>
            <div class="vx-text-xl vx-font-extrabold">
                Error
            </div>
            <div class="vx-text-sm vx-text-slate-500">
                {{ error }}
            </div>
            <div class="vx-pt-8">
                <vx-button block color="muted" size="lg" href="/login">
                    Log In Options <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>
            </div>
        </div>
        <form v-else @submit.prevent="checkWorkspace()"
            class="vx-flex vx-flex-col vx-gap-4">

            <div>
                <vx-label for="workspace_slug">
                    Workspace
                </vx-label>
                <vx-input required type="text" v-model="workspaceSlug" autofocus name="workspace_slug" id="workspace_slug" size="lg"></vx-input>
                <a class="vx-flex vx-items-center vx-mt-2 vx-underline vx-text-slate-600 hover:vx-text-slate-600 vx-text-xs" href="https://support.voxie.com" target="_blank" rel="noopener noreferrer">
                    <font-awesome-icon :icon="faCircleInfo" class="vx-mr-1"></font-awesome-icon>
                    What is my Workspace name?
                </a>
            </div>

            <div class="vx-flex vx-flex-col vx-gap-2 vx-mt-4 md:vx-flex-row-reverse">
                <vx-button :loading="submitting" class="md:vx-flex-grow" type="submit" color="primary" size="lg">
                   Continue <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>

                <vx-button color="muted" size="lg" href="/login">
                    Cancel
                </vx-button>
            </div>
        </form>
    </auth-box>
</template>
<script setup>
import AuthBox from '../../AuthBox.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxLabel, VxInput, VxButton } from '@voxie/frontend-components';
import { faArrowRight, faCircleInfo, faWarning } from '@fortawesome/pro-solid-svg-icons';
import { ref } from 'vue';
import axios from '~/services/axios';

const props = defineProps({
    errorMessage: String
})

const workspaceSlug = ref('');
const submitting = ref(false);

const error = ref(props.errorMessage);

const checkWorkspace = async () => {
    try {
        const response = await axios.get(`/sso/saml2/${encodeURIComponent(workspaceSlug.value)}/redirect`)

        if (response?.data?.redirect_url) {
            window.location.href = response.data.redirect_url;
        } else {
            error.value = 'An error occurred when trying to lookup your workspace.';
        }
    } catch (e) {
        error.value = 'An error occurred when trying to lookup your workspace.';
    } finally {
        submitting.value = false;
    }

}
</script>
