<template>
    <page-container class="vx-grid vx-grid-cols-12 vx-gap-8">
        <settings-sidebar></settings-sidebar>
        <div class="vx-col-span-12 md:vx-col-span-8 lg:vx-col-span-10">
            <page-header v-if="widget === 'edit'">{{ promotion.id ? 'Promotion Details' : 'Create Promotion' }}</page-header>
            <page-header v-else>Promotions</page-header>

            <promotion-show v-if="widget === 'show'" :promotion="promotion" />
            <promotion-form v-if="widget === 'edit'" :promotion="promotion" />

            <div v-if="widget === 'list'" class="list">
                <help-block id="broadcasts" type="success">
                    A promotion allows you to create coupon codes that you can assign to contacts at campaign message send time.
                </help-block>
                <table class="c-table resource-table">
                    <caption class="c-table__title">
                        <a ref="newBtn" @click.prevent="presentNewPromotionUI" class="pull-right c-btn c-btn--success"
                        href="#" data-router="client-route">
                            <i class="fa fa-plus u-text-small u-opacity-medium u-mr-xsmall"></i>New Promotion
                        </a>
                        <search v-if="!loadingCollection"
                                identity="promotions-search"
                                :searchUrl="searchUrl"
                                maxlength="256"
                                method="get" />
                    </caption>
                    <thead v-if="!loadingCollection" class="c-table__head c-table__head--slim">
                        <tr class="c-table__row">
                            <th class="c-table__cell c-table__cell--head center d-none d-sm-table-cell">ID</th>
                            <th class="c-table__cell c-table__cell--head tag-cell">Name</th>
                            <th class="c-table__cell c-table__cell--head center d-none d-md-table-cell">
                                Coupons <span class="text-nowrap">(used/total)</span>
                            </th>
                            <th class="c-table__cell c-table__cell--head center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="loadingCollection">
                            <td class="c-table__cell center" colspan="4">
                                <loading-resource />
                            </td>
                        </tr>

                        <tr v-else-if="loadingCollectionError">
                            <td class="c-table__cell center" colspan="4">
                                <alert-error
                                    text="Error loading promotions."
                                />
                            </td>
                        </tr>

                        <tr v-else-if="!collection.length">
                            <td class="c-table__cell center" colspan="4">
                                <alert-no-results />
                            </td>
                        </tr>

                        <template v-else>
                            <promotion-table-row
                                v-for="promotion in collection"
                                :key="promotion.id"
                                :promotion="promotion"
                            />
                        </template>
                    </tbody>
                </table>
                <pagination v-if="totalPages > 1" identity="promotions-pages" :totalPageCount="totalPages"
                            :nextPage="nextPageUrl" :prevPage="prevPageUrl" />
            </div>
        </div>
    </page-container>
</template>

<script>
import { promotionsClient } from '../../services';
import HelpBlock from '../general/HelpBlock.vue';
import Search from '../general/Search.vue';
import PromotionShow from './PromotionShow.vue';
import PromotionForm from './PromotionForm.vue';
import PromotionTableRow from './PromotionTableRow.vue';
import AlertNoResults from '../general/AlertNoResults.vue';
import AlertError from '../general/AlertError.vue';
import LoadingResource from '../general/LoadingResource.vue';
import { displayNotActiveModal, isNotActive } from '../../utils/team';
import Pagination from '../general/Pagination.vue';
import PageContainer from '~/components/general/PageContainer.vue';
import PageHeader from '~/components/general/PageHeader.vue';
import clientRoutes from './../../mixins/client-routes';
import SettingsSidebar from '~/components/settings/menus/SettingsSidebar.vue'

export default {
    mixins: [
        clientRoutes,
    ],
    components: {
    HelpBlock,
    Search,
    PromotionShow,
    PromotionForm,
    PromotionTableRow,
    AlertNoResults,
    AlertError,
    LoadingResource,
    Pagination,
    PageHeader,
    PageContainer,
    SettingsSidebar,
},
inject: ['FEATURES'],
    data() {
        return {
            teamId: Spark.state.currentTeam.id,
            loadingCollection: false,
            loadingCollectionError: false,
            promotion: null,
            collection: [],
            prevPageUrl: null,
            nextPageUrl: null,
            totalPages: 0,
            widget: 'list',
        };
    },
    mounted() {
        if(isNotActive()) {
            displayNotActiveModal()
        }
    },
    created() {
        this.getPromotions();

        Bus.$on('promotionShow', (promotion) =>{
            this.setPromotionAndWidget(promotion, 'show');
        });

        Bus.$on('promotionEdit', (promotion) => {
            this.setPromotionAndWidget(promotion, 'edit');
        });

        Bus.$on('showPromotionsList', () => {
            this.setPromotionAndWidget(null, 'list');
        });

        Bus.$on("promotionCreated", (data) => {
            this.collection.unshift(data);
        });

        Bus.$on("promotionUpdated", (data) => {
            const { collection } = this;
            const objIndex = collection.findIndex(obj => obj.id === data.id);
            if (objIndex >= 0) {
                collection[objIndex] = {
                    ...collection[objIndex],
                    ...data,
                };
            }

            if (this.promotion.id === data.id) {
                this.promotion = {...this.promotion, ...data};
            }
        });

        Bus.$on('promotionDeleted', (data) => {
            const objIndex = this.collection.findIndex(p => p.id === data.id);
            if (objIndex >= 0) {
                this.collection.splice(objIndex, 1);
            }
        });

        Bus.$on('promotions-pages-collection-updated', (total, data) => {
            this.collection = data;
        });

        Bus.$on('promotions-search-results', (data) => {
            this.loadSearchData(data);
        });

        Bus.$on('promotions-search-cancel', () => {
            this.getPromotions();
        });
    },
    computed: {
        searchUrl() {
            return `/api/v3/teams/${this.teamId}/promotions`;
        },
    },
    methods: {
        getPromotions() {
            this.loadingCollection = true;
            this.loadingCollectionError = false;

            // Get current page from URL hash.
            const route = this.currentClientRoute();
            const page = (route?.hash === 'promotions-pages') ? parseInt(route.parameters[0]) : 1;

            // Fetch promotions.
            promotionsClient.getAll(this.teamId, {
                page,
            }).then(({ data }) => {
                this.collection = data.data;
                this.currentPage = data.current_page;
                this.totalPages = data.last_page;
                this.prevPageUrl = data.prev_page_url;
                this.nextPageUrl = data.next_page_url;
            }).catch((error) => {
                this.$toasted.global.platform_error();
                console.log(error.response.data);
                this.loadingCollectionError = true;
            })
            .finally(() => {
                this.loadingCollection = false;
            });
        },
        presentNewPromotionUI() {
            Bus.$emit('promotionEdit', {
                name: '',
                code: '',
                coupon_vendor: '',
            });
        },
        setPromotionAndWidget(promotion, widget) {
            this.promotion = promotion;
            this.widget = widget;
        },
        loadSearchData(data) {
            this.collection = data.data;
            this.currentPage = data.current_page;
            this.totalPages = data.last_page;
            this.prevPageUrl = data.prev_page_url;
            this.nextPageUrl = data.next_page_url;
        },
    },
}
</script>
