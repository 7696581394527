import BaseClient from './BaseClient';

class InvitationsClient extends BaseClient {
    invitesCount(teamId, params) {
        const url = `/api/v3/teams/${teamId}/invitations/count`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    listInvites(teamId, params) {
        const url = `/api/v3/teams/${teamId}/invitations`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    sendInvite(teamId, params) {
        const url = `/api/v3/teams/${teamId}/invitations`;
        return this.makeCoreApiRequest(url, 'post', params);
    }

    resendInvite(teamId, invitationId) {
        const url = `/api/v3/teams/${teamId}/invitations/${invitationId}`;
        return this.makeCoreApiRequest(url, 'post');
    }

    deleteInvite(teamId, inviteId) {
        const url = `/api/v3/teams/${teamId}/invitations/${inviteId}`;
        return this.makeCoreApiRequest(url, 'delete');
    }
}

export default new InvitationsClient();
