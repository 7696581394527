<template>
    <page-container class="vx-grid vx-grid-cols-12 vx-gap-8">
        <settings-sidebar></settings-sidebar>
        <div ref="content" class="vx-col-span-12 md:vx-col-span-8 lg:vx-col-span-9">
            <profile-settings v-if="hash === 'profile' || !hash" :user="props.user"></profile-settings>

            <teams-settings v-if="hash === 'teams' && usesTeams" :user="props.user" :teams="props.teams"></teams-settings>

            <security-settings v-if="hash === 'security' && !props.user.isUsingSingleSignOn" :user="props.user" :uses-two-factor-auth="props.usesTwoFactorAuth" />

            <api-settings v-if="hash === 'api' && usesApi && !props.user.isUsingSingleSignOn"
                :production-or-staging="props.productionOrStaging"
                :developer="isImpersonatorOrDev"
                :available-abilities="availableAbilities"
            ></api-settings>

            <div v-if="isImpersonatorOrDev && hash?.startsWith('cloning')">
                <!-- Cloning Targets -->
                <div class="card card-default">
                    <div class="card-header">
                        <a style="color: black;display: block;" data-toggle="collapse" href="#cloning-targets-section" role="button" aria-expanded="false" aria-controls="cloning-targets-section">
                            <div class="vx-flex vx-items-center">
                                <span>Cloning Targets</span>
                            </div>
                        </a>
                    </div>

                    <div class="collapse" id="cloning-targets-section">
                        <cloning-targets></cloning-targets>
                    </div>
                </div>

                <!-- Clone teams -->
                <clone-teams></clone-teams>
            </div>
        </div>
    </page-container>
</template>

<script setup>
import PageContainer from '~/components/general/PageContainer.vue';
import ProfileSettings from '../profile/ProfileSettings.vue';
import TeamsSettings from '../teams/TeamsSettings.vue';
import SecuritySettings from '../security/SecuritySettings.vue';
import ApiSettings from '../api/ApiSettings.vue';
import CloneTeams from '../teams/CloneTeams.vue';
import { ref, computed, watch, nextTick } from 'vue';
import CloningTargets from '~/components/settings/teams/CloningTargets.vue';
import SettingsSidebar from './SettingsSidebar.vue';
import { useBrowserLocation } from '@vueuse/core'
import axios from '~/services/axios';

const props = defineProps({
    user: Object,
    teams: Object,
    usesTwoFactorAuth: Boolean,
    productionOrStaging: Boolean,
});

const usesTeams = Spark.usesTeams;
const usesApi = Spark.usesApi;
const isImpersonatorOrDev = Spark.isImpersonating || Spark.isDeveloper

const availableAbilities = ref([]);


axios.get('/settings/api/token/abilities')
    .then(response => availableAbilities.value = response.data);

const location = useBrowserLocation();

const hash = computed(() => location.value.hash?.replace('#/', '').replace('#', ''))

if (!hash.value) {
    location.value.hash = '#/profile';
}

const content = ref();
watch(hash, async () => {
    await nextTick();
    if (!content.value || window.innerWidth >= 768) {
        return;
    }
    content.value.scrollIntoView({ behavior: 'smooth', block: 'start' });
}, { immediate: true })

</script>
