<template>
    <div v-if="loading || !triggers.length || !actions.length"
        class="vx-text-center vx-py-10">
        <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
    </div>
    <div v-else class="vx-space-y-8 md:vx-space-y-12 vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8">
        <step-form-heading v-if="!data.id"
            :icon="faRobot">
            New Automation

            <template v-slot:description>
                Automatically run actions based on a triggering event.
            </template>
        </step-form-heading>

        <page-header v-if="data.id"
            class="vx-mb-6 lg:vx-mb-12">
            <template v-slot:subtitle>
                <a href="/automation/event"
                    class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                    <font-awesome-icon class="vx-mr-1"
                        :icon="faArrowLeft"></font-awesome-icon>
                    All Automations
                </a>
            </template>

            {{ data.name }}
        </page-header>

        <div :class="{
              'vx-grid vx-grid-cols-12': data.id,
          }">
            <page-sidebar v-if="data.id" :links="[
              {href: `/automation/event/${data.id}`, label: 'Details'},
              {href: `/automation/event/${data.id}#filters`, label: 'Filters'},
              {href: `/automation/event/${data.id}#actions`, label: 'Actions'},
            ]"></page-sidebar>
            <vx-sticky-nav :sections="[
                { id: 'name', label: 'Name' },
                { id: 'category', label: 'Category' },
                { id: 'trigger', label: 'Trigger' },
                { id: 'filters', label: 'Filters' },
                { id: 'actions', label: 'Actions' },
            ]"
                class="vx-col-span-12"
                :class="{
                    'lg:vx-col-span-10': !!data.id,
                }"
                :navClasses="`${success || data.id ? 'vx-hidden' : ''}`">
                <div data-test="alert-state"
                    class="vx-max-w-screen-sm vx-w-full vx-mb-2 md:vx-mb-8"
                        :class="{
                            'vx-mx-auto': !data.id,
                        }">
                    <flow-resource-state-alert v-if="data?.flow_id"
                        :resourceName="data.name"
                        :success="success"
                        :editing="!!data.id"
                        :flowId="data.flow_id"
                        :flowSection="data.flow_section"></flow-resource-state-alert>
                    <vx-alert v-else-if="(success && !!data.id) || recentlyCreated"
                        color="success"
                        :closeable="false"
                        shadow>
                        <template v-slot:icon>
                            <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                        </template>
                        <div class="vx-flex vx-items-center vx-justify-between">
                            <span v-if="recentlyCreated"><strong>{{ data.name }}</strong> Created successfully</span>
                            <span v-else>Updated successfully</span>
                            <vx-button href="/automation/event"
                                size="sm"
                                color="success">Back to Automations <font-awesome-icon
                                    :icon="faSquareArrowUpLeft"></font-awesome-icon></vx-button>
                        </div>
                    </vx-alert>
                </div>
                <div v-if="!recentlyCreated || data.id"
                    class="vx-flex vx-flex-col vx-w-full vx-justify-center vx-gap-2 md:vx-gap-8"
                    :class="{
                        'vx-mx-auto': !data.id,
                    }">
                    <vx-expandable-card id="name"
                          class="vx-max-w-screen-sm vx-w-full"
                          :class="{
                              'vx-mx-auto': !data.id,
                          }">
                        <template v-slot:header> Name </template>

                        <template v-slot:content>
                            <vx-input v-model="data.name"
                                data-test="automation-name"
                                :error="errors.name || displayFirstError(validate.name.$errors)"
                                @blur="validate.name.$touch()"
                                autofocus
                                size="lg"
                                :maxlength="200"></vx-input>
                        </template>
                    </vx-expandable-card>

                    <resource-category
                        class="vx-max-w-screen-sm vx-w-full"
                        :class="{
                            'vx-mx-auto': !data.id,
                        }"
                        id="category"
                        v-model="data.category"
                        :disabled="!!data.flow_id">
                    </resource-category>

                    <vx-expandable-card
                        id="trigger"
                        class="vx-max-w-screen-sm vx-w-full"
                        :class="{
                            'vx-mx-auto': !data.id,
                        }">
                        <template v-slot:header> Trigger </template>
                        <template v-slot:headerSubtitle> Select an event that will trigger this automation to run.
                        </template>

                        <template v-slot:content>
                            <vx-selectable size="lg"
                                v-model="data.event"
                                data-test="automation-event"
                                :clearable="false"
                                :options="triggers"
                                :reduce="option => option.value"
                                label="label"
                                placeholder="Select a trigger..."
                                :error="errors.event || displayFirstError(validate.event.$errors)">
                                <template v-slot:option="{ option }">
                                    <vx-badge size="sm"
                                        class="vx-px-1.5 vx-mr-4"
                                        :color="option.color">
                                        <font-awesome-icon class="!vx-text-xs"
                                            :icon="option.icon"></font-awesome-icon>
                                    </vx-badge>
                                    {{ option.label }}
                                </template>
                                <template v-slot:selected-option="{ option }">
                                    <vx-badge size="sm"
                                        class="vx-px-1.5 vx-mr-4"
                                        :color="option.color">
                                        <font-awesome-icon class="!vx-text-xs"
                                            :icon="option.icon"></font-awesome-icon>
                                    </vx-badge>
                                    {{ option.label }}
                                </template>
                            </vx-selectable>
                        </template>
                    </vx-expandable-card>

                    <div
                        id="filters"
                        :class="{
                            'vx-mx-auto vx-max-w-5xl vx-w-full': !data.id,
                            'vx-w-full xl:vx-w-[62rem]': data.id
                        }"
                        >
                        <automation-rule-form-matches :error="displayFirstError(validate.match_groups) || maxMatchesError"></automation-rule-form-matches>
                    </div>
                    <div
                        id="actions"
                        :class="{
                            'vx-mx-auto vx-max-w-5xl vx-w-full': !data.id,
                            'vx-w-full xl:vx-w-[62rem]': data.id
                        }">
                        <automation-rule-form-actions :error="displayFirstError(validate.automated_actions)"></automation-rule-form-actions>
                    </div>

                    <div v-if="error"
                        class="vx-max-w-screen-sm vx-w-full">
                        <vx-alert color="danger"
                            :closeable="false">
                            {{ error }}
                        </vx-alert>
                    </div>

                    <vx-sticky-footer
                          class="vx-max-w-screen-sm vx-flex-wrap"
                          :class="{
                              'vx-mx-auto': !data.id,
                          }">
                        <vx-button href="/automation/event"
                            class="vx-order-3 vx-w-full lg:vx-w-20 lg:vx-order-1"
                            size="lg"
                            color="muted-dark"
                            outline>
                            Cancel
                        </vx-button>
                        <vx-button @click="submit()"
                            data-test="submit"
                            :loading="saving"
                            type="button"
                            class="vx-order-1 vx-w-full lg:vx-w-auto lg:vx-grow lg:vx-order-3"
                            size="lg"
                            color="primary">
                            {{ data.id ? 'Update Automation' : 'Create Automation' }}
                        </vx-button>
                    </vx-sticky-footer>
                </div>
            </vx-sticky-nav>
        </div>
    </div>
</template>

<script setup>
import { faArrowLeft, faCircleCheck, faRobot, faSquareArrowUpLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxAlert, VxButton, VxExpandableCard, VxIconLoading, VxInput, VxStickyFooter, VxStickyNav, VxSelectable, VxBadge } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { helpers, maxLength, required } from '@vuelidate/validators';
import { computed, onMounted } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';
import PageSidebar from '~/components/general/PageSidebar.vue';
import { useToasted } from '~/composables/useToasted';
import { displayFirstError } from '~/utils/validation';
import StepFormHeading from '~/components/general/StepFormHeading.vue';
import { useAutomationRule } from './useAutomationRule';
import FlowResourceStateAlert from './flows/components/FlowResourceStateAlert.vue';
import { useAutomationRulesMetadata } from './useAutomationRulesMetadata';
import AutomationRuleFormMatches from './AutomationRuleFormMatches.vue';
import AutomationRuleFormActions from './AutomationRuleFormActions.vue';
import { useUrlSearchParams } from '@vueuse/core';
import flowsClient from '~/services/flowsClient';
import ResourceCategory from '~/components/general/categories/ResourceCategory.vue';

const teamId = Spark.state.currentTeam.id;

const toasted = useToasted();

const props = defineProps({
    automationRuleId: {
        type: Number
    }
});

const { actions, triggers } = useAutomationRulesMetadata();
const { mount, data, loading, save, saving, errors, error, recentlyCreated, success } = useAutomationRule();

const validate = useVuelidate({
    name: { required, maxLength: maxLength(120) },
    event: { required },
    match_groups: {
        maxLength: helpers.withMessage('An automation can only contain 10 filter groups', maxLength(10)),
    },
    automated_actions: {
        maxLength: helpers.withMessage('An automation can only have 25 actions', maxLength(25)),
    },
}, data);

const queryParams = useUrlSearchParams();

onMounted(async () => {
    await mount(props.automationRuleId || queryParams.duplicate_from_id);

    if (queryParams.duplicate_from_id) {
        // unsets the id and name when duplicating
        data.value.id = undefined;
        data.value.name = '';

        if (data.value.flow_id) {
            try {
                const { data: { config: { sections } } } = await flowsClient.getConfig(teamId, data.value.flow_id);
                // find section
                data.value.flow_section = sections.findIndex(
                    (section) => section.resources.find(
                        resource => resource.type === 'automation' && Number(resource.id) === Number(queryParams.duplicate_from_id)
                    )
                )
            } catch (e) {
                console.error(e)
            }
        }
    }

    if (!data.value.flow_id && queryParams.flow_id) {
        data.value.flow_id = queryParams.flow_id;
        data.value.flow_section = Number(queryParams.flow_section || 0);
    }
});

const maxMatchesError = computed(() => {
    const sum = (data.value.match_groups || []).reduce((count, matchGroup) => {
        return count + matchGroup.matches.length;
    }, 0);

    if (sum > 50) {
        return 'An automation can only contain 50 filters total'
    }

    return '';
});

const submit = async () => {
    const isValid = await validate.value.$validate();

    if (!isValid || maxMatchesError.value) {
        toasted.error('Please check form data.');
        return;
    }

    await save();

    if (success.value) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>
