<template>
    <div v-if="loading"
        class="vx-text-center vx-py-10">
        <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
    </div>
    <div v-else class="vx-space-y-8 md:vx-space-y-12 vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8">
        <page-header v-if="props.contactId"
            class="vx-mb-6 lg:vx-mb-12">
            <template v-slot:subtitle>
                <a href="/contacts"
                    class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                    <font-awesome-icon class="vx-mr-1"
                        :icon="faArrowLeft"></font-awesome-icon>
                    All Contacts
                </a>
            </template>

            <slot name="header">{{ fullName }}</slot>
        </page-header>

        <div :class="{
            'vx-grid vx-grid-cols-12 vx-items-start': props.contactId,
        }">
            <page-sidebar v-if="props.contactId"
                appearance="nested"
                :links="[
                    { href: `/contacts/${props.contactId}`, label: 'Details', pathToRegexp: pathToRegexp([`/contacts/${props.contactId}`, `/contacts/${props.contactId}/view`]) },
                    {
                        label: 'Logs',
                        links: logsLinks
                    },
                ]"></page-sidebar>
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import PageHeader from '~/components/general/PageHeader.vue';
import PageSidebar from '~/components/general/PageSidebar.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { inject, computed, onMounted } from 'vue';
import { pathToRegexp } from 'path-to-regexp';
import { useContactDetails } from './useContactDetails';
import { VxIconLoading } from '@voxie/frontend-components';

const FEATURES = inject('FEATURES')

const props = defineProps({
    contactId: {
        type: Number,
        required: false,
    },
});

const { fullName, loading, mount } = useContactDetails();

onMounted(() => {
    mount(props.contactId);
})

const logsLinks = computed(() => {
    const links = [
        {
            label: 'Messages',
            href: `/contacts/${props.contactId}/messages`,
        },
        {
            label: 'Segments',
            href: `/contacts/${props.contactId}/segments`,
        },
        {
            label: 'Campaigns',
            href: `/contacts/${props.contactId}/campaigns`,
        },
        {
            label: 'Custom Attributes',
            href: `/contacts/${props.contactId}/custom-attributes`,
        },
        {
            label: 'Subscriptions',
            href: `/contacts/${props.contactId}/subscriptions`,
        },
    ];

    if (FEATURES.promotions) {
        links.push({
            label: 'Promotions',
            href: `/contacts/${props.contactId}/promotions`,
        })
    }
    return links;
})
</script>
