<template>
    <vx-chart-column
        legend
        tooltipItemKey="tooltip"
        :error="hasError"
        @error="chartError = true"
        :loading="loading"
        :data="dataComputed.data"
        :colors="dataComputed.colors"
        :axis="{
            x: {
                key: 'label',
                axis: true,
                label: '',
                domain: dataComputed.colors.map((item) => item.label),
            },
            y: {
                key: 'count',
                axis: true,
                label: '',
                ticks: 5,
                tickFormat: (v) => formatCount(v),
            },
        }"
    >
        <template v-slot:title>
            <slot name="title">
                <span>
                    New Contacts by Entry Point
                </span>
            </slot>
        </template>
        <template v-slot:subtitle>
            <slot name="subtitle"></slot>
        </template>
        <template v-slot:right>
            <slot name="right">
                <experimental-pill subject="Beta Feedback - Analytics" v-if="!loading" class="-vx-mt-[4px]"></experimental-pill>
            </slot>
        </template>
        <template v-slot:footer>
            <vx-button
                href="/contacts"
                outline
                color="muted-light"
                size="xs"
            >
                Go to Contacts <font-awesome-icon :icon="faArrowRight" />
            </vx-button>
        </template>

        <template v-slot:explainHeader>New Contacts by Entry Point</template>
        <template v-slot:explainContent>
            <p>
                This chart allows you to evaluate the distribution of contacts created through the different means that Voxie provides, as well as to identify which provides the greatest flow and <strong>Quantity of New Contacts</strong> during the evaluation period.
            </p>
        </template>
    </vx-chart-column>
</template>
<script setup>
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxButton, VxChartColumn } from '@voxie/frontend-components';
import dayjs, { toUTCDateTimeFormat } from '~/utils/dayjs';
import { ref, watch, computed } from 'vue';
import { analyticsClient } from '~/services';
import { getClientTz } from '~/utils/date';
import { entryPoint, formatCount } from '~/components/filters';
import { generateLegendColors } from '~/utils/analytics';
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';
import { useVersionedRequest } from '~/composables/useVersionedRequest';

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
});
const chartError = ref(false);
const hasError = computed(() => {
    return Boolean(error.value) || chartError.value;
});

const calculateRange = () => {
    const startDate = dayjs(props.startDate).tz(getClientTz());
    const endDate = dayjs(props.endDate).tz(getClientTz());

    return [toUTCDateTimeFormat(startDate), toUTCDateTimeFormat(endDate)];
};

const { loading, response, error, fetchData } = useVersionedRequest();

const dataComputed = computed(() => {
    const dataMapped = (response.value?.data?.data || []).map((item) => ({
        label: entryPoint(item.group_id),
        count: item.values[0],
        tooltip: `${entryPoint(item.group_id)}: ${Intl.NumberFormat('en').format(item.values[0])}`,
    }));

    return {
        data: dataMapped.every((i) => i.count === 0) ? [] : dataMapped,
        colors: generateLegendColors(
            dataMapped.map((item) => item.label)
        ),
    }
});

watch(
    [() => props.startDate, () => props.endDate],
    () => {
        chartError.value = false;

        fetchData(analyticsClient.contactsTotalCreatedByEntryPoint(Spark.state.currentTeam.id, { range: calculateRange() }))
            .catch((e) => {
                console.error(e);
            });
    },
    { immediate: true }
);
</script>
