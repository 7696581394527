<template>
    <analytics-stat-card
        :loading="loading"
        :error="Boolean(error)"
        :percentage="dataComputed.percentage"
        :value="dataComputed.number?.value"
        :color="dataComputed.percentage < 0 ? 'bad' : 'good'"
        :direction="dataComputed.percentage < 0 ? 'down' : 'up'">
            <template v-slot:title>
                <slot name="title">New Contacts</slot>
            </template>
            <template v-slot:subtitle>
                <slot name="subtitle"></slot>
            </template>

            <span>{{ dataComputed.number?.label || '' }}</span>

            <template v-slot:footer>
                <vx-button href="/contacts" size="xs" outline color="muted-light">
                    Go to Contacts
                    <font-awesome-icon
                        :icon="faArrowRight"
                    ></font-awesome-icon>
                </vx-button>
            </template>

            <template v-slot:explainHeader>Contacts</template>
            <template v-slot:explainContent>
                <p>
                    <strong>New Contacts</strong><br /> Gross Increase Count of Contacts during the selected period.
                </p>

                <p>
                    <strong>New Contacts %</strong><br /> Gross increase of Contacts during the selected period relative to the previous period.
                </p>
            </template>
        </analytics-stat-card>
</template>
<script setup>
import { analyticsClient } from '../../../../services';
import { computed, watch } from 'vue';
import AnalyticsStatCard from '../../partial/AnalyticsStatCard.vue';
import { VxButton } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { calculateDifference, formatCountPlusSign, computeComparisonRange } from '../../../../utils/analytics';
import { useVersionedRequest } from '~/composables/useVersionedRequest';

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
});

const { loading, response, error, fetchData } = useVersionedRequest();

const dataComputed = computed(() => {
    const responseData = response.value?.data;

    if (!responseData?.values?.length) {
        return {
            number: undefined,
            percentage: undefined,
        }
    }

    const number = responseData.values.length >= 2 ? responseData.values[1] : responseData.values[0];
    const percentage = responseData.values.length >= 2 ? calculateDifference(responseData.values) : undefined;

    return {
        number: {
            value: number,
            label: formatCountPlusSign(number)
        },
        percentage: percentage,
    }
});

watch([() => props.startDate, () => props.endDate], async () => {
    const params = {
        range: computeComparisonRange({ start: props.startDate, end: props.endDate })
    };

    fetchData(analyticsClient.contactsTotalCreated(Spark.state.currentTeam.id, params))
        .catch((e) => {
            console.error(e);
        })
}, { immediate: true });

</script>
