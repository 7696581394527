<template>
    <div class="threads-actions-v2">
        <vx-input class="threads-actions__search" v-model.trim="search" placeholder="Search">
            <template #before>
                <vx-icon name="search-bold" :size="16" class="threads-actions-v2__search-icon"></vx-icon>
            </template>
            <template #after>
                <button v-if="search.length" role="button" @click="clearSearch()" class="threads-actions-v2__search-clear">
                    <vx-icon name="times" :size="16"></vx-icon>
                </button>
                <filter-drop-down
                    @applyFilters="applyFilters"
                    :isFilteringEnabled="isFilteringEnabled"
                ></filter-drop-down>
            </template>
        </vx-input>

        <vx-dropdown
            v-if="!FEATURES.simple_message_hub"
            :disabled="!hasSelectedThreads"
            class="ds-v2-contact-title__actions-dropdown"
            placement="right"
        >
            <vx-button :disabled="!hasSelectedThreads" appearance="primary-outline" size="medium" right="tick">
                Actions
            </vx-button>
            <template #items>
                <actions-drop-down @changeStatus="emit('changeStatus')" />
            </template>
        </vx-dropdown>

        <v-tooltip>
            <vx-fab-button data-test="new_conversation_button" size="extra-small" appearance="primary-dark" @click="showNewConversationUi">
                <vx-icon name="plus"></vx-icon>
            </vx-fab-button>
            <template #content> New Conversation </template>
        </v-tooltip>
    </div>
</template>

<script setup>
import { ref, computed, watch, inject } from 'vue';
import { useStore } from 'vuex';
import ActionsDropDown from './misc/ActionsDropDown.vue';
import FilterDropDown from './misc/FilterDropDown.vue';
import VTooltip from '~/components/general/VTooltip.vue';

import { VxInput, VxIcon, VxDropdown, VxFabButton, VxButton } from '@voxie/frontend-components-v3';

import debounce from '~/utils/debounce';

defineProps({
    isFilteringEnabled: {
        type: Boolean,
        default: false,
    },
});
const FEATURES = inject('FEATURES');
const emit = defineEmits(['applyFilters', 'changeStatus']);

const store = useStore();
const hasSelectedThreads = computed(() => store.state.threads.selectedThreads.length > 0);

const search = ref('');

const clearSearch = () => search.value = '';

const applyFilters = (filters) => {
    if (!filters.search?.trim().length) {
        filters.search = null;
    }

    emit('applyFilters', filters)
};

const applySearch = debounce((search) => {
    applyFilters({ search });
}, 500);

watch(search, applySearch);

const showNewConversationUi = () => Bus.$emit('showNewConversationUi', true);
</script>

<style lang="postcss">
.threads-actions-v2 {
  display: flex;
  gap: 1rem;
  padding: 0 0.5rem;
  margin-top: 13px;
  margin-bottom: 13px;
}
.threads-actions-v2 .popper {
  --popper-theme-background-color: var(--color-primary-dark);
  --popper-theme-background-color-hover: var(--color-primary-dark);
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 4px;
  --popper-theme-padding: 4px 8px;
  --popper-theme-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  font-family: var(--font-open-sans);
  font-size: 0.75rem;
  line-height: 1.25rem;
}
.threads-actions-v2 .vx-input__input {
  height: 30px;
}
.threads-actions-v2__search-icon {
  color: var(--fc-color-gray-40);
}
.threads-actions-v2__search-clear {
  border: none;
  background: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  left: -28px;
  height: 100%;
  top: 0;
  position: absolute;
  padding-top: 6px;
  color: var(--fc-color-gray-40);
}
.threads-actions-v2 .vx-fab-button {
  flex-shrink: 0;
}
</style>
