<template>
    <div
        class="vx-relative vx-font-sans"
    >
        <vx-selectable :modelValue="selectedRange.value" @update:modelValue="handleControlDateRangeOption"
            :options="options" :reduce="option => option.value" label="label" :clearable="false" size="lg"></vx-selectable>
        <Transition
            enter-from-class="vx-opacity-0 -vx-translate-y-4"
            enter-to-class="vx-opacity-100 vx-translate-y-0"
            leave-from-class="vx-opacity-100 vx-translate-y-0"
            leave-to-class="vx-opacity-0 -vx-translate-y-4"
        >
            <div v-if="isDatePickerOppened" class="vx-absolute vx-z-20 vx-mb-8 vx-w-full vx-transition lg:vx-max-w-md"
                @click.stop
            >
                <vx-date-picker
                    mode="date"
                    :modelValue="datePickerRangeModel"
                    @update:modelValue="handleDatePickerRange"
                    is-range
                    v-on-click-outside="() => closeDatePickerRange()"
                    :timezone="getClientTz()"
                    :maxDate="maxCustomDate"
                    :minDate="minCustomDate"
                    @dayclick="handleDayClick"
                />
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { ref, computed, nextTick, watch } from 'vue';
import { VxSelectable, VxDatePicker } from '@voxie/frontend-components';
import { vOnClickOutside } from '@vueuse/components'
import { controlDateRangeOptions, formatDateForRange, getCurrentDate } from '../../../utils/analytics';
import { getClientTz, shortDate } from '../../../utils/date';
import dayjs from '~/utils/dayjs';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

// dayjs calculates an end date starting from a next day. in order to get the right range including a current day we decrease max days by 1.
const MAX_DAYS_RANGE = 89;

const clickedDay = ref(null);

const handleDayClick = (event) => {
    if (!clickedDay.value) {
        clickedDay.value = event.date;
    }
}

const maxAllowed = computed(() => {
    const currentDate = getCurrentDate();

    // current day is only available if the time is at least 01:30 local.
    if (currentDate.isAfter(currentDate.clone().set('hour', 1).set('minutes', 30))) {
        return currentDate.toDate();
    }

    return currentDate.subtract(1, 'day').endOf('day').toDate();
})

const maxCustomDate = computed(() => {
    if (clickedDay.value && dayjs(clickedDay.value).add(MAX_DAYS_RANGE, 'days').isAfter(maxAllowed.value)) {
        return maxAllowed.value;
    }

    if (clickedDay.value) {
        return dayjs(clickedDay.value).add(MAX_DAYS_RANGE, 'days').toDate();
    }

    return maxAllowed.value;
})

const minCustomDate = computed(() => {
    const minAllowed = dayjs.utc('2024-01-01 05:00:00');

    if (clickedDay.value && dayjs(clickedDay.value).subtract(MAX_DAYS_RANGE, 'days').isBefore(minAllowed)) {
        return minAllowed.toDate();
    }

    if (clickedDay.value) {
        return dayjs(clickedDay.value).subtract(MAX_DAYS_RANGE, 'days').toDate();
    }

    return minAllowed.toDate();
});

const customRangeOption = ref(null);
const options = computed(() => {
    const options = controlDateRangeOptions()

    if (customRangeOption.value) {
        options.push({
            ...customRangeOption.value
        })
    }

    return options;
})

// In order to get fresh dates on reset.
const defaultDatePickerRange = () => ({
    start: dayjs(maxCustomDate.value).tz(getClientTz()).subtract(2, 'days').startOf('day').toDate(),
    end: dayjs(maxCustomDate.value).tz(getClientTz()).startOf('day').toDate(),
});

const selectedRange = ref(props.modelValue);
const isDatePickerOppened = ref(false);
const datePickerRangeModel = ref(defaultDatePickerRange());

const handleControlDateRangeOption = (optionKey) => {
    const option = options.value.find((o) => o.value === optionKey);

    selectedRange.value = { ...option };

    if (optionKey === 'selected_custom') {
        return;
    }

    if (optionKey === "custom") {
        isDatePickerOppened.value = true;
        customRangeOption.value = null;
        return;
    }

    customRangeOption.value = null;

    emit('update:modelValue', selectedRange.value);
}

const handleDatePickerRange = async ({start, end}) => {
    selectedRange.value = customRangeOption.value;

    customRangeOption.value = {
        value: 'selected_custom',
        start_date: formatDateForRange(dayjs(start)),
        end_date: formatDateForRange(dayjs.min([getCurrentDate(), dayjs(end).add(1, 'day')])),
        label: `${shortDate(start)} - ${shortDate(end)}`,
    };

    emit('update:modelValue', customRangeOption.value);

    isDatePickerOppened.value = false;
    datePickerRangeModel.value = defaultDatePickerRange();
    await nextTick();
    clickedDay.value = null;
}

const closeDatePickerRange = () => {
    isDatePickerOppened.value = false;
    handleDatePickerRange(defaultDatePickerRange())
}

watch(() => props.modelValue, () => {
    selectedRange.value = props.modelValue;
    if (selectedRange.value.value === 'selected_custom') {
        customRangeOption.value = props.modelValue;
    }
}, { immediate: true })

</script>
