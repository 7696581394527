<template>
    <vx-box color="info"
        class="vx-py-4 lg:vx-py-6">
        <div
            class="vx-flex vx-items-center vx-space-x-4 vx-text-slate-800  vx-font-extrabold vx-px-4 vx-pb-4 lg:vx-px-9 lg:vx-text-xl">
            <font-awesome-icon :icon="faBarsFilter"
                class="vx-text-base vx-ml-2" />

            <span>Contact Filters</span>

            <vx-badge color="primary"
                size="xs"
                data-test="filters-count">
                {{ filtersCount }}
            </vx-badge>

        </div>
        <segment-step-form-filter-group v-for="(group, groupIndex) in groups"
            :key="group.id"
            v-model="groups[groupIndex]"
            @createGroup="createGroup()"
            @removeGroup="removeGroup(groupIndex)"
            :isLast="(groupIndex + 1) == groups.length">
        </segment-step-form-filter-group>
    </vx-box>
</template>


<script setup>
import { onMounted, computed } from 'vue';
import SegmentStepFormFilterGroup from './SegmentStepFormFilterGroup.vue';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBadge, VxBox } from '@voxie/frontend-components';
import { makeGroup } from './segmentFiltersHelpers';

const groups = defineModel({
    type: Array,
    required: true,
    default: () => ([]),
})

const createGroup = () => {
    groups.value.push(makeGroup());
}

const removeGroup = (groupIndex) => {
    if (groups.value.length === 1) {
        return;
    }

    groups.value = groups.value.filter((group, currentIndex) => currentIndex != groupIndex)
}

const filtersCount = computed(() => {
    return groups.value.reduce((acc, group) => {
        return acc + group.filters.length
    }, 0)
})

onMounted(() => {
    if (!groups.value?.length) {
        groups.value = [makeGroup()];
    }
})

</script>
