<template>
    <vx-expandable-card>
        <template v-slot:header>
            <div class="mb-2">
                <vx-badge color="rose"
                          size="xs">
                    Required <font-awesome-icon class="vx-text-rose-500" :icon="faAsterisk"></font-awesome-icon>
                </vx-badge>
            </div>
            Name </template>
        <template v-slot:content>
            <vx-input
                data-test="name-input"
                autofocus
                :modelValue="props.modelValue"
                @update:modelValue="emit('update:modelValue', $event)"
                size="lg"
                placeholder="Name"
                :error="props.error"
            ></vx-input>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxBadge, VxExpandableCard, VxInput } from '@voxie/frontend-components';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
        default: '',
    },
    error: {
        type: [String, Array],
    }
});

const emit = defineEmits(['update:modelValue']);
</script>
