<template>
    <analytics-stat-card
        :loading="loading"
        :error="Boolean(error)"
        :value="error ? undefined : number">
            <template v-slot:title>
                Entry Points Active
            </template>
            <template v-slot:subtitle>
                All Time
            </template>

            <span>{{ numberLabel }}</span>

            <template v-slot:footer>
                <vx-button href="/contacts" size="xs" outline color="muted-light">
                    Go to Contacts
                    <font-awesome-icon
                        :icon="faArrowRight"
                    ></font-awesome-icon>
                </vx-button>
            </template>
            <template v-slot:explainHeader>
                Entry Points
            </template>
            <template v-slot:explainContent>
                How many Entry points you have enabled in Voxie to ingest contacts, out of the following:
                <div v-for="entryPoint in entryPointsForSelect({ includeLegacy: true })" :key="entryPoint.value">
                    {{ entryPoint.label }}
                </div>
            </template>
        </analytics-stat-card>
</template>
<script setup>
import AnalyticsStatCard from '../../partial/AnalyticsStatCard.vue';
import { VxButton } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { computed, ref } from 'vue';
import analyticsClient from '../../../../services/analyticsClient';
import { entryPointsForSelect, formatCount } from '~/components/filters';

const error = ref(false);
const loading = ref(false);
const number = ref(undefined);

const numberLabel = computed(() => formatCount(number.value));

loading.value = true;
analyticsClient
    .contactsTotalActiveEntryPoints(Spark.state.currentTeam.id)
    .then((res) => {
        number.value = res.data.value;
    })
    .catch((e) => {
        console.error(e);
        error.value = true;
    })
    .finally(() => {
        loading.value = false;
    });

</script>
