<template>
    <card title="Subaccount Invites">
        <alert v-if="success" :text="success" type="success" />

        <organizations-invites-form :team="team" @created="inviteCreated" />

        <div class="row mb-0">
            <table class="table mt-3 mb-0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Subaccount Name</th>
                        <th>Owner Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>

                    <tr v-for="invite in invites" :key="invite.id">
                        <td>
                            {{ invite.id }}
                        </td>
                        <td>
                            {{ subaccountName(invite) }}
                        </td>
                        <td>
                            {{ ownerName(invite) }}
                        </td>
                        <td>
                            <template v-if="invite.email && invite.email.length > 20">
                                {{ invite.email.substr(0, 10) }}

                                <popper class="organizations-invites__popper" hover :content="invite.email">
                                    <a href="#" @click.prevent>...</a>
                                </popper>
                            </template>
                            <template v-else>
                                {{ invite.email }}
                            </template>
                        </td>
                        <td>
                            {{ invite.status }}
                        </td>
                        <td>
                            <drop-down-actions v-if="invite.status !== 'completed'" label="Actions">
                                <button
                                    v-if="invite.status === 'pending' || invite.status === 'sent'"
                                    class="c-dropdown__item dropdown-item"
                                    type="button"
                                    data-test="delete-invite"
                                    @click.prevent="inviteToDelete = invite"
                                >
                                    Delete
                                </button>
                                <template v-if="invite.invite_url && (invite.status === 'pending' || invite.status === 'sent')">
                                    <button
                                        class="c-dropdown__item dropdown-item"
                                        type="button"
                                        data-test="resend-invite"
                                        @click.prevent="resendInvite(invite)"
                                    >
                                        Resend
                                    </button>
                                    <button
                                        class="c-dropdown__item dropdown-item"
                                        type="button"
                                        data-test="copy-invite"
                                        @click.prevent="copyInvite(invite)"
                                    >
                                        Copy Invite
                                    </button>
                                </template>
                            </drop-down-actions>
                            <template v-else>
                                -
                            </template>
                        </td>
                    </tr>
                    <tr v-if="loading">
                        <td colspan="6" class="text-center">
                            <loading-resource />
                        </td>
                    </tr>
                    <tr v-if="!invites.length && !loading">
                        <td colspan="6">
                            You can add subaccounts to your Account organization by sending invitation emails
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="col-md-12" v-if="!loading && cursor">
                <button
                    type="button"
                    @click="getInvites()"
                    class="c-pagination__control u-text-dark mx-auto"
                >
                    Show more
                </button>
            </div>
        </div>
        <organizations-invite-delete-confirm
            :team="team"
            :invite="inviteToDelete"
            @deleted="inviteDeleted"
        />
    </card>
</template>

<script>
import LoadingResource from '../../../general/LoadingResource.vue';
import Alert from '../../../general/Alert.vue';
import Card from '../../../general/Card.vue';
import DropDownActions from '../../../general/DropDownActions.vue';
import OrganizationsInviteDeleteConfirm from './OrganizationsInviteDeleteConfirm.vue';
import OrganizationsInvitesForm from './OrganizationsInvitesForm.vue';
import { organizationsClient } from '../../../../services';
import Popper from 'vue3-popper';

export default {
    components: {
        Alert,
        Card,
        LoadingResource,
        OrganizationsInviteDeleteConfirm,
        OrganizationsInvitesForm,
        DropDownActions,
        Popper,
    },
    props: {
        team: {
            required: true
        }
    },
    data() {
        return {
            loading: false,
            invites: [],
            cursor: null,
            success: null,
            inviteToDelete: null,
        };
    },
    mounted() {
        this.getInvites();


    },
    methods: {
        subaccountName(invite) {
            return invite.child_team?.name || '-'
        },
        ownerName(invite) {
            return invite.child_team?.owner?.name || '-'
        },
        inviteDeleted(invite) {
            this.inviteToDelete = null;
            this.invites = this.invites.filter(current => current.id != invite.id)
        },
        inviteCreated(invite) {
            this.invites.unshift(invite);
        },
        resendInvite(invite) {
            organizationsClient
                .resendInvite(this.team.id, invite.id)
                .then(() => {
                    this.invites = this.invites.map(current => ({
                        ...current,
                        status: current.id === invite.id ? 'pending' : current.status
                    }))
                    this.$toasted.success('Invite resent succesfully.')
                })
                .catch(err => {
                    console.error(err)
                    this.$toasted.global.platform_error()
                })
        },
        copyInvite(invite) {
            navigator.clipboard.writeText(invite.invite_url).then(() => this.$toasted.success('Invite copied'));
        },
        getInvites() {
            if (this.cursor === null) {
                this.invites = [];
            }

            this.loading = true;
            organizationsClient
                .getInvites(this.team.id, this.cursor)
                .then(response => {
                    this.invites = this.invites.concat(response.data.data);

                    if(response.data.next_page_url) {
                        const params = new URLSearchParams(response.data.next_page_url.split('?')[1])
                        this.cursor = params.get('cursor')
                    } else {
                        this.cursor = null;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.$toasted.global.platform_error();
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style>
.organizations-invites__popper {
    max-height: 400px;
}
</style>
