const featureIDs = [
    'api_destinations',
    'attribution_ui',
    'automations_disabled',
    'campaigns_disabled',
    'campaign_filtering',
    'contact_groups',
    'dynamic_field_extraction_ai',
    'flows',
    'message_hub_ai',
    'notifications',
    'promotions',
    'quick_blast_ai',
    'salesforce_integration',
    'sentiment_analysis_ai',
    'shift_to_text',
    'simple_message_hub',
    'single_sign_on',
    'subscriptions',
    'team_users_reskin',
    'text_analysis_v2',
    'time_zone_aware_campaigns',
    'transactional_default',
    'webhooks_ui',
];

const team = window.Spark?.state.currentTeam;

const FEATURES = featureIDs.reduce((acc, id) => {
    const teamAttributes = team?.team_attributes || [];
    acc[id] = Boolean(teamAttributes[id]);
    return acc;
}, {});

export { featureIDs, FEATURES };
