<template>
    <div class="card card-default">
        <div v-if="title" class="card-header">
            {{ title }}
            <div class="pull-right" v-if="hasRightHeading">
                <slot name="right-heading" />
            </div>
        </div>
        <div class="card-body" :class="{'p-0': noPadding}">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        noPadding: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasRightHeading() {
            return !!this.$slots['right-heading']
        }
    }
};
</script>
