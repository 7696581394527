<template>
    <component :is="inputComponent"
        :class="{
            'c-input': !library
        }"
        :modelValue="modelValue"
        :value="modelValue"
        type="text"
        placeholder="MM/DD/YYYY HH:MM tt"
        @input="input"
        @focus="$emit('focus')"
        @blur="blur"
        @keypress.prevent
        @keydown.prevent
        @keyup.prevent
    />
</template>

<script>
import dayjs from '~/utils/dayjs';
import { VxInput } from '@voxie/frontend-components'

export default {
    components: {
        VxInput,
    },
    props: {
        modelValue: String,
        format: {
            type: String,
            default: 'MM/DD/YYYY hh:mm a',
        },
        library: {
            type: Boolean,
            required: false,
        }
    },
    emits: ['update:modelValue', 'blur', 'focus'],
    methods: {
        input(e) {
            this.$emit('update:modelValue', e.target.value);
        },
        blur() {
            if (this.modelValue && !dayjs(this.modelValue, this.format, true).isValid()) {
                this.$emit('update:modelValue', '');
            }
            this.$emit('blur');
        },
    },
    computed: {
        inputComponent() {
            return this.library ? VxInput : 'input';
        }
    }
};
</script>
