import { isNaturalNumber } from '~/utils/helpers';
import dayjs from '~/utils/dayjs';

export const OPERATORS_DATE = [
    { value: '=', label: 'Is Equal to' },
    { value: '!=', label: 'Is NOT Equal to' },
    { value: 'is null', label: 'Is Blank' },
    { value: 'is not null', label: 'Is Not Blank' },
    { value: '<', label: 'Is earlier than' },
    { value: '<=', label: 'Is earlier than or equal to' },
    { value: '>', label: 'Is later than' },
    { value: '>=', label: 'Is later than or equal to' },
    { value: 'this_week', label: 'This Week', igoring_year: true },
    { value: 'last_week', label: 'Last Week', igoring_year: true },
    { value: 'this_month', label: 'This Month', igoring_year: true },
    { value: 'last_month', label: 'Last Month', igoring_year: true },
    { value: 'last_n_days', label: 'In the last n Days', igoring_year: true },
    { value: 'not_last_n_days', label: 'Not in the last n Days', igoring_year: true },
    { value: 'today', label: 'Today', igoring_year: true },
    { value: 'next_month', label: 'Next Month', igoring_year: true },
    { value: 'last_n_months', label: 'Last n Months', igoring_year: true },
    { value: 'next_n_months', label: 'Next n Months', igoring_year: true },
    { value: 'not_last_n_months', label: 'Not in the last n Months', igoring_year: true },
    { value: 'next_week', label: 'Next Week', igoring_year: true },
    { value: 'next_n_days', label: 'Next n Days', igoring_year: true },
];

export const OPERATORS_N = [
    'last_n_days_ignoring_year',
    'last_n_days',
    'last_n_months_ignoring_year',
    'last_n_months',
    'next_n_days_ignoring_year',
    'next_n_days',
    'next_n_months_ignoring_year',
    'next_n_months',
    'not_last_n_days_ignoring_year',
    'not_last_n_days',
    'not_last_n_months_ignoring_year',
    'not_last_n_months',
];

export const OPERATORS_THAT_DONT_NEED_VALUE = [
    'is null',
    'is not null',
    '= 1',
    '= 0',
    'this_week',
    'last_week',
    'this_month',
    'last_month',
    'today',
    'next_week',
    'next_month',
    'this_week_ignoring_year',
    'last_week_ignoring_year',
    'this_month_ignoring_year',
    'last_month_ignoring_year',
    'today_ignoring_year',
    'next_week_ignoring_year',
    'next_month_ignoring_year',
];

export const makeId = () => {
    return -1 * Math.floor(Math.random() * 999999);
};

export const makeFilter = () => {
    return {
        id: makeId(),
        attribute_name: null,
        data_type: null,
        key: null,
        operator: null,
        value: null,
    };
};

export const makeGroup = () => {
    return {
        id: makeId(),
        filters: [makeFilter()],
    };
};

/**
 * @param {{name: string, type: string}} segment
 * @param {{ id: number, filters: {attribute_name?: string|null, data_type?:string|null, operator?:string|null, key?:string|null, value?:string|null }[]}} groups
 * @returns
 */
export const validateFilters = (segment, groups) => {
    const errors = [];

    let hasNullAttr = false;
    let hasNullDataType = false;
    let wrongDataType = false;
    let hasNullOperator = false;
    let hasNullValue = false;
    let hasNullKey = false;
    let filterValueWrong = false;
    let tooManyFiltersInGroup = false;
    let filtersCount = 0;

    const attributesThatRequiresKey = [
        'contacts.custom_attributes.key_value',
        'contacts.custom_attributes.exclude_key_value',
    ];

    const dateValueOperators = ['=', '!=', '<', '<=', '>', '>='];

    groups.forEach((group) => {
        if (group.filters.length > 25) {
            tooManyFiltersInGroup = true;
        }

        group.filters.forEach((filter) => {
            filtersCount++;

            if (!filter.attribute_name) {
                hasNullAttr = true;
            }

            if (!filter.data_type) {
                hasNullDataType = true;
            } else {
                if (filter.data_type === 'number') {
                    if (filter.value && isNaN(Number(filter.value))) {
                        wrongDataType = true;
                    }
                }

                if (filter.data_type === 'date') {
                    if (OPERATORS_N.includes(filter.operator) && !isNaturalNumber(filter.value)) {
                        wrongDataType = true;
                    }

                    if (OPERATORS_N.includes(filter.operator) && isNaturalNumber(filter.value)) {
                        const max = filter.operator.includes('month') ? 64 : 2000;
                        if (Number(filter.value) < 1 || Number(filter.value) > max) {
                            const operatorLabel = OPERATORS_DATE.find((option) => {
                                const operator = filter.operator?.endsWith('_ignoring_year')
                                    ? filter.operator.slice(0, -14)
                                    : filter.operator;

                                return option.value == operator;
                            })?.label;

                            errors.push(`${operatorLabel || 'N value'} must be set with a value between 1 and ${max}.`);
                        }
                    }

                    if (
                        dateValueOperators.includes(filter.operator) &&
                        !dayjs(filter.value, 'YYYY-MM-DD', true).isValid()
                    ) {
                        wrongDataType = true;
                    }
                }

                if (!/^[ 0-9a-zA-Z$%&’'()+,\-!#?|./:<@_]{1,60}$/.test(filter.value)) {
                    filterValueWrong = true;
                }
            }

            if (!filter.operator) hasNullOperator = true;
            if (OPERATORS_THAT_DONT_NEED_VALUE.indexOf(filter.operator) < 0 && !filter.value) hasNullValue = true;

            if (attributesThatRequiresKey.includes(filter.attribute_name) && !filter.key) {
                hasNullKey = true;
            }
        });
    });

    const segmentNameRegex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9:/\\@#$%&*()\-=_+[\],?. ]{1,95}$/);

    if (!segmentNameRegex.test(segment.name)) {
        errors.push('Segment name is invalid.');
    }

    if (!segment.id) {
        if (!segment.audience_type) {
            errors.push('Segment Audience Type is required.');
        }

        if (!segment.type) {
            errors.push('Segment Type is required.');
        }
    }

    if (hasNullAttr) {
        errors.push('All filters must have the <b>Attribute Name</b> selected.');
    }
    if (hasNullDataType) {
        errors.push('All filters must have the <b>Data Type</b> selected.');
    }

    if (wrongDataType) {
        errors.push('Please, check that the <b>Values</b> have the right format.');
    }

    if (hasNullOperator) {
        errors.push('All filters must have the <b>Operator</b> selected.');
    }

    if (hasNullKey) {
        errors.push('All filters with a <b>Key</b> field must specify a key.');
    }

    if (hasNullValue) {
        errors.push('All filters with a <b>Value</b> field must have a value.');
    }

    if (filterValueWrong) errors.push('<b>Filter Value</b> format is wrong.');

    if (tooManyFiltersInGroup) {
        errors.push('<b>Filter Group</b> should not contain more than 25 <b>Filters</b>.');
    }

    if (groups.length > 10) {
        errors.push('Segment should not contain more than 10 Filter Groups.');
    }

    if (filtersCount > 100) {
        errors.push('Segment should not contain more than 100 <b>Filters</b>.');
    }

    return errors;
};
