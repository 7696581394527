import { ref, reactive } from 'vue';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_NOT_FOUND } from '~/support/HttpStatuses';
import { createGlobalState } from '@vueuse/core';
import segmentsClient from '~/services/segmentsClient';
import automationRulesClient from '~/services/automationRulesClient';
import campaignsClient from '~/services/campaignsClient';
import flowsClient from '~/services/flowsClient';
import { useToasted } from '~/composables/useToasted';
import { randomString } from '~/utils/string';

export const useCustomFlow = createGlobalState(() => {
    const toasted = useToasted();
    const teamId = Spark.state.currentTeam.id;

    const flow = ref({});

    const sections = ref([]);

    // this is used as in-memory storage of the fetched resources to
    // prevent fetching their data everytime a resource is rendered
    const resourcesBucket = reactive({
        automation: [],
        campaign: [],
        segment: [],
    });

    const fetching = ref(false);

    const resourceDragging = reactive({
        oldSectionIndex: undefined,
        newSectionIndex: undefined,
        resource: undefined,
    });

    const mount = async (flowId) => {
        flow.value = (await flowsClient.get(teamId, flowId)).data;

        await fetchSections();
    };

    const fetchSections = async () => {
        try {
            sections.value = (
                (await flowsClient.getConfig(teamId, flow.value.flow_id)).data.config?.sections || []
            ).map((section) => {
                section.draggable_id = randomString();
                section.open = false;
                return section;
            });

            if (!sections.value.length) {
                addSection();
            }

            const sectionsResources = sections.value.reduce(
                (resources, section) => resources.concat(section.resources),
                []
            );

            fetchResources(sectionsResources);
        } catch (e) {
            console.error(e);
            toasted.global.platform_error();
        }
    };

    const saveSections = async (data) => {
        return flowsClient.saveConfig(teamId, flow.value.flow_id, {
            config: {
                sections: [...data].map((item) => {
                    const section = { ...item };

                    delete section.draggable_id;
                    delete section.open;
                    return section;
                }),
            },
        });
    };

    const addSection = async () => {
        sections.value.push({
            draggable_id: randomString(),
            name: `Step ${sections.value.length + 1}`,
            resources: [],
            open: true,
        });

        try {
            await saveSections(sections.value);
        } catch (e) {
            console.error(e);
            if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
                toasted.global.platform_error();
            } else {
                toasted.error(e.response?.data?.message || 'Something went wrong.');
            }

            fetchSections();
        }
    };

    const removeSection = async (sectionIndex) => {
        sections.value.splice(sectionIndex, 1);
        // First try to remove section.
        try {
            await saveSections(sections.value);
        } catch (e) {
            console.error(e);
            if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
                toasted.global.platform_error();
            } else {
                toasted.error(e.response?.data?.message || 'Something went wrong.');
            }
        }
    };

    const removeResources = async (toRemoveResources) => {
        // avoids changing in memory before request
        const removedResources = [...sections.value].map((section) => {
            return {
                ...section,
                resources: section.resources.filter((resource) => {
                    return !toRemoveResources.some(
                        (removing) => removing.type === resource.type && removing.id === resource.id
                    );
                }),
            };
        });
        await saveSections(removedResources);
        // assign when saved successfully
        sections.value = removedResources;
    };

    const addResources = async (toAddFlowSection, toAddResources) => {
        // avoids changing in memory before request
        const addedResources = [...sections.value].map((section, sectionIndex) => {
            return {
                ...section,
                resources:
                    sectionIndex === toAddFlowSection ? section.resources.concat(toAddResources) : section.resources,
            };
        });
        await saveSections(addedResources);
        // assign when saved successfully
        sections.value = addedResources;
    };

    const fetchResource = async (resourceType, resourceId) => {
        return new Promise((resolve, reject) => {
            // when its currently making a request call wait a bit before retrieving again
            if (fetching.value) {
                setTimeout(() => resolve(fetchResource(resourceType, resourceId)), 100);
                return;
            }

            // retrieves from memory
            const existing = resourcesBucket[resourceType].find((resource) => resource.id == resourceId);

            if (existing) {
                resolve(existing);
                return;
            }

            // in case doesn't exist make api call
            fetching.value = true;

            let client;

            if (resourceType === 'automation') {
                client = automationRulesClient.get(teamId, resourceId);
            } else if (resourceType === 'campaign') {
                client = campaignsClient.campaignGet(teamId, resourceId);
            } else if (resourceType === 'segment') {
                client = segmentsClient.getSegment(teamId, resourceId);
            }

            client
                .then((response) => {
                    resourcesBucket[resourceType].push(response.data);
                    resolve(response.data);
                })
                .catch((e) => {
                    console.error(e);
                    if (e.response && e.response.status === HTTP_NOT_FOUND) {
                        resolve(null);
                    } else {
                        reject(e);
                    }
                })
                .finally(() => {
                    fetching.value = false;
                });
        });
    };

    const uniqueResources = (data) => {
        return Object.values(
            data.reduce((uniqueResources, obj) => {
                // keep latest version only
                uniqueResources[obj.id] = uniqueResources[obj.id]
                    ? obj.index > uniqueResources[obj.id].index
                        ? obj
                        : uniqueResources[obj.id]
                    : obj;
                return uniqueResources;
            }, {})
        );
    };

    // fetch resources data to store in the in-memory bucket
    // to display information about the resources
    const fetchResources = async (resources) => {
        fetching.value = true;

        return Promise.all([
            automationRulesClient.search(teamId, {
                ids: resources.filter((resource) => resource.type === 'automation').map((resource) => resource.id),
                per_page: 50,
            }),
            campaignsClient.campaignsGetRecords(teamId, {
                ids: resources.filter((resource) => resource.type === 'campaign').map((resource) => resource.id),
                per_page: 50,
            }),
            segmentsClient.getSegments(teamId, {
                'filter[ids]': resources
                    .filter((resource) => resource.type === 'segment')
                    .map((resource) => resource.id),
                per_page: 50,
            }),
        ])
            .then(([automationsResponse, campaignsReponse, segmentsResponse]) => {
                resourcesBucket.automation = uniqueResources(
                    resourcesBucket.automation.concat(automationsResponse.data.data)
                );
                resourcesBucket.campaign = uniqueResources(resourcesBucket.campaign.concat(campaignsReponse.data.data));
                resourcesBucket.segment = uniqueResources(resourcesBucket.segment.concat(segmentsResponse.data.data));
            })
            .catch((e) => {
                console.error(e);
                toasted.global.platform_error();
            })
            .finally(() => {
                fetching.value = false;
            });
    };

    return {
        fetchResource,
        fetchResources,
        mount,
        addSection,
        addResources,
        saveSections,
        flow,
        sections,
        removeSection,
        removeResources,
        resourceDragging,
    };
});
