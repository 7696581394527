<template>
    <vx-expandable-card v-if="!file">
        <template v-slot:content>
            <h3 class="vx-text-xl vx-text-slate-700 vx-font-extrabold">
                Upload
            </h3>
            <p class="vx-text-sm">
                New contacts will be created. Phone numbers that match an existing contact will be updated with what you
                upload. Any conflicts with the subscription status or tags set above will be overridden by the CSV values.
            </p>

            <label class="
                    vx-my-4
                    vx-p-8
                    vx-border-dashed
                    vx-border-2
                    vx-rounded-[16px]
                    vx-flex
                    vx-flex-col
                    vx-items-center
                    vx-justify-center
                    vx-text-center
                    vx-relative
                    vx-cursor-pointer
            " :class="{
                'vx-border-sky-500 vx-bg-white': dragging,
                'vx-border-slate-300 vx-bg-slate-100': !dragging,
            }" data-test="drop-file" @dragenter.prevent="dragging = true" @dragover.prevent="dragging = true"
                @dragleave.prevent="dragging = false" @drop.prevent="onFileDropped">
                <input type="file" @change="onFileAdded" class="vx-absolute vx-opacity-0" accept=".csv" />

                <font-awesome-icon :icon="faFileCsv" size="3x" class="vx-text-sky-400"></font-awesome-icon>
                <div class="vx-font-semibold vx-mt-2">
                    Drag media here
                </div>
                <div class="vx-mb-3 vx-mt-2">— or —</div>

                <!-- vx-pointer-events-none on this button is necessary for the parent label to work -->
                <vx-button color="primary" size="sm" type="button" class="vx-pointer-events-none">
                    Browse Computer
                    <font-awesome-icon class="vx-text-sm" :icon="faFileMedical"></font-awesome-icon>
                </vx-button>
            </label>

            <div class="vx-text-slate-600 vx-font-base">
                Files must be <strong>CSV</strong> and less than <strong>1GB</strong>.
            </div>

        </template>
    </vx-expandable-card>
    <vx-expandable-card v-if="file">
        <template v-slot:content>
            <h3 class="vx-text-xl vx-text-slate-700 vx-font-extrabold">
                Upload Preview
            </h3>
            <p class="vx-text-lg" data-test="upload-summary-text">
                <template v-if="validations?.message || validations?.nonRecoverableErrors?.length">
                    The provided file is invalid and cannot be imported. Please go back and make corrections to the file.
                </template>
                <template v-else-if="validations?.recoverableErrors?.length">
                    We detected issues with some rows in the provided file. We'd recommend that you correct or remove these issues before proceeding.
                </template>
                <template v-else>
                    We didn't detect any issues in the provided file. Proceed to import if the number of rows matches what you expected.
                </template>
            </p>

            <div class="
                vx-my-4
                vx-p-4
                vx-rounded-[16px]
                vx-bg-white
                vx-flex
                vx-items-center
                vx-gap-4
                vx-relative
            ">
                <font-awesome-icon :icon="faFileCsv" size="3x" class="vx-text-slate-400"></font-awesome-icon>
                <div data-test="drop-file-name" class="vx-text-slate-500 vx-font-normal vx-text-lg vx-mt-2 vx-break-all">
                    {{ file.name }}
                </div>
                <button @click="clearFile" type="button"
                    class="vx-bg-rose-400 vx-rounded-full vx-text-center vx-flex vx-items-center vx-justify-center vx-p-0 vx-appearance-none vx-border-solid vx-border-[5px] vx-border-slate-50 vx-w-9 vx-h-9 vx-absolute -vx-right-4 -vx-top-4">
                    <font-awesome-icon :icon="faTrash" fixed-width class="vx-text-sm vx-text-white"></font-awesome-icon>
                </button>
            </div>

            <div v-if="!validations" class="vx-text-sky-500 vx-text-lg vx-font-normal">
                Calculating
                <font-awesome-icon class="vx-ml-2" :icon="faSpinner" spin></font-awesome-icon>
            </div>
            <div v-else>
                <div v-if="validations?.validCount" data-test="drop-file-valid"
                    class="vx-rounded-[8px] vx-text-sky-600 vx-flex vx-items-center vx-border vx-border-solid vx-border-sky-300 vx-bg-sky-100 vx-text-xs vx-px-2 vx-py-1">
                    <font-awesome-icon fixed-width :icon="faFileCheck" class="vx-text-sm vx-mr-1"></font-awesome-icon>
                    <span>{{ validations.validCount }} valid rows</span>
                </div>

                <div v-if="validations?.message || validations?.errorsCount"
                    class="vx-rounded-[8px] vx-mt-4 vx-overflow-hidden vx-border vx-border-solid vx-border-rose-300 vx-text-xs ">
                    <div @click="seeErrors = !seeErrors" data-test="drop-file-invalid"
                        class="vx-flex vx-items-center vx-justify-between vx-cursor-pointer vx-text-rose-600 vx-bg-rose-100 vx-px-2 vx-py-1">
                        <div class="vx-flex vx-items-center">
                            <font-awesome-icon fixed-width :icon="faHexagonXmark"
                                class="vx-text-sm vx-mr-1"></font-awesome-icon>
                            <span>{{ pluralized('error', validations?.errorsCount || 1, true) }}</span>
                        </div>
                        <font-awesome-icon :icon="seeErrors ? faChevronUp : faChevronDown"></font-awesome-icon>
                    </div>
                    <div v-if="seeErrors" data-test="drop-file-invalid-summary"
                        class="vx-bg-slate-50 vx-text-rose-700 vx-flex vx-flex-col vx-gap-2 vx-p-2">
                        <template v-if="!validations?.message">
                            <div v-for="(error, index) in validationsErrors.slice(0, 5)" :key="index">
                                {{ error }}
                            </div>
                            {{ validationsErrors.length > 5 ? '...' : '' }}
                            <vx-button v-if="validationsErrors.length > 5" @click="downloadErrors"
                                data-test="download-errors" size="sm" color="muted">
                                Download Error Log to See All
                                <font-awesome-icon :icon="faDownload"></font-awesome-icon>
                            </vx-button>
                        </template>
                        <template v-else>{{ validations.message }}</template>
                    </div>
                </div>
            </div>

        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxExpandableCard, VxButton } from '@voxie/frontend-components';
import { computed, ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronDown, faChevronUp, faDownload, faFileCheck, faFileCsv, faFileMedical, faHexagonXmark, faSpinner, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { validateCsvFile } from './validateCsvFile';
import fileDownload from 'js-file-download';
import { pluralized } from '~/components/filters';

const emit = defineEmits(['selected']);

const dragging = ref(false);

const file = ref();

const validations = ref(undefined);
const seeErrors = ref(false);

/**
 * @param {File|undefined} csv
 */
const setFile = async (csv) => {
    file.value = csv;

    if (!csv) {
        validations.value = undefined;
        emit('selected', undefined);
        return;
    }

    try {
        const validated = await validateCsvFile(csv)
        validations.value = validated

        if (!validations.value.nonRecoverableErrors?.length) {
            emit('selected', csv);
        }
    } catch (e) {
        validations.value = {
            message: e.message
        }
    }
}

const validationsErrors = computed(() => {
    if (!validations.value) {
        return [];
    }
    return [...validations.value.nonRecoverableErrors, ...validations.value.recoverableErrors];
})

const downloadErrors = () => {
    if (!validationsErrors.value.length || !file.value?.name) {
        return;
    }

    fileDownload(validationsErrors.value.join('\n'), `Errors ${file.value.name}.txt`)
}

const onFileDropped = (event) => {
    dragging.value = false;

    if (!event.dataTransfer.files[0]) {
        return;
    }

    setFile(event.dataTransfer.files[0]);
}

const onFileAdded = async (event) => {
    if (!event.target.files[0]) {
        return;
    }

    try {
        await setFile(event.target.files[0])
    } finally {
        event.target.value = ''
    }
}

const clearFile = () => {
    setFile(undefined)
}
</script>
