<template>
    <vx-modal :visible="Boolean(snippet)" @update:visible="emit('close')" :padding="false" size="md">
        <template v-slot:header>
            <div class="vx-p-6">
                Replace attachment
            </div>
        </template>

        <div class="vx-p-6" v-if="props.snippet">
            <div class="vx-mb-4">
                The snippet contains an attachment. Do you wish to replace the current attachment with the snippet attachment?
            </div>
            <div class="vx-flex vx-flex-col vx-gap-4 lg:vx-flex-row">
                <div class="vx-grow">
                    <strong class="vx-text-slate-800 vx-text-sm">Current attachment:</strong>
                    <message-builder-media-preview
                        :mediaUrl="props.currentMediaUrl"
                        :mediaContentType="props.currentMediaContentType"
                    ></message-builder-media-preview>
                </div>
                <div class="vx-grow">
                    <strong class="vx-text-slate-800 vx-text-sm">Snippet attachment:</strong>
                    <message-builder-media-preview
                        :mediaUrl="props.snippet.media_url"
                        :mediaContentType="props.snippet.media_content_type"
                    ></message-builder-media-preview>

                </div>
            </div>
        </div>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="no()"
                    data-test="swap-no"
                    type="button"
                    size="lg"
                    color="muted">
                    No
                    <font-awesome-icon :icon="faCircleX"></font-awesome-icon>
                </vx-button>
                <vx-button @click="yes()"
                    data-test="swap-yes"
                    type="button"
                    size="lg"
                    color="primary"
                    class="lg:vx-grow">
                    Yes
                    <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import { VxModal, VxButton } from '@voxie/frontend-components';
import MessageBuilderMediaPreview from './MessageBuilderMediaPreview.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleX, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

const props = defineProps({
    currentMediaUrl: String,
    currentMediaContentType:  String,
    snippet: Object,
})

const emit = defineEmits(['close', 'no', 'yes']);

const no = () => {
    emit('no', {
        ...props.snippet,
        media_url: undefined,
        media_content_type: undefined,
    });
    emit('close');
}

const yes = () => {
    emit('yes', props.snippet);
    emit('close');
}

</script>
