<template>
    <div class="search-container">
        <div class="c-field c-field--inline has-icon-right u-mr-small u-hidden-down@desktop">
            <input v-model="query" @keyup.enter="runSearch" class="c-input" type="text" :placeholder="placeholder" :disabled="collectionLoading">

            <span v-if="!query" class="c-field__icon" ref="searchIconWrap"><i class="fa fa-search"></i></span>
            <span
                v-if="searching"
                class="c-field__icon"
                ref="loadingIconWrap"
                data-test="loading-icon"
            >
                <i class="fa fa-spin fa-circle-o-notch"></i>
            </span>

            <a
                v-if="query && !searching"
                @click.prevent="cancelSearch"
                href="#"
                class="c-field__icon"
                ref="cancelIconWrap"
                data-test="cancel-icon"
            >
                <i class="fa fa-times"></i>
            </a>
        </div>
        <div v-if="hasHelp" class="search-simple__help u-mr-small">
            <popper>
                <a
                    @click.prevent
                    href="#"
                    class="u-text-tiny ">
                        {{ helpLabel }}
                </a>
                <template v-slot:content>
                    <slot></slot>
                </template>
            </popper>
        </div>
    </div>

</template>

<script>
import Popper from 'vue3-popper'

export default {
    components: {
        Popper
    },
    props: {
        defaultValue: {
            type: String,
            default: '',
        },
        collectionLoading: {
            type: Boolean,
            default: false,
        },
        searching: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: 'Search',
        },
        hasHelp: {
            type: Boolean,
            default: false,
        },
        helpLabel: {
            type: String,
            default: 'Search Help',
        },
    },
    emits: ['change'],
    data() {
        return {
            query: ''
        }
    },
    created() {
        this.query = this.defaultValue;
    },
    watch: {
        defaultValue: {
            handler(newData) {
                this.query = newData;
            },
            immediate: true
        }
    },
    methods: {
        runSearch() {
            this.emitChange()
        },
        cancelSearch() {
            this.query = ''
            this.emitChange()
        },
        emitChange() {
            this.$emit('change', this.query)
        }
    }
}
</script>

<style scoped lang="postcss">
.search-simple__help {
    text-align: right;
    position: absolute;
    right: 0;
    bottom: -16px;
    width: 100%;
}
</style>
