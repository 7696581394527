<template>
    <div>
        <vx-label size="lg">
            <slot name="label"></slot>
        </vx-label>
        <div class="vx-flex vx-flex-col vx-gap-2 lg:vx-flex-row lg:vx-gap-4">

            <div class="vx-grow">
                <message-builder :modelValue="props.modelValue?.message ?? ''"
                    @update:modelValue="emitValue('message', $event)"
                    :disableMedia="true"
                    :maxlength="props.maxlength"
                    preview
                    @blur="validation.message.$touch()"
                    >

                    <template v-slot:error v-if="validation.$invalid">
                        <template v-if="validation.message.$invalid">
                            {{ displayFirstError(validation.message.$errors) }}
                        </template>
                        <template v-else-if="validation.field_type.$invalid">
                            Data Type of Answer {{ displayFirstError(validation.field_type.$errors)?.toLowerCase() }}, adjust in <span @click.prevent="configModal = true" class="vx-underline vx-cursor-pointer">Settings</span>.
                        </template>
                        <template v-else-if="validation.custom_attribute.$invalid">
                            Answer Name {{ displayFirstError(validation.custom_attribute.$errors)?.toLowerCase() }}, adjust in <span @click.prevent="configModal = true" class="vx-underline vx-cursor-pointer">Settings</span>.
                        </template>
                    </template>
                </message-builder>

                <div
                    v-if="$slots.hint"
                    class="vx-px-2 vx-text-slate-500 vx-text-xs vx-pt-2"
                >
                    <slot name="hint"></slot>
                </div>
            </div>

            <div class="vx-grid vx-grid-cols-2 vx-gap-2 lg:vx-flex lg:vx-flex-col vx-min-w-11">

                <vx-button
                    @click.prevent="configModal = props.editable"
                    data-test="edit-settings"
                    type="button"
                    :disabled="!props.editable"
                    size="md"
                    block
                    :color="validation.field_type.$invalid || validation.custom_attribute.$invalid ? 'danger' : 'muted'"
                    outline
                    class="vx-col-start-1"
                    :class="{'vx-hidden':!props.editable}"
                >
                    <font-awesome-icon :icon="faGear"></font-awesome-icon>
                </vx-button>

                <vx-button
                    @click="emit('remove')"
                    type="button"
                    :disabled="!props.removable"
                    size="md"
                    block
                    color="muted"
                    outline
                    class="vx-col-start-2"
                    :class="{'vx-hidden':!props.removable}"
                >
                    <font-awesome-icon :icon="faTrashCan"></font-awesome-icon>
                </vx-button>

            </div>
        </div>

        <vx-modal v-if="editable" v-model:visible="configModal"
            size="md"
            :padding="false">
            <template v-slot:header>
                <div class="vx-pt-6 vx-px-6">
                    Question Settings
                </div>
            </template>
            <template v-slot:subheader>
                <div class="vx-px-6">
                    Configure settings for how answers are stored.
                </div>
            </template>

            <div class="vx-p-8">
                <dynamic-field-extraction
                    :disableFieldType="props.disableFieldType"
                    :fieldType="props.modelValue.field_type"
                    @update:fieldType="emitValue('field_type', $event)"
                    @blur:customAttribute="validation.custom_attribute.$touch()"
                    :errorCustomAttribute="displayFirstError(validation.custom_attribute.$errors)"

                    :customAttribute="props.modelValue.custom_attribute"
                    @update:customAttribute="emitValue('custom_attribute', $event)"
                    @blur:fieldType="validation.field_type.$touch()"
                    :errorFieldType="displayFirstError(validation.field_type.$errors)"
                    :multipleChoices="props.modelValue.multiple_choices"
                    @update:multipleChoices="emitValue('multiple_choices', $event)"
                ></dynamic-field-extraction>
            </div>

        </vx-modal>
    </div>
</template>

<script setup>
import { VxLabel, VxButton, VxModal } from '@voxie/frontend-components';
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faGear, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import DynamicFieldExtraction from '~/components/dynamic-fields/DynamicFieldExtraction.vue';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import { computed, ref } from 'vue';
import { csvInjection, customAttributeKey, displayFirstError } from '~/utils/validation';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => ({})
    },
    removable: {
        type: Boolean,
        default: false,
    },
    editable: {
        type: Boolean,
        default: false,
    },
    disableFieldType: {
        type: Boolean,
        default: false,
    },
    maxlength: {
        type: [ String, Number ],
    },
});

const emit = defineEmits(['update:modelValue', 'remove']);

const firstClass = (fieldType) => ['contact_name', 'email'].includes(fieldType);
const isNotFirstClass = computed(() => !firstClass(props.modelValue.field_type));

const emitValue = (key, value) => {
    const data = {
        ...props.modelValue,
        [key]: value,
    };

    if (firstClass(data.field_type)) {
        delete data.custom_attribute;
    }

    emit('update:modelValue', data);
}

const configModal = ref(false);

const validation = useVuelidate({
    message: {
        required,
    },
    field_type: !props.disableFieldType && props.editable ? { required, $autoDirty: true } : {},
    custom_attribute: props.editable && { requiredIfNotFirstClass: requiredIf(isNotFirstClass), csvInjection, customAttributeKey, $autoDirty: true }
}, computed(() => props.modelValue));

</script>
