<template>
    <div>
        <help-block
            v-if="readOnly"
            id="campaigns-contacts"
            type="success"
            :topPosition="70"
        >
            Campaign Contact list can be only edited in Draft state
        </help-block>

        <div class="row u-mb-small">
            <div class="col-lg-9">
                <h2>
                    Campaign: {{campaign.name}}
                </h2>
            </div>
            <div class="col-lg-3">
                <a href="#" class="c-btn c-btn--secondary pull-right" @click.prevent="close">
                    Close Contact Management
                </a>
            </div>
        </div>

        <resource-table
            :table-title="tableTitle"
            :table-columns="tableColumns"
            :search-feature="true"
            :select-all-rows-feature="true"
            :search-include-params="['campaigns']"
            :search-help="true"
            :page-url="pageUrl"
            :search-url="searchUrl"
            :entity-name="entityName"
            :entity-fetch-client="campaignsApiClient.campaignsAllContacts.bind(campaignsApiClient, campaign.id, true)"
            :entity-search-client="contactsApiClient.searchContacts.bind(contactsApiClient)"
            v-on:collection-changed="collectionChanged"
        >
            <template v-slot:actions>
                <i v-if="bulkOperating" class="pull-right u-mt-xsmall fa fa-spin fa-circle-o-notch hidden-xs"></i>

                <button @click="bulkRemove" :disabled="!showBulkOps || readOnly" class="pull-right c-btn c-btn--warning hidden-xs">Remove Selected</button>
                <button @click="bulkAdd" :disabled="!showBulkOps || readOnly" class="u-mr-xsmall pull-right c-btn c-btn--success hidden-xs">Add Selected</button>
            </template>

            <template v-slot:search-popover>
                <div>
                    <p>
                        This search function will find contacts by full or partial
                        match on first name, last name, email, or phone.
                    </p>
                </div>
            </template>

            <template v-slot:table-row="slotProps">
                <campaign-contact-table-row
                    :contact="slotProps.entity"
                    :campaign="campaign"
                    :mIndex="slotProps.index"
                    :key="slotProps.entity.id"
                    :read-only="readOnly"
                ></campaign-contact-table-row>
            </template>
        </resource-table>
    </div>
</template>


<script>
import { campaignsClient, contactsClient } from '../../services';
import { statuses as CampaignStatus, durationType as CampaignDurationType } from '../../constants/campaign'
import { camelCase } from '~/utils/string';
import HelpBlock from '../general/HelpBlock.vue';
import ResourceTable from '../../components/management/ResourceTable.vue';
import CampaignContactTableRow from '../../components/campaign-contacts/CampaignContactTableRow.vue';
import clientRoutes from './../../mixins/client-routes';

export default {
    props: ['campaign'],
    emits: ['close'],

    inject: ['FEATURES'],

    mixins: [
        clientRoutes
    ],

    components: {
        HelpBlock,
        ResourceTable,
        CampaignContactTableRow,
    },

    data() {
        return {
            teamId: Spark.state.currentTeam.id,
            bulkOperating: false,
            collection: [],

            campaignsApiClient: campaignsClient,
            contactsApiClient: contactsClient,

            pageUrl: `campaign-contacts/${this.campaign.id}`,
            searchUrl: `campaign-contacts/${this.campaign.id}/search`,
            entityName: 'Contact',
            tableTitle: 'Manage Campaign Contacts',
            tableColumns: [
                {
                    name: 'Contact',
                    classNames: ['hidden-xs', 'hidden-sm']
                },
                {
                    name: 'Identity',
                    classNames: []
                },
                {
                    name: 'Campaign Sends',
                    classNames: ['center']
                },
                {
                    name: 'Add / Remove',
                    classNames: ['center']
                },
            ]
        };
    },

    mounted() {
        Bus.$on('campaignContactsAdd', (contactId, campaignId) => {
            if (this.campaign.id === campaignId) {
                this.addToCampaign([contactId]);
            }
        });

        Bus.$on('campaignContactsRemove', (contactId, campaignId) => {
            if (this.campaign.id === campaignId) {
                this.removeFromCampaign([contactId]);
            }
        });

        this.loadCollectionAndUi();

    },

    beforeUnmount() {
        Bus.$off('campaignContactsAdd');
        Bus.$off('campaignContactsRemove');
    },

    computed: {
        readOnly() {
            return (!this.canAddContactsForCampaignDuration) || (this.campaign?.status === CampaignStatus.PUBLISHED && this.campaign?.duration_type === CampaignDurationType.ONE_TIME);
        },
        showBulkOps() {
            return this.collection.filter(item => item.selected).length > 1;
        },
        campaignCompleted() {
            return this.campaign.status === CampaignStatus.COMPLETED;
        },
        canAddContactsForCampaignDuration() {
            const {status, duration_type} = this.campaign;

            return [CampaignStatus.DRAFT, CampaignStatus.SCHEDULED].includes(status)
                || ([CampaignDurationType.PREDEFINED, CampaignDurationType.EVERGREEN].includes(duration_type) && [CampaignStatus.PUBLISHED, CampaignStatus.PAUSED].includes(status));
        }
    },

    methods: {
        close() {
            Bus.$emit('cancelCampaignsManageContacts');
            this.$emit('close')
        },

        collectionChanged(collection) {
            this.collection = collection;
        },

        bulkAdd() {
            this.bulkOperating = true;

            const contactIds = this.collection.reduce((acc, next) => {
                if (next.selected) {
                    return [ ...acc, next.id ];
                } else {
                    return acc;
                }
            }, []);

            this.addToCampaign(contactIds);
        },

        bulkRemove() {
            this.bulkOperating = true;

            const contactIds = this.collection.reduce((acc, next) => {
                if (next.selected) {
                    return [ ...acc, next.id ];
                } else {
                    return acc;
                }
            }, []);

            this.removeFromCampaign(contactIds);
        },

        addToCampaign(ids) {
            ids.forEach(id => Bus.$emit(`campaignContactsPending-${id}`, true));

            campaignsClient.addContactsToCampaign(this.teamId, this.campaign.id, ids)
                .then(response => {
                    response.data.forEach(contactId => {
                        Bus.$emit(`campaignContactsPending-${contactId}`, false);

                        const contactIndex = this.collection.findIndex(c => c.id === contactId);

                        if (contactIndex >= 0) {
                            const campInd = this.collection[contactIndex].campaigns.findIndex(c => c.id === this.campaign.id);

                            if(campInd === -1) {
                                this.collection[contactIndex].campaigns.push(this.campaign);
                            }
                        }
                    });
                })
                .catch (error => {
                    this.$toasted.global.platform_error();
                    console.log(error.response);
                })
                .finally(() => {
                    this.bulkOperating = false;
                    Bus.$emit('campaignContactsClearPending');
                });
        },

        removeFromCampaign(ids) {
            ids.forEach(id => Bus.$emit(`campaignContactsPending-${id}`, true));

            campaignsClient.campaignsDeleteContacts(this.teamId, this.campaign.id, ids)
                .then(response => {
                    response.data.forEach(contactId => {
                        Bus.$emit(`campaignContactsPending-${contactId}`, false);

                        const contactIndex = this.collection.findIndex(c => c.id === contactId);

                        if (contactIndex >= 0) {
                            //remove the campaign from the contact
                            this.collection[contactIndex].campaigns =
                                this.collection[contactIndex].campaigns.filter(cp =>
                                    cp.id !== this.campaign.id
                                );
                        }
                    });
                })
                .catch (error => {
                    this.$toasted.global.platform_error();
                    console.log(error.response);
                })
                .finally(() => {
                    this.bulkOperating = false;
                    Bus.$emit('campaignContactsClearPending');
                });
        },

        loadCollectionAndUi() {
            const route = this.currentClientRoute();
            const eventEntity = camelCase(this.entityName);

            if (route) {

                if (route.hash === 'campaign-contacts' && route.parameters.length === 2) {
                    Bus.$emit(`${eventEntity}UpdateResourceTable`, {
                        searchParam: null,
                        cursor: route.parameters[1]
                    })
                } else if (route.hash === 'campaign-contacts' && route.parameters.length >= 3 && route.parameters[1] === 'search') {
                    Bus.$emit(`${eventEntity}UpdateResourceTable`, {
                        searchParam: route.parameters[2],
                        cursor: null
                    })
                } else {
                    Bus.$emit(`${eventEntity}UpdateResourceTable`, {
                        searchParam: null,
                        cursor: null
                    })
                }
            } else {
                Bus.$emit(`${eventEntity}UpdateResourceTable`, {
                    searchParam: null,
                    cursor: null
                })
            }
        },
    }
}
</script>
