<template>
    <div class="card card-default" v-if="user && !user.isUsingSingleSignOn">
        <div class="card-header">Profile Photo</div>

        <div class="card-body">
            <div class="alert alert-danger" v-if="form.errors.has('photo')">
                {{ form.errors.get('photo') }}
            </div>

            <form role="form">
                <div class="form-group row justify-content-center">
                    <div class="col-md-6 d-flex align-items-center">
                        <div class="image-placeholder mr-4">
                            <span role="img" class="profile-photo-preview" :style="previewStyle"></span>
                        </div>
                        <div class="spark-uploader mr-4">
                            <input ref="photo" type="file" class="spark-uploader-control" name="photo" @change="update" :disabled="form.busy">
                            <div class="btn btn-outline-dark">Update Photo</div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from '~/services/axios';

export default {
    props: ['user'],

    /**
     * The component's data.
     */
    data() {
        return {
            form: new SparkForm({})
        };
    },

    created() {
        Bus.$on(`sparkFormUpdated.${this.form.sparkFormId}`, this.$forceUpdate)
    },

    methods: {
        /**
         * Update the user's profile photo.
         */
        update(e) {
            e.preventDefault();

            if (! this.$refs.photo.files.length) {
                return;
            }

            var self = this;

            this.form.startProcessing();

            // We need to gather a fresh FormData instance with the profile photo appended to
            // the data so we can POST it up to the server. This will allow us to do async
            // uploads of the profile photos. We will update the user after this action.
            axios.post('/settings/photo', this.gatherFormData())
                .then(
                    () => {
                        Bus.$emit('updateUser');

                        self.form.finishProcessing();
                    },
                    (error) => {
                        self.form.setErrors(error.response.data.errors);
                    }
                );
        },


        /**
         * Gather the form data for the photo upload.
         */
        gatherFormData() {
            const data = new FormData();

            data.append('photo', this.$refs.photo.files[0]);

            return data;
        }
    },


    computed: {
        /**
         * Calculate the style attribute for the photo preview.
         */
        previewStyle() {
            return `background-image: url(${this.user.photo_url})`;
        }
    }
}
</script>
