<template>
    <vx-box-glass active>
        <home-slider :autoPlay="false">
            <div
                v-if="FEATURES.simple_message_hub"
                class="vx-grid vx-gap-8 vx-p-8 vx-pb-0 @xl:vx-grid-cols-2"
            >
                <div class="@xl:vx-pb-24">
                    <h2 class="vx-text-xl vx-font-extrabold">
                        Voxie Arrives on Android
                    </h2>
                    <p class="vx-mb-4 vx-mt-2 vx-text-sm vx-text-slate-500">
                        Get real-time notifications on your phone for new
                        messages. Respond quickly and efficiently, ensuring
                        every conversation gets the attention it&nbsp;deserves.
                    </p>
                    <vx-button
                        href="https://play.google.com/store/apps/details?id=com.voxie.mobile"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Get the App
                        <font-awesome-icon :icon="faArrowUpRightFromSquare" />
                    </vx-button>
                </div>
                <div class="vx-flex vx-self-end @xl:vx-flex-row">
                    <img
                        src="https://res.cloudinary.com/dsztk6f59/image/upload/w_320,dpr_2.0,f_auto/v1720812786/android-art_xc70k3.png"
                        alt="android phone with voxie and android logos"
                        class="vx-mx-auto vx-w-full vx-max-w-[320px] vx-aspect-[640/508]"
                    />
                </div>
            </div>

            <div
                class="vx-grid @xl:vx-grid-cols-[.4fr_.6fr] vx-gap-6 vx-p-8 @xl:vx-flex-row @xl:vx-pb-0"
            >
                <div class="@2xl:vx-flex-shrink-[1.2] @xl:vx-pb-24">
                    <h2 class="vx-text-xl vx-font-extrabold">
                        Introducing Quick&nbsp;Blast
                    </h2>
                    <p class="vx-mb-4 vx-mt-2 vx-text-sm vx-text-slate-500">
                        The fastest way to send a single message to multiple
                        people. This intro video will get you generating
                        results&nbsp;today!
                    </p>
                    <vx-button type="button" @click="tutorial = true">
                        Watch Tutorial
                        <font-awesome-icon
                            :icon="faCirclePlay"
                        ></font-awesome-icon>
                    </vx-button>
                </div>

                <div
                    class="vx-relative @xl:vx-pb-8"
                    vx-flex
                    vx-items-end
                    vx-justify-end
                >
                    <button
                        @click="tutorial = true"
                        role="button"
                        class="vx-group/intro-video vx-m-0 vx-p-0 vx-relative vx-bg-transparent vx-w-full vx-max-w-[511px]"
                    >
                        <img
                            src="https://res.cloudinary.com/dsztk6f59/image/upload/w_511,dpr_2.0,f_auto/v1720812036/quickblast-preview_nwwgvr.jpg"
                            alt="Quick Blast Demo Video Screenshot"
                            class="vx-rounded-xl vx-w-full vx-aspect-[1022/593]"
                        />
                        <font-awesome-icon
                            :icon="faCirclePlay"
                            class="vx-absolute vx-inset-0 vx-m-auto vx-h-auto vx-w-12 vx-text-sky-400 group-hover/intro-video:vx-text-sky-500 vx-transition"
                        />
                    </button>
                </div>
            </div>

            <div
                class="vx-flex vx-flex-col vx-justify-between vx-p-8 vx-pb-0 @xl:vx-flex-row"
            >
                <div class="@xl:vx-pb-24">
                    <h2 class="vx-text-xl vx-font-extrabold">
                        Expand Your Reach
                    </h2>
                    <p class="vx-mb-4 vx-mt-2 vx-text-sm vx-text-slate-500">
                        Did you know you can significantly increase your reach
                        by simply adding our contact collector to
                        your&nbsp;website?
                    </p>
                    <vx-button href="/settings/integrations#/contact-collector">
                        Connect Your Website
                        <font-awesome-icon :icon="faArrowRight" />
                    </vx-button>
                </div>
                <div
                    class="-vx-mx-3 vx-overflow-hidden vx-flex vx-items-end vx-justify-end @xl:-vx-ml-24 @xl:vx-w-[330px] @2xl:vx-w-[400px] @xl:vx-flex-shrink-0 -vx-z-10"
                >
                    <img
                        src="https://res.cloudinary.com/dsztk6f59/image/upload/w_535,dpr_2.0,f_auto/v1721143202/reach_art_b5qxvy.png"
                        alt="paper airplane graphic"
                        class="vx-mb-12 @sm:vx-mb-8 @md:vx-mb-0 @xl:-vx-mb-8 vx-w-full vx-max-w-[535px] vx-aspect-[1070/676]"
                    />
                </div>
            </div>
        </home-slider>
    </vx-box-glass>
    <vx-modal
        v-model:visible="tutorial"
        :padding="false"
        class="vx-max-w-[1200px] vx-p-4 sm:vx-p-10"
    >
        <template v-slot:header>
            <div class="vx-mb-4">Quick Blast Introduction</div>
        </template>
        <iframe
            src="https://www.loom.com/embed/3551f9676b3a4af980469a96d1b7899b?hideEmbedTopBar=true&autoplay=true"
            frameborder="0"
            class="vx-w-full vx-h-full vx-block vx-rounded-xl vx-aspect-video"
        >
        </iframe>
    </vx-modal>
</template>

<script setup>
import { VxBoxGlass, VxButton, VxModal } from "@voxie/frontend-components";
import { ref, inject } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faCirclePlay,
    faArrowUpRightFromSquare,
    faArrowRight,
} from "@fortawesome/pro-solid-svg-icons";
import HomeSlider from "./HomeSlider.vue";

const FEATURES = inject("FEATURES");

const tutorial = ref(false);
</script>
