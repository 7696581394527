<template>
    <div
        v-show="visible"
        id="snippets-variables"
        :class="[
            'snippets-variables',
            noSnippets && 'pt-2',
        ]"
    >
        <template v-if="!noSnippets">
            <ul class="sm-nav-tabs" style="padding: 0 10px">
                <li :class="{active: isSnippetsOpen}" @click.stop="showSnippets">
                    Snippets
                </li>
                <li :class="{active: isVariablesOpen}" @click.stop="showVariables">
                    Variables
                </li>
            </ul>
            <span class="line mt-0"></span>
        </template>
        <templates-picker-snippets-tab
            v-if="!noSnippets && mountSnippetsTab"
            :style="isSnippetsOpen ? '' : 'display:none!important'"
            :allow-create="allowCreate"
            :disable-media="disableMedia"
            @select="$emit('select', $event)"
        />
        <templates-picker-variables-tab
            v-if="mountVariablesTab"
            :style="isVariablesOpen ? '' : 'display:none!important'"
            :input="input"
            @variableInserted="$emit('variableInserted', $event)"
        />
    </div>
</template>

<script>
import TemplatesPickerSnippetsTab from './TemplatesPickerSnippetsTab.vue';
import TemplatesPickerVariablesTab from './TemplatesPickerVariablesTab.vue';


export default {
    name: "TemplatesPicker",
    components: { TemplatesPickerSnippetsTab, TemplatesPickerVariablesTab },
    props: {
        visible: {
            type: Boolean,
            default: true,
        },
        input: {
            required: true,
        },
        allowCreate: {
            type: Boolean,
            default: true
        },
        noSnippets: {
            type: Boolean,
            default: false,
        },
        disableMedia: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeTab: this.noSnippets ? 'variables' : 'snippets',
            mountSnippetsTab: !this.noSnippets && this.visible,
            mountVariablesTab: this.noSnippets && this.visible,
        };
    },
    computed: {
        isSnippetsOpen() {
            return this.activeTab === 'snippets';
        },
        isVariablesOpen() {
            return this.activeTab === 'variables';
        },
    },
    watch: {
        visible(val) {
            if (val) {
                if (this.isSnippetsOpen) {
                    this.mountSnippetsTab = true;
                } else {
                    this.mountVariablesTab = true;
                }
            }
        },
    },

    methods: {
        showSnippets() {
            this.activeTab = 'snippets';
            this.mountSnippetsTab = true;
        },

        showVariables() {
            this.activeTab = 'variables';
            this.mountVariablesTab = true;
        },
    },
}
</script>
