<template>
    <page-container class="vx-grid vx-grid-cols-12 vx-gap-8">
        <page-sidebar class="md:vx-col-span-4"
            :links="sidebarLinks"
            :bold="false"></page-sidebar>
        <div ref="content"
            class="vx-col-span-12 md:vx-col-span-8 lg:vx-col-span-10">
            <kiosk-users v-if="hash === 'users'" :userId="userId"></kiosk-users>
            <kiosk-teams v-if="hash === 'teams'"></kiosk-teams>
        </div>
    </page-container>
</template>

<script setup>
import { faUserUnlock, faUsers } from '@fortawesome/pro-solid-svg-icons';
import KioskUsers from './KioskUsers.vue';
import KioskTeams from './KioskTeams.vue';
import { useBrowserLocation } from '@vueuse/core';
import PageContainer from '../general/PageContainer.vue';
import PageSidebar from '../general/PageSidebar.vue';
import { computed } from 'vue';
import { pathToRegexp } from 'path-to-regexp';

const location = useBrowserLocation();

const hash = computed(() => location.value.hash?.replace('#/', '').replace('#', '')?.split('/')?.[0])
const userId = computed(() => location.value.hash?.replace('#/', '').replace('#', '')?.split('/')?.[1])

if (!hash.value) {
    location.value.hash = '#/teams';
}

const sidebarLinks = [
    {
        label: 'Kiosk',
        links: [
            {
                label: 'Teams',
                href: '/spark/kiosk#/teams',
                pathToRegexp: pathToRegexp(['/spark/kiosk#/teams(.*)']),
                icon: faUsers,
            },
            {
                label: 'Users',
                href: '/spark/kiosk#/users',
                pathToRegexp: pathToRegexp(['/spark/kiosk#/users(.*)']),
                icon: faUserUnlock,
            },
        ]
    }
]
</script>
