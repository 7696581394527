<template>
  <div
    class="vx-mb-4 md:vx-mb-6 vx-flex vx-flex-wrap vx-items-center vx-justify-between vx-gap-4 vx-w-full sm:vx-flex-nowrap"
    :class="!$slots.actions && 'lg:vx-h-12'"
  >
    <div class="vx-flex vx-flex-col vx-justify-center">
      <span
       v-if="$slots.subtitle"
        class="vx-font-sans vx-text-slate-400"
      >
        <slot name="subtitle" />
      </span>

      <h1
        v-if="$slots.default"
        class="vx-text-2xl xl:vx-text-3xl vx-font-sans vx-font-extrabold vx-leading-9 vx-m-0 vx-flex vx-items-center"
      >
        <slot />
      </h1>
    </div>
  <div class="vx-flex vx-grow vx-justify-end vx-gap-2">
      <slot name="actions" />
  </div>
</div>
</template>
<script setup></script>
