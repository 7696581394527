<template>
    <div>
        <!-- Loading Indicator -->
        <div class="row" v-if="loading">
            <div class="col-md-12">
                <div class="card card-default">
                    <div class="card-body"><i class="fa fa-btn fa-spinner fa-spin"></i> Loading</div>
                </div>
            </div>
        </div>

        <!-- User Profile -->
        <div v-if="!loading && profile">
            <div class="row">
                <div class="col-md-12">
                    <page-header>
                        {{ profile.name }}
                        <template v-slot:subtitle>
                            <a href="#/users"
                                class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                                <font-awesome-icon class="vx-mr-1"
                                    :icon="faArrowLeft"></font-awesome-icon>
                                Go Back
                            </a>
                        </template>
                    </page-header>
                    <div class="card card-default" data-test="user-card">
                        <div class="card-body">
                            <div class="row">
                                <!-- Profile Photo -->
                                <div class="col-md-3 text-center">
                                    <img :src="profile.photo_url" class="spark-profile-photo-xl" alt="Profile Photo" />
                                </div>

                                <div class="col-md-9">
                                    <!-- Email Address -->
                                    <p>
                                        <strong>Email Address: </strong>
                                        <a :href="'mailto:' + profile.email">{{ profile.email }}</a>
                                    </p>

                                    <!-- Joined Date -->
                                    <p><strong>Joined Date: </strong>{{ dateTime(profile.created_at) }}</p>

                                    <!-- Using SSO -->
                                    <p><strong>Using SSO: </strong>{{ profile.isUsingSingleSignOn ? 'Yes' : 'No' }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer card-flush text-right">
                            <button
                                class="btn btn-default btn-sm"
                                @click="impersonate(profile)"
                                :disabled="currentUserId === profile.id || profile.isUsingSingleSignOn"
                                data-test="impersonate-button"
                            >
                                Impersonate
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Teams -->
            <div class="row" v-if="profile.owned_teams.length > 0" data-test="teams-card">
                <div class="col-md-12">
                    <div class="card card-default">
                        <div class="card-header">Teams</div>

                        <div class="table-responsive">
                            <table class="table table-valign-middle mb-0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Subscription</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="team in profile.owned_teams" :key="team.id">
                                        <!-- Photo -->
                                        <td>
                                            <img :src="team.photo_url" class="spark-profile-photo" alt="Team Photo" />
                                        </td>

                                        <!-- Team Name -->
                                        <td>
                                            <div>
                                                {{ team.name }}
                                            </div>
                                        </td>

                                        <!-- Subscription -->
                                        <td>
                                            <div>
                                                <span v-if="activePlan(team)">
                                                    <a :href="customerUrlOnBillingProvider(team)" target="_blank">
                                                        {{ activePlan(team).name }} ({{ activePlan(team).interval }})
                                                    </a>
                                                </span>

                                                <span v-else> None </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from '../general/PageHeader.vue';
import { currency, dateTime } from '~/utils/helpers';
import dayjs from '~/utils/dayjs';
import axios from '~/services/axios';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    components: {
        PageHeader,
        FontAwesomeIcon,
    },
    props: ['userId','plans'],
    data() {
        return {
            loading: false,
            profile: null,
            revenue: 0,
            currentUserId: Spark.state.user.id,
        };
    },
    watch: {
        userId: function (userId) {
            if (userId) {
                this.getUserProfile(userId);
            } {
                this.profile = null;
                this.revenue = null;
            }
        }
    },
    mounted() {
        window.Mousetrap.bind('esc', () => {
            window.location.hash = '#/users';
        });
        if (this.userId) {
            this.getUserProfile(this.userId);
        }
    },
    computed: {
        faArrowLeft: () => faArrowLeft,
    },
    methods: {
        currency,
        dateTime,
        impersonate(user) {
            if (user.teams.length) {
                window.location = '/spark/kiosk/users/impersonate/' + user.id;
            } else {
                this.$toasted.show('It is not possible to impersonate a user that is a member of no teams');
            }
        },
        /**
         * Get the profile user.
         */
        getUserProfile(id) {
            this.loading = true;

            axios.get('/spark/kiosk/users/' + id + '/profile').then((response) => {
                this.profile = response.data.user;
                this.revenue = response.data.revenue;

                this.loading = false;
            });
        },

        /**
         * Get the plan the user is actively subscribed to.
         */
        activePlan(team) {
            const activeSubscription = this.activeSubscription(team);
            if (!activeSubscription) {
                return;
            }

            return this.plans?.find((plan) => {
                    return plan.id === activeSubscription.provider_plan;
            });
        },

        /**
         * Get the active, valid subscription for the user.
         */
        activeSubscription(team) {
            const subscription = this.subscription(team);

            if (!subscription || (subscription.ends_at && dayjs.utc().isAfter(dayjs.utc(subscription.ends_at)))) {
                return;
            }

            return subscription;
        },

        /**
         * Get the active subscription instance.
         */
        subscription(team) {
            return team?.subscriptions?.find((subscription) => subscription.name === 'default');
        },

        /**
         * Get the customer URL on the billing provider's website.
         */
        customerUrlOnBillingProvider(team) {
            if (!team) {
                return;
            }

            return 'https://dashboard.stripe.com/customers/' + team.stripe_id;
        },

    },
};
</script>
