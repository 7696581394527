import { ref } from 'vue';
import { useToasted } from '~/composables/useToasted';
import parseLinkHeader from 'parse-link-header';

export const useCursorPagination = () => {
    const loading = ref(false);
    const list = ref([]);
    const fetchParams = ref({});
    const toasted = useToasted();

    const fetchRecords = async (fetchRequest, filterBy = '') => {
        if (loading.value) {
            return;
        }

        loading.value = true;
        try {
            const response = await fetchRequest;

            const data = filterBy ? filterData(response.data.data, filterBy) : response.data.data;
            list.value = !fetchParams.value.cursor ? response.data.data : [...list.value, ...data];

            // set next cursor for subsequent request
            if (response.headers?.link) {
                const nextOrPrev =
                    fetchParams.value.forward == undefined || fetchParams.value.forward ? 'next' : 'prev';
                const cursorLink = parseLinkHeader(response.headers?.link)?.[nextOrPrev];

                fetchParams.value = {
                    ...fetchParams.value,
                    cursor: cursorLink?.cursor,
                };
            } else {
                const nextPageParams = new URLSearchParams(response.data.next_page_url?.split('?')[1]);
                fetchParams.value = {
                    ...fetchParams.value,
                    cursor: nextPageParams.get('cursor'),
                };
            }
        } catch (e) {
            console.error(e);
            toasted.global.platform_error();
        } finally {
            loading.value = false;
        }
    };

    const filterData = (data, filterBy) => {
        return data.filter((item) => {
            return !list.value.some((listItem) => listItem[filterBy] === item[filterBy]);
        });
    };

    return { list, loading, fetchParams, fetchRecords };
};
