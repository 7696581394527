<template>
    <div>
        <template v-if="isTeamAdmin">
            <!-- Send Invitation -->
            <div v-if="user && team">
                <send-invitation
                    :user="user"
                    :team="team"
                    :default-role="defaultRole"
                    @update-invitations="getInvitations"
                />
            </div>

            <!-- Pending Invitations -->
            <div v-if="invitations && invitations.length > 0">
                <mailed-invitations :team="team" :invitations="invitations" @update-invitations="getInvitations" />
            </div>
        </template>

        <!-- Team Members -->
        <div v-if="user && team">
            <team-members :user="user" :team="team" />
        </div>
    </div>
</template>

<script>
import SendInvitation from './SendInvitation.vue';
import MailedInvitations from './MailedInvitations.vue';
import TeamMembers from './TeamMembers.vue';
import axios from '~/services/axios';

export default {
    components: {
        SendInvitation,
        MailedInvitations,
        TeamMembers,
    },

    props: ['user', 'team', 'isTeamAdmin', 'defaultRole'],

    /**
     * The component's data.
     */
    data() {
        return {
            invitations: [],
        };
    },

    /**
     * The component has been created by Vue.
     */
    created() {
        this.getInvitations();
    },

    methods: {
        /**
         * Get all of the invitations for the team.
         */
        getInvitations() {
            axios.get(`/settings/teams/${this.team.id}/invitations`).then((response) => {
                this.invitations = response.data;
            });
        },
    },
};
</script>
