<template>
    <div>
        <vx-label size="lg">Select a Campaign</vx-label>
        <select-campaign size="lg"
            v-model="data.campaign_id"
            :flowId="props.flowId"
            :error="displayFirstError(validate.campaign_id)"></select-campaign>

        <use-event-contact-action-data class="vx-mt-4" v-model="data"></use-event-contact-action-data>
    </div>
</template>

<script setup>
import SelectCampaign from '~/components/general/SelectCampaign.vue';
import UseEventContactActionData from './UseEventContactActionData.vue';
import { VxLabel } from '@voxie/frontend-components';
import { displayFirstError } from '~/utils/validation';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

const props = defineProps({
    flowId: String
});

const data = defineModel();

const validate = useVuelidate({
    campaign_id: {
        required: helpers.withMessage('Campaign is required', required)
    }
}, data)
</script>
