<template>
    <div class="vx-mb-4">
        <vx-label>
            Custom Attribute
        </vx-label>

        <div class="vx-flex vx-flex-col vx-gap-2 lg:vx-flex-row">
            <div class="vx-grow">
                <vx-input :modelValue="props.modelValue.key"
                    placeholder="Key"
                    size="lg"
                    @update:modelValue="emitValue('key', $event.toLowerCase())"
                    :maxlength="80"
                    @blur="validate.key.$touch()"
                    :error="displayFirstError(validate.key.$errors)"
                    ></vx-input>
            </div>

            <div class="">
                <vx-tabs size="lg" :modelValue="props.modelValue.mode" @update:modelValue="emitValue('mode', $event)">
                    <vx-tab value="include">Exists</vx-tab>
                    <vx-tab value="exclude" class="vx-whitespace-nowrap">Does Not Exists</vx-tab>
                </vx-tabs>
            </div>

            <vx-button @click="emit('remove')"
                class="vx-shrink-0"
                type="button"
                size="lg"
                color="muted"
                outline>
                <font-awesome-icon :icon="faTrashCan"></font-awesome-icon>
            </vx-button>
        </div>
    </div>
</template>

<script setup>
import { VxInput, VxButton, VxLabel, VxTabs, VxTab } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import useVuelidate from '@vuelidate/core';
import { computed } from 'vue';
import { required } from '@vuelidate/validators';
import { customAttributeKey, csvInjection, displayFirstError } from '~/utils/validation';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => ({})
    }
});

const emit = defineEmits(['update:modelValue', 'remove']);

const emitValue = (key, value) => {
    emit('update:modelValue', {
        ...props.modelValue,
        [key]: value
    })
}

const validate = useVuelidate({
    key: { required, csvInjection, customAttributeKey, }
}, computed(() => props.modelValue));

</script>
