<template>
    <div class="vx-space-y-8 md:vx-space-y-12 vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8">
        <div v-if="loading"
            class="vx-text-center vx-py-10">
            <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
        </div>
        <template v-else-if="!success || campaign?.flow_id">
            <step-form-heading v-if="!props.campaignId"
                :icon="faFlagSwallowtail">
                New Campaign

                <template v-slot:description>
                    Create one-time, scheduled, and evergreen campaigns.
                </template>
            </step-form-heading>

            <page-header v-if="props.campaignId"
                class="vx-mb-6 lg:vx-mb-12">
                <template v-slot:subtitle>
                    <a href="/messaging/campaigns"
                        class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                        <font-awesome-icon class="vx-mr-1"
                            :icon="faArrowLeft"></font-awesome-icon>
                        All Campaigns
                    </a>
                </template>

                {{ campaign.name }}
            </page-header>

            <div :class="{
                'vx-grid vx-grid-cols-12': props.campaignId,
            }">
                <page-sidebar v-if="props.campaignId"
                    :links="[
                        { href: `/messaging/campaigns/${props.campaignId}`, label: 'Details' },
                        { href: `/messaging/campaigns/${props.campaignId}/contacts`, label: 'Contacts' },
                    ]"></page-sidebar>
                <vx-sticky-nav :sections="[
                    { id: 'name', label: 'Name' },
                    { id: 'category', label: 'Category' },
                    { id: 'audience_type', label: 'Audience' },
                    { id: 'when', label: 'When' },
                    { id: 'what', label: 'What' },
                    { id: 'to', label: 'To' },
                ]"
                    class="vx-col-span-12"
                    :class="{
                        'lg:vx-col-span-10': !!props.campaignId,
                    }"
                    :navClasses="`${success || props.campaignId ? 'vx-hidden' : ''}`">
                    <div v-if="campaign?.flow_id"
                        class="vx-max-w-screen-sm vx-w-full"
                        :class="{
                            'vx-mx-auto': !props.campaignId,
                        }">
                        <flow-resource-state-alert :resourceName="campaign?.name"
                            :success="success"
                            :editing="!!campaign?.id"
                            :flowId="campaign?.flow_id"
                            :flowSection="campaign?.flow_section">
                        </flow-resource-state-alert>
                    </div>

                    <div v-if="!success || props.campaignId"
                        class="vx-flex vx-flex-col vx-w-full vx-justify-center vx-gap-2 md:vx-gap-8"
                        :class="{
                            'vx-mx-auto': !props.campaignId,
                        }">
                        <campaign-step-form-name id="name"
                            v-model="campaign.name"
                            class="vx-max-w-screen-sm vx-w-full"
                            :class="{
                              'vx-mx-auto': !props.campaignId,
                            }"
                            :error="errors.name || displayFirstError(validation.name.$errors)"></campaign-step-form-name>
                        <resource-category
                            id="category"
                            class="vx-max-w-screen-sm vx-w-full"
                            :class="{
                              'vx-mx-auto': !props.campaignId,
                            }"
                            v-model="campaign.category"
                            :disabled="!!campaign.flow_id">
                        </resource-category>
                        <campaign-step-form-audience-type
                            id="audience_type"
                            class="vx-max-w-screen-sm vx-w-full"
                            :class="{
                              'vx-mx-auto': !props.campaignId,
                            }"
                            :disabled="disabled"
                            v-model="campaign.type"
                            :campaignSegments="campaign?.segments || []"></campaign-step-form-audience-type>
                        <campaign-step-form-when
                            id="when"
                            class="vx-max-w-screen-sm vx-w-full"
                            :class="{
                              'vx-mx-auto': !props.campaignId,
                            }"
                            ref="whenStepRef"
                            :disabled="disabled"
                            :campaignStatus="campaign.status"
                            :campaignStartedAt="campaign.started_at"
                            :originalLiveAt="campaign.original_live_at"
                            v-model:isRepeatable="campaign.is_repeatable"
                            v-model:durationType="campaign.duration_type"
                            v-model:liveAt="campaign.live_at"
                            v-model:completeAt="campaign.complete_at"
                            v-model:timeZoneAware="campaign.time_zone_aware"></campaign-step-form-when>
                        <campaign-step-form-what
                            id="what"
                            class="vx-max-w-screen-sm vx-w-full"
                            :class="{
                              'vx-mx-auto': !props.campaignId,
                            }"
                            :campaignId="campaign.id"
                            v-model="campaign.campaign_messages"></campaign-step-form-what>
                        <campaign-step-form-to
                            id="to"
                            class="vx-max-w-screen-sm vx-w-full"
                            :class="{
                              'vx-mx-auto': !props.campaignId,
                            }"
                            :disabled="disabled"
                            :campaignId="campaign.id"
                            :campaignType="campaign.type"
                            :campaignDurationType="campaign.duration_type"
                            :campaignStatus="campaign.status"
                            :flowId="campaign?.flow_id"
                            v-model:originalCampaignId="campaign.original_campaign_id"
                            v-model:segmentIds="campaign.segments"
                            v-model:everyone="campaign.everyone"
                            v-model:removeOnResponse="campaign.remove_on_response"
                            v-model:removeOnLinkClick="campaign.remove_on_link_click"
                            v-model:isRepeatable="campaign.is_repeatable"
                            v-model:repeatDelay="campaign.repeat_delay"
                            v-model:audiencePercentage="campaign.audience_percentage"></campaign-step-form-to>

                        <vx-alert v-if="error"
                            color="danger"
                            :closeable="false">
                            {{ error }}
                        </vx-alert>

                        <vx-sticky-footer class="vx-max-w-screen-sm vx-w-full"
                            :class="{
                                'vx-mx-auto': !props.campaignId,
                            }">
                            <vx-button href="/messaging/campaigns"
                                class="vx-w-20"
                                size="lg"
                                color="muted">
                                Cancel
                            </vx-button>
                            <vx-button @click="submit()"
                                data-test="submit-button"
                                class="vx-grow"
                                size="lg"
                                color="primary"
                                :loading="submiting">
                                {{
                                    props.campaignId
                                    ? "Update Campaign"
                                    : "Create Campaign"
                                }}
                            </vx-button>
                        </vx-sticky-footer>
                    </div>
                </vx-sticky-nav>
            </div>
        </template>

        <template v-else>
            <success-box>
                Your campaign has been {{ props.campaignId ? 'updated' : 'created' }}!

                <template v-slot:action>
                    <vx-button size="lg"
                        href="/messaging/campaigns"
                        color="secondary">
                        View All Campaigns
                        <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                    </vx-button>
                </template>
            </success-box>
        </template>
    </div>
</template>

<script setup>
import {
    VxButton,
    VxIconLoading,
    VxAlert
} from "@voxie/frontend-components";
import StepFormHeading from '~/components/general/StepFormHeading.vue';
import SuccessBox from '~/components/general/SuccessBox.vue';
import PageHeader from '~/components/general/PageHeader.vue';
import PageSidebar from '~/components/general/PageSidebar.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowLeft, faArrowRight, faFlagSwallowtail } from '@fortawesome/pro-solid-svg-icons';
import { ref, onMounted, inject, computed } from 'vue';
import { VxStickyNav, VxStickyFooter } from '@voxie/frontend-components';
import CampaignStepFormName from './CampaignStepFormName.vue'
import CampaignStepFormAudienceType from './CampaignStepFormAudienceType.vue'
import CampaignStepFormWhen from './CampaignStepFormWhen.vue'
import CampaignStepFormWhat from './CampaignStepFormWhat.vue'
import CampaignStepFormTo from './CampaignStepFormTo.vue'
import { useUrlSearchParams } from '@vueuse/core';
import campaignsClient from '~/services/campaignsClient';
import FlowResourceStateAlert from '~/components/automation/flows/components/FlowResourceStateAlert.vue';
import { useToasted } from "~/composables/useToasted";
import { statuses as CampaignStatus, AUDIENCE_TYPE_MARKETING, AUDIENCE_TYPE_TRANSACTIONAL } from '~/constants/campaign';
import { HTTP_UNPROCESSABLE_ENTITY, HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';
import dayjs, { toUTCDateTimeFormat } from '~/utils/dayjs';
import { getClientTz } from "~/utils/date";
import useVuelidate from "@vuelidate/core";
import { required, maxLength } from '@vuelidate/validators';
import { displayFirstError } from "~/utils/validation";
import flowsClient from "~/services/flowsClient";
import { clientTzToAmericaNewYorkUTC, UTCNewYorkToClientTz } from './timeZoneUtils';
import ResourceCategory from '~/components/general/categories/ResourceCategory.vue';

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    campaignId: {
        type: Number,
        required: false,
    },
});

const FEATURES = inject('FEATURES');

const whenStepRef = ref();

const error = ref();
const errors = ref({});
const success = ref(false);
const submiting = ref(false);
const loading = ref(true);

const campaign = ref({
    name: '',
    type: FEATURES.transactional_default ? AUDIENCE_TYPE_TRANSACTIONAL : AUDIENCE_TYPE_MARKETING,
    duration_type: '',
    live_at: new Date(),
    original_live_at: toUTCDateTimeFormat(dayjs()),
    complete_at: null,
    original_campaign_id: null,
    segments: [],
    everyone: false,
    remove_on_response: false,
    remove_on_link_click: false,
    time_zone_aware: false,
    is_repeatable: false,
    repeat_delay: 10080,
    audience_percentage: null,
    campaign_messages: [],
    category: '',
});

const validation = useVuelidate({
    name: { required, maxLength: maxLength(180), $autoDirty: true },
}, campaign)

const queryParams = useUrlSearchParams();

const disabled = computed(() => props.campaignId && ![CampaignStatus.DRAFT, CampaignStatus.SCHEDULED].includes(campaign.value.status))

const transformResponse = (data) => {
    const transformed = {
        ...data,
    }
    if (data.time_zone_aware) {
        transformed.live_at = UTCNewYorkToClientTz(data.live_at);
        transformed.complete_at = UTCNewYorkToClientTz(data.complete_at);
    } else {
        transformed.live_at = data.live_at ? dayjs.utc(data.live_at).toDate() : null;
        transformed.complete_at = data.complete_at ? dayjs.utc(data.complete_at).toDate() : null;
    }

    transformed.original_live_at = data.live_at;
    transformed.original_complete_at = data.complete_at;

    return transformed;
}

const duplicate = async () => {

    const response = await campaignsClient.campaignGet(teamId, queryParams.duplicate_from_id);

    let flowSection;
    if (response.data.flow_id) {
        try {
            const { data: { config: { sections } } } = await flowsClient.getConfig(teamId, response.data.flow_id);
            flowSection = sections.findIndex(
                (section) => section.resources.find(
                    resource => resource.type === 'campaign' && Number(resource.id) === Number(queryParams.duplicate_from_id)
                )
            )
        } catch (e) {
            console.error(e)
        }
    }

    campaign.value = {
        ...transformResponse(response.data),
        id: undefined,
        name: '',
        flow_section: flowSection === -1 ? 0 : flowSection,
    }
}

onMounted(async () => {
    try {
        loading.value = true;
        if (props.campaignId) {
            const response = await campaignsClient.campaignGet(teamId, props.campaignId);
            campaign.value = transformResponse(response.data);
        }

        if (queryParams.duplicate_from_id) {
            await duplicate();
        }

        if (!campaign.value.flow_id && queryParams.flow_id) {
            campaign.value.flow_id = queryParams.flow_id;
            campaign.value.flow_section = Number(queryParams.flow_section || 0);
        }
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
});


const submit = async () => {
    await whenStepRef.value.validate();
    const isValid = await validation.value.$validate();

    if (!isValid) {
        toasted.error('Please check form data.');
        return;
    }

    if (submiting.value) {
        return;
    }

    try {
        error.value = '';
        errors.value = {}
        submiting.value = true;

        const data = { ...campaign.value };

        if (data.time_zone_aware) {
            // when time_zone_aware is set to true we send to the server as if the date was set in America/New_York
            data.live_at = clientTzToAmericaNewYorkUTC(data.live_at);
            data.complete_at = clientTzToAmericaNewYorkUTC(data.complete_at);
        } else {
            data.live_at = data.live_at ? toUTCDateTimeFormat(dayjs.tz(data.live_at, getClientTz())) : null;
            data.complete_at = data.complete_at ? toUTCDateTimeFormat(dayjs.tz(data.complete_at, getClientTz())) : null;
        }

        data.segment_ids = data.segments.map(segment => segment.id);

        if (disabled.value) {
            delete data.segment_ids;
        }

        delete data.original_live_at;
        delete data.original_complete_at;

        await campaignsClient.campaignSave(teamId, data);

        success.value = true;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (e) {
        console.error(e)
        if (e?.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
            errors.value = e.response.data.errors;
        }

        if (e?.response?.status === HTTP_INTERNAL_SERVER_ERROR) {
            error.value = 'An unexpected error occurred. Please try again.';
        } else {
            error.value = e.response?.data?.message;
        }

        toasted.error(error.value || 'Something went wrong.');
    } finally {
        submiting.value = false;
    }
};

</script>
