<template>
    <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8 2xl:vx-max-w-screen-xl">
         <page-header>
             <template v-slot:subtitle>Analytics</template>
             Dashboard
             <template v-slot:actions>
                 <analytics-controls v-model="controls" />
             </template>
         </page-header>

        <div class="vx-grid vx-gap-6 vx-grid-cols-1 lg:vx-grid-cols-12">
            <analytics-contacts-total-created class="lg:vx-col-span-4" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                   {{ rangeLabel }}
                </template>
            </analytics-contacts-total-created>

            <analytics-marketing-subscriptions-total-opt-ins class="lg:vx-col-span-4" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                   {{ rangeLabel }}
                </template>
            </analytics-marketing-subscriptions-total-opt-ins>

            <analytics-marketing-subscriptions-total-opt-outs class="lg:vx-col-span-4" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                   {{ rangeLabel }}
                </template>
            </analytics-marketing-subscriptions-total-opt-outs>

            <analytics-contacts-total-created-by-entry-point class="lg:vx-col-span-12" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                   {{ rangeLabel }}
                </template>
            </analytics-contacts-total-created-by-entry-point>

            <analytics-outbound-messages-total-contacts-messaged class="lg:vx-col-span-3" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                   {{ rangeLabel }}
                </template>
            </analytics-outbound-messages-total-contacts-messaged>

            <analytics-outbound-messages-sent-by-type class="lg:vx-col-span-3" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                   {{ rangeLabel }}
                </template>
            </analytics-outbound-messages-sent-by-type>

            <analytics-outbound-messages-sent-by-type class="lg:vx-col-span-3" type="sms" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                   {{ rangeLabel }}
                </template>
            </analytics-outbound-messages-sent-by-type>

            <analytics-outbound-messages-sent-by-type class="lg:vx-col-span-3" type="mms" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                   {{ rangeLabel }}
                </template>
            </analytics-outbound-messages-sent-by-type>

            <analytics-outbound-messages-delivery-rate class="lg:vx-col-span-6" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                   {{ rangeLabel }}
                </template>
            </analytics-outbound-messages-delivery-rate>

            <analytics-marketing-campaigns-message-reply-rate class="lg:vx-col-span-6" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                    {{ rangeLabel }}
                </template>
            </analytics-marketing-campaigns-message-reply-rate>

            <analytics-marketing-campaigns-total-messages-sent-by-campaign class="lg:vx-col-span-6" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                    {{ rangeLabel }}
                </template>
            </analytics-marketing-campaigns-total-messages-sent-by-campaign>

            <analytics-marketing-campaigns-click-through-rate-by-campaign class="lg:vx-col-span-6" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                    {{ rangeLabel }}
                </template>
            </analytics-marketing-campaigns-click-through-rate-by-campaign>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';
import AnalyticsControls from './partial/AnalyticsControls.vue';
import AnalyticsMarketingSubscriptionsTotalOptIns from './charts/time-series/AnalyticsMarketingSubscriptionsTotalOptIns.vue';
import AnalyticsMarketingSubscriptionsTotalOptOuts from './charts/time-series/AnalyticsMarketingSubscriptionsTotalOptOuts.vue';
import AnalyticsContactsTotalCreated from './charts/time-series/AnalyticsContactsTotalCreated.vue';
import AnalyticsContactsTotalCreatedByEntryPoint from './charts/time-series/AnalyticsContactsTotalCreatedByEntryPoint.vue';
import AnalyticsOutboundMessagesTotalContactsMessaged from './charts/time-series/AnalyticsOutboundMessagesTotalContactsMessaged.vue';
import AnalyticsOutboundMessagesSentByType from './charts/time-series/AnalyticsOutboundMessagesSentByType.vue';
import AnalyticsOutboundMessagesDeliveryRate from './charts/time-series/AnalyticsOutboundMessagesDeliveryRate.vue';
import AnalyticsMarketingCampaignsMessageReplyRate from './charts/time-series/AnalyticsMarketingCampaignsMessageReplyRate.vue';
import AnalyticsMarketingCampaignsTotalMessagesSentByCampaign from './charts/time-series/AnalyticsMarketingCampaignsTotalMessagesSentByCampaign.vue';
import AnalyticsMarketingCampaignsClickThroughRateByCampaign from './charts/time-series/AnalyticsMarketingCampaignsClickThroughRateByCampaign.vue';

import { controlDateRangeOptions } from '../../utils/analytics';

const controls = ref(controlDateRangeOptions().find(option => option.value === 'last_30_days'));

const rangeLabel = computed(() => controls.value.label)
</script>
