<template>
    <div class="table-responsive">
        <div class="alert alert-info mb-0" v-if="uploading">
            Don't close this window. Your file is being uploaded.
        </div>
        <div class="alert alert-success mb-0" v-else-if="uploadedSuccessfully">
            Your file was succesfully uploaded
        </div>
        <div class="alert alert-danger mb-0" v-else-if="errors.length">
            <div v-for="(error, index) of errors" :key="index">
                {{ error }}
            </div>
        </div>

        <audio ref="audio" @pause="soundPaused" @error="soundError"></audio>
        <input type="file" id="upload-sound" accept=".wav" ref="uploadSound" @change="uploadSound">

        <table class="table table-borderless mb-0">
            <thead>
                <tr>
                    <th>Order</th>
                    <th>Content</th>
                    <th>Filename</th>
                    <th width="60">Updated at</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(sound, index) of parsedSounds" :key="index">
                    <td>
                        {{ sound.order }}
                    </td>
                    <td @click.prevent="toggleSound(sound.type)" class="cursor-pointer">
                        <i class="fa fa-fw fa-pause" v-if="playing === sound.type"></i>
                        <i class="fa fa-fw fa-spin fa-circle-o-notch" v-else-if="loading === sound.type"></i>
                        <i class="fa fa-fw fa-play" v-else></i>

                        <strong>
                            {{ sound.content }}
                        </strong>
                    </td>
                    <td>
                        {{ sound.filename }}
                    </td>
                    <td class="text-center">
                        <span class="text-nowrap" v-if="sound.updated_at">{{ dateFormat(sound.updated_at) }}</span>
                        <span class="text-nowrap" v-if="sound.updated_at">{{ timeFormat(sound.updated_at) }}</span>
                    </td>
                    <td>
                        <dropdown-actions label="Actions">
                            <dropdown-action-item
                                event="shiftToTextPlay"
                                :payload="sound.type"
                            >
                                {{ loading === sound.type ? 'Loading' : 'Play' }}
                            </dropdown-action-item>
                            <dropdown-action-item
                                event="shiftToTextUpload"
                                :payload="sound.type"
                            >
                                Upload
                            </dropdown-action-item>
                        </dropdown-actions>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import DropdownActionItem from '~/components/general/DropDownActionItem.vue';
import DropdownActions from '~/components/general/DropDownActions.vue';
import dayjs from '~/utils/dayjs';
import { parseSounds } from './soundsHelpers';
import shiftToTextClient from '../../../../services/shiftToTextClient';

export default {
    components: {
        DropdownActionItem,
        DropdownActions,
    },
    props: {
        sounds: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            loading: null,
            urls: {},
            uploadType: null,
            uploading: false,
            playing: null,
            uploadedSuccessfully: false,
            errors: []
        }
    },
    mounted() {
        this.listenForBusEvents()
    },
    beforeUnmount() {
        Bus.$off('shiftToTextPlay')
    },
    methods: {
        listenForBusEvents() {
            Bus.$on('shiftToTextPlay', (type) => {
                this.fetchSound(type)
            })
            Bus.$on('shiftToTextUpload', (type) => {
                if(type && this.$refs.uploadSound) {
                    this.uploadType = type;
                    this.$refs.uploadSound.click()
                }
            })
        },
        uploadSoundFileValid() {
            const file = this.uploadSoundFile()
            if (!file) {
                return false;
            }
            if (!file?.size) {
                return false;
            }
            const fileSize = file.size / 1024 / 1024; // in MB
            if (fileSize > 20) {
                return false;
            }
            return true;
        },
        uploadSoundFile() {
            return this.$refs.uploadSound.files[0]
        },

        uploadSound() {
            if(!this.uploadSoundFileValid()) {
                this.errors = ['Your file is too large, the size limit of your wav file should not exceed 20MB'];
                return;
            }

            const file = this.uploadSoundFile()

            if(!file) {
                this.$refs.uploadSound.value = ''
                this.uploadType = null
                return
            }

            const teamId = Spark.state.currentTeam.id;

            this.uploading = true;
            this.uploadedSuccessfully = false;
            this.errors = [];

            shiftToTextClient.uploadSound(teamId, this.uploadType, file).then((response) => {
                this.uploadedSuccessfully = true
                this.$emit('soundUpdated', response.data)
                setTimeout(() => this.uploadedSuccessfully = false, 6000)
            }).catch((error) => {
                if (error?.response?.status === 422) {
                    const responseErrors = error.response.data.errors;

                    Object.keys(responseErrors).forEach((key) => {
                        this.errors.push(responseErrors[key].join(' '))
                    });
                } else {
                    this.errors = []
                    this.$toasted.global.platform_error();
                }
            }).finally(() => {
                delete this.urls[this.uploadType]

                this.$refs.uploadSound.value = ''

                this.uploading = false;
                this.uploadType = null
            })
        },
        fetchSound(type) {
            const teamId = Spark.state.currentTeam.id;

            if (this.urls[type]) {
                return this.playSound(type)
            }

            this.loading = type
            shiftToTextClient.sound(teamId, type).then((response) => {
                this.urls[type] = response.data.url
                this.playSound(type)
            }).catch(() => {
                this.$toasted.global.platform_error()
            }).finally(() => {
                this.loading = null
            })
        },
        soundError() {
            delete this.urls[this.playing]
            this.fetchSound(this.playing)
        },
        soundPaused() {
            this.playing = null;
        },
        toggleSound(type) {
            if(this.playing === type){
                this.pauseCurrentSound();
            } else {
                this.fetchSound(type)
            }
        },
        pauseCurrentSound() {
            this.$refs.audio.pause()
        },
        playSound(type) {
            if (!this.$refs.audio.paused) {
                this.$refs.audio.pause()
            }

            this.playing = type;

            this.$refs.audio.src = this.urls[type];
            this.$refs.audio.play()
        },
        dateFormat(date) {
            return dayjs(date).format("MM/DD/YYYY ");
        },
        timeFormat(date) {
            return dayjs(date).format("hh:mm A");
        }
    },
    computed: {
        parsedSounds() {
            return parseSounds(this.sounds)
        }
    }
};
</script>

<style scoped>
#upload-sound {
    opacity: 0;
    height: 1px;
    width: 1px;
    position: absolute;
}
</style>
