<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        :padding="false"
        size="md">
        <template v-slot:header>
            <div class="vx-px-6 vx-pt-6">
                Variables
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6 vx-pb-4">
                Variables can be inserted into your message to dynamically render contextual information.
            </div>
            <div class="vx-px-6">
                <vx-input v-model="search"
                    size="lg"
                    placeholder="Search">
                    <template v-slot:right>
                        <font-awesome-icon class="vx-text-slate-800"
                            :icon="faMagnifyingGlass"></font-awesome-icon>
                    </template>
                </vx-input>
            </div>
        </template>

        <div class="vx-p-6">
            <div v-if="loading"
                class="vx-py-36 vx-text-center">
                <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
            </div>
            <template v-else-if="search.length > 0 && !filteredVariablesLength">
                <div class="vx-text-center">
                    No results found
                </div>
            </template>
            <template v-else>
                <template v-if="filteredVariables.team.length || filteredVariables.team_custom_attributes.length">
                    <variable-title>Team</variable-title>

                    <variable-badges>
                        <template v-if="filteredVariables.team.length">
                            <variable-badge v-for="v in filteredVariables.team"
                                :key="v.label + Math.random()"
                                @click.prevent="select(v.var)">
                                {{ v.label }}
                            </variable-badge>
                        </template>

                        <template v-if="filteredVariables.team_custom_attributes.length">
                            <variable-badge v-for="v in filteredVariables.team_custom_attributes"
                                :key="v.label + Math.random()"
                                @click.prevent="select(v.var)">
                                {{ v.label }}
                            </variable-badge>
                        </template>
                    </variable-badges>
                </template>

                <template v-if="filteredVariables.contact.length || filteredVariables.contact_custom_attributes.length">
                    <variable-title>Contact</variable-title>

                    <variable-badges>
                        <variable-badge v-for="v in filteredVariables.contact"
                            :key="v.label + Math.random()"
                            @click.prevent="select(v.var)">
                            {{ v.label }}
                        </variable-badge>

                        <variable-badge v-for="v in filteredVariables.contact_custom_attributes"
                            :key="v.label + Math.random()"
                            @click.prevent="select(v.var)">
                            {{ v.label }}
                        </variable-badge>
                    </variable-badges>
                </template>

                <div v-if="hasStores && (filteredVariables.shopify.general.length ||
                    filteredVariables.shopify.checkout.length ||
                    filteredVariables.shopify.post_purchase.length)">
                    <template v-if="filteredVariables.shopify.general.length">
                        <variable-title>Shopify General</variable-title>

                        <variable-badges>
                            <variable-badge v-for="v in filteredVariables.shopify.general"
                                :key="v.label + Math.random()"
                                @click.prevent="select(v.var)">
                                {{ v.label }}
                            </variable-badge>
                        </variable-badges>
                    </template>

                    <template v-if="filteredVariables.shopify.checkout.length">
                        <variable-title>Shopify Abandoned Checkout</variable-title>

                        <variable-badges>
                            <variable-badge v-for="v in filteredVariables.shopify.checkout"
                                :key="v.label + Math.random()"
                                @click.prevent="select(v.var)">
                                {{ v.label }}
                            </variable-badge>
                        </variable-badges>
                    </template>

                    <template v-if="filteredVariables.shopify.post_purchase.length">
                        <variable-title>Shopify Post Purchase</variable-title>

                        <variable-badges>
                            <variable-badge v-for="v in filteredVariables.shopify.post_purchase"
                                :key="v.label + Math.random()"
                                @click.prevent="select(v.var)">
                                {{ v.label }}
                            </variable-badge>
                        </variable-badges>
                    </template>
                </div>

                <template v-if="filteredVariables.contact_collections.length">
                    <variable-title>Contact Collections</variable-title>

                    <variable-badges>
                        <variable-badge v-for="v in filteredVariables.contact_collections"
                            :key="v.label + Math.random()"
                            @click.prevent="select(v.var)">
                            {{ v.label }}
                        </variable-badge>
                    </variable-badges>
                </template>

                <template v-if="filteredVariables.promotions.length">
                    <variable-title>Promotions</variable-title>

                    <variable-badges>
                        <variable-badge v-for="(v, index) in filteredVariables.promotions"
                            :key="index"
                            @click.prevent="select(v.var)">
                            {{ v.label }}
                        </variable-badge>
                    </variable-badges>
                </template>
            </template>
        </div>
    </vx-modal>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { VxModal, VxInput, VxIconLoading } from '@voxie/frontend-components';
import { computed, h, inject, reactive, ref, watch } from 'vue';
import { useToasted } from '../../../../composables/useToasted';
import { customAttributesClient, teamCustomAttributesClient, promotionsClient, teamsClient } from "../../../../services";

const VariableTitle = (props, { slots }) => h('h3', {
    class: 'vx-text-slate-700 vx-text-base vx-font-extrabold vx-mb-2',
}, [slots.default()]);

const VariableBadge = (props, { slots }) => h('div', {
    class: 'vx-h-5 vx-px-1 vx-bg-teal-100 vx-rounded-sm vx-text-slate-700 vx-text-sm vx-font-normal vx-cursor-pointer hover:vx-text-white hover:vx-bg-teal-500',
    'data-test': 'variable-badge',
}, [slots.default()]);

const VariableBadges = (props, { slots }) => h('div', {
    class: 'vx-flex vx-flex-wrap vx-gap-x-1.5 vx-gap-y-3 vx-mb-4',
}, [slots.default()]);


const toasted = useToasted();

const props = defineProps({
    visible: Boolean,
});
const emit = defineEmits(['update:visible', 'selected']);

const teamId = Spark.state.currentTeam.id;
const hasStores = ref(false);
const FEATURES = inject('FEATURES');

const search = ref('');

const loading = ref(true);

const variables = reactive({
    team: [
        { label: 'team name', var: '{{team=>name}}' },
    ],

    contact: [
        { label: 'first name', var: '{{contact=>first_name}}' },
        { label: 'last name', var: '{{contact=>last_name}}' },
        { label: 'phone', var: '{{contact=>phone}}' },
        { label: 'email', var: '{{contact=>email}}' },
        { label: 'created date', var: '{{contact=>created_at}}' },
        { label: 'updated date', var: '{{contact=>updated_at}}' },
    ],

    message: [
        { label: 'from', var: '{{message=>from}}' },
    ],

    shopify: {
        'general': [
            {
                label: 'lifetime purchase amount',
                var: `{{contact=>custom_attributes=>SH_Contact_Lifetime_Purchased_Amount}}`
            }
        ],
        'checkout': [
            {
                label: 'checkout recovery url',
                var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Recovery_Link}}`
            },
            {
                label: 'total amount',
                var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Total_Amount}}`
            },
            { label: 'item count', var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Item_Count}}` },
            {
                label: 'total discount',
                var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Total_Discount}}`
            },
            {
                label: 'product skus',
                var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Product_SKUs}}`
            },
            {
                label: 'product names',
                var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Product_Names}}`
            },
            {
                label: 'first product sku',
                var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Product_1_SKU}}`
            },
            {
                label: 'first product name',
                var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Product_1_Name}}`
            }
        ],
        'post_purchase': [
            { label: 'total amount', var: `{{contact=>custom_attributes=>SH_Last_Order_Total_Amount}}` },
            { label: 'product skus', var: `{{contact=>custom_attributes=>SH_Last_Order_Product_SKUs}}` },
            { label: 'product names', var: `{{contact=>custom_attributes=>SH_Last_Order_Product_Names}}` },
            {
                label: 'first product sku',
                var: `{{contact=>custom_attributes=>SH_Last_Order_Product_1_SKU}}`
            },
            {
                label: 'first product name',
                var: `{{contact=>custom_attributes=>SH_Last_Order_Product_1_Name}}`
            },
            {
                label: 'first product quantity',
                var: `{{contact=>custom_attributes=>SH_Last_Order_Product_1_Quantity}}`
            },
        ]
    },

    contact_collections: [
        { label: 'tags', var: "\n{% for tag in contact=>tags %}\n\t{{tag}}\n{% endfor %}" },
        {
            label: 'custom attributes',
            var: '\n{% for ca in contact=>custom_attributes %}\n\tName: {{ca["key"]}}\n\tValue: {{ca["value"]}}\n{% endfor %}'
        },
    ],

    contact_custom_attributes: [], //loaded via API response

    team_custom_attributes: [], //loaded via API response

    promotions: [], //loaded via API response
});

const select = (variable) => {
    emit('selected', variable);
    emit('update:visible', false);
}

const filteredVariables = computed(() => {
    const allVariables = {}

    Object.keys(variables).forEach((key) => {
        if (Array.isArray(variables[key])) {
            allVariables[key] = variables[key]
                .filter(v => v.label.toLowerCase().includes(search.value.toLowerCase()))
        } else if (typeof variables[key] === "object") {
            allVariables[key] = {}
            Object.keys(variables[key]).forEach(subKey => {
                allVariables[key][subKey] = variables[key][subKey]
                    .filter(v => v.label.toLowerCase().includes(search.value.toLowerCase()));
            })
        }
    });

    return allVariables;
});

const filteredVariablesLength = computed(() => {
    return Object.keys(filteredVariables.value).reduce((acc, curr) => {
        if (Array.isArray(filteredVariables.value[curr])) {
            return acc + filteredVariables.value[curr].length;
        } else if (typeof filteredVariables.value[curr] === "object") {
            return acc + Object.keys(filteredVariables.value[curr]).reduce((subAcc, subKey) => {
                return subAcc + filteredVariables.value[curr][subKey].length;
            }, 0)
        }
    }, 0)
})

watch(() => props.visible, () => {
    if (!props.visible) {
        return;
    }

    teamsClient.countShopifyStores(teamId)
        .then(({ data }) => {
            hasStores.value = data.count > 0;
        });

    customAttributesClient.customAttributesMeta()
        .then(({ data }) => {
            variables.contact_custom_attributes = data.reduce((acc, next) => {
                const nextValue = next.replace(/[^A-Za-z0-9]/g, '_');

                if (next.toUpperCase().indexOf('SH_') < 0) {
                    return [...acc, { label: next, var: `{{contact=>custom_attributes=>${nextValue}}}` }];
                } else {
                    return acc;
                }
            }, []);
        })
        .catch(error => {
            console.error(error.response.data);
            toasted.global.platform_error();
        })
        .finally(() => {
            loading.value = false;
        });

    teamCustomAttributesClient.getList(teamId)
        .then(({ data }) => {
            variables.team_custom_attributes = [];
            const attrList = data.data.sort((a, b) => { (a.key > b.key) ? 1 : -1; });

            attrList.forEach((attr) => {
                const varValue = { label: attr.key + ' Value', var: `{{team=>custom_attributes=>${attr.key}}}` };
                variables.team_custom_attributes.push(varValue);
            });
        })
        .catch(error => {
            console.error(error);
            toasted.global.platform_error();
        });

    // Fetch promotions.
    if (FEATURES.promotions) {
        promotionsClient.getAll(teamId)
            .then(response => {
                const promotionVariables = response.data.data.map(promotion => {
                    return {
                        label: promotion.name,
                        var: `{{promotion=>coupon=>${promotion.code}}}`,
                    };
                });
                promotionVariables.sort((a, b) => a.label.localeCompare(b.label));
                variables.promotions = promotionVariables;
            })
            .catch((error) => {
                console.error(error);
                toasted.global.platform_error();
            });
    }
}, { immediate: true })
</script>
