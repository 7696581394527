<template>
    <div>
        <div class="vx-text-slate-700 vx-text-base vx-font-bold">Method</div>
        <div class="vx-text-sm vx-leading-tight vx-mb-4 vx-text-slate-500">
            The method of HTTP request.
        </div>

        <div class="vx-grid sm:vx-grid-cols-4 vx-gap-2">
            <vx-box v-for="httpMethod in availableHttpMethods()"
                tabindex="0"
                @click.prevent="change(httpMethod.method)"
                @keydown.space.prevent="change(httpMethod.method)"
                @keydown.enter.prevent="change(httpMethod.method)"
                :aria-selected="props.active"
                :key="httpMethod.method"
                class="vx-cursor-pointer vx-flex vx-items-center vx-p-4 vx-gap-2"
                :class="{
                    '!vx-bg-slate-100 vx-shadow-none': props.modelValue !== httpMethod.method,
                }"
                >
                <vx-radio :model-value="props.modelValue === httpMethod.method">
                </vx-radio>
                <vx-badge class="vx-grow vx-justify-center" size="lg" :color="httpMethod.color">
                    {{ httpMethod.method }}
                </vx-badge>
            </vx-box>
        </div>
    </div>
</template>

<script setup>
import {
    VxBox,
    VxRadio,
    VxBadge
} from "@voxie/frontend-components";

import { availableHttpMethods } from '../apiDestinationHelper';
const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    error: {
        type: String,
        default: undefined,
    },
});

const emit = defineEmits(['update:modelValue']);

const change = (method) => emit('update:modelValue', method)
</script>
