<template>
    <vx-selectable v-model="model"
        placeholder="Custom Attribute Key"
        :taggable="true"
        :loading="loading"
        :options="options"
        @search="search"
        :clearable="false"
        data-test="custom-attribute-key-selectable" />
</template>

<script setup>
import { VxSelectable } from '@voxie/frontend-components';
import { computed, ref } from 'vue';
import { useToasted } from '~/composables/useToasted';
import { customAttributesClient } from '~/services';
import debounce from '~/utils/debounce';

const teamId = Spark.state.currentTeam.id;
const toasted = useToasted();

const props = defineProps(['modelValue']);

const emit = defineEmits(['update:modelValue']);
const model = computed({
    set: (event) => {
        if (Array.isArray(event)) {
            emit('update:modelValue', event.map(key => key.toLowerCase()))
        } else {
            emit('update:modelValue', event.toLowerCase());
        }
    },
    get: () => props.modelValue,
});

const options = ref([]);
const loading = ref();

const search = debounce((query) => {
    loading.value = true;

    customAttributesClient.customAttributesAutocomplete(teamId, {
        query: query,
    }).then((response) => {
        options.value = response.data.data;
    }).catch((e) => {
        console.error(e)
        toasted.global.platform_error()
    }).finally(() => {
        loading.value = false
    })
}, 300);

search();
</script>
