<template>
    <div class="vx-relative vx-w-full">
        <div class="vx-absolute vx-inset-x-5 vx-bottom-0 vx-z-10 vx-overflow-hidden">
            <div
                class="vx-translate-y-1/2 vx-border-t-0 vx-border-x-0 vx-border-b-[5px] vx-border-dotted vx-border-slate-400/90">
            </div>
        </div>
        <div class="vx-absolute vx-inset-x-5 vx-bottom-0 vx-z-10 vx-overflow-hidden">
            <div
                class="vx-translate-y-[70%] vx-border-t-0 vx-border-x-0 vx-border-b-[5px] vx-border-dotted vx-border-slate-300">
            </div>
        </div>
    </div>
    <vx-expandable-card toggleable
        :active="currentStep === 'when'"
        class="vx-relative vx-overflow-hidden vx-mt-8 vx-origin-top-right"
        :class="{
            'vx-animate-rip': open,
            'vx-animate-replace': !open,
        }">
        <template v-slot:header
            v-if="showForm">
            <span class="vx-pr-1.5 vx-mr-2 vx-border-y-0 vx-border-l-0 vx-border-r vx-border-solid sm:vx-pr-3 sm:vx-mr-4"
                :class="{
                    'vx-border-r-transparent': !canEditDate,
                    'vx-border-r-slate-400': canEditDate,
                }">
                Follow-up in...
            </span>
            <span v-if="canEditDate"
                class="vx-text-xs vx-font-normal vx-text-slate-700 sm:vx-text-base sm:vx-font-semibold">
                {{ formattedFollowUpDate }}
            </span>
        </template>
        <template v-slot:headerRight
            v-if="canEditDate">
            <vx-button @click.stop="currentStep = 'when'"
                color="muted"
                size="md">
                <span class="vx-inline-block">Edit</span>
                <font-awesome-icon class="vx-text-base vx-hidden sm:vx-inline-block"
                    :icon="faPencil"></font-awesome-icon>
            </vx-button>
        </template>
        <template v-slot:content>
            <div class="vx-absolute -vx-top-1 vx-inset-x-5 vx-border-t-0 vx-border-x-0 vx-border-b-[5px] vx-border-dotted vx-border-slate-300"
                :class="{
                    '-vx-translate-y-full vx-delay-300': open,
                    'vx-translate-y-1/2 vx-delay-200': !open
                }">
            </div>

            <animate-height :height="height">
                <div ref="contentDiv"
                    class="vx-w-full">
                    <quick-blast-follow-up-callout v-if="!showForm"
                        @continue="open = true"
                        :success="success"></quick-blast-follow-up-callout>
                    <div v-if="showForm">
                        <quick-blast-follow-up-in @cancel="open = false"
                            @continue="currentStep = 'message'"
                            :submiting="submiting"
                            :originalScheduledAt="props.originalScheduledAt"
                            v-model="followUpDate"
                            :formattedModelValue="formattedFollowUpDate"></quick-blast-follow-up-in>
                    </div>
                </div>
            </animate-height>
        </template>
    </vx-expandable-card>
        <quick-blast-follow-up-message v-if="showMessage" ref="followUpMessageComp" @fetched="onFollowUpMessagesFetched()"
        class="vx-mt-8"
        :active="currentStep == 'message'"
        @update:active="$event ? currentStep = 'message' : currentStep = 'when'"
        @continue="createFollowUp()"
        :submiting="submiting"
        :originalCampaignId="props.originalCampaignId"
        :originalCampaignType="props.originalCampaignType"
        :originalMessage="props.originalMessage"
        v-model="message"></quick-blast-follow-up-message>
</template>

<script setup>
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxButton, VxExpandableCard } from '@voxie/frontend-components';
import { computed, onMounted, ref, watch } from 'vue';
import AnimateHeight from 'vue-animate-height';
import { getClientTz } from '../../utils/date';
import QuickBlastFollowUpIn from './QuickBlastFollowUpIn.vue';
import QuickBlastFollowUpMessage from './QuickBlastFollowUpMessage.vue';
import QuickBlastFollowUpCallout from './QuickBlastFollowUpCallout.vue';
import campaignsClient from '../../services/campaignsClient';
import { useToasted } from '../../composables/useToasted';
import dayjs, { toUTCDateTimeFormat } from '~/utils/dayjs';

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    originalCampaignId: {
        type: Number,
        required: true,
    },
    originalCampaignType: {
        type: String,
        required: true,
    },
    originalCampaignName: {
        type: String,
        required: true,
    },
    originalScheduledAt: {
        type: Date,
        required: true,
    },
    originalMessage: {
        type: Object,
        required: true,
        default: () => ({})
    }
});

const toasted = useToasted();

const currentStep = ref('when');

const open = ref(false);
const showForm = ref(false);
const showMessage = ref(false);
const height = ref('auto');
const contentDiv = ref(null);

const followUpId = ref(undefined);
const followUpName = computed(() => `${props.originalCampaignName} - Follow-Up`);

const followUpDate = ref();
const message = ref({
    body: "",
    mediaUrl: null,
    mediaContentType: null,
    selectedSnippetBody: null,
});

const success = ref(false);
const submiting = ref(false);

const followUpMessageComp  = ref(null);

const createFollowUp = async () => {
    if (submiting.value) {
        return
    }

    submiting.value = true;

    try {
        const response = await campaignsClient.campaignSave(teamId, {
            id: followUpId.value || undefined,
            name: followUpName.value,
            type: props.originalCampaignType,
            duration_type: 'one_time',
            original_campaign_id: props.originalCampaignId,
            remove_on_response: 1,
            remove_on_link_click: 1,
            live_at: toUTCDateTimeFormat(dayjs.tz(followUpDate.value, getClientTz())),
            campaign_messages: [
                {
                    body: message.value.body,
                    media_url: message.value.mediaUrl || null,
                    media_content_type: message.value.mediaContentType || null,
                    status: "published"
                }
            ]
        });

        followUpId.value = response.data.id;
        await campaignsClient.changeStatus(teamId, followUpId.value, 'scheduled');
        success.value = true;
        open.value = false;

        currentStep.value = 'when';
    } catch (e) {
        console.error(e)
        toasted.global.platform_error();
    } finally {
        submiting.value = false;
    }
}

const formattedFollowUpDate = computed(() => {
    if (!followUpDate.value) {
        return '';
    }

    const dayjsDate = dayjs(followUpDate.value);
    const tz = dayjsDate.tz(getClientTz()).format('z');
    return dayjsDate.tz(getClientTz()).format('MMMM Do, YYYY [at] h:mm a') + ' ' + tz
});

const canEditDate = computed(() => {
    return currentStep.value != 'when' && followUpDate.value;
})

const setupResizer = () => {
    const resizeObserver = new ResizeObserver(() => {
        height.value = contentDiv.value.clientHeight
    })

    resizeObserver.observe(contentDiv.value);
}
onMounted(() => {
    setupResizer();
});

watch(canEditDate, () => {
    if (!canEditDate.value) {
        setTimeout(() => {
            setupResizer();
        }, 100);
    }
})

watch(open, (newVal) => {
    if (newVal) {
        setTimeout(() => {
            showForm.value = true
        }, 350)

        setTimeout(() => {
            showMessage.value = true
        }, 500)
    } else {
        showForm.value = false
        showMessage.value = false
    }
});

const onFollowUpMessagesFetched = () => {
    if (followUpId.value && followUpDate.value) {
        submiting.value = false;
        currentStep.value = 'message';
    }
}
</script>
