<template>
    <div>
        <div class="modal docked docked-right" id="modal-notifications" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header text-center">Notifications</div>

                    <div class="modal-body">
                        <!-- Informational Messages -->
                        <div class="notification-container" v-if="loadingNotifications">
                            <div><i class="fa fa-btn fa-spinner fa-spin"></i> Loading Notifications</div>
                        </div>

                        <div
                            class="notification-container"
                            v-if="!loadingNotifications && activeNotifications.length == 0"
                        >
                            <div class="alert alert-warning">
                                We don't have anything to show you right now! But when we do, we'll be sure to let you
                                know. Talk to you soon!
                            </div>
                        </div>

                        <!-- List Of Notifications -->
                        <div class="notification-container" v-if="hasNotifications">
                            <div class="notification" v-for="(notification,index) in notifications" :key="index">
                                <!-- Notification Icon -->
                                <figure>
                                    <img
                                        v-if="notification.creator"
                                        :src="notification.creator.photo_url"
                                        class="spark-profile-photo"
                                        alt="Creator Photo"
                                    />

                                    <span v-else class="fa-stack fa-2x">
                                        <i class="fa fa-circle fa-stack-2x"></i>
                                        <i :class="['fa', 'fa-stack-1x', 'fa-inverse', notification.icon]"></i>
                                    </span>
                                </figure>

                                <!-- Notification -->
                                <div class="notification-content">
                                    <div class="meta">
                                        <p class="title">
                                            <span v-if="notification.creator">
                                                {{ notification.creator.name }}
                                            </span>

                                            <span v-else>
                                                {{ product }}
                                            </span>
                                        </p>

                                        <div class="date">
                                            {{ relative(notification.created_at) }}
                                        </div>
                                    </div>

                                    <div class="notification-body" v-html="notification.parsed_body"></div>

                                    <!-- Notification Action -->
                                    <a
                                        :href="notification.action_url"
                                        class="btn btn-primary"
                                        v-if="notification.action_text"
                                    >
                                        {{ notification.action_text }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { relative } from '../../utils/date';

export default {
    props: ['notifications', 'loadingNotifications', 'product'],
    methods: {
        relative,
    },
    computed: {
        activeNotifications() {
            if (!this.notifications) {
                return [];
            }

            return this.notifications;
        },

        /**
         * Determine if the user has any notifications.
         */
        hasNotifications() {
            return Boolean(this.notifications?.length);
        },
    },
};
</script>
