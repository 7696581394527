<template>
    <div>
        <toggle-action-data v-model="data.use_event_custom_attribute"
            :open="!data.use_event_custom_attribute"
            class="vx-mb-2">
            <template v-slot:label>
                Use custom attribute value from trigger event
            </template>

            <vx-label>Group ID</vx-label>
            <vx-input size="lg"
                type="text"
                placeholder="Group ID"
                v-model="data.group_id"
                :error="displayFirstError(validate.group_id)">
            </vx-input>
        </toggle-action-data>

        <toggle-action-data v-if="props.automation === 'contact_add_group'"
            data-test="contact-add-group-is-preferred"
            v-model="data.is_preferred"
            :open="false"
            class="vx-mb-2">
            <template v-slot:label>
                Set this as the contact's preferred group
            </template>
        </toggle-action-data>

        <use-event-contact-action-data v-model="data"></use-event-contact-action-data>
    </div>
</template>

<script setup>
import { VxInput, VxLabel } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf } from '@vuelidate/validators';
import { displayFirstError } from '~/utils/validation';
import ToggleActionData from './ToggleActionData.vue';
import UseEventContactActionData from './UseEventContactActionData.vue';

const data = defineModel();
const props = defineProps({
    automation: {
        type: String,
        required: true,
    },
});

if (data.value.use_event_custom_attribute === undefined) {
    data.value.use_event_custom_attribute = false;
}

const validate = useVuelidate({
    group_id: {
        requiredIf: helpers.withMessage('Group ID is required when not using event custom attribute', requiredIf(() => !data.value.use_event_custom_attribute))
    }
}, data, {
    $autoDirty: true,
});
</script>
