<template>
    <card-form
        title="Outbound Message"
        save-btn-text="Update"
        :no-save-btn="loading || failed"
        :saving="saving"
        @save="updateShiftToTextMessage"
    >
        <loading-spinner v-if="loading" />
        <failed-to-load-data v-else-if="failed" @reload="loadShiftToTextMessage" />
        <message-builder v-else
            class="mb-2"
            v-model="message"
            disableSnippets
            disableMedia
            testable
        />
    </card-form>
</template>

<script>
import CardForm from '../../../general/CardForm.vue';
import LoadingSpinner from '../../../general/LoadingSpinner.vue';
import FailedToLoadData from '../../../general/FailedToLoadData.vue';
import { teamsClient } from '../../../../services';
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import Swal from 'sweetalert2';

export default {
    components: {
        CardForm,
        MessageBuilder,
        LoadingSpinner,
        FailedToLoadData,
    },
    data() {
        return {
            loading: true,
            failed: false,
            saving: false,
            message: '',
        };
    },
    created() {
        this.loadShiftToTextMessage();
    },
    methods: {
        loadShiftToTextMessage() {
            this.loading = true;
            teamsClient.getShiftToTextMessage(Spark.state.currentTeam.id)
                .then((r) => {
                    this.loading = false;
                    this.failed = false;
                    this.message = r.data?.message_content || '';
                })
                .catch(() => {
                    this.loading = false;
                    this.failed = true;
                });
        },
        updateShiftToTextMessage() {
            this.saving = true;
            teamsClient.updateShiftToTextMessage(Spark.state.currentTeam.id, this.message)
                .then(() => {
                    this.saving = false;
                    Swal.fire({
                        title: 'Success!',
                        text: 'Shift to Text outbound message was updated.',
                        icon: 'success'
                    });
                })
                .catch((error) => {
                    this.saving = false;
                    console.log(error);
                    this.$toasted.global.platform_error();
                });
        },
    },
};
</script>
