<template>
  <div class="quick-blast vx-font-sans vx-text-slate-700 vx-space-y-8 md:vx-space-y-12 vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8">
      <step-form-heading
          :icon="faRocketLaunch">
          Quick Blast

          <template v-slot:description>
            The fastest way to send a single message to multiple people.
          </template>
      </step-form-heading>

      <vx-sticky-nav
          class="vx-mt-8"
          v-model="currentStep"
          :sections="sections"
          :navClasses="`${success ? 'vx-hidden' : 'vx-flex'}`"
      >
      <div class="vx-relative vx-max-w-screen-md vx-mx-auto" ref="stepsWrapper">
          <div
              class="vx-flex vx-transition-all vx-duration-500 vx-ease-[cubic-bezier(0.5,0.5,0.3,1)]"
              :class="{
                  'vx-scale-[.80]': success,
              }"
          >
              <quick-blast-type
                  v-model="campaign.type"
                  :active="currentStep == 'type'"
                  @update:active="
                      $event
                          ? (currentStep = 'type')
                          : (currentStep = null)
                  "
                  @continue="continueFromType()"
                  :success="success"
              ></quick-blast-type>
          </div>
          <div
              class="vx-flex vx-transition-all vx-duration-500 vx-ease-[cubic-bezier(0.5,0.5,0.3,1)]"
              :class="{
                  'vx-scale-[.85] -vx-mt-9': success,
                  'vx-mt-2 md:vx-mt-8': !success,
              }"
          >
              <quick-blast-who
                  v-model="people"
                  :active="currentStep == 'who'"
                  :campaign-type="campaign.type"
                  @update:active="
                      $event
                          ? (currentStep = 'who')
                          : (currentStep = null)
                  "
                  @continue="continueFromWho($event)"
                  @estimationModal="estimationModal = true"
                  :success="success"
                  :estimation="estimation"
                  :estimationLoading="estimationLoading"
              ></quick-blast-who>
          </div>
          <div
              class="vx-flex vx-transition-all vx-duration-500 vx-ease-[cubic-bezier(0.5,0.5,0.3,1)]"
              :class="{
                  'vx-scale-[.90] -vx-mt-8': success,
                  'vx-mt-2 md:vx-mt-8': !success,
              }"
          >
              <quick-blast-what
                  v-model="message"
                  :active="currentStep == 'what'"
                  :campaign-type="campaign.type"
                  @update:active="
                      $event
                          ? (currentStep = 'what')
                          : (currentStep = null)
                  "
                  @continue="currentStep = 'when'"
                  :success="success"
              ></quick-blast-what>
          </div>
          <div
              class="vx-flex vx-transition-all vx-duration-500 vx-ease-[cubic-bezier(0.5,0.5,0.3,1)]"
              :class="{
                  'vx-scale-[.95] -vx-mt-7': success,
                  'vx-mt-2 md:vx-mt-8': !success,
              }"
          >
              <quick-blast-when
                  v-model="scheduledAt"
                  :active="currentStep == 'when'"
                  @update:active="
                      $event
                          ? (currentStep = 'when')
                          : (currentStep = null)
                  "
                  @continue="currentStep = 'launch'"
                  :success="success"
              ></quick-blast-when>
          </div>
      </div>
      <div
          class="vx-max-w-screen-md vx-mx-auto vx-flex vx-relative vx-transition-all vx-duration-500 vx-ease-[cubic-bezier(0.5,0.5,0.3,1)]"
          :class="{
              'vx-scale-[1.02] -vx-mt-6': success,
              'vx-mt-2 md:vx-mt-8': !success,
          }"
      >
          <div class="vx-w-full">
              <quick-blast-launch
                  v-model:name="campaign.name"
                  :active="currentStep === 'launch'"
                  :mediaUrl="message.mediaUrl"
                  :mediaContentType="message.mediaContentType"
                  :bodyMessage="message.body"
                  :success="success"
                  @submit="submit()"
                  :submiting="submiting"
              ></quick-blast-launch>
              <quick-blast-follow-up
                v-if="currentStep === 'launch' && success"
                :originalCampaignId="campaign.id"
                :originalCampaignName="campaign.name"
                :originalCampaignType="campaign.type"
                :originalScheduledAt="scheduledAt === 'now' ? new Date() : scheduledAt"
                :originalMessage="message"
            ></quick-blast-follow-up>
          </div>
      </div>
      </vx-sticky-nav>
  </div>
  <vx-modal v-model:visible="estimationModal" class="vx-max-w-lg">
      <template v-slot:header> Recipient Estimation </template>
      <p class="vx-text-slate-500 vx-text-base vx-mb-2">
          The total number of recipients is based on several variables that
          can change all the way up to the time a message sends. As a result,
          we can only provide an exact number once messages go out.
      </p>
      <p class="vx-text-slate-500 vx-text-base">
          However, we do our best to provide an accurate estimation so you
          have a general idea of how many people you'll reach.
      </p>
  </vx-modal>
</template>

<script setup>
import { faRocketLaunch } from '@fortawesome/pro-solid-svg-icons';
import { VxModal, VxStickyNav } from '@voxie/frontend-components';
import { reactive, ref, watch, computed, inject } from 'vue';
import { useToasted } from '../../composables/useToasted';
import actionsClient from '../../services/actionsClient';
import campaignsClient from '../../services/campaignsClient';
import contactsClient from '../../services/contactsClient';
import { formatTz, getClientTz } from '../../utils/date';
import QuickBlastLaunch from './QuickBlastLaunch.vue';
import QuickBlastType from './QuickBlastType.vue';
import QuickBlastWhat from './QuickBlastWhat.vue';
import QuickBlastWhen from './QuickBlastWhen.vue';
import QuickBlastWho from './QuickBlastWho.vue';
import QuickBlastFollowUp from './QuickBlastFollowUp.vue';
import { AUDIENCE_TYPE_MARKETING, AUDIENCE_TYPE_TRANSACTIONAL } from '../../constants/campaign';
import StepFormHeading from '../general/StepFormHeading.vue';
import dayjs from '~/utils/dayjs';

const FEATURES = inject('FEATURES')

const teamId = Spark.state.currentTeam.id;
const campaign = reactive({
  name: '',
  type: FEATURES.transactional_default ? AUDIENCE_TYPE_TRANSACTIONAL : AUDIENCE_TYPE_MARKETING,
  everyone: false,
});

const submiting = ref(false);

const toasted = useToasted();

const estimationModal = ref(false);
const estimation = ref(null);
const estimationLoading = ref(false);

const steps = ['type', 'who', 'what', 'when', 'launch'];
const currentStep = ref("type");
const success = ref(false);

const sections = computed(() => {
  const currentStepIndex = steps.indexOf(currentStep.value);
  return [
      { id: 'type', label: 'Type' },
      { id: 'who', label: 'Who', disabled: currentStepIndex < steps.indexOf('who')},
      { id: 'what', label: 'What', disabled: currentStepIndex < steps.indexOf('what') },
      { id: 'when', label: 'When', disabled: currentStepIndex < steps.indexOf('when')},
      { id: 'launch', label: 'Launch', disabled: currentStepIndex < steps.indexOf('launch') }
  ]
})

const message = ref({
  body: "",
  mediaUrl: null,
  mediaContentType: null,
  selectedSnippetBody: null,
});

const scheduledAt = ref();

const people = ref({
  type: "segments",
  data: [],
});

const stepsWrapper = ref(null);

const upsertCampaign = async () => {
    const now = dayjs().tz(getClientTz());
    campaign.name =
        "Quick Blast - " +
        now.format("MM/DD/YYYY h:mm:ss a") +
        " " +
        formatTz(now.format("z"));
    campaign.duration_type = "one_time";
    campaign.remove_on_response = 1;
    campaign.remove_on_link_click = 1;

    const response = await campaignsClient.campaignSave(teamId, campaign);

    campaign.id = response.data.id;
};


// clears the segments when type changes, this
// happens because the segments must have the
// same audience type as the campaign type
watch(
  () => campaign.type,
  () => {
      // only clears in case the campaign has been created
      // and the people selected is of type segments
      if (campaign.id && people.value.type === 'segments') {
          campaign.segment_ids = [];
          people.value = {
              type: 'segments',
              data: [],
          };
      }
  }
)

const continueFromType = () => {
  upsertCampaign().then(() => {
    currentStep.value = "who";
  }).catch((err) => {
    console.error(err);
    toasted.global.platform_error();
  });
};

const clearEstimation = () => {
  estimationLoading.value = true;
};

const fetchEstimation = async () => {
  estimationLoading.value = true;
  try {
      if (people.value.type === "contacts") {
          const contactsCount = await contactsClient.contactsExpectedCount(
              teamId,
              campaign.type,
              people.value.data.map((item) => item.id)
          );

          estimation.value = contactsCount.data.count;
          estimationLoading.value = false;

      } else if (people.value.type === "segments" || people.value.type === 'everyone') {
          const campaignAudience = await campaignsClient.campaignCapacity(
              teamId,
              campaign.id
          );

          estimation.value = campaignAudience.data.audience_size;
          estimationLoading.value = false;
      }
  } catch (e) {
      console.error(e);
  }
};

const continueFromWho = async () => {
    campaign.everyone = people.value.type === 'everyone';

    clearEstimation();

    // clear segments when type is contacts or everyone
    if (people.value.type === "contacts" || people.value.type === "everyone") {
        try {
            await campaignsClient.campaignSave(teamId, {
                ...campaign,
                segment_ids: [],
            });

            currentStep.value = "what";
        } catch (err) {
            console.error(err);
            toasted.global.platform_error();
        }
    }

    // when segments we already add them to the campaign
    if (people.value.type === "segments") {
        // Contacts are added only at the final step.
        // No need to clear them here like we clear
        // the segments when type is contacts

        try {
            await campaignsClient.campaignSave(teamId, {
                ...campaign,
                segment_ids: people.value.data.map((item) => item.id),
            });

            currentStep.value = "what";
        } catch (err) {
            console.error(err);
            toasted.global.platform_error();
        }
    }

    fetchEstimation();
};

const submitFailed = (message) => {
  submiting.value = false;
  toasted.error(message);
};

const submit = async () => {
  if (submiting.value) {
      return;
  }

  submiting.value = true;

  if (people.value.type === "contacts") {
      try {
          await campaignsClient.addContactsToCampaign(
              teamId,
              campaign.id,
              people.value.data.map((item) => item.id)
          );
      } catch (e) {
          console.error(e);
          submitFailed(
              "It was not possible to add the contacts to the campaign. Please try again."
          );
          return;
      }
  }

  try {
      await campaignsClient.campaignSave(teamId, {
          ...campaign,
          campaign_messages: [
            {
                body: message.value.body,
                media_url: message.value.mediaUrl,
                media_content_type: message.value.mediaContentType,
                status: "published"
            }
          ],
          live_at:
              scheduledAt.value === 'now'
                  ? dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
                  : dayjs(scheduledAt.value).tz(getClientTz()).utc().format('YYYY-MM-DD HH:mm:ss'),
      });

      await campaignsClient.changeStatus(teamId, campaign.id, scheduledAt.value === 'now' ? 'published' : 'scheduled');

      window.scrollTo({
          top: stepsWrapper.value?.offsetTop || 0,
          behavior: 'smooth',
      });

      success.value = true;

      if (
          message.value.selectedSnippetBody &&
          message.value.body.includes(message.value.selectedSnippetBody)
      ) {
          actionsClient.send("snippet_used");
      }
  } catch (e) {
      console.error(e);
      submitFailed(
          "It was not possible to schedule this message. Please try again."
      );
  }
};
</script>

<style lang="postcss" scoped>
.quick-blast :deep(.vx-message-builder__toolbar) {
    @apply vx-bg-white vx-border-b vx-border-x-0 vx-border-t-0 vx-border-solid vx-border-slate-300;
}
.quick-blast :deep(.vx-message-builder) {
    @apply vx-border vx-border-solid vx-border-slate-300 vx-shadow-none vx-rounded-xl vx-overflow-hidden;
}
.quick-blast :deep(.vx-mb-control__icon) {
    @apply vx-text-slate-400;
}
.quick-blast :deep(.vx-vertical-divider) {
    @apply vx-h-4 vx-bg-slate-200;
}
.quick-blast :deep(.message-builder__body) {
    @apply vx-font-sans vx-text-slate-700 vx-font-normal vx-text-base;
}
.quick-blast :deep(.vx-mb-control-attachment__text),
.quick-blast :deep(.vx-input__input),
.quick-blast :deep(.vx-button__text),
.quick-blast :deep(.emoji-mart-category-label),
.quick-blast :deep(.emoji-mart-search input),
.quick-blast :deep(.vx-mb-control.vx-mb-control-variables .popper),
.quick-blast :deep(.vx-mb-control.vx-mb-control-templates .popper),
.quick-blast :deep(.message-builder__body) {
    @apply vx-font-sans;
}
</style>
