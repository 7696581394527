<template>
    <vx-navbar-action v-if="props.metadata.isImpersonator" nested href="/spark/kiosk/users/stop-impersonating" :icon="faRotateLeft">
        Back To My Account
    </vx-navbar-action>

    <vx-navbar-action v-if="props.metadata.isDeveloper" nested href="/spark/kiosk" :icon="faMasksTheater">
        Kiosk
    </vx-navbar-action>

    <vx-navbar-action v-if="canSubscribe" nested :href="`/settings/teams/${currentTeam.id}#/subscription`" :icon="faAward">
        Subscribe
    </vx-navbar-action>

    <vx-navbar-action :icon="faGear" nested href="/settings" data-test="navbar-settings-action">
        Settings
    </vx-navbar-action>

    <vx-navbar-action v-if="props.metadata.showsTeamSwitcher" nested open :icon="faScreenUsers">
        Teams
        <template v-slot:items>
            <vx-navbar-action-item
                v-for="team in teams"
                :key="team.id"
                :href="`/settings/teams/${team.id}/switch`"
                class="vx-w-40 !vx-whitespace-normal"
                nested
            >
                <vx-radio class="vx-mt-0.5" :model-value="team.id == currentTeam.id"></vx-radio>
                {{ team.name }}
            </vx-navbar-action-item>
            <vx-navbar-action-item v-if="seeAllTeams" href="/settings#/teams">See All Teams</vx-navbar-action-item>
        </template>
    </vx-navbar-action>

    <vx-button href="/logout" data-test="navbar-logout" color="muted-light" block class="vx-mt-2">
        Logout <font-awesome-icon class="vx-text-sm" :icon="faRightFromBracket"></font-awesome-icon>
    </vx-button>
</template>

<script setup>
import { VxNavbarAction, VxNavbarActionItem, VxButton, VxRadio } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faRightFromBracket, faGear, faMasksTheater, faRotateLeft, faScreenUsers, faAward} from '@fortawesome/pro-solid-svg-icons';
import { computed } from 'vue';

const props = defineProps({
    metadata: {
        type: Object,
        required: true,
    }
})

const canSubscribe = computed(
  () =>
      props.metadata.usesTeams &&
      props.metadata.ownsCurrentTeam &&
      props.metadata.currentTeamOnTrial
);

const seeAllTeams = Spark.state.teams?.length > 4;
const teams = Spark.state.teams?.slice?.(0, 4) || [];

const currentTeam = Spark.state.currentTeam;

</script>
