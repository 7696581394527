<template>
    <div>
        <step-form-heading v-if="!props.contactId"
            :icon="faUser">
            New Contact

            <template v-slot:description>
                Add a new contact to start messaging.
            </template>
        </step-form-heading>

        <contact-details :contactId="props.contactId">
            <vx-sticky-nav :sections="[
                { id: 'details', label: 'Details' },
                { id: 'subscriptions', label: 'Sub Status' },
                { id: 'tags', label: 'Tags' },
                FEATURES.contact_groups ? { id: 'groups', label: 'Groups' } : null,
                { id: 'custom-attributes', label: 'Custom Attr' },
            ].filter((s) => !!s)"
                class="vx-col-span-12"
                :class="{
                    'lg:vx-col-span-10': !!props.contactId,
                }"
                :navClasses="`${props.contactId ? 'vx-hidden' : ''}`">

                <div class="vx-flex vx-flex-col vx-w-full vx-max-w-screen-sm vx-justify-center vx-gap-2 md:vx-gap-8"
                    :class="{
                        'vx-mx-auto': !props.contactId,
                    }">

                    <vx-alert v-if="success"
                        color="success"
                        :closeable="false"
                        shadow>
                        <template v-slot:icon>
                            <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                        </template>
                        <div class="vx-flex vx-items-center vx-justify-between">
                            <span>
                                <strong>{{ props.contactId ? 'Existing Contact' : 'New Contact' }}</strong>
                                {{ props.contactId ? 'updated' : 'created' }}
                                successfully
                            </span>
                            <vx-button href="/contacts"
                                size="sm"
                                color="success">Back to Contacts<font-awesome-icon
                                    :icon="faSquareArrowUpLeft"></font-awesome-icon></vx-button>
                        </div>
                    </vx-alert>

                    <vx-alert v-if="error"
                        color="danger"
                        :closeable="false"
                        shadow>{{ error }}</vx-alert>
                    <contact-form-details :contactId="props.contactId"></contact-form-details>
                    <contact-form-subscriptions></contact-form-subscriptions>
                    <contact-form-tags></contact-form-tags>
                    <contact-form-groups v-if="FEATURES.contact_groups"></contact-form-groups>
                    <contact-form-custom-attributes></contact-form-custom-attributes>
                    <vx-alert v-if="error"
                        color="danger"
                        :closeable="false"
                        shadow>{{ error }}</vx-alert>

                    <vx-sticky-footer>
                        <vx-button href="/contacts"
                            class="vx-w-20"
                            size="lg"
                            color="muted">
                            Cancel
                        </vx-button>
                        <vx-button @click="submit()"
                            data-test="contact-save"
                            class="vx-grow"
                            size="lg"
                            color="primary"
                            :loading="saving">
                            Save
                        </vx-button>
                    </vx-sticky-footer>
                </div>
            </vx-sticky-nav>
        </contact-details>
    </div>
</template>

<script setup>
import {
    VxAlert,
    VxStickyNav,
    VxStickyFooter,
    VxButton,
} from "@voxie/frontend-components";
import StepFormHeading from '~/components/general/StepFormHeading.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleCheck, faSquareArrowUpLeft, faUser } from '@fortawesome/pro-solid-svg-icons';
import { inject, ref } from 'vue';
import ContactDetails from '../ContactDetails.vue';
import ContactFormDetails from './ContactFormDetails.vue';
import ContactFormSubscriptions from './ContactFormSubscriptions.vue';
import ContactFormTags from './ContactFormTags.vue';
import ContactFormGroups from './ContactFormGroups.vue';
import ContactFormCustomAttributes from './ContactFormCustomAttributes.vue'
import { useContactDetails } from "../useContactDetails";
import useVuelidate from "@vuelidate/core";
import { useToasted } from "~/composables/useToasted";

const toasted = useToasted();
const FEATURES = inject('FEATURES');

const props = defineProps({
    contactId: {
        type: Number,
        required: false,
    },
});

const {
    saveAll,
    saving,
    error,
    unmount,

    onMountedContact,

    getStickyPhoneNumber,
    getSubscriptions,
    getGroups,
    getTags,
    getCustomAttributes,
} = useContactDetails();

onMountedContact(() => {
    if (!props.contactId) {
        return;
    }
    getStickyPhoneNumber()
    getSubscriptions()
    getGroups()
    getTags()
    getCustomAttributes()
});

const success = ref(false);

const validate = useVuelidate();

const submit = async () => {
    const isValid = await validate.value.$validate();

    if (!isValid) {
        toasted.error('Please check form data.');
        error.value = 'Please check form data.';
        return;
    }

    try {
        await saveAll();
        success.value = true;
        if (!props.contactId) {
            unmount();
        }
        validate.value.$reset();
    } catch (e) {
        console.error(e)
    } finally {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

</script>
