import axios from './axios';
import { stringifyQueryString } from '../utils/string';

class BaseClient {
    constructor(config = {}) {
        this.apiBase = config?.apiBase || null;
        this.client = config?.client || axios;
    }

    async makeCoreApiRequest(url, method = 'get', data = null, params = null, extraConfig = {}) {
        const queryString = this.computeQueryString(params);
        const config = {
            method: method.toLowerCase(),
            url: queryString ? `${url}?${queryString}` : url,
            data: data,
            ...extraConfig,
        };

        return this.client.request(config);
    }

    computeQueryString(params) {
        return stringifyQueryString(params);
    }
}

export default BaseClient;
