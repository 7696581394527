<template>
    <div class="row" v-if="pageCount > 1">
        <div class="col-md-10 offset-md-1 u-mt-small">
            <nav class="c-pagination u-justify-center">
                <a class="d-flex align-items-center c-pagination__control u-text-dark" href="#" @click.prevent="loadPage(1)">
                    <span class="u-text-large mr-1">&laquo;</span> First
                </a>
                <a class="d-flex align-items-center c-pagination__control u-text-dark" href="#" @click.prevent="loadPrevPage">
                    <span class="u-text-large mr-1">&lsaquo;</span>
                    Prev
                </a>
                <p class="c-pagination__counter">Page {{ page }} of {{ pageCount }}</p>
                <a class="d-flex align-items-center c-pagination__control u-text-dark" href="#" @click.prevent="loadNextPage">
                    Next
                    <span class="u-text-large ml-1">&rsaquo;</span>
                </a>
                <a class="d-flex align-items-center c-pagination__control u-text-dark" href="#" @click.prevent="loadPage(pageCount)">
                    Last <span class="u-text-large ml-1">&raquo;</span>
                </a>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        page: {
            type: Number,
            required: true,
        },
        total: { // rows
            type: Number,
            required: true,
        },
        perPage: {
            type: Number,
            default: 15,
        },
        disabled: Boolean,
    },
    emits: ['update:page'],
    computed: {
        pageCount() {
            return Math.ceil(this.total / this.perPage);
        },
    },
    methods: {
        loadPage(page) {
            if (! this.disabled && (this.page !== page)) {
                this.$emit('update:page', page);
            }
        },
        loadPrevPage() {
            this.loadPage(Math.max(this.page - 1, 1));
        },
        loadNextPage() {
            this.loadPage(Math.min(this.page + 1, this.pageCount));
        },
    },
};
</script>
