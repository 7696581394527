<template>
    <vx-dropdown placement="right">
        <vx-button type="button" color="muted" outline class="vx-w-[45px]">
            <font-awesome-icon :icon="faEllipsis" />
        </vx-button>

        <template v-slot:items>
            <vx-dropdown-item
                :href="`/contacts/segments/${props.segment.id}/contacts`"
            >
                View Contacts
                <font-awesome-icon :icon="faAddressBook"></font-awesome-icon>
            </vx-dropdown-item>

            <segment-row-export :segmentId="props.segment.id"></segment-row-export>
            <vx-dropdown-item v-if="isStatic" @click="refreshSegment()">
                Refresh
                <font-awesome-icon :icon="faArrowsRotate"></font-awesome-icon>
            </vx-dropdown-item>
            <vx-dropdown-item :href="`/contacts/segments/new?duplicateFromId=${props.segment.id}`" data-test="segment-duplicate-action">
                Duplicate
                <font-awesome-icon :icon="faCopy"></font-awesome-icon>
            </vx-dropdown-item>
            <vx-dropdown-item @click="deleteSegment()">
                Delete
                <font-awesome-icon :icon="faTrash"></font-awesome-icon>
            </vx-dropdown-item>
        </template>
    </vx-dropdown>
</template>

<script setup>
import {
    faAddressBook,
    faArrowsRotate,
    faCopy,
    faEllipsis,
    faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxButton,
    VxDropdown,
    VxDropdownItem,
} from "@voxie/frontend-components";
import { useToasted } from '../../../composables/useToasted';
import segmentsClient from '../../../services/segmentsClient';
import { SegmentType } from '../../../constants/segment';
import { computed } from 'vue';
import { HTTP_INTERNAL_SERVER_ERROR } from "~/support/HttpStatuses";
import SegmentRowExport from "./SegmentRowExport.vue";

const isStatic = computed(() => props.segment.type === SegmentType.STATIC);
const toasted = useToasted();

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    segment: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(["deleted"]);

const refreshSegment = async () => {
    try {
        await segmentsClient.refreshSegment(teamId, props.segment.id);

        toasted.success("Segment refresh started successfully.");
    } catch (error) {
        console.error(error);
        toasted.global.platform_error();
    }
};

const deleteSegment = async () => {
    if (
        !window.confirm(
            "Are you sure? This segment and its association to contacts will be deleted. There is no undo."
        )
    ) {
        return;
    }

    try {
        await segmentsClient.deleteSegment(teamId, props.segment.id);
        emit("deleted", props.segment.id);
    } catch (error) {
        console.error(error);
        if (error?.response?.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
        } else {
            toasted.error(error.response?.data?.message || 'Something went wrong.');
        }
    }
};
</script>
