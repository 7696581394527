import { HTTP_NOT_FOUND } from '../../../support/HttpStatuses';
import { contactsClient, messagesClient } from '../../../services';
import scheduledMessagesClient from '../../../services/scheduledMessagesClient';
import dayjs from '~/utils/dayjs';
import axios from 'axios';

let getContactSubscriptionStatusAbort;
let listMessagesAbort;
let contactGetAbort;
let loadScheduledMessagesAbort;

const contactDefaultData = {
    phone: null,
    first_name: null,
    last_name: null,
    time_zone: null,
    entry_point: undefined,
    email: null,
    created_at: null,
    updated_at: null,
    messages: [],
    scheduled_messages: [],
    scheduled_messages_cursor: '',
    tags: [],
    campaigns: [],
    segments: [],
    custom_attributes: [],
    promotion_coupons: [],
    subscriptions: [],
};

export default {
    namespaced: true,
    state: {
        selectedContact: {
            ...contactDefaultData,
        },
        selectedContactMessagesLinks: {},
        loadingMoreMessages: false,
        deletedContacts: [],
    },
    getters: {
        isContactOnState: (state) => (contactId) => {
            return state.selectedContact?.id === contactId;
        },
    },
    mutations: {
        setActiveContact(state, contact) {
            Object.keys(contact).forEach((key) => {
                state.selectedContact[key] = contact[key];
            });
        },

        setScheduledMessages(state, scheduledMessages) {
            state.selectedContact.scheduled_messages = scheduledMessages;
        },

        setScheduledMessagesCursor(state, nextPageUrl) {
            try {
                state.selectedContact.scheduled_messages_cursor = new URL(nextPageUrl).searchParams.get('cursor');
            } catch (e) {
                state.selectedContact.scheduled_messages_cursor = '';
            }
        },

        setTags(state, tags) {
            state.selectedContact.tags = tags;
        },

        setCustomAttributes(state, customAttributes) {
            state.selectedContact.custom_attributes = customAttributes;
        },
        setPromotionCoupons(state, promotionCoupons) {
            state.selectedContact.promotion_coupons = promotionCoupons;
        },
        setSegments(state, segments) {
            state.selectedContact.segments = segments;
        },

        pullContact(state, contact) {
            if (state.selectedContact && state.selectedContact.id === contact.id) {
                state.selectedContact = {
                    ...contactDefaultData,
                };
            }
        },

        unselectContact(state) {
            state.selectedContact = {
                ...contactDefaultData,
            };
        },

        updateContactById(state, { contactId, payload }) {
            if (state.selectedContact?.id === contactId) {
                state.selectedContact = { ...state.selectedContact, ...payload };
            }
        },

        pushMessage(state, { contact, message }) {
            if (state.selectedContact?.id === contact.id) {
                const messages = state.selectedContact?.messages
                    ? [...state.selectedContact.messages, message]
                    : [message];

                state.selectedContact = {
                    ...state.selectedContact,
                    messages: messages,
                };
            }
        },

        pushScheduledMessage(state, { contact, scheduled_message }) {
            if (state.selectedContact?.id === contact.id) {
                let scheduledMessages = state.selectedContact?.scheduled_messages
                    ? [...state.selectedContact.scheduled_messages, scheduled_message]
                    : [scheduled_message];

                scheduledMessages = scheduledMessages.sort((a, b) => dayjs(b.send_at).diff(a.send_at));

                state.selectedContact = {
                    ...state.selectedContact,
                    scheduled_messages: scheduledMessages,
                };
            }
        },

        deleteScheduledMessage(state, { contact, scheduled_message }) {
            if (state.selectedContact?.id === contact.id) {
                let index = state.selectedContact.scheduled_messages.findIndex((t) => t.id === scheduled_message.id);

                if (index > -1) {
                    state.selectedContact.scheduled_messages.splice(index, 1);
                }
            }
        },

        upsertCustomAttr(state, { contact, custom_attribute }) {
            if (state.selectedContact?.id === contact.id) {
                const isExists = state.selectedContact.custom_attributes.some(
                    (ca) => ca.key.toLowerCase() === custom_attribute.key.toLowerCase()
                );
                const custom_attributes = !isExists
                    ? [...state.selectedContact.custom_attributes, custom_attribute]
                    : state.selectedContact.custom_attributes.map((ca) =>
                          ca.key.toLowerCase() === custom_attribute.key.toLowerCase()
                              ? { ...ca, ...custom_attribute }
                              : ca
                      );

                state.selectedContact = {
                    ...state.selectedContact,
                    custom_attributes: custom_attributes,
                };
            }
        },

        deleteCustomAttr(state, { contact, custom_attribute }) {
            if (state.selectedContact?.id === contact.id) {
                const custom_attributes = state.selectedContact.custom_attributes.filter(
                    (ca) => ca.key.toLowerCase() !== custom_attribute.key.toLowerCase()
                );

                state.selectedContact = {
                    ...state.selectedContact,
                    custom_attributes: custom_attributes,
                };
            }
        },

        pushTag(state, { contact, tag }) {
            if (state.selectedContact?.id === contact.id) {
                const isExists = state.selectedContact.tags?.some(
                    (ca) => ca.tag_name.toLowerCase() === tag.tag_name.toLowerCase()
                );
                const tags = !isExists
                    ? [...state.selectedContact.tags, tag]
                    : state.selectedContact.tags.map((ca) =>
                          ca.tag_name.toLowerCase() === tag.tag_name.toLowerCase() ? { ...ca, ...tag } : ca
                      );

                state.selectedContact = {
                    ...state.selectedContact,
                    tags: tags,
                };
            }
        },

        pullTag(state, { contact, tag }) {
            if (state.selectedContact?.id === contact.id) {
                const tags = state.selectedContact.tags.filter(
                    (ct) => ct.tag_name?.toLowerCase() !== tag.tag_name?.toLowerCase()
                );

                state.selectedContact = {
                    ...state.selectedContact,
                    tags: tags,
                };
            }
        },

        upsertContactCampaign(state, { contact, campaign }) {
            const { selectedContact } = state;
            if (selectedContact?.id === contact.id) {
                const exists = selectedContact.campaigns.some((c) => c.id === campaign.id);
                const campaigns = !exists
                    ? [...selectedContact.campaigns, campaign]
                    : selectedContact.campaigns.map((c) => (c.id === campaign.id ? { ...c, ...campaign } : c));

                state.selectedContact = {
                    ...selectedContact,
                    campaigns,
                };
            }
        },

        deleteContactCampaign(state, { contact, campaign }) {
            const { selectedContact } = state;
            if (selectedContact?.id === contact.id) {
                state.selectedContact = {
                    ...selectedContact,
                    campaigns: selectedContact.campaigns.filter((c) => c.id !== campaign.id),
                };
            }
        },

        updateCampaign(state, campaign) {
            const { selectedContact } = state;
            if (selectedContact) {
                state.selectedContact = {
                    ...selectedContact,
                    campaigns: selectedContact.campaigns.map((c) => (c.id === campaign.id ? { ...c, ...campaign } : c)),
                };
            }
        },

        deleteCampaign(state, campaignId) {
            const { selectedContact } = state;
            if (selectedContact) {
                state.selectedContact = {
                    ...selectedContact,
                    campaigns: selectedContact.campaigns.filter((c) => c.id !== campaignId),
                };
            }
        },

        upsertPromotionCoupon(state, { contact, coupon, promotion }) {
            const { selectedContact } = state;
            if (selectedContact?.id === contact.id && selectedContact.promotion_coupons) {
                const exists = selectedContact.promotion_coupons.some((c) => c.id === coupon.id);
                const promotionCoupon = {
                    ...coupon,
                    promotion: { ...promotion },
                };
                const promotionCoupons = !exists
                    ? [...selectedContact.promotion_coupons, promotionCoupon]
                    : selectedContact.promotion_coupons.map((c) =>
                          c.id === coupon.id ? { ...c, ...promotionCoupon } : c
                      );

                state.selectedContact = {
                    ...selectedContact,
                    promotion_coupons: promotionCoupons,
                };
            }
        },

        updatePromotion(state, { promotion }) {
            const { selectedContact } = state;
            if (selectedContact && selectedContact.promotion_coupons) {
                state.selectedContact = {
                    ...selectedContact,
                    promotion_coupons: selectedContact.promotion_coupons.map((promotionCoupon) => {
                        return promotionCoupon.promotion?.id === promotion.id
                            ? {
                                  ...promotionCoupon,
                                  promotion: {
                                      ...promotionCoupon.promotion,
                                      ...promotion,
                                  },
                              }
                            : promotionCoupon;
                    }),
                };
            }
        },

        deletePromotion(state, promotionId) {
            const { selectedContact } = state;
            if (selectedContact && selectedContact.promotion_coupons) {
                state.selectedContact = {
                    ...selectedContact,
                    promotion_coupons: selectedContact.promotion_coupons.filter((c) => c.promotion?.id !== promotionId),
                };
            }
        },

        markContactAsDeleted(state, { contactId }) {
            if (!state.deletedContacts.includes(contactId)) {
                state.deletedContacts = [...state.deletedContacts, contactId];
            }
        },

        setContactSubscriptions(state, subscriptions = []) {
            state.selectedContact.subscriptions = subscriptions;
        },
    },
    actions: {
        async loadTagsList({ commit }, { contactId, $toasted }) {
            try {
                const teamId = Spark.state.currentTeam.id;
                const response = await contactsClient.contactTagsList(teamId, contactId);

                commit('setTags', response.data.data);

                return response;
            } catch (error) {
                $toasted.global.platform_error();

                return Promise.reject(error);
            }
        },

        async loadCustomAttributesList({ commit }, { contactId, $toasted }) {
            try {
                const teamId = Spark.state.currentTeam.id;
                const response = await contactsClient.contactCustomAttributesList(teamId, contactId);

                commit('setCustomAttributes', response.data.data);

                return response;
            } catch (error) {
                $toasted.global.platform_error();

                return Promise.reject(error);
            }
        },
        async loadConversation({ commit, state }, { contactId, $toasted, thread }) {
            if (thread) {
                commit('setActiveContact', {
                    ...contactDefaultData,
                    id: contactId,
                    team_id: thread.team_id,
                    first_name: thread.contact_name,
                    last_name: undefined,
                    phone: thread.contact_phone,
                });
            }

            const teamId = Spark.state.currentTeam.id;

            contactGetAbort?.abort();
            contactGetAbort = new AbortController();

            listMessagesAbort?.abort();
            listMessagesAbort = new AbortController();

            getContactSubscriptionStatusAbort?.abort();
            getContactSubscriptionStatusAbort = new AbortController();

            const catchError = (error) => {
                if (error?.response?.status === HTTP_NOT_FOUND) {
                    commit('markContactAsDeleted', { contactId });
                    commit('pullContact', { id: contactId });
                } else if (!axios.isCancel(error)) {
                    console.error(error);
                    $toasted.global.platform_error();
                }
            };

            messagesClient
                .listMessages(teamId, contactId, { sort_dir: 'desc' }, { signal: listMessagesAbort.signal })
                .then((messagesResponse) => {
                    state.selectedContactMessagesLinks = {
                        prev: messagesResponse.data.prev_page_url,
                        next: messagesResponse.data.next_page_url,
                    };
                    state.selectedContact.messages = messagesResponse.data.data;
                })
                .catch(catchError);

            contactsClient
                .getContactSubscriptionStatus(teamId, contactId, { signal: getContactSubscriptionStatusAbort.signal })
                .then((subscriptionsResponse) => {
                    commit('setContactSubscriptions', subscriptionsResponse.data.data);
                })
                .catch(catchError);

            return contactsClient
                .contactGet(teamId, contactId, { signal: contactGetAbort.signal })
                .then((contactResponse) => {
                    commit('setActiveContact', contactResponse.data);
                    return contactResponse;
                })
                .catch(catchError);
        },
        async loadMoreMessages({ state }, { $toasted }) {
            if (state.loadingMoreMessages) {
                return Promise.resolve();
            }

            const link = state.selectedContactMessagesLinks.next;

            if (!link) {
                return Promise.resolve();
            }

            try {
                state.loadingMoreMessages = true;
                const moreMessagesResponse = await messagesClient.listMessagesUsingLink(link);
                state.selectedContactMessagesLinks = {
                    prev: moreMessagesResponse.data.prev_page_url,
                    next: moreMessagesResponse.data.next_page_url,
                };
                state.selectedContact.messages = [...moreMessagesResponse.data.data, ...state.selectedContact.messages];
            } catch (error) {
                $toasted.global.platform_error();
                console.error(error);
                throw error;
            } finally {
                setTimeout(() => {
                    state.loadingMoreMessages = false;
                }, 2000);
            }
        },
        async loadScheduledMessages({ commit }, { contactId, $toasted }) {
            loadScheduledMessagesAbort?.abort();
            loadScheduledMessagesAbort = new AbortController();

            try {
                const teamId = Spark.state.currentTeam.id;
                const responseScheduled = await scheduledMessagesClient.getContactScheduledMessages(
                    teamId,
                    contactId,
                    undefined,
                    {
                        signal: loadScheduledMessagesAbort.signal,
                    }
                );

                commit('setScheduledMessages', responseScheduled.data.data);
                commit('setScheduledMessagesCursor', responseScheduled.data.next_page_url);
            } catch (e) {
                if (!axios.isCancel(e)) {
                    console.error(e);
                    $toasted.global.platform_error();
                }
            } finally {
                loadScheduledMessagesAbort = undefined;
            }
        },
        async loadNextScheduledMessages({ commit, state }, { contactId, $toasted }) {
            const teamId = Spark.state.currentTeam.id;
            try {
                const response = await scheduledMessagesClient.getContactScheduledMessages(teamId, contactId, {
                    cursor: state.selectedContact.scheduled_messages_cursor,
                });

                commit('setScheduledMessages', state.selectedContact.scheduled_messages.concat(response.data.data));
                commit('setScheduledMessagesCursor', response.data.next_page_url);

                return response;
            } catch (error) {
                if (error.response.status !== HTTP_NOT_FOUND) {
                    $toasted.global.platform_error();
                } else {
                    commit('markContactAsDeleted', { contactId });
                    commit('pullContact', { id: contactId });
                }
                return Promise.reject(error);
            }
        },
    },
};
