import axios from '~/services/axios';
import Swal from 'sweetalert2';
import clientRoutes from './client-routes';
import scrollTo from './scroll-to';

export default {
    mixins: [clientRoutes, scrollTo],

    data() {
        return {
            resourceName: 'resource',
            collectionTitle: 'All Resources',
            resourceUrl: '/api/resources',
            resourceReadUrl: undefined,
            resourceWriteUrl: undefined,

            showFilterUi: false,
            isSearchActive: false,
            loadingCollection: false,
            loadingCollectionError: false,
            collection: [],
            prevPageUrl: null,
            nextPageUrl: null,
            totalPages: 0,
            stash: {},
            showResourceObj: null,
            editResourceObj: null,
            newResourceObj: null,
            copyObj: null,
            collectionWasLoaded: false,
        };
    },

    computed: {
        clearEditObj() {
            return true;
        },
        clearNewObj() {
            return true;
        },
    },
    methods: {
        newResource(obj) {
            this.newResourceObj = obj;
        },

        cancelNewResource() {
            this.newResourceObj = null;
        },

        createResource(data) {
            this.createUpdateResource('post', this.resourceWriteUrl ?? this.resourceUrl, data, 'resourceCreated');
        },

        resourceCreated(data) {
            Bus.$emit(`${this.resourceName}ResourceWasCreated`, data);
            this.collection.unshift(data);
            if (this.clearNewObj) {
                this.newResourceObj = null;
            }
            this.afterResourceCreated(data);
        },

        afterResourceCreated() {
            //for extension later
        },

        updateResource(id, data) {
            this.createUpdateResource(
                'put',
                `${this.resourceWriteUrl ?? this.resourceUrl}/${id}`,
                data,
                'resourceUpdated'
            );
        },

        resourceUpdated(data) {
            const prevData = this.collection.find((obj) => obj.id == data.id);
            Bus.$emit(`${this.resourceName}ResourceWasUpdated`, data, prevData);
            let objIndex = this.collection.findIndex((obj) => obj.id == data.id);
            this.collection[objIndex] = data;
            if (this.clearEditObj) {
                this.editResourceObj = null;
            }
            this.afterResourceUpdated(data);
        },

        resourceCopy(data) {
            Bus.$emit(`${this.resourceName}ResourceWasCopy`, data);
            let objIndex = this.collection.findIndex((obj) => obj.id == data.id);
            this.collection[objIndex] = data;
        },

        afterResourceUpdated() {
            //for extension later
        },

        createUpdateResource(method, url, data, completionMethod, errorMethod = 'handleCreateUpdateError') {
            Bus.$emit(`${this.resourceName}ResourceBusy`, true);

            axios[method](url, data)
                .then((response) => {
                    this[completionMethod](response.data);
                })
                .catch((error) => {
                    this[errorMethod](error);
                })
                .finally(() => {
                    Bus.$emit(`${this.resourceName}ResourceBusy`, false);
                });
        },

        fetchAndUpdateResource(id, attributes) {
            Bus.$emit(`${this.resourceName}Pending-${id}`, true);

            axios
                .get(`${this.resourceReadUrl ?? this.resourceUrl}/${id}`)
                .then(({ data }) => {
                    const updateObject = { ...data, ...attributes };

                    Bus.$emit(`${this.resourceName}Update`, id, updateObject);
                })
                .catch((error) => {
                    console.log(error.response.data);
                    this.$toasted.global.platform_error();
                });
        },

        handleCreateUpdateError(error) {
            if (error.response.status === 422) {
                const responseErrors = error.response.data.errors;
                let errors = [];

                Bus.$emit(`${this.resourceName}ResourceErrors`, responseErrors);

                Object.keys(responseErrors).forEach((key) => {
                    const keyErrors = responseErrors[key].map((x) => `<p>${x}</p>`).join('\n');

                    errors.push(keyErrors);
                });

                let errorMessage = '<p>We found the following errors:</p><br />' + errors.join('\n');

                Swal.fire({
                    title: 'Oops..',
                    html: errorMessage,
                    icon: 'error',
                });
            } else {
                console.log(error);
                this.$toasted.global.platform_error();
            }
        },

        editResource(id) {
            Bus.$emit(`${this.resourceName}Pending-${id}`, true);

            axios
                .get(`${this.resourceReadUrl ?? this.resourceUrl}/${id}`)
                .then((response) => {
                    this.editResourceObj = response.data;
                    this.scrollToPageTop();
                })
                .catch((error) => {
                    console.log(error.response.data);
                    this.$toasted.global.platform_error();
                })
                .finally(() => {
                    Bus.$emit(`${this.resourceName}Pending-${id}`, false);
                });
        },

        cancelEditResource() {
            this.editResourceObj = null;
        },

        showResource(id) {
            Bus.$emit(`${this.resourceName}Pending-${id}`, true);

            axios
                .get(`${this.resourceReadUrl ?? this.resourceUrl}/${id}`)
                .then((response) => {
                    this.showResourceObj = response.data;
                    this.scrollToPageTop();
                    this.afterShowResource(this.showResourceObj);
                })
                .catch((error) => {
                    console.log(error.response.data);
                    this.$toasted.global.platform_error();
                })
                .finally(() => {
                    Bus.$emit(`${this.resourceName}Pending-${id}`, false);
                });
        },

        afterShowResource() {
            //for extension later
        },

        cancelShowResource() {
            this.showResourceObj = null;
        },

        deleteResource(id) {
            Bus.$emit(`${this.resourceName}Pending-${id}`, true);

            axios
                .delete(`${this.resourceWriteUrl ?? this.resourceUrl}/${id}`)
                .then(() => {
                    this.collection = this.collection.filter((e) => e.id != id);
                    this.afterDeleteResource();
                })
                .catch(({ response }) => {
                    if (response.status >= 500) {
                        this.$toasted.global.platform_error();
                    } else {
                        this.$toasted.show(response.data?.message || 'Something went wrong.');
                    }
                })
                .finally(() => {
                    Bus.$emit(`${this.resourceName}Pending-${id}`, false);
                });
        },

        afterDeleteResource() {
            //for extension later
        },

        loadCollectionAndUi() {
            let url = this.resourceReadUrl ?? this.resourceUrl;
            let route = this.currentClientRoute();

            if (route) {
                if (route.hash === `${this.resourceName}-pages`) {
                    url += '?page=' + route.parameters[0];
                } else if (route.hash == 'new') {
                    Bus.$emit(`${this.resourceName}New`);
                } else if (route.hash == 'edit') {
                    Bus.$emit(`${this.resourceName}Edit`, parseInt(route.parameters[0]));
                } else if (route.hash == 'show') {
                    Bus.$emit(`${this.resourceName}Show`, parseInt(route.parameters[0]));
                } else if (route.hash == 'search') {
                    this.showFilterUi = true;
                    return;
                }
            }

            this.loadCollectionFromUrl(url);
        },

        setCollection(data) {
            this.collection = data || [];
            this.afterSetCollection(data);
        },

        setLoadedCollectionResponse(data) {
            this.collectionWasLoaded = true;
            this.showFilterUi = true;
            this.setCollection(data.data);
            this.currentPage = data.current_page;
            this.totalPages = data.last_page;
            this.prevPageUrl = data.prev_page_url;
            this.nextPageUrl = data.next_page_url;
        },

        afterSetCollection() {
            //for extension later
        },

        loadCollectionFromUrl(url) {
            if (this.loadingCollection) {
                return;
            }

            this.loadingCollection = true;
            this.loadingCollectionError = false;

            axios
                .get(url)
                .then((response) => {
                    this.setLoadedCollectionResponse(response.data);
                })
                .catch((error) => {
                    this.loadingCollectionError = true;
                    console.log(error.response.data);
                })
                .finally(() => {
                    this.loadingCollection = false;
                });
        },

        loadFilteredCollection(data, title) {
            this.loadingCollection = false;

            if (!Object.keys(this.stash).length) {
                console.log('We store stash');
                this.stash = {};
                this.stash.collectionTitle = this.collectionTitle;
                this.stash.collection = this.collection;
                this.stash.currentPage = this.currentPage;
                this.stash.totalPages = this.totalPages;
                this.stash.prevPageUrl = this.prevPageUrl;
                this.stash.nextPageUrl = this.nextPageUrl;
            } else {
                console.log('We do not store stash');
            }

            this.collectionTitle = title;
            this.collection = data.data || [];
            this.currentPage = data.current_page;
            this.totalPages = data.last_page;
            this.prevPageUrl = data.prev_page_url;
            this.nextPageUrl = data.next_page_url;
        },

        unloadFilteredCollection() {
            if (!this.collectionWasLoaded || !Object.keys(this.stash).length) {
                this.collection = [];
                this.collectionTitle = `All ${this.resourceName}`;
                this.loadCollectionAndUi();
                return;
            }

            console.log('Read from stash');
            this.collectionTitle = this.stash.collectionTitle;
            this.collection = this.stash.collection;
            this.currentPage = this.stash.currentPage;
            this.totalPages = this.stash.totalPages;
            this.prevPageUrl = this.stash.prevPageUrl;
            this.nextPageUrl = this.stash.nextPageUrl;
            this.stash = {};
            console.log('Set stash to {}');
        },

        copyResource(method, url, data, errorMethod = 'handleCreateUpdateError') {
            Bus.$emit(`${this.resourceName}ResourceBusy`, true);

            axios[method](url, data)
                .then((response) => {
                    this(response.data);
                    console.log('called and tested');
                })
                .catch((error) => {
                    this[errorMethod](error);
                })
                .finally(() => {
                    Bus.$emit(`${this.resourceName}ResourceBusy`, false);
                });
        },

        copyCollectionRow(id) {
            Bus.$emit(`${this.resourceName}Pending-${id}`, true);

            axios
                .get(`${this.resourceReadUrl ?? this.resourceUrl}/${id}`)
                .then((response) => {
                    this.copyObj = response.data;
                    delete this.copyObj.id;

                    this.copyObj.name = 'COPY OF ' + this.copyObj.name;
                    this.copyObj.status = 'draft';
                    this.copyObj.segment_ids = this.copyObj.segments;
                    this.copyResource('post', this.resourceWriteUrl ?? this.resourceUrl, this.copyObj);
                })
                .catch((error) => {
                    console.log(error.response.data);
                    this.$toasted.global.platform_error();
                })
                .finally(() => {
                    Bus.$emit(`${this.resourceName}Pending-${id}`, false);
                    location.reload(true);
                });
        },
        copyCollectionRowAutomations(id) {
            Bus.$emit(`${this.resourceName}Pending-${id}`, true);

            axios
                .get(`${this.resourceReadUrl ?? this.resourceUrl}/${id}`)
                .then((response) => {
                    this.newResourceObj = response.data;
                    delete this.newResourceObj.id;
                    this.newResourceObj.name = '';
                    this.newResourceObj.automated_actions.forEach((el) => {
                        el.status = 'draft';
                    });
                    this.newResourceObj.status = 'draft';
                })
                .catch((error) => {
                    console.log(error.response.data);
                    this.$toasted.global.platform_error();
                })
                .finally(() => {
                    Bus.$emit(`${this.resourceName}Pending-${id}`, false);
                });
        },

        /**
         * Should be called in component created()
         */
        listenForResourceEvents() {
            setTimeout(() => this.usePushStateForClientRoutes(), 500);

            Bus.$on(`${this.resourceName}New`, (obj = {}) => {
                this.newResource(obj);
            });

            Bus.$on(`${this.resourceName}CancelNew`, () => {
                this.cancelNewResource();
            });

            Bus.$on(`${this.resourceName}Create`, (data) => {
                this.createResource(data);
            });

            Bus.$on(`${this.resourceName}Edit`, (id) => {
                this.editResource(id);
            });

            Bus.$on(`${this.resourceName}CancelEdit`, (id) => {
                this.cancelEditResource(id);
            });

            Bus.$on(`${this.resourceName}Update`, (id, data) => {
                this.updateResource(id, data);
            });

            Bus.$on(`${this.resourceName}Show`, (id) => {
                this.showResource(id);
            });

            Bus.$on(`${this.resourceName}CancelShow`, (id) => {
                this.cancelShowResource(id);
            });

            Bus.$on(`${this.resourceName}Delete`, (id) => {
                this.deleteResource(id);
            });

            Bus.$on(`${this.resourceName}-pages-collection-updated`, (total, data) => {
                this.collection = data || [];
            });

            Bus.$on(`${this.resourceName}-search-results`, (data, heading = 'Search Results') => {
                this.isSearchActive = true;
                this.loadFilteredCollection(data, heading);
            });

            Bus.$on(`${this.resourceName}-search-cancel`, () => {
                this.isSearchActive = false;
                this.unloadFilteredCollection();
            });

            Bus.$on(`${this.resourceName}Created`, (data) => {
                this.resourceCreated(data);
            });

            Bus.$on(`${this.resourceName}Copy`, (data) => {
                this.copyCollectionRow(data);
            });

            Bus.$on(`${this.resourceName}CopyAutomations`, (data) => {
                this.copyCollectionRowAutomations(data);
            });
        },
    },
};
