<template>
    <vx-expandable-card
        toggleable
        id="when"
        :active="active"
    >
        <template v-slot:header>
            <span
                class="vx-pr-1.5 vx-mr-2 vx-border-y-0 vx-border-l-0 vx-border-r vx-border-solid sm:vx-pr-3 sm:vx-mr-4"
                :class="{
                    'vx-border-r-transparent': !showDate,
                    'vx-border-r-slate-400': showDate,
                }"
            >
                When
            </span>
            <span
                v-if="showDate"
                class="vx-text-xs vx-font-normal vx-text-slate-700 sm:vx-text-base sm:vx-font-semibold"
                data-test="when-date"
            >
                {{
                    (props.modelValue === 'now' || !props.modelValue) ? 'Now' : (props.modelValue && dateFormatted)
                }}
            </span>
        </template>

        <template v-if="editable && !props.success" v-slot:headerRight>
            <vx-button
                @click.stop="emit('update:active', true)"
                color="muted"
                size="md"
            >
                <span class="vx-inline-block">Edit</span>
                <font-awesome-icon class="vx-text-base vx-hidden sm:vx-inline-block" :icon="faPencil"></font-awesome-icon>
            </vx-button>
        </template>

        <template v-slot:content>
            <vx-tabs v-model="currentTab">
                <vx-tab value="now">Now</vx-tab>
                <vx-tab value="later">Later</vx-tab>
            </vx-tabs>

            <template v-if="currentTab === 'now'">
                <div
                    class="vx-text-center vx-flex vx-justify-center vx-text-slate-600 vx-text-lg vx-font-normal vx-my-12"
                >
                    <span class="vx-text-center vx-block">
                        Your message will send immediately after clicking the
                        launch button.
                    </span>
                </div>
            </template>
            <template v-if="currentTab === 'later'">
                <div class="vx-flex vx-justify-center vx-my-4 lg:vx-my-10">
                    <vx-date-picker
                        v-model="date"
                        mode="datetime"
                        :timezone="getClientTz()"
                        :minDate="dayjs().tz(getClientTz()).add(4, 'minutes').toDate()"
                    ></vx-date-picker>
                </div>
            </template>
            <vx-alert color="info">
                You should respect safe sending hours. (Texting is allowed
                between 8 am to 9 pm in the contact's time-zone.)
            </vx-alert>

            <vx-button
                @click="confirm()"
                data-test="when-continue"
                class="vx-mt-4 lg:vx-mt-6"
                size="lg"
                :block="true"
            >
                Preview
                <font-awesome-icon
                    class="vx-text-base"
                    :icon="faArrowTurnDown"
                ></font-awesome-icon>
            </vx-button>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import {
    VxExpandableCard,
    VxDatePicker,
    VxTabs,
    VxTab,
    VxAlert,
    VxButton,
} from "@voxie/frontend-components";
import { computed, ref, watch } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPencil, faArrowTurnDown } from '@fortawesome/pro-solid-svg-icons';
import { getClientTz, formatTz } from '../../utils/date';
import dayjs from '~/utils/dayjs';

const currentTab = ref("now");

const props = defineProps({
    modelValue: {
        required: false,
    },
    active: {
        type: Boolean,
        default: false,
    },
    success: {
        type: Boolean,
        default: true,
    },
});

const dateFormatted = computed(() => {
    const timezone = dayjs(date.value).tz(getClientTz()).format('z');

    return dayjs(date.value).tz(getClientTz()).format("MMM DD, YYYY [at] h:mm a") +
        ' ' +
        formatTz(timezone)
    }
);

const date = ref('now');
const emit = defineEmits(["update:modelValue", "update:active", "continue"]);

watch(
    () => props.modelValue,
    (newModelValue) => (date.value = newModelValue),
    {
        immediate: true,
    }
);
watch(date, (newDate) => emit("update:modelValue", newDate));
watch(currentTab, (newCurrentTab) => {
    if (newCurrentTab === "now") {
        date.value = 'now';
    } else if (newCurrentTab === "later") {
        date.value = dayjs().tz(getClientTz()).add(5, 'minutes').toDate()
    }
});

const showDate = computed(
    () => props.active || (props.modelValue)
);
const editable = computed(
    () => !props.active && props.modelValue
);

const confirm = () => {
    if (currentTab.value === 'now') {
        date.value = 'now';
    }

    emit('continue');
}
</script>
