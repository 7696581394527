<template>
    <vx-expandable-card>
        <template v-slot:header> Audience Type </template>
        <template v-slot:headerSubtitle> Select the type of message you’d like to send. This selection will limit the
            segments you can choose. </template>

        <template v-slot:content>
            <div class="vx-mt-2">
                <audience-type-radio-selection :modelValue="props.modelValue"
                    @update:modelValue="emit('update:modelValue', $event)"
                    :disabled="props.campaignSegments.length !== 0 || props.disabled">
                </audience-type-radio-selection>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import {
    VxExpandableCard,
} from "@voxie/frontend-components";
import AudienceTypeRadioSelection from '~/components/general/segments/AudienceTypeRadioSelection.vue';

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
    },
    campaignSegments: {
        type: Array,
        required: true,
    },
});

const emit = defineEmits(["update:modelValue"]);
</script>
