<template>
    <vx-expandable-card>
        <template v-slot:header> Type </template>

        <template v-slot:content>
            <vx-radio-selection
                :modelValue="props.modelValue"
                @update:modelValue="emit('update:modelValue', $event)"
                :disabled="props.disabled"
            >
                <vx-radio-selection-item :value="SegmentType.AUTO_REFRESH">
                    <template v-slot:header>{{
                        SegmentTypeToLabel[SegmentType.AUTO_REFRESH]
                    }}</template>
                    <template v-slot:content>
                        Updates automatically when contacts change to meet the
                        filter criteria below.
                    </template>
                </vx-radio-selection-item>
                <vx-radio-selection-item :value="SegmentType.STATIC">
                    <template v-slot:header>{{
                        SegmentTypeToLabel[SegmentType.STATIC]
                    }}</template>
                    <template v-slot:content>
                        Contacts won't change unless you manually refresh this
                        segment.
                    </template>
                </vx-radio-selection-item>
            </vx-radio-selection>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import {
    VxExpandableCard,
    VxRadioSelection,
    VxRadioSelectionItem,
} from "@voxie/frontend-components";
import { SegmentTypeToLabel, SegmentType } from '../../../../constants/segment';

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
    },
});

const emit = defineEmits(["update:modelValue"]);
</script>
