<template>
    <page-container v-if="!loading">
        <template v-if="!success">
            <step-form-heading v-if="!props.authConfigId" :icon="faFingerprint">
                <experimental-pill class="-vx-mt-10 -vx-mr-4 vx-mb-2" type="alpha"></experimental-pill>
                <div>
                    New API Auth
                </div>

                <template v-slot:description>
                    Store API credentials safe and securely for reuse in your automations.
                </template>
            </step-form-heading>
            <page-header v-if="props.authConfigId" class="vx-mb-6 lg:vx-mb-12">
                <template v-slot:subtitle>
                    <a href="/settings/integrations#/auth-configs"
                        class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                        <font-awesome-icon class="vx-mr-1" :icon="faArrowLeft"></font-awesome-icon>
                        All API Auths
                    </a>
                </template>

                {{ data.friendly_name }}
            </page-header>
            <div :class="{
                'vx-grid vx-grid-cols-12': props.authConfigId,
            }">
                <page-sidebar v-if="props.authConfigId" :links="[
                    {
                        icon: faCog,
                        label: 'Settings',
                        href: '/settings/integrations/auth-configs/' + props.authConfigId,
                    }
                ]"></page-sidebar>
                <vx-sticky-nav :sections="[
                    { id: 'name', label: 'Name' },
                    { id: 'headers', label: 'Headers' },
                ]" class="vx-col-span-12" :class="{
    'lg:vx-col-span-10': !!props.authConfigId,
}" :navClasses="`${props.authConfigId ? 'vx-hidden' : ''}`">

                    <form @submit.prevent="handleSubmit()" autocomplete="off" id="auth-config-form"
                        class="vx-flex vx-flex-col vx-mx-auto vx-justify-center vx-gap-2 md:vx-gap-8">

                        <vx-expandable-card id="name" class="vx-max-w-screen-sm vx-w-full" :class="{
                            'vx-mx-auto': !props.authConfigId,
                        }">
                            <template v-slot:content>
                                <auth-config-form-name v-model="data.friendly_name"
                                    :error="errors?.friendly_name"></auth-config-form-name>
                            </template>
                        </vx-expandable-card>

                        <vx-expandable-card id="headers" class="vx-max-w-screen-sm vx-w-full" :class="{
                            'vx-mx-auto': !props.authConfigId,
                        }">
                            <template v-slot:content>
                                <auth-config-form-headers
                                    :defaultTab="defaultTab"
                                    :authConfigId="props.authConfigId"
                                    v-model:name="data.header_name"
                                    v-model:value="data.header_value"
                                    :error="errors?.header_name || errors?.header_value">
                                </auth-config-form-headers>
                            </template>
                        </vx-expandable-card>

                        <vx-sticky-footer class="vx-max-w-screen-sm vx-w-full" :class="{
                            'vx-mx-auto': !props.authConfigId,
                        }">
                            <vx-button href="/settings/integrations#/auth-configs" class="vx-w-20" size="lg"
                                color="muted">
                                Cancel
                            </vx-button>
                            <vx-button form="auth-config-form" type="submit" class="vx-grow" size="lg" color="primary"
                                :loading="submitting">
                                {{ props.authConfigId ? 'Update' : 'Create' }} API Auth
                            </vx-button>
                        </vx-sticky-footer>
                    </form>
                </vx-sticky-nav>
            </div>
        </template>
        <template v-else>
            <success-box>
                Your API Auth has been {{ props.authConfigId ? 'updated' : 'created' }}!

                <template v-slot:action>
                    <vx-button size="lg" href="/settings/integrations#/auth-configs" color="secondary">
                        View All API Auths
                        <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                    </vx-button>
                </template>
            </success-box>
        </template>
    </page-container>
</template>

<script setup>
import {
    VxStickyNav,
    VxStickyFooter,
    VxButton,
    VxExpandableCard
} from "@voxie/frontend-components";
import StepFormHeading from '../../../../general/StepFormHeading.vue';
import { faArrowRight, faArrowLeft, faFingerprint, faCog } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ExperimentalPill from "../../../../general/ExperimentalPill.vue";
import AuthConfigFormName from './AuthConfigFormName.vue';
import AuthConfigFormHeaders from './AuthConfigFormHeaders.vue';
import SuccessBox from '../../../../general/SuccessBox.vue';
import PageHeader from '../../../../general/PageHeader.vue';
import PageContainer from '../../../../general/PageContainer.vue';
import PageSidebar from '../../../../general/PageSidebar.vue';
import { useAuthConfigForm } from "./useAuthConfigForm";
import { onMounted, ref } from "vue";

const props = defineProps({
    authConfigId: {
        type: String,
        required: false,
        default: undefined,
    }
});

const { data, errors, submit, submitting, loading, success } = useAuthConfigForm();

const handleSubmit = async () => {
    try {
        await submit();
    } catch (e) {
        console.error(e);
    }
}

const defaultTab = ref('basic');

onMounted(() => {
    if (props.authConfigId) {
       data.value.auth_config_id = props.authConfigId;
       defaultTab.value = 'custom';
    }
});
</script>
