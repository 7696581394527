<template>
    <api-delivery-card :icon="faRobot">
        <template v-slot:title>
            Automation
        </template>
        <template v-slot:headerRight>
            <vx-button v-if="props.automationRule" :href="`/automation/event/${props.automationRule.id}`" target="_blank"
                    size="sm" color="muted-light">Edit <font-awesome-icon :icon="faArrowUpRight" /></vx-button>
        </template>

        <template v-slot:content>
            <div v-if="props.automationRule === undefined" class="vx-w-full vx-flex vx-items-center vx-justify-center">
                <vx-icon-loading spin></vx-icon-loading>
            </div>
            <template v-else-if="props.automationRule">
                <div class="vx-text-slate-500 vx-text-xl vx-font-normal vx-break-all">{{ props.automationRule.name }}</div>
            </template>
            <div v-else
                class="vx-w-full vx-p-4 vx-bg-slate-50 vx-rounded-[10px] vx-justify-start vx-items-center vx-gap-3 vx-inline-flex">
                <font-awesome-icon :icon="faGhost" class="vx-text-slate-500" />
                <div class="vx-text-sky-950 vx-text-sm vx-font-normal">Automation missing</div>
            </div>
        </template>
    </api-delivery-card>
</template>
<script setup>
import ApiDeliveryCard from './ApiDeliveryCard.vue';
import { VxButton, VxIconLoading } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faRobot, faArrowUpRight, faGhost } from '@fortawesome/pro-solid-svg-icons';

const props = defineProps({
    automationRule: {
        type: Object,
        required: false,
    },
});
</script>
