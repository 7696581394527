<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        size="lg"
        :padding="false"
        :stacked="authModalVisible"
        >
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6 vx-flex vx-items-center vx-gap-2">
                <span>
                    {{ data.id ? 'Edit' : 'New' }}  API Destination
                </span>
                <experimental-pill type="alpha"
                    class="-vx-mt-1"></experimental-pill>
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">This will {{ data.id ? 'update' : 'create' }}  and select a new API Destination.</div>
        </template>

        <form @submit.prevent="handleSubmit()"
            id="api-destination-modal-form"
            class="vx-flex vx-flex-col vx-gap-4 vx-py-4 vx-px-5 lg:vx-py-7 lg:vx-px-10">

            <api-destination-form-name ref="nameRef" v-model="data.friendly_name" :error="errors?.friendly_name"></api-destination-form-name>
            <api-destination-form-endpoint v-model="data.uri_template" :error="errors?.uri_template" :disabled="!!data.id"></api-destination-form-endpoint>
            <div v-if="data.id">
                <vx-button @click.prevent="makeACopy()"
                    color="muted"
                    size="sm"
                >
                    Make a copy <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
            </div>
            <api-destination-form-http-method v-model="data.http_method" :error="errors?.http_method"></api-destination-form-http-method>
            <api-destination-form-auth v-model="data.auth_config_id" :error="errors?.auth_config_id" v-model:authModalVisible="authModalVisible"></api-destination-form-auth>
        </form>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="emit('update:visible', false)"
                    type="button"
                    class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                    size="lg"
                    color="muted">
                    Cancel
                </vx-button>
                <vx-button type="submit"
                    form="api-destination-modal-form"
                    :loading="submitting"
                    class="vx-grow"
                    size="lg"
                    color="primary">
                    {{ data.id ? 'Update' : 'Create and Select' }} API Destination <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import {
    VxModal,
    VxButton,
} from "@voxie/frontend-components";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight, faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import ExperimentalPill from "../../../../general/ExperimentalPill.vue";
import ApiDestinationFormName from './ApiDestinationFormName.vue';
import ApiDestinationFormEndpoint from './ApiDestinationFormEndpoint.vue';
import ApiDestinationFormHttpMethod from './ApiDestinationFormHttpMethod.vue';
import ApiDestinationFormAuth from './ApiDestinationFormAuth.vue';
import { useApiDestinationForm } from "./useApiDestinationForm";
import { ref, watch } from "vue";

const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
    apiDestinationId: {
        type: String,
        required: false,
        default: null,
    }
});

const emit = defineEmits(['update:visible', 'upserted']);

const nameRef = ref();

const authModalVisible = ref(false);

const { data, errors, submit, submitting, duplicate } = useApiDestinationForm();

const handleSubmit = async () => {
    try {
        emit('upserted', await submit());
        emit('update:visible', false)
    } catch (e) {
        console.error(e);
    }
}

const makeACopy = () => {
    data.value.id = undefined;
    duplicate(props.apiDestinationId);
    nameRef.value.focus();
}

watch(() => props.visible, async () => {
    if (props.visible) {
        data.value.id = props.apiDestinationId;
    }
}, {
    immediate: true,
});
</script>
