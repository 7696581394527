<template>
    <div v-if="loading" class="vx-py-6 vx-flex vx-justify-center">
        <vx-icon-loading class="vx-w-9 vx-h-9" spin></vx-icon-loading>
    </div>

    <vx-alert v-else-if="props.unavailable" color="info" :closeable="false">
        AI Message Assistant is <strong>currently unavailable</strong>. Contact support or your Account Manager.
    </vx-alert>

    <vx-alert v-else-if="isAdminOrOwner && configured && saved" color="success" :closeable="false">
        <template v-slot:icon>
            <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
        </template>
        <div class="vx-text-lg vx-font-semibold">
            Configured
        </div>

        <div class="vx-mb-3">
            You configuration was saved. You can always adjust this under <a class="vx-text-inherit vx-underline" target="_blank" href="/settings/integrations#/ai-integration">Team Settings</a>.
        </div>
        <vx-button size="lg" color="success" type="button" @click.prevent="emit('update:configuring', true)">
            Edit Configuration
            <font-awesome-icon :icon="faGear"></font-awesome-icon>
        </vx-button>
    </vx-alert>

    <vx-alert v-else-if="isAdminOrOwner && !configured" color="info" :closeable="false">
        <div class="vx-text-lg vx-font-semibold">
            AI Configuration
        </div>

        <div class="vx-mb-3">This feature requires some configuration before it can be used.</div>

        <vx-button size="lg" color="info" type="button" @click.prevent="emit('update:configuring', true)">
            Configure your assistant
            <font-awesome-icon :icon="faGear"></font-awesome-icon>
        </vx-button>
    </vx-alert>

    <vx-alert v-else-if="!isAdminOrOwner && !configured" color="info" :closeable="false">
        <div class="vx-text-lg vx-font-semibold">
            AI Configuration
        </div>

        This feature requires some configuration before it can be used. Your account <strong>does not have correct permission</strong> to do this. Please contact your admin.
    </vx-alert>

    <ai-configuration-modal :visible="props.configuring" @update:visible="emit('update:configuring', $event)" @saved="onSaved($event)"></ai-configuration-modal>
</template>

<script setup>
import { VxAlert, VxButton, VxIconLoading } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleCheck, faGear } from '@fortawesome/pro-solid-svg-icons';
import { useAiConfiguration } from './useAiConfiguration'; 
import { ref } from 'vue';
import AiConfigurationModal from './AiConfigurationModal.vue';

const props = defineProps({
    unavailable: {
        type: Boolean,
        default: false,
    },
    configuring: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:configuring', 'saved'])

const isAdminOrOwner = Spark.isAdminOrOwner;

const { loading, configured, data } = useAiConfiguration();

const saved = ref(false);

const onSaved = (config) => {
    data.value = config;
    saved.value = true;
    emit('saved', config);
}
</script>
