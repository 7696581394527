<template>
    <div class="card card-default">
        <div class="card-header">Two-Factor Authentication</div>

        <div class="card-body">
            <!-- Information Message -->
            <div class="alert alert-info">
                In order to use two-factor authentication, you must install the Google Authenticator or any other compatible application on your smartphone.
            </div>

            <form role="form">
                <!-- Enable Button -->
                <div class="form-group row mb-0">
                    <div class="col-md-6">
                        <button type="submit" class="btn btn-primary" @click.prevent="enable" :disabled="form.busy">
                            <span v-if="form.busy"> <i class="fa fa-btn fa-spinner fa-spin"></i> Enabling </span>

                            <span v-else> Enable </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

defineProps({
    user: {
        type: Object,
        required: true
    }
});

const form = ref(new SparkForm({}));

const enable = () => {
    Spark.post('/settings/two-factor-auth', form.value).then(({two_factor_reset_code, qr_image}) => {
        Bus.$emit('receivedTwoFactorConfirmation', {two_factor_reset_code, qr_image});

        Bus.$emit('updateUser');
    });
}
</script>
