import BaseClient from './BaseClient';

class UsersClient extends BaseClient {
    searchUsers(teamId, params) {
        const url = `/api/v3/teams/${teamId}/search/users`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    usersCount(teamId, params) {
        const url = `/api/v3/teams/${teamId}/users/count`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    listUsers(teamId, params) {
        const url = `/api/v3/teams/${teamId}/users`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    updateUser(teamId, userId, params) {
        const url = `/api/v3/teams/${teamId}/users/${userId}`;
        return this.makeCoreApiRequest(url, 'put', params);
    }

    deleteUser(teamId, userId) {
        const url = `/api/v3/teams/${teamId}/users/${userId}`;
        return this.makeCoreApiRequest(url, 'delete');
    }
}

export default new UsersClient();
