<template>
    <multi-select-attribution-card
        entity="CampaignMessage"
        entityTitle="Campaign Message"
        entityDescription="campaign message"
        :selected-items="form.selected_campaigns"
        v-model:type="form.campaign_attribution_type"
        :loading-options="loadingOptions"
        :options="options"
        show-id
        @search-change="search"
    />
</template>

<script>
import MultiSelectAttributionCard from './MultiSelectAttributionCard.vue';
import { campaignsClient } from '../../../../services';

export default {
    components: {
        MultiSelectAttributionCard,
    },
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loadingOptions: false,
            options: [],
        };
    },

    created() {
        this.search('');
    },

    methods: {
        search(query) {
            this.loadingOptions = true;
            campaignsClient.campaignsGetRecords(Spark.state.currentTeam.id, { query })
                .then(({ data }) => {
                    this.options = data.data.map(c => ({
                        id: c.id,
                        name: c.name,
                        label: `${c.id} - ${c.name}`,
                    }));
                })
                .finally(() => {
                    this.loadingOptions = false;
                });
        },
    },
};
</script>
