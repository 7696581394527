<template>
    <div>
        <div class="vx-hidden vx-text-slate-900 vx-text-xs vx-font-semibold vx-grid-cols-12 vx-gap-2 vx-px-6 vx-py-4 vx-w-[calc(100%-5rem)] md:vx-grid">
            <div class="vx-col-span-4">
                Number
            </div>
            <div class="vx-col-span-2">
                State
            </div>
            <div class="vx-col-span-3">
                City
            </div>
            <div class="vx-col-span-3">
                Zip
            </div>
        </div>
        <div v-if="props.list.length"
            class="vx-border vx-border-solid vx-border-slate-200 vx-rounded-lg">
            <search-phone-number-row v-for="phoneNumber in list"
                :key="phoneNumber.phone_number"
                @selected="emit('selected', $event)"
                :phone-number="phoneNumber"></search-phone-number-row>
        </div>
    </div>
</template>

<script setup>
import SearchPhoneNumberRow from './SearchPhoneNumberRow.vue';

const emit = defineEmits(['selected']);

const props = defineProps({
    list: {
        type: Array,
        required: false,
        default: () => [],
    },
});
</script>
