<template>
    <div class="vx-flex vx-flex-col vx-gap-2.5">
        <slot></slot>
        <div class="vx-w-full vx-flex vx-items-center vx-gap-0.5">
            <div v-for="(item, index) in steps" :key="`progress-item-${item}`" class="vx-w-full vx-h-1 " :class="{
                'vx-bg-sky-500': item <= currentStep || props.completed,
                'vx-animate-pulse': item === currentStep && !props.completed,
                'vx-bg-sky-200': item > currentStep,
                'vx-rounded-lg': index === 0 || index === steps.length
            }">
            </div>
        </div>
        <slot name="info" :countdownSeconds="countdownSeconds"></slot>
    </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { useTimeoutFn } from "@vueuse/core";

const props = defineProps({
    maxSteps: {
        type: Number,
        required: true,
    },
    startStep: {
        type: Number,
        default: 1,
    },
    stepDuration: {
        type: Number,
        default: 30
    },
    completed: {
        type: Boolean,
        default: false,
    }
});

const emit = defineEmits(['step', 'finish']);

const steps = ref(Array.from({ length: props.maxSteps }, (_, index) => index + 1));

const currentStep = ref(props.startStep);
const countdownSeconds = ref(props.stepDuration);

const { start, stop } = useTimeoutFn(() => {
    if (currentStep.value === props.maxSteps && countdownSeconds.value === 0) {
        emit('finish');
        stop();
        return;
    }

    countdownSeconds.value--;
    if (countdownSeconds.value === 0) {
        countdownSeconds.value = props.stepDuration;
        currentStep.value++
        emit('step');
    }
    start();
}, 1000);


watch([() => props.completed, () => props.startStep], ([completed, startStep]) => {
    if (completed) {
        stop();
    }

    currentStep.value = startStep;
}, { immediate: true })

</script>
