<template>
    <vx-table-row>
        <vx-table-row-item class="xl:!vx-col-span-3">
            <template v-slot:label> Name: </template>
            <div class="vx-line-clamp-none vx-break-all lg:vx-line-clamp-5">
                {{ props.modelValue.promotion?.name }}
            </div>
        </vx-table-row-item>

        <vx-table-row-item class="xl:!vx-col-span-2">
            <template v-slot:label> Coupon: </template>
            <div class="vx-line-clamp-none vx-break-all lg:vx-line-clamp-5 xl:vx-py-2">
                {{ props.modelValue.code }}
            </div>
        </vx-table-row-item>

        <vx-table-row-item v-if="props.modelValue.allocated_at">
            <template v-slot:label> Allocated on: </template>

            <vx-popover hover
                        :arrow="true"
                        placement="top"
                        :key="allocatedAtKey">
                {{ fromNow(props.modelValue.allocated_at) }}
                <template v-slot:content>
                    {{ shortDateTimeTzSpread(props.modelValue.allocated_at) }}
                </template>
            </vx-popover>
        </vx-table-row-item>

    </vx-table-row>
</template>

<script setup>
import {
    VxPopover,
    VxTableRow,
    VxTableRowItem,
} from "@voxie/frontend-components";
import { fromNow, shortDateTimeTzSpread } from '~/utils/date';
import { useInterval } from '@vueuse/core';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

const allocatedAtKey = useInterval(60000);
</script>
