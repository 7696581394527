<template>
    <page-controls>
        <template v-slot:left>
            <div class="@3xl/controls:vx-w-1/2 @4xl/controls:vx-max-w-md">
                <vx-filterable :active="active"
                    @toggle="toggleFilterable">
                    <template v-slot:placeholder>
                        <span class="vx-mr-2">Find</span>
                        <vx-badge v-if="filtersCount > 0"
                            :borders="false"
                            size="xs"
                            @click.stop="reset">
                            {{ filtersCount }} filters
                            <font-awesome-icon :icon="faXmarkCircle" />
                        </vx-badge>
                    </template>
                    <template v-slot:form>
                        <form @submit.prevent="apply"
                            class="vx-space-y-4">
                            <div>
                                <vx-label>
                                    Keyword Search
                                </vx-label>

                                <vx-input autofocus
                                    type="text"
                                    data-test="filters-query"
                                    placeholder="Search"
                                    maxlength="191"
                                    v-model="filters.query">
                                    <template v-slot:right>
                                        <font-awesome-icon class="vx-text-xs"
                                            :icon="faMagnifyingGlass" />
                                    </template>
                                </vx-input>
                            </div>

                            <div>
                                <vx-label>
                                    Role
                                </vx-label>

                                <vx-selectable data-test="filters-roles"
                                    v-model="filters.role"
                                    :reduce="option => option.value"
                                    :clearable="true"
                                    :options="teamRolesOptions(props.type === 'pending' ? ['owner'] : [])">
                                </vx-selectable>
                            </div>

                            <div class="vx-flex vx-gap-2">
                                <vx-button type="button"
                                    color="muted-light"
                                    @click="cancel">Cancel</vx-button>
                                <vx-button data-test="filters-apply"
                                    type="submit"
                                    block
                                >
                                    Apply
                                    <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                                </vx-button>
                            </div>
                        </form>
                    </template>
                </vx-filterable>
            </div>
            <div class="@3xl/controls:vx-w-1/2 @4xl/controls:vx-w-56">
                <vx-button-group size="lg"
                    class="vx-w-full">
                    <vx-select :modelValue="model.sort_field"
                        @update:modelValue="
                            updateModelValue('sort_field', $event);
                        emit('apply');
                        "
                        :options="[
                            {
                                value: 'id',
                                label: 'Sort: Created',
                            },
                        ]"></vx-select>
                    <vx-button @click.prevent="
                        model.sort_dir === 'asc'
                            ? updateModelValue('sort_dir', 'desc')
                            : updateModelValue('sort_dir', 'asc');
                    emit('apply');
                    "
                        type="button"
                        color="white"
                        shadow>
                        <font-awesome-icon :icon="model.sort_dir === 'asc'
                            ? faArrowUpWideShort
                            : faArrowDownWideShort
                            "></font-awesome-icon>
                    </vx-button>
                </vx-button-group>
            </div>
        </template>

        <template v-if="isAdminOrOwner"
            v-slot:right>
            <vx-button size="lg"
                class="vx-w-full"
                @click="emit('add')"
                color="primary">
                Add User
                <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
            </vx-button>
        </template>
    </page-controls>

</template>

<script setup>
import PageControls from '~/components/general/PageControls.vue';
import { faArrowDownWideShort, faArrowUpWideShort, faCircleCheck, faCirclePlus, faMagnifyingGlass, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBadge, VxButton, VxButtonGroup, VxFilterable, VxInput, VxLabel, VxSelect, VxSelectable } from '@voxie/frontend-components';
import { computed, nextTick, ref, watch } from 'vue';
import { teamRolesOptions } from '~/constants/roles';

const props = defineProps({
    type: {
        type: String,
        default: 'active',
    }
})

const isAdminOrOwner = Spark.isAdminOrOwner;

const filters = ref({})
const model = defineModel({});

watch(() => model.value, (value) => {
    filters.value = { ...value };
}, {
    deep: true,
    immediate: true,
});

const emit = defineEmits(['update:modelValue', 'apply', 'reset', 'add']);

const active = ref(false);

const toggleFilterable = (event) => {
    active.value = event;
}

const filtersCount = computed(
    () =>
        (model.value.query ? 1 : 0) +
        (model.value.role ? 1 : 0)
);

const reset = () => {
    active.value = false;
    emit('reset');
}

const cancel = async () => {
    filters.value = { ...model.value }

    active.value = false;
}

const apply = async () => {
    active.value = false;
    emit('update:modelValue', { ...filters.value });
    await nextTick();
    emit('apply');
}

const updateModelValue = (key, value) => {
    emit("update:modelValue", {
        ...model.value,
        [key]: value,
    });
};


</script>
