<template>
    <auth-box class="vx-max-w-[420px]"
        :errors="props.errors">
        <form @submit="submitting = true"
            method="POST"
            action="/login/token"
            class="vx-flex vx-flex-col vx-gap-4">

            <input name="token"
                :value="code.join('')"
                type="hidden">

            <div>
                <div class="vx-text-base vx-font-bold">2 Factor Code</div>
                <div class="vx-text-sm vx-text-slate-500">
                    Six-digit code from your authenticator app.
                </div>

            </div>
            <div>
                <div class="vx-flex vx-gap-2 vx-w-full">
                    <div v-for="(n, index) in code" :key="'code_' + index" :class="['vx-w-[50px] vx-text-center', {
                        'vx-mr-2': index === 2,
                        'vx-ml-2': index === 3,
                    }]">
                        <vx-input
                            ref="inputs"
                            type="tex"
                            size="lg"
                            inputmode="numeric"
                            pattern="\d*"
                            :maxlength="1"
                            v-model="code[index]"
                            @input="onInput($event, index)"
                            @keypress="validateKeyPress"
                            @keydown.delete="onDelete($event, index)"
                            @paste="onPaste"
                            class="vx-text-center"
                        />
                    </div>

                </div>
                <a class="vx-flex vx-mt-2 vx-items-center vx-underline vx-text-slate-600 hover:vx-text-slate-600 vx-text-xs"
                    :href="`/login-via-emergency-token`">
                    Trouble with your code?
                </a>
            </div>



            <div class="vx-flex vx-flex-col vx-gap-2 vx-mt-4 md:vx-flex-row-reverse">
                <vx-button :loading="submitting"
                    class="md:vx-flex-grow"
                    type="submit"
                    color="primary"
                    size="lg">
                    Verify <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>

                <vx-button color="muted"
                    size="lg"
                    href="/login">
                    Cancel
                </vx-button>
            </div>
        </form>
    </auth-box>
</template>
<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AuthBox from '../AuthBox.vue';
import { VxInput, VxButton } from '@voxie/frontend-components';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { nextTick, onMounted, ref } from 'vue';

const props = defineProps({
    errors: Array,
});

const submitting = ref(false);

const inputs = ref([]);

onMounted(async () => {
    await nextTick();
    inputs.value[0].field.focus();
})

const code = ref([null, null, null, null, null, null]);

const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Enter"];

const validateKeyPress = (event) => {
    const keyPressed = event.key;
    if (!keysAllowed.includes(keyPressed)) {
        event.currentTarget.value = "";
        event.preventDefault();
    }
}

const onInput = (event, index) => {
    if (event.inputType === "insertText") {
        inputs.value[index + 1]?.field.focus();
    }
}

const onDelete = async (event, index) => {
    event.preventDefault();
    code.value[index] = "";
    await nextTick();
    inputs.value[index - 1]?.field.focus();
}

const onPaste = async (event) => {
    event.preventDefault();
    const pasted = (event).clipboardData
        ?.getData("text")
        .trim()
        .split("")
        .filter(value => keysAllowed.includes(value))
        .slice(0, 6);

    pasted.forEach((number, index) => {
        code.value[index] = number;
    });

    await nextTick();

    if (pasted.length >= 6) {
        inputs.value[inputs.value.length - 1].field.focus();
    } else {
        inputs.value[pasted.length].field.focus();
    }
}

</script>
