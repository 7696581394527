import Dinero from 'dinero.js';
import dayjs from '~/utils/dayjs';
import { getClientTz } from './date';
import axios from '~/services/axios';

export const mediaUrl = (path) => {
    if (path.substring(0, 4) === 'http') {
        return path;
    }

    if (window.Spark.env === 'local') {
        return `/${path}`;
    }

    var bucket = window.Spark.env === 'production' ? 'voxie' : 'qa-bucket-voxie';

    return `https://${bucket}.s3.us-west-2.amazonaws.com/${path}`;
};

export function currency(value) {
    return Dinero({
        amount: Math.round(value * 100),
        currency: window.Spark.currency,
    })
        .setLocale(window.Spark.currencyLocale)
        .toFormat('$0,0.00');
}

export function dateTime(value) {
    if (!value) {
        return 'never';
    }

    let timeZone = getClientTz();

    return dayjs.utc(value).tz(timeZone).format('MMM Do, YYYY h:mm A z');
}

export function capitalize(value) {
    if (!value && value !== 0) {
        return '';
    }

    return value.toString().charAt(0).toUpperCase() + value.slice(1);
}

export function date(value) {
    return dayjs.utc(value).local().format('MMMM Do, YYYY');
}

export function initials(firstName, lastName) {
    if (!firstName && !lastName) return null;

    return `${(firstName ?? '').charAt(0)} ${(lastName ?? '').charAt(0)}`.replace(/ /g, '');
}

export const isNaturalNumber = (number) => {
    return /^\d+$/.test(number);
};

export const getSlotChildrenText = (children) =>
    children
        .map((node) => {
            if (!node.children || typeof node.children === 'string') return node.children || '';
            else if (Array.isArray(node.children)) return getSlotChildrenText(node.children);
            else if (node.children.default) return getSlotChildrenText(node.children.default());
        })
        .join('');

export const decodeBase64 = (string) => {
    try {
        return new TextDecoder().decode(Uint8Array.from(atob(string), (c) => c.charCodeAt(0)));
    } catch {
        return null;
    }
};

export const isValidJsonString = (string) => {
    try {
        JSON.parse(string);
    } catch {
        return false;
    }
    return true;
};

export const momentoAuthorizer = (channel) =>
    axios
        .post('/broadcasting/auth', {
            channel_name: channel,
        })
        .then((response) => {
            return { token: response.data.auth };
        });

export const isAuthErrorEcho = (error) => error?.type === 'AuthError' || error?.code === 'AUTHORIZATION_ERROR';

export const has = (obj, path) => {
    if (!obj || typeof obj !== 'object') {
        return false;
    }

    const pathArray = Array.isArray(path) ? path : path.split('.');

    let currentObject = obj;
    for (let i = 0; i < pathArray.length; i++) {
        if (!Object.prototype.hasOwnProperty.call(currentObject, pathArray[i])) {
            return false;
        }
        currentObject = currentObject[pathArray[i]];
    }

    return true;
};

export const uniqBy = (array, iteratee = (x) => x) => {
    const set = new Set();

    return array.filter((item) => {
        const value = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];
        if (set.has(value)) {
            return false;
        }
        set.add(value);
        return true;
    });
};

export const unionBy = (...args) => {
    // Check if the last argument is an iteratee (function or string).
    const lastArg = args[args.length - 1];
    const isIteratee = typeof lastArg === 'function' || typeof lastArg === 'string';

    let arrays, iteratee;

    if (isIteratee) {
        iteratee = lastArg;
        arrays = args.slice(0, -1); // All arguments except iteratee.
    } else {
        iteratee = null;
        arrays = args;
    }

    arrays = args.filter((a) => Array.isArray(a)); // Make sure only arrays there.

    const combinedArray = [].concat(...arrays);

    if (!iteratee) {
        return combinedArray;
    }

    const set = new Set();

    return combinedArray.filter((item) => {
        const value = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];

        if (set.has(value)) {
            return false;
        }
        set.add(value);
        return true;
    });
};

/**
 * Deep compare arrays, objects, numbers, strings and booleans.
 *
 * @param {*} value
 * @param {*} other
 * @returns
 */
export const isEqual = (value, other) => {
    if (value === other) {
        return true;
    }

    if (
        [null, undefined].includes(value) ||
        [null, undefined].includes(other) ||
        typeof value !== 'object' ||
        typeof other !== 'object'
    ) {
        return false;
    }

    const valueProps = Object.getOwnPropertyNames(value);
    const otherProps = Object.getOwnPropertyNames(other);

    if (valueProps.length !== otherProps.length) {
        return false;
    }

    for (let i = 0; i < valueProps.length; i++) {
        const propName = valueProps[i];
        if (!Object.prototype.hasOwnProperty.call(other, propName)) {
            return false;
        }
        if (!isEqual(value[propName], other[propName])) {
            return false;
        }
    }

    return true;
};
