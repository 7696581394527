<script setup>
import {
  faMessages,
  faUsers,
  faShareNodes,
  faCirclePlus,
  faRobot,
} from '@fortawesome/pro-solid-svg-icons'
import SvgNode from './SvgNode.vue'
import SvgNodeIconOnly from './SvgNodeIconOnly.vue'
import { vxSvg } from '@voxie/frontend-components';
import ArtNoodle from './ArtNoodle.vue'
import { svgWidthLg, svgWidthSm, classNameLg, classNameSm } from './artVars'

const pathLg = [
  { marker: 'M', values: { x: 80, y: 22 } },
  { marker: 'L', values: { x: svgWidthLg, y: 22 } },
  { marker: 'L', values: { x: svgWidthLg, y: 76 } },
  { marker: 'L', values: { x: 0, y: 76 } },
  { marker: 'L', values: { x: 0, y: 129 } },
  { marker: 'L', values: { x: svgWidthLg - 80, y: 129 } },
  { marker: 'M', values: { x: 80, y: 129 } },
  { marker: 'L', values: { x: svgWidthLg * 0.33, y: 129 } },
  { marker: 'L', values: { x: svgWidthLg * 0.33, y: 198 } },
  { marker: 'L', values: { x: svgWidthLg - 80, y: 198 } },
  { marker: 'M', values: { x: 80, y: 129 } },
  { marker: 'L', values: { x: svgWidthLg * 0.33, y: 129 } },
  { marker: 'L', values: { x: svgWidthLg * 0.33, y: 265 } },
  { marker: 'L', values: { x: 300, y: 265 } },
]
const pathLgRounded = () => {
  return vxSvg.roundCommands(pathLg, 20, 2)?.path
}

const pathSm = [
  { marker: 'M', values: { x: 32, y: 18 } },
  { marker: 'L', values: { x: svgWidthSm, y: 18 } },
  { marker: 'L', values: { x: svgWidthSm, y: 48 } },
  { marker: 'L', values: { x: 0, y: 48 } },
  { marker: 'L', values: { x: 0, y: 76 } },
  { marker: 'L', values: { x: svgWidthSm - 32, y: 76 } },
  { marker: 'M', values: { x: 32, y: 76 } },
  { marker: 'L', values: { x: svgWidthSm * 0.35, y: 76 } },
  { marker: 'L', values: { x: svgWidthSm * 0.35, y: 128 } },
  { marker: 'L', values: { x: svgWidthSm - 32, y: 128 } },
  { marker: 'M', values: { x: 32, y: 76 } },
  { marker: 'L', values: { x: svgWidthSm * 0.35, y: 76 } },
  { marker: 'L', values: { x: svgWidthSm * 0.35, y: 176 } },
  { marker: 'L', values: { x: svgWidthSm * 0.5, y: 176 } },
]
const pathSmRounded = () => {
  return vxSvg.roundCommands(pathSm, 12, 2)?.path
}
</script>

<template>
  <svg
    :viewBox="`0 0 ${svgWidthLg} 288`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    :width="svgWidthLg"
    :class="classNameLg"
  >
    <art-noodle :d="pathLgRounded()" strokeDasharray="0 150" animated />

    <!-- nodes -->

    <svg-node
      :x="34"
      :y="0"
      :icon="faUsers"
      label="Contact List"
      fill="vx-fill-violet-300"
    ></svg-node>
    <svg-node
      :x="249"
      :y="0"
      :icon="faMessages"
      label="Introduction"
      fill="vx-fill-sky-300"
    ></svg-node>
    <svg-node
      :x="463"
      :y="0"
      :icon="faMessages"
      label="Wait 2 Min"
      fill="vx-fill-slate-300"
    ></svg-node>

    <svg-node
      :x="34"
      :y="108"
      :icon="faShareNodes"
      label="Logic Gate"
      fill="vx-fill-orange-400"
    ></svg-node>
    <svg-node
      :x="249"
      :y="108"
      :icon="faMessages"
      label="Ask Name"
      fill="vx-fill-sky-300"
    ></svg-node>
    <svg-node
      :x="463"
      :y="108"
      :icon="faRobot"
      label="Save Name"
      fill="vx-fill-amber-300"
    ></svg-node>

    <svg-node
      :x="249"
      :y="176"
      :icon="faMessages"
      label="Ask Birthday"
      fill="vx-fill-sky-300"
    ></svg-node>
    <svg-node
      :x="463"
      :y="176"
      :icon="faRobot"
      label="Save Birthday"
      fill="vx-fill-amber-300"
    ></svg-node>

    <svg-node
      :x="249"
      :y="244"
      :icon="faMessages"
      fill="vx-fill-sky-300"
    ></svg-node>
  </svg>

  <svg
    :viewBox="`0 0 ${svgWidthSm} 195`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    :width="svgWidthSm"
    :class="classNameSm"
  >
    <art-noodle :d="pathSmRounded()" />
    <!-- nodes -->
    <!-- row -->
    <svg-node-icon-only
      :x="24"
      :y="0"
      :icon="faUsers"
      fill="vx-fill-violet-300"
    ></svg-node-icon-only>
    <svg-node-icon-only
      :x="svgWidthSm / 2 - 24"
      :y="0"
      :icon="faMessages"
      fill="vx-fill-sky-300"
    ></svg-node-icon-only>
    <svg-node-icon-only
      :x="svgWidthSm - 48 - 24"
      :y="0"
      :icon="faMessages"
      fill="vx-fill-slate-300"
    ></svg-node-icon-only>

    <!-- row -->
    <svg-node-icon-only
      :x="24"
      :y="60"
      :icon="faShareNodes"
      fill="vx-fill-orange-400"
    ></svg-node-icon-only>
    <svg-node-icon-only
      :x="svgWidthSm / 2 - 24"
      :y="60"
      :icon="faMessages"
      fill="vx-fill-sky-300"
    ></svg-node-icon-only>
    <svg-node-icon-only
      :x="svgWidthSm - 48 - 24"
      :y="60"
      :icon="faRobot"
      fill="vx-fill-amber-300"
    ></svg-node-icon-only>

    <!-- row -->
    <svg-node-icon-only
      :x="svgWidthSm / 2 - 24"
      :y="110"
      :icon="faMessages"
      fill="vx-fill-sky-300"
    ></svg-node-icon-only>
    <svg-node-icon-only
      :x="svgWidthSm - 48 - 24"
      :y="110"
      :icon="faRobot"
      fill="vx-fill-amber-300"
    ></svg-node-icon-only>

    <!-- row -->
    <svg-node-icon-only
      :x="svgWidthSm / 2 - 24"
      :y="160"
      :icon="faCirclePlus"
      fill="vx-fill-sky-300"
    ></svg-node-icon-only>
  </svg>
</template>
