<template>
    <div class="card card-default">
        <div class="card-header">Create Team</div>

        <div class="card-body">
            <form role="form" v-if="canCreateMoreTeams">
                <!-- Name -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Team Name</label>

                    <div class="col-md-6">
                        <input type="text" id="create-team-name" class="form-control" name="name" v-model="form.name" :class="{'is-invalid': form.errors.has('name')}">

                        <span class="invalid-feedback" v-if="hasTeamLimit">
                            You currently have {{ remainingTeams }} teams remaining.
                        </span>

                        <span class="invalid-feedback" v-show="form.errors.has('name')">
                            {{ form.errors.get('name') }}
                        </span>
                    </div>
                </div>

                <!-- Create Button -->
                <div class="form-group row mb-0">
                    <div class="offset-md-4 col-md-6">
                        <button type="submit" class="btn btn-primary"
                                @click.prevent="create"
                                :disabled="form.busy">

                            Create
                        </button>
                    </div>
                </div>
            </form>

            <div v-else>
                <span class="text-danger">
                    Your current plan doesn't allow you to create more teams
                    <a href="/settings#/subscription">please upgrade your subscription</a>.
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '~/services/axios';

export default {
    name: 'CreateTeam',
    data() {
        return {
            plans: [],
            form: new SparkForm({
                name: '',
            })
        };
    },
    computed: {
        activeSubscription() {
            return this.$parent?.team?.subscriptions?.find(
                (subscription) => subscription.name === 'default'
            );
        },
        activePlan() {
            return this.plans?.find((plan) => {
                return plan.id === this.activeSubscription?.provider_plan;
            });
        },
        hasTeamLimit() {
            return !! this.activePlan?.attributes?.teams;
        },
        remainingTeams() {
            const ownedTeams = this.$parent?.teams?.filter((t) =>
                this.$parent?.team?.id && t.owner_id === this.$parent?.team?.id
            );

            return this.activePlan
                ? this.activePlan.attributes.teams - ownedTeams.length
                : 0;
        },
        canCreateMoreTeams() {
            if (! this.hasTeamLimit) {
                return true;
            }

            return this.remainingTeams > 0;
        },
    },
    created() {
        this.getPlans();
        Bus.$on(`sparkFormUpdated.${this.form.sparkFormId}`, this.$forceUpdate)
    },
    methods: {
        create() {
            Spark.post('/settings/teams', this.form)
                .then(response => {
                    this.form.name = '';

                    if (response.pendingPayment) {
                        window.location = '/' + Spark.cashierPath + '/payment/' + response.pendingPayment + '?redirect=/settings%23/subscription';
                    } else {
                        Bus.$emit('updateUser');
                        Bus.$emit('updateTeams');
                    }
                });
        },
        getPlans() {
            axios.get('/spark/plans')
                .then(response => {
                    this.plans = response.data;
                });
        }
    }
}
</script>
