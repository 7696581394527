<template>
    <component :is="open ? VxBox : 'div'" :class="{
        'vx-rounded-[16px] vx-border vx-border-solid vx-border-slate-300 vx-bg-slate-200': !open
    }" color="info"
        class="vx-py-4 lg:vx-py-6">
        <div
            class="vx-flex vx-items-center vx-justify-between vx-text-slate-800 vx-px-4 lg:vx-pl-9 lg:vx-pr-6">
            <div class="vx-flex vx-items-center vx-gap-4">
                <font-awesome-icon :icon="faBarsFilter"
                    class="vx-text-base vx-ml-2" />

                <span class="vx-font-extrabold lg:vx-text-xl">Filters</span>
                <vx-badge color="primary"
                    size="xs"
                    data-test="filters-count">
                    {{ matchesCount }}
                </vx-badge>
            </div>

            <div>
                <vx-button @click="open = !open" class="vx-w-12 vx-px-4 vx-ml-4" color="muted-dark" size="md" type="button">
                    <font-awesome-icon :icon="open ? faCaretUp : faCaretDown"></font-awesome-icon>
                </vx-button>
            </div>
        </div>

        <div v-show="open" class="vx-mt-4">
            <automation-rule-form-match-group v-for="(matchGroup, matchGroupIndex) in data.match_groups"
                :key="matchGroup.id"
                :matchGroupIndex="matchGroupIndex"
                :isLast="(matchGroupIndex + 1) == data.match_groups.length">
            </automation-rule-form-match-group>
            <div v-if="props.error" class="vx-relative vx-px-7 vx-pt-4 vx-text-xs vx-text-rose-800">
                {{ props.error }}
            </div>
        </div>
    </component>
</template>


<script setup>
import { computed, ref } from 'vue';
import { faBarsFilter, faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBox, VxButton, VxBadge } from '@voxie/frontend-components';
import { useAutomationRule } from './useAutomationRule';
import AutomationRuleFormMatchGroup from './AutomationRuleFormMatchGroup.vue';

const props = defineProps({
    error: String,
});

const { data } = useAutomationRule();
const open = ref(true);

const matchesCount = computed(() => {
    return (data.value.match_groups || []).reduce((acc, matchGroup) => {
        return acc + matchGroup.matches.length
    }, 0)
})

</script>
