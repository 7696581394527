<template>
    <div>
        <page-header>
            Contact Export Settings
        </page-header>
        <div class="vx-max-w-screen-sm vx-w-full vx-flex vx-flex-col vx-items-stretch vx-gap-8">
            <div class="vx-flex vx-flex-col vx-gap-6">
                <vx-alert v-if="disabled" :closeable="false">
                    You don't have permission to edit these settings.
                </vx-alert>
                <input-list placeholder="Enter custom attribute key" :maxValues="maxKeys" :disabled="disabled"
                    @search="search"
                    :options="options"
                    :loading="loading" :validationRules="validationRules" :modelValue="attributes" @update:modelValue="($event) => {
                        attributes = $event.map(attr => ({...attr, value: attr.value.toLowerCase()}));
                        isDirty = true;
                    }">
                    <template v-slot:header>
                        Custom Attributes
                    </template>
                    <template v-slot:subheader>
                        Custom attributes added here will automatically be included in your contact exports.
                        You can add up to {{ maxKeys }} custom attributes. These can be overridden when exporting.
                    </template>
                    <template v-slot:empty>
                        No custom attributes selected.
                    </template>
                    <template v-slot:max-values>
                        <span v-if="attributes.length < maxKeys" class="vx-px-1 vx-text-teal-600">
                            {{ `${attributes.length}/${maxKeys} attributes used` }}
                        </span>
                        <span v-else class="vx-px-1 vx-text-rose-600">
                            You've reached the {{ maxKeys }} attribute limit.
                        </span>
                    </template>
                    <template v-slot:footer>
                        <vx-button block size="lg" class="vx-mt-5" :color="isDirty ? 'primary' : 'muted'"
                            :disabled="disabled || loading || !isDirty" :loading="savingConfig" @click="saveConfig"
                            data-test="submit-button">
                            Save
                        </vx-button>
                    </template>
                </input-list>
            </div>
        </div>
    </div>
</template>
<script setup>
import PageHeader from '~/components/general/PageHeader.vue';
import { VxAlert, VxButton } from '@voxie/frontend-components';
import { ref, computed } from 'vue';
import contactsClient from '~/services/contactsClient';
import { useToasted } from '~/composables/useToasted';
import { helpers, maxLength, required } from '@vuelidate/validators';
import { csvInjection, customAttributeKey, reservedCustomAttributesKeys } from '~/utils/validation';
import { randomString } from '~/utils/string';
import InputList from '~/components/general/InputList.vue';
import debounce from '~/utils/debounce';
import { customAttributesClient } from '~/services';

const toasted = useToasted();
const options = ref([]);

const isAdminOrOwner = Spark.isAdminOrOwner;
const teamId = Spark.state.currentTeam.id;

const disabled = computed(() => !isAdminOrOwner);
const attributes = ref([]);
const maxKeys = 20;

const isDirty = ref(false);

const loading = ref(false);
const getConfig = async () => {
    if (loading.value) {
        return;
    }

    loading.value = true;

    try {
        const response = await contactsClient.getContactsExportConfig(teamId);

        attributes.value = response.data?.settings?.custom_attribute_keys.map((attr) => ({
            id: randomString(20),
            value: attr,
        })) || [];
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
}

const validationRules = ref({
    required,
    maxLength: maxLength(80),
    csvInjection,
    customAttributeKey,
    reservedCustomAttributesKeys,
    distinct: helpers.withMessage(({
        $model
    }) => `Custom attribute "${$model}" has already been added`, (value) => !attributes.value.find((attr) => attr.value === value.trim()))
});

const savingConfig = ref(false);
const saveConfig = async () => {
    if (savingConfig.value) {
        return;
    }

    savingConfig.value = true;

    try {
        await contactsClient.saveContactsExportConfig(teamId, {
            settings: {
                custom_attribute_keys: attributes.value.map((attr) => attr.value)
            }
        });

        toasted.global.saved_successfully();
        isDirty.value = false;
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    } finally {
        savingConfig.value = false;
    }
}

getConfig();

const search = debounce((query) => {
    customAttributesClient.customAttributesAutocomplete(teamId, {
        query: query,
    }).then(response => {
        options.value = response.data.data;
    })
}, 300)

</script>
