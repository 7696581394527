<template>
    <page-container>
        <page-header>
            Flows

            <template v-slot:actions>
                <vx-button @click="showOverview = !showOverview"
                    data-test="toggle-overview"
                    type="button"
                    color="muted-dark"
                    size="lg">
                    <span class="vx-hidden sm:vx-inline-block">{{ showOverview ? 'Hide' : 'Show' }} Overview</span>
                    <font-awesome-icon class="vx-hidden sm:vx-inline-block"
                        :icon="faQuestionCircle"></font-awesome-icon>
                    <font-awesome-icon class="vx-inline-block sm:vx-hidden"
                        :icon="showOverview ? faCircleChevronUp : faCircleChevronDown"></font-awesome-icon>
                </vx-button>
            </template>
        </page-header>

        <flows-overview v-show="showOverview"
            class="vx-mb-16"></flows-overview>

        <flows-filters class="vx-mb-12"
            v-model="filters"
            @apply="applyFilters"
            @reset="resetFilters"
            :list="list"
            :loading="loading"
            @new="newFlow = true"></flows-filters>

        <div v-if="loading"
            class="vx-text-center vx-py-10">
            <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
        </div>
        <template v-else>
            <div class="vx-flex vx-flex-col vx-gap-4">
                <flow-card v-for="item in filteredList"
                    :key="item.flow_id"
                    :modelValue="item"
                    @pause="handleLaunch(item)"
                    @launch="handleLaunch(item)"
                    @deleted="remove(item.flow_id)"></flow-card>
            </div>
            <vx-box v-if="!list.length"
                color="info"
                class="vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                No flows yet.
                <new-flow-button @click.prevent="newFlow = true"
                    :disabled="!!(loading || list.length >= FLOWS_LIMIT)">
                </new-flow-button>
            </vx-box>
            <vx-box v-else-if="!filteredList.length"
                color="info"
                class="vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                <div class="vx-w-64 vx-mx-auto vx-text-center">
                    No results for current search, try adjusting your filters.
                </div>
                <vx-button type="button" color="secondary" @click="resetFilters">
                    Reset All <font-awesome-icon :icon="faCircleX" />
                </vx-button>
            </vx-box>
        </template>

        <flows-new-modal v-model:visible="newFlow"
            :list="list"></flows-new-modal>

        <playbook-launch :visible="!!(statusModal.flow_id && statusModal.status === 'draft')"
            @update:visible="() => statusModal = {}"
            :flowId="statusModal.flow_id"
            :flowStatus="statusModal.status"
            :flowCreatedAt="statusModal.created_at"
            @launched="setStatus('active', statusModal.flow_id); statusModal = {}">
        </playbook-launch>
        <playbook-pause :visible="!!(statusModal.flow_id && statusModal.status === 'active')"
            @update:visible="() => statusModal = {}"
            :flowName="statusModal.name"
            :flowId="statusModal.flow_id"
            @paused="setStatus('paused', statusModal.flow_id); statusModal = {}">
        </playbook-pause>
    </page-container>
</template>

<script setup>
import { faCircleChevronDown, faCircleChevronUp, faCircleX, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBox, VxButton, VxIconLoading } from '@voxie/frontend-components';
import { onMounted, ref } from 'vue';
import PageContainer from '~/components/general/PageContainer.vue';
import PageHeader from '~/components/general/PageHeader.vue';
import { useToasted } from '~/composables/useToasted';
import flowsClient from '~/services/flowsClient';
import FlowCard from './FlowCard.vue';
import FlowsFilters from './FlowsFilters.vue';
import FlowsNewModal from './FlowsNewModal.vue';
import FlowsOverview from './FlowsOverview.vue';
import NewFlowButton from './components/NewFlowButton.vue';
import PlaybookLaunch from './playbook/modals/PlaybookLaunch.vue';
import PlaybookPause from './playbook/modals/PlaybookPause.vue';

import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';
import { FLOWS_LIMIT } from './playbook/playbook';

const teamId = Spark.state.currentTeam.id;
const toasted = useToasted();

const newFlow = ref(false);

const showOverview = ref(true);

const defaultFilters = () => ({
    query: "",
    sort_field: 'created_at',
    sort_dir: 'desc',
    type: [],
    status: undefined,
    playbook: undefined,
    category: '',
});

const filters = ref(defaultFilters());

const loading = ref(true);
const list = ref([]);
const filteredList = ref([]);

const fetchRecords = async () => {
    try {
        loading.value = true;
        const response = await flowsClient.list(teamId);
        list.value = response.data.data;
        applyFilters();
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
}

const applyFilters = () => {
    filteredList.value = list.value.filter((item) => {
        const name = item.name.toLocaleLowerCase().includes(filters.value.query.toLocaleLowerCase());
        const type = !filters.value.type?.length || filters.value.type.includes('playbook') && Boolean(item.playbook) || filters.value.type.includes('custom_flow') && !item.playbook;
        const status = !filters.value.status || filters.value.status === item.status;
        const playbook = !filters.value.playbook || filters.value.playbook === item.playbook;
        const category = !filters.value.category?.length || filters.value.category === item.category;

        return name && type && status && playbook && category;
    }).sort((a, b) => {
        if (filters.value.sort_field === 'name') {
            return filters.value.sort_dir === 'desc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        } else if (['created_at', 'updated_at'].includes(filters.value.sort_field)) {
            return filters.value.sort_dir === 'desc' ? new Date(b[filters.value.sort_field]) - new Date(a[filters.value.sort_field]) : new Date(a[filters.value.sort_field]) - new Date(b[filters.value.sort_field]);
        } else {
            return 0;
        }
    })
}

const resetFilters = () => {
    filters.value = defaultFilters();
    applyFilters();
}

const remove = async (flowId) => {
    list.value = list.value.filter(item => item.flow_id !== flowId);
    filteredList.value = filteredList.value.filter(item => item.flow_id !== flowId);
}

const statusModal = ref({});

const setStatus = (status, flowId) => {
    list.value = list.value.map((flow) => {
        if (flow.flow_id === flowId) {
            flow.status = status;
        }

        return flow;
    });

    statusModal.value = {}
}

const launchingId = ref(undefined);
const unpause = async (flow) => {
    if (launchingId.value) {
        return;
    }

    launchingId.value = flow.flow_id;

    try {
        await flowsClient.unpause(teamId, flow.flow_id);
        setStatus('active', flow.flow_id);
    } catch (e) {
        console.error(e);
        if (e?.response?.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
            return;
        }

        const errorMessage = e.response?.data?.message;
        toasted.error(errorMessage || 'Something wrong.');
    } finally {
        launchingId.value = undefined;
    }
}

const handleLaunch = (flow) => {
    if (flow.status === 'draft' || flow.status === 'active') {
        statusModal.value = flow;
    } else if (flow.status === 'paused') {
        unpause(flow);
    }
}

onMounted(() => {
    fetchRecords();
})


</script>
