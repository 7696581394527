<template>
    <div>
        <div class="vx-relative vx-px-4 lg:vx-px-6">
            <div class="vx-absolute vx-left-8 vx-top-0 vx-w-px vx-h-full vx-bg-slate-300 lg:vx-left-16"></div>

            <template v-for="(match, matchIndex) in data.match_groups[props.matchGroupIndex].matches" :key="match.id">
                <automation-rule-form-match-item
                    :matchGroupIndex="matchGroupIndex"
                    :matchIndex="matchIndex"
                    v-model="data.match_groups[props.matchGroupIndex].matches[matchIndex]"
                    />
            </template>

            <div class="vx-flex vx-items-center vx-gap-4">
                <vx-box class="vx-relative vx-p-3 vx-inline-flex vx-space-x-2">
                    <vx-button @click="addMatch(props.matchGroupIndex)"
                        type="button"
                        size="lg"
                        color="primary"
                        data-test="add-match-item">
                        {{ props.matchGroupIndex === 0 && !data.match_groups[props.matchGroupIndex].matches?.length ? 'Add filter condition' : 'And' }}
                        <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                    </vx-button>
                    <vx-button v-if="props.isLast && !(props.matchGroupIndex === 0 && !data.match_groups[props.matchGroupIndex].matches?.length)"
                        @click="addMatchGroup"
                        type="button"
                        size="lg"
                        color="info"
                        data-test="add-match-group">
                        Or <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                    </vx-button>
                </vx-box>
                <div v-if="displayFirstError(validate.matches)" class="vx-text-xs vx-text-rose-800">
                    {{ displayFirstError(validate.matches) }}
                </div>
            </div>
        </div>

        <div v-if="!props.isLast"
            class="vx-relative vx-flex vx-p-8">
            <div
                class="vx-absolute vx-left-0 vx-top-1/2 -vx-translate-y-1/2 vx-w-full vx-h-px vx-border-solid vx-border-y vx-border-x-0 vx-border-t-slate-300 vx-border-b-white">
            </div>
            <div class="vx-relative vx-inline-block vx-border vx-border-solid vx-border-slate-300 vx-rounded-full vx-overflow-hidden"
                style="filter: drop-shadow(0px 1px 0px #FFFFFF);">
                <div
                    class="vx-bg-amber-50 vx-rounded-full vx-overflow-hidden vx-border-t vx-border-solid vx-border-white vx-border-b-0 vx-border-x-0 vx-text-base vx-font-extrabold vx-py-2 vx-px-6">
                    Or
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { VxButton, VxBox } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { useAutomationRule } from './useAutomationRule';
import AutomationRuleFormMatchItem from './AutomationRuleFormMatchItem.vue';
import useVuelidate from '@vuelidate/core';
import { computed } from 'vue';
import { helpers, maxLength } from '@vuelidate/validators';
import { displayFirstError } from '~/utils/validation';

const props = defineProps({
    matchGroupIndex: {
        type: Number,
        required: true,
    },
    isLast: {
        type: Boolean,
        required: false,
    },
});

const { addMatchGroup, addMatch, data } = useAutomationRule();

const validate = useVuelidate({
    matches: {
        maxLength: helpers.withMessage('An automation filter group can only contain 25 filters', maxLength(25)),
    }
}, computed(() => data.value.match_groups[props.matchGroupIndex]))
</script>
