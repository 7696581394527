<template>
    <div class="time-range-picker vx-grid vx-grid-cols-6 vx-gap-2 lg:vx-gap-4 vx-mb-6 lg:vx-mb-4 lg:vx-flex lg:vx-items-center lg:vx-flex-row">
        <div class="vx-grid vx-grid-cols-12 vx-col-span-6 vx-items-center vx-flex-col vx-gap-2 vx-grow lg:vx-flex lg:vx-flex-row">
            <div class="vx-col-span-12 lg:vx-w-48">
                <vx-selectable v-model="model.day"
                    :clearable="false"
                    placeholder="Select Week Day"
                    :reduce="(option) => option.value"
                    :options="[
                        { label: 'Sunday', value: 0 },
                        { label: 'Monday', value: 1 },
                        { label: 'Tuesday', value: 2 },
                        { label: 'Wednesday', value: 3 },
                        { label: 'Thursday', value: 4 },
                        { label: 'Friday', value: 5 },
                        { label: 'Saturday', value: 6 }
                    ]"></vx-selectable>
            </div>

            <div class="vx-col-span-3 vx-text-xs vx-font-extrabold vx-text-slate-500 vx-text-left vx-w-full lg:vx-w-auto">From</div>
            <div class="vx-col-span-9">
                <vx-date-picker size="md" v-model="from" mode="time"></vx-date-picker>
            </div>

            <div class="vx-col-span-3 vx-text-xs vx-font-extrabold vx-text-slate-500 vx-text-left vx-w-full lg:vx-w-auto">To</div>
            <div class="vx-col-span-9">
                <vx-date-picker size="md" v-model="to" mode="time"></vx-date-picker>
            </div>
        </div>

        <vx-button @click.prevent="emit('add')"
            type="button"
            color="muted-light"
            class="vx-col-span-4 lg:vx-w-12">
            <font-awesome-icon :icon="faPlus"></font-awesome-icon>
        </vx-button>
        <vx-button @click.prevent="emit('remove')"
            type="button"
            color="muted-light"
            class="vx-col-span-2 lg:vx-w-12">
            <font-awesome-icon :icon="faTrash"></font-awesome-icon>
        </vx-button>
    </div>
</template>


<script setup>
import { VxButton, VxSelectable, VxDatePicker } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { computed } from 'vue';
import dayjs from '~/utils/dayjs';

const model = defineModel();
const emit = defineEmits(['remove', 'add']);

const from = computed({
    set: (date) => {
        model.value.from = dayjs(date).format('HH:mm');
    },
    get: () => {
        return dayjs('2024-05-03 ' + model.value.from).toDate();
    },
});

const to = computed({
    set: (date) => {
        model.value.to = dayjs(date).format('HH:mm');
    },
    get: () => {
        return dayjs('2024-05-03 ' + model.value.to).toDate();
    },
});

</script>

<style scoped lang="postcss">
.time-range-picker :deep(.vx-date-picker.vx-date-picker--md),
.time-range-picker :deep(.vc-time-picker) {
    width: 10.5rem;
}
@media(min-width: 1024px) {
    .time-range-picker :deep(.vx-date-picker.vx-date-picker--md),
    .time-range-picker :deep(.vc-time-picker) {
        width: 12.5rem;
    }
}

</style>
