<template>
    <playbook-form-card :icon="faMessage">
        <template v-slot:header>
            Final Message
        </template>

        <div class="vx-flex vx-flex-col vx-gap-2">
            <vx-label size="lg">
                Message
            </vx-label>
            <message-builder ref="messageBuilderRef"
                :modelValue="props.modelValue || ''"
                @update:modelValue="emit('update:modelValue', $event)"
                :disableMedia="true"
                @blur="validation.modelValue.$touch()"
                preview
                :error="displayFirstError(validation.modelValue.$errors)">
            </message-builder>
        </div>
    </playbook-form-card>
</template>


<script setup>
import useVuelidate from '@vuelidate/core';
import { required, } from '@vuelidate/validators';
import { computed, ref } from 'vue';
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import { displayFirstError } from '~/utils/validation';
import PlaybookFormCard from '../PlaybookFormCard.vue';
import { faMessage } from '@fortawesome/pro-solid-svg-icons';
import { VxLabel } from '@voxie/frontend-components';

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    }
});

const emit = defineEmits(['update:modelValue']);

const messageBuilderRef = ref();

const validation = useVuelidate({
    modelValue: {
        required,
    },
}, computed(() => props));

defineExpose({
    focusMessage: () => messageBuilderRef.value?.textarea?.focus?.()
});

</script>
