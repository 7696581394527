<template>
  <div class="vx-space-y-8 md:vx-space-y-12 vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8">
      <template v-if="!success || segment?.flow_id">
          <step-form-heading v-if="!props.segmentId"
              :icon="faScreenUsers">
              New Segment

              <template v-slot:description>
                  Create automated groups of contacts.
              </template>
          </step-form-heading>

          <page-header v-if="props.segmentId"
              class="vx-mb-6 lg:vx-mb-12">
              <template v-slot:subtitle>
                  <a href="/contacts/segments"
                      class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                      <font-awesome-icon class="vx-mr-1"
                          :icon="faArrowLeft"></font-awesome-icon>
                      All Segments
                  </a>
              </template>

              {{ segment.name }}
          </page-header>

          <div :class="{
              'vx-grid vx-grid-cols-12': props.segmentId,
          }">
              <page-sidebar v-if="props.segmentId" :links="[
                {href: `/contacts/segments/${props.segmentId}`, label: 'Details'},
                {href: `/contacts/segments/${props.segmentId}#filters`, label: 'Filters'},
                {href: `/contacts/segments/${props.segmentId}/contacts`, label: 'Contacts'},
              ]"></page-sidebar>
              <vx-sticky-nav :sections="[
                  { id: 'name', label: 'Name' },
                  { id: 'category', label: 'Category' },
                  { id: 'type', label: 'Type' },
                  { id: 'audience', label: 'Audience' },
                  FEATURES.contact_groups ? { id: 'group', label: 'Group' } : null,
                  { id: 'filters', label: 'Filters' },
              ].filter((s) => !!s)"
                  class="vx-col-span-12"
                  :class="{
                      'lg:vx-col-span-10': !!props.segmentId,
                  }"
                  :navClasses="`${success || props.segmentId ? 'vx-hidden' : ''}`">
                    <div v-if="segment?.flow_id"
                        class="vx-max-w-screen-sm vx-w-full"
                        :class="{
                            'vx-mx-auto': !props.segmentId,
                        }">
                        <flow-resource-state-alert
                            :resourceName="segment?.name"
                            :success="success"
                            :editing="!!segment?.id"
                            :flowId="segment?.flow_id"
                            :flowSection="segment?.flow_section"
                        >
                        </flow-resource-state-alert>

                    </div>

                  <div v-if="!success || segment?.id"
                    class="vx-flex vx-flex-col vx-w-full vx-justify-center vx-gap-2 md:vx-gap-8"
                    :class="{
                        'vx-mx-auto': !props.segmentId,
                    }">
                      <div id="name"
                          class="vx-max-w-screen-sm vx-w-full"
                          :class="{
                              'vx-mx-auto': !props.segmentId,
                          }">
                          <segment-step-form-name v-model="segment.name"></segment-step-form-name>
                      </div>

                      <resource-category
                        id="category"
                        class="vx-max-w-screen-sm vx-w-full"
                        :class="{
                            'vx-mx-auto': !props.segmentId,
                        }"
                        v-model="segment.category"
                        :disabled="!!segment.flow_id">
                      </resource-category>

                      <div id="type"
                          class="vx-max-w-screen-sm vx-w-full"
                          :class="{
                              'vx-mx-auto': !props.segmentId,
                          }">
                          <segment-step-form-type v-model="segment.type" :disabled="!!props.segmentId"></segment-step-form-type>
                      </div>
                      <div id="audience"
                          class="vx-max-w-screen-sm vx-w-full"
                          :class="{
                              'vx-mx-auto': !props.segmentId,
                          }">
                          <segment-step-form-audience v-model="segment.audience_type" :disabled="!!props.segmentId"></segment-step-form-audience>
                      </div>
                      <div v-if="FEATURES.contact_groups" id="group"
                          class="vx-max-w-screen-sm vx-w-full"
                          :class="{
                              'vx-mx-auto': !props.segmentId,
                          }">
                          <segment-step-form-group v-model="segment.group_id" :disabled="!!props.segmentId"></segment-step-form-group>
                      </div>
                      <div id="filters"
                          :class="{
                              'vx-mx-auto vx-max-w-5xl vx-w-full': !props.segmentId,
                              'vx-w-full xl:vx-w-[62rem]': props.segmentId
                          }">
                          <segment-step-form-filters v-if="!props.segmentId || (props.segmentId && segment.id)" v-model="segment.filter_groups"></segment-step-form-filters>
                      </div>

                      <vx-sticky-footer class="vx-max-w-screen-sm vx-w-full"
                          :class="{
                              'vx-mx-auto': !props.segmentId,
                          }">
                          <vx-button href="/contacts/segments"
                              class="vx-w-20"
                              size="lg"
                              color="muted">
                              Cancel
                          </vx-button>
                          <vx-button @click="submit()"
                              class="vx-grow"
                              size="lg"
                              color="primary"
                              :loading="submiting">
                              {{
                                  props.segmentId
                                  ? "Update Segment"
                                  : "Create Segment"
                              }}
                          </vx-button>
                      </vx-sticky-footer>
                  </div>
              </vx-sticky-nav>
          </div>
      </template>

      <template v-else>
          <success-box>
              Your segment has been {{props.segmentId ? 'updated' : 'created'}}!

              <template v-slot:action>
                  <vx-button size="lg" href="/contacts/segments" color="secondary">
                      View All Segments
                      <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                  </vx-button>
              </template>
          </success-box>
      </template>

      <vx-modal :visible="errors.length > 0"
          @update:visible="errors = []"
          class="vx-max-w-lg"
        >
          <div class="vx-text-center vx-text-5xl vx-text-rose-500 vx-pb-6">
              <font-awesome-icon :icon="faCircleExclamation"></font-awesome-icon>
          </div>
          <div class="vx-max-w-md">
              <div v-for="error in errors"
                  :key="error"
                  v-html="error"></div>
          </div>
      </vx-modal>
  </div>
</template>

<script setup>
import {
  VxStickyNav,
  VxStickyFooter,
  VxButton,
  VxModal,
} from "@voxie/frontend-components";
import StepFormHeading from '../../../general/StepFormHeading.vue';
import SuccessBox from '../../../general/SuccessBox.vue';
import SegmentStepFormName from './SegmentStepFormName.vue';
import SegmentStepFormType from './SegmentStepFormType.vue';
import SegmentStepFormAudience from './SegmentStepFormAudience.vue';
import SegmentStepFormGroup from './SegmentStepFormGroup.vue';
import SegmentStepFormFilters from './segment-step-form-filters/SegmentStepFormFilters.vue';
import PageHeader from '../../../general/PageHeader.vue';
import PageSidebar from '../../../general/PageSidebar.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowLeft, faArrowRight, faCircleExclamation, faScreenUsers } from '@fortawesome/pro-solid-svg-icons';
import { ref, inject, onMounted } from 'vue';
import segmentsClient from '../../../../services/segmentsClient';
import { SegmentType } from '../../../../constants/segment';
import { AUDIENCE_TYPE_MARKETING, AUDIENCE_TYPE_TRANSACTIONAL } from '../../../../constants/campaign';
import { validateFilters } from './segment-step-form-filters/segmentFiltersHelpers';
import { HTTP_UNPROCESSABLE_ENTITY } from '../../../../support/HttpStatuses';
import { useToasted } from '../../../../composables/useToasted';
import { useUrlSearchParams } from '@vueuse/core';
import FlowResourceStateAlert from '~/components/automation/flows/components/FlowResourceStateAlert.vue';
import flowsClient from "~/services/flowsClient";
import ResourceCategory from '~/components/general/categories/ResourceCategory.vue';

const FEATURES = inject('FEATURES')

const toasted = useToasted();

const props = defineProps({
  segmentId: {
      type: Number,
      required: false,
  },
});

const teamId = Spark.state.currentTeam.id;

const segment = ref({
  name: '',
  type: SegmentType.AUTO_REFRESH,
  audience_type: FEATURES.transactional_default ? AUDIENCE_TYPE_TRANSACTIONAL : AUDIENCE_TYPE_MARKETING,
  group_id: null,
  filter_groups: [],
  category: '',
});

const errors = ref([]);
const submiting = ref(false);

const success = ref(false);

const submit = async () => {
  try {
      submiting.value = true;

      errors.value = validateFilters(segment.value, segment.value.filter_groups);

      if (segment.value.name) {
          const searchDuplicated = await segmentsClient.searchDuplicates(teamId, {
              query: segment.value.name,
              segment_id: props.segmentId
          })

          if (searchDuplicated.data.data !== null) {
              errors.value.unshift('The Segment Name you are entering already exists.')
          }
      }

      if (errors.value.length) {
          return;
      }

      await (props.segmentId ? segmentsClient.updateSegment(teamId, props.segmentId, segment.value) : segmentsClient.createSegment(teamId, segment.value));

      success.value = true;
      window.scrollTo({ top: 0, behavior: 'smooth' });
  } catch (error) {
      if (error.response.status === HTTP_UNPROCESSABLE_ENTITY) {
          const responseErrors = error.response.data.errors;

          errors.value = Object.keys(responseErrors).reduce((errors, key) => {
              return [...errors, ...responseErrors[key]]
          }, []);

      } else {
          console.error(error);
          toasted.global.platform_error();
      }
  } finally {
      submiting.value = false;
  }
};

const queryParams = useUrlSearchParams();


const duplicate = async () => {
    const response = await segmentsClient.getSegment(teamId, queryParams.duplicateFromId);

    let flowSection;
    if (response.data.flow_id) {
        try {
            const { data: { config: { sections } } } = await flowsClient.getConfig(teamId, response.data.flow_id);
            flowSection = sections.findIndex(
                (section) => section.resources.find(
                    resource => resource.type === 'segment' && Number(resource.id) === Number(queryParams.duplicateFromId)
                )
            )
        } catch (e) {
            console.error(e)
        }
    }

    segment.value = {
        ...response.data,
        id: undefined,
        name: '',
        flow_section: flowSection === -1 ? 0 : flowSection,
    }
}

onMounted(async () => {
    try {
        if (props.segmentId) {
            segment.value = (await segmentsClient.getSegment(teamId, props.segmentId)).data
        }

        if (queryParams.duplicateFromId) {
            await duplicate();
        }

        if (!segment.value.flow_id && queryParams.flow_id) {
            segment.value.flow_id = queryParams.flow_id;
            segment.value.flow_section = queryParams.flow_section || 0;
        }
    } catch (e) {
        console.error(e);
    }
});
</script>
