<template>
    <div class="vx-grid vx-gap-6 vx-grid-cols-1 lg:vx-grid-cols-12">
        <analytics-marketing-campaigns-total-contacts-messaged class="lg:vx-col-span-4" :campaignId="props.campaignId" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-total-contacts-messaged>

        <analytics-marketing-campaigns-messages-sent class="lg:vx-col-span-4" :campaignId="props.campaignId" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-messages-sent>

        <analytics-marketing-campaigns-delivery-rate class="lg:vx-col-span-4" :campaignId="props.campaignId" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-delivery-rate>

        <analytics-marketing-campaigns-opt-out-rate class="lg:vx-col-span-4" :campaignId="props.campaignId" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-opt-out-rate>

        <analytics-marketing-campaigns-message-reply-rate class="lg:vx-col-span-4" :campaignId="props.campaignId" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:title>
                Message Reply Rate
            </template>
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-message-reply-rate>

        <analytics-marketing-campaigns-click-through-rate class="lg:vx-col-span-4" :campaignId="props.campaignId" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-click-through-rate>
    </div>
</template>
<script setup>
import AnalyticsMarketingCampaignsClickThroughRate from './charts/time-series/AnalyticsMarketingCampaignsClickThroughRate.vue';
import AnalyticsMarketingCampaignsMessageReplyRate from './charts/time-series/AnalyticsMarketingCampaignsMessageReplyRate.vue';
import AnalyticsMarketingCampaignsTotalContactsMessaged from './charts/time-series/AnalyticsMarketingCampaignsTotalContactsMessaged.vue';
import AnalyticsMarketingCampaignsMessagesSent from './charts/time-series/AnalyticsMarketingCampaignsMessagesSent.vue';
import AnalyticsMarketingCampaignsOptOutRate from './charts/time-series/AnalyticsMarketingCampaignsOptOutRate.vue';
import AnalyticsMarketingCampaignsDeliveryRate from './charts/time-series/AnalyticsMarketingCampaignsDeliveryRate.vue';

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
    rangeLabel: {
        type: String,
        required: true,
    },
    campaignId: {
        type: Number,
        required: true,
    }
})
</script>
