<template>
    <contact-details :contactId="props.contactId">
        <div
            class="vx-col-span-12 lg:vx-col-span-10 vx-flex vx-flex-col vx-w-full vx-max-w-screen-sm vx-justify-center vx-gap-2 md:vx-gap-8">

            <vx-expandable-card>
                <template v-slot:content>
                    <div :class="headingClasses">
                        <span>Details</span>
                        <vx-button :href="`/contacts/${props.contactId}`" outline color="muted">Edit <font-awesome-icon :icon="faPen"></font-awesome-icon></vx-button>
                    </div>
                    <div :class="gridClasses">
                        <div :class="gridKeyClasses">
                            Phone:
                        </div>
                        <div :class="gridValueClasses">
                            {{ phoneNumberNational(contact.phone) || '-' }}
                        </div>
                        <div :class="gridKeyClasses">
                            First Name:
                        </div>
                        <div :class="gridValueClasses">
                            {{ contact.first_name || '-' }}
                        </div>
                        <div :class="gridKeyClasses">
                            Last Name:
                        </div>
                        <div :class="gridValueClasses">
                            {{ contact.last_name || '-' }}
                        </div>
                        <div :class="gridKeyClasses">
                            Email:
                        </div>
                        <div :class="gridValueClasses">
                            {{ contact.email || '-' }}
                        </div>
                        <div :class="gridKeyClasses">
                            Time-zone:
                        </div>
                        <div :class="gridValueClasses">
                            {{ contact.time_zone || '-' }}
                        </div>
                        <div :class="gridKeyClasses">
                            Added:
                        </div>
                        <div :class="gridValueClasses">
                            {{ contact.created_at ? shortDateTime(contact.created_at) : '-' }}
                        </div>
                        <div :class="gridKeyClasses">
                            Entry Point:
                        </div>
                        <div :class="gridValueClasses">
                            {{ entryPoint(contact.entry_point) }}
                        </div>
                        <div :class="gridKeyClasses">
                            Sticky Phone Number:
                        </div>
                        <div :class="gridValueClasses">
                            <vx-icon-loading v-if="loadingStickyPhoneNumber"
                                spin></vx-icon-loading>
                            <div v-else>
                                {{ stickyPhoneNumber ? phoneNumberNational(stickyPhoneNumber.phone_number) : '-' }}
                            </div>
                        </div>
                    </div>
                    <div :class="headingClasses">Subscriptions</div>
                    <div :class="gridClasses">
                        <div :class="gridKeyClasses">
                            Marketing:
                        </div>
                        <div :class="gridValueClasses">
                            <vx-icon-loading v-if="loadingSubscriptions"
                                spin></vx-icon-loading>
                            <contact-subscription-badge v-else
                                type="marketing"
                                :status="marketingStatus"></contact-subscription-badge>
                        </div>
                        <div :class="gridKeyClasses">
                            Transactional:
                        </div>
                        <div :class="gridValueClasses">
                            <vx-icon-loading v-if="loadingSubscriptions"
                                spin></vx-icon-loading>
                            <contact-subscription-badge v-else
                                type="transactional"
                                :status="transactionalStatus"></contact-subscription-badge>
                        </div>
                    </div>
                    <div :class="headingClasses">Tags</div>
                    <div class="vx-mb-6">
                        <vx-icon-loading v-if="loadingTags" spin></vx-icon-loading>
                        <vx-alert :closeable="false" v-else-if="!tags.length">No tags were added to this contact.</vx-alert>
                        <vx-disclosure v-else appearance="boxed" v-model:active="tagsDisclosure">
                            <template v-slot:header>
                                <font-awesome-icon class="vx-text-slate-300"
                                    :icon="faTag"
                                    fixed-width></font-awesome-icon>
                                <span>{{ tags.length }}</span>
                            </template>
                            <template v-slot:content>
                                <div class="vx-flex vx-flex-wrap vx-gap-2">
                                    <div v-for="tag in tags"
                                        :key="tag"
                                        class="vx-py-0.5 vx-px-2 vx-bg-slate-100 vx-break-all vx-rounded-[4px] vx-flex vx-items-center vx-text-slate-500 vx-text-xs">
                                        {{ tag }}
                                    </div>
                                </div>
                            </template>
                        </vx-disclosure>
                    </div>
                    <template v-if="FEATURES.contact_groups">
                        <div :class="headingClasses">Groups</div>
                        <div class="vx-mb-6">
                            <vx-icon-loading v-if="loadingGroups" spin></vx-icon-loading>
                            <vx-alert :closeable="false" v-else-if="!groups.length">No groups were specified for this contact.</vx-alert>
                            <vx-disclosure v-else appearance="boxed" v-model:active="groupsDisclosure">
                                <template v-slot:header>
                                    <font-awesome-icon class="vx-text-slate-300"
                                        :icon="faGrid2Plus"
                                        fixed-width></font-awesome-icon>
                                    <span>{{ groups.length }}</span>
                                </template>
                                <template v-slot:content>
                                    <div class="vx-flex vx-flex-wrap vx-gap-2">
                                        <div v-for="group in groups"
                                            :key="group.group_id"
                                            class="vx-py-0.5 vx-px-2 vx-bg-slate-100 vx-break-all vx-rounded-[4px] vx-flex vx-items-center vx-text-slate-500 vx-text-xs">
                                            {{ group.group_id }}
                                        </div>
                                    </div>
                                </template>
                            </vx-disclosure>
                        </div>
                    </template>
                    <div :class="headingClasses">Custom Attributes</div>
                    <vx-icon-loading spin v-if="loadingCustomAttributes"></vx-icon-loading>
                    <vx-alert :closeable="false" v-else-if="!customAttributes.length" class="vx-text-sm">No custom attributes were added to this contact.</vx-alert>
                    <div :class="gridClasses">
                        <template v-for="customAttribute in customAttributes" :key="customAttribute.key">
                            <div :class="gridKeyClasses">
                                {{ customAttribute.key }}:
                            </div>
                            <div :class="gridValueClasses">
                                {{ customAttribute.value }}
                            </div>
                        </template>
                    </div>
                </template>
            </vx-expandable-card>

        </div>
    </contact-details>
</template>

<script setup>
import {
    VxAlert,
    VxDisclosure,
    VxExpandableCard,
    VxIconLoading,
    VxButton
} from "@voxie/frontend-components";
import ContactDetails from './ContactDetails.vue';
import ContactSubscriptionBadge from './ContactSubscriptionBadge.vue';
import { useContactDetails } from './useContactDetails';
import { inject } from 'vue';
import { entryPoint, phoneNumberNational } from "~/components/filters";
import { shortDateTime } from '~/utils/date';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTag, faGrid2Plus, faPen } from '@fortawesome/pro-solid-svg-icons';
import { ref } from "vue";

const FEATURES = inject('FEATURES');

const props = defineProps({
    contactId: {
        type: Number,
        required: false,
    },
});

const tagsDisclosure = ref(false);
const groupsDisclosure = ref(false);

const headingClasses = 'vx-font-semibold vx-text-base vx-mb-2 vx-flex vx-items-center vx-justify-between'
const gridClasses = 'vx-grid vx-grid-cols-12 vx-items-center vx-gap-y-2 vx-gap-x-1 vx-text-slate-800 vx-text-sm vx-mb-6';
const gridKeyClasses = 'vx-col-span-3 vx-break-all vx-text-xs vx-text-slate-500';
const gridValueClasses = 'vx-col-span-9 vx-break-all';

const {
    onMountedContact,
    contact,

    getStickyPhoneNumber,
    loadingStickyPhoneNumber,
    stickyPhoneNumber,

    getGroups,
    loadingGroups,
    groups,

    getSubscriptions,
    marketingStatus,
    transactionalStatus,
    loadingSubscriptions,

    getTags,
    loadingTags,
    tags,

    getCustomAttributes,
    loadingCustomAttributes,
    customAttributes,
} = useContactDetails();

onMountedContact(() => {
    getStickyPhoneNumber()
    getGroups().then(() => {
        if (groups.value.length <= 25) {
            groupsDisclosure.value = true;
        }
    })
    getSubscriptions()
    getTags().then(() => {
        if (tags.value.length <= 25) {
            tagsDisclosure.value = true;
        }
    })
    getCustomAttributes()
});


</script>
