<template>
    <vx-expandable-card>
        <template v-slot:content>
            <div class="vx-flex vx-items-center vx-justify-between">
                <div>
                    <vx-label size="md">Use for Sending?</vx-label>
                    <div class="vx-text-sm vx-text-slate-700">Should this number be used to send messages?</div>
                </div>
                <vx-toggle v-model="useForSending" size="lg"></vx-toggle>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import {
    VxExpandableCard,
    VxToggle,
    VxLabel,
} from '@voxie/frontend-components';

const useForSending = defineModel();
</script>
