import BaseClient from './BaseClient';

class PromotionsClient extends BaseClient {
    getAll(teamId, params) {
        return this.makeCoreApiRequest(`/api/v3/teams/${teamId}/promotions`, 'get', null, params);
    }

    getPromotion(teamId, promotionId) {
        return this.makeCoreApiRequest(`/api/v3/teams/${teamId}/promotions/${promotionId}`);
    }

    savePromotion(teamId, promotionId, form) {
        return promotionId
            ? this.makeCoreApiRequest(`/api/v3/teams/${teamId}/promotions/${promotionId}`, 'put', form)
            : this.makeCoreApiRequest(`/api/v3/teams/${teamId}/promotions`, 'post', form);
    }

    deletePromotion(teamId, promotionId) {
        return this.makeCoreApiRequest(`/api/v3/teams/${teamId}/promotions/${promotionId}`, 'delete');
    }

    searchPromotions(teamId, params) {
        return this.makeCoreApiRequest(`/api/v3/teams/${teamId}/search/promotions`, 'get', null, params);
    }
}

export default new PromotionsClient();
