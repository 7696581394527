<template>
    <div class="vx-flex-col vx-justify-start vx-items-start vx-gap-4 vx-flex">
        <div class="vx-self-stretch vx-flex-col vx-justify-start vx-items-start vx-flex">
            <div class="vx-self-stretch vx-text-slate-800 vx-text-xl vx-font-extrabold">
                Attempts
            </div>
        </div>
        <div v-if="loading && !list.length" class="vx-w-full vx-flex vx-flex-col vx-px-8 vx-py-20 vx-items-center">
            <vx-icon-loading class="vx-w-8 vx-h-8" spin></vx-icon-loading>
        </div>
        <vx-box color="info" v-else-if="!list.length"
            class="vx-w-full vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
            No attempts yet.
        </vx-box>
        <vx-table v-else class="vx-w-full !vx-mt-4 md:vx-grid-cols-5-auto vx-gap-y-0" rowBreakpoint="md">
            <vx-table-row isHeader class="!vx-pb-4">
                <div>
                    Status
                </div>
                <div>
                    Completed
                </div>
                <div>
                    Duration
                </div>
                <div>
                    Attempt ID
                </div>
            </vx-table-row>

            <api-delivery-attempt-row v-for="(item, index) in list" :key="list[index].attempt_id" :item="list[index]"
                :next-item="list[index + 1]">
            </api-delivery-attempt-row>
        </vx-table>
        <div v-if="fetchParams.cursor" class="vx-mt-8">
            <vx-button @click="loadData()" data-test="load-more-btn" color="muted-dark" size="xl" class="vx-grow-0" :loading="loading">
                Load More
            </vx-button>
        </div>

    </div>
</template>
<script setup>
import ApiDeliveryAttemptRow from './ApiDeliveryAttemptRow.vue';
import customDeliveriesClient from '../../../../../../../services/customDeliveriesClient';
import { VxTable, VxTableRow, VxButton, VxIconLoading, VxBox } from '@voxie/frontend-components';
import { watch } from 'vue';
import { useCursorPagination } from '../../../../../../../composables/useCursorPagination';

const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const props = defineProps({
    delivery: {
        type: Object,
        required: true,
    }
});

const teamId = Spark.state.currentTeam.id;

const defaultFilters = () => ({
    limit: 15,
    cursor: null,
    forward: false,
});

const loadData = () => {
    fetchRecords(customDeliveriesClient.attemptsList(
        teamId,
        props.delivery.origin_type,
        props.delivery.origin_id,
        props.delivery.delivery_id,
        {
            ...fetchParams.value
        },
    ));
};

watch(() => props.delivery, () => {
    fetchParams.value = defaultFilters();
    loadData();
}, { immediate: true });
</script>
