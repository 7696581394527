<template>
    <div class="row justify-content-center">
        <div class="col-lg-8">
            <!-- Coupon -->
            <div class="alert alert-success" v-if="coupon">
                The coupon {{ discount }} discount will be applied to your subscription!
            </div>

            <!-- Invalid Coupon -->
            <div class="alert alert-danger" v-if="invalidCoupon">
                Whoops! This coupon code is invalid.
            </div>

            <!-- Invitation -->
            <div class="alert alert-success" v-if="invitation">
                {{ teams.we_found_invitation_to_team }} {{ invitation.team.name }}
            </div>

            <!-- Invalid Invitation -->
            <div class="alert alert-danger" v-if="invalidInvitation">
                Whoops! This invitation code is invalid.
            </div>
        </div>
    </div>

    <!-- Plan Selection -->
    <div class="row justify-content-center" v-if="paidPlans.length > 0 && !registerForm.invitation">
        <div class="col-lg-8">
            <div class="card card-default">
                <div class="card-header">
                    <div class="float-left">
                        Subscription
                    </div>

                    <!-- Interval Selector Button Group -->
                    <div class="float-right">
                        <div class="btn-group btn-group-sm" v-if="hasMonthlyAndYearlyPlans" style="padding-top: 2px;">
                            <!-- Monthly Plans -->
                            <button
                                type="button"
                                class="btn btn-light"
                                @click="$emit('showMonthly')"
                                :class="{'active': showingMonthlyPlans}"
                            >
                                Monthly
                            </button>

                            <!-- Yearly Plans -->
                            <button
                                type="button"
                                class="btn btn-light"
                                @click="$emit('showYearly')"
                                :class="{'active': showingYearlyPlans}"
                            >
                                Yearly
                            </button>
                        </div>
                    </div>

                    <div class="clearfix"></div>
                </div>

                <div class="table-responsive">
                    <!-- Plan Error Message - In General Will Never Be Shown -->
                    <div class="alert alert-danger m-4" v-if="registerForm.errors.has('plan')">
                        {{ registerForm.errors.get('plan') }}
                    </div>

                    <table class="table table-responsive-sm table-valign-middle mb-0 ">
                        <thead></thead>
                        <tbody>
                            <tr v-for="(plan, index) in plansForActiveInterval" :key="index">
                                <!-- Plan Name -->
                                <td>
                                    <div class="d-flex align-items-center">
                                        <i class="radio-select mr-2" @click="$emit('selectPlan', plan)"
                                        :class="{'radio-select-selected': isSelected(plan)}"></i>
                                        Custom Plan
                                    </div>
                                </td>

                                <td>
                                    <div>
                                        Voxie custom plan - please contact your account rep for details.
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Basic Profile -->
    <div class="row justify-content-center">
        <div class="col-lg-8">
            <div class="card card-default">
                <div class="card-header">
                    <span v-if="paidPlans.length > 0">
                        Profile
                    </span>

                    <span v-else>
                        Register
                    </span>
                </div>

                <div class="card-body">
                    <!-- Generic Error Message -->
                    <div class="alert alert-danger" v-if="registerForm.errors.has('form')">
                        {{ registerForm.errors.get('form') }}
                    </div>

                    <!-- Invitation Code Error -->
                    <div class="alert alert-danger" v-if="registerForm.errors.has('invitation')">
                        {{ registerForm.errors.get('invitation') }}
                    </div>

                    <!-- Registration Form -->
                    <register-common-form
                        :teams="teams"
                        :only-team-plans="onlyTeamPlans"
                        :invitation="invitation"
                        :register-form="registerForm"
                        :selected-plan="selectedPlan"
                        @register="$emit('register')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RegisterCommonForm from './RegisterCommonForm.vue';
import Dinero from 'dinero.js';

export default {
    props: {
        teams: Object,
        coupon: Object,
        invalidCoupon: Boolean,
        invitation: Object,
        invalidInvitation: Boolean,
        paidPlans: Array,
        registerForm: Object,
        hasMonthlyAndYearlyPlans: Boolean,
        plansForActiveInterval: Array,
        showingMonthlyPlans: Boolean,
        showingYearlyPlans: Boolean,
        selectedPlan: Object,
        onlyTeamPlans: Boolean,
    },
    emits: ['showMonthly', 'showYearly', 'selectPlan', 'register'],
    components: {
        RegisterCommonForm,
    },
    computed: {
        /**
         * Get the displayable discount for the coupon.
         */
        discount() {
            if (this.coupon) {
                if (this.coupon.percent_off) {
                    return this.coupon.percent_off + '%';
                } else {
                    return this.discountCurrency(this.coupon.amount_off / 100);
                }
            }

            return '';
        },
        discountCurrency(value) {
            return Dinero({
                amount: Math.round(value * 100),
                currency: Spark.currency
            }).setLocale(Spark.currencyLocale).toFormat('$0,0.00');
        },
    },
    methods: {
        /**
         * Determine if the given plan is selected.
         */
        isSelected(plan) {
            return this.selectedPlan && plan.id === this.selectedPlan.id;
        },
    },
};
</script>
