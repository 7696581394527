<template>
    <vx-expandable-card>
        <template v-slot:header>
            <div class="vx-flex vx-items-center">
                <span>SAML2 Connection</span>
                <experimental-pill class="vx-ml-2"></experimental-pill>
            </div>
        </template>
        <template v-slot:headerRight>
            <vx-button type="button" size="sm" color="muted-dark" data-test="saml2-urls" title="Show connection URLs"
                @click="showUrls = true" :disabled="!isConnected">
                <font-awesome-icon :icon="faLink"></font-awesome-icon>
            </vx-button>
        </template>
        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-4">
                <div class="vx-flex vx-flex-col">
                    <vx-label class="vx-mb-1">IdP Entity ID</vx-label>
                    <vx-input v-model="form.idp_entity_id" type="url" size="lg" placeholder="IdP Entity ID" :disabled="isConnected" data-test="idp-entity-id"></vx-input>
                </div>

                <div class="vx-flex vx-flex-col">
                    <vx-label class="vx-mb-1">IdP Login URL</vx-label>
                    <vx-input v-model="form.idp_login_url" type="url" size="lg" placeholder="IdP Login URL" :disabled="isConnected" data-test="idp-login-url"></vx-input>
                </div>

                <div class="vx-flex vx-flex-col">
                    <vx-label class="vx-mb-1">IdP x509 Cert</vx-label>
                    <vx-input v-model="form.idp_x509_cert" :rows="3" size="lg" placeholder="IdP x509 Cert" :disabled="isConnected" class="vx-min-h-[180px] vx-text-sm" data-test="idp-x509-cert"></vx-input>
                </div>

                <div class="vx-flex vx-flex-col">
                    <vx-label class="vx-mb-1">Name ID Format</vx-label>
                    <vx-select v-model="form.name_id_format" :options="NAMEID_FORMATS" :disabled="isConnected" data-test="name-id-format"></vx-select>
                </div>

                <div class="vx-flex vx-flex-col">
                    <vx-label class="vx-mb-1">Email Attribute</vx-label>
                    <vx-input v-model="form.email_attribute" size="lg" type="url" placeholder="Email Attribute" :disabled="isConnected" data-test="email-attribute"></vx-input>
                </div>

                <div class="vx-flex vx-flex-col">
                    <vx-label class="vx-mb-1">Name Attributes</vx-label>
                    <div class="vx-relative">
                        <div class="vx-absolute vx-left-8 vx-top-0 vx-w-px vx-h-full vx-bg-slate-300 lg:vx-left-16">
                        </div>
                        <div v-for="(nameAttribue, index) in form.name_attributes" :key="`saml2-connection-name-attribute-${nameAttribue.key}`"
                            class="vx-relative vx-mb-4 vx-flex vx-gap-2">
                            <vx-box class="vx-relative vx-p-3 vx-flex-grow vx-space-x-2">
                                <vx-input v-model="nameAttribue.value" size="lg" type="url"
                                    placeholder="Name Attribute" :disabled="isConnected" :data-test="`name-attribute-${nameAttribue.key}`"></vx-input>
                            </vx-box>
                            <vx-box v-if="index > 0" class="vx-p-3 vx-flex vx-items-center vx-justify-center">
                                <remove-field @click="removeNameAttribute(nameAttribue.key)"></remove-field>
                            </vx-box>
                        </div>
                        <vx-box class="vx-relative vx-inline-flex vx-p-3 vx-space-x-2">
                            <vx-button type="button" size="lg" color="primary" data-test="add-name-attribute"
                                @click="addNameAttribute" :disabled="isConnected">
                                Add <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                            </vx-button>
                        </vx-box>
                    </div>
                </div>

                <div class="vx-flex">
                    <vx-button class="vx-grow" size="lg" :color="isConnected ? 'danger' : 'primary'" :loading="loading" :disabled="!isConnected && !isValid"
                        @click="isConnected ? deleteConnection() : createConnection()" data-test="submit-button">
                        {{ isConnected ? 'Disconnect' : 'Connect' }}
                    </vx-button>
                </div>
            </div>

            <vx-modal :visible="errors.length > 0" @update:visible="errors = []">
                <div class="vx-text-center vx-text-5xl vx-text-rose-500 vx-pb-6">
                    <font-awesome-icon :icon="faCircleExclamation"></font-awesome-icon>
                </div>
                <div class="vx-max-w-md">
                    <div v-for="error in errors" :key="error">{{ error }}</div>
                </div>
            </vx-modal>

            <vx-modal :visible="showUrls" @update:visible="showUrls = false">
                <div class="vx-max-w-md vx-flex vx-flex-col vx-gap-3">
                    <div>
                        <vx-label class="vx-mb-1">SP ID URL</vx-label>
                        <span>{{ spIdUrl }}</span>
                    </div>

                    <div>
                        <vx-label class="vx-mb-1">ACS URL</vx-label>
                        <span>{{ acsUrl }}</span>
                    </div>

                    <div>
                        <vx-label class="vx-mb-1">Login URL</vx-label>
                        <span>{{ loginUrl }}</span>
                    </div>
                </div>
            </vx-modal>
        </template>
    </vx-expandable-card>
</template>
<script setup>
import { VxExpandableCard, VxLabel, VxInput, VxSelect, VxBox, VxButton, VxModal } from '@voxie/frontend-components';
import { h, onMounted, ref, reactive, computed } from 'vue';
import { NAMEID_FORMATS } from '../../../../../constants/sso';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleExclamation, faCirclePlus, faLink, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { singleSignOnClient } from '../../../../../services';
import { useToasted } from '../../../../../composables/useToasted';
import incrementingId from '../../../../../utils/incrementingId';
import { HTTP_NOT_FOUND } from '../../../../../support/HttpStatuses';
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';

const $toasted = useToasted();

const props = defineProps({
    team: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['connected', 'disconnected']);

const loading = ref(false);
const errors = ref([]);
const showUrls = ref(false);
const spIdUrl = `https://${window.location.host}/sso/saml2/${props.team.organization_id}/metadata`;
const acsUrl = `https://${window.location.host}/sso/saml2/${props.team.organization_id}/acs`;
const loginUrl = `https://${window.location.host}/sso/saml2/${props.team.organization_id}/login`;

const makeEmptyNameAttribute = () => {
    return {
        key: incrementingId(),
        value: undefined,
    }
}

const defaultForm = () => {
   return {
    idp_entity_id: undefined,
    idp_login_url: undefined,
    idp_x509_cert: undefined,
    name_id_format: NAMEID_FORMATS.find((o) => !!o.value)?.value,
    email_attribute: undefined,
    name_attributes: [makeEmptyNameAttribute()],
   }
}

const form = reactive(defaultForm());

const isConnected = ref(false);

const isValid = computed(() => {
    return !validate().length
});

const RemoveField = (props) => {
    return h(VxButton, {
        class: `vx-w-12 ${props.class}`,
        onClick: props.click,
        type: 'button',
        size: 'md',
        color: 'muted-light',
        'data-test': 'remove-field',
    }, () => [
        h(FontAwesomeIcon, {
            icon: faTrash
        })
    ])
}

const addNameAttribute = () => {
    if (form.name_attributes.length === 3) {
        errors.value.push('Name Attributes should not contain more than 3 Name Attribute.');

        return;
    }

    form.name_attributes.push(makeEmptyNameAttribute());
}

const removeNameAttribute = (key) => {
    form.name_attributes = form.name_attributes.filter((na) => na.key !== key);

    if (form.name_attributes.length < 1) {
        addNameAttribute();
    }
}

const validate = () => {
    const messages = [];
    if (!form.idp_entity_id) {
        messages.push('IdP Entity ID field is required!');
    }

    if (!form.idp_entity_id?.startsWith('http://') && !form.idp_entity_id?.startsWith('https://')) {
        messages.push('IdP Entity ID field should be a valid url!');
    }

    if (!form.idp_login_url) {
        messages.push('IdP Login URL field is required!');
    }

    if (!form.idp_login_url?.startsWith('http://') && !form.idp_login_url?.startsWith('https://')) {
        messages.push('IdP Login URL field should be a valid url!');
    }

    if (!form.idp_x509_cert) {
        messages.push('IdP x509 Cert field is required!');
    }

    if (!form.name_id_format) {
        messages.push('Name ID Format field is required!');
    }

    if (!NAMEID_FORMATS.map((nif) => nif.value).includes(form.name_id_format)) {
        messages.push('Name ID Format field should be a valid format!');
    }

    if (!form.email_attribute) {
        messages.push('Email Attribute field is required!');
    }

    if (!form.email_attribute?.startsWith('http://') && !form.email_attribute?.startsWith('https://')) {
        messages.push('Email Attribute field should be a valid url!');
    }

    if (!form.name_attributes.filter((na) => !na?.value?.trim()).length < 1) {
        messages.push('Name Attributes should contain at least a one Name Attribute.');
    }

    return messages;
}

const createConnection = async () => {
    if (loading.value) {
        return;
    }

    loading.value = true;

    try {
        errors.value = validate();

        if (errors.value.length) {
            loading.value = false;
            return;
        }

        await singleSignOnClient.createConnection(props.team.id, {
            ...form,
            name_attributes: form.name_attributes.map((na) => na.value),
        });

        setConnected(true);
    } catch (e) {
        const serverErrors = e?.response?.data?.errors;
        if (serverErrors) {
            errors.value = Object.values(serverErrors).flat();
        }

        $toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
};


const deleteConnection = async () => {
    if (loading.value) {
        return;
    }


    if (!window.confirm('Are you sure you want to disconnect? There is no undo.')) {
        return;
    }

    loading.value = true;

    try {
        await singleSignOnClient.deleteConnection(props.team.id);

        Object.assign(form, defaultForm())

        setConnected(false);
    } catch (e) {
        setConnected(true);

        $toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
};

const getConnection = async () => {
    if (loading.value) {
        return;
    }

    loading.value = true;

    try {
        const response = await singleSignOnClient.getConnection(props.team.id);

        form.idp_entity_id = response.data.idp_entity_id;
        form.idp_login_url = response.data.idp_login_url;
        form.idp_x509_cert = response.data.idp_x509_cert;
        form.name_id_format = response.data.name_id_format || NAMEID_FORMATS.find((o) => !!o.value)?.value;
        form.email_attribute = response.data.email_attribute;
        form.name_attributes = response.data.name_attributes.map((na) => ({key: incrementingId(), value: na})) || [makeEmptyNameAttribute()];

        setConnected(true);

    } catch (e) {
        if (e?.response?.status !== HTTP_NOT_FOUND){
            $toasted.global.platform_error();
        }
    } finally {
        loading.value = false;
    }
}

const setConnected = (connected) => {
    isConnected.value = connected;

    emit(connected ? 'connected' : 'disconnected');
}

onMounted(() => {
    getConnection();
})
</script>
