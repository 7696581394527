<template>
    <div class="card card-default">
        <div class="card-header">
            Clone Teams
        </div>

        <div class="card-body">
            <div v-if="errors.isNotEmpty()" class="alert alert-danger" role="alert">
                <ul>
                    <li v-for="key in errors.keys()" :key="key" v-text="errors.first(key)"></li>
                </ul>
            </div>
            <form role="form" @submit.prevent="submit">
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Source Team</label>
                    <div class="col-md-6">
                        <v-select
                            :model-value="sourceTeam"
                            @update:model-value="sourceTeam = $event"
                            placeholder="Choose a source team"
                            label="name"
                            :options="teams"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Cloning Targets</label>
                    <div class="col-md-6">
                        <v-select
                            id="selectedCloningTargets"
                            :model-value="selectedCloningTargets"
                            @update:model-value="selectCloningTargets($event)"
                            placeholder="Choose cloning targets"
                            label="name"
                            :options="cloningTargets"
                            multiple
                            :deselect-from-dropdown="true"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Target Teams</label>
                    <div class="col-md-6">
                        <v-select
                            id="selectedTargetTeams"
                            :model-value="selectedTargetTeams"
                            @update:model-value="selectTeams($event)"
                            placeholder="Choose target teams"
                            label="name"
                            :options="teams"
                            multiple
                            :deselect-from-dropdown="true"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-4">
                        <div class="form-check">
                            <input type="checkbox" id="cloneSegments" class="form-check-input" :disabled="sourceTeam && cloneIds.segments.length" v-model="clone.segments">
                            <label class="form-check-label" for="cloneSegments">
                                Clone all segments
                            </label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-check">
                            <input type="checkbox" id="cloneAutomations" class="form-check-input" :disabled="sourceTeam && cloneIds.automations.length" v-model="clone.automations">
                            <label class="form-check-label" for="cloneAutomations">Clone all automations</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-check">
                            <input type="checkbox" id="cloneTemplates" class="form-check-input" :disabled="sourceTeam && cloneIds.snippets.length" v-model="clone.snippets">
                            <label class="form-check-label" for="cloneTemplates">Clone all snippets</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <div class="form-check">
                            <input type="checkbox" id="cloneCampaigns" class="form-check-input" :disabled="sourceTeam && cloneIds.campaigns.length" v-model="clone.campaigns">
                            <label class="form-check-label" for="cloneCampaigns">Clone all campaigns</label>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-check">
                            <input type="checkbox" id="cloneTeamCustomAttributes" class="form-check-input" :disabled="cloneIds.team_custom_attributes.length" v-model="clone.team_custom_attributes">
                            <label class="form-check-label" for="cloneTeamCustomAttributes">Clone all team custom attributes</label>
                        </div>
                    </div>
                </div>

                <div class="form-group row mb-10">
                    <div class="offset-md-4 col-md-6">
                        <button type="submit"
                                style="width: 220px"
                                class="btn btn-primary btn-lg"
                                :disabled="! sourceTeam || ! selectedTargetTeams.length || (! (clone.segments || clone.campaigns || clone.automations || clone.team_custom_attributes || clone.snippets) && ! hasCloneIds())"
                        >Clone Selections</button>
                    </div>
                </div>

                <div class="c-tabs">
                    <nav class="c-tabs__list nav nav-tabs p-10" role="tablist">
                        <a
                            class="c-tabs__link"
                            v-bind:class="{ active: activeTab == 'segments', disabled: ! sourceTeam }"
                            v-on:click="activeTab = 'segments'"
                        >Segments</a>

                        <a
                            class="c-tabs__link"
                            v-bind:class="{ active: activeTab == 'campaigns', disabled: ! sourceTeam }"
                            v-on:click="activeTab = 'campaigns'"
                        >Campaigns</a>

                        <a
                            class="c-tabs__link"
                            v-bind:class="{ active: activeTab == 'automations', disabled: ! sourceTeam}"
                            v-on:click="activeTab = 'automations'"
                        >Automations</a>

                        <a
                            class="c-tabs__link"
                            v-bind:class="{ active: activeTab == 'team_custom_attributes', disabled: ! sourceTeam}"
                            v-on:click="activeTab = 'team_custom_attributes'"
                        >Team Custom Attributes</a>

                        <a
                            class="c-tabs__link"
                            v-bind:class="{ active: activeTab == 'snippets', disabled: ! sourceTeam}"
                            v-on:click="activeTab = 'snippets'"
                        >Templates</a>


                    </nav>

                    <div class="c-tabs__content tab-content p-10" id="cloneTabContent">
                        <div class="tab-pane fade active show row" role="tabpanel" v-if="! sourceTeam">
                            <h3 class="text-center">No source team selected.</h3>
                        </div>
                        <div class="tab-pane fade active show row" role="tabpanel" v-if="sourceTeam && ! activeTab">
                            <h3 class="text-center">No tab selected.</h3>
                        </div>
                        <div class="tab-pane fade active show row" role="tabpanel" v-if="activeTab == 'segments' && sourceTeam">
                            <h3 class="text-center">Segments</h3>

                            <div v-if="tabsData.segments.length > 0">
                                <div class="col-md-8">
                                    <v-select
                                        :model-value="cloneIds.segments"
                                        @update:model-value="cloneIds.segments = $event; clone.segments = false"
                                        placeholder="Choose a target segment"
                                        label="name"
                                        :options="tabsData.segments"
                                        multiple
                                        :deselect-from-dropdown="true"
                                    />
                                </div>
                            </div>

                            <div v-if="tabsData.segments.length == 0">
                                <p class="text-left m-4.5">Source team has no segments.</p>
                            </div>

                        </div>
                        <div class="tab-pane fade active show row" role="tabpanel" v-if="activeTab == 'campaigns' && sourceTeam">
                            <h3 class="text-center">Campaigns</h3>

                            <div v-if="tabsData.campaigns.length > 0">
                                <div class="col-md-8">
                                    <v-select
                                        :model-value="cloneIds.campaigns"
                                        @update:model-value="cloneIds.campaigns = $event; clone.campaigns = false"
                                        placeholder="Choose a target campaign"
                                        label="name"
                                        :options="tabsData.campaigns"
                                        multiple
                                        :deselect-from-dropdown="true"
                                    />
                                </div>
                            </div>

                            <div v-if="tabsData.campaigns.length == 0">
                                <p class="text-left m-4.5">Source team has no campaigns.</p>
                            </div>
                        </div>
                        <div class="tab-pane fade active show row" role="tabpanel" v-if="activeTab == 'automations' && sourceTeam">
                            <h3 class="text-center">Automations</h3>

                            <div v-if="tabsData.automations.length > 0">
                                <div class="col-md-8">
                                    <v-select
                                        :model-value="cloneIds.automations"
                                        @update:model-value="cloneIds.automations = $event; clone.automations = false"
                                        placeholder="Choose a target automation"
                                        label="name"
                                        :options="tabsData.automations"
                                        multiple
                                        :deselect-from-dropdown="true"
                                    />
                                </div>
                            </div>

                            <div v-if="tabsData.automations.length == 0">
                                <p class="text-left m-4.5">Source team has no automations.</p>
                            </div>
                        </div>
                        <div class="tab-pane fade active show row" role="tabpanel" v-if="activeTab == 'team_custom_attributes' && sourceTeam">
                            <h3 class="text-center">Custom Team Attributes</h3>

                            <div v-if="tabsData.team_custom_attributes.length > 0">
                                <div class="col-md-8">
                                    <v-select
                                        :model-value="cloneIds.team_custom_attributes"
                                        @update:model-value="cloneIds.team_custom_attributes = $event; clone.team_custom_attributes = false"
                                        placeholder="Choose a target team custom attribute"
                                        label="key"
                                        :options="tabsData.team_custom_attributes"
                                        multiple
                                        :deselect-from-dropdown="true"
                                    />
                                </div>
                            </div>

                            <div v-if="tabsData.team_custom_attributes.length == 0">
                                <p class="text-left m-4.5">Source team has no team custom attributes.</p>
                            </div>
                        </div>
                        <div class="tab-pane fade active show row" role="tabpanel" v-if="activeTab == 'snippets' && sourceTeam">
                            <h3 class="text-center">Templates</h3>

                            <div v-if="tabsData.snippets.length > 0">
                                <div class="col-md-8">
                                    <v-select
                                        :model-value="cloneIds.snippets"
                                        @update:model-value="cloneIds.snippets = $event; clone.snippets = false"
                                        placeholder="Choose a target snippet"
                                        label="title"
                                        :options="tabsData.snippets"
                                        multiple
                                        :deselect-from-dropdown="true"
                                    />
                                </div>
                            </div>

                            <div v-if="tabsData.snippets.length == 0">
                                <p class="text-left m-4.5">Source team has no snippets.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</template>
<script>
import MessageBag from '../../../support/MessageBag';
import { HTTP_UNPROCESSABLE_ENTITY } from '../../../support/HttpStatuses';
import {
    adminCloningClient,
    adminCloningTargetsClient,
} from '../../../services';
import vSelect from 'vue-select';

export default {
    name: 'clone-teams',

    components: {
        vSelect,
    },

    data() {
        return {
            sourceTeam: null,
            selectedTargetTeams: [],
            selectedCloningTargets: [],
            teams: [],
            cloningTargets: [],
            errors: new MessageBag(),
            activeTab: null,
            tabsData: {
                segments: [],
                campaigns: [],
                automations: [],
                snippets: [],
                team_custom_attributes: [],
            },
            clone: {
                segments: false,
                campaigns: false,
                automations: false,
                snippets: false,
                team_custom_attributes: false,
            },
            cloneIds: {
                segments: [],
                campaigns: [],
                automations: [],
                snippets: [],
                team_custom_attributes: [],
            },
        };
    },

    mounted() {
        this.fetchAndSetTeamsAndCloningTargets();

        Bus.$on('cloningTargetCreated', () => {
            this.fetchAndSetTeamsAndCloningTargets();
        });

        Bus.$on('cloningTargetUpdated', () => {
            this.fetchAndSetTeamsAndCloningTargets();
        });

        Bus.$on('cloningTargetDeleted', () => {
            this.fetchAndSetTeamsAndCloningTargets();
        });
    },

    watch: {
        sourceTeam() {
            this.updateTabsData();
        },
    },

    methods: {
        fetchAndSetTeamsAndCloningTargets() {
            this.selectedTargetTeams = [];
            this.selectedCloningTargets = [];

            adminCloningClient.getTeams().then((response) => (this.teams = response.data.data));
            adminCloningTargetsClient.getCloningTargets(50, null).then((response) => (this.cloningTargets = response.data.data));
        },

        updateTabsData() {
            if (!this.sourceTeam) {
                return;
            }

            this.tabsData = {
                segments: [],
                campaigns: [],
                automations: [],
                snippets: [],
                team_custom_attributes: [],
            };

            this.cloneIds = {
                segments: [],
                campaigns: [],
                automations: [],
                snippets: [],
                team_custom_attributes: [],
            };

            adminCloningClient.getClonableSegments(this.sourceTeam.id)
                .then(response => (this.tabsData.segments = response.data));

            adminCloningClient.getClonableCampaigns(this.sourceTeam.id)
                .then(response => (this.tabsData.campaigns = response.data));

            adminCloningClient.getClonableAutomations(this.sourceTeam.id)
                .then(response => (this.tabsData.automations = response.data));

            adminCloningClient.getClonableSnippets(this.sourceTeam.id)
                .then(response => (this.tabsData.snippets = response.data));

            adminCloningClient.getClonableTeamCustomAttributes(this.sourceTeam.id)
                .then(response => (this.tabsData.team_custom_attributes = response.data));
        },

        getResourceData(resource) {
            let resources, resourceKey;

            if (this.clone[resource] && Array.isArray(this.tabsData[resource]) && this.tabsData[resource].length) {
                resources = this.tabsData[resource];
            } else if (Array.isArray(this.cloneIds[resource]) && this.cloneIds[resource].length) {
                resources = this.cloneIds[resource];
            } else {
                return null;
            }

            resourceKey = `source_${resource.substring(0, resource.length - 1)}_ids`;
            let data = {};

            if (resource === 'automations') {
                resource = 'automation-rules';
                resourceKey = 'source_automation_rule_ids';
            } else if (resource === 'team_custom_attributes') {
                resource = 'team-custom-attributes';
            }

            data[resourceKey] = resources.map((item) => item.id);

            return data;
        },

        hasCloneIds() {
            for (const resource in this.cloneIds) {
                if (this.cloneIds[resource].length) {
                    return true;
                }
            }

            return false;
        },

        requestCloneTeamResources() {
            if (!this.sourceTeam) {
                return;
            }

            let hasData = false;
            let data = {
                target_team_ids: this.selectedTargetTeams.map((team) => team.id),
                source_team_id: this.sourceTeam.id,
            };

            for (const resource in this.clone) {
                let resourceData = this.getResourceData(resource);

                if (resourceData) {
                    hasData = true;
                    data = {
                        ...data,
                        ...resourceData,
                    }
                }
            }

            if (hasData) {
                adminCloningClient.cloneResources(data).catch((error) => {
                    if (error.response.status === HTTP_UNPROCESSABLE_ENTITY) {
                        this.errors = new MessageBag(error.response.data.errors);
                    }

                    Swal.fire({
                        title: 'Oops...',
                        text: `Something went wrong while requesting to clone team resources.`,
                        icon: 'error',
                    });
                });
            }
        },

        submit() {
            const targetTeamNames = this.selectedTargetTeams.map((team) => team.name);
            const text = `You're going to clone team ${this.sourceTeam.name} into team ${targetTeamNames}.`;

            Swal.fire({
                title: 'Are you sure?',
                text: text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, clone it!',
            }).then(({ value: isActionConfirmed }) => {
                if (!isActionConfirmed) {
                    return;
                }

                this.requestCloneTeamResources();

                Swal.fire({
                    title: 'Success',
                    text: 'Your cloning request has been received.',
                    icon: 'success',
                });
            });
        },

        selectTeams(teams) {
            // Select teams
            this.selectedTargetTeams = teams;

            // Disband group if at least one is not selected
            for (let i = 0; i < this.selectedCloningTargets.length; i++) {
                for (let j = 0; j < this.selectedCloningTargets[i].teams.length; j++) {
                    if (!this.selectedTargetTeams.find(team => team.id === this.selectedCloningTargets[i].teams[j].id)) {
                        this.selectedCloningTargets.splice(i, 1);
                        break;
                    }
                }
            }
        },

        selectCloningTargets(cloningTargets) {
            // Get diff (select/deselect)
            const deselectionDiff = this.selectedCloningTargets.filter(tg => !cloningTargets.find(gs => tg.id === gs.id));
            const selectionDiff = cloningTargets.filter(gs => !this.selectedCloningTargets.find(tg => gs.id === tg.id));

            // Select cloning targets
            this.selectedCloningTargets = cloningTargets;

            // Select teams
            selectionDiff.forEach((selectedCloningTarget) => {
                selectedCloningTarget.teams.forEach(selectedTeam => {
                    if (!this.selectedTargetTeams.find(team => team.id === selectedTeam.id)) {
                        this.selectedTargetTeams.push(selectedTeam);
                    }
                });
            });

            // Deselect teams
            deselectionDiff.forEach((deselectedCloningTarget) => {
                deselectedCloningTarget.teams.forEach(teamToDeselect => {
                    this.selectedTargetTeams.splice(this.selectedTargetTeams.findIndex(tt => tt.id === teamToDeselect.id), 1)
                });
            });
        },
    },
}
</script>
