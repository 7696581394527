<template>
    <div class="vx-flex vx-flex-col" :class="{
        'vx-gap-6 lg:vx-gap-8': props.labelSize === 'md',
        'vx-gap-4': props.labelSize === 'sm',
    }">
        <div v-if="!props.disableFieldType">
            <vx-label :size="props.labelSize">
                 Answer Data Type
                <template v-slot:description>
                    This will be the type of data you expect to collect.
                </template>
            </vx-label>
            <vx-selectable :modelValue="props.fieldType ?? ''"
                @update:modelValue="emitFieldType($event)"
                :options="dynamicFieldTypes"
                :reduce="(option) => option.value"
                label="label"
                :clearable="false"
                size="lg"
                @close="validation.fieldType.$touch();"
                :error="displayFirstError(validation.fieldType.$errors)"
                ></vx-selectable>
        </div>
        <div v-if="!!props.fieldType && !isDynamicFieldTypeFirstClass(props.fieldType)">
            <vx-label :size="props.labelSize">
                Answer Name
                <template v-slot:description>
                    This will be the custom attribute where Voxie stores the answer.
                </template>
            </vx-label>
            <vx-selectable :modelValue="props.customAttribute ?? ''"
                @update:modelValue="emit('update:customAttribute', $event.toLowerCase())"
                size="lg"
                :clearable="false"
                :loading="loadingCustomAttributes"
                taggable
                :error="displayFirstError(validation.customAttribute.$errors)"
                @search="searchCustomAttribute"
                data-test="dynamic-field-extraction-name"
            >
            </vx-selectable>
        </div>
        <dynamic-field-extraction-choices
                v-if="props.fieldType === 'choice'"
                :labelSize="props.labelSize"
                :modelValue="props.choices" @update:modelValue="emit('update:choices', $event)"
                :multipleChoices="props.multipleChoices" @update:multipleChoices="emit('update:multipleChoices', $event)"
        ></dynamic-field-extraction-choices>
    </div>
</template>

<script setup>
import { VxLabel, VxSelectable } from '@voxie/frontend-components';
import { computed, nextTick, ref } from 'vue';
import DynamicFieldExtractionChoices from './DynamicFieldExtractionChoices.vue';
import { isDynamicFieldTypeFirstClass } from '~/constants/campaign';
import useVuelidate from '@vuelidate/core';
import { requiredIf, required, maxLength } from '@vuelidate/validators';
import { csvInjection, customAttributeKey, displayFirstError } from '~/utils/validation';
import { customAttributesClient } from '~/services';
import debounce from '~/utils/debounce';

const props = defineProps({
    labelSize: {
        type: String,
        default: 'md',
    },
    fieldType: {
        type: String,
        default: ''
    },
    customAttribute: {
        type: String,
        default: ''
    },
    choices: {
        type: [Array, undefined],
        default: undefined,
    },
    multipleChoices: {
        type: Boolean,
        default: false
    },
    disableFieldType: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:fieldType', 'update:customAttribute', 'update:choices', 'update:multipleChoices']);

const emitFieldType = async (fieldType) => {
    emit('update:fieldType', fieldType);

    if (!isDynamicFieldTypeFirstClass(fieldType)) {
        await nextTick();
        emit('update:customAttribute', undefined);
    }

    if (fieldType !== 'choice') {
        await nextTick();
        emit('update:choices', undefined);
        await nextTick();
        emit('update:multipleChoices', null);
    }
}

const dynamicFieldTypes = [
    {
        label: 'Name',
        value: 'name',
    },
    {
        label: 'Contact Name',
        value: 'contact_name',
    },
    {
        label: 'Number',
        value: 'number',
    },
    {
        label: 'Email',
        value: 'email',
    },
    {
        label: 'Date',
        value: 'date',
    },
    {
        label: 'Location',
        value: 'location',
    },
    {
        label: 'Zip Code',
        value: 'zip_code',
    },
    {
        label: 'Multiple Choice',
        value: 'choice',
    }
];

const isNotFirstClass = computed(() => !isDynamicFieldTypeFirstClass(props.fieldType));

const validation = useVuelidate({
    fieldType: { required },
    customAttribute: { requiredIfNotFirstClass: requiredIf(isNotFirstClass), csvInjection, customAttributeKey, maxLength: maxLength(80) }
}, computed(() => ({
    fieldType: props.fieldType,
    customAttribute: props.customAttribute,
})), {
    $autoDirty: true,
});

const loadingCustomAttributes = ref(false)
const customAttributeOptions = ref([])
const searchCustomAttribute = debounce((query) => {
    loadingCustomAttributes.value = true;
    customAttributesClient.customAttributesAutocomplete(Spark.state.currentTeam.id, {
        query: query,
    }).then(response => {
        customAttributeOptions.value = response.data.data;
    }).finally(() => {
        loadingCustomAttributes.value = false;
    })
}, 300);

</script>
