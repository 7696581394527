<template>
    <div>
        <page-header>
            Groups
            <template v-slot:actions>
                <vx-button @click="showOverview = !showOverview"
                    data-test="toggle-overview"
                    type="button"
                    color="muted-dark"
                    size="lg">
                    <span class="vx-hidden sm:vx-inline-block">{{ showOverview ? 'Hide' : 'Show' }} Overview</span>
                    <font-awesome-icon class="vx-hidden sm:vx-inline-block"
                        :icon="faQuestionCircle"></font-awesome-icon>
                    <font-awesome-icon class="vx-inline-block sm:vx-hidden"
                        :icon="showOverview ? faCircleChevronUp : faCircleChevronDown"></font-awesome-icon>
                </vx-button>
                <vx-button v-if="isAdminOrOwner" size="lg"
                    :href="`/settings/teams/${teamId}/groups/new`">
                    New Group <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
            </template>
        </page-header>

        <groups-overview v-if="showOverview"></groups-overview>

        <group-controls v-model="fetchParams"
            @apply="applyFilters"
            @reset="resetFilters"
            :loading="loading"
            class="vx-mb-12"></group-controls>

        <page-loading v-if="loading && !list.length"></page-loading>

        <template v-if="!loading && !list.length">
            <vx-box color="info"
                class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                No Groups yet.
                <vx-button v-if="isAdminOrOwner"
                    :href="`/settings/teams/${teamId}/groups/new`">
                    New Group
                    <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
            </vx-box>
        </template>

        <vx-table v-else-if="list.length"
            class="xl:vx-grid-cols-4-auto"
            rowBreakpoint="xl">
            <vx-table-row isHeader>
                <div>
                    Name
                </div>
                <div>
                    External ID
                </div>
                <div>
                    Users
                </div>
            </vx-table-row>

            <group-row v-for="(item, index) in list"
                :modelValue="list[index]"
                :key="list[index].group_id"
                @deleted="removeFromList($event)"></group-row>
        </vx-table>

        <div v-if="list.length && fetchParams.cursor"
            class="vx-mt-8">
            <vx-button @click="loadData()"
                color="muted-dark"
                size="xl"
                data-test="load-more-btn"
                class="vx-grow-0"
                :loading="loading">
                Load More
            </vx-button>
        </div>
    </div>
</template>

<script setup>
import {
    faCircleChevronDown,
    faCircleChevronUp,
    faCirclePlus,
    faQuestionCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBox, VxButton, VxTable, VxTableRow } from '@voxie/frontend-components';
import { onMounted, ref } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';
import PageLoading from '~/components/general/PageLoading.vue';
import { useCursorPagination } from '~/composables/useCursorPagination';
import groupsClient from '~/services/groupsClient';
import GroupControls from './GroupControls.vue';
import GroupsOverview from './GroupsOverview.vue';
import GroupRow from './GroupRow.vue';

const showOverview = ref(true);

const teamId = Spark.state.currentTeam.id;
const isAdminOrOwner = Spark.isAdminOrOwner;

const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const defaultFilters = () => ({
    per_page: 15,
    cursor: null,
    sort_field: 'name',
    sort_dir: 'desc'
});

const resetFilters = () => {
    fetchParams.value = defaultFilters();

    loadData();
};

const applyFilters = () => {
    fetchParams.value = {
        ...fetchParams.value,
        cursor: null,
    }

    loadData();
}

onMounted(() => {
    resetFilters();
});

const loadData = () => {
    fetchRecords(groupsClient.listGroups(teamId, { ...fetchParams.value }));
};

const removeFromList = (itemId) => {
    list.value = list.value.filter((item) => item.group_id !== itemId);

    if (!list.value.length) {
        loadData();
    }
};

</script>
