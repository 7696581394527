<template>
    <vx-radio-selection :modelValue="props.modelValue" @update:modelValue="emit('update:modelValue', $event)">
        <vx-radio-selection-item
            v-for="(item, index) in items"
            :key="item"
            :value="item"
        >
            <template v-slot:header>Option {{index+1}}</template>
            <template v-slot:content>
                <vx-writer :speed="5" :text="item" @completed="loadNextItem()"></vx-writer>
            </template>
        </vx-radio-selection-item>
    </vx-radio-selection>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { VxWriter, VxRadioSelection, VxRadioSelectionItem } from '@voxie/frontend-components';

const props = defineProps({
    messages: {
        type: Array,
        required: true,
    },
    modelValue: {
        type: String,
    }
});

const emit = defineEmits(['update:modelValue']);

const items = ref([]);

onMounted(() => {
    loadNextItem();
})

const loadNextItem = () => {
    const nextItem = props.messages[items.value.length];

    if (nextItem) {
        items.value.push(nextItem)
    }
}

</script>
