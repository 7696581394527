<template>
    <div class="vx-grid vx-grid-cols-12 vx-gap-4 lg:vx-gap-6 xl:vx-grid-rows-2 sm:vx-pb-4 lg:vx-pb-6 xl:vx-pb-8">
        <div class="vx-col-span-12 lg:vx-col-span-6 xl:vx-col-span-5 xl:vx-row-span-2">
            <vx-box-glass class="vx-text-slate-700 vx-font-sans">
                <vx-wallpaper :icon="faCube"></vx-wallpaper>
                <div class="vx-relative vx-p-6 lg:vx-pl-10 lg:vx-py-10 lg:vx-pr-6 xl:vx-p-10">
                    <h2 class="vx-mb-2 vx-text-xl vx-font-bold">
                        Overview
                        <font-awesome-icon
                            :icon="faTelescope"
                        ></font-awesome-icon>
                    </h2>
                    <p
                        class="vx-text-sm vx-mb-3 vx-font-sans vx-text-slate-700 lg:vx-text-base"
                    >
                        A segment is a set of contacts grouped together by
                        filters. Auto-refreshing segments refresh automatically
                        while static segments need to be refreshed manually.
                        Each segment has an audience type that limits the sort
                        of messages that can be sent.
                    </p>
                    <a
                        href="https://support.voxie.com/hc/en-us/articles/9691139057549-Segments"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="vx-text-sm vx-font-sans vx-font-normal vx-text-sky-500 vx-underline lg:vx-text-base"
                    >
                        See Docs for More
                        <font-awesome-icon
                            class="vx-ml-2"
                            :icon="faArrowRightLong"
                        ></font-awesome-icon>
                    </a>
                </div>
            </vx-box-glass>
        </div>
        <div class="vx-col-span-12 lg:vx-col-span-6 xl:vx-col-span-7">
            <vx-chart-categorical
                class="vx-pb-8"
                :loading="audienceTypeLoading"
                :data="audienceTypeCount"
                :colors="audienceTypeColors"
                :axis="{
                    x: {
                        key: 'count',
                    },
                }"
            >
                <template v-slot:title> Audience Type </template>
            </vx-chart-categorical>
        </div>
        <div class="vx-col-span-12 xl:vx-col-span-7">
            <vx-chart-categorical
                class="vx-pb-8"
                :loading="refreshTypeLoading"
                :data="refreshTypeCount"
                :colors="refreshTypeColors"
                :axis="{
                    x: {
                        key: 'count',
                    },
                }"
            >
                <template v-slot:title> Refresh Type </template>
            </vx-chart-categorical>
        </div>
    </div>
</template>

<script setup>
import {
    VxBoxGlass,
    VxChartCategorical,
    VxWallpaper
} from "@voxie/frontend-components";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRightLong } from '@fortawesome/pro-solid-svg-icons';
import { faTelescope, faCube } from '@fortawesome/pro-regular-svg-icons';
import { ref } from 'vue';
import segmentsClient from '../../../services/segmentsClient';
import { generateLegendColors } from '../../../utils/analytics';
import { uniqBy } from '~/utils/helpers';
import { SegmentType, SegmentTypeToLabel } from '../../../constants/segment';
import { audienceTypeLabel } from '../../../constants/campaign';

const teamId = Spark.state.currentTeam.id;

const audienceTypeLoading = ref(true);
const audienceTypeCount = ref([]);
const audienceTypeColors = ref([]);

const refreshTypeLoading = ref(true);
const refreshTypeCount = ref([]);
const refreshTypeColors = ref([]);

segmentsClient.countByAudienceType(teamId).then((res) => {
    audienceTypeCount.value = res.data.data.map((item) => ({
        label: audienceTypeLabel(item.audience_type),
        count: item.count,
    }));

    audienceTypeColors.value = generateLegendColors(
        uniqBy(audienceTypeCount.value, "label").map((item) => item.label)
    );
}).finally(() => audienceTypeLoading.value = false);

segmentsClient.countByRefreshType(teamId).then((res) => {
    refreshTypeCount.value = res.data.data.map((item) => ({
        label: SegmentTypeToLabel[item.refresh_type],
        count: item.count,
    }));

    refreshTypeColors.value = [
        {
            // violet
            label: SegmentTypeToLabel[SegmentType.AUTO_REFRESH],
            iconColor: "#8B5CF6",
            bgColor: "#C4B5FD",
        },
        {
            // green
            label: SegmentTypeToLabel[SegmentType.STATIC],
            iconColor: "#22c55e",
            bgColor: "#86efac",
        },
    ];
}).finally(() => refreshTypeLoading.value = false);
</script>
