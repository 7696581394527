<template>
    <div class="card card-default">
        <div class="card-header">Extra Billing Information</div>

        <div class="card-body">
            <!-- Information Message -->
            <div class="alert alert-info" data-test="info-alert">
                <template v-if="canUpdate">
                    This information will appear on all of your receipts, and is a great place to add your full business name or address of record. Do not include any confidential or financial information such as credit card numbers.
                </template>
                <template v-else>
                    Extra billing information may only be set/changed by the team owner.
                </template>
            </div>

            <!-- Success Message -->
            <div
                class="alert alert-success"
                data-test="success-alert"
                v-if="form.successful">
                Your extra billing information has been updated!
            </div>

            <!-- Error Message -->
            <div
                v-else-if="form.errors.has('form')"
                class="alert alert-danger"
                data-test="error-alert"
            >
                {{ form.errors.get('form') }}
            </div>

            <!-- Extra Billing Information -->
            <form role="form">
                <div class="form-group row">
                    <div class="col-md-12">
                        <textarea class="form-control"
                                  rows="7"
                                  v-model="form.information"
                                  style="font-family: monospace;"
                                  :class="[{'is-invalid': form.errors.has('information')}, {'cursor-not-allowed': !canUpdate}]"
                                  :disabled="!canUpdate"
                        ></textarea>

                        <span class="invalid-feedback"
                              data-test="textarea-error"
                              v-show="form.errors.has('information')">
                            {{ form.errors.get('information') }}
                        </span>
                    </div>
                </div>

                <!-- Update Button -->
                <div class="form-group row mb-0" v-if="canUpdate">
                    <div class="offset-md-4 col-md-8 text-right">
                        <button type="submit" class="btn btn-primary" @click.prevent="update" :disabled="form.busy">
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: ['team', 'ownsTeam', 'isTeamAdmin'],

    /**
     * The component's data.
     */
    data() {
        return {
            form: new SparkForm({
                information: ''
            })
        };
    },


    /**
     * Prepare the component.
     */
    mounted() {
        this.form.information = this.team.extra_billing_information;
        Bus.$on(`sparkFormUpdated.${this.form.sparkFormId}`, this.$forceUpdate);
    },



    methods: {
        /**
         * Update the extra billing information.
         */
        update() {
            Spark.put(this.urlForUpdate, this.form);
        }
    },


    computed: {
        /**
         * Get the URL for the extra billing information method update.
         */
        urlForUpdate() {
            return `/settings/teams/${this.team.id}/extra-billing-information`;
        },
        canUpdate() {
            return this.ownsTeam || this.isTeamAdmin;
        },
    }
};
</script>
