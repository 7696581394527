<template>
    <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8">
        <page-header class="vx-mb-6 lg:vx-mb-12">
            <template v-slot:subtitle>
                <a href="/automation/flows"
                    class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                    <font-awesome-icon class="vx-mr-1"
                        :icon="faArrowLeft"></font-awesome-icon>
                    All Flows
                </a>
            </template>

            {{ flow.name }}
        </page-header>

        <div class="vx-grid vx-grid-cols-12">
            <custom-flow-sidebar :flowId="props.flowId"></custom-flow-sidebar>

            <div v-if="loading"
                class="vx-col-span-12 lg:vx-col-span-10 vx-text-center vx-py-10">
                <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
            </div>
            <div v-else
                class="vx-col-span-12 lg:vx-col-span-10 vx-space-y-8">
                <vx-alert v-if="recentlyCreated"
                    color="success"
                    :closeable="false"
                    shadow>
                    <template v-slot:icon>
                        <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                    </template>
                    <div class="vx-flex vx-items-center vx-justify-between">
                        <span><strong>{{ flow.name }}</strong> Created successfully</span>
                        <vx-button href="/automation/flows"
                            size="sm"
                            color="success">Back to Flows <font-awesome-icon
                                :icon="faSquareArrowUpLeft"></font-awesome-icon></vx-button>
                    </div>
                </vx-alert>

                <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row lg:vx-items-center lg:vx-justify-between">
                    <custom-flow-resources-filters v-model="filters"
                        @reset="resetFilters"
                        :loading="loading"></custom-flow-resources-filters>
                    <vx-button @click="addSection()"
                        type="button"
                        size="lg"
                        color="primary">
                        New Section
                        <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                    </vx-button>
                </div>
                <draggable tag="div"
                    itemKey="draggable_id"
                    handle=".section-handle"
                    :animation="200"
                    :group="{ name: 'sections' }"
                    :list="sections"
                    class="vx-space-y-8 vx-w-full"
                    @change="changeDraggable">
                    <template v-slot:item="{ element, index }">
                        <custom-flow-section v-model:open="element.open"
                            :flowId="flow.flow_id"
                            :flowSection="index"
                            :section="element"
                            @draggable:change="changeDraggable"
                            @remove="removeSection(index)"></custom-flow-section>
                    </template>
                </draggable>
            </div>
        </div>
    </div>
</template>

<script setup>
import { faArrowLeft, faCircleCheck, faCirclePlus, faSquareArrowUpLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxAlert, VxButton, VxIconLoading } from '@voxie/frontend-components';
import { computed, onMounted, ref } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';
import { useToasted } from '~/composables/useToasted';
import dayjs from '~/utils/dayjs';
import CustomFlowResourcesFilters from './CustomFlowResourcesFilters.vue';
import CustomFlowSection from './CustomFlowSection.vue';
import CustomFlowSidebar from './CustomFlowSidebar.vue';
import { useCustomFlow } from './useCustomFlow';
import { useUrlSearchParams } from '@vueuse/core';
import Draggable from "vuedraggable";
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';
import debounce from '~/utils/debounce';

const toasted = useToasted();
const { mount, flow, sections, addSection, removeSection, saveSections } = useCustomFlow();

const props = defineProps({
    flowId: {
        type: String,
    }
});

const defaultFilters = () => ({
    query: "",
});

const filters = ref({});

const resetFilters = () => {
    filters.value = defaultFilters();
}

const loading = ref(false);

const queryParams = useUrlSearchParams();

const changeDraggable = debounce(async () => {
    try {
        await saveSections(sections.value);
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
        } else {
            toasted.error(e.response?.data?.message || 'Something went wrong.');
        }
    }
}, 500);

onMounted(async () => {
    loading.value = true;
    try {
        await mount(props.flowId)
        if (queryParams.flow_section != null) {
            sections.value[queryParams.flow_section].open = true;
        }
    } catch (e) {
        console.error(e)
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
});

const recentlyCreated = computed(() => flow.value.created_at ? dayjs.utc(flow.value.created_at).isAfter(dayjs().subtract(15, 'seconds')) : false);
</script>
