<template>
    <vx-modal :visible="props.visible" @update:visible="updateVisible($event)" class="vx-max-w-lg">
        <template v-slot:header>Adjust Message Balance</template>

        <div class="vx-p-1 vx-mb-3">
            <p class="vx-text-base vx-text-slate-500 vx-mb-3">The current message balance is: <b>{{ props.modelValue }}</b>.</p>
            <vx-input
                data-test="adjust-message-balance-value"
                type="number"
                step="1"
                :max="props.modelValue + 1000000"
                :min="props.modelValue - 1000000"
                v-model="newMessageBalance"
                placeholder="Enter a new message balance">
            </vx-input>
            <span v-for="error in errors" :key="error" class="vx-text-xs vx-text-rose-500">
                {{ error }}
            </span>
        </div>

        <div class="vx-flex vx-space-x-2 vx-justify-center">
            <vx-button
                data-test="adjust-message-balance-submit-button"
                @click="submit"
                type="button"
                size="lg"
                color="primary"
                class="vx-flex-grow"
                :disabled="!valueToAdjust || valueToAdjust > 1000000 || valueToAdjust < -1000000"
                :loading="loading"
            >
                Adjust Balance{{ valueToAdjust ? ` By ${Intl.NumberFormat('en').format(valueToAdjust)}` : '' }}
            </vx-button>
        </div>
    </vx-modal>
</template>
<script setup>
import { computed, ref } from 'vue';
import { VxButton, VxInput, VxModal } from '@voxie/frontend-components';
import { teamsClient } from '../../../services';
import { useToasted } from '../../../composables/useToasted';

const $toasted = useToasted();

const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
    },
    visible: {
        type: Boolean,
        default: false,
    }
});

const emit = defineEmits(['update:visible', 'update:modelValue']);
const updateVisible = (isVisible) => {
    emit('update:visible', isVisible);
}

const loading = ref(false);
const newMessageBalance = ref(props.modelValue);
const errors = ref([]);

const valueToAdjust = computed(() => {
    if (newMessageBalance.value === null || newMessageBalance.value === '') {
        return 0;
    }

    return Number(newMessageBalance.value) - props.modelValue;
});

const submit = async () => {
    if (loading.value || newMessageBalance.value === null || newMessageBalance.value === '') {
        return;
    }
    
    loading.value = true;

    try {
        const response = await teamsClient.adjustMessageBalance(Spark.state.currentTeam.id, {
            amount: valueToAdjust.value,
        });
        
        emit('update:modelValue', response.data.message_balance);

        updateVisible(false);
    } catch (e) {
        const serverErrors = e?.response?.data?.errors;
        if (serverErrors) {
            errors.value = Object.values(serverErrors).flat();
        }

        $toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
}

</script>
