<template>
    <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8 vx-font-sans vx-max-w-screen-xl">
        <h1 class="vx-sr-only">Dashboard</h1>

        <div class="vx-grid vx-grid-cols-12 vx-gap-x-6 vx-gap-y-8">
            <div class="vx-col-span-12 md:vx-col-span-8 lg:vx-col-span-8 vx-grid vx-items-stretch">
                <home-call-to-action></home-call-to-action>
            </div>
            <div class="vx-col-span-12 md:vx-col-span-4 lg:vx-col-span-4">
                <home-features></home-features>
            </div>

            <home-recently-created-campaigns class="vx-col-span-12"></home-recently-created-campaigns>

            <template v-if="canSeeAnalytics" class="vx-w-full">
                <home-analytics></home-analytics>
            </template>
        </div>
    </div>
</template>

<script setup>
import { inject, computed } from 'vue';
import HomeCallToAction from './HomeCallToAction.vue';
import HomeFeatures from './HomeFeatures.vue';
import HomeAnalytics from './HomeAnalytics.vue';
import HomeRecentlyCreatedCampaigns from './HomeRecentlyCreatedCampaigns.vue';
import { hasScope } from '../../utils/users';
const FEATURES = inject('FEATURES')

const canSeeAnalytics = computed(() => hasScope('analytics:read'));
</script>
