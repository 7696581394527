<template>
    <div>
        <div v-if="form.message" class="c-alert c-alert--success alert">
            <i class="c-alert__icon fa fa-check-circle"></i>
            {{ form.message }}
        </div>

        <div class="card card-default">
            <div class="card-header">
                <div class="pull-left">
                    Shopify
                </div>

                <div class="pull-right" v-if="hasPermission && storeId && !loading">
                    <badge :type="status === 'success' ? 'success' : 'warning'">
                        {{ status === 'success' ? 'Connected' : 'Pending' }}
                    </badge>
                </div>
            </div>
            
            <div v-if="!hasPermission" class="card-body">
                Only the team owner or admin can configure integrations.
            </div>
            <form v-else class="card-body" role="form">
                <!-- Store Name -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Store Name</label>

                    <div class="col-md-6">
                        <input type="text" class="form-control" name="name" v-model="form.name" :disabled="disableControl">

                        <span class="c-field__message u-color-danger" v-if="form.errors.has('name')">
                            <i class="fa fa-times-circle"></i>
                            {{ form.errors.get('name') }}
                        </span>
                    </div>
                </div>

                <!-- Shopify Domain Name -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Domain Name</label>

                    <div class="col-md-6">
                        <input type="text" class="form-control" name="domain" v-model="form.domain" :disabled="disableControl">

                        <span class="c-field__message u-color-danger" v-if="form.errors.has('domain')">
                            <i class="fa fa-times-circle"></i>
                            {{ form.errors.get('domain') }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">App Key</label>

                    <div class="col-md-6">
                        <input type="text" class="form-control" name="config.app_key" v-model="form.config.app_key" :disabled="disableControl" placeholder="Leave it empty to keep the same app key.">
                        <span class="c-field__message u-color-danger" v-if="form.errors.has('config.app_key')">
                            <i class="fa fa-times-circle"></i>
                            {{ form.errors.get('config.app_key') }}
                        </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">App Secret</label>

                    <div class="col-md-6">
                        <input type="text" class="form-control" name="config.app_secret" v-model="form.config.app_secret" :disabled="disableControl" placeholder="Leave it empty to keep the same app secret.">
                        <span class="c-field__message u-color-danger" v-if="form.errors.has('config.app_secret')">
                            <i class="fa fa-times-circle"></i>
                            {{ form.errors.get('config.app_secret') }}
                        </span>
                    </div>
                </div>

                <!-- Vendor Tags -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Create Vendor Tags</label>

                    <div class="col-md-6">
                        <v-switch
                            id="vendor_tags"
                            :checked="form.config.create_vendor_tags"
                            :disabled="disableControl">
                        </v-switch>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right"></label>
                    <div class="col-md-6">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="createOrUpdate()"
                            :disabled="disableControl"
                        >
                            {{ buttonText }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger ml-2"
                            @click="destroyStore()"
                            :disabled="disableControl"
                            v-if="storeId !== null"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { shopifyClient } from '../../../services';
import { HTTP_UNPROCESSABLE_ENTITY } from '../../../support/HttpStatuses';
import VSwitch from '../../general/Switch.vue'
import Badge from '../../general/Badge.vue'

export default {
    components: {
        VSwitch,
        Badge,
    },
    props: {
        ownsTeam: {
            type: Boolean,
            default: false,
        },
        isTeamAdmin: {
            type: Boolean, 
            default: false,
        }
    },
    data() {
        return {
            loading: true,
            storeId: null,
            form: new SparkForm({
                name: null,
                domain: null,
                config: {
                    create_vendor_tags: false,
                    app_key: null,
                    app_secret: null,
                },
                message: null,
            }),
            status: 'pending',
        };
    },

    created() {
        Bus.$on('switch-vendor_tags-changed', (value) => {
            this.form.config.create_vendor_tags = value;
        });

        Bus.$on(`sparkFormUpdated.${this.form.sparkFormId}`, this.$forceUpdate)
    },

    mounted() {
        if (this.hasPermission) {
            this.getStore();
        }
    },

    computed: {
        disableControl() {
            return this.loading ? true : null;
        },
        teamId() {
            return Spark.state.currentTeam.id;
        },
        buttonText() {
            if (this.loading) {
                return 'Loading...';
            } else if (this.storeId) {
                return 'Update';
            } else {
                return 'Create';
            }
        },
        hasPermission() {
            return this.ownsTeam || this.isTeamAdmin;
        }
    },

    methods: {
        getStore() {
            shopifyClient
                .getStore(this.teamId)
                .then((response) => {
                    this.form.name = response.data.name;
                    this.form.domain = response.data.domain;
                    this.form.config.create_vendor_tags = response.data.create_vendor_tags;
                    this.storeId = response.data.id;
                    this.healthCheck();
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        this.storeId = null;

                        this.form = new SparkForm({
                            name: null,
                            domain: null,
                            config: {
                                create_vendor_tags: false,
                            },
                        });
                    } else {
                        this.form.errors = error.response ? error.response.data : error;
                    }
                })
                .finally(() => (this.loading = false));
        },
        healthCheck() {
            this.loading = true;

            shopifyClient
                .healthCheck(this.teamId, this.storeId)
                .then((response) => {
                    this.status = response?.data?.is_healthy ? 'success' : 'pending';
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        createOrUpdate() {
            if (this.storeId) {
                this.updateStore();
            } else {
                this.createStore();
            }
        },

        createStore() {
            if (!this.hasPermission) {
                this.permissionError(); 
                return;
            }
            this.loading = true;

            shopifyClient
                .createStore(this.teamId, this.form)
                .then((response) => {
                    this.refreshMessages(response);
                    this.storeId = response.data.storeId;
                })
                .catch((error) => {
                    this.checkErrors(error);
                })
                .finally(() => (this.loading = false));
        },

        updateStore() {
            if (!this.hasPermission) {
                this.permissionError();
                return;
            }
            this.loading = true;

            shopifyClient
                .updateStore(this.teamId, this.storeId, this.form)
                .then((response) => {
                    this.refreshMessages(response);
                })
                .catch((error) => {
                    this.checkErrors(error);
                })
                .finally(() => (this.loading = false));
        },

        destroyStore() {
            if (!this.hasPermission) {
                this.permissionError();
                return;
            }
            if (!window.confirm("Are you sure you want to delete? This action can't be undone")) {
                return;
            }

            this.loading = true;

            shopifyClient
                .deleteStore(this.teamId, this.storeId)
                .then(() => {
                    this.getStore();
                })
                .catch((error) => {
                    this.form.errors = error.response ? error.response.data : error;
                    this.loading = false;
                });
        },

        checkErrors(error) {
            if (error?.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
                const dataErrors = error.response?.data?.errors;
                const errorsObj = new Map();
                for (const error in dataErrors) {
                    errorsObj.set(error, dataErrors[error][0]);
                }
                this.form.errors = errorsObj;
            } else {
                console.error(error);
            }
        },

        refreshMessages(response) {
            this.form.message = response?.data?.message || '';
            this.form.errors = new Map();
        },
        permissionError() {
            this.$toasted.error('Only the team owner or admin is allowed to create the integration.');
        }
    },
}
</script>
