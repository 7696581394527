<template>
    <vx-modal
        class="vx-max-w-lg"
        :visible="visible"
        @update:visible="emit('update:visible', $event)"
    >
        <div v-if="!success" class="vx-font-sans">
            <h2
                class="vx-text-xl vx-font-bold vx-text-slate-700 vx-font-sans vx-my-0"
            >
                Apply Tags to Contacts
            </h2>

            <p class="vx-text-slate-500 vx-text-base vx-font-sans vx-mb-4">
                Specify tags that will be added to
                <strong>all contacts</strong> currently in the
                {{ props.segment.name }} Segment.
            </p>
            <div class="vx-mb-8">
                <label
                    class="vx-font-bold vx-text-slate-700 vx-font-sans vx-mb-1"
                >
                    Select or Create Tags
                </label>
                <vx-selectable
                    multiple
                    taggable
                    placeholder="Enter a tag"
                    :clearable="false"
                    v-model="selectedTags"
                    :options="tags"
                    @search="onSearch"
                    @option:created="onCreated"
                    :reduce="(option) => (option?.value || option)?.toLowerCase()"
                >
                </vx-selectable>
            </div>
            <div class="vx-flex vx-space-x-2 vx-justify-center">
                <vx-button
                    @click="emit('update:visible', false)"
                    type="button"
                    size="lg"
                    color="muted"
                    class="vx-w-20"
                >
                    Cancel
                </vx-button>
                <vx-button
                    data-test="add-tags"
                    @click="tagContacts"
                    type="button"
                    size="lg"
                    color="primary"
                    class="vx-grow"
                    :loading="submiting"
                >
                    Tag Contacts
                </vx-button>
            </div>
        </div>
        <div v-else>
            <div class="vx-flex vx-flex-col vx-text-center vx-items-center">
                <font-awesome-icon
                    class="vx-text-green-600 vx-text-5xl vx-mb-4"
                    :icon="faCheckCircle"
                ></font-awesome-icon>
                <div class="vx-mb-8">
                    <h3 class="vx-text-3xl vx-font-extrabold vx-text-slate-700">
                        Success
                    </h3>
                    <p class="vx-text-lg vx-font-light vx-text-slate-600">
                        Contacts have successfully been tagged.
                    </p>
                </div>
                <vx-button
                    @click="emit('update:visible', false)"
                    color="primary"
                    size="lg"
                    block
                >
                    Ok
                </vx-button>
            </div>
        </div>
    </vx-modal>
</template>

<script setup>
import { ref, watch } from 'vue';
import segmentsClient from '../../../services/segmentsClient';
import tagsClient from '../../../services/tagsClient';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

import { useToasted } from '../../../composables/useToasted';
import { VxButton, VxModal, VxSelectable } from '@voxie/frontend-components';
import debounce from '~/utils/debounce';

const props = defineProps({
    segment: {
        type: Object,
        required: true,
    },
    visible: Boolean,
});

const toasted = useToasted();

const teamId = Spark.state.currentTeam.id;

const emit = defineEmits(["update:visible"]);

const success = ref(false);
const submiting = ref(false);

const tags = ref([]);
const selectedTags = ref([]);

const onSearch = (search, loading) => {
    loading(true);
    debouncedSearch(loading, search);
};

const runSearch = async (loading = () => {}, search = "") => {
    loading(true);

    try {
        const response = await tagsClient.tagsAutocomplete(teamId, {
            query: search,
        });

        tags.value = response.data.data.map((tag) => tag.value.toLowerCase());
    } catch (e) {
        console.error(e);
    } finally {
        loading(false);
    }
};

const onCreated = async (tagName) => {
    tags.value = [tagName.toLowerCase(), ...tags.value];
};

const debouncedSearch = debounce(runSearch, 350);

// act as on mounted as well
watch(
    () => props.visible,
    (visible) => {
        success.value = false;
        submiting.value = false;
        selectedTags.value = [];

        if (visible) {
            runSearch();
        }
    },
    {
        immediate: true,
    }
);

const tagContacts = async () => {
    if (submiting.value || !selectedTags.value.length) {
        return;
    }

    submiting.value = true;
    try {
        await segmentsClient.tagContacts(teamId, props.segment.id, {
            tags: selectedTags.value,
        });
        success.value = true;
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    } finally {
        submiting.value = false;
    }
};
</script>
