<template>
    <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8 2xl:vx-max-w-screen-xl">

        <page-header>
            Tags
        </page-header>

        <tags-controls class="vx-mb-8 vx-mt-10" v-model="fetchParams" @reset="resetFilters()" @apply="applyFilters()"></tags-controls>

        <div v-if="loading"
            class="vx-text-center vx-py-10">
            <vx-icon-loading class="vx-w-9 vx-h-9" spin></vx-icon-loading>
        </div>
        <vx-box color="info"
            v-if="!loading && !list.length"
            class="vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
            No tags found.
        </vx-box>

        <div v-else-if="list.length"
            class="vx-flex vx-flex-col vx-gap-2">
            <vx-box v-for="item in list"
                :key="item.tag_name"
                class="vx-text-slate-700 vx-flex vx-items-center vx-p-4 vx-gap-y-1 vx-gap-x-2 vx-justify-between sm:vx-gap-x-4 ">
                <span class="vx-font-mono vx-grow vx-text-sm">{{ item.tag_name }}</span>
                <vx-button @click.prevent="deleteTag = item"
                    data-test="delete-row"
                    class="vx-shrink-0"
                    type="button"
                    color="muted"
                    outline>
                    Delete
                    <font-awesome-icon :icon="faTrash" />
                </vx-button>
            </vx-box>
        </div>
        <div v-if="fetchParams.cursor"
            class="vx-mt-8 vx-flex vx-justify-between vx-items-center vx-w-full vx-gap-10">
            <vx-button @click="loadData()"
                color="muted-dark"
                size="xl"
                data-test="load-more-btn"
                class="vx-grow sm:vx-grow-0">
                Load More
                <font-awesome-icon :icon="faSpinner"
                    :spin="loading"></font-awesome-icon>
            </vx-button>
        </div>
        <delete-tag-modal v-model:tag="deleteTag"
            @deleted="removeFromList" />
    </div>
</template>

<script setup>
import { faSpinner, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBox, VxButton, VxIconLoading } from '@voxie/frontend-components';
import { onMounted, ref } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';
import { tagsClient } from '~/services';
import DeleteTagModal from './DeleteTagModal.vue';
import TagsControls from './TagsControls.vue';
import { useCursorPagination } from '../../../composables/useCursorPagination';

const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const teamId = Spark.state.currentTeam.id;

const deleteTag = ref(null);

const defaultFilters = () => ({
    query: '',
    cursor: null,
});

const resetFilters = () => {
    fetchParams.value = defaultFilters();

    loadData();
};

const applyFilters = () => {
    fetchParams.value = {
        ...fetchParams.value,
        cursor: null,
    }

    loadData();
}

onMounted(() => {
    resetFilters();
});

const loadData = () => {
    fetchRecords(tagsClient.tagsGetAll(teamId, {...fetchParams.value}));
};

const removeFromList = (tagName) => {
    list.value = list.value.filter((item) => item.tag_name !== tagName);

    if (!list.value.length) {
        loadData();
    }
};
</script>
