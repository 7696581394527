<template>
    <vx-chart-categorical
        :loading="loading"
        :data="data"
        :colors="colors"
        :axis="{
            x: {
                key: 'count',
            },
        }"
    >
        <template v-slot:title>
            Recently Created Marketing Campaigns
        </template>
        <template v-slot:subtitle> Last 30 Days </template>
        <template v-slot:footer>
            <vx-button
                size="xs"
                href="/messaging/campaigns"
                outline
                color="muted-light"
            >
                Go to Campaigns
                <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
            </vx-button>
        </template>
    </vx-chart-categorical>
</template>

<script setup>
import {
    VxButton,
    VxChartCategorical,
} from "@voxie/frontend-components";
import { ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import campaignsClient from '../../services/campaignsClient';
import { uniqBy } from '~/utils/helpers';
import { generateLegendColors } from '../../utils/analytics';
import { getClientTz } from '../../utils/date';
import { generalStatusLabel } from '../filters';
import dayjs from '~/utils/dayjs';

const loading = ref(true);
const data = ref([]);
const colors = ref([]);

campaignsClient
    .campaignsCountByStatus(Spark.state.currentTeam.id, {
        start_date: dayjs().tz(getClientTz()).subtract(30, "days").utc().format("YYYY-MM-DD H:m:s"),
        end_date: dayjs().tz(getClientTz()).utc().format("YYYY-MM-DD H:m:s"),
        type: 'marketing',
    })
    .then((response) => {
        data.value = response.data.data.map((item) => ({
            label: generalStatusLabel(item.status),
            count: item.count,
        }));

        colors.value = generateLegendColors(
            uniqBy(data.value, "label").map((item) => item.label)
        );
    })
    .finally(() => {
        loading.value = false;
    });
</script>
