import BaseClient from './BaseClient';

class AdminPhoneNumberPoolsClient extends BaseClient {
    /**
     * @param {Number} teamId
     * @param {String} action Can be 'add' or 'remove'
     * @param {Number} phoneNumberPoolId
     * @param {Array} phoneNumberIds
     */
    addRemovePoolNumbers(teamId, action, phoneNumberPoolId, phoneNumberIds) {
        const url = `/api/admin/teams/${teamId}/phone-number-pools/${phoneNumberPoolId}/${action}-phone-numbers`;
        return this.makeCoreApiRequest(url, 'post', { phone_number_ids: phoneNumberIds });
    }
}

export default new AdminPhoneNumberPoolsClient();
