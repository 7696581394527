<template>
    <div class="u-block u-mb-xsmall u-mt-xsmall">
        <div :class="[checked ? 'is-active' : '', 'c-switch', {'disabled': disabled}]" @click="switchClicked">

            <input class="c-switch__input" :id="id" type="checkbox"
                :checked="checked">

            <label class="c-switch__label" :for="id">{{ label }}</label>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {},
        label: {},
        checked: {},
        disabled: {
            default: false,
            type: Boolean
        }
    },
    emits: ['update:checked'],
    methods: {
        switchClicked(){
            if (this.disabled) {
                return;
            }

            this.$emit('update:checked', !this.checked);
            if (this.id) {
                Bus.$emit(`switch-${this.id}-changed`, !this.checked);
            }
        }
    }
}
</script>
<style scoped>
    .disabled:hover {
        cursor: not-allowed;
    }
</style>
