<template>
    <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
            <th scope="col">Name</th>
            <th scope="col">Teams</th>
            <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
            <tr v-if="cloningTargets.length === 0"><td colspan="3" style="width:100%">No Cloning Targets Found.</td></tr>
            <tr v-for="cloningTarget in cloningTargets" :key="cloningTarget.id">
                <td>{{ cloningTarget.name }}</td>
                <td class="text-sm teams-list-col">
                    <template v-for="(team, key) in cloningTarget.teams" :key="team.id">
                        {{ team.name }}<span v-if="key !== Object.keys(cloningTarget.teams).length - 1">,&nbsp;</span>
                    </template>
                </td>
                <td>
                    <button class="btn btn-outline-primary mr-1" data-test="edit-button" @click="editCloningTarget(cloningTarget.id)">
                        <i class="fa fa-pencil"></i>
                    </button>

                    <button class="btn btn-outline-danger" data-test="delete-button" @click="deleteCloningTarget(cloningTarget.id)">
                        <i class="fa fa-trash"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="form-group">
        <div class="col-md-12">
            <cursor-pagination
                v-model:cursor="cursor"
                :loading="loadingPagination"
                :firstPage="firstPageUrl"
                :nextPage="nextPageUrl"
                :prevPage="prevPageUrl"
                :lastPage="lastPageUrl"
                @change="changeCursor"
            ></cursor-pagination>
        </div>
    </div>
</template>
<script>
import adminCloningTargetsClient from '../../../services/adminCloningTargetsClient';
import cursorPagination from '~/components/general/CursorPagination.vue';
import clientRoutes from './../../../mixins/client-routes';

export default {
    name: 'cloning-targets',
    components: {
        cursorPagination
    },

    props: {
        shouldReload: Boolean,
    },

    mixins: [clientRoutes],

    emits: ['cloningTargetEdited', 'cloningTargetDeleted'],
    data() {
        return {
            teamId: Spark.state.currentTeam.id,
            cloningTargets: [],
            itemsPerPage: 5,

            cursor: null,
            firstPageUrl: null,
            prevPageUrl: null,
            nextPageUrl: null,
            lastPageUrl: null,
            loadingPagination: false,
        };
    },

    watch: {
        shouldReload: function () {
            this.getCloningTargets();
        }
    },

    methods: {
        getCloningTargets() {
            this.loadingPagination = true;
            adminCloningTargetsClient.getCloningTargets(this.itemsPerPage, this.cursor).then(({ data }) => {
                if (data.data.length) {
                    this.cloningTargets = data.data;
                    this.firstPageUrl = data.first_page_url;
                    this.prevPageUrl = data.prev_page_url;
                    this.nextPageUrl = data.next_page_url;
                    this.lastPageUrl = data.last_page_url;

                    this.updateRouting();
                }
            }).catch(error => {
                console.error(error);
                console.error(error.response.data);
                this.$toasted.global.platform_error();
            }).finally(() => {
                this.loadingPagination = false;
            })
        },

        editCloningTarget(cloningTargetId) {
            this.$emit('cloningTargetEdited', cloningTargetId);
        },

        changeCursor() {
           this.getCloningTargets();
        },

        updateRouting() {
            const cursor = this.cursor ? '/' + this.cursor : '';

            this.showClientRoute(`#/cloning${cursor}`);
        },

        deleteCloningTarget(cloningTargetId) {
            if (!window.confirm('Are you sure you want to delete this clonneing target? There is no undo.')) {
                return;
            }

            adminCloningTargetsClient.deleteCloningTarget(cloningTargetId).then(()  => {
                this.$emit('cloningTargetDeleted');
            }).finally(() => {
                this.getCloningTargets();
            })
        }
    },

    mounted() {
        this.getCloningTargets();
    },
}
</script>

<style lang="postcss" scoped>
.table {
    border-collapse: separate;
    border-radius: 6px;
}

.table th,
.table td {
    text-align: center;
    vertical-align: middle;
}

.teams-list-col {
    width: 60%;
}
</style>
