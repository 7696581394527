<template>
    <div>
        <page-header>
            API Destinations
            <experimental-pill class="vx-ml-4 -vx-mt-2" type="alpha"></experimental-pill>
        </page-header>
        <page-controls>
            <template v-slot:left>
                <vx-button-group class="vx-w-full" @click.prevent="fetchParams.sort_dir = fetchParams.sort_dir === 'asc' ? 'desc' : 'asc'; applyFilters()" size="lg">
                    <vx-button
                        @click.prevent
                        class="vx-flex-grow lg:vx-flex-grow-0"
                        type="button"
                        color="white"
                        shadow
                    >
                        <span class="vx-font-normal">Sort: Created at</span>
                    </vx-button>
                    <vx-button
                        @click.prevent
                        type="button"
                        color="white"
                        shadow
                    >
                        <font-awesome-icon
                            :icon="
                                fetchParams.sort_dir === 'asc'
                                    ? faArrowUpWideShort
                                    : faArrowDownWideShort
                            "
                        ></font-awesome-icon>
                    </vx-button>
                </vx-button-group>
            </template>
            <template v-slot:right>
                <vx-popover class="vx-w-full"
                    hover
                    :arrow="true"
                    placement="left">
                    <vx-button
                        block
                        :disabled="!isAdminOrOwner"
                        href="/settings/integrations/api-destinations/new"
                        size="lg"
                        color="primary"
                    >
                        New API Destination
                        <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                    </vx-button>
                    <template v-if="!isAdminOrOwner" v-slot:content>
                        You do not have permission to create.
                    </template>
                </vx-popover>
            </template>
        </page-controls>

        <template v-if="!loading && !list.length">
            <vx-box color="info" class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                No API Destinations yet.
                <vx-popover hover
                    :arrow="true"
                    placement="left">
                    <vx-button
                        :disabled="!isAdminOrOwner"
                        href="/settings/integrations/api-destinations/new"
                    >
                        New API Destination
                        <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                    </vx-button>
                    <template v-if="!isAdminOrOwner" v-slot:content>
                        You do not have permission to create.
                    </template>
                </vx-popover>
            </vx-box>
        </template>

        <vx-table v-else-if="list.length" class="lg:vx-grid-cols-2 xl:vx-grid-cols-[minmax(100px,auto)_auto_minmax(auto,200px)_repeat(4,auto)]" rowBreakpoint="xl">
            <vx-table-row isHeader>
                <div>
                    Name
                </div>
                <div>
                    Method
                </div>
                <div>
                    Hostname
                </div>
                <div>
                    Auth
                </div>
                <div>
                    Automations
                </div>
                <div>
                    Created at
                </div>
            </vx-table-row>

            <api-destination-row
                v-for="(item, index) in list"
                :item="list[index]"
                :key="list[index].id" @deleted="removeFromList($event)"
            ></api-destination-row>

        </vx-table>

        <div
            v-if="list.length"
            class="vx-mt-8 vx-flex vx-justify-between vx-w-full vx-gap-10"
        >
            <vx-button
                v-if="fetchParams.cursor"
                @click="loadData()"
                color="muted-dark"
                size="xl"
                data-test="load-more-btn"
                class="vx-grow-0"
                :loading="loading"
            >
                Load More
            </vx-button>
        </div>
    </div>
</template>


<script setup>
import PageHeader from '../../../general/PageHeader.vue';
import PageControls from '../../../general/PageControls.vue';
import ExperimentalPill from '../../../general/ExperimentalPill.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCirclePlus, faArrowUpWideShort, faArrowDownWideShort } from '@fortawesome/pro-solid-svg-icons';

import { VxButtonGroup, VxButton, VxBox, VxTable, VxPopover, VxTableRow } from '@voxie/frontend-components';
import { onMounted } from 'vue';

import apiDestinationsClient from '../../../../services/apiDestinationsClient';
import ApiDestinationRow from './ApiDestinationRow.vue';
import { useCursorPagination } from '../../../../composables/useCursorPagination';

const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const isAdminOrOwner = Spark.isAdminOrOwner;
const teamId = Spark.state.currentTeam.id;

const defaultFilters = () => ({
    per_page: 15,
    cursor: null,
    sort_dir: 'desc'
});

const resetFilters = () => {
    fetchParams.value = defaultFilters();

    loadData();
};

const applyFilters = () => {
    fetchParams.value = {
        ...fetchParams.value,
        cursor: null,
    }

    loadData();
}

onMounted(() => {
    resetFilters();
});

const loadData = () => {
    fetchRecords(apiDestinationsClient.list(teamId, {...fetchParams.value}));
};

const removeFromList = (itemId) => {
    list.value = list.value.filter((item) => item.id != itemId);

    if (!list.value.length) {
        loadData();
    }
};

</script>
