<template>
    <div
        class="vx-p-4 vx-bg-white vx-rounded-[19px] vx-shadow vx-flex-col vx-justify-start vx-items-start vx-gap-3 vx-inline-flex sm:vx-p-6">
        <div class="vx-self-stretch">
            <div class="vx-flex vx-flex-wrap vx-justify-between vx-gap-1 md:vx-gap-0">
                <div class="vx-justify-start vx-items-center vx-gap-2 vx-inline-flex">
                    <font-awesome-icon v-if="props.icon" :icon="props.icon" class="vx-text-black vx-text-base" />
                    <div class="vx-text-slate-700 vx-text-xl vx-font-extrabold">
                        <slot name="title"></slot>
                    </div>
                </div>
                <slot name="headerRight"></slot>
            </div>
        </div>
        <slot name="content"></slot>
    </div>
</template>
<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const props = defineProps({
    icon: {
        type: Object,
        required: false,
    }
})
</script>
