import { helpers } from '@vuelidate/validators';
import { RESERVED_CUTOM_ATTRIBUTE_KEYS } from './customAttributes';
import dayjs from '~/utils/dayjs';
import { isValidPhoneNumber } from './phoneNumber';

/**
 * @param {string} value
 */
export const csvInjection = helpers.withMessage('Cannot start with @, =, - or +', (value) => {
    if (!value) {
        return true;
    }

    return !(value.startsWith('@') || value.startsWith('=') || value.startsWith('-') || value.startsWith('+'));
});

export const teamCustomAttributeKeyFirstLetter = helpers.withMessage('Cannot start with non-alphanumeric', (value) => {
    if (!value) {
        return true;
    }

    return /[a-zA-Z0-9]/.test(value.charAt(0));
});

export const groupCustomAttributeKey = helpers.withMessage(
    'You can use only alphanumeric letters, and the following symbols: _-',
    helpers.regex(/^[A-Za-z0-9][A-Za-z0-9_-]*$/)
);

export const teamCustomAttributeKey = helpers.withMessage(
    'You can use only alphanumeric letters and the following symbols: _-',
    helpers.regex(/^[a-zA-Z0-9_-]*$/)
);

export const customAttributeKey = helpers.withMessage(
    "You can use only alphanumeric letters, space and the following symbols: _-!#$%&'()*+,./:?@|",
    helpers.regex(/^[a-zA-Z0-9_\- !#$%&'()*+,./:?@|]*$/)
);
export const customAttributeValue = helpers.withMessage(
    "You can use only alphanumeric letters, space and the following symbols: :.@#$%&'*(),!?\\-=_+[]/",
    helpers.regex(/^[a-zA-Z0-9:.@#$%&'*(),!?\-\\=_+[\] /]*$/)
);

export const phoneNumber = helpers.withMessage('Should be a valid phone number', (value) => {
    if (!value) {
        return true;
    }

    return isValidPhoneNumber(value);
});

export const reservedCustomAttributesKeys = helpers.withMessage(
    'Reserved custom attribute keys are not allowed',
    (value) => !RESERVED_CUTOM_ATTRIBUTE_KEYS.includes(value)
);

export const isAfterDate = ({ date = () => new Date(), required = true, message } = {}) => {
    message = typeof message === 'function' ? message() : message?.value || message;

    return helpers.withMessage(message || 'Date must be after now', (value) => {
        if (!required && !value) {
            return true;
        }
        return dayjs(value).isSameOrAfter(typeof date === 'function' ? date() : date.value, 'minutes');
    });
};

export function displayFirstError(errors) {
    if (typeof errors?.[0] === 'string') {
        return errors?.[0];
    }
    if (errors?.$errors !== undefined) {
        errors = errors.$errors;
    }
    return errors?.[0]?.$message;
}
