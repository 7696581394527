<template>
    <modal
        id="modal-assign-conversation"
        v-model:visible="show"
        :title="title"
        submit-text="Done"
        close-text="Cancel"
        size="md"
        @submit="submit"
        @hidden="closePickers"
    >
        <div class="form-group d-flex flex-column flex-grow-1">
            <div class="option-group">
                <label v-for="(option, index) in choices" :key="index" :for="`assign-to-option.${option.key}.${index}`">
                    <input :id="`assign-to-option.${option.key}.${index}`"
                           :key="`assign-to-option.${option.key}.${index}`"
                           name="assign-to-option"
                           :value="option.key"
                           v-model="choice" type="radio"/>
                    {{ option.label }}
                </label>
            </div>
            <div class="selection-box">
                <div class="selection-input" @click="openSelectionDropDown">
                    <div class="selection-item"><span>{{ selectedAgent || `Select a person...` }}</span></div>
                    <i class="selection-arrow">
                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.8931 0.571289H9.88867C9.82036 0.571289 9.75608 0.604771 9.7159 0.659682L5.91099 5.90433L2.10608 0.659682C2.0659 0.604771 2.00161 0.571289 1.93331 0.571289H0.928846C0.841792 0.571289 0.790899 0.670396 0.841792 0.741379L5.56411 7.25165C5.73554 7.48736 6.08644 7.48736 6.25652 7.25165L10.9788 0.741379C11.0311 0.670396 10.9802 0.571289 10.8931 0.571289Z"
                                fill="black" fill-opacity="0.25"></path>
                        </svg>
                    </i>
                </div>
                <ul
                    :class="{
                        'selection-dropdown': true,
                        'd-none': !selectionDropdownActive
                    }"
                >
                    <li v-for="(agent, index) in agentsOptions"
                        :key="`agent-option-${agent.value}-${index}`"
                        @click="selectAgent(agent)">
                        {{ agent.label }}
                    </li>
                </ul>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Swal from 'sweetalert2'
import { contactName } from '../../../../filters'
import Modal from '../../../../general/Modal.vue';

export default {
    name: 'AssignConversationModal',
    components: {
        Modal,
    },
    data () {
        return {
            show: false,
            selectionDropdownActive: false,
            choices: [
                {
                    key: 'mySelf',
                    label: 'Assign to myself'
                },
                {
                    key: 'someOneElse',
                    label: 'Assign to someone else'
                },
            ],
            choice: 'mySelf',
            agent: null
        }
    },
    watch: {
        choice: {
            handler: function (val) {
                this.selectionDropdownActive = false;
                if (val === 'mySelf') {
                    this.setMySelf()
                } else {
                    this.agent = null
                }
            },
            immediate: true
        }
    },
    mounted() {
        Bus.$on('showAssignConversationUi', () => {
            this.show = true;
        })
    },
    computed: {
        ...mapGetters({
            'activeThread': 'getActiveThread',
        }),
        ...mapState({
            selectedContact: (state) => state.contacts_v3.selectedContact,
            agents: (state) => state.threads.agents,
            selectedThreads: (state) => state.threads.selectedThreads,
        }),
        contactFullName () {
            if (this.selectedThreads.length) return ''
            if (this.selectedContact.id) {
                return contactName(this.selectedContact)
            }
            return ''
        },
        title() {
            if (this.selectedThreads.length) {
                return 'Assign conversations to';
            }
            if (this.contactFullName) {
                return `Assign the conversation with ${this.contactFullName} to`;
            }
            return 'Assign the conversation to';
        },
        agentsOptions () {
            const options = [
                {
                    value: 'unassigned',
                    label: 'None (Unassigned)'
                }]

            return [...options,
                ...this.agents
                    .map(a => ({
                        value: a.id,
                        label: a.name
                    }))
                .filter(a => a.value !== Spark.state.user.id)]
        },
        selectedAgent () {
            if (!this.agent) return '';
            return this.agent.name;
        }
    },
    methods: {
        ...mapActions({
            'assignThread': 'assignThread',
            'unAssignThread': 'unAssignThread'
        }),
        openSelectionDropDown () {
            if (this.choice === 'mySelf') return false
            this.selectionDropdownActive = !this.selectionDropdownActive
        },
        setMySelf () {
            this.agent = {
                assignee_id: Spark.state.user.id,
                name: Spark.state.user.name,
            }
        },
        selectAgent (op) {
            if (op === null) return false
            let { value } = op
            this.agent = {
                assignee_id: value,
                name: op.label
            }

            this.$nextTick(() => {
                this.selectionDropdownActive = false;
            })
        },
        closeModal () {
            this.show = false;
        },
        closePickers() {
            this.showTemplatePicker = false;
            this.showEmojiPicker = false;
        },
        submit () {
            if (!this.agent || !this.agent.assignee_id) {
                Swal.fire({
                    title: 'Error',
                    text: 'Please select an agent.',
                    icon: 'error',
                });
                return false
            }
            const assigneeId = this.agent.assignee_id;

            let isUnassigned = this.agent.assignee_id === 'unassigned'
            let promises = []
            if (this.selectedThreads.length) {
                this.selectedThreads.forEach((thread) => {
                    if (isUnassigned) {
                        promises = [...promises, this.unAssignThread({ thread })]
                    } else {
                        promises = [...promises, this.assignThread({ thread, assigneeId: assigneeId })]
                    }
                })
            } else if (this.activeThread && this.activeThread.contact_id) {
                if (isUnassigned) {
                    promises = [...promises, this.unAssignThread({ thread: this.activeThread })]
                } else {
                    promises = [...promises, this.assignThread({ thread: this.activeThread, assigneeId: assigneeId })]
                }
            }

            Promise.all(promises).then(() => {
                this.closeModal()
                const textPrefix = this.selectedThreads.length ? 'Threads were' : 'The thread was'
                if (isUnassigned) {
                    Swal.fire({ title: 'Success', text: `${textPrefix} unassigned successfully.`, icon: 'success' })
                } else {
                    Swal.fire({ title: 'Success', text: `${textPrefix} assigned successfully.`, icon: 'success' })
                }
            }).catch(e => {
                this.$toasted.global.platform_error();

                throw e;
            });
        }
    }
}
</script>

<style scoped>

.form-group {
    width: 100%;
    display: flex;
    justify-content: center;
}

.form-group label.agent-select {
    width: 100%;
}

.v-select ul.dropdown-menu {
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    max-width: 302px !important;
    padding: 4px 0px;
}

label.agent-select {
    padding: 4px 12px;
}

input.form-control {
    /* Neutral/1 */
    background: #FFFFFF;
    /* Neutral/5 */
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 0px;
}

h5.mc-text-bold {
    margin-top: 27px;
    display: flex;
}

div#agent-select {
    width: 100%;
}

input.assign-to-option {
    margin: 0px 8px;
}

.action-buttons-wrapper {
    margin-bottom: 27px;
}
</style>
