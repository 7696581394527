<template>
    <page-controls>
        <template v-slot:left>
            <div class="@3xl/controls:vx-w-1/2 @4xl/controls:vx-max-w-md">
                <vx-filterable :active="active"
                    @toggle="toggleFilterable">
                    <template v-slot:placeholder>
                        <span class="vx-mr-2">Search</span>
                        <vx-badge v-if="filtersCount > 0"
                            :borders="false"
                            size="xs"
                            @click.stop="reset">
                            {{ filtersCount }} filters
                            <font-awesome-icon :icon="faXmarkCircle" />
                        </vx-badge>
                    </template>
                    <template v-slot:form>
                        <form @submit.prevent="apply"
                            class="vx-space-y-4">
                            <div>
                                <vx-label>
                                    Keyword Search
                                </vx-label>

                                <vx-input autofocus
                                    type="text"
                                    data-test="filters-query"
                                    placeholder="Search"
                                    maxlength="191"
                                    v-model="filters.query">
                                    <template v-slot:right>
                                        <font-awesome-icon class="vx-text-xs"
                                            :icon="faMagnifyingGlass" />
                                    </template>
                                </vx-input>
                            </div>
                            <div>
                                <vx-label>
                                    Country
                                </vx-label>

                                <vx-selectable data-test="filters-country"
                                    v-model="filters.iso_country"
                                    :reduce="option => option.value"
                                    :clearable="true"
                                    :options="[
                                        {
                                            label: '🇺🇸 US',
                                            value: 'US',
                                        },
                                        {
                                            label: '🇨🇦 CA',
                                            value: 'CA',
                                        },
                                    ]">
                                </vx-selectable>
                            </div>
                            <div>
                                <vx-label>
                                    Number Type
                                </vx-label>

                                <vx-selectable data-test="filters-number-type"
                                    v-model="filters.number_type"
                                    :reduce="option => option.value"
                                    :clearable="true"
                                    :options="numberTypesOptions()">
                                </vx-selectable>
                            </div>
                            <div>
                                <vx-label>
                                    Sending
                                </vx-label>

                                <div class="vx-flex vx-flex-col vx-gap-2">
                                    <vx-checkbox-selection-item @selected="usedForSending = !usedForSending"
                                        :active="usedForSending">
                                        Used for Sending
                                    </vx-checkbox-selection-item>
                                    <vx-checkbox-selection-item @selected="notUsedForSending = !notUsedForSending"
                                        :active="notUsedForSending">
                                        Not used for Sending
                                    </vx-checkbox-selection-item>
                                </div>
                            </div>



                            <div class="vx-flex vx-gap-2">
                                <vx-button type="button"
                                    color="muted-light"
                                    @click="cancel">Cancel</vx-button>
                                <vx-button data-test="filters-apply"
                                    type="submit"
                                    block
                                    @click.stop="apply">
                                    Apply
                                    <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                                </vx-button>
                            </div>
                        </form>
                    </template>
                </vx-filterable>
            </div>
            <div class="@3xl/controls:vx-w-1/2 @4xl/controls:vx-w-56">
                <vx-button-group size="lg"
                    class="vx-w-full">
                    <vx-select :modelValue="props.modelValue.sort_field"
                        @update:modelValue="
                            updateModelValue('sort_field', $event);
                        emit('apply');
                        "
                        :options="[
                            {
                                value: 'id',
                                label: 'Sort: Created',
                            },
                            {
                                value: 'phone_number',
                                label: 'Sort: Number',
                            },
                        ]"></vx-select>
                    <vx-button @click.prevent="
                        props.modelValue.sort_dir === 'asc'
                            ? updateModelValue('sort_dir', 'desc')
                            : updateModelValue('sort_dir', 'asc');
                    emit('apply');
                    "
                        type="button"
                        color="white"
                        shadow>
                        <font-awesome-icon :icon="props.modelValue.sort_dir === 'asc'
                            ? faArrowUpWideShort
                            : faArrowDownWideShort
                            "></font-awesome-icon>
                    </vx-button>
                </vx-button-group>
            </div>
        </template>

        <template v-if="isImpersonatingOrDeveloper"
            v-slot:right>
            <div class="vx-grid vx-grid-cols-2 vx-gap-4">
                <vx-button size="lg"
                    @click="emit('showHostNumberModal')"
                    color="muted-dark">
                    Add Hosted Number
                    <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
                <vx-button :href="`/settings/teams/${teamId}/phone-numbers/new`"
                    size="lg"
                    color="primary">
                    Add Phone Number
                    <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
            </div>
        </template>

    </page-controls>

</template>

<script setup>
import PageControls from '~/components/general/PageControls.vue';
import { faArrowDownWideShort, faArrowUpWideShort, faCircleCheck, faCirclePlus, faMagnifyingGlass, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBadge, VxButton, VxButtonGroup, VxFilterable, VxInput, VxLabel, VxSelect, VxSelectable, VxCheckboxSelectionItem } from '@voxie/frontend-components';
import { computed, nextTick, ref, watch } from 'vue';
import { numberTypesOptions } from '~/constants/phoneNumbers';

const isImpersonatingOrDeveloper = Spark.isImpersonating || Spark.isDeveloper;

const teamId = Spark.state.currentTeam.id;

const filters = ref({})
const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

const usedForSending = ref(false);
const notUsedForSending = ref(false);
const setRefUsedForSending = () => {
    if (props.modelValue.use_for_sending === 0) {
        usedForSending.value = false;
        notUsedForSending.value = true;
    } else if (props.modelValue.use_for_sending === 1) {
        usedForSending.value = true;
        notUsedForSending.value = false;
    }
}

watch(() => props.modelValue, (modelValue) => {
    filters.value = { ...modelValue };

    setRefUsedForSending();
}, {
    deep: true,
    immediate: true,
});
watch([usedForSending, notUsedForSending], () => {
    if (usedForSending.value && !notUsedForSending.value) {
        filters.value.use_for_sending = 1;
    } else if (!usedForSending.value && notUsedForSending.value) {
        filters.value.use_for_sending = 0;
    } else {
        filters.value.use_for_sending = null;
    }
}, {
    immediate: true,
});

const emit = defineEmits(['update:modelValue', 'apply', 'reset', 'showHostNumberModal']);

const active = ref(false);

const toggleFilterable = (event) => {
    active.value = event;
}

const filtersCount = computed(
    () =>
        (props.modelValue.query ? 1 : 0) +
        (props.modelValue.iso_country ? 1 : 0) +
        (props.modelValue.number_type ? 1 : 0) +
        ((usedForSending.value || notUsedForSending.value) ? 1 : 0)
);

const reset = () => {
    active.value = false;
    usedForSending.value = false;
    notUsedForSending.value = false;
    emit('reset');
}

const cancel = async () => {
    filters.value = { ...props.modelValue }

    setRefUsedForSending();

    active.value = false;
}

const apply = async () => {
    active.value = false;
    emit('update:modelValue', { ...filters.value });
    await nextTick();
    emit('apply');
}

const updateModelValue = (key, value) => {
    emit("update:modelValue", {
        ...props.modelValue,
        [key]: value,
    });
};


</script>
