<template>
    <auth-box class="vx-max-w-[480px]"
        :logo="false">
        <div class="vx-flex vx-flex-col vx-justify-center vx-gap-2 vx-py-10 vx-text-center">
            <div class="vx-flex vx-justify-center">
                <vx-logo-loading></vx-logo-loading>
            </div>
            <div class="vx-text-xl vx-font-bold">
                Redirecting...
            </div>
            <div class="vx-text-slate-500 vx-text-sm">
                This will redirect back to <br />Voxie shortly.
            </div>
        </div>
    </auth-box>
</template>
<script setup>
import { onMounted } from 'vue';
import AuthBox from '../../AuthBox.vue';
import { VxLogoLoading } from '@voxie/frontend-components';

const props = defineProps({
    link: String,
});

onMounted(() => {
    window.location.href = props.link;
})
</script>
