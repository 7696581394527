<template>
    <div>
        <vx-badge v-if="props.disabled" size="xs" color="amber" class="vx-mb-4">
            Locked
            <font-awesome-icon :icon="faLockKeyhole"></font-awesome-icon>
        </vx-badge>
        <div class="vx-text-slate-700 vx-text-base vx-font-bold">Endpoint</div>
        <template v-if="props.disabled">

            <div class="vx-text-sm vx-leading-tight vx-mb-2 vx-text-slate-500">
                This field is locked to prevent breaking related automations.
            </div>
        </template>
        <template v-else>
            <div class="vx-text-sm vx-leading-tight vx-mb-2 vx-text-slate-500">
                The URI template for the service you are connecting to. If you need help adding URL variables
                <a href="#" class="!vx-text-sky-700 vx-underline"> check out this
                    documentation</a>.
            </div>
        </template>

        <vx-input size="lg" placeholder="https://example.com" autoresize :disabled="props.disabled" :error="props.error"
            :modelValue="props.modelValue" @update:modelValue="updateModelValue">
            <template v-slot:box>
                <font-awesome-icon :icon="props.disabled ? faLockKeyhole : faCodeSimple"></font-awesome-icon>
            </template>
        </vx-input>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCodeSimple, faLockKeyhole } from "@fortawesome/pro-solid-svg-icons";
import {
    VxInput,
    VxBadge,
} from "@voxie/frontend-components";

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    error: {
        type: String,
        default: undefined,
    },
})

const emit = defineEmits(['update:modelValue']);

const updateModelValue = ($event) => {
    if (props.disabled) {
        return;
    }

    emit('update:modelValue', $event);
}
</script>
