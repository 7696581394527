<template>
    <card :title="title">
        <form role="form" @submit.prevent="$emit('save')">
            <slot />
            <div v-if="!noSaveBtn" class="text-right">
                <button type="submit" class="btn btn-primary" :disabled="saving">
                    {{ saveBtnText }}
                </button>
            </div>
        </form>
    </card>
</template>

<script>
import Card from './Card.vue';

export default {
    components: { Card },
    props: {
        title: String,
        saveBtnText: {
            type: String,
            default: 'Save',
        },
        saving: {
            type: Boolean,
            default: false,
        },
        noSaveBtn: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
