<template>
    <alert type="warning" :text="text" />
</template>

<script>
import Alert from './Alert.vue';

export default {
    components: { Alert },
    props: {
        text: {
            type: String,
            default: 'No results found.'
        }
    },
}
</script>
