<template>
  <div class="ds-v2-contact-title">
    <div class="ds-v2-contact-title__left-side">
      <button
        class="
          d-inline-block d-md-none
          btn btn-reset
          text-gray-600
          border-0
          shadow-none
        "
        @click.prevent="unselectThread"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
            fill="black"
          />
        </svg>
      </button>
      <template v-if="selectedContact.id">
        <h3 v-if="contactTitle.value" class="vx-heading vx-heading--level-3" data-test="contact-title-value">{{ contactTitle.value }}</h3>
        <vx-text
          v-if="selectedContact.phone && contactTitle.type !== 'phone'"
          :level="2"
          color="secondary"
          secondary
          >{{ selectedContactPhoneFormatted }}</vx-text
        >
      </template>
    </div>
    <div class="ds-v2-contact-title__right-side">
      <div @click="showProfileSection">
        <vx-button size="medium" appearance="transparent-primary-outline">
          View Profile
        </vx-button>
      </div>

      <vx-dropdown
        v-if="!FEATURES.simple_message_hub"
        class="ds-v2-contact-title__actions-dropdown"
        placement="right"
      >
        <vx-button appearance="primary-outline" size="medium" right="tick">Actions</vx-button>
        <template v-slot:items>
          <vx-dropdown-item v-if="activeThread && activeThread.status === 'open'">
            <div @click="showAssignConversationUi">
              <i class="fa fa-user-plus"></i> Assign To
            </div>
          </vx-dropdown-item>
          <vx-dropdown-item v-if="isMyInbox">
            <div @click="unassign">
              <i class="fa fa-ban"></i> Unassign
            </div>
          </vx-dropdown-item>
          <vx-dropdown-item v-if="!isClosedInbox">
            <div @click="deleteCheckpoint">
              <i class="fa fa-eye-slash"></i> Mark as Unread
            </div>
          </vx-dropdown-item>
        <vx-dropdown-item>
            <button-confirm
                    @confirmed="changeStatus(shouldShowOpenAction ? 'open' : 'closed')"
                    :text="`Do you agree with this action?`"
                    :confirm-button-text="`Yes, ${shouldShowOpenAction ? 'open' : 'close'} thread`">
                    <i class="fa" :class="[shouldShowOpenAction ? 'fa-folder-open' : 'fa-ban']"></i> {{ shouldShowOpenAction ? 'Open' : 'Close' }}
            </button-confirm>
        </vx-dropdown-item>
        </template>
      </vx-dropdown>
    </div>
  </div>
</template>

<script setup>
import {
  VxButton,
  VxText,
  VxDropdown,
  VxDropdownItem,
} from "@voxie/frontend-components-v3";
import ButtonConfirm from '../../general/ButtonConfirm.vue';
import Swal from 'sweetalert2';
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import { phoneNumberNational } from '../../filters';
import { useToasted } from '../../../composables/useToasted'; // your path

const emit = defineEmits(["showProfileSection", "changeStatus", "unassign"]);
const props = defineProps({
  selectedContact: {
    type: Object,
    required: true,
  },
});

const store = useStore();
const $toasted = useToasted();
const FEATURES = inject('FEATURES');
// Commputed.

const activeThreadsInbox = computed(
  () => store.state.threads.activeThreadsInbox
);

const activeThread = computed(() => store.getters.getActiveThread);

const contactTitle = computed(() => {
  if (props.selectedContact) {
    if (props.selectedContact.first_name && props.selectedContact.last_name) {
      return {
        type: "name",
        value: `${props.selectedContact.first_name} ${props.selectedContact.last_name}`,
      };
    } else if (
      props.selectedContact.first_name ||
      props.selectedContact.last_name
    ) {
      return {
        type: "name",
        value: `${
          props.selectedContact.first_name || props.selectedContact.last_name
        }`,
      };
    } else if (props.selectedContact.phone) {
      return {
        type: "phone",
        value: props.selectedContact.phone,
      };
    }
  }
  return {
    type: null,
    value: null,
  };
});

const selectedContactPhoneFormatted = computed(() => {
  return phoneNumberNational(props.selectedContact.phone);
});

const isClosedInbox = computed(() => {
  return activeThreadsInbox.value === "closed";
});

const shouldShowOpenAction = computed(() => {
  return (
    isClosedInbox.value ||
    (activeThread.value && activeThread.value.status === "closed")
  );
});

const isMyInbox = computed(() => {
  return activeThreadsInbox.value === "me";
});

// Methods
const deleteCheckpoint = () => {
  store
    .dispatch("destroyCheckpoint", { thread: activeThread.value })
    .then(() => {
      Swal.fire({
        title: "Success",
        text: `The thread was marked as unread successfully.`,
        icon: "success",
      });
    })
    .catch((e) => {
      $toasted.global.platform_error();
      throw e;
    });
};

const changeStatus = (status) => {
  store
    .dispatch("changeState", { thread: activeThread.value, state: status })
    .then(() => {
      emit("changeStatus");
      Bus.$emit("threadStatusChanged");

      Swal.fire({
        title: "Success",
        text: `The thread was ${status} successfully.`,
        icon: "success",
      });
    });
};

const unassign = () => {
  store
    .dispatch("unAssignThread", { thread: activeThread.value })
    .then(() => {
      emit("unassign");

      Swal.fire({
        title: "Success",
        text: `The thread was unassigned successfully.`,
        icon: "success",
      });
    })
    .catch((e) => {
      $toasted.global.platform_error();

      throw e;
    });
};

const unselectThread = () => {
  store.commit("contacts_v3/unselectContact");
  store.commit("setActiveThread", null);
};

const showProfileSection = () => emit("showProfileSection");

const showAssignConversationUi = () => {
  Bus.$emit("showAssignConversationUi");
};
</script>

<style lang="postcss">
.ds-v2-contact-title {
  align-items: center;
  border-bottom: 1px solid #f0f2f5;
  display: flex;
  justify-content: space-between;
  min-height: 55px;
  padding: 7px;
}
.ds-v2-contact-title__right-side {
  display: flex;
}
.ds-v2-contact-title__left-side {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.ds-v2-contact-title__left-side h3 {
  font-weight: 600;
}
.ds-v2-contact-title__left-side > *, .ds-v2-contact-title__right-side > * {
  margin: 0 11px 0 11px;
}
.ds-v2-contact-title__right-side > :last-child {
  margin-right: 28px;
}
.ds-v2-contact-title__actions-dropdown .vx-dropdown-item div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.ds-v2-contact-title__actions-dropdown .vx-dropdown-item button {
  width: 100%;
  height: 100%;
  padding: unset;
  text-align: left;
  font-family: inherit;
  outline: none;
  border: none;
  background: none;
}
.ds-v2-contact-title .vx-dialog__heading, .ds-v2-contact-title .vx-dialog__message, .ds-v2-contact-title .vx-dialog__buttons {
  display: flex;
  justify-content: center;
}
.ds-v2-contact-title .fa {
  font-size: 14px;
  margin-right: 10px;
}
</style>
