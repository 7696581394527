<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        :padding="false"
        size="md">
        <template v-slot:header>
            <div class="vx-px-6 vx-pt-6">
                Media
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">
                Attach images or videos to your message.
            </div>
        </template>

        <div class="vx-p-6 vx-flex vx-flex-col vx-gap-4">
            <input type="file"
                id="media-input"
                class="vx-absolute vx-opacity-0"
                @change="fileChange">
            <label v-if="!url"
                data-test="media-drop"
                for="media-input"
                class="vx-w-80 vx-max-w-full vx-mx-auto vx-px-8 vx-py-16 vx-bg-slate-50 vx-rounded-2xl vx-border-dashed vx-border-2 vx-border-slate-300 vx-flex-col vx-justify-center vx-items-center vx-gap-2 vx-flex vx-cursor-pointer vx-mb-0"
                :class="{
                    'vx-border-sky-200 vx-bg-white': dragging,
                }"
                @dragenter.prevent.stop="dragging = true"
                @dragover.prevent.stop="dragging = true"
                @dragleave.prevent.stop="dragging = false"
                @drop.prevent.stop="dropped">
                <font-awesome-icon v-if="!uploading"
                    :icon="faImage"
                    class="vx-text-sky-400 vx-text-5xl">
                </font-awesome-icon>
                <vx-icon-loading v-else
                    class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
                <div class="vx-text-center [text-wrap:balance] vx-text-slate-700 vx-text-sm vx-font-normal">
                    <template v-if="uploading">
                        Uploading...
                    </template>
                    <template v-else>
                        Drag media here or click to open file browser.
                    </template>
                </div>
            </label>
            <div v-if="url"
                class="vx-w-96 vx-max-w-full vx-mx-auto">
                <message-builder-media-preview :mediaUrl="url"
                    :mediaContentType="contentType"
                    @remove="remove()"
                    removeable>
                </message-builder-media-preview>
            </div>
            <div class="vx-text-center vx-text-slate-500 vx-text-sm">
                Max file size is 1MB. <br />
                Only 1 item can be attached to a message.
                <template v-if="url">
                    <br />
                    Remove existing file to add a new one.
                </template>
            </div>
            <vx-alert v-if="error"
                :closeable="false"
                color="danger"
                class="vx-whitespace-pre-line">{{ error }}</vx-alert>
            <vx-alert v-if="warning"
                color="info"
                :closeable="false">
                {{ warning }}
            </vx-alert>
        </div>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click.prevent="emit('update:visible', false)"
                    color="muted"
                    size="lg"
                    type="button">
                    Cancel
                    <font-awesome-icon :icon="faCircleX"></font-awesome-icon>
                </vx-button>
                <vx-button @click.prevent="save()"
                    type="button"
                    color="primary"
                    size="lg"
                    class="vx-grow"
                    :loading="uploading">
                    Save
                    <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import { VxButton, VxModal, VxAlert, VxIconLoading } from '@voxie/frontend-components';
import { nextTick, ref, watch } from 'vue';
import messageMediaClient from '../../../../services/messageMediaClient';
import { faImage, faCircleX, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { HTTP_UNPROCESSABLE_ENTITY } from '../../../../support/HttpStatuses';
import MessageBuilderMediaPreview from './MessageBuilderMediaPreview.vue';
import ImageBlobReduce from 'image-blob-reduce';
import Heic2Any from 'heic2any'

const props = defineProps({
    visible: Boolean,
    mediaUrl: {
        type: String,
        required: false,
    },
    mediaContentType: {
        type: String,
        required: false,
    },
});

const emit = defineEmits(['update:visible', 'update:mediaUrl', 'update:mediaContentType']);

const dragging = ref(false);
const uploading = ref(false);
const teamId = Spark.state.currentTeam.id;

const error = ref('');
const warning = ref('');
const url = ref(undefined);
const contentType = ref(undefined);

const dropped = (event) => {
    if (event.dataTransfer.files[0]) {
        upload(event.dataTransfer.files[0])
    }
    dragging.value = false;
}

const fileChange = (event) => {
    if (event.target.files[0]) {
        upload(event.target.files[0]);
        event.target.value = '';
    }
}

const imageDimensions = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                resolve([img.width, img.height]);
            }
            img.onerror = (error) => {
                reject(error)
            }
            img.src = event.target.result;
        }
        reader.onerror = (error) => {
            reject(error)
        }
        reader.readAsDataURL(file);
    })
}

const halveImageDimension = async (file) => {
    const [width] = await imageDimensions(file);

    const resizedWidth = width / 2;
    const reducer = new ImageBlobReduce()
    const result = await reducer.toBlob(file, { max: resizedWidth })

    const resizedFile = new File([result], file.name, {
        type: file.type,
    });

    return resizedFile;
}

const reduceImageQuality = async (file, quality) => {
    if (quality && (quality > 1 || quality < 0.1)) {
        throw new Error('reduceImageQuality(): quality should be between 0.1-1.0. Provided: ' + quality)
    }
    const reducer = new ImageBlobReduce()

    reducer._create_blob = function (env) {
        return this.pica.toBlob(env.out_canvas, file.type, quality)
            .then(function (blob) {
                env.out_blob = blob;
                return env;
            });
    };
    const result = await reducer.toBlob(file)

    return new File([result], file.name, {
        type: file.type,
    });
}

const inTargetSizeRange = (size) => {
    return ((size / 1000) < 600) && ((size / 1000) > 500);
}
/**
 * @param {File} file
 */
const convertHeicToJpeg = async (file) => {
    const blob = await Heic2Any({
        blob: file,
        toType: 'image/jpeg',
    });

    // if heic ended up being bigger than 600KB resize it
    if (blob.size / 1000 > 600) {
        return resizeImage(blob);
    }
    return blob;
}

/**
 * Resize image to find the best quality option between 500KB and 600KB
 * @param {File} originalFile
 */
const resizeImage = async (originalFile) => {
    let processed = 0;

    // files that were processed and are smaller than 600KB
    const attempts = [];

    const processQuality = async (file, attempt = 1, lowQuality = 0.6, highQuality = 1) => {
        if (processed > 10) {
            // avoids iterating more than 10 times
            return;
        }
        processed++;
        // the first attempt we try to reduce it to 0.6, then on each attempt
        // we reduce the quality until we find the best image size
        const quality = attempt === 1 ? 0.6 : (lowQuality + highQuality) / 2;
        const reducedQuality = await reduceImageQuality(file, quality);

        // when its the first attempt and the image size is bigger
        // than 600KB, we stop reducing the quality and let
        // the processImage halve the image dimension
        if (attempt === 1 && (reducedQuality.size / 1000) > 600) {
            return;
        }

        if (inTargetSizeRange(reducedQuality.size)) {
            // found correct size quality, aborts the process
            attempts.push(reducedQuality);
            return;
        }

        if (reducedQuality.size / 1000 < 500) {
            if (highQuality - lowQuality > 0.05) {
                // Too small, increase quality
                await processQuality(file, attempt + 1, quality, highQuality);
            } else {
                attempts.push(reducedQuality); // Close enough
            }
        } else {
            // Too large, decrease quality
            await processQuality(file, attempt + 1, lowQuality, quality);
        }
    }

    const processImage = async (file) => {
        if (processed > 10) {
            // avoids iterating more than 10 times
            return;
        }

        await processQuality(file);

        if (attempts.length > 0) {
            return;
        }

        // when it wasn't possible to keep the dimension and reduce the quality
        // we halve the image dimension and process it again
        const halvedSize = await halveImageDimension(file);
        await processImage(halvedSize);
    }

    await processImage(originalFile);

    if (!attempts.length) {
        throw new Error('Could not resize the image to be smaller than 600KB.')
    }

    // picks the attempt that has the highest size
    return attempts.sort((a, b) => b.size - a.size)[0];
}

const upload = async (file) => {
    if (uploading.value) {
        return;
    }
    try {
        uploading.value = true;
        await processUpload(file)
    } finally {
        uploading.value = false;
    }
}

/**
 * @param {File} file
 */
const processUpload = async (file) => {
    const staticImage = ['image/jpeg', 'image/png'].includes(file.type);
    const heicImage = 'image/heic' === file.type;
    const biggerThan600KB = file.size / 1000 > 600;
    const smallerThan1MB = file.size / 1000 / 1000 <= 1;

    if (!smallerThan1MB && !staticImage && !heicImage) {
        error.value = 'Max file size is 1MB.';
        return;
    }

    if (file.size / 1000 / 1000 > 20) {
        error.value = 'The source image must not be more than 20MB.'
        return;
    }

    try {
        error.value = '';

        const formData = new FormData();

        if (heicImage) {
            const blob = await convertHeicToJpeg(file);
            // removes .heic extension and add .jpeg
            const fileName = `${file.name.slice(0, -5)}.jpeg`;
            formData.append('file', blob, fileName)

            warning.value = 'The image has been automatically converted from HEIC format to JPEG and resized to be under 600KB.';
        } else if (biggerThan600KB && staticImage) {
            const blob = await resizeImage(file);
            formData.append('file', blob, file.name)

            warning.value = 'The image has been automatically resized to be under 600KB.';
        } else {
            formData.append('file', file);
        }

        const response = await messageMediaClient.uploadMedia(teamId, formData)
        url.value = response.data.url;
        contentType.value = file.type;

        if (biggerThan600KB && !staticImage && !heicImage) {
            warning.value = 'The attachment exceeds 600KB. Please note that it may fail to be delivered, and you may still be charged. We recommend replacing the attachment with something smaller than 600KB in size to ensure successful delivery and avoid potential charges.';
        }
    } catch (e) {
        console.error(e);
        if (e?.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
            error.value = e.response.data?.errors?.file?.[0] || 'The file failed to upload.';
        } else if (e?.message?.toLowerCase?.()?.includes('fingerprinting protection')) {
            error.value = 'It was not possible to resize the image due to fingerprinting protection. Upload an image smaller than 600KB.';
        } else {
            error.value = 'The file failed to upload. Max file size is 1MB.';
        }
    }
}

const remove = () => {
    error.value = '';
    warning.value = '';
    url.value = undefined;
    contentType.value = undefined;
}

const save = async () => {
    if (uploading.value) {
        return;
    }

    emit('update:mediaContentType', contentType.value);
    await nextTick();
    emit('update:mediaUrl', url.value);
    emit('update:visible', false)
}

watch(() => props.visible, () => {
    uploading.value = false;
    dragging.value = false;
    error.value = '';
    warning.value = '';
    url.value = props.mediaUrl;
    contentType.value = props.mediaContentType;
});
</script>
