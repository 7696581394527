<template>
    <page-container class="vx-grid vx-grid-cols-12 vx-gap-8">
        <settings-sidebar></settings-sidebar>
            <!-- Tab cards -->
            <div ref="content" class="vx-col-span-12 md:vx-col-span-8 lg:vx-col-span-10">
                <shopify-integration v-if="hash === 'shopify' || !hash" :ownsTeam="isAdminOrOwner" :isTeamAdmin="isAdminOrOwner" />

                <contact-collector v-if="hash === 'contact-collector'" :ownsTeam="isAdminOrOwner" :isTeamAdmin="isAdminOrOwner" />

                <salesforce-integration v-if="hash === 'salesforce'" :ownsTeam="isAdminOrOwner" />

                <ai-configuration v-if="hash === 'ai-integration' && (FEATURES.message_hub_ai || FEATURES.quick_blast_ai)" :ownsTeam="isAdminOrOwner" />

                <auth-configs-list v-if="hash === 'auth-configs' && FEATURES.api_destinations" />

                <api-destinations-list v-if="hash === 'api-destinations' && FEATURES.api_destinations" />
            </div>
    </page-container>
</template>

<script setup>
import SalesforceIntegration from './SalesforceIntegration.vue';
import ShopifyIntegration from './ShopifyIntegration.vue';
import ContactCollector from './ContactCollector.vue';
import AiConfiguration from './ai-configuration/AiConfiguration.vue';
import ApiDestinationsList from './api-destinations/ApiDestinationsList.vue';
import AuthConfigsList from './auth-configs/AuthConfigsList.vue';
import PageContainer from '~/components/general/PageContainer.vue';
import SettingsSidebar from '../menus/SettingsSidebar.vue';
import { ref, inject, computed, watch, nextTick } from 'vue';
import { useBrowserLocation } from '@vueuse/core'

const FEATURES = inject('FEATURES');

const location = useBrowserLocation();

const hash = computed(() => location.value.hash?.replace('#/', '').replace('#', ''));

const content = ref();
watch(hash, async () => {
    await nextTick();
    if (!content.value || window.innerWidth >= 768) {
        return;
    }
    content.value.scrollIntoView({ behavior: 'smooth', block: 'start' });
}, { immediate: true })

const isAdminOrOwner = Spark.isAdminOrOwner;
</script>
