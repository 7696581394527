<template>
    <conversation-nav class="vx-justify-between">
        <div class="vx-flex vx-flex-col vx-items-start">
            <div class="vx-flex vx-flex-col vx-items-start">
                <experimental-pill type="alpha"></experimental-pill>
                <span class="vx-text-xl vx-text-white vx-font-semibold">{{ contactTitle }}</span>
            </div>
            <span v-if="props.contact.first_name || props.contact.last_name"
                class="vx-text-sm vx-text-white vx-font-semibold">{{ phoneNumberNational(props.contact.phone) }}</span>
        </div>
        <vx-button color="transparent" outline @click="emit('open:contactDetails')">
            <font-awesome-icon :icon="faInfoCircle" size="2xl" inverse />
        </vx-button>
    </conversation-nav>
</template>
<script setup>
import { computed } from 'vue';
import { phoneNumberNational } from '~/components/filters';
import { VxButton } from '@voxie/frontend-components';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ConversationNav from '~/components/messages/browser-extension/components/ConversationNav.vue'
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';

const props = defineProps({
    contact: {
        type: Object,
        required: true,
    }
});

const emit = defineEmits(['open:contactDetails']);

const contactTitle = computed(() => {
    if (props.contact.first_name && props.contact.last_name) {
        return `${props.contact.first_name} ${props.contact.last_name}`
    } else if (
        props.contact.first_name ||
        props.contact.last_name
    ) {
        return `${props.contact.first_name || props.contact.last_name}`
    }

    return phoneNumberNational(props.contact.phone)
});
</script>
