import BaseClient from './BaseClient';

class CustomDeliveriesClient extends BaseClient {
    list(teamId, originType, originId, params) {
        return this.makeCoreApiRequest(
            `${this.apiBase}/teams/${teamId}/custom-deliveries/${originType}/${originId}`,
            'get',
            null,
            params
        );
    }

    get(teamId, originType, originId, deliveryId) {
        return this.makeCoreApiRequest(
            `${this.apiBase}/teams/${teamId}/custom-deliveries/${originType}/${originId}/${deliveryId}`,
            'get'
        );
    }

    getRequest(teamId, originType, originId, deliveryId) {
        return this.makeCoreApiRequest(
            `${this.apiBase}/teams/${teamId}/custom-deliveries/${originType}/${originId}/${deliveryId}/request`,
            'get'
        );
    }

    attemptsList(teamId, originType, originId, deliveryId, params) {
        return this.makeCoreApiRequest(
            `${this.apiBase}/teams/${teamId}/custom-deliveries/${originType}/${originId}/${deliveryId}/attempts`,
            'get',
            null,
            params
        );
    }

    getAttemptResponse(teamId, originType, originId, deliveryId, attemptId) {
        return this.makeCoreApiRequest(
            `${this.apiBase}/teams/${teamId}/custom-deliveries/${originType}/${originId}/${deliveryId}/attempts/${attemptId}/response`,
            'get'
        );
    }
}

export default new CustomDeliveriesClient({
    apiBase: window.Spark.apiBase,
});
