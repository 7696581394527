<template>
    <div>
        <alert type="danger" text="Failed to load data." />
        <div class="text-right mt-3">
            <button class="btn btn-outline-primary" type="button" @click="$emit('reload')">
                Reload
            </button>
        </div>
    </div>
</template>

<script>
import Alert from './Alert.vue';

export default {
    components: { Alert },
};
</script>
