<template>
    <div>
        <toggle-action-data v-model="data.use_event_custom_attribute"
            :open="!data.use_event_custom_attribute"
            class="vx-mb-2">
            <template v-slot:label>
                Use <strong>custom attribute</strong> value from trigger event
            </template>

            <vx-label>Key</vx-label>
            <select-custom-attribute-key data-test="custom-attribute-key"
                size="lg"
                v-model="data.attribute_name"
                :error="displayFirstError(validate.attribute_name)"></select-custom-attribute-key>

        </toggle-action-data>

        <use-event-contact-action-data v-model="data"></use-event-contact-action-data>
    </div>
</template>
<script setup>
import { VxLabel } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf } from '@vuelidate/validators';
import SelectCustomAttributeKey from '~/components/general/SelectCustomAttributeKey.vue';
import { displayFirstError } from '~/utils/validation';
import ToggleActionData from './ToggleActionData.vue';
import UseEventContactActionData from './UseEventContactActionData.vue';

const data = defineModel();

if (data.value.use_event_custom_attribute == undefined) {
    data.value.use_event_custom_attribute = false;
}

const validate = useVuelidate({
    attribute_name: { requiredIf: helpers.withMessage('Key is required', requiredIf(() => !data.value.use_event_custom_attribute)) },
}, data, {
    $autoDirty: true,
})

</script>

