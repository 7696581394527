<template>
    <vx-modal size="xl"
        :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        :padding="false"
        :stacked="!!zoom"
    >
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6">
                Playbook Gallery
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">Choose one of our templates as a starting place for your flow.</div>
        </template>
        <div class="vx-p-4 vx-grid vx-grid-cols-1 vx-gap-8 vx-gap-y-10 sm:vx-grid-cols-2 xl:vx-grid-cols-3 lg:vx-p-10 ">
            <playbook-card v-for="item in playbookGallery" :key="item.type" :disabled="isPlaybookDisabled(item)">
                <template v-slot:type>{{ item.badge }}</template>
                <template v-slot:title>{{ item.name }}</template>
                <template v-slot:description>{{ item.description }}</template>
                <template v-slot:footer>
                    <vx-popover
                        class="vx-w-full vx-text-center"
                        :hover="true"
                        arrow
                        placement="top">
                        <vx-button
                            :href="`/automation/flows/new?playbook=${item.type}`"
                            size="md"
                            :disabled="isPlaybookDisabled(item)"
                            block>
                            Select
                        </vx-button>
                        <template v-if="isPlaybookDisabled(item)" v-slot:content>
                            You already have a version of this Playbook.
                        </template>
                    </vx-popover>

                </template>

                <div class="vx-mb-8">
                    <component :is="item.art"></component>

                    <vx-button @click.prevent="zoom = item" class="!vx-absolute vx-right-4 vx-bottom-4" size="sm" color="muted" type="button">
                        Zoom <font-awesome-icon :icon="faMagnifyingGlassPlus"></font-awesome-icon>
                    </vx-button>
                </div>
            </playbook-card>
        </div>
    </vx-modal>

    <vx-modal size="xl"
        :visible="!!zoom"
        @update:visible="zoom = undefined"
        :padding="false"
    >
        <div class="vx-p-6 bg-dots vx-bg-slate-100">
            <div class="vx-text-center vx-text-xs vx-text-slate-800">Preview: {{ zoom.name }}</div>
            <div class="vx-@container vx-min-h-[60vh] vx-w-full vx-flex vx-items-center vx-justify-center">
                <component :is="zoom.art"></component>
            </div>
        </div>
    </vx-modal>
</template>

<script setup>
import { VxModal, VxButton, VxPopover } from '@voxie/frontend-components';
import PlaybookCard from './PlaybookCard.vue';
import { playbookGallery } from './playbook';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMagnifyingGlassPlus } from '@fortawesome/pro-solid-svg-icons';
import { shallowRef } from 'vue';
import { FLOWS_LIMIT } from './playbook';

const props = defineProps({
    visible: {
        type: Boolean,
        required: true,
    },
    list: {
        type: Array,
        default: () => ([]),
    }
});

const zoom = shallowRef(undefined);

const emit = defineEmits(['update:visible']);

const isPlaybookDisabled = (playbookCard) => {
    return props.list.length >= FLOWS_LIMIT ||
        !!('compliance' === playbookCard.type && props.list.some((flow) => 'compliance' === flow.playbook));
}
</script>

<style scoped lang="postcss">
:deep(.bg-dots), .bg-dots {
  background-image: radial-gradient(rgba(0, 0, 0, 0.08) 1.5px, transparent 0);
  background-size: 24px 24px;
  background-position: 50% 50%;
}
</style>
