<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        :padding="false"
        size="sm">
        <template v-slot:header>
            <div class="vx-px-12 vx-pt-12">
                Rename Section
            </div>
        </template>

        <div class="vx-font-sans vx-pt-4 vx-px-12 vx-pb-12">
            <vx-input ref="inputRef" :modelValue="props.modelValue"
                @update:modelValue="emit('update:modelValue', $event)"
                size="lg"
                @keyup.enter="update"
                :error="displayFirstError(validation.name.$errors)"></vx-input>

            <vx-button @click.prevent="update"
                :loading="submitting"
                data-test="save-button"
                block
                type="button"
                size="lg"
                color="primary"
                class="vx-mt-4 lg:vx-mt-6">
                Update
            </vx-button>
        </div>
    </vx-modal>
</template>

<script setup>
import { VxButton, VxInput, VxModal } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { helpers, maxLength, required } from '@vuelidate/validators';
import { computed, nextTick, ref, watch } from 'vue';
import { useToasted } from '~/composables/useToasted';
import { displayFirstError } from '~/utils/validation';
import { useCustomFlow } from './useCustomFlow';
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';

const props = defineProps({
    visible: {
        type: Boolean,
        required: true,
    },
    modelValue: {
        type: String,
        required: true,
    }
});

const toasted = useToasted();
const emit = defineEmits(["update:visible", "update:modelValue"]);

const inputRef = ref();
watch(() => props.visible, async () => {
    await nextTick();
    if (props.visible) {
        inputRef.value.field.focus();
    }
})

const submitting = ref(false);

const validation = useVuelidate({
    name: {
        required,
        maxLength: maxLength(80),
        regex: helpers.withMessage('You can only use alphanumeric, scores and dashes.', helpers.regex(/^[a-zA-Z0-9][a-zA-Z0-9 _-]*$/)),
    }
}, computed(() => ({ name: props.modelValue })), {
    $autoDirty: true,
});

const { saveSections, sections } = useCustomFlow();

const update = async () => {
    const isValid = await validation.value.$validate();
    if (submitting.value || !isValid) {
        return;
    }

    try {
        submitting.value = true;
        await saveSections(sections.value);

        emit('update:visible', false);
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
        } else {
            toasted.error(e.response?.data?.message || 'Something went wrong.');
        }
    } finally {
        submitting.value = false;
    }
};

</script>
