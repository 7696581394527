<template>
    <analytics-marketing-subscriptions-total-opt-ins
        :startDate="startDate"
        :endDate="endDate"
        class="vx-col-span-12 sm:vx-col-span-6 lg:vx-col-span-4"
    >
        <template v-slot:subtitle> Last 30 days </template>
    </analytics-marketing-subscriptions-total-opt-ins>
    <analytics-outbound-messages-sent-by-type
        class="vx-col-span-12 sm:vx-col-span-6 lg:vx-col-span-4"
        :startDate="startDate"
        :endDate="endDate"
    >
        <template v-slot:subtitle> Last 30 days </template>
    </analytics-outbound-messages-sent-by-type>

    <vx-box class="vx-p-6 vx-flex vx-flex-col vx-justify-between vx-gap-2 vx-col-span-12 lg:vx-col-span-4">
        <div>
            <h3 class="vx-text-base vx-font-extrabold vx-text-slate-800 vx-mb-1">More Analytics</h3>
            <p class="vx-text-xs vx-font-normal vx-text-slate-400">
                Visit the analytics page for more in depth coverage.
            </p>
        </div>

        <vx-button href="/analytics/dashboard" class="vx-mt-2 lg:vx-mt-0" size="lg" block color="primary">
            Analytics
            <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
        </vx-button>
    </vx-box>
</template>

<script setup>
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBox, VxButton } from '@voxie/frontend-components';

import AnalyticsMarketingSubscriptionsTotalOptIns from '~/components/analytics/charts/time-series/AnalyticsMarketingSubscriptionsTotalOptIns.vue';
import AnalyticsOutboundMessagesSentByType from '~/components/analytics/charts/time-series/AnalyticsOutboundMessagesSentByType.vue';

import { getDateRange } from '../../utils/analytics';

const { start_date: startDate, end_date: endDate } = getDateRange('last_30_days');
</script>
