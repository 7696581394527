<template>
    <modal
        v-model:visible="visibleForModal"
        class="modal-suspend-confirm"
        title="Warning!"
        submit-text="Suspend"
        close-text="Cancel"
        @submit="suspendConfirm"
    >
        <p class="modal-suspend-confirm__text">
            Are you sure you want to proceed? By Suspending this Account, the following will happen:
        </p>

        <ul class="modal-suspend-confirm__list">
            <li>
                All current Owners and Members will see their capabilities drastically diminished,
                they'll have read only capabilities mostly.
            </li>
            <li>
                All outbound messaging capabilities will be stopped.
            </li>
        </ul>
    </modal>
</template>
<script>
import Modal from '../../general/Modal.vue';

export default {
    components: {
        Modal
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        confirm: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            visibleForModal: false
        }
    },
    watch: {
        visible: function(value) {
            this.visibleForModal = value
        },
        visibleForModal: function(value) {
            this.$emit('update:visible', value);
        },
    },
    methods: {
        suspendConfirm() {
            this.$emit('update:visible', false);
            this.$emit('update:confirm', true);
        },
    }
}
</script>

<style lang="postcss" scoped>
.modal-suspend-confirm__text {
    margin-bottom: 1rem;
}

.modal-suspend-confirm__list {
    list-style-type: disc;
    padding-inline-start: 1rem;
    font-size: 0.875rem;
}

.modal-suspend-confirm__list>li {
    margin-bottom: 1rem;
}
</style>
