import Papa from 'papaparse';

const ERRORS_MESSAGES = {
    encoding: (rowIndex) => `Invalid character at row ${rowIndex}. Make sure your CSV has UTF-8 encoding.`,
    headingEmpty: () => 'Found an empty heading.',
    headingLength: (heading) => `Heading "${heading}" is too long.`,
    headingUnique: (heading) => `Heading "${heading}" must be unique.`,
    headingPhoneRequired: () => `Heading phone is required.`,
    headingInvalid: (heading) =>
        `The field ${heading} could not be mapped, please refer to the example template and follow format as is in it, including case-folding.`,
    invalidColumns: (rowIndex) => `Wrong number of values at row ${rowIndex}.`,
    failedToProcess: (rowIndex, message) =>
        `It was not possible to process row ${rowIndex}.` + (message ? ` ${message}` : ''),
    invalidPhone: (rowIndex) => `Phone is invalid at row ${rowIndex}.`,
    invalidTags: (rowIndex) =>
        `Tags is too long at row ${rowIndex}. Importing only supports a maximum of 20 tags per contact.`,
    invalidSubscriptionStatus: (rowIndex) => `Subscription status is invalid at row ${rowIndex}.`,
    invalidValueLength: (index, col) => `${col} is too long at row ${index}.`,
};

/**
 * @param {File} file
 */
export function validateCsvFile(file) {
    return new Promise((resolve, reject) => {
        if (!file?.type || file.type !== 'text/csv') {
            reject({
                message: 'The file is not a CSV. Please use CSV file in order to proceed with import.',
            });
        }

        if (file.size > 1024 * 1024 * 1024) {
            reject({
                message: 'Max file size is 1GB.',
            });
        }

        // Parse the CSV file and validate data.
        let optionalHeadings = ['first_name', 'last_name', 'email', 'time_zone', 'marketing', 'transactional'];
        let rowsCount = 0;
        let invalidRowsCount = 0;

        const nonRecoverableErrors = [];
        const recoverableErrors = [];
        const csvHeadings = [];

        Papa.parse(file, {
            delimiter: ',',
            worker: true,
            skipEmptyLines: true,
            step: (results, parser) => {
                rowsCount++;
                const recoverableRowErrors = [];
                const nonRecoverableRowErrors = [];

                // Handle parser errors.
                if (results.errors.length) {
                    const invalidRows = {};
                    results.errors.forEach((error) => {
                        invalidRows[error.row] = error.message;
                    });

                    Object.keys(invalidRows).forEach((rowIdx) => {
                        nonRecoverableErrors.push(ERRORS_MESSAGES.failedToProcess(rowIdx, invalidRows[rowIdx]));
                    });

                    invalidRowsCount = Object.keys(invalidRows).length;

                    parser.abort();
                    return;
                }

                const data = results.data;

                // Check UTF-8 encoding.
                for (const value of data) {
                    // Check whether value has �.
                    // If it is so, it either means the file does not use UTF-8 or � is really present in value.
                    if (value && value.indexOf('�') >= 0) {
                        nonRecoverableErrors.push(ERRORS_MESSAGES.encoding(rowsCount));
                        invalidRowsCount = 1;

                        parser.abort();
                        return;
                    }
                }

                if (rowsCount === 1) {
                    // Validate headings.
                    if (!data.includes('phone')) {
                        nonRecoverableRowErrors.push(ERRORS_MESSAGES.headingPhoneRequired());
                    }

                    const lcHeadingHash = {};

                    for (const heading of data) {
                        if (!heading) {
                            nonRecoverableRowErrors.push(ERRORS_MESSAGES.headingEmpty());
                        } else {
                            if (heading.length > 191) {
                                nonRecoverableRowErrors.push(ERRORS_MESSAGES.headingLength(heading));
                            }

                            const lcHeading = heading.toLowerCase();

                            if (Object.keys(lcHeadingHash).includes(lcHeading)) {
                                recoverableRowErrors.push(ERRORS_MESSAGES.headingUnique(heading));
                            }

                            lcHeadingHash[lcHeading] = 1;

                            if (optionalHeadings.indexOf(lcHeading) >= 0) {
                                if (heading != lcHeading) {
                                    recoverableRowErrors.push(ERRORS_MESSAGES.headingInvalid(lcHeading));
                                }
                            }

                            csvHeadings.push(lcHeading);
                        }
                    }

                    invalidRowsCount += nonRecoverableRowErrors.length || recoverableRowErrors.length ? 1 : 0;

                    if (recoverableRowErrors.length) {
                        recoverableErrors.push(...recoverableRowErrors);
                    }

                    if (nonRecoverableRowErrors.length) {
                        nonRecoverableErrors.push(...nonRecoverableRowErrors);
                        parser.abort();
                    }

                    return;
                }

                // Validate a row.
                if (data.length != csvHeadings.length) {
                    recoverableRowErrors.push(ERRORS_MESSAGES.invalidColumns(rowsCount));
                }

                for (let i = 0; i < data.length; i++) {
                    const value = data[i];

                    switch (csvHeadings[i]) {
                        case 'phone':
                            if (!value) {
                                recoverableRowErrors.push(ERRORS_MESSAGES.invalidPhone(rowsCount));
                            }
                            break;
                        case '@@tags@@':
                        case '@@tag@@':
                            if (value && value.length > 1620) {
                                recoverableRowErrors.push(ERRORS_MESSAGES.invalidTags(rowsCount));
                            }
                            break;
                        case 'marketing':
                        case 'transactional':
                            if (!['opt_in', 'opt_out', ''].includes(value)) {
                                recoverableRowErrors.push(ERRORS_MESSAGES.invalidSubscriptionStatus(rowsCount));
                            }
                            break;
                        default:
                            if (value && value.length > 191) {
                                recoverableRowErrors.push(
                                    ERRORS_MESSAGES.invalidValueLength(rowsCount, csvHeadings[i])
                                );
                            }
                            break;
                    }
                }

                invalidRowsCount += recoverableRowErrors.length || nonRecoverableRowErrors.length ? 1 : 0;

                if (recoverableRowErrors.length) {
                    recoverableErrors.push(...recoverableRowErrors);
                }

                if (nonRecoverableRowErrors.length) {
                    nonRecoverableErrors.push(...nonRecoverableRowErrors);
                }
            },
            complete: () => {
                const validCount = rowsCount - invalidRowsCount;
                const errorsCount = nonRecoverableErrors.length + recoverableErrors.length;

                if (validCount <= 0 && errorsCount <= 0) {
                    reject({
                        message:
                            'The CSV file does not contain any data. Please go back and make corrections to the file.',
                    });
                }

                resolve({
                    validCount: validCount > 1 ? validCount - 1 : 0,
                    totalRows: rowsCount - 1,
                    invalidRows: invalidRowsCount,
                    errorsCount,
                    nonRecoverableErrors,
                    recoverableErrors,
                });
            },
            error: () => {
                reject({
                    message: 'It was not possible to process the CSV file. Please contact support.',
                });
            },
        });
    });
}
