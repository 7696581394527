<template>
    <vx-modal
        class="vx-max-w-lg"
        :visible="visible"
        @update:visible="emit('update:visible', $event)"
    >
        <template v-slot:header>Are you sure?</template>

        <p class="vx-text-base vx-text-slate-500 vx-mb-3">Before you Launch the Campaign make sure of the following:</p>

        <ul class="vx-ml-4 vx-list-disc	vx-text-base vx-text-slate-500">
            <li>You've chosen the right Campaign Duration</li>
            <li>You added the desired Contacts to the Campaign</li>
            <li>You've created the right set of messages</li>
            <li>Your automations, if needed, are in place</li>
        </ul>

        <vx-alert
            v-if="status === 'suggest_sms_convert'"
            color="info"
            data-test="throughput-warning"
            class="vx-mt-6"
        >
            <p>
                The current audience size is approximately {{ audienceSize }} contacts.
            </p>

            <p>
                Based on your carrier capacity for this campaign, we recommend reducing your audience size to
                <span data-test="throughput-suggestion">{{ availableCapacity }}</span> contacts or less. Alternatively,
                we can automatically convert your campaign to pure SMS, and we estimate you have sufficient capacity to
                send it. Click <a href="#" @click.prevent="convertCampaignToSMS" data-test="convert-campaign-to-sms">here</a> to have us do this for you.
            </p>
        </vx-alert>

        <vx-alert
            v-if="status === 'suggest_contact_support'"
            color="info"
            data-test="throughput-warning"
            class="vx-mt-6"
        >
            <p>
                The current audience size is approximately {{ audienceSize }} contacts.
            </p>

            <p>
                Based on your carrier capacity for this campaign, we recommend reducing your audience size to
                <span data-test="throughput-suggestion">{{ availableCapacity }}</span> contacts or less. To increase your
                carrier capacity, please reach out to your <a href="mailto:support@voxiechat.com">account manager</a>.
            </p>
        </vx-alert>

        <vx-alert
            v-if="status === 'no_campaign_message'"
            color="info"
            data-test="no-campaign-message-warning"
            class="vx-mt-6"
        >
            <p class="vx-mb-2">
                The current audience size is approximately {{ audienceSize }} contacts.
            </p>

            <p>
                We are unable to determine if you have sufficient capacity to send this campaign
                because it has no live campaign messages.
            </p>
        </vx-alert>

        <vx-alert
            v-if="status === 'no_phone_number'"
            color="info"
            data-test="no-phone-number-warning"
            class="vx-mt-6"
        >
            <p class="vx-mb-2">
                The current audience size is approximately {{ audienceSize }} contacts.
            </p>

            <p>
                We are unable to determine if you have sufficient capacity to send this campaign
                because no phone numbers are configured to send on.
            </p>
        </vx-alert>

        <vx-alert
            v-if="status === 'no_action_required'"
            color="primary"
            data-test="no-action-required-info"
            class="vx-mt-6"
        >
            <p class="vx-mb-2">
                The current audience size is approximately {{ audienceSize }} contacts.
            </p>

            <p>
                We estimate you have sufficient capacity to send this campaign.
            </p>
        </vx-alert>

        <vx-button
            class="vx-mt-8"
            block
            size="lg"
            :disabled="isSubmitting || isLoadingCampaignCapacity"
            @click="liveMode"
            data-test="liveModeBtn"
        >
            Launch
            <font-awesome-icon :icon="faRocketLaunch" />
        </vx-button>
    </vx-modal>
</template>

<script setup>
import { actionsClient, campaignsClient } from '../../services';
import { numberWithCommas } from '~/components/filters';
import { ref, watch } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faRocketLaunch } from '@fortawesome/pro-solid-svg-icons';
import { VxButton, VxModal, VxAlert } from '@voxie/frontend-components';
import { useToasted } from '../../composables/useToasted';
import dayjs from '~/utils/dayjs';

const props = defineProps({
    campaign: {
        type: Object,
        required: false,
    },
    visible: Boolean,
});

const emit = defineEmits(['update:visible', 'update:campaign']);

const teamId = Spark.state.currentTeam.id;
const isSubmitting = ref(false);
const isLoadingCampaignCapacity = ref(false);
const availableCapacity = ref(null);
const audienceSize = ref(null);
const status = ref(null);

const close = () => emit('update:visible', false);

const toasted = useToasted();

watch(() => props.visible, (visible) => {
    if (!visible) {
        isLoadingCampaignCapacity.value = false;
        availableCapacity.value = null;
        audienceSize.value = null;
        status.value = null;
        return;
    }

    if (props.campaign?.id) {
        getCampaignCapacity();
    }
}, {
    immediate: true,
})

const liveMode = () => {
    changeStatus(props.campaign.live_at && dayjs.utc(props.campaign.live_at).isAfter(dayjs()) ? 'scheduled' : 'published');
}

const changeStatus = (newStatus) => {
    const campaign = { ...props.campaign };
    isSubmitting.value = true;

    campaignsClient.changeStatus(teamId, campaign.id, newStatus)
        .then(({ data }) => {
            if (newStatus === 'published' && campaign.status !== 'published') {
                actionsClient.send('campaign_turned_live');
            }
            campaign.status = newStatus;
            campaign.started_at = data.started_at;
            emit('update:campaign', campaign);
            close();
            toasted.global.saved_successfully();
        })
        .catch(() => toasted.global.platform_error())
        .finally(() => isSubmitting.value = false);
}

const getCampaignCapacity = () => {
    isLoadingCampaignCapacity.value = true;
    campaignsClient.campaignCapacity(teamId, props.campaign.id).then(({ data }) => {
        availableCapacity.value = data.available_capacity != null ? numberWithCommas(data.available_capacity) : null;
        audienceSize.value = data.audience_size != null ? numberWithCommas(data.audience_size) : null;
        status.value = data.status;
    })
    .finally(() => isLoadingCampaignCapacity.value = false);
};

const convertCampaignToSMS = () => {
    campaignsClient.campaignsMessageGetAll(teamId, props.campaign.id).then(({ data }) => {
        const updateCampaignMessagePromises = [];
        data.data.forEach(campaignMessage => {
            campaignMessage.media_url = null;
            campaignMessage.media_content_type = null;
            updateCampaignMessagePromises.push(
                campaignsClient.campaignsMessageSave(teamId, props.campaign.id, campaignMessage)
            );
        });
        Promise.all(updateCampaignMessagePromises).finally(() => {
            getCampaignCapacity();
        })
    });
}
</script>
