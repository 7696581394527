<template>
    <api-delivery-card :icon="props.icon">
        <template v-slot:title>
            <slot name="title"></slot>
        </template>

        <template v-slot:headerRight>
            <vx-popover hover :arrow="true" placement="top">
                <vx-tabs v-model="mode" :disabled="!isPrettyAllowed" size="sm">
                    <vx-tab value="raw">
                        Raw
                    </vx-tab>
                    <vx-tab value="pretty">Pretty</vx-tab>
                </vx-tabs>
                <template v-if="!isPrettyAllowed" v-slot:content>
                    <span v-if="isBinary || !isJson">
                        Cannot format a non-JSON payload.
                    </span>
                    <span v-else-if="props.isTruncated">
                        Cannot format a truncated payload.
                    </span>
                </template>
            </vx-popover>
        </template>
        <template v-slot:content>
            <!-- match style to response content to reduce layout shift -->
            <div v-if="props.loading" class="vx-w-full vx-p-4 vx-bg-slate-50 vx-rounded-[10px] vx-justify-start vx-items-center vx-gap-3 vx-inline-flex vx-h-[52px]">
                <vx-icon-loading spin></vx-icon-loading>
            </div>

            <template v-else-if="decodedBody || isBinary">
                <div class="vx-flex vx-flex-col vx-w-full vx-gap-4">
                    <slot name="metadata"></slot>
                    <div class="vx-flex vx-flex-col vx-w-full">
                        <div v-if="mode === 'raw'"
                            class="vx-w-full vx-px-5 vx-py-3 vx-bg-slate-50 vx-rounded-2xl vx-border vx-border-solid vx-border-slate-200 vx-text-slate-500 vx-text-sm vx-font-normal vx-leading-snug vx-break-all">
                            <div v-if="isBinary"
                                class="vx-w-full vx-p-4 vx-bg-slate-50 vx-rounded-[10px] vx-justify-start vx-items-center vx-gap-3 vx-inline-flex">
                                <font-awesome-icon :icon="faFileXmark" class="vx-text-black vx-text-base" />
                                <div class="vx-text-slate-500 vx-text-base">Binary file not shown.</div>
                            </div>
                            <template v-else>
                                {{ payload }}
                                <template v-if="props.isTruncated">
                                    <br />...
                                    <br />truncated
                                </template>
                            </template>
                        </div>
                        <div v-else class="vx-w-full">
                            <vx-code-editor :modelValue="payload" disabled></vx-code-editor>
                        </div>
                    </div>
                </div>
            </template>

            <div v-else
                class="vx-w-full vx-p-4 vx-bg-slate-50 vx-rounded-[10px] vx-justify-start vx-items-center vx-gap-3 vx-inline-flex">
                <font-awesome-icon :icon="faGhost" class="vx-text-slate-500" />
                <div class="vx-text-sky-950 vx-text-sm vx-font-normal">
                    <slot name="missing-message"></slot>
                </div>
            </div>
        </template>
    </api-delivery-card>
</template>

<script setup>
import ApiDeliveryCard from './ApiDeliveryCard.vue';
import { VxTabs, VxTab, VxCodeEditor, VxPopover, VxIconLoading } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faGhost, faFileXmark } from '@fortawesome/pro-solid-svg-icons';
import { computed, ref, watch } from 'vue';
import { decodeBase64, isValidJsonString } from '../../../../../../../utils/helpers';

const props = defineProps({
    code: {
        type: String,
        required: false,
    },
    contentType: {
        type: String,
        required: false,
    },
    isTruncated: {
        type: Boolean,
        required: false,
    },
    loading: {
        type: Boolean,
        required: false,
    },
    icon: {
        type: Object,
        required: false,
    }
});

const isJson = computed(() => {
    if (!props.contentType || !decodedBody.value) {
        return false;
    }

    return props.contentType.split(';')[0] === 'application/json' && isValidJsonString(decodedBody.value);
});

const decodedBody = computed(() => {
    if (typeof props.code !== 'string') {
        return null;
    }

    return decodeBase64(props.code);
})

const isBinary = computed(() => {
    if (!decodedBody.value) {
        return !!props.code;
    }

    return decodedBody.value.indexOf('�') >= 0;
})

const payload = computed(() => {
    if (!decodedBody.value || isBinary.value) {
        return null;
    }

    return props.isTruncated || !isJson.value ? decodedBody.value : JSON.stringify(JSON.parse(decodedBody.value), null, 4) // spacing level = 4
})

const mode = ref('raw');
const isPrettyAllowed = computed(() => !props.isTruncated && isJson.value && !isBinary.value);

watch(() => [props.code, props.contentType, props.isTruncated], () => {
    mode.value = !isPrettyAllowed.value ? 'raw' : 'pretty';
});
</script>
