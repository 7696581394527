<template>
    <div>
        <!-- Create API Token -->
        <div>
            <create-token :available-abilities="availableAbilities"></create-token>
        </div>

        <!-- API Tokens -->
        <div>
            <api-tokens :tokens="tokens" :available-abilities="availableAbilities"></api-tokens>
        </div>

        <template v-if="!productionOrStaging">
            <div v-if="developer">
                <passport-clients></passport-clients>
            </div>
            <div>
                <passport-authorized-clients></passport-authorized-clients>
            </div>
            <div>
                <passport-personal-access-tokens></passport-personal-access-tokens>
            </div>
        </template>
    </div>
</template>

<script>
import ApiTokens from './ApiTokens.vue';
import CreateToken from './CreateToken.vue';
import PassportClients from '../../passport/Clients.vue';
import PassportAuthorizedClients from '../../passport/AuthorizedClients.vue';
import PassportPersonalAccessTokens from '../../passport/PersonalAccessTokens.vue';
import axios from '~/services/axios';

export default {
    components: {
        ApiTokens,
        CreateToken,
        PassportClients,
        PassportAuthorizedClients,
        PassportPersonalAccessTokens,
    },
    props: ['developer', 'productionOrStaging'],
    /**
     * The component's data.
     */
    data() {
        return {
            tokens: [],
            availableAbilities: [],
        };
    },

    /**
     * Prepare the component.
     */
    mounted() {
        this.getTokens();
        this.getAvailableAbilities();
    },

    /**
     * The component has been created by Vue.
     */
    created() {
        const self = this;

        Bus.$on('updateTokens', function () {
            self.getTokens();
        });
    },

    methods: {
        /**
         * Get the current API tokens for the user.
         */
        getTokens() {
            axios.get('/settings/api/tokens').then((response) => (this.tokens = response.data));
        },

        /**
         * Get all of the available token abilities.
         */
        getAvailableAbilities() {
            axios.get('/settings/api/token/abilities').then((response) => (this.availableAbilities = response.data));
        },
    },
};
</script>
