<template>
    <div class="vx-font-sans">
        <div class="vx-mb-12">
            <h2 class="vx-text-3xl vx-text-slate-900 vx-font-extrabold vx-m-0">
                Contact Collector
            </h2>
            <p class="vx-text-xl vx-text-slate-700 vx-font-normal vx-my-4">
                Install a pop-up style chat window to collect name, phone and
                email from your users to add to Voxie. Just replace the company
                name and link to your terms in the example below.
            </p>
            <p class="vx-text-base vx-text-slate-500 vx-font-normal vx-m-0">
                Need help with installation? Our docs have examples &rarr;
            </p>
        </div>

        <div
            class="vx-shadow vx-rounded-[1rem] vx-border vx-border-white vx-bg-slate-100 vx-overflow-hidden"
        >
            <div
                class="vx-text-black vx-text-lg vx-font-extrabold vx-bg-white vx-border-b vx-border-slate-200 vx-py-4 vx-px-6"
            >
                <span>Interactive Preview</span>
            </div>
            <div
                class="vx-slate-500 vx-text-base vx-font-normal vx-flex vx-flex-col lg:vx-flex-row"
            >
                <div class="lg:vx-w-1/2 vx-p-12">
                    <p class="vx-mb-2">
                        You can click on this preview to see how the collector
                        will work on your site.
                    </p>
                    <p>
                        Theme customization is coming soon so you'll be able to
                        tailor this to match your brand.
                    </p>
                </div>
                <div
                    class="lg:vx-w-1/2 vx-flex vx-justify-center vx-items-center vx-py-10 vx-bg-gray-300"
                >
                    <pop-up-example></pop-up-example>
                </div>
            </div>
        </div>

        <div
            class="vx-shadow vx-rounded-[1rem] vx-border vx-border-white vx-bg-slate-100 vx-overflow-hidden vx-mt-12"
        >
            <div
                class="vx-text-black vx-text-lg vx-font-extrabold vx-bg-white vx-border-b vx-border-slate-200 vx-py-4 vx-px-6"
            >
                <span>Installation</span>
            </div>
            <div class="vx-slate-500 vx-text-base vx-font-normal vx-p-12">
                <p class="vx-slate-700 vx-font-extrabold vx-mb-4">
                    1. Generate & Copy the code.
                </p>
                <pre
                    v-if="connectionKey"
                    class="vx-bg-white vx-border vx-border-slate-200 vx-rounded-[1rem] vx-font-mono vx-text-black vx-font vx-px-8 vx-py-6"
                    ref="snippet"
                >&lt;!-- Voxie Contact Collector --&gt;
&lt;link rel="stylesheet" href="https://unpkg.com/@voxie/contacts.js@^1.1/dist/index.css"&gt;&lt;/link&gt;
&lt;script src="https://unpkg.com/@voxie/contacts.js@^1.1/dist/contacts.js"&gt;&lt;/script&gt;
&lt;script defer&gt;
    (async function () {
        window.voxie = await Voxie.init({
            publicKeyId: '{{ connectionKey.key_id }}',
            publicSecret: '{{ connectionKey.secret }}',
            connectBaseUri: '{{ connectionKey.connect_base_uri }}',
        });
        const popUp = window.voxie.popUp({
          // name: 'Company',
          // terms: 'https://example.com/terms',
        });
        popUp.auto();
    })();
&lt;/script&gt;</pre
                >
                <div class="vx-my-4">
                    <vx-button
                        data-test="generate-copy-contact-collector"
                        @click="connectionKey ? copyScript() : generateConnectionKey()"
                        size="xl"
                        class="vx-font-sans"
                        :disabled="loading"
                    >
                        {{ connectionKey ? "Copy" : "Generate" }}
                        <i
                            class="fa fa-refresh"
                            :class="{
                                'fa-clipboard': connectionKey,
                                'fa-refresh': !connectionKey,
                            }"
                        ></i>
                    </vx-button>
                </div>

                <hr class="vx-border-slate-200" />
                <p class="vx-slate-700 vx-font-extrabold vx-my-4">
                    2. Paste in your site!
                </p>
                <p class="vx-m-0">
                    This can be pasted right
                    <strong class="vx-font-bold">before</strong> the closing
                    &lt;/body&gt; tag of your website.
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useToasted } from '../../../composables/useToasted';
import connectionsClient from '../../../services/connectionsClient';
import { ref, computed} from 'vue';
import PopUpExample from './contact-collector/PopUpExample.vue';
import { VxButton } from '@voxie/frontend-components';

const connectionKey = ref(null);
const toasted = useToasted();
const loading = ref(false);
const snippet = ref(null);

const props = defineProps({
    ownsTeam: {
        type: Boolean,
        default: false,
    },
    isTeamAdmin: {
        type: Boolean,
        default: false,
    },
})

const canGenerate = computed(() => props.ownsTeam || props.isTeamAdmin)

const copyScript = () => {
    navigator.clipboard.writeText(snippet.value.innerText);
};

const generateConnectionKey = async () => {
    if (!canGenerate.value) {
        toasted.error('Only the team owner or admin can generate the script.');
        return;
    }

    if (loading.value) {
        return false;
    }

    try {
        loading.value = true;
        const response = await connectionsClient.generateConnectionKey(
            Spark.state.currentTeam.id
        );

        connectionKey.value = response.data;
    } catch {
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
};
</script>
