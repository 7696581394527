const focusableSelector = [
    'a[href]',
    'area[href]',
    'input:not([disabled])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'iframe',
    '[tabindex]',
    '[contentEditable=true]',
]
    .map((selector) => `${selector}:not([tabindex="-1"])`)
    .join(',');

export const ESCAPE_KEY = 27;
export const TAB_KEY = 9;

export function getFocusableElements(container) {
    if (container == null) return [];
    return Array.from(container.querySelectorAll(focusableSelector));
}

export function containerSortedFocusableElements(container) {
    const focusableElements = getFocusableElements(container);

    return focusableElements.slice().sort((aItem, zItem) => {
        const position = aItem.compareDocumentPosition(zItem);

        if (position & Node.DOCUMENT_POSITION_FOLLOWING) return -1;
        if (position & Node.DOCUMENT_POSITION_PRECEDING) return 1;

        return 0;
    });
}
