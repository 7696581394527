export default {
    methods: {
        /**
         * Initialize push state handling for nav.
         */
        usePushStateForClientRoutes() {
            this.registerClickHandler();

            window.addEventListener('popstate', () => {
                this.activateCurrentHash();
            });

            if (window.location.hash) {
                this.activateCurrentHash();
            }
        },

        /**
         * Register the click handler for all of the routes.
         */
        registerClickHandler() {
            $(`a[data-router="client-route"], button[data-router="client-route"]`).on('click', (event) => {
                this.showClientRoute($(event.currentTarget).attr('href'));

                this.broadcastRouteChange($(event.currentTarget).attr('href').substring(1));
            });
        },

        /**
         * Activate the route for the current hash in the URL.
         */
        activateCurrentHash() {
            var hash = window.location.hash.substring(2);

            var parameters = hash.split('/');

            hash = parameters.shift();

            this.broadcastRouteChange(hash, parameters);
        },

        /**
         * Broadcast that a tab change happened.
         */
        broadcastRouteChange(hash, parameters) {
            Bus.$emit('clientRouteChanged', hash, parameters);
        },

        currentClientRoute() {
            if (!window.location.hash) {
                return null;
            }

            var hash = window.location.hash.substring(2);

            var parameters = hash.split('/');

            hash = parameters.shift();

            return {
                hash: hash,
                parameters: parameters,
            };
        },

        clearClientRoute() {
            history.pushState(null, null, '#');
        },

        showClientRoute(href) {
            history.pushState(null, null, href);
        },

        /**
         * It extracts cursor from the current URL hash for cursor pagination.
         *
         * @param {String} identity The value you pass into the cursor pagination component e.g. 'contacts-pages'
         * @returns {String|undefined}
         */
        getCursorFromHash(identity) {
            const route = this.currentClientRoute();
            if (route && route.hash === identity && route.parameters.length === 1) {
                return route.parameters[0];
            }
        },

        setCursorHash(identity, cursor) {
            this.showClientRoute(cursor ? `#/${identity}/${cursor}` : '#');
        },
    },
};
