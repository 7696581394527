<template>
    <page-sidebar :links="links"></page-sidebar>
</template>

<script setup>
import { faCog, faLinkSimple } from '@fortawesome/pro-solid-svg-icons';
import { computed } from 'vue';
import PageSidebar from '../../../general/PageSidebar.vue';

const props = defineProps({
    apiDestinationId: {
        required: true,
        type: String,
    },
    sectionLinks: {
        type: Array,
        default: () => [],
    }
});

const links = computed(() => ([
    {
        href: `/settings/integrations/api-destinations/${props.apiDestinationId}`,
        label: 'Settings',
        icon: faCog,
    },
    {
        href: `/settings/integrations/api-destinations/${props.apiDestinationId}/deliveries`,
        label: 'API Deliveries',
        icon: faLinkSimple,
    },
    ...props.sectionLinks,
]));


</script>
