<script setup>
import { faMessages, faRobot } from '@fortawesome/pro-solid-svg-icons'
import SvgNode from './SvgNode.vue'
import SvgNodeIconOnly from './SvgNodeIconOnly.vue'
import { vxSvg } from '@voxie/frontend-components'
import ArtNoodle from './ArtNoodle.vue'
import { svgWidthLg, svgWidthSm, classNameLg, classNameSm } from './artVars'

const pathLg = [
  { marker: 'M', values: { x: svgWidthLg * 0.4, y: 20 } },
  { marker: 'L', values: { x: svgWidthLg * 0.5, y: 20 } },
  { marker: 'M', values: { x: svgWidthLg * 0.4, y: 100 } },
  { marker: 'L', values: { x: svgWidthLg * 0.5, y: 100 } },
]
const pathLgRounded = () => {
  return vxSvg.roundCommands(pathLg, 20, 2)?.path
}

const pathSm = [
  { marker: 'M', values: { x: svgWidthSm * 0.4, y: 17 } },
  { marker: 'L', values: { x: svgWidthSm * 0.6, y: 17 } },
  { marker: 'M', values: { x: svgWidthSm * 0.4, y: 77 } },
  { marker: 'L', values: { x: svgWidthSm * 0.6, y: 77 } },
]
const pathSmRounded = () => {
  return vxSvg.roundCommands(pathSm, 12, 2)?.path
}
</script>

<template>
  <svg
    :viewBox="`0 0 ${svgWidthLg} 122`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    :width="svgWidthLg"
    :class="classNameLg"
  >
    <art-noodle :d="pathLgRounded()" animated />

    <!-- nodes -->

    <svg-node
      :x="160"
      :y="0"
      :icon="faMessages"
      :width="110"
      label="Help"
      fill="vx-fill-sky-300"
    ></svg-node>
    <svg-node
      :x="svgWidthLg * 0.5"
      :y="0"
      :width="180"
      :icon="faRobot"
      label="Handle Request"
      fill="vx-fill-amber-300"
    ></svg-node>

    <svg-node
      :x="160"
      :y="80"
      :width="110"
      :icon="faMessages"
      label="Start"
      fill="vx-fill-sky-300"
    ></svg-node>
    <svg-node
      :x="svgWidthLg * 0.5"
      :y="80"
      :width="120"
      :icon="faRobot"
      label="Opt-in"
      fill="vx-fill-amber-300"
    ></svg-node>
  </svg>

  <svg
    :viewBox="`0 0 ${svgWidthSm} 92`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    overflow="visible"
    :width="svgWidthSm"
    :class="classNameSm"
  >
    <art-noodle :d="pathSmRounded()" />

    <!-- nodes -->
    <!-- row -->
    <svg-node-icon-only
      :x="svgWidthSm * 0.24"
      :y="0"
      :icon="faMessages"
      fill="vx-fill-sky-300"
    ></svg-node-icon-only>
    <svg-node-icon-only
      :x="svgWidthSm * 0.58"
      :y="0"
      :icon="faRobot"
      fill="vx-fill-amber-300"
    ></svg-node-icon-only>

    <!-- row -->
    <svg-node-icon-only
      :x="svgWidthSm * 0.24"
      :y="60"
      :icon="faMessages"
      fill="vx-fill-sky-300"
    ></svg-node-icon-only>
    <svg-node-icon-only
      :x="svgWidthSm * 0.58"
      :y="60"
      :icon="faRobot"
      fill="vx-fill-amber-300"
    ></svg-node-icon-only>
  </svg>
</template>
