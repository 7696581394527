<template>
    <div class="vx-mt-4 lg:vx-mt-6">
        <template v-if="!verifying">
            <span
                class="vx-text-slate-900 vx-font-semibold vx-text-sm vx-block vx-mb-2 sm:vx-text-base"
            >
                Enter a list of phone numbers
            </span>

            <vx-input rows="6" v-model="phoneNumbersRaw"></vx-input>

            <ul
                class="vx-mt-2 vx-list-disc vx-pl-4 vx-text-slate-500 vx-text-xs"
            >
                <li>Numbers can be one per line or comma separated.</li>
                <li>
                    You should have explicit permission to text these numbers or
                    risk service disruption.
                </li>
                <li>Max {{ MAX_PHONE_NUMBERS }} numbers for manual entry.</li>
            </ul>
            <vx-button
                :disabled="processing"
                class="vx-mt-4 lg:vx-mt-6"
                size="lg"
                :block="true"
                @click="verifyPhoneNumbers()"
                data-test="manual-verify"
            >
                {{ processing ? "Verifying" : "Verify Numbers" }}
                <font-awesome-icon
                    class="vx-text-base"
                    :icon="processing ? faSpinnerThird : faShieldCheck"
                    :spin="processing"
                ></font-awesome-icon>
            </vx-button>
        </template>
        <template v-else>
            <div
                :class="{
                    'vx-pointer-events-none': processing,
                }"
            >
                <div
                    class="vx-border vx-border-solid vx-border-slate-200 vx-rounded-2xl vx-w-full vx-py-4 vx-px-6 vx-text-sm vx-text-slate-600 vx-font-semibold vx-flex vx-justify-between vx-items-center"
                >
                    {{ validContacts.length }} new + existing
                    <font-awesome-icon
                        :icon="faCircleCheck"
                    ></font-awesome-icon>
                </div>
                <div
                    v-if="!invalidPhoneNumbers.length"
                    class="vx-border vx-border-solid vx-border-slate-200 vx-rounded-2xl vx-w-full vx-py-4 vx-px-6 vx-text-sm vx-text-slate-600 vx-font-semibold vx-flex vx-justify-between vx-items-center vx-mt-1"
                >
                    {{ invalidPhoneNumbers.length }} invalid
                    <font-awesome-icon
                        :icon="faCircleCheck"
                    ></font-awesome-icon>
                </div>
                <vx-disclosure v-else class="vx-mt-1" :active="true">
                    <template v-slot:header>
                        <span class="vx-text-rose-900">
                            {{ invalidPhoneNumbers.length }} invalid
                        </span>
                    </template>
                    <template v-slot:content>
                        <p class="vx-text-slate-700 vx-mb-4 vx-text-sm">
                            The following numbers will be excluded from the
                            blast if not corrected.
                        </p>

                        <div
                            v-for="phoneNumber in invalidPhoneNumbers"
                            :key="phoneNumber.key"
                            class="vx-flex vx-gap-1 vx-items-center vx-mb-3 vx-flex-wrap vx-w-full last:vx-mb-0 lg:vx-flex-nowrap lg:vx-gap-3"
                            data-test="manual-invalid-row"
                        >
                            <div class="vx-relative vx-w-[calc(100%-2.25rem)] lg:vx-w-36">
                                <input
                                    type="text"
                                    class="vx-appearance-none vx-border vx-border-solid vx-border-slate-300 vx-rounded-md vx-h-8 vx-pl-3 vx-pr-6 vx-text-slate-700 vx-text-sm vx-font-sans vx-w-full"
                                    v-model="phoneNumber.phoneNumber"
                                    @blur="updateInvalid(phoneNumber)"
                                />

                                <button
                                    role="button"
                                    class="vx-appearance-none vx-border-none vx-bg-transparent vx-p-0 vx-absolute vx-top-[5px] vx-right-3"
                                    @click="updateInvalid(phoneNumber)"
                                    data-test="manual-invalid-row-update"
                                >
                                    <font-awesome-icon
                                        :icon="faPenToSquare"
                                    ></font-awesome-icon>
                                </button>
                            </div>
                            <button
                                role="button"
                                class="vx-appearance-none vx-border-none vx-bg-transparent vx-p-0 vx-w-8 lg:vx-grow-0"
                                @click="removeInvalid(phoneNumber)"
                                data-test="manual-invalid-row-remove"
                            >
                                <font-awesome-icon
                                    :icon="faTrashCan"
                                ></font-awesome-icon>
                            </button>
                            <span
                                class=""
                                :class="{
                                    'vx-text-rose-900': phoneNumber.reason,
                                    'vx-text-teal-600': !phoneNumber.reason,
                                }"
                            >
                                {{ phoneNumber.reason || "Updated" }}
                            </span>
                        </div>
                    </template>
                </vx-disclosure>
            </div>
            <div class="vx-flex vx-gap-2 vx-mt-4 lg:vx-mt-6">
                <vx-button
                    :disabled="processing"
                    class="vx-w-28"
                    size="lg"
                    color="muted"
                    @click="reset()"
                    data-test="manual-reset"
                >
                    Reset
                    <font-awesome-icon
                        class="vx-text-base"
                        :icon="faArrowRotateLeft"
                    ></font-awesome-icon>
                </vx-button>
                <vx-button
                    v-if="canReverify && invalidPhoneNumbers.length"
                    :disabled="processing"
                    size="lg"
                    :block="true"
                    @click="reverifyPhoneNumbers()"
                    data-test="manual-reverify"
                >
                    {{ processing ? "Verifying" : "Reverify" }}
                    <font-awesome-icon
                        class="vx-text-base"
                        :icon="processing ? faSpinnerThird : faShieldCheck"
                        :spin="processing"
                    ></font-awesome-icon>
                </vx-button>
                <vx-button
                    v-else
                    size="lg"
                    :disabled="processing"
                    :block="true"
                    @click="confirm()"
                    data-test="manual-continue"
                >
                    Continue
                    <font-awesome-icon
                        class="vx-text-base"
                        :icon="faArrowTurnDown"
                    ></font-awesome-icon>
                </vx-button>
            </div>
        </template>
    </div>
</template>

<script setup>
import { VxDisclosure, VxButton, VxInput } from '@voxie/frontend-components';
import { ref, watch } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import libphonenumber from 'google-libphonenumber';
import {
    faShieldCheck,
    faArrowRotateLeft,
    faArrowTurnDown,
    faPenToSquare,
    faCircleCheck,
    faSpinnerThird,
} from "@fortawesome/pro-solid-svg-icons";
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import contactsClient from '../../services/contactsClient';
import actionsClient from '../../services/actionsClient';
import { useToasted } from '../../composables/useToasted';

const MAX_PHONE_NUMBERS = 100;
const teamId = Spark.state.currentTeam.id;

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const PNF = libphonenumber.PhoneNumberFormat;

const toasted = useToasted();

const phoneNumbersRaw = ref("");

const props = defineProps({
    modelValue: {
        type: Array,
        required: false,
        default: () => [],
    },
});

const emit = defineEmits(["update:modelValue", "continue"]);

const verifying = ref(Boolean(props.modelValue.length));
const processing = ref(false);
const canReverify = ref(false);

const validContacts = ref(props.modelValue);
const invalidPhoneNumbers = ref([]);

watch(
    () => props.modelValue,
    (newModelValue) => {
        validContacts.value = newModelValue;
    }
);

watch(validContacts, (newValidContacts) => {
    emit("update:modelValue", newValidContacts);
});

/**
 * @param {{key: number, reason: string, phoneNumber: string}} phoneNumber
 */
const updateInvalid = (phoneNumber) => {
    canReverify.value = true;

    invalidPhoneNumbers.value = invalidPhoneNumbers.value.map((item) => {
        if (item.key == phoneNumber.key) {
            item.reason = null;
            return item;
        }

        return item;
    });
};

/**
 * @param {{key: number, reason: string, phoneNumber: string}} phoneNumber
 */
const removeInvalid = (phoneNumber) => {
    invalidPhoneNumbers.value = invalidPhoneNumbers.value.filter(
        (item) => item.key !== phoneNumber.key
    );
};

const confirm = async () => {
    emit("continue", validContacts.value);
};

const reset = () => {
    verifying.value = false;
    processing.value = false;
    canReverify.value = false;

    validContacts.value = [];
    invalidPhoneNumbers.value = [];
};

/**
 * Takes the content from the textarea and processes them
 */
const verifyPhoneNumbers = () => {
    if (!phoneNumbersRaw.value.length) {
        toasted.error('Please enter a list of phone numbers');
        return;
    }

    const phoneNumbers = phoneNumbersRaw.value.split(/\r?\n|\r|\n|,|;/g).filter((phoneNumberRaw) => phoneNumberRaw?.trim?.()?.length);

    processPhoneNumbers(phoneNumbers);
};

/**
 * Takes the invalid phone numbers that have been corrected and reprocess them
 */
const reverifyPhoneNumbers = () => {
    const phoneNumbers = invalidPhoneNumbers.value.map(
        (item) => item.phoneNumber
    );
    processPhoneNumbers(phoneNumbers);
};

/**
 *
 * @param {string[]} phoneNumbers
 */
const processPhoneNumbers = async (phoneNumbers) => {
    processing.value = true;
    canReverify.value = false;

    const currentInvalidPhoneNumbers = [];
    const phoneNumbersFormatted = [];

    try {
        for (const [index, phoneNumber] of phoneNumbers.entries()) {
            if (index + validContacts.value.length >= MAX_PHONE_NUMBERS) {
                currentInvalidPhoneNumbers.push({
                    reason: "Max phone numbers reached",
                    key: Math.floor(Math.random() * 10000),
                    phoneNumber: phoneNumber,
                });
                continue;
            }

            const phoneNumberFormatted = e164Format(phoneNumber);

            if (!phoneNumberFormatted?.trim()?.length) {
                currentInvalidPhoneNumbers.push({
                    reason: "Invalid format",
                    key: Math.floor(Math.random() * 10000),
                    phoneNumber: phoneNumber,
                });
                continue;
            }

            if (!isPhoneNumberValid(phoneNumberFormatted)) {
                currentInvalidPhoneNumbers.push({
                    reason: "Invalid phone number",
                    key: Math.floor(Math.random() * 10000),
                    phoneNumber: phoneNumber,
                });
                continue;
            }

            phoneNumbersFormatted.push(phoneNumberFormatted)
        }

        for (const validPhoneNumber of phoneNumbersFormatted) {
            await processPhoneNumber(validPhoneNumber);
        }
    } finally {
        processing.value = false;
        verifying.value = true;
        invalidPhoneNumbers.value = currentInvalidPhoneNumbers;
    }
};

const processPhoneNumber = async (phoneNumber) => {
    const contact = await lookupContact(phoneNumber);

    const contactAlreadyAdded = validContacts.value.find(
        (contact) => contact.phone === phoneNumber
    );

    if (contactAlreadyAdded) {
        return;
    }

    validContacts.value = [...validContacts.value, contact];
};

const lookupContact = async (phoneNumber) => {
    try {
        return await fetchContact(phoneNumber);
    } catch (e) {
        return await createContact(phoneNumber);
    }
};

const fetchContact = async (phoneNumber) => {
    try {
        const response = await contactsClient.searchContacts(teamId, {
            "filter[phone]": phoneNumber,
        });

        if (
            response.data?.data?.length === 1 &&
            response.data.data[0].phone === phoneNumber
        ) {
            return response.data.data[0];
        }
    } catch (e) {
        console.error(e);
    }

    throw new Error(
        `It was not possible to fetch the contact with the phone number ${phoneNumber}.`
    );
};

const createContact = async (phoneNumber) => {
    try {
        const response = await contactsClient.contactSave(teamId, {
            phone: phoneNumber,
        });
        actionsClient.send("contact_added");
        return response.data;
    } catch (e) {
        throw new Error(
            `It was not possible to create a new contact with the phone number ${phoneNumber}.`
        );
    }
};

const e164Format = (phone) => {
    try {
        return phoneUtil.format(phoneUtil.parse(phone, "US"), PNF.E164);
    } catch {
        return null;
    }
};

const isPhoneNumberValid = (phoneNumber) => {
    try {
        return Boolean(
            phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber, "US"))
        );
    } catch (err) {
        return false;
    }
};
</script>
