<template>
    <multi-select-attribution-card
        entity="UserMessage"
        entityTitle="User Message"
        entityDescription="user message"
        :selected-items="form.selected_users"
        v-model:type="form.user_attribution_type"
        :loading-options="loadingOptions"
        :options="options"
        @search-change="search"
    />
</template>

<script>
import MultiSelectAttributionCard from './MultiSelectAttributionCard.vue';
import { usersClient } from '../../../../services';

export default {
    components: {
        MultiSelectAttributionCard,
    },
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loadingOptions: false,
            options: [],
        };
    },

    created() {
        this.search('');
    },

    methods: {
        search(query) {
            this.loadingOptions = true;
            const params = {
                query,
                per_page: 20,
                minimal: 1,
            };
            usersClient.searchUsers(Spark.state.currentTeam.id, params)
                .then(({ data }) => {
                    this.options = data.data.map(u => ({
                        id: u.id,
                        name: u.name,
                        label: u.name,
                    }));
                })
                .finally(() => {
                    this.loadingOptions = false;
                });
        },
    },
};
</script>
