<template>
  <div v-if="success" class="vx-pt-14">
      <success-box>
          File queued for import!

          <template v-slot:action>
              <vx-button size="lg" href="/contacts/import" color="secondary">
                  View All Imports
                  <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
              </vx-button>
          </template>
      </success-box>
  </div>
  <div v-else class="vx-space-y-8 md:vx-space-y-12 vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8">
      <step-form-heading :icon="faArrowsDownToPeople">
          New Import

          <template v-slot:description>
              Create and update Voxie contacts in bulk.
          </template>
      </step-form-heading>

      <vx-sticky-nav :sections="[
          { id: 'template', label: 'Template' },
          { id: 'status', label: 'Status' },
          { id: 'tags', label: 'Tags' },
          { id: 'upload', label: 'Upload' },
      ]">
          <div class="vx-max-w-screen-md vx-w-full vx-mx-auto vx-flex vx-flex-col vx-gap-2 md:vx-gap-8">
              <contact-import-new-template id="template"></contact-import-new-template>
              <contact-import-new-subscription-status id="status" v-model="subscriptions"></contact-import-new-subscription-status>
              <contact-import-new-tags id="tags" v-model="tags"></contact-import-new-tags>
              <div id="upload">
                  <contact-import-new-upload @selected="selectedFile"></contact-import-new-upload>
              </div>

              <vx-sticky-footer>
                  <vx-button href="/contacts/import"
                      class="vx-w-20"
                      size="lg"
                      color="muted">
                      Cancel
                  </vx-button>
                  <vx-button @click="submit()"
                      data-test="submit-button"
                      :disabled="!file"
                      class="vx-grow"
                      size="lg"
                      color="primary"
                      :loading="submiting">
                      Import
                  </vx-button>
              </vx-sticky-footer>
          </div>
      </vx-sticky-nav>
  </div>
</template>

<script setup>
import { VxButton, VxStickyNav, VxStickyFooter } from '@voxie/frontend-components';
import { ref } from 'vue';
import StepFormHeading from '../../../general/StepFormHeading.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight, faArrowsDownToPeople } from '@fortawesome/pro-solid-svg-icons'
import ContactImportNewTemplate from './ContactImportNewTemplate.vue';
import ContactImportNewSubscriptionStatus from './ContactImportNewSubscriptionStatus.vue';
import ContactImportNewTags from './ContactImportNewTags.vue';
import contactsClient from '../../../../services/contactsClient';
import ContactImportNewUpload from './ContactImportNewUpload.vue';
import { useToasted } from '../../../../composables/useToasted';
import SuccessBox from '../../../general/SuccessBox.vue';

const teamId = Spark.state.currentTeam.id;

const toasted = useToasted();

const file = ref()
const tags = ref([])
const subscriptions = ref([]);

const submiting = ref(false)
const success = ref(false)

const selectedFile = (event) => {
  file.value = event;
}

const submit = async () => {
  if (submiting.value || !file.value) {
      return;
  }
  submiting.value = true;

  try {
      const response = await contactsClient.generateImportUploadUrl(teamId, file.value.name, tags.value, subscriptions.value);
      const uploadUrl = response.data?.uploadUrls?.[0];

      if (!uploadUrl) {
          throw new Error('Could not parsed the upload url');
      }

      await contactsClient.importCsvFile(uploadUrl, file.value);
      success.value = true;
  } catch (error) {
      console.error(error)
      toasted.global.platform_error();
  } finally {
      submiting.value = false;
  }

}
</script>
