<template>
    <div v-on-click-outside="() => showDatePicker = false" class="datepicker-input__wrap">
        <date-time-input
            :model-value="formattedDate"
            :placeholder="placeholder"
            :format="format"
            @focus="showDatePicker = true"
            :library="library"
        />
        <div v-if="showDatePicker" class="datepicker-input__datepicker">
            <date-time-picker v-model="date" mode="date" @update:model-value="emitModelValue" />
        </div>
    </div>
</template>

<script>
import DateTimeInput from './DateTimeInput.vue';
import DateTimePicker from './DateTimePicker.vue';
import { vOnClickOutside as OnClickOutside } from '@vueuse/components'
import dayjs from '~/utils/dayjs';

export default {
    props: {
        modelValue: String,
        placeholder: String,
        format: {
            default: 'MM/DD/YYYY'
        },
        library: Boolean,
    },
    emits: ['update:modelValue'],
    components: {
        DateTimeInput,
        DateTimePicker,
    },
    directives: { OnClickOutside },
    data() {
        return {
            showDatePicker: false,
            date: null,
        };
    },
    watch: {
        modelValue: {
            handler: function (newValue) {
                const parsed = dayjs(newValue, 'YYYY-MM-DD');
                this.date = parsed.isValid() ? parsed.toDate() : null;
            },
            immediate: true
        },
    },
    computed: {
        formattedDate(){
            return this.date ? dayjs(this.date).format(this.format) : null;
        },
    },
    methods: {
        emitModelValue(event) {
            this.$emit('update:modelValue', dayjs(event).format('YYYY-MM-DD'))
        }
    }
};
</script>

<style lang="postcss">
.datepicker-input__datepicker {
  position: absolute;
  z-index: 11;
  width: 275px;
}
.datepicker-input__wrap {
  position: relative;
}
</style>
