<template>

    <vx-expandable-card class="vx-mt-4 vx-relative">
        <template v-slot:header>
            <div class="vx-flex vx-items-center vx-gap-2">
                <font-awesome-icon :icon="props.icon" class="vx-text-base"></font-awesome-icon>
                <div class="vx-text-lg">
                    <slot name="header"></slot>
                </div>
            </div>
            <div v-if="$slots.description" class="vx-text-slate-500 vx-text-sm vx-font-normal">
                <slot name="description"></slot>
            </div>
        </template>
        
        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-6 vx-mt-3 lg:vx-gap-8 lg:vx-mt-5">
                <slot></slot>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxExpandableCard } from '@voxie/frontend-components';

const props = defineProps({
    icon: {
        required: true,
    }
})
</script>
