export const RESERVED_CUTOM_ATTRIBUTE_KEYS = [
    'id',
    'first_name',
    'last_name',
    'email',
    'phone',
    'date_added',
    'tags',
    'marketing_subscription_status',
    'transactional_subscription_status',
];
