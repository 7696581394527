<template>
    <auth-box class="vx-max-w-[480px]">
        <div class="vx-flex vx-flex-col vx-gap-2">
            <span>You are not logged in. Please <a href="/login" target="_blank">login</a> and reload page.</span>
            <vx-button @click="reloadPage()" :loading="loading">Reload page</vx-button>
        </div>
    </auth-box>
</template>
<script setup>
import { ref } from 'vue';
import AuthBox from '~/components/auth/AuthBox.vue';
import { VxButton } from '@voxie/frontend-components';

const loading = ref(false);
const reloadPage = () => {
    if (loading.value) {
        return;
    }

    loading.value = true;
    window.location.reload();
}
</script>
