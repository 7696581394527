<template>
    <div>
        <card v-if="loadingSettings" title="Loading...">
            <div class="d-flex justify-content-center">
                <loading-spinner />
            </div>
        </card>

        <template v-else>
            <!-- Attribution Settings -->
            <attribution-settings :form="form" />

            <!-- Campaign Message Attribution -->
            <campaign-message-attribution :form="form" />

            <!-- User Message Attribution -->
            <user-message-attribution :form="form" />

            <!-- Promotion Attribution -->
            <div v-if="FEATURES.promotions">
                <promotion-attribution :form="form" />
            </div>

            <!-- Discount Code Attribution -->
            <discount-code-attribution :form="form" />

            <div class="form-group mb-0">
                <div class="d-flex justify-content-end">
                    <a :href="analyticsURL" class="c-btn mr-4 c-btn--secondary">Close</a>

                    <button
                        type="submit"
                        class="btn mr-4 btn-primary"
                        @click.prevent="update"
                        :disabled="updateDisabled"
                    >
                        Update
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import AttributionSettings from './AttributionSettings.vue';
import CampaignMessageAttribution from './CampaignMessageAttribution.vue';
import UserMessageAttribution from './UserMessageAttribution.vue';
import PromotionAttribution from './PromotionAttribution.vue';
import DiscountCodeAttribution from './DiscountCodeAttribution.vue';
import LoadingSpinner from '../../../general/LoadingSpinner.vue';
import Card from '../../../general/Card.vue';
import { attributionClient } from '../../../../services';

export default {
    components: {
        AttributionSettings,
        CampaignMessageAttribution,
        UserMessageAttribution,
        PromotionAttribution,
        DiscountCodeAttribution,
        LoadingSpinner,
        Card,
    },
    inject: ['FEATURES'],
    data() {
        return {
            form: {
                attribution_window: 14,
                attribution_types: [ 'campaign_message', 'user_message', 'promotion', 'discount_code' ],
                campaign_attribution_type: 'include',
                selected_campaigns: [],
                user_attribution_type: 'include',
                selected_users: [],
                promotion_attribution_type: 'include',
                selected_promotions: [],
                vx_coupons: [],
            },
            updateDisabled: true,
            analyticsURL: Spark.analyticsHost ? Spark.analyticsHost + '/teams/' + Spark.state.currentTeam.id : '',
            loadingSettings: true,
        };
    },
    created() {
        attributionClient.getConfig(Spark.state.currentTeam.id)
            .then(({ data }) => {
                this.form.attribution_window = data.attribution_window;
                this.form.attribution_types = data.attribution_types;

                if (data.included_campaigns.length) {
                    this.form.campaign_attribution_type = 'include';
                    this.form.selected_campaigns = data.included_campaigns;
                } else {
                    this.form.campaign_attribution_type = 'exclude';
                    this.form.selected_campaigns = data.excluded_campaigns;
                }

                if (data.included_users.length) {
                    this.form.user_attribution_type = 'include';
                    this.form.selected_users = data.included_users;
                } else {
                    this.form.user_attribution_type = 'exclude';
                    this.form.selected_users = data.excluded_users;
                }

                if (data.included_promotions.length) {
                    this.form.promotion_attribution_type = 'include';
                    this.form.selected_promotions = data.included_promotions;
                } else {
                    this.form.promotion_attribution_type = 'exclude';
                    this.form.selected_promotions = data.excluded_promotions;
                }

                this.form.vx_coupons = data.vx_coupons;
            })
            .finally(() => {
                this.loadingSettings = false;

                this.unwatchForm = this.$watch('form', {
                    handler() {
                        this.updateDisabled = false;

                        this.unwatchForm();
                    },
                    deep: true,
                });
            });
    },
    methods: {
        update() {
            Swal.fire({
                title: 'Are you sure?',
                text: 'This will change the way conversions are attributed going forward. Existing attributions will not be changed.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            }).then(({ value }) => {
                if (!value) return;

                if (!this.validate()) {
                    return;
                }

                const updateObject = {
                    attribution_window: this.form.attribution_window,
                    attribution_types: this.form.attribution_types,
                    included_campaigns: this.form.campaign_attribution_type === 'include'
                        ? this.form.selected_campaigns.map(campaign => campaign.id)
                        : [],
                    excluded_campaigns: this.form.campaign_attribution_type === 'exclude'
                        ? this.form.selected_campaigns.map(campaign => campaign.id)
                        : [],
                    included_users: this.form.user_attribution_type === 'include'
                        ? this.form.selected_users.map(user => user.id)
                        : [],
                    excluded_users: this.form.user_attribution_type === 'exclude'
                        ? this.form.selected_users.map(user => user.id)
                        : [],
                    included_promotions: this.form.promotion_attribution_type === 'include'
                        ? this.form.selected_promotions.map(promotion => promotion.id)
                        : [],
                    excluded_promotions: this.form.promotion_attribution_type === 'exclude'
                        ? this.form.selected_promotions.map(promotion => promotion.id)
                        : [],
                    vx_coupons: this.form.vx_coupons,
                };

                attributionClient.storeConfig(Spark.state.currentTeam.id, updateObject)
                    .then(() => {
                        if (this.analyticsURL) {
                            location.href = this.analyticsURL;
                            return;
                        }

                        this.$toasted.global.saved_successfully();
                    })
                    .catch(() => {
                        this.$toasted.global.platform_error();
                    });
            });
        },
        validate() {
            let errors = [];

            if (this.form.selected_campaigns.length > 100) {
                errors.push('Too many campaigns selected');
            }

            if (this.form.selected_users.length > 100) {
                errors.push('Too many users selected');
            }

            if (this.form.selected_promotions.length > 100) {
                errors.push('Too many promotions selected');
            }

            if (this.form.vx_coupons.length > 50) {
                errors.push('Too many discount codes added');
            }

            for (let coupon of this.form.vx_coupons) {
                if(!coupon || coupon.length > 80) {
                    errors.push('Discount code length must be between 1 and 80 characters long');

                    break;
                }
            }

            if (errors.length === 0) {
                return true;
            }

            const errorMessage = '<p>We found the following errors:</p><br />' + errors.join("\n");

            Swal.fire({
                title: 'Oops..',
                html: errorMessage,
                icon: 'error',
            });

            return false;
        },
    },
};
</script>
