<template>
    <div class="vx-text-sm vx-text-slate-900 vx-flex vx-items-center vx-justify-between vx-py-3 vx-px-6 vx-border-b vx-border-x-0 vx-border-t-0 vx-border-solid vx-border-slate-200 last:vx-border-transparent">
        <div class="md:vx-grid vx-grid-cols-12 vx-gap-2 vx-grow">
            <div class="vx-break-all vx-col-span-4">
                {{ phoneNumberNational(props.phoneNumber.phone_number) }}
            </div>
            <div class="vx-break-all vx-col-span-2">
                {{ props.phoneNumber.region || '-' }}
            </div>
            <div class="vx-col-span-3">
                {{ props.phoneNumber.locality || '-' }}
            </div>
            <div class="vx-break-all vx-col-span-3">
                {{ props.phoneNumber.postal_code || '-' }}
            </div>
        </div>
        <div class="vx-shrink-0 vx-w-20 vx-text-right">
            <vx-button type="button"
                color="transparent"
                size="xs"
                class="vx-text-sky-600"
                @click.prevent="emit('selected', props.phoneNumber)">
                Add
                <font-awesome-icon class="vx-text-xs vx-ml-1"
                    :icon="faPlusCircle"></font-awesome-icon>
            </vx-button>
        </div>
    </div>
</template>

<script setup>
import { VxButton } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { phoneNumberNational } from '~/components/filters';

const emit = defineEmits(['selected']);

const props = defineProps({
    phoneNumber: {
        type: Object,
        required: true,
    },
})
</script>
