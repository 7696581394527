<template>
    <div class="pull-right search-container">
        <div class="c-field c-field--inline has-icon-right u-mr-small u-hidden-down@desktop">
            <input v-model="query" @keyup.enter="runSearch" class="c-input" type="text" :placeholder="placeholder" :maxlength="maxlength">
            <span v-if="!query" class="c-field__icon"><i class="fa fa-search"></i></span>
            <span v-if="searching" class="c-field__icon"><i class="fa fa-spin fa-circle-o-notch"></i></span>
            <a v-if="query && !searching" @click.prevent="cancelSearch" href="#" class="c-field__icon"><i class="fa fa-times"></i></a>
        </div>

        <a v-if="helpId"
            @click.prevent
            href="#"
            :id="helpId"
            class="u-hidden-down@desktop u-text-tiny search-help">
            {{ helpLabel }}
        </a>
        <slot></slot>
    </div>

</template>

<script>
import clientRoutes from './../../mixins/client-routes';
import axios from '~/services/axios';

export default {
    props: {
        identity: {
            type: String,
            required: true,
        },
        searchUrl: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: 'Search',
        },
        shouldUpdateState: {
            type: Boolean,
            default: true,
        },
        helpId: [ String ],
        helpLabel: {
            type: String,
            default: 'Search Help',
        },
        method: {
            type: String,
            default: 'post',
        },
        vThree: {
            type: Boolean,
            default: false,
        },
        searchSortParams: [ Array ],
        searchIncludeParams: [ Array ],
        searchFilters: [ Array ],
        searchParams: Object,
        maxlength: [ String, Number ],
    },

    mixins: [clientRoutes],

    data() {
        return {
            query: null,
            searching: false,
        }
    },

    mounted(){

        let route = this.currentClientRoute();

        if(route) {
            if(route.hash == 'search' && route.parameters[0] == this.identity) {
                const decodedQuery = decodeURI(route.parameters[1]);

                this.query = decodedQuery && decodedQuery !== 'null' ? decodedQuery : null;
                this.runSearch();
            }
        }

    },

    watch: {
        searchParams() {
            this.runSearch();
        },
    },

    methods: {

        runSearch() {
            if(this.vThree) {
                this.runNewSearch();
            } else {
                this.runOldSearch();
            }
        },

        runOldSearch() {
            if(this.shouldUpdateState) {
                this.updateState();
            }

            this.searching = true;

            Bus.$emit(`${this.identity}-run`, this.query);

            if (this.method==='get') {
                axios.get(this.searchUrl, {
                    params: {
                        query: this.query,
                        ...this.searchParams,
                    }
                })
                    .then(response => {
                        Bus.$emit(`${this.identity}-results`, response.data);
                    })
                    .catch (error => {
                        console.log(error.response.data);
                        this.$toasted.global.platform_error();
                    })
                    .finally(() => {
                        this.searching = false;
                    });
            }
            else {
                axios.post(this.searchUrl, {'query': this.query})
                    .then(response => {
                        Bus.$emit(`${this.identity}-results`, response.data);
                    })
                    .catch (error => {
                        console.log(error.response.data);
                        this.$toasted.global.platform_error();
                    })
                    .finally(() => {
                        this.searching = false;
                    });
            }
        },

        runNewSearch() {
            if(this.shouldUpdateState) {
                this.updateState();
            }

            this.searching = true;

            Bus.$emit(`${this.identity}-run`, this.query);

            if (this.method==='get') {
                let params = {};

                if(this.searchSortParams && this.searchSortParams.length) {
                    params['sort'] = this.searchSortParams.join();
                }

                if(this.searchIncludeParams && this.searchIncludeParams.length) {
                    params['include'] = this.searchIncludeParams.join();
                }

                if(this.searchFilters && this.searchFilters.length) {
                    const filtersObject = this.searchFilters.reduce((acc, next) => {
                        acc[`filter[${next}]`] = this.query;

                        return acc;
                    }, {});

                    params = { ...params, ...filtersObject };
                } else {
                    params['filter[search]'] = this.query;
                }

                axios.get(this.searchUrl, { params })
                    .then(response => {
                        Bus.$emit(`${this.identity}-results`, response.data);
                    })
                    .catch (error => {
                        console.log(error.response.data);
                        this.$toasted.global.platform_error();
                    })
                    .finally(() => {
                        this.searching = false;
                    });
            }
            else {
                let data = {};

                if(this.searchSortParams && this.searchSortParams.length) {
                    data['sort'] = this.searchSortParams.join();
                }

                if(this.searchIncludeParams && this.searchIncludeParams.length) {
                    data['include'] = this.searchIncludeParams.join();
                }

                if(this.searchFilters && this.searchFilters.length) {
                    const filtersObject = this.searchFilters.reduce((acc, next) => {
                        acc[`filter[${next}]`] = this.query;

                        return acc;
                    }, {});

                    data = { ...data, ...filtersObject };
                } else {
                    data['filter[search]'] = this.query;
                }

                axios.post(this.searchUrl, data)
                    .then(response => {
                        Bus.$emit(`${this.identity}-results`, response.data);
                    })
                    .catch (error => {
                        console.log(error.response.data);
                        this.$toasted.global.platform_error();
                    })
                    .finally(() => {
                        this.searching = false;
                    });
            }
        },

        cancelSearch() {
            this.query = null;
            this.clearClientRoute();
            Bus.$emit(`${this.identity}-cancel`);
        },

        updateState() {
            history.pushState(null, null, '#/search/' + this.identity + '/' + encodeURIComponent(this.query));

            this.broadcastRouteChange(this.identity, this.query);
        }

    }
}
</script>
