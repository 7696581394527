<template>
    <div class="vx-grid vx-gap-6 vx-grid-cols-1 lg:vx-grid-cols-12">
        <analytics-marketing-campaigns-total-active class="lg:vx-col-span-4" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-total-active>
        <analytics-marketing-campaigns-total-contacts-messaged class="lg:vx-col-span-4" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-total-contacts-messaged>
        <analytics-marketing-campaigns-messages-sent class="lg:vx-col-span-4" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-messages-sent>
        <analytics-marketing-campaigns-click-through-rate class="lg:vx-col-span-4" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-click-through-rate>
        <analytics-marketing-campaigns-message-reply-rate class="lg:vx-col-span-4" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:title>
                Message Reply Rate
            </template>
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-message-reply-rate>
        <analytics-marketing-campaigns-opt-out-rate class="lg:vx-col-span-4" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-opt-out-rate>
        <analytics-marketing-campaigns-total-messages-sent-by-campaign class="lg:vx-col-span-6" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-total-messages-sent-by-campaign>

        <analytics-marketing-campaigns-click-through-rate-by-campaign class="lg:vx-col-span-6" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-click-through-rate-by-campaign>

        <analytics-marketing-campaigns-message-reply-rate-top-10 class="lg:vx-col-span-6" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-message-reply-rate-top-10>
        <analytics-marketing-campaigns-opt-out-rate-by-campaign class="lg:vx-col-span-6" :startDate="props.startDate" :endDate="props.endDate">
            <template v-slot:subtitle>
                {{ props.rangeLabel }}
            </template>
        </analytics-marketing-campaigns-opt-out-rate-by-campaign>
    </div>
</template>

<script setup>
import AnalyticsMarketingCampaignsTotalActive from './charts/time-series/AnalyticsMarketingCampaignsTotalActive.vue';
import AnalyticsMarketingCampaignsTotalContactsMessaged from './charts/time-series/AnalyticsMarketingCampaignsTotalContactsMessaged.vue';
import AnalyticsMarketingCampaignsMessagesSent from './charts/time-series/AnalyticsMarketingCampaignsMessagesSent.vue';
import AnalyticsMarketingCampaignsClickThroughRate from './charts/time-series/AnalyticsMarketingCampaignsClickThroughRate.vue';
import AnalyticsMarketingCampaignsMessageReplyRate from './charts/time-series/AnalyticsMarketingCampaignsMessageReplyRate.vue';
import AnalyticsMarketingCampaignsOptOutRate from './charts/time-series/AnalyticsMarketingCampaignsOptOutRate.vue';
import AnalyticsMarketingCampaignsMessageReplyRateTop10 from './charts/time-series/AnalyticsMarketingCampaignsMessageReplyRateTop10.vue';
import AnalyticsMarketingCampaignsTotalMessagesSentByCampaign from './charts/time-series/AnalyticsMarketingCampaignsTotalMessagesSentByCampaign.vue';
import AnalyticsMarketingCampaignsClickThroughRateByCampaign from './charts/time-series/AnalyticsMarketingCampaignsClickThroughRateByCampaign.vue';
import AnalyticsMarketingCampaignsOptOutRateByCampaign from './charts/time-series/AnalyticsMarketingCampaignsOptOutRateByCampaign.vue';

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
    rangeLabel: {
        type: String,
        required: true,
    }
})
</script>
