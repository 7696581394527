<template>
    <div>
        <div class="card card-default">
            <div class="card-header">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span>
                        OAuth Clients
                    </span>

                    <a class="action-link" tabindex="-1" @click="showCreateClientModal = true">
                        Create New Client
                    </a>
                </div>
            </div>

            <div class="card-body">
                <!-- Current Clients -->
                <p class="mb-0" v-if="clients.length === 0">
                    You have not created any OAuth clients.
                </p>

                <table class="table table-responsive table-borderless mb-0" v-if="clients.length > 0">
                    <thead>
                        <tr>
                            <th>Client ID</th>
                            <th>Name</th>
                            <th>Secret</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(client,index) in clients" :key="index">
                            <!-- ID -->
                            <td style="vertical-align: middle;">
                                {{ client.id }}
                            </td>

                            <!-- Name -->
                            <td style="vertical-align: middle;">
                                {{ client.name }}
                            </td>

                            <!-- Secret -->
                            <td style="vertical-align: middle;">
                                <code>{{ client.secret ? client.secret : '-' }}</code>
                            </td>

                            <!-- Edit Button -->
                            <td style="vertical-align: middle;">
                                <a class="action-link" tabindex="-1" @click="edit(client)">
                                    Edit
                                </a>
                            </td>

                            <!-- Delete Button -->
                            <td style="vertical-align: middle;">
                                <a class="action-link text-danger" @click="destroy(client)">
                                    Delete
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Create Client Modal -->
        <modal v-model:visible="showCreateClientModal" title="Create Client" submit-text="Create" @submit="store"
               @shown="$refs.createClientNameInp.focus()">
            <!-- Form Errors -->
            <div class="alert alert-danger" v-if="createForm.errors.length > 0">
                <p class="mb-0"><strong>Whoops!</strong> Something went wrong!</p>
                <br />
                <ul>
                    <li v-for="(error,index) in createForm.errors" :key="index">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <!-- Create Client Form -->
            <form role="form">
                <!-- Name -->
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Name</label>

                    <div class="col-md-9">
                        <input ref="createClientNameInp" type="text" class="form-control"
                               @keyup.enter="store" v-model="createForm.name">

                        <span class="form-text text-muted">
                            Something your users will recognize and trust.
                        </span>
                    </div>
                </div>

                <!-- Redirect URL -->
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Redirect URL</label>

                    <div class="col-md-9">
                        <input type="text" class="form-control" name="redirect"
                                        @keyup.enter="store" v-model="createForm.redirect">

                        <span class="form-text text-muted">
                            Your application's authorization callback URL.
                        </span>
                    </div>
                </div>

                <!-- Confidential -->
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Confidential</label>

                    <div class="col-md-9">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" v-model="createForm.confidential">
                            </label>
                        </div>

                        <span class="form-text text-muted">
                            Require the client to authenticate with a secret. Confidential clients can hold credentials in a secure way without exposing them to unauthorized parties. Public applications, such as native desktop or JavaScript SPA applications, are unable to hold secrets securely.
                        </span>
                    </div>
                </div>
            </form>
        </modal>

        <!-- Edit Client Modal -->
        <modal v-model:visible="showEditClientModal" title="Edit Client" submit-text="Save Changes" @submit="update"
               @shown="$refs.editClientNameInp.focus()">
            <!-- Form Errors -->
            <div class="alert alert-danger" v-if="editForm.errors.length > 0">
                <p class="mb-0"><strong>Whoops!</strong> Something went wrong!</p>
                <br>
                <ul>
                    <li v-for="(error,index) in editForm.errors" :key="index">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <!-- Edit Client Form -->
            <form role="form">
                <!-- Name -->
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Name</label>

                    <div class="col-md-9">
                        <input ref="editClientNameInp" type="text" class="form-control"
                               @keyup.enter="update" v-model="editForm.name">

                        <span class="form-text text-muted">
                            Something your users will recognize and trust.
                        </span>
                    </div>
                </div>

                <!-- Redirect URL -->
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Redirect URL</label>

                    <div class="col-md-9">
                        <input type="text" class="form-control" name="redirect"
                                        @keyup.enter="update" v-model="editForm.redirect">

                        <span class="form-text text-muted">
                            Your application's authorization callback URL.
                        </span>
                    </div>
                </div>
            </form>
        </modal>

        <!-- Client Secret Modal -->
        <modal v-model:visible="showClientSecretModal" title="Client Secret" no-submit-btn>
            <p>
                Here is your new client secret. This is the only time it will be shown so don't lose it!
                You may now use this secret to make API requests.
            </p>

            <input type="text" class="form-control" v-model="clientSecret" />
        </modal>
    </div>
</template>

<script>
    import Modal from '../general/Modal.vue';
    import axios from '~/services/axios';

    export default {
        components: {
            Modal,
        },

        /*
         * The component's data.
         */
        data() {
            return {
                showCreateClientModal: false,
                showEditClientModal: false,
                showClientSecretModal: false,

                clients: [],

                clientSecret: null,

                createForm: {
                    errors: [],
                    name: '',
                    redirect: '',
                    confidential: true
                },

                editForm: {
                    errors: [],
                    name: '',
                    redirect: ''
                }
            };
        },

        /**
         * Prepare the component (Vue 1.x).
         */
        ready() {
            this.prepareComponent();
        },

        /**
         * Prepare the component (Vue 2.x).
         */
        mounted() {
            this.prepareComponent();
        },

        methods: {
            /**
             * Prepare the component.
             */
            prepareComponent() {
                this.getClients();
            },

            /**
             * Get all of the OAuth clients for the user.
             */
            getClients() {
                axios.get('/oauth/clients')
                        .then(response => {
                            this.clients = response.data;
                        });
            },

            /**
             * Create a new OAuth client for the user.
             */
            store() {
                this.persistClient(
                    'post',
                    '/oauth/clients',
                    this.createForm,
                );
            },

            /**
             * Edit the given client.
             */
            edit(client) {
                this.editForm.id = client.id;
                this.editForm.name = client.name;
                this.editForm.redirect = client.redirect;

                this.showEditClientModal = true;
            },

            /**
             * Update the client being edited.
             */
            update() {
                this.persistClient(
                    'put',
                    '/oauth/clients/' + this.editForm.id,
                    this.editForm,
                );
            },

            /**
             * Persist the client to storage using the given form.
             */
            persistClient(method, uri, form) {
                form.errors = [];

                axios[method](uri, form)
                    .then(response => {
                        this.getClients();

                        form.name = '';
                        form.redirect = '';
                        form.errors = [];

                        this.showCreateClientModal = false;
                        this.showEditClientModal = false;

                        if (response.data.plainSecret) {
                            this.showClientSecret(response.data.plainSecret);
                        }
                    })
                    .catch(error => {
                        if (typeof error.response.data === 'object') {
                            form.errors = Object.values(error.response.data.errors).flat();
                        } else {
                            form.errors = ['Something went wrong. Please try again.'];
                        }
                    });
            },

            /**
             * Show the given client secret to the user.
             */
            showClientSecret(clientSecret) {
                this.clientSecret = clientSecret;

                this.showClientSecretModal = true;
            },

            /**
             * Destroy the given client.
             */
            destroy(client) {
                axios.delete('/oauth/clients/' + client.id)
                        .then(() => {
                            this.getClients();
                        });
            }
        }
    }
</script>

<style scoped>
    .action-link {
        cursor: pointer;
    }
</style>
