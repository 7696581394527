export const isLargeTeam = () => {
    // see app/Team.php
    const largeTeamMap = {
        100404: true, // Victra Wireless
        100818: true, // Edible Arrangements
        100823: true, // D.P. Dough
        100978: true, // PJ's Coffee
        101824: true, // Eargo - Marketing
        102472: true, // Jersey Mike's
        103150: true, // Inspire Brands - Sonic
    };

    return Boolean(largeTeamMap[Spark.state.currentTeam.id]);
};

export const isNotActive = () => {
    return Spark.state.currentTeam.status !== 'active';
};

export const displayNotActiveModal = () => {
    $('#modal-not-active-team').modal('show');
};
