<template>
    <div>
        <vx-label class="vx-flex vx-flex-col">
            Category
            <vx-button
                color="muted"
                size="xs"
                type="button"
                @click.prevent="model = undefined"
            >
                Reset Filter
                <font-awesome-icon :icon="faUndo"></font-awesome-icon>
            </vx-button>
        </vx-label>
        <vx-selectable
            data-test="category-filter"
            placeholder="Select Category"
            :clearable="false"
            :options="CATEGORIES"
            v-model="model"
            :reduce="(option) => option.value"
            class="vx-mb-4"
        >
            <template v-slot:option="{ option }">
                <font-awesome-icon fixed-width class="vx-px-1.5 vx-mr-2 vx-text-slate-500 vx-text-xs" :icon="option.icon"></font-awesome-icon>
                {{ option.label }}
            </template>
            <template v-slot:selected-option="{ option }">
                <font-awesome-icon fixed-width class="vx-px-1.5 vx-mr-2 vx-text-slate-500 vx-text-xs" :icon="option.icon"></font-awesome-icon>
                {{ option.label }}
            </template>
        </vx-selectable>
    </div>
</template>

<script setup>
import { VxButton, VxLabel, VxSelectable } from "@voxie/frontend-components";
import { CATEGORIES } from '~/constants/categories';
import { faUndo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const model = defineModel({
    type: String,
    default: '',
})
</script>
