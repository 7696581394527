import BaseClient from './BaseClient';

class ApiDestinationsClient extends BaseClient {
    list(teamId, params) {
        return this.makeCoreApiRequest(`/api/v3/teams/${teamId}/api-destinations`, 'get', null, params);
    }

    create(teamId, data) {
        return this.makeCoreApiRequest(`/api/v3/teams/${teamId}/api-destinations`, 'post', data);
    }

    get(teamId, apiDestinationId) {
        return this.makeCoreApiRequest(`/api/v3/teams/${teamId}/api-destinations/${apiDestinationId}`, 'get');
    }

    update(teamId, apiDestinationId, data) {
        return this.makeCoreApiRequest(`/api/v3/teams/${teamId}/api-destinations/${apiDestinationId}`, 'patch', data);
    }

    delete(teamId, apiDestinationId) {
        return this.makeCoreApiRequest(`/api/v3/teams/${teamId}/api-destinations/${apiDestinationId}`, 'delete');
    }

    countByAuthConfig(teamId, authConfigId) {
        return this.makeCoreApiRequest(
            `/api/v3/teams/${teamId}/api-destinations/count-by-auth-config/${authConfigId}`,
            'get'
        );
    }
}

export default new ApiDestinationsClient();
