<template>
    <div class="vx-relative vx-mb-4 vx-flex vx-flex-col vx-gap-2 vx-text-slate-700">
        <vx-box class="vx-relative vx-flex vx-p-4 vx-gap-2 vx-items-center vx-flex-wrap lg:vx-flex-nowrap lg:vx-py-3 lg:vx-pl-6 lg:vx-pr-3">
            <div class="vx-w-[100px] vx-shrink-0 vx-order-1">
                <font-awesome-icon :icon="faTurnDownRight"
                    class="vx-mr-2 vx-text-sky-500"></font-awesome-icon>
                <span class="vx-whitespace-nowrap vx-text-slate-800 vx-text-base vx-font-extrabold">
                    {{ props.actionIndex === 0 ? 'Do' : 'Then do' }}
                </span>
            </div>
            <div data-test="action-wrapper" class="vx-grow vx-w-full vx-order-2 lg:vx-order-1 lg:vx-w-auto">
                <vx-selectable placeholder="automated action"
                    :reduce="option => option.value"
                    :getOptionLabel="getLabel"
                    v-model="action.automation"
                    :options="actions"
                    :clearable="false"
                    :error="displayFirstError(validate.automation)"
                    >
                </vx-selectable>
            </div>

            <div class="vx-flex vx-items-center vx-justify-end vx-gap-2 vx-ml-auto vx-order-1 lg:vx-ml-0 lg:vx-order-2">
                <vx-toggle :model-value="action.status === 'published'"
                    @update:model-value="action.status = $event ? 'published' : 'draft'"
                    size="md">
                    <template v-slot:no>Off</template>
                    <template v-slot:yes>On</template>
                </vx-toggle>

                <vx-dropdown placement="right">
                    <vx-button size="md"
                        type="button"
                        color="muted-light">
                        <font-awesome-icon :icon="faEllipsis"></font-awesome-icon>
                    </vx-button>
                    <template v-slot:items>
                        <vx-dropdown-item @click.prevent="duplicateAction(props.actionIndex)"
                            type="button">
                            Duplicate
                            <font-awesome-icon :icon="faCopy"></font-awesome-icon>
                        </vx-dropdown-item>
                        <vx-dropdown-item @click.prevent="removeAction(props.actionIndex)"
                            type="button">
                            Delete
                            <font-awesome-icon :icon="faTrash"></font-awesome-icon>
                        </vx-dropdown-item>
                        <vx-dropdown-item :disabled="actionIndex === 0"
                            @click.prevent="moveAction(props.actionIndex, 'up')"
                            type="button">
                            Move Up
                            <font-awesome-icon :icon="faArrowUp"></font-awesome-icon>
                        </vx-dropdown-item>
                        <vx-dropdown-item :disabled="actionIndex + 1 === data.automated_actions.length"
                            @click.prevent="moveAction(props.actionIndex, 'down')"
                            type="button">
                            Move Down
                            <font-awesome-icon :icon="faArrowDown"></font-awesome-icon>
                        </vx-dropdown-item>
                    </template>
                </vx-dropdown>
            </div>

        </vx-box>

        <vx-box v-show="showBox"
            class="vx-relative vx-p-4 vx-ml-10 lg:vx-ml-[122px] lg:vx-p-6">
            <div
                class="vx-absolute vx-top-9 -vx-left-[25px] vx-w-6 vx-h-px vx-border-solid vx-border-y vx-border-x-0 vx-border-t-slate-300 vx-border-b-white lg:-vx-left-20 lg:vx-w-[78px]">
            </div>

            <message-send v-if="action.automation == 'message_send'"
                v-model="action.data">
            </message-send>

            <notify-message-action v-else-if="action.automation == 'notify_message_action'"
                v-model="action.data">
            </notify-message-action>

            <extract-data v-else-if="action.automation == 'extract_data'"
                v-model="action.data">
            </extract-data>

            <campaign-add-remove-contact
                v-else-if="action.automation == 'campaign_add_contact' || action.automation === 'campaign_remove_contact'"
                :flowId="data.flow_id"
                v-model="action.data">
            </campaign-add-remove-contact>

            <contact-add-remove-group
                v-else-if="action.automation == 'contact_add_group' || action.automation == 'contact_remove_group'"
                :automation="action.automation"
                v-model="action.data">
            </contact-add-remove-group>

            <contact-add-remove-tag
                v-else-if="action.automation == 'contact_add_tag' || action.automation == 'contact_remove_tag'"
                v-model="action.data">
            </contact-add-remove-tag>

            <contact-add-update-custom-attribute v-else-if="action.automation == 'contact_add_update_custom_attribute'"
                v-model="action.data">
            </contact-add-update-custom-attribute>

            <contact-remove-custom-attribute v-else-if="action.automation == 'contact_remove_custom_attribute'"
                v-model="action.data">
            </contact-remove-custom-attribute>

            <force-use-event-contact-true
                v-else-if="action.automation == 'contact_archive_messages' || action.automation == 'contact_unsubscribe' || contactSubscriptionAction"
                v-model="action.data">
            </force-use-event-contact-true>

            <invoke-api-destination v-else-if="action.automation == 'invoke_api_destination'"
                :event="data.event"
                v-model="action.data">
            </invoke-api-destination>

        </vx-box>
    </div>
</template>

<script setup>
import { faArrowDown, faArrowUp, faCopy, faEllipsis, faTrash, faTurnDownRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBox, VxButton, VxDropdown, VxDropdownItem, VxSelectable, VxToggle } from '@voxie/frontend-components';
import { computed } from 'vue';
import CampaignAddRemoveContact from '~/components/automation/automations/actions/CampaignAddRemoveContact.vue';
import ContactAddRemoveGroup from '~/components/automation/automations/actions/ContactAddRemoveGroup.vue';
import ContactAddRemoveTag from '~/components/automation/automations/actions/ContactAddRemoveTag.vue';
import ContactAddUpdateCustomAttribute from '~/components/automation/automations/actions/ContactAddUpdateCustomAttribute.vue';
import ForceUseEventContactTrue from '~/components/automation/automations/actions/ForceUseEventContactTrue.vue';
import ContactRemoveCustomAttribute from '~/components/automation/automations/actions/ContactRemoveCustomAttribute.vue';
import ExtractData from '~/components/automation/automations/actions/ExtractData.vue';
import InvokeApiDestination from '~/components/automation/automations/actions/InvokeApiDestination.vue';
import MessageSend from '~/components/automation/automations/actions/MessageSend.vue';
import NotifyMessageAction from '~/components/automation/automations/actions/NotifyMessageAction.vue';
import { useAutomationRule } from './useAutomationRule';
import { useAutomationRulesMetadata } from './useAutomationRulesMetadata';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { displayFirstError } from '~/utils/validation';

const { actions } = useAutomationRulesMetadata();
const { removeAction, duplicateAction, moveAction, data } = useAutomationRule();

const action = defineModel();
const props = defineProps({
    actionIndex: {
        type: Number,
        required: true,
    }
});

const contactSubscriptionAction = computed(() => {
    return [
        'contact_marketing_subscription_opt_in',
        'contact_marketing_subscription_opt_out',
        'contact_transactional_subscription_opt_in',
        'contact_transactional_subscription_opt_out'
    ].includes(action.value.automation)
});

const showBox = computed(() => {
    if (!action.value.automation) {
        return false;
    }
    if (contactSubscriptionAction.value) {
        return false;
    }

    if (['contact_archive_messages', 'contact_unsubscribe', 'mark_as_handled'].includes(action.value.automation)) {
        return false;
    }

    return true;
});

const validate = useVuelidate({
    automation: {
        required: helpers.withMessage('Action is required', required),
    }
}, action);

const getLabel = (option) => {
    if (typeof option === 'object') {
        return option.label.toLowerCase();
    } else if (typeof option === 'string') {
        return option.toLowerCase();
    } else {
        return option;
    }
}
</script>
