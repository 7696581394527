<template>
    <card title="Account Status">
        <template v-slot:right-heading>
            <badge :type="status === 'active' ? 'success' : 'warning'">
                {{ status.toLocaleUpperCase() }}
            </badge>
        </template>
        <div class="row">
            <div
                :class="{
                    'col-md-9': impersonator,
                    'col-md-12': !impersonator
                }"
            >
                <template v-if="status === 'closed'">
                    This Account is permanently Closed. Further usage will require a new Account.
                </template>
                <template v-else>
                    <template v-if="impersonator">
                        <template v-if="status === 'active'">
                            This Account has been successfully activated.
                        </template>
                        <template v-else-if="status === 'suspended'">
                            Activate the Account if Suspension reasons have been rectified.
                        </template>
                        <template v-else>
                            Activate the Account for the first time once the Subscription and Payment Method are validated.
                        </template>
                    </template>
                    <template v-else>
                        <template v-if="status === 'active'">
                            Your account is Active, all capabilities are ready to be used.
                        </template>
                        <template v-else-if="status === 'suspended'">
                            Your Account is in Suspended status, please contact your Account Administrator to rectify the account situation.
                        </template>
                        <template v-else>
                            Your Account is in pending status, our team needs to validate your plan and payment method to proceed.
                        </template>
                    </template>
                </template>
            </div>
            <div class="col-md-3 text-right" v-if="impersonator && status !== 'closed'">
                <dropdown-actions label="Actions">
                    <template v-for="status in statusActions" :key="status.type">
                        <button
                            class="c-dropdown__item dropdown-item"
                            :data-test="status.type"
                            type="button"
                            @click.prevent="status.method"
                        >
                            {{ status.name }}
                        </button>
                    </template>
                </dropdown-actions>
            </div>
        </div>

        <modal-activate-confirm
            v-model:visible="modalActivateConfirmVisible"
            :suspended="status === 'suspended'"
            @submitActivate="activate"
        />

        <modal-suspend-confirm
            v-model:visible="modalSuspendConfirmVisible"
            v-model:confirm="modalSuspendConfirmation"
        />

        <modal-suspend-reason
            v-model:visible="modalSuspendConfirmation"
            @submitSuspend="suspendReasonConfirmation"
        />
    </card>
</template>

<script>
import Card from '../../general/Card.vue';
import DropdownActions from '../../general/DropDownActions.vue';
import Badge from '../../general/Badge.vue';
import { teamsClient } from '../../../services';
import ModalSuspendConfirm from './ModalSuspendConfirm.vue';
import ModalActivateConfirm from './ModalActivateConfirm.vue';
import ModalSuspendReason from './ModalSuspendReason.vue';

export default {
    components: {
        ModalSuspendReason,
        ModalSuspendConfirm,
        ModalActivateConfirm,
        Card,
        DropdownActions,
        Badge,
    },
    props: {
        team: {
            required: true
        },
        impersonator: {
            default: false
        }
    },
    data() {
        return {
            status: this.team.status || 'pending',
            modalActivateConfirmVisible: false,
            modalSuspendConfirmVisible: false,
            modalSuspendConfirmation: false,
            modalSuspendReasonVisible: false,
        }
    },
    computed: {
        statusActions() {
            switch (this.status) {
                case 'suspended':
                case 'pending':
                    return [{ type: 'active', name: 'Activate', method: this.showModalActivateConfirm }]
                case 'active':
                    return [{ type: 'suspended', name: 'Suspend', method: this.showModalSuspendConfirm }]
                default:
                    return []
            }
        }
    },
    methods: {
        activate() {
            teamsClient.activate(this.team.id)
                .then(() => {
                    this.status = 'active'
                })
                .catch((err) => {
                    console.error(err)
                    this.$toasted.global.platform_error();
                })
        },
        showModalSuspendConfirm() {
            this.modalSuspendConfirmVisible = true;
        },
        showModalActivateConfirm() {
            this.modalActivateConfirmVisible = true;
        },
        suspendReasonConfirmation(reason) {
            teamsClient.suspend(this.team.id, { reason })
                .then(() => {
                    this.status = 'suspended'
                })
                .catch((err) => {
                    console.error(err)
                    this.$toasted.global.platform_error();
                })
        }
    }
};
</script>
