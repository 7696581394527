<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        :padding="false"
        size="md">
        <template v-slot:header>
            <div class="vx-px-6 vx-pt-6">
                Schedule Message
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">
                Sends message at a later date.
            </div>
        </template>

        <form id="message-builder-schedule"
            @submit.prevent="submit"
            class="vx-px-6 vx-py-12 vx-flex vx-flex-col vx-items-center vx-gap-2">
            <div class="vx-text-center vx-text-sm vx-text-slate-700">
                {{ dateFormatted }}
            </div>
            <vx-date-picker :key="minDateKey"
                v-model="date"
                mode="datetime"
                :timezone="getClientTz()"
                :minDate="minDate"
                is-required></vx-date-picker>
        </form>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="emit('update:visible', false)"
                    size="lg"
                    color="muted">
                    Cancel
                    <font-awesome-icon :icon="faCircleX"></font-awesome-icon>
                </vx-button>
                <vx-button form="message-builder-schedule"
                    type="submit"
                    size="lg"
                    color="primary"
                    class="lg:vx-grow">
                    Schedule Message
                    <font-awesome-icon :icon="faPaperPlane"></font-awesome-icon>
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleX, faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { VxModal, VxButton, VxDatePicker } from '@voxie/frontend-components';
import { computed, ref, watch } from 'vue';
import { formatTz, getClientTz } from '../../../../utils/date';
import dayjs, { toUTCDateTimeFormat } from '~/utils/dayjs';
import { useInterval } from '@vueuse/core';

const props = defineProps({
    visible: Boolean,
});

const minDate = computed(() => dayjs().tz(getClientTz()).add(15, 'minutes').toDate());
const date = ref(dayjs().tz(getClientTz()).add(1, 'hour').toDate());

const dateFormatted = computed(() => {
    const timezone = dayjs(date.value).tz(getClientTz()).format('z');

    return dayjs(date.value).tz(getClientTz()).format("MMM DD, YYYY [at] h:mm a") +
        ' ' +
        formatTz(timezone)
});

const minDateKey = useInterval(60000);

const emit = defineEmits(['update:visible', 'schedule']);

watch(() => props.visible, () => {
    date.value = dayjs().tz(getClientTz()).add(1, 'hour').toDate();
})

const submit = async () => {
    emit('schedule', toUTCDateTimeFormat(dayjs(date.value).tz(getClientTz())));
    emit('update:visible', false)
}
</script>
