<template>
    <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8"
        :class="{
            'vx-space-y-8 md:vx-space-y-12': editing,
            '2xl:vx-max-w-screen-xl': !editing
        }">
        <step-form-heading v-if="!editing"
            :icon="faShareNodes">
            New Custom Flow

            <template v-slot:description>
                Group a set of resources together.
            </template>
        </step-form-heading>

        <page-header v-else
            class="vx-mb-6 lg:vx-mb-12">
            <template v-slot:subtitle>
                <a href="/automation/flows"
                    class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                    <font-awesome-icon class="vx-mr-1"
                        :icon="faArrowLeft"></font-awesome-icon>
                    All Flows
                </a>
            </template>

            {{ name }}
        </page-header>

        <div class="vx-grid vx-grid-cols-12">
            <custom-flow-sidebar v-if="editing" :flowId="props.flow?.flow_id"></custom-flow-sidebar>

            <div v-if="loading"
                class="vx-col-span-12 vx-text-center vx-py-10"
                :class="{
                    'lg:vx-col-span-10': editing,
                }">
                <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
            </div>
            <vx-sticky-nav v-else
                :sections="[
                    { id: 'name', label: 'Name' },
                    { id: 'notes', label: 'Description' },
                    { id: 'category', label: 'Category' },
                ]"
                class="vx-col-span-12"
                :class="{
                    'lg:vx-col-span-10': editing,
                }"
                :navClasses="`${editing ? 'vx-hidden' : ''}`">

                <div class="vx-flex vx-flex-col vx-max-w-screen-md vx-w-full vx-justify-center vx-gap-2 md:vx-gap-8"
                    :class="{
                        'vx-mx-auto': !editing,
                    }">
                    <vx-alert v-if="updated" color="success"
                        :closeable="false"
                        shadow>
                        <template v-slot:icon>
                            <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                        </template>
                        <div class="vx-flex vx-items-center vx-justify-between">
                            <span>Updated successfully</span>
                            <vx-button href="/automation/flows" size="sm"
                                color="success">Back to Flows <font-awesome-icon
                                    :icon="faSquareArrowUpLeft"></font-awesome-icon></vx-button>
                        </div>
                    </vx-alert>

                    <vx-expandable-card id="name">
                        <template v-slot:header> Name </template>

                        <template v-slot:content>
                            <vx-input v-model="name"
                                data-test="flow-name"
                                :error="errors.name || displayFirstError(validate.name.$errors)"
                                @blur="validate.name.$touch()"
                                autofocus
                                size="lg"
                                :maxlength="80"></vx-input>
                        </template>
                    </vx-expandable-card>
                    <vx-expandable-card id="notes">
                        <template v-slot:header> Description </template>

                        <template v-slot:content>
                            <vx-input v-model="notes"
                                data-test="flow-notes"
                                :error="errors.notes || displayFirstError(validate.notes.$errors)"
                                @blur="validate.notes.$touch()"
                                autofocus
                                autoresize
                                :rows="3"
                                size="lg"
                                :maxlength="2000"></vx-input>
                        </template>
                    </vx-expandable-card>

                    <resource-category id="category" v-model="category"></resource-category>

                    <div v-if="error">
                        <vx-alert color="danger"
                            :closeable="false">
                            {{ error }}
                        </vx-alert>
                    </div>

                    <vx-sticky-footer class="!vx-flex-col lg:vx-flex lg:!vx-flex-row">
                        <vx-button href="/automation/flows"
                            class="vx-order-3 lg:vx-w-20 lg:vx-order-1"
                            size="lg"
                            color="muted-dark"
                            outline>
                            Cancel
                        </vx-button>
                        <vx-button @click="submit()"
                            data-test="submit"
                            :loading="saving"
                            type="button"
                            class="vx-order-1 lg:vx-grow lg:vx-order-3"
                            size="lg"
                            color="primary">
                            {{ editing ? 'Update Flow' : 'Create Flow' }}
                        </vx-button>
                    </vx-sticky-footer>
                </div>
            </vx-sticky-nav>
        </div>
    </div>
</template>

<script setup>
import { faArrowLeft, faCircleCheck, faShareNodes, faSquareArrowUpLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxAlert, VxButton, VxExpandableCard, VxIconLoading, VxInput, VxStickyFooter, VxStickyNav } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ref, watch, computed } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';
import { useToasted } from '~/composables/useToasted';
import flowsClient from '~/services/flowsClient';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_UNPROCESSABLE_ENTITY } from '~/support/HttpStatuses';
import { displayFirstError } from '~/utils/validation';
import StepFormHeading from '~/components/general/StepFormHeading.vue';
import CustomFlowSidebar from './CustomFlowSidebar.vue';
import ResourceCategory from '~/components/general/categories/ResourceCategory.vue';

const toasted = useToasted();

const props = defineProps({
    flow: {
        type: [Object, undefined],
        default: undefined,
    }
})

const teamId = Spark.state.currentTeam.id;

const editing = computed(() => Boolean(props.flow?.flow_id));

const id = ref(props.flow?.flow_id);
const name = ref('');
const notes = ref('');
const category = ref('');

const validate = useVuelidate({
    name: { required },
    notes: { required },
}, {
    name: name,
    notes: notes,
});

const loading = ref(false);

const prepareForm = async () => {
    if (loading.value) {
        return
    }

    try {
        loading.value = true;

        if (props.flow) {
            id.value = props.flow.flow_id;

            name.value = props.flow.name;
            notes.value = props.flow.notes;
            category.value = props.flow.category;
        }

    } catch (e) {
        console.error(e);

        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
}

watch(() => props.flow?.id, async () => {
    prepareForm();
}, {
    immediate: true
});

const updated = ref(false);

const error = ref(undefined);
const errors = ref({});
const saving = ref(false);

const submit = async () => {
    const isValid = await validate.value.$validate();

    if (!isValid) {
        toasted.error('Please check form data.');
        return;
    }

    if (saving.value) {
        return;
    }

    try {
        saving.value = true;
        errors.value = {};

        const { data } = await flowsClient.createOrUpdate(teamId, {
            flow_id: id.value,
            name: name.value,
            notes: notes.value,
            category: category.value
        });

        id.value = data.flow_id;

        if (editing.value) {
            updated.value = true;
        } else {
            window.location.href = `/automation/flows/${id.value}/resources`
        }
    } catch (e) {
        console.error(e);

        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
            return
        } else if (e.response.status === HTTP_UNPROCESSABLE_ENTITY) {
            errors.value = e.response.data.errors;
        } else {
            error.value = e.response?.data?.message || 'Something went wrong.';
        }

        toasted.error('Please check form data.');
    } finally {
        saving.value = false;
    }
}


</script>
