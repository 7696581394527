<template>
    <div class="row">
        <div class="col-md-10 offset-md-1 u-mt-small">
            <nav class="c-pagination u-justify-center">
                <a class="d-flex align-items-center c-pagination__control u-text-dark" :href="pageHref(1)" @click="loadPage(1)" data-router="client-route">
                    <span class="u-text-large mr-1">&laquo;</span> First
                </a>
                <a class="d-flex align-items-center c-pagination__control u-text-dark" :href="prevHref" @click="loadPrevPage" data-router="client-route">
                    <i class="fa fa-circle-o-notch fa-spin" v-if="prevPending"></i>
                    <span v-if="! prevPending" class="u-text-large mr-1">&lsaquo;</span>
                    Prev
                </a>
                <p class="c-pagination__counter">Page {{ currentPage }} of {{ totalPages }}</p>
                <a class="d-flex align-items-center c-pagination__control u-text-dark" :href="nextHref" @click="loadNextPage" data-router="client-route">
                    Next
                    <i class="fa fa-circle-o-notch fa-spin" v-if="nextPending"></i>
                    <span v-if="! nextPending" class="u-text-large ml-1">&rsaquo;</span>
                </a>
                <a class="d-flex align-items-center c-pagination__control u-text-dark" :href="pageHref(totalPageCount)" @click="loadPage(totalPageCount)" data-router="client-route">
                    Last <span class="u-text-large ml-1">&raquo;</span>
                </a>
            </nav>
        </div>
    </div>
</template>

<script>
    import axios from '~/services/axios';
    import clientRoutes from './../../mixins/client-routes';
    import scrollTo from './../../mixins/scroll-to';

    export default {
        props: [
            'identity',
            'totalPageCount',
            'prevPage',
            'nextPage'
        ],

        mixins: [
            clientRoutes,
            scrollTo
        ],

        data () {
            return {
                prevPageUrl: null,
                nextPageUrl: null,
                nextPending: false,
                prevPending: false,
                currentPage: 1,
                totalPages: 0
            }
        },

        computed: {
            nextHref: function(){
                let href = '#';

                if(this.nextPageUrl){
                    href += '/' + this.identity + '/' + (this.currentPage + 1);
                }

                return href;
            },

            prevHref: function(){
                let href = '#';

                if(this.prevPageUrl){
                    href += '/' + this.identity + '/' + (this.currentPage - 1);
                }

                return href;
            }
        },

        created () {
            this.totalPages = this.totalPageCount;
            this.prevPageUrl = this.prevPage;
            this.nextPageUrl = this.nextPage;

            let route = this.currentClientRoute();

            if(route && route.hash === this.identity){
                this.currentPage = parseInt(route.parameters[0]);
            }
        },

        watch: {
            totalPageCount: function (newCount) {
                this.totalPages = newCount;
            },
            // PrevPage comes us null from the parent - so it will never change
            // what we should do is to reset the page (current page)
            prevPage: function(n) {
                this.prevPageUrl = n;
            },
            nextPage: function(n) {
                this.nextPageUrl = n;

                //reset the page to 1
                this.currentPage = 1;
            }

        },

        methods: {
            pageHref(page) {
                return '#/' + this.identity + '/' + page;
            },

            loadPage(page) {
                let url = (this.prevPageUrl || this.nextPageUrl).replace(/\bpage=[0-9]+/, `page=${page}`);

                axios.get(url).then((response) => {
                    Bus.$emit(`${this.identity}-collection-updated`, response.data.total, response.data.data);

                    this.currentPage = response.data.current_page;
                    this.totalPages = response.data.last_page;
                    this.prevPageUrl = response.data.prev_page_url;
                    this.nextPageUrl = response.data.next_page_url;

                    this.scrollToPageTop();
                }).catch((error) => {
                    console.log(error.response.data);
                    this.$toasted.global.platform_error();
                });
            },

            loadPrevPage() {
                if(this.prevPageUrl != null && !this.prevPending){
                    this.prevPending = true;

                    axios.get(this.prevPageUrl).then(response => {
                        this.prevPending = false;

                        Bus.$emit(
                            `${this.identity}-collection-updated`,
                            response.data.total,
                            response.data.data);

                        this.currentPage = response.data.current_page;
                        this.totalPages = response.data.last_page;
                        this.prevPageUrl = response.data.prev_page_url;
                        this.nextPageUrl = response.data.next_page_url;

                        this.scrollToPageTop();
                    })
                    .catch (error => {
                        console.log(error.response.data);
                        this.$toasted.global.platform_error();
                    });
                }
            },


            loadNextPage() {
                if(this.nextPageUrl != null && !this.nextPending){
                    this.nextPending = true;

                    axios.get(this.nextPageUrl).then(response => {
                        this.nextPending = false;

                        Bus.$emit(
                            `${this.identity}-collection-updated`,
                            response.data.total,
                            response.data.data);

                        this.currentPage = response.data.current_page;
                        this.totalPages = response.data.last_page;
                        this.prevPageUrl = response.data.prev_page_url;
                        this.nextPageUrl = response.data.next_page_url;

                        this.scrollToPageTop();
                    })
                    .catch (error => {
                        console.log(error.response.data);
                        this.$toasted.global.platform_error();
                    });
                }
            }
        }
    }
</script>
