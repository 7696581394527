<template>
    <page-controls>
        <template v-slot:left>
            <div class="vx-grow @3xl/controls:vx-max-w-md">
                <vx-filterable :active="active" @toggle="toggleFilterable" data-test="segment-filters">
                    <template v-slot:placeholder>
                        <span class="vx-mr-2">Find Segments</span>
                        <vx-badge
                            v-if="filtersCount > 0"
                            :borders="false"
                            size="xs"
                            @click.stop="reset"
                        >
                            {{ filtersCount }} filters
                            <font-awesome-icon :icon="faXmarkCircle" />
                        </vx-badge>
                    </template>
                    <template v-slot:form>
                        <form @submit.prevent="apply">
                            <vx-label class="vx-mb-1">
                                Keyword Search
                            </vx-label>
                            <vx-input
                                autofocus
                                type="text"
                                data-test="segment-controls-query"
                                placeholder="Search"
                                maxlength="96"
                                v-model="filters.query"
                                class="vx-mb-4"
                            >
                                <template v-slot:right>
                                    <font-awesome-icon
                                        class="vx-text-xs"
                                        :icon="faMagnifyingGlass"
                                    />
                                </template>
                            </vx-input>

                            <vx-label class="vx-flex vx-flex-col">
                                Audience Type
                                <vx-button
                                    color="muted"
                                    size="xs"
                                    type="button"
                                    @click="
                                        filters['filter[audience_type]'] = undefined
                                    "
                                >
                                    Reset Filter
                                    <font-awesome-icon :icon="faUndo"></font-awesome-icon>
                                </vx-button>
                            </vx-label>
                            <vx-selectable
                                placeholder="Select Audience"
                                :options="audienceTypeOptions"
                                v-model="filters['filter[audience_type]']"
                                :reduce="(option) => option.value"
                                class="vx-mb-4"
                            >
                            </vx-selectable>

                            <vx-label class="vx-flex vx-flex-col">
                                Refresh Type
                                <vx-button
                                    color="muted"
                                    size="xs"
                                    type="button"
                                    @click="
                                        filters['filter[refresh_type]'] = undefined
                                    "
                                >
                                    Reset Filter
                                    <font-awesome-icon :icon="faUndo"></font-awesome-icon>
                                </vx-button>
                            </vx-label>
                            <vx-selectable
                                data-test="refresh-type"
                                placeholder="Select Type"
                                :options="refreshTypeOptions"
                                v-model="filters['filter[refresh_type]']"
                                :reduce="(option) => option.value"
                                class="vx-mb-4"
                            >
                            </vx-selectable>

                            <category-filter-select
                                v-model="filters['filter[category]']"
                            >
                            </category-filter-select>

                            <in-flow-filterable-item v-if="FEATURES.flows" class="vx-mb-4"
                                v-model="filters['filter[flow]']">
                            </in-flow-filterable-item>

                            <div class="vx-flex vx-gap-2">
                                <vx-button
                                    type="button"
                                    color="muted-light"
                                    @click="cancel"
                                >
                                    Cancel
                                </vx-button>
                                <vx-button
                                    data-test="segment-controls-apply"
                                    type="submit"
                                    block
                                    @click="apply"
                                >
                                    Apply
                                    <font-awesome-icon
                                        :icon="faCircleCheck"
                                    ></font-awesome-icon>
                                </vx-button>
                            </div>
                        </form>
                    </template>
                </vx-filterable>
            </div>
            <div class="vx-flex vx-w-full vx-gap-2 @xl/controls:vx-w-auto">
                <vx-button-group size="lg" class="vx-w-full">
                    <vx-select
                        v-model="filters.sort_field"
                        @update:modelValue="apply"
                        :options="[
                            {
                                value: 'name',
                                label: 'Sort by: Name',
                            },
                            {
                                value: 'id',
                                label: 'Sort by: Created',
                            },
                        ]"
                    ></vx-select>
                    <vx-button
                        @click.prevent="
                            filters.sort_dir = filters.sort_dir === 'asc' ? 'desc' : 'asc';
                            apply();
                        "
                        type="button"
                        color="white"
                        shadow
                    >
                        <font-awesome-icon
                            :icon="
                                filters.sort_dir === 'asc'
                                    ? faArrowUpWideShort
                                    : faArrowDownWideShort
                            "
                        ></font-awesome-icon>
                    </vx-button>
                </vx-button-group>
            </div>
        </template>
        <template v-slot:right>
            <vx-button
                block
                href="/contacts/segments/new"
                size="lg"
                color="primary"
            >
                New Segment
                <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
            </vx-button>
        </template>
    </page-controls>
</template>

<script setup>
import { computed, ref, inject, watch, nextTick } from 'vue';
import {
    VxBadge,
    VxLabel,
    VxButton,
    VxButtonGroup,
    VxFilterable,
    VxInput,
    VxSelectable,
    VxSelect,
} from "@voxie/frontend-components";
import {
    faCircleCheck,
    faMagnifyingGlass,
    faXmarkCircle,
    faArrowDownWideShort,
    faArrowUpWideShort,
    faCirclePlus,
    faUndo,
} from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { audienceTypes } from '../../../constants/campaign';
import { SegmentTypeToLabel } from '../../../constants/segment';

import PageControls from '../../general/PageControls.vue';
import InFlowFilterableItem from '~/components/automation/flows/components/InFlowFilterableItem.vue';
import CategoryFilterSelect from '~/components/general/categories/CategoryFilterSelect.vue';

const FEATURES = inject('FEATURES');

const audienceTypeOptions = computed(() =>
    audienceTypes().map((audienceType) => ({
        label: audienceType.label,
        value: audienceType.name,
    }))
);

const refreshTypeOptions = computed(() =>
    Object.keys(SegmentTypeToLabel).map((refreshType) => ({
        value: refreshType,
        label: SegmentTypeToLabel[refreshType],
    }))
);

const filters = ref({})
const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});
watch(() => props.modelValue, (modelValue) => {
    filters.value = {...modelValue};
}, {
    deep: true,
    immediate: true,
})

const emit = defineEmits(["update:modelValue", "apply", "reset"]);

const active = ref(false);

const toggleFilterable = (event) => {
    active.value = event;
};

const isFlowFilterApplied = computed(() => {
    if (!props.modelValue['filter[flow]']) {
        return false;
    }

    return props.modelValue['filter[flow]'].in_flow !== undefined || !!(props.modelValue['filter[flow]'].flow_id)
});

const filtersCount = computed(
    () =>
        (props.modelValue['filter[audience_type]'] ? 1 : 0) +
        (props.modelValue['filter[refresh_type]'] ? 1 : 0) +
        (props.modelValue.query ? 1 : 0) +
        (isFlowFilterApplied.value ? 1 : 0) +
        (props.modelValue['filter[category]']?.length ? 1 : 0)
);



const reset = () => {
    active.value = false;
    emit("reset");
};

const cancel = async () => {
    filters.value = { ...props.modelValue }
    active.value = false;
}

const apply = async () => {
    active.value = false;
    emit('update:modelValue', { ...filters.value });
    await nextTick();
    emit('apply');
}
</script>
