<template>
    <div class="salesforce-integration">
        <div class="card card-default">
            <div class="card-header">
                <div class="pull-left">
                    <div class="vx-flex vx-items-center">
                        <span>Salesforce Credentials</span>
                        <experimental-pill class="vx-ml-2" type="alpha"></experimental-pill>
                    </div>
                </div>

                <div v-if="hasPermission" class="pull-right">
                    <vx-badge :color="authStatus === 'success' ? 'success' : 'grey'">
                        {{ authStatus === 'success' ? 'Connected' : 'Pending' }}
                    </vx-badge>
                </div>
            </div>
            <div v-if="!hasPermission" class="card-body">
                Only the team owner can configure integrations.
            </div>
            <form
                v-else
                ref="urlForm"
                class="card-body"
                role="form"
                :action="`/teams/${teamId}/integrations/salesforce/oauth`"
                method="post"
                @submit.prevent="createCredentials"
                >

                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Base URL</label>

                    <div class="col-md-6">
                        <vx-input
                            type="text"
                            name="salesforce_domain"
                            placeholder="my-instance.my.salesforce.com"
                            v-model="salesforceDomain"
                            required
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right"></label>
                    <div class="col-md-6">
                        <vx-button type="submit" class="btn btn-primary">
                            Authenticate
                        </vx-button>
                    </div>
                </div>
            </form>
        </div>

        <div class="card card-default" v-if="authStatus === 'success' && hasPermission">
            <div class="card-header">
                <div class="pull-left">
                    <div class="vx-flex vx-items-center">
                        <span>Salesforce Field Mapping</span>
                        <experimental-pill class="vx-ml-2" type="alpha"></experimental-pill>
                    </div>
                </div>
            </div>

            <div class="salesforce-config__row salesforce-config__row--header">
                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">Voxie Attribute</div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">Salesforce Field</div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">Source of Truth</div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner"></div>
                </div>
            </div>

            <!-- Phone field -->
            <div class="salesforce-config__row">
                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">
                        <p>Phone</p>
                    </div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">
                        <p>MobilePhone</p>
                    </div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">
                        <p>-</p>
                    </div>
                </div>

                <div class="salesforce-config__field"></div>
            </div>

            <!-- Fixed values -->
            <div class="salesforce-config__row" data-test="fields-fixed-rows" v-for="row in fixedRows" :key="`salesforce-config__row-${row.id}`">
                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">
                        {{ fixedFieldLabels[row.key] }}
                    </div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">
                        <vx-input
                            :model-value="row.value"
                            @update:model-value="onChangeFixedInput({ id: row.id, value: $event })"
                        />
                    </div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">
                        <vx-select
                            class="salesforce-config__search-input"
                            :model-value="row.source"
                            :filterable="false"
                            :options="syncTypeOptions"
                            :clearable="false"
                            :disabled="true"
                            @update:model-value="onChangeFixedSource({ id: row.id, value: $event })"
                        >
                            <template #option="{ option }">
                                <div v-if="option.label">{{ option.label }}</div>
                            </template>

                            <template #selected-option="{ option }">
                                <div>{{ option.label }}</div>
                            </template>
                        </vx-select>
                    </div>
                </div>

                <div class="salesforce-config__field"></div>
            </div>

            <div class="salesforce-config__row salesforce-config__row--header">
                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">Voxie Custom Attribute</div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">Salesforce Field</div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">Source of Truth</div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner"></div>
                </div>
            </div>

            <!-- Custom attributes -->
            <div class="salesforce-config__row" data-test="fields-custom-attribute-rows" v-for="row in customAttributeRows" :key="`salesforce-config__row-${row.id}`">
                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">
                        <vx-select
                            class="salesforce-config__search-input"
                            placeholder="Custom_Attribute"
                            :model-value="row.key"
                            :loading="loadingData"
                            :filterable="false"
                            :options="options"
                            :clearable="false"
                            @search="onSearch"
                            @update:model-value="onChangeKey({ id: row.id, value: $event })"
                        >
                            <template #no-options="{ search }">
                                <div>
                                    <template v-if="search">
                                        Sorry, no matching options.
                                    </template>

                                    <template v-else>
                                        Start typing to search field
                                    </template>
                                </div>
                            </template>

                            <template #option="{ option }">
                                <div v-if="option.label">{{ option.label }}</div>
                            </template>

                            <template #selected-option="{ option }">
                                <div>{{ option.label }}</div>
                            </template>
                        </vx-select>
                    </div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">
                        <vx-input
                            :model-value="row.value"
                            @update:model-value="onChangeCustomAttributeInput({ id: row.id, value: $event })"
                        />
                    </div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">
                        <vx-select
                            class="salesforce-config__search-input"
                            :model-value="row.source"
                            :filterable="false"
                            :options="syncTypeOptions"
                            :clearable="false"
                            @update:model-value="onChangeCustomAttributeSource({ id: row.id, value: $event })"
                        >
                            <template #option="{ option }">
                                <div v-if="option.label">{{ option.label }}</div>
                            </template>

                            <template #selected-option="{ option }">
                                <div>{{ option.label }}</div>
                            </template>
                        </vx-select>
                    </div>
                </div>

                <div class="salesforce-config__field">
                    <div class="salesforce-config__field--inner">
                        <i class="salesforce-config__delete-row-icon u-color-danger fa fa-fw fa-trash-o fa-lg" @click="removeRow(row.id)"></i>
                    </div>
                </div>
            </div>

            <div class="salesforce-config__actions">
                <vx-button appearance="primary" size="medium" @click="addNewCustomAttributeRow">
                    Add
                </vx-button>

                <vx-button appearance="primary" size="medium" @click="saveConfig">
                    Save
                </vx-button>
            </div>
        </div>
    </div>
</template>


<script setup>
import { ref, computed } from 'vue';
import { VxSelect, VxInput, VxButton, VxBadge } from '@voxie/frontend-components-v3';
import debounce from '~/utils/debounce';
import incrementingId from '../../../utils/incrementingId';
import { customAttributesClient, salesforceClient } from '../../../services';
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';
import Swal from 'sweetalert2';
import { htmlEncode } from 'js-htmlencode';

const props = defineProps({
    ownsTeam: {
        type: Boolean,
        default: false,
    }
})

const hasPermission = computed(() => props.ownsTeam);

const salesforceDomain = ref('');
const urlForm = ref(null);
const teamId = Spark.state.currentTeam.id;
const loadingData = ref(false);
const isNew = ref(true);
const customAttributeRows = ref([]);
const fixedRows = ref([]);
const authStatus = ref('pending');
const options = ref([]);
const syncTypeOptions = [{
    value: 'voxie',
    label: 'Voxie',
}, {
    value: 'salesforce',
    label: 'Salesforce',
}];
const fixedFieldLabels = {
  'first_name_mapping': 'First Name',
  'last_name_mapping': 'Last Name',
  'email_mapping': 'Email'
};

const createCredentials = () => {
    if (!validateUrl()) {
        return;
    }

    urlForm.value.submit();
};

const validateUrl = () => {
    const errors = [];

    if ('' === salesforceDomain.value) {
        errors.push('Salesforce domain field is required!');
    }

    if (salesforceDomain.value.startsWith('http://') || salesforceDomain.value.startsWith('https://')) {
        errors.push('Salesforce domain field should not contain http:// or https://!');
    }

    if (!salesforceDomain.value.endsWith('.my.salesforce.com')) {
        errors.push('Salesforce domain field should end with .my.salesforce.com!');
    }

    if (errors.length === 0) {
        return true;
    }

    const errorMessage = `<p>We found the following errors:</p><br/>${errors.join("<br/>")}`;

    Swal.fire({
        title: 'Oops..',
        html: errorMessage,
        icon: 'error',
    });

    return false;
};

const getSalesforceCredentials = async () => {
    try {
        const { data } = await salesforceClient.getCredentials(teamId);

        if (data.access_token) {
            authStatus.value = 'success';
            salesforceDomain.value = data.salesforce_domain;
        }

        await getConfig();
    } catch (e) {
        authStatus.value = 'pending';
    }
}

const getConfig = async () => {
    const source = { value: 'salesforce', label: 'Salesforce' };

    try {
        const { data } = await salesforceClient.getConfig(teamId);
        isNew.value = false;

        addNewFixedRow('first_name_mapping', data.first_name_mapping.attribute, source);
        addNewFixedRow('last_name_mapping', data.last_name_mapping.attribute, source);
        addNewFixedRow('email_mapping', data.email_mapping.attribute, source);

        if (data?.custom_attribute_mappings) {
            Object.keys(data.custom_attribute_mappings).forEach((key) => {
                customAttributeRows.value.push({
                    id: incrementingId(),
                    key,
                    value: data.custom_attribute_mappings[key].attribute,
                    source: {
                        value: data.custom_attribute_mappings[key].source,
                        label: syncTypeOptions.find(option => option.value === data.custom_attribute_mappings[key].source).label
                    },
                })
            });
        }
    } catch (error) {
        isNew.value = true;

        addNewFixedRow('first_name_mapping', '', source);
        addNewFixedRow('last_name_mapping', '', source);
        addNewFixedRow('email_mapping', '', source);
    } finally {
        if (customAttributeRows.value.length === 0) {
            addNewCustomAttributeRow();
        }
    }
}

const saveConfig = () => {
    const config = {
        first_name_mapping: { attribute: fixedRows.value[0].value, source: fixedRows.value[0].source.value },
        last_name_mapping: { attribute: fixedRows.value[1].value, source: fixedRows.value[1].source.value },
        email_mapping: { attribute: fixedRows.value[2].value, source: fixedRows.value[2].source.value },
    }

    customAttributeRows.value.forEach((row) => {
        if (row.key) {
            if (!config.custom_attribute_mappings) {
                config.custom_attribute_mappings = {}
            }

            config.custom_attribute_mappings[row.key] = { attribute: row.value, source: row.source.value };
        }
    });

    if (isNew.value) {
        createConfig(config);
    } else {
        updateConfig(config);
    }
}

const createConfig = async (config) => {
    await salesforceClient.createConfig(teamId, config)
        .then(() => {
            Swal.fire({
                title: 'Success!',
                text: 'Salesforce config has successfully been created',
                icon: 'success'
            });

            isNew.value = false;
        })
        .catch((err) => {
            showValidationErrors(err);
        });
}

const updateConfig = async (config) => {
    await salesforceClient.updateConfig(teamId, config)
        .then(() => {
            Swal.fire({
                title: 'Success!',
                text: 'Salesforce config has successfully been updated',
                icon: 'success'
            });

            isNew.value = false;
        })
        .catch((err) => {
            showValidationErrors(err);
        });
}

const addNewFixedRow = (key, value, source) => {
    fixedRows.value.push({
        id: incrementingId(),
        key,
        value,
        source,
    });
}

const addNewCustomAttributeRow = (key = null, value = null, source = {
    value: 'voxie',
    label: 'Voxie'
}) => {
    customAttributeRows.value.push({
        id: incrementingId(),
        key,
        value,
        source,
    });
}

const removeRow = (id) => {
    const index = customAttributeRows.value.findIndex((row) => row.id === id);

    if (index === -1) {
        return;
    }

    customAttributeRows.value.splice(index, 1);

    if (customAttributeRows.value.length === 0) {
        addNewCustomAttributeRow();
    }
}

const onChangeFixedSource = ({ id, value }) => {
    const index = fixedRows.value.findIndex((row) => row.id === id);

    if (index === -1) {
        return;
    }

    fixedRows.value[index].source = value;
};

const onChangeCustomAttributeSource = ({ id, value }) => {
    const index = customAttributeRows.value.findIndex((row) => row.id === id);

    if (index === -1) {
        return;
    }

    customAttributeRows.value[index].source = value;
};

const onChangeKey = ({ id, value }) => {
    const index = customAttributeRows.value.findIndex((row) => row.id === id);

    if (index === -1) {
        return;
    }

    customAttributeRows.value[index].key = value;
};

const onChangeFixedInput = ({ id, value }) => {
    const index = fixedRows.value.findIndex((row) => row.id === id);
    if (index === -1) {
        return;
    }
    fixedRows.value[index].value = value;
};
const onChangeCustomAttributeInput = ({ id, value }) => {
    const index = customAttributeRows.value.findIndex((row) => row.id === id);

    if (index === -1) {
        return;
    }

    customAttributeRows.value[index].value = value;
};

const runSearch = (loading, query) => {
    const params = query ? { query } : null;

    customAttributesClient.customAttributesAutocomplete(teamId, params)
        .then(response => {
            if (response.data.data.length > 0) {
                options.value = response.data.data.map((value) => value.toString());
                loading(false);
            }
        })
        .catch(error => {
            console.error(error);
            this.$toasted.global.platform_error();
        });
};

const debouncedSearch = debounce(runSearch, 350);

const onSearch = (search, loading) => {
    loading(true);
    debouncedSearch(loading, search);
};

const showValidationErrors = (err) => {
    const responseErrors = err.response.data.errors;
    const errors = [];

    Object.keys(responseErrors).flatMap((k) => {
        responseErrors[k].map(e => errors.push(htmlEncode(e)));
    });

    const errorMessage = `<p>We found the following errors:</p><br/>${errors.join("<br/>")}`;

    Swal.fire({
        title: 'Oops..',
        html: errorMessage,
        icon: 'error',
    });
}

if (hasPermission.value) {
    getSalesforceCredentials();
}
</script>
<style lang="postcss" scoped>
.salesforce-config {
    margin-bottom: 24px;
}

.salesforce-config__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: var(--color-white);
    font-size: 16px;
    font-weight: var(--weight-semibold);
    color: var(--color-grey);
}

.salesforce-config__close-icon {
    cursor: pointer;
}

.salesforce-config__row {
    display: grid;
    grid-template-columns: 35% 35% 25% 5%;
    font-family: var(--font-open-sans);
    background-color: var(--color-white);
}

.salesforce-config__row--header {
    background-color: var(--color-background);
}

.salesforce-config__field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
}

.salesforce-config__field--inner {
    font-size: 0.9375rem;
    font-weight: var(--weight-semibold);
    color: var(--color-grey);
    margin: 0;
}

.salesforce-config__actions {
    display: flex;
    padding: 16px;
    background-color: var(--color-white);
}

.salesforce-config__actions button {
    margin-right: 10px;
}
</style>
