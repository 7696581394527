<template>
    <vx-table-row>
        <vx-table-row-title>
            <div class="vx-flex vx-items-center vx-gap-2">
                <vx-popover
                hover
                :arrow="true"
                placement="top" class="[&>div]:vx-flex">
                    <vx-badge size="xs" color="slate">{{ props.item.auth_config_id?.slice(-6) }}</vx-badge>
                    <template v-slot:content>
                        <vx-badge size="xs" color="slate">{{ props.item.auth_config_id }}</vx-badge>
                    </template>
                </vx-popover>
                {{ item.friendly_name }}
            </div>
        </vx-table-row-title>

        <vx-table-row-item>
            <template v-slot:label> Destanations: </template>
            <font-awesome-icon
                v-if="destinationCount === null"
                spin
                :icon="faSpinner"
            ></font-awesome-icon>
            <span v-else>{{ destinationCount === undefined ? '-' : destinationCount }}</span>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Created at: </template>

            <vx-popover
                hover
                :arrow="true"
                placement="top"
                :key="createdAtKey">
                {{ fromNow(props.item.created_at) }}
                <template v-slot:content>
                    {{ shortDateTime(props.item.created_at) }}
                </template>
            </vx-popover>
        </vx-table-row-item>

        <vx-table-row-buttons>
            <vx-dropdown placement="right">
                <vx-button type="button"
                    color="muted"
                    outline
                    class="vx-w-[45px]">
                    <font-awesome-icon :icon="faEllipsis" />
                </vx-button>
                <template v-slot:items>
                    <vx-popover hover
                        :arrow="true"
                        placement="left">
                        <vx-dropdown-item
                            :disabled="!canDelete"
                            @click="deleteConfig()">
                                Delete
                            <font-awesome-icon :icon="faTrash"></font-awesome-icon>
                        </vx-dropdown-item>
                        <template v-if="!canDelete" v-slot:content>
                            <div class="vx-font-normal vx-w-40">
                                <template v-if="!isAdminOrOwner">
                                    You do not have permission to delete.
                                </template>
                                <template v-else-if="possiblyUsedInDestinations">
                                    Cannot delete if being used in Destinations.
                                </template>
                            </div>
                        </template>
                    </vx-popover>
                </template>
            </vx-dropdown>

            <vx-popover
                hover
                :arrow="true"
                placement="left">
                <vx-button :disabled="!isAdminOrOwner"
                    :href="'/settings/integrations/auth-configs/' + props.item.auth_config_id"
                    block
                    color="muted"
                    outline>
                    Edit <font-awesome-icon :icon="faFilePen" />
                </vx-button>
                <template v-if="!isAdminOrOwner" v-slot:content>
                    You do not have permission to edit.
                </template>
            </vx-popover>
        </vx-table-row-buttons>
    </vx-table-row>
</template>

<script setup>
import {
    faFilePen,
    faTrash,
    faEllipsis,
    faSpinner,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxButton,
    VxTableRow,
    VxTableRowItem,
    VxTableRowTitle,
    VxTableRowButtons,
    VxDropdown,
    VxDropdownItem,
    VxBadge,
    VxPopover
} from "@voxie/frontend-components";
import { ref, computed } from 'vue';
import apiDestinationsClient from '../../../../services/apiDestinationsClient';
import authConfigsClient from '../../../../services/authConfigsClient';
import { fromNow, shortDateTime } from '../../../../utils/date';
import { useToasted } from '../../../../composables/useToasted';
import { useInterval } from '@vueuse/core';

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;
const isAdminOrOwner = Spark.isAdminOrOwner;

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(["deleted"]);

const destinationCount = ref(null);

const getDestinationCount = async () => {
    try {
        destinationCount.value = (await apiDestinationsClient.countByAuthConfig(teamId, props.item.auth_config_id)).data.count;
    } catch (error) {
        destinationCount.value = undefined;
        console.error(error);
        toasted.global.platform_error();
    }
}

getDestinationCount();

const possiblyUsedInDestinations = computed(() => {
    return destinationCount.value === undefined || destinationCount.value === null || destinationCount.value > 0;
});

const canDelete = computed(() => {
    return !possiblyUsedInDestinations.value && isAdminOrOwner;
});

const deleteConfig = async () => {
    if (
        !window.confirm(
            "Are you sure? There is no undo."
        )
    ) {
        return;
    }

    try {
        await authConfigsClient.delete(teamId, props.item.auth_config_id);
        emit("deleted", props.item.auth_config_id);
    } catch (error) {
        console.error(error);
        toasted.global.platform_error();
    }
};

const createdAtKey = useInterval(60000);

</script>
