<template>
    <vx-alert color="success"
              :closeable="false"
              shadow>
        <template v-slot:icon>
            <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
        </template>
        <div class="vx-flex vx-items-center vx-justify-between">
            <span><strong>{{ phoneNumberNational(props.phoneNumber.phone_number) }}</strong> selected</span>
            <vx-button @click.prevent="emit('edit')"
                       size="sm"
                       color="success">Edit<font-awesome-icon
                :icon="faSquareArrowUpLeft"></font-awesome-icon></vx-button>
        </div>
    </vx-alert>
</template>

<script setup>
import {
    VxAlert,
    VxButton,
} from '@voxie/frontend-components';
import { faCircleCheck, faSquareArrowUpLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { phoneNumberNational } from '~/components/filters';

const emit = defineEmits(['edit']);

const props = defineProps({
    phoneNumber: {
        type: Object,
        required: true,
    },
});
</script>
