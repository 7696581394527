<template>
    <div>
        <div class="vx-mb-8 -vx-ml-4 vx-w-[calc(100%+32px)] lg:-vx-ml-8 lg:vx-w-[calc(100%+64px)] vx-relative">
            <div class="vx-w-full vx-absolute vx-h-0.5 vx-bg-slate-300 vx-left-0 vx-top-4"></div>
            <div class="vx-w-full vx-absolute vx-h-px vx-bg-white vx-left-0 vx-top-4"></div>
            <button @click.prevent="choosingDelay = true"
                data-test="choose-delay-button"
                type="button"
                class="vx-p-0 vx-font-sans vx-mx-8 vx-relative vx-h-8 vx-rounded-full vx-shadow-[0px_1px_0px_0px_#fff] vx-border vx-border-solid vx-border-slate-300 vx-bg-slate-100 ">
                <div
                    class="vx-rounded-full vx-px-4 vx-w-full vx-h-full vx-border-solid vx-text-slate-400 vx-border-t vx-border-white vx-border-x-0 vx-border-b-0 vx-flex vx-justify-center vx-items-center vx-gap-2">
                    <font-awesome-icon :icon="faClock"></font-awesome-icon>
                    <span v-if="!choosingDelay && props.modelValue.delay_amount && props.modelValue.delay_type"
                        class="vx-text-sm vx-text-slate-800">
                        Wait {{ pluralized(props.modelValue.delay_type, props.modelValue.delay_amount, true) }} then
                        continue
                    </span>
                </div>
            </button>

            <div v-if="choosingDelay"
                class="vx-px-4 -vx-mt-4 vx-pb-6 vx-pt-8 vx-bg-slate-200 lg:vx-px-8 ">
                <div class="vx-text-sm vx-mb-4">
                    How long should we wait before sending the next message?
                </div>
                <div class="vx-flex vx-flex-col vx-items-center vx-gap-2 lg:vx-flex-row lg:vx-gap-4">
                    <div>
                        <vx-input :size="isLg ? 'lg' : 'sm'"
                            data-test="delay-amount-input"
                            type="number"
                            min="0"
                            step="1"
                            :modelValue="props.modelValue.delay_amount.toString() || 0"
                            @update:modelValue="updateModelValue('delay_amount', parseInt($event, 10) ? parseInt($event, 10) : 0)"></vx-input>
                    </div>
                    <div>
                        <vx-tabs data-test="delay-type-tabs"
                            class="lg:vx-shrink-0"
                            :disabled="props.disabled"
                            :size="isLg ? 'lg' : 'sm'"
                            :modelValue="props.modelValue.delay_type"
                            @update:modelValue="updateModelValue('delay_type', $event)">
                            <vx-tab value="minutes">Minutes</vx-tab>
                            <vx-tab value="hours">Hours</vx-tab>
                            <vx-tab value="days">Days</vx-tab>
                        </vx-tabs>
                    </div>
                    <div>
                        <vx-button @click.prevent="choosingDelay = false"
                            data-test="choose-delay-confirm"
                            type="button"
                            size="lg"
                            color="primary">
                            Confirm
                            <font-awesome-icon :icon="faCheck"></font-awesome-icon>
                        </vx-button>
                    </div>
                </div>

            </div>

        </div>
        <div class="vx-shadow vx-rounded-lg vx-border vx-border-solid vx-border-white">
            <div @click.prevent="open = props.total === 1 ? true : !open"
                class="vx-flex vx-rounded-t-lg vx-justify-between vx-items-center vx-px-4 vx-py-3 vx-cursor-pointer vx-border-t-0 vx-border-x-0 vx-border-b vx-border-solid"
                :class="{
                    'vx-bg-slate-100 vx-border-b-slate-200': !props.campaignId,
                    'vx-bg-sky-50 vx-border-b-sky-100': props.modelValue.status === 'published' && props.campaignId,
                    'vx-bg-slate-50 vx-border-b-slate-100': props.modelValue.status === 'draft' && props.campaignId,
                }">
                <div>
                    <vx-badge v-if="!props.campaignId"
                        color="primary"
                        size="xs">New Message</vx-badge>
                    <template v-else>
                        <vx-toggle size="sm" :modelValue="props.modelValue.status === 'published'"
                            @update:modelValue="updateModelValue('status', $event ? 'published' : 'draft')">
                            <template v-slot:no>Inactive</template>
                            <template v-slot:yes>Active</template>
                        </vx-toggle>
                    </template>
                </div>

                <font-awesome-icon :icon="open ? faChevronUp : faChevronDown"
                    :class="{
                        'vx-opacity-35': props.total === 1
                    }"></font-awesome-icon>
            </div>
            <div class="vx-px-8 vx-bg-white vx-rounded-b-lg">
                <div v-if="!open"
                    class="vx-py-4">
                    <div class="vx-line-clamp-2"
                        :class="{
                            'vx-text-slate-700': props.modelValue.body,
                            'vx-text-slate-500 vx-italic': !props.modelValue.body,
                        }">{{ props.modelValue.body || 'Your message here...' }}</div>
                    <div v-if="displayFirstError(validation.body.$errors)"
                        class="vx-text-xs vx-text-rose-800 vx-mt-2">{{ displayFirstError(validation.body.$errors) }}</div>
                </div>
                <div v-else
                    class="vx-py-8">
                    <message-builder ref="messageBuilderRef"
                        testable
                        data-test="message-builder"
                        placeholder="Your message here..."
                        :modelValue="props.modelValue.body"
                        :mediaContentType="props.modelValue.media_content_type"
                        :mediaUrl="props.modelValue.media_url"
                        @update:modelValue="updateModelValue('body', $event)"
                        @update:mediaContentType="updateModelValue('media_content_type', $event)"
                        @update:mediaUrl="updateModelValue('media_url', $event)"
                        :error="displayFirstError(validation.body.$errors)"
                        @blur="validation.body.$touch()"></message-builder>

                    <div class="vx-mt-8 vx-text-slate-700 vx-flex vx-flex-col vx-gap-2 vx-text-sm">
                        <div class="vx-p-6 vx-bg-slate-50 vx-rounded-2xl">
                            <div class="vx-justify-between vx-items-center vx-flex">
                                <div>
                                    Message should be limited to a <strong>specific timeframe</strong>?
                                </div>
                                <div>
                                    <vx-toggle size="sm" data-test="should-send-on-schedule-toggle"
                                        :modelValue="!!props.modelValue.should_send_on_schedule"
                                        @update:modelValue="updateModelValue('should_send_on_schedule', $event)"></vx-toggle>
                                </div>
                            </div>
                            <div v-if="props.modelValue.should_send_on_schedule"
                                class="vx-mt-6">
                                <div><strong>What days should this message send?</strong></div>
                                <div class="vx-mt-4 vx-mb-6 vx-text-sm">
                                    <div class="vx-flex vx-gap-4 vx-items-center">
                                        <div v-for="day in days"
                                            class="vx-text-center"
                                            :key="'campaign_message.' + props.modelValue.id + '.' + day.key">
                                            <vx-checkbox size="lg"
                                                :data-test="day.key + '-checkbox'"
                                                :modelValue="props.modelValue[day.key]"
                                                @update:modelValue="updateModelValue(day.key, $event)"></vx-checkbox>
                                            <div class="vx-mt-1">{{ day.label }}</div>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <strong>Between what times should this message send?</strong> <br />
                                    This will use the recipient’s time-zone.
                                </div>
                                <div class="vx-flex vx-items-center vx-gap-4 vx-mt-4">
                                    <div class="vx-w-36">
                                        <vx-selectable size="lg"
                                            :clearable="false"
                                            :options="timeOptions"
                                            :reduce="option => option.value"
                                            :modelValue="props.modelValue.send_at_time"
                                            @update:modelValue="updateModelValue('send_at_time', $event)">
                                        </vx-selectable>
                                    </div>
                                    <font-awesome-icon :icon="faArrowsLeftRight"
                                        class="vx-text-slate-400"></font-awesome-icon>
                                    <div class="vx-w-36">
                                        <vx-selectable size="lg"
                                            :clearable="false"
                                            :options="timeOptions"
                                            :reduce="option => option.value"
                                            :modelValue="props.modelValue.send_stop_time"
                                            @update:modelValue="updateModelValue('send_stop_time', $event)">
                                        </vx-selectable>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="vx-p-6 vx-bg-slate-50 vx-rounded-2xl">
                            <div class="vx-justify-between vx-items-center vx-flex">
                                <div>
                                    Should this message <strong>collect data</strong>?
                                </div>
                                <div>
                                    <vx-toggle size="sm" data-test="dynamic-field-toggle"
                                        :modelValue="!!props.modelValue.dynamic_field"
                                        @update:modelValue="updateModelValue('dynamic_field', $event ? {} : null)"></vx-toggle>
                                </div>
                            </div>

                            <div v-if="props.modelValue.dynamic_field"
                                class="">
                                <div class="vx-text-slate-500 vx-my-6">You can save specific data like a person’s name or
                                    email when they respond to this message. Select from the list below to see options.
                                </div>
                                <dynamic-field-extraction :fieldType="props.modelValue.dynamic_field.type"
                                    @update:fieldType="updateDynamicField('type', $event)"
                                    :customAttribute="props.modelValue.dynamic_field.custom_attribute_name"
                                    @update:customAttribute="updateDynamicField('custom_attribute_name', $event)"
                                    :choices="props.modelValue.dynamic_field.choices"
                                    @update:choices="updateDynamicField('choices', $event)"
                                    :multipleChoices="props.modelValue.dynamic_field.multiple_choices"
                                    @update:multipleChoices="updateDynamicField('multiple_choices', $event)"
                                    ></dynamic-field-extraction>
                            </div>
                        </div>

                    </div>
                    <vx-button v-if="props.total !== 1"
                        class="vx-mt-8"
                        @click.prevent="emit('remove')"
                        type="button"
                        color="muted-light"
                        size="sm">
                        Delete Message <font-awesome-icon :icon="faTrash"></font-awesome-icon>
                    </vx-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { faArrowsLeftRight, faCheck, faChevronDown, faChevronUp, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBadge, VxButton, VxCheckbox, VxInput, VxSelectable, VxTab, VxTabs, VxToggle } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useMediaQuery } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import DynamicFieldExtraction from '~/components/dynamic-fields/DynamicFieldExtraction.vue';
import { pluralized } from '~/components/filters';
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import { displayFirstError } from '~/utils/validation';

const isLg = useMediaQuery('(min-width: 1024px)')

const props = defineProps({
    campaignId: {
        type: [Number, undefined],
        required: false,
    },
    modelValue: {
        type: Object,
        required: true,
    },
    total: {
        type: Number,
        default: 0,
    },
});

const emit = defineEmits(["update:modelValue", 'remove']);

const updateModelValue = (key, value) => {
    emit("update:modelValue", {
        ...props.modelValue,
        [key]: value,
    });
};

const updateDynamicField = (key, value) => {
    emit('update:modelValue', {
        ...props.modelValue,
        dynamic_field: {
            ...props.modelValue.dynamic_field,
            [key]: value,
        }
    })
}

const open = ref(props.total === 1 || !props.modelValue.body);

const choosingDelay = ref(false);

watch(() => props.total, () => {
    if (props.total === 1) {
        open.value = true;
    }
});

const days = [
    {
        key: 'send_on_0',
        label: 'Su',
    },
    {
        key: 'send_on_1',
        label: 'Mo',
    },
    {
        key: 'send_on_2',
        label: 'Tu',
    },
    {
        key: 'send_on_3',
        label: 'We',
    },
    {
        key: 'send_on_4',
        label: 'Th',
    },
    {
        key: 'send_on_5',
        label: 'Fr',
    },
    {
        key: 'send_on_6',
        label: 'Sa',
    },
];

const timeOptions = computed(() => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
        [0, 15, 30, 45].forEach(minute => {
            const convertedHours = hour % 12 || 12;
            const amPm = hour < 12 ? 'am' : 'pm';

            options.push({
                label: `${convertedHours}:${minute.toString().padStart(2, '0')} ${amPm}`,
                value: `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`,
            })
        })
    }

    return options;
});

const messageBuilderRef = ref();

const validation = useVuelidate({
    body: {
        required,
    },
}, computed(() => props.modelValue));

defineExpose({
    focusMessage: () => messageBuilderRef.value?.textarea?.focus?.(),
    close: () => open.value = false
});

</script>
