<template>
    <div @click.prevent="updateModelValue(!props.modelValue)" class="vx-text-black vx-text-sm vx-font-normal" :class="{
        'vx-opacity-60': props.modelValue,
        'vx-opacity-50': !props.modelValue,
    }">Active</div>
    <vx-switch size="sm" :loading="props.loading" :modelValue="props.modelValue"
        @update:modelValue="updateModelValue">
    </vx-switch>
</template>
<script setup>
import { VxSwitch } from '@voxie/frontend-components';

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
    loading: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue']);

const updateModelValue = (event) => {
    if (props.loading) {
        return;
    }

    emit('update:modelValue', event);
}
</script>
