<template>
    <div class="vx-flex vx-flex-col vx-gap-2">
        <div>
            <vx-label size="md">Audience Type</vx-label>
            <vx-selectable data-test="audience-type-select"
                v-model="data.audience_type"
                size="lg"
                :clearable="false"
                :options="audienceTypes()"
                :reduce="audienceType => audienceType.name"></vx-selectable>
        </div>

        <div class="vx-mb-4">
            <vx-label size="md">Message</vx-label>
            <message-builder v-model="data.response_text"
                v-model:mediaUrl="data.response_media_url"
                v-model:mediaContentType="data.response_media_content_type"
                :error="displayFirstError(validate.response_text) || displayFirstError(validate.response_media_url)"
                testable>
            </message-builder>
        </div>

        <use-event-contact-action-data v-model="data"></use-event-contact-action-data>

        <toggle-action-data :model-value="Boolean(data.dynamicField)"
            @update:modelValue="data.dynamicField = $event ? {
                custom_attribute_name: null,
                type: null,
                choices: []
            } : null"
            :open="Boolean(data.dynamicField)"
            data-test="toggle-dynamic-field">
            <template v-slot:label>
                Should this message <strong>collect data</strong>?
            </template>
            <dynamic-field-extraction v-model:customAttribute="data.dynamicField.custom_attribute_name"
                v-model:fieldType="data.dynamicField.type"
                v-model:choices="data.dynamicField.choices"
                v-model:multipleChoices="data.dynamicField.multiple_choices"
                labelSize="sm"></dynamic-field-extraction>
        </toggle-action-data>
    </div>
</template>

<script setup>
import { VxSelectable, VxLabel } from '@voxie/frontend-components';
import DynamicFieldExtraction from '~/components/dynamic-fields/DynamicFieldExtraction.vue';
import { AUDIENCE_TYPE_TRANSACTIONAL, audienceTypes } from '~/constants/campaign';
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import UseEventContactActionData from './UseEventContactActionData.vue';
import ToggleActionData from './ToggleActionData.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf } from '@vuelidate/validators';
import { displayFirstError } from '~/utils/validation';

const data = defineModel();

if (data.value.use_event_contact == undefined) {
    data.value.use_event_contact = true;
}

if (data.value.audience_type == undefined) {
    data.value.audience_type = AUDIENCE_TYPE_TRANSACTIONAL;
}

if (data.value.response_text == undefined) {
    data.value.response_text = '';
}

const validate = useVuelidate({
    response_text: {
        requiredIf: helpers.withMessage('Message text is required if no media is provided', requiredIf(() => !data.value.response_media_url)),
    },
    response_media_url: {
        requiredIf: helpers.withMessage('Message media is required if a message text is not provided', requiredIf(() => !data.value.response_text)),
    },
}, data);
</script>
