<template>
    <div>
        <!-- Update Profile Photo -->
        <update-profile-photo :user="user"></update-profile-photo>

        <!-- Update Contact Information -->
        <update-contact-information :user="user"></update-contact-information>

        <!-- Update Profile Details -->
        <update-profile-details :user="user"></update-profile-details>
    </div>
</template>

<script>
import UpdateContactInformation from './UpdateContactInformation.vue'
import UpdateProfilePhoto from './UpdateProfilePhoto.vue'
import UpdateProfileDetails from './UpdateProfileDetails.vue'

export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    components: {
        UpdateContactInformation,
        UpdateProfilePhoto,
        UpdateProfileDetails,
    }
}
</script>
