import { globals } from './globals';
import { createApp } from 'vue';
import App from './App.vue';

// Third party styles
import 'vue-select/dist/vue-select.css';
import 'sweetalert2/dist/sweetalert2.css';
import 'emoji-mart-vue-fast/css/emoji-mart.css';
import '@voxie/frontend-components-v3/dist/style.css';
import '@voxie/frontend-components/dist/style.css';

// App settings and configuration
import * as Components from './components/index';
import * as Directives from './directives/index';
import { store } from './store';
import { FEATURES } from './provides/features';

const app = createApp(App);

for (const [name, component] of Object.entries(Components)) {
    app.component(name, component);
}

for (const [name, directive] of Object.entries(Directives)) {
    app.directive(name, directive);
}

app.config.globalProperties.$cspNonce = Spark.cspNonce;
app.config.compilerOptions.whitespace = 'preserve';

app.provide('FEATURES', FEATURES);
app.mixin(globals.globalMixin).use(store).use(globals.bugsnagVue).mount('#spark-app');
