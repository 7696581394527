<template>
    <div class="vx-col-span-12 vx-flex vx-flex-col vx-pb-8 lg:vx-col-span-2">
        <component v-for="link in props.links"
            :is="link.href ? SidebarLink : 'div'"
            :key="`link-${link.href}-${link.label}`"
            :nested="false"
            :href="link.href"
            :pathToRegexp="link.pathToRegexp"
            :icon="link.icon"
            :appearance="props.appearance">

            <template v-if="link.href">
                {{ link.label }}
            </template>
            <div v-else
                class="vx-mb-6">
                <div class="vx-text-slate-600 vx-text-base vx-font-extrabold vx-font-sans vx-mb-2">
                    {{ link.label }}
                </div>
                <div class="vx-relative" :class="appearanceStyles.sublinks">
                    <sidebar-link v-for="sublink in link.links"
                        :key="`sublink-${sublink.href}-${sublink.label}`"
                        :nested="true"
                        :href="sublink.href"
                        :pathToRegexp="sublink.pathToRegexp"
                        :icon="sublink.icon"
                        :appearance="props.appearance">
                        {{ sublink.label }}
                    </sidebar-link>
                </div>

            </div>
        </component>
    </div>
</template>
<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, h } from 'vue';
import { useBrowserLocation } from '@vueuse/core'

const props = defineProps({
    links: {
        required: true,
        type: Array
    },
    bold: {
        type: Boolean,
        required: false,
        default: true,
    },
    appearance: {
        type: String,
        required: false,
    },
});

const location = useBrowserLocation();

const appearanceStyles = computed(() => {
    if (props.appearance === 'nested') {
        return {
            nestedLink: 'vx-pl-3 vx-border-solid vx-border-l vx-border-y-0 vx-border-r-0 vx-border-slate-400 vx-text-sm !vx-font-normal',
        }
    }
    return {};
})

const isActive = (href, pathRegExps) => {
  if(!pathRegExps) {
      return location.value.href?.endsWith(href);
  }
  return Boolean(pathRegExps?.exec(location.value.pathname + location.value.hash));
}

const hasIcons = computed(() => props.links.some(link => link.icon));
const bold = computed(() => props.bold)

const SidebarLink = (props, { slots }) => h('a', {
    class: ['vx-group !vx-no-underline vx-py-1 hover:vx-text-sky-600 vx-block', {
        'vx-text-slate-600': !isActive(props.href, props.pathToRegexp),
        'vx-text-sky-600': isActive(props.href, props.pathToRegexp),
        'vx-font-extrabold': bold.value,
    }, {
        [appearanceStyles.value.nestedLink]: props.nested
    }],
    href: props.href,
}, [
    props.icon ? h(FontAwesomeIcon, {
        icon: props.icon,
        fixedWidth: true,
        class: ['vx-mr-2 group-hover:vx-text-sky-600', {
            'vx-text-slate-400': !isActive(props.href, props.pathToRegexp),
            'vx-text-sky-600': isActive(props.href, props.pathToRegexp),
        }],
    }) : (hasIcons.value && h('div', { class: 'vx-inline-block vx-w-[20px] vx-h-[16px] vx-mr-2' })),
    slots.default()
]);
</script>
