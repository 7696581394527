<template>
    <div>
        <div class="c-card u-p-medium u-mb-medium">
            <table class="table promotion-show-tbl" style="table-layout:fixed">
                <tbody>
                <tr>
                    <td style="width:9rem"><strong>Name</strong></td>
                    <td>{{ promotion.name }}</td>
                </tr>
                <tr>
                    <td><strong>Code</strong></td>
                    <td>{{ promotion.code }}</td>
                </tr>
                </tbody>
            </table>
            <div class="u-mt-medium text-right">
                <button @click="clickedEdit" class="c-btn c-btn--info u-mr-xsmall">
                    Edit
                </button>
                <button @click="clickedClose" class="c-btn c-btn--secondary">
                    Close
                </button>
            </div>
        </div>
        <promotion-coupons-list :team-id="teamId"
                                :promotion-id="promotion.id"
        />
    </div>
</template>

<script setup>
import PromotionCouponsList from './PromotionCouponsList.vue'

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    promotion: {
        type: Object,
        required: true,
    },
})

const clickedEdit = () => Bus.$emit('promotionEdit', props.promotion);

const clickedClose = () => Bus.$emit('showPromotionsList');

</script>
