import dayjs, { toUTCDateTimeFormat } from '~/utils/dayjs';
import { getClientTz, shortDate } from './date';
import { formatCount } from '~/components/filters';

/** @param {string[]} list */
export const generateLegendColors = (list) => {
    const colors = [
        {
            // sky
            iconColor: '#14ABE0',
            bgColor: '#73D8FC',
        },
        {
            // blue
            iconColor: '#3B82F6',
            bgColor: '#93C5FD',
        },
        {
            // violet
            iconColor: '#8B5CF6',
            bgColor: '#C4B5FD',
        },
        {
            // fuchsia
            iconColor: '#D946EF',
            bgColor: '#F0ABFC',
        },
        {
            // pink
            iconColor: '#EC4899',
            bgColor: '#F9A8D4',
        },
        {
            // green
            iconColor: '#22c55e',
            bgColor: '#86efac',
        },
        {
            // rose
            iconColor: '#F43F5E',
            bgColor: '#FDA4AF',
        },
        {
            // orange
            iconColor: '#F97316',
            bgColor: '#FDBA74',
        },
        {
            // amber
            iconColor: '#F59E0B',
            bgColor: '#FCD34D',
        },
        {
            // lime
            iconColor: '#84CC16',
            bgColor: '#BEF264',
        },
        {
            // blue gray
            iconColor: '#64748B',
            bgColor: '#CBD5E1',
        },
        {
            // cyan
            iconColor: '#06b6d4',
            bgColor: '#67e8f9',
        },
        {
            // slate
            iconColor: '#64748b',
            bgColor: '#cbd5e1',
        },
    ];

    return list.map((item, index) => ({
        label: item,
        ...colors[index],
    }));
};

/**
 * Returns the percentage difference between two numbers.
 *
 * @param {number[]} numbers
 * @returns {number}
 */
export const calculateDifference = (numbers) => {
    const difference = numbers[0] !== 0 ? (numbers[1] - numbers[0]) / numbers[0] : null;

    return difference;
};

export const getCurrentDate = () => {
    return dayjs().tz(getClientTz()).subtract(30, 'minutes');
};

export const formatDateForRange = (date) => {
    return toUTCDateTimeFormat(date.utc().startOf('hour'));
};

export const getDateRange = (value) => {
    const currentDate = getCurrentDate();

    if (value === 'last_7_days') {
        const startDate =
            currentDate.hour() === 0
                ? currentDate.clone().startOf('day').subtract(7, 'days')
                : currentDate.clone().startOf('day').subtract(6, 'days');

        const endDate = currentDate.clone();

        return {
            start_date: formatDateForRange(startDate),
            end_date: formatDateForRange(endDate),
        };
    }

    if (value === 'monday_today') {
        const startDate = currentDate.clone().startOf('isoWeek');

        const endDate = currentDate.clone();

        return {
            start_date: formatDateForRange(startDate),
            end_date: formatDateForRange(endDate),
        };
    }

    if (value === 'month_today') {
        const startDate = currentDate.clone().startOf('month');

        const endDate = currentDate.clone();

        return {
            start_date: formatDateForRange(startDate),
            end_date: formatDateForRange(endDate),
        };
    }

    if (value === 'previous_month') {
        const startDate = currentDate.clone().subtract(1, 'month').startOf('month');

        const endDate = currentDate.clone().startOf('month');

        return {
            start_date: formatDateForRange(startDate),
            end_date: formatDateForRange(endDate),
        };
    }

    if (value === 'last_30_days') {
        const startDate =
            currentDate.hour() === 0
                ? currentDate.clone().startOf('day').subtract(30, 'days')
                : currentDate.clone().startOf('day').subtract(29, 'days');

        const endDate = currentDate.clone();

        return {
            start_date: formatDateForRange(startDate),
            end_date: formatDateForRange(endDate),
        };
    }

    if (value === 'last_90_days') {
        const startDate =
            currentDate.hour() === 0
                ? currentDate.clone().startOf('day').subtract(90, 'days')
                : currentDate.clone().startOf('day').subtract(89, 'days');

        if (startDate.isBefore(dayjs.utc('2024-01-01 05:00:00'))) {
            return {
                start_date: null,
                end_date: null,
            };
        }
        const endDate = currentDate.clone();

        return {
            start_date: formatDateForRange(startDate),
            end_date: formatDateForRange(endDate),
        };
    }

    return {
        start_date: null,
        end_date: null,
    };
};

/**
 * @param {Date[]} param
 */
export const findDateRangeOption = ([startDate, endDate]) => {
    const option = controlDateRangeOptions().find(
        (option) => dayjs(startDate).isSame(option.start_date) && dayjs(endDate).isSame(option.end_date)
    );

    if (option) {
        return option;
    }

    return {
        value: 'selected_custom',
        start_date: formatDateForRange(dayjs(startDate)),
        end_date: formatDateForRange(dayjs.min([getCurrentDate(), dayjs(endDate).add(1, 'day')])),
        label: `${shortDate(startDate)} - ${shortDate(endDate)}`,
    };
};

/**
 * This needs to be a function in order to use dayjs in export.
 * @returns {array}
 */
export const controlDateRangeOptions = () => {
    let options = [
        {
            value: 'last_7_days',
            label: 'Last 7 Days',
            ...getDateRange('last_7_days'),
        },
        {
            value: 'monday_today',
            label: 'Monday To Today',
            ...getDateRange('monday_today'),
        },
        {
            value: 'month_today',
            label: 'Current Month',
            ...getDateRange('month_today'),
        },
        {
            value: 'previous_month',
            label: 'Last Month',
            ...getDateRange('previous_month'),
        },
        {
            value: 'last_30_days',
            label: 'Last 30 Days',
            ...getDateRange('last_30_days'),
        },
        {
            value: 'last_90_days',
            label: 'Last 90 Days',
            ...getDateRange('last_90_days'),
        },
        {
            value: 'custom',
            label: 'Custom Period',
        },
    ];

    return options.filter(
        (option) =>
            option.value !== 'last_90_days' ||
            (option.value === 'last_90_days' && option.dates?.start_date && option.dates?.end_date)
    );
};

export function formatCountPlusSign(number) {
    if (number === 0) {
        return '0';
    }

    if (!number) {
        return '';
    }

    return `+${formatCount(number)}`;
}

export const computeComparisonRange = ({ start, end }) => {
    const startDate = dayjs(start).tz(getClientTz());
    const endDate = dayjs(end).tz(getClientTz());

    const daysDiff = Math.ceil(endDate.diff(startDate, 'days', true));

    const comparisonDate = startDate.clone().subtract(daysDiff, 'days');

    if (comparisonDate.isBefore(dayjs.utc('2023-07-01 04:00:00'))) {
        return [toUTCDateTimeFormat(startDate), toUTCDateTimeFormat(endDate)];
    }

    return [toUTCDateTimeFormat(comparisonDate), toUTCDateTimeFormat(startDate), toUTCDateTimeFormat(endDate)];
};

export const calculateRangeBetweenTwoDates = ({ startDate, endDate }) => {
    const dates = [];
    const startDayjs = dayjs(startDate).tz(getClientTz());
    const endDayjs = dayjs(endDate).tz(getClientTz());

    let currentDate = startDayjs.clone();

    // first point
    dates.push(toUTCDateTimeFormat(currentDate));

    // remaining points
    while (currentDate.unix() < endDayjs.unix()) {
        currentDate = currentDate.add(1, 'day');
        dates.push(toUTCDateTimeFormat(currentDate));
    }

    // move last point back
    dates[dates.length - 1] = toUTCDateTimeFormat(dayjs(endDate));

    return dates;
};

export const formatDateAxisTicks = (current, currentIndex, all) => {
    const totalItems = all.length;
    const maxItemsToShow = 15;
    const timeZonedFormattedDate = dayjs.utc(current).tz(getClientTz()).format('MM/DD');

    if (maxItemsToShow >= totalItems) {
        return timeZonedFormattedDate;
    }

    // -2 to account for the first and last items
    const stepSize = Math.ceil(totalItems / (maxItemsToShow - 2));

    return currentIndex === 0 || currentIndex === totalItems - 1 || currentIndex % stepSize === 0
        ? timeZonedFormattedDate
        : null;
};
