<style scoped>
    .action-link {
        cursor: pointer;
    }
</style>

<template>
    <div>
        <div>
            <div class="card card-default">
                <div class="card-header">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>
                            Personal Access Tokens
                        </span>

                        <a class="action-link" tabindex="-1" @click="showCreateTokenModal = true">
                            Create New Token
                        </a>
                    </div>
                </div>

                <div class="card-body">
                    <!-- No Tokens Notice -->
                    <p class="mb-0" v-if="tokens.length === 0">
                        You have not created any personal access tokens.
                    </p>

                    <!-- Personal Access Tokens -->
                    <table class="table table-responsive table-borderless mb-0" v-if="tokens.length > 0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(token,index) in tokens" :key="index">
                                <!-- Client Name -->
                                <td style="vertical-align: middle;">
                                    {{ token.name }}
                                </td>

                                <!-- Delete Button -->
                                <td style="vertical-align: middle;">
                                    <a class="action-link text-danger" @click="revoke(token)">
                                        Delete
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Create Token Modal -->
        <modal v-model:visible="showCreateTokenModal" title="Create Token" submit-text="Create" @submit="store"
               @shown="$refs.createTokenNameInp.focus()">
            <!-- Form Errors -->
            <div class="alert alert-danger" v-if="form.errors.length > 0">
                <p class="mb-0"><strong>Whoops!</strong> Something went wrong!</p>
                <br>
                <ul>
                    <li v-for="(error,index) in form.errors" :key="index">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <!-- Create Token Form -->
            <form role="form" @submit.prevent="store">
                <!-- Name -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Name</label>

                    <div class="col-md-6">
                        <input ref="createTokenNameInp" type="text" class="form-control" name="name" v-model="form.name" />
                    </div>
                </div>

                <!-- Scopes -->
                <div class="form-group row" v-if="scopes.length > 0">
                    <label class="col-md-4 col-form-label">Scopes</label>

                    <div class="col-md-6">
                        <div v-for="(scope, index) in scopes" :key="index">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox"
                                        @click="toggleScope(scope.id)"
                                        :checked="scopeIsAssigned(scope.id)">

                                        {{ scope.id }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </modal>

        <!-- Access Token Modal -->
        <modal v-model:visible="showAccessTokenModal" title="Personal Access Token" no-submit-btn>
            <p>
                Here is your new personal access token. This is the only time it will be shown so don't lose it!
                You may now use this token to make API requests.
            </p>

            <textarea class="form-control" rows="10" :value="accessToken"></textarea>
        </modal>
    </div>
</template>

<script>
    import Modal from '../general/Modal.vue';
    import axios from '~/services/axios';

    export default {
        components: {
            Modal,
        },

        /*
         * The component's data.
         */
        data() {
            return {
                showCreateTokenModal: false,
                showAccessTokenModal: false,
                accessToken: null,

                tokens: [],
                scopes: [],

                form: {
                    name: '',
                    scopes: [],
                    errors: []
                }
            };
        },

        /**
         * Prepare the component (Vue 1.x).
         */
        ready() {
            this.prepareComponent();
        },

        /**
         * Prepare the component (Vue 2.x).
         */
        mounted() {
            this.prepareComponent();
        },

        methods: {
            /**
             * Prepare the component.
             */
            prepareComponent() {
                this.getTokens();
                this.getScopes();
            },

            /**
             * Get all of the personal access tokens for the user.
             */
            getTokens() {
                axios.get('/oauth/personal-access-tokens')
                        .then(response => {
                            this.tokens = response.data;
                        });
            },

            /**
             * Get all of the available scopes.
             */
            getScopes() {
                axios.get('/oauth/scopes')
                        .then(response => {
                            this.scopes = response.data;
                        });
            },

            /**
             * Create a new personal access token.
             */
            store() {
                this.accessToken = null;

                this.form.errors = [];

                axios.post('/oauth/personal-access-tokens', this.form)
                        .then(response => {
                            this.form.name = '';
                            this.form.scopes = [];
                            this.form.errors = [];

                            this.tokens.push(response.data.token);

                            this.showAccessToken(response.data.accessToken);
                        })
                        .catch(error => {
                            if (typeof error.response.data === 'object') {
                                this.form.errors = Object.values(error.response.data.errors).flat();
                            } else {
                                this.form.errors = ['Something went wrong. Please try again.'];
                            }
                        });
            },

            /**
             * Toggle the given scope in the list of assigned scopes.
             */
            toggleScope(scope) {
                if (this.scopeIsAssigned(scope)) {
                    this.form.scopes = this.form.scopes.filter((s) => s !== scope);
                } else {
                    this.form.scopes.push(scope);
                }
            },

            /**
             * Determine if the given scope has been assigned to the token.
             */
            scopeIsAssigned(scope) {
                return this.form.scopes.indexOf(scope) >= 0;
            },

            /**
             * Show the given access token to the user.
             */
            showAccessToken(accessToken) {
                this.showCreateTokenModal = false;

                this.accessToken = accessToken;

                this.showAccessTokenModal = true;
            },

            /**
             * Revoke the given token.
             */
            revoke(token) {
                axios.delete('/oauth/personal-access-tokens/' + token.id)
                        .then(() => {
                            this.getTokens();
                        });
            }
        }
    }
</script>
