<template>
    <campaign-contact-management
        v-if="campaign?.id"
        :campaign="campaign"
        @close="goToList()"
    >
    </campaign-contact-management>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useToasted } from '../../../composables/useToasted';
import campaignsClient from '../../../services/campaignsClient';
import CampaignContactManagement from '../../campaign-contacts/CampaignContactManagement.vue';

const teamId = Spark.state.currentTeam.id;
const props = defineProps(["campaignId"]);

onMounted(() => {
    fetchData();
});

const campaign = ref({});

const toasted = useToasted();

const fetchData = async () => {
    try {
        const response = await campaignsClient.campaignGet(
            teamId,
            props.campaignId
        );
        campaign.value = response.data;
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    } 
};


const goToList = () => {
    window.location.href = "/messaging/campaigns";
};
</script>
