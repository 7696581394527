<template>
    <vx-expandable-card>
        <template v-slot:header>
            Dashboard
        </template>
        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-6">
                <vx-alert v-if="success" :closeable="false" color="success" shadow>
                    Updated successfully
                </vx-alert>
                <vx-alert v-if="error" :closeable="false" color="danger" shadow>
                    {{ error }}
                </vx-alert>

                <div class="vx-flex vx-items-center vx-justify-between">
                    <div>
                        <vx-label size="md">Enable Dashboard Link</vx-label>
                        <div class="vx-text-sm vx-text-slate-500">Allows linking to separate analytics dashboard.</div>
                    </div>
                    <vx-toggle v-model="enabled" size="lg"></vx-toggle>
                </div>

                <div>
                    <vx-label size="md">Dashboard GUID</vx-label>
                    <vx-input v-model="team.guid" size="lg" :disabled="!enabled" :error="displayFirstError(errors.guid)"></vx-input>
                </div>

                <div>
                    <vx-label size="md">URL Template</vx-label>
                    <vx-input v-model="team.url_template" size="lg" :disabled="!enabled" :error="displayFirstError(errors.url_template)"></vx-input>
                </div>

                <div class="help-block" v-if="enabled">
                   <vx-label size="md">Preview</vx-label>
                    <a class="vx-block vx-font-mono vx-font-normal vx-break-all vx-underline vx-text-sky-700" target="_blank" :href="getDashboardUrl(team.guid, team.url_template, team.id)">
                        {{ getDashboardUrl(team.guid, team.url_template, team.id) }}
                    </a>
                </div>

                <vx-button size="lg" @click.prevent="submit"
                    :loading="submitting"
                    :disabled="team.dashboard_url === computedDashboardUrl">
                    Update
                </vx-button>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxExpandableCard, VxInput, VxToggle, VxButton, VxLabel, VxAlert } from '@voxie/frontend-components';
import { nextTick, watch } from 'vue';
import { computed } from 'vue';
import { onMounted, ref } from 'vue';
import { teamsClient } from '~/services';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_UNPROCESSABLE_ENTITY } from '~/support/HttpStatuses';
import { displayFirstError } from '~/utils/validation';

const team = defineModel('team', {
    type: Object,
    required: true,
});

const submitting = ref(false);
const success = ref(false);
const error = ref('');
const errors = ref({});
const enabled = ref(false);

onMounted(async () => {
    await nextTick();
    parseGuidAndTemplate();
    enabled.value = Boolean(team.value.dashboard_url && team.value.dashboard_url.length > 1);
    if (!team.value.dashboard_url) {
        team.value.dashboard_url = '';
    }
});

watch(enabled, () => {
    if (!enabled.value) {
        team.value.guid = '';
        team.value.url_template = '';
    }
});

const submit = () => {
    if (submitting.value) {
        return;
    }

    if (team.value.dashboard_url === computedDashboardUrl.value) {
        return;
    }

    if (!window.confirm('Are you sure? Changing the dashboard URL may cause analytics to no longer function.')) {
        return;
    }

    success.value = false;
    errors.value = {};
    error.value = ''
    submitting.value = true;

    teamsClient.updateDetails(
        team.value.id,
        {
            dashboard_url: computedDashboardUrl.value,
        }
    ).then(() => {
        success.value = true;
    }).catch((e) => {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            error.value = 'Something went wrong.'
        } else {
            if (e.response.status === HTTP_UNPROCESSABLE_ENTITY) {
                errors.value = e.response.data.errors
            }
            error.value = e.response?.data?.message || 'Something went wrong.';
        }
    }).finally(() => {
        submitting.value = false;
    })
}

const parseGuidAndTemplate = () => {
    if (typeof team.value.dashboard_url !== 'string' || team.value.dashboard_url.length === 0) {
        team.value.url_template = '';
        team.value.guid = '';
    } else {
        let parts1 = team.value.dashboard_url.split('?');
        team.value.url_template = parts1[1] || '';

        let parts2 = parts1[0].split('/');
        team.value.guid = parts2[parts2.length - 1];
    }

    return;
}

const computedDashboardUrl = computed(() => {
    if (team.value.guid === '' || !enabled.value) {
        return '';
    }

    return getDashboardUrl(team.value.guid, team.value.url_template);
})

const getDashboardUrl = (guid, template, teamId) => {
    let urlbase = 'https://metabase.voxie.com/public/dashboard/';

    let url = urlbase + guid;
    if (template) {
        url += '?' + template;
    }

    if (typeof teamId !== 'undefined') return url.replace(/{team->id}/, teamId);
    else return url;
}

</script>
