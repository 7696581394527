<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons'

const props = defineProps({
  x: {
    type: Number,
    default: 34,
  },
  y: {
    type: Number,
    default: 10,
  },
  fill: {
    type: String,
    default: 'vx-fill-violet-300',
  },
  icon: {
    default: faCirclePlus,
  },
})

const heightSm = 32
const iconBoxWidthSm = 48
const radiusSm = heightSm / 2
</script>

<template>
  <svg :x="props.x" :y="props.y" overflow="visible">
    <!-- background -->
    <rect
      x="0"
      y="0"
      fill="#fff"
      :width="iconBoxWidthSm"
      :height="heightSm"
      :rx="radiusSm"
      class="vx-drop-shadow-md"
    />

    <!-- icon box -->
    <rect
      x="0"
      y="0"
      fill="#0ff"
      :width="iconBoxWidthSm"
      :height="heightSm"
      :rx="radiusSm"
      :class="props.fill"
    />

    <font-awesome-icon
      :icon="props.icon"
      :width="iconBoxWidthSm * 0.3"
      :height="heightSm"
      :x="iconBoxWidthSm * 0.35"
      :y="0"
      class="vx-text-black"
    />

    <!-- border -->
    <rect
      x="0"
      y="0"
      :width="iconBoxWidthSm"
      :height="heightSm"
      :rx="radiusSm"
      stroke="#fff"
      stroke-width="2"
    />
  </svg>
</template>
