<script>
import dayjs from '~/utils/dayjs';
import axios from '~/services/axios';

export default {
    /**
     * The application's data.
     */
    data() {
        return {
            lastRefreshedApiTokenAt: null,
            user: Spark.state.user,
            teams: Spark.state.teams,
            currentTeam: Spark.state.currentTeam,
            loadingNotifications: false,
            notifications: null,
        };
    },

    /**
     * The component has been created by Vue.
     */
    created() {
        const self = this;

        if (Spark.userId) {
            this.loadDataForAuthenticatedUser();
        }

        if (Spark.userId && Spark.usesApi) {
            this.refreshApiTokenEveryFewMinutes();
        }

        Bus.$on('updateUser', function () {
            self.getUser();
        });

        Bus.$on('updateUserData', function () {
            self.loadDataForAuthenticatedUser();
        });

        Bus.$on('updateTeams', function () {
            self.getTeams();
        });

        Bus.$on('showNotifications', function () {
            $('#modal-notifications').modal('show');
            self.markNotificationsAsRead();
        });
    },

    /**
     * Prepare the application.
     */
    mounted() {
        this.whenReady();

        if (Spark.showEnableMFAModal) {
            $('#modal-enable-two-factor-authentication').modal('show');
        }
    },

    methods: {
        /**
         * Finish bootstrapping the application.
         */
        whenReady() {
            if (this.shouldDisplayNotActivated()) {
                this.displayNotActiveModal();
            }
        },

        shouldDisplayNotActivated() {
            if (!this.currentTeam) {
                return false;
            }
            const sessionKey = `displayedNotActiveTeam.${this.currentTeam.id}`;

            const wasDisplayed = window.sessionStorage.getItem(sessionKey);

            if (this.isNotActive && !wasDisplayed) {
                window.sessionStorage.setItem(sessionKey, 1);
                return true;
            }

            return false;
        },

        displayNotActiveModal() {
            $('#modal-not-active-team').modal('show');
        },

        /**
         * Load the data for an authenticated user.
         */
        loadDataForAuthenticatedUser() {
            this.getNotifications();
        },

        /**
         * Refresh the current API token every few minutes.
         */
        refreshApiTokenEveryFewMinutes() {
            this.lastRefreshedApiTokenAt = dayjs();

            setInterval(() => {
                this.refreshApiToken();
            }, 240000);

            setInterval(() => {
                if (dayjs().diff(this.lastRefreshedApiTokenAt, 'minutes') >= 5) {
                    this.refreshApiToken();
                }
            }, 5000);
        },

        /**
         * Refresh the current API token.
         */
        refreshApiToken() {
            this.lastRefreshedApiTokenAt = dayjs();

            axios.put('/spark/token');
        },

        /*
         * Get the current user of the application.
         */
        getUser() {
            axios.get('/user/current').then((response) => {
                this.user = response.data;
            });
        },

        /**
         * Get the current team list.
         */
        getTeams() {
            axios.get('/settings/teams').then((response) => {
                this.teams = response.data;
            });
        },

        /**
         * Get the current team.
         */
        getCurrentTeam() {
            axios
                .get(`/settings/teams/current`)
                .then((response) => {
                    this.currentTeam = response.data;
                })
                .catch(() => {
                    //
                });
        },

        /**
         * Get the application notifications.
         */
        getNotifications() {
            this.loadingNotifications = true;
            axios.get('/notifications/recent')
                .then((response) => {
                    this.notifications = response.data.data;
                    this.loadingNotifications = false;
                });
        },

        /**
         * Mark the current notifications as read.
         */
        markNotificationsAsRead() {
            if (!this.hasUnreadNotifications) {
                return;
            }

            axios.put('/notifications/read', {
                notifications: this.notifications.map((n) => n.id),
            });

            this.notifications = this.notifications.map((n) => {
                n.read = 1;

                return n;
            });
        },

    },

    computed: {
        isNotActive() {
            return Spark.state.currentTeam.status !== 'active';
        },
        unreadNotificationsCount() {
            if (this.notifications) {
                return this.notifications.filter((notification) => {
                    return !notification.read;
                }).length;
            }

            return 0;
        },
        hasUnreadNotifications() {
            return this.unreadNotificationsCount > 0;
        },
    },
};
</script>
