<template>
    <multi-select-attribution-card
        entity="Promotion"
        entityTitle="Promotion"
        entityDescription="promotion"
        :selected-items="form.selected_promotions"
        v-model:type="form.promotion_attribution_type"
        :loading-options="loadingOptions"
        :options="options"
        @search-change="search"
    />
</template>

<script>
import MultiSelectAttributionCard from './MultiSelectAttributionCard.vue';
import { promotionsClient } from '../../../../services';

export default {
    components: {
        MultiSelectAttributionCard,
    },
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loadingOptions: false,
            options: [],
        };
    },

    created() {
        this.search('');
    },

    methods: {
        search(query) {
            this.loadingOptions = true;
            const params = {
                query,
                per_page: 20,
                minimal: 1,
            };
            promotionsClient.searchPromotions(Spark.state.currentTeam.id, params)
                .then(({ data }) => {
                    this.options = data.data.map(p => ({
                        id: p.id,
                        name: p.name,
                        label: p.name,
                    }));
                })
                .finally(() => {
                    this.loadingOptions = false;
                });
        },
    },
};
</script>
