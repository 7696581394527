<template>
    <th class="cursor-pointer">
        <div class="d-flex align-items-center">
            <div>
                <slot />
            </div>
            <div class="ml-1">
                <sort-icon :sort-dir="sortDir" />
            </div>
        </div>
    </th>
</template>

<script>
import SortIcon from './SortIcon.vue';

export default {
    components: { SortIcon },
    props: {
        sortDir: String, // Can be 'asc' or 'desc'
    },
};
</script>
