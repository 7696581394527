<template>
    <div class="vx-space-y-8 md:vx-space-y-12 vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8">
        <div v-if="loading"
            class="vx-text-center vx-py-10">
            <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
        </div>
        <step-form-heading v-if="!props.groupId" :icon="faGrid2Plus">
            New Group

            <template v-slot:description>
                Create a group to connect specific users to a set of resources in Voxie.
            </template>
        </step-form-heading>

        <page-header v-else class="vx-mb-6 lg:vx-mb-12">
            <template v-slot:subtitle>
                <a :href="`/settings/teams/${teamId}#/groups`"
                   class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                    <font-awesome-icon class="vx-mr-1" :icon="faArrowLeft"></font-awesome-icon>
                    All Groups
                </a>
            </template>

            {{ group.name }}
        </page-header>

        <div :class="{'vx-grid vx-grid-cols-12': props.groupId}">
            <page-sidebar v-if="props.groupId" appearance="nested" :links="[
                {href: `/settings/teams/${teamId}/groups/${props.groupId}`, label: 'Details'},
                {href: `/settings/teams/${teamId}/groups/${props.groupId}/users`, label: 'Users'},
                {href: `/settings/teams/${teamId}/groups/${props.groupId}/collections`, label: 'Collections'},
                {label: 'Resources', links: resourcesLinks},
            ]"></page-sidebar>

            <vx-sticky-nav :sections="[
                { id: 'group_id', label: 'ID' },
                { id: 'name', label: 'Name' },
                { id: 'description', label: 'Description' },
                { id: 'attributes', label: 'Attributes' },
            ]"
               class="vx-col-span-12"
               :class="{'lg:vx-col-span-10': !!props.groupId}"
               :navClasses="`${success || props.groupId ? 'vx-hidden' : ''}`">

                <div class="vx-flex vx-flex-col vx-w-full vx-justify-center vx-gap-2 md:vx-gap-8 vx-mx-auto">

                    <vx-alert v-if="success"
                              color="success"
                              class="vx-max-w-screen-sm vx-w-full"
                              :class="{'vx-mx-auto': !props.groupId}"
                              :closeable="false"
                              shadow>
                        <template v-slot:icon>
                            <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                        </template>
                        <div class="vx-flex vx-items-center vx-justify-between">
                           <span>
                                <strong>{{ props.groupId ? 'Existing Group' : 'New Group' }}</strong>
                                {{ props.groupId ? 'updated' : 'created' }} successfully
                            </span>
                            <vx-button :href="`/settings/teams/${teamId}#/groups`"
                                       size="sm"
                                       color="success">Back to Groups<font-awesome-icon
                                :icon="faSquareArrowUpLeft"></font-awesome-icon></vx-button>
                        </div>
                    </vx-alert>

                    <vx-alert v-if="error"
                              color="danger"
                              class="vx-max-w-screen-sm vx-w-full"
                              :class="{'vx-mx-auto': !props.groupId}"
                              :closeable="false"
                              shadow>{{ error }}</vx-alert>

                    <group-form-external-id id="group_id"
                        v-model="group.group_id"
                        class="vx-max-w-screen-sm vx-w-full"
                        :class="{'vx-mx-auto': !props.groupId}"
                        :locked="!!props.groupId"
                        :disabled="!!props.groupId"
                        :requiredLabel="!props.groupId"
                        :error="errors.name || displayFirstError(validation.group_id.$errors)">
                    </group-form-external-id>

                    <group-form-name id="name"
                        v-model="group.name"
                        class="vx-max-w-screen-sm vx-w-full"
                        :class="{'vx-mx-auto': !props.groupId}"
                        :error="errors.name || displayFirstError(validation.name.$errors)">
                    </group-form-name>

                    <group-form-description id="description"
                        v-model="group.description"
                        class="vx-max-w-screen-sm vx-w-full"
                        :class="{'vx-mx-auto': !props.groupId}"
                        :error="errors.description || displayFirstError(validation.description.$errors)">
                    </group-form-description>

                    <group-form-phone-numbers v-if="props.groupId && groupsUseCorporateNumbers"
                        v-model="phoneNumbers"
                        class="vx-max-w-screen-sm vx-w-full">
                    </group-form-phone-numbers>

                    <group-form-custom-attributes id="attributes"
                        v-model="customAttributes"
                        class="vx-max-w-screen-sm vx-w-full"
                        :class="{'vx-mx-auto': !props.groupId}">
                    </group-form-custom-attributes>

                    <vx-sticky-footer class="vx-max-w-screen-sm vx-w-full" :class="{'vx-mx-auto': !props.groupId}">
                        <vx-button :href="`/settings/teams/${teamId}#/groups`"
                            class="vx-w-20"
                            size="lg"
                            color="muted">
                            Cancel
                        </vx-button>
                        <vx-button @click="submit()"
                            data-test="submit-button"
                            class="vx-grow"
                            size="lg"
                            color="primary"
                            :loading="submitting">
                            {{
                                props.groupId
                                    ? 'Update'
                                    : 'Save'
                            }}
                        </vx-button>
                    </vx-sticky-footer>
                </div>
            </vx-sticky-nav>
        </div>
    </div>
</template>

<script setup>
import {
    VxButton,
    VxIconLoading,
    VxAlert
} from "@voxie/frontend-components";
import StepFormHeading from '~/components/general/StepFormHeading.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowLeft, faCircleCheck, faGrid2Plus, faSquareArrowUpLeft } from '@fortawesome/pro-solid-svg-icons';
import { ref, computed, onMounted } from 'vue';
import { VxStickyNav, VxStickyFooter } from '@voxie/frontend-components';
import { useToasted } from '~/composables/useToasted';
import useVuelidate from '@vuelidate/core';
import { required, maxLength, helpers } from '@vuelidate/validators';
import { displayFirstError } from '~/utils/validation';
import GroupFormExternalId from '~/components/settings/teams/groups/form/GroupFormExternalId.vue';
import GroupFormName from '~/components/settings/teams/groups/form/GroupFormName.vue';
import GroupFormDescription from './GroupFormDescription.vue';
import GroupFormCustomAttributes from './GroupFormCustomAttributes.vue';
import groupsClient from '~/services/groupsClient';
import PageHeader from '../../../../general/PageHeader.vue';
import PageSidebar from '../../../../general/PageSidebar.vue';
import GroupFormPhoneNumbers from './GroupFormPhoneNumbers.vue';
import phoneNumbersClient from '~/services/phoneNumbersClient';
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;
const groupsUseCorporateNumbers = Spark.state.currentTeam.groups_use_corporate_numbers;

const props = defineProps({
    groupId: {
        type: String,
        required: false,
    },
});

const error = ref();
const errors = ref({});
const success = ref(false);
const submitting = ref(false);
const loadingGroup = ref(false);
const loadingPhoneNumbers = ref(false);
const loadingCustomAttributes = ref(false);
const loading = computed(() => loadingGroup.value && loadingPhoneNumbers.value && loadingCustomAttributes.value)

const defaultGroup = () => {
    return {
        group_id: '',
        name: '',
        description: '',
    };
};

const group = ref(defaultGroup());
const customAttributes = ref([]);
const originalCustomAttributes = ref([]);
const phoneNumbers = ref([]);

const resourcesLinks = computed(() => {
    return [
        {
            label: 'Contacts',
            href: `/settings/teams/${teamId}/groups/${props.groupId}/contacts`,
        },
        {
            label: 'Segments',
            href: `/settings/teams/${teamId}/groups/${props.groupId}/segments`,
        },
        {
            label: 'Campaigns',
            href: `/settings/teams/${teamId}/groups/${props.groupId}/campaigns`,
        },
        {
            label: 'Snippets',
            href: `/settings/teams/${teamId}/groups/${props.groupId}/snippets`,
        },
    ];
});

onMounted(() => {
    if (props.groupId) {
        getGroup();
        getGroupCustomAttributes();

        if (groupsUseCorporateNumbers) {
            getGroupPhoneNumbers();
        }
    }
});

const validation = useVuelidate({
    group_id: {
        required,
        maxLength: maxLength(40),
        format: helpers.withMessage('You can use only alphanumeric lower case letters, and the following symbols: _-', helpers.regex(/^[a-z0-9][a-z0-9_-]*$/)),
    },
    name: {
        required,
        maxLength: maxLength(80),
        format: helpers.withMessage('You can use only alphanumeric letters, and the following symbols: _-', helpers.regex(/^[a-zA-Z0-9][a-zA-Z0-9 _-]*$/)),
    },
    description: { maxLength: maxLength(2000) },
}, group, { $autoDirty: true });

const submit = async () => {
    if (submitting.value) {
        return;
    }

    const isValid = await validation.value.$validate();

    if (!isValid) {
        toasted.error('Please check form data.');
        error.value = 'Please check form data.';
        // Remove success alert card if visible
        success.value = false;

        return;
    }

    submitting.value = true;

    try {
        if (undefined === props.groupId) {
            const response = await groupsClient.createGroup(teamId, group.value);

            await Promise.all(customAttributes.value.map((customAttribute) => {
                return groupsClient.createGroupCustomAttribute(teamId, response.data.group_id, customAttribute);
            }));

            group.value = defaultGroup();
            customAttributes.value = [];
        } else {
            await groupsClient.updateGroup(teamId, props.groupId, { name: group.value.name, description: group.value.description });
            await syncGroupCustomAttributes();

            originalCustomAttributes.value = customAttributes.value.map(attribute => ({...attribute}));
        }

        success.value = true;
        error.value = null;
        validation.value.$reset();
    } catch (e) {
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            error.value = 'Something went wrong.'
        } else {
            error.value = e.response?.data?.message || 'Something went wrong.';
        }
        toasted?.error(error.value);

        // Remove success alert card if visible
        success.value = false;
    } finally {
        submitting.value = false;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
};

const getGroup = async () => {
    try {
        loadingGroup.value = true;
        const response = await groupsClient.showGroup(teamId, props.groupId);
        group.value = response.data;
    } finally {
        loadingGroup.value = false;
    }
};

const getGroupCustomAttributes = async () => {
    try {
        loadingCustomAttributes.value = true;
        const response = await groupsClient.listGroupCustomAttributes(teamId, props.groupId);
        customAttributes.value = response.data.data.map(attribute => ({...attribute}));
        originalCustomAttributes.value = response.data.data.map(attribute => ({...attribute}));
    } catch (e) {
        error.value = e.response?.data?.message || 'Something went wrong.';
        toasted?.error(error.value);
    } finally {
        loadingCustomAttributes.value = false;
    }
};

const getGroupPhoneNumbers = async () => {
    try {
        loadingPhoneNumbers.value = true;
        const response = await phoneNumbersClient.filterPhoneNumbers(teamId, { group_id: props.groupId });
        phoneNumbers.value = response.data.data;
    } catch (e) {
        error.value = e.response?.data?.message || 'Something went wrong.';
        toasted?.error(error.value);
    } finally {
        loadingPhoneNumbers.value = false;
    }
};

const syncGroupCustomAttributes = () => {
    return Promise.all([deleteCustomAttributes(), updateCustomAttributes(), createCustomAttributes()]);
};

const deleteCustomAttributes = () => {
    const attributesToDelete = originalCustomAttributes.value.filter(
        attribute => !customAttributes.value.map(a => a.key).includes(attribute.key)
    );

    return Promise.all(attributesToDelete.map((customAttribute) => {
        return groupsClient.deleteGroupCustomAttribute(teamId, props.groupId, customAttribute.key);
    }));
};

const updateCustomAttributes = () => {
    const attributesToUpdate = customAttributes.value.filter(
        attribute => {
            const value = originalCustomAttributes.value.find(a => a.key === attribute.key)?.value;
            return undefined !== value && value !== attribute.value;
        }
    );

    return Promise.all(attributesToUpdate.map((customAttribute) => {
        return groupsClient.updateGroupCustomAttribute(teamId, props.groupId, customAttribute.key, { value: customAttribute.value });
    }));
};

const createCustomAttributes = () => {
    const attributesToCreate = customAttributes.value.filter(
        attribute => !originalCustomAttributes.value.map(a => a.key).includes(attribute.key)
    );

    return Promise.all(attributesToCreate.map((customAttribute) => {
        return groupsClient.createGroupCustomAttribute(teamId, props.groupId, customAttribute);
    }));
};
</script>
