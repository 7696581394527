<template>
    <div>
        <resource-table
            :table-title="tableTitle"
            :table-columns="tableColumns"
            :table-title-pluralize="false"
            :create-feature="false"
            :search-feature="false"
            :page-url="pageUrl"
            :entity-name="entityName"
            :entity-fetch-client="apiClient.getSegmentContacts.bind(apiClient, segment.id)"
        >
            <template v-slot:actions>
                <div class="vx-flex vx-space-x-2">
                    <vx-button color="primary" type="button" @click="tagContactsModal = true">Tag Contacts <font-awesome-icon :icon="faTags"></font-awesome-icon></vx-button>
                    <vx-button color="primary" type="button" @click="addContactsModal = true">Add to Campaign <font-awesome-icon :icon="faSquarePlus"></font-awesome-icon></vx-button>
                    <vx-button color="secondary" href="/contacts/segments">Back to Segments</vx-button>
                </div>
            </template>

            <template v-slot:table-row="slotProps">
                <segment-contact-row
                    :contact="slotProps.entity"
                    :key="slotProps.entity.id"
                ></segment-contact-row>
            </template>
        </resource-table>

        <modal-tag-contacts :segment="this.segment" v-model:visible="tagContactsModal"></modal-tag-contacts>
    </div>
</template>

<script>
import { VxButton } from '@voxie/frontend-components';
import { segmentsClient } from '../../../services';
import { camelCase } from '~/utils/string';
import ResourceTable from '../../../components/management/ResourceTable.vue';
import SegmentContactRow from '../../contacts/segments/SegmentContactRow.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTags, faSquarePlus,  } from '@fortawesome/pro-solid-svg-icons';
import ModalTagContacts from './ModalTagContacts.vue';
import clientRoutes from './../../../mixins/client-routes';

export default {
    props: ['segment'],

    components: {
        ResourceTable,
        SegmentContactRow,
        VxButton,
        FontAwesomeIcon,
        ModalTagContacts,
    },
    inject: ['FEATURES'],

    mixins: [
        clientRoutes,
    ],

    data() {
        return {
            addContactsModal: false,
            tagContactsModal: false,
            teamId: Spark.state.currentTeam.id,

            apiClient: segmentsClient,

            pageUrl: `contacts/${this.segment.id}`,
            entityName: 'Contact',
            tableTitle: `Contacts for Segment: ${this.segment.name}`,
            tableColumns: [{
                name: 'Contact',
                classNames: ['hidden-xs', 'hidden-sm']
            }, {
                name: 'Identity',
                classNames: []
            }, {
                name: 'Segments',
                classNames: ['center', 'hidden-xs']
            }, {
                name: 'Tags',
                classNames: ['center', 'hidden-xs']
            }, {
                name: 'Status',
                classNames: ['center', 'hidden-xs']
            }, {
                name: 'Actions',
                classNames: ['center']
            },]
        };
    },

    mounted() {
      this.loadCollectionAndUi();
    },
    computed: {
        faTags: () => faTags,
        faSquarePlus: () => faSquarePlus,
    },

    methods: {
        loadCollectionAndUi() {
            let route = this.currentClientRoute();

            if (route) {
                const eventEntity = camelCase(this.entityName);

                if (route.hash === 'show' && route.parameters.length === 2) {
                    Bus.$emit(`${eventEntity}UpdateResourceTable`, {
                        searchParam: null,
                        cursor: route.parameters[1]
                    })
                } else {
                    Bus.$emit(`${eventEntity}UpdateResourceTable`, {
                        searchParam: null,
                        cursor: null
                    })
                }
            } else {
                const eventEntity = camelCase(this.entityName);
                Bus.$emit(`${eventEntity}UpdateResourceTable`, {
                    searchParam: null,
                    cursor: null
                })
            }
        },

        close() {
            Bus.$emit('segmentsCancelShowContacts', this.segment.id);
        },
    }
}
</script>
