<template>
    <div>
        <playbook-form-card :icon="faHourglassHalf">
            <template v-slot:header>
                Delay
            </template>
            <template v-slot:description>
                This allows you to delay the first message by a set number of days after a contact matches the parameters
                set in the previous step.
            </template>
            <div>
                <vx-label size="lg" class="vx-mb-1.5">
                    How many days should we wait?
                </vx-label>
                <vx-input size="lg" placeholder="0" :modelValue="String(props.modelValue)" @blur="validation.modelValue.$touch()"
                    @update:modelValue="emit('update:modelValue', $event)">
                    <template v-slot:hint>
                        <span v-if="validation.modelValue.$errors.length" class="vx-text-rose-800" data-test="initial-delay-validation-error">
                            {{ displayFirstError(validation.modelValue.$errors) }}
                        </span>
                        <span v-else>
                            The value must be between 0 and 30
                        </span>
                    </template>
                </vx-input>
            </div>
        </playbook-form-card>
    </div>
</template>

<script setup>
import PlaybookFormCard from '../PlaybookFormCard.vue';
import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons';
import { VxLabel, VxInput } from '@voxie/frontend-components';
import { computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, between } from '@vuelidate/validators';
import { displayFirstError } from '~/utils/validation';

const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: '',
    },
});

const emit = defineEmits(['update:modelValue']);

const validation = useVuelidate({
    modelValue: {
        required,
        betweenValue: between(0, 30),

    },
}, computed(() => props));

</script>
