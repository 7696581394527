<template>
    <vx-expandable-card
        toggleable
        id="type"
        :active="active"
    >
        <template v-slot:header>
            <span
                class="vx-pr-1.5 vx-mr-2 vx-border-y-0 vx-border-l-0 vx-border-r vx-border-solid sm:vx-pr-3 sm:vx-mr-4"
                :class="{
                    'vx-border-r-transparent': !editable,
                    'vx-border-r-slate-400': editable,
                }"
            >
                Type
            </span>
            <span
                v-if="editable"
                class="vx-capitalize vx-text-xs vx-font-normal vx-text-slate-700 sm:vx-text-base sm:vx-font-semibold"
            >
                {{ props.modelValue }}
            </span>
        </template>
        <template v-if="editable && !props.success" v-slot:headerRight>
            <vx-button
                @click.stop="emit('update:active', true)"
                color="muted"
                size="md"
            >
                <span class="vx-inline-block">Edit</span>
                <font-awesome-icon class="vx-text-base vx-hidden sm:vx-inline-block" :icon="faPencil"></font-awesome-icon>
            </vx-button>
        </template>
        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-items-start">
                <strong class="vx-text-slate-900 vx-text-sm vx-font-semibold sm:vx-text-base sm:vx-leading-6">
                    What audience type are you messaging?
                </strong>
                <span class="vx-text-slate-500 vx-text-xs vx-font-normal vx-mb-2">
                    This selection will limit the Segments you can send to.
                </span>
            </div>

            <vx-radio-selection :modelValue="props.modelValue" @update:modelValue="updateType($event)">
                <vx-radio-selection-item :value="AUDIENCE_TYPE_MARKETING" data-test="quick-blast-type-marketing">
                    <template v-slot:header>
                        Marketing
                    </template>
                    <template v-slot:content>
                      <ul class="vx-list-disc vx-list-outside vx-ml-10 lg:vx-ml-4" v-html="audienceTypeDescription(AUDIENCE_TYPE_MARKETING)"></ul>
                    </template>
                </vx-radio-selection-item>
                <vx-radio-selection-item :value="AUDIENCE_TYPE_TRANSACTIONAL" data-test="quick-blast-type-transactional">
                    <template v-slot:header>
                        Transactional
                    </template>
                    <template v-slot:content>
                      <ul class="vx-list-disc vx-list-outside vx-ml-10 lg:vx-ml-4" v-html="audienceTypeDescription(AUDIENCE_TYPE_TRANSACTIONAL)"></ul>
                    </template>
                </vx-radio-selection-item>
            </vx-radio-selection>
            <vx-button
                @click="emit('continue')"
                class="vx-mt-4 lg:vx-mt-6"
                size="lg"
                :block="true"
                data-test="type-continue"
            >
                Continue
                <font-awesome-icon
                    class="vx-text-base"
                    :icon="faArrowTurnDown"
                ></font-awesome-icon>
            </vx-button>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import {
    VxExpandableCard,
    VxRadioSelection,
    VxRadioSelectionItem,
    VxButton,
} from "@voxie/frontend-components";
import { computed } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPencil, faArrowTurnDown } from '@fortawesome/pro-solid-svg-icons';
import { audienceTypeDescription, AUDIENCE_TYPE_MARKETING, AUDIENCE_TYPE_TRANSACTIONAL } from '../../constants/campaign';

const props = defineProps({
    modelValue: {
        type: String,
    },
    active: {
        type: Boolean,
        default: false,
    },
    success: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits(["update:modelValue", "update:active", "continue"]);

const updateType = (type) => {
    emit("update:modelValue", type);
};

const editable = computed(() => !props.active && props.modelValue);
</script>
