<template>
    <scheduled-message-modal v-model:visible="this.showModal" @scheduledMessageEdited="editScheduledMessage" :scheduled-message="selectedScheduledMessage"></scheduled-message-modal>

    <table class="table table-borderless">
        <thead class="scheduled-messages__table-header">
            <tr>
                <th scope="col">Scheduled Date</th>
                <th scope="col">Scheduled By</th>
                <th scope="col">Contact</th>
                <th v-if="isPendingTabSelected"><!--Empty header--></th>
            </tr>
        </thead>
        <tbody class="scheduled-messages__table-body">
            <tr v-if="loadingTable">
                <td class="c-table__cell center text-center" colspan="4">
                    <loading-resource />
                </td>
            </tr>
            <tr v-else-if="!scheduledMessages.length">
                <td class="c-table__cell center text-center" colspan="4">
                    <vx-heading :level=2 :subheading="true" v-if="isPendingTabSelected">No Pending Scheduled Messages</vx-heading>
                    <vx-heading :level=2 :subheading="true" v-else>No Sent Scheduled Messages</vx-heading>
                </td>
            </tr>

            <tr v-for="scheduledMessage in scheduledMessages" :key="scheduledMessage.id">
                <td data-test="formatted-date">{{ formattedDate(scheduledMessage.send_at) }}</td>
                <td>{{ scheduledMessage.user.name }}</td>
                <td>
                    <span>{{ scheduledMessage.contact.first_name }} {{ scheduledMessage.contact.last_name }}</span> <br><br>
                    <span class="scheduled-messages__body-text">{{ scheduledMessage.body }}</span>
                </td>
                <td v-if="isPendingTabSelected">
                    <div class="d-flex justify-content-center align-items-center">
                        <vx-dropdown placement="right">
                            <vx-button appearance="primary-dark-outline" size="large" right="tick">Actions</vx-button>
                            <template v-slot:items>
                                <vx-dropdown-item @click="openEditModal(scheduledMessage)">Edit</vx-dropdown-item>
                                <vx-dropdown-item @click="deleteScheduledMessage(scheduledMessage.id)">Delete</vx-dropdown-item>
                            </template>
                        </vx-dropdown>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import {VxButton, VxDropdown, VxDropdownItem, VxHeading } from '@voxie/frontend-components-v3';
import dayjs from '~/utils/dayjs';
import scheduledMessagesClient from '../../services/scheduledMessagesClient';
import LoadingResource from '../general/LoadingResource.vue';
import ScheduledMessageModal from './modals/ScheduledMessageModal.vue';
import ScheduledMessages from '../../utils/scheduledMessages';
import { getClientTz, formatTz } from '../../utils/date';

export default {
    name: "ScheduledMessagesTable",

    components: {
        LoadingResource,
        ScheduledMessageModal,
        VxButton,
        VxDropdown,
        VxDropdownItem,
        VxHeading,
    },

    props: {
        scheduledMessages: {
            type: Array
        },
        status: {
            type: String
        },
        loadingTable: {
            type: Boolean,
        }
    },

    data() {
        return {
            team: Spark.state.currentTeam.id,
            selectedScheduledMessage: null,
            showModal: false,
        }
    },

    computed: {
        isPendingTabSelected() {
            return this.status === 'pending';
        }
    },

    methods: {
        formattedDate(value) {
            const date = dayjs.utc(value).tz(getClientTz())
            const timezone = date.format('z');

            return date.format('MM/DD/YYYY hh:mm A') + ' ' + (formatTz(timezone));
        },

        deleteScheduledMessage(id) {
            if (!window.confirm('Are you sure you want to delete this scheduled message? There is no undo.')) {
                return;
            }

            scheduledMessagesClient.deleteScheduledMessage(this.team, id).catch(() => {
                this.$toasted.global.platform_error();
            });

            this.$emit('scheduledMessageDeleted');
        },

        async editScheduledMessage(data) {
            await ScheduledMessages.editScheduledMessage(data, this.$toasted);

            this.$emit('scheduledMessageEdited', data);
        },

        openEditModal(scheduledMessage) {
            this.selectedScheduledMessage = scheduledMessage;
            this.showModal = true;
        }
    },
}
</script>
