import {
    faBullhorn,
    faEnvelopesBulk,
    faGear,
    faGift,
    faHandshake,
    faHandsHoldingCircle,
    faHeadset,
    faIdCardClip,
    faInbox,
    faMessageQuote,
    faMessagesDollar,
    faPersonWalkingArrowLoopLeft,
    faShoppingCart,
} from '@fortawesome/pro-solid-svg-icons';

export const CATEGORIES = [
    {
        label: 'Acquisition',
        value: 'acquisition',
        icon: faHandshake,
    },
    {
        label: 'Inbound Text',
        value: 'inbound_text',
        icon: faInbox,
    },
    {
        label: 'Lead Engagement',
        value: 'lead_engagement',
        icon: faMessagesDollar,
    },
    {
        label: 'Loyalty',
        value: 'loyalty',
        icon: faGift,
    },
    {
        label: 'Marketing',
        value: 'marketing',
        icon: faBullhorn,
    },
    {
        label: 'Membership',
        value: 'membership',
        icon: faIdCardClip,
    },
    {
        label: 'Post Purchase',
        value: 'post_purchase',
        icon: faShoppingCart,
    },
    {
        label: 'Promotions',
        value: 'promotions',
        icon: faEnvelopesBulk,
    },
    {
        label: 'Retention',
        value: 'retention',
        icon: faPersonWalkingArrowLoopLeft,
    },
    {
        label: 'Reviews',
        value: 'reviews',
        icon: faMessageQuote,
    },
    {
        label: 'Support',
        value: 'support',
        icon: faHeadset,
    },
    {
        label: 'System Default',
        value: 'system_default',
        icon: faGear,
    },
    {
        label: 'Zero-Party Data',
        value: 'zero_party_data',
        icon: faHandsHoldingCircle,
    },
];
