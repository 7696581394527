<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        size="lg"
        :padding="false">
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6">
                Add Resources
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">
                Choose the resources you want to add to your flow.
            </div>
        </template>

        <div v-if="!confirming"
            class="vx-p-6 vx-flex vx-flex-col vx-gap-4 lg:vx-px-12 lg:vx-py-10 ">
            <resource-add-section resourceType="segment">
                <resource-selectable resourceType="segment"
                    v-model="resources.segment">
                </resource-selectable>
            </resource-add-section>

            <resource-add-section resourceType="campaign">
                <resource-selectable resourceType="campaign"
                    v-model="resources.campaign">
                </resource-selectable>
            </resource-add-section>

            <resource-add-section resourceType="automation">
                <resource-selectable resourceType="automation"
                    v-model="resources.automation">
                </resource-selectable>
            </resource-add-section>

        </div>

        <div v-else-if="confirming"
            class="vx-p-6 lg:vx-px-12 lg:vx-py-10">
            <!-- v-if visible triggers the mounted hook -->
            <custom-flow-resource-dependencies v-if="props.visible"
                ref="dependenciesRef"
                action="add"
                :resources="selectedResources"></custom-flow-resource-dependencies>
            <vx-alert v-if="error"
                color="danger"
                class="vx-mt-6"
                :closeable="false">{{ error }}</vx-alert>
        </div>

        <template v-slot:footer
            v-if="!confirming">
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="clearAndClose"
                    type="button"
                    class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                    size="lg"
                    color="muted">
                    Cancel
                </vx-button>
                <vx-button @click="checkDependencies()"
                    data-test="add-resources"
                    type="button"
                    class="vx-grow"
                    size="lg"
                    color="primary">
                    Add to Flow
                    <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
            </div>
        </template>
        <template v-slot:footer
            v-else>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="confirming = false"
                    type="button"
                    class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                    size="lg"
                    color="muted">
                    Back
                </vx-button>
                <vx-button @click="confirm()"
                    :disabled="dependenciesRef?.loading"
                    :loading="saving"
                    data-test="confirm-resource-add"
                    type="button"
                    class="vx-grow"
                    size="lg"
                    color="primary">
                    Confirm
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxButton, VxModal, VxAlert } from '@voxie/frontend-components';
import { computed, reactive, ref } from 'vue';
import { useToasted } from '~/composables/useToasted';
import { useCustomFlow } from '../useCustomFlow';
import ResourceAddSection from './ResourceAddSection.vue';
import ResourceSelectable from './ResourceSelectable.vue';
import CustomFlowResourceDependencies from '../CustomFlowResourceDependencies.vue';
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';

const toasted = useToasted();

const customFlow = useCustomFlow();

const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
    flowSection: {
        type: Number,
        required: true,
    },
});

const confirming = ref(false);
const saving = ref(false);

const error = ref('');

const emit = defineEmits(['update:visible']);

const dependenciesRef = ref();

const resources = reactive({
    automation: [],
    campaign: [],
    segment: [],
});

const clearAndClose = () => {
    confirming.value = false;
    saving.value = false;
    resources.automation = [];
    resources.campaign = [];
    resources.segment = [];

    emit('update:visible', false);
}

const selectedResources = computed(() => {
    const selectedResources = [];

    resources.automation.forEach(automation => {
        selectedResources.push({
            id: automation.id,
            type: 'automation',
        });
    });

    resources.campaign.forEach(campaign => {
        selectedResources.push({
            id: campaign.id,
            type: 'campaign',
        });
    });

    resources.segment.forEach(segment => {
        selectedResources.push({
            id: segment.id,
            type: 'segment',
        });
    });

    return selectedResources;
});

const confirm = async () => {
    if (!dependenciesRef.value) {
        return;
    }

    if (saving.value || dependenciesRef.value.loading) {
        return;
    }

    const existingCount = customFlow.sections.value.reduce((count, section) => count + section.resources.length, 0);
    const totalCount = existingCount + selectedResources.value.length + dependenciesRef.value.list.length;

    if (totalCount > 50) {
        error.value = `At most 50 resources can be placed in a flow. ${totalCount} resources present.`
        return;
    }

    try {
        saving.value = true;
        await customFlow.addResources(props.flowSection, [...selectedResources.value, ...dependenciesRef.value.list]);
        clearAndClose()
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
            return;
        }

        error.value = e.response?.data?.message;
    } finally {
        saving.value = false;
    }
}

const checkDependencies = async () => {
    if (!selectedResources.value.length) {
        toasted.show('You must select a resource to add to the Flow.');
        return;
    }
    confirming.value = true;
}

</script>
