<template>
    <page-controls>
        <template v-slot:left>
            <div class="vx-grow @3xl/controls:vx-max-w-md">
                <vx-filterable :active="active"
                    @toggle="toggleFilterable"
                    data-test="flows-filters">
                    <template v-slot:placeholder>
                        <span class="vx-mr-2">Find</span>
                        <vx-badge v-if="filtersCount > 0"
                            data-test="flows-reset"
                            :borders="false"
                            size="xs"
                            @click.stop="reset">
                            {{ filtersCount }} filters
                            <font-awesome-icon :icon="faXmarkCircle" />
                        </vx-badge>
                    </template>
                    <template v-slot:form>
                        <form @submit.prevent
                            class="vx-flex vx-flex-col vx-gap-4">
                            <div>
                                <vx-label>
                                    Type
                                </vx-label>
                                <div>
                                    <vx-checkbox-selection v-model="filters.type"
                                        class="vx-flex-row vx-flex vx-gap-1">
                                        <vx-checkbox-selection-item value="playbook"
                                            class="vx-grow">
                                            Playbook
                                        </vx-checkbox-selection-item>
                                        <vx-checkbox-selection-item value="custom_flow"
                                            class="vx-grow">
                                            Custom Flow
                                        </vx-checkbox-selection-item>
                                    </vx-checkbox-selection>
                                </div>
                            </div>

                            <div>
                                <vx-label>
                                    Keyword Search
                                </vx-label>
                                <vx-input type="text"
                                    data-test="flows-query"
                                    placeholder="Search"
                                    maxlength="80"
                                    v-model="filters.query">
                                    <template v-slot:right>
                                        <font-awesome-icon class="vx-text-xs"
                                            :icon="faMagnifyingGlass" />
                                    </template>
                                </vx-input>
                            </div>
                            <div>
                                <vx-label>
                                    Status
                                </vx-label>
                                <vx-selectable :options="[
                                    {
                                        label: 'Live',
                                        value: 'active',
                                    },
                                    {
                                        label: 'Draft',
                                        value: 'draft',
                                    },
                                    {
                                        label: 'Paused',
                                        value: 'paused',
                                    },
                                ]"
                                    :reduce="option => option.value"
                                    label="label"
                                    :clearable="true"
                                    placeholder="Status"
                                    v-model="filters.status">
                                </vx-selectable>
                            </div>
                            <div>
                                <vx-label>
                                    Base Playbook
                                </vx-label>
                                <vx-selectable :options="playbookGallery"
                                    :reduce="option => option.type"
                                    label="name"
                                    :clearable="true"
                                    placeholder="Base Playbook"
                                    v-model="filters.playbook">
                                </vx-selectable>
                            </div>

                            <category-filter-select
                                v-model="filters.category">
                            </category-filter-select>

                            <div class="vx-flex vx-gap-2 vx-pt-4">
                                <vx-button data-test="flows-cancel"
                                    type="button"
                                    color="muted-light"
                                    @click="cancel">
                                    Cancel
                                </vx-button>
                                <vx-button data-test="flows-apply"
                                    type="submit"
                                    block
                                    @click="apply"
                                >
                                    Apply
                                    <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                                </vx-button>
                            </div>
                        </form>
                    </template>
                </vx-filterable>
            </div>
            <div class="vx-flex vx-w-full vx-gap-2 @xl/controls:vx-w-auto">
                <vx-button-group size="lg"
                    class="vx-w-full">
                    <vx-select v-model="filters.sort_field"
                        @update:modelValue="apply"
                        :options="[
                            {
                                value: 'name',
                                label: 'Sort by: Name',
                            },
                            {
                                value: 'created_at',
                                label: 'Sort by: Created',
                            },
                            {
                                value: 'updated_at',
                                label: 'Sort by: Updated',
                            },
                        ]"></vx-select>
                    <vx-button @click.prevent="
                        filters.sort_dir = filters.sort_dir === 'asc' ? 'desc' : 'asc'
                        apply();
                    "
                        type="button"
                        color="white"
                        shadow>
                        <font-awesome-icon :icon="props.modelValue.sort_dir === 'asc'
                            ? faArrowUpWideShort
                            : faArrowDownWideShort
                            "></font-awesome-icon>
                    </vx-button>
                </vx-button-group>
            </div>
        </template>
        <template v-slot:right>
            <new-flow-button data-test="flows-new"
                block
                @click.prevent="emit('new')"
                size="lg"
                :disabled="!!(loading || list.length >= FLOWS_LIMIT)">
            </new-flow-button>
        </template>
    </page-controls>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue';
import {
    VxBadge,
    VxLabel,
    VxButton,
    VxButtonGroup,
    VxFilterable,
    VxInput,
    VxSelect,
    VxCheckboxSelection,
    VxCheckboxSelectionItem,
    VxSelectable,
} from "@voxie/frontend-components";
import {
    faCircleCheck,
    faMagnifyingGlass,
    faXmarkCircle,
    faArrowDownWideShort,
    faArrowUpWideShort,
} from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import PageControls from '~/components/general/PageControls.vue';
import NewFlowButton from './components/NewFlowButton.vue';

import { FLOWS_LIMIT, playbookGallery } from './playbook/playbook';
import CategoryFilterSelect from '~/components/general/categories/CategoryFilterSelect.vue';

const filters = ref({});
const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    list: {
        type: Array,
        default: () => ([]),
    },
    loading: {
        type: Boolean,
        default: false,
    }
});
watch(() => props.modelValue, (modelValue) => {
    filters.value = {...modelValue};
}, {
    deep: true,
    immediate: true,
})

const emit = defineEmits(["update:modelValue", "apply", "reset", "new"]);

const active = ref(false);

const toggleFilterable = (event) => {
    active.value = event;
};

const filtersCount = computed(
    () => (
        (props.modelValue.query ? 1 : 0) +
        (props.modelValue.status ? 1 : 0) +
        (props.modelValue.playbook ? 1 : 0) +
        (props.modelValue.type?.length ? 1 : 0) +
        (props.modelValue.category?.length ? 1 : 0)
    )
);

const reset = () => {
    active.value = false;
    emit("reset");
};

const cancel = async () => {
    filters.value = { ...props.modelValue }
    active.value = false;
}

const apply = async () => {
    active.value = false;
    emit('update:modelValue', { ...filters.value });
    await nextTick();
    emit('apply');
}
</script>
