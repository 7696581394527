import BaseClient from './BaseClient';

class WebhooksClient extends BaseClient {
    getWebhooks(teamId) {
        const url = `/api/v3/teams/${teamId}/webhooks`;
        return this.makeCoreApiRequest(url);
    }

    getWebhookEvents(teamId) {
        const url = `/api/v3/teams/${teamId}/webhooks/events`;
        return this.makeCoreApiRequest(url);
    }

    createWebhook(teamId, data) {
        const url = `/api/v3/teams/${teamId}/webhooks`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    updateWebhook(teamId, webhookId, data) {
        const url = `/api/v3/teams/${teamId}/webhooks/${webhookId}`;
        return this.makeCoreApiRequest(url, 'put', data);
    }

    deleteWebhook(teamId, webhookId) {
        const url = `/api/v3/teams/${teamId}/webhooks/${webhookId}`;
        return this.makeCoreApiRequest(url, 'delete');
    }
}

export default new WebhooksClient();
