<template>
    <div>
        <div class="c-table__title vx-flex vx-justify-between xl:vx-items-center vx-flex-col xl:vx-flex-row">
            <div>
                <h1>{{ title }}</h1>
            </div>
           <div class="vx-shrink-0 vx-flex vx-items-center vx-gap-2">
                <search-simple
                    v-if="searchFeature"
                    @change="searchForTheFirstTime"
                    :searching="loadingSearch"
                    :default-value="searchParam"
                    :collection-loading="loadingTable"
                    helpLabel="Search Help"
                    :hasHelp="searchHelp"
                >
                    <slot name="search-popover"></slot>
                </search-simple>
                <slot name="filters" v-bind:applyFilters="applyFilters"></slot>

                <slot :loadingTable="loadingTable" name="actions"></slot>

                <button
                    v-if="createFeature"
                    :disabled="loadingTable"
                    @click="createEntity"
                    type="button"
                    class="c-btn c-btn--success u-mr-small"
                >
                    <i class="fa fa-plus u-text-small u-opacity-medium u-mr-xsmall"></i>New {{ entityName }}
                </button>

           </div>
        </div>

        <table class="c-table resource-table">
            <thead v-if="!loadingTable" class="c-table__head c-table__head--slim">
                <tr class="c-table__row">
                    <th
                        v-if="selectAllRowsFeature"
                        class="c-table__cell c-table__cell--head center hidden-xs hidden-sm"
                    >
                        <input type="checkbox" @change="selectAllChange($event)" />
                    </th>

                    <template v-for="tableColumn in tableColumns" :key="tableColumn.name">
                        <sort-th
                            v-if="isSortEnable && tableColumn.sort"
                            class="c-table__cell c-table__cell--head center hidden-xs"
                            :sort-dir="tableColumn.sort"
                            @click="sortChanged(tableColumn)"
                        >
                            {{ tableColumn.name }}
                        </sort-th>

                        <th v-else v-bind:class="tableColumn.classNames" class="c-table__cell c-table__cell--head">
                            {{ tableColumn.name }}
                        </th>
                    </template>
                </tr>
            </thead>

            <tbody>
                <tr v-if="loadingTable">
                    <td class="c-table__cell center text-center" colspan="6">
                        <loading-resource />
                    </td>
                </tr>

                <tr v-else-if="loadingTableError">
                    <td class="c-table__cell center" colspan="6">
                        <alert-error />
                    </td>
                </tr>

                <tr v-else-if="!collection.length">
                    <td class="c-table__cell center" colspan="6">
                        <alert-no-results />
                    </td>
                </tr>

                <template v-else>
                    <slot name="table-row" v-for="(entity, index) in collection" :entity="entity" :index="index"></slot>
                </template>
            </tbody>
        </table>

        <cursor-pagination
            v-if="!loadingTable"
            v-model:cursor="cursor"
            :loading="loadingPagination"
            :firstPage="firstPageUrl"
            :nextPage="nextPageUrl"
            :prevPage="prevPageUrl"
            :lastPage="lastPageUrl"
            @change="changeCursor"
        ></cursor-pagination>
    </div>
</template>

<script>
import AlertNoResults from '../general/AlertNoResults.vue';
import AlertError from '../general/AlertError.vue';
import LoadingResource from '../general/LoadingResource.vue';
import SearchSimple from '../general/SearchSimple.vue';
import CursorPagination from '../general/CursorPagination.vue';
import SortTh from '../general/SortTh.vue';
import { camelCase } from '~/utils/string';
import clientRoutes from './../../mixins/client-routes';
import scrollTo from './../../mixins/scroll-to';
import { isLargeTeam } from '~/utils/team';

export default {
    mixins: [clientRoutes, scrollTo],
    components: {
        AlertNoResults,
        AlertError,
        LoadingResource,
        SearchSimple,
        CursorPagination,
        SortTh,
    },
    props: {
        selectAllRowsFeature: Boolean,
        createFeature: Boolean,
        searchFeature: Boolean,
        searchHelp: {
            type: Boolean,
            default: false,
        },
        searchIncludeParams: Array,

        pageUrl: {
            type: String,
        },
        searchUrl: String,

        simpleSearch: Boolean, // AKA Legacy ?
        isSortEnable: {
            type: Boolean,
            default: false,
        },

        tableTitle: { type: String, required: true },
        entityName: { type: String, required: true },
        tableColumns: { type: Array, required: true },

        entityFetchClient: { type: Function, required: true },
        entitySearchClient: { type: Function, required: false },
    },
    watch: {
        collection: {
            deep: true,
            handler(collection) {
                this.$emit('collection-changed', collection);
            },
        },
    },
    data() {
        return {
            teamId: Spark.state.currentTeam.id,

            title: '',
            loadingTable: true,
            loadingTableError: false,

            collection: [],

            cursor: null,
            firstPageUrl: null,
            prevPageUrl: null,
            nextPageUrl: null,
            lastPageUrl: null,
            loadingPagination: false,

            stash: null,

            showSearch: false,

            searchParam: '',
            filterParams: {},
            searching: false,
            firstTimeSearching: true,
            loadingSearch: false,
        };
    },
    mounted() {
        this.title = this.tableTitle;

        const route = this.currentClientRoute();

        if (this.simpleSearch && route && route.hash == 'search') {
            const decodedQuery = decodeURI(route.parameters[0]);

            this.searchParam = decodedQuery && decodedQuery !== 'null' ? decodedQuery : null;
            this.search(this.searchParam);
        }
    },
    created() {
        const eventEntity = camelCase(this.entityName);

        Bus.$on(`${eventEntity}Created`, (entity) => {
            this.collection.unshift(entity);
        });

        Bus.$on(`${eventEntity}Updated`, (entity) => {
            const index = this.collection.findIndex((row) => row.id === entity.id);
            this.collection[index] = entity;
        });

        Bus.$on(`${eventEntity}Deleted`, ({ id }) => {
            this.collection = this.collection.filter((row) => row.id !== id);
        });

        Bus.$on(`${eventEntity}UpdateResourceTable`, ({ cursor, searchParam }) => {
            this.cursor = cursor;

            if (searchParam) {
                this.search(searchParam);
            } else {
                this.loadRecords();
            }
        });
    },
    methods: {
        createEntity() {
            this.$emit('createEntity');
        },
        changeCursor() {
            if (this.searching) {
                this.search(this.searchParam);
            } else {
                this.loadRecords();
            }
        },
        loadRecords() {
            this.loadingTableError = false;
            this.loadingPagination = true;
            this.loadingTable = true;

            this.entityFetchClient(this.teamId, this.cursor, this.getSortDir())
                .then(({ data }) => {
                    if (data.data.length) {
                        this.collection = data.data;
                        this.firstPageUrl = data.first_page_url;
                        this.prevPageUrl = data.prev_page_url;
                        this.nextPageUrl = data.next_page_url;
                        this.lastPageUrl = data.last_page_url;

                        this.updateRouting();
                    }
                })
                .catch((error) => {
                    this.loadingTableError = true;
                    console.log(error);
                })
                .finally(() => {
                    this.loadingTable = false;
                    this.loadingPagination = false;
                    this.scrollToPageTop();
                });
        },
        searchForTheFirstTime(searchParam) {
            this.firstTimeSearching = true;
            this.search(searchParam);
        },
        search(searchParam) {
            this.searchParam = searchParam ? decodeURI(searchParam) : null;

            if (!this.searchParam && !Object.keys(this.filterParams).length) {
                this.unloadSearchData();

                return this.loadRecords();
            }

            this.searching = true;
            this.loadingPagination = true;
            this.loadingSearch = true;
            this.loadingTable = true;

            const params = {
                cursor: this.firstTimeSearching ? null : this.cursor,
                include: this.searchIncludeParams,
                ...this.filterParams,
            };

            if (this.isSortEnable && this.getSortDir() === 'desc') {
                params.sort_dir = 'desc';
            }

            if (this.simpleSearch) {
                params['query'] = this.searchParam;
            } else if (this.entityName.toLowerCase() === 'contact' && isLargeTeam()) {
                params['filter[phone]'] = this.searchParam;
            } else {
                params['filter[search]'] = this.searchParam;
            }

            this.entitySearchClient(this.teamId, params)
                .then(({ data }) => {
                    if (data.data.length) {
                        if (this.firstTimeSearching) {
                            this.stash = {};
                            this.stash.title = this.title;
                            this.stash.collection = this.collection;
                            this.stash.firstPageUrl = this.firstPageUrl;
                            this.stash.prevPageUrl = this.prevPageUrl;
                            this.stash.nextPageUrl = this.nextPageUrl;
                            this.stash.lastPageUrl = this.lastPageUrl;
                            this.stash.cursor = this.cursor;
                            this.cursor = null;
                        }
                        this.firstTimeSearching = false;

                        this.collection = data.data;
                        this.firstPageUrl = data.first_page_url;
                        this.prevPageUrl = data.prev_page_url;
                        this.nextPageUrl = data.next_page_url;
                        this.lastPageUrl = data.last_page_url;

                        this.updateRouting();
                    } else {
                        this.collection = [];
                    }
                })
                .catch((error) => {
                    this.$toasted.global.platform_error();
                    console.error(error);
                })
                .finally(() => {
                    this.loadingTable = false;
                    this.loadingPagination = false;
                    this.loadingSearch = false;

                    this.scrollToPageTop();
                });
        },
        unloadSearchData() {
            this.searching = false;
            this.searchParam = null;
            this.filterParams = {};

            if (this.stash) {
                this.title = this.stash.title;
                this.collection = this.stash.collection;
                this.firstPageUrl = this.stash.firstPageUrl;
                this.prevPageUrl = this.stash.prevPageUrl;
                this.nextPageUrl = this.stash.nextPageUrl;
                this.lastPageUrl = this.stash.lastPageUrl;
                this.cursor = this.stash.cursor;
                this.stash = null;
            }
        },
        updateRouting() {
            const cursor = this.cursor ? '/' + this.cursor : '';

            if (this.searchParam) {
                this.showClientRoute(`#/${this.searchUrl ? this.searchUrl : 'search'}/${this.searchParam}${cursor}`);
            } else {
                this.showClientRoute(`#/${this.pageUrl ? this.pageUrl : 'pages'}${cursor}`);
            }
        },
        selectAllChange(event) {
            this.collection = this.collection.map((entity) => ({
                ...entity,
                selected: event.target.checked,
            }));
        },
        applyFilters(filterParams) {
            this.filterParams = filterParams;
            this.search(this.searchParam);
        },
        sortChanged(tableColumn) {
            this.$emit('sortChanged', tableColumn);

            this.cursor = null;
            this.updateRouting();

            this.search(this.searchParam);
        },
        getSortDir() {
            return this.tableColumns.find((item) => item.sort)?.sort;
        },
    },
};
</script>
