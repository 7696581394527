<template>
    <div class="vx-text-center vx-text-slate-700 vx-font-sans vx-mb-8">
        <h3
            class="vx-font-extrabold vx-text-2xl vx-leading-7 vx-flex vx-justify-center vx-items-center vx-gap-3 vx-mb-3"
        >
            <font-awesome-icon
                :icon="props.icon"
                class="vx-text-base"
            ></font-awesome-icon>
            <span>
                <slot></slot>
            </span>
        </h3>
        <p class="vx-text-base vx-leading-6 vx-font-normal">
            <slot name="description"></slot>
        </p>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const props = defineProps({
    icon: {
        type: Object,
        required: true,
    },
});
</script>
