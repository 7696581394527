<template>
    <vx-modal
        :padding="false"
        :stacked="stacked"
        :preventClosing="preventClosing"
        v-model:visible="data.showModal"
        @close="resetDefaults();"
        size="md"
    >
        <template v-slot:header>
            <div class="vx-px-6 vx-pt-6">
                New Conversation
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">
                Seamlessly send texts to both new and existing contacts.
            </div>
        </template>

        <div class="vx-p-6">
            <div>
                <vx-label>Phone Number</vx-label>
                <vx-input ref="phoneNumberRef" autofocus v-model="data.phoneNumberInput" @paste="onPaste" placeholder="Press enter to add" @keydown.enter="onEnterInput">
                    <template v-slot:hint>
                        Paste or press enter to add up to {{ PHONE_NUMBERS_LIMIT }} phone numbers separated by comma.
                    </template>
                </vx-input>
            </div>

            <contact-search v-if="data.phoneNumberInput" @selected="onSelected" :canCreate="true" @create="onCreateContact" :search="data.phoneNumberInput"></contact-search>

            <div v-if="data.contacts.length" class="vx-mt-2 vx-mb-4">
                <vx-popover hover arrow placement="top" v-for="contact in data.contacts" :key="contact.phoneNumber">
                    <vx-badge :color="contact.processing ? 'slate' : (contact.invalid ? 'rose' : 'primary')"
                        size="sm"
                        :borders="false"
                        class="vx-mr-2 vx-mb-1 vx-flex vx-items-center vx-gap-2"
                    >
                        <span>
                            {{contact.name || phoneNumberNational(contact.phoneNumber)}}
                            <i class="fa fa-spin fa-spinner" v-if="contact.processing"></i>
                        </span>
                        <font-awesome-icon class="vx-cursor-pointer" @click="removeContact(contact.phoneNumber)" :icon="faTimes"></font-awesome-icon>
                    </vx-badge>
                    <template v-slot:content v-if="contact.invalid || contact.phoneNumber">
                        {{contact.invalid || phoneNumberNational(contact.phoneNumber)}}
                    </template>
                </vx-popover>
            </div>

            <vx-label class="vx-mt-4">Message</vx-label>
            <message-builder
                ref="messageBuilderRef"
                v-model="data.body"
                v-model:mediaUrl="data.media_url"
                v-model:mediaContentType="data.media_content_type"
                @selected:snippet="data.selectedSnippetBody = $event.body"
                @submit="sendMessage()"
                @schedule="data.sendAt = $event; sendMessage({ isScheduling: true })"
                preview
            ></message-builder>

            <vx-alert data-test="sending-errors" class="vx-mt-4" color="danger" :visible="!!data.sendingErrors.length" @update:visible="data.sendingErrors = []">
                <div v-for="error in data.sendingErrors" class="vx-pb-1" :key="error">
                    {{ error }}
                </div>
            </vx-alert>

        </div>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 vx-justify-between lg:vx-flex-row">
                <vx-button
                    color="muted"
                    size="lg"
                    @click="cancel"
                >
                    Cancel
                </vx-button>

                <div class="vx-grow vx-flex vx-justify-end vx-gap-2">
                    <vx-button :loading="data.sending" @click.prevent="openScheduleModal();" color="secondary" size="lg" type="button">Schedule <font-awesome-icon :icon="faCalendar"></font-awesome-icon></vx-button>
                    <vx-button :loading="data.sending" @click.prevent="sendMessage();" color="primary" size="lg" type="button">Send <font-awesome-icon :icon="faPaperPlane"></font-awesome-icon></vx-button>
                </div>

            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import libphonenumber from 'google-libphonenumber';
import { displayNotActiveModal, isNotActive } from '../../../../../utils/team'
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import ContactSearch from '../../../../general/ContactSearch.vue';

import { VxButton, VxInput, VxModal, VxLabel, VxAlert, VxBadge, VxPopover } from '@voxie/frontend-components';

import {
    actionsClient,
    contactsClient,
    messagesClient,
    scheduledMessagesClient,
} from '../../../../../services';
import dayjs from '~/utils/dayjs';
import {
    roundToNearest5,
    getClientTz
} from "../../../../../utils/date";
import { uniqBy } from '~/utils/helpers';
import { phoneNumberNational } from '~/components/filters';
import { faCalendar, faPaperPlane, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useToasted } from '../../../../../composables/useToasted';
import debounce from '~/utils/debounce';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const PNF = libphonenumber.PhoneNumberFormat;

const PHONE_NUMBERS_LIMIT = 20;

const toasted = useToasted();

const messageBuilderRef = ref();

const stacked = computed(() => {
    if (!messageBuilderRef.value) {
        return false;
    }
    return messageBuilderRef.value.modalOpen;
});

const preventClosing = ref(false);

watch([
        () => messageBuilderRef.value?.emojisPicker,
        () => messageBuilderRef.value?.actionsDropdown
    ],
    debounce(() => {
        preventClosing.value = messageBuilderRef.value?.emojisPicker || messageBuilderRef.value?.actionsDropdown;
    }, 150)
)

const phoneNumberRef = ref();

const data = reactive({
    body: '',
    showModal: false,
    media_url: null,
    media_content_type: null,
    selectedSnippetBody: '',
    sending: false,
    isScheduledMessage: false,
    sendAt: roundToNearest5(dayjs().tz(getClientTz()).add(1, 'h').utc()),
    teamId: Spark.state.currentTeam.id,
    contacts: [],
    phoneNumberInput: '',
    sendingErrors: [],
});

onMounted(() => {
    Bus.$on('showNewConversationUi', () => {
        data.showModal = true;
    })

    Bus.$on('newTemplateModalSnippetCreated', onSnippetCreated);
})

watch(() => data.contacts.length, () => {
    data.sendingErrors = [];
})

const openScheduleModal = () => {
    messageBuilderRef.value.scheduleModal = true;
}

/**
 *
 * @param {ClipboardEvent} event
 */
const onPaste = (event) => {
    event.preventDefault();

    const text = event.clipboardData.getData('text');

    let newContacts = mapTextIntoContacts(text);

    if (data.contacts.length + newContacts.length > PHONE_NUMBERS_LIMIT) {
        contactsLimitReachedAlert();
        const contactsToSlice = newContacts.length - (newContacts.length - PHONE_NUMBERS_LIMIT) - data.contacts.length;
        newContacts = newContacts.slice(0, contactsToSlice);
    }

    data.contacts = [...data.contacts, ...newContacts];
    processContacts(newContacts);
}

const onEnterInput = (event) => {
    event.preventDefault();

    let newContacts = mapTextIntoContacts(data.phoneNumberInput);
    if (data.contacts.length + newContacts.length > PHONE_NUMBERS_LIMIT) {
        contactsLimitReachedAlert();
        const contactsToSlice = newContacts.length - (newContacts.length - PHONE_NUMBERS_LIMIT) - data.contacts.length;
        newContacts = newContacts.slice(0, contactsToSlice);
    }

    data.contacts = [...data.contacts, ...newContacts];
    processContacts(newContacts)
    data.phoneNumberInput = '';
};

const onSelected = (contact) => {
    if (data.contacts.length + 1 > PHONE_NUMBERS_LIMIT) {
        contactsLimitReachedAlert();
    } else if (!data.contacts.find(c => c.phoneNumber === contact.phone)) {
        data.contacts.push({
            contactId: contact.id,
            phoneNumber: contact.phone,
            name: ((contact.first_name || '') + ' ' + (contact.last_name || '')).trim() || null,
            invalid: null,
            processing: false,
        });
    }

    data.phoneNumberInput = '';

    focusPhoneNumberInput();
};


const onCreateContact = (phoneNumber) => {
    if (data.contacts.length >= PHONE_NUMBERS_LIMIT) {
        contactsLimitReachedAlert();
        return;
    }

    data.contacts = [
        ...data.contacts,
        ...mapTextIntoContacts(phoneNumber)
    ]

    processContacts(data.contacts)
    data.phoneNumberInput = '';
    focusPhoneNumberInput();
};

const focusPhoneNumberInput = () => {
    phoneNumberRef.value.field.focus();
};

const contactsLimitReachedAlert = () => {
    toasted.show(`You cannot add more than ${PHONE_NUMBERS_LIMIT} phone numbers.`)
};

const mapTextIntoContacts = (text) => {
    return uniqBy(text.split(/\r?\n|\r|\n|,/g)
        .map((phoneNumber) => {
            phoneNumber = e164Format(phoneNumber);

            if(!phoneNumber?.trim()?.length) {
                return null;
            }

            const invalid = !isValidPhoneNumber(phoneNumber) ? `${phoneNumber} is not a valid phone number.` : null;

            if (!invalid && data.contacts.find(contact => contact.phoneNumber === phoneNumber)) {
                return null;
            }
            return {
                contactId: null,
                phoneNumber: phoneNumber,
                name: null,
                invalid: invalid,
                processing: !invalid, // its only processing when not invalid
            };
        })
        .filter(phoneNumber => phoneNumber !== null),
        'phoneNumber'
    )
}

const processContacts = (contacts) => {
    contacts.forEach((contact) => {
        if(!contact.processing) {
            return;
        }

        lookupContact(contact.phoneNumber)
            .then((lookupContact) => {
                const index = data.contacts.findIndex(existingContact => existingContact.phoneNumber === contact.phoneNumber)

                if (index === -1) {
                    return;
                }

                data.contacts[index].contactId = lookupContact.id;

                const firstName = lookupContact.first_name || '';
                const lastName = lookupContact.last_name || '';

                data.contacts[index].name = (lastName ? `${firstName} ${lastName}` : firstName).trim();
                data.contacts[index].processing = false;
            }).catch((error) => {
                const index = data.contacts.findIndex(existingContact => existingContact.phoneNumber === contact.phoneNumber)

                if (index === -1) {
                    return;
                }

                data.contacts[index].contactId = null;
                data.contacts[index].name = null;
                data.contacts[index].invalid = error?.message || `It was not possible to process the phone number ${contact.phoneNumber}.`;
                data.contacts[index].processing = false;
            })
    })
}

const removeContact = (phoneNumber) => {
    data.contacts = data.contacts.filter(contact => contact.phoneNumber !== phoneNumber)
}

const onSnippetCreated = (event) => {
    if (event.media_url) {
        data.media_url = event.media_url
        data.media_content_type = event.media_content_type
    }

    data.body = event.body;
    data.selectedSnippetBody = event.body;
}

const isValidPhoneNumber = (phone) => {
    try {
        return Boolean(phoneUtil.isValidNumber(
            phoneUtil.parse(phone, 'US'),
        ));
    } catch (err) {
        console.error(err)
        return false;
    }
}
const e164Format = (phone) => {
    try {
        return phoneUtil.format(
            phoneUtil.parse(phone, 'US'),
            PNF.E164,
        );
    } catch {
        return null
    }
}

const validate = () => {
    if (data.contacts.length > PHONE_NUMBERS_LIMIT) {
        data.sendingErrors.push(`You cannot send a message to more than ${PHONE_NUMBERS_LIMIT} phone numbers.`)
    } else {
        data.contacts.forEach((contact) => {
            if (contact.processing || contact.invalid) {
                data.sendingErrors.push(contact.processing ? `Please wait the phone number ${contact.phoneNumber} is still being processed.` : contact.invalid)
            }
        })
    }

    if (!data.body || data.body.trim() === '') {
        data.sendingErrors.push('Message body is required.');
    }

    if (data.isScheduledMessage) {
        if (!data.sendAt) {
            data.sendingErrors.push('Send At is required.');
        }

        if (data.sendAt !== null && dayjs().tz(getClientTz()).utc().isSameOrAfter(dayjs.utc(data.sendAt).tz(getClientTz()))) {
            data.sendingErrors.push('The send at field cannot be set in the past.');
        }
    }
    if (data.sendingErrors.length === 0) return true;

    return false;
}

const lookupContact = async (phoneNumber) => {
    try {
        return await fetchContact(phoneNumber)
    } catch (e) {
        return await createContact(phoneNumber)
    }
}

const fetchContact = async (phoneNumber) => {
    try {
        const response = await contactsClient.searchContacts(data.teamId, {
            'filter[phone]': phoneNumber
        })

        if (response.data?.data?.length === 1 && response.data.data[0].phone === phoneNumber) {
            return response.data.data[0]
        }
    } catch(e) {
        console.error(e)
    }

    throw new Error(`It was not possible to fetch a contact with the phone number ${phoneNumber}.`);
}

const createContact = async (phoneNumber) => {
    try {
        const response = await contactsClient.contactSave(data.teamId, { phone: phoneNumber })
        actionsClient.send('contact_added');
        return response.data;
    } catch (e) {
        throw new Error(`It was not possible to create a new contact with the phone number ${phoneNumber}.`)
    }
}

const deliverMessage = (contactId, phoneNumber) => {
    const payload = {
        contact_id: contactId,
        body: data.body,
    };

    if (data.isScheduledMessage) {
        payload.send_at = data.sendAt;
    }

    if (data.media_url) {
        payload.media_url = data.media_url;
        payload.media_content_type = data.media_content_type;
    }

    return (data.isScheduledMessage ? scheduledMessagesClient.createScheduledMessage(data.teamId, contactId, payload) : messagesClient.createV3(data.teamId, payload))
        .then((response) => {
            if (response.data.error_message) {
                data.sendingErrors.push(`It was not possible to send the message to ${phoneNumber}`)
                data.sendingErrors.push(`response.data.error_message (code: ${response.data.error_code})`)
            }
        })
        .catch((error) => {
            data.sendingErrors.push(`It was not possible to send the message to ${phoneNumber}`)
            if (error?.response?.status === 422 && error.response.data?.errors) {
                Object.keys(error.response.data.errors).forEach((key) => {
                    error.response.data.errors[key].forEach(error => {
                        data.sendingErrors.push(`${error} `);
                    });
                });
                data.sendingErrors.push('')
                data.sendingErrors.push('')
            } else {
                console.error(error);
            }
        });
}

const sendMessage = (event) => {
    data.sendingErrors = [];

    data.isScheduledMessage = event ? event.isScheduling : false;

    if (isNotActive()) {
        displayNotActiveModal()
        return
    }

    if (data.sending) {
        return
    }

    if (!validate()) return;

    data.sending = true;
    const deliveringPromises = [];

    data.contacts.forEach(contact => {
        deliveringPromises.push(deliverMessage(contact.contactId, contact.phoneNumber));
    })

    Promise.all(deliveringPromises).then(() => {
        if (!data.sendingErrors.length) {
            data.showModal = false;
            resetDefaults();
        }

        actionsClient.send('message_sent');

        if (data.selectedSnippetBody && data.body.includes(data.selectedSnippetBody)) {
            actionsClient.send('snippet_used');
        }
    }).finally(() => {
        data.sending = false;
    })
}

const resetDefaults = () => {
    data.body = '';
    data.media_url = null;
    data.media_content_type = null;
    data.selectedSnippetBody = '';
    data.isScheduledMessage = false;
    data.phoneNumberInput = '';
    data.contacts = [];
    data.sendingErrors = [];
}

const cancel = () => {
    data.showModal = false;
    resetDefaults();
}

defineExpose({
    validate,
})

</script>
