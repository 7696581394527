<template>
  <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8 2xl:vx-max-w-screen-xl vx-space-y-8">
    <div class="vx-flex vx-flex-col vx-gap-4">
      <campaign-list-header :count="totalCount"></campaign-list-header>
      <campaign-controls v-if="totalCount > 0 || !isEqual(defaultFilters(), fetchParams)" v-model="fetchParams"
        @reset="resetFilters" @apply="applyFilters"></campaign-controls>
      <template v-if="!loading">
        <vx-box color="info" v-if="totalCount === 0 && !isEqual(defaultFilters(), fetchParams)"
          class="vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
          <div class="vx-w-64 vx-mx-auto vx-text-center">
            No results for current search, try adjusting your filters.
          </div>
          <vx-button type="button" color="secondary" @click="resetFilters">
            Reset All <font-awesome-icon :icon="faCircleX" />
          </vx-button>
        </vx-box>
        <vx-box color="info" v-else-if="totalCount === 0"
          class="vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
          No campaigns yet.
          <vx-button href="/messaging/campaigns/new">
            New Campaign <font-awesome-icon :icon="faPlusCircle" />
          </vx-button>
        </vx-box>
      </template>
    </div>

    <div v-if="list.length" class="vx-grid vx-grid-cols-1 sm:vx-grid-cols-2 vx-gap-4 lg:vx-grid-cols-1">
      <campaign-card v-for="(campaign, index) in list" :key="campaign.id" v-model="list[index]"
        @deleted="removeFromList($event)"
        @launch="modalCampaignIndex = index;">
      </campaign-card>
    </div>

    <div v-if="list.length" class="vx-mt-8 vx-flex vx-justify-between vx-items-center vx-w-full vx-gap-10">
      <vx-button v-if="fetchParams.cursor" @click="loadData()" color="muted-dark" size="xl" data-test="load-more-btn"
        class="vx-grow sm:vx-grow-0">
        Load More
        <font-awesome-icon :icon="faSpinner" :spin="loading"></font-awesome-icon>
      </vx-button>

      <div class="vx-text-lg vx-grow-0 vx-ml-auto vx-font-normal vx-text-slate-600">
        {{ list.length }}/{{ totalCount }}
      </div>
    </div>

    <campaign-launch-modal :visible="!!list[modalCampaignIndex]" @update:visible="modalCampaignIndex = undefined" v-model:campaign="list[modalCampaignIndex]" />
  </div>
</template>

<script setup>
import CampaignListHeader from './CampaignListHeader.vue';
import CampaignControls from './CampaignControls.vue';
import { faCircleX, faPlusCircle, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBox, VxButton } from '@voxie/frontend-components';
import { ref, onMounted, inject } from 'vue';
import CampaignCard from '../campaign-card/CampaignCard.vue';
import { campaignsClient } from '../../../services';
import CampaignLaunchModal from '../CampaignLaunchModal.vue';
import { useToasted } from '../../../composables/useToasted';
import { isEqual } from '~/utils/helpers';
import { useCursorPagination } from '../../../composables/useCursorPagination';

const FEATURES = inject('FEATURES');

const teamId = Spark.state.currentTeam.id;

const toasted = useToasted();

const totalCount = ref(0);
const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const modalCampaignIndex = ref();

let defaultFilters = () => {
  const defaultFilters = {
    query: "",
    per_page: 10,
    cursor: null,
    ui_durations: [],
    ui_statuses: [],
    start_date: undefined,
    end_date: undefined,
    time_zone_aware: undefined,
    category: undefined,
    sort_field: 'id',
    sort_dir: 'desc'
  }

  if (FEATURES.flows) {
    defaultFilters.flow = {
        in_flow: undefined,
        flow_id: null,
    };
  }

  return defaultFilters;
};

const resetFilters = () => {
  fetchParams.value = defaultFilters();

    loadData();
};

const applyFilters = () => {
  fetchParams.value = {
    ...fetchParams.value,
    cursor: undefined,
  }

    loadData();
}

onMounted(() => {
  resetFilters();
});

const fetchTotalCount = async () => {
  try {
    const response = await campaignsClient.campaignsCount(teamId, { ...fetchParams.value });
    totalCount.value = response.data?.count || 0;
  } catch (e) {
    console.error(e);
    toasted.global.platform_error();
  }
}

const removeFromList = (campaignId) => {
  list.value = list.value.filter((item) => item.id != campaignId);
  totalCount.value = totalCount.value - 1;

  if (!list.value.length) {
      loadData();
  }
};

const loadData = () => {
  fetchRecords(campaignsClient.campaignsGetRecords(teamId, {...fetchParams.value}));
  fetchTotalCount();
};
</script>
