<template>
    <vx-radio-selection :modelValue="props.modelValue"
        @update:modelValue="updateType($event)"
        :disabled="props.disabled">
        <vx-radio-selection-item :value="AUDIENCE_TYPE_MARKETING"
            data-test="audience-type-marketing">
            <template v-slot:header>
                Marketing
            </template>
            <template v-slot:content>
                <ul class="vx-list-disc vx-list-outside vx-ml-10 lg:vx-ml-4" v-html="audienceTypeDescription(AUDIENCE_TYPE_MARKETING)"></ul>
              </template>
            </vx-radio-selection-item>
            <vx-radio-selection-item :value="AUDIENCE_TYPE_TRANSACTIONAL"
            data-test="audience-type-transactional">
            <template v-slot:header>
              Transactional
            </template>
            <template v-slot:content>
              <ul class="vx-list-disc vx-list-outside vx-ml-10 lg:vx-ml-4" v-html="audienceTypeDescription(AUDIENCE_TYPE_TRANSACTIONAL)"></ul>
            </template>
          </vx-radio-selection-item>
          <vx-radio-selection-item :value="AUDIENCE_TYPE_ACQUISITION"
          data-test="audience-type-acquisition">
          <template v-slot:header>
            Acquisition
          </template>
          <template v-slot:content>
            <ul class="vx-list-disc vx-list-outside vx-ml-10 lg:vx-ml-4" v-html="audienceTypeDescription(AUDIENCE_TYPE_ACQUISITION)"></ul>
          </template>
        </vx-radio-selection-item>
        <vx-radio-selection-item :value="AUDIENCE_TYPE_RE_ACQUISITION"
        data-test="audience-type-re-acquisition">
          <template v-slot:header>
            Re-acquisition
          </template>
          <template v-slot:content>
            <ul class="vx-list-disc vx-list-outside vx-ml-10 lg:vx-ml-4" v-html="audienceTypeDescription(AUDIENCE_TYPE_RE_ACQUISITION)"></ul>
          </template>
        </vx-radio-selection-item>
    </vx-radio-selection>
</template>

<script setup>
import { inject } from 'vue';
import {
    VxRadioSelection,
    VxRadioSelectionItem,
} from "@voxie/frontend-components";
import { audienceTypeDescription, AUDIENCE_TYPE_MARKETING, AUDIENCE_TYPE_TRANSACTIONAL, AUDIENCE_TYPE_ACQUISITION, AUDIENCE_TYPE_RE_ACQUISITION } from '../../../constants/campaign';

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
    },
});

const FEATURES = inject('FEATURES')

const emit = defineEmits(["update:modelValue"]);

const updateType = (type) => {
    emit("update:modelValue", type);
};

if (!props.modelValue) {
    emit('update:modelValue', FEATURES.transactional_default ? AUDIENCE_TYPE_TRANSACTIONAL : AUDIENCE_TYPE_MARKETING)
}

</script>
