<template>
    <modal
        v-model:visible="visibleForModal"
        id="modal-activate-confirm"
        title="Warning!"
        submit-text="Activate"
        close-text="Cancel"
        @submit="confirm"
    >
        <p class="modal-activate-confirm__text" :class="{ 'u-text-center': !suspended }">
            Are you sure you want to proceed?
            <template v-if="suspended">
                By Activating this Account, the following will happen:
            </template>
        </p>

        <ul class="modal-activate-confirm__list" v-if="suspended">
            <li>
                All current Owners and Members will recover their respective roles privileges.
            </li>
            <li>
                All automated messaging capabilities will be resumed.
            </li>
            <li>
                Notice: Messages scheduled to be sent during the suspended period are already discarded
            </li>
        </ul>
    </modal>
</template>
<script>
import Modal from '../../general/Modal.vue';

export default {
    components: {
        Modal
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        suspended: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            visibleForModal: false
        }
    },
    watch: {
        visible: function(value) {
            this.visibleForModal = value
        },
        visibleForModal: function(value) {
            this.$emit('update:visible', value);
        },
    },
    methods: {
        confirm() {
            this.$emit('update:visible', false);
            this.$emit('submitActivate');
        },
    }
}
</script>

<style lang="postcss" scoped>
.modal-activate-confirm__text {
    margin-bottom: 1rem;
}

.modal-activate-confirm__list {
    list-style-type: disc;
    padding-inline-start: 1rem;
    font-size: 0.875rem;
}

.modal-activate-confirm__list>li {
    margin-bottom: 1rem;
}
</style>
