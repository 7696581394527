<template>
    <div>
        <div class="card card-default">
            <div class="card-header">Current Teams</div>

            <div class="table-responsive">
                <table class="table table-valign-middle mb-0">
                    <thead>
                    <tr>
                        <th class="th-fit"></th>
                        <th>Name</th>
                        <th>Owner</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr  v-for="(team,index) in teams" :key="index">
                        <!-- Photo -->
                        <td>
                            <img :src="team.photo_url" class="spark-profile-photo" alt="Team Photo" />
                        </td>

                        <!-- Team Name -->
                        <td>
                            <div>
                                <a :href="`/settings/teams/${team.id}/switch`">
                                    {{ team.name }}
                                </a>
                            </div>
                        </td>

                        <!-- Owner Name -->
                        <td>
                            <div>
                                <span v-if="user.id === team.owner.id">
                                    You
                                </span>

                                <span v-else>
                                    {{ team.owner.name }}
                                </span>
                            </div>
                        </td>

                        <!-- Edit Button -->
                        <td class="td-fit">
                            <span v-if="currentTeamId === team.id">
                                <a :href="`/settings/teams/${team.id}`">
                                    <button class="btn btn-outline-primary">
                                        <i class="fa fa-cog"></i>
                                    </button>
                                </a>
                            </span>

                            <span v-if="user.id !== team.owner.id && !user.isUsingSingleSignOn">
                                <button class="btn btn-outline-warning" @click="approveLeavingTeam(team)" data-toggle="tooltip" title=" Leave Team">
                                    <i class="fa fa-sign-out"></i>
                                </button>
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Leave Team Modal -->
        <div class="modal" id="modal-leave-team" tabindex="-1" role="dialog">
            <div class="modal-dialog" v-if="leavingTeam">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Leave Team ({{ leavingTeam.name }})
                        </h5>
                    </div>

                    <div class="modal-body">
                        Are you sure you want to leave this team?
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">No, Go Back</button>

                        <button type="button" class="btn btn-warning" @click="leaveTeam" :disabled="leaveTeamForm.busy">
                            Yes, Leave
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete Team Modal -->
        <div class="modal" id="modal-delete-team" tabindex="-1" role="dialog">
            <div class="modal-dialog" v-if="deletingTeam">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Delete Team
                        </h5>
                    </div>

                    <div class="modal-body">
                        Are you sure you want to delete this team?
                        If you choose to delete the team all data will be permanently deleted.
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">No, Go Back</button>

                        <button type="button" class="btn btn-danger" @click="deleteTeam" :disabled="deleteTeamForm.busy">
                            <span v-if="deleteTeamForm.busy">
                                <i class="fa fa-btn fa-spinner fa-spin"></i> Deleting
                            </span>

                            <span v-else>
                                Yes, Delete
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CurrentTeams',

    props: ['user', 'teams'],

    /**
     * The component's data.
     */
    data() {
        return {
            leavingTeam: null,
            deletingTeam: null,

            leaveTeamForm: new SparkForm({}),
            deleteTeamForm: new SparkForm({})
        };
    },


    /**
     * Prepare the component.
     */
    mounted() {
        $('[data-toggle="tooltip"]').tooltip();

        Bus.$on(`sparkFormUpdated.${this.leaveTeamForm.sparkFormId}`, this.$forceUpdate)
        Bus.$on(`sparkFormUpdated.${this.deleteTeamForm.sparkFormId}`, this.$forceUpdate)
    },


    computed: {
        /**
         * Get the URL for leaving a team.
         */
        urlForLeaving() {
            return `/settings/teams/${this.leavingTeam.id}/members/${this.user.id}`;
        },

        currentTeamId() {
            return Spark.state.currentTeam.id;
        },
    },


    methods: {
        /**
         * Approve leaving the given team.
         */
        approveLeavingTeam(team) {
            this.leavingTeam = team;

            $('#modal-leave-team').modal('show');
        },


        /**
         * Leave the given team.
         */
        leaveTeam() {
            Spark.delete(this.urlForLeaving, this.leaveTeamForm)
                .then(() => {
                    Bus.$emit('updateUser');
                    Bus.$emit('updateTeams');

                    $('#modal-leave-team').modal('hide');
                });
        },


        /**
         * Approve the deletion of the given team.
         */
        approveTeamDelete(team) {
            this.deletingTeam = team;

            $('#modal-delete-team').modal('show');
        },


        /**
         * Delete the given team.
         */
        deleteTeam() {
            Spark.delete(`/settings/teams/${this.deletingTeam.id}`, this.deleteTeamForm)
                .then(() => {
                    Bus.$emit('updateUser');
                    Bus.$emit('updateTeams');

                    $('#modal-delete-team').modal('hide');
                });
        }
    }
}
</script>
