<template>
    <vx-expandable-card
        toggleable
        id="what"
        :active="active"
    >
        <template v-slot:header>
            <div class="vx-flex vx-items-center">
                <span
                    class="vx-pr-1.5 vx-mr-2 vx-border-y-0 vx-border-l-0 vx-border-r vx-border-solid sm:vx-pr-3 sm:vx-mr-4"
                    :class="{
                        'vx-border-r-transparent': !showStats,
                        'vx-border-r-slate-400': showStats,
                    }"
                >
                    What
                </span>
                <div v-if="showStats" class="vx-flex vx-gap-4">
                    <div
                        v-for="data in metadata"
                        :key="data.label"
                        class="vx-flex vx-flex-col"
                    >
                        <span
                            class="vx-font-bold vx-text-xs vx-text-slate-700 vx-block sm:vx-text-base sm:vx-font-semibold"
                            >{{ data.value }}</span
                        >
                        <span
                            class="vx-text-xs vx-font-normal vx-text-slate-500 vx-block"
                        >
                            <span class="vx-hidden sm:vx-inline">{{ data.label }}</span>
                            <span class="vx-inline sm:vx-hidden">{{ data.shortLabel }}</span>
                        </span>
                    </div>

                </div>
            </div>
        </template>
        <template v-if="editable && !props.success" v-slot:headerRight>
            <vx-button
                @click.stop="emit('update:active', true)"
                color="muted"
                size="md"
            >
                <span class="vx-inline-block">Edit</span>
                <font-awesome-icon class="vx-text-base vx-hidden sm:vx-inline-block" :icon="faPencil"></font-awesome-icon>
            </vx-button>
        </template>
        <template v-slot:content>
            <strong
                class="vx-text-slate-900 vx-text-sm vx-font-semibold vx-mb-2 vx-block sm:vx-text-base"
            >
                Message
            </strong>
            <message-builder
                ref="messageBuilder"
                v-model="data.body"
                v-model:mediaUrl="data.mediaUrl"
                v-model:mediaContentType="data.mediaContentType"
                @selected:snippet="data.selectedSnippetBody = $event.body"
                preview
            >
                <template v-slot:actions v-if="FEATURES.quick_blast_ai">
                    <vx-dropdown-item @click.prevent="aiModal = true">
                        <span class="vx-flex vx-items-center vx-gap-1">
                            <font-awesome-icon :icon="faWandMagicSparkles"
                                class="vx-text-sm"></font-awesome-icon>
                            <span>AI Message</span>
                            <experimental-pill class="vx-pointer-events-none" placement="right"></experimental-pill>
                        </span>
                    </vx-dropdown-item>
                </template>
            </message-builder>


            <message-builder-quick-blast-generation v-if="FEATURES.quick_blast_ai" v-model:visible="aiModal" :campaign-type="props.campaignType" @selected="data.body = $event"></message-builder-quick-blast-generation>

            <vx-button
                @click="confirm()"
                data-test="what-continue"
                class="vx-mt-4 lg:vx-mt-6"
                size="lg"
                :block="true"
            >
                Continue
                <font-awesome-icon
                    class="vx-text-base"
                    :icon="faArrowTurnDown"
                ></font-awesome-icon>
            </vx-button>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import {
    VxExpandableCard,
    VxButton,
    VxDropdownItem
} from "@voxie/frontend-components";
import { computed, reactive, watch, inject, ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPencil, faArrowTurnDown } from '@fortawesome/pro-solid-svg-icons';
import { faWandMagicSparkles } from '@fortawesome/pro-regular-svg-icons';
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import MessageBuilderQuickBlastGeneration from '~/components/general/message-builder/modals/MessageBuilderQuickBlastGeneration.vue';
import { AUDIENCE_TYPE_MARKETING, AUDIENCE_TYPE_TRANSACTIONAL } from '../../constants/campaign';
import ExperimentalPill from "~/components/general/ExperimentalPill.vue";

import {
    UNICODE_TO_GSM,
    GSM_MAX_SINGLE_SEGMENT_SIZE,
    GSM_MAX_CONCAT_SEGMENT_SIZE,
    UCS2_MAX_SINGLE_SEGMENT_SIZE,
    UCS2_MAX_CONCAT_SEGMENT_SIZE,
} from "../../support/GSM";
import {useToasted} from '../../composables/useToasted';

const toasted = useToasted()
const FEATURES = inject('FEATURES')

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => ({}),
    },
    active: {
        type: Boolean,
        default: false,
    },
    success: {
        type: Boolean,
        default: true,
    },
    campaignType: {
        type: String,
        required: true,
        validator: (campaignType) => [AUDIENCE_TYPE_MARKETING, AUDIENCE_TYPE_TRANSACTIONAL].includes(campaignType)
    }
});

const emit = defineEmits(["update:modelValue", "update:active", "continue"]);

const aiModal = ref(false);

const data = reactive({
    body: "",
    selectedSnippetBody: null,
    mediaUrl: null,
    mediaContentType: null,
});

watch(data, () => {
    emit("update:modelValue", data);
});

watch(
    () => props.modelValue,
    () => {
        data.body = props.modelValue.body || "";
        data.selectedSnippetBody = props.modelValue.selectedSnippetBody || "";
        data.mediaUrl = props.modelValue.mediaUrl || "";
        data.mediaContentType = props.modelValue.mediaContentType || "";
    },
    { immediate: true }
);

const editable = computed(() => Boolean(!props.active && data.body));
const showStats = computed(() => Boolean(props.active || data.body));

const confirm = () => {
    if (!data.body.length) {
        toasted.error('Enter a message.')
        return;
    }
    emit('continue')
}

const encoding = computed(() =>
    bodyCodePoints.value.every((charDec) => charDec in UNICODE_TO_GSM)
        ? "GSM"
        : "UCS2"
);

const messageType = computed(() => (data.mediaUrl ? "MMS" : "SMS"));

const bodyCodePoints = computed(() =>
    (data.body?.split("") || []).map((char) => char.codePointAt(0) ?? 0)
);

const bodyGSMEncodedLength = computed(() =>
    bodyCodePoints.value.reduce(
        (total, charDec) => (total += UNICODE_TO_GSM[charDec]?.length ?? 0),
        0
    )
);

const segmentsCount = computed(() => {
    if (messageType.value === "MMS") return undefined;

    if ((data.body?.length ?? 0) === 0) return 0;

    if (encoding.value === "GSM") {
        return bodyGSMEncodedLength.value <= GSM_MAX_SINGLE_SEGMENT_SIZE
            ? 1
            : Math.ceil(
                  bodyGSMEncodedLength.value / GSM_MAX_CONCAT_SEGMENT_SIZE
              );
    }

    if (encoding.value === "UCS2") {
        if (data.body.length <= UCS2_MAX_SINGLE_SEGMENT_SIZE) return 1;

        let subTotal = 0;
        const segments = (data.body?.split("") || [])
            .map((char) => char.length)
            .reduce((acc, charLength) => {
                subTotal += charLength;
                if (subTotal <= UCS2_MAX_CONCAT_SEGMENT_SIZE) return acc;

                subTotal = charLength;
                return ++acc;
            }, 1);

        return segments;
    }

    return 0;
});

const metadata = computed(() => {
    const items = [
        {
            label: "message",
            shortLabel: "msg",
            value: messageType.value,
        },
    ];

    if (segmentsCount.value !== undefined) {
        items.push({
            label: "segments",
            shortLabel: "segs",
            value: segmentsCount.value,
        });
    }

    items.push({
        label: "characters",
        shortLabel: "chars",
        value: data.body?.length ?? 0,
    });

    return items;
});
</script>
