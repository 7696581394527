<template>
    <api-delivery-code-card class="vx-w-full" :icon="faInboxIn" :loading="props.response === undefined" :code="props.response?.response_body" :content-type="props.response?.response_content_type" :is-truncated="props.response?.is_truncated">
        <template v-slot:title>
            Response
        </template>
        <template v-slot:metadata>
            <div class="vx-flex vx-flex-col vx-gap-1 vx-text-slate-700 sm:vx-flex-row sm:vx-gap-6">
                <div class="vx-flex vx-flex-wrap vx-gap-1">
                    <strong>Code:</strong> {{ props.response?.response_status_code || '-' }}
                </div>
                <div class="vx-flex vx-flex-wrap vx-gap-1">
                    <strong>Content Type:</strong> {{ props.response?.response_content_type || '-' }}
                </div>
            </div>
        </template>
        <template v-slot:missing-message>
            Response Missing
        </template>
    </api-delivery-code-card>
</template>
<script setup>
import ApiDeliveryCodeCard from './ApiDeliveryCodeCard.vue';
import { faInboxIn } from '@fortawesome/pro-solid-svg-icons';

const props = defineProps({
    response: {
        type: Object,
        required: false,
    }
});

</script>
