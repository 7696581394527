export const TEAM_USER_ROLES = {
    owner: 'Owner',
    admin: 'Administrator',
    manager: 'Manager',
    service: 'Service',
    mh_supervisor: 'Supervisor',
    mh_service_rep: 'Service Representative',
    billing: 'Accounting',
    member: 'Member',
};

export const teamRolesOptions = (exclude = []) =>
    Object.keys(TEAM_USER_ROLES)
        .reduce(
            (acc, key) => [
                ...acc,
                {
                    label: TEAM_USER_ROLES[key],
                    value: key,
                },
            ],
            []
        )
        .filter((role) => !exclude.includes(role.value));
