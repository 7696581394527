<template>
    <div class="vx-flex vx-flex-col-reverse vx-flex-1 vx-overflow-y-auto vx-py-2" @scroll="handleScroll">
        <div v-for="(messageGroup, key) in messageGroups" :key="key" class="t-chat-messages-group">
            <span class="vx-flex vx-items-center vx-justify-center vx-text-slate-600 vx-text-xs vx-my-4">{{
        messageGroup.prettyDate }}</span>
            <template v-for="message in messageGroup.messages">
                <vx-message-cloud v-if="message.body || message.media_url" :key="message.id" :messageId="message.id"
                    :mediaUrl="message.media_url" :mediaContentType="message.media_content_type"
                    :messageBody="message.body" :isInbound="message.direction === 'inbound'"
                    @mediaPlaceholderClick="($event) => mediaExpiredModal = !!$event.isFileExpired">
                    <template v-slot:info>
                        <div v-if="message.body || message.media_url">
                            <span v-if="message.direction === 'outbound'">
                                <span v-if="message.origin_meta.type">{{ message.origin_meta.type }} • {{ message.origin_meta.description }} • </span>
                                <span v-else>Outbound • Ghost • </span>
                            </span>
                            <span v-else>Inbound • </span>
                            <span>{{ toShortDateAtTime(message.created_at) }}</span>
                        </div>
                    </template>
                </vx-message-cloud>
            </template>
        </div>
        <vx-modal v-model:visible="mediaExpiredModal" class="vx-max-w-lg">
            <template v-slot:header>
                <div class="vx-flex vx-flex-col vx-items-center vx-justify-center vx-gap-y-2">
                    <font-awesome-icon :icon="faImage" size="2xl" class="vx-text-rose-400" />
                    <span class="vx-text-3xl vx-font-extrabold vx-text-center">Expired Media</span>
                </div>
            </template>

            <div class="vx-flex vx-flex-col vx-items-center vx-justify-center vx-gap-y-8">
                <p class="vx-text-base vx-font-normal vx-text-center vx-text-slate-500">
                    Images and videos are only kept by our MMS provider for a short time. After this time, the media
                    will
                    disappear. Instead of deleting the message entirely, we show a placeholder icon so you can see when
                    the
                    media was sent or received.
                </p>
                <div class="vx-flex vx-items-center vx-justify-center vx-w-full">
                    <vx-button @click="mediaExpiredModal = false" size="lg" type="button" block color="primary"
                        class="lg:vx-mt-6 vx-max-w-[269px]">
                        Got It
                    </vx-button>
                </div>
            </div>
        </vx-modal>
    </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import messagesClient from '~/services/messagesClient';
import dayjs from '~/utils/dayjs';
import { VxMessageCloud } from '@voxie/frontend-components-v3';
import { VxModal, VxButton } from '@voxie/frontend-components';
import debounce from '~/utils/debounce';
import { toShortDateAtTime } from '~/utils/date';
import { isAuthErrorEcho } from '~/utils/helpers';
import { useCursorPagination } from '../../../../composables/useCursorPagination';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faImage } from '@fortawesome/pro-solid-svg-icons';

const { list: messages, fetchParams, fetchRecords } = useCursorPagination();

const props = defineProps({
    contact: {
        type: Object,
        required: true,
    }
});
const teamId = Spark.state.currentTeam.id;

const messageGroups = computed(() => {
    const messagesToDay = ([day, group]) => {
        return {
            fullDate: day,
            prettyDate: dayjs(day).format('MMMM D, YYYY'),
            messages: group.sort((mA, mB) => mA.id - mB.id)
        };
    };

    const groups = messages.value.reduce((groups, message) => {
        const groupKey = dayjs(message.created_at).startOf('day').toString();
        if (!groups[groupKey]) {
            groups[groupKey] = [];
        }

        groups[groupKey].push(message);

        return groups;
    }, {});

    return Object.entries(groups).map(messagesToDay).sort((gA, gB) => {
        return new Date(gB.fullDate).getTime() - new Date(gA.fullDate).getTime();
    });
});

const mediaExpiredModal = ref(false);

const defaultFilters = () => ({
    per_page: 15,
    cursor: null,
    sort_dir: 'desc'
});

const isNearBottom = ref(false);
const isUserNearBottom = (el) => {
    const threshold = 10;
    return el.scrollTop + threshold >= 0;
};
const isUserNearTop = (el) => {
    const threshold = 50;
    const scrollHeight = el.scrollHeight;
    const scrollTop = el.scrollTop;
    const clientHeight = el.clientHeight;

    const scrollPosition = (scrollTop - clientHeight) * -1;

    return scrollPosition + threshold >= scrollHeight
};
const handleScroll = debounce((evt) => {
    isNearBottom.value = isUserNearBottom(evt.target);
    if (isUserNearTop(evt.target)) {
        fetchRecords(messagesClient.listMessages(teamId, props.contact.id, {...fetchParams.value}));
    }
}, 300);

onMounted(() => {
    fetchParams.value = defaultFilters();
    fetchRecords(messagesClient.listMessages(teamId, props.contact.id, {...fetchParams.value}));
})

const echoErrors = [];
Echo.private(`messages.${teamId}`)
    .listen('MessageCreated', ({ contact, message }) => {
        if (contact.id === props.contact.id) {
            messages.value.push(message);
        }
    })
    .error?.((e) => {
        if (echoErrors.length <= 3 && !isAuthErrorEcho(e)) {
            window?.Bugsnag?.notify(e instanceof Error ? e : new Error(JSON.stringify(e)));
            echoErrors.push(e);
        }
    });
</script>
