<template>
    <div class="d-flex flex-column flex-grow-1 overflow-auto">
        <section class="d-flex flex-column">
            <search-input v-model.trim="searchText" placeholder="Search Variables" maxlength="256" />
            <p style="padding: 0px 10px; margin: 5px 0px; font-family: &quot;Source Sans Pro&quot;; font-style: normal; font-weight: normal; font-size: 11px; line-height: 16px;">
                Variables can be inserted into your message to dynamically render contextual information.
            </p>
        </section>
        <span class="line" />
        <section class="list">
            <message-variables
                :search-text="searchText"
                :show-description="false"
                :input="input"
                @variableInserted="$emit('variableInserted', $event)"
            />
        </section>
    </div>
</template>

<script>
import MessageVariables from '../../../general/MessageVariables.vue';
import SearchInput from '../../../general/SearchInput.vue';

export default {
    components: { MessageVariables, SearchInput },
    props: {
        input: {
            required: true,
        },
    },
    data() {
        return {
            searchText: '',
        };
    },
};
</script>
