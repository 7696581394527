<template>
    <div>
        <vx-expandable-card toggleable
            :active="props.active">
            <template v-slot:header>
                Follow-up message
            </template>
            <template v-slot:headerRight
                v-if="props.active">
                <vx-button @click="previewModal = true"
                    size="sm"
                    color="muted">
                    Preview Original Message
                    <font-awesome-icon :icon="faEye"></font-awesome-icon>
                </vx-button>
            </template>
            <template v-slot:content>
                <vx-tabs v-model="currentTab"
                    size="lg"
                    class="vx-mb-6">
                    <vx-tab value="recommended">
                        Recommended
                        <experimental-pill v-if="FEATURES.quick_blast_ai" class="vx-ml-2" :disabled="currentTab !== 'recommended'"></experimental-pill>
                    </vx-tab>
                    <vx-tab value="custom">Custom</vx-tab>
                </vx-tabs>

                <template v-if="currentTab === 'recommended'">
                    <vx-radio-selection v-model="data.body">
                        <vx-radio-selection-item v-for="recommended in recommendedMessages"
                                :value="recommended"
                                :key="recommended"
                                :disabled="props.submiting || fetching"
                                >
                                <template v-slot:content>
                                    <div class="vx-flex vx-items-center vx-justify-between vx-gap-6">
                                        <div class="vx-grow">
                                            {{ recommended }}
                                        </div>
                                        <vx-button v-if="recommended === data.body"
                                            @click="currentTab = 'custom'"
                                            type="button"
                                            color="muted"
                                            size="sm">
                                            Edit
                                            <font-awesome-icon :icon="faPencil"></font-awesome-icon>
                                        </vx-button>
                                    </div>
                                </template>
                            </vx-radio-selection-item>
                    </vx-radio-selection>
                </template>
                <strong v-if="currentTab === 'custom'"
                    class="vx-text-slate-900 vx-text-sm vx-font-semibold vx-mb-2 vx-block sm:vx-text-base">
                    Custom Message
                </strong>
                <message-builder v-if="currentTab === 'custom'"
                    v-model="data.body"
                    v-model:mediaUrl="data.mediaUrl"
                    v-model:mediaContentType="data.mediaContentType"
                    @selected:snippet="data.selectedSnippetBody = $event.body"
                    preview
                >
                </message-builder>
                <div class="vx-pt-4 vx-flex vx-gap-2">
                    <vx-button @click.prevent="emit('update:active', false)"
                        type="button"
                        size="lg"
                        color="muted">Back</vx-button>
                    <vx-button @click.prevent="confirm()"
                        :loading="props.submiting || fetching"
                        :disabled="props.submiting || fetching"
                        type="button"
                        size="lg"
                        class="vx-flex-grow">
                        Schedule
                    </vx-button>
                </div>
            </template>
        </vx-expandable-card>
        <message-preview-modal v-model:visible="previewModal"
            :message="props.originalMessage">
            <template v-slot:header>
                Original Message
            </template>
            <template v-slot:subheader>
                This is the original Quick Blast message
            </template>
        </message-preview-modal>
    </div>
</template>

<script setup>
import { faEye, faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxButton, VxExpandableCard, VxTabs, VxTab, VxRadioSelection, VxRadioSelectionItem } from '@voxie/frontend-components';
import { reactive, ref, watch, computed, onMounted, inject } from 'vue';
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import { useToasted } from '../../composables/useToasted';
import MessagePreviewModal from '../general/message-builder/modals/MessagePreviewModal.vue'
import aiClient from '../../services/aiClient';
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';

const teamId = Spark.state.currentTeam.id;
const FEATURES = inject('FEATURES')

const toasted = useToasted();
const emit = defineEmits(['continue', 'update:modelValue', 'update:active', 'fetched'])

const props = defineProps({
    submiting: {
        type: Boolean,
    },
    active: {
        type: Boolean,
    },
    modelValue: {
        type: Object,
        required: true,
        default: () => ({})
    },
    originalCampaignId: {
        type: Number,
        required: true,
    },
    originalCampaignType: {
        type: String,
        required: true,
    },
    originalMessage: {
        type: Object,
        required: true,
        default: () => ({})
    },
});

const currentTab = ref('recommended');
const previewModal = ref(false);

watch(currentTab, () => {
    if (currentTab.value === 'recommended') {
        data.body = recommendedMessages.value[0];
    }
})

const data = reactive({
    body: "",
    selectedSnippetBody: null,
    mediaUrl: null,
    mediaContentType: null,
});

const recommendedMarketingMessages = [
    '🌟 Missed our last message? Don\'t wait, this deal is about to ✨expire✨!',
    '⏳ Last chance to save big! Scroll up ⬆️ for all the details!',
    '📣 Final call! You only have a few hours left to snag that deal. Check out the last text for all the info! 🛍️'
]

const recommendedTransactionalMessages = [
    'Don\'t forget about our last message, scroll up ⬆️ for all the details!',
    '⏳ We didn\'t hear from you after our last message. Please make sure to review everything above and let us know if you have any questions! 📣',
    'Important alert! Scroll up to catch the details you might have missed. 👆'
];

const recommendedMessages = computed(() => {
    if (props.originalCampaignType === 'marketing') {
        return [...followUpAiMessages.value, ...recommendedMarketingMessages.slice(0, 3 - followUpAiMessages.value.length)];
    }

    return [...followUpAiMessages.value, ...recommendedTransactionalMessages.slice(0, 3 - followUpAiMessages.value.length)];
});


watch(data, () => {
    emit("update:modelValue", data);
});

watch(
    () => props.modelValue,
    () => {
        data.body = props.modelValue.body || "";
        data.selectedSnippetBody = props.modelValue.selectedSnippetBody || "";
        data.mediaUrl = props.modelValue.mediaUrl || "";
        data.mediaContentType = props.modelValue.mediaContentType || "";
    },
    { immediate: true }
);

const confirm = ref(() => {
    if (!data.body.length) {
        toasted.error('Enter a message.');
        return;
    }

    emit('continue');
});

const followUpAiMessages = ref([]);
const fetching = ref(false);
const fetchAIMessages = async () => {
    if (fetching.value) {
        return;
    }

    fetching.value = true;
    try {
        const response = await aiClient.quickBlastFollowUp(teamId, props.originalCampaignId);

        followUpAiMessages.value = response.data?.data?.map((m) => m.message) || [];
    } catch (e) {
        console.error(e);
    } finally {
        fetching.value = false;

        data.body = recommendedMessages.value[0];

        emit('fetched');
    }
}

onMounted(() => {
    if (!props.modelValue.body) {
        data.body = recommendedMessages.value[0];
    }
});

if (FEATURES.quick_blast_ai) {
    fetchAIMessages();
}

defineExpose({
    fetching,
});
</script>
