import BaseClient from './BaseClient';

class PhoneNumbersClient extends BaseClient {
    filterPhoneNumbers(teamId, params) {
        const url = `/api/v3/teams/${teamId}/phone-numbers/filter`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    showPhoneNumber(teamId, phoneNumberId) {
        const url = `/api/v3/teams/${teamId}/phone-numbers/${phoneNumberId}`;
        return this.makeCoreApiRequest(url, 'get');
    }
}

export default new PhoneNumbersClient();
