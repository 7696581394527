export default function debounce(callback, wait) {
    let debounceTimer;

    return function debounced(...args) {
        const context = this;

        const delayedFunction = () => {
            debounceTimer = null;
            callback.apply(context, args);
        };

        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        debounceTimer = setTimeout(delayedFunction, wait ?? 300);
    };
}
