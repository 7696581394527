<template>
    <vx-expandable-card :class="{
        'vx-opacity-40 vx-cursor-not-allowed': props.disabled
    }">
        <template v-slot:header>
            <div class="vx-flex vx-flex-col vx-items-start" :class="{
                'vx-opacity-40 vx-cursor-not-allowed': props.disabled
            }">
                <slot name="header"></slot>

                <span class="vx-text-sm vx-font-normal vx-text-slate-500">
                    <slot name="subheader"></slot>
                </span>
            </div>
        </template>
        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-6">
                <div class="vx-relative">
                    <vx-selectable
                        :placeholder="props.placeholder"
                        size="lg"
                        :taggable="true"
                        @search="emit('search', $event)"
                        :options="props.options || []"
                        v-model.lowercase="inputModel"
                        :disabled="props.disabled || props.loading || hitMax"
                        :error="displayFirstError(validation.$errors)"
                        @option:selected="addValue()"
                    ></vx-selectable>
                    <div v-if="$slots['max-values']" class="vx-mt-2 vx-px-0.5 vx-text-xs vx-text-slate-600">
                        <slot name="max-values"></slot>
                    </div>
                </div>
            </div>
            <div class="vx-flex vx-flex-col vx-mt-6">
                <div v-if="props.loading || !props.modelValue.length" class="vx-text-center vx-py-10">
                    <vx-icon-loading v-if="props.loading" class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
                    <div v-else class="vx-text-xl vx-text-slate-500">
                        <slot name="empty"></slot>
                    </div>
                </div>
                <transition-group tag="div" class="vx-relative"
                    enter-active-class="vx-transition-all vx-transition-ease-out vx-duration-300 vx-origin-right"
                    enter-from-class="vx-opacity-0 -vx-translate-y-20 vx-scale-95"
                    enter-to-class="vx-opacity-100 vx-translate-y-0"
                    leave-active-class="vx-transition-all vx-transition-ease-out vx-absolute vx-duration-150"
                    leave-from-class="vx-opacity-100 vx-translate-y-0" leave-to-class="vx-opacity-0 vx-scale-90"
                    move-class="vx-transition-all vx-transition-ease-out vx-duration-300">
                    <div v-for="(item, index) in props.modelValue" :key="`value-${item.id}`"
                        class="vx-flex vx-w-full vx-flex-row-reverse vx-items-center vx-justify-between vx-border-0" :class="{
                            'vx-border-b vx-border-solid vx-border-b-slate-200': index !== item.length - 1
                        }" data-test="value">
                        <span @click="removeValue(item.id)"
                            class="vx-group/del vx-peer vx-relative vx-px-4 vx-py-2 vx-transition" :class="{
                                'vx-cursor-pointer': !props.disabled,
                                'vx-pointer-events-none vx-cursor-not-allowed vx-opacity-40': props.disabled,
                            }" data-test="remove-value">
                            <font-awesome-icon :icon="faCircleMinusReg"
                                class="vx-text-slate-500 vx-transition group-hover/del:vx-opacity-0" />
                            <font-awesome-icon :icon="faCircleMinus"
                                class="vx-absolute vx-inset-0 vx-m-auto vx-text-rose-500 vx-opacity-0 vx-transition group-hover/del:vx-opacity-100" />
                        </span>

                        <span
                            class="vx-truncate vx-content-[''] vx-relative vx-transition after:vx-absolute after:-vx-inset-x-1 after:vx-top-1/2 after:vx-h-px after:vx-origin-left after:-vx-translate-y-1/2 after:vx-scale-x-0 after:vx-bg-black after:vx-transition peer-hover:vx-opacity-50 peer-hover:after:vx-scale-x-100">
                            {{ item.value }}
                        </span>
                    </div>
                </transition-group>
            </div>
            <slot name="footer"></slot>
        </template>
    </vx-expandable-card>
</template>
<script setup>
import { VxExpandableCard, VxIconLoading, VxSelectable } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleMinus } from '@fortawesome/pro-solid-svg-icons';
import { faCircleMinus as faCircleMinusReg } from '@fortawesome/pro-regular-svg-icons';
import { ref, computed, onMounted } from 'vue';
import useVuelidate from '@vuelidate/core';
import { displayFirstError } from '~/utils/validation';
import { randomString } from '~/utils/string';

const props = defineProps({
    modelValue: {
        type: Array,
        default: () => ([
            {
                id: randomString(),
                value: '',
            }
        ])
    },
    placeholder: {
        type: String,
        default: '',
    },
    options: {
        type: Array,
        default: () => ([]),
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    maxValues: {
        type: [Number, undefined],
    },
    validationRules: {
        type: Object,
        default: () => ({}),
    }
});

const emit = defineEmits(['update:modelValue', 'search']);

const inputModel = ref('');

onMounted(() => {
    emit('search', inputModel.value)
});

const hitMax = computed(() => undefined !== props.maxValues && props.modelValue.length >= props.maxValues);
const addValue = async () => {
    if (
        hitMax.value || props.disabled || props.loading
    ) {
        return;
    }

    const isValid = await validation.value.$validate();
    if (!isValid) {
        return;
    }

    const collection = props.modelValue;

    collection.unshift({
        id: randomString(20),
        value: inputModel.value,
    });

    emit('update:modelValue', collection);

    inputModel.value = '';
    validation.value.$reset();
}

const removeValue = (id) => {
    if (props.disabled || props.loading) {
        return;
    }

    emit('update:modelValue', props.modelValue.filter((item) => item.id !== id));
}

const rules = computed(() => props.validationRules);
const validation = useVuelidate(rules, inputModel);
</script>
