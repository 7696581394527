<template>
    <vx-expandable-card id="custom-attributes">
        <template v-slot:header> Custom Attributes </template>
        <template v-slot:headerSubtitle> Metadata that can be used in other resources. </template>

        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-2 vx-mb-3 lg:vx-flex-row lg:vx-mb-6">
                <div class="lg:vx-w-60" data-test="custom-attribute-key">
                    <vx-label>Key</vx-label>
                    <vx-input
                        size="lg"
                        v-model="key"
                        placeholder="key"
                        :error="displayFirstError(validate.key)"></vx-input>
                </div>
                <div class="vx-flex vx-grow vx-gap-2">
                    <div class="vx-grow" data-test="custom-attribute-value">
                        <vx-label>Value</vx-label>
                        <vx-input ref="valueEl"
                            data-test="custom-attribute-value"
                            size="lg"
                            placeholder="value"
                            v-model="value"
                            @keypress.enter="add()"
                            :error="displayFirstError(validate.value)"></vx-input>
                    </div>
                    <div class="vx-pt-7 lg:vx-pt-6">
                        <vx-button @click="add()"
                            data-test="custom-attribute-add"
                            size="lg"
                            type="button">
                            <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                        </vx-button>
                    </div>
                </div>
            </div>
            <vx-alert :closeable="false"
                v-if="!model.length">
                No custom attributes were added to this group.
            </vx-alert>
            <div v-else>
                <vx-list-animated v-model="model"
                    value="key"
                    label="key">
                    <template v-slot:item="{ item }">
                        <div class="vx-flex vx-flex-col vx-items-start vx-gap-2 vx-py-3 lg:vx-flex-row">
                            <div class="vx-font-bold vx-break-all lg:vx-w-60 lg:vx-font-normal">{{ item.key }}</div>
                            <div class="vx-break-all lg:vx-w-[17rem]">{{ item.value }}</div>
                        </div>
                    </template>
                </vx-list-animated>
            </div>
        </template>
    </vx-expandable-card>

</template>

<script setup>
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxAlert, VxButton, VxExpandableCard, VxInput, VxLabel, VxListAnimated } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { maxLength, requiredIf } from '@vuelidate/validators';
import { nextTick, ref } from 'vue';
import { csvInjection, groupCustomAttributeKey, customAttributeValue, displayFirstError } from '~/utils/validation';

const model = defineModel({ default: () => []});
const valueEl = ref();

const key = ref('');
const value = ref('');

const adding = ref(false);

const validate = useVuelidate({
    key: {
        requiredIf: requiredIf(adding),
        maxLength: maxLength(40),
        format: groupCustomAttributeKey,
        csvInjection: csvInjection,
    },
    value: {
        requiredIf: requiredIf(adding),
        maxLength: maxLength(350),
        format: customAttributeValue,
        csvInjection: csvInjection,
    },
}, {
    key,
    value,
}, {
    $autoDirty: true,
});

const add = async () => {
    adding.value = true;
    await nextTick();

    const isValid = await validate.value.$validate();

    if (!isValid) {
        setTimeout(() => {
            adding.value = false;
        }, 3500);
        return;
    }

    const existingIndex = model.value.findIndex(customAttribute => customAttribute.key === key.value);
    if (existingIndex !== -1) {
        model.value.splice(existingIndex, 1);
    }

    model.value.unshift({
        key: key.value.toLowerCase(),
        value: value.value,
    });

    key.value = '';
    value.value = '';
    adding.value = false;
}

</script>
