<template>
    <auth-box class="vx-max-w-[480px]">
        <div v-if="props.status" class="vx-flex vx-flex-col vx-gap-1 vx-py-10 vx-text-center">
            <font-awesome-icon :icon="faCircleCheck" class="vx-text-emerald-500" size="2x"></font-awesome-icon>
            <div class="vx-text-xl vx-font-extrabold">
                Password Reset Sent
            </div>
            <div class="vx-text-sm vx-text-slate-500">
                Check your email for next steps!
            </div>
        </div>
        <form v-else id="password-reset-form"
            method="POST"
            action="/password/email"
            class="vx-flex vx-flex-col vx-gap-4">

            <vx-alert v-if="status" color="success">{{ status }}</vx-alert>

            <div>
                <vx-label>
                    Email
                </vx-label>
                <vx-input required v-model="data.email" type="email" autofocus name="email" id="email" size="lg"
                    :error="v$.email.$error ? 'Email is invalid' : ''"
                    @blur="v$.email.$touch"
                ></vx-input>
            </div>

            <div class="vx-flex vx-flex-col vx-gap-2 vx-mt-4 md:vx-flex-row-reverse">
                <vx-button v-if="props.recaptchaKey"
                    data-size="invisible"
                    :data-sitekey="props.recaptchaKey"
                    data-callback="submitPasswordReset"
                    :loading="submitting" class="g-recaptcha md:vx-flex-grow" type="submit" color="primary" size="lg">
                    Reset <font-awesome-icon :icon="faPaperPlane"></font-awesome-icon>
                </vx-button>

                <vx-button v-else :loading="submitting" class="md:vx-flex-grow" type="submit" color="primary" size="lg">
                    Reset <font-awesome-icon :icon="faPaperPlane"></font-awesome-icon>
                </vx-button>

                <vx-button color="muted" size="lg" href="/login">
                    Cancel
                </vx-button>
            </div>
        </form>
    </auth-box>
</template>
<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AuthBox from './AuthBox.vue';
import { VxLabel, VxInput, VxButton, VxAlert } from '@voxie/frontend-components';
import { faCircleCheck, faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { ref, reactive, onMounted } from 'vue';
import { useUrlSearchParams } from '@vueuse/core'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

const props = defineProps({
    status: String,
    recaptchaKey: String,
});

onMounted(() => {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?hl=en')
    document.head.appendChild(recaptchaScript)
});

window.submitPasswordReset = () => {
    submitting.value = true;
    window.document.getElementById('password-reset-form').submit()
}

const submitting = ref(false);

const params = useUrlSearchParams('history', {
    removeNullishValues: true,
    removeFalsyValues: true,
});

const data = reactive({  email: params.email || '' });

const v$ = useVuelidate({
    email: {required, email},
}, data);

</script>
