<template>
    <vx-expandable-card
        toggleable
        id="launch"
        :active="active"
    >
        <template v-slot:header>Launch</template>
        <template v-slot:content>
            <quick-blast-launch-success
                v-if="props.success"
            ></quick-blast-launch-success>
            <template v-else>
                <quick-blast-launch-preview
                    :name="props.name"
                    @update:name="emit('update:name', $event)"
                    :mediaUrl="props.mediaUrl"
                    :bodyMessage="props.bodyMessage"
                ></quick-blast-launch-preview>

                <div class="vx-flex vx-flex-col-reverse vx-mt-4 lg:vx-mt-12 lg:vx-flex-row">
                    <vx-button
                        size="xl"
                        color="muted"
                        class="vx-mt-2 lg:vx-mb-0 lg:vx-mt-0 lg:vx-mr-2 lg:vx-w-36"
                        type="button"
                        @click="testing = true"
                        data-test="launch-test"
                    >
                        Send Test
                        <font-awesome-icon
                            :icon="faFlaskVial"
                        ></font-awesome-icon>
                    </vx-button>
                    <vx-button
                        :loading="props.submiting"
                        :disabled="props.submiting"
                        size="xl"
                        color="primary"
                        class="vx-flex-grow"
                        type="button"
                        @click="emit('submit')"
                        data-test="launch-submit"
                    >
                        Schedule Message
                    </vx-button>
                </div>
            </template>
        </template>
    </vx-expandable-card>
    <message-builder-test
        v-model:visible="testing"
        :bodyMessage="props.bodyMessage"
        :mediaUrl="props.mediaUrl"
        :mediaContentType="props.mediaContentType"
    ></message-builder-test>
</template>

<script setup>
import { ref } from 'vue';
import {
    faFlaskVial,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxButton,
    VxExpandableCard,
} from "@voxie/frontend-components";
import QuickBlastLaunchPreview from './QuickBlastLaunchPreview.vue';
import QuickBlastLaunchSuccess from './QuickBlastLaunchSuccess.vue';
import MessageBuilderTest from '~/components/general/message-builder/modals/MessageBuilderTest.vue';

const props = defineProps({
    active: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        required: true,
    },
    mediaUrl: {
        type: String,
        required: false,
    },
    mediaContentType: {
        type: String,
        required: false,
    },
    bodyMessage: {
        type: String,
        required: true,
    },
    success: {
        type: Boolean,
        default: false,
    },
    submiting: {
        type: Boolean,
        default: false,
    },
});

const testing = ref(false);

const emit = defineEmits(["submit", "update:name"]);
</script>
