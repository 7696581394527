<template>
    <card :title="title">
        <p class="font-weight-bold mb-3">
            By default, all {{ entityPlural }} will be considered for attribution.
            You can skip this section if you do not need to include or exclude specific {{ entityPlural }}.
        </p>
        <p class="mb-3">
            To exclude specific {{ entityPlural }}, select the {{ entityPlural }} and click the exclude list button.
            The selected {{ entityPlural }} will be added to the exclude list.
        </p>
        <p class="mb-5">
            To include only specific {{ entityPlural }}, select the {{ entityPlural }} and click the include list button.
            The selected {{ entityPlural }} will be added to the include list.
        </p>

        <div class="multi-select-attribution-card__select-wrap d-sm-flex align-items-center mx-auto">
            <div class="font-weight-bold mr-3">
                {{ selectLabel }}
            </div>
            <div class="flex-grow-1">
                <v-select
                    :model-value="null"
                    @update:model-value="selectById"
                    placeholder="Search"
                    :loading="loadingOptions"
                    :options="options"
                    :disabled="multiselectDisabled"
                    @search="searchChange"
                />
            </div>
        </div>
        <div class="input-radio-group">
            <div class="input-radio-group-option">
                <input
                    type="radio"
                    id="include"
                    class="input-radio-item"
                    :name="entity"
                    value="include"
                    :disabled="type === 'exclude' && selectedItems.length > 0"
                    :checked="type === 'include'"
                    @change="$emit('update:type','include')"
                />
                <label for="include"> Include </label>
            </div>
            <div class="input-radio-group-option">
                <input
                    type="radio"
                    id="exclude"
                    class="input-radio-item"
                    :name="entity"
                    value="exclude"
                    :disabled="type === 'include' && selectedItems.length > 0"
                    :checked="type === 'exclude'"
                    @change="$emit('update:type','exclude')"
                />
                <label for="exclude"> Exclude </label>
            </div>
        </div>
        <table v-if="selectedItems.length" class="multi-select-attribution-card__table table table-valign-middle w-auto">
            <thead>
                <tr>
                    <th v-if="showId" class="text-center">ID</th>
                    <th>{{ entityTitle }} Name</th>
                    <th class="multi-select-attribution-card__actions-th text-center">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in selectedItems" :key="item.id">
                    <td v-if="showId" class="text-center">
                        {{ item.id }}
                    </td>
                    <td>{{ item.name }}</td>
                    <td class="text-center">
                        <i
                            class="fa fa-trash-o fa-lg cursor-pointer text-danger"
                            data-testid="del-btn"
                            @click="selectedItems.splice(index, 1)"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
        <alert v-else class="mb-0" text="There are no linked items" />
    </card>
</template>

<script>
import pluralize from 'pluralize';
import debounce from '~/utils/debounce';
import Card from '../../../general/Card.vue';
import Alert from '../../../general/Alert.vue';
import VSelect from 'vue-select';

export default {
    components: {
        Card,
        Alert,
        VSelect,
    },
    props: {
        entity: {
            type: String,
            required: true,
        },
        entityTitle: {
            type: String,
            required: true,
        },
        entityDescription: {
            type: String,
            required: true,
        },
        type: {
            type: String,
        },
        selectedItems: {
            type: Array,
            required: true,
        },
        loadingOptions: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,  // each item is an object that has 'id' and 'name'
            required: true,
        },
        limit: {
            type: Number,
            default: 100,
        },
        showId: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:type', 'search-change'],
    computed: {
        title() {
            return this.entityTitle + ' Attribution';
        },
        entityPlural() {
            return pluralize(this.entityDescription);
        },
        multiselectDisabled() {
            return this.selectedItems.length >= this.limit;
        },
        selectLabel() {
            return 'Select ' + this.entityPlural.toLowerCase();
        },
    },
    methods: {
        selectById(selOption) {
            if (selOption) {
                if (!this.selectedItems.find(i => i.id === selOption.id)) {
                    this.selectedItems.push({
                        id: selOption.id,
                        name: selOption.name,
                    });
                }
            }
        },
        searchChange(q) {
            this.searchChangeDebounced(q, this);
        },
        searchChangeDebounced: debounce((q, vm) => {
            vm.$emit('search-change', q);
        }, 350),
    },
};
</script>

<style lang="postcss">
.multi-select-attribution-card__select-wrap {
  max-width: 43rem;
}
.multi-select-attribution-card__table {
  margin: 0 -1.25rem;
}
.multi-select-attribution-card__actions-th {
  width: 1%;
}

.input-radio-group {
  display: flex;
  justify-content: center;
  gap: 0.5em;
  margin: 0.5em;
}

.input-radio-group-option input {
  margin-right: 0.5em;
}
</style>
