import BaseClient from './BaseClient';

class TagsClient extends BaseClient {
    /** Tags operations */
    tagsGetAll($teamId, params) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/tags`;

        return this.makeCoreApiRequest(url, method, null, params);
    }

    tagDelete($teamId, $tagName) {
        const method = 'delete';
        const url = `/api/v3/teams/${$teamId}/tags/${encodeURIComponent($tagName)}`;

        return this.makeCoreApiRequest(url, method);
    }

    /** Tags autocomplete operations */
    tagsAutocomplete($teamId, params) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/tags/autocomplete`;

        return this.makeCoreApiRequest(url, method, null, params);
    }
}

export default new TagsClient();
