<template>
    <div class="vx-flex vx-flex-col vx-gap-2">
        <vx-label :size="props.labelSize">
            Response Options
            <template v-slot:description>
                Add all possible answers to your question.
            </template>
        </vx-label>
        <div class="vx-flex vx-flex-col vx-gap-2" ref="choicesEl">
            <dynamic-field-extraction-choice v-for="choice in choices" :key="`choice-${choice.id}`"
                ref="choicesRef"
                :modelValue="choice.value" @update:modelValue="updateChoice($event, choice.id)"
                @add="addChoice()"
                @remove="removeChoice(choice.id)" :list="choices">
            </dynamic-field-extraction-choice>
        </div>
        <div class="vx-flex vx-flex-col vx-gap-2 lg:vx-flex-row lg:vx-justify-between">
            <vx-button @click="addChoice" size="sm" color="muted-dark" :disabled="choices.length === 20" class="vx-self-start">
                Add Option
                <font-awesome-icon :icon="faCirclePlus" />
            </vx-button>
            <div class="vx-text-sm vx-text-slate-500 vx-flex vx-items-center vx-gap-2">
                <span>Allow multiple answers</span>
                <vx-toggle size="sm" :modelValue="props.multipleChoices" @update:modelValue="emit('update:multipleChoices', $event)"></vx-toggle>
            </div>
        </div>
    </div>
</template>
<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { VxButton, VxLabel, VxToggle } from '@voxie/frontend-components';
import { nextTick, onMounted, ref } from 'vue';
import { randomString } from '~/utils/string';
import { useSortable, moveArrayElement } from '@vueuse/integrations/useSortable'
import DynamicFieldExtractionChoice from './DynamicFieldExtractionChoice.vue';

const props = defineProps({
    labelSize: {
        type: String,
        default: 'lg'
    },
    modelValue: {
        type: [Array, undefined],
        default: undefined,
    },
    multipleChoices: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue', 'update:multipleChoices']);

const choices = ref((props.modelValue || []).map((choice) => ({
    id: randomString(10),
    value: choice
})));

// used for sortable
const choicesEl = ref(null);

const { start: startSortable } = useSortable(choicesEl, choices, {
    handle: '.choice-move',
    draggable: '.choice-draggable',
    animation: 200,
    onUpdate: (e) => {
        moveArrayElement(choices.value, e.oldIndex, e.newIndex);

        nextTick(() => {
            emitUpdateChoices();
        });
    }
});

const emitUpdateChoices = () => {
    emit('update:modelValue', choices.value.map((choice) => choice.value))
}

// used for focusing
const choicesRef = ref([]);

const addChoice = async () => {
    if (choices.value.length === 20) {
        return;
    }

    choices.value.push({
        id: randomString(10),
        value: '',
    });

    emitUpdateChoices();

    await nextTick();
    if (choices.value.length > 1) {
        choicesRef.value?.[choices.value.length - 1]?.focus?.();
    }
}

const updateChoice = (choiceValue, id) => {
    choices.value = choices.value.map((choice) => {
        if (choice.id === id) {
            choice.value = choiceValue;
        }

        return choice;
    })

    emitUpdateChoices();
}

const removeChoice = async (id) => {
    if(choices.value.length === 1) {
        choices.value[0].value = '';
    } else {
        choices.value = choices.value.filter((choice) => choice.id !== id);
    }
    emitUpdateChoices();

    await nextTick();
    choicesRef.value?.[choices.value.length - 1]?.focus?.();
}

onMounted(async () => {
    if (!props.modelValue || props.modelValue.length === 0) {
        await addChoice();
    }

    if (props.multipleChoices !== false && props.multipleChoices !== true) {
        await nextTick();
        emit('update:multipleChoices', false);
    }

    await nextTick();
    startSortable();
});

</script>
