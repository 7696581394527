<template>
    <api-delivery-code-card :icon="faInboxOut" :loading="props.request === undefined" :code="props.request?.request_body"
        :content-type="props.request?.request_content_type" :is-truncated="props.request?.is_truncated">
        <template v-slot:title>
            Request Payload
        </template>
       
        <template v-slot:missing-message>
            Request Missing
        </template>
    </api-delivery-code-card>
</template>
<script setup>
import ApiDeliveryCodeCard from './ApiDeliveryCodeCard.vue';
import { faInboxOut } from '@fortawesome/pro-solid-svg-icons';

const props = defineProps({
    request: {
        type: Object,
        required: false,
    }
});

</script>
