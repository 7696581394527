<template>
    <vx-chart-column
        tooltipItemKey="tooltip"
        :loading="loading"
        :error="hasError"
        @error="chartError = true"
        :data="dataComputed"
        :colors="dataComputed"
        :axis="{
            x: {
                key: 'label',
                axis: true,
                label: '',
                domain: dataComputed.map((item) => item.label),
                tickFormat: formatDateAxisTicks
            },
            y: {
                key: 'count',
                axis: true,
                label: '',
                ticks: 5,
                tickFormat: (v) => formatCount(v),
            },
        }"
    >
        <template v-slot:title>
            <slot name="title">
                <span>
                    Daily New Contacts
                </span>
            </slot>
        </template>
        <template v-slot:subtitle>
            <slot name="subtitle"></slot>
        </template>
        <template v-slot:right>
            <slot name="right">
                <experimental-pill subject="Beta Feedback - Analytics" v-if="!loading" class="-vx-mt-[4px]"></experimental-pill>
            </slot>
        </template>
        <template v-slot:footer>
            <vx-button
                href="/contacts"
                outline
                color="muted-light"
                size="xs"
            >
                Go to Contacts <font-awesome-icon :icon="faArrowRight" />
            </vx-button>
        </template>

        <template v-slot:explainHeader>Daily New Contacts</template>
        <template v-slot:explainContent>
            <p>
                Daily Gross Count of Contacts added into Voxie platform.
            </p>
            <p>
                <strong>New Contacts</strong><br />
                Gross Increase Count of Contacts during the selected period.
            </p>
        </template>
    </vx-chart-column>
</template>
<script setup>
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxButton, VxChartColumn } from '@voxie/frontend-components';
import { ref, computed, watch } from 'vue';
import { formatCount } from '~/components/filters';
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';
import analyticsClient from '~/services/analyticsClient';
import { formatDateAxisTicks, calculateRangeBetweenTwoDates } from '~/utils/analytics';
import dayjs from '~/utils/dayjs';
import { getClientTz } from '~/utils/date';
import { useVersionedRequest } from '~/composables/useVersionedRequest';

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
});

const chartError = ref(false);

const hasError = computed(() => {
    return Boolean(error.value) || chartError.value;
});

const { loading, response, error, fetchData } = useVersionedRequest();

const dataComputed = computed(() => {
    const responseData = response.value?.data;
    if (!responseData?.time_range?.length || !responseData?.values?.length) {
        return [];
    }

    const dataMapped = responseData.time_range.map((date, index) => {
        const value = responseData.values[index] || 0;

        return {
            label: date,
            count: value,
            tooltip: `${dayjs.utc(date).tz(getClientTz()).format('MM/DD')}: ${Intl.NumberFormat('en').format(
                value
            )}`,
            iconColor: '#14ABE0',
            bgColor: '#73D8FC',
        };
    });

    dataMapped.pop();

    return dataMapped;
});

watch(
    [() => props.startDate, () => props.endDate],
    () => {
        chartError.value = false;

        const params = {
            range: calculateRangeBetweenTwoDates({
                startDate: props.startDate,
                endDate: props.endDate,
            }),
        };

        fetchData(analyticsClient.contactsTotalCreated(Spark.state.currentTeam.id, params))
            .catch((e) => {
                console.error(e);
            });
    },
    { immediate: true }
);
</script>
