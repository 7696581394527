<template>
    <div class="vx-space-y-1">
        <vx-label class="vx-flex vx-flex-col">
            <span class="vx-grow">Flow</span>
        </vx-label>

        <div class="vx-w-full vx-flex vx-items-center vx-justify-between vx-gap-1 vx-mb-1">
            <vx-checkbox-selection-item :active="inFlow" @selected="inFlow = !inFlow" class="vx-grow" >
                In Flow
            </vx-checkbox-selection-item>
            <vx-checkbox-selection-item :active="notInFlow" @selected="notInFlow = !notInFlow" class="vx-grow">
                Not In Flow
            </vx-checkbox-selection-item>
        </div>
        <vx-selectable v-if="inFlow && !notInFlow" placeholder="Select Flow"
            :options="flowOptions" :modelValue="props.modelValue.flow_id" :reduce="(option) => option.value"
            @update:modelValue="updateModelValue('flow_id', $event)">
        </vx-selectable>
    </div>
</template>
<script setup>
import { VxCheckboxSelectionItem, VxLabel, VxSelectable } from '@voxie/frontend-components';
import { onMounted, ref, watch } from 'vue';
import { useToasted } from '~/composables/useToasted';
import flowsClient from '~/services/flowsClient';

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['update:modelValue']);
const inFlow = ref(false);
const notInFlow = ref(false);

const updateModelValue = (key, value) => {
    const modelValue = {
        ...props.modelValue,
        [key]: value
    };

    if (key === 'in_flow' && !value) {
        modelValue.flow_id = null;
    }

    emit('update:modelValue', modelValue);
}

watch([inFlow, notInFlow], ([inFlow, notInFlow]) => {
    if (inFlow && notInFlow) {
        updateModelValue('in_flow', null);
    } else if (inFlow && !notInFlow) {
        updateModelValue('in_flow', 1);
    } else if (!inFlow && notInFlow) {
        updateModelValue('in_flow', 0);
    } else {
        updateModelValue('in_flow', undefined);
    }
});

const flowOptions = ref([]);
const loadFlowOptions = async () => {
    try {
        const response = await flowsClient.list(teamId);
        flowOptions.value = response.data.data.filter((flow) => flow.playbook === null).map((flow) => {
            return {
                label: flow.name,
                value: flow.flow_id,
            }
        });
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    }
}



onMounted(() => {
    loadFlowOptions();

    if (props.modelValue?.in_flow === null) {
        inFlow.value = true;
        notInFlow.value = true;
    } else if (props.modelValue?.in_flow === undefined) {
        inFlow.value = false;
        notInFlow.value = false;
    } else if (props.modelValue?.in_flow === 1) {
        inFlow.value = true;
        notInFlow.value = false;
    } else if (props.modelValue?.in_flow === 0) {
        inFlow.value = false;
        notInFlow.value = true;
    }
})
</script>
