/**
 * Check https://laravel.com/docs/10.x/vite#blade-processing-static-assets for more info.
 * In short we just need to include some of assets that will be used by blade.
 */
import.meta.glob(['../../img/**']);

/*
 * Load jQuery and Bootstrap jQuery, used for front-end interaction.
 */
import './jquery.global';
import './popper';
import 'bootstrap';

import eventBus from './eventBus';
window.Bus = eventBus;

import URI from 'urijs';
window.URI = URI;

import Swal from 'sweetalert2';
window.Swal = Swal;

import promise from 'promise';
window.Promise = promise;

// Bug reporting
let bugsnagVue;

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
if (window.bugsnagApiKey) {
    Bugsnag.start({
        autoTrackSessions: false,
        apiKey: window.bugsnagApiKey,
        appVersion: window.bugsnagAppVersion,
        plugins: [new BugsnagPluginVue()],
        releaseStage: window.appEnvironment,
    });

    if (window.bugsnagUser) {
        // IP is collected by default
        Bugsnag.setUser(window.bugsnagUser.id, window.bugsnagUser.email, window.bugsnagUser.name);

        if (window.bugsnagCurrentTeam) {
            Bugsnag.addMetadata('team', {
                id: window.bugsnagCurrentTeam.id,
                name: window.bugsnagCurrentTeam.name,
            });
        }
    }

    document.addEventListener('securitypolicyviolation', function (cspEvent) {
        // Ignore CSP errors caused by extensions.
        // Those errors mean the extension does not support websites that have CSP header.
        if ((cspEvent.sourceFile || '').startsWith('moz-extension')) {
            return;
        }

        Bugsnag.notify(new Error('Content-Security-Policy violation occurred'), function (event) {
            event.addMetadata('CSP Event', {
                blockedURI: cspEvent.blockedURI,
                documentURI: cspEvent.documentURI,
                effectiveDirective: cspEvent.effectiveDirective,
                originalPolicy: cspEvent.originalPolicy,
                sourceFile: cspEvent.sourceFile,
                violatedDirective: cspEvent.violatedDirective,
            });
        });
    });

    bugsnagVue = Bugsnag.getPlugin('vue');

    window.Bugsnag = Bugsnag;
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
import { Echo, MomentoConnector } from '@voxie/echo';
import { momentoAuthorizer } from '../utils/helpers';

window.Echo = new Echo(
    new MomentoConnector({
        cache: window.momentoCacheName,
        namespace: 'App.Events',
        authorizer: momentoAuthorizer,
        healthCheckDelay: 5000,
        logger: window.appEnvironment === 'sandbox' ? window.console : undefined,
    })
);
import mixin from './mixin';
const globalMixin = mixin;

import './forms/bootstrap';

export const globals = { bugsnagVue, globalMixin };
