<template>
  <div>
    <vx-drop-zone
      ref="mediaDropzone"
      :multiple="multiple"
      :max-files="maxFiles"
      :max-size="maxSize"
      :remove-button="removeButton"
      :file-types="['.csv']"
      @added="emit('added', $event)"
      @error="emit('error', $event)"
    />
    <p class="u-text-mute u-text-center u-mb-small">
      To upload, drag files to the dropzone above, or click it to select. The
      file type must be <b>CSV</b> and not exceed
      <b>{{ formatBytes(maxSize) }}</b
      >.
    </p>
  </div>
</template>
<script setup>
import { VxDropZone } from '@voxie/frontend-components-v3';
import { ref } from 'vue';
import { formatBytes } from '../filters';

defineProps({
  multiple: {
    type: Boolean,
    default: false,
  },
  maxFiles: {
    type: Number,
    default: 1,
  },
  maxSize: {
    type: Number,
    default: 209715200,
  },
  removeButton: {
    type: Boolean,
    default: false,
  },
});

const mediaDropzone = ref();

// Methods
const emit = defineEmits(["added", "error"]);

const removeFile = (file) => {
  mediaDropzone.value.removeFile(file);
}

const updateProgress = (file, progress) => {
  mediaDropzone.value.updateProgress(file, progress);
}


defineExpose({
  removeFile,
  updateProgress
});

</script>
