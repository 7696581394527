<template>
    <div v-if="loading"
        class="vx-text-center vx-py-10">
        <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
    </div>
    <div v-else>
        <div v-if="list.length >= 1"
            class="vx-text-slate-500 vx-font-extrabold vx-mb-1">
            When {{ props.action === 'add' ? 'adding' : 'removing' }}
            {{ props.resources.length > 1 ? 'these resources' : 'this resource' }}:
        </div>
        <div class="vx-flex vx-flex-col vx-gap-4">
            <custom-flow-resource v-for="resource in props.resources"
                :key="'recource.' + resource.type + '.' + resource.id"
                :resource="resource"
                simple></custom-flow-resource>
        </div>
        <template v-if="list.length >= 1">
            <div class="vx-mt-8 vx-text-slate-500 vx-font-extrabold vx-mb-1">These related resources will also be
                {{ props.action === 'add' ? 'added' : 'removed' }}:</div>
            <div class="vx-flex vx-flex-col vx-gap-4">
                <custom-flow-resource v-for="dependency in list"
                    :key="'dependency.' + dependency.type + '.' + dependency.id"
                    :resource="dependency"
                    simple></custom-flow-resource>
            </div>
        </template>
    </div>
</template>

<script setup>
import { VxIconLoading } from '@voxie/frontend-components';
import { onMounted, ref } from 'vue';
import { useToasted } from '~/composables/useToasted';
import automationRulesClient from '~/services/automationRulesClient';
import campaignsClient from '~/services/campaignsClient';
import segmentsClient from '~/services/segmentsClient';
import CustomFlowResource from './CustomFlowResource.vue';
import { useCustomFlow } from './useCustomFlow';
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';

const teamId = Spark.state.currentTeam.id;

const toasted = useToasted();

const props = defineProps({
    action: {
        type: String,
        default: 'add',
    },
    resources: {
        type: Array,
        required: true,
    }
});

const customFlow = useCustomFlow();
const loading = ref(false);
const list = ref([])

const fetchDependencies = async () => {
    loading.value = true;
    try {
        for (const resource of props.resources || []) {
            let client;
            if (resource.type === 'automation') {
                client = automationRulesClient.dependencies(teamId, resource.id);
            } else if (resource.type === 'campaign') {
                client = campaignsClient.dependencies(teamId, resource.id);
            } else if (resource.type === 'segment') {
                client = segmentsClient.dependencies(teamId, resource.id);
            }

            const resourceDeps = ((await client)?.data?.data || []).filter((dependency) => {
                return !props.resources.some((r) => r.id === dependency.id && r.type === dependency.type) &&
                    !list.value.some((r) => r.id === dependency.id && r.type === dependency.type)
            });

            list.value = list.value.concat(
                resourceDeps
            );
        }

        await customFlow.fetchResources(list.value);
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
            return;
        }

        toasted.error(e.response?.data?.message);
    } finally {
        loading.value = false;
    }
}

onMounted(() => {
    fetchDependencies();
});

defineExpose({
    loading: loading,
    list: list,
});
</script>
