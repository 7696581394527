<template>
    <div v-if="!props.success"
        class="vx-w-full vx-pt-4 vx-flex vx-flex-col vx-space-y-6 vx-items-center vx-justify-between lg:vx-flex-row lg:vx-space-y-0 lg:vx-pt-8">
        <div>
            <h3 class="vx-font-extrabold vx-text-slate-700">
                Increase your conversion!
            </h3>
            <p class="vx-text-xs vx-text-slate-500">
                Automatically send a follow-up message to remind your customers to convert.
                <br />
                We&apos;ve found this reliably boosts conversion to the original message!
            </p>
        </div>

        <div class="vx-relative vx-grid vx-place-items-center">
            <vx-button @click="emit('continue')"
                type="button"
                size="lg"
                color="info">
                Add Follow-Up <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
            </vx-button>
        </div>
    </div>
    <div v-else
        class="vx-pt-8 vx-pb-6 vx-text-center lg:vx-pt-12">
        <vx-badge :borders="true"
            class="vx-my-2">
            <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
            Follow-Up Queued
        </vx-badge>
        <p class="vx-mt-4 vx-text-lg vx-font-normal vx-text-slate-600">
            Your message is queued for delivery.
        </p>
    </div>
</template>

<script setup>
import { faCirclePlus, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBadge, VxButton } from '@voxie/frontend-components';
const emit = defineEmits(['continue'])
const props = defineProps({
    success: {
        type: Boolean,
    }
})
</script>
