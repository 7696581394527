<template>
    <div>
        <!-- Update Password -->
        <update-password />

        <!-- Two-Factor Authentication -->
        <template v-if="usesTwoFactorAuth">
            <div v-if="user && !user.uses_two_factor_auth">
                <enable-two-factor-auth :user="user" />
            </div>

            <div v-if="user && user.uses_two_factor_auth">
                <disable-two-factor-auth :user="user" />
            </div>

            <!-- Two-Factor Confirmation Modal -->
            <two-factor-confirmation-modal :two-factor-reset-code="twoFactorResetCode" :qr-image="qrImage"/>
        </template>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import UpdatePassword from './UpdatePassword.vue';
import EnableTwoFactorAuth from './EnableTwoFactorAuth.vue';
import DisableTwoFactorAuth from './DisableTwoFactorAuth.vue';
import TwoFactorConfirmationModal from './modals/TwoFactorConfirmationModal.vue';

defineProps({
    usesTwoFactorAuth: {
        type: Boolean,
        default: false
    },
    user: {
        type: Object,
        required: true
    }
});

const twoFactorResetCode = ref(null);
const qrImage= ref(null);

Bus.$on('receivedTwoFactorConfirmation', ({two_factor_reset_code, qr_image}) => {
    twoFactorResetCode.value = two_factor_reset_code;
    qrImage.value = qr_image;

    window.$('#modal-show-two-factor-confirmation').modal('show');
});

</script>
