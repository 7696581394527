import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { dateTime as dateTimeHelper, mediaUrl } from '../utils/helpers';
import { getClientTz } from '../utils/date';
import pluralize from 'pluralize';
import { vxFormatCount, vxFormatPercentage } from '@voxie/frontend-components';
import dayjs from '~/utils/dayjs';

const entryPointsList = {
    api: 'API',
    automated_upload: 'Automated File Upload',
    connect: 'Contact Collector',
    conversion: 'Conversion',
    ibt: 'Inbound Text',
    manual_upload: 'Manual File Upload',
    salesforce: 'Salesforce',
    shopify: 'Shopify',
    stt: 'Shift to Text',
    user: 'Manual Creation',
    zipwhip_import: 'Zipwhip Import',
};

export function weekDayOfMonth(value) {
    let tz = getClientTz();

    return dayjs.utc(value).tz(tz).format('dddd, MMMM Do');
}

export function timeAmPm(value) {
    const tz = getClientTz();
    const timezone = dayjs.utc(value).tz(tz).format('z');

    return (
        dayjs.utc(value).tz(tz).format('h:mm a') +
        ' ' +
        (timezone.startsWith('+') || timezone.startsWith('-') ? 'GMT' + timezone : timezone)
    );
}

export function campaignStatusCss(campaign) {
    let opts = {
        draft: 'secondary',
        scheduled: 'warning',
        published: 'success',
        archived: 'warning',
        completed: 'info',
        paused: 'danger',
    };

    return opts[campaign.status];
}

export function generalStatusLabel(status) {
    const opts = {
        draft: 'Draft',
        scheduled: 'Scheduled',
        published: 'Live',
        archived: 'Archived',
        completed: 'Completed',
        paused: 'Paused',
    };

    return opts[status];
}

export function formatCampaignMessageDelay(message) {
    return dayjs.duration(message.delay_amount, message.delay_type).humanize();
}

export function mediaUrlFilter(path) {
    return mediaUrl(path);
}

export function displayContactName(contact) {
    if (contact.first_name && contact.last_name) {
        return contact.first_name + ' ' + contact.last_name;
    } else if (contact.first_name) {
        return contact.first_name;
    } else if (contact.last_name) {
        return contact.last_name;
    } else {
        return contact.phone;
    }
}

/**
 * Display the contact name or phone number.
 *
 * @param {Object} contact
 * @return {String}
 */
export function contactName(contact) {
    if (contact.first_name && contact.last_name) {
        return contact.first_name + ' ' + contact.last_name;
    } else if (contact.first_name) {
        return contact.first_name;
    } else if (contact.last_name) {
        return contact.last_name;
    } else if (contact.phone) {
        return phoneNumber(contact.phone);
    } else {
        return 'Anonymous';
    }
}

/**
 * Display the contact phone if they have a first or last name.
 *
 * @param {Object} contact
 * @return {String}
 */
export function contactSubtitle(contact) {
    if ((contact.first_name || contact.last_name) && contact.phone) {
        return phoneNumber(contact.phone);
    }

    return '';
}

/**
 * Display readable date in the user timezone.
 *
 * @param {String} value
 * @return {String}
 */
export function date(value) {
    if (!value) {
        return 'never';
    }

    let timeZone = getClientTz();

    return dayjs.utc(value).tz(timeZone).format('MMM Do, YYYY');
}

/**
 * Display readable date and time in the user timezone.
 *
 * @param {String} value
 * @return {String}
 */
export function dateTime(value) {
    return dateTimeHelper(value);
}

export function formatCount(number) {
    return vxFormatCount(number);
}

export function formatPercentage(percentage) {
    return vxFormatPercentage(percentage);
}

/**
 * Display a short version of the given date.
 *
 * @param {String} value
 * @return {String}
 */
export function shortDate(value) {
    let timeZone = getClientTz();
    let dateTime = dayjs.utc(value).tz(timeZone);

    return dateTime.isSame(dayjs(), 'day') ? dateTime.format('h:mm A') : dateTime.format('MM/DD/YY');
}

/**
 * Display a short version of the given date.
 *
 * @param {String} value
 * @return {String}
 */
export function formatMessageTime(value) {
    if (value) {
        const timeZone = getClientTz();
        const dateTime = dayjs.utc(value).tz(timeZone);
        const format = dateTime.isSame(dayjs(), 'day') ? 'h:mm A' : 'M/D/YY h:mm A';

        return dateTime.format(format);
    }

    return '';
}

/**
 * Format the phone number to human readable format.
 *
 * @todo Implement better phone formatting.
 *
 * @param {String} value
 * @return {String}
 */
export function phoneNumber(value) {
    if (value && value.length < 14) {
        let phoneNumber = parsePhoneNumberFromString(value);

        if (phoneNumber) {
            return phoneNumber.formatInternational();
        }
    }

    return value;
}

/**
 * Format the phone number using national phone number format.
 *
 * @param {String} srcPhone It can be e.g. '+12133734253'
 * @return {String} E.g. '(213) 373-4253'
 */
export function phoneNumberNational(srcPhone) {
    if (srcPhone && srcPhone.length < 14) {
        const phoneNumber = parsePhoneNumberFromString(srcPhone, 'US');
        if (phoneNumber) {
            return phoneNumber.countryCallingCode === '1'
                ? phoneNumber.formatNational()
                : phoneNumber.formatInternational();
        }
    }

    return srcPhone;
}

/**
 * Covert size in bytes by StackOverflow's community.
 *
 * @param {Number} bytes It can be e.g. '209715200'.
 * @param {Number} decimals Fractional part length e.g. '.11'
 * @return {String} E.g. '200MB' or '1.92GB' etc.
 */
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
}

/**
 * Adds commas to numbers greater or equal to 1000.
 * E.g. 999 -> 999
 *      1000 -> 1,000
 *      2000 -> 2,000
 *      20000 -> 20,000
 *      2000000 -> 2,000,000
 *      2000000000 -> 2,000,000,000
 *
 * @param {string|number} number
 * @returns {string}
 */
export const numberWithCommas = (number) => {
    let parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};

/**
 * Pluralize or singularize a word based on the passed in count.
 *
 * @param  {string}  word      The word to pluralize
 * @param  {number}  count     How many of the word exist
 * @param  {boolean} inclusive Whether to prefix with the number (e.g. 3 ducks)
 * @return {string}
 */
export const pluralized = (word, count, inclusive) => {
    return pluralize(word, count, inclusive);
};

/**
 * Display the contact entry point.
 *
 * @param {String|null} entryPoint
 * @return {String}
 */
export function entryPoint(entryPoint) {
    return entryPointsList[entryPoint] || 'Legacy';
}

/**
 * Get entry points for Select components.
 *
 * @returns {{value: string, label: string}[]}
 */
export function entryPointsForSelect({ includeLegacy = false } = {}) {
    const list = { ...entryPointsList };

    if (includeLegacy) {
        list.legacy = 'Legacy';
    }

    return Object.entries(list)
        .sort(([, valueA], [, valueB]) => valueA.localeCompare(valueB))
        .map(([key, value]) => {
            return {
                value: key,
                label: value,
            };
        });
}
