<template>
    <div class="threads-actions-drop-down-v2">
        <vx-dropdown-item
            v-if="isAllSelectedThreadsAreOpen || (activeThread && activeThread.status === 'open')"
            @click="showAssignConversationUi"
        >
            <i class="fa fa-user-plus"><span>Assign To</span></i>
        </vx-dropdown-item>

        <vx-dropdown-item v-if="isMyInbox" @click="unassign">
            <i class="fa fa-ban"><span>Unassign</span></i>
        </vx-dropdown-item>

        <vx-dropdown-item v-if="!isClosedInbox" @click="deleteCheckpoint">
            <i class="fa fa-eye-slash"><span>Mark as Unread</span></i>
        </vx-dropdown-item>

        <vx-dropdown-item>
            <button-confirm
                @confirmed="changeStatus(shouldShowOpenAction ? 'open' : 'closed')"
                :text="`Do you agree with this action?`"
                :confirm-button-text="`Yes, ${shouldShowOpenAction ? 'open' : 'close'} thread`"
            >
                <i class="fa" :class="[shouldShowOpenAction ? 'fa-folder-open' : 'fa-ban']"
                    ><span>{{ shouldShowOpenAction ? 'Open' : 'Close' }}</span></i
                >
            </button-confirm>
        </vx-dropdown-item>

        <vx-dropdown-item v-if="selectedContact.id">
            <button class="dropdown-item mobile" @click="showProfileSection">
                <i class="fa fa-user-circle-o"><span>View Profile</span></i>
            </button>
        </vx-dropdown-item>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useToasted } from '../../../../composables/useToasted';
import Swal from 'sweetalert2';
import ButtonConfirm from '../../../general/ButtonConfirm.vue';
import { VxDropdownItem } from '@voxie/frontend-components-v3';

const emit = defineEmits(['showProfileSection', 'unassign', 'changeStatus']);

const store = useStore();
const $toasted = useToasted();

const activeThread = computed(() => store.getters.getActiveThread);
const activeThreadsInbox = computed(() => store.state.threads.activeThreadsInbox);
const selectedThreads = computed(() => store.state.threads.selectedThreads);
const selectedContact = computed(() => store.state.contacts_v3.selectedContact);

const isClosedInbox = computed(() => activeThreadsInbox.value === 'closed');
const isMyInbox = computed(() => activeThreadsInbox.value === 'me');

const isAllSelectedThreadsAreClosed = computed(
    () => selectedThreads.value.length && selectedThreads.value.every((t) => t.status === 'closed')
);
const isAllSelectedThreadsAreOpen = computed(
    () => selectedThreads.value.length && selectedThreads.value.every((t) => t.status === 'open')
);

const shouldShowOpenAction = computed(
    () =>
        isClosedInbox.value ||
        (activeThread.value && activeThread.value.status === 'closed') ||
        isAllSelectedThreadsAreClosed.value
);

const textPrefix = computed(() => (selectedThreads.value.length ? 'Threads were' : 'The thread was'));

const showProfileSection = () => emit('showProfileSection');
const showAssignConversationUi = () => Bus.$emit('showAssignConversationUi');

const unassign = () => {
    const promises = [];
    if (selectedThreads.value.length) {
        selectedThreads.value.forEach((thread) => {
            promises.push(store.dispatch('unAssignThread', { thread }));
        });
    } else if (activeThread.value && activeThread.value.contact_id) {
        promises.push(store.dispatch('unAssignThread', { thread: activeThread.value }));
    }

    Promise.all(promises)
        .then(() => {
            emit('unassign');
            Swal.fire({ title: 'Success', text: `${textPrefix.value} unassigned successfully.`, icon: 'success' });
        })
        .catch((e) => {
            $toasted.global.platform_error();
            throw e;
        });
};

const deleteCheckpoint = () => {
    const promises = [];
    if (selectedThreads.value.length) {
        selectedThreads.value.forEach((thread) => {
            promises.push(store.dispatch('destroyCheckpoint', { thread }));
        });
    } else if (activeThread.value && activeThread.value.contact_id) {
        promises.push(store.dispatch('destroyCheckpoint', { thread: activeThread.value }));
    }

    Promise.all(promises)
        .then(() => {
            Swal.fire({
                title: 'Success',
                text: `${textPrefix.value} marked as unread successfully.`,
                icon: 'success',
            });
        })
        .catch((e) => {
            $toasted.global.platform_error();
            throw e;
        });
};

const changeStatus = (status) => {
    const promises = [];
    if (selectedThreads.value.length) {
        selectedThreads.value.forEach((thread) => {
            promises.push(store.dispatch('changeState', { thread, state: status }));
        });
    } else if (activeThread.value && activeThread.value.contact_id) {
        promises.push(store.dispatch('changeState', { thread: this.activeThread, state: status }));
    }

    Promise.all(promises)
        .then(() => {
            emit('changeStatus');
            Bus.$emit('threadStatusChanged');
            Swal.fire({ title: 'Success', text: `${textPrefix.value} ${status} successfully.`, icon: 'success' });
        })
        .catch((e) => {
            $toasted.global.platform_error();
            throw e;
        });
};
</script>

<style lang="postcss">
.threads-actions-drop-down-v2 i {
  display: flex;
  gap: 10px;
  line-height: 1.5;
}
.threads-actions-drop-down-v2 i span {
  font-family: var(--font-open-sans);
  font-size: 14px;
  color: var(--color-black);
}
.threads-actions-drop-down-v2 i.fa {
  font-size: 14px;
}
</style>
