<template>
    <div>
    </div>
</template>

<script setup>
const data = defineModel();

data.value.use_event_contact = true;
</script>
