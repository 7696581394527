<template>
    <div>
        <page-header>
            Users
        </page-header>

        <vx-alert v-if="success"
            class="vx-mb-6"
            color="success"
            :closeable="false"
            shadow>
            <template v-slot:icon>
                <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
            </template>
            <span v-html="success"></span>
        </vx-alert>

        <vx-tabs
            v-if="showTabs"
            v-model="currentTab"
            size="lg"
            class="vx-mb-8"
        >
            <vx-tab value="active">Active</vx-tab>
            <vx-tab value="pending">
                <span class="vx-relative">
                    Pending
                    <span class="vx-absolute -vx-right-1.5 vx-top-0 vx-bg-sky-500 vx-w-1.5 vx-h-1.5 vx-rounded-full"></span>
                </span>
            </vx-tab>
        </vx-tabs>

        <team-active-users
            v-show="currentTab === 'active'"
            @add="inviteModal = true"
            @updated="onUserUpdated"
            ></team-active-users>
        <team-pending-users
            ref="pendingUsersRef"
            v-show="currentTab === 'pending'"
            @add="inviteModal = true"
            @deleted="onInviteDeleted"
            @resent="onInviteResent"
            :totalCount="invitesCount">
        </team-pending-users>
        <team-user-form-modal
            v-model="inviteUser"
            v-model:visible="inviteModal"
            @invited="onInvited"
        ></team-user-form-modal>
    </div>
</template>
<script setup>
import PageHeader from '~/components/general/PageHeader.vue';
import TeamActiveUsers from './TeamActiveUsers.vue';
import TeamPendingUsers from './TeamPendingUsers.vue';
import TeamUserFormModal from './TeamUserFormModal.vue';
import { VxTabs, VxTab, VxAlert } from '@voxie/frontend-components';
import { useToasted } from '~/composables/useToasted';
import { ref } from 'vue';
import invitationsClient from '~/services/invitationsClient';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;

const showTabs = ref(false);
const currentTab = ref('active');

const pendingUsersRef = ref();
const invitesCount = ref(0);
const inviteModal = ref(false);
const inviteUser = ref({
    email: '',
    role: '',
})

invitationsClient.invitesCount(teamId).then(({ data }) => {
    invitesCount.value = data.count || 0;
    showTabs.value = invitesCount.value > 0;
}).catch((e) => {
    console.error(e)
    toasted.global.platform_error()
});

const success = ref('');
let successTimeout;

const onInvited = () => {
    clearTimeout(successTimeout);
    pendingUsersRef?.value?.loadData();
    invitesCount.value++;
    showTabs.value = true;

    success.value = '<strong>New User</strong> invited successfully';
    successTimeout = setTimeout(() => {
        success.value = '';
    }, 10000);
}

const onInviteResent = () => {
    clearTimeout(successTimeout);

    success.value = '<strong>Invitation</strong> re-sent successfully';
    successTimeout = setTimeout(() => {
        success.value = '';
    }, 10000);
}

const onUserUpdated = () => {
    clearTimeout(successTimeout);

    success.value = '<strong>User</strong> updated successfully';
    successTimeout = setTimeout(() => {
        success.value = '';
    }, 10000);
}

const onInviteDeleted = () => {
    invitesCount.value--;
    showTabs.value = invitesCount.value > 0;

    currentTab.value = showTabs.value ? 'pending' : 'active';
}

</script>
