<template>
    <div class="vx-flex vx-items-baseline vx-gap-2 choice-draggable">
        <span class="vx-text-slate-500 vx-cursor-move choice-move">
            <font-awesome-icon :icon="faGripDotsVertical" />
        </span>
        <div class="vx-w-full">
            <vx-input ref="inputRef" size="lg" placeholder="Choice" :modelValue="props.modelValue"
                @blur="validation.$touch()"
                :error="displayFirstError(validation.$errors)"
                @keyup.prevent.enter="emit('add')"
                @keydown.backspace="removeOnBackspace"
                @update:modelValue="emit('update:modelValue', $event)" :maxlength="80"></vx-input>
        </div>
        <vx-button @click.prevent="emit('remove')" data-test="remove-choice" color="muted">
            <font-awesome-icon :icon="faTrashCan" />
        </vx-button>
    </div>
</template>
<script setup>
import { faTrashCan, faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxInput, VxButton } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { required, maxLength, helpers} from '@vuelidate/validators';
import { computed, ref } from 'vue';
import { displayFirstError } from '~/utils/validation';

const props = defineProps({
    modelValue: {
        type: String,
        default: '',
    },
    list: {
        type: Array,
        default: () => ([]),
    }
});

const emit = defineEmits(['update:modelValue' ,'remove']);

const validation = useVuelidate({
    required,
    maxLength: maxLength(80),
    format: helpers.withMessage('You can use only alphanumeric letters, space and the following symbols: _-', helpers.regex(/^[a-zA-Z0-9][a-zA-Z0-9 _-]{0,79}$/)),
    distinct: helpers.withMessage(({ $model }) => `Choice ${$model} has already been added`, (value) => props.list.filter((choice) => choice.value === value).length < 2),
}, computed(() => props.modelValue))

const inputRef = ref();

const removeOnBackspace = (event) => {
    if(!props.modelValue.length) {
        event.preventDefault();
        emit('remove')
    }
}

defineExpose({
    focus: () => inputRef.value?.field?.focus?.(),
});
</script>
