<template>
    <div class="vx-bg-slate-50 vx-rounded-2xl vx-shadow-inner vx-p-4 lg:vx-px-6">
        <div class="vx-flex vx-flex-col vx-gap-2 lg:vx-flex-row lg:vx-justify-between lg:vx-items-center">
            <div class="vx-text-sm">
                <slot name="label"></slot>
            </div>
            <div>
                <vx-toggle v-model="model"></vx-toggle>
            </div>
        </div>
        <div v-if="props.open"
            class="vx-border-t vx-border-solid vx-border-x-0 vx-border-b-0 vx-border-slate-300 vx-pt-4 vx-mt-4">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { VxToggle } from '@voxie/frontend-components';
const model = defineModel({
    type: Boolean,
    default: false,
});
const props = defineProps({ open: Boolean })

</script>
