<template>
    <div class="vx-relative vx-mb-4 vx-flex vx-flex-col vx-gap-2">
        <vx-box v-if="isCustomAttributeKeyValue"
            class="vx-relative vx-flex vx-p-4 vx-gap-2 vx-items-center vx-flex-wrap lg:vx-flex-nowrap lg:vx-py-3 lg:vx-pl-6 lg:vx-pr-3">
            <div class="vx-w-24 vx-shrink-0">
                <and-if></and-if>
            </div>
            <div class="vx-grow vx-w-full vx-flex vx-flex-col vx-gap-2 vx-order-2 lg:vx-order-1 lg:vx-grow-0">
                <attribute-select :modelValue="filter.attribute_name"
                    @update:modelValue="selectAttribute">
                </attribute-select>
            </div>
            <remove-filter class="vx-ml-auto vx-order-1 lg:vx-order-2"></remove-filter>
        </vx-box>

        <vx-box class="vx-relative vx-flex vx-flex-wrap vx-items-start vx-gap-2 lg:vx-flex-nowrap"
            :class="{
                'vx-p-4 vx-ml-10 lg:vx-p-6 lg:vx-ml-32': isCustomAttributeKeyValue,
                'vx-p-4 lg:vx-py-3 lg:vx-pl-6 lg:vx-pr-3': !isCustomAttributeKeyValue,
            }">
            <div v-if="!isCustomAttributeKeyValue" class="vx-w-24 vx-pt-2">
                <and-if></and-if>
            </div>
            <div class="vx-flex vx-flex-col vx-gap-2 vx-grow vx-w-full vx-order-2 lg:vx-order-1 lg:vx-w-auto">
                <div v-if="!isCustomAttributeKeyValue">
                    <attribute-select :modelValue="filter.attribute_name"
                        @update:modelValue="selectAttribute">
                    </attribute-select>
                </div>
                <div v-else
                    class="vx-relative vx-text-base vx-font-extrabold vx-text-slate-800">
                    Custom Attribute
                    <div
                        class="vx-absolute vx-top-1/2 -vx-translate-y-1/2 vx-h-px vx-border-solid vx-border-y vx-border-x-0 vx-border-t-slate-300 vx-border-b-white -vx-left-[40px] vx-w-[23px] lg:-vx-left-28 lg:vx-w-[86]">
                    </div>
                </div>

                <div v-if="isCustomAttributeKeyValue">
                    <vx-selectable
                        placeholder="key"
                        data-test="custom-attribute"
                        v-model="filter.key"
                        :options="autocompleteItems"
                        @search="fetchDropdownData"
                        taggable
                        :clearable="false" />
                </div>

                <!-- v-show is used here instead of v-if because we rely on the watch state of DataTypeSelect -->
                <div v-show="dataTypesLength > 1">
                    <data-type-select :modelValue="filter.data_type"
                        @update:modelValue="selectDataType"
                        :filter="filter"
                        @lengthChanged="dataTypesLength = $event"></data-type-select>
                </div>

                <div :class="[operatorCol]">
                    <operator-select
                        :modelValue="filter.operator"
                        @update:modelValue="selectOperator"
                        :filter="filter"></operator-select>
                </div>

                <div v-if="hasValue">
                    <vx-selectable v-if="isApiCall && filter.attribute_name !== 'contacts.custom_attributes.key_value' && filter.attribute_name !== 'contacts.custom_attributes.exclude_key_value'"
                        placeholder="value"
                        v-model="filter.value"
                        :options="autocompleteItems"
                        @search="fetchDropdownData"
                        taggable
                        data-test="value-select"
                        :clearable="false" />
                    <vx-selectable v-else-if="options.length"
                        placeholder="value"
                        v-model="filter.value"
                        label="label"
                        :reduce="option => option.value"
                        :options="options"
                        data-test="value-select"
                        :clearable="false"></vx-selectable>
                    <date-picker-input v-else-if="!shouldShowPositiveInt && filter.data_type === 'date'"
                        v-model="filter.value"
                        placeholder="MM/DD/YYYY"
                        format="MM/DD/YYYY"
                        :library="true"
                        data-test="value-date-picker" />
                    <vx-input v-else-if="shouldShowPositiveInt"
                        v-model="filter.value"
                        type="number"
                        min="1"
                        max="2000"
                        step="1"
                        placeholder="value"
                        data-test="value-positive-int" />
                    <vx-input v-else
                        v-model="filter.value"
                        type="text"
                        placeholder="value"
                        data-test="value-input" />
                </div>
                <div v-if="canIgnoreYear" class="vx-bg-slate-50 vx-text-slate-800 vx-flex vx-items-center vx-justify-between vx-p-4 vx-rounded-2xl">
                    <div>Should we <strong>ignore the year?</strong></div>
                    <vx-toggle size="sm" :modelValue="ignoringYear" @update:modelValue="toggleIgnoringYear($event)"></vx-toggle>
                </div>
            </div>

            <remove-filter v-if="!isCustomAttributeKeyValue" class="vx-ml-auto vx-order-1 lg:vx-ml-0 lg:vx-order-2"></remove-filter>

        </vx-box>
    </div>
</template>

<script setup>
import { h, ref, computed, onMounted, nextTick } from 'vue'
import { customAttributesClient, tagsClient } from '../../../../../services';
import DatePickerInput from '../../../../general/DatePickerInput.vue';
import { entryPointsForSelect } from '~/components/filters';
import { phoneAreaCodes } from '~/components/automation/phone-area-codes';
import { useToasted } from '../../../../../composables/useToasted';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faShareNodes, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { VxButton, VxBox, VxInput, VxSelectable, VxToggle } from '@voxie/frontend-components';
import AttributeSelect from './AttributeSelect.vue';
import OperatorSelect from './OperatorSelect.vue';
import DataTypeSelect from './DataTypeSelect.vue';
import { OPERATORS_DATE, OPERATORS_N, OPERATORS_THAT_DONT_NEED_VALUE } from './segmentFiltersHelpers';
import debounce from '~/utils/debounce';

const filter = defineModel({type: Object, required: true});

const props = defineProps({
    isFirst: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['removeFilter'])

const AndIf = () => {
    return h('div', {}, [
        h(
            FontAwesomeIcon,
            { class: 'vx-mr-2 vx-text-sky-500', icon: faShareNodes }
        ),
        h(
            'span',
            { class: 'vx-whitespace-nowrap vx-text-slate-800 vx-text-base vx-font-extrabold' },
            [props.isFirst ? 'If' : 'And If']
        ),
    ]);
}

const RemoveFilter = (props) => {
    return h(VxButton, {
        class: `vx-w-12 ${props.class}`,
        onClick: () => emit('removeFilter'),
        type: 'button',
        size: 'md',
        color: 'muted-light',
        'data-test': 'remove-filter',
    }, () => [
        h(FontAwesomeIcon, {
            icon: faTrash
        })
    ])
}

const contact_phone_states_prefix = phoneAreaCodes.map(pac => {
    return {
        value: pac.internal,
        label: pac.state,
    }
});

const toasted = useToasted();

const teamId = Spark.state.currentTeam.id;

const autocompleteItems = ref([]);

const dataTypesLength = ref(false);

onMounted(async () => {
    await nextTick();
    if (isApiCall.value) {
        fetchDropdownData();
    }
})

function selectDataType(dataType) {
    filter.value.data_type = dataType;
    filter.value.operator = null;
    filter.value.value = null;

    if (isApiCall.value) {
        fetchDropdownData();
    }
}

function selectOperator(operator) {
    filter.value.operator = operator;

    if (!hasValue.value) {
        filter.value.value = null;
    }
}

function selectAttribute(attr) {
    filter.value.attribute_name = attr;

    // unsets prefilled data
    filter.value.data_type = null;
    filter.value.operator = null;
    filter.value.value = null;
    filter.value.key = null;

    autocompleteItems.value = [];

    if (isApiCall.value) {
        fetchDropdownData();
    }
}

const fetchDropdownData = debounce(async (query = null) => {
    const params = query ? { query } : null;

    try {
        let response;

        switch (filter.value.attribute_name) {
            case 'contacts.tags.tag_name':
            case 'contacts.tags.exclude_tags':
                response = await tagsClient.tagsAutocomplete(teamId, params)
                if (response.data.data.length > 0) {
                    autocompleteItems.value = response.data.data.map((e) => e.value.toString());
                }

                break;
            case 'contacts.custom_attributes.key':
            case 'contacts.custom_attributes.key_value':
            case 'contacts.custom_attributes.exclude_key_value':
            case 'contacts.custom_attributes.exclude':
                response = await customAttributesClient.customAttributesAutocomplete(teamId, params)
                if (response.data.data.length > 0) {
                    autocompleteItems.value = response.data.data;
                }

                break;
        }
    } catch (e) {
        console.error(e);

        toasted.global.platform_error();
    }
}, 300)

const hasValue = computed(() => {
    return !OPERATORS_THAT_DONT_NEED_VALUE.includes(filter.value.operator);
})

const shouldShowPositiveInt = computed(() => {
    return filter.value.data_type == 'date' && OPERATORS_N.includes(filter.value.operator);
})

const isCustomAttributeKeyValue = computed(() => {
    return filter.value.attribute_name === 'contacts.custom_attributes.key_value'
        || filter.value.attribute_name === 'contacts.custom_attributes.exclude_key_value';
})

const isApiCall = computed(() => {
    const usesApi = [
        'contacts.tags.tag_name',
        'contacts.custom_attributes.key',
        'contacts.custom_attributes.exclude',
        'contacts.tags.exclude_tags',
    ];

    return usesApi.includes(filter.value.attribute_name) && (!filter.value.data_type || filter.value.data_type === 'text');
});

/**
 * @returns {{label: string, value: string}[]}
 */
const options = computed(() => {
    switch (filter.value.attribute_name) {
        case 'contacts.entry_point':
            return entryPointsForSelect();
        case 'contacts.phone_state':
            return contact_phone_states_prefix;
        default:
            return [];
    }
})

const operatorCol = computed(() => {
    return dataTypesLength.value > 1 ? 'vx-col-span-3' : 'vx-col-span-6';
})

const canIgnoreYear = computed(() => {
    return Boolean(filter.value.data_type === 'date' &&
        OPERATORS_DATE.find(operator => operator.value === filter.value.operator?.slice(0, -14) || operator.value === filter.value.operator)?.igoring_year);
});

const ignoringYear = computed(() => Boolean(filter.value.operator?.endsWith('_ignoring_year')));

const toggleIgnoringYear = (ignoresYear) => {
    if (ignoresYear) {
        filter.value.operator = `${filter.value.operator}_ignoring_year`;
    } else {
        filter.value.operator = filter.value.operator?.endsWith('_ignoring_year') ? filter.value.operator.slice(0, -14) : filter.value.operator;
    }
}
</script>
