<template>
    <div>
        <promotion-coupons-import-file :promotion="props.promotion"/>
    </div>
</template>

<script setup>
import PromotionCouponsImportFile from './CouponsImport/PromotionCouponsImportFile.vue';
const props = defineProps({
    promotion: {
        type: Object,
        required: true,
    },
});
</script>

<style lang="postcss" scoped>
.coupons-import__form__source {
    padding-left: 47px;
}

.coupons-import__form__source__label-wrapper {
    display: flex;
    align-items: center;
    height: 63px;
}

.coupons-import__form__source__label-wrapper>label {
    font-size: 17px;
}

.coupons-import-form__file-source-wrapper {
    margin-right: 47px;
}
</style>
