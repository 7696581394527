<template>
    <div class="threads-inboxes-v2">
        <vx-tabs :tabs="tabs" :selected="selected" :size="12" @select-tab="onSelectTab"></vx-tabs>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { VxTabs } from '@voxie/frontend-components-v3';

const emit = defineEmits(['setActiveThreadsInbox']);

const store = useStore();

const threadsInboxes = computed(() => store.getters.getThreadInboxes);
const activeThreadsInbox = computed(() => store.state.threads.activeThreadsInbox);

const tabs = computed(() => Object.entries(threadsInboxes.value).map(([key, value]) => ({ title: key, badge: value })));
const selected = computed(() => tabs.value.findIndex((tab) => tab.title === activeThreadsInbox.value));

const onSelectTab = (selectedTabIndex) => {
    const key = tabs.value[selectedTabIndex].title;

    store.commit('setActiveThreadsInbox', key);

    emit('setActiveThreadsInbox', key);
};
</script>

<style lang="postcss">
.threads-inboxes-v2 {
  border-bottom: 1px solid #f0f2f5;
  padding: 0.375rem 0.5rem 0 0.5rem;
}
.threads-inboxes-v2 .vx-tab {
  line-height: 1rem;
}
.threads-inboxes-v2 .vx-tab-badge {
  font-size: 0.75rem;
  line-height: 1rem;
}

@media (max-width: 1360px) {
  .threads-inboxes-v2 .vx-tab {
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>
