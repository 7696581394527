<template>
    <div class="card card-default">
        <div class="card-header">
            <div class="float-left">
                {{ 'Update Subscription' }}
            </div>

            <!-- Interval Selector Button Group -->
            <div class="float-right">
                <div class="btn-group btn-group-sm" v-if="hasMonthlyAndYearlyPlans">
                    <!-- Monthly Plans -->
                    <button
                        type="button"
                        class="btn btn-light"
                        @click="showMonthlyPlans"
                        :class="{'active': showingMonthlyPlans}"
                    >
                        Monthly
                    </button>

                    <!-- Yearly Plans -->
                    <button
                        type="button"
                        class="btn btn-light"
                        @click="showYearlyPlans"
                        :class="{'active': showingYearlyPlans}"
                    >
                        Yearly
                    </button>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>

        <div class="table-responsive">
            <!-- Plan Error Message - In General Will Never Be Shown -->
            <div class="alert alert-danger m-4" v-if="planForm.errors.has('plan')">
                {{ planForm.errors.get('plan') }}
            </div>

            <!-- Current Subscription (Active) -->
            <div class="m-4" v-if="activePlan && activePlan.active">
                You are currently subscribed to the {{ activePlan.name }} ({{ capitalize(activePlan.interval) }}) plan.
            </div>

            <!-- Current Subscription (Archived) -->
            <div class="alert alert-warning m-4" v-if="activePlan && !activePlan.active">
                You are currently subscribed to the {{ activePlan.name }} ({{ capitalize(activePlan.interval) }}) plan.
                This plan has been discontinued, but you may continue your subscription to this plan as long as you wish. If you cancel your subscription and later want to begin a new subscription, please contact <a href="mailto:support@voxie.com">support@voxie.com</a> for assistance.
            </div>

            <table class="table table-responsive-sm table-valign-middle mb-0 ">
                <thead></thead>
                <tbody>
                    <tr v-for="(plan, index) in plansForActiveInterval" :key="index">
                        <!-- Plan Name -->
                        <td>
                            <div class="d-flex align-items-center">
                                <i class="radio-select mr-2" @click="!isActivePlan(plan) ? confirmPlanUpdate(plan) : 0"
                                :class="{'radio-select-selected': isActivePlan(plan), invisible: selectingPlan}"></i>
                                {{ plan.name }}
                            </div>
                        </td>

                        <!-- Plan Features Button -->
                        <td>
                            <button class="btn btn-default" @click="showPlanDetails(plan)">
                                <i class="fa fa-btn fa-star-o"></i> Features
                            </button>
                        </td>

                        <!-- Plan Price -->
                        <td>
                            <div>
                                <span v-if="plan.price == 0">
                                    Free
                                </span>

                                <span v-else>
                                    <strong class="table-plan-price">{{ currency(priceWithTax(plan)) }}</strong>
                                    {{ plan.type == 'team' && chargesTeamsPerSeat ? '/ '+ teamSeatName : '' }}
                                    {{ plan.type == 'team' && chargesTeamsPerMember ? '/ '+ teams.member : '' }}
                                    / {{ capitalize(plan.interval) }}
                                </span>
                            </div>
                        </td>

                        <!-- Plan Select Button -->
                        <td class="text-right">
                            <span v-if="selectingPlan && selectingPlan === plan">
                                <i class="fa fa-btn fa-spinner fa-spin"></i> Updating
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- Confirm Plan Update Modal -->
    <div class="modal" id="modal-confirm-plan-update" tabindex="-2" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content" v-if="confirmingPlan">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h5 class="modal-title">
                        Update Subscription
                    </h5>
                </div>

                <!-- Modal Body -->
                <div class="modal-body">
                    <p>
                        Are you sure you want to switch to the <strong>{{ confirmingPlan.name }} ({{ capitalize(confirmingPlan.interval) }})</strong> plan?

                        <span v-if="(new Date(team.trial_ends_at)) > (new Date((new Date()).getUTCFullYear(), (new Date()).getUTCMonth(), (new Date()).getUTCDate(),  (new Date()).getUTCHours(), (new Date()).getUTCMinutes(), (new Date()).getUTCSeconds()))">
                            Your trial period will end, you will be charged <strong>${{ confirmingPlan.price }}</strong> and be switched to this plan immediately.
                        </span>

                        <span v-if="(new Date(team.trial_ends_at)) <= (new Date((new Date()).getUTCFullYear(), (new Date()).getUTCMonth(), (new Date()).getUTCDate(),  (new Date()).getUTCHours(), (new Date()).getUTCMinutes(), (new Date()).getUTCSeconds()))">
                            You will be charged <strong>${{ confirmingPlan.price }}</strong> and be switched to this plan immediately.
                        </span>
                    </p>
                </div>

                <!-- Modal Actions -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">No, Go Back</button>

                    <button type="button" class="btn btn-primary" @click="approvePlanUpdate">Yes, I'm Sure</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { currency, capitalize } from '../../../utils/helpers';
import axios from '~/services/axios';

export default {
    props: [
        'team',
        'teams',
        'plans',
        'activeSubscription',
        'showingMonthlyPlans',
        'showingYearlyPlans',
    ],
    data() {
        return {
            planForm: new SparkForm({}),
            confirmingPlan: null,
            selectingPlan: null,
        };
    },
    created() {
        Bus.$on(`sparkFormUpdated.${this.planForm.sparkFormId}`, this.$forceUpdate);
    },
    mounted() {
        this.selectActivePlanInterval();

         // We need to watch the activePlan computed property for changes so we can select
         // the proper active plan on the plan interval button group. So, we will watch
         // this property and fire off a method anytime it changes so it can sync up.
        this.$watch('activePlan', () => {
            this.selectActivePlanInterval();
        });
    },
    methods: {
        currency,
        capitalize,
        confirmPlanUpdate(plan) {
            this.confirmingPlan = plan;

            $('#modal-confirm-plan-update').modal('show');
        },
        approvePlanUpdate() {
            $('#modal-confirm-plan-update').modal('hide');

            this.updateSubscription(this.confirmingPlan);
        },
        updateSubscription(plan) {
            this.selectingPlan = plan;

            this.planForm.errors.forget();

             // Here we will send the request to the server to update the subscription plan and
             // update the user and team once the request is complete. This method gets used
             // for both updating subscriptions plus resuming any cancelled subscriptions.
            axios.put(this.urlForPlanUpdate, {"plan": plan.id})
                .then(() => {
                    Bus.$emit('updateTeam');
                })
                .catch(errors => {
                    if (errors.response.status == 400) {
                        window.location = '/' + Spark.cashierPath + '/payment/' + errors.response.data.paymentId + '?redirect=' + this.urlForPlanRedirect;
                    } else if (errors.response.status === 422) {
                        this.planForm.setErrors(errors.response.data.errors);
                    } else {
                        this.planForm.setErrors({plan: [ "We were unable to update your subscription. Please contact customer support." ]});
                    }
                })
                .finally(() => {
                    this.selectingPlan = null;
                });
        },
        showPlanDetails(plan) {
            Bus.$emit('showPlanDetails', plan);
        },
        selectActivePlanInterval() {
            if (this.activePlanIsMonthly || this.yearlyPlans.length === 0) {
                this.showMonthlyPlans();
            } else {
                this.showYearlyPlans();
            }
        },
        showMonthlyPlans() {
            this.$emit('showMonthlyPlans');
        },
        showYearlyPlans() {
            this.$emit('showYearlyPlans');
        },
        isActivePlan(plan) {
            return this.activeSubscription &&
                this.activeSubscription.provider_plan === plan.id;
        },
        priceWithTax(plan) {
            return plan.price + (plan.price * (this.team.tax_rate / 100));
        },
    },
    computed: {
        activePlanIsMonthly() {
            return this.activePlan && this.activePlan.interval === 'monthly';
        },
        urlForPlanUpdate() {
            return `/settings/teams/${this.team.id}/subscription`;
        },
        urlForPlanRedirect() {
            return `/settings/teams/${this.team.id}%23/subscription`;
        },
        activePlan() {
            return this.plans?.find((plan) => {
                return plan.id === this.activeSubscription?.provider_plan;
            });
        },
        activeInterval() {
            return this.showingMonthlyPlans ? 'monthly' : 'yearly';
        },
        plansForActiveInterval() {
            return this.plans?.filter((plan) => {
                return plan.active && (plan.price === 0 || plan.interval === this.activeInterval);
            }) || [];
        },
        monthlyPlans() {
            return this.plans?.filter((plan) => {
                return plan.active && plan.interval === 'monthly';
            }) || [];
        },
        yearlyPlans() {
            return this.plans?.filter((plan) => {
                return plan.active && plan.interval === 'yearly';
            }) || [];
        },
        hasMonthlyAndYearlyPlans() {
            return this.monthlyPlans.length > 0 && this.yearlyPlans.length > 0;
        },
        chargesUsersPerSeat() {
            return Spark.chargesUsersPerSeat;
        },
        chargesTeamsPerSeat() {
            return Spark.chargesTeamsPerSeat;
        },
        chargesTeamsPerMember() {
            return Spark.chargesTeamsPerMember;
        },
        seatName() {
            return Spark.seatName;
        },
        teamSeatName() {
            return Spark.teamSeatName;
        }
    },
};
</script>
