<template>
   <div class="contact-search mb-3" v-if="contacts.length || showsCreateItem">

        <div v-if="!contacts.length && showsCreateItem"
            class="contact-search__item"
            @click.prevent="createContact()"
        >
            {{ phoneNumberNational(search) }}
        </div>
        <div class="contact-search__item"
            v-for="contact in contacts"
            :key="contact.id"
            @click.prevent="selectContact(contact)"
        >
            {{ contactLabel(contact) }}
        </div>
    </div>
</template>

<script setup>
import debounce from '~/utils/debounce';
import contactsClient from '../../services/contactsClient';

import { computed, ref, watch } from 'vue';
import { phoneNumberNational } from '~/components/filters';
import libphonenumber from 'google-libphonenumber';
import { isLargeTeam } from '~/utils/team';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

const props = defineProps({
    search: String,
    canCreate: {
        type: Boolean,
        default: false,
    }
})

const emit = defineEmits(['selected', 'create']);

const contactLabel = (contact) => {
    const name = ((contact.first_name || '') + ' ' + (contact.last_name || '')).trim();

    if (name) {
        return `${name} - ${phoneNumberNational(contact.phone)}`;
    }

    return phoneNumberNational(contact.phone);
}

const contacts = ref([]);
const loading = ref(false);

const selectContact = (contact) => {
    emit('selected', contact);
    contacts.value = [];
}
const createContact = () => {
    emit('create', props.search);
    contacts.value = [];
}

const showsCreateItem = computed(() => props.canCreate && !loading.value && isValidPhoneNumber(props.search))

const runSearch = (search) => {
    loading.value = true;

    const teamId = Spark.state.currentTeam.id;

    const params = {
        per_page: 5
    };

    if (isLargeTeam()) {
        params['filter[phone]'] = search.trim()
    } else {
        params['filter[search]'] = search.trim()
    }

    contactsClient.searchContacts(teamId, params)
        .then(response => {
            if (response.data.data) {
               contacts.value = response.data.data;
            }
        })
        .finally(() => {
            loading.value = false;
        });
};

const debouncedSearch = debounce(runSearch, 350);

const isValidPhoneNumber = (phone) => {
    try {
        return Boolean(phoneUtil.isValidNumber(
            phoneUtil.parse(phone, 'US'),
        ));
    } catch (err) {
        return false;
    }
};

watch(
    () => props.search,
    search => {
        if(search?.trim().length) {
            debouncedSearch(search);
        }
    },
)
</script>

<style lang="postcss" scoped>
.contact-search {
    max-height: 150px;
    overflow: auto;
}

.contact-search__item {
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--color-grey-50);
    cursor: pointer;
}

.contact-search__item:hover {
    background: var(--color-primary-200);
}
</style>
