<template>
    <vx-table-row>
        <vx-table-row-title class="xl:!vx-col-span-2">
            {{ fullName }}
        </vx-table-row-title>

        <vx-table-row-item>
            <template v-slot:label> Phone: </template>
            {{ phoneNumberNational(props.modelValue.phone) }}
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Marketing: </template>
            <vx-icon-loading v-if="loadingSubscriptions"
                spin></vx-icon-loading>

            <contact-subscription-badge v-else
                type="marketing"
                :status="marketingStatus"></contact-subscription-badge>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Transactional: </template>
            <vx-icon-loading v-if="loadingSubscriptions"
                spin></vx-icon-loading>
            <contact-subscription-badge v-else
                type="transactional"
                :status="transactionalStatus"></contact-subscription-badge>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Added: </template>

            <vx-popover hover
                :arrow="true"
                placement="top"
                :key="createdAtKey">
                {{ fromNow(props.modelValue.created_at) }}
                <template v-slot:content>
                    {{ shortDateTime(props.modelValue.created_at) }}
                </template>
            </vx-popover>
        </vx-table-row-item>

        <vx-table-row-buttons>
            <vx-dropdown placement="right">
                <vx-button data-test="rule-dropdown"
                    type="button"
                    color="muted"
                    outline
                    class="vx-w-[45px]">
                    <font-awesome-icon :icon="faEllipsis" />
                </vx-button>

                <template v-slot:items>
                    <vx-dropdown-item data-test="view"
                        :href="`/contacts/${props.modelValue.id}/view`">
                        View
                        <font-awesome-icon :icon="faEye"></font-awesome-icon>
                    </vx-dropdown-item>
                    <vx-dropdown-item v-if="marketingStatus !== 'opt_out'"
                        data-test="unsubscribe"
                        @click="unsubscribeContact()">
                        Unsubscribe
                        <font-awesome-icon :icon="faHandWave"></font-awesome-icon>
                    </vx-dropdown-item>
                    <vx-dropdown-item v-if="marketingStatus !== 'opt_in'"
                        data-test="subscribe"
                        @click="subscribeContact()">
                        Subscribe
                        <font-awesome-icon :icon="faThumbsUp"></font-awesome-icon>
                    </vx-dropdown-item>
                    <vx-dropdown-item data-test="delete"
                        @click="deleteContact()">
                        Delete
                        <font-awesome-icon :icon="faTrash"></font-awesome-icon>
                    </vx-dropdown-item>
                </template>
            </vx-dropdown>

            <vx-button :href="`/contacts/${props.modelValue.id}`"
                data-test="edit"
                color="muted"
                outline>
                Edit <font-awesome-icon :icon="faFilePen" />
            </vx-button>
        </vx-table-row-buttons>

    </vx-table-row>
</template>

<script setup>
import {
    faEllipsis,
    faFilePen,
    faTrash,
    faEye,
    faHandWave,
    faThumbsUp,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxButton,
    VxDropdown,
    VxDropdownItem,
    VxPopover,
    VxTableRow,
    VxTableRowButtons,
    VxTableRowItem,
    VxTableRowTitle,
    VxIconLoading
} from "@voxie/frontend-components";
import { useInterval } from '@vueuse/core';
import { useToasted } from '~/composables/useToasted';
import contactsClient from '~/services/contactsClient';
import { HTTP_INTERNAL_SERVER_ERROR } from "~/support/HttpStatuses";
import { fromNow, shortDateTime } from '~/utils/date';
import { computed, ref } from 'vue';
import { phoneNumberNational } from '~/components/filters';
import ContactSubscriptionBadge from './ContactSubscriptionBadge.vue';

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['deleted', 'edit']);

const deleteContact = async () => {
    if (!window.confirm('Are you sure? There is no undo.')) return;

    try {
        await contactsClient.contactDelete(teamId, props.modelValue.id);
        emit('deleted', props.modelValue.id);
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
        } else {
            toasted.error(e.response?.data?.message || 'Something went wrong.');
        }
    }
};

const createdAtKey = useInterval(60000);

const fullName = computed(() => {
    const fullName = `${props.modelValue.first_name || ''} ${props.modelValue.last_name || ''}`.trim();
    return fullName || '-';
})

const loadingSubscriptions = ref(true);
const subscriptions = ref([]);

const getSubscriptions = () => {
    contactsClient
        .getContactSubscriptionStatus(teamId, props.modelValue.id)
        .then(({ data }) => {
            subscriptions.value = data.data;
        })
        .finally(() => {
            loadingSubscriptions.value = false;
        });
}

getSubscriptions();

const marketingStatus = computed(() => {
    return subscriptions.value.find(subscription => subscription.type === 'marketing')?.status;
})
const transactionalStatus = computed(() => {
    return subscriptions.value.find(subscription => subscription.type === 'transactional')?.status;
})

const subscribeContact = () => {
    if (loadingSubscriptions.value) {
        return;
    }
    loadingSubscriptions.value = true;

    contactsClient
        .contactSubscriptionOptIn(teamId, props.modelValue.id, 'marketing')
        .finally(() => {
            getSubscriptions();
            loadingSubscriptions.value = false;
        });
}

const unsubscribeContact = () => {
    if (loadingSubscriptions.value) {
        return;
    }
    loadingSubscriptions.value = true;

    contactsClient
        .contactSubscriptionOptOut(teamId, props.modelValue.id, 'marketing')
        .finally(() => {
            getSubscriptions();
            loadingSubscriptions.value = false;
        });
}
</script>
