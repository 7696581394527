<template>
    <div class="modal fade" id="modal-plan-details" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content" v-if="detailingPlan">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ detailingPlan.name }}
                    </h5>
                </div>

                <!-- Modal Body -->
                <div class="modal-body pl-0 pr-0">
                    <ul class="plan-feature-list p-0 m-0 row">
                        <li v-for="(feature, index) in detailingPlan.features" class="col-md-6" :key="index">
                            <i class="fa fa-check-circle-o fa-xs u-mr-xsmall"></i>
                            {{ feature }}
                        </li>
                    </ul>
                </div>

                <!-- Modal Actions -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'detailingPlan' ],
};
</script>
