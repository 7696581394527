<template>
    <create-team
        v-if="createsAdditionalTeams"
    />

    <pending-invitations />

    <current-teams
        v-if="user && teams.length > 0"
        :user="user"
        :teams="teams"
    />
</template>

<script>

import CreateTeam from './CreateTeam.vue';
import PendingInvitations from './PendingInvitations.vue';
import CurrentTeams from './CurrentTeams.vue';

export default {
    props: ['user', 'teams'],

    components: {
        CreateTeam,
        PendingInvitations,
        CurrentTeams
    },

    computed: {
        createsAdditionalTeams() {
            return Spark.createsAdditionalTeams;
        },
    },
}
</script>
