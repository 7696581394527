import { faCircle, faMessage, faReceipt, faShoppingCart, faUser } from '@fortawesome/pro-solid-svg-icons';
import { createGlobalState } from '@vueuse/core';
import { shallowRef } from 'vue';
import { useToasted } from '~/composables/useToasted';
import { automationRulesClient } from '~/services';

export const useAutomationRulesMetadata = createGlobalState(() => {
    const teamId = Spark.state.currentTeam.id;

    const toasted = useToasted();

    const triggers = shallowRef([]);
    const actions = shallowRef([]);

    const mount = async () => {
        try {
            const metadataResponse = await automationRulesClient.getAutomationRulesMetadata(teamId);
            actions.value = metadataResponse.data.actions;
            triggers.value = metadataResponse.data.rules.map((rule) => {
                const appearance = {
                    'campaign.contact.filtered': {
                        short_label: 'Campaign Filtered',
                        color: 'amber',
                        icon: faUser,
                    },
                    'contact.campaign.completed': {
                        short_label: 'Campaign Completed',
                        color: 'primary',
                        icon: faUser,
                    },
                    'contact.created': {
                        short_label: 'Created',
                        color: 'primary',
                        icon: faUser,
                    },
                    'contact.custom_attribute.created': {
                        short_label: 'Custom Attr Created',
                        color: 'primary',
                        icon: faUser,
                    },
                    'contact.custom_attribute.updated': {
                        short_label: 'Custom Attr Updated',
                        color: 'amber',
                        icon: faUser,
                    },
                    'contact.group.added': {
                        short_label: 'Group Added',
                        color: 'primary',
                        icon: faUser,
                    },
                    'contact.marketing_subscription_opt_in': {
                        short_label: 'Marketing Opt-In',
                        color: 'primary',
                        icon: faUser,
                    },
                    'contact.tag.added': {
                        short_label: 'Tag Added',
                        color: 'primary',
                        icon: faUser,
                    },
                    'contact.transactional_subscription_opt_in': {
                        short_label: 'Transactional Opt-In',
                        color: 'primary',
                        icon: faUser,
                    },
                    'conversion.created': {
                        short_label: 'Created',
                        color: 'primary',
                        icon: faReceipt,
                    },

                    'contact.group.removed': {
                        short_label: 'Group Removed',
                        color: 'danger',
                        icon: faUser,
                    },
                    'contact.marketing_subscription_opt_out': {
                        short_label: 'Marketing Opt-Out',
                        color: 'danger',
                        icon: faUser,
                    },
                    'contact.tag.removed': {
                        short_label: 'Tag Removed',
                        color: 'danger',
                        icon: faUser,
                    },
                    'contact.transactional_subscription_opt_out': {
                        short_label: 'Transactional Opt-Out',
                        color: 'danger',
                        icon: faUser,
                    },
                    'message.created': {
                        short_label: 'Created (deprecated)',
                        color: 'primary',
                        icon: faMessage,
                    },
                    'message.dyn_field.extract_failure': {
                        short_label: 'Extraction Failure',
                        color: 'danger',
                        icon: faMessage,
                    },
                    'message.dyn_field.extract_success': {
                        short_label: 'Extraction Success',
                        color: 'primary',
                        icon: faMessage,
                    },
                    'message.inbound': {
                        short_label: 'Inbound',
                        color: 'violet',
                        icon: faMessage,
                    },
                    'message.outbound': {
                        short_label: 'Outbound',
                        color: 'violet',
                        icon: faMessage,
                    },
                    'message.short_url.clicked': {
                        short_label: 'Short Url Click',
                        color: 'violet',
                        icon: faMessage,
                    },
                    'shopify_order.order_placed': {
                        short_label: 'Order Placed',
                        color: 'primary',
                        icon: faShoppingCart,
                    },
                    'shopify_checkout.checkout_abandoned': {
                        short_label: 'Checkout Abandoned',
                        color: 'danger',
                        icon: faShoppingCart,
                    },
                };

                return {
                    ...rule,
                    ...(appearance[rule.value] || {
                        color: 'slate',
                        icon: faCircle,
                    }),
                };
            });
        } catch (e) {
            console.error(e);
            toasted.global.platform_error();
        }
    };

    mount();

    return { triggers, actions };
});
