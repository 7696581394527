
<template>
    <div
        class="vx-mt-6 vx-inline-flex vx-w-full vx-flex-col vx-items-start vx-justify-start vx-gap-6 vx-rounded-2xl vx-bg-white vx-p-6">
        <div class="vx-w-full vx-cursor-pointer vx-gap-2 md:vx-flex md:vx-items-center md:vx-justify-between">
            <div class="vx-flex vx-flex-col vx-items-start md:vx-gap-2 md:vx-flex-row md:vx-items-center">
                <vx-popover :hover="true"
                    :arrow="true"
                    placement="top">
                    <div class="vx-max-w-sm">
                        Time-zone aware start
                        <font-awesome-icon :icon="faCircleInfo"
                            size="sm" />
                    </div>

                    <template v-slot:content>
                        <div class="vx-max-w-sm">
                            <p v-if="!props.timeZoneAware"
                                class="vx-text-sm vx-text-slate-600">
                                This campaign will start based on your
                                <strong class="vx-font-semibold">local time-zone</strong>.
                                That means some contacts could receive the first message
                                at different time of day.
                            </p>

                            <div v-if="props.timeZoneAware"
                                class="vx-space-y-6">
                                <div class="vx-space-y-1">
                                    <h3 class="vx-font-extrabold">Time-zone Aware Start</h3>

                                    <p class="vx-text-sm">
                                        Campaign will start at
                                        {{ selectedDateTimeNewYork.format('h:mm a z') }}.
                                        The first message will be delayed to match
                                        {{ selectedDateTimeNewYork.format('h:mm a') }} for the
                                        <strong class="vx-font-semibold">contact’s time-zone</strong>
                                        if possible.
                                        <br /> <br />
                                        Contacts must have an assigned time-zone or Guess Contact Time-Zone must be enabled in team settings.
                                    </p>
                                </div>
                                <div class="vx-flex vx-gap-2">
                                    <vx-button color="muted"
                                        size="xs"
                                        target="_blank"
                                        :href="'/settings/teams/' + teamId + '#/owner'">
                                        Configure Defaults
                                        <font-awesome-icon :icon="faGear"></font-awesome-icon>
                                    </vx-button>

                                    <vx-button color="muted"
                                        size="xs"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://support.voxie.com">
                                        Documentation
                                        <font-awesome-icon :icon="faBook"></font-awesome-icon>
                                    </vx-button>
                                </div>
                            </div>
                        </div>
                    </template>
                </vx-popover>
            </div>

            <vx-toggle size="sm" class="vx-mt-2 md:vx-mt-0" :disabled="props.disabled"
                :modelValue="props.timeZoneAware"
                @update:modelValue="emit('update:timeZoneAware', $event)" />
        </div>

        <div class="vx-flex vx-w-full vx-flex-col vx-items-center">
            <div
                class="vx-relative vx-z-10 vx-mx-auto vx-inline-flex vx-transform-gpu vx-justify-center vx-gap-1.5 vx-rounded-full vx-border vx-border-sky-300 vx-border-solid vx-bg-sky-100 vx-py-1.5 vx-pl-2 vx-pr-3">
                <div class="vx-flex vx-items-center vx-justify-center">
                    <font-awesome-icon :icon="faClock"
                        size="xs"
                        class="vx-text-sky-700" />
                </div>

                <div class="vx-leading-none vx-text-sky-900">
                    {{
                        dayjs(selectedDateTime)
                            .tz(getClientTz())
                            .format('h:mm a')
                    }}
                    &rarr; local time
                </div>
            </div>

            <div
                class="vx-ml-0.5 vx-h-4 vx-border-l-2 vx-border-sky-700 vx-border-solid vx-border-r-0 vx-border-y-0 vx-transition vx-delay-300 vx-duration-300 vx-will-change-transform">
            </div>
            <figure class="vx-relative vx-z-0 vx-w-full vx-space-y-4">
                <div class="vx-space-y-1">
                    <div v-for="(zone, index) in displayZones(selectedDateTime)"
                        :key="zone.value"
                        :style="{
                            'transition-delay': `${(displayZones.length - index) * 0.03}s`,
                        }"
                        class="vx-relative vx-inline-flex vx-w-full vx-items-center vx-justify-start vx-gap-2 vx-overflow-clip vx-rounded-lg vx-border vx-border-solid vx-border-sky-300 vx-bg-white vx-px-4 vx-py-3">
                        <div class="vx-z-20 vx-flex vx-items-center vx-gap-1 vx-text-xs vx-text-sky-800"
                            :class="dayjs(selectedDateTime).tz(zone.zone).format('z') ===
                                dayjs(selectedDateTime)
                                    .tz(getClientTz())
                                    .format('z')
                                ? 'vx-font-semibold'
                                : ''
                                "
                            :title="dayjs(selectedDateTime).tz(zone.zone).format('zzz')
                                ">
                            <font-awesome-icon class="vx-text-sky-700"
                                :icon="faClock"
                                size="xs"
                                v-if="dayjs(selectedDateTime)
                                    .tz(zone.zone)
                                    .format('z') ===
                                    dayjs(selectedDateTime)
                                        .tz(getClientTz())
                                        .format('z')
                                    " />
                            {{ zone.label }} ({{ zone.value }})
                        </div>

                        <div class="vx-background-repeat safe-sending-background vx-absolute vx-bottom-0 vx-left-0 vx-top-0 vx-z-0 vx-w-[200%] vx-bg-blend-screen"
                            :style="{
                                backgroundSize: '50%, 6px 6px',
                                backgroundPositionX: `${backgroundOffset(selectedDateTime, zone.utcOffset, zone.zone, props.timeZoneAware)}%`,
                            }"></div>

                        <div class="vx-absolute vx-bottom-0 vx-top-0 vx-z-20 -vx-ml-4 vx-inline-flex vx-w-full vx-items-center vx-gap-2 vx-border-sky-700 vx-px-2 vx-text-sky-700 vx-transition vx-duration-[.4s] vx-ease-[cubic-bezier(0.35,1.3,0.5,1)] vx-border-r-0 vx-border-y-0"
                            :class="props.timeZoneAware &&
                                timeHasPassed(
                                    selectedDateTime,
                                    zone.zone,
                                ) &&
                                dayjs(selectedDateTime).tz(zone.zone).format('z') !== dayjs(selectedDateTime).tz(getClientTz()).format('z')
                                ? 'vx-border-l-2 vx-border-dashed vx-opacity-50'
                                : 'vx-border-l-2 vx-border-solid'
                                "
                            :style="{
                                transform: props.timeZoneAware
                                    ? `translateX(${markerOffset(selectedDateTime, zone.utcOffset, zone.zone, props.timeZoneAware)}%)`
                                    : 'translateX(50%)',
                            }">
                            <template v-if="props.timeZoneAware">
                                <template
                                    v-if="timeHasPassed(selectedDateTime, zone.zone)">
                                    Now
                                    <font-awesome-icon :icon="faArrowRightLongToLine"
                                        size="xs" />
                                </template>
                                <template v-else>
                                    {{ dayjs(selectedDateTime).tz(getClientTz()).format('h:mm a') }}
                                </template>
                            </template>
                            <template v-else>
                                {{ dayjs(selectedDateTime).tz(zone.zone).format('h:mm a') }}
                            </template>
                        </div>
                    </div>
                </div>

                <figcaption class="vx-text-xs vx-text-slate-600">
                    <dl class="vx-grid vx-grid-cols-[16px_minmax(0,_1fr)] vx-gap-y-2">
                        <dt class="vx-border-l-2 vx-border-sky-700 vx-border-r-0 vx-border-y-0 vx-border-solid" />
                        <dd>Campaign start time</dd>

                        <dt
                            class="vx-border-l-2 vx-border-dashed vx-border-sky-700 vx-opacity-50 vx-border-r-0 vx-border-y-0" />
                        <dd>Time passed, immediate start</dd>

                        <dt class="safe-sending-key vx-bg[left:6px_6px] vx-background-repeat vx-h-full vx-w-2" />
                        <dd>Outside safe sending hours</dd>
                    </dl>
                </figcaption>
            </figure>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faClock,
    faArrowRightLongToLine,
    faGear,
    faBook,
} from '@fortawesome/pro-solid-svg-icons'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import {
    VxButton,
    VxPopover,
    VxToggle
} from '@voxie/frontend-components';
import dayjs from '~/utils/dayjs'
import { backgroundOffset, markerOffset, timeHasPassed, displayZones } from './timeZoneUtils'
import { getClientTz } from '~/utils/date'

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    timeZoneAware: {
        type: Boolean,
        default: false,
    },
    liveAt: {
        type: Date,
    }
});

const selectedDateTime = computed(() => props.liveAt || new Date());
const selectedDateTimeNewYork = computed(() => {
    const selectedDayjs = dayjs.tz(selectedDateTime.value, getClientTz());

    return selectedDayjs.clone().tz('America/New_York')
        .set('hours', selectedDayjs.get('hours'))
        .set('minutes', selectedDayjs.get('minutes'));
})

const teamId = Spark.state.currentTeam.id;

const emit = defineEmits(['update:timeZoneAware']);
</script>

<style lang="postcss" scoped>
.vx-date-picker {
    @apply vx-flex vx-justify-center;
}

.safe-sending-background {
    /*
  safe sending 8am - 9pm, however display as 8pm due to staggering and queuing.
  each hour = 4.1667%
  midnight -> 0%
  8am -> 33.3%
  8pm -> 83.33%
  midnight -> 100%
  */

    background-image: linear-gradient(90deg,
            rgba(115, 216, 252, 0.4) 0%,
            rgba(115, 216, 252, 0.4) 29.3%,
            rgba(255, 255, 255, 0.4) 29.3%,
            rgba(255, 255, 255, 0.4) 79.55%,
            rgba(115, 216, 252, 0.4) 79.55%,
            rgba(115, 216, 252, 0.4) 100%),
        url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    background-blend-mode: overlay;
}

.safe-sending-key {
    background-image: linear-gradient(90deg,
            rgba(115, 216, 252, 0.4) 0%,
            rgba(115, 216, 252, 0.4) 100%),
        url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    background-blend-mode: overlay;
}
</style>
