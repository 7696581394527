import { stringify } from 'qs';

export function cursorEncode(cursorObj) {
    if (cursorObj) {
        return btoa(JSON.stringify(cursorObj)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }
}

export function cursorDecode(cursor) {
    if (cursor) {
        try {
            const obj = JSON.parse(atob(cursor.replace(/-/g, '+').replace(/_/g, '/')));
            if (obj && typeof obj === 'object' && typeof obj._pointsToNextItems === 'boolean') {
                return obj;
            }
        } catch (e) {
            console.log(e);
        }
    }
}

export function getCursorFromUrl(url) {
    if (url) {
        return new URLSearchParams(new URL(url).search).get('cursor');
    }

    return null;
}

export function stringifyQueryString(params) {
    return stringify(params ?? {}, { arrayFormat: 'indices', skipNulls: true });
}

export const camelCase = (string) => {
    string = String(string);
    if (!string?.trim()) {
        return string;
    }

    return string
        .split(new RegExp('[^A-Za-z0-9]+', 'g'))
        .filter((s) => !!s?.trim())
        .map((word, index) => {
            word = word.toLowerCase();
            return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join('');
};

export const randomString = (length = 6) => {
    return [...Array(length)].map(() => Math.random().toString(36)[2]).join('');
};

export const countryEmoji = (isoCountry) => {
    return isoCountry
        .toUpperCase()
        .split('')
        .map((char) => String.fromCodePoint(char.charCodeAt(0) + 0x1f1a5))
        .join('');
};
