<template>
    <vx-popover hover arrow placement="top">
        <vx-badge :color="style.color" size="xs">
            <span class="vx-flex vx-items-center vx-gap-1">
                {{ style.label }}
                <font-awesome-icon fixed-width :icon="style.icon" :class="['vx-text-xs', style.iconClass]"></font-awesome-icon>
            </span>
        </vx-badge>
        <template v-slot:content>
            <div class="vx-text-center vx-p-1">
                <div class="vx-text-xs vx-text-slate-500">Subscription Status</div>
                <div class="vx-text-sm vx-text-slate-800">
                    <span class="vx-capitalize">{{ props.type }}: </span>
                    <span>{{ style.label }}</span>
                </div>
            </div>
        </template>
    </vx-popover>
</template>

<script setup>
import { faArrowRightToBracket, faArrowRightFromBracket, faSquareDashed } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBadge, VxPopover } from '@voxie/frontend-components';
import { computed } from 'vue';

const props = defineProps({
    type: {
        type: String,
        required: true,
    },
    status: String
});

const style = computed(() => {
    if (props.status === 'opt_in') {
        return {
            color: 'primary',
            label: 'Opt-in',
            icon: faArrowRightToBracket,
            iconClass: 'vx-text-sky-500',
        };
    }
    if (props.status === 'opt_out') {
        return {
            color: 'amber',
            label: 'Opt-out',
            icon: faArrowRightFromBracket,
            iconClass: 'vx-text-amber-500',
        };
    }

    return {
        color: 'slate',
        label: 'No-pref',
        icon: faSquareDashed,
        iconClass: 'vx-text-slate-500',
    };
});
</script>
