<template>
    <page-container>
        <page-header>
            Automations

            <template v-slot:actions>
                <vx-button @click="showOverview = !showOverview" data-test="toggle-overview" type="button"
                    color="muted-dark" size="lg">
                    <span class="vx-hidden sm:vx-inline-block">{{ showOverview ? 'Hide' : 'Show' }} Overview</span>
                    <font-awesome-icon class="vx-hidden sm:vx-inline-block"
                        :icon="faQuestionCircle"></font-awesome-icon>
                    <font-awesome-icon class="vx-inline-block sm:vx-hidden"
                        :icon="showOverview ? faCircleChevronUp : faCircleChevronDown"></font-awesome-icon>
                </vx-button>
            </template>
        </page-header>

        <automations-overview v-show="showOverview" class="vx-mb-16"></automations-overview>

        <automation-rule-controls v-model="fetchParams" @apply="applyFilters" @reset="resetFilters" :loading="loading"
            class="vx-mb-12"></automation-rule-controls>

        <div v-if="loading && !list.length" class="vx-text-center vx-py-10">
            <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
        </div>

        <template v-else>
            <vx-box color="info" v-if="list.length === 0 && !isEqual(defaultFilters(), fetchParams)"
                class="vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                <div class="vx-w-64 vx-mx-auto vx-text-center">
                    No results for current search, try adjusting your filters.
                </div>
                <vx-button type="button" color="secondary" @click="resetFilters">
                    Reset All <font-awesome-icon :icon="faCircleX" />
                </vx-button>
            </vx-box>
            <vx-box color="info" v-else-if="totalCount === 0"
                class="vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                No automations yet.
                <vx-button href="/automation/event/new">
                    New Automation <font-awesome-icon :icon="faPlusCircle" />
                </vx-button>
            </vx-box>
            <template v-else>
                <vx-table v-if="list.length" class="xl:vx-grid-cols-[repeat(6,auto)]"
                    rowBreakpoint="xl">
                    <vx-table-row isHeader>
                        <div>
                            Name
                        </div>
                        <div>
                            Trigger Event
                        </div>
                        <div>
                            Filters
                        </div>
                        <div>
                            Actions
                        </div>
                        <div>
                            Updated
                        </div>
                    </vx-table-row>
                    <automation-rule-row v-for="(item, index) in list"
                        v-model="list[index]"
                        :key="item.id" @deleted="removeFromList($event)"></automation-rule-row>
                </vx-table>

                <div v-if="list.length" class="vx-mt-8 vx-flex vx-justify-between vx-w-full vx-gap-10">
                    <vx-button v-if="fetchParams.cursor" @click="loadData()" color="muted-dark" size="xl"
                        class="vx-grow-0" :loading="loading" data-test="load-more-btn">
                        Load More
                        <font-awesome-icon :icon="faSpinner"></font-awesome-icon>
                    </vx-button>
                    <div class="vx-text-lg vx-grow-0 vx-ml-auto vx-font-normal vx-text-slate-600">
                        {{ list.length }}/{{ totalCount }}
                    </div>
                </div>
            </template>
        </template>
    </page-container>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { faCircleChevronDown, faCircleChevronUp, faQuestionCircle, faSpinner, faCircleX, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBox, VxButton, VxIconLoading, VxTable, VxTableRow } from '@voxie/frontend-components';
import PageContainer from '~/components/general/PageContainer.vue';
import PageHeader from '~/components/general/PageHeader.vue';
import AutomationsOverview from '~/components/automation/automations/components/AutomationsOverview.vue';
import AutomationRuleControls from '~/components/automation/automations/components/AutomationRuleControls.vue';
import AutomationRuleRow from '~/components/automation/AutomationRuleRow.vue';

import { automationRulesClient } from '~/services';
import { useToasted } from '~/composables/useToasted';
import { isEqual } from '~/utils/helpers';
import { useCursorPagination } from '../../composables/useCursorPagination';

const teamId = Spark.state.currentTeam.id;
const toasted = useToasted();
const showOverview = ref(true);

const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const defaultFilters = () => ({
    query: '',
    per_page: 10,
    cursor: null,
    flow: {
        in_flow: undefined,
        flow_id: null,
    },
    category: undefined,
    sort_field: 'id',
    sort_dir: 'desc',
});

const resetFilters = () => {
    fetchParams.value = defaultFilters();

    loadData();
};

const applyFilters = () => {
    fetchParams.value = {
        ...fetchParams.value,
        cursor: undefined,
    }

    loadData();
}

const totalCount = ref(0);

const loadData = () => {
    fetchRecords(automationRulesClient.search(teamId, {...fetchParams.value}));
    fetchTotalCount();
}

const fetchTotalCount = async () => {
    try {
        const response = await automationRulesClient.automationRulesCount(teamId, { ...fetchParams.value });
        totalCount.value = response.data?.count || 0;
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    }
}

const removeFromList = (ruleId) => {
    list.value = list.value.filter((item) => item.id != ruleId);
    totalCount.value = totalCount.value - 1;

    if (!list.value.length) {
        loadData();
    }
};

onMounted(() => {
    resetFilters();
})
</script>
