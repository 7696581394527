<template>
    <div>
        <div v-show="!userId">
            <page-header>Users</page-header>
            <!-- Search Field card -->
            <div class="card card-default"
                style="border: 0">
                <div class="card-body">
                    <form role="form"
                        @submit.prevent>
                        <!-- Search Field -->
                        <input type="text"
                            id="kiosk-users-search"
                            class="form-control"
                            autofocus
                            name="search"
                            maxlength="191"
                            placeholder="Search By Name Or Email Address..."
                            v-model="searchForm.query"
                            @keyup.enter="search" />
                    </form>
                </div>
            </div>

            <!-- Searching -->
            <div class="card card-default"
                v-if="searching">
                <div class="card-header">Search Results</div>

                <div class="card-body"><i class="fa fa-btn fa-spinner fa-spin"></i> Searching</div>
            </div>

            <!-- No Search Results -->
            <div class="card card-default"
                v-if="!searching && noSearchResults">
                <div class="card-header">Search Results</div>

                <div class="card-body">No users matched the given criteria.</div>
            </div>

            <!-- User Search Results -->
            <div class="card card-default"
                v-if="!searching && searchResults.length > 0">
                <div class="card-header">Search Results</div>

                <div class="table-responsive">
                    <table class="table table-valign-middle mb-0">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Email Address</th>
                                <th class="th-fit"></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="searchUser in searchResults"
                                :key="searchUser.id">
                                <!-- Profile Photo -->
                                <td>
                                    <img :src="searchUser.photo_url"
                                        class="spark-profile-photo"
                                        alt="User Photo" />
                                </td>

                                <!-- Name -->
                                <td>
                                    <div>
                                        {{ searchUser.name }}
                                    </div>
                                </td>

                                <!-- Email Address -->
                                <td>
                                    <div>
                                        {{ searchUser.email }}
                                    </div>
                                </td>

                                <td>
                                    <!-- View User Profile -->
                                    <a class="btn btn-default"
                                        :href="'/spark/kiosk#/users/' + searchUser.id">
                                        <i class="fa fa-search"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- User Profile Detail -->
        <div v-show="userId">
            <kiosk-profile :userId="userId" :plans="plans" />
        </div>
    </div>
</template>

<script>
import KioskProfile from './KioskProfile.vue';
import axios from '~/services/axios';
import PageHeader from '../general/PageHeader.vue';

export default {
    components: { KioskProfile, PageHeader },
    props: ['userId'],
    data() {
        return {
            plans: [],

            searchForm: new SparkForm({
                query: '',
            }),

            searching: false,
            noSearchResults: false,
            searchResults: [],

            showingUserProfile: false,
        };
    },
    created() {
        this.getPlans();

        Bus.$on(`sparkFormUpdated.${this.searchForm.sparkFormId}`, this.$forceUpdate);
    },
    methods: {
        /**
         * Get all of the available subscription plans.
         */
        getPlans() {
            axios.get('/spark/plans').then((response) => {
                this.plans = response.data;
            });
        },

        /**
         * Perform a search for the given query.
         */
        search() {
            this.searching = true;
            this.noSearchResults = false;

            axios.post('/spark/kiosk/users/search', this.searchForm).then((response) => {
                this.searchResults = response.data;
                this.noSearchResults = this.searchResults.length === 0;

                this.searching = false;
            });
        },

        /**
         * Show the search results and update the browser history.
         */
        navigateToSearch() {
            this.showSearch();
        },



    },
};
</script>
