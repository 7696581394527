<template>
    <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8 2xl:vx-max-w-screen-xl">
        <page-header>
            <template v-slot:subtitle>Analytics</template>
            Marketing Campaigns
            <template v-slot:actions>
                <div class="vx-w-full sm:vx-w-60">
                    <vx-selectable
                        :modelValue="Number(params.campaignId || 0)"
                        @update:modelValue="params.campaignId = $event"
                        :clearable="false"
                        appearance="select"
                        :options="options"
                        :reduce="option => option.id"
                        @search="searchCampaigns($event)"
                        :loading="loading"
                        label="name"
                        size="lg"
                        @open="handleOpen"
                    ></vx-selectable>
                </div>
                <analytics-controls :modelValue="controls" @update:modelValue="updateControls" />
            </template>
        </page-header>

        <analytics-marketing-campaigns-all v-if="!params.campaignId" :startDate="controls.start_date" :endDate="controls.end_date" :rangeLabel="rangeLabel"></analytics-marketing-campaigns-all>
        <analytics-marketing-campaigns-single v-else :campaignId="Number(params.campaignId)" :startDate="controls.start_date" :endDate="controls.end_date" :rangeLabel="rangeLabel"></analytics-marketing-campaigns-single>
    </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';
import AnalyticsControls from './partial/AnalyticsControls.vue';
import { controlDateRangeOptions, findDateRangeOption } from '../../utils/analytics';
import { VxSelectable } from '@voxie/frontend-components';
import campaignsClient from '../../services/campaignsClient';
import { useToasted } from '../../composables/useToasted';
import debounce from '~/utils/debounce';
import AnalyticsMarketingCampaignsAll from './AnalyticsMarketingCampaignsAll.vue';
import AnalyticsMarketingCampaignsSingle from './AnalyticsMarketingCampaignsSingle.vue';
import { useUrlSearchParams } from '@vueuse/core'

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;

const params = useUrlSearchParams('history', {
    removeNullishValues: true,
    removeFalsyValues: true,
});

const controls = ref(controlDateRangeOptions().find(option => option.value === 'last_30_days'));

const rangeLabel = computed(() => controls.value.label)

const loading = ref(false);
const campaigns = ref([]);
const options = computed(() => {
    const items = [{ id: 0, name: 'All Campaigns' }, ...campaigns.value];

    if (params.campaignId && !campaigns.value.find((campaign) => campaign.id === Number(params.campaignId))) {
        items.push({
            id: Number(params.campaignId),
            name: `Campaign - ${params.campaignId}`,
        });
    }

    return items;
});

const handleOpen = () => {
    if (!open.value) {
        searchCampaigns();
        open.value = true;
    }
}

const searchCampaigns = debounce(async (event) => {
    try {
        loading.value = true;
        const requestParams = {
            query: event,
            sort_field: 'id',
            sort_dir: 'desc',
            per_page: 10,
            with_trashed: 1,
        }
        if (requestParams.campaignId) {
            requestParams.ids = [params.campaignId];
        }
        const response = await campaignsClient.campaignsGetRecords(teamId, requestParams)

        campaigns.value = response.data.data;
    } catch (e) {
        console.error(e)
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
}, 300);

if (params.startDate && params.endDate) {
    controls.value = findDateRangeOption([params.startDate, params.endDate]);
}

const updateControls = (event) => {
    controls.value = event;
    params.startDate = event.start_date;
    params.endDate = event.end_date;
}

if (params.campaignId) {
    searchCampaigns();
}
</script>

<style scoped lang="postcss">
:deep(.vx-selectable .vs__dropdown-menu) {
    max-height: 550px;
}
</style>
