<template>
    <vx-selectable v-model="model"
        placeholder="Search Group"
        :reduce="option => option.value"
        :loading="loading"
        :options="options"
        @search="search"
        data-test="group-selectable">
    </vx-selectable>
</template>

<script setup>
import { VxSelectable } from '@voxie/frontend-components';
import { ref } from 'vue';
import { useToasted } from '~/composables/useToasted';
import groupsClient from '~/services/groupsClient';
import debounce from '~/utils/debounce';

const teamId = Spark.state.currentTeam.id;
const toasted = useToasted();

const model = defineModel();

const options = ref([]);
const loading = ref();

const search = debounce((query) => {
    loading.value = true;

    groupsClient.listGroups(teamId, {
        query: query || model.value,
    }).then((response) => {
        options.value = response.data.data.map((group) => ({
            value: group.group_id,
            label: `${group.name} #${group.group_id}`.trim()
        }))
    }).catch((e) => {
        console.error(e)
        toasted.global.platform_error()
    }).finally(() => {
        loading.value = false
    })
}, 300);

search();
</script>
