<template>
    <div class="card card-default">
        <div class="card-header">
            <div class="float-left">
                Resume Subscription
            </div>

            <!-- Interval Selector Button Group -->
            <div class="float-right">
                <div class="btn-group btn-group-sm" v-if="hasMonthlyAndYearlyPlans">
                    <!-- Monthly Plans -->
                    <button
                        type="button"
                        class="btn btn-light"
                        @click="showMonthlyPlans"
                        :class="{'active': showingMonthlyPlans}"
                    >
                        Monthly
                    </button>

                    <!-- Yearly Plans -->
                    <button
                        type="button"
                        class="btn btn-light"
                        @click="showYearlyPlans"
                        :class="{'active': showingYearlyPlans}"
                    >
                        Yearly
                    </button>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>

        <div class="table-responsive">
            <!-- Plan Error Message - In General Will Never Be Shown -->
            <div class="alert alert-danger mb-4" v-if="planForm.errors.has('plan')">
                {{ planForm.errors.get('plan') }}
            </div>

            <!-- Cancellation Information -->
            <div class="m-4">
                You have cancelled your subscription to the {{ activePlan.name }} ({{ capitalize(activePlan.interval) }}) plan.
            </div>

            <div class="m-4">
                The benefits of your subscription will continue until your current billing period ends on {{ date(activeSubscription.ends_at) }}. You may resume your subscription at no extra cost until the end of the billing period.
            </div>

            <table class="table table-responsive-sm table-valign-middle mb-0 ">
                <thead></thead>
                <tbody>
                    <tr v-for="(plan, index) in plansForActiveInterval" :key="index">
                        <!-- Plan Name -->
                        <td>
                            <div class="d-flex align-items-center">
                                {{ plan.name }}
                            </div>
                        </td>

                        <!-- Plan Features Button -->
                        <td>
                            <button class="btn btn-default" @click="showPlanDetails(plan)">
                                <i class="fa fa-btn fa-star-o"></i> Features
                            </button>
                        </td>

                        <!-- Plan Price -->
                        <td>
                            <div>
                                <strong class="table-plan-price">{{ currency(priceWithTax(plan)) }}</strong>
                                {{ chargesTeamsPerSeat ? '/ '+ teamSeatName : '' }}
                                {{ chargesTeamsPerMember ? '/ '+ teams.member : '' }}
                                / {{ capitalize(plan.interval) }}
                            </div>
                        </td>

                        <!-- Plan Select Button -->
                        <td class="text-right">
                            <button
                                class="btn btn-plan"
                                :class="{'btn-default': !isActivePlan(plan), 'btn-warning': isActivePlan(plan)}"
                                @click="updateSubscription(plan)"
                                :disabled="selectingPlan"
                            >
                                <span v-if="selectingPlan === plan">
                                    <i class="fa fa-btn fa-spinner fa-spin"></i> Resuming
                                </span>

                                <span v-if="!isActivePlan(plan) && selectingPlan !== plan">
                                    Switch
                                </span>

                                <span v-if="isActivePlan(plan) && selectingPlan !== plan">
                                    Resume Subscription
                                </span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { currency, capitalize, date } from '../../../utils/helpers';
import axios from '~/services/axios';

export default {
    props: [
        'team',
        'plans',
        'teams',
        'activeSubscription',
        'showingMonthlyPlans',
        'showingYearlyPlans',
    ],
    data() {
        return {
            planForm: new SparkForm({}),
            selectingPlan: null,
        };
    },
    created() {
        Bus.$on(`sparkFormUpdated.${this.planForm.sparkFormId}`, this.$forceUpdate)
    },
    methods: {
        date,
        currency,
        capitalize,
        showMonthlyPlans() {
            this.$emit('showMonthlyPlans')
        },
        showYearlyPlans() {
            this.$emit('showYearlyPlans');
        },
        showPlanDetails(plan) {
            Bus.$emit('showPlanDetails', plan);
        },
        priceWithTax(plan) {
            return plan.price + (plan.price * (this.team.tax_rate / 100));
        },
        isActivePlan(plan) {
            return this.activeSubscription &&
                this.activeSubscription.provider_plan === plan.id;
        },
        updateSubscription(plan) {
            this.selectingPlan = plan;

            this.planForm.errors.forget();

             // Here we will send the request to the server to update the subscription plan and
             // update the user and team once the request is complete. This method gets used
             // for both updating subscriptions plus resuming any cancelled subscriptions.
            axios.put(this.urlForPlanUpdate, {"plan": plan.id})
                .then(() => {
                    Bus.$emit('updateTeam');
                })
                .catch(errors => {
                    if (errors.response.status == 400) {
                        window.location = '/' + Spark.cashierPath + '/payment/' + errors.response.data.paymentId + '?redirect=' + this.urlForPlanRedirect;
                    } else if (errors.response.status === 422) {
                        this.planForm.setErrors(errors.response.data.errors);
                    } else {
                        this.planForm.setErrors({plan: [ "We were unable to update your subscription. Please contact customer support." ]});
                    }
                })
                .finally(() => {
                    this.selectingPlan = null;
                });
        },
    },
    computed: {
        urlForPlanUpdate() {
            return `/settings/teams/${this.team.id}/subscription`;
        },
        urlForPlanRedirect() {
            return `/settings/teams/${this.team.id}%23/subscription`;
        },
        activeInterval() {
            return this.showingMonthlyPlans ? 'monthly' : 'yearly';
        },
        activePlan() {
            return this.plans?.find((plan) => {
                return plan.id === this.activeSubscription?.provider_plan;
            });
        },
        plansForActiveInterval() {
            return this.plans?.filter((plan) => {
                return plan.active && (plan.price === 0 || plan.interval === this.activeInterval);
            }) || [];
        },
        monthlyPlans() {
            return this.plans?.filter((plan) => {
                return plan.active && plan.interval === 'monthly';
            }) || [];
        },
        yearlyPlans() {
            return this.plans?.filter((plan) => {
                return plan.active && plan.interval === 'yearly';
            }) || [];
        },
        hasMonthlyAndYearlyPlans() {
            return this.monthlyPlans.length > 0 && this.yearlyPlans.length > 0;
        },
        chargesUsersPerSeat() {
            return Spark.chargesUsersPerSeat;
        },
        chargesTeamsPerSeat() {
            return Spark.chargesTeamsPerSeat;
        },
        chargesTeamsPerMember() {
            return Spark.chargesTeamsPerMember;
        },
        seatName() {
            return Spark.seatName;
        },
        teamSeatName() {
            return Spark.teamSeatName;
        }
    },
};
</script>
