<template>
    <api-delivery-card :icon="faLinkSimple">
        <template v-slot:title>
            Request Metadata
        </template>
        <template v-slot:content>
            <div  v-if="props.request === undefined" class="vx-w-full vx-flex vx-items-center vx-justify-center">
                <vx-icon-loading spin></vx-icon-loading>
            </div>
            <div v-else-if="props.request" class="vx-justify-start vx-items-center vx-gap-4 vx-inline-flex vx-break-all vx-flex-wrap">
                <vx-badge :color="colorForHttpMethod(props.request.request_method)">
                    {{ props.request.request_method }}
                </vx-badge>
                <div class="vx-text-slate-500 vx-text-base vx-font-normal">{{ props.request.request_content_type }}</div>
                <div class="vx-text-slate-500 vx-text-base vx-font-normal">{{ props.request.request_uri }}</div>
            </div>
            <div v-else
                    class="vx-w-full vx-p-4 vx-bg-slate-50 vx-rounded-[10px] vx-justify-start vx-items-center vx-gap-3 vx-inline-flex">
                    <font-awesome-icon :icon="faGhost" class="vx-text-slate-500" />
                    <div class="vx-text-sky-950 vx-text-sm vx-font-normal">Request Missing</div>
            </div>
        </template>
    </api-delivery-card>
</template>
<script setup>
import ApiDeliveryCard from './ApiDeliveryCard.vue';
import { colorForHttpMethod } from '../../../apiDestinationHelper';

import { VxBadge, VxIconLoading } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faLinkSimple, faGhost } from '@fortawesome/pro-solid-svg-icons';

const props = defineProps({
    request: {
        type: Object,
        required: false,
    }
});

</script>
