<template>
    <span @click="toggle" class="pointer">
        <i :class="['fa fa-check-circle-o fa-xs u-mr-xsmall', {'text-primary': feature.enabled}]"></i>
        {{ feature.name }}
    </span>
    <small class="mt-1 d-block">{{ feature.description }}</small>
</template>

<script>
    import { teamsClient } from '../../../../services';
    import Swal from 'sweetalert2';

    export default {
        name: 'TeamFeatureItem',
        props: ['feature', 'team'],
        data() {
            return {
                featureData: this.feature,
                loading: false
            }
        },
        methods: {
            toggle() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                let method = 'enableFeature';
                if (this.feature.enabled) {
                    method = 'disableFeature';
                }

                teamsClient[method](this.team.id, this.feature.id)
                    .then(() => {
                        this.feature.enabled = ! this.feature.enabled;
                    })
                    .catch((error) => {
                        if (409 === error.response.status || 404 === error.response.status) {
                            return Swal.fire({
                                title: 'Whoops!',
                                text: error.response.data.message.replace(this.feature.id, this.feature.name),
                                icon: 'error',
                            });
                        }

                        this.$toasted.global.platform_error();
                    })
                    .finally(() => this.loading = false );

            }
        },
    };
</script>
<style scoped lang="postcss">
.fa {
    color: #9aa5ac;
}
</style>
