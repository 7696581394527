import BaseClient from './BaseClient';

class OpenAISettingsClient extends BaseClient {
    getConfig(teamId) {
        const url = `/api/v3/teams/${teamId}/ai/config`;
        return this.makeCoreApiRequest(url);
    }

    createConfig(teamId, data) {
        const url = `/api/v3/teams/${teamId}/ai/config`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    updateConfig(teamId, data) {
        const url = `/api/v3/teams/${teamId}/ai/config`;
        return this.makeCoreApiRequest(url, 'patch', data);
    }
}

export default new OpenAISettingsClient();
