<template>
    <!-- Session Expired Modal -->
    <div class="modal fade" id="modal-session-expired" tabindex="-1" role="dialog" data-backdrop="static"
        data-keyboard="false">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        Session Expired
                    </h4>
                </div>

                <div class="modal-body">
                    Your session has expired. Please reload page to continue.
                </div>

                <!-- Modal Actions -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" @click="reloadPage">
                        <i class="fa fa-btn fa-sign-in"></i> Reload Page
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>
<script setup>
const reloadPage = () => {
    localStorage.setItem('page_last_reloaded_at', Date.now());
    window.location.reload();
}
</script>
