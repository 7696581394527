<template>
    <vx-expandable-card>
        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-4">
                <div>
                    <vx-label size="md">Voice - Inbound Call Strategy</vx-label>
                    <vx-selectable placeholder="-"
                        :error="displayFirstError(strategyValidate.$errors)"
                        size="lg"
                        :options="strategies"
                        v-model="strategy"
                        :clearable="false"
                        :reduce="(option) => option.value">
                    </vx-selectable>
                </div>

                <template v-if="strategy === 'say'">
                    <div>
                        <vx-label size="md">Message</vx-label>
                        <vx-input :rows="3"
                            v-model="model.voice_message_text"
                            size="lg"
                            :error="displayFirstError(validate.voice_message_text)"></vx-input>
                    </div>
                </template>

                <template v-if="strategy === 'forward'">
                    <div>
                        <vx-label size="md">Forward to</vx-label>
                        <vx-input v-model="model.forward_voice_to"
                            data-test="forward_voice_to"
                            size="lg"
                            @blur="formatForwardVoiceTo()"
                            :error="displayFirstError(validate.forward_voice_to)"></vx-input>
                    </div>

                    <div>
                        <vx-label size="md">Press digits after forward</vx-label>
                        <vx-input v-model="model.forward_voice_send_digits"
                            size="lg"
                            :error="displayFirstError(validate.forward_voice_send_digits)"></vx-input>
                    </div>
                </template>
            </div>
        </template>
    </vx-expandable-card>
</template>
<script setup>
import {
    VxExpandableCard,
    VxSelectable,
    VxLabel,
    VxInput,
} from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { maxLength, required, requiredIf } from '@vuelidate/validators';
import { inject, ref, watch } from 'vue';
import { e164Format } from '~/utils/phoneNumber';
import { displayFirstError, phoneNumber } from '~/utils/validation';

const FEATURES = inject('FEATURES');

const strategy = ref();
const model = defineModel({
    type: Object,
    default: () => ({})
});

const strategyValidate = useVuelidate({
    required,
}, strategy, {
    $autoDirty: true,
})

const validate = useVuelidate({
    forward_voice_send_digits: {
        maxLength: maxLength(20)
    },
    voice_message_text: {
        requiredIf: requiredIf(() => strategy.value === 'say'),
        maxLength: maxLength(4000),
    },
    forward_voice_to: {
        requiredIf: requiredIf(() => strategy.value === 'forward'),
        phoneNumber: phoneNumber,
    },
}, model, {
    $autoDirty: true,
})

const findStrategy = () => {
    if (model.value.inbound_call_handler === 'shift_to_text') {
        strategy.value = 'shift_to_text';
    } else if (model.value.forward_voice_to) {
        strategy.value = 'forward';
    } else if (model.value.voice_message_text) {
        strategy.value = 'say';
    } else if (model.value.id) {
        strategy.value = 'default';
    }
}
findStrategy();

watch(strategy, (selectedStrategy) => {
    switch (selectedStrategy) {
        case 'forward':
            model.value.voice_message_text = null;
            model.value.inbound_call_handler = 'core';
            break;

        case 'say':
            model.value.forward_voice_to = null;
            model.value.forward_voice_send_digits = null;
            model.value.inbound_call_handler = 'core';
            break;

        case 'shift_to_text':
            model.value.forward_voice_to = null;
            model.value.forward_voice_send_digits = null;
            model.value.voice_message_text = null;
            model.value.inbound_call_handler = 'shift_to_text';
            break;

        case 'default':
            model.value.voice_message_text = null;
            model.value.forward_voice_to = null;
            model.value.forward_voice_send_digits = null;
            model.value.inbound_call_handler = 'core';
            break;
    }
})

const strategies = [
    { value: 'default', label: 'Say a system message indicating no voice service' },
    { value: 'forward', label: 'Forward call to another number' },
    { value: 'say', label: 'Say the given message' },
];

if (FEATURES.shift_to_text) {
    strategies.push({value: 'shift_to_text', label: 'Shift to Text'});
}

const formatForwardVoiceTo = () => {
    model.value.forward_voice_to = e164Format(model.value.forward_voice_to);
}
</script>
