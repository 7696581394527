<template>
    <vue-recaptcha
        ref="vueRecaptcha"
        :sitekey="sitekey"
        load-recaptcha-script
        language="en"
        @verify="verify"
        @expired="expired"
        @error="expired"
    />
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    components: {
        VueRecaptcha,
    },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            sitekey: Spark.recaptchaSiteKey,
            checked: false,
        };
    },
    watch: {
        value(val) {
            if (!val && this.checked) {
                this.checked = false;
                this.$refs.vueRecaptcha.reset();
            }
        },
    },
    methods: {
        verify(response) {
            this.checked = true;
            this.$emit('update:modelValue', response);
        },
        expired() {
            this.checked = false;
            this.$emit('update:modelValue', '');
        },
    },
};
</script>
