<template>
    <vx-expandable-card id="details">
        <template v-slot:header>Phone Numbers</template>
        <template v-slot:headerSubtitle>Numbers must be managed by a Voxie admin in
            <a :href="`/settings/teams/${teamId}#/phone-numbers`" class="vx-text-sky-700 vx-underline">resources / phone numbers.</a> <a href="https://support.voxie.com/hc/en-us/requests/new" class="vx-text-slate-500 vx-underline">Contact Voxie</a> for help.
        </template>
        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-2">

              <template v-if="model.length">
                <div v-for="phoneNumber in model"
                     :key="phoneNumber.id"
                     class="vx-rounded-lg vx-px-5 vx-w-full vx-py-4 vx-flex vx-justify-between vx-items-center vx-text-slate-700"
                     :class="bgColor(phoneNumber.use_for_sending)">
                  <div class="vx-self-center">
                    <slot name="icon">
                      <font-awesome-icon :class="textColor(phoneNumber.use_for_sending)" :icon="faCirclePhone" />
                    </slot>
                  </div>
                  <div class="vx-text-sm vx-font-normal vx-flex-grow vx-self-center vx-pl-3">
                    <strong>{{ phoneNumberNational(phoneNumber.phone_number) }}</strong> {{ phoneNumber.use_for_sending ? 'used for sending' : '' }}
                  </div>
                </div>
              </template>

                <div v-else class="vx-rounded-lg vx-bg-rose-100 vx-px-5 vx-w-full vx-py-4 vx-flex vx-justify-between vx-items-center vx-text-slate-700">
                    <div class="vx-self-center">
                        <slot name="icon">
                            <font-awesome-icon class="vx-text-rose-500" :icon="faWarning" />
                        </slot>
                    </div>
                    <div class="vx-text-sm vx-font-normal vx-flex-grow vx-self-center vx-pl-3">
                        no number set
                    </div>
                </div>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { faCirclePhone, faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { VxExpandableCard } from "@voxie/frontend-components";
import { phoneNumberNational } from "~/components/filters";

const model = defineModel({default: []});
const teamId = Spark.state.currentTeam.id;

const bgColor = (usedForSending) => {
    return usedForSending ? 'vx-bg-teal-100' : 'vx-bg-slate-100';
};

const textColor = (usedForSending) => {
    return usedForSending ? 'vx-text-teal-500' : 'vx-text-slate-500';
};
</script>
