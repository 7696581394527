<template>
    <div class="vx-flex vx-flex-col vx-gap-6 vx-font-sans">
        <sso-config :team="props.team"></sso-config>
        <saml2-management :team="props.team"></saml2-management>
    </div>
</template>
<script setup>
import SsoConfig from './sso/SsoConfig.vue';
import Saml2Management from './sso/Saml2Management.vue';

const props = defineProps({
    team: {
        type: Object,
        required: true,
    },
});
</script>
