<template>
    <div class="promotion-coupons-list list">
        <h2>Coupons</h2>
        <loading-spinner v-if="loadingCollection" />
        <table v-else class="c-table resource-table">
            <caption class="c-table__title">
                <div>
                    {{ allocatedCount }}<small>Coupons Allocated</small>  /  {{ coupons.value.total }}<small>Total Coupons</small>

                    <div class="pull-right search-container">
                        <div class="c-field c-field--inline has-icon-right u-mr-small u-hidden-down@desktop">
                            <input v-model="query.search" class="c-input" type="text" placeholder="Search by Coupon or Phone Number">
                            <span v-if="!!query.string" class="c-field__icon"><i class="fa fa-search"></i></span>
                            <span v-if="searching" class="c-field__icon"><i class="fa fa-spin fa-circle-o-notch"></i></span>
                            <a v-if="query.search && !searching" href="#" @click.prevent="cancelSearch" class="c-field__icon"><i class="fa fa-times"></i></a>
                        </div>
                    </div>
                </div>
            </caption>

            <thead v-if="couponsFound" class="c-table__head c-table__head--slim">
                <tr class="c-table__row">
                    <th class="c-table__cell c-table__cell--head tag-cell">Code</th>
                    <th class="c-table__cell c-table__cell--head tag-cell">Contact</th>
                    <th class="c-table__cell c-table__cell--head tag-cell">Phone</th>
                    <th class="c-table__cell c-table__cell--head tag-cell">Allocated At</th>
                </tr>
                </thead>
            <tbody v-if="couponsFound">
                <tr v-for="coupon in coupons.value.data" :key="coupon.id">
                    <td class="c-table__cell tag-cell">
                        <span class="u-block">{{ coupon.code }}</span>
                    </td>
                    <td class="c-table__cell tag-cell">
                        <span v-if="coupon.contact_name" class="u-block">{{ coupon.contact_name }}</span>
                        <span v-else class="u-block">-</span>
                    </td>
                    <td class="c-table__cell tag-cell">
                        <span v-if="coupon.contact_phone" class="u-block">{{ coupon.contact_phone }}</span>
                        <span v-else class="u-block">-</span>
                    </td>
                    <td class="c-table__cell tag-cell">
                        <span class="u-block">{{ dateTime(coupon.allocated_at) }}</span>
                    </td>
                </tr>
                </tbody>
            <alert v-else type="warning" text="There are no coupons." />

        </table>

        <pagination-nav v-if="!loadingCollection" v-model:page="query.page" :total="coupons.value.total" :disabled="loadingCollection" />
    </div>
</template>

<script setup>

import {computed, reactive, ref, watch} from 'vue';
import {promotionCouponsClient} from '../../services';
import { dateTime } from '../filters';
import LoadingSpinner from '../general/LoadingSpinner.vue';
import Alert from '../general/Alert.vue';
import PaginationNav from '../general/PaginationNav.vue';
import debounce from '~/utils/debounce';

const props = defineProps({
    promotionId: {
        type: Number,
        required: true,
    },
    teamId: {
        type: Number,
        required: true,
    },
})

const loadingCollection = ref(true);

const query = reactive({
    page: 1,
    search: ''
})

const coupons = reactive({});

const allocatedCount = ref(0);

const searching = ref(false);

const couponsFound = computed(
    () => !!coupons.value?.data?.length
)

watch(() => query.search, () => {
    debouncedGetCoupons();
    debouncedGetAllocated();
});

watch(() => query.page, () => getCoupons());

const getCoupons = () => {
    if (query.search !== '') {
        query.page = 1;
    }

    promotionCouponsClient.getPromotionCoupons(props.teamId, props.promotionId, query).then(response => {
        coupons.value = response.data;
        loadingCollection.value = false;
    })
}

const getAllocated = () => {
    const params = {
        page: 1,
        search: query.search
    }

    promotionCouponsClient.getAllocatedTotal(props.teamId, props.promotionId, params).then(response => {
        allocatedCount.value = response.data.total;
    })
}

const debouncedGetCoupons = debounce(getCoupons, 350);
const debouncedGetAllocated = debounce(getAllocated, 350);

const cancelSearch = () => query.search = '';

getCoupons();

getAllocated();

</script>
<style lang="postcss">
.promotion-coupons-list .c-table__title small::before {
  content: none;
}
</style>
