<template>
    <vx-expandable-card>
        <template v-slot:header> What </template>
        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-mt-4 vx-gap-8">
                <campaign-step-form-message v-for="(message, index) in props.modelValue"
                    :key="'campaign_message.' + message.id"
                    :campaignId="props.campaignId"
                    ref="messagesRef"
                    :modelValue="message"
                    @update:modelValue="updateModelValue(index, $event)"
                    :index="index"
                    @remove="removeMessage(index)"
                    :total="props.modelValue.length"></campaign-step-form-message>

                <div v-if="props.modelValue.length < 20"
                    class="vx-rounded-lg vx-bg-slate-100 vx-px-8 vx-py-4 vx-flex vx-justify-between vx-items-center vx-text-slate-700">
                    Add New Message

                    <vx-button @click.prevent="addMessage()"
                        type="button"
                        size="sm"
                        color="muted">
                        Add <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                    </vx-button>
                </div>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxExpandableCard, VxButton } from '@voxie/frontend-components';
import CampaignStepFormMessage from './CampaignStepFormMessage.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { randomString } from '~/utils/string';
import { nextTick, onMounted, ref } from 'vue';

const props = defineProps({
    campaignId: {
        type: [Number, undefined],
        required: false,
    },
    modelValue: {
        type: Array,
        required: true,
        default: () => ([]),
    },
});

const messagesRef = ref([]);

const emit = defineEmits(["update:modelValue"]);

const updateModelValue = (updateIndex, $event) => {
    emit('update:modelValue', props.modelValue.map((message, index) => ({
        ...(updateIndex === index ? $event : message)
    })))
}

const addMessage = async () => {
    messagesRef.value?.forEach(message => message.close());

    emit('update:modelValue', [...props.modelValue, {
        id: randomString(),
        body: '',
        media_content_type: '',
        media_url: '',
        status: 'published',
        delay_type: 'minutes',
        delay_amount: 0,
        should_send_on_schedule: false,
        send_on_0: true,
        send_on_1: true,
        send_on_2: true,
        send_on_3: true,
        send_on_4: true,
        send_on_5: true,
        send_on_6: true,
        send_at_time: '09:00:00',
        send_stop_time: '20:00:00',
    }]);

    await nextTick();
    if (props.modelValue.length > 1) {
        messagesRef.value?.[props.modelValue.length - 1]?.focusMessage?.();
    }
}

onMounted(() => {
    if (!props.modelValue.length) {
        addMessage();
    }
});

const removeMessage = (removeIndex) => {
    emit('update:modelValue', props.modelValue.filter((message, index) => index !== removeIndex));
}
</script>
