<template>
    <div class="ds-v2-contact-panel">
        <div class="ds-v2-contact-panel__heading">
            <div class="ds-v2-contact-panel__title">
                <vx-icon name="user-alt" :size="18"></vx-icon> Info
            </div>
            <button @click="$emit('close')" class="ds-v2-contact-panel__back">
                <vx-icon name="arrow-right" :size="18"></vx-icon>
            </button>
        </div>
        <div class="ds-v2-contact-panel__content">
            <vx-panel data-test="contact-profile">
                <vx-panel-list :max="5" :items="profile"></vx-panel-list>
                <vx-button :href="`/contacts/${selectedContact.id}/view`" class="mt-3" block size="small" appearance="transparent-primary-outline">
                    View Contact
                </vx-button>
            </vx-panel>

            <vx-panel data-test="contact-subscription-status-v2">
                <template v-slot:title>Subscription Statuses</template>

                <vx-panel-list v-if="subscriptionItems.length" :items="subscriptionItems"></vx-panel-list>
                <div v-else>None</div>
            </vx-panel>

            <vx-panel>
                <template v-slot:title>Custom Attributes</template>

                <vx-panel-list v-if="selectedContact.custom_attributes?.length" :items="selectedContact.custom_attributes"></vx-panel-list>
                <div v-else>None</div>
            </vx-panel>

            <vx-panel>
                <template v-slot:title>
                    <!-- TODO: move call openAddTagModal to vx-fab-button -->
                    <div class="ds-v2-contact-panel__subtitle" @click.stop="openAddTagModal">
                        <span>Tags</span>
                        <vx-fab-button appearance="primary-dark" size="extra-small">
                            <vx-icon name="plus"></vx-icon>
                        </vx-fab-button>
                    </div>
                </template>
                <template v-if="selectedContact.tags?.length">
                    <v-tooltip openDelay="200" style="max-width: 100%" v-for="tag in selectedContact.tags" :key="tag.tag_name">
                        <vx-badge color="primary" radius="lg" :close="true" @close="removeTag(tag)">
                            {{ tag.tag_name.toLowerCase() }}
                        </vx-badge>
                        <template v-slot:content>
                            {{ tag.tag_name.toLowerCase() }}
                        </template>
                    </v-tooltip>
                </template>
                <div v-else>None</div>
            </vx-panel>

            <vx-panel v-if="FEATURES.contact_groups">
                <template v-slot:title>
                    <div class="ds-v2-contact-panel__subtitle">
                        <span>Groups</span>
                    </div>
                </template>
                <template v-if="selectedContactGroups?.length">
                    <v-tooltip openDelay="200" style="max-width: 100%" v-for="group in selectedContactGroups" :key="group.group_id">
                        <vx-badge color="primary" radius="lg" class="ds-v2-contact-panel__badge--no-icon">
                            {{ group.group_id.toLowerCase() }}
                        </vx-badge>
                        <template v-slot:content>
                            {{ group.group_id.toLowerCase() }}
                        </template>
                    </v-tooltip>
                </template>
                <div v-else>None</div>
            </vx-panel>

            <vx-panel>
                <template v-slot:title>Segments</template>
                <loading-spinner v-if="selectedContact.segments === undefined || selectedContact.segments === null" />
                <template v-else-if="selectedContact.segments?.length">
                    <span class="ds-v2-contact-panel__text" v-for="segment in selectedContact.segments" :key="segment.id">
                        {{ segment.name }}
                    </span>
                </template>
                <div v-else>None</div>
            </vx-panel>

            <vx-panel v-if="FEATURES.promotions" data-test="promotions-panel">
                <template v-slot:title>Promotions</template>
                <template v-if="selectedContact.promotion_coupons?.length">
                    <span class="ds-v2-contact-panel__text" v-for="(promotionCoupon) in selectedContact.promotion_coupons" :key="promotionCoupon.id">
                        {{ promotionCoupon.promotion.name }}
                    </span>
                </template>
                <div v-else>None</div>
            </vx-panel>
        </div>

        <add-tag-modal></add-tag-modal>
    </div>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue';
import { VxButton, VxPanel, VxPanelList, VxBadge, VxIcon, VxFabButton } from '@voxie/frontend-components-v3'
import AddTagModal from '../../messages/threads/misc/modals/AddTagModal.vue';
import LoadingSpinner from '../../general/LoadingSpinner.vue';
import VTooltip from '../../general/VTooltip.vue';
import { date, entryPoint } from '../../filters';
import { useStore } from 'vuex';
import { contactsClient } from '../../../services';
import { HTTP_NOT_FOUND } from '../../../support/HttpStatuses';
import { computeSubscriptionsItems } from '~/constants/contactSubscription';
import axios from 'axios';

defineEmits(['close'])

const FEATURES = inject('FEATURES');

const store = useStore()

const selectedContact = computed(() => store.state.contacts_v3.selectedContact);

const pullTag = (tag) => store.commit('contacts_v3/pullTag', { contact: selectedContact.value, tag })

let contactTagsListAbort;
let contactCustomAttributesListAbort;
let getContactSegmentsAbort;
let listContactGroupsAbort;
let listPromotionCouponsAbort;

const removeTag = (tag) => {
    const teamId = Spark.state.currentTeam.id

    contactsClient.contactDeleteTag(teamId, selectedContact.value.id, tag.tag_name.toLowerCase())
        .then(() => {
            pullTag(tag)
        })
        .catch(e => {
            if(e.response.status === HTTP_NOT_FOUND){
                pullTag(tag)
            }
        })
}

const openAddTagModal = () => Bus.$emit('showAddTagModal')

const profile = computed(() => {
    return [
        {
            key: 'Number',
            value: selectedContact.value.phone
        },
        {
            key: 'Email',
            value: selectedContact.value.email || '-'
        },
        {
            key: 'Date Added',
            value: selectedContact.value.created_at ? date(selectedContact.value.created_at) : '-'
        },
        {
            key: 'Entry Point',
            value: selectedContact.value.entry_point === undefined ? '-' : entryPoint(selectedContact.value.entry_point)
        },
    ];
})

const subscriptionItems = computed(() => computeSubscriptionsItems(selectedContact.value.subscriptions || []));

const fetchTags = () => {
    contactTagsListAbort?.abort()
    contactTagsListAbort = new AbortController();

    contactsClient.contactTagsList(Spark.state.currentTeam.id, selectedContact.value.id, { signal: contactTagsListAbort.signal }).then(response => {
        store.commit('contacts_v3/setTags', response.data.data.map((t) => {
            t.tag_name = t.tag_name.toLowerCase();
            return t;
        }));
    }).catch(e => {
        if (!axios.isCancel(e)) {
            throw e;
        }
    })
};

const fetchCustomAttributes = () => {
    contactCustomAttributesListAbort?.abort()
    contactCustomAttributesListAbort = new AbortController();

    contactsClient.contactCustomAttributesList(Spark.state.currentTeam.id, selectedContact.value.id, { signal: contactCustomAttributesListAbort.signal }).then(response => {
        store.commit('contacts_v3/setCustomAttributes', response.data.data)
    }).catch(e => {
        if (!axios.isCancel(e)) {
            throw e;
        }
    })
};

const fetchSegments = () => {
    getContactSegmentsAbort?.abort()
    getContactSegmentsAbort = new AbortController();

    contactsClient.getContactSegments(Spark.state.currentTeam.id, selectedContact.value.id, { per_page: 100 }, { signal: getContactSegmentsAbort.signal })
        .then(({ data }) => {
            store.commit('contacts_v3/setSegments', data.data)
        }).catch(e => {
            if (!axios.isCancel(e)) {
                throw e;
            }
        })
}

const fetchPromotionCoupons = () => {
    if (!FEATURES.promotions) {
        return
    }

    listPromotionCouponsAbort?.abort()
    listPromotionCouponsAbort = new AbortController();

    contactsClient.listPromotionCoupons(
        Spark.state.currentTeam.id,
        selectedContact.value.id,
        { sort_dir: 'desc', per_page: 100 },
        { signal: listPromotionCouponsAbort.signal },
    ).then(({ data }) => {
        store.commit('contacts_v3/setPromotionCoupons', data.data)
    }).catch(e => {
        if (!axios.isCancel(e)) {
            throw e;
        }
    })
}

const selectedContactGroups = ref([]);
const fetchContactGroups = () => {
    if (!FEATURES.contact_groups) {
        return
    }

    listContactGroupsAbort?.abort()
    listContactGroupsAbort = new AbortController();

    contactsClient.listContactGroups(Spark.state.currentTeam.id, selectedContact.value.id, { signal: listContactGroupsAbort.signal }).then(response => {
        selectedContactGroups.value = response.data.data.map((g) => ({group_id: g.group_id.toLowerCase()}));
    }).catch(e => {
        if (!axios.isCancel(e)) {
            throw e;
        }
    })
}

watch(
    () => selectedContact.value.id,
    () => {
        fetchSegments();
        fetchTags();
        fetchCustomAttributes();
        fetchContactGroups();
        fetchPromotionCoupons();
    },
    {
        immediate: true,
    }
)

</script>

<style lang="postcss">
.ds-v2-contact-panel {
  min-height: 100%;
  background: var(--color-background);
}
.ds-v2-contact-panel .vx-panel {
  width: 100%;
}
.ds-v2-contact-panel__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #f0f2f5;
  color: var(--color-primary-dark);
}
.ds-v2-contact-panel__heading .vx-icon {
  display: block;
}
.ds-v2-contact-panel__title {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.ds-v2-contact-panel__title .vx-icon {
  margin-right: 16px;
}
.ds-v2-contact-panel__back {
  padding: 0;
  margin: 0;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
}
.ds-v2-contact-panel__subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ds-v2-contact-panel .vx-fab-button {
  width: 21px;
  height: 21px;
}
.ds-v2-contact-panel__content .vx-panel {
  padding: 0px 20px 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid #f0f2f5;
}
.ds-v2-contact-panel__content .vx-panel-list {
  margin-bottom: 8px;
}
.ds-v2-contact-panel__content .vx-button {
  text-decoration: none;
}
.ds-v2-contact-panel .vx-badge {
  padding-left: 0.75rem;
  margin-right: 8px;
  margin-bottom: 8px;
}
.ds-v2-contact-panel__badge--no-icon {
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
}
.ds-v2-contact-panel__text {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 6px;
}
</style>
