import { ref } from 'vue';

export const useVersionedRequest = () => {
    const response = ref(null);
    const loading = ref(false);
    const error = ref(null);
    const currentRequestId = ref(0);

    const fetchData = async (requestFunction) => {
        currentRequestId.value++;
        const requestId = currentRequestId.value;

        loading.value = true;
        error.value = null;
        response.value = null;

        try {
            const result = await requestFunction;

            if (currentRequestId.value === requestId) {
                response.value = result;
            }

            return response.value;
        } catch (e) {
            if (currentRequestId.value === requestId) {
                error.value = e;

                throw e;
            }
        } finally {
            if (currentRequestId.value === requestId) {
                loading.value = false;
            }
        }
    };

    return { response, loading, error, fetchData };
};
