<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', false)"
        :padding="false"
        size="md"
        :stacked="configuring"
        >
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6 vx-flex vx-items-center vx-gap-2">
                <span>
                    AI Message Assistant
                </span>
                <experimental-pill class="-vx-mt-1"></experimental-pill>
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">Our AI-powered technology generates customized, compelling messages that engage customers
                and drive revenue growth.</div>
        </template>

        <div class="vx-py-4 vx-px-5 vx-flex vx-flex-col vx-gap-8 lg:vx-py-7 lg:vx-px-10">
            <ai-configuration-status v-if="!generating" @saved="refetch()" v-model:configuring="configuring" :unavailable="!!(error && configured)"></ai-configuration-status>

            <div v-if="generating" data-test="generating-messages" class="vx-flex vx-flex-col vx-items-center vx-justify-center vx-py-6 vx-space-y-8">
                <div class="vx-overflow-hidden">
                    <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
                </div>
                <p class="vx-text-center vx-text-base vx-text-primary lg:vx-px-24">
                    Your messages are being generated by our advanced AI tool...
                </p>
            </div>
            
            <div v-else-if="allSuggestions.length">
                <h4 class="vx-text-base vx-mb-3 vx-font-bold vx-text-slate-700">
                    Generated Messages
                </h4>
                <message-generation-list v-model="selected"
                    :messages="allSuggestions"></message-generation-list>
            </div>
        </div>

        <template v-slot:footer>
            <div class="vx-flex vx-gap-4">
                <template v-if="generating || error || !configured">
                    <vx-button @click.prevent="emit('update:visible', false)"
                        type="button"
                        color="muted"
                        size="lg"
                        class="vx-grow">
                        Cancel <font-awesome-icon :icon="faTimesCircle"></font-awesome-icon>
                    </vx-button>
                </template>
                <template v-else-if="allSuggestions.length">
                    <vx-button class="vx-grow-0"
                        color="muted-dark"
                        size="lg"
                        type="button"
                        @click="generate()">
                        Generate New
                        <font-awesome-icon :icon="faWandMagicSparkles"></font-awesome-icon>
                    </vx-button>
                    <vx-button class="vx-grow"
                        type="button"
                        size="lg"
                        @click="confirm()"
                        data-test="confirm-message">
                        Confirm
                        <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                    </vx-button>
                </template>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import {
    faArrowRight,
    faWandMagicSparkles,
    faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxModal, VxButton, VxIconLoading } from '@voxie/frontend-components';
import { ref, watch, nextTick, computed } from 'vue';
import aiClient from '../../../../services/aiClient';
import MessageGenerationList from './MessageGenerationList.vue';
import ExperimentalPill from "~/components/general/ExperimentalPill.vue";
import AiConfigurationStatus from "~/components/settings/integrations/ai-configuration/AiConfigurationStatus.vue"
import openAISettingsClient from '../../../../services/openAISettingsClient';

const props = defineProps({
    contactId: {
        type: Number,
        required: true,
    },
    visible: Boolean,
});

const emit = defineEmits(['selected', 'update:visible']);

const configuring = ref(false);

const allSuggestions = ref([]);
const selected = ref("");

const error = ref(false);
const generating = ref(false);

const config = ref(undefined);

const configured = computed(() => config.value?.settings?.tone && config.value?.settings?.role);

const getAiConfig = async () => {
    if (configured.value) {
        return
    }

    try {
        config.value = (await openAISettingsClient.getConfig(Spark.state.currentTeam.id)).data;
    } catch(e) {
        console.error(e)
        config.value = undefined;
    }
}

const generate = async () => {
    error.value = false;

    if (generating.value) {
        return;
    }

    if (!configured.value) {
        return;
    }

    generating.value = true;
    
    try {
        const response = await aiClient.messageHubSuggestions(Spark.state.currentTeam.id, {
            contact_id: props.contactId
        })
        
        allSuggestions.value = response.data.data.map(choice => choice?.message).filter(message => message?.length);

        await nextTick()
        if (!allSuggestions.value.length) {
            error.value = true;
        }
    } catch (e) {
        console.error(e)
        error.value = true;
    } finally {
        generating.value = false;
    }
};

const confirm = () => {
    if (!selected.value) {
        selected.value = allSuggestions.value[0]
    }

    emit("selected", selected.value);
    emit('update:visible', false);
    reset();
};

const reset = () => {
    allSuggestions.value = [];
    selected.value = "";
    error.value = false;
}

const refetch = async () => {
    await getAiConfig();
    generate();
}

watch([() => props.visible], async () => {
    if(!props.visible) {
        reset();
        return;
    }

    await getAiConfig();

    if (!allSuggestions.value.length) {
        generate();
    }
}, {
    immediate: true
})

</script>
