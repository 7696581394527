<template>
    <modal
        v-model:visible="visibleForModal"
        class="modal-suspend-reason"
        title="Suspension Reason"
        submit-text="Suspend"
        close-text="Cancel"
        @submit="reasonConfirm"
    >
        <p class="modal-suspend__text vx-mb-2">
            Please select the reason why this account is being suspended:
        </p>

        <template v-for="r in reasonsInputs" :key="r.id">
            <label
                class="font-weight-light text-sm tracking-wide text-gray-800"
                :for="r.id"
            >
                <input
                    type="radio"
                    v-model="reason"
                    :id="r.id"
                    :value="r.id"
                    @change="handleClickInput"
                    class="vx-translate-y-0.5"
                />
                {{ r.text }}
            </label>
        </template>

        <div class="c-field">
            <textarea
                v-if="reason === 'reasonCustomMessage'"
                class="c-input"
                v-model="message"
                maxlength="250"
            ></textarea>
            <small v-if="errors.message" class="c-field__message u-color-danger">
                <i class="fa fa-times-circle"></i>
                {{ errors.message }}
            </small>
        </div>
    </modal>
</template>

<script>
import Modal from '../../general/Modal.vue';

export default {
    components: {
        Modal
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            visibleForModal: false,
            reason: 'reasonInvalidPayment',
            message: null,
            errors: {
                message: null
            }
        }
    },
    computed: {
        reasonsInputs() {
            return [
                {
                    id: 'reasonInvalidPayment',
                    text: 'Invalid or refused Payment Method',
                },
                {
                    id: 'reasonRequestedSuspension',
                    text: 'The Client requested suspension',
                },
                {
                    id: 'reasonCustomMessage',
                    text: 'Custom Message',
                },
            ]
        }
    },
    methods: {
        handleClickInput() {
            if (this.reason !== 'reasonCustomMessage') {
                this.errors = {}
                this.message = null;
            }
        },
        reasonMessage() {
            if (this.reason === 'reasonCustomMessage') {
                return this.message
                } else {
                return this.reasonsInputs.find(item => item.id === this.reason)?.text;
            }
        },
        reasonConfirm() {
            if(this.reason === 'reasonCustomMessage' && !this.message?.trim().length) {
                this.errors.message = 'The message field is required.'
                return
            }

            this.$emit('submitSuspend', this.reasonMessage());

            this.visibleForModal = false;
            this.reason = 'reasonInvalidPayment';
            this.message = null;
            this.errors = {}
        },
    },
    watch: {
        visible: function(value) {
            this.visibleForModal = value
        },
        visibleForModal: function(value) {
            this.$emit('update:visible', value);
        },
    },
}
</script>

<style lang="postcss" scoped>
.modal-suspend-reason textarea {
    display: block;
    margin-top: 1rem;
}
</style>
