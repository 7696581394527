<template>
    <div>
        <page-header>
            API Auth
            <experimental-pill class="vx-ml-4 -vx-mt-2" type="alpha"></experimental-pill>
        </page-header>
        <page-controls>
            <template v-slot:left>
                <vx-button-group class="vx-w-full" @click.prevent="fetchParams.forward = !fetchParams.forward; applyFilters()" size="lg">
                    <vx-button
                        @click.prevent
                        class="vx-flex-grow @2xl/controls:vx-flex-grow-0"
                        type="button"
                        color="white"
                        shadow
                    >
                        <span class="vx-font-normal">Sort: Created at</span>
                    </vx-button>
                    <vx-button
                        @click.prevent
                        type="button"
                        color="white"
                        shadow
                    >
                        <font-awesome-icon
                            :icon="
                                fetchParams.forward
                                    ? faArrowUpWideShort
                                    : faArrowDownWideShort
                            "
                        ></font-awesome-icon>
                    </vx-button>
                </vx-button-group>
            </template>
            <template v-slot:right>
                <vx-popover
                    class="vx-w-full"
                    hover
                    :arrow="true"
                    placement="left">
                    <vx-button block
                        :disabled="!isAdminOrOwner"
                        size="lg"
                        color="primary"
                        href="/settings/integrations/auth-configs/new"
                    >
                        New API Auth
                        <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                    </vx-button>

                    <template v-if="!isAdminOrOwner" v-slot:content>
                        You do not have permission to create.
                    </template>
                </vx-popover>
            </template>
        </page-controls>

        <template v-if="!loading && !list.length">
            <vx-box color="info" class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                No API Auths yet.
                <vx-popover
                    hover
                    :arrow="true"
                    placement="left">
                    <vx-button :disabled="!isAdminOrOwner"
                        size="lg"
                        color="primary"
                        href="/settings/integrations/auth-configs/new"
                    >
                        New API Auth
                        <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                    </vx-button>

                    <template v-if="!isAdminOrOwner" v-slot:content>
                        You do not have permission to create.
                    </template>
                </vx-popover>
            </vx-box>
        </template>

        <vx-table v-else-if="list.length" class="lg:vx-grid-cols-2 xl:vx-grid-cols-4-auto" rowBreakpoint="xl">
            <vx-table-row isHeader>
                <div>
                    Name
                </div>
                <div>
                    Destinations
                </div>
                <div>
                    Created at
                </div>
            </vx-table-row>

            <auth-config-row v-for="(item, index) in list" :item="list[index]" :key="list[index].id" @deleted="removeFromList($event)"></auth-config-row>

        </vx-table>

        <div
            v-if="list.length"
            class="vx-mt-8 vx-flex vx-justify-between vx-w-full vx-gap-10"
        >
            <vx-button
                v-if="fetchParams.cursor"
                @click="fetchRecords()"
                color="muted-dark"
                size="xl"
                class="vx-grow-0"
                :loading="loading"
            >
                Load More
                <font-awesome-icon
                    :icon="faSpinner"
                ></font-awesome-icon>
            </vx-button>
        </div>
    </div>
</template>


<script setup>
import PageHeader from '../../../general/PageHeader.vue';
import PageControls from '../../../general/PageControls.vue';
import ExperimentalPill from '../../../general/ExperimentalPill.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCirclePlus, faArrowUpWideShort, faArrowDownWideShort, faSpinner } from '@fortawesome/pro-solid-svg-icons';

import { VxButtonGroup, VxButton, VxBox, VxTable, VxTableRow, VxPopover } from '@voxie/frontend-components';
import { onMounted, ref } from 'vue';

import AuthConfigRow from './AuthConfigRow.vue';
import { useToasted } from '../../../../composables/useToasted';
import authConfigsClient from '../../../../services/authConfigsClient';
import parseLinkHeader from 'parse-link-header';

const teamId = Spark.state.currentTeam.id;
const isAdminOrOwner = Spark.isAdminOrOwner;
const toasted = useToasted();

const defaultFilters = () => ({
    limit: 15,
    cursor: undefined,
    forward: true,
});

const list = ref([]);
const loading = ref(false);

const fetchParams = ref({});

const resetFilters = () => {
    fetchParams.value = defaultFilters();

    fetchRecords();
};

const applyFilters = () => {
    fetchParams.value = {
        ...fetchParams.value,
        cursor: undefined,
    }

    fetchRecords();
}

onMounted(() => {
    resetFilters();
});


const fetchRecords = async () => {
    if (loading.value) {
        return;
    }

    loading.value = true;
    try {
        const response = await authConfigsClient.list(teamId, {
            ...fetchParams.value,
        });

        list.value =
            !fetchParams.value?.cursor
                ? response.data.data
                : [...list.value, ...response.data.data];

        const nextLink = parseLinkHeader(response.headers?.link)?.[fetchParams.value.forward ? 'next' : 'prev'];
        const cursor = nextLink?.cursor;

        fetchParams.value = {
            ...fetchParams.value,
            cursor,
        }
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
};

const removeFromList = (itemId) => {
    list.value = list.value.filter((item) => item.id != itemId);

    if (!list.value.length) {
        fetchRecords();
    }
};

</script>
