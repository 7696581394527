<template>
    <div>
        <div class="vx-relative vx-px-4 lg:vx-px-6">
            <div class="vx-absolute vx-left-8 vx-top-0 vx-w-px vx-h-full vx-bg-slate-300 lg:vx-left-16"></div>

            <template v-for="(filter, filterIndex) in group.filters"
                :key="filter.id">
                <segment-step-form-filter v-model="group.filters[filterIndex]"
                    :isFirst="filterIndex === 0"
                    @removeFilter="removeFilter(filterIndex)" />
            </template>


            <vx-box class="vx-relative vx-p-3 vx-inline-flex vx-space-x-2">
                <vx-button @click="createFilter"
                    type="button"
                    size="lg"
                    color="primary"
                    data-test="create-filter">
                    And <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
                <vx-button v-if="props.isLast"
                    @click="emit('createGroup')"
                    type="button"
                    size="lg"
                    color="info"
                    data-test="create-group">
                    Or <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
            </vx-box>
        </div>

        <div v-if="!props.isLast"
            class="vx-relative vx-flex vx-p-8">
            <div
                class="vx-absolute vx-left-0 vx-top-1/2 -vx-translate-y-1/2 vx-w-full vx-h-px vx-border-solid vx-border-y vx-border-x-0 vx-border-t-slate-300 vx-border-b-white">
            </div>
            <div class="vx-relative vx-inline-block vx-border vx-border-solid vx-border-slate-300 vx-rounded-full vx-overflow-hidden"
                style="filter: drop-shadow(0px 1px 0px #FFFFFF);">
                <div
                    class="vx-bg-amber-50 vx-rounded-full vx-overflow-hidden vx-border-t vx-border-solid vx-border-white vx-border-b-0 vx-border-x-0 vx-text-base vx-font-extrabold vx-py-2 vx-px-6">
                    Or
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { VxButton, VxBox } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import SegmentStepFormFilter from './SegmentStepFormFilter.vue';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { makeFilter } from './segmentFiltersHelpers';

const group = defineModel({
    type: Object,
    required: true,
})
const props = defineProps({
    isLast: {
        type: Boolean,
        required: false,
    },
})

const emit = defineEmits(['createGroup', 'removeGroup']);

const createFilter = () => {
    group.value.filters = [...group.value.filters, makeFilter()];
}

const removeFilter = (filterIndex) => {
    if (group.value.filters.length === 1) {
        emit('removeGroup')
        return;
    }

    group.value.filters = group.value.filters.filter((filter, currentIndex) => currentIndex !== filterIndex)
};
</script>
