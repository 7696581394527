<template>
    <div>
        <div class="card card-default">
            <div class="card-body">
                <button
                    v-if="impersonator"
                    class="btn btn-outline-danger"
                    @click="confirmCancellation"
                    :disabled="form.busy"
                >
                    Cancel Subscription
                </button>
                <p v-else>
                    If you would like to <span class="text-danger"><b>cancel your subscription</b></span> please contact
                    <a href="mailto:support@voxiechat.com">support@voxiechat.com</a>.
                </p>
            </div>
        </div>

        <!-- Confirm Cancellation Modal -->
        <div class="modal" id="modal-confirm-cancellation" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Cancel Subscription
                        </h5>
                    </div>

                    <div class="modal-body">
                        Are you sure you want to cancel your subscription?
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">No, Go Back</button>

                        <button type="button" class="btn btn-danger" @click="cancel" :disabled="form.busy">

                        <span v-if="form.busy">
                            <i class="fa fa-btn fa-spinner fa-spin"></i> Cancelling
                        </span>

                            <span v-else>
                            Yes, Cancel
                        </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from '~/services/axios';

export default {
    props: {
        impersonator: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object
        },
        team: {
            type: Object
        },
        teamId: {
            type: Number
        }
    },
    data() {
        return {
            form: new SparkForm({}),
            team: null
        };
    },
    created() {
        const self = this;

        this.getTeam();

        Bus.$on('updateTeam', function () {
            self.getTeam();
        });

        Bus.$on(`sparkFormUpdated.${this.form.sparkFormId}`, this.$forceUpdate);
    },
    mounted() {
        this.usePushStateForTabs('.spark-settings-tabs');
    },
    methods: {
        confirmCancellation() {
            $('#modal-confirm-cancellation').modal('show');
        },
        cancel() {
            Spark.delete(this.urlForCancellation, this.form)
                .then(() => {
                    Bus.$emit('updateTeam');

                    $('#modal-confirm-cancellation').modal('hide');
                });
        },
        getTeam() {
            axios.get(`/settings/teams/json/${this.teamId}`)
                .then(response => {
                    this.team = response.data;
                });
        },
        usePushStateForTabs(selector) {
            this.pushStateSelector = selector;

            this.registerTabClickHandler();

            window.addEventListener('popstate', () => {
                this.activateTabForCurrentHash();
            });

            if (window.location.hash) {
                this.activateTabForCurrentHash();
            } else {
                this.activateFirstTab();
            }
        },
        registerTabClickHandler() {
            const self = this;

            $(`${this.pushStateSelector} a[data-toggle="tab"]`).on('click', function() {
                self.removeActiveClassFromTabs();

                history.pushState(null, null, '#/' + $(this).attr('href').substring(1));

                self.broadcastTabChange($(this).attr('href').substring(1));
            });
        },
        activateTabForCurrentHash() {
            let hash = window.location.hash.substring(2);

            const parameters = hash.split('/');

            hash = parameters.shift();

            this.removeActiveClassFromTabs();

            const tab = $(`${this.pushStateSelector} a[href="#${hash}"][data-toggle="tab"]`);

            if (tab.length > 0) {
                tab.tab('show');

                this.broadcastTabChange(hash, parameters);
            } else {
                this.activateFirstTab();
            }

        },
        activateFirstTab() {
            const tab = $(`${this.pushStateSelector} a[data-toggle="tab"]`).first();

            if (tab.length) {
                tab.tab('show');

                this.broadcastTabChange(tab.attr('href').substring(1));
            }
        },
        removeActiveClassFromTabs() {
            $(`${this.pushStateSelector} a`).removeClass('active');
        },
        broadcastTabChange(hash, parameters) {
            Bus.$emit('sparkHashChanged', hash, parameters);
        }
    },
    computed: {
        urlForCancellation() {
            return `/settings/teams/${this.team.id}/subscription`;
        }
    }
}
</script>
