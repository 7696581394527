<template>
    <tr class="c-table__row">
        <td class="hidden-xs hidden-sm c-table__cell c-table__cell--img o-media">

            <div class="o-media__img u-mr-xsmall u-mt-medium">
                <contact-avatar :contact="contact"></contact-avatar>
            </div>

            <div class="o-media__body u-mt-medium" data-test="contact-name">
                {{ contact.first_name || 'Anonymous' }} {{ contact.last_name || 'User' }}
                <span class="u-block u-text-mute u-text-xsmall">Added {{ shortDateTime(contact.created_at) }} </span>
            </div>
        </td>

        <td class="c-table__cell identity-cell">

            <span class="visible-xs u-block u-text-mute">
                <span
                    class="u-text-xsmall"
                    data-test="identity-name">{{ contact.first_name || 'Anonymous' }} {{ contact.last_name || 'User' }}</span>
            </span>

            <span class="u-block u-text-mute" v-if="contact.phone" data-test="contact-phone">
                <i class="fa fa-mobile-phone fa-lg"></i>
                <span class="u-text-xsmall">{{ contact.phone }}</span>
            </span>
            <span class="u-block u-text-mute" v-if="contact.email" data-test="contact-email">
                <i class="fa fa-envelope-o"></i>
                <span class="u-text-xsmall">{{ contact.email }}</span>
            </span>
        </td>
        <td class="hidden-xs c-table__cell center">
            <span class="u-block huge-text center" data-test="contact-segments">
                <i v-if="loadingSegmentsCount" class="fa fa-spin fa-circle-o-notch" />
                <template v-else-if="segmentsCount !== null">
                    {{ segmentsCount }}
                </template>
            </span>
        </td>
        <td class="hidden-xs c-table__cell center">
            <span class="u-block huge-text center" data-test="contact-tags">{{ contact.tags.length }}</span>
        </td>
        <td class="hidden-xs c-table__cell center">
            <i v-if="loadingSubscriptions" class="huge-text fa fa-spin fa-circle-o-notch" />
            <contact-status-v2 v-else :subscriptions="subscriptions" />
        </td>

        <td class="c-table__cell center">

            <a :href="'/contacts/' + contact.id + '/view'" class="c-btn c-btn--secondary" data-test="contact-link">View</a>

        </td>
    </tr>
</template>


<script>
    import { shortDateTime } from '../../../utils/date';
    import { contactsClient } from '../../../services';
    import ContactAvatar from '../ContactAvatar.vue';
    import ContactStatusV2 from '../shared/ContactStatusV2.vue'

    export default {
        props: ['contact'],
        components: {
            ContactAvatar,
            ContactStatusV2,
        },
        data() {
            return {
                teamId: Spark.state.currentTeam.id,
                loadingSegmentsCount: true,
                loadingSubscriptions: true,
                segmentsCount: null,
                subscriptions: [],
            };
        },
        inject: ['FEATURES'],
        methods: {
            shortDateTime,
            getSubscriptions() {
                contactsClient
                    .getContactSubscriptionStatus(this.teamId, this.contact.id)
                    .then(({ data }) => {
                        const subscriptions = data.data;

                        const hasMarketingSubscription = !!subscriptions.find(subscription => subscription.type === 'marketing');
                        const hasTransactionalSubscription = !!subscriptions.find(subscription => subscription.type === 'transactional');

                        if (!hasMarketingSubscription) {
                            subscriptions.push({
                                type: 'marketing',
                                status: 'unknown',
                                reason_text: '-',
                                updated_at: this.contact.created_at
                            });
                        }

                        if (!hasTransactionalSubscription) {
                            subscriptions.push({
                                type: 'transactional',
                                status: 'unknown',
                                reason_text: '-',
                                updated_at: this.contact.created_at
                            });
                        }

                        this.subscriptions = subscriptions;
                    })
                    .finally(() => {
                        this.loadingSubscriptions = false;
                    });
            }
        },
        created() {
            contactsClient.contactsSegmentsCount(this.teamId, this.contact.id)
                .then(({ data }) => {
                    this.segmentsCount = data.count;
                })
                .finally(() => {
                    this.loadingSegmentsCount = false;
                });

            this.getSubscriptions();
        },
    }
</script>


<style>
    .identity-cell i {
        width: 17px;
    }

    .identity-cell i.fa-mobile-phone {
        width: 14px;
        margin-left: 2px;
    }
</style>
