import BaseClient from './BaseClient';

class SingleSignOnClient extends BaseClient {
    getConfig(teamId) {
        const url = `/api/v3/teams/${teamId}/organization/sso/config`;
        return this.makeCoreApiRequest(url);
    }

    updateConfig(teamId, data) {
        const url = `/api/v3/teams/${teamId}/organization/sso/config`;
        return this.makeCoreApiRequest(url, 'put', data);
    }

    getConnection(teamId) {
        const url = `/api/v3/teams/${teamId}/organization/sso/saml2/connection`;
        return this.makeCoreApiRequest(url);
    }

    createConnection(teamId, data) {
        const url = `/api/v3/teams/${teamId}/organization/sso/saml2/connection`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    deleteConnection(teamId) {
        const url = `/api/v3/teams/${teamId}/organization/sso/saml2/connection`;
        return this.makeCoreApiRequest(url, 'delete');
    }

    getRoleMapping(teamId) {
        const url = `/api/v3/teams/${teamId}/organization/sso/saml2/connection/role-mapping`;
        return this.makeCoreApiRequest(url);
    }

    updateRoleMapping(teamId, data) {
        const url = `/api/v3/teams/${teamId}/organization/sso/saml2/connection/role-mapping`;
        return this.makeCoreApiRequest(url, 'put', data);
    }
}

export default new SingleSignOnClient();
