<template>
    <vx-modal :visible="visible" @update:visible="updateVisible($event)" class="vx-max-w-lg" :padding="false">
        <template v-slot:header>
            <div class="vx-text-2xl vx-pt-6 vx-px-6" :class="{
                'vx-pb-6': model.id,
            }">
                {{ model.id ? 'Edit User' : 'Add User' }}
            </div>
        </template>
        <template v-if="!model.id" v-slot:subheader>
            <div class="vx-px-6">
                Invite a new user to your team.
            </div>
        </template>

        <div class="vx-p-6">
            <div class="vx-flex vx-flex-col vx-gap-4">
                <vx-alert v-if="error" color="danger" :closeable="false">
                    {{ error }}
                </vx-alert>
                <div>
                    <vx-label>Email</vx-label>
                    <vx-input data-test="user-email" type="email" v-model="model.email" :disabled="model.id" size="lg" :error="displayFirstError(validate.email)">
                    </vx-input>
                </div>
                <div>
                    <vx-label>Role</vx-label>
                    <vx-selectable v-model="model.role"
                        size="lg"
                        relative-dropdown
                        :reduce="option => option.value"
                        :options="teamRolesOptions(['owner'])"
                        :clearable="false"
                        :error="displayFirstError(validate.role)"
                    >
                    </vx-selectable>
                </div>
            </div>
        </div>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="updateVisible(false)"
                    type="button"
                    class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                    size="lg"
                    color="muted"
                >
                    Cancel
                </vx-button>
                <vx-button @click="submit()"
                    data-test="invite-button"
                    :loading="submitting"
                    type="button"
                    class="vx-grow"
                    size="lg"
                    color="primary"
                >
                    {{ model.id ? 'Update' : 'Invite' }}
                    <font-awesome-icon v-if="!model.id" :icon="faPaperPlane"></font-awesome-icon>
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>
<script setup>
import { ref } from 'vue';
import { VxAlert, VxButton, VxInput, VxLabel, VxModal, VxSelectable } from '@voxie/frontend-components';
import { usersClient } from '~/services';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_UNPROCESSABLE_ENTITY } from '~/support/HttpStatuses';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { teamRolesOptions } from '~/constants/roles';
import { displayFirstError } from '~/utils/validation';
import useVuelidate from '@vuelidate/core';
import { email, maxLength, required } from '@vuelidate/validators';
import invitationsClient from '~/services/invitationsClient';

const visible = defineModel('visible', {
    type: Boolean,
    default: false,
});

const model = defineModel({
    type: Object,
    required: false,
    default: () => ({
        email: '',
        role: '',
    })
});

const error = ref('');

const validate = useVuelidate(
    {
        email: {
            maxLength: maxLength(255),
            required: required,
            email: email,
        },
        role: {
            required: required,
        }
    },
    model,
    {
        $autoDirty: true,
    }
)

const emit = defineEmits(['invited', 'updated']);

const teamId = Spark.state.currentTeam.id;
const submitting = ref(false);

const updateVisible = (isVisible) => {
    if (!model.value.id) {
        model.value.email = '';
        model.value.role = '';
    }
    validate.value.$reset();
    error.value = '';
    visible.value = isVisible;
}

const submit = async () => {
    const isValid = await validate.value.$validate();

    if (!isValid) {
        return;
    }

    if (submitting.value) {
        return;
    }

    error.value = '';

    submitting.value = true;

    try {
        if (!model.value.id) {
            await invitationsClient.sendInvite(teamId, model.value);
            emit('invited');
        } else {
            await usersClient.updateUser(teamId, model.value.id, model.value);
            emit('updated');
        }
        updateVisible(false);
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            error.value = 'Something went wrong.'
        } else {
            if (e.response.status === HTTP_UNPROCESSABLE_ENTITY) {
                error.value = e.response.data.errors
            }
            error.value = e.response?.data?.message || 'Something went wrong.';
        }
    } finally {
        submitting.value = false;
    }
}
</script>
