<template>
    <div>
        <div class="vx-text-slate-700 vx-text-base vx-font-bold">Company Pitch</div>
        <div class="vx-text-sm vx-leading-tight vx-mb-4 vx-text-slate-500">
            Describe your company and what they do. The more details you provide the better the AI can respond.
        </div>

        <vx-input class="vx-min-h-[144px]" counter :minlength="100" :minlengthWarning="200" :maxlength="3600" placeholder="Describe your company and what they do." size="lg" autoresize :disabled="props.disabled" :error="props.error"
            :modelValue="props.modelValue" @update:modelValue="emit('update:modelValue', $event)">
        </vx-input>
    </div>
</template>

<script setup>
import {
    VxInput,
} from "@voxie/frontend-components";

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    error: {
        type: String,
        default: undefined,
    },
})

const emit = defineEmits(['update:modelValue']);
</script>
