<template>
    <vx-table-row>
        <vx-table-row-item>
            <template v-slot:label> Audience Type: </template>
            <div class="vx-break-all">
                {{ audienceType }}
            </div>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Status: </template>
            <contact-subscription-badge
                :type="props.modelValue.type"
                :status="props.modelValue.status"></contact-subscription-badge>
        </vx-table-row-item>

        <vx-table-row-item class="xl:!vx-col-span-2 xl:vx-py-2">
            <template v-slot:label> Reason: </template>
            <div class="vx-break-all">
                {{ props.modelValue.reason_text }}
            </div>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Updated at: </template>

            <vx-popover hover
                        :arrow="true"
                        placement="top"
                        :key="dateTimeKey">
                {{ fromNow(props.modelValue.updated_at) }}
                <template v-slot:content>
                    {{ shortDateTimeTzSpread(props.modelValue.updated_at) }}
                </template>
            </vx-popover>
        </vx-table-row-item>

    </vx-table-row>
</template>

<script setup>
import {
    VxPopover,
    VxTableRow,
    VxTableRowItem,
} from "@voxie/frontend-components";
import { fromNow, shortDateTimeTzSpread } from '~/utils/date';
import { useInterval } from '@vueuse/core';
import { computed } from 'vue';
import { audienceTypeLabel } from '../../../../constants/campaign';
import ContactSubscriptionBadge from '../ContactSubscriptionBadge.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

const dateTimeKey = useInterval(60000);

const audienceType = computed(() =>
    audienceTypeLabel(props.modelValue.type)
);
</script>
