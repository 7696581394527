import { createStore } from 'vuex';
import threads from './modules/threads';
import contacts_v3 from './modules/v3/contacts';

export const store = createStore({
    modules: {
        threads,
        contacts_v3,
    },

    plugins: [
        //
    ],

    state: {
        //
    },

    getters: {
        //
    },

    mutations: {
        //
    },

    actions: {
        //
    },
});
