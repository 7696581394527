<template>
    <div
        v-if="!lazy || visible"
        class="base-modal"
        :class="{ 'base-modal-show': !lazy && visible, 'base-modal-hide': !lazy && !visible }"
    >
        <div
            :id="id"
            ref="modalRef"
            class="modal fade"
            :class="{ show: visible }"
            role="dialog"
            aria-modal="true"
            @click="onDialogClick"
        >
            <div class="modal-dialog modal-dialog-centered" :class="`modal-${size}`">
                <div class="modal-content" tabindex="-1">
                    <header
                        class="modal-header justify-content-center justify-content-xs-between align-items-center modal-header-mobile"
                        :class="{ 'd-sm-none': !title }"
                    >
                        <slot name="modal-header">
                            <span
                                data-test="mobile-close-button"
                                class="action-link d-xs-visible invisible"
                                @click="close"
                            >
                                {{ closeText }}
                            </span>
                            <h5 class="modal-title">
                                {{ title }}
                            </h5>
                            <span class="action-link invisible">
                                {{ closeText }}
                            </span>
                        </slot>
                    </header>

                    <div class="modal-body d-flex flex-column">
                        <slot />
                    </div>

                    <footer class="modal-footer justify-content-center">
                        <slot name="modal-footer">
                            <button
                                data-test="close-button"
                                class="btn"
                                :class="{ 'btn-default d-none d-sm-block': !doneBtnOnly, 'btn-primary': doneBtnOnly }"
                                type="button"
                                @click="close"
                            >
                                {{ doneBtnOnly ? 'Done' : closeText }}
                            </button>
                            <button
                                v-if="!doneBtnOnly && !noSubmitBtn"
                                data-test="submit-button"
                                class="btn flex-grow-1 flex-sm-grow-0"
                                :class="`btn-${submitVariant}`"
                                type="button"
                                @click="submit"
                                :disabled="submitDisabled"
                            >
                                {{ submitText }}
                            </button>
                        </slot>
                    </footer>
                </div>
            </div>
        </div>
        <div class="modal-backdrop"></div>
    </div>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue';
import { containerSortedFocusableElements, ESCAPE_KEY, TAB_KEY } from '../../utils/dom';

const props = defineProps({
    id: String,
    visible: Boolean,
    backdropStatic: Boolean,
    title: String,
    submitText: {
        type: String,
        default: 'Submit',
    },
    submitVariant: {
        type: String,
        default: 'primary',
    },
    submitDisabled: Boolean,
    closeText: {
        type: String,
        default: 'Close',
    },
    doneBtnOnly: Boolean,
    noSubmitBtn: Boolean,
    size: {
        type: String,
        default: 'sm',
        validator: (size) => ['sm', 'md', 'lg'].includes(size),
    },
    lazy: {
        type: Boolean,
        default: false,
    },
    zIndex: {
        type:Number,
        default: 1040
    }
});

const emit = defineEmits(['update:visible', 'submit', 'shown', 'hidden']);

const modalRef = ref(null);
const modalFocusableElements = ref([]);
const currentFocusedElementIndex = ref(0);

const addModalListeners = () => {
    modalRef.value?.addEventListener('keydown', escEventListener);
    modalRef.value?.addEventListener('keydown', tabEventListener);
};
const removeModalListeners = () => {
    modalRef.value?.removeEventListener('keydown', escEventListener);
    modalRef.value?.removeEventListener('keydown', tabEventListener);
};
const getFocusableElements = () => {
    modalFocusableElements.value = containerSortedFocusableElements(modalRef.value);
};

const setFocus = (index) => {
    modalFocusableElements.value[index]?.focus({ preventScroll: true });
    currentFocusedElementIndex.value = index;
};

const escEventListener = (event) => {
    event.keyCode === ESCAPE_KEY && close();
};
const tabEventListener = (event) => {
    if (event.keyCode === TAB_KEY) {
        event.preventDefault();

        if (modalFocusableElements.value.length === 0) return;

        const nextIndex = event.shiftKey
            ? (currentFocusedElementIndex.value + modalFocusableElements.value.length - 1) %
              modalFocusableElements.value.length
            : (currentFocusedElementIndex.value + 1) % modalFocusableElements.value.length;

        setFocus(nextIndex);
    }
};

watch(
    () => props.visible,
    async () => {
        if (props.visible) {
            await nextTick();
            emit('shown');
            addModalListeners();
            getFocusableElements();
            setFocus(0);
        } else {
            emit('hidden');
            removeModalListeners();
        }
    }
);

const close = () => emit('update:visible', false);
const submit = () => emit('submit');
const onDialogClick = (event) => {
    event.target === modalRef.value && close();
};
</script>
<style lang="postcss">
.base-modal {
    position: absolute;
    z-index: v-bind("props.zIndex");
}

.base-modal .modal {
    display: block;
    overflow-y: auto;
}

.base-modal-hide {
    display: none;
}

.base-modal-show {
    display: block;
}
</style>
