import BaseClient from './BaseClient';

class TrackingClient extends BaseClient {
    track(eventName, appVariant) {
        return this.makeCoreApiRequest('/api/v3/track', 'post', {
            event_name: eventName,
            location: window.location.href,
            app_variant: appVariant ?? null,
        });
    }
}

export default new TrackingClient();
