import BaseClient from './BaseClient';

class MessagesClient extends BaseClient {
    /** Messages contacts operations */
    messagesGetContacts($contactId, params) {
        const method = 'get';
        const url = `/api/messages/contacts/${$contactId}`;

        return this.makeCoreApiRequest(url, method, null, params);
    }

    create(data) {
        const url = '/api/messages';
        return this.makeCoreApiRequest(url, 'post', data);
    }

    listMessages(teamId, contactId, params, config = {}) {
        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/messages`;
        return this.makeCoreApiRequest(url, 'get', null, params, config);
    }

    listMessagesUsingLink(link) {
        return this.makeCoreApiRequest(link);
    }

    createV3(teamId, data) {
        const url = `/api/v3/teams/${teamId}/messages`;
        return this.makeCoreApiRequest(url, 'post', data);
    }
}

export default new MessagesClient();
