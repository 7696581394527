import { HTTP_NOT_FOUND } from '../../support/HttpStatuses';
import { teamsClient, threadsClient } from '../../services';
import { unionBy } from '~/utils/helpers';
import { removeOldestThread } from '../../utils/threads';
import parseLinkHeader from 'parse-link-header';
import { isLargeTeam } from '~/utils/team';

let threadsGetAllAbort;
let threadsGetSummaryAbort;

const initialThreadFilters = {
    tag: null,
    campaign: null,
    assignee: null,
    forward: 'false',
    limit: 15,
    search: null,
    status: null,
};

export default {
    state: {
        threadsInboxes: {
            unassigned: 0,
            open: 0,
            me: 0,
            closed: 0,
            all: 0,
        },
        threads: [],
        activeThreadsInbox: null,
        showRespondToNextOn: [],
        selectedThreads: [],
        loadingThreads: false,
        loadingMoreThreads: false,
        agents: [],
        currentMaxThreadsNumber: 15,
        linkHeader: null,
        threadFilters: { ...initialThreadFilters },
    },
    getters: {
        getThreads(state) {
            return state.threads;
        },
        getActiveThread(state) {
            return state.threads.find((t) => !!t.active);
        },
        getThreadInboxes(state) {
            let object = {};
            Object.keys(state.threadsInboxes).forEach((key) => {
                if (['closed', 'all'].includes(key)) {
                    object[key] = 0;
                } else {
                    object[key] = parseInt(state.threadsInboxes[key]) > 999 ? '999+' : state.threadsInboxes[key] || 0;
                }
            });

            return object;
        },
    },
    mutations: {
        setLoadingThreads(state, bool) {
            state.loadingThreads = bool;
        },

        setSelectedThreads(state, threads) {
            state.selectedThreads = threads;
        },

        pullSelectedThread(state, thread) {
            state.selectedThreads = state.selectedThreads.filter((t) => t.contact_id !== thread.contact_id);
        },

        pushSelectedThread(state, thread) {
            state.selectedThreads = [...state.selectedThreads, thread];
        },

        setThreadsInboxes(state, inboxes) {
            state.threadsInboxes = inboxes;
        },

        setActiveThreadsInbox(state, threadsInbox) {
            state.activeThreadsInbox = threadsInbox;
        },

        setThreads(state, threads) {
            state.threads = threads;
        },

        setActiveThread(state, thread) {
            state.threads = state.threads.map((t) => {
                t.active = t.contact_id === thread?.contact_id;
                return t;
            });
        },

        setShowRespondToNextOn(state, inboxes) {
            state.showRespondToNextOn = inboxes;
        },

        setAgents(state, agents) {
            state.agents = agents;
        },

        setClaim(state, { claimData }) {
            state.threads = state.threads.map((t) => {
                if (claimData && t.contact_id === claimData.contact_id) {
                    if (claimData.new_assignee_id) {
                        t.assignee_id = claimData.new_assignee_id;
                    } else {
                        t.assignee_id = null;
                    }
                }

                return t;
            });
        },

        setState(state, { thread, newState }) {
            state.threads = state.threads.map((t) => {
                if (t.contact_id === thread.contact_id) {
                    t.status = newState;
                }
                return t;
            });
        },

        setThread(state, { thread }) {
            if (thread && thread.contact_id) {
                state.threads = state.threads.map((t) => {
                    if (t.contact_id === thread.contact_id) {
                        t = { ...t, ...thread };
                    }
                    return t;
                });
            }
        },

        incrementThreadUnread(state, { thread }) {
            if (thread && thread.contact_id) {
                state.threads = state.threads.map((t) => {
                    if (t.contact_id === thread.contact_id) {
                        t.unread++;
                    }
                    return t;
                });
            }
        },

        setUnread(state, { thread, unread }) {
            if (thread && thread.contact_id) {
                state.threads = state.threads.map((t) => {
                    if (t.contact_id === thread.contact_id) {
                        t.unread = unread;
                    }
                    return t;
                });
            }
        },

        closeThread(state, { thread }) {
            state.threads.map((t) => {
                if (t.contact_id === thread.contact_id) {
                    t.status = 'closed';
                }
                return t;
            });
        },

        pushThreads(state, threads) {
            state.threads = unionBy(state.threads, threads, 'contact_id');
        },

        upsertThread(state, { thread }) {
            let mappedThread = {
                contact_id: thread.contact_id,
                team_id: thread.team_id,
                contact_name: thread.contact_name,
                contact_phone: thread.contact_phone,
                last_message_at: thread.last_message_at,
                last_message_content: thread.last_message_content,
                last_message_id: thread.last_message_id,
                message_count: thread.message_count,
                status: thread.status,
                assignee_id: thread.assignee_id,
            };

            const existingThreadIndex = state.threads.findIndex((t) => t.contact_id === thread.contact_id);
            const threads =
                existingThreadIndex !== -1
                    ? state.threads.map((t) => {
                          if (t.contact_id === thread.contact_id) {
                              t = { ...t, ...mappedThread };
                          }
                          return t;
                      })
                    : [mappedThread, ...state.threads];

            // Sort.
            const { forward } = state.threadFilters;

            threads.sort((a, b) => {
                const diff = a.last_message_id - b.last_message_id;
                const coef = forward === 'true' ? 1 : -1;
                return coef * diff;
            });

            // Remove oldest thread if max threads limit is exceeded.
            state.threads = removeOldestThread(threads, state.currentMaxThreadsNumber);
        },

        deleteThread(state, { thread }) {
            state.threads = state.threads.filter((t) => t.contact_id !== thread.contact_id);
        },

        setCurrentMaxThreadsNumber(state, { number }) {
            state.currentMaxThreadsNumber = number;
        },

        removeOldestThread(state) {
            state.threads = removeOldestThread(state.threads, state.currentMaxThreadsNumber);
        },

        setThreadFilters(state, threadFilters) {
            state.threadFilters = {
                ...state.threadFilters,
                ...threadFilters,
            };
        },
        setLinkHeader(state, linkHeader) {
            state.linkHeader = linkHeader;
        },
        resetThreadFilters(state) {
            state.threadFilters = { ...initialThreadFilters };
        },
    },
    actions: {
        async fetchThreadsInboxes({ state, commit }, { teamId }) {
            threadsGetSummaryAbort?.abort();
            threadsGetSummaryAbort = new AbortController();

            const result = await threadsClient.threadsGetSummary(teamId, { signal: threadsGetSummaryAbort.signal });
            const threadsInboxes = {
                unassigned: result.data.unassigned,
                open: result.data.open,
                me: result.data.me,
                closed: result.data.closed,
                all: result.data.all,
            };

            commit(
                'setActiveThreadsInbox',
                state.activeThreadsInbox || Object.keys(threadsInboxes).find((ti) => ti !== undefined)
            );
            commit('setThreadsInboxes', threadsInboxes);

            return result;
        },

        async fetchThreads({ commit }, { teamId, payload }) {
            threadsGetAllAbort?.abort();
            threadsGetAllAbort = new AbortController();

            commit('setLoadingThreads', true);

            const params =
                payload.search && isLargeTeam() ? { ...payload, search: null, phone: payload.search } : { ...payload };

            const result = await threadsClient.threadsGetAll(teamId, params, { signal: threadsGetAllAbort.signal });
            const threads = result.data.data;

            commit('setThreads', threads);
            commit('setLoadingThreads', false);
            commit('setLinkHeader', result.headers.link);

            return result;
        },

        async fetchMoreThreads({ state, commit }) {
            if (state.loadingMoreThreads) {
                return Promise.resolve({});
            }

            state.loadingMoreThreads = true;

            const links = parseLinkHeader(state.linkHeader);

            const expectedLink = state.threadFilters.forward === 'true' ? 'next' : 'prev';

            if (!links[expectedLink]?.url) {
                state.loadingMoreThreads = false;
                return Promise.resolve({});
            }

            try {
                const result = await threadsClient.threadsListUsingLink(links[expectedLink].url);
                commit('setLinkHeader', result.headers.link);
                commit('pushThreads', result.data.data);

                return result;
            } finally {
                state.loadingMoreThreads = false;
            }
        },

        async fetchAgents({ commit }) {
            const teamId = Spark.state.currentTeam.id;
            let response = await teamsClient.getTeamAgents(teamId);
            commit('setAgents', response.data);

            return response;
        },

        async fetchShowRespondToNextOn({ commit }) {
            return new Promise((resolve) => {
                const inboxes = ['unassigned', 'open', 'me', 'closed', 'all'];
                commit('setShowRespondToNextOn', inboxes);

                resolve(inboxes);
            });
        },

        async unAssignThread({ commit }, { thread }) {
            const teamId = Spark.state.currentTeam.id;

            const result = await threadsClient.threadsUnclaim(teamId, thread.contact_id);
            commit('setClaim', {
                claimData: {
                    contact_id: thread.contact_id,
                },
            });

            return result;
        },

        async assignThread({ commit }, { thread, assigneeId }) {
            const teamId = Spark.state.currentTeam.id;
            const assignToMySelf = assigneeId === Spark.state.user.id;

            let response = assignToMySelf
                ? await threadsClient.threadsAssignToSelf(teamId, thread.contact_id)
                : await threadsClient.threadsAssignTo(teamId, thread.contact_id, { assignee_id: assigneeId });

            commit('setClaim', {
                claimData: {
                    contact_id: thread.contact_id,
                    new_assignee_id: response.data.assignee_id,
                },
            });
        },

        async changeState({ commit }, { thread, state }) {
            const teamId = Spark.state.currentTeam.id;

            const result = await threadsClient.threadsState(teamId, thread.contact_id, state);
            commit('setState', { thread, newState: state });

            return result;
        },

        async storeCheckpoint({ commit }, { thread }) {
            const teamId = Spark.state.currentTeam.id;

            const result = await threadsClient.threadsCheckpointSave(teamId, thread.contact_id);
            commit('setUnread', { thread, unread: 0 });

            return result;
        },

        async destroyCheckpoint({ commit }, { thread }) {
            const teamId = Spark.state.currentTeam.id;
            const userId = Spark.state.user.id;

            const result = await threadsClient.threadsCheckpointDelete(teamId, thread.contact_id, userId);
            commit('setUnread', { thread, unread: thread.message_count });

            return result;
        },

        async checkpointGet({ commit }, { thread }) {
            const teamId = Spark.state.currentTeam.id;
            try {
                const result = await threadsClient.threadsCheckpointGet(teamId, thread.contact_id);
                let unread = thread.message_count - result.data.message_count;
                commit('setUnread', { thread: thread, unread: unread < 0 ? 0 : unread });

                return result;
            } catch (e) {
                if (e.response.status === HTTP_NOT_FOUND) {
                    commit('setUnread', { thread: thread, unread: thread.message_count });
                    return null;
                } else {
                    console.log(e);
                    throw e;
                }
            }
        },
    },
};
