<template>
    <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8 2xl:vx-max-w-screen-xl">

        <page-header>
            Custom Attributes
        </page-header>

        <custom-attributes-controls class="vx-mb-8 vx-mt-10"
            v-model="fetchParams"
            @reset="resetFilters()"
            @apply="applyFilters()"></custom-attributes-controls>

        <div v-if="loading"
            class="vx-text-center vx-py-10">
            <vx-icon-loading class="vx-w-9 vx-h-9" spin></vx-icon-loading>
        </div>

        <vx-box color="info"
            v-if="!loading && !list.length"
            class="vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
            No custom attributes found.
        </vx-box>

        <div v-else-if="list.length"
            class="vx-flex vx-flex-col vx-gap-2">
            <vx-box v-for="item in list"
                :key="item"
                class="vx-text-slate-700 vx-flex vx-items-center vx-p-4 vx-gap-y-1 vx-gap-x-2 vx-justify-between sm:vx-gap-x-4 ">
                <span class="vx-font-mono vx-grow vx-text-sm">{{ item.key }}</span>
                <vx-button @click.prevent="deleteCustomAttribute(item.key)"
                    data-test="delete-row"
                    class="vx-shrink-0"
                    type="button"
                    color="muted"
                    outline>
                    Delete
                    <font-awesome-icon :icon="faTrash" />
                </vx-button>
            </vx-box>
        </div>
        <div v-if="fetchParams.cursor"
            class="vx-mt-8 vx-flex vx-justify-between vx-items-center vx-w-full vx-gap-10">
            <vx-button @click="loadData()"
                color="muted-dark"
                size="xl"
                data-test="load-more-btn"
                class="vx-grow sm:vx-grow-0">
                Load More
                <font-awesome-icon :icon="faSpinner"
                    :spin="loading"></font-awesome-icon>
            </vx-button>
        </div>
    </div>
</template>

<script setup>
import { faSpinner, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBox, VxButton, VxIconLoading } from '@voxie/frontend-components';
import { onMounted, ref } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';
import { useToasted } from '~/composables/useToasted';
import { customAttributesClient } from '~/services';
import CustomAttributesControls from './CustomAttributesControls.vue';
import { useCursorPagination } from '../../../composables/useCursorPagination';

const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;

const deleting = ref(false);

const defaultFilters = () => ({
    query: '',
    cursor: null,
});

const resetFilters = () => {
    fetchParams.value = defaultFilters();

    loadData();
};

const applyFilters = () => {
    fetchParams.value = {
        ...fetchParams.value,
        cursor: null,
    }

    loadData();
}

onMounted(() => {
    resetFilters();
});

const deleteCustomAttribute = (key) => {
    if (!window.confirm('Are you sure? There is no undo.')) {
        return;
    }
    if (deleting.value) {
        return;
    }
    deleting.value = true;

    customAttributesClient.delete(teamId, key).then(() => {
        list.value = list.value.filter((item) => item.key !== key);
    }).catch((error) => {
        console.error(error);
        toasted.global.platform_error();
    }).finally(() => {
        deleting.value = false;
    });

};

const loadData = () => {
    fetchRecords(customAttributesClient.list(teamId, {...fetchParams.value}));
};

</script>
