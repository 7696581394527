<template>
    <div class="vx-my-2 vx-mx-4 vx-pb-4">
        <message-builder v-model="data.body" v-model:mediaUrl="data.media_url"
            v-model:mediaContentType="data.media_content_type"
            @selected:snippet="data.selectedSnippetBody = $event.body" @submit="deliverMessage()"
            @schedule="data.sendAt = $event; data.isScheduledMessage = true; deliverMessage()">
            <template v-slot:actions v-if="FEATURES.message_hub_ai">
                <vx-dropdown-item @click.prevent="data.aiModal = true">
                    <span class="vx-flex vx-items-center vx-gap-1">
                        <font-awesome-icon :icon="faWandMagicSparkles" class="vx-text-sm"></font-awesome-icon>
                        <span>AI Message</span>
                        <experimental-pill class="vx-pointer-events-none" placement="right"></experimental-pill>
                    </span>
                </vx-dropdown-item>
            </template>
            <template v-if="!isContactSubscribed" v-slot:alert>
                <vx-alert color="info" :closeable="false">
                    <strong>Disabled:</strong> Contact has opted out of transactional messages.
                </vx-alert>
            </template>
        </message-builder>
        <message-builder-message-hub-generation v-model:visible="data.aiModal" v-if="FEATURES.message_hub_ai"
            :contact-id="props.contact.id" @selected="data.body = $event" />
    </div>
</template>
<script setup>
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import dayjs from '~/utils/dayjs';
import { reactive, inject, computed } from 'vue';
import { useToasted } from '~/composables/useToasted';
import { getClientTz, roundToNearest5 } from '~/utils/date';
import { messagesClient, scheduledMessagesClient } from '~/services';
import { VxDropdownItem, VxAlert } from '@voxie/frontend-components';
import { faWandMagicSparkles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ExperimentalPill from "~/components/general/ExperimentalPill.vue";
import { CONTACT_SUBSCRIPTION_STATUS_OPT_OUT, CONTACT_SUBSCRIPTION_TYPE_TRANSACTIONAL } from '~/constants/contactSubscription';
import MessageBuilderMessageHubGeneration from '~/components/general/message-builder/modals/MessageBuilderMessageHubGeneration.vue';

const FEATURES = inject('FEATURES');
const $toasted = useToasted();

const props = defineProps({
    contact: {
        type: Object,
        required: true,
    }
});

const teamId = Spark.state.currentTeam.id;
const data = reactive({
    body: '',
    showModal: false,
    media_url: null,
    media_content_type: null,
    selectedSnippetBody: '',
    isScheduledMessage: false,
    sendAt: roundToNearest5(dayjs().tz(getClientTz()).add(1, 'h').utc()),
    aiModal: false
});

const isContactSubscribed = computed(() => {
    return (props.contact?.subscriptions || []).find((s) =>
        s.type === CONTACT_SUBSCRIPTION_TYPE_TRANSACTIONAL)?.status !== CONTACT_SUBSCRIPTION_STATUS_OPT_OUT;
})

const validate = () => {
    if (!data.body || data.body.trim() === '') {
        $toasted.error('Message body is required.');

        return false;
    }

    if (data.isScheduledMessage) {
        if (!data.sendAt) {
            $toasted.error('Send At is required.');
            return false;
        }

        if (data.sendAt !== null && dayjs().tz(getClientTz()).utc().isSameOrAfter(dayjs.utc(data.sendAt).tz(getClientTz()))) {
            $toasted.error('The send at field cannot be set in the past.');
            return false;
        }
    }

    return true;
}

const deliverMessage = async () => {
    if (!validate()) {
        return;
    }

    const payload = {
        contact_id: props.contact.id,
        body: data.body,
    };

    if (data.isScheduledMessage) {
        payload.send_at = data.sendAt;
    }

    if (data.media_url) {
        payload.media_url = data.media_url;
        payload.media_content_type = data.media_content_type;
    }

    try {
        const response = await (data.isScheduledMessage ?
            scheduledMessagesClient.createScheduledMessage(teamId, props.contact.id, payload) :
            messagesClient.createV3(teamId, payload));
        if (response.data.error_message) {
            $toasted.error(`It was not possible to send the message to ${props.contact.phone}`)
            $toasted.error(`response.data.error_message (code: ${response.data.error_code})`)
        }

    } catch (e) {
        $toasted.error(`It was not possible to send the message to ${props.contact.phone}`)
        if (e?.response?.status === 422 && e.response.data?.errors) {
            Object.keys(e.response.data.errors).forEach((key) => {
                e.response.data.errors[key].forEach(error => {
                    $toasted.error(`${error} `);
                });
            });
        } else {
            console.error(e);
        }
    } finally {
        resetDefaults();
    }
}

const resetDefaults = () => {
    data.body = '';
    data.media_url = null;
    data.media_content_type = null;
    data.selectedSnippetBody = '';
    data.isScheduledMessage = false;
    data.aiModal = false;
}

</script>
