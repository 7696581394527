<template>
    <div data-test-id="root-container">
        <div class="alert alert-success" v-if="currentDiscount">
            <span v-if="currentDiscount.duration == 'repeating' && currentDiscount.duration_in_months > 1">
                You currently receive a discount of {{ formattedDiscount(currentDiscount) }} for all invoices during the
                next {{ currentDiscount.duration_in_months }} months.
            </span>
            <span v-if="currentDiscount.duration == 'repeating' && currentDiscount.duration_in_months == 1">
                You currently receive a discount of {{ formattedDiscount(currentDiscount) }} for all invoices during the
                next month.
            </span>
            <span v-if="currentDiscount.duration == 'forever'">
                You currently receive a discount of {{ formattedDiscount(currentDiscount) }} forever.
            </span>
            <span v-if="currentDiscount.duration == 'once'">
                You currently receive a discount of {{ formattedDiscount(currentDiscount) }} for a single invoice.
            </span>
        </div>

        <!-- Update Card -->
        <update-payment-method-stripe
            v-if="isUsingStipe"
            :team="team"
            :collects-billing-address="collectsBillingAddress"
            :countries="countries"
        ></update-payment-method-stripe>

        <div>
            <div v-if="team.stripe_id">
                <!-- Redeem Coupon -->
                <redeem-coupon :team="team"></redeem-coupon>
            </div>
        </div>
    </div>
</template>

<script>
import UpdatePaymentMethodStripe from './UpdatePaymentMethodStripe.vue';
import RedeemCoupon from './RedeemCoupon.vue';
import { currency } from '../../../utils/helpers';
import axios from '~/services/axios';

export default {
    components: {
        UpdatePaymentMethodStripe,
        RedeemCoupon,
    },
    props: {
        team: {
            required: true,
        },
        collectsBillingAddress: {
            required: true,
        },
        countries: {
            required: true,
        },
    },
    data() {
        return {
            currentDiscount: null,
            loadingCurrentDiscount: false,
            isUsingStipe: !!Spark.stripeKey,
        };
    },
    created() {
        const self = this;

        Bus.$on('updateDiscount', function () {
            self.getCurrentDiscountForTeam(self.team);

            return true;
        });
    },
    mounted() {
        this.getCurrentDiscountForTeam(this.team);
    },
    methods: {
        /**
         * Get the current discount for the team.
         */
        getCurrentDiscountForTeam(team) {
            this.currentDiscount = null;

            this.loadingCurrentDiscount = true;

            axios.get(`/settings/teams/coupon/${team.id}`).then((response) => {
                if (response.status === 200) {
                    this.currentDiscount = response.data;
                }

                this.loadingCurrentDiscount = false;
            });
        },

        /**
         * Get the formatted discount amount for the given discount.
         */
        formattedDiscount(discount) {
            if (!discount) {
                return;
            }

            if (discount.percent_off) {
                return `${discount.percent_off}%`;
            } else {
                return currency(this.calculateAmountOff(discount.amount_off));
            }
        },

        /**
         * Calculate the amount off for the given discount amount.
         */
        calculateAmountOff(amount) {
            return amount / 100;
        },
    },
};
</script>
