<template>
    <vx-table-row>
        <vx-table-row-item>
            <template v-slot:label> ID: </template>
            <div class="vx-break-all">
                <vx-badge class="vx-mr-2 vx-cursor-pointer" @click.prevent="copyId()" size="xs" color="slate">{{ props.modelValue.id }}</vx-badge>
            </div>

        </vx-table-row-item>

        <vx-table-row-item class="xl:!vx-col-span-2">
            <template v-slot:label> Name: </template>
            <div class="vx-font-bold vx-text-slate-700 vx-break-all xl:vx-py-2">
                {{ props.modelValue.name }}
            </div>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Status: </template>
            <vx-badge class="vx-mr-4" size="xs" :color="statusColor" data-test="campaign-status">
                {{ generalStatusLabel(props.modelValue.status) }}
            </vx-badge>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Audience Type: </template>
            {{ audienceType }}
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Added at: </template>

            <vx-popover hover
                        :arrow="true"
                        placement="top"
                        :class="createdColor"
                        :key="dateTimeKey">
                {{ fromNow(props.modelValue.created_at) }}
                <template v-slot:content>
                    {{ shortDateTimeTzSpread(props.modelValue.created_at) }}
                </template>
            </vx-popover>
        </vx-table-row-item>
    </vx-table-row>
</template>

<script setup>
import {
    VxBadge,
    VxPopover,
    VxTableRow,
    VxTableRowItem,
} from "@voxie/frontend-components";
import { computed } from 'vue';
import { audienceTypeLabel } from '../../../../constants/campaign';
import { useToasted } from '../../../../composables/useToasted';
import { fromNow, shortDateTimeTzSpread } from '../../../../utils/date';
import { useInterval } from '@vueuse/core';
import { generalStatusLabel } from '~/components/filters';
import dayjs from 'dayjs';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

const toasted = useToasted();

const copyId = () => {
    navigator.clipboard.writeText(props.modelValue.id);
    toasted.success(`${props.modelValue.id} copied to clipboard.`)
};

const audienceType = computed(() =>
    audienceTypeLabel(props.modelValue.type)
);

const createdColor = computed(() => {
        return dayjs(props.modelValue.created_at).isToday() ? 'vx-text-emerald-700' : '';
    },
);

const dateTimeKey = useInterval(60000);

const statusColor = computed(() => {
    const colors = {
        draft: 'amber',
        scheduled: 'orange',
        published: 'teal',
        completed: 'neutral',
        paused: 'danger',
    };

    return colors[props.modelValue.status] || 'primary';
});
</script>
