<template>
    <vx-popover
        hover
        arrow
        placement="top">
        <span v-if="segmentsCount !== undefined">{{ props.body.length }} Char • {{ segmentsCount }} Seg • {{ messageType }}</span>
        <span v-else>{{ props.body.length }} Char • {{ messageType }}</span>
        <template v-slot:content>
            <div class="vx-grid vx-grid-cols-[1fr_auto] vx-items-center vx-gap-1">
                <span>Characters:</span>
                <strong class="vx-font-bold">{{ props.body.length }}</strong>

                <span v-if="segmentsCount !== undefined">SMS Segments:</span>
                <strong class="vx-font-bold" v-if="segmentsCount !== undefined">{{ segmentsCount }}</strong>

                <span>Message Type:</span>
                <strong class="vx-font-bold">{{ messageType }}</strong>
            </div>
        </template>
    </vx-popover>
</template>

<script setup>
import { VxPopover } from '@voxie/frontend-components';
import { computed } from 'vue';
import {
    UNICODE_TO_GSM, GSM_MAX_SINGLE_SEGMENT_SIZE,
    GSM_MAX_CONCAT_SEGMENT_SIZE,
    UCS2_MAX_SINGLE_SEGMENT_SIZE,
    UCS2_MAX_CONCAT_SEGMENT_SIZE,
} from '../../../support/GSM';

const props = defineProps({
    body: {
        type: String,
        default: '',
    },
    mediaUrl: {
        type: String,
    }
});

const messageType = computed(() => (props.mediaUrl ? 'MMS' : 'SMS'));

const bodyCodePoints = computed(() => [...props.body].map((char) => char.codePointAt(0) ?? 0));

const encoding = computed(() =>
    bodyCodePoints.value.every((charDec) => charDec in UNICODE_TO_GSM) ? 'GSM' : 'UCS2'
);

const bodyGSMEncodedLength = computed(() =>
    bodyCodePoints.value.reduce((total, charDec) => (total += UNICODE_TO_GSM[charDec]?.length ?? 0), 0)
);

const segmentsCount = computed(() => {
    if (messageType.value === 'MMS') return undefined;

    if (props.body.length === 0) return 0;

    if (encoding.value === 'GSM') {
        return bodyGSMEncodedLength.value <= GSM_MAX_SINGLE_SEGMENT_SIZE
            ? 1
            : Math.ceil(bodyGSMEncodedLength.value / GSM_MAX_CONCAT_SEGMENT_SIZE);
    }

    if (encoding.value === 'UCS2') {
        if (props.body.length <= UCS2_MAX_SINGLE_SEGMENT_SIZE) return 1;

        let subTotal = 0;
        const segments = [...props.body]
            .map((char) => char.length)
            .reduce((acc, charLength) => {
                subTotal += charLength;
                if (subTotal <= UCS2_MAX_CONCAT_SEGMENT_SIZE) return acc;

                subTotal = charLength;
                return ++acc;
            }, 1);

        return segments;
    }

    return 0;
});
</script>
