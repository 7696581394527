<template>
    <div class="vx-@container/media-preview vx-relative">
        <template v-if="!error">
            <img v-if="isImage"
                class="vx-w-full vx-h-auto vx-max-h-[50vh] vx-object-cover vx-rounded-md"
                :src="props.mediaUrl"
                :alt="`message_image`"
                @error="error = true" />
            <video v-else-if="isVideo"
                controls
                class="vx-w-full vx-max-h-[50vh] vx-mx-auto vx-aspect-video vx-rounded-md">
                <source :src="props.mediaUrl"
                    :type="props.mediaContentType"
                    @error="error = true" />
            </video>
            <audio v-else-if="isAudio"
                controls
                class="vx-w-full vx-max-h-[50vh] vx-mx-auto vx-aspect-video">
                <source :src="props.mediaUrl"
                    :type="props.mediaContentType"
                    @error="error = true" />
            </audio>
            <a v-else
                :href="props.mediaUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="vx-border-dashed vx-border-slate-300 vx-rounded-md vx-flex vx-justify-center vx-items-center vx-mx-auto vx-w-full vx-max-w-[160px] vx-h-28 !vx-no-underline">
                <font-awesome-icon :icon="isPdf ? faFilePdf : (isVCard ? faAddressCard : faFile)"
                    class="vx-text-sky-400 vx-text-3xl @sm/media-preview:vx-text-5xl" />
            </a>
        </template>
        <div v-if="error"
            class="vx-flex vx-flex-col vx-items-center vx-justify-center vx-gap-1 vx-border-dashed vx-border-slate-300 vx-rounded-md vx-mx-auto vx-w-full vx-max-w-[160px] vx-h-28">
            <font-awesome-icon :icon="faFileXmark"
                class="vx-text-slate-500 vx-text-3xl @sm/media-preview:vx-text-5xl" />
            <div class="[text-wrap:balance] vx-text-center vx-text-xs vx-text-slate-600">
                Unable to open the file.
            </div>
        </div>

        <vx-button v-if="props.removeable"
            @click.prevent="emit('remove')"
            type="button"
            size="sm"
            color="white"
            shadow
            :class="{
                '!vx-absolute vx-right-2 vx-top-2': !isAudio,
                'vx-mx-auto vx-my-2 !vx-block': isAudio,
            }">
            <span class="vx-hidden @xs/media-preview:vx-inline">Remove</span>
            <font-awesome-icon :icon="faTrash"></font-awesome-icon>
        </vx-button>
    </div>
</template>

<script setup>
import { VxButton } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAddressCard, faFile, faFilePdf, faFileXmark, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { computed, ref } from 'vue';

const props = defineProps({
    mediaUrl: {
        type: String,
        required: true
    },
    mediaContentType: {
        type: String,
        required: false,
    },
    removeable: Boolean,
});

const emit = defineEmits(['remove']);

const isImage = computed(() => Boolean(props.mediaUrl?.toLowerCase()?.match(/\.(png|jpg|jpeg|gif|bmp)$/)));
const isVideo = computed(() => Boolean(props.mediaUrl?.toLowerCase().match(/\.(mp4|webm)$/)));
const isAudio = computed(() => Boolean(props.mediaUrl?.toLowerCase().match(/\.(mp3|mpeg|ogg|wav)$/)));
const isPdf = computed(() => Boolean(props.mediaUrl?.toLowerCase().match(/\.(pdf)$/)));
const isVCard = computed(() => Boolean(props.mediaUrl?.toLowerCase().match(/\.(vcf|vcard)$/)));

const error = ref(false);
</script>

