<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        size="lg"
        :padding="false"
        :stacked="gallery">
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6">
                New Flow
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">Choose the type of Flow you want to make.</div>
        </template>

        <div class="vx-p-4 vx-space-y-4 lg:vx-p-10">
            <vx-radio-selection-item :active="type === 'playbook'" @selected="type = 'playbook'">
                <template v-slot:header>
                    Playbook
                    <experimental-pill type="alpha"></experimental-pill>
                </template>
                <template v-slot:content>
                    <ul class="vx-pl-3 vx-list-disc vx-text-xs">
                        <li>Pre-made templates</li>
                        <li>Simple configuration</li>
                        <li>Managed Resources</li>
                        <li>Easiest to use</li>
                    </ul>
                </template>
            </vx-radio-selection-item>

            <div class="vx-relative">
                <vx-badge  v-if="!FEATURES.flows" size="xs"
                    color="amber"
                    class="vx-absolute vx-right-2 vx-top-2 vx-z-[1]">
                    Coming Soon <font-awesome-icon :icon="faHelmetSafety"></font-awesome-icon>
                </vx-badge>
                <vx-radio-selection-item :active="type === 'custom'" @selected="type = 'custom'">
                    <template v-slot:header>
                        Custom
                        <experimental-pill type="beta"></experimental-pill>
                    </template>
                    <template v-slot:content>
                        <ul class="vx-pl-3 vx-list-disc vx-text-xs">
                            <li>Build your own collection of resources (segments, campaigns, automations)</li>
                            <li>Best for highly custom workflows</li>
                            <li>Meant for <strong>advanced users</strong></li>
                        </ul>
                    </template>
                </vx-radio-selection-item>
            </div>
        </div>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="emit('update:visible', false)"
                    type="button"
                    class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                    size="lg"
                    color="muted">
                    Cancel
                </vx-button>
                <vx-button @click="next()"
                    type="button"
                    class="vx-grow"
                    size="lg"
                    color="primary">
                    Next
                </vx-button>
            </div>
        </template>
    </vx-modal>

    <playbook-gallery v-model:visible="gallery" :list="props.list"></playbook-gallery>

</template>

<script setup>
import { faHelmetSafety } from '@fortawesome/pro-solid-svg-icons';
import { VxModal, VxButton, VxRadioSelectionItem, VxBadge } from '@voxie/frontend-components';
import { inject, ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import PlaybookGallery from './playbook/PlaybookGallery.vue';
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';

const FEATURES = inject('FEATURES');

const props = defineProps({
    visible: {
        type: Boolean,
        required: true,
    },
    list: {
        type: Array,
        default: () => ([]),
    }
})

const emit = defineEmits(['update:visible']);

const gallery = ref(false);

const type = ref('playbook');

const next = () => {
    if (type.value === 'playbook') {
        gallery.value = true;
        return;
    }

    window.location.href = '/automation/flows/new';
}
</script>
