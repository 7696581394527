<template>
    <div :class="['message-variables', extraClass]">
        <template v-if="showDescription">
            <p>
                The following variables can be inserted into your message
                to dynamically render contextual information when the
                message is sent. Click on the variable name to insert it.

                <br/>
                Also see: <a href="https://shopify.github.io/liquid/" target="_blank">advanced use</a>.
            </p>
            <br/>
        </template>

        <template v-if="searchText.length > 0 && !variablesCount">
            <div class="d-flex align-items-center justify-content-center w-100 h-100">
                <p>No results found :(</p>
            </div>
        </template>
        <template v-else>
            <template v-if="allVariables.team.length || allVariables.team_custom_attributes.length">
                <h5>Team</h5>

                <p>
                    <template v-if="allVariables.team.length">
                        <a v-for="v in allVariables.team"
                            :key="v.label+Math.random()"
                            @click.prevent="insertVariable(v.var)"
                            class="varLink">
                            <span class="badge badge-secondary">{{ v.label }}</span>
                        </a>
                    </template>

                    <template v-if="allVariables.team_custom_attributes.length">
                        <a v-for="v in allVariables.team_custom_attributes"
                            :key="v.label+Math.random()"
                            @click.prevent="insertVariable(v.var)"
                            class="varLink">
                            <span class="badge badge-secondary">{{ v.label }}</span>
                        </a>
                    </template>
                </p>
            </template>

            <template v-if="allVariables.contact.length || allVariables.contact_custom_attributes.length">
                <h5 class="mt-3">Contact</h5>

                <p>
                    <template v-if="allVariables.contact.length">
                        <a v-for="v in allVariables.contact"
                            :key="v.label+Math.random()"
                            @click.prevent="insertVariable(v.var)"
                            class="varLink">
                            <span class="badge badge-secondary">{{ v.label }}</span>
                        </a>
                    </template>

                    <template v-if="allVariables.contact_custom_attributes.length">
                        <a v-for="v in allVariables.contact_custom_attributes"
                            :key="v.label+Math.random()"
                            @click.prevent="insertVariable(v.var)"
                            class="varLink">
                            <span class="badge badge-secondary">{{ v.label }}</span>
                        </a>
                    </template>
                </p>
            </template>

            <div v-if="hasStores && (allVariables.shopify.general.length ||
                                    allVariables.shopify.checkout.length ||
                                    allVariables.shopify.post_purchase.length)">
                <h5 class="mt-3">Shopify</h5>

                <template v-if="allVariables.shopify.general.length">
                    <p>General</p>
                    <p>
                        <a v-for="v in allVariables.shopify.general"
                           :key="v.label+Math.random()"
                           @click.prevent="insertVariable(v.var)"
                           class="varLink">
                            <span class="badge badge-secondary">{{ v.label }}</span>
                        </a>
                    </p>
                </template>

                <template v-if="allVariables.shopify.checkout.length">
                    <p class="mt-3">Abandoned Checkout</p>
                    <p>
                        <a v-for="v in allVariables.shopify.checkout"
                           :key="v.label+Math.random()"
                           @click.prevent="insertVariable(v.var)"
                           class="varLink">
                            <span class="badge badge-secondary">{{ v.label }}</span>
                        </a>
                    </p>
                </template>

                <template v-if="allVariables.shopify.post_purchase.length">
                    <p class="mt-3">Post Purchase</p>
                    <p>
                        <a v-for="v in allVariables.shopify.post_purchase"
                           :key="v.label+Math.random()"
                           @click.prevent="insertVariable(v.var)"
                           class="varLink">
                            <span class="badge badge-secondary">{{ v.label }}</span>
                        </a>
                    </p>
                </template>
            </div>

            <template v-if="allVariables.contact_collections.length">
                <h5 class="mt-3">Contact Collections</h5>

                <p>
                    <a v-for="v in allVariables.contact_collections"
                       :key="v.label+Math.random()"
                       @click.prevent="insertVariable(v.var)"
                       class="varLink">
                        <span class="badge badge-secondary">{{ v.label }}</span>
                    </a>
                </p>
            </template>

            <template v-if="allVariables.promotions.length">
                <h5 class="mt-3">Promotions</h5>
                <p>
                    <a v-for="(v, index) in allVariables.promotions"
                       :key="index"
                       @click.prevent="insertVariable(v.var)"
                       class="varLink">
                        <span class="badge badge-secondary">{{ v.label }}</span>
                    </a>
                </p>
            </template>
        </template>
    </div>
</template>

<script>
import { customAttributesClient, teamCustomAttributesClient, promotionsClient, teamsClient } from '../../services';

export default {
    name: "MessageVariables",
    props: {
        input: {
            required: true,
        },
        searchText: {
            default: ''
        },
        showDescription: {
            default: true
        },
        extraClass: String,
    },
    inject: ['FEATURES'],
    data() {
        return {
            hasStores: false,
            variables: {

                team: [
                    {label: 'team name', var: '{{team=>name}}'},
                ],

                contact: [
                    {label: 'first name', var: '{{contact=>first_name}}'},
                    {label: 'last name', var: '{{contact=>last_name}}'},
                    {label: 'phone', var: '{{contact=>phone}}'},
                    {label: 'email', var: '{{contact=>email}}'},
                    {label: 'created date', var: '{{contact=>created_at}}'},
                    {label: 'updated date', var: '{{contact=>updated_at}}'},
                ],

                message: [
                    {label: 'from', var: '{{message=>from}}'},
                ],

                shopify: {
                    'general': [
                        {
                            label: 'lifetime purchase amount',
                            var: `{{contact=>custom_attributes=>SH_Contact_Lifetime_Purchased_Amount}}`
                        }
                    ],
                    'checkout': [
                        {
                            label: 'checkout recovery url',
                            var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Recovery_Link}}`
                        },
                        {
                            label: 'total amount',
                            var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Total_Amount}}`
                        },
                        {label: 'item count', var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Item_Count}}`},
                        {
                            label: 'total discount',
                            var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Total_Discount}}`
                        },
                        {
                            label: 'product skus',
                            var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Product_SKUs}}`
                        },
                        {
                            label: 'product names',
                            var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Product_Names}}`
                        },
                        {
                            label: 'first product sku',
                            var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Product_1_SKU}}`
                        },
                        {
                            label: 'first product name',
                            var: `{{contact=>custom_attributes=>SH_Abandoned_Checkout_Product_1_Name}}`
                        }
                    ],
                    'post_purchase': [
                        {label: 'total amount', var: `{{contact=>custom_attributes=>SH_Last_Order_Total_Amount}}`},
                        {label: 'product skus', var: `{{contact=>custom_attributes=>SH_Last_Order_Product_SKUs}}`},
                        {label: 'product names', var: `{{contact=>custom_attributes=>SH_Last_Order_Product_Names}}`},
                        {
                            label: 'first product sku',
                            var: `{{contact=>custom_attributes=>SH_Last_Order_Product_1_SKU}}`
                        },
                        {
                            label: 'first product name',
                            var: `{{contact=>custom_attributes=>SH_Last_Order_Product_1_Name}}`
                        },
                        {
                            label: 'first product quantity',
                            var: `{{contact=>custom_attributes=>SH_Last_Order_Product_1_Quantity}}`
                        },
                    ]
                },

                contact_collections: [
                    {label: 'tags', var: "\n{% for tag in contact=>tags %}\n\t{{tag}}\n{% endfor %}"},
                    {
                        label: 'custom attributes',
                        var: '\n{% for ca in contact=>custom_attributes %}\n\tName: {{ca["key"]}}\n\tValue: {{ca["value"]}}\n{% endfor %}'
                    },
                ],

                contact_custom_attributes: [], //loaded via API response

                team_custom_attributes: [], //loaded via API response

                promotions: [], //loaded via API response
            },
        }
    },
    computed: {
        allVariables() {
            let variables = {}
            Object.keys(this.variables).forEach((key) => {
                if (Array.isArray(this.variables[key])) {
                    variables[key] = this.variables[key]
                        .filter(v => v.label.toLowerCase().includes(this.searchText.toLowerCase()))
                } else if (typeof this.variables[key] === "object") {
                    variables[key] = {}
                    Object.keys(this.variables[key]).forEach(subKey => {
                        variables[key][subKey] = this.variables[key][subKey]
                            .filter(v => v.label.toLowerCase().includes(this.searchText.toLowerCase()));
                    })
                }
            });
            return variables;
        },
        variablesCount() {
            const allVariables = this.allVariables;

            return Object.keys(allVariables).reduce((acc, curr) => {
                if (Array.isArray(allVariables[curr])) {
                    return acc + allVariables[curr].length;
                } else if (typeof allVariables[curr] === "object") {
                    return acc + Object.keys(allVariables[curr]).reduce((subAcc, subKey) => {
                        return subAcc + allVariables[curr][subKey].length;
                    }, 0)
                }
            }, 0)
        },
    },
    created(){
        customAttributesClient.customAttributesMeta()
            .then(({ data }) => {
                this.variables.contact_custom_attributes = data.reduce((acc, next) => {
                    const nextValue = next.replace(/[^A-Za-z0-9]/g, '_');

                    if(next.toUpperCase().indexOf('SH_') < 0) {
                        return [ ...acc, {label: next, var: `{{contact=>custom_attributes=>${nextValue}}}`} ];
                    } else {
                        return acc;
                    }
                }, []);
            })
            .catch (error => {
                console.log(error.response.data);
                this.$toasted.global.platform_error();
            });

        const teamId = Spark.state.currentTeam.id;
        teamCustomAttributesClient.getList(teamId)
            .then(({ data }) => {
                this.variables.team_custom_attributes = [];
                let attrList = data.data.sort((a, b) => {(a.key > b.key) ? 1: -1;});

                attrList.forEach((attr) => {
                    const varValue = {label: attr.key + ' Value', var: `{{team=>custom_attributes=>${attr.key}}}`};
                    this.variables.team_custom_attributes.push(varValue);
                });
            })
            .catch (error => {
                console.log(error);
                this.$toasted.global.platform_error();
            });

        // Fetch promotions.
        if (this.FEATURES.promotions) {
            promotionsClient.getAll(teamId)
                .then(response => {
                    const promotionVariables = response.data.data.map(promotion => {
                        return {
                            label: promotion.name,
                            var: `{{promotion=>coupon=>${promotion.code}}}`,
                        };
                    });
                    promotionVariables.sort((a, b) => a.label.localeCompare(b.label));
                    this.variables.promotions = promotionVariables;
                })
                .catch (() => {
                    this.$toasted.global.platform_error();
                });
        }

        teamsClient.countShopifyStores(teamId)
            .then(({ data }) => {
                this.hasStores = data.count > 0;
            });
    },
    methods: {
        insertVariable(insert) {
            // filter:
            if (0 == insert) {
                return;
            }

            let startPos, endPos, tmpStr;
            // get cursor's position:
            if (typeof this.input.$el === 'undefined') {
                    startPos = this.input.selectionStart,
                    endPos = this.input.selectionEnd,
                    tmpStr = this.input.value || this.input.modelValue;
            } else {
                    startPos = this.input.$refs?.input?.selectionStart,
                    endPos = this.input.$refs?.input?.selectionEnd,
                    tmpStr = this.input.value || this.input.modelValue;
            }

            if (typeof startPos === 'undefined' || typeof endPos === 'undefined') {
                console.error('Probable breakage of the message variable functionality. Check MessageVariablePopper.vue component');
                return;
            }

            const suff = tmpStr.substring(endPos, tmpStr.length);
            const newContent = tmpStr.substring(0, startPos).replace(/\{\{[\w=>]*$/, '') + insert + suff;

            this.$emit('variableInserted', {
                body: newContent,
                cursor: newContent.length - suff.length,
            });
        }
    },
}
</script>

<style scoped>

</style>
