import ArtBusinessQuestions from './arts/ArtBusinessQuestions.vue';
import ArtOptIn from './arts/ArtOptIn.vue';
import ArtCompliance from './arts/ArtCompliance.vue';
import ArtCustomerSurvey from './arts/ArtCustomerSurvey.vue';

import PlaybookOptInConfig from './forms/config/PlaybookOptInConfig.vue';
import PlaybookComplianceConfig from './forms/config/PlaybookComplianceConfig.vue';
import PlaybookBusinessQuestionsConfig from './forms/config/PlaybookBusinessQuestionsConfig.vue';
import PlaybookIntroductionConfig from './forms/config/PlaybookIntroductionConfig.vue';

export const playbookGallery = [
    {
        badge: 'System Default',
        type: 'compliance',
        name: 'Compliance Automations',
        description: 'Default automations that act on specific keywords from Inbound Text.',
        art: ArtCompliance,
        config: PlaybookComplianceConfig,
        defaultConfig: {
            start_message:
                'You have successfully been re-subscribed to messages from this number. Reply HELP for help. Reply STOP to unsubscribe. Msg & data rates may apply.',
            help_message: 'Reply STOP to unsubscribe. Msg & data rates may apply.',
        },
    },
    {
        badge: 'Acquisition',
        type: 'opt_in',
        name: 'Opt-In',
        description: 'Expand your marketing customer base asking consent.',
        art: ArtOptIn,
        config: PlaybookOptInConfig,
        defaultConfig: {
            question_message:
                "Hi! This is the {{team=>name}} text line where you'll receive automated, personalized marketing texts.\nReply Y to subscribe.",
            confirmation_message:
                '{{team=>name}}: Msg & data rates may apply. Msg frequency varies. Reply HELP for help and STOP to cancel.',
        },
    },
    {
        badge: 'Zero-Party Data',
        type: 'introduction',
        name: 'Collect Name & Birthday',
        description: 'Engage in conversational contact enrichment.',
        art: ArtCustomerSurvey,
        config: PlaybookIntroductionConfig,
        defaultConfig: {
            introduction_message: 'Welcome to our text line! Save our contact info so you never miss an update! 🎉',
            name_question: {
                message:
                    "I have some quick questions so we can always send you the best updates and offers. First, what's your name?",
            },
            birthday_question: {
                message:
                    'Following Up, {{contact=>first_name}}!\nWe want to know your birthday, so we can shower you with sparkles ✨🎂✨.',
                field_type: 'date',
                custom_attribute: 'birthday',
            },
            additional_questions: [],
            final_message: 'Thanks!',
            initial_delay: 0,
        },
    },
    {
        badge: 'Zero-Party Data',
        type: 'business_questions',
        name: 'Custom Information Collection',
        description: 'Engage in conversational contact enrichment.',
        art: ArtBusinessQuestions,
        config: PlaybookBusinessQuestionsConfig,
        defaultConfig: {
            questions: [],
            final_message: 'Thanks!',
            initial_delay: 0,
        },
    },
];

export const playbookTypeLabel = (playbookType) => {
    return playbookGallery.find((playbook) => playbook.type === playbookType)?.badge || 'Unknown';
};

export const playbookName = (playbookType) => {
    return playbookGallery.find((playbook) => playbook.type === playbookType)?.name || 'Unknown';
};

export const playbookArt = (playbookType) => {
    return playbookGallery.find((playbook) => playbook.type === playbookType)?.art;
};

export const FLOWS_LIMIT = 20;
