<template>
    <div class="vx-flex vx-flex-col vx-h-full" :class="{
        'vx-justify-between vx-bg-white': !!contact?.id,
        'vx-justify-center vx-items-center': !contact?.id
    }">
        <template v-if="notAvailable">
            <browser-extension-not-available>
                {{
        !FEATURES.simple_message_hub ? 'Enterprise Message Hub is not currently supported.' :
            'Teams using the groups feature are not currently supported.'
    }}
            </browser-extension-not-available>
        </template>
        <template v-else-if="loading">
            <vx-icon-loading class="vx-w-9 vx-h-9" spin></vx-icon-loading>
        </template>
        <template v-else-if="contact">
            <conversation-title v-show="!contactDetailsOpened" :contact="contact"
                @open:contactDetails="contactDetailsOpened = true"></conversation-title>
            <conversation-messages v-show="!contactDetailsOpened" :contact="contact"></conversation-messages>
            <conversation-scheduled-messages v-show="!contactDetailsOpened"
                :contact="contact"></conversation-scheduled-messages>
            <conversation-input v-show="!contactDetailsOpened" :contact="contact"></conversation-input>

            <conversation-details v-show="contactDetailsOpened" :modelValue="contact"
                @update:modelValue="contact = { ...contact, ...$event }"
                @close="contactDetailsOpened = false"></conversation-details>
        </template>
        <template v-else>
            <div class="vx-flex vx-flex-col vx-items-center vx-gap-2">
                <vx-logo-loading></vx-logo-loading>
                <span class="vx-text-md vx-font-semibold">
                    Waiting numbers...
                </span>
            </div>
        </template>
    </div>
</template>
<script setup>
import BrowserExtensionNotAvailable from '~/components/messages/browser-extension/components/misc/BrowserExtensionNotAvailable.vue';
import ConversationTitle from '~/components/messages/browser-extension/components/ConversationTitle.vue';
import ConversationDetails from '~/components/messages/browser-extension/components/ConversationDetails.vue';
import ConversationMessages from '~/components/messages/browser-extension/components/ConversationMessages.vue';
import ConversationScheduledMessages from '~/components/messages/browser-extension/components/ConversationScheduledMessages.vue';
import ConversationInput from '~/components/messages/browser-extension/components/ConversationInput.vue';
import { useUrlSearchParams } from '@vueuse/core';
import { onMounted, ref, inject } from 'vue';
import { actionsClient, contactsClient } from '~/services';
import { e164Format, isValidPhoneNumber } from '~/utils/phoneNumber';
import { VxLogoLoading, VxIconLoading } from '@voxie/frontend-components';
import { useToasted } from '~/composables/useToasted';

const $toasted = useToasted();
const FEATURES = inject('FEATURES');

const queryParams = useUrlSearchParams();
const teamId = Spark.state.currentTeam.id;

const contact = ref();
const contactDetailsOpened = ref(false);

const notAvailable = !FEATURES.simple_message_hub || FEATURES.contact_groups;

const loading = ref(false);
const lookupContact = async (phoneNumber) => {
    contact.value = undefined;
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
        loading.value = false;

        return;
    }

    if (loading.value) {
        return;
    }

    loading.value = true;

    try {
        const response = await contactsClient.searchContacts(teamId, {
            'filter[phone]': phoneNumber,
        });

        if (response.data?.data && response.data?.data[0] && response.data?.data[0].phone === phoneNumber) {
            contact.value = response.data?.data[0];
        } else {
            const response = await contactsClient.contactSave(teamId, { phone: phoneNumber });
            actionsClient.send('contact_added');

            contact.value = response.data;
        }
    } catch (e) {
        console.error(e);
        $toasted.error(`It was not possible to fetch or create a contact with the phone number ${phoneNumber}.`);
    } finally {
        loading.value = false;
    }
}

const getContactSubscriptionStatus = async () => {
    try {
        const response = await contactsClient.getContactSubscriptionStatus(teamId, contact.value.id);

        contact.value.subscriptions = response.data.data;
    } catch (e) {
        console.error(e)
        $toasted.global.platform_error();
    }
}

const loadContact = async (phoneNumber) => {
    await lookupContact(e164Format(phoneNumber));
    await getContactSubscriptionStatus();
}

document.addEventListener('VoxieExtension:PhoneNumberDetected', async (event) => {
    const data = event?.detail;

    if (notAvailable || !e164Format(data?.phoneNumber)) {
        return;
    }

    queryParams.phoneNumber = data?.phoneNumber;
    loadContact(data?.phoneNumber);
});

Echo.private(`contacts.${teamId}`)
    .listen('ContactDetailsUpdated', (payload) => {
        contact.value = payload.contact;
    });

onMounted(async () => {
    if (notAvailable || !e164Format(queryParams.phoneNumber)) {
        return;
    }

    loadContact(queryParams.phoneNumber);
});

</script>
