<template>
    <component :is="props.dropdownItem ? VxDropdownItem : VxButton"
        v-if="props.modelValue.status == 'draft'"
        color="transparent"
        size="sm"
        @click="emit('launch', props.modelValue)"
        data-test="launch"
    >
        Launch
        <font-awesome-icon :icon="faRocketLaunch"></font-awesome-icon>
    </component>
    <component :is="props.dropdownItem ? VxDropdownItem : VxButton"
        v-if="showStopAction"
        color="transparent"
        size="sm"
        @click="stop()"
        data-test="stop"
        :disabled="teamStatus !== 'active'"
    >
        End
        <font-awesome-icon :icon="faStop"></font-awesome-icon>
    </component>
    <component :is="props.dropdownItem ? VxDropdownItem : VxButton"
        v-if="props.modelValue.status == 'paused'"
        color="transparent"
        size="sm"
        @click="changeStatusTo('published', 'Campaign resumed successfully')"
        data-test="resume"
    >
        Resume
        <font-awesome-icon :icon="faPlay"></font-awesome-icon>
    </component>
    <component :is="props.dropdownItem ? VxDropdownItem : VxButton"
        v-if="showPauseAction"
        color="transparent"
        size="sm"
        @click="changeStatusTo('paused', 'Campaign paused successfully')"
        data-test="pause"
    >
        Pause
        <font-awesome-icon :icon="faPause"></font-awesome-icon>
    </component>
</template>

<script setup>
import { VxButton, VxDropdownItem } from '@voxie/frontend-components';
import { faPause, faPlay, faRocketLaunch, faStop } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import campaignsClient from '../../../services/campaignsClient';
import { useToasted } from '../../../composables/useToasted';
import { htmlEncode } from 'js-htmlencode';
import Swal from 'sweetalert2';
import { computed, ref } from 'vue';

const teamId = Spark.state.currentTeam.id;
const teamStatus = Spark.state.currentTeam.status;

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    dropdownItem: {
        type: Boolean,
        default: false,
    }
});

const emit = defineEmits(["update:modelValue", "launch"]);
const emitModelValue = (data) =>
    emit("update:modelValue", { ...props.modelValue, ...data });

const processing = ref(false);
const toasted = useToasted();

const changeStatusTo = (status, message) => {
    processing.value = true;
    campaignsClient
        .changeStatus(teamId, props.modelValue.id, status)
        .then(() => {
            emitModelValue({
                status: status,
            });

            if (message) {
                toasted.success(message);
            } else {
                toasted.global.saved_successfully();
            }
        })
        .catch((error) => {
            console.error(error);
            toasted.global.platform_error();
        })
        .finally(() => {
            processing.value = false;
        });
};

const stop = () => {
    Swal.fire({
        title: "Are you sure?",
        html:
            "You are about to stop the <b>" +
            htmlEncode(props.modelValue.name) +
            "</b> campaign. Once you stop this campaign you will not be able to relaunch the campaign and it will appear as complete.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, stop it!",
    }).then(({ value }) => {
        if (!value) {
            return;
        }
        processing.value = true;
        campaignsClient
            .changeStatus(teamId, props.modelValue.id, "completed")
            .then(({ data }) => {
                emitModelValue({
                    status: "completed",
                    completed_at: data.completed_at,
                });

                toasted.success("Campaign stopped successfully");
            })
            .catch((error) => {
                console.error(error);
                toasted.global.platform_error();
            })
            .finally(() => {
                processing.value = false;
            });
    });
};

const showPauseAction = computed(() => {
    return (
        props.modelValue.status == "published" &&
        ["predefined", "evergreen"].includes(props.modelValue.duration_type)
    );
});

const showStopAction = computed(() => {
    return (
        ["published", "paused"].includes(props.modelValue.status) &&
        ["predefined", "evergreen"].includes(props.modelValue.duration_type)
    );
});
</script>
