<template>
    <page-sidebar :links="[
        { href: `/automation/flows/${props.flowId}/resources`, label: 'Resources' },
        { href: `/automation/flows/${props.flowId}`, label: 'Details' },
    ]">
    </page-sidebar>
</template>

<script setup>
import PageSidebar from '~/components/general/PageSidebar.vue';

const props = defineProps({
    flowId: {
        type: String,
        required: true,
    }
})
</script>
