<template>
    <div ref="placement"
        class="vx-font-sans">
        <teleport v-if="props.visible"
            to="body">
            <div v-on-click-outside="() => emit('update:visible', false)"
                class="vx-absolute -vx-translate-y-full vx-z-50"
                :style="{
                    left: left,
                    top: top,
                }">
                <emoji-picker :data="emojiIndex"
                    :showPreview="false"
                    native
                    autoFocus
                    :perLine="8"
                    :emojiSize="20"
                    @select="select" />
            </div>

        </teleport>
    </div>
</template>

<script setup>
import { Picker as EmojiPicker, EmojiIndex } from 'emoji-mart-vue-fast/src';
import data from "emoji-mart-vue-fast/data/all.json";
import { nextTick, onMounted, ref, watch, watchEffect } from 'vue';
import { useEventListener } from '@vueuse/core'
import debounce from '~/utils/debounce';
import { vOnClickOutside } from '@vueuse/components'

const emojiIndex = ref(new EmojiIndex(data));

const props = defineProps({
    visible: {
        type: Boolean,
        required: true,
        default: false,
    }
});

const emit = defineEmits(['selected', 'update:visible']);

const select = (event) => {
    emit('selected', event);
    emit('update:visible', false);
}

const placement = ref();
const left = ref(0);
const top = ref(0);

const placeEmojiPicker = () => {
    if (!placement.value) {
        return;
    }

    const position = placement.value.getBoundingClientRect();
    left.value = `${position.left}px`;
    top.value = `${position.bottom + window.scrollY}px`;
}

useEventListener(window, 'scrollend', debounce(placeEmojiPicker, 150));
useEventListener(window, 'resize', debounce(placeEmojiPicker, 150));
watchEffect(debounce(placeEmojiPicker, 150));
watch(() => props.visible, debounce(placeEmojiPicker, 25));

onMounted(() => nextTick(placeEmojiPicker));

</script>

<style lang="postcss" scoped>
:deep(.emoji-mart) {
    @apply vx-border vx-font-sans vx-border-solid vx-border-white vx-bg-white vx-shadow-xl vx-rounded-lg !vx-w-[320px] !vx-max-w-full !vx-h-[320px];
}

:deep(.emoji-mart-search) {
    @apply vx-m-0 vx-px-4 vx-pt-4 vx-pb-1;
}

:deep(.emoji-mart-scroll) {
    @apply vx-px-4;
}

:deep(.emoji-mart-search input) {
    @apply vx-font-sans vx-h-12 vx-rounded-xl vx-border-slate-300 vx-px-4 focus:vx-outline-none focus:vx-ring-2 focus:vx-ring-blue-500 focus:vx-ring-offset-2 focus:vx-ring-offset-slate-50;
}

:deep(.emoji-mart-category) {
    @apply vx-grid vx-grid-cols-8 vx-pt-4;
}

:deep(.emoji-mart-category.emoji-mart-no-results) {
    @apply vx-flex vx-text-center vx-w-full;
}

:deep(.emoji-mart-category-label) {
    @apply vx-col-span-8 vx-font-sans vx-text-sm vx-font-bold vx-text-black vx-mb-0 vx-py-0;
}


:deep(.emoji-mart-category .emoji-mart-emoji span) {
    @apply vx-cursor-pointer;
}

:deep(.emoji-mart-bar.emoji-mart-bar-anchors) {
    @apply vx-order-3 vx-bg-slate-50 vx-rounded-b-lg vx-border-0;
}

:deep(.emoji-mart-anchor) {
    @apply vx-text-slate-800;
}

:deep(.emoji-mart-anchor.emoji-mart-anchor-selected) {
    @apply !vx-text-sky-500;
}

:deep(.emoji-mart-anchor-bar) {
    @apply vx-hidden;
}
</style>
