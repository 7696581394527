<template>
    <div>
        <!-- Common Subscribe Form Contents -->
        <subscribe-common
            :teams="teams"
            :plans="plans"
            :team="team"
            :form="form"
            :selected-plan="selectedPlan"
            :showing-monthly-plans="showingMonthlyPlans"
            :showing-yearly-plans="showingYearlyPlans"
            @plan-selected="selectPlan"
            @show-monthly-plans="showMonthlyPlans"
            @show-yearly-plans="showYearlyPlans"
        />

        <!-- Billing Information -->
        <div class="card card-default" v-show="selectedPlan">
            <div class="card-header">Billing Information</div>

            <div class="card-body">
                <!-- Generic 500 Level Error Message / Stripe Threw Exception -->
                <div class="alert alert-danger" v-if="form.errors.has('form')">
                    We had trouble validating your card. It\'s possible your card provider is preventing us from charging the card. Please contact your card provider or customer support.
                </div>

                <form role="form">
                    <!-- Payment Method -->
                    <div class="form-group row" v-if="hasPaymentMethod()">
                        <label for="use_existing_payment_method" class="col-md-4 col-form-label text-md-right">Payment Method</label>

                        <div class="col-md-6">
                            <select name="use_existing_payment_method" v-model="form.use_existing_payment_method" id="use_existing_payment_method" class="form-control">
                                <option value="1">Use existing payment method</option>
                                <option value="0">Use a different method</option>
                            </select>
                        </div>
                    </div>

                    <!-- Cardholder's Name -->
                    <div class="form-group row" v-show="form.use_existing_payment_method != '1'">
                        <label for="name" class="col-md-4 col-form-label text-md-right">Cardholder\'s Name</label>

                        <div class="col-md-6">
                            <input type="text" class="form-control" name="name" v-model="cardForm.name">
                        </div>
                    </div>

                    <!-- Card Details -->
                    <div class="form-group row" v-show="form.use_existing_payment_method != '1'">
                        <label for="subscription-card-element" class="col-md-4 col-form-label text-md-right">Card</label>

                        <div class="col-md-6">
                            <div id="subscription-card-element"></div>
                            <span class="invalid-feedback" v-show="cardForm.errors.has('card')">
                                {{ cardForm.errors.get('card') }}
                            </span>
                        </div>
                    </div>

                    <!-- Billing Address Fields -->
                    <subscribe-address
                        v-if="collectsBillingAddress"
                        :form="form"
                        :countries-list="countriesList"
                    />

                    <!-- ZIP Code -->
                    <div class="form-group row" v-if="!collectsBillingAddress">
                        <label for="number" class="col-md-4 col-form-label text-md-right">ZIP / Postal Code</label>

                        <div class="col-md-6">
                            <input type="text" class="form-control" name="zip" v-model="form.zip">
                        </div>
                    </div>

                    <!-- Coupon -->
                    <div class="form-group row">
                        <label class="col-md-4 col-form-label text-md-right">Coupon</label>

                        <div class="col-md-6">
                            <input type="text" class="form-control" v-model="form.coupon" :class="{'is-invalid': form.errors.has('coupon')}">

                            <span class="invalid-feedback" v-show="form.errors.has('coupon')">
                                {{ form.errors.get('coupon') }}
                            </span>
                        </div>
                    </div>

                    <!-- Tax / Price Information -->
                    <div class="form-group row" v-if="selectedPlan">
                        <label class="col-md-4 col-form-label text-md-right">&nbsp;</label>

                        <div class="col-md-6">
                            <div class="alert alert-info" style="margin: 0;">
                                <strong>Tax:</strong> {{ currency(taxAmount(selectedPlan)) }}
                                <br><br>
                                <strong>Total Price Including Tax:</strong>
                                {{ currency(priceWithTax(selectedPlan)) }}
                                {{ chargesTeamsPerSeat ? '/ '+ teamSeatName : '' }}
                                {{ chargesTeamsPerMember ? '/ '+ teams.member : '' }}
                                / {{ capitalize(selectedPlan.interval) }}
                            </div>
                        </div>
                    </div>

                    <!-- Subscribe Button -->
                    <div class="form-group row mb-0">
                        <div class="col-md-6 offset-md-4">
                            <button type="submit" class="btn btn-primary" @click.prevent="subscribe" :disabled="form.busy">
                            <span v-if="form.busy">
                                <i class="fa fa-btn fa-spinner fa-spin"></i> Subscribing
                            </span>

                            <span v-else>
                                Subscribe
                            </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import SubscribeCommon from './SubscribeCommon.vue';
import SubscribeAddress from './SubscribeAddress.vue';
import { currency, capitalize } from '../../../utils/helpers';

export default {
    components: {
        SubscribeCommon,
        SubscribeAddress
    },
    props: [
        'user',
        'team',
        'teams',
        'plans',
        'form',
        'countriesList',
        'cardForm',
        'stripe',
        'showingMonthlyPlans',
        'showingYearlyPlans',
        'selectedPlan',
        'taxRate',
    ],
    mounted() {
        this.cardElement = this.createCardElement('#subscription-card-element');

        if (this.onlyHasYearlyPaidPlans) {
            this.showYearlyPlans();
        }

        Bus.$on(`sparkFormUpdated.${this.form.sparkFormId}`, this.$forceUpdate)
        Bus.$on(`sparkFormUpdated.${this.cardForm.sparkFormId}`, this.$forceUpdate)
    },
    methods: {
        currency,
        capitalize,
        hasPaymentMethod() {
            return this.team ? this.team.card_last_four : this.user.card_last_four;
        },
        showMonthlyPlans() {
            this.$emit('showMonthlyPlans');
        },
        showYearlyPlans() {
            this.$emit('showYearlyPlans');
        },
        selectPlan(plan) {
            this.$emit('plan-selected', plan);
        },
        subscribe() {
            this.$emit('subscribe');
        },
        taxAmount(plan) {
            return plan.price * (this.taxRate / 100);
        },
        priceWithTax(plan) {
            return plan.price + this.taxAmount(plan);
        },
        createCardElement(container) {
            if (!this.stripe) {
                throw "Invalid Stripe Key/Secret";
            }

            const card = this.stripe.elements().create('card', {
                hideIcon: true,
                hidePostalCode: true,
                style: {
                    base: {
                        '::placeholder': {
                            color: '#aab7c4'
                        },
                        fontFamily: 'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
                        color: '#495057',
                        fontSize: '15px'
                    }
                }
            });

            card.mount(container);

            return card;
        },
    },

    computed: {
        paidPlans() {
            return this.plans?.filter((plan) => {
                return plan.active && plan.price > 0;
            }) || [];
        },
        onlyHasYearlyPaidPlans() {
            return !this.paidPlans.some((p) => p.interval === 'monthly') &&
                !!this.paidPlans.some((p) => p.interval === 'yearly')
        },
        collectsBillingAddress () {
            return Spark.collectsBillingAddress;
        },
        chargesUsersPerSeat() {
            return Spark.chargesUsersPerSeat;
        },
        chargesTeamsPerSeat() {
            return Spark.chargesTeamsPerSeat;
        },
        chargesTeamsPerMember() {
            return Spark.chargesTeamsPerMember;
        },
        seatName() {
            return Spark.seatName;
        },
        teamSeatName() {
            return Spark.teamSeatName;
        }
    },
};
</script>
