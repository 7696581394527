<template>
    <vx-selectable v-model="model"
        placeholder="Search Campaign"
        :reduce="option => option.value"
        :loading="loading"
        :options="options"
        @search="search"
        :clearable="false"
        data-test="campaign-selectable" />
</template>

<script setup>
import { VxSelectable } from '@voxie/frontend-components';
import { inject, ref } from 'vue';
import { useToasted } from '~/composables/useToasted';
import { campaignsClient } from '~/services';
import debounce from '~/utils/debounce';

const teamId = Spark.state.currentTeam.id;
const FEATURES = inject('FEATURES');
const toasted = useToasted();

const model = defineModel();
const props = defineProps({
    flowId: String
});
const options = ref([]);
const loading = ref();

const search = debounce((query) => {
    loading.value = true;

    campaignsClient.campaignsAutocomplete(teamId, {
        query: query || model.value,
        flow: FEATURES.flows ? {
            in_flow: props.flowId ? 1 : 0,
            flow_id: props.flowId,
        } : undefined
    }).then((response) => {
        options.value = response.data.data.map((campaign) => ({
            value: campaign.id,
            label: `${campaign.id} - ${campaign.name}`
        }))
    }).catch((e) => {
        console.error(e)
        toasted.global.platform_error()
    }).finally(() => {
        loading.value = false
    })
}, 300);

search();
</script>
