<template>
    <div ref="dropdownMenuRef" :id="id" :class="['modal-dropdown-menu dropdown-menu', positionClass, extraClass]">
        <div class="d-flex flex-column h-100" @click.stop>
            <div
                class="modal-dropdown-menu-header d-sm-none text-white d-flex justify-content-between align-items-center"
            >
                <div class="text-sm cursor-pointer" @click="cancel">Cancel</div>
                <div class="modal-dropdown-menu-modal-title">{{ modalTitle }}</div>
                <div class="text-sm cursor-pointer" @click="emit('reset')">Reset</div>
            </div>
            <div class="p-3 flex-grow-1">
                <slot />
            </div>
            <hr />
            <div class="p-3 d-sm-none">
                <vx-button
                    appearance="primary"
                    size="large"
                    data-test="dd-menu-submit-mobile"
                    block
                    @click="submit"
                    class="text-uppercase"
                >
                    {{ submitBtnText || 'Submit' }}
                </vx-button>
            </div>
            <div class="modal-dropdown-menu__actions p-3 d-none d-sm-flex align-items-center" data-test="dd-menu-actions">
                <vx-button
                    class="modal-dropdown-menu__actions-reset-icon"
                    appearance="transparent"
                    size="medium"
                    data-test="dd-menu-reset"
                    @click="emit('reset')"
                >
                    Reset
                </vx-button>

                <div class="modal-dropdown-menu__actions-right">
                    <vx-button appearance="primary-outline" size="medium" data-test="dd-menu-cancel" @click="cancel">
                        Cancel
                    </vx-button>

                    <vx-button appearance="primary" size="medium" data-test="dd-menu-submit" @click="submit">
                        {{ submitBtnText || 'Submit' }}
                    </vx-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { VxButton } from '@voxie/frontend-components-v3';

const props = defineProps(['id', 'modalTitle', 'extraClass', 'submitBtnText']);

const emit = defineEmits(['submit', 'canceled', 'reset']);

const dropdownMenuRef = ref();

const positionClass = computed(() => {
    if (props.extraClass && !props.extraClass.includes('dropdown-menu-left')) return 'dropdown-menu-right';

    return 'dropdown-menu-left';
});

const hide = () => window.$(dropdownMenuRef.value).dropdown('hide');
const submit = () => {
    hide();
    emit('submit');
};
const cancel = () => {
    hide();
    emit('canceled');
};
</script>

<style lang="postcss">
.modal-dropdown-menu__actions {
  justify-content: space-between;
}

.modal-dropdown-menu__actions-right {
  display: flex;
  gap: 0.5rem;
}

.modal-dropdown-menu__actions-reset-icon {
  border: none;
  padding-left: 0;
}
.modal-dropdown-menu__actions-reset-icon .vx-button__text {
  margin-left: 0;
}
</style>
