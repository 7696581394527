<template>
    <!-- reversed toggle logic -->
    <toggle-action-data :model-value="!data.use_event_contact"
        @update:modelValue="data.use_event_contact = !$event"
        :open="!data.use_event_contact">

        <template v-slot:label>
            Send to a <strong>contact</strong> different from the trigger event?
        </template>

        <vx-label>Contacts</vx-label>
        <select-contact-v2 size="lg"
            v-model="data.contact_id"
            :error="displayFirstError(validate.contact_id)">
        </select-contact-v2>

    </toggle-action-data>
</template>


<script setup>
import { VxLabel } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf } from '@vuelidate/validators';
import SelectContactV2 from '~/components/general/SelectContactV2.vue';
import { displayFirstError } from '~/utils/validation';
import ToggleActionData from './ToggleActionData.vue';

const data = defineModel();

if (data.value.use_event_contact == undefined) {
    data.value.use_event_contact = true;
}

const validate = useVuelidate({
    contact_id: {
        requiredIf: helpers.withMessage('Contact is required when using a different contact from trigger event', requiredIf(() => !data.value.use_event_contact))
    }
}, data, {
    $autoDirty: true,
})
</script>
