<template>
    <contact-details :contactId="props.contactId">
        <vx-expandable-card class="vx-col-span-12 lg:vx-col-span-10">
            <template v-slot:header>
                <div class="vx-mb-4">
                    <font-awesome-icon :icon="faMessages"></font-awesome-icon>
                    Messages
                </div>
            </template>
            <template v-slot:content>
                <div v-if="loading && !messages.length"
                    class="vx-text-center vx-py-10">
                    <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
                </div>

                <vx-alert v-else-if="!loading && messages.length === 0 && !error"
                    :closeable="false">
                    There are no messages for this contact.
                </vx-alert>

                <vx-alert v-else-if="error"
                    color="danger"
                    :closeable="false">
                    {{ error }}
                </vx-alert>

                <div v-else
                    class="vx-bg-white vx-border-solid vx-border-slate-200 vx-border vx-py-6 vx-rounded-xl vx-flex vx-flex-col vx-gap-4">
                    <template v-for="(message, msgIndex) in messages"
                        :key="message.id">

                        <div class="vx-px-6">
                            <message-cloud :message="message">
                            </message-cloud>
                        </div>

                        <div v-if="showDivider(message, msgIndex)"
                            class="vx-my-6 vx-relative vx-flex vx-justify-center"
                            data-test="message-divider">
                            <div
                                class="vx-absolute vx-top-1/2 -vx-translate-y-1/2 vx-h-px vx-bg-slate-200 vx-left-0 vx-w-full">
                            </div>
                            <div
                                class="vx-relative vx-bg-slate-50 vx-border-slate-200 vx-border vx-border-solid vx-rounded-full">
                                <div
                                    class="vx-text-xs vx-border-solid vx-border-t vx-border-x-0 vx-border-b-0 vx-border-t-white vx-rounded-full vx-py-1 vx-px-3 vx-text-slate-500">
                                    {{ weekDayOfMonth(message.created_at) }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </vx-expandable-card>
    </contact-details>
</template>

<script setup>
import ContactDetails from '../ContactDetails.vue';
import { VxIconLoading, VxAlert, VxExpandableCard } from '@voxie/frontend-components';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_NOT_FOUND } from '~/support/HttpStatuses';
import { ref } from 'vue';
import { weekDayOfMonth } from '~/components/filters';
import MessageCloud from '~/components/general/messages/MessageCloud.vue';
import { messagesClient } from '~/services';
import dayjs from '~/utils/dayjs';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMessages } from '@fortawesome/pro-solid-svg-icons';

const props = defineProps({
    contactId: {
        type: Number,
        required: true,
    },
});

const teamId = Spark.state.currentTeam.id;

const error = ref();
const messages = ref([]);
const loading = ref(false);

const loadMessages = () => {
    loading.value = true;

    messagesClient.listMessages(teamId, props.contactId, { sort_dir: 'desc' }).then((response) => {
        messages.value = response.data.data;
    })
        .catch((e) => {
            console.error(e);
            if (
                !e?.response?.status ||
                e?.response?.status >= HTTP_INTERNAL_SERVER_ERROR ||
                e?.response.status == HTTP_NOT_FOUND
            ) {
                error.value = 'There was an error communicating with the platform.';
            } else {
                error.value = e.response?.data?.message || 'Something went wrong.';
            }
        })
        .finally(() => {
            loading.value = false;
        });
};

const showDivider = (message, index) => {
    if (index === messages.value.length - 1) {
        return true
    }

    const nextMessage = messages.value[index + 1];

    return Boolean(nextMessage) && !dayjs(nextMessage.created_at).isSame(dayjs(message.created_at), 'day');
};

loadMessages();
</script>
