<template>
    <tr class="c-table__row">
        <td class="c-table__cell status-col hidden-xs">
            <div :class="stateCssClasses">
                {{ item.status.toLocaleUpperCase().replaceAll("_", " ") }}
            </div>
        </td>

        <td class="c-table__cell hidden-xs">
            <span>Import</span>
            <div
                class="u-text-mute"
                @click="copyImportId(item.import_id)"
                :style="{ cursor: 'pointer' }"
            >
                <popper
                    hover
                    :arrow="false"
                    offsetDistance="0"
                    :content="item.import_id"
                >
                    <div>
                        ID
                        {{ item.import_id.slice(-6) }}
                        <span class="c-badge c-badge--secondary c-badge--small"
                            >copy</span
                        >
                    </div>
                </popper>
            </div>
            <small class="u-block u-text-mute" v-if="item.created_at">
                Started {{ createdAt }}
            </small>
        </td>

        <td class="c-table__cell u-text-center" data-test="rows-total">
            <span class="huge-text">{{ item.row_count || 0 }}</span>
            <small class="u-text-mute"> rows</small>
        </td>

        <td class="c-table__cell u-text-right">
            <span
                v-if="hasErrors"
                @click="showErrors = true"
                class="u-color-danger pr-3 d-inline-block"
                :style="{ cursor: 'pointer' }"
            >
                errors
            </span>
        </td>
        <vx-dialog v-if="hasErrors" :visible="showErrors" @close="showErrors = false">
            <template v-slot:header> Errors </template>
            <template v-slot:buttons>
                <div class="d-flex justify-content-between align-items-center">
                    <popper
                        hover
                        :arrow="false"
                        offsetDistance="0"
                        :content="item.import_id"
                    >
                        <div :style="{ cursor: 'pointer' }" class="text-muted">
                            ID
                            {{ item.import_id.slice(-6) }}
                            <span
                                class="c-badge c-badge--secondary c-badge--small"
                                >copy</span
                            >
                        </div>
                    </popper>
                    <vx-button
                        @click="showErrors = false"
                        appearance="primary-outline"
                        size="small"
                    >
                        Close
                    </vx-button>
                </div>
            </template>
            <template v-slot:message>
                <div
                    :style="{
                        maxWidth: '100%',
                        width: '600px',
                        maxHeight: '70vh',
                        overflow: 'auto',
                    }"
                >
                    <div class="d-flex">
                        <div class="col-md-4"><strong>Row number</strong></div>
                        <div class="col-md-8"><strong>Details</strong></div>
                    </div>
                    <div
                        v-for="error in (errorsResponse.data || [])"
                        class="d-flex border-bottom py-2"
                        :key="error.error_id"
                    >
                        <div class="col-md-4">
                            {{ error.row_number }}
                        </div>
                        <div class="col-md-8">
                            {{ error.detail }}
                        </div>
                    </div>
                    <div class="col-md-12" v-if="!errorsResponse.data?.length">
                        No errors to show
                    </div>
                    <div class="container">
                        <cursor-pagination
                            v-model:cursor="errorsCursor"
                            :loading="loadingErrors"
                            :firstPage="errorsResponse.first_page_url"
                            :nextPage="errorsResponse.next_page_url"
                            :prevPage="errorsResponse.prev_page_url"
                            :lastPage="errorsResponse.last_page_url"
                            @change="fetchErrors"
                        ></cursor-pagination>
                    </div>
                </div>
            </template>
        </vx-dialog>
    </tr>
</template>

<script setup>
import { computed, ref } from 'vue';
import { dateTime } from '../../../utils/helpers';
import { VxDialog, VxButton } from '@voxie/frontend-components-v3';
import Popper from 'vue3-popper';
import importsClient from '../../../services/importsClient';
import { useToasted } from '../../../composables/useToasted';
import CursorPagination from '../../../components/general/CursorPagination.vue'
import parseLinkHeader from 'parse-link-header';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const createdAt = computed(() => dateTime(props.item.created_at));

const loadingErrors = ref(false);
const showErrors = ref(false);
const errorsCursor = ref(null);
const errorsResponse = ref({});
const hasErrors = ref(false)

const stateCssClasses = computed(() => {
    let type;

    switch (props.item.status) {
        case "in_progress":
            type = "warning";
            break;

        case "complete":
            type = "success";
            break;

        default:
            type = "secondary";
    }

    return `c-badge c-badge--${type}`;
});

const copyImportId = (importId) => {
    navigator.clipboard.writeText(importId);
};

const toasted = useToasted();

const fetchErrors = () => {
    loadingErrors.value = true;

    const params = {};

    if (errorsCursor.value) {
        params.cursor = errorsCursor.value
    }

    importsClient
        .listErrors(
            props.item.team_id,
            props.item.import_type,
            props.item.import_id,
            params
        )
        .then((response) => {
            if (response.data?.data.length) {
                errorsResponse.value = response.data
                if (!hasErrors.value) {
                   hasErrors.value = Boolean(errorsResponse.value.data?.length);
                }
                const links = parseLinkHeader(response.headers?.link);

                errorsResponse.value.first_page_url = links?.first?.url
                errorsResponse.value.next_page_url = links?.next?.url
                errorsResponse.value.prev_page_url = links?.prev?.url
                errorsResponse.value.last_page_url = links?.last?.url
            } else {
                errorsCursor.value = null;
                errorsResponse.value = {}
            }
        })
        .catch((err) => {
            toasted.global.platform_error();
            console.error(err);
        })
        .finally(() => {
            loadingErrors.value = false;
        });
};

fetchErrors();
</script>
