<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        @close="reset()"
        size="md"
        :padding="false"
        :stacked="configuring"
    >
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6 vx-flex vx-items-center vx-gap-2">
                <span>
                    AI Message Assistant
                </span>
                <experimental-pill class="-vx-mt-1"></experimental-pill>
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">Our AI-powered technology generates customized, compelling messages that engage customers and drive revenue growth.</div>
        </template>

        <div class="vx-py-4 vx-px-5 vx-flex vx-flex-col vx-gap-8 lg:vx-py-7 lg:vx-px-10">
            <ai-configuration-status v-if="!generating && !allSuggestions.length"  @saved="getAiConfig()" v-model:configuring="configuring" :unavailable="!!(error && hasAiConfig)"></ai-configuration-status>

            <div v-if="generating" data-test="generating-messages" class="vx-flex vx-flex-col vx-items-center vx-justify-center vx-py-6 vx-space-y-8">
                <div class="vx-overflow-hidden">
                    <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
                </div>
                <p class="vx-text-center vx-text-base vx-text-primary lg:vx-px-24">
                    Your messages are being generated by our advanced AI tool...
                </p>
            </div>

            <div v-else-if="!allSuggestions.length" :class="{
                'vx-opacity-40': !hasAiConfig,
            }">
                <h4 class="vx-text-base vx-mb-3 vx-font-bold vx-text-slate-700">
                    Message Description
                </h4>
                <p class="vx-text-sm vx-mb-3 vx-font-normal vx-text-slate-500">
                    Please describe briefly what you'd like your message to be about.
                </p>
                <vx-input
                    :disabled="!hasAiConfig"
                    v-model="details"
                    :rows="10"
                    class="vx-h-28"
                    placeholder="I'd like a message about..."
                ></vx-input>
            </div>

            <div v-else-if="allSuggestions.length">
                <h4 class="vx-text-base vx-mb-3 vx-font-bold vx-text-slate-700">
                    Generated Messages
                </h4>

                <message-generation-list v-model="selected" :messages="allSuggestions"></message-generation-list>
            </div>
        </div>
        <template v-slot:footer v-if="!generating">
            <div class="vx-flex vx-gap-4">
                <template v-if="!hasAiConfig">
                    <vx-button @click="close()" type="button" color="muted" size="lg" class="vx-grow">
                        Cancel <font-awesome-icon :icon="faTimesCircle"></font-awesome-icon>
                    </vx-button>
                </template>
                <template v-else-if="!allSuggestions.length">
                    <vx-button @click="close()" type="button" color="muted" size="lg" class="vx-grow-0">
                        Cancel <font-awesome-icon :icon="faTimesCircle"></font-awesome-icon>
                    </vx-button>
                    <vx-button
                        @click.prevent="generate()"
                        class="vx-grow"
                        type="button"
                        size="lg"
                        :loading="generating"
                        data-test="generate-message"
                    >
                        Generate AI Messages
                        <font-awesome-icon
                            :icon="faWandMagicSparkles"
                        ></font-awesome-icon>
                    </vx-button>
                </template>
                <template v-else-if="allSuggestions.length">
                    <vx-button
                        class="vx-grow-0"
                        color="muted-dark"
                        size="lg"
                        type="button"
                        @click="reset()"
                    >
                        Generate New
                        <font-awesome-icon :icon="faWandMagicSparkles"></font-awesome-icon>
                    </vx-button>
                    <vx-button class="vx-grow" type="button" size="lg" @click="confirm()" data-test="confirm-message">
                        Confirm
                        <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                    </vx-button>
                </template>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import {
    faArrowRight,
    faWandMagicSparkles,
    faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxModal, VxInput, VxButton, VxIconLoading } from '@voxie/frontend-components';
import { ref, watch } from 'vue';
import aiClient from '../../../../services/aiClient';
import MessageGenerationList from './MessageGenerationList.vue';
import ExperimentalPill from "~/components/general/ExperimentalPill.vue";
import openAISettingsClient from '../../../../services/openAISettingsClient';
import AiConfigurationStatus from "~/components/settings/integrations/ai-configuration/AiConfigurationStatus.vue"

const props = defineProps({
    campaignType: {
        type: String,
        default: "marketing",
        validator: (campaignType) =>
            ["marketing", "transactional"].includes(campaignType),
    },
    visible: Boolean,
});

const emit = defineEmits(["selected", "update:visible"]);

const configuring = ref(false)

const details = ref("");

const allSuggestions = ref([]);
const selected = ref("");

const error = ref(false);
const generating = ref(false);

const hasAiConfig = ref(false);
const getAiConfig = async () => {
    if (hasAiConfig.value) {
        return;
    }
    try {
        await openAISettingsClient.getConfig(Spark.state.currentTeam.id);
        hasAiConfig.value = true;
    } catch (e) {
        console.error(e)
        hasAiConfig.value = false;
    }
}

watch([() => props.visible], () => {
    getAiConfig();
}, {
    immediate: true
})

const generate = async () => {
    if (!details.value) {
        return;
    }

    error.value = false;

    if (generating.value) {
        return;
    }

    generating.value = true;

    const additionalPrompt = props.campaignType === 'marketing' ? `Create a ${props.campaignType} campaign message with these details: ` : '';

    try {
        const response = await aiClient.quickBlastSuggestions(Spark.state.currentTeam.id, {
            details: `${additionalPrompt} ${details.value}`
        })
        allSuggestions.value = response.data.data.map(choice => choice?.message).filter(message => message?.length);

        if (!allSuggestions.value.length) {
            error.value = true;
        }
    } catch (e) {
        console.error(e)
        error.value = true;
    } finally {
        generating.value = false;
    }
};

const confirm = () => {
    if (!selected.value){
        selected.value = allSuggestions.value[0];
    }

    emit("selected", selected.value);
    emit('update:visible', false);
    reset();
};

const reset = () => {
    allSuggestions.value = [];
    selected.value = "";
    error.value = false;
}

const close = () => {
    emit('update:visible', false);
}
</script>
