<template>
    <template v-if="savedForLater || launched || updated">
        <success-box v-if="savedForLater">
            <template v-slot:icon>
                <font-awesome-icon :icon="faFloppyDisk"></font-awesome-icon>
            </template>
            <template v-slot:title>
                Saved for Later
            </template>

            We have saved your configuration but your flow is not Launched yet.

            <template v-slot:action>
                <vx-button size="lg"
                    href="/automation/flows"
                    color="secondary">
                    View All Flows
                    <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>
            </template>
        </success-box>
        <success-box v-else-if="launched">
            New Flow created from Playbook!

            <template v-slot:action>
                <vx-button size="lg"
                    href="/automation/flows"
                    color="secondary">
                    View All Flows
                    <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>
            </template>
        </success-box>
        <success-box v-else-if="updated">
            Flow updated!

            <template v-slot:action>
                <vx-button size="lg"
                    href="/automation/flows"
                    color="secondary">
                    View All Flows
                    <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>
            </template>
        </success-box>
    </template>
    <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8" :class="{
        'vx-space-y-8 md:vx-space-y-12': editing,
        '2xl:vx-max-w-screen-xl': !editing
    }" v-else>
        <page-header v-if="editing" class="vx-mb-6 lg:vx-mb-12">
            <template v-slot:subtitle>
                <a href="/automation/flows" class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                    <font-awesome-icon class="vx-mr-1" :icon="faArrowLeft"></font-awesome-icon>
                    All Flows
                </a>
            </template>

            {{ name }}
        </page-header>

        <div class="vx-grid vx-grid-cols-12">
            <page-sidebar v-if="editing" :links="[
                    { href: `/automation/flows/${props.flow?.flow_id}`, label: 'Edit' },
                ]">
            </page-sidebar>

            <div v-if="mounted && !loading"
                  class="vx-col-span-12"
                  :class="{
                      'lg:vx-col-span-10': editing,
                  }">
                <playbook-card>
                    <template v-slot:type>{{ playbookOptions.badge }}</template>
                    <template v-if="editing" v-slot:toggle>
                        <switch-flow-status :modelValue="status === 'active'" @update:modelValue="handleLaunch(props.flow)"></switch-flow-status>
                    </template>
                    <template v-slot:title>{{ playbookOptions.name }}</template>
                    <template v-slot:description>{{ playbookOptions.description }}</template>

                    <component :is="playbookOptions.art"></component>
                </playbook-card>

                <div class="vx-max-w-screen-sm" :class="{
                    'vx-mx-auto vx-pt-8 lg:vx-pt-14': !editing,
                    'vx-pt-6': editing
                }">
                    <div v-if="!editing" class="vx-text-2xl vx-leading-tight vx-font-extrabold vx-mb-6">
                        Configure this Playbook
                    </div>

                    <div id="playbook-form">
                        <playbook-form-card :icon="faSliders">
                            <template v-slot:header>
                                Details
                            </template>

                            <div>
                                <vx-label size="lg">
                                    Flow Name
                                </vx-label>
                                <vx-input v-model="name"
                                    data-test="flow-name"
                                    :error="errors.name || displayFirstError(validate.name.$errors)"
                                    @blur="validate.name.$touch()"
                                    autofocus
                                    size="lg"
                                    :maxlength="80"
                                    ></vx-input>
                            </div>
                            <div>
                                <vx-label size="lg">
                                    Notes
                                </vx-label>
                                <vx-input v-model="notes"
                                    data-test="flow-notes"
                                    :error="errors.notes || displayFirstError(validate.notes.$errors)"
                                    @blur="validate.notes.$touch()"
                                    :rows="4"
                                    size="lg"
                                    :maxlength="2000"></vx-input>
                            </div>
                        </playbook-form-card>

                        <div>
                            <component :is="playbookOptions.config"
                                v-model="config"></component>
                        </div>

                        <div v-if="error" class="vx-mt-4">
                            <vx-alert color="danger"
                                :closeable="false">
                                {{ error }}
                            </vx-alert>
                        </div>

                        <vx-sticky-footer class="!vx-flex-col vx-mt-4 lg:vx-flex lg:!vx-flex-row">
                            <vx-button href="/automation/flows"
                                class="vx-order-3 lg:vx-w-20 lg:vx-order-1"
                                size="lg"
                                color="muted-dark"
                                outline>
                                Cancel
                            </vx-button>
                            <template v-if="editing">
                                <vx-button @click="update()"
                                    data-test="submit-update"
                                    :loading="saving"
                                    type="button"
                                    class="vx-order-1 lg:vx-grow lg:vx-order-3"
                                    size="lg"
                                    :color="status === 'active' ? 'primary' : 'muted-dark'">
                                    Update
                                </vx-button>
                            </template>
                            <template v-else>
                                <vx-button @click="saveForLater()"
                                    data-test="save-for-later"
                                    :loading="saving"
                                    type="button"
                                    class="vx-order-2 lg:vx-grow lg:vx-order-2"
                                    size="lg"
                                    color="muted-dark">
                                    Save for Later
                                </vx-button>
                            </template>
                        </vx-sticky-footer>
                    </div>
                </div>
                    <playbook-launch
                        :visible="!!(statusModal.flow_id && statusModal.status === 'draft')"
                        @update:visible="() => statusModal = {}"
                        :flowId="statusModal.flow_id"
                        :flowStatus="statusModal.status"
                        :flowCreatedAt="statusModal.created_at"
                        @launched="() => {
                            status = 'active'; statusModal = {};
                            if (!props.flow) {
                                launched = true;
                            }
                        }"
                    >
                    </playbook-launch>
                    <playbook-pause
                        :visible="!!(statusModal.flow_id && statusModal.status === 'active')"
                        @update:visible="() => statusModal = {}"
                        :flowId="statusModal.flow_id"
                        @paused="status = 'paused'; statusModal = {}">
                    </playbook-pause>
            </div>
        </div>
    </div>
</template>

<script setup>
import SwitchFlowStatus from '../../components/SwitchFlowStatus.vue';
import PageHeader from '~/components/general/PageHeader.vue';
import PageSidebar from '~/components/general/PageSidebar.vue';
import PlaybookCard from '../PlaybookCard.vue';
import { playbookGallery } from '../playbook';
import { faSliders } from '@fortawesome/pro-solid-svg-icons';
import PlaybookFormCard from './PlaybookFormCard.vue';
import { VxAlert, VxLabel, VxInput, VxStickyFooter, VxButton } from '@voxie/frontend-components';
import { ref, watch, shallowRef, computed } from 'vue';
import SuccessBox from '~/components/general/SuccessBox.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight, faArrowLeft, faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import PlaybookLaunch from '../modals/PlaybookLaunch.vue';
import PlaybookPause from '../modals/PlaybookPause.vue';
import flowsClient from '~/services/flowsClient';
import { useToasted } from '~/composables/useToasted';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_UNPROCESSABLE_ENTITY } from '~/support/HttpStatuses';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { displayFirstError } from '~/utils/validation';

const toasted = useToasted();

const props = defineProps({
    flow: {
        type: [Object, undefined],
        default: undefined,
    }
})

const mounted = ref(false);

const params = new URLSearchParams(window.location.search);
const teamId = Spark.state.currentTeam.id;

const editing = computed(() => Boolean(props.flow?.flow_id));

const id = ref();

const name = ref('');
const notes = ref('');
const status = ref('draft');
const playbook = ref('');

const config = ref({});

const validate = useVuelidate({
    name: { required },
    notes: { required },
}, {
    name: name,
    notes: notes,
});

const playbookOptions = shallowRef(undefined);
const loading = ref(false);

const prepareNewForm = async () => {
    playbookOptions.value = playbookGallery.find(item => item.type === params.get('playbook'));

    if (!playbookOptions.value) {
        window.location.href = '/automation/flows';
    }

    notes.value = playbookOptions.value.description;

    config.value = playbookOptions.value.defaultConfig;
    playbook.value = playbookOptions.value.type;
}

const prepareEditForm = async () => {
    id.value = props.flow.flow_id;

    name.value = props.flow.name;
    notes.value = props.flow.notes;
    status.value = props.flow.status;
    playbook.value = props.flow.playbook;

    playbookOptions.value = playbookGallery.find(item => item.type === playbook.value);

    const configResponse = await flowsClient.getConfig(teamId, props.flow.flow_id);

    config.value = configResponse.data.config;
}

const prepareForm = async () => {
    if (loading.value) {
        return
    }

    try {
        mounted.value = false;
        loading.value = true;

        if (!props.flow) {
            await prepareNewForm();
        } else {
            await prepareEditForm();
        }

        mounted.value = true;
    } catch (e) {
        console.error(e);

        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
}

watch(() => props.flow?.id, async () => {
    prepareForm();
}, {
    immediate: true
});

const launched = ref(false);
const updated = ref(false);

const savedForLater = ref(false);

const error = ref(undefined);
const errors = ref({});
const saving = ref(false);

const save = async () => {
    const isValid = await validate.value.$validate();

    if (!isValid) {
        toasted.error('Please check form data.');
        throw new Error('Form is invalid.');
    }

    if (saving.value) {
        throw new Error('Wait before trying again.');
    }

    try {
        saving.value = true;
        errors.value = {};

        const { data } = await flowsClient.createOrUpdate(teamId, {
            flow_id: id.value,
            name: name.value,
            notes: notes.value,
            playbook: playbook.value,
        });

        id.value = data.flow_id;
        status.value = data.status;

        await flowsClient.saveConfig(teamId, id.value, {
            config: config.value
        });
    } catch (e) {
        console.error(e);

        if (e?.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
            errors.value = e.response.data.errors;
        }

        if (e?.response?.status === HTTP_INTERNAL_SERVER_ERROR) {
            error.value = 'An unexpected error occurred. Please try again.';
        } else {
            error.value = e.response?.data?.message;
        }


        toasted.error('Please check form data.');
        throw e;
    } finally {
        saving.value = false;
    }
}

const saveForLater = async () => {
   try {
        await save();
        savedForLater.value = true;
   } catch (e) {
        console.error(e)
   }
}

const update = async () => {
   try {
        await save();
        updated.value = true;
   } catch (e) {
        console.error(e)
   }
}

const statusModal = ref({});
const launchingId = ref(undefined);

const unpause = async (flow) => {
    if (launchingId.value) {
        return;
    }

    launchingId.value = flow.flow_id;

    try {
        await flowsClient.unpause(teamId, flow.flow_id);
        status.value = 'active'
    } catch (e) {
        console.error(e);
        if (e?.response?.status === HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
            return;
        }

        const errorMessage = e.response?.data?.message;
        toasted.error(errorMessage || 'Something wrong.');
    } finally {
        launchingId.value = undefined;
    }
}

const handleLaunch = (flow) => {
    if (flow.status === 'draft' || flow.status === 'active') {
        statusModal.value = flow;
    } else if (flow.status === 'paused') {
        unpause(flow);
    }
}

</script>
