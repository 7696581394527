<template>
    <playbook-form-card :icon="faAddressBook">
        <template v-slot:header>Contacts</template>
        <template v-slot:description>
            What contacts will be targeted by this Playbook?
        </template>

        <div>
            <vx-alert color="violet"
                bordered
                :closeable="false">
                <template v-slot:icon>
                    <font-awesome-icon class="vx-text-violet-900"
                        :icon="faPeopleGroup"></font-awesome-icon>
                </template>
                <div class="vx-flex vx-justify-between vx-font-extrabold vx-text-lg">
                    <div>
                        Audience
                    </div>
                    <div class="vx-text">
                        <vx-popover :hover="true"
                            arrow
                            placement="left">
                            {{ audienceTypeLabel(props.type) }}
                            <template v-slot:content>
                                <ul class="vx-max-w-[292px] vx-pl-3 vx-text-xs vx-list-disc vx-text-slate-500" v-html="audienceTypeDescription(props.type)">
                                </ul>
                            </template>
                        </vx-popover>
                    </div>
                </div>
            </vx-alert>
        </div>

        <div>
            <vx-label size="lg">
                Entry Point
                <template v-slot:description>
                    Only match contacts from these entry points.
                </template>
            </vx-label>

            <vx-radio-selection v-model="entryPointsTab">
                <vx-radio-selection-item value="recommended">
                    Recommended

                    <template v-slot:content>
                        <ul class="vx-pl-3 vx-list-disc vx-text-xs">
                            <li v-for="entryPoint in recommendedEntryPoints"
                                :key="entryPoint">
                                {{ entryPointLabel(entryPoint) }}
                            </li>
                        </ul>
                    </template>
                </vx-radio-selection-item>

                <vx-radio-selection-item value="custom" data-test="custom-entry-points">
                    Custom
                </vx-radio-selection-item>
            </vx-radio-selection>

            <div v-if="entryPointsTab === 'custom'" class="vx-grid vx-grid-cols-1 vx-gap-3 vx-relative vx-py-5 vx-pl-10 lg:vx-pl-14 lg:vx-grid-cols-3">
                <div class="vx-absolute vx-w-0.5 vx-bg-sky-500 vx-h-[calc(100%_-_8px)] vx-top-px vx-left-5 lg:vx-left-7"></div>
                <div class="vx-absolute vx-w-1 vx-h-1 vx-bg-sky-500 vx-rounded-full vx-bottom-0 vx-left-[19px] lg:vx-left-[27px]"></div>
                <div v-for="entryPoint in entryPointsForSelect({ includeLegacy: true })"
                    data-test="toggle-entry-point"
                    :key="entryPoint.value"
                    class="vx-flex vx-items-center vx-text-sm vx-gap-2 vx-cursor-pointer lg:vx-whitespace-nowrap"
                    :class="{
                        'vx-text-slate-800': selectedEntryPoints.includes(entryPoint.value),
                        'vx-text-slate-500': !selectedEntryPoints.includes(entryPoint.value),
                    }"
                    tabindex="0"
                    @click.prevent="toggleEntryPoint(entryPoint.value)"
                    @keydown.space.prevent="toggleEntryPoint(entryPoint.value)"
                    @keydown.enter.prevent="toggleEntryPoint(entryPoint.value)">

                    <vx-checkbox :modelValue="selectedEntryPoints.includes(entryPoint.value)">
                    </vx-checkbox>

                    {{ entryPoint.label }}
                </div>

            </div>

        </div>


        <div>
            <vx-label size="lg" class="vx-mb-1.5">
                Include Prior Matches
            </vx-label>

            <vx-radio-selection :modelValue="props.modelValue.include_prior_matches ? 'yes' : 'no'" @update:modelValue="($event) => {
                emitValue('include_prior_matches', $event === 'yes' ? true : false);
            }" data-test="include-prior-matches">
                <vx-radio-selection-item value="yes">
                    Yes
                    <template v-slot:content>
                        Process contacts from anytime.
                    </template>
                </vx-radio-selection-item>

                <vx-radio-selection-item value="no">
                    No - Recommended
                    <template v-slot:content>
                        Only process contacts that are matched after this flow is launched.
                    </template>
                </vx-radio-selection-item>
            </vx-radio-selection>
        </div>

        <div>
            <vx-label size="lg">
                Matching
                <template v-slot:description>
                    Add rules that a contact must meet.
                </template>
            </vx-label>

            <div>
                <component v-for="(customAttribute, index) in customAttributes"
                    :is="customAttribute.value === null || customAttribute.value === undefined ? PlaybookAudienceCustomAttributeExists : PlaybookAudienceCustomAttributeValue"
                    :modelValue="customAttribute"
                    :key="'customAttribute' + index"
                    @update:modelValue="updateCustomAttribute(index, $event)"
                    @remove="removeCustomAttribute(index)"></component>

                <playbook-audience-tag-exists v-for="(tag, index) in tags"
                    :modelValue="tag"
                    :key="'tag' + index"
                    @update:modelValue="updateTag(index, $event)"
                    @remove="removeTag(index)"></playbook-audience-tag-exists>
            </div>

            <div class="vx-gap-2 vx-flex vx-flex-col lg:vx-items-start">
                <vx-button @click="addCustomAttributeValue()"
                    data-test="add-custom-attribute-value"
                    type="button"
                    color="muted-light"
                    size="md">
                    Custom Attribute Key & Value
                    <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
                <vx-button @click="addCustomAttributeExists()"
                    data-test="add-custom-attribute-exists"
                    type="button"
                    color="muted-light"
                    size="md">
                    Custom Attribute Value
                    <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
                <vx-button @click="addTag()"
                    data-test="add-tag"
                    type="button"
                    color="muted-light"
                    size="md">
                    Tag Name
                    <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
            </div>
        </div>
    </playbook-form-card>
</template>

<script setup>
import { faAddressBook, faCirclePlus, faPeopleGroup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxAlert, VxButton, VxCheckbox, VxLabel, VxPopover, VxRadioSelection, VxRadioSelectionItem } from '@voxie/frontend-components';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { entryPoint as entryPointLabel, entryPointsForSelect } from '~/components/filters';
import { audienceTypeDescription, audienceTypeLabel } from '~/constants/campaign';
import PlaybookFormCard from '../../PlaybookFormCard.vue';
import PlaybookAudienceCustomAttributeExists from './PlaybookAudienceCustomAttributeExists.vue';
import PlaybookAudienceCustomAttributeValue from './PlaybookAudienceCustomAttributeValue.vue';
import PlaybookAudienceTagExists from './PlaybookAudienceTagExists.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => ({})
    },
    type: {
        type: String,
        required: true,
    }
});

const emit = defineEmits(['update:modelValue']);

const emitValue = (key, value) => {
    emit('update:modelValue', {
        ...props.modelValue,
        [key]: value,
    })
}

const entryPointsTab = ref('recommended');
const selectedEntryPoints = computed(() => props.modelValue?.entry_points || []);

const recommendedEntryPointsSelected = computed(() => {
    if (selectedEntryPoints.value.length !== recommendedEntryPoints.length) {
        return false;
    }

    return selectedEntryPoints.value.every(entryPoint => recommendedEntryPoints.includes(entryPoint));
});

const recommendedEntryPoints = ['api', 'automated_upload', 'connect'];

watch(entryPointsTab, (tab) => {
    if (tab === 'recommended') {
        emitValue('entry_points', [...recommendedEntryPoints]);
    }
});

onMounted(async () => {
    if (!selectedEntryPoints.value.length) {
        await nextTick();
        emitValue('entry_points', [...recommendedEntryPoints])
    }

    await nextTick();
    entryPointsTab.value = recommendedEntryPointsSelected.value ? 'recommended' : 'custom';

    if (!props.modelValue.include_prior_matches) {
        await nextTick();
        emitValue('include_prior_matches', false);
    }
})

const toggleEntryPoint = (entryPoint) => {
    if (selectedEntryPoints.value.includes(entryPoint)) {
        emitValue('entry_points', selectedEntryPoints.value.filter(item => item !== entryPoint));
    } else {
        emitValue('entry_points', [...selectedEntryPoints.value, entryPoint]);
    }
}

// custom attributes management
const customAttributes = computed(() => (props.modelValue?.custom_attributes || []))
const addCustomAttributeValue = () => {
    emitValue('custom_attributes', [
        ...customAttributes.value,
        {
            key: '',
            mode: 'include',
            value: '',
        }
    ])
}
const addCustomAttributeExists = () => {
    emitValue('custom_attributes', [
        ...customAttributes.value,
        {
            key: '',
            mode: 'include',
        }
    ])
}

const removeCustomAttribute = (removeIndex) => {
    emitValue('custom_attributes', customAttributes.value.filter((item, index) => removeIndex != index))
}

const updateCustomAttribute = (updateIndex, data) => {
    const values = [...customAttributes.value];

    values[updateIndex] = data;
    emitValue('custom_attributes', values)
}

// tags management
const tags = computed(() => (props.modelValue?.tags || []))
const addTag = () => {
    emitValue('tags', [
        ...tags.value,
        {
            name: '',
            mode: 'include',
        }
    ])
}

const removeTag = (removeIndex) => {
    emitValue('tags', tags.value.filter((item, index) => removeIndex != index))
}

const updateTag = (updateIndex, data) => {
    const values = [...tags.value];

    values[updateIndex] = data;
    emitValue('tags', values)
}

</script>
