<template>
    <vx-popover class="vx-w-full"
        hover
        :arrow="true"
        placement="left">
        <vx-dropdown-item :disabled="!isExportAllowed"
            @click="exportSegmentContacts()">
            Export Contacts
            <font-awesome-icon :icon="faFileArrowDown"></font-awesome-icon>
        </vx-dropdown-item>
        <template v-if="!isExportAllowed"
            v-slot:content>
            You do not have permission to export contacts.
        </template>
    </vx-popover>
</template>
<script setup>
import {
    VxDropdownItem,
    VxPopover
} from "@voxie/frontend-components";
import { useToasted } from '../../../composables/useToasted';
import contactsClient from '../../../services/contactsClient';
import { faFileArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const props = defineProps({
    segmentId: {
        type: Number,
        required: true,
    },
});

const isExportAllowed = Spark.isAdminOrOwner || Spark.roleOnCurrentTeam === 'manager';
const teamId = Spark.state.currentTeam.id;

const toasted = useToasted();

const exportSegmentContacts = async () => {
    if (!isExportAllowed) {
        return;
    }

    try {
        await contactsClient.contactsExport(teamId, null, {
            segment_id: props.segmentId,
        });

        toasted.success("Contact export started successfully. We'll email you when it's ready.");
    } catch (error) {
        console.error(error);
        toasted.global.platform_error();
    }
};


</script>
