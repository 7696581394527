import axios from '~/services/axios';
import BaseClient from './BaseClient';

class ContactsClient extends BaseClient {
    contactsGetAll($teamId, cursor) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/contacts`;

        return this.makeCoreApiRequest(url, method, null, { cursor });
    }

    contactsExpectedCount(teamId, messageType, contactIds) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/contacts/expected-count`;
        const params = {
            contacts: contactIds.join(','),
            message_type: messageType,
        };

        return this.makeCoreApiRequest(url, method, null, params);
    }

    contactGet($teamId, $contactId, config = {}) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/contacts/${$contactId}`;

        return this.makeCoreApiRequest(url, method, undefined, undefined, config);
    }

    contactSave($teamId, $contact) {
        const method = 'post';
        const url = `/api/v3/teams/${$teamId}/contacts`;

        return this.makeCoreApiRequest(url, method, $contact);
    }

    contactUpdate($teamId, $contactId, $contact) {
        const method = 'put';
        const url = `/api/v3/teams/${$teamId}/contacts/${$contactId}`;

        return this.makeCoreApiRequest(url, method, $contact);
    }

    contactTagsList($teamId, $contactId, config = {}) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/contacts/${$contactId}/tags`;
        const params = {
            per_page: 100,
        };

        return this.makeCoreApiRequest(url, method, null, params, config);
    }

    contactAddTag($teamId, $contactId, $tag) {
        const method = 'post';
        const url = `/api/v3/teams/${$teamId}/contacts/${$contactId}/tags`;

        return this.makeCoreApiRequest(url, method, $tag);
    }

    contactDelete($teamId, $contactId) {
        const method = 'delete';
        const url = `/api/v3/teams/${$teamId}/contacts/${$contactId}`;

        return this.makeCoreApiRequest(url, method);
    }

    contactDeleteTag($teamId, $contactId, $tagName) {
        const method = 'delete';
        const url = `/api/v3/teams/${$teamId}/contacts/${$contactId}/tags/${encodeURIComponent($tagName)}`;

        return this.makeCoreApiRequest(url, method);
    }

    contactsSyncCustomAttributes($teamId, $contactId, $attributes) {
        const method = 'post';
        const url = `/api/v3/teams/${$teamId}/contacts/${$contactId}/custom-attributes/sync`;

        return this.makeCoreApiRequest(url, method, $attributes);
    }

    contactCustomAttributesList($teamId, $contactId, config = {}) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/contacts/${$contactId}/custom-attributes`;
        const params = {
            per_page: 100,
        };

        return this.makeCoreApiRequest(url, method, null, params, config);
    }

    contactsSyncTags($teamId, $contactId, $tags) {
        const method = 'post';
        const url = `/api/v3/teams/${$teamId}/contacts/${$contactId}/tags/sync`;

        return this.makeCoreApiRequest(url, method, $tags);
    }

    generateImportUploadUrl(teamId, fileName, tags, subscriptionStatuses) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/contacts/upload-urls`;

        return this.makeCoreApiRequest(url, method, {
            number_of_files_to_be_uploaded: 1,
            original_file_name: fileName,
            tags: tags,
            subscription_statuses: subscriptionStatuses,
        });
    }

    importCsvFile(preSignedS3Url, file, uploadProgressHandler) {
        return axios.put(preSignedS3Url, file, {
            headers: {
                'Content-Type': 'text/csv',
            },
            onUploadProgress: (progressEvent) => {
                uploadProgressHandler(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
        });
    }

    contactsExport(teamId, type, params = {}) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/contacts/export`;

        return this.makeCoreApiRequest(url, method, {
            type,
            ...params,
        });
    }

    getContactsExportConfig(teamId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/contacts/export/config`;

        return this.makeCoreApiRequest(url, method);
    }

    saveContactsExportConfig(teamId, params) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/contacts/export/config`;

        return this.makeCoreApiRequest(url, method, params);
    }

    searchContacts(teamId, params) {
        const url = `/api/v3/teams/${teamId}/search/contacts`;

        params = params ?? {};
        params.per_page = `${params?.per_page || 50}`;

        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    contactsSegmentsCount(teamId, contactId) {
        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/segments/count`;

        return this.makeCoreApiRequest(url);
    }

    contactsCompletedCount(teamId, campaignId, contactId) {
        const url = `/api/v3/teams/${teamId}/campaigns/${campaignId}/contacts/${contactId}/completed/count`;

        return this.makeCoreApiRequest(url, 'get');
    }

    getContactSegments(teamId, contactId, params, config = {}) {
        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/segments`;

        return this.makeCoreApiRequest(url, 'get', null, params, config);
    }

    getContactSubscriptionStatus(teamId, contactId, config = {}) {
        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/subscriptions`;

        return this.makeCoreApiRequest(url, 'get', undefined, undefined, config);
    }

    updateContactSubscription(teamId, contactId, type, status) {
        const statusRoute = {
            opt_in: 'opt-in',
            opt_out: 'opt-out',
        }[status];

        if (!statusRoute) {
            throw new Error(`Status route for "${status}" was not found.`);
        }

        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/subscriptions/${type}/${statusRoute}`;

        return this.makeCoreApiRequest(url, 'post');
    }

    contactSubscriptionOptIn(teamId, contactId, type) {
        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/subscriptions/${type}/opt-in`;

        return this.makeCoreApiRequest(url, 'post');
    }

    contactSubscriptionOptOut(teamId, contactId, type) {
        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/subscriptions/${type}/opt-out`;

        return this.makeCoreApiRequest(url, 'post');
    }

    listCampaigns(teamId, contactId, params) {
        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/campaigns`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    listPromotionCoupons(teamId, contactId, params, config = {}) {
        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/promotion-coupons`;
        return this.makeCoreApiRequest(url, 'get', null, params, config);
    }

    listContactGroups(teamId, contactId, config = {}) {
        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/groups`;
        return this.makeCoreApiRequest(url, 'get', undefined, undefined, config);
    }

    contactsSyncGroups(teamId, contactId, params) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/groups/sync`;

        return this.makeCoreApiRequest(url, method, params);
    }

    getStickyPhoneNumber(teamId, contactId) {
        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/sticky-phone-number`;
        return this.makeCoreApiRequest(url, 'get');
    }
}

export default new ContactsClient();
