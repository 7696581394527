<template>
    <div class="vx-relative vx-mb-4 vx-flex vx-flex-col vx-gap-2">
        <vx-box class="vx-relative vx-flex vx-p-4 vx-gap-2 vx-items-start vx-flex-wrap lg:vx-flex-nowrap lg:vx-py-3 lg:vx-pl-6 lg:vx-pr-3">
            <div class="vx-w-[100px] vx-shrink-0 vx-order-1 vx-pt-2">
                <font-awesome-icon :icon="faShareNodes"
                    class="vx-mr-2 vx-text-sky-500"></font-awesome-icon>
                <span class="vx-whitespace-nowrap vx-text-slate-800 vx-text-base vx-font-extrabold">
                    {{ props.matchIndex === 0 ? 'If' : 'And If' }}
                </span>
            </div>
            <div class="vx-grow vx-w-full vx-flex vx-flex-col vx-gap-2 vx-order-2 lg:vx-order-1 lg:vx-grow-0">
                <div data-test="attribute-name-wrapper"
                    :class="customAttributeKeyAndValue() ? 'vx-col-span-12' : 'vx-col-span-7'">
                    <vx-selectable placeholder="attribute name"
                        :model-value="match.attribute_name"
                        :options="attributes"
                        @update:model-value="match.attribute_name = $event"
                        :reduce="option => option.value"
                        :clearable="false"
                        :error="displayFirstError(validate.attribute_name.$errors)">
                        <template v-slot:no-options>
                            Select a Trigger Event to see options.
                        </template>
                        <template v-slot:selected-option="{ option }">
                            <div v-if="option.value === 'message.body.virtual.sentiment'" class="vx-flex vx-items-center vx-gap-2">
                                <span>{{ option.label.toLowerCase() }}</span>
                                <experimental-pill></experimental-pill>
                            </div>
                            <template v-else>
                                {{ option.label.toLowerCase() }}
                            </template>
                        </template>
                        <template v-slot:option="{ option }">
                            <div v-if="option.value === 'message.body.virtual.sentiment'" class="vx-flex vx-items-center vx-gap-2">
                                <span>{{ option.label.toLowerCase() }}</span>
                                <experimental-pill></experimental-pill>
                            </div>
                            <template v-else>
                                {{ option.label.toLowerCase() }}
                            </template>
                        </template>
                    </vx-selectable>
                </div>

                <div data-test="operator-wrapper"
                    class="vx-col-span-5"
                    v-show="showOperator">
                    <vx-selectable placeholder="operator"
                        v-model="match.operator"
                        :getOptionLabel="(option) => option.label.toLowerCase()"
                        :reduce="option => option.value"
                        :options="operators"
                        :clearable="false"
                        :error="displayFirstError(validate.operator.$errors)">
                    </vx-selectable>
                </div>

                <div data-test="value-wrapper"
                    :class="{
                        'vx-col-span-12': showOperator,
                        'vx-col-span-5': !showOperator
                    }"
                    v-show="showValue">
                    <vx-selectable v-if="valueOptions().length"
                        placeholder="value"
                        v-model="match.value"
                        :reduce="option => option.value"
                        :options="valueOptions()"
                        :clearable="false"
                        :error="displayFirstError(validate.value.$errors)" />
                    <vx-selectable v-else-if="apiCall"
                        placeholder="value"
                        :model-value="specialValue"
                        @update:model-value="specialValue = $event"
                        :options="state.autocompleteItems"
                        :loading="state.autocompleteLoading"
                        :reduce="option => option?.value || option"
                        :create-option="option => option"
                        @search="fetchOptions"
                        :multiple="multiple"
                        :taggable="isTaggable"
                        :clearable="false"
                        :error="displayFirstError(validate.value.$errors)" />
                    <vx-selectable v-else-if="isTaggable"
                        placeholder="value"
                        v-model="specialValue"
                        multiple
                        :taggable="true"
                        :clearable="false"
                        :error="displayFirstError(validate.value.$errors)" />
                    <vx-input v-else
                        v-model="match.value"
                        type="text"
                        placeholder="value"
                        :error="displayFirstError(validate.value.$errors)"></vx-input>
                </div>

                <div v-if="isCollectionFilterExpression()"
                    data-test="collection-filter-expression"
                    class="vx-col-span-12">
                    <vx-input v-model="collectionFilter"
                        type="text"
                        placeholder="Collection Filter Expression"></vx-input>
                </div>
            </div>
            <div class="vx-flex vx-ml-auto vx-order-1 lg:vx-order-2">
                <vx-button @click.prevent="removeMatch(props.matchGroupIndex, props.matchIndex)"
                    type="button"
                    size="md"
                    color="muted-light"
                    data-test="remove-filter">
                    <font-awesome-icon :icon="faTrash"></font-awesome-icon>
                </vx-button>
            </div>
        </vx-box>

        <vx-box class="vx-relative vx-p-4 vx-ml-10 lg:vx-ml-[122px] lg:vx-p-6"
            v-if="currentTimeAttribute()">
            <line-nested></line-nested>

            <div class="vx-font-extrabold vx-text-slate-800 vx-mb-4">Day Range</div>

            <time-range-selector v-for="(range, rangeIndex) in state.time_ranges"
                :key="range.id"
                v-model="state.time_ranges[rangeIndex]"
                @add="addTimeRange(rangeIndex)"
                @remove="removeTimeRange(rangeIndex)" />

            <div class="vx-font-extrabold vx-text-slate-800 vx-mb-4 vx-mt-6">Time Zone</div>
            <vx-selectable data-test="time-zone-select"
                placeholder="Time Zone"
                v-model="state.selectedTimeZone"
                :options="timeZones"
                :clearable="false">
            </vx-selectable>
        </vx-box>

        <vx-box data-test="custom-attribute-key-and-value-wrapper"
            class="vx-relative vx-p-4 vx-ml-10 lg:vx-ml-[122px] lg:vx-p-6"
            v-if="customAttributeKeyAndValue()">
            <line-nested></line-nested>

            <div class="vx-font-extrabold vx-text-slate-800 vx-mb-4">Custom Attribute</div>
            <div class="vx-flex vx-flex-col vx-gap-2 lg:vx-gap-4 lg:vx-grid lg:vx-grid-cols-12 ">
                <div class="vx-col-span-6">
                    <vx-selectable placeholder="key"
                        data-test="custom-attribute-key"
                        v-model="match.key"
                        :options="state.autocompleteItems"
                        :loading="state.autocompleteLoading"
                        :reduce="option => option?.value || option"
                        :create-option="option => option"
                        @search="fetchOptions"
                        :taggable="true"
                        :clearable="false"
                        :error="displayFirstError(validate.key.$errors)" />
                </div>

                <div class="vx-col-span-3">
                    <vx-selectable placeholder="data type"
                        data-test="custom-attribute-data-type"
                        v-model="match.data_type"
                        :options="baseDataTypes"
                        :getOptionLabel="(option) => option.label.toLowerCase()"
                        :reduce="option => option.value"
                        :clearable="false"
                        :error="displayFirstError(validate.data_type.$errors)">
                    </vx-selectable>
                </div>

                <div class="vx-col-span-3">
                    <vx-selectable placeholder="operator"
                        data-test="custom-attribute-operator"
                        v-model="match.operator"
                        :getOptionLabel="(option) => option.label.toLowerCase()"
                        :reduce="option => option.value"
                        :options="operators"
                        :clearable="false"
                        :error="displayFirstError(validate.operator.$errors)">
                    </vx-selectable>
                </div>

                <div class="vx-col-span-12">
                    <vx-input placeholder="value"
                        data-test="custom-attribute-value"
                        v-model="match.value"
                        type="text"
                        :error="displayFirstError(validate.value.$errors)"></vx-input>
                </div>

            </div>
        </vx-box>
    </div>
</template>

<script setup>
import TimeRangeSelector from '~/components/general/TimeRangeSelector.vue';
import { phoneAreaCodes } from '~/components/automation/phone-area-codes';
import { entryPointsForSelect } from '~/components/filters';
import { subscriptionStatusForSelect } from '~/constants/contactSubscription'
import timeZones from '~/utils/timeZones';
import axios from '~/services/axios';
import { computed, h, inject, nextTick, onMounted, reactive, watch } from 'vue';
import { useAutomationRule } from './useAutomationRule';
import { useAutomationRulesMetadata } from './useAutomationRulesMetadata';
import { MATCH_OPERATORS, MATCH_EQUALS_OPERATOR, MATCH_EQUALS_ANY_OPERATOR, MATCH_IS_NULL_OPERATOR, MATCH_IS_NOT_NULL_OPERATOR } from './matchItemHelpers';
import { randomString } from '~/utils/string';
import { useToasted } from '~/composables/useToasted';
import { VxInput, VxSelectable, VxBox, VxButton } from '@voxie/frontend-components';
import { getClientTz } from '~/utils/date';
import { faShareNodes, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import useVuelidate from '@vuelidate/core';
import { helpers, maxLength, required, requiredIf } from '@vuelidate/validators';
import { displayFirstError } from '~/utils/validation';
import debounce from '~/utils/debounce';
import ExperimentalPill from '../general/ExperimentalPill.vue';

const LineNested = h('div', { class: 'vx-absolute vx-top-9 -vx-left-[25px] vx-w-6 vx-h-px vx-border-solid vx-border-y vx-border-x-0 vx-border-t-slate-300 vx-border-b-white lg:-vx-left-20 lg:vx-w-[78px]' })

const FEATURES = inject('FEATURES');

const toasted = useToasted();

const props = defineProps({
    matchGroupIndex: {
        type: Number,
        required: true,
    },
    matchIndex: {
        type: Number,
        required: true,
    },
});

const { triggers } = useAutomationRulesMetadata();
const { data, removeMatch } = useAutomationRule();

const baseDataTypes = [
    { value: "text", label: "Text" },
    { value: "number", label: "Numeric" },
];

const state = reactive({
    autocompleteLoading: false,
    autocompleteItems: [],

    selectedTimeZone: getClientTz(),
    time_ranges: [],
})

const match = defineModel();
const validate = useVuelidate({
    attribute_name: {
        required: helpers.withMessage('Attribute is required', required),
    },
    key: {
        requiredIf: helpers.withMessage('Key is required', requiredIf(() => customAttributeKeyAndValue())),
    },
    data_type: {
        required: helpers.withMessage('Data Type is required', requiredIf(() => customAttributeKeyAndValue())),
    },
    operator: {
        required: helpers.withMessage('Operator is required', required),
    },
    value: {
        requiredIf: helpers.withMessage('Value is required', requiredIf(() => !nullableValue.value)),
        maxLength: maxLength(191)
    },
}, match, {
    $autoDirty: true,
});


const multiple = computed(() => !campaignIdAttribute() && !automationIdAttribute());

// used for apiCall, isTaggable and collectionFilter
// Collection filter raw value example: [filter] null [value] foo
const specialValue = computed({
    set: (newValue) => {
        if (!Array.isArray(newValue)) {
            // for campaign id and automation id handling
            // its not an array since its not multiple
            // we still want to parse the value
            newValue = [newValue];
        }

        // for taggable it returns only the label
        const commaValues = (newValue || [])
            .map(option => option?.value || option?.label || option)
            .filter(option => Boolean(option))
            .join(',');

        if (isCollectionFilterExpression()) {
            match.value.value = collectionFilter.value || commaValues ? `[filter] ${collectionFilter.value} [value] ${commaValues}` : '';
        } else {
            match.value.value = commaValues
        }
    },
    get: () => {
        if (isCollectionFilterExpression()) {
            // gets only the values after [value] from the raw data
            return (match.value.value || '').split('[value]')[1]?.trim().split(',').filter(value => value?.length) || [];
        }
        return (match.value.value || '').split(',').filter(value => value?.length)
    }
});

// collection filter value example: [filter] null [value] foo
const collectionFilter = computed({
    set: (filter) => {
        match.value.value = filter || specialValue.value ? `[filter] ${filter} [value] ${specialValue.value}` : '';
    },
    get: () => {
        // grab filter from before [value] and strip [filter]
        return (match.value.value || '').split('[value]')[0].replace('[filter]', '').trim();
    }
});

const usingAttribute = (attributeName) => {
    return (data.value.match_groups || []).some(matchGroup => {
        return (matchGroup.matches || []).some(match => match.attribute_name === attributeName);
    })
}

const attributes = computed(() => {
    const raw = triggers.value.find(trigger => trigger.value === data.value.event)?.attributes;
    if (!raw) {
        return [];
    }

    return Object.keys(raw).reduce((all, key) => {

        // hide sentiment when the feature is not enabled. only hides if not using it already
        // if the feature was enabled, then disabled the match will be still be present here
        if (key === 'message.body.virtual.sentiment' && !usingAttribute('message.body.virtual.sentiment') && !FEATURES.sentiment_analysis_ai)  {
            return all;
        }
        all.push({
            value: key,
            label: raw[key]
        })
        return all;
    }, []);
})

const teamId = Spark.state.currentTeam.id;

const apiCall = computed(() => {
    if (match.value.attribute_name === 'message.contact.tags.tag_name') {
        return {
            url: `/api/v3/teams/${teamId}/tags/autocomplete`,
            showValue: false,
        };
    }

    if (customAttributeKeyAndValue() || customAttributeKey()) {
        return {
            url: `/api/v3/teams/${teamId}/custom-attributes/autocomplete`,
            showValue: true,
        };
    }

    if (campaignIdAttribute()) {
        return {
            url: `/api/v3/teams/${teamId}/campaigns/autocomplete`,
            showValue: true,
        };
    }

    if (automationIdAttribute()) {
        return {
            url: `/api/v3/teams/${teamId}/search/automation-rules`,
            showValue: true,
        };
    }

    return null;
})

const entryPointAttribute = (attributeName) => {
    return (attributeName || match.value.attribute_name)?.includes('contact.entry_point');
};

const subscriptionStatusAttribute = (attributeName) => {
    return [
        'contact.marketing_subscription',
        'contact.transactional_subscription',
        'contact.previous_marketing_subscription',
        'contact.previous_transactional_subscription',
    ].some(suffix => (attributeName || match.value.attribute_name)?.includes(suffix));
};

const originTypeAttribute = (attributeName) => {
    return (attributeName || match.value.attribute_name)?.includes('origin_type');
};

const messageDirectionAttribute = (attributeName) => {
    return (attributeName || match.value.attribute_name)?.includes('message.direction')
}

const messageBodySentimentAttribute = (attributeName) => {
    return (attributeName || match.value.attribute_name) === 'message.body.virtual.sentiment';
}

const phoneStateAttribute = (attributeName) => {
    return (attributeName || match.value.attribute_name)?.includes('virtual.phone_state');
}

const customAttributeKeyAndValue = (attributeName) => {
    return (attributeName || match.value.attribute_name)?.includes('virtual.key_value');
}

const customAttributeKey = (attributeName) => {
    if (customAttributeKeyAndValue(attributeName)) {
        return false;
    }

    return [
        'contact.custom_attributes.key',
        'custom_attribute.key',
    ].some(suffix => (attributeName || match.value.attribute_name)?.includes(suffix))
}

const mediaUrlAttribute = (attributeName) => {
    return (attributeName || match.value.attribute_name)?.includes('message.media_url');
}

const currentTimeAttribute = (attributeName) => {
    return (attributeName || match.value.attribute_name) === 'current_time';
}

const isCollectionFilterExpression = (attributeName) => {
    if (customAttributeKeyAndValue(attributeName)) {
        return false;
    }

    return (attributeName || match.value.attribute_name)?.includes('custom_attributes');
};

const campaignIdAttribute = (attributeName) => {
    return ['contact.campaign_ids',
        'message.contact.campaign_ids',
        'message.antecedent_campaign_id',
        'short_url_click.short_url.contact.campaign_ids',
        'custom_attribute.contact.campaign_ids'].some(suffix => (attributeName || match.value.attribute_name)?.includes(suffix))
};
const automationIdAttribute = (attributeName) => {
    return ['message.antecedent_automation_rule_id'].some(suffix => (attributeName || match.value.attribute_name)?.includes(suffix))
};

const isTaggable = computed(() => {
    return [
        'custom_attribute.key',
        'contact.custom_attributes.key',
        'custom_attribute.virtual.key_value',
        'contact.custom_attributes.virtual.key_value',
        'message.contact.custom_attributes.virtual.key_value',
        'short_url_click.short_url.contact.custom_attributes.virtual.key_value',
        'message.contact.tags.tag_name',
        'message.contact.custom_attributes.key',
        'message.contact.custom_attributes.value'
    ].includes(match.value.attribute_name);
});

const nullableValue = computed(() => {
    return ['is_null', 'is_not_null', 'is_true', 'is_false'].includes(match.value.operator);
})

onMounted(async () => {
    if (currentTimeAttribute()) {
        // transforms 'America/New_York; 1_08:00-1_17:00; 2_08:00-2_17:00; 3_08:00-3_17:00; 4_08:00-4_21:33; 5_08:00-5_17:00; 6_13:00-6_17:00;'
        // into a workable array of objects and sets the timezone: state.selectedTimeZone = 'America/New_York';
        // array of objects example:
        // [ { day: 0, from: '08:00', to: '17:00' }, { day: 1, from: '08:00', to: '17:00' }, ... ]

        const rawValue = match.value.value || '';
        state.selectedTimeZone = rawValue.split(';')[0] || getClientTz(); // grabs tz

        let ranges = rawValue.replace(state.selectedTimeZone, '')
            .split(';')
            .filter(piece => !!piece) // removes empty values
            .map(piece => {
                const [from, to] = piece.split('-');
                return {
                    id: randomString(),
                    day: Number(from.split('_')[0].trim()),
                    from: from.split('_')[1].trim(),
                    to: to.split('_')[1].trim(),
                }
            });

        if (!ranges.length) {
            ranges = [makeTimeRange()];
        }

        state.time_ranges = ranges;
    }
    await nextTick();
    // necessary to prefill the campaign name and automation name
    if (campaignIdAttribute() || automationIdAttribute()) {
        fetchOptions(match.value.value);
    }
})

watch(() => data.value.event, async () => {
    await nextTick();
    //if a newly selected event doesn't provide the same attributes for existing matches
    //then deselect the attribute name / operator / value; effectivley resetting the match row
    // TODO: Retain when it's the same but for different initial resource e.g.
    // TODO: short_url_click.short_url.contact.phone = message.contact.phone
    if (match.value.attribute_name) {
        const exists = Boolean(attributes.value.find(option => option.value == match.value.attribute_name));
        if (!exists) {
            match.value.attribute_name = null;
            match.value.operator = null;
            match.value.value = null;
            match.value.key = null;
            match.value.data_type = null;
        }
    }
}, {
    immediate: true,
});

watch(() => match.value.value, () => {
    if (!match.value.value) {
        return;
    }

    // set custom operator for predefined value and default operator when value option provides it
    const predefinedOperator = valueOptions().find(option => option.value === match.value.value)?.operator_value;
    if (predefinedOperator) {
        match.value.operator = predefinedOperator;
    }
})

watch([() => state.time_ranges, () => state.selectedTimeZone], () => {
    if (!currentTimeAttribute()) {
        return;
    }

    //Sunday is the starting day of the week and it's index is 0.
    //'America/New_York; 1_08:00-1_17:00; 2_08:00-2_17:00; 3_08:00-3_17:00; 4_08:00-4_21:33; 5_08:00-5_17:00; 6_13:00-6_17:00;'
    let str = `${state.selectedTimeZone}; `;

    state.time_ranges.forEach((range) => {
        str += `${range.day}_${range.from}-${range.day}_${range.to}; `;
    });

    match.value.value = str.trim();
}, {
    deep: true,
});


watch(() => match.value.operator, () => {
    if (nullableValue.value) {
        match.value.value = null;
    }
});

watch(() => match.value.data_type, async () => {
    await nextTick()
    if (!operators.value.find(operator => operator.value == match.value.operator)) {
        match.value.operator = null;
    }
})

watch(() => match.value.attribute_name, async (attributeName, previousAttributeName) => {
    if (!customAttributeKeyAndValue()) {
        match.value.data_type = null;
        match.value.key = null;
    }

    // When the previous attribute have options we only retain the value
    // in case it exists in the new available valueOptions
    const valueExists = Boolean(valueOptions().find(option => option.value === match.value.value));
    if (valueOptions(previousAttributeName).length && !valueExists) {
        match.value.value = null;
    }
    // When previous was current time or collectionFilterExpression, unsets the value
    if (currentTimeAttribute(previousAttributeName) || isCollectionFilterExpression(previousAttributeName)) {
        match.value.value = null;
    }

    if (apiCall.value) {
        match.value.value = null;
    }

    // If operator does not exist clear it. In case there are options set to equals.
    // When there are valueOptions some cases do not require an operator or have
    // a predefined operator such as subscription status
    const operatorExists = Boolean(operators.value.find(operator => operator.value === match.value.operator));
    if (!operatorExists) {
        match.value.operator = valueOptions().length ? MATCH_EQUALS_OPERATOR.value : null;
    }

    if (currentTimeAttribute()) {
        match.value.operator = 'in_range';
        state.time_ranges = [
            { id: randomString(), day: 1, from: "08:00", to: "17:00" },
            { id: randomString(), day: 2, from: "08:00", to: "17:00" },
            { id: randomString(), day: 3, from: "08:00", to: "17:00" },
            { id: randomString(), day: 4, from: "08:00", to: "17:00" },
            { id: randomString(), day: 5, from: "08:00", to: "17:00" },
            { id: randomString(), day: 6, from: "08:00", to: "17:00" },
            { id: randomString(), day: 0, from: "08:00", to: "17:00" },
        ]
    }

    fetchOptions();
})

const areaCodesOptions = phoneAreaCodes.map(pac => ({
    value: pac.internal,
    label: pac.state,
}));

const valueOptions = ((attributeName) => {
    if (entryPointAttribute(attributeName)) {
        return entryPointsForSelect()
    }

    if (subscriptionStatusAttribute(attributeName)) {
        return subscriptionStatusForSelect();
    }

    if (originTypeAttribute(attributeName)) {
        return [
            { value: 'App\\CampaignMessage', label: 'Is Campaign Message', operator_value: MATCH_EQUALS_OPERATOR.value },
            { value: 'App\\AutomatedMessage', label: 'Is Automated Message', operator_value: MATCH_EQUALS_OPERATOR.value },
            { value: 'App\\User,App\\ScheduledMessage', label: 'Is User Message', operator_value: MATCH_EQUALS_ANY_OPERATOR.value },
        ]
    }

    if (phoneStateAttribute(attributeName)) {
        return areaCodesOptions;
    }

    if (messageDirectionAttribute(attributeName)) {
        return [
            { value: 'inbound', label: 'Is Inbound' },
            { value: 'outbound', label: 'Is Outbound' },
        ]
    }

    if (messageBodySentimentAttribute(attributeName)) {
        return [
            { value: 'positive', label: 'Is Positive' },
            { value: 'negative', label: 'Is Negative' },
            { value: 'neutral', label: 'Is Neutral' },
        ]
    }
    return []
});

const operators = computed(() => {
    if (!match.value.attribute_name) {
        return [];
    }

    if (match.value.attribute_name == 'current_time') {
        return MATCH_OPERATORS.current_time;
    }

    if (originTypeAttribute()) {
        return [MATCH_EQUALS_OPERATOR, MATCH_EQUALS_ANY_OPERATOR];
    }

    if (messageDirectionAttribute() || messageBodySentimentAttribute()) {
        return [MATCH_EQUALS_OPERATOR];
    }

    if (mediaUrlAttribute()) {
        return [{ value: MATCH_IS_NULL_OPERATOR.value, label: 'Is Not Present' }, { value: MATCH_IS_NOT_NULL_OPERATOR.value, label: 'Is Present' }];
    }

    if (match.value.attribute_name.indexOf('tags.tag_name') >= 0) {
        return MATCH_OPERATORS.includes;
    }

    if (match.value.attribute_name.indexOf('message.antecedent_') >= 0) {
        return [MATCH_EQUALS_OPERATOR];
    }

    if (
        match.value.attribute_name.indexOf('shopify_checkout.total_price') >= 0
        || match.value.attribute_name.indexOf('shopify_checkout.total_discounts') >= 0
        || match.value.attribute_name.indexOf('shopify_checkout.total_items') >= 0
        || match.value.attribute_name.indexOf('shopify_order.total_price') >= 0
        || match.value.attribute_name.indexOf('shopify_order.item_count') >= 0
        || match.value.attribute_name.indexOf('shopify_order.total_discounts') >= 0
    ) {
        return MATCH_OPERATORS.shopify;
    }

    if (subscriptionStatusAttribute()) {
        return [MATCH_EQUALS_OPERATOR];
    }

    if (entryPointAttribute()) {
        return MATCH_OPERATORS.contact_entrypoint;
    }

    if (phoneStateAttribute()) {
        return MATCH_OPERATORS.contact_phone_state;
    }

    if (match.value.attribute_name.indexOf('campaign.id') >= 0) {
        return MATCH_OPERATORS.equals;
    }

    if (campaignIdAttribute()) {
        return MATCH_OPERATORS.includes;
    }

    /**
     * Moving on to checks for variable type
     */
    if (match.value.data_type) {
        if (match.value.data_type === 'number') {
            return MATCH_OPERATORS.numeric_data_type;
        } else {
            return MATCH_OPERATORS.text_data_type;
        }
    } else if (isAttrAny()) {
        return [].concat(MATCH_OPERATORS.any, MATCH_OPERATORS.boolean, MATCH_OPERATORS.string, MATCH_OPERATORS.numeric)
    } else if (isAttrString()) {
        return [].concat(MATCH_OPERATORS.any, MATCH_OPERATORS.string)
    } else if (isAttrNumeric()) {
        return [].concat(MATCH_OPERATORS.any, MATCH_OPERATORS.numeric);
    }

    return MATCH_OPERATORS.any;
});

const isAttrString = () => {
    if (isAttrAny()) {
        return true;
    }

    if (isAttrNumeric()) {
        return false;
    }

    // everything not numeric or ANY should be a string
    return true;
};

const isAttrNumeric = () => {
    if (isAttrAny()) {
        return true;
    }

    if (match.value.attribute_name.match(/_at$/)) {
        return true;
    }

    return false;
};

const isAttrAny = () => [
    'custom_attribute.value',
    'custom_attributes.value'
].some(universal => match.value.attribute_name.includes(universal));

const fetchOptions = debounce((search) => {
    if (!apiCall.value) {
        return;
    }

    state.autocompleteLoading = true;
    axios.get(apiCall.value.url, {
        params: {
            query: search
        }
    })
        .then(response => {
            state.autocompleteItems = response.data.data.map((row) => {
                if (typeof row === 'string') {
                    return {
                        label: row,
                        value: row,
                    };
                }

                const label = row.name || row.value;
                const value = String(row.id || row.value);
                return {
                    label: `${value && apiCall.value.showValue ? value + ' - ' : ''}${label}`,
                    value: value,
                }
            });
        })
        .catch((e) => {
            state.autocompleteItems = [];
            console.error(e);
            toasted.global.platform_error();
        })
        .finally(() => {
            state.autocompleteLoading = false;
        });
}, 300);

const showOperator = computed(() => {
    if (!match.value.attribute_name) {
        return false;
    }

    // Custom attribute key AND value has a special logic to display the value and operator. Check the template.
    if (customAttributeKeyAndValue()) {
        return false;
    }

    return ![
        'origin_type',
        'message.direction',
        'message.body.virtual.sentiment',
        'contact.marketing_subscription',
        'contact.transactional_subscription',
        'contact.previous_marketing_subscription',
        'contact.previous_transactional_subscription',
    ].some(hiddenAttribute => match.value.attribute_name.includes(hiddenAttribute))
});

const showValue = computed(() => {
    if (nullableValue.value) {
        return false;
    }

    // Custom attribute key AND value has a special logic to display the value and operator. Check the template.
    if (customAttributeKeyAndValue()) {
        return false;
    }

    // Current time have special logic
    if (currentTimeAttribute()) {
        return false;
    }

    if (mediaUrlAttribute()) {
        return false;
    }

    if (!match.value.attribute_name) {
        return false;
    }

    return true;
});

const removeTimeRange = (rangeIndex) => {
    if (state.time_ranges.length === 1) {
        toasted.error('At least one day range is required.')

        return;
    }

    state.time_ranges = state.time_ranges.filter((currentRange, currentRangeIndex) => currentRangeIndex != rangeIndex);
};

const addTimeRange = (afterRangeIndex) => {
    state.time_ranges.splice(afterRangeIndex + 1, 0, makeTimeRange());
};

const makeTimeRange = () => {
    return { id: randomString(), day: 1, from: "08:00", to: "17:00" };
};
</script>
