import BaseClient from './BaseClient';

class OrganizationsClient extends BaseClient {
    getInvites(teamId, cursor) {
        if (cursor) {
            cursor = `&cursor=${cursor}`;
        } else {
            cursor = '';
        }

        const url = `/api/v3/teams/${teamId}/organization/invites?per_page=10${cursor}`;
        return this.makeCoreApiRequest(url);
    }

    createInvite(teamId, email) {
        const url = `/api/v3/teams/${teamId}/organization/invites`;
        return this.makeCoreApiRequest(url, 'post', { email });
    }

    resendInvite(teamId, id) {
        const url = `/api/v3/teams/${teamId}/organization/invites/${id}/resend`;
        return this.makeCoreApiRequest(url, 'post');
    }

    deleteInvite(teamId, id) {
        const url = `/api/v3/teams/${teamId}/organization/invites/${id}`;
        return this.makeCoreApiRequest(url, 'delete');
    }

    getTeams(teamId) {
        const url = `/api/v3/teams/${teamId}/organization/teams`;
        return this.makeCoreApiRequest(url);
    }
}

export default new OrganizationsClient();
