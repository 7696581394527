<template>
    <div>
        <playbook-audience :modelValue="props.modelValue.audience || {}"
            @update:modelValue="emitValue('audience', $event)"
            :type="AUDIENCE_TYPE_MARKETING"></playbook-audience>

        <playbook-initial-delay :modelValue="props.modelValue.initial_delay"
            @update:modelValue="emitValue('initial_delay', $event)"></playbook-initial-delay>

        <playbook-custom-questions :modelValue="questions"
            @update:modelValue="emitValue('questions', $event)"></playbook-custom-questions>

        <playbook-final-message :modelValue="props.modelValue.final_message"
            @update:modelValue="emitValue('final_message', $event)"></playbook-final-message>
    </div>
</template>

<script setup>
import { AUDIENCE_TYPE_MARKETING } from '~/constants/campaign';
import PlaybookAudience from './audience/PlaybookAudience.vue';
import PlaybookCustomQuestions from './PlaybookCustomQuestions.vue';
import PlaybookFinalMessage from './PlaybookFinalMessage.vue';
import { computed } from 'vue';
import PlaybookInitialDelay from './PlaybookInitialDelay.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => ({})
    },
});

const emit = defineEmits(['update:modelValue']);

const emitValue = (key, value) => {
    emit('update:modelValue', {
        ...props.modelValue,
        [key]: value,
    });
}

const questions = computed(() => props.modelValue.questions || [])

</script>
