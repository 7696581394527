<template>
    <div>
        <!-- Update Extra Billing Information -->
        <update-extra-billing-information
            :team="team"
            :owns-team="ownsTeam"
            :isTeamAdmin="isTeamAdmin"
        />
        <update-billing-contact-email-address
            :team="team"
            :owns-team="ownsTeam"
            :isTeamAdmin="isTeamAdmin"
        />

        <!-- Invoice List -->
        <div v-if="invoices.length > 0">
            <invoice-list
                :team="team"
                :invoices="invoices"
            />
        </div>
    </div>
</template>

<script>
import UpdateExtraBillingInformation from './UpdateExtraBillingInformation.vue';
import UpdateBillingContactEmailAddress from './UpdateBillingContactEmailAddress.vue';
import InvoiceList from './InvoiceList.vue';
import axios from '~/services/axios';

export default {
    components: {
        UpdateExtraBillingInformation,
        UpdateBillingContactEmailAddress,
        InvoiceList,
    },
    props: ['team', 'ownsTeam', 'isTeamAdmin'],

	/**
	 * The component's data.
	 */
	data() {
		return {
			invoices: []
		};
	},

	/**
	 * Prepare the component.
	 */
	mounted() {
		this.getInvoices();
	},

	methods: {
		/**
		 * Get the teams's billing invoices
		 */
		getInvoices() {
			axios.get(this.urlForInvoices)
				.then(response => {
					this.invoices = response.data.filter(invoice => {
						return invoice.total != '$0.00';
					});
				});
		},
	},

	computed: {
		/**
		 * Get the URL for retrieving the invoices.
		 */
		urlForInvoices() {
			return `/settings/teams/${this.team.id}/invoices`;
		},
	},
};
</script>
