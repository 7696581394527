<template>
    <vx-selectable placeholder="attribute name"
        :modelValue="props.modelValue"
        label="label"
        :getOptionLabel="(option) => option.label.toLowerCase()"
        :reduce="attribute => attribute.value"
        :options="attributes"
        @update:modelValue="emit('update:modelValue', $event)"
        data-test="attribute-name"
        :clearable="false">
    </vx-selectable>
</template>

<script setup>
import { VxSelectable } from '@voxie/frontend-components';

const attributes = [
    {
        value: "contacts.first_name",
        label: "Contact First Name"
    },
    {
        value: "contacts.last_name",
        label: "Contact Last Name"
    },
    {
        value: "contacts.email",
        label: "Contact Email"
    },
    {
        value: "contacts.phone",
        label: "Contact Phone Number"
    },
    {
        value: "contacts.phone_state",
        label: "Contact Phone Number State"
    },
    {
        value: "contacts.entry_point",
        label: "Contact Entry Point"
    },
    {
        value: "contacts.created_at",
        label: "Contact Created"
    },
    {
        value: "contacts.updated_at",
        label: "Contact Updated"
    },
    {
        value: "contacts.custom_attributes.key",
        label: "Contact has a Custom Attribute Key"
    },
    {
        value: "contacts.custom_attributes.exclude",
        label: "Exclude Contact that has a Custom Attribute Key"
    },
    {
        value: "contacts.custom_attributes.value",
        label: "Contact has a custom attribute where the value"
    },
    {
        value: "contacts.custom_attributes.created_at",
        label: "Contact has a Custom Attribute Created"
    },
    {
        value: "contacts.custom_attributes.updated_at",
        label: "Contact has a Custom Attribute Updated"
    },
    {
        value: "contacts.custom_attributes.key_value",
        label: "Contact has a Custom Attribute with Key and Value"
    },
    {
        value: "contacts.custom_attributes.exclude_key_value",
        label: "Exclude Contact that has a Custom Attribute with Key and Value"
    },
    {
        value: "contacts.tags.tag_name",
        label: "Contact has a Tag"
    },
    {
        value: "contacts.tags.exclude_tags",
        label: "Exclude Contact that has a Tag"
    }
];
const props = defineProps({
    modelValue: {
        required: true,
    },
})

const emit = defineEmits(['update:modelValue'])
</script>
