<template>
    <div>
        <toggle-action-data v-model="data.use_event_custom_attribute"
            :open="!data.use_event_custom_attribute"
            class="vx-mb-2">
            <template v-slot:label>
                Use <strong>custom attribute</strong> value from trigger event
            </template>

            <div class="vx-flex vx-flex-col vx-gap-2 lg:vx-grid lg:vx-grid-cols-12">
                <div class="vx-col-span-4">
                    <vx-label>Key</vx-label>
                    <select-custom-attribute-key data-test="custom-attribute-key"
                        size="lg"
                        v-model="data.attribute_name"
                        :error="displayFirstError(validate.attribute_name)"></select-custom-attribute-key>
                </div>

                <div class="vx-col-span-3">
                    <vx-label>Type</vx-label>
                    <vx-selectable size="lg"
                        :options="[{ value: 'text', label: 'Text' }, { value: 'current_date', label: 'Current Date' }]"
                        :reduce="option => option.value"
                        :clearable="false"
                        v-model="data.attribute_value_type"
                        data-test="custom-attribute-type"
                        :error="displayFirstError(validate.attribute_value_type)">
                    </vx-selectable>
                </div>

                <div v-if="data.attribute_value_type === 'text'"
                    class="vx-col-span-5">
                    <vx-label>Value</vx-label>
                    <vx-input size="lg"
                        type="text"
                        data-test="custom-attribute-value"
                        v-model="data.attribute_value"
                        :error="displayFirstError(validate.attribute_value)"
                        placeholder="Custom Attribute Value" />
                </div>
            </div>
        </toggle-action-data>

        <use-event-contact-action-data v-model="data"></use-event-contact-action-data>
    </div>
</template>

<script setup>
import { VxInput, VxSelectable, VxLabel } from '@voxie/frontend-components';
import SelectCustomAttributeKey from '~/components/general/SelectCustomAttributeKey.vue';
import UseEventContactActionData from './UseEventContactActionData.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf } from '@vuelidate/validators';
import { displayFirstError } from '~/utils/validation';
import ToggleActionData from './ToggleActionData.vue';

const data = defineModel();

if (data.value.use_event_custom_attribute == undefined) {
    data.value.use_event_custom_attribute = false;
}

if (data.value.attribute_value_type == undefined) {
    data.value.attribute_value_type = 'text';
}

const validate = useVuelidate({
    attribute_name: { requiredIf: helpers.withMessage('Key is required', requiredIf(() => !data.value.use_event_custom_attribute)) },
    attribute_value_type: { requiredIf: helpers.withMessage('Type is required', requiredIf(() => !data.value.use_event_custom_attribute)) },
    attribute_value: { requiredIf: helpers.withMessage('Value is required', requiredIf(() => !data.value.use_event_custom_attribute && data.value.attribute_value_type === 'text')) },
}, data, {
    $autoDirty: true,
});
</script>

