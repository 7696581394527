<template>
    <modal
        v-model:visible="showModal"
        title="Respond to next conversation?"
        submit-text="Yes, respond"
        close-text="Cancel"
        size="md"
        @submit="respondToNext"
    >
        <div class="form-group d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <span v-if="thread" class="date-time mb-1">{{ threadLastMessage }}</span>
            <span v-if="latestMessage" class="message-content mb-1">“{{ latestMessage}}”</span>
        </div>
    </modal>
</template>

<script>
import Modal from '../../../../general/Modal.vue';
import {mapActions, mapGetters} from 'vuex'
import { dateTime } from '../../../../filters';
import { threadsClient } from '../../../../../services';

export default {
    name: 'RespondNextModal',
    props: {},
    components: {
        Modal,
    },
    data() {
        return {
            showModal: false,
            thread: null
        }
    },
    mounted() {
        Bus.$on('showRespondNextModal', () => {
            this.showModal = true;

            const teamId = Spark.state.currentTeam.id;
            threadsClient.threadsGetAll(teamId, {
                'status': 'unassigned'
            }).then(({data}) => {
                this.thread = data.data.find(t => t.team_id == teamId && t.contact_id !== undefined);
            });
        })
    },
    computed: {
        ...mapGetters({
            'threads': 'getThreads'
        }),
        threadLastMessage() {
            return dateTime(this.thread.last_message_at);
        },
        latestMessage () {
            if (this.thread) {
                return this.thread.last_message_content.length > 32 ?
                    this.thread.last_message_content.slice(0, 32) :
                    this.thread.last_message_content
            } else {
                return '';
            }
        }
    },
    methods: {
        ...mapActions({
            'loadConversation': 'contacts_v3/loadConversation',
        }),
        async respondToNext () {
            if (this.thread && this.thread.contact_id) {
                this.loadConversation({contactId: this.thread.contact_id, $toasted: this.$toasted, thread: this.thread}).then(() => {
                    this.$emit('respondToNext', this.thread)
                    this.showModal = false;
                });
            } else {
                this.showModal = false;
            }
        },
    }
}
</script>

<style scoped>

</style>
