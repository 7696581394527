<template>
    <vx-dropdown teleport placement="right">
        <vx-button
            :disabled="processing"
            color="muted"
            :outline="true"
            data-test="campaign-dropdown"
        >
            <font-awesome-icon
                v-if="processing"
                :icon="faSpinnerThird"
                :spin="true"
                :fixed-width="true"
            ></font-awesome-icon>
            <font-awesome-icon
                v-else
                :icon="faEllipsis"
                :fixed-width="true"
            ></font-awesome-icon>
        </vx-button>
        <template v-slot:items>
            <vx-dropdown-item
                :href="`/messaging/campaigns/${props.campaign.id}/contacts`"
            >
                Contacts
                <font-awesome-icon :icon="faUserGroup" />
            </vx-dropdown-item>
            <vx-dropdown-item
                :href="'/messaging/campaigns/new?duplicate_from_id=' + props.campaign.id"
                type="button"
                :disabled="teamStatus !== 'active'"
                data-test="copy"
            >
                Duplicate
                <font-awesome-icon :icon="faCopy" />
            </vx-dropdown-item>

            <vx-dropdown-item
                type="button"
                :disabled="teamStatus !== 'active'"
                data-test="delete"
                @click.prevent="destroy()"
            >
                Delete
                <font-awesome-icon :icon="faTrash" />
            </vx-dropdown-item>
        </template>
    </vx-dropdown>
</template>

<script setup>
import { VxButton, VxDropdown, VxDropdownItem } from '@voxie/frontend-components';
import { faEllipsis, faSpinnerThird, faCopy, faTrash, faUserGroup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import campaignsClient from '../../../services/campaignsClient';
import { useToasted } from '../../../composables/useToasted';
import { ref } from 'vue';
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';

const teamId = Spark.state.currentTeam.id;
const teamStatus = Spark.state.currentTeam.status;

const props = defineProps({
    campaign: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(["deleted"]);

const processing = ref(false);
const toasted = useToasted();

const destroy = () => {
    if (
        !window.confirm(
            "Are you sure? This campaign and it's messages will be deleted. There is no undo."
        )
    ) {
        return;
    }

    processing.value = true;

    campaignsClient
        .campaignDelete(teamId, props.campaign.id)
        .then(() => {
            emit("deleted", props.campaign.id);
        })
        .catch((error) => {
            console.error(error);
            if (error?.response?.status >= HTTP_INTERNAL_SERVER_ERROR) {
                toasted.global.platform_error();
            } else {
                toasted.error(error.response?.data?.message || 'Something went wrong.');
            }
        })
        .finally(() => {
            processing.value = false;
        });
};

</script>
