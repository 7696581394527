import dayjs from '~/utils/dayjs';

export function getClientTz() {
    return Spark.userTz || dayjs.tz.guess();
}

export function getTeamTz() {
    return Spark.state.currentTeam.default_contact_time_zone || dayjs.tz.guess();
}

export function toServerDateTime(formDateTime) {
    if (formDateTime) {
        // `dayjs.tz` crashes on invalid date provided.
        try {
            const d = dayjs(formDateTime, 'MM/DD/YYYY hh:mm a', true).tz(getClientTz(), true);
            if (d.isValid()) {
                return d.utc();
            }
        } catch (e) {
            return '';
        }
    }

    return '';
}

export function toClientTime(serverDateTime, format) {
    return toTime(serverDateTime, format, getClientTz());
}

export function toTeamTime(serverDateTime, format) {
    return toTime(serverDateTime, format, getTeamTz());
}

export function toTime(serverDateTime, format, tz) {
    if (serverDateTime) {
        const m =
            typeof serverDateTime === 'string'
                ? dayjs.utc(serverDateTime, 'YYYY-MM-DD HH:mm:ss', true)
                : dayjs.utc(serverDateTime);
        if (m.isValid()) {
            return m.tz(tz).format(format);
        }
    }

    return '';
}

export function toFormDateTime(serverDateTime) {
    return toClientTime(serverDateTime, 'MM/DD/YYYY hh:mm A');
}

export function toShortDateAtTime(serverDateTime) {
    return toClientTime(serverDateTime, 'MM/DD/YY [at] h:mm a z');
}

export function toShortDateTime(serverDateTime) {
    const timezone = dayjs.utc(serverDateTime).tz(getClientTz()).format('z');
    return toClientTime(serverDateTime, 'MM/DD/YY h:mm a') + ' ' + formatTz(timezone);
}

export function toShortDateTimeRelativeDays(value, timezone, showSuffix = true) {
    timezone = timezone || getClientTz();

    const date = dayjs.utc(value).tz(timezone);

    const suffix = showSuffix ? formatTz(date.format('z')) : '';

    if (date.isToday()) {
        return 'Today ' + date.format('h:mm a') + ' ' + suffix;
    } else if (date.isYesterday()) {
        return 'Yesterday ' + date.format('h:mm a') + ' ' + suffix;
    } else if (date.isTomorrow()) {
        return 'Tomorrow ' + date.format('h:mm a') + ' ' + suffix;
    }

    return date.format('MM/DD/YY h:mm a') + ' ' + suffix;
}

/**
 * Formats -03 to GMT-03, and if the timezone has acronym version like EST it will keep as EST.
 * @param {string} timezone
 * @returns {string}
 */
export function formatTz(timezone) {
    return timezone.startsWith('+') || timezone.startsWith('-') ? 'GMT' + timezone : timezone;
}

export function shortDateTime(value) {
    if (!value) return 'Never';

    let tz = Spark.userTz ? Spark.userTz : dayjs.tz.guess();
    return dayjs.utc(value).tz(tz).format('MMM Do, YYYY h:mm a z');
}

export function shortDate(value) {
    if (!value) return 'Never';

    return dayjs.utc(value).tz(getClientTz()).format('MMM Do, YYYY');
}

export function shortDateTimeNoTz(value, format = 'YYYY-MM-DD HH:mm:ss') {
    if (!value) return 'Never';

    return dayjs(value, format).format('MMM Do, YYYY h:mm a');
}

export function shortDateTimeTzSpread(value) {
    if (!value) return 'Never';
    const timezone = dayjs.utc(value).tz(getClientTz()).format('z');

    return dayjs.utc(value).tz(getClientTz()).format('hh:mm a MM/DD/YYYY') + ' ' + formatTz(timezone);
}

export function relative(value) {
    return dayjs.utc(value).local().locale('en-short').fromNow();
}

export function fromNow(value) {
    return dayjs.utc(value).tz(getClientTz()).fromNow();
}

export function roundToNearest5(date = dayjs()) {
    const coeff = 1000 * 60 * 5;
    return new Date(Math.round(date.valueOf() / coeff) * coeff);
}

export const formatDuration = (durationInMilliseconds) => {
    if (durationInMilliseconds < 0) {
        return 'Invalid input'; // Handle negative input
    }

    // Calculate seconds, minutes, hours, and days
    const milliseconds = durationInMilliseconds % 1000;
    const seconds = Math.floor((durationInMilliseconds / 1000) % 60);
    const minutes = Math.floor((durationInMilliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((durationInMilliseconds / (1000 * 60 * 60)) % 24);
    const days = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24));

    // Create a formatted string
    let formattedString = '';
    if (days > 0) {
        formattedString += `${days}d `;
    }
    if (hours > 0) {
        formattedString += `${hours}h `;
    }
    if (minutes > 0) {
        formattedString += `${minutes}m `;
    }
    if (seconds > 0) {
        formattedString += `${seconds}s `;
    }
    if (milliseconds > 0) {
        formattedString += `${milliseconds}ms`;
    }

    return formattedString.trim(); // Remove trailing space
};
