<template>
    <div>
        <div class="vx-text-slate-700 vx-text-base vx-font-bold vx-mb-6">Request Headers</div>

        <vx-tabs size="lg" v-model="currentTab" class="vx-mb-6">
            <vx-tab value="basic" data-test="basic">Basic Auth</vx-tab>
            <vx-tab value="custom" data-test="custom">Custom</vx-tab>
        </vx-tabs>

        <div v-if="currentTab == 'basic'">
            <vx-label>Username</vx-label>
            <vx-input class="vx-mb-4"
                autocomplete="off"
                size="lg"
                v-model="basic.username"></vx-input>

            <vx-label>Password</vx-label>
            <vx-input size="lg"
                autocomplete="off"
                :type="showSecret ? 'text' : 'password'"
                :error="props.error"
                v-model="basic.password">
                <template v-slot:right>
                    <vx-button class="-vx-mr-1"
                         @click.prevent="showSecret = !showSecret"
                        color="transparent"
                        size="sm">
                        <font-awesome-icon :icon="showSecret ? faEyeSlash : faEye"></font-awesome-icon>
                    </vx-button>
                </template>
            </vx-input>
        </div>

        <div v-if="currentTab == 'custom'">
            <vx-label>Name</vx-label>
            <vx-input class="vx-mb-4"
                size="lg"
                :modelValue="props.name"
                @update:modelValue="emit('update:name', $event)"></vx-input>

            <vx-label>Value</vx-label>
            <vx-input
                size="lg"
                :type="showSecret ? 'text' : 'password'"
                :error="props.error"
                :modelValue="props.value"
                @update:modelValue="emit('update:value', $event)"
                @focus="focusCustomValueInput"
                @blur="showSecret = false">
            </vx-input>
        </div>

    </div>
</template>

<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import {
    VxTabs,
    VxTab,
    VxInput,
    VxButton,
    VxLabel
} from "@voxie/frontend-components";
import { ref, watch, reactive, nextTick, onMounted } from 'vue';

const props = defineProps({
    authConfigId: {
        type: String,
        required: false,
        default: undefined,
    },
    name: {
        type: String,
    },
    defaultTab: {
        type: String,
        default: 'basic',
    },
    value: {
        type: String,
    },
    error: {
        type: String,
        default: undefined,
    },
});

const emit = defineEmits(['update:name', 'update:value']);

const currentTab = ref('basic');

watch(() => props.defaultTab,
    () => {
        currentTab.value = props.defaultTab
    },
    { immediate: true });

const showSecret = ref(false);

const basic = reactive({
    username: '',
    password: '',
})

watch(basic, () => {
    emit('update:name', 'Authorization');
    emit('update:value', 'Basic ' + btoa(`${basic.username}:${basic.password}`))
});

const focusCustomValueInput = async () => {
    if (props.value === '*******') {
        emit('update:value', '');
    }

    await nextTick();
    showSecret.value = true;
}

onMounted(() => {
    if (props.authConfigId) {
        emit('update:value', '*******');
    }
})
</script>
