import BaseClient from './BaseClient';

class CampaignsClient extends BaseClient {
    /** Campaign operations */
    campaignsGetAll($teamId) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/campaigns`;

        return this.makeCoreApiRequest(url, method);
    }

    campaignGet($teamId, $campaignId) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/campaigns/${$campaignId}`;

        return this.makeCoreApiRequest(url, method);
    }

    dependencies($teamId, $campaignId) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/campaigns/${$campaignId}/dependencies`;

        return this.makeCoreApiRequest(url, method);
    }

    campaignsAutocomplete($teamId, params) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/campaigns/autocomplete`;

        return this.makeCoreApiRequest(url, method, null, params);
    }

    campaignsGetRecords($teamId, params) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/search/campaigns`;

        return this.makeCoreApiRequest(url, method, null, params);
    }

    campaignsCount($teamId, params) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/search/campaigns/count`;

        return this.makeCoreApiRequest(url, method, null, params);
    }

    campaignsCountByStatus($teamId, params) {
        return this.makeCoreApiRequest(`/api/v3/teams/${$teamId}/campaigns/count/status`, 'get', null, params);
    }

    campaignSave($teamId, $campaign) {
        let method = '';
        let url = '';

        if (typeof $campaign.id === 'undefined') {
            method = 'post';
            url = `/api/v3/teams/${$teamId}/campaigns`;
        } else {
            method = 'put';
            url = `/api/v3/teams/${$teamId}/campaigns/${$campaign.id}`;
        }

        return this.makeCoreApiRequest(url, method, $campaign);
    }

    changeStatus(teamId, campaignId, status) {
        const url = `/api/v3/teams/${teamId}/campaigns/${campaignId}/status`;

        return this.makeCoreApiRequest(url, 'patch', {
            status,
        });
    }

    addContactsToCampaign(teamId, campaignId, contactIds) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/campaigns/${campaignId}/contacts`;
        const data = { contact_ids: contactIds };

        return this.makeCoreApiRequest(url, method, data);
    }

    campaignsDeleteContacts($teamId, $campaignId, contactIds) {
        const method = 'delete';
        const url = `/api/v3/teams/${$teamId}/campaigns/${$campaignId}/contacts`;
        const data = { contact_ids: contactIds };

        return this.makeCoreApiRequest(url, method, data);
    }

    campaignsContactsCount(campaignId, teamId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/campaigns/${campaignId}/contacts/count`;

        return this.makeCoreApiRequest(url, method);
    }

    campaignsAllContacts(campaignId, minimal, teamId, cursor) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/campaigns/${campaignId}/contacts`;

        return this.makeCoreApiRequest(url, method, null, { cursor, minimal });
    }

    campaignDelete($teamId, $campaignId) {
        const method = 'delete';
        const url = `/api/v3/teams/${$teamId}/campaigns/${$campaignId}`;

        return this.makeCoreApiRequest(url, method);
    }

    /** Campaign Message operations */
    campaignsMessageGetAll($teamId, $campaignId, params) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/campaigns/${$campaignId}/campaign-messages`;

        return this.makeCoreApiRequest(url, method, null, params);
    }

    campaignCapacity(teamId, campaignId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/campaigns/${campaignId}/capacity`;

        return this.makeCoreApiRequest(url, method);
    }

    campaignsMessageSave($teamId, $campaignId, $campaignMessage) {
        let method = '';
        let url = '';

        if (typeof $campaignMessage.id === 'undefined') {
            method = 'post';
            url = `/api/v3/teams/${$teamId}/campaigns/${$campaignId}/campaign-messages`;
        } else {
            method = 'put';
            url = `/api/v3/teams/${$teamId}/campaigns/${$campaignId}/campaign-messages/${$campaignMessage.id}`;
        }

        return this.makeCoreApiRequest(url, method, $campaignMessage);
    }
}

export default new CampaignsClient();
