<template>
    <div class="vx-flex vx-w-full vx-items-center vx-gap-1"
        @dragstart="dragStart"
        @dragend="dragEnd">
        <div v-if="!props.simple"
            class="resource-dragging-hide vx-shrink-0">
            <vx-button color="transparent"
                size="sm"
                type="button"
                class="!vx-cursor-move resource-handle">
                <font-awesome-icon :icon="faGripDotsVertical"></font-awesome-icon>
            </vx-button>
        </div>
        <div
            class="resource-item vx-bg-white vx-w-full vx-rounded-2xl vx-shadow vx-border-solid vx-border-t-0 vx-border-l vx-border-r vx-border-b vx-border-white vx-items-center vx-inline-flex">
            <div class="resource-icon vx-w-40 vx-self-stretch vx-pl-5 vx-pr-8 vx-py-2 vx-rounded-tl-2xl vx-rounded-bl-2xl vx-border vx-border-solid vx-border-white vx-border-opacity-50 vx-items-center vx-gap-4 vx-flex"
                :class="options.color">
                <div class="vx-items-center vx-gap-2 vx-flex">
                    <div class="vx-w-5 vx-h-5 vx-justify-center vx-items-center vx-flex">
                        <font-awesome-icon fixed-width
                            :icon="options.icon"></font-awesome-icon>
                    </div>
                    <div
                        class="resource-dragging-hide vx-text-slate-800 vx-text-sm vx-font-extrabold vx-leading-tight vx-capitalize">
                        {{ resource.type }}
                    </div>
                </div>
            </div>
            <div
                class="resource-dragging-hide vx-grow vx-shrink vx-basis-0 vx-h-16 vx-pl-5 vx-pr-3 vx-py-3 vx-items-center vx-gap-4 vx-flex">
                <div class="vx-grow vx-shrink vx-basis-0 vx-text-slate-900 vx-line-clamp-2">
                    <template v-if="data">
                        {{ data.name }}
                    </template>
                    <template v-else>
                        <vx-icon-loading spin></vx-icon-loading>
                    </template>
                </div>
                <div v-if="props.simple">
                    <font-awesome-icon :icon="faToggleLargeOn"></font-awesome-icon>
                </div>
                <div v-if="!props.simple"
                    class="vx-items-center vx-gap-2 vx-flex">

                    <template v-if="data">

                        <template v-if="props.resource.type === 'automation'">
                            <div>
                                <font-awesome-icon
                                    :icon="!publishedAutomatedActions ? faToggleLargeOff : faToggleLargeOn"></font-awesome-icon>
                            </div>
                            <div class="vx-text-slate-800 vx-text-sm vx-leading-tight">
                                {{ publishedAutomatedActions ? 'Active' : 'Inactive' }}
                                <vx-badge :borders="false"
                                    color="slate"
                                    size="xs"
                                    class="!vx-font-extrabold">
                                    {{ publishedAutomatedActions }}/{{ totalAutomatedActions }}
                                </vx-badge>
                            </div>
                        </template>
                        <template v-else-if="props.resource.type === 'campaign'">
                            <div>
                                <font-awesome-icon
                                    :icon="['draft', 'paused', 'archived'].includes(data.status) ? faToggleLargeOff : faToggleLargeOn"></font-awesome-icon>
                            </div>
                            <div class="vx-text-slate-800 vx-text-sm vx-leading-tight">
                                {{ generalStatusLabel(data.status) }}
                            </div>
                        </template>
                        <template v-if="props.resource.type === 'segment'">
                            <div class="vx-text-slate-800 vx-text-sm vx-leading-tight">
                                <font-awesome-icon fixedWidth
                                    :icon="data.type === 'static' ? faMapPin : faLoader" />
                                {{ SegmentTypeToLabel[data.type] }}
                                <vx-icon-loading v-if="segmentContactsCount === null"
                                    spin></vx-icon-loading>
                                <template v-else>
                                    {{ vxFormatCount(segmentContactsCount) }}
                                </template>
                            </div>
                        </template>
                    </template>

                </div>
                <div v-if="!props.simple"
                    class="vx-items-center vx-gap-2 vx-flex">
                    <vx-dropdown>
                        <vx-button color="muted"
                            outline
                            size="sm">
                            <font-awesome-icon :icon="faEllipsis"></font-awesome-icon>
                        </vx-button>
                        <template v-slot:items>
                            <template v-if="props.resource.type === 'automation' && totalAutomatedActions">
                                <vx-dropdown-item v-if="publishedAutomatedActions !== totalAutomatedActions" @click.prevent="updateActionsStatus('published')">
                                    Turn On All Actions
                                    <font-awesome-icon :icon="faPlay"></font-awesome-icon>
                                </vx-dropdown-item>
                                <vx-dropdown-item v-if="publishedAutomatedActions !== 0" @click.prevent="updateActionsStatus('draft')">
                                    Turn Off All Actions
                                    <font-awesome-icon :icon="faPause"></font-awesome-icon>
                                </vx-dropdown-item>
                            </template>
                            <template v-else-if="props.resource.type === 'campaign' && data">
                                <campaign-card-actions v-model="data" @launch="campaignLaunch = true" dropdown-item></campaign-card-actions>
                            </template>
                            <template v-else-if="props.resource.type === 'segment'">
                                <vx-dropdown-item :href="`/contacts/segments/${props.resource.id}/contacts`">
                                    View Contacts
                                    <font-awesome-icon :icon="faAddressBook"></font-awesome-icon>
                                </vx-dropdown-item>
                                <segment-row-export :segmentId="props.resource.id"></segment-row-export>
                            </template>

                            <vx-dropdown-item @click="emit('remove')">
                                Remove from Flow
                                <font-awesome-icon :icon="faCircleMinus"></font-awesome-icon>
                            </vx-dropdown-item>
                        </template>
                    </vx-dropdown>
                    <vx-button :href="options.edit"
                        color="muted"
                        outline
                        size="sm">
                        Edit
                        <font-awesome-icon :icon="faArrowUpRight"></font-awesome-icon>
                    </vx-button>
                </div>
            </div>
        </div>
        <campaign-launch-modal v-if="props.resource.type === 'campaign' && data" v-model:visible="campaignLaunch" v-model:campaign="data" />
    </div>
</template>

<style lang="postcss" scoped>
.resource-dragging .resource-dragging-hide {
    @apply !vx-hidden;
}

.resource-dragging .resource-item {
    transform: rotate(-14deg);
    @apply !vx-w-auto vx-overflow-hidden !vx-border-0;
}

.resource-dragging .resource-icon {
    @apply vx-p-4 !vx-w-auto;
}
</style>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMessages, faToggleLargeOff, faToggleLargeOn, faMapPin, faContactBook, faLoader, faRobot, faEllipsis, faArrowUpRight, faPlay, faCircleMinus, faGripDotsVertical, faAddressBook, faPause } from '@fortawesome/pro-solid-svg-icons';
import { computed, onMounted, ref } from 'vue';
import { useCustomFlow } from './useCustomFlow';
import { VxBadge, VxButton, VxDropdown, VxDropdownItem, VxIconLoading, vxFormatCount } from '@voxie/frontend-components';
import { generalStatusLabel } from '~/components/filters';
import { useToasted } from '~/composables/useToasted';
import segmentsClient from '~/services/segmentsClient';
import { SegmentTypeToLabel } from '~/constants/segment';
import SegmentRowExport from '~/components/contacts/segments/SegmentRowExport.vue';
import { automationRulesClient } from '~/services';
import CampaignLaunchModal from '~/components/campaigns/CampaignLaunchModal.vue';
import CampaignCardActions from '~/components/campaigns/campaign-card/CampaignCardActions.vue';

const teamId = Spark.state.currentTeam.id;
const toasted = useToasted();

const props = defineProps({
    resource: {
        type: Object,
        required: true,
    },
    simple: {
        type: Boolean,
        default: false,
    },
    flowSection: {
        type: Number,
        required: false,
    }
});

const emit = defineEmits(['remove']);

const data = ref();

const customFlow = useCustomFlow();

const options = computed(() => {
    if (props.resource.type === 'automation') {
        return {
            color: 'vx-bg-amber-300',
            icon: faRobot,
            edit: '/automation/event/' + props.resource.id,
        };
    }
    if (props.resource.type === 'campaign') {
        return {
            color: 'vx-bg-sky-300',
            icon: faMessages,
            edit: '/messaging/campaigns/' + props.resource.id,
        };
    }
    if (props.resource.type === 'segment') {
        return {
            color: 'vx-bg-violet-300',
            icon: faContactBook,
            edit: '/contacts/segments/' + props.resource.id,
        };
    }

    return {};
})

const publishedAutomatedActions = computed(
    () => data.value.automated_actions?.filter(action => action.status === "published")?.length || data.value.live_actions_count || 0
)

const totalAutomatedActions = computed(
    () => data.value.automated_actions?.length || data.value.actions_count || 0
);

const segmentContactsCount = ref(null);

onMounted(async () => {
    try {
        data.value = await customFlow.fetchResource(props.resource.type, props.resource.id);
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
        data.value = undefined;
    }

    try {
        if (props.resource.type === 'segment' && !props.simple) {
            segmentContactsCount.value = (await segmentsClient.getSegmentContactsCount(props.resource.id, teamId)).data?.count || null;
        }
    } catch (e) {
        console.error(e);
    }

});

const dragStart = () => {
    if (props.simple) {
        return;
    }
    customFlow.resourceDragging.resource = props.resource;
    customFlow.resourceDragging.oldSectionIndex = props.flowSection;
}

const dragEnd = () => {
    if (props.simple) {
        return;
    }
    setTimeout(() => {
        customFlow.resourceDragging.resource = undefined;
        customFlow.resourceDragging.oldSectionIndex = undefined
        customFlow.resourceDragging.newSectionIndex = undefined
    }, 50); // this is so CustomFlowSection@dragEnd has some time to pickup the data before cleaning the dragging data.
}


const campaignLaunch = ref(false);

const updateActionsStatus = (status) => {
    if (!window.confirm(`Are you sure you want to turn ${status === 'published' ? 'on' : 'off'} all actions?`)) {
        return;
    }
    automationRulesClient.updateActionsStatus(teamId, props.resource.id, status)
        .then(() => {
            if (data.value.automated_actions?.length) {
                data.value.automated_actions.map(action => {
                    action.status = status;
                    return action;
                })
            } else {
                data.value.live_actions_count = status === 'draft' ? 0 : data.value.actions_count;
            }
        }).catch((error) => {
            console.error(error);
            toasted.global.platform_error();
        })
}


</script>
