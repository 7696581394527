import BaseClient from './BaseClient';

class SegmentsClient extends BaseClient {
    createSegment(teamId, segment) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/segments`;

        return this.makeCoreApiRequest(url, method, segment);
    }

    updateSegment(teamId, segmentId, segment) {
        const method = 'put';
        const url = `/api/v3/teams/${teamId}/segments/${segmentId}`;

        return this.makeCoreApiRequest(url, method, segment);
    }

    deleteSegment(teamId, segmentId) {
        const method = 'delete';
        const url = `/api/v3/teams/${teamId}/segments/${segmentId}`;

        return this.makeCoreApiRequest(url, method);
    }

    getSegment(teamId, segmentId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/segments/${segmentId}`;

        return this.makeCoreApiRequest(url, method);
    }

    getSegments(teamId, params) {
        const url = `/api/v3/teams/${teamId}/segments`;

        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    getSegmentsCount(teamId, params) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/segments/count`;

        return this.makeCoreApiRequest(url, method, null, params);
    }

    searchDuplicates(teamId, params) {
        let url = `/api/v3/teams/${teamId}/search/segments/duplicates`;

        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    getSegmentContacts(segmentId, teamId, cursor) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/segments/${segmentId}/contacts`;

        return this.makeCoreApiRequest(url, method, null, { cursor });
    }

    refreshSegment($teamId, $id) {
        const method = 'post';
        const url = `/api/v3/teams/${$teamId}/segments/${$id}/refresh`;

        return this.makeCoreApiRequest(url, method);
    }

    getSegmentContactsCount(segmentId, teamId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/segments/${segmentId}/contacts/count`;

        return this.makeCoreApiRequest(url, method);
    }

    dependencies(teamId, segmentId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/segments/${segmentId}/dependencies`;

        return this.makeCoreApiRequest(url, method);
    }

    getSegmentStatus(segmentId, teamId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/segments/${segmentId}/status`;

        return this.makeCoreApiRequest(url, method);
    }

    tagContacts(teamId, segmentId, data) {
        const url = `/api/v3/teams/${teamId}/segments/${segmentId}/tag`;

        return this.makeCoreApiRequest(url, 'post', data);
    }

    countByAudienceType(teamId) {
        const url = `/api/v3/teams/${teamId}/segments/count/audience-type`;

        return this.makeCoreApiRequest(url, 'get');
    }

    countByRefreshType(teamId) {
        const url = `/api/v3/teams/${teamId}/segments/count/refresh-type`;

        return this.makeCoreApiRequest(url, 'get');
    }
}

export default new SegmentsClient();
