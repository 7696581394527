<template>
    <div>
        <div>
            <div class="card card-default" v-if="tokens.length > 0">
                <div class="card-header">API Tokens</div>

                <div class="table-responsive">
                    <table class="table table-valign-middle mb-0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Created</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="token in tokens" :key="token.id">
                                <!-- Name -->
                                <td>
                                    <div>
                                        {{ token.name }}
                                    </div>
                                </td>

                                <!-- Created At -->
                                <td>
                                    <div>
                                        {{ dateTime(token.created_at) }}
                                    </div>
                                </td>

                                <!-- Edit Button -->
                                <td class="td-fit">
                                    <div class="text-right">
                                        <button class="btn btn-outline-primary" @click="editToken(token)">
                                            <i class="fa fa-cog"></i>
                                        </button>

                                        <button class="btn btn-outline-danger" @click="approveTokenDelete(token)">
                                            <i class="fa fa-remove"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Update Token Modal -->
        <div class="modal" id="modal-update-token" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-md" v-if="updatingToken">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Edit Token ({{ updatingToken.name }})</h5>
                    </div>

                    <div class="modal-body">
                        <!-- Update Token Form -->
                        <form role="form">
                            <!-- Token Name -->
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label text-md-right">Token Name</label>

                                <div class="col-md-6">
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="name"
                                        v-model="updateTokenForm.name"
                                        :class="{ 'is-invalid': updateTokenForm.errors.has('name') }"
                                    />

                                    <span class="invalid-feedback" v-show="updateTokenForm.errors.has('name')">
                                        {{ updateTokenForm.errors.get('name') }}
                                    </span>
                                </div>
                            </div>

                            <!-- Token Abilities -->
                            <div class="form-group row" v-if="availableAbilities.length > 0">
                                <label class="col-md-4 col-form-label text-md-right">Token Can</label>

                                <div class="col-md-6">
                                    <div v-for="ability in availableAbilities" :key="ability.value">
                                        <div class="checkbox">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    @click="toggleAbility(ability.value)"
                                                    :checked="abilityIsAssigned(ability.value)"
                                                />

                                                {{ ability.name }}
                                            </label>
                                        </div>
                                    </div>

                                    <span class="invalid-feedback" v-show="updateTokenForm.errors.has('abilities')">
                                        {{ updateTokenForm.errors.get('abilities') }}
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>

                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="updateToken"
                            :disabled="updateTokenForm.busy"
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete Token Modal -->
        <div class="modal" id="modal-delete-token" tabindex="-1" role="dialog">
            <div class="modal-dialog" v-if="deletingToken">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Delete Token ({{ deletingToken.name }})</h5>
                    </div>

                    <div class="modal-body">
                        Are you sure you want to delete this token? If deleted, API requests that attempt to
                        authenticate using this token will no longer be accepted.
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">No, Go Back</button>

                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="deleteToken"
                            :disabled="deleteTokenForm.busy"
                        >
                            Yes, Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getClientTz } from '~/utils/date';
import dayjs from '~/utils/dayjs';

export default {
    props: ['tokens', 'availableAbilities'],

    /**
     * The component's data.
     */
    data() {
        return {
            updatingToken: null,
            deletingToken: null,

            updateTokenForm: new SparkForm({
                name: '',
                abilities: [],
            }),

            deleteTokenForm: new SparkForm({}),
        };
    },

    created() {
        Bus.$on(`sparkFormUpdated.${this.updateTokenForm.sparkFormId}`, this.$forceUpdate);
        Bus.$on(`sparkFormUpdated.${this.deleteTokenForm.sparkFormId}`, this.$forceUpdate);
    },

    methods: {
        dateTime(value) {
            return dayjs.utc(value).tz(getClientTz()).format('MMMM Do, YYYY h:mm A');
        },
        /**
         * Show the edit token modal.
         */
        editToken(token) {
            this.updatingToken = token;

            this.initializeUpdateFormWith(token);

            $('#modal-update-token').modal('show');
        },

        /**
         * Initialize the edit form with the given token.
         */
        initializeUpdateFormWith(token) {
            this.updateTokenForm.name = token.name;

            this.updateTokenForm.abilities = token.metadata.abilities;
        },

        /**
         * Update the token being edited.
         */
        updateToken() {
            Spark.put(`/settings/api/token/${this.updatingToken.id}`, this.updateTokenForm).then(() => {
                Bus.$emit('updateTokens');

                $('#modal-update-token').modal('hide');
            });
        },

        /**
         * Toggle the ability on the current token being edited.
         */
        toggleAbility(ability) {
            if (this.abilityIsAssigned(ability)) {
                this.updateTokenForm.abilities = this.updateTokenForm.abilities.filter((a) => a !== ability);
            } else {
                this.updateTokenForm.abilities.push(ability);
            }
        },

        /**
         * Determine if the ability has been assigned to the token being edited.
         */
        abilityIsAssigned(ability) {
            return this.updateTokenForm.abilities.includes(ability);
        },

        /**
         * Get user confirmation that the token should be deleted.
         */
        approveTokenDelete(token) {
            this.deletingToken = token;

            $('#modal-delete-token').modal('show');
        },

        /**
         * Delete the specified token.
         */
        deleteToken() {
            Spark.delete(`/settings/api/token/${this.deletingToken.id}`, this.deleteTokenForm).then(() => {
                Bus.$emit('updateTokens');

                $('#modal-delete-token').modal('hide');
            });
        },
    },
};
</script>
