<template>
    <div class="vx-text-slate-700 vx-font-sans vx-max-w-screen-sm vx-w-full">
        <page-header class="vx-mb-8">
            AI Configuration
            <experimental-pill class="vx-ml-4 -vx-mt-2"></experimental-pill>
        </page-header>

        <vx-box color="white" class="vx-p-6 vx-mb-8">
            <div class="vx-flex vx-items-center vx-mb-4">
                <font-awesome-icon class="vx-mr-2" :icon="faTowerBroadcast"></font-awesome-icon>
                <span class="vx-text-xl vx-font-extrabold">Status</span>
            </div>
            <vx-alert v-if="!isAdminOrOwner" :color="data.id ? 'primary' : 'info'" :closeable="false">
                <div class="vx-text-lg vx-font-semibold">AI Configuration</div>
                <div>
                    This feature requires some configuration before it can be used. Your account <strong>does not have correct permission</strong> to do this. Please contact your admin.
                </div>
            </vx-alert>
            <div v-else-if="loading" class="vx-py-6 vx-flex vx-justify-center">
                <vx-icon-loading class="vx-w-9 vx-h-9" spin></vx-icon-loading>
            </div>
            <vx-alert v-else :color="data.id ? 'primary' : 'info'" :closeable="false">
                <template v-slot:icon>
                    <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                </template>
                <div class="vx-text-lg vx-font-semibold">AI Configuration</div>
                <div>
                    <template v-if="data.id">
                        Ready to use.
                    </template>
                    <template v-else>
                        This feature requires some configuration before it can be used.
                    </template>
                </div>
            </vx-alert>
        </vx-box>

        <form v-if="isAdminOrOwner && !loading" @submit.prevent="submit" class="vx-flex vx-flex-col vx-gap-4 lg:vx-gap-8">
            <vx-expandable-card id="company_pitch">
                <template v-slot:content>
                    <ai-configuration-company-pitch v-model="data.company_pitch"
                        :error="errors.company_pitch"></ai-configuration-company-pitch>
                </template>
            </vx-expandable-card>
            <vx-expandable-card v-if="FEATURES.message_hub_ai" id="role">
                <template v-slot:content>
                    <ai-configuration-role v-model="data.settings.role"
                        :error="errors['settings.role']"></ai-configuration-role>
                </template>
            </vx-expandable-card>
            <vx-expandable-card v-if="FEATURES.message_hub_ai" id="tone">
                <template v-slot:content>
                    <ai-configuration-tone v-model="data.settings.tone"
                        :error="errors['settings.tone']"></ai-configuration-tone>
                </template>
            </vx-expandable-card>
            
            <vx-alert color="success" v-model:visible="success">AI Configuration successfully saved and ready to be used!</vx-alert>

            <vx-expandable-card>
                <template v-slot:content>
                    <vx-button
                        block
                        size="lg"
                        type="submit"
                        :loading="submitting"
                    >
                        {{ data.id ? 'Update' : 'Save' }}
                    </vx-button>
                </template>
            </vx-expandable-card>
        </form>
    </div>
</template>

<script setup>
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';
import PageHeader from '~/components/general/PageHeader.vue';
import { VxAlert, VxBox, VxIconLoading, VxExpandableCard, VxButton } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleCheck, faTowerBroadcast } from '@fortawesome/pro-solid-svg-icons';
import AiConfigurationCompanyPitch from './AiConfigurationCompanyPitch.vue';
import AiConfigurationRole from './AiConfigurationRole.vue';
import AiConfigurationTone from './AiConfigurationTone.vue';
import { useAiConfiguration } from './useAiConfiguration'; 
import { inject } from 'vue';

const isAdminOrOwner = Spark.isAdminOrOwner;

const FEATURES = inject('FEATURES');
const { errors, submitting, loading, submit, data, success } = useAiConfiguration();

</script>
