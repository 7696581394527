<template>
    <div
        class="vx-relative vx-@container vx-z-10"
        @mouseenter="stopInterval"
        @mouseleave="startInterval"
    >
        <div
            ref="scrollContainer"
            :class="
                fadeSlides ? 'vx-opacity-0  vx-duration-300' : 'vx-duration-700'
            "
            class="scrollbar-hidden vx-pointer-events-auto vx-flex vx-ease-in vx-snap-x vx-snap-mandatory vx-overflow-y-hidden vx-overflow-x-scroll vx-scroll-smooth vx-transition vx-will-change-scroll [&>*]:vx-w-full [&>*]:vx-flex-shrink-0 [&>*]:vx-snap-start [&>*]:vx-will-change-transform"
        >
            <slot />
        </div>

        <div
            class="vx-absolute vx-bottom-0 vx-left-0 vx-right-0 vx-mx-auto vx-flex vx-justify-center vx-p-8 @xl:vx-right-auto vx-pointer-events-none"
        >
            <div
                class="vx-flex vx-items-center vx-gap-2 vx-rounded-full vx-bg-slate-100 vx-px-4 vx-py-1 vx-pointer-events-auto"
                @mouseenter="listenForScroll = false"
                @mouseleave="listenForScroll = true"
            >
                <button
                    @click="
                        currentSlide == 1
                            ? scrollToSlide(slideCount)
                            : scrollToSlide(currentSlide - 1)
                    "
                    aria-label="Previous Slide"
                    class="vx-pr-1 vx-text-slate-400 vx-transition hover:vx-text-slate-600 vx-bg-transparent vx-p-0"
                >
                    <font-awesome-icon :icon="faArrowLeft" />
                </button>
                <button
                    v-for="n in slideCount"
                    :key="n"
                    :aria-label="'Go to slide ' + n"
                    class="vx-relative vx-aspect-square vx-w-3 vx-transition vx-bg-transparent vx-p-0"
                    @click="scrollToSlide(n)"
                >
                    <div
                        class="vx-flex vx-text-slate-300 hover:vx-text-slate-400"
                    >
                        <font-awesome-icon
                            class="vx-h-full vx-w-full"
                            :icon="faCircle"
                        />
                    </div>
                    <div
                        v-if="currentSlide == n"
                        :class="intervalId ? 'countdown' : ''"
                        class="vx-absolute vx-top-0 vx-flex vx-bg-slate-100 vx-text-sky-400"
                        :style="`animation-delay: ${SLIDE_DURATION}ms`"
                    >
                        <font-awesome-icon
                            class="vx-h-full vx-w-full"
                            :icon="faCircleQuarterStroke"
                        />
                    </div>
                    <div
                        v-if="currentSlide == n"
                        :class="intervalId ? 'countdown' : ''"
                        class="vx-absolute vx-top-0 vx-flex vx-bg-slate-100 vx-text-sky-400"
                        :style="`animation-delay: ${SLIDE_DURATION * 0.75}ms`"
                    >
                        <font-awesome-icon
                            class="vx-h-full vx-w-full"
                            :icon="faCircleHalfStroke"
                        />
                    </div>
                    <div
                        v-if="currentSlide == n"
                        :class="intervalId ? 'countdown' : ''"
                        class="vx-absolute vx-top-0 vx-flex vx-bg-slate-100 vx-text-sky-400"
                        :style="`animation-delay: ${SLIDE_DURATION * 0.5}ms`"
                    >
                        <font-awesome-icon
                            class="vx-h-full vx-w-full"
                            :icon="faCircleThreeQuartersStroke"
                        />
                    </div>
                    <div
                        v-if="currentSlide == n"
                        :class="intervalId ? 'countdown' : ''"
                        class="vx-absolute vx-top-0 vx-flex vx-bg-slate-100 vx-text-sky-400"
                        :style="`animation-delay: ${SLIDE_DURATION * 0.25}ms`"
                    >
                        <font-awesome-icon
                            class="vx-h-full vx-w-full"
                            :icon="faCircle"
                        />
                    </div>
                </button>
                <button
                    @click="
                        currentSlide == slideCount
                            ? scrollToSlide(1)
                            : scrollToSlide(currentSlide + 1)
                    "
                    aria-label="Next Slide"
                    class="vx-pl-1 vx-text-slate-400 vx-transition hover:vx-text-slate-600 vx-bg-transparent vx-p-0"
                >
                    <font-awesome-icon :icon="faArrowRight" />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faCircleThreeQuartersStroke,
    faCircleHalfStroke,
    faCircleQuarterStroke,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

const props = defineProps({
    autoPlay: { default: false, type: Boolean },
    loadRandomSlide: { default: false, type: Boolean },
});

const currentSlide = ref(null);
const scrollContainer = ref(null);
const slideCount = ref();
const listenForScroll = ref(true);
let ticking = false;
let slides = [];
const intervalId = ref(null);
const fadeSlides = ref(false);
const SLIDE_DURATION = 12000;
const SLIDE_FADE_DURATION = 300;

const scrollToSlide = (index, behavior = "smooth") => {
    const element = slides[index - 1];
    if (element && scrollContainer.value) {
        const containerRect = scrollContainer.value.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();
        const scrollLeft =
            elementRect.left -
            containerRect.left +
            scrollContainer.value.scrollLeft;

        scrollContainer.value.scrollTo({
            left: scrollLeft,
            behavior: behavior,
        });

        currentSlide.value = index;
    }
};

const checkVisibility = () => {
    slides.forEach((slide, i) => {
        const rect = slide.getBoundingClientRect();
        const isVisible = rect.left >= 0 && rect.right <= window.innerWidth;
        if (isVisible) {
            currentSlide.value = i + 1;
        }
    });
    ticking = false; // Reset the ticking flag after the check
};

const handleScroll = () => {
    if (!ticking && listenForScroll.value & !intervalId.value) {
        window.requestAnimationFrame(checkVisibility);
        ticking = true;
    }
};

const nextSlide = () => {
    fadeSlides.value = true;
    setTimeout(() => {
        if (currentSlide.value == slideCount.value) {
            scrollToSlide(1, "instant");
        } else {
            scrollToSlide(currentSlide.value + 1, "instant");
        }
        fadeSlides.value = false;
    }, SLIDE_FADE_DURATION);
};

const startInterval = () => {
    if (!intervalId.value && props.autoPlay) {
        intervalId.value = setInterval(nextSlide, SLIDE_DURATION);
    }
};

const stopInterval = () => {
    if (intervalId.value) {
        clearInterval(intervalId.value);
        intervalId.value = null; // Clear the interval ID to indicate it's paused
    }
};

const randomSlide = () => {
    return Math.floor(Math.random() * slideCount.value) + 1;
};

onMounted(async () => {
    if (scrollContainer.value) {
        slideCount.value = scrollContainer.value.children.length;
        slides = scrollContainer.value.querySelectorAll(":scope > *");
        scrollContainer.value.addEventListener("scroll", handleScroll);
        window.addEventListener("DOMContentLoaded", () => {
            scrollToSlide(props.loadRandomSlide ? randomSlide() : 1, "instant");
        });
        startInterval();
    }
});

onUnmounted(() => {
    if (scrollContainer.value) {
        scrollContainer.value.removeEventListener("scroll", handleScroll);
    }
    stopInterval();
});
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}

.countdown {
    animation: fadeOut 0.2s linear forwards;
}
</style>
