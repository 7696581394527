<template>
    <auth-box class="vx-max-w-[480px]" :errors="props.errors">
        <form id="login-form"
            method="POST"
            action="/login"
            class="vx-flex vx-flex-col vx-gap-4" novalidate>
            <input name="remember" value="1" type="hidden">
            <div>
                <vx-label>
                    Email
                </vx-label>
                <vx-input v-model="data.email" type="email" autofocus name="email" id="email" size="lg"
                        :error="v$.email.$error ? 'Email is invalid' : ''"
                >
                </vx-input>
            </div>
            <div>
                <vx-label>
                    Password
                </vx-label>
                <vx-input type="password" v-model="data.password"
                        autofocus name="password"
                        id="password"
                        size="lg"
                        :error="v$.password.$error ? 'Password is invalid' : ''"
                ></vx-input>
                <a class="vx-flex vx-items-center vx-mt-2 vx-underline vx-text-slate-600 hover:vx-text-slate-600 vx-text-xs" :href="`/password/reset?email=${encodeURIComponent(data.email)}`">
                    <font-awesome-icon :icon="faCircleInfo" class="vx-mr-1"></font-awesome-icon>
                    Forgot Password?
                </a>
            </div>

            <div class="vx-flex vx-flex-col vx-gap-2 vx-mt-4 md:vx-flex-row-reverse">
                <vx-button v-if="props.recaptchaKey"
                    data-size="invisible"
                    :data-sitekey="props.recaptchaKey"
                    data-callback="submitLogin"
                    :loading="submitting" class="g-recaptcha md:vx-flex-grow" type="submit" color="primary" size="lg">
                    Log In <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>
                <vx-button v-else :loading="submitting" class="md:vx-flex-grow" type="submit" color="primary" size="lg">
                    Log In <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>

                <vx-button color="muted" size="lg" href="/login/sso">
                    Use SSO <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>
            </div>
        </form>
    </auth-box>
</template>
<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AuthBox from '../AuthBox.vue';
import { VxLabel, VxInput, VxButton } from '@voxie/frontend-components';
import { faArrowRight, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { ref, reactive, onMounted } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

const props = defineProps({
    recaptchaKey: String,
    errors: Array,
    oldEmail: String,
});

onMounted(() => {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?hl=en')
    document.head.appendChild(recaptchaScript)
});

const data = reactive({
    email: props.oldEmail || '',
    password: '',
});

const submitting = ref(false);

const v$ = useVuelidate({
    email: {required, email},
    password: {required},
}, data);

window.submitLogin = async () => {
    submitting.value = true;

    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) {
        submitting.value = false;
        return;
    }

    window.document.getElementById('login-form').submit()
}



/**
 * Clear all watch marks suspended account message
 * {@link vendor/voxie/spark-aurelius/resources/js/spark.js}
 */
Object.keys(window.sessionStorage)
    .filter((key) => { return /displayedNotActiveTeam/.test(key); })
    .forEach((key) => { window.sessionStorage.removeItem(key); });
</script>
