<template>
    <div>
        <div class="card card-default" v-if="invitations.length > 0">
            <div class="card-header">Pending Invitations</div>

            <div class="table-responsive">
                <table class="table table-valign-middle mb-0">
                    <thead>
                    <tr>
                        <th> Team</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="(invitation,index) in invitations" :key="index">
                        <!-- Team Name -->
                        <td>
                            <div>
                                {{ invitation.team.name }}
                            </div>
                        </td>

                        <!-- Accept Button -->
                        <td class="td-fit">
                            <button class="btn btn-outline-success" @click="accept(invitation)">
                                <i class="fa fa-check"></i>
                            </button>

                            <button class="btn btn-outline-danger" @click="reject(invitation)">
                                <i class="fa fa-times"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '~/services/axios';

export default {
    name: "PendingInvitations",

    /**
     * The component's data.
     */
    data() {
        return {
            invitations: []
        };
    },


    /**
     * The component has been created by Vue.
     */
    created() {
        this.getPendingInvitations();
    },


    methods: {
        /**
         * Get the pending invitations for the user.
         */
        getPendingInvitations() {
            axios.get('/settings/invitations/pending')
                .then(response => {
                    this.invitations = response.data;
                });
        },


        /**
         * Accept the given invitation.
         */
        accept(invitation) {
            axios.post(`/settings/invitations/${invitation.id}/accept`)
                .then(() => {
                    Bus.$emit('updateTeams');

                    this.getPendingInvitations();
                });

            this.removeInvitation(invitation);
        },


        /**
         * Reject the given invitation.
         */
        reject(invitation) {
            axios.post(`/settings/invitations/${invitation.id}/reject`)
                .then(() => {
                    this.getPendingInvitations();
                });

            this.removeInvitation(invitation);
        },


        /**
         * Remove the given invitation from the list.
         */
        removeInvitation(invitation) {
            this.invitations = this.invitations.filter((i) => i.id !== invitation.id);
        }
    }
}
</script>
