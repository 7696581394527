import authConfigsClient from '../../../../../services/authConfigsClient';
import { ref, watch } from 'vue';
import { HTTP_UNPROCESSABLE_ENTITY } from '../../../../../support/HttpStatuses';
import { useToasted } from '../../../../../composables/useToasted';

const defaultData = () => ({ friendly_name: '', header_name: '', header_value: '' });

export const useAuthConfigForm = () => {
    const toasted = useToasted();

    const teamId = Spark.state.currentTeam.id;

    const originalData = ref(defaultData());
    const data = ref(defaultData());
    const errors = ref({});
    const success = ref(false);
    const submitting = ref(false);
    const loading = ref(false);

    watch(
        () => data.value?.auth_config_id,
        async (id) => {
            errors.value = {};
            success.value = false;
            submitting.value = false;
            loading.value = true;

            if (id) {
                originalData.value = (await authConfigsClient.get(teamId, id)).data;
                data.value = { ...originalData.value };
            } else {
                originalData.value = defaultData();
                data.value = defaultData();
            }
            loading.value = false;
        },
        { immediate: true }
    );

    const submit = async () => {
        if (submitting.value) {
            return;
        }

        errors.value = {};
        submitting.value = true;
        try {
            if (data.value.header_value === '*******') {
                delete data.value.header_value;
            }

            if (data.value.auth_config_id) {
                const changedData = Object.keys(data.value)
                    .filter(
                        (key) => data.value[key] !== originalData.value[key] && Object.keys(defaultData()).includes(key)
                    )
                    .reduce(
                        (changedData, key) => ({
                            ...changedData,
                            [key]: data.value[key],
                        }),
                        {}
                    );

                const response = (await authConfigsClient.update(teamId, data.value.auth_config_id, changedData)).data;
                success.value = true;

                return response;
            } else {
                const response = (await authConfigsClient.create(teamId, data.value)).data;
                success.value = true;

                return response;
            }
        } catch (error) {
            success.value = false;
            if (error?.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
                const responseErrors = error.response.data.errors;

                Object.keys(responseErrors).map((k) => {
                    errors.value[k] = responseErrors[k][0];
                });
            } else {
                toasted.global.platform_error();
            }

            throw error;
        } finally {
            submitting.value = false;
        }
    };

    return { errors, submitting, loading, submit, data, success };
};
