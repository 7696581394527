<template>
    <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8 2xl:vx-max-w-screen-xl">
         <page-header>
             <template v-slot:subtitle>Analytics</template>
             Contacts
             <template v-slot:actions>
                 <analytics-controls v-model="controls" />
             </template>
         </page-header>

        <div class="vx-grid vx-gap-6 vx-grid-cols-1 lg:vx-grid-cols-12">
            <analytics-daily-new-contacts-subscribers-unsubscribers class="lg:vx-col-span-12" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                    {{ rangeLabel }}
                </template>
            </analytics-daily-new-contacts-subscribers-unsubscribers>

            <analytics-contacts-total-created class="lg:vx-col-span-4" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                    {{ rangeLabel }}
                </template>
            </analytics-contacts-total-created>
            <analytics-marketing-subscriptions-total-opt-ins class="lg:vx-col-span-4" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                    {{ rangeLabel }}
                </template>
            </analytics-marketing-subscriptions-total-opt-ins>
             <analytics-marketing-subscriptions-total-opt-outs class="lg:vx-col-span-4" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                    {{ rangeLabel }}
                </template>
            </analytics-marketing-subscriptions-total-opt-outs>

            <analytics-marketing-subscriptions-total-by-status class="lg:vx-col-span-4"/>
            <analytics-marketing-subscriptions-total-by-status class="lg:vx-col-span-4" status="opt_in"/>
            <analytics-marketing-subscriptions-total-by-status class="lg:vx-col-span-4" status="unknown"/>

            <analytics-daily-new-contacts class="lg:vx-col-span-12" :startDate="controls.start_date" :endDate="controls.end_date">
                <template v-slot:subtitle>
                    {{ rangeLabel }}
                </template>
            </analytics-daily-new-contacts>


        </div>
    </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';
import AnalyticsControls from './partial/AnalyticsControls.vue';
import AnalyticsMarketingSubscriptionsTotalOptIns from './charts/time-series/AnalyticsMarketingSubscriptionsTotalOptIns.vue';
import AnalyticsMarketingSubscriptionsTotalOptOuts from './charts/time-series/AnalyticsMarketingSubscriptionsTotalOptOuts.vue';

import AnalyticsContactsTotalCreated from './charts/time-series/AnalyticsContactsTotalCreated.vue';
import AnalyticsMarketingSubscriptionsTotalByStatus from './charts/current/AnalyticsMarketingSubscriptionsTotalByStatus.vue';
import AnalyticsDailyNewContacts from './charts/time-series/AnalyticsDailyNewContacts.vue';
import AnalyticsDailyNewContactsSubscribersUnsubscribers from './charts/time-series/AnalyticsDailyNewContactsSubscribersUnsubscribers.vue';
import { controlDateRangeOptions } from '../../utils/analytics';

const controls = ref(controlDateRangeOptions().find(option => option.value === 'last_30_days'));

const rangeLabel = computed(() => controls.value.label)
</script>
