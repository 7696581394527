import { createGlobalState } from '@vueuse/core';
import { moveArrayElement } from '@vueuse/integrations/useSortable';
import { ref } from 'vue';
import { useToasted } from '~/composables/useToasted';
import { automationRulesClient } from '~/services';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_UNPROCESSABLE_ENTITY } from '~/support/HttpStatuses';
import dayjs from '~/utils/dayjs';
import { randomString } from '~/utils/string';

const makeMatch = () => {
    return {
        id: randomString(),
        attribute_name: null,
        operator: null,
        value: null,
        key: null,
        data_type: null,
    };
};

const makeAction = () => {
    return {
        id: randomString(),
        automation: null,
        data: {
            team_id: Spark.state.currentTeam.id,
        },
        status: 'draft',
    };
};

const defaultData = () => ({
    id: null,
    name: '',
    event: '',
    created_at: null,
    match_groups: [
        {
            id: randomString(),
            matches: [],
        },
    ],
    automated_actions: [makeAction()],
});

export const useAutomationRule = createGlobalState(() => {
    const teamId = Spark.state.currentTeam.id;

    const toasted = useToasted();

    const loading = ref(false);
    const saving = ref(false);
    const success = ref(false);
    const data = ref(defaultData());

    const error = ref('');
    const errors = ref({});

    const mount = async (automationRuleId) => {
        loading.value = true;

        try {
            if (automationRuleId) {
                const response = await automationRulesClient.get(teamId, automationRuleId);
                data.value = response.data;
            } else {
                data.value = defaultData();
            }
            loading.value = false;
        } catch (e) {
            console.error(e);
            toasted.global.platform_error();
        }
    };

    const recentlyCreated = ref(false);

    const save = async () => {
        if (saving.value) {
            return;
        }
        success.value = false;
        error.value = '';
        errors.value = {};

        try {
            saving.value = true;
            errors.value = {};

            const response = await automationRulesClient.createOrUpdate(teamId, data.value);

            recentlyCreated.value = response.data.created_at
                ? dayjs.utc(response.data.created_at).isAfter(dayjs().subtract(15, 'seconds'))
                : false;

            if (data.value.id || recentlyCreated.value) {
                success.value = true;
            } else {
                window.location.href = `/automation/event/${response.data.id}`;
            }
        } catch (e) {
            console.error(e);

            if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
                toasted.global.platform_error();
                return;
            } else if (e.response.status === HTTP_UNPROCESSABLE_ENTITY) {
                errors.value = e.response.data.errors;
                error.value = e.response?.data?.message || 'Something went wrong.';
            } else {
                error.value = e.response?.data?.message || 'Something went wrong.';
            }

            toasted.error('Please check form data.');
        } finally {
            saving.value = false;
        }
    };

    const addMatchGroup = () => {
        data.value.match_groups.push({
            id: randomString(),
            matches: [makeMatch()],
        });
    };

    const addMatch = (matchGroupIndex) => {
        data.value.match_groups[matchGroupIndex].matches.push(makeMatch());
    };

    const removeGroup = (matchGroupIndex) => {
        if (data.value.match_groups.length === 1) {
            data.value.match_groups[matchGroupIndex].matches = [];
            return;
        }
        data.value.match_groups = data.value.match_groups.filter(
            (currentMatch, currentMatchGroupIndex) => currentMatchGroupIndex != matchGroupIndex
        );
    };

    const removeMatch = (matchGroupIndex, matchIndex) => {
        if (data.value.match_groups[matchGroupIndex].matches.length === 1) {
            removeGroup(matchGroupIndex);
            return;
        }
        data.value.match_groups[matchGroupIndex].matches = data.value.match_groups[matchGroupIndex].matches.filter(
            (currentMatch, currentMatchIndex) => currentMatchIndex != matchIndex
        );
    };

    const addAction = () => {
        data.value.automated_actions.push(makeAction());
    };

    const removeAction = (actionIndex) => {
        if (data.value.automated_actions.length === 1) {
            data.value.automated_actions = [makeAction()];
            return;
        }
        data.value.automated_actions = data.value.automated_actions.filter(
            (currentAction, currentActionIndex) => currentActionIndex != actionIndex
        );
    };

    const duplicateAction = (actionIndex) => {
        data.value.automated_actions.splice(actionIndex + 1, 0, {
            ...data.value.automated_actions[actionIndex],
        });
    };

    const moveAction = async (fromActionIndex, direction) => {
        if (fromActionIndex === 0 && direction === 'up') {
            return;
        }
        if (fromActionIndex + 1 === data.value.automated_actions.length && direction === 'down') {
            return;
        }

        const directionOffset = direction === 'up' ? -1 : 1;
        const toIndex = fromActionIndex + directionOffset;

        moveArrayElement(data.value.automated_actions, fromActionIndex, toIndex);
    };

    return {
        loading,
        data,
        mount,
        recentlyCreated,
        saving,
        save,
        errors,
        error,
        success,
        addMatchGroup,
        addMatch,
        removeMatch,
        addAction,
        removeAction,
        duplicateAction,
        moveAction,
    };
});
