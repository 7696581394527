<template>
    <vx-expandable-card>
        <template v-slot:content>
            <vx-badge color="violet"
                size="sm"
                class="!vx-px-2">
                Optional
                <font-awesome-icon :icon="faPencilSlash"></font-awesome-icon>
            </vx-badge>
            <h3 class="vx-mt-2 vx-text-xl vx-text-slate-700 vx-font-extrabold">
                Tags
            </h3>
            <p class="vx-text-sm">
                Should we add any tags to these contacts?
            </p>

            <div class="vx-mt-2">
                <vx-selectable
                    placeholder="Add or create tags"
                    :modelValue="props.modelValue"
                    @update:modelValue="emit('update:modelValue', $event.map(tag => tag.toLocaleLowerCase()))"
                    :loading="loading"
                    :options="options"
                    :clearable="true"
                    :close-on-select="false"
                    taggable
                    multiple
                    @search="search">
                </vx-selectable>
            </div>

        </template>
    </vx-expandable-card>
</template>

<script setup>
import { faPencilSlash } from '@fortawesome/pro-solid-svg-icons';
import { VxExpandableCard, VxBadge, VxSelectable } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import tagsClient from '../../../../services/tagsClient';
import { ref } from 'vue';
import debounce from '~/utils/debounce';

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    modelValue: {
        type: Array,
        default: () => ([])
    }
})

const emit = defineEmits(['update:modelValue']);

const loading = ref(false)
const options = ref([]);

const search = debounce(async (tagName) => {
    try {
        loading.value = true;
        const response = await tagsClient.tagsAutocomplete(teamId, { query: tagName })
        options.value = response.data.data.map(tag => tag.value)
    } catch (e) {
        console.error(e)
    } finally {
        loading.value = false;
    }
}, 300)

search();
</script>
