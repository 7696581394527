import BaseClient from './BaseClient';

class GroupsClient extends BaseClient {
    listGroups(teamId, params) {
        const url = `/api/v3/teams/${teamId}/groups`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    showGroup(teamId, groupId) {
        const url = `/api/v3/teams/${teamId}/groups/${groupId}`;
        return this.makeCoreApiRequest(url, 'get');
    }

    createGroup(teamId, data) {
        const url = `/api/v3/teams/${teamId}/groups`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    updateGroup(teamId, groupId, data) {
        const url = `/api/v3/teams/${teamId}/groups/${groupId}`;
        return this.makeCoreApiRequest(url, 'put', data);
    }

    deleteGroup(teamId, groupId) {
        const url = `/api/v3/teams/${teamId}/groups/${groupId}`;
        return this.makeCoreApiRequest(url, 'delete');
    }

    listGroupCustomAttributes(teamId, groupId) {
        const url = `/api/v3/teams/${teamId}/groups/${groupId}/custom-attributes`;
        return this.makeCoreApiRequest(url, 'get');
    }

    createGroupCustomAttribute(teamId, groupId, data) {
        const url = `/api/v3/teams/${teamId}/groups/${groupId}/custom-attributes`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    updateGroupCustomAttribute(teamId, groupId, customAttributeKey, data) {
        const url = `/api/v3/teams/${teamId}/groups/${groupId}/custom-attributes/${customAttributeKey}`;
        return this.makeCoreApiRequest(url, 'put', data);
    }

    deleteGroupCustomAttribute(teamId, groupId, customAttributeKey) {
        const url = `/api/v3/teams/${teamId}/groups/${groupId}/custom-attributes/${customAttributeKey}`;
        return this.makeCoreApiRequest(url, 'delete');
    }
}

export default new GroupsClient();
