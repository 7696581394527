<template>
    <div class="modal" id="modal-enable-two-factor-authentication" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Two-Factor Authentication
                    </h5>
                </div>

                <div class="modal-body">
                    <div>
                        <div class="alert alert-warning">
                          We strongly recommend to <a href="/settings#/security">enable</a> two factor authentication.
                        </div>
                    </div>
                </div>

                <!-- Modal Actions -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script></script>
<style scoped>
.alert-warning {
  font-size: 1rem;
  font-weight: 400;
}

a {
  color: #856404;
  font-weight: bold;
}
</style>
