<template>
    <div class="card-body">
        <template v-if="!showEditForm">
            <div class="alert alert-success alert-dismissible" v-if="successMessage">
                <a href="#" class="close" data-dismiss="alert" aria-label="close" @click="successMessage = ''">&times;</a>
                {{ successMessage }}
            </div>

            <cloning-targets-new @cloningTargetCreated="onCloningTargetCreated"></cloning-targets-new>

            <div class="mt-5">
                <cloning-targets-list @cloningTargetEdited="editCloningTarget" @cloningTargetDeleted="onCloningTargetDeleted" v-model:should-reload="shouldReload"></cloning-targets-list>
            </div>
        </template>

        <cloning-targets-edit v-if="showEditForm" :cloning-target="cloningTarget" @edited="onCloningTargetEdited" @canceled="closeEditForm"></cloning-targets-edit>
    </div>
</template>
<script>
import CloningTargetsNew from '~/components/settings/teams/CloningTargetsNew.vue';
import CloningTargetsList from '~/components/settings/teams/CloningTargetsList.vue';
import CloningTargetsEdit from '~/components/settings/teams/CloningTargetsEdit.vue';
import adminCloningTargetsClient from '../../../services/adminCloningTargetsClient';

export default {
    name: 'cloning-targets',

    components: {
        CloningTargetsNew,
        CloningTargetsList,
        CloningTargetsEdit
    },

    data() {
        return {
            showEditForm: false,
            cloningTarget: null,
            successMessage: '',
            shouldReload: false,
        };
    },

    methods: {
        editCloningTarget(CloningTargetId) {
            adminCloningTargetsClient.getCloningTarget(CloningTargetId).then(response => {
                this.cloningTarget = response.data;
                this.showEditForm = true;
            })
        },

        onCloningTargetEdited() {
            this.successMessage = 'Cloning Target was edited successfully.'
            this.closeEditForm();
            this.hideSuccessMessageAfterSomeTime();

            Bus.$emit('cloningTargetUpdated');
        },

        onCloningTargetDeleted() {
            this.successMessage = 'Cloning Target was deleted successfully.'
            this.hideSuccessMessageAfterSomeTime();

            Bus.$emit('cloningTargetDeleted');
        },

        onCloningTargetCreated() {
            this.successMessage = 'A new cloning target has been created successfully.'
            this.reloadCloningTargets();
            this.hideSuccessMessageAfterSomeTime();

            Bus.$emit('cloningTargetCreated');
        },

        hideSuccessMessageAfterSomeTime() {
            setTimeout(() => {
                this.successMessage = '';
            }, 2000);
        },

        closeEditForm() {
            this.showEditForm = false;
        },

        reloadCloningTargets() {
            this.shouldReload = !this.shouldReload;
        },
    },
}
</script>
