<template>
    <api-delivery-card :icon="faHashtag">
        <template v-slot:title>
            Delivery ID
        </template>
        <template v-slot:content>
            <vx-badge color="slate" size="lg">
                {{ props.deliveryId }}
            </vx-badge>
        </template>
    </api-delivery-card>
</template>
<script setup>
import ApiDeliveryCard from './ApiDeliveryCard.vue';
import { VxBadge } from '@voxie/frontend-components';
import { faHashtag } from '@fortawesome/pro-solid-svg-icons';

const props = defineProps({
    deliveryId: {
        type: String,
        required: true,
    }
})
</script>
