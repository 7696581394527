<template>
    <div class="vx-mx-auto vx-w-full vx-p-3">
        <a v-if="logo" href="/" class="vx-text-center vx-my-8 sm:vx-pt-12 vx-block">
            <img :src="logoUrl" class="vx-w-[160px] vx-h-[47px]" />
        </a>
        <div class="vx-p-6 sm:vx-p-8 vx-bg-slate-50 vx-rounded-2xl vx-shadow vx-border vx-border-white vx-text-slate-700"
            :class="{
                'vx-mt-32': !logo,
            }"
        >
            <div v-if="props.errors?.length"
                class="vx-pb-6">
                <vx-alert color="danger" :closeable="false">
                    <div v-for="(error, index) in props.errors"
                        :key="error"
                        :class="{
                            'vx-pb-1': index + 1 != props.errors.length
                        }">
                        {{ error }}
                    </div>
                </vx-alert>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { VxAlert } from '@voxie/frontend-components';
import logoUrl from '@img/logo.png'

const props = defineProps({
    errors: Array,
    logo: {
        type: Boolean,
        default: true,
    }
});

</script>
