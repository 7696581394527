import BaseClient from './BaseClient';

class TeamThroughputClient extends BaseClient {
    list(teamId) {
        const url = `/api/v3/teams/${teamId}/throughputs`;
        return this.makeCoreApiRequest(url);
    }

    store(teamId, throughputType, throughputRate) {
        const url = `/api/v3/teams/${teamId}/throughputs/${throughputType}`;
        return this.makeCoreApiRequest(url, 'put', {
            throughput_rate: throughputRate,
        });
    }
}

export default new TeamThroughputClient();
