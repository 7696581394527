<template>
    <div>
        <playbook-audience :modelValue="props.modelValue.audience || {}"
            @update:modelValue="emitValue('audience', $event)" :type="AUDIENCE_TYPE_ACQUISITION"></playbook-audience>

        <playbook-form-card :icon="faMessage">
            <template v-slot:header>
                Messages
            </template>

            <playbook-predefined-question :modelValue="{ message: props.modelValue.question_message }" @update:modelValue="emitValue('question_message', $event.message)">
                <template v-slot:label>
                    Ask Consent
                </template>
                <template v-slot:hint>
                    We recommend you to keep an explicit consent phrase such as <span class="vx-text-italic">“Reply Y to subscribe”</span>.
                </template>
            </playbook-predefined-question>

            <playbook-predefined-question :modelValue="{ message: props.modelValue.confirmation_message }" @update:modelValue="emitValue('confirmation_message', $event.message)">
                <template v-slot:label>
                    Welcome and Terms of Texting
                </template>
                <template v-slot:hint>
                    We recommend you to keep an explicit consent phrase such as <span class="vx-text-italic">“Reply HELP for help and STOP to cancel”</span>.
                </template>
            </playbook-predefined-question>

        </playbook-form-card>
    </div>
</template>

<script setup>
import { AUDIENCE_TYPE_ACQUISITION } from '~/constants/campaign';
import PlaybookAudience from './audience/PlaybookAudience.vue';
import PlaybookFormCard from '../PlaybookFormCard.vue';
import { faMessage } from '@fortawesome/pro-solid-svg-icons';
import PlaybookPredefinedQuestion from './PlaybookPredefinedQuestion.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => ({})
    },
});

const emit = defineEmits(['update:modelValue']);

const emitValue = (key, value) => {
    emit('update:modelValue', {
        ...props.modelValue,
        [key]: value,
    })
}

</script>
