<template>
    <auth-box class="vx-max-w-[480px]"
        :errors="props.errors">
        <form @submit="submitting = true"
            method="POST"
            action="/login-via-emergency-token"
            class="vx-flex vx-flex-col vx-gap-4">

            <vx-alert color="info">
                After logging in via your emergency token, <strong>two-factor authentication will be disabled for your account</strong>. If you would like to maintain two-factor authentication security, you should re-enable it after logging in.
            </vx-alert>

            <div>
                <vx-label>
                    Emergency Token
                </vx-label>
                <vx-input required type="password" autofocus name="token" id="token" size="lg">
                </vx-input>
            </div>


            <div class="vx-flex vx-flex-col vx-gap-2 vx-mt-4 md:vx-flex-row-reverse">
                <vx-button :loading="submitting"
                    class="md:vx-flex-grow"
                    type="submit"
                    color="primary"
                    size="lg">
                    Log In <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>

                <vx-button color="muted"
                    size="lg"
                    href="/login">
                    Cancel
                </vx-button>
            </div>
        </form>
    </auth-box>
</template>
<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AuthBox from '../AuthBox.vue';
import { VxInput, VxButton, VxAlert, VxLabel } from '@voxie/frontend-components';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { ref } from 'vue';

const props = defineProps({
    errors: Array,
});

const submitting = ref(false);
</script>
