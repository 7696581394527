<template>
    <page-controls>
        <template v-slot:left>
            <div class="vx-grow @3xl/controls:vx-max-w-md">
                <automation-rule-filters :modelValue="props.modelValue"
                    @update:modelValue="emit('update:modelValue', $event)" @apply="emit('apply')"
                    @reset="emit('reset')"></automation-rule-filters>
            </div>
            <div class="vx-flex vx-w-full vx-gap-2 @xl/controls:vx-w-auto">
                <vx-button-group size="lg" class="vx-w-full">
                    <vx-select :modelValue="props.modelValue.sort_field" @update:modelValue="
        updateModelValue('sort_field', $event);
    emit('apply');
    " :options="[
        {
            value: 'name',
            label: 'Sort by: Name',
        },
        {
            value: 'id',
            label: 'Sort by: Created',
        },
    ]"></vx-select>
                    <vx-button @click.prevent="
        props.modelValue.sort_dir === 'asc'
            ? updateModelValue('sort_dir', 'desc')
            : updateModelValue('sort_dir', 'asc');
    emit('apply');
    " type="button" color="white" shadow>
                        <font-awesome-icon :icon="props.modelValue.sort_dir === 'asc'
        ? faArrowUpWideShort
        : faArrowDownWideShort
        "></font-awesome-icon>
                    </vx-button>
                </vx-button-group>
            </div>
        </template>
        <template v-slot:right>
            <vx-button :disabled="props.loading" size="lg" block href="/automation/event/new">
                New Automation <font-awesome-icon :icon="faPlusCircle" />
            </vx-button>
        </template>
    </page-controls>

</template>
<script setup>
import { faArrowUpWideShort, faArrowDownWideShort, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxButton, VxButtonGroup, VxSelect } from '@voxie/frontend-components';
import AutomationRuleFilters from './AutomationRuleFilters.vue';
import PageControls from '~/components/general/PageControls.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    loading: {
        type: Boolean,
        default: false,
    }
});

const emit = defineEmits(['update:modelValue', 'apply', 'reset', 'new'])

const updateModelValue = (key, value) => {
    emit("update:modelValue", {
        ...props.modelValue,
        [key]: value,
    });
};
</script>
