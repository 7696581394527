<template>
    <page-container class="vx-grid vx-grid-cols-12 vx-gap-8}">
      <settings-sidebar></settings-sidebar>
        <div class="vx-col-span-12 md:vx-col-span-8 lg:vx-col-span-10">
            <page-header v-if="newResourceObj && shouldShowNew">{{ newResourceObj.id ? 'Update Phone Pool' : 'Create Phone Pool' }}</page-header>
            <page-header v-else-if="editResourceObj && shouldShowEdit">{{ editResourceObj.id ? 'Update Phone Pool' : 'Create Phone Pool' }}</page-header>
            <page-header v-else-if="showResourceObj && shouldShowShow">{{ showResourceObj.phone_number }}</page-header>
            <page-header v-else-if="shouldShowAddRemoveNumbers">
            Manage Numbers <span v-if="showAddRemoveNumbersForPoolId">&nbsp;for {{showAddRemoveNumbersForPoolId.friendly_name}}</span>
            </page-header>
            <page-header v-else-if="shouldShowBulkUpdatePhonePool">Update Numbers</page-header>
            <page-header v-else>Phone Pools</page-header>

            <phone-number-pool-form v-if="newResourceObj && shouldShowNew" :phoneNumberPool="newResourceObj"></phone-number-pool-form>

            <phone-number-pool-form v-if="editResourceObj && shouldShowEdit" :phoneNumberPool="editResourceObj"></phone-number-pool-form>

            <phone-number-pool-show v-if="showResourceObj && shouldShowShow" :phoneNumberPool="showResourceObj"></phone-number-pool-show>

            <pool-add-remove-phone-numbers v-if="shouldShowAddRemoveNumbers" :phoneNumberPoolId="showAddRemoveNumbersForPoolId"></pool-add-remove-phone-numbers>

            <div v-if="shouldShowList" class="list">

                <table class="c-table resource-table">
                    <caption class="c-table__title">
                        <a v-if="isImpersonatingOrDeveloper" @click="presentNewUi" class="pull-right c-btn c-btn--success" href="/message_sources/phone_number_pools#/new" data-router="client-route">
                            <i class="fa fa-plus u-text-small u-opacity-medium u-mr-xsmall"></i>New Phone Pool
                        </a>

                    </caption>

                    <thead v-if="!loadingCollection" class="c-table__head c-table__head--slim">
                        <tr class="c-table__row">

                            <th class="c-table__cell c-table__cell--head tag-cell">Name</th>
                            <th class="c-table__cell c-table__cell--head center">Phone Numbers</th>
                            <th v-if="isImpersonatingOrDeveloper" class="c-table__cell c-table__cell--head center">Actions</th>

                        </tr>
                    </thead>

                    <tbody>

                        <tr v-if="loadingCollection">
                            <td class="c-table__cell center" colspan="3">
                                <loading-resource />
                            </td>
                        </tr>

                        <tr v-else-if="loadingCollectionError">
                            <td class="c-table__cell center" colspan="6">
                                <alert-error />
                            </td>
                        </tr>

                        <tr v-else-if="!collection.length">
                            <td class="c-table__cell center" colspan="6">
                                <alert-no-results />
                            </td>
                        </tr>

                        <template v-else>
                            <phone-number-pool-table-row v-for="resource in collection" :phoneNumberPool="resource" :key="resource.id"></phone-number-pool-table-row>
                        </template>

                    </tbody>
                </table><!-- // .c-table -->

                <pagination
                        v-if="!loadingCollection && !(!prevPageUrl && !nextPageUrl)"
                        identity="phone_number_pools-pages"
                        :totalPageCount="totalPages"
                        :nextPage="nextPageUrl"
                        :prevPage="prevPageUrl"
                        ></pagination>

            </div><!-- eo .list -->
        </div>

    </page-container>
</template>


<script>
import Pagination from '~/components/general/Pagination.vue';
import PhoneNumberPoolForm from '~/components/phone-number-pools/PhoneNumberPoolForm.vue';
import PhoneNumberPoolShow from '~/components/phone-number-pools/PhoneNumberPoolShow.vue';
import PhoneNumberPoolTableRow from '~/components/phone-number-pools/PhoneNumberPoolTableRow.vue';
import PoolAddRemovePhoneNumbers from '~/components/phone-number-pools/AddRemovePhoneNumbers.vue';
import AlertNoResults from '../general/AlertNoResults.vue';
import AlertError from '../general/AlertError.vue';
import LoadingResource from '../general/LoadingResource.vue';
import { displayNotActiveModal, isNotActive } from '../../utils/team';
import PageHeader from '~/components/general/PageHeader.vue';
import resourceManagement from './../../mixins/resource-management'
import SettingsSidebar from '~/components/settings/menus/SettingsSidebar.vue'
import PageContainer from '~/components/general/PageContainer.vue';

export default {

  mixins: [
    resourceManagement
  ],


  components: {
      Pagination,
      PhoneNumberPoolForm,
      PhoneNumberPoolShow,
      PhoneNumberPoolTableRow,
      PoolAddRemovePhoneNumbers,
      AlertNoResults,
      AlertError,
      LoadingResource,
      PageHeader,
      PageContainer,
      SettingsSidebar,
  },
  inject: ['FEATURES'],
  data() {
      return {
          resourceName: 'phone_number_pools',
          resourceReadUrl: `/api/v3/teams/${Spark.state.currentTeam.id}/phone-number-pools`,
          resourceWriteUrl: `/api/admin/teams/${Spark.state.currentTeam.id}/phone-number-pools`,
          showAddRemoveNumbersForPoolId: null,
          showBulkUpdateForPoolId: null
      };
  },

  mounted(){

      this.listenForResourceEvents();
      this.loadCollectionAndUi();

      Bus.$on(`${this.resourceName}ResourceWasCreated`, () => {
          this.newResourceObj = null;
      });

      Bus.$on(`${this.resourceName}ResourceWasUpdated`, () => {
          this.editResourceObj = null;
      });

      Bus.$on(`${this.resourceName}ManageNumbers`, (id) => {
          this.showAddRemoveNumbersForPoolId = id;
      });

      Bus.$on(`${this.resourceName}CancelManageNumbers`, () => {
          this.showAddRemoveNumbersForPoolId = null;
      });

      Bus.$on(`${this.resourceName}BulkUpdate`, (id) => this.showBulkUpdateForPoolId = id);

      Bus.$on("pool_numbersUpdated", (poolId, data) => {
          let cnt = 0;
          Object.keys(data).forEach((key) => {
              if(data[key] == true){
                  cnt++;
              }
          });

          let objIndex = this.collection.findIndex((obj => obj.id == poolId));
          this.collection[objIndex].phone_numbers_count = cnt;
      });

      let route = this.currentClientRoute();

      if(route){
          if(route.hash == 'phone_numbers'){

              Bus.$emit(`${this.resourceName}ManageNumbers`, parseInt(route.parameters[0]));

          }
      }

      if(isNotActive()) {
          displayNotActiveModal()
      }

  },

  beforeUnmount() {
      Bus.$off(`${this.resourceName}ResourceWasCreated`);
      Bus.$off(`${this.resourceName}ResourceWasUpdated`);
      Bus.$off(`${this.resourceName}ManageNumbers`);
      Bus.$off(`${this.resourceName}CancelManageNumbers`);
      Bus.$off(`${this.resourceName}BulkUpdate`);
  },

  computed: {
      shouldShowAddRemoveNumbers() {
          return !this.editResourceObj
              && !this.newResourceObj
              && !this.showResourceObj
              && !this.showBulkUpdateForPoolId
              && this.showAddRemoveNumbersForPoolId;
      },

      shouldShowList() {
          return !this.editResourceObj
              && !this.newResourceObj
              && !this.showResourceObj
              && !this.showAddRemoveNumbersForPoolId
              && !this.showBulkUpdateForPoolId;
      },

      shouldShowNew() {
          return !this.editResourceObj
              && this.newResourceObj
              && !this.showResourceObj
              && !this.showAddRemoveNumbersForPoolId
              && !this.showBulkUpdateForPoolId;
      },

      shouldShowEdit() {
          return this.editResourceObj
              && !this.newResourceObj
              && !this.showResourceObj
              && !this.showAddRemoveNumbersForPoolId
              && !this.showBulkUpdateForPoolId;
      },

      shouldShowShow() {
          return !this.editResourceObj
              && !this.newResourceObj
              && this.showResourceObj
              && !this.showAddRemoveNumbersForPoolId
              && !this.showBulkUpdateForPoolId;
      },

      shouldShowBulkUpdatePhonePool() {
          return !this.editResourceObj
              && !this.newResourceObj
              && !this.showResourceObj
              && !this.showAddRemoveNumbersForPoolId
              && this.showBulkUpdateForPoolId;
      },

      isImpersonatingOrDeveloper() {
          return Spark.isImpersonating || Spark.isDeveloper;
      },
  },

  methods: {
      cancelBulkUpdate() {
          this.showBulkUpdateForPoolId = false;
      },

      presentNewUi(){
          Bus.$emit('phone_number_poolsNew', {
              vendor: 'twilio',
              friendly_name: '',
              type: 'long_code',
          });
      }
  },
}
</script>
