<template>
    <div class="vx-@container/tone">
        <div class="vx-text-slate-700 vx-text-base vx-font-bold">Voice</div>

        <div class="vx-mt-4 vx-grid vx-gap-2 vx-grid-cols-2 @lg/tone:vx-grid-cols-4">
            <vx-radio-selection-item value="friendly" @selected="emit('update:modelValue', $event)" :active="'friendly' === props.modelValue">
                Friendly
            </vx-radio-selection-item>
            <vx-radio-selection-item value="formal" @selected="emit('update:modelValue', $event)" :active="'formal' === props.modelValue">
                Formal
            </vx-radio-selection-item>
            <vx-radio-selection-item value="casual" @selected="emit('update:modelValue', $event)" :active="'casual' === props.modelValue">
                Casual
            </vx-radio-selection-item>
            <vx-radio-selection-item value="funny" @selected="emit('update:modelValue', $event)" :active="'funny' === props.modelValue">
                Funny
            </vx-radio-selection-item>
        </div>
        <div v-if="props.error" class="vx-mt-1 vx-px-0.5 vx-text-xs vx-text-rose-800">{{ props.error }}</div>
    </div>
</template>

<script setup>
import {
    VxRadioSelectionItem,
} from "@voxie/frontend-components";

const props = defineProps({
    modelValue: {
        type: String,
    },
    error: {
        type: String,
        default: undefined,
    },
})

const emit = defineEmits(['update:modelValue']);
</script>
