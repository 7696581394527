<template>
    <vx-chart-line
        legend
        tooltipItemKey="label"
        :loading="loading"
        :error="hasError"
        @error="chartError = true"
        :data="dataComputed.data"
        :colors="dataComputed.colors"
        :axis="{
            x: {
                key: 'date',
                axis: true,
                label: '',
                type: 'point',
                align: 0,
                padding: 0,
                tickFormat: formatDateAxisTicks
            },
            y: {
                key: 'count',
                axis: true,
                label: '',
                ticks: 5,
                tickFormat: (v) => formatCount(v),
            },
        }"
    >
        <template v-slot:title>
            <slot name="title">
                <span>
                    Daily New Contacts, Subscribers, and Unsubscribers
                </span>
            </slot>
        </template>
        <template v-slot:subtitle>
            <slot name="subtitle"></slot>
        </template>
        <template v-slot:right>
            <slot name="right">
                <experimental-pill subject="Beta Feedback - Analytics" v-if="!loading" class="-vx-mt-[4px]"></experimental-pill>
            </slot>
        </template>
        <template v-slot:footer>
            <vx-button
                href="/contacts"
                outline
                color="muted-light"
                size="xs"
            >
                Go to Contacts <font-awesome-icon :icon="faArrowRight" />
            </vx-button>
        </template>
        <template v-slot:explainHeader>Daily New Contacts, Subscribers and Unsubscribers</template>
        <template v-slot:explainContent>
            <p>
                Evolution of Contacts, Subscribers and those who have Unsubscribed across time.
            </p>
            <p>
                <strong>New Contacts</strong><br />
                Gross Increase Count of Contacts during the selected period.
            </p>

            <p>
                <strong>New Subscribers</strong><br />
                Gross Increase Count of Contacts that consented to receive marketing messages, during the selected period.
            </p>

            <p>
                <strong>New Usubscribers</strong><br />
                Gross Increase Count of Contacts that have explicitly denied receiving marketing messages, during the selected period.
            </p>
        </template>
    </vx-chart-line>
</template>
<script setup>
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxButton, VxChartLine } from '@voxie/frontend-components';
import { ref, computed, watch } from 'vue';
import { analyticsClient } from '~/services';
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';
import { formatCount } from '~/components/filters';
import { formatDateAxisTicks, calculateRangeBetweenTwoDates } from '~/utils/analytics';
import dayjs from '~/utils/dayjs';
import { getClientTz } from '~/utils/date';
import { useVersionedRequest } from '~/composables/useVersionedRequest';

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
});

const chartError = ref(false);

const { loading, response, error, fetchData } = useVersionedRequest();

const hasError = computed(() => {
    return Boolean(error.value) || chartError.value;
});

const mapContactsTotalCreated = (rawData) => {
    if (!rawData || !rawData?.time_range?.length || !rawData?.values?.length) {
        return [];
    }

    const mappedData = rawData.time_range.map((date, index) => {
        const value = rawData.values[index] || 0;
        return {
            label: date,
            count: value,
            tooltip: `${dayjs.utc(date).tz(getClientTz()).format('MM/DD')}: ${Intl.NumberFormat('en').format(
                value
            )}`,
            iconColor: '#14ABE0',
            bgColor: '#73D8FC',
        };
    });

    mappedData.pop();

    return mappedData.map(
        (item) => ({
            ...item,
            date: item.label,
            label: 'Contacts',
        })
    );
}

const loadData = async () => {
    const params = {
        range: calculateRangeBetweenTwoDates({
            startDate: props.startDate, endDate: props.endDate
        }),
    };

    const contactsTotalCreated = await analyticsClient.contactsTotalCreated(Spark.state.currentTeam.id, params);

    const marketingSubscriptionsTotalOptIns = await analyticsClient.marketingSubscriptionsTotalOptIns(Spark.state.currentTeam.id, params);
    const marketingSubscriptionsTotalOptInsValues = marketingSubscriptionsTotalOptIns.data.values;
    const marketingSubscriptionsTotalOptInsTimeRange = marketingSubscriptionsTotalOptIns.data.time_range.map((date, index) => {
        const value = marketingSubscriptionsTotalOptInsValues[index] || 0;
        return { label: 'Subscribers', date, count: value };
    });
    marketingSubscriptionsTotalOptInsTimeRange.pop();

    const marketingSubscriptionsTotalOptOuts = await analyticsClient.marketingSubscriptionsTotalOptOuts(Spark.state.currentTeam.id, params);
    const marketingSubscriptionsTotalOptOutsValues = marketingSubscriptionsTotalOptOuts.data.values;
    const marketingSubscriptionsTotalOptOutsTimeRange = marketingSubscriptionsTotalOptOuts.data.time_range.map((date, index) => {
        const value = marketingSubscriptionsTotalOptOutsValues[index] || 0;
        return { label: 'Unsubscribers', date, count: value };
    });
    marketingSubscriptionsTotalOptOutsTimeRange.pop();

    return [
        ...marketingSubscriptionsTotalOptInsTimeRange,
        ...mapContactsTotalCreated(contactsTotalCreated.data),
        ...marketingSubscriptionsTotalOptOutsTimeRange
    ];
}

const dataComputed = computed(() => {
    const responseData = response.value || [];

    return {
        data: responseData.every((i) => i.count === 0) || [...new Set(responseData.map(item => item.date))].length <= 1 ? [] : responseData,
        colors: responseData.length ? [
            {
                // blue/300/500
                iconColor: '#3B82F6',
                bgColor: '#93C5FD',
                label: 'Contacts',
            },
            {
                // sky/300/500
                iconColor: '#14ABE0',
                bgColor: '#73D8FC',
                label: 'Subscribers',
            },
            {
                // violet/300/500
                iconColor: '#8B5CF6',
                bgColor: '#C4B5FD',
                label: 'Unsubscribers',
            }
        ] : []
    }
})

watch(
    [() => props.startDate, () => props.endDate],
    () => {
        chartError.value = false;

        fetchData(loadData()).catch((e) => {
            console.error(e);
        });
    },
    { immediate: true }
);
</script>
