<template>
    <div class="vx-border vx-border-solid vx-rounded-[16px] vx-p-6"
        :class="appearance.elementColors">
        <div class="vx-flex vx-items-center vx-gap-2">
            <div class="vx-w-10 vx-h-8 vx-border-solid vx-border vx-border-white vx-border-opacity-50 vx-rounded-[16px] vx-text-base vx-flex vx-items-center vx-justify-center"
                :class="appearance.iconColor">
                <font-awesome-icon :icon="appearance.icon"></font-awesome-icon>
            </div>
            <div class="vx-text-slate-900 vx-font-semibold vx-capitalize vx-pt-0.5">
                {{ appearance.plural }}
            </div>

        </div>
        <div class="vx-text-slate-500 vx-text-xs vx-mb-6">
            Choose all the {{ appearance.plural }} you want to add to this Flow.
        </div>

        <slot></slot>
    </div>
</template>

<script setup>
import { faContactBook, faMessages, faRobot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

const props = defineProps({
    resourceType: {
        type: String,
        validator: (resourceType) => ['automation', 'campaign', 'segment'].includes(resourceType)
    },
});

const appearance = computed(() => {
    if (props.resourceType === 'automation') {
        return {
            plural: 'automations',
            elementColors: 'vx-border-amber-200 vx-bg-amber-50',
            iconColor: 'vx-bg-amber-300',
            icon: faRobot,
        };
    }
    if (props.resourceType === 'campaign') {
        return {
            plural: 'campaigns',
            elementColors: 'vx-border-sky-200 vx-bg-sky-50',
            iconColor: 'vx-bg-sky-300',
            icon: faMessages,
        };
    }
    if (props.resourceType === 'segment') {
        return {
            plural: 'segments',
            elementColors: 'vx-border-violet-200 vx-bg-violet-50',
            iconColor: 'vx-bg-violet-300',
            icon: faContactBook,
        };
    }

    return {};
})

</script>
