<template>
    <div>
        <div class="vx-text-slate-700 vx-text-base vx-font-bold">Chatbot Role</div>
        <div class="vx-text-sm vx-leading-tight vx-mb-4 vx-text-slate-500">
            Describe what will the chatbot will do. You can put multiple answers in text area below e.g. sales rep, support.
        </div>

        <vx-input class="vx-min-h-[96px]" counter :maxlength="120" placeholder="What will the chatbot do (sales rep, support, etc)" size="lg" autoresize :disabled="props.disabled" :error="props.error"
            :modelValue="props.modelValue" @update:modelValue="emit('update:modelValue', $event)">
        </vx-input>
    </div>
</template>

<script setup>
import {
    VxInput,
} from "@voxie/frontend-components";

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    error: {
        type: String,
        default: undefined,
    },
})

const emit = defineEmits(['update:modelValue']);

</script>
