<template>
        <vx-expandable-card>
        <template v-slot:header>
            Details
        </template>

        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-6">
                <vx-alert v-if="success" :closeable="false" color="success" shadow>
                    Updated successfully
                </vx-alert>
                <vx-alert v-if="error" :closeable="false" color="danger" shadow>
                    {{ error }}
                </vx-alert>
                <!-- Name -->
                <div>
                    <vx-label size="md">
                        Name
                    </vx-label>

                    <vx-input
                        size="lg"
                        name="name"
                        v-model="team.name"
                        :disabled="!isTeamAdmin"
                        :error="displayFirstError(errors.name)"
                    ></vx-input>
                </div>

                <div v-if="team.organization_type === 'brand'">
                    <vx-label size="md">
                        Workspace Slug
                    </vx-label>

                    <vx-input
                        size="lg"
                        name="workspace_slug"
                        v-model="team.workspace_slug"
                        :disabled="!isTeamAdmin"
                        :error="displayFirstError(errors.workspace_slug)"
                    />
                </div>

                <div>
                    <div class="vx-flex vx-items-center vx-justify-between">
                        <div>
                            <vx-label size="md">Guess Contact Time-zone</vx-label>
                            <div class="vx-text-sm vx-text-slate-500">Voxie will attempt to set a time-zone if it is missing on a contact.</div>
                        </div>
                        <vx-toggle data-test="guess-contact-time-zone" v-model="team.guess_contact_time_zone" size="lg" :disabled="!isTeamAdmin"></vx-toggle>
                    </div>
                    <div
                        v-if="displayFirstError(errors.guess_contact_time_zone)"
                        class="vx-mt-2 vx-px-0.5 vx-text-xs vx-text-rose-800"
                    >
                        {{ displayFirstError(errors.guess_contact_time_zone) }}
                    </div>
                </div>

                <div>
                    <vx-label size="md">Default Contact Time-Zone</vx-label>

                    <vx-selectable
                        size="lg"
                        name="default_contact_time_zone"
                        v-model="team.default_contact_time_zone"
                        data-test="default-contact-time-zone"
                        :disabled="!isTeamAdmin"
                        :error="displayFirstError(errors.default_contact_time_zone)"
                        :options="timeZonesList"
                        :reduce="option => option.value"
                    >
                    </vx-selectable>
                </div>

                <vx-button
                    block
                    size="lg"
                    @click.prevent="submit"
                    :loading="submitting"
                    :disabled="!isTeamAdmin || !dirty"
                >
                    Update
                </vx-button>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxButton, VxExpandableCard, VxLabel, VxInput, VxAlert, VxToggle, VxSelectable } from '@voxie/frontend-components';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_UNPROCESSABLE_ENTITY } from '~/support/HttpStatuses';
import timeZones from '~/utils/timeZones';
import { computed, ref, watch } from 'vue';
import { displayFirstError } from '~/utils/validation';
import { teamsClient } from '~/services';

const team = defineModel('team', {
    type: Object,
    required: true,
});

const props = defineProps({
    isTeamAdmin: {
        type: Boolean,
        required: false,
    },
});

const dirty = ref(false);

watch(
    () => team.value.name +
        team.value.workspace_slug +
        team.value.guess_contact_time_zone +
        team.value.default_contact_time_zone,
    () => {
        dirty.value = true;
    }
);

const submitting = ref(false);
const success = ref(false);
const error = ref('');
const errors = ref({});

const timeZonesList = computed(() => timeZones.map(timeZone => ({
    value: timeZone,
    label: timeZone.split("_").join(" ")
})))

const submit = () => {
    if (submitting.value || !props.isTeamAdmin) {
        return;
    }

    success.value = false;
    errors.value = {};
    error.value = ''
    submitting.value = true;

    teamsClient.updateDetails(
        team.value.id,
        {
            name: team.value.name,
            workspace_slug: team.value.organization_type === 'brand' ? team.value.workspace_slug : undefined,
            default_contact_time_zone: team.value.default_contact_time_zone,
            guess_contact_time_zone: team.value.guess_contact_time_zone,
        }
    ).then(() => {
        success.value = true;
    }).catch((e) => {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            error.value = 'Something went wrong.'
        } else {
            if (e.response.status === HTTP_UNPROCESSABLE_ENTITY) {
                errors.value = e.response.data.errors
            }
            error.value = e.response?.data?.message || 'Something went wrong.';
        }
    }).finally(() => {
        submitting.value = false;
    })
}

</script>
