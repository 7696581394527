<template>
    <div class="tlt-chat-scheduled-messages">
        <div v-for="(messageGroup, key) in messageGroups"
             :key="`t-message-group-${key}`"
             class="t-chat-messages-group">
            <template v-for="message in messageGroup.messages" :key="`t-chat-message-${message.id}`">
                <vx-message-cloud
                    v-if="message.body || message.media_url"
                    :key="`t-message-cloud-${message.id}`"
                    :message-id="message.id"
                    :message-body="message.body"
                    :media-url="message.media_url"
                    :media-content-type="message.media_content_type"
                    :is-scheduled="true"
                    @edit="openEditModal(message)"
                >
                    <template v-slot:info>
                        {{message.user ? `User - ${message.user?.name} -` : ''}} scheduled for {{message.send_at_formatted}}
                    </template>
               </vx-message-cloud>
            </template>
        </div>
        <div class="t-chat-button">
            <vx-button
                v-show="showLoadMore"
                class="t-chat-load-more"
                appearance="transparent-dark-outline"
                size="large"
                @click="loadMore"
            >
                Load More
            </vx-button>
        </div>
        <scheduled-message-modal v-model:visible="this.showModal" @scheduledMessageEdited="editScheduledMessage" :scheduled-message="selectedScheduledMessage"></scheduled-message-modal>
    </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import dayjs from '~/utils/dayjs';
import { VxButton, VxMessageCloud } from '@voxie/frontend-components-v3';
import ScheduledMessageModal from '../../scheduled-messages/modals/ScheduledMessageModal.vue';
import {formatMessageTime} from '../../filters';
import ScheduledMessages from '../../../utils/scheduledMessages';

export default {
    name: 'ScheduledMessages',
    components: {
        ScheduledMessageModal,
        VxButton,
        VxMessageCloud,
    },
    data() {
        return {
            team: Spark.state.currentTeam.id,
            showLoadMore: true,
            user: Spark.state.user,
            selectedScheduledMessage: null,
            showModal: false,
        }
    },
    mounted() {
        this.loadScheduledMessages({contactId: this.activeThread.contact_id, $toasted: this.$toasted}).finally(() => {
            this.toggleLoadMore();
        })
    },
    computed: {
        ...mapGetters({
            'activeThread': 'getActiveThread',
        }),
        ...mapState({
            selectedContact: (state) => state.contacts_v3.selectedContact,
        }),
        messageGroups() {
            const messagesToDay = ([day, group]) => {
                return {
                    fullDate: day,
                    prettyDate: dayjs(day).format('MMMM D, YYYY'),
                    messages: group.sort((mA, mB) => mA.id - mB.id)
                };
            };

            const groups = this.messages.reduce((groups, message) => {
                    const groupKey = dayjs(message.created_at).startOf('day').toString();
                    if (!groups[groupKey]) {
                        groups[groupKey] = [];
                    }

                    groups[groupKey].push(message);

                    return groups;
            }, {});

            return Object.entries(groups).map(messagesToDay).sort((gA, gB) => {
                return new Date(gA.fullDate).getTime() - new Date(gB.fullDate).getTime();
            });
        },
        messages() {
            return (this.selectedContact.scheduled_messages || [])
                .map(scheduledMessage => {
                    scheduledMessage.send_at_formatted = formatMessageTime(scheduledMessage.send_at);
                    return scheduledMessage;
                })
                .slice()
                .sort((a, b) => {
                    return b.id - a.id;
                });
        },
    },
    methods: {
        ...mapActions({
            'loadScheduledMessages': 'contacts_v3/loadScheduledMessages',
            'loadNextScheduledMessages': 'contacts_v3/loadNextScheduledMessages',
            'loadConversation': 'contacts_v3/loadConversation',
        }),
        loadMore() {
            this.loadNextScheduledMessages({contactId: this.activeThread.contact_id, $toasted: this.$toasted})
                .then(() => {
                    this.toggleLoadMore();
                });
        },
        toggleLoadMore() {
            if (!this.selectedContact.scheduled_messages_cursor) {
                this.showLoadMore = false;
            }
        },
        openEditModal(scheduledMessage) {
            scheduledMessage.contact = {
                first_name: this.selectedContact.first_name,
                last_name: this.selectedContact.last_name,
                phone: this.selectedContact.phone,
            };
            this.selectedScheduledMessage = scheduledMessage;
            this.showModal = true;
        },
        async editScheduledMessage(data) {
            await ScheduledMessages.editScheduledMessage(data, this.$toasted);

            await this.loadConversation({contactId: data.contact_id, $toasted: this.$toasted});

            this.$emit('scheduledMessageEdited', data);
        },
    },
};
</script>

<style scoped>
.t-chat-button {
    margin: 0 auto;
}
.t-chat-load-more {
    margin-top: 15px;
}
</style>
