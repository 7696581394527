<template>
    <vx-table-row>
        <vx-table-row-title>
            {{ model.id === userId ? 'You' : model.name }}
        </vx-table-row-title>

        <vx-table-row-item>
            <template v-slot:label> Team Role: </template>
            {{ TEAM_USER_ROLES[model.role] || '-' }}
        </vx-table-row-item>

        <vx-table-row-buttons v-if="model.id !== userId && isAdminOrOwner">
            <vx-dropdown placement="right">
                <vx-button data-test="dropdown"
                    type="button"
                    color="muted"
                    outline
                    class="vx-w-[45px]">
                    <font-awesome-icon :icon="faEllipsis" />
                </vx-button>

                <template v-slot:items>
                    <vx-dropdown-item data-test="delete"
                        @click="deleteUser()">
                        Delete
                        <font-awesome-icon :icon="faTrash"></font-awesome-icon>
                    </vx-dropdown-item>
                </template>
            </vx-dropdown>

            <vx-button
                @click="editModal = true"
                data-test="edit"
                color="muted"
                outline>
                Edit <font-awesome-icon :icon="faFilePen" />
            </vx-button>
        </vx-table-row-buttons>

        <team-user-form-modal :modelValue="model" v-model:visible="editModal" @updated="emit('updated')"></team-user-form-modal>
    </vx-table-row>
</template>

<script setup>
import {
    faEllipsis,
    faFilePen,
    faTrash
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxButton,
    VxDropdown,
    VxDropdownItem,
    VxTableRow,
    VxTableRowButtons,
    VxTableRowItem,
    VxTableRowTitle,
} from "@voxie/frontend-components";
import { useToasted } from '~/composables/useToasted';
import { TEAM_USER_ROLES } from "~/constants/roles";
import { usersClient } from "~/services";
import { HTTP_INTERNAL_SERVER_ERROR } from "~/support/HttpStatuses";
import TeamUserFormModal from './TeamUserFormModal.vue';
import { ref } from "vue";

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;
const userId = Spark.state.user.id;

const isAdminOrOwner = Spark.isAdminOrOwner;

const model = defineModel({
    type: Object,
    required: true,
});

const editModal = ref(false);

const emit = defineEmits(['updated', 'deleted']);

const deleteUser = async () => {
    if (!window.confirm('Are you sure? There is no undo.')) return;

    try {
        await usersClient.deleteUser(teamId, model.value.id);
        emit('deleted', model.value.id);
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
        } else {
            toasted.error(e.response?.data?.message || 'Something went wrong.');
        }
    }
};

</script>
