<template>
    <vx-expandable-card>
        <template v-slot:content>
            <vx-badge color="violet"
                size="sm"
                class="!vx-px-2">
                Optional
                <font-awesome-icon :icon="faPencilSlash"></font-awesome-icon>
            </vx-badge>
            <h3 class="vx-mt-2 vx-text-xl vx-text-slate-700 vx-font-extrabold">
                Subscription Status
            </h3>
            <p class="vx-text-sm">
                How should we update the marketing and transactional subscription statuses on this import?
            </p>

            <div class="vx-mt-4 vx-grid vx-gap-6 lg:vx-grid-cols-2 lg:vx-gap-8">
                <div>
                    <div class="vx-text-slate-700 vx-text-base vx-font-extrabold vx-mb-1">Marketing</div>
                    <vx-radio-selection v-model="marketing"
                        @update:modelValue="updateModelValue()">
                        <vx-radio-selection-item v-for="option in options"
                            :key="`marketing-${option.key}`"
                            :value="option.value">
                            {{ option.label }}
                        </vx-radio-selection-item>
                    </vx-radio-selection>
                </div>
                <div>
                    <div class="vx-text-slate-700 vx-text-base vx-font-extrabold vx-mb-1">Transactional</div>
                    <vx-radio-selection v-model="transactional"
                        @update:modelValue="updateModelValue()">
                        <vx-radio-selection-item v-for="option in options"
                            :key="`transactional-${option.key}`"
                            :value="option.value">
                            {{ option.label }}
                        </vx-radio-selection-item>
                    </vx-radio-selection>
                </div>
            </div>

        </template>
    </vx-expandable-card>
</template>

<script setup>
import { faPencilSlash } from '@fortawesome/pro-solid-svg-icons';
import { VxExpandableCard, VxBadge, VxRadioSelection, VxRadioSelectionItem } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ref, watch } from 'vue'
import {
    CONTACT_SUBSCRIPTION_STATUS_OPT_IN,
    CONTACT_SUBSCRIPTION_STATUS_OPT_OUT,
    CONTACT_SUBSCRIPTION_TYPE_MARKETING,
    CONTACT_SUBSCRIPTION_TYPE_TRANSACTIONAL
} from '../../../../constants/contactSubscription'

const options = [
    {
        label: 'Do nothing',
        value: null,
    },
    {
        label: 'Opt-in',
        value: CONTACT_SUBSCRIPTION_STATUS_OPT_IN,
    },
    {
        label: 'Opt-out',
        value: CONTACT_SUBSCRIPTION_STATUS_OPT_OUT
    },
];

const props = defineProps({
    modelValue: {
        type: Array,
        default: () => ([])
    }
})

const emit = defineEmits(['update:modelValue']);

const marketing = ref(null);
const transactional = ref(null);

watch(() => props.modelValue, (subscriptions) => {
    subscriptions.forEach(subscription => {
        if (subscription.type === CONTACT_SUBSCRIPTION_TYPE_MARKETING) {
            marketing.value = subscription.status;
        }
        if (subscription.type === CONTACT_SUBSCRIPTION_TYPE_TRANSACTIONAL) {
            transactional.value = subscription.status;
        }
    })
}, {
    immediate: true,
})

const updateModelValue = () => {
    const subscriptions = [];

    if (transactional.value) {
        subscriptions.push({
            type: CONTACT_SUBSCRIPTION_TYPE_TRANSACTIONAL,
            status: transactional.value
        })
    }

    if (marketing.value) {
        subscriptions.push({
            type: CONTACT_SUBSCRIPTION_TYPE_MARKETING,
            status: marketing.value
        })
    }

    emit('update:modelValue', subscriptions);
}
</script>
