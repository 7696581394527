<template>
    <div class="text-center">
        <div class="spinner-border text-gray-500">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
export default {
};
</script>
