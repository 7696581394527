<script setup>
const props = defineProps({
  d: {
    type: String,
  },
  strokeDasharray: {
    type: String,
    default: '0 50',
  },
  animated: {
    type: Boolean,
    default: false,
  },
})
</script>

<style>
.playbook-dash {
  animation: playbook-dash 10s linear infinite forwards;
  filter: drop-shadow(0 0px 3px #c4b5fdff) drop-shadow(0 0px 5px #ffffff)
    drop-shadow(0 0px 5px #ffffff) drop-shadow(0 0px 5px #ffffff);
}

@keyframes playbook-dash {
  to {
    stroke-dashoffset: 0;
  }
}
</style>

<template>
  <path
    :d="d"
    stroke-width="2"
    stroke-linecap="round"
    class="vx-stroke-slate-300"
  />

  <!-- animated path -->
  <path
    v-if="animated"
    :d="d"
    stroke-width="6"
    stroke-linecap="round"
    stroke-dashoffset="300"
    :stroke-dasharray="props.strokeDasharray"
    class="playbook-dash vx-stroke-violet-400"
  />
</template>
