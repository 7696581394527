export default {
    computed: {
        /**
         * Access the global Spark object.
         */
        spark() {
            return window.Spark;
        },
    },
};
