<template>
    <div v-if="teamSubscription" class="alert alert-warning mb-4">
        Please <a :href="teamPaymentLink">confirm your payment</a> to activate your subscription!
    </div>
    <div v-else-if="subscriptionIsOnTrial" class="alert alert-warning mb-4">
        You are currently within your free trial period. Your trial will expire on <strong>{{ trialEndsAt }}</strong>
    </div>
</template>

<script>
import dayjs from '~/utils/dayjs';

export default {
    props: [
        'cashierPath',
        'teamSubscription',
        'teamLastPaymentId',
        'team',
        'activeSubscription',
    ],
    computed: {
        teamPaymentLink() {
            return `/${this.cashierPath}/payment/${this.teamLastPaymentId}?redirect=/home`;
        },
        onGenericTrial() {
            return this.team.trial_ends_at &&
                dayjs.utc(this.team.trial_ends_at).isAfter(dayjs.utc());
        },
        subscriptionIsOnTrial() {
            if (this.onGenericTrial) {
                return true;
            }

            return this.activeSubscription &&
                this.activeSubscription.trial_ends_at &&
                dayjs.utc().isBefore(dayjs.utc(this.activeSubscription.trial_ends_at));
        },
        trialEndsAt() {
            if (!this.subscriptionIsOnTrial) {
                return;
            }

            if (this.onGenericTrial) {
                return dayjs.utc(this.team.trial_ends_at).local().format('MMMM Do, YYYY');
            }

            return dayjs.utc(this.activeSubscription.trial_ends_at).local().format('MMMM Do, YYYY');
        },
    },
};
</script>
