<template>
    <div>
        <message-builder ref="messageBuilderRef"
            :modelValue="props.modelValue.message || ''"
            @update:modelValue="emitValue('message', $event)"
            :disableMedia="true"
            @blur="validation.message.$touch()"
            preview
            :error="displayFirstError(validation.message.$errors)"
            >
            <template v-if="props.modelValue.choices" v-slot:previewHeader>
                Preview
            </template>
            <template v-if="props.modelValue.choices" v-slot:previewSubheader>
                Some parts of this message are required and cannot be changed.
            </template>
            <template v-if="props.modelValue.choices" v-slot:previewBody>
                <div class="vx-flex vx-flex-col vx-gap-2">
                    <span>{{ props.modelValue.message || '' }}</span>
                    <div>
                        Choices are:
                        <ul>
                            <li v-for="(choice, index) in props.modelValue.choices" :key="`choice-${index}`">
                                - {{ choice }}
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </message-builder>
    </div>

    <dynamic-field-extraction
        :fieldType="props.modelValue.field_type"
        @update:fieldType="emitValue('field_type', $event)"
        :customAttribute="props.modelValue.custom_attribute"
        @update:customAttribute="emitValue('custom_attribute', $event)"
        :choices="props.modelValue.choices"
        @update:choices="emitValue('choices', $event)"
        :multipleChoices="props.modelValue.multiple_choices"
        @update:multipleChoices="emitValue('multiple_choices', $event)"
    ></dynamic-field-extraction>
</template>


<script setup>
import useVuelidate from '@vuelidate/core';
import { required,} from '@vuelidate/validators';
import { computed, ref } from 'vue';
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import DynamicFieldExtraction from '~/components/dynamic-fields/DynamicFieldExtraction.vue';
import { displayFirstError } from '~/utils/validation';
import { isDynamicFieldTypeFirstClass } from '~/constants/campaign';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => ({})
    }
});

const emit = defineEmits(['update:modelValue']);

const emitValue = (key, value) => {
    const data = {
        ...props.modelValue,
        [key]: value,
    };

    if (isDynamicFieldTypeFirstClass(data.field_type)) {
        delete data.custom_attribute;
    }

    emit('update:modelValue', data);
}

const messageBuilderRef = ref();

const validation = useVuelidate({
    message: {
        required,
    },
}, computed(() => props.modelValue));

defineExpose({
    focusMessage: () => messageBuilderRef.value?.textarea?.focus?.()
});

</script>
