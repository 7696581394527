<template>
    <vx-expandable-card
        toggleable
        id="who"
        :active="props.active"
    >
        <template v-slot:header>
            <span
                class="vx-pr-1.5 vx-mr-2 vx-border-y-0 vx-border-l-0 vx-border-r vx-border-solid sm:vx-pr-3 sm:vx-mr-4"
                :class="{
                    'vx-border-r-transparent': !editable,
                    'vx-border-r-slate-400': editable,
                }"
            >
                Who
            </span>
            <span
                v-if="editable && props.estimationLoading === false && props.estimation !== null"
                class="vx-text-xs vx-font-normal vx-text-slate-700 sm:vx-text-base sm:vx-font-semibold"
            >
                {{ formatCount(props.estimation) }} Recipients
                <span
                    @click.stop="emit('estimationModal')"
                    class="vx-text-xs vx-font-normal vx-cursor-pointer"
                >
                    (est
                    <font-awesome-icon :icon="faCircleInfo"></font-awesome-icon>
                    )
                </span>
            </span>
            <span v-else-if="editable" class="vx-text-xs vx-font-normal vx-text-slate-400 sm:vx-text-base sm:vx-font-semibold">
                Recipients
                <font-awesome-icon :icon="faSpinner" :spin="true"></font-awesome-icon>
            </span>
        </template>
        <template v-if="editable && !props.success" v-slot:headerRight>
            <vx-button
                @click.stop="emit('update:active', true)"
                color="muted"
                size="md"
            >
                <span class="vx-inline-block">Edit</span>
                <font-awesome-icon class="vx-text-base vx-hidden sm:vx-inline-block" :icon="faPencil"></font-awesome-icon>
            </vx-button>
        </template>
        <template v-slot:content>
            <vx-tabs
                v-model="currentTab"
                size="lg"
            >
                <vx-tab value="contacts">Number List</vx-tab>
                <vx-tab value="segments">Segments</vx-tab>
                <vx-tab value="everyone">Everyone</vx-tab>
            </vx-tabs>

            <quick-blast-who-manual
                v-if="currentTab === 'contacts'"
                :modelValue="props.modelValue.type === 'contacts' ? props.modelValue.data : []"
                @update:modelValue="
                    emit('update:modelValue', {
                        type: 'contacts',
                        data: $event,
                    })
                "
                @continue="confirm()"
            ></quick-blast-who-manual>

            <template
                v-if="currentTab === 'segments'"
            >
                <div class="vx-mt-4 vx-max-w-full lg:vx-mt-6">
                    <div class="vx-mb-4">
                        <span
                            class="vx-text-slate-900 vx-font-semibold vx-text-sm vx-block sm:vx-text-base"
                        >
                            Segments to receive this message
                        </span>
                        <span class="vx-text-slate-500 vx-text-xs">
                            Segments filtered to match the audience type <strong>{{ props.campaignType }}</strong> that you chose on the previous step.
                        </span>
                    </div>

                    <segments-selectable
                        size="lg"
                        :audience-type="props.campaignType"
                        :modelValue="props.modelValue.type === 'segments' ? props.modelValue.data : []"
                        @update:modelValue="
                            emit('update:modelValue', {
                                type: 'segments',
                                data: $event,
                            })
                        "
                    >
                    </segments-selectable>
                    <div
                        class="vx-text-xs vx-text-slate-500 vx-font-normal vx-mt-4 vx-flex vx-items-center"
                    >
                        <font-awesome-icon :icon="faCircleInfo" class="vx-text-gray vx-text-sm vx-mr-1"></font-awesome-icon>
                        <span class="vx-flex vx-flex-col vx-items-start sm:vx-block">
                            To edit or create segments, visit the&nbsp;
                            <span>
                                <a href="/contacts/segments" target="_blank" class="vx-text-inherit vx-underline"
                                >segments page</a>.
                            </span>
                        </span>
                    </div>

                    <confirm-button></confirm-button>
                </div>
            </template>

            <div v-if="currentTab === 'everyone'" class="vx-my-4 vx-max-w-full lg:vx-mt-6">
                <div class="vx-text-sm vx-p-4 lg:vx-px-36 lg:vx-py-10 lg:vx-text-base" data-test="who-everyone-message">
                    This message will go to all contacts eligible to receive <strong>{{ props.campaignType }}</strong> messages.
                </div>
                <confirm-button></confirm-button>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxExpandableCard, VxTab, VxTabs, VxButton } from '@voxie/frontend-components';
import { ref, computed, h } from 'vue';
import QuickBlastWhoManual from './QuickBlastWhoManual.vue';
import SegmentsSelectable from '../general/segments/SegmentsSelectable.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPencil, faSpinner, faCircleInfo, faArrowTurnDown } from '@fortawesome/pro-solid-svg-icons';
import { AUDIENCE_TYPE_MARKETING, AUDIENCE_TYPE_TRANSACTIONAL } from '../../constants/campaign';
import { formatCount } from '../filters';
import { useToasted } from '../../composables/useToasted';

const toasted = useToasted();

const confirmButton = () => {
    return h(
        VxButton,
        {
            onClick: () => confirm(),
            class: 'vx-mt-4 lg:vx-mt-6',
            size: 'lg',
            block: true,
            'data-test': 'who-confirm',
        },
        () => [
            'Continue',
            h(FontAwesomeIcon, { icon: faArrowTurnDown, class: 'vx-text-base', })
        ]
    )
}

const props = defineProps({
    modelValue: {
        type: Object,
        default: () => ({}),
    },
    active: {
        type: Boolean,
        default: false,
    },
    success: {
        type: Boolean,
        default: true,
    },
    estimation: {
        required: false,
    },
    estimationLoading: {
        type: Boolean,
        default: false,
    },
    campaignType: {
        type: String,
        default: undefined,
        validator: (campaignType) => [AUDIENCE_TYPE_MARKETING, AUDIENCE_TYPE_TRANSACTIONAL, undefined].includes(campaignType)
    }
});

const currentTab = ref(props.modelValue.type);

const editable = computed(() => !props.active && (hasConfirmed.value || props.modelValue.data?.length));
const hasConfirmed = ref(false);

const confirm = () => {
    if (currentTab.value === 'everyone') {
        emit('update:modelValue', { type: 'everyone', data: [] })
        emit('continue')
        hasConfirmed.value = true;
        return;
    }

    if (!props.modelValue.data?.length || props.modelValue.type !== currentTab.value) {
        toasted.error("You haven't added any " + currentTab.value);
        return;
    }
    hasConfirmed.value = true;
    emit("continue");
};

const emit = defineEmits([
    "update:active",
    "update:modelValue",
    "continue",
    "estimationModal",
]);
</script>
