<template>
    <component :is="open ? VxBox : 'div'"
        :class="{
            'vx-rounded-[16px] vx-border vx-border-solid vx-border-slate-300 vx-bg-slate-200': !open
        }"
        color="info"
        class="vx-py-4 lg:vx-py-6">
        <div class="vx-flex vx-items-center vx-justify-between vx-text-slate-800 vx-px-4 lg:vx-pl-9 lg:vx-pr-6">
            <div class="vx-flex vx-items-center vx-gap-4">
                <font-awesome-icon :icon="faScrewdriverWrench"
                    class="vx-text-base vx-ml-2" />

                <span class="vx-font-extrabold lg:vx-text-xl">Actions</span>
                <vx-badge color="primary"
                    size="xs"
                    data-test="filters-count">
                    {{ data.automated_actions.length }}
                </vx-badge>
            </div>

            <div>
                <vx-button @click="open = !open"
                    class="vx-w-12 vx-px-4 vx-ml-4"
                    color="muted-dark"
                    size="md"
                    type="button">
                    <font-awesome-icon :icon="open ? faCaretUp : faCaretDown"></font-awesome-icon>
                </vx-button>
            </div>
        </div>

        <div v-show="open"
            class="vx-mt-4 vx-px-4 vx-relative lg:vx-px-6">
            <div class="vx-absolute vx-left-8 vx-top-0 vx-w-px vx-h-full vx-bg-slate-300 lg:vx-left-16"></div>

            <automation-rule-form-action v-for="(action, actionIndex) in data.automated_actions"
                :key="action.id"
                v-model="data.automated_actions[actionIndex]"
                :actionIndex="actionIndex">
            </automation-rule-form-action>

            <div class="vx-flex vx-items-center vx-gap-4">
                <vx-box class="vx-relative vx-p-3 vx-inline-flex vx-space-x-2">
                    <vx-button @click="addAction()"
                        type="button"
                        size="lg"
                        color="primary"
                        data-test="add-action">
                        Then Do <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                    </vx-button>
                </vx-box>
            </div>
        </div>
        <div v-if="props.error" class="vx-relative vx-px-7 vx-pt-4 vx-text-xs vx-text-rose-800">
            {{ props.error }}
        </div>
    </component>
</template>


<script setup>
import { ref } from 'vue';
import { faCaretDown, faCaretUp, faScrewdriverWrench, faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBox, VxButton, VxBadge } from '@voxie/frontend-components';
import { useAutomationRule } from './useAutomationRule';
import AutomationRuleFormAction from './AutomationRuleFormAction.vue';

const props = defineProps({
    error: String,
});

const { data, addAction } = useAutomationRule();
const open = ref(true);

</script>
