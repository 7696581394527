export const CONTACT_SUBSCRIPTION_TYPE_TRANSACTIONAL = 'transactional';
export const CONTACT_SUBSCRIPTION_TYPE_MARKETING = 'marketing';

export const CONTACT_SUBSCRIPTION_STATUS_OPT_IN = 'opt_in';
export const CONTACT_SUBSCRIPTION_STATUS_OPT_OUT = 'opt_out';
export const CONTACT_SUBSCRIPTION_STATUS_UNKNOWN = 'unknown';

export const types = {
    [CONTACT_SUBSCRIPTION_TYPE_TRANSACTIONAL]: 'Transactional',
    [CONTACT_SUBSCRIPTION_TYPE_MARKETING]: 'Marketing',
};

export const statuses = {
    [CONTACT_SUBSCRIPTION_STATUS_OPT_IN]: 'Opted In',
    [CONTACT_SUBSCRIPTION_STATUS_OPT_OUT]: 'Opted Out',
    [CONTACT_SUBSCRIPTION_STATUS_UNKNOWN]: 'No Preference',
};

export const subscriptionStatusForSelect = () => {
    return Object.entries(statuses).map(([key, value]) => ({
        label: value,
        value: key,
    }));
};

export const computeSubscriptionsItems = (subscriptions) => {
    const items = [...(subscriptions ?? [])] || [];
    const hasMarketingSubscription = !!items.find((subscription) => subscription.type === 'marketing');
    const hasTransactionalSubscription = !!items.find((subscription) => subscription.type === 'transactional');

    if (!hasMarketingSubscription) {
        items.push({
            type: CONTACT_SUBSCRIPTION_TYPE_MARKETING,
            status: CONTACT_SUBSCRIPTION_STATUS_UNKNOWN,
        });
    }

    if (!hasTransactionalSubscription) {
        items.push({
            type: CONTACT_SUBSCRIPTION_TYPE_TRANSACTIONAL,
            status: CONTACT_SUBSCRIPTION_STATUS_UNKNOWN,
        });
    }

    return items.map((subscription) => ({
        key: types[subscription.type],
        value: statuses[subscription.status],
    }));
};
