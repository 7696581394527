<template>
    <div class="vx-relative">
        <vx-popover @click.stop
            :arrow="true"
            :hover="false"
            :placement="props.placement">
            <vx-badge size="xs"
                :color="experimental.color"
                :class="['vx-cursor-pointer', {
                    'vx-opacity-40': props.disabled
                }]">
                <span>
                    {{ experimental.label }}
                </span>
                <font-awesome-icon :icon="experimental.icon"></font-awesome-icon>
            </vx-badge>
            <template v-slot:content>
                <div class="vx-flex vx-flex-col vx-font-sans vx-p-2 lg:vx-w-[286px]">
                    <div class="vx-font-sans vx-text-slate-700 vx-text-lg vx-font-extrabold vx-mb-1">
                        <font-awesome-icon :icon="experimental.icon"
                            class="vx-mr-1"></font-awesome-icon> {{ experimental.label }} Feature
                    </div>
                    <p class="vx-font-sans vx-text-slate-700 !vx-text-xs vx-mb-3 vx-font-normal">
                        This feature is still in the testing phase. We encourage you to <strong>provide feedback</strong> or
                        <strong>report issues</strong> so we can make your experience better!
                    </p>
                    <vx-button color="info"
                        size="sm"
                        :href="`mailto:support@voxie.com?subject=${subject}`"
                        target="_blank"
                        rel="noopener noreferrer">
                        Submit Feedback
                        <font-awesome-icon :icon="faMessageLines"></font-awesome-icon>
                    </vx-button>
                </div>
            </template>
        </vx-popover>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFlask, faMessageLines, faVialVirus } from '@fortawesome/pro-solid-svg-icons';
import { VxBadge, VxPopover, VxButton } from '@voxie/frontend-components';
import { computed } from 'vue';

const props = defineProps({
    subject: String,
    placement: {
        type: String,
        default: 'top',
    },
    type: {
        type: String,
        default: 'beta',
    },
    disabled: Boolean,
});


const experimental = computed(() => {
    if (props.type === 'alpha') {
        return {
            color: 'fuchsia',
            label: 'Alpha',
            icon: faVialVirus,
        }
    }

    return {
        color: 'amber',
        label: 'Beta',
        icon: faFlask,
    }
})

const subject = computed(() => encodeURIComponent(props.subject || `${experimental.value.label} Feedback`));
</script>
