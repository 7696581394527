<template>
    <div class="card card-default" data-test="contact-information" v-if="user && !user.isUsingSingleSignOn">
        <div class="card-header">Contact Information</div>

        <div class="card-body">
            <!-- Success Message -->
            <div class="alert alert-success" v-if="form.successful">
                Your contact information has been updated!
            </div>

            <form role="form">
                <!-- Name -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">
                        Name
                    </label>

                    <div class="col-md-6">
                        <input
                            type="text"
                            class="form-control"
                            name="name"
                            v-model="form.name"
                            :class="{ 'is-invalid': form.errors.has('name') }"
                        />

                        <span
                            class="invalid-feedback"
                            v-show="form.errors.has('name')"
                        >
                            {{ form.errors.get("name") }}
                        </span>
                    </div>
                </div>

                <!-- Email Address -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">
                        Email Address
                    </label>

                    <div class="col-md-6">
                        <input
                            type="email"
                            class="form-control"
                            name="email"
                            v-model="form.email"
                            :class="{ 'is-invalid': form.errors.has('email') }"
                        />

                        <span
                            class="invalid-feedback"
                            v-show="form.errors.has('email')"
                        >
                            {{ form.errors.get("email") }}
                        </span>
                    </div>
                </div>

                <!-- Update Button -->
                <div class="form-group row mb-0">
                    <div class="col-md-6 offset-md-4">
                        <button
                            type="submit"
                            class="btn btn-primary"
                            @click.prevent="update"
                            :disabled="form.busy"
                        >
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="card card-default" data-test="contact-information" v-if="user && user.isUsingSingleSignOn">
        <div class="card-header">Contact Information</div>

        <div class="card-body">
            <form role="form">
                <!-- Name -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">
                        Name
                    </label>

                    <div class="col-md-6">
                        <input
                            type="text"
                            class="form-control"
                            name="name"
                            readonly
                            v-model="form.name"
                        />
                    </div>
                </div>

                <!-- Email Address -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">
                        Email Address
                    </label>

                    <div class="col-md-6">
                        <input
                            type="email"
                            class="form-control"
                            name="email"
                            readonly
                            v-model="form.email"
                        />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: ["user"],

    data() {
        return {
            form: new SparkForm({
                name: "",
                email: "",
            }),
        };
    },

    mounted() {
        this.form.name = this.user.name;
        this.form.email = this.user.email;

        Bus.$on(`sparkFormUpdated.${this.form.sparkFormId}`, this.$forceUpdate)
    },

    methods: {
        update() {
            Spark.put("/settings/contact", this.form).then(() => {
                Bus.$emit("updateUser");
            });
        },
    },
};
</script>
