<template>
    <vx-modal
        :visible="!!tag"
        @update:visible="close"
        :padding="false"
        size="md"
    >
        <template v-slot:header>
            <div class="vx-px-6 vx-pt-6">
                Are you sure you want to delete this tag?
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">
                This tag and it's association to contacts will be deleted. There is no undo.
            </div>
        </template>
        <div class="vx-p-6">
            <div v-if="loadingTagUsage">
                <vx-icon-loading class="vx-w-9 vx-h-9" :spin="true"></vx-icon-loading>
            </div>
            <vx-alert v-else-if="failedToLoadTagUsage" color="danger" :closeable="false">
                Failed to load data.
                <a href="#" @click.prevent="loadTagUsage()">Retry.</a>
            </vx-alert>
            <vx-alert v-else :color="segmentCount ? 'info' : 'primary'" :closeable="false" >
                {{ tagUsageText }}
            </vx-alert>
        </div>
        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="close"
                    size="lg"
                    color="muted">
                    Cancel
                </vx-button>
                <vx-button @click="deleteTag"
                    data-test="del-btn"
                    :disabled="loadingTagUsage"
                    type="button"
                    size="lg"
                    color="danger"
                    class="lg:vx-grow">
                    Delete
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script>
import { VxAlert, VxModal, VxButton, VxIconLoading } from '@voxie/frontend-components';
import { segmentsClient, tagsClient } from '../../../services';

export default {
    components: {
        VxAlert,
        VxModal,
        VxButton,
        VxIconLoading,
    },
    props: {
        tag: Object,
    },
    data() {
        return {
            teamId: Spark.state.currentTeam.id,
            loadingTagUsage: true,
            failedToLoadTagUsage: false,
            segmentCount: null,
            segmentNames: [],
        };
    },
    computed: {
        tagUsageText() {
            const { segmentCount, segmentNames } = this;
            if (segmentCount === null) {
                return '';
            }

            if (!segmentCount) {
                return 'The tag is not used in any segments.';
            }

            let label = 'The tag is used in ' + segmentCount + ' segment';
            if (segmentCount != 1) {
                label += 's';
            }
            label += ': ' + segmentNames.join(', ');
            if (segmentNames.length < segmentCount) {
                label += ', etc.';
            } else {
                label += '.';
            }

            return label;
        },
    },
    watch: {
        tag(val) {
            if (val) {
                this.loadTagUsage();
            }
        },
    },
    methods: {
        async loadTagUsage() {
            const tagName = this.tag?.tag_name;
            if (!tagName) {
                return;
            }

            this.loadingTagUsage = true;
            this.failedToLoadTagUsage = false;
            try {
                const { data } = await segmentsClient.getSegments(this.teamId, {
                    'filter[tag_name]': tagName,
                    per_page: 5,
                });

                const { data: dataCount } = await segmentsClient.getSegmentsCount(this.teamId, {
                    'filter[tag_name]': tagName,
                })

                this.segmentCount = dataCount.count;
                this.segmentNames = data.data.map(segment => segment.name);
                this.failedToLoadTagUsage = false;
            } catch(e) {
                this.failedToLoadTagUsage = true;
            } finally {
                this.loadingTagUsage = false;
            }
        },
        close() {
            this.$emit('update:tag', null);
        },
        deleteTag() {
            tagsClient.tagDelete(this.teamId, this.tag.tag_name).then(() => {
                this.$emit('deleted', this.tag.tag_name);
                this.close();
            }).catch((error) => {
                console.error(error);
                this.$toasted.global.platform_error();
            });


        },
    },
};
</script>
