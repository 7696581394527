<template>
    <div class="vx-inline-flex vx-items-center vx-gap-2" data-test="campaign-date">
        <span data-test="campaign-date">{{ text }}</span>

        <vx-popover v-if="props.modelValue.time_zone_aware && text" hover placement="top" arrow>
            <vx-badge size="xs" color="slate">
                <font-awesome-icon :icon="faWatchSmart"></font-awesome-icon>
            </vx-badge>
            <template v-slot:content>
                <div class="vx-font-sans vx-max-w-80">
                    <strong>Time-Zone Aware Start</strong> <br />
                    {{ timeZoneStartMessage }} The first message will be delayed to match {{ liveAtTime }} for the <strong>contact’s time-zone</strong> if possible.
                </div>
            </template>

        </vx-popover>
    </div>
</template>

<script setup>
import { VxPopover, VxBadge } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { computed } from 'vue';
import { getClientTz, toShortDateTimeRelativeDays } from '../../../utils/date';
import { faWatchSmart } from '@fortawesome/pro-solid-svg-icons';
import dayjs from '~/utils/dayjs';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

const text = computed(() => {
    const {
        status,
        duration_type,
        live_at,
        started_at,
        complete_at,
        time_zone_aware
    } = props.modelValue;

    if (status === 'draft') return '';

    const timezone = time_zone_aware ? 'America/New_York' : getClientTz();
    const initialDate = time_zone_aware ? (live_at || started_at) : (started_at || live_at);

    if (duration_type === 'one_time') {
        return toShortDateTimeRelativeDays(initialDate, timezone, !time_zone_aware);
    } else if (duration_type === 'predefined') {
        return toShortDateTimeRelativeDays(initialDate, timezone, !time_zone_aware) + " -> " + toShortDateTimeRelativeDays(complete_at)
    } else if (duration_type === 'evergreen') {
        return toShortDateTimeRelativeDays(initialDate, timezone, !time_zone_aware) + " -> Forever";
    }

    return '';
});

const liveAtDayjs = computed(() => dayjs.utc(props.modelValue.live_at).tz('America/New_York'));
const liveAtTime = computed(() => liveAtDayjs.value.format('h:mm a'));

const timeZoneStartMessage = computed(() => {
    if (props.modelValue.status === 'draft' || props.modelValue.status === 'scheduled') {
        if (!props.modelValue.live_at || (props.modelValue.live_at && liveAtDayjs.value.isBefore())) {
            return 'The campaign will start right away.';
        }

        return `The campaign will start at ${ liveAtDayjs.value.format('h:mm a z') }.`;
    }

    return 'The campaign has started sending.';
});
</script>
