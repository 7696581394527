<template>
    <div class="respond-to-next-v2">
        <div class="respond-to-next-v2__unread-wrap">
            <vx-icon name="wave" :size="24" color="white"></vx-icon>
            <span class="respond-to-next-v2__unread"> {{ count }} New Unassigned </span>
        </div>
        <div class="respond-to-next-actions-v2__wrap">
            <vx-button appearance="light" size="medium" @click="emit('dismissRespondToNext')">Dismiss</vx-button>
            <vx-button appearance="primary-no-border" size="medium" :has-shadow="true" @click="emit('respondToNext')"
                >Respond</vx-button
            >
        </div>
    </div>
</template>

<script setup>
import { VxIcon, VxButton } from '@voxie/frontend-components-v3';

defineProps({
    count: {
        type: [String, Number],
        default: 0,
    },
});

const emit = defineEmits(['dismissRespondToNext', 'respondToNext']);
</script>

<style lang="postcss">
.respond-to-next-v2 {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0.5rem;
  background: var(--color-primary);
}
.respond-to-next-v2 .respond-to-next-v2__unread-wrap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.respond-to-next-v2 .respond-to-next-v2__unread-wrap .respond-to-next-v2__unread {
  font-family: var(--font-dm-sans);
  font-size: 14px;
  color: var(--color-white);
}
.respond-to-next-v2 .respond-to-next-actions-v2__wrap {
  display: flex;
  gap: 1rem;
}
.respond-to-next-v2 .respond-to-next-actions-v2__wrap button {
  border: none;
}
.respond-to-next-v2 .respond-to-next-actions-v2__wrap button:hover {
  opacity: 1;
}
.respond-to-next-v2 .respond-to-next-actions-v2__wrap button:hover span {
  opacity: 0.8;
}
</style>
