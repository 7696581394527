<template>
    <div
        class="vx-flex vx-w-full vx-items-center vx-justify-between vx-text-slate-800 vx-py-3 lg:vx-py-0"
        :class="{
            'vx-opacity-60 vx-cursor-not-allowed': props.disabled
        }"
    >
        <div class="vx-flex vx-flex-col vx-w-[calc(100%-5rem)] vx-justify-between lg:vx-w-auto lg:vx-items-center lg:vx-flex-row">
            <div class="vx-truncate vx-w-full lg:vx-w-60" :title="props.segment.name">
                {{ props.segment.name }}
            </div>
            <div class="lg:vx-w-40 lg:vx-shrink-0">
                <font-awesome-icon
                    :icon="props.segment.type === SegmentType.STATIC ? faMapPin : faLoader"
                    class="vx-text-slate-500 vx-mr-1"
                ></font-awesome-icon>
                {{ formatSegmentType(props.segment.type) }}
            </div>
            <div class="lg:vx-w-14 lg:vx-shrink-0" data-test="segment-row-contact-count">
                <template v-if="props.contactsCount !== null && props.contactsCount !== undefined">
                    <font-awesome-icon
                        :icon="faUser"
                        class="vx-mr-1 vx-text-slate-500 lg:vx-hidden"
                    >
                    </font-awesome-icon>
                    {{ formatCount(props.contactsCount) }}
                </template>
                <font-awesome-icon v-else :icon="faSpinnerThird" spin>
                </font-awesome-icon>
            </div>
        </div>
        <div class="vx-w-20 vx-text-right vx-shrink-0">
            <vx-button
                v-if="props.selected"
                type="button"
                color="muted"
                size="xs"
                :disabled="props.disabled"

            >
                Remove
            </vx-button>
            <vx-button
                v-else
                type="button"
                color="muted"
                size="xs"
                :disabled="props.disabled"
            >
                Add
                <font-awesome-icon
                    class="vx-text-xs vx-ml-1"
                    :icon="faPlusCircle"
                ></font-awesome-icon>
            </vx-button>
        </div>
    </div>
</template>

<script setup>
import { VxButton } from '@voxie/frontend-components';
import { SegmentType, SegmentTypeToLabel } from '../../../constants/segment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faPlusCircle,
    faMapPin,
    faUser
} from "@fortawesome/pro-solid-svg-icons";
import { faLoader, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

const props = defineProps({
    segment: {
        type: Object,
        required: true,
    },
    contactsCount: {
        type: Number,
        required: false,
    },
    selected: {
        type: Boolean,
        required: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    }
})

const formatCount = (number) => {
    return number < 1000 ? Intl.NumberFormat('en').format(number) : Intl.NumberFormat('en', { notation: 'compact', minimumSignificantDigits: 3, maximumSignificantDigits: 3 }).format(number);
}
const formatSegmentType = (type) => {
    return SegmentTypeToLabel[type];
}

</script>
