import { ref } from 'vue';
import authConfigsClient from '../../../../services/authConfigsClient';
import { HTTP_NOT_FOUND } from '../../../../support/HttpStatuses';
import { createGlobalState } from '@vueuse/core';

export const useAuthConfigs = createGlobalState(() => {
    const configs = ref([]);

    const getting = ref(false);

    const get = async (teamId, authConfigId) => {
        return new Promise((resolve, reject) => {
            // when its currently making a request call wait a bit before retrieving again
            if (getting.value) {
                setTimeout(() => resolve(get(teamId, authConfigId)), 100);
                return;
            }

            // retrieves from memory
            const existing = configs.value.find(
                (config) => config.team_id == teamId && config.auth_config_id == authConfigId
            );

            if (existing) {
                resolve(existing);
                return;
            }

            // in case doesn't exist make api call
            getting.value = true;
            authConfigsClient
                .get(teamId, authConfigId)
                .then((response) => {
                    configs.value.push(response.data);
                    resolve(response.data);
                })
                .catch((e) => {
                    console.error(e);
                    if (e.response && e.response.status === HTTP_NOT_FOUND) {
                        resolve(null);
                    } else {
                        reject(e);
                    }
                })
                .finally(() => {
                    getting.value = false;
                });
        });
    };

    return {
        get,
    };
});
