import { getClientTz } from '~/utils/date';
import dayjs, { toUTCDateTimeFormat } from '~/utils/dayjs';

const HOUR_PERCENTAGE = 4.1667;
const MINUTE_PERCENTAGE = 0.0695;

function backgroundOffset(selectedDateTime, relativeUtcOffset, relativeZone, checkIfPassed) {
    const utcOffset = dayjs().tz(getClientTz()).utcOffset() / 60;

    const computeOffset = (dayjsInstance) =>
        -relativeUtcOffset * HOUR_PERCENTAGE +
        (12 - dayjsInstance.get('hours')) * HOUR_PERCENTAGE -
        dayjsInstance.get('minutes') * MINUTE_PERCENTAGE +
        utcOffset * HOUR_PERCENTAGE +
        HOUR_PERCENTAGE;

    if (
        timeHasPassed(selectedDateTime, getClientTz()) &&
        timeHasPassed(selectedDateTime, relativeZone) &&
        checkIfPassed
    ) {
        return computeOffset(dayjs().tz(getClientTz()));
    }

    return computeOffset(dayjs.tz(selectedDateTime, getClientTz()));
}

function markerOffset(selectedDateTime, relativeUtcOffset, relativeZone, checkIfPassed) {
    const selectedDayjs = dayjs(selectedDateTime).tz(getClientTz());
    const selectedUtcOffset = selectedDayjs.utcOffset() / 60;

    const relativeOffset = 50 + selectedUtcOffset * HOUR_PERCENTAGE - relativeUtcOffset * HOUR_PERCENTAGE;

    // if time has passed, we need to put the marker at now
    if (timeHasPassed(selectedDateTime, relativeZone) && checkIfPassed) {
        return 50;
    }

    // else return the shift the marker position based on relative offset
    return relativeOffset;
}

const timeHasPassed = (selectedDateTime, comparingZone) => {
    const selectedDayjs = dayjs.tz(selectedDateTime, getClientTz());

    const comparingZoneDayjs = selectedDayjs
        .clone()
        .tz(comparingZone)
        .set('hours', selectedDayjs.get('hours'))
        .set('minutes', selectedDayjs.get('minutes'));

    return comparingZoneDayjs.isBefore();
};

const abbrs = {
    EST: 'Eastern',
    EDT: 'Eastern',
    CST: 'Central',
    CDT: 'Central',
    MST: 'Mountain',
    MDT: 'Mountain',
    PST: 'Pacific',
    PDT: 'Pacific',
    HST: 'Hawaii',
    HAST: 'Hawaii',
    HDT: 'Hawaii',
    HADT: 'Hawaii',
    AKST: 'Alaska',
    AKDT: 'Alaska',
};

const timeZones = [
    // 'America/Adak',
    'America/Anchorage',
    'America/Boise',
    'America/Chicago',
    'America/Denver',
    'America/Detroit',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Los_Angeles',
    'America/Menominee',
    'America/Metlakatla',
    'America/New_York',
    'America/Nome',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    // 'America/Phoenix',
    'America/Sitka',
    // 'America/Yakutat',
    'Pacific/Honolulu',
];

function sortTimeZones(a, b) {
    if (a.utcOffset === b.utcOffset) {
        return a.value.localeCompare(b.value);
    }
    return b.utcOffset - a.utcOffset;
}

const displayZones = (date) => {
    return timeZones
        .reduce((zones, zone) => {
            const existingZone = zones.find((z) => z.value === dayjs.tz(date, zone).format('z'));
            if (!existingZone) {
                zones.push({
                    label: abbrs[dayjs.tz(date, zone).format('z')],
                    value: dayjs.tz(date, zone).format('z'),
                    utcOffset: dayjs.tz(date, zone).utcOffset() / 60,
                    zone: zone,
                });
            }
            return zones;
        }, [])
        .sort(sortTimeZones);
};

/**
 * @returns {string}
 */
const clientTzToAmericaNewYorkUTC = (date) => {
    if (!date) {
        return null;
    }

    const clientTz = dayjs.tz(date, getClientTz());

    const newYork = dayjs
        .tz(date, 'America/New_York')
        .set('hours', clientTz.get('hours'))
        .set('minutes', clientTz.get('minutes'));

    return toUTCDateTimeFormat(newYork);
};

/**
 * @returns {Date}
 */
const UTCNewYorkToClientTz = (date) => {
    if (!date) {
        return null;
    }

    const newYork = dayjs.utc(date).tz('America/New_York');

    const clientTz = dayjs
        .utc(date)
        .tz(getClientTz())
        .set('hours', newYork.get('hours'))
        .set('minutes', newYork.get('minutes'));

    return clientTz.toDate();
};

export {
    HOUR_PERCENTAGE,
    MINUTE_PERCENTAGE,
    backgroundOffset,
    markerOffset,
    timeHasPassed,
    displayZones,
    clientTzToAmericaNewYorkUTC,
    UTCNewYorkToClientTz,
};
