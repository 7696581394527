<template>
    <form role="form">
        <template v-if="onlyTeamPlans">
            <!-- Team Name -->
            <div class="form-group row" v-if="!invitation">
                <label class="col-md-4 col-form-label text-md-right">Team Name</label>

                <div class="col-md-6">
                    <input type="text" class="form-control" name="team" v-model="registerForm.team" :class="{'is-invalid': registerForm.errors.has('team')}" autofocus>

                    <span class="invalid-feedback" v-show="registerForm.errors.has('team')">
                        {{ registerForm.errors.get('team') }}
                    </span>
                </div>
            </div>
        </template>

        <!-- Name -->
        <div class="form-group row">
            <label class="col-md-4 col-form-label text-md-right">Name</label>

            <div class="col-md-6">
                <input type="text" class="form-control" name="name" v-model="registerForm.name" :class="{'is-invalid': registerForm.errors.has('name')}" autofocus>

                <span class="invalid-feedback" v-show="registerForm.errors.has('name')">
                    {{ registerForm.errors.get('name') }}
                </span>
            </div>
        </div>

        <!-- Email Address -->
        <div class="form-group row">
            <label class="col-md-4 col-form-label text-md-right">Email Address</label>

            <div class="col-md-6">
                <input type="email" class="form-control" name="email" v-model="registerForm.email" :class="{'is-invalid': registerForm.errors.has('email')}">

                <span class="invalid-feedback" v-show="registerForm.errors.has('email')">
                    {{ registerForm.errors.get('email') }}
                </span>
            </div>
        </div>

        <!-- Password -->
        <div class="form-group row">
            <label class="col-md-4 col-form-label text-md-right">Password</label>

            <div class="col-md-6">
                <input type="password" class="form-control" name="password" v-model="registerForm.password" :class="{'is-invalid': registerForm.errors.has('password')}">

                <span class="invalid-feedback" v-show="registerForm.errors.has('password')">
                    {{ registerForm.errors.get('password') }}
                </span>
            </div>
        </div>

        <!-- Password Confirmation -->
        <div class="form-group row">
            <label class="col-md-4 col-form-label text-md-right">Confirm Password</label>

            <div class="col-md-6">
                <input type="password" class="form-control" name="password_confirmation" v-model="registerForm.password_confirmation" :class="{'is-invalid': registerForm.errors.has('password_confirmation')}">

                <span class="invalid-feedback" v-show="registerForm.errors.has('password_confirmation')">
                    {{ registerForm.errors.get('password_confirmation') }}
                </span>
            </div>
        </div>

        <!-- Terms And Conditions -->
        <div v-if="!selectedPlan || selectedPlan.price == 0">
            <div class="form-group row">
                <div class="col-md-6 offset-md-4">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="terms" :class="{'is-invalid': registerForm.errors.has('terms')}" v-model="registerForm.terms">
                        <label class="form-check-label" for="terms">
                            I Accept <a href="/terms" target="_blank">The Terms Of Service</a>
                        </label>
                        <div class="invalid-feedback" v-show="registerForm.errors.has('terms')">
                            <strong>{{ registerForm.errors.get('terms') }}</strong>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-6 offset-md-4">
                    <div :class="{'is-invalid': registerForm.errors.has('recaptcha')}">
                        <google-recaptcha v-model="registerForm.recaptcha" />
                    </div>
                    <div class="invalid-feedback" v-show="registerForm.errors.has('recaptcha')">
                        {{ registerForm.errors.get('recaptcha') }}
                    </div>
                </div>
            </div>

            <div class="form-group row mb-0">
                <div class="col-md-6 offset-md-4">
                    <button class="btn btn-primary" @click.prevent="$emit('register')" :disabled="registerForm.busy">
                        <span v-if="registerForm.busy">
                            <i class="fa fa-btn fa-spinner fa-spin"></i> Registering
                        </span>

                        <span v-else>
                            <i class="fa fa-btn fa-check-circle"></i> Register
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import GoogleRecaptcha from '../general/GoogleRecaptcha.vue';

export default {
    props: {
        teams: Object,
        invitation: Object,
        registerForm: Object,
        selectedPlan: Object,
        onlyTeamPlans: Boolean,
    },
    components: {
        GoogleRecaptcha,
    },
};
</script>
