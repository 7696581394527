<template>
    <div class="c-dropdown dropdown">
        <button class="c-btn c-btn--secondary has-dropdown dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false" :disabled="shouldBeDisabled">
            <i class="fa fa-spin fa-circle-o-notch" v-if="pending"></i>
            {{ displayLabel }}
        </button>

        <div class="c-dropdown__menu dropdown-menu"
             x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 39px, 0px); top: 0px; left: 0px; will-change: transform;">

            <slot></slot>

        </div>
    </div>
</template>


<script>
    export default {
        props: ['label', 'updateEvent', 'isDisabled'],

        data() {
            return {
                pending: false,
                disabled: false,
                displayLabel: null
            }
        },

        watch: {
            isDisabled: function (newData) {
                this.disabled = newData;
            }
        },

        created() {
            this.displayLabel = this.label;
            this.disabled = this.isDisabled;

            Bus.$on(this.updateEvent, (updating) => {
                this.pending = updating;
                if(updating){
                    this.displayLabel = 'Loading';
                } else {
                    this.displayLabel = this.label;
                }
            });
        },

        computed: {
            shouldBeDisabled(){
                return this.pending || this.disabled;
            }
        }
    }
</script>
