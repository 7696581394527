<template>
    <div class="vx-flex vx-flex-col vx-inset-0 vx-h-full">
        <conversation-nav class="vx-gap-2">
            <vx-button color="transparent" outline @click="emit('close')">
                <font-awesome-icon :icon="faCaretLeft" size="2xl" inverse />
            </vx-button>
            <div class="vx-flex vx-flex-col vx-items-start">
                <experimental-pill type="alpha"></experimental-pill>
                <span class="vx-text-xl vx-text-white vx-font-semibold">Contact Details</span>
            </div>
        </conversation-nav>
        <div class="vx-flex vx-flex-col vx-gap-2 vx-flex-grow vx-p-4 vx-overflow-y-auto">
            <div class="vx-flex vx-items-center vx-gap-2">
                <contact-avatar :contact="props.modelValue" />
                <div class="vx-flex vx-items-center vx-gap-2">
                    <vx-input :modelValue="contactFullName" @update:modelValue="contactName = $event"
                        placeholder="Enter contact name"></vx-input>
                    <vx-button v-if="false !== dirtyName" color="primary" @click="updateContactName">
                        <font-awesome-icon v-if="!loading" :icon="faCheck" size="xl" />
                        <vx-icon-loading v-else spin />
                    </vx-button>
                </div>
            </div>
            <hr />
            <div class="vx-flex vx-flex-col vx-items-start">
                <span class="vx-text-xs">Phone: <strong>{{ phoneNumberNational(props.modelValue.phone)
                        }}</strong></span>
                <span class="vx-text-xs">Email: <strong>{{ props.modelValue.email || '-' }}</strong></span>
                <span class="vx-text-xs">Date Added: <strong>{{ date(props.modelValue.created_at) }}</strong></span>
                <span class="vx-text-xs">Entry Point: <strong>{{ props.modelValue.entry_point === undefined ? '-' :
                entryPoint(props.modelValue.entry_point) }}</strong></span>
            </div>
            <hr />
            <div class="vx-flex vx-flex-col vx-items-start">
                <span class="vx-text-sm vx-font-semibold vx-mb-2">Subscription Statuses</span>
                <span v-for="subscription in subscriptionItems" :key="subscription.key" class="vx-text-xs">
                    {{ subscription.key }}: <strong>{{ subscription.value }}</strong>
                </span>
            </div>
            <hr />
            <div class="vx-flex vx-flex-col vx-items-start">
                <span class="vx-text-sm vx-font-semibold vx-mb-2">Custom Attributes</span>
                <template v-if="customAttributes.length">
                    <span v-for="customAttribute in customAttributes" :key="customAttribute.id" class="vx-text-xs">
                        {{ customAttribute.key }}: <strong>{{ customAttribute.value }}</strong>
                    </span>
                </template>
                <span v-else class="vx-text-xs">None</span>
            </div>
            <hr />
            <div class="vx-flex vx-flex-col vx-items-start">
                <div class="vx-flex vx-items-center vx-justify-between vx-w-full">
                    <span class="vx-text-sm vx-font-semibold vx-mb-2">Tags</span>
                    <vx-button @click="showAddTagModal = true" size="xs" color="transparent" outline>
                        <font-awesome-icon :icon="faPlusCircle" />
                    </vx-button>
                </div>
                <template v-if="tags.length">
                    <div class="vx-flex vx-items-center vx-flex-wrap vx-gap-1">
                        <vx-badge v-for="tag in tags" :key="tag.tag_name" :borders="false" size="xs">
                            {{ tag.tag_name }}
                            <font-awesome-icon class="vx-cursor-pointer" :icon="faXmarkCircle" @click.stop="removeTag(tag.tag_name)"/>
                        </vx-badge>
                    </div>
                </template>
                <span v-else class="vx-text-xs">None</span>
            </div>
            <hr />
            <div v-if="FEATURES.contact_groups" class="vx-flex vx-flex-col vx-items-start">
                <span class="vx-text-sm vx-font-semibold vx-mb-2">Groups</span>
                <template v-if="customAttributes.length">
                    <span v-for="customAttribute in customAttributes" :key="customAttribute.id" class="vx-text-xs">
                        {{ customAttribute.key }}: <strong>{{ customAttribute.value }}</strong>
                    </span>
                </template>
                <span v-else class="vx-text-xs">None</span>
            </div>
            <hr v-if="FEATURES.contact_groups" />
            <div class="vx-flex vx-flex-col vx-items-start">
                <span class="vx-text-sm vx-font-semibold vx-mb-2">Segments</span>
                <template v-if="segments.length">
                    <span v-for="segment in segments" :key="segment.id" class="vx-text-xs">
                        <strong>{{ segment.name }}</strong>
                    </span>
                </template>
                <span v-else class="vx-text-xs">None</span>
            </div>
            <hr />
            <div v-if="FEATURES.promotions" class="vx-flex vx-flex-col vx-items-start">
                <span class="vx-text-sm vx-font-semibold vx-mb-2">Promotions</span>
                <template v-if="promotionCoupons.length">
                    <span v-for="promotionCoupon in promotionCoupons" :key="promotionCoupon.id" class="vx-text-xs">
                        <strong>{{ promotionCoupon.promotion.name }}</strong>
                    </span>
                </template>
                <span v-else class="vx-text-xs">None</span>
            </div>
        </div>
        <conversation-add-tag-modal v-model:visible="showAddTagModal" :contact="props.modelValue" @tagged="syncTags" />
    </div>
</template>
<script setup>
import { computed, ref, watch, inject } from 'vue';
import { phoneNumberNational } from '~/components/filters';
import { VxButton, VxInput, VxIconLoading, VxBadge } from '@voxie/frontend-components';
import { faCaretLeft, faCheck, faXmarkCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ConversationNav from '~/components/messages/browser-extension/components/ConversationNav.vue';
import ConversationAddTagModal from '~/components/messages/browser-extension/components/misc/ConversationAddTagModal.vue';
import ContactAvatar from '~/components/contacts/shared/ContactAvatar.vue';
import { contactsClient } from '~/services';
import { date, entryPoint } from '~/components/filters';
import { computeSubscriptionsItems } from '~/constants/contactSubscription';
import axios from '~/services/axios';
import { useToasted } from '~/composables/useToasted';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_NOT_FOUND } from "~/support/HttpStatuses";
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';

const $toasted = useToasted();

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    }
});

const FEATURES = inject('FEATURES')

const emit = defineEmits(['close', 'update:modelValue']);

const teamId = Spark.state.currentTeam.id;

const contactFullName = computed(() => {
    const fullName = `${props.modelValue.first_name || ''} ${props.modelValue.last_name || ''}`.trim();

    return fullName || '';
});
const contactName = ref(contactFullName.value);
const dirtyName = computed(() => contactName.value !== contactFullName.value);

const loading = ref(false);
const updateContactName = async () => {
    if (loading.value) {
        return
    }

    loading.value = true;
    try {
        let parts = contactName.value.split(' ')
        let firstName = parts.shift();
        let lastName = parts.join(' ')

        const payload = {
            ...props.modelValue,
            first_name: firstName,
            last_name: lastName
        }

        await contactsClient.contactUpdate(teamId, props.modelValue.id, payload, { signal: contactTagsListAbort.signal });

        emit('update:modelValue', payload)
    } catch (e) {
        console.error(e)
    } finally {
        loading.value = false;
    }
}

watch(() => props.modelValue, () => {
    contactName.value = contactFullName.value;
});

const subscriptionItems = computed(() => computeSubscriptionsItems(props.modelValue.subscriptions || []));

let contactTagsListAbort;
let contactCustomAttributesListAbort;
let getContactSegmentsAbort;
let listContactGroupsAbort;
let listPromotionCouponsAbort;

const showAddTagModal = ref(false);
const tags = ref([]);
const fetchTags = async () => {
    contactTagsListAbort?.abort()
    contactTagsListAbort = new AbortController();

    try {
        const response = await contactsClient.contactTagsList(teamId, props.modelValue.id, { signal: contactTagsListAbort.signal });

        tags.value = response.data.data.map((t) => {
            t.tag_name = t.tag_name.toLowerCase();
            return t;
        });
    } catch (e) {
        if (!axios.isCancel(e)) {
            throw e;
        }
    }
};

const syncTags = async (tag) => {
    if (!tags.value.find((t) => t.tag_name === tag.tag_name)) {
        tags.value.push(tag);
    }
}

const removeTag = async (tagName) => {
    try {
        await contactsClient.contactDeleteTag(teamId, props.modelValue.id, tagName.toLowerCase());
        tags.value = tags.value.filter((tag) => tag.tag_name !== tagName);
    } catch (e) {
        console.error(e);
        if (e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            $toasted.global.platform_error();
        } else if (e.response.status === HTTP_NOT_FOUND) {
            tags.value = tags.value.filter((tag) => tag.tag_name !== tagName);
        } else {
            $toasted.error(e.response?.data?.message || 'Something went wrong.');
        }
    }
}

const customAttributes = ref([]);
const fetchCustomAttributes = async () => {
    contactCustomAttributesListAbort?.abort()
    contactCustomAttributesListAbort = new AbortController();

    try {
        const response = await contactsClient.contactCustomAttributesList(teamId, props.modelValue.id, { signal: contactCustomAttributesListAbort.signal })
        customAttributes.value = response.data.data;
    } catch (e) {
        if (!axios.isCancel(e)) {
            throw e;
        }
    }
};

const segments = ref([]);
const fetchSegments = async () => {
    getContactSegmentsAbort?.abort()
    getContactSegmentsAbort = new AbortController();

    try {
        const response = await contactsClient.getContactSegments(teamId, props.modelValue.id, { per_page: 100 }, { signal: getContactSegmentsAbort.signal });
        segments.value = response.data.data
    } catch (e) {
        if (!axios.isCancel(e)) {
            throw e;
        }
    }
}

const promotionCoupons = ref([]);
const fetchPromotionCoupons = async () => {
    if (!FEATURES.promotions) {
        return
    }

    listPromotionCouponsAbort?.abort()
    listPromotionCouponsAbort = new AbortController();

    try {
        const response = await contactsClient.listPromotionCoupons(
            teamId, props.modelValue.id, { sort_dir: 'desc', per_page: 100 }, { signal: listPromotionCouponsAbort.signal }
        );
        promotionCoupons.value = response.data.data;
    } catch (e) {
        if (!axios.isCancel(e)) {
            throw e;
        }
    }
}

const selectedContactGroups = ref([]);
const fetchContactGroups = async () => {
    if (!FEATURES.contact_groups) {
        return
    }

    listContactGroupsAbort?.abort()
    listContactGroupsAbort = new AbortController();

    try {
        const response = await contactsClient.listContactGroups(teamId, props.modelValue.id, { signal: listContactGroupsAbort.signal });
        selectedContactGroups.value = response.data.data.map((g) => ({ group_id: g.group_id.toLowerCase() }));
    } catch (e) {
        if (!axios.isCancel(e)) {
            throw e;
        }
    }
}

watch(
    () => props.modelValue.id,
    () => {
        fetchSegments();
        fetchTags();
        fetchCustomAttributes();
        fetchContactGroups();
        fetchPromotionCoupons();
    },
    {
        immediate: true,
    }
)
</script>
