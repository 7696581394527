import BaseClient from './BaseClient';

class AdminCloningTargetsClient extends BaseClient {
    getCloningTargets(itemsPerPage, cursor) {
        const url = '/api/admin/cloning-targets';
        const method = 'get';
        const params = {
            per_page: itemsPerPage,
            cursor,
        };

        return this.makeCoreApiRequest(url, method, null, params);
    }

    getCloningTarget(cloningTargetId) {
        const method = 'get';
        const url = `/api/admin/cloning-targets/${cloningTargetId}`;

        return this.makeCoreApiRequest(url, method);
    }

    createCloningTarget(data) {
        const method = 'post';
        const url = `/api/admin/cloning-targets`;

        return this.makeCoreApiRequest(url, method, data);
    }

    updateCloningTarget(cloningTargetId, data) {
        const method = 'put';
        const url = `/api/admin/cloning-targets/${cloningTargetId}`;

        return this.makeCoreApiRequest(url, method, data);
    }

    deleteCloningTarget(cloningTargetId) {
        const method = 'delete';
        const url = `/api/admin/cloning-targets/${cloningTargetId}`;

        return this.makeCoreApiRequest(url, method);
    }
}

export default new AdminCloningTargetsClient();
