<template>
    <analytics-stat-card :loading="loading" :error="Boolean(error)" :percentage="dataComputed.percentage" :value="dataComputed.number?.value"
        :color="dataComputed.percentage > 0 ? 'bad' : 'good'" :direction="dataComputed.percentage < 0 ? 'down' : 'up'">
        <template v-slot:title>
            <slot name="title">
                Unsubscribe Rate
            </slot>
        </template>
        <template v-slot:subtitle>
            <slot name="subtitle"></slot>
        </template>

        <span>{{ dataComputed.number?.label || '' }}</span>

        <template v-slot:footer>
            <vx-button href="/messaging/campaigns" size="xs" outline color="muted-light">
                Go to Campaigns
                <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
            </vx-button>
        </template>

        <template v-slot:explainHeader>Unsubscribe Rate</template>
        <template v-slot:explainContent>
            <p>
                <strong>Rate</strong><br /> Overall percentage of Contact that have explicitly denied receiving marketing messages as the result of this Marketing Campaign.
            </p>

            <p>
                <strong>Variation rate</strong><br /> Variation of Unsubscribe rate during the selected period relative to the previous period.
            </p>
        </template>
    </analytics-stat-card>
</template>
<script setup>
import { analyticsClient } from '../../../../services';
import { computed, watch } from 'vue';
import AnalyticsStatCard from '../../partial/AnalyticsStatCard.vue';
import { VxButton } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { computeComparisonRange } from '../../../../utils/analytics';
import { formatPercentage } from '~/components/filters';
import { calculateDifference } from '../../../../utils/analytics';
import { useVersionedRequest } from '~/composables/useVersionedRequest';

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
    campaignId: {
        type: Number,
        default: undefined,
    },
});

const { loading, response, error, fetchData } = useVersionedRequest();

const dataComputed = computed(() => {
    const responseData = response.value?.data;

    const values = (props.campaignId ? responseData?.data?.[0]?.values : responseData?.values) || [];

    if (!values.length) {
        return {
            number: undefined,
            percentage: undefined,
        }
    }

    const number = values.length >= 2 ? values[1] : values[0];
    const percentage = values.length >= 2 ? calculateDifference(values) : undefined;

    return {
        number: {
            value: number,
            label: formatPercentage(Math.abs(number)),
        },
        percentage: percentage,
    }
});

watch([() => props.startDate, () => props.endDate, () => props.campaignId], () => {
    const params = {
        range: computeComparisonRange({ start: props.startDate, end: props.endDate })
    }

    if (props.campaignId) [
        params.filter = {
            campaign_ids: [props.campaignId]
        }
    ]

    fetchData(analyticsClient[
        props.campaignId ? 'marketingCampaignsOptOutRateByCampaign' : 'marketingCampaignsOptOutRate'
    ](Spark.state.currentTeam.id, params)).catch((e) => {
        console.error(e);
    });
}, { immediate: true });
</script>
