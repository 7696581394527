<template>
    <vx-expandable-card>
        <template v-slot:header>
            Estimated Throughput (MPS)
        </template>

        <template v-if="listError" v-slot:content>
            <vx-alert :closeable="false" color="danger" shadow>
                {{ listError }}
            </vx-alert>
        </template>
        <template v-else-if="loading" v-slot:content>
            <page-loading></page-loading>
        </template>
        <template v-else v-slot:content>
            <!-- Success Message -->
            <div class="vx-flex vx-flex-col vx-gap-6">
                <vx-alert v-if="success" :closeable="false" color="success" shadow>
                    Your estimated number throughput settings have been updated
                </vx-alert>
                <vx-alert v-if="error" :closeable="false" color="danger" shadow>
                    {{ error }}
                </vx-alert>

                <div>
                    <vx-label size="md">10DLC SMS</vx-label>
                    <vx-input type="number" size="lg" name="10dlc_sms" v-model="throughput10DLCSMS" :error="displayFirstError(validate.throughput10DLCSMS)"></vx-input>
                </div>

                <div>
                    <vx-label size="md">10DLC MMS</vx-label>
                    <vx-input type="number" size="lg" name="10dlc_mms" v-model="throughput10DLCMMS" :error="displayFirstError(validate.throughput10DLCMMS)"></vx-input>
                </div>

                <div>
                    <vx-label size="md">Tollfree SMS</vx-label>
                    <vx-input type="number" size="lg" name="tf_sms" v-model="throughputTFSMS" :error="displayFirstError(validate.throughputTFSMS)"></vx-input>
                </div>

                <div>
                    <vx-label size="md">Tollfree MMS</vx-label>
                    <vx-input type="number" size="lg" name="tf_mms" v-model="throughputTFMMS" :error="displayFirstError(validate.throughputTFMMS)"></vx-input>
                </div>

                <vx-button size="lg"
                    @click.prevent="submit"
                    :loading="submitting"
                    :disabled="!validate.$anyDirty">
                    Update
                </vx-button>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxExpandableCard, VxInput, VxButton, VxLabel, VxAlert } from '@voxie/frontend-components';
import teamThroughputClient from '../../../../services/teamThroughputClient';
import PageLoading from '~/components/general/PageLoading.vue';
import { onMounted, ref } from 'vue';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_UNPROCESSABLE_ENTITY } from '~/support/HttpStatuses';
import { displayFirstError } from '~/utils/validation';
import useVuelidate from '@vuelidate/core';
import { maxValue , minValue, required } from '@vuelidate/validators';

const props = defineProps({
    teamId: {
        type: Number,
        required: true,
    }
});

const loading = ref(true);
const submitting = ref(false);
const success = ref(false);
const listError = ref('');
const error = ref('');
const errors = ref({});

const throughput10DLCSMS = ref('');
const throughput10DLCMMS = ref('');
const throughputTFSMS = ref('');
const throughputTFMMS = ref('');

const validate = useVuelidate({
    throughput10DLCSMS: { required: required, minValue: minValue(0.01), maxValue: maxValue(9999.99) },
    throughput10DLCMMS: { required: required, minValue: minValue(0.01), maxValue: maxValue(9999.99) },
    throughputTFSMS: { required: required, minValue: minValue(0.01), maxValue: maxValue(9999.99) },
    throughputTFMMS: { required: required, minValue: minValue(0.01), maxValue: maxValue(9999.99) },
}, {
    throughput10DLCSMS,
    throughput10DLCMMS,
    throughputTFSMS,
    throughputTFMMS,
}, {
    $autoDirty: true,
});

onMounted(async () => {
    try {
        const { data } = await  teamThroughputClient.list(props.teamId);
        throughput10DLCSMS.value = String(data.data.find(t => t.throughput_type === '10dlc_sms')?.throughput_rate || 0)
        throughput10DLCMMS.value = String(data.data.find(t => t.throughput_type === '10dlc_mms')?.throughput_rate || 0)
        throughputTFSMS.value = String(data.data.find(t => t.throughput_type === 'tf_sms')?.throughput_rate || 0)
        throughputTFMMS.value = String(data.data.find(t => t.throughput_type === 'tf_mms')?.throughput_rate || 0)
        validate.value.$reset();
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            listError.value = 'Something went wrong.'
        } else {
            listError.value = e.response?.data?.message || 'Something went wrong.';
        }
    } finally {
        loading.value = false;
    }
})

const submit = async () => {
    const isValid = await validate.value.$validate();
    if (!isValid) {
        error.value = 'Please check form data.';
        return;
    }
    if (submitting.value) {
        return;
    }

    success.value = false;
    errors.value = {};
    error.value = ''
    submitting.value = true;

    const promise10DLCSMS = teamThroughputClient.store(props.teamId, '10dlc_sms', throughput10DLCSMS.value);
    const promise10DLCMMS = teamThroughputClient.store(props.teamId, '10dlc_mms', throughput10DLCMMS.value);
    const promiseTFSMS = teamThroughputClient.store(props.teamId, 'tf_sms', throughputTFSMS.value);
    const promiseTFMMS = teamThroughputClient.store(props.teamId, 'tf_mms', throughputTFMMS.value);

    Promise.all([
        promise10DLCSMS,
        promise10DLCMMS,
        promiseTFSMS,
        promiseTFMMS,
    ]).then(() => {
        success.value = true;
    }).catch((e) => {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            error.value = 'Something went wrong.'
        } else {
            if (e.response.status === HTTP_UNPROCESSABLE_ENTITY) {
                errors.value = e.response.data.errors
            }
            error.value = e.response?.data?.message || 'Something went wrong.';
        }
    }).finally(() => {
        submitting.value = false;
    })
};
</script>
