<template>
    <button type="submit" :title="title" @click.prevent="onClick">
        <slot />
    </button>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    props: {
        icon: {
            type: String,
            default: 'warning',
        },
        title: {
            type: String,
            default: 'Are you sure?',
        },
        text: {
            type: String,
            default: 'You won\'t be able to revert this!',
        },
        confirmButtonText: {
            type: String,
            default: 'Yes, continue',
        },
        cancelButtonText: {
            type: String,
            default: 'No, cancel',
        }
    },

    methods: {
        onClick() {
            Swal.fire({
                ...this.$props,
                reverseButtons: true,
                showCancelButton: true,
            }).then((result) => {
                if (typeof result.value !== 'undefined' && result.value) {
                    this.$emit('confirmed');
                } else {
                    this.$emit('canceled');
                }
            });
        },
    },
};
</script>
