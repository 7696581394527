<template>
    <div>
        <playbook-form-card v-for="(question, index) in questions"
            :icon="faCommentsQuestion"
            :key="'customQuestion' + index">
            <template v-slot:header>
                <slot name="header">
                    <span>Question {{ index + 1 }}</span>
                    <span class="vx-absolute vx-right-2.5 vx-top-2.5">
                        <vx-button @click.prevent="removeQuestion(index)" data-test="remove-question" color="muted" size="sm">
                            <font-awesome-icon :icon="faTrashCan" />
                        </vx-button>
                    </span>
                </slot>
            </template>

            <playbook-custom-question ref="questionsRef" :modelValue="question" @update:modelValue="updateQuestion(index, $event)"></playbook-custom-question>
        </playbook-form-card>

        <vx-box v-if="questions.length < props.limit"
            color="info"
            class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
            Add another Question to this playbook flow ({{ questions.length }} of {{ props.limit }}).
            <vx-button @click.prevent="addQuestion" data-test="add-question" color="muted-dark">
                Add Question <font-awesome-icon :icon="faPlusCircle" />
            </vx-button>
        </vx-box>
    </div>
</template>


<script setup>
import { VxBox, VxButton } from '@voxie/frontend-components';
import { faCommentsQuestion, faPlusCircle, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, nextTick, onMounted, ref } from 'vue';
import PlaybookFormCard from '../PlaybookFormCard.vue';
import PlaybookCustomQuestion from './PlaybookCustomQuestion.vue';

const props = defineProps({
    modelValue: {
        type: Array,
        required: true,
        default: () => ([])
    },
    limit: {
        type: Number,
        default: 10,
    }
});

const emit = defineEmits(['update:modelValue']);

const questions = computed(() => props.modelValue || []);

const questionsRef = ref([]);

const updateQuestion = (index, updatedQuestion) => {
    emit('update:modelValue', props.modelValue.map((question, currentIndex) => {
        if (index === currentIndex) {
            return updatedQuestion;
        }
        return question;
    }))
}

const addQuestion = async () => {
    emit('update:modelValue', [...questions.value, {
        message: '',
        field_type: '',
        custom_attribute: ''
    }]);

    await nextTick();
    questionsRef.value?.[questions.value.length - 1]?.focusMessage?.();
}

const removeQuestion = (index) => {
    emit('update:modelValue', questions.value.filter((question, currentIndex) => currentIndex !== index));
}

onMounted(async () => {
    if (!questions.value?.length) {
        emit('update:modelValue', [])
    }
})

</script>
