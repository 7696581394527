<template>
    <div class="vx-@container/controls vx-relative vx-z-[1]">
        <div class="vx-flex vx-flex-col-reverse vx-my-4 @4xl/controls:vx-flex-row @4xl/controls:vx-gap-x-8 @4xl/controls:vx-justify-between">
            <div class="vx-flex vx-flex-col vx-gap-y-2 @2xl/controls:vx-flex-row @2xl/controls:vx-gap-x-4 @3xl/controls:vx-grow @4xl/controls:vx-gap-x-2">
                <slot name="left"></slot>
            </div>
            <div class="vx-mb-4 @4xl/controls:vx-mb-0">
                <slot name="right"></slot>
            </div>
        </div>
    </div>

</template>

<script setup>
</script>
