<template>
    <div class="card card-default">
        <div class="card-header">
            <div class="float-left">
                Subscribe
            </div>

            <!-- Interval Selector Button Group -->
            <div class="float-right">
                <div class="btn-group btn-group-sm" role="group" v-if="hasMonthlyAndYearlyPaidPlans">
                    <!-- Monthly Plans -->
                    <button type="button" class="btn btn-light"
                        @click="$emit('showMonthlyPlans')"
                        :class="{'active': showingMonthlyPlans}"
                    >
                        Monthly
                    </button>

                    <!-- Yearly Plans -->
                    <button type="button" class="btn btn-light"
                        @click="$emit('showYearlyPlans')"
                        :class="{'active': showingYearlyPlans}"
                    >
                        Yearly
                    </button>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>

        <div class="table-responsive">
            <!-- Plan Error Message -->
            <div class="alert alert-danger m-4" v-if="form.errors.has('plan')">
                {{ form.errors.get('plan') }}
            </div>

            <table class="table table-responsive-sm table-valign-middle mb-0 ">
                <thead></thead>
                <tbody>
                    <tr v-for="(plan, index) in plansForActiveInterval" :key="index">
                        <!-- Plan Name -->
                        <td>
                            <div class="d-flex align-items-center">
                                <i class="radio-select mr-2" @click="selectPlan(plan)"
                                :class="{'radio-select-selected': selectedPlan == plan, invisible: form.busy}"></i>
                                {{ plan.name }}
                            </div>
                        </td>

                        <!-- Plan Features Button -->
                        <td>
                            <button class="btn btn-default" @click="showPlanDetails(plan)">
                                <i class="fa fa-btn fa-star-o"></i> Features
                            </button>
                        </td>

                        <!-- Plan Price -->
                        <td>
                            <span class="table-plan-text">
                                <strong class="table-plan-price">{{ currency(plan.price) }}</strong>
                                {{ chargesTeamsPerSeat ? '/ '+ teamSeatName : '' }}
                                {{ chargesTeamsPerMember ? '/ '+ teams.member : '' }}
                                / {{ capitalize(plan.interval) }}
                            </span>
                        </td>

                        <!-- Trial Days -->
                        <td class="table-plan-price table-plane-text text-right">
                            <span v-if="plan.trialDays && !hasSubscribed(plan)">
                                {{ plan.trialDays }} Day Trial
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { currency, capitalize } from '../../../utils/helpers';

export default {
    props: [
        'teams',
        'plans',
        'team',
        'form',
        'selectedPlan',
        'showingMonthlyPlans',
        'showingYearlyPlans',
    ],
    computed: {
        activeInterval() {
            return this.showingMonthlyPlans ? 'monthly' : 'yearly';
        },
        paidPlans() {
            return this.plans?.filter((plan) => {
                return plan.active && plan.price > 0;
            }) || [];
        },
        plansForActiveInterval() {
            return this.plans?.filter((plan) => {
                return plan.active && (plan.price === 0 || plan.interval === this.activeInterval);
            }) || [];
        },
        hasMonthlyAndYearlyPaidPlans() {
            return !!this.paidPlans.some((p) => p.interval === 'monthly') &&
                !!this.paidPlans.some((p) => p.interval === 'yearly')
        },
        chargesUsersPerSeat() {
            return Spark.chargesUsersPerSeat;
        },
        chargesTeamsPerSeat() {
            return Spark.chargesTeamsPerSeat;
        },
        chargesTeamsPerMember() {
            return Spark.chargesTeamsPerMember;
        },
        seatName() {
            return Spark.seatName;
        },
        teamSeatName() {
            return Spark.teamSeatName;
        }
    },
    methods: {
        currency,
        capitalize,
        selectPlan(plan) {
            this.$emit('plan-selected', plan);
        },
        showPlanDetails(plan) {
            Bus.$emit('showPlanDetails', plan);
        },
        hasSubscribed(plan) {
            return !!this.team.subscriptions?.filter((s) => s.provider_plan === plan.id).length;
        },
    },
};
</script>
