import BaseClient from './BaseClient';

class PhoneNumberPoolsClient extends BaseClient {
    getList(teamId, params) {
        const url = `/api/v3/teams/${teamId}/phone-number-pools`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    getById(teamId, id) {
        const url = `/api/v3/teams/${teamId}/phone-number-pools/${id}`;
        return this.makeCoreApiRequest(url);
    }
}

export default new PhoneNumberPoolsClient();
