<template>
    <div class="cell-sorter">
        <span class="cell-sorter__text">
            <slot />
        </span>
        <span class="ttl-cell-sorter-icon" :class="[threadsCnt === 0 && 'ttl-cell-sorter-icon__visible__hiden']">
            <sort-icon :sort-dir="sortDir" />
        </span>
    </div>
</template>

<script>
import SortIcon from '../../general/SortIcon.vue';

export default {
    components: { SortIcon },
    props: ['threadsCnt', 'sortDir'],
};
</script>

<style lang="postcss" scoped>
.cell-sorter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: 8px 0px;
}

.cell-sorter__text {
    font-family: 'Open sans', sans-serif;
    font-size: 12px;
    line-height: 20px;
    color: #787879;
}

.cell-sorter .ttl-cell-sorter-icon__visible__hiden {
    visibility: hidden;
}
</style>
