<template>
    <page-container class="vx-grid vx-grid-cols-12 vx-gap-8">
            <!-- Tabs -->
            <settings-sidebar></settings-sidebar>

            <!-- Tab cards -->
            <page-loading v-if="!team" class="vx-col-span-12 md:vx-col-span-8 lg:vx-col-span-10"></page-loading>
            <div v-else ref="content" class="vx-col-span-12 md:vx-col-span-8 lg:vx-col-span-10">
                    <!-- Owner Information -->
                    <div v-if="(hash === 'owner' || !hash)" role="tabcard">
                        <team-profile
                            :user="user"
                            v-model:team="team"
                            :isTeamAdmin="isTeamAdmin"
                            :impersonator="isImpersonatorOrDev">
                        </team-profile>
                    </div>

                    <template v-if="teamAndOrganizationId">
                        <div v-if="hash === 'organizations-invites' && isBrand" role="tabcard">
                            <div v-if="team">
                                <organizations-invites :team="team"></organizations-invites>
                            </div>
                        </div>

                        <div v-if="hash === 'organizations-sso-settings' && team && FEATURES.single_sign_on" role="tabcard">
                                <organizations-sso-settings :team="team"></organizations-sso-settings>
                        </div>
                    </template>

                    <!-- Membership -->
                    <div v-if="hash === 'membership'" role="tabcard">
                        <template v-if="!FEATURES.team_users_reskin">
                            <div v-if="team && user && !user.isUsingSingleSignOn">
                                <team-membership
                                    :user="user"
                                    :team="team"
                                    :is-team-admin="isTeamAdmin"
                                    :default-role="defaultRole"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <team-users v-if="team && user && !user.isUsingSingleSignOn"/>
                        </template>
                    </div>

                    <div v-if="hash === 'phone-numbers'" role="tabcard">
                        <team-phone-numbers />
                    </div>

                    <div v-if="hash === 'customattributes'" role="tabcard">
                        <div v-if="team">
                            <team-custom-attributes :user="user" :team="team" />
                        </div>
                    </div>

                    <div v-if="hash === 'shift-to-text' && FEATURES.shift_to_text" role="tabcard">
                        <div v-if="team">
                            <shift-to-text />
                        </div>
                    </div>

                    <!-- Webhooks -->
                    <div v-if="hash === 'webhooks'" role="tabcard">
                        <webhooks-settings></webhooks-settings>
                    </div>

                    <!-- Features -->
                    <div v-if="hash === 'feature' && isImpersonatorOrDev" role="tabcard">
                        <div v-if="team">
                            <team-features :team="team" title="Features"></team-features>
                        </div>
                    </div>

                    <div v-if="hash === 'analytics' && FEATURES.attribution_ui" role="tabcard">
                        <div>
                            <analytics-settings />
                        </div>
                    </div>

                    <div v-if="hash === 'contacts-exports'" role="tabcard">
                        <div>
                            <contact-export-settings />
                        </div>
                    </div>

                    <div v-if="hash === 'groups'" role="tabcard">
                        <groups-list />
                    </div>

                    <!-- Billing Tab Panes -->
                    <template v-if="ownsTeam || isTeamAdmin">
                        <!-- Subscription -->
                        <div v-if="hash === 'subscription' && hasPaidTeamPlans" role="tabcard">
                            <div v-if="user && team">
                                <spark-subscription
                                    :user="user"
                                    :team="team"
                                    :teams="teams"
                                    :is-impersonator-or-dev="isImpersonatorOrDev"
                                    :cashier-path="cashierPath"
                                    :team-subscription="teamSubscription"
                                    :team-last-payment-id="teamLastPaymentId"
                                    :team-message-balance="teamMessageBalance"
                                    :countries-list="countriesList"
                                    :has-impersonator="hasImpersonator"
                                ></spark-subscription>
                            </div>
                        </div>

                        <!-- Payment Method -->
                        <div v-if="hash === 'payment-method'" role="tabcard">
                            <div v-if="user && team">
                                <payment-method
                                    :team="team"
                                    :collects-billing-address="collectsBillingAddress"
                                    :countries="countriesList"
                                >
                                </payment-method>
                            </div>
                        </div>

                        <!-- Invoices -->
                        <div v-if="hash === 'invoices'" role="tabcard">
                            <div v-if="team">
                                <invoice-settings
                                    :team="team"
                                    :owns-team="ownsTeam"
                                    :isTeamAdmin="isTeamAdmin"
                                >
                                </invoice-settings>
                            </div>
                        </div>
                        <div v-if="hash === 'plan-information'" role="tabcard">
                            <plan-information
                                :teamPlan="props.teamPlan"
                                :teamMessageBalance="props.teamMessageBalance"
                                :teamSumBalanceAmount="props.teamSumBalanceAmount"
                            ></plan-information>
                        </div>
                    </template>
                </div>
    </page-container>
</template>

<script setup>
import { ref, inject, computed, watch, nextTick } from 'vue';
import PageContainer from '~/components/general/PageContainer.vue';
import PageLoading from '~/components/general/PageLoading.vue';
import SettingsSidebar from './SettingsSidebar.vue';
import TeamProfile from '../teams/profile/TeamProfile.vue';
import OrganizationsInvites from '../teams/organizations/OrganizationsInvites.vue';
import OrganizationsSsoSettings from '../teams/organizations/OrganizationsSsoSettings.vue';
import TeamMembership from '../teams/membership/TeamMembership.vue';
import TeamUsers from '../teams/membership/TeamUsers.vue';
import TeamCustomAttributes from '../teams/TeamCustomAttributes.vue';
import TeamPhoneNumbers from '../teams/phone-numbers/TeamPhoneNumbers.vue';
import ShiftToText from '../teams/shift-to-text/ShiftToText.vue';
import TeamFeatures from '../teams/features/TeamFeatures.vue';
import AnalyticsSettings from '../teams/analytics-settings/AnalyticsSettings.vue';
import SparkSubscription from '../subscription/SparkSubscription.vue';
import PaymentMethod from '../payment-method/PaymentMethod.vue';
import InvoiceSettings from '../invoices/InvoiceSettings.vue';
import WebhooksSettings from '../webhooks/WebhooksSettings.vue';
import ContactExportSettings from '../teams/contact-export-settings/ContactExportSettings.vue';
import GroupsList from '../teams/groups/list/GroupsList.vue';
import PlanInformation from '../teams/plan-information/PlanInformation.vue';
import { useBrowserLocation } from '@vueuse/core';
import axios from '~/services/axios';

const FEATURES = inject('FEATURES');

const props = defineProps({
        user: Object,
        teamId: Number,
        ownsTeam: Boolean,
        onTeam: Boolean,
        hasPaidTeamPlans: Boolean,
        teamAndOrganizationId: Boolean,
        isBrand: Boolean,
        isImpersonatorOrDev: Boolean,
        isTeamAdmin: Boolean,
        defaultRole: String,
        countriesList: Array,
        cashierPath: String,
        teamPlan: Object,
        teamSubscription: Boolean,
        teamLastPaymentId: Number,
        teamMessageBalance: Number,
        teamSumBalanceAmount: Number,
        hasImpersonator: Boolean,
})

const collectsBillingAddress = Spark.collectsBillingAddress;

const team = ref(null);

const getTeam = ()  => {
    axios.get(`/settings/teams/json/${props.teamId}`)
        .then(response => {
            team.value = response.data;
        });
}

getTeam();

const location = useBrowserLocation();

const hash = computed(() => location.value.hash?.replace('#/', '').replace('#', ''))

if (!hash.value) {
    location.value.hash = '#/owner';
}

const content = ref();
watch(hash, async () => {
    await nextTick();
    if (!content.value || window.innerWidth >= 768) {
        return;
    }
    content.value.scrollIntoView({ behavior: 'smooth', block: 'start' });
}, { immediate: true })


Bus.$on('updateTeam', () => {
    getTeam();
});

</script>
