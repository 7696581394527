<template>
    <div class="helpTip">
        <div v-if="isOpen" :class="'c-alert c-alert--' + type">
            <i :class="`c-alert__icon fa fa-${iconType}`"></i>

            <span class="alert-content">
                <slot></slot>
            </span>

            <button @click.prevent="close" class="c-close" data-dismiss="alert" type="button">×</button>
        </div>
        <a v-else
            href="#"
            @click.prevent="open"
            class="helpTipOpener"
            :style="helpTipStyle"
        >
            <i class="fa fa-info-circle"></i>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        topPosition: {
            type: Number,
            default: 3,
        }
    },

    data(){
        return {
            isOpen: true
        }
    },

    created() {
        if (!localStorage.getItem(this.cookieName)) {
            localStorage.setItem(this.cookieName, '1');
        } else {
            this.isOpen = localStorage.getItem(this.cookieName) === '1';
        }
    },

    methods: {
        close(){
            localStorage.setItem(this.cookieName, '0');
            this.isOpen = false;
        },

        open(){
            localStorage.setItem(this.cookieName, '1');
            this.isOpen = true;
        }
    },

    computed: {
        cookieName(){
            return this.id +'_is_open';
        },
        helpTipStyle(){
            return `top: ${this.topPosition}px`;
        },
        iconType() {
            return this.type === 'warning' ? 'exclamation-triangle' : 'check-circle';
        }
    }
}
</script>

<style>
.alert-content {
    display: inline-block;
    margin-right: 30px;
}
.helpTipOpener {
    position: absolute;
    right: 22px;
    z-index: 1;
}
.helpTip ul {
    margin-top: 1rem;
    list-style-type: disc;
}
</style>
