<template>
  <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8 2xl:vx-max-w-screen-xl">
    <page-header>Scheduled Messages</page-header>

    <div class="scheduled-messages__box">
        <div class="scheduled-messages__header">
            <div></div>
            <vx-button appearance="primary-dark" :size="'large'" @click="showModal = true">
                <vx-icon name="plus" :size="14" />
                <span class="ml-2">New Scheduled Message</span>
            </vx-button>

            <scheduled-message-modal v-model:visible="this.showModal" @newScheduledMessageCreated="getScheduledMessages"></scheduled-message-modal>
        </div>

        <div class="mt-5 d-flex">
            <vx-tabs
                @selectTab="changeTabAndUpdateData"
                :size="14"
                color="primary-dark"
                :selected="selected"
                :tabs="[
                { title: 'Pending', badge: 0 },
                { title: 'Sent', badge: 0 },
            ]"
            >
            </vx-tabs>
        </div>

        <div class="mt-3">
            <scheduled-messages-table
                :status="status"
                :scheduled-messages="scheduledMessages"
                :loading-table="loadingTable"
                @scheduledMessageDeleted="getScheduledMessages"
                @scheduledMessageEdited="getScheduledMessages"
            ></scheduled-messages-table>
        </div>

        <cursor-pagination
            v-if="!loadingTable"
            v-model:cursor="cursor"
            :loading="loadingPagination"
            :firstPage="firstPageUrl"
            :nextPage="nextPageUrl"
            :prevPage="prevPageUrl"
            :lastPage="lastPageUrl"
            @change="changeCursor"
        ></cursor-pagination>
    </div>
  </div>
</template>


<script>
import { isAuthErrorEcho } from '../../utils/helpers';
import { VxButton, VxIcon, VxTabs } from '@voxie/frontend-components-v3';
import scheduledMessagesClient from '../../services/scheduledMessagesClient';
import ScheduledMessageModal from './modals/ScheduledMessageModal.vue';
import CursorPagination from '../general/CursorPagination.vue';
import ScheduledMessagesTable from './ScheduledMessagesTable.vue';
import PageHeader from '~/components/general/PageHeader.vue';
import clientRoutes from './../../mixins/client-routes';

export default {

    mixins: [
        clientRoutes,
    ],

    components: {
        CursorPagination,
        ScheduledMessageModal,
        ScheduledMessagesTable,
        VxButton,
        VxIcon,
        VxTabs,
        PageHeader
    },

    inject: ['FEATURES'],

    data() {
        return {
            teamId: Spark.state.currentTeam.id,
            selected: 0,
            scheduledMessages: [],
            status: 'pending',
            cursor: null,
            firstPageUrl: null,
            prevPageUrl: null,
            nextPageUrl: null,
            lastPageUrl: null,
            loadingPagination: false,
            loadingTable: false,
            echoErrors: [],
            showModal: false,
        };
    },

    computed: {
        isPendingTabSelected() {
            return this.selected === 0;
        },

    },

    methods: {
        changeTabAndUpdateData(number) {
            this.selected = number;

            this.status = this.isPendingTabSelected ? 'pending' : 'sent';
            this.cursor = null;

            this.getScheduledMessages();
        },

        getScheduledMessages() {
            this.loadingTable = true;

            scheduledMessagesClient.getTeamScheduledMessages(this.teamId, {'status' : this.status, 'cursor': this.cursor})
                .then(({ data }) => {
                    if (Array.isArray(data.data)) {
                        this.scheduledMessages = data.data;
                        this.firstPageUrl = data.first_page_url;
                        this.prevPageUrl = data.prev_page_url;
                        this.nextPageUrl = data.next_page_url;
                        this.lastPageUrl = data.last_page_url;

                        this.updateRouting();
                    }
                }).catch(() => {
                    this.$toasted.global.platform_error();
                }).finally(() => {
                    this.loadingTable = false;
                })
        },

        changeCursor() {
            this.getScheduledMessages();
        },

        updateRouting() {
            const cursor = this.cursor ? '/' + this.cursor : '';

            this.showClientRoute(`#/${this.pageUrl ? this.pageUrl : 'pages'}${cursor}`);
        },
    },

    mounted() {
        this.getScheduledMessages();

        Echo.private(`scheduled_messages.${this.teamId}`)
            .listen('ScheduledMessageCancelled', () => {
                this.getScheduledMessages();
            })
            .listen('ScheduledMessageCreated', () => {
                this.getScheduledMessages();
            })
            .listen('ScheduledMessageEnqueued', () => {
                this.getScheduledMessages();
            })
            .error?.((e) => {
                if (this.echoErrors.length <= 3 && !isAuthErrorEcho(e)) {
                    window?.Bugsnag?.notify(e instanceof Error ? e : new Error(JSON.stringify(e)));
                    this.echoErrors.push(e);
                }
            });
    }
}
</script>
