<template>
    <contact-details :contactId="props.contactId">
        <div class="vx-col-span-12 lg:vx-col-span-10 vx-flex vx-flex-col vx-w-full vx-justify-center vx-gap-2 md:vx-gap-2">
            <vx-box class="vx-bg-slate-50 vx-flex vx-flex-col vx-max-w-screen-sm vx-p-8 vx-text-xl" color="info">
                <h2 class="vx-text-xl vx-font-extrabold vx-leading-7 vx-text-slate-700">
                    Segments
                </h2>
                <p class="vx-text-sm vx-font-sans vx-text-slate-500 lg:vx-text-base">
                    Segments of this contact.
                </p>
            </vx-box>

            <div v-if="loading && !list.length" class="vx-text-center vx-py-10">
                <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
            </div>

            <template v-else-if="!loading && list.length === 0">
                <vx-box color="info" class="vx-mt-8 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                    There are no segments for this contact.
                </vx-box>
            </template>
            <template v-else>
                <vx-table v-if="list.length" class="xl:vx-grid-cols-4" rowBreakpoint="xl">
                    <vx-table-row isHeader>
                        <div>
                            ID
                        </div>
                        <div>
                            Name
                        </div>
                        <div>
                            Audience Type
                        </div>
                        <div>
                            Refresh Type
                        </div>
                    </vx-table-row>
                    <contact-promotion-row v-for="(item, index) in list"
                        v-model="list[index]"
                        :key="item.id"></contact-promotion-row>
                </vx-table>
            </template>
        </div>
    </contact-details>
</template>

<script setup>

import ContactDetails from '../ContactDetails.vue';
import { VxBox, VxIconLoading, VxTable, VxTableRow } from '@voxie/frontend-components';
import contactsClient from '../../../../services/contactsClient';
import ContactPromotionRow from './ContactSegmentRow.vue';
import { onMounted, ref } from 'vue';
import { useToasted } from '../../../../composables/useToasted';

const props = defineProps({
    contactId: {
        type: Number,
        required: true,
    },
});

const loading = ref(false);
const list = ref([]);
const toasted = useToasted();

onMounted(() => {
    getSegments();
});

const getSegments = async() => {
    if (loading.value) {
        return;
    }

    loading.value = true;
    try {
        const response = await contactsClient.getContactSegments(
            Spark.state.currentTeam.id,
            props.contactId,
            { per_page: 100 }
        );

        list.value = response.data.data;
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
};
</script>

