<template>
    <div class="card card-default">
        <div class="card-header">Invoices</div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                </thead>
                <tbody>
                <tr v-for="invoice in invoices" :key="invoice.id" class="invoice-row">
                    <!-- Invoice Date -->
                    <td>
                        <strong>{{ date(invoice.created_at) }}</strong>
                    </td>

                    <!-- Invoice Total -->
                    <td>
                        {{ currency(invoice.total) }}
                    </td>

                    <!-- Invoice Download Button -->
                    <td class="text-right">
                        <a :href="downloadUrlFor(invoice)">
                            <button class="btn btn-default">
                                <i class="fa fa-btn fa-file-pdf-o"></i> Download PDF
                            </button>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { currency, date } from  '../../../utils/helpers';

export default {
    props: [ 'team', 'invoices' ],
    methods: {
        currency,
        date,
        downloadUrlFor(invoice) {
            return `/settings/teams/${this.team.id}/invoice/${invoice.id}`;
        }
    },
};
</script>
