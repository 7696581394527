<template>
    <vx-avatar :size="size" :color="color" :text-color="textColor">
        <span v-if="contactInitials" class="contact-avatar__initials" :class="[sizeClass]">{{ contactInitials }}</span>
        <vx-icon v-else name="avatar-small" :size="iconSize"></vx-icon>
    </vx-avatar>
</template>

<script setup>
import { computed } from 'vue';
import { VxAvatar, VxIcon } from '@voxie/frontend-components-v3';
import { initials } from '../../../utils/helpers';

const props = defineProps({
    contact: {
        type: Object,
        required: true,
    },
    size: {
        type: Number,
        default: 3,
    },
    color: {
        type: String,
        default: 'var(--color-primary)',
    },
    textColor: {
        type: String,
        default: 'var(--color-white)',
    },
});

const contactInitials = computed(() => initials(props.contact.first_name, props.contact.last_name));
const sizeClass = computed(() => `contact-avatar__initials--size-${props.size}`);
const iconSize = computed(() => {
    switch (props.size) {
        case 1:
        case 2:
            return 18;
        default:
            return 24;
    }
});
</script>

<style lang="postcss">
.contact-avatar__initials {
  font-family: var(--font-dm-sans);
  font-weight: var(--weight-bold);
}
.contact-avatar__initials--size-2 {
  font-size: 1.125rem;
}
.contact-avatar__initials--size-3 {
  font-size: 1.5rem;
}
</style>
