<template>
    <div>
        <page-header>
            Plan Information
        </page-header>
        <div class="vx-max-w-screen-sm vx-w-full vx-flex vx-flex-col vx-gap-8">

            <vx-alert v-if="props.teamPlan?.attributes?.recharge"
                :closeable="false">
                To <a href="mailto:support@voxie.com?subject=I'd%20like%20to%20upgrade%20my%20Voxie%20plan&body=I'm%20interested%20in%20upgrading%20my%20plan%20in%20Voxie.%20Can%20you%20please%20send%20me%20more%20information%3F">upgrade your plan</a> or for other billing related questions, please contact <a href="mailto:support@voxie.com">support@voxie.com</a>.
            </vx-alert>
            <vx-alert v-if="!props.teamPlan"
                color="info"
                :closeable="false">Your plan information could not be loaded. Please contact <a
                    href="https://support.voxie.com/hc/en-us/requests/new"
                    target="_blank"
                    rel="noreferrer">support</a>. </vx-alert>
            <vx-expandable-card v-if="props.teamPlan">
                <template v-slot:header>
                    <span>{{ props.teamPlan.name }}</span>

                </template>
                <template v-slot:headerRight>
                    <span>
                        {{ planPrice }}
                        <span class="vx-text-sm vx-text-slate-400">
                            /{{ props.teamPlan.is_annual ? 'year' : 'month' }}
                        </span>
                    </span>
                </template>
                <template v-slot:content>
                    <div class="vx-flex vx-flex-col vx-gap-4">
                        <div class="vx-whitespace-pre-wrap">{{ planDescription }}</div>
                        <div v-if="loading"
                            class="vx-py-3 vx-text-center">
                            <vx-icon-loading class="vx-w-9 vx-h-9"
                                spin></vx-icon-loading>
                        </div>

                        <vx-alert v-else-if="error"
                            :closeable="false"
                            color="danger">Failed to load usage for this month.</vx-alert>

                        <template v-else>
                            <div v-if="props.teamPlan.attributes?.recharge">
                                <div v-if="props.teamMessageBalance != undefined && !props.teamPlan.attributes?.no_rollover">
                                    Current balance: {{ formatNumber(props.teamMessageBalance) }} segments
                                </div>
                                <div v-else-if="props.teamPlan.attributes?.no_rollover">
                                    Current balance: {{ formatNumber(currentBalanceForNoRollover) }} segments
                                </div>
                                <div>
                                    Usage this month: {{ formatNumber(totalCreditsUsed) }} segments
                                </div>
                                <div v-if="props.teamPlan.attributes?.no_rollover">
                                    Number of recharges: {{ formatNumber(numberOfRecharges) }}
                                </div>
                            </div>
                            <div v-if="!props.teamPlan.attributes?.recharge">
                                SMS usage this month: {{ formatNumber(usageByType.sms) }} segments <br />
                                MMS usage this month: {{ formatNumber(usageByType.mms) }} messages
                            </div>
                            <vx-alert color="info" :closeable="false">
                                These estimates can take a few hours to update and are not necessarily reflective of final invoice
                                amounts.
                            </vx-alert>
                        </template>
                    </div>

                </template>
            </vx-expandable-card>
            <vx-expandable-card data-test="billing-email">
                <template v-slot:header>
                    Billing Email
                </template>
                <template v-slot:content>
                    <div class="vx-flex vx-flex-col vx-gap-2">
                        <vx-alert v-if="success"
                            type="success"
                            :closeable="false">Billing email has been successfully updated</vx-alert>
                        <vx-alert v-if="emailError"
                            type="danger"
                            :closeable="false">Billing email has failed to updated</vx-alert>
                        <vx-input :disabled="!isAdminOrOwner"
                            size="lg"
                            placeholder="Email for billing"
                            v-model="billingEmail"
                            :error="displayFirstError(validate.email)"></vx-input>
                        <div v-if="isAdminOrOwner">
                            <vx-button type="button"
                                size="lg"
                                :loading="saving"
                                @click.prevent="saveBillingEmail">Save <font-awesome-icon
                                    :icon="faCheck"></font-awesome-icon></vx-button>
                        </div>
                    </div>

                </template>
            </vx-expandable-card>
        </div>
    </div>
</template>
<script setup>
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxAlert, VxButton, VxExpandableCard, VxIconLoading, VxInput } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { computed, onMounted, ref } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';
import { analyticsClient } from '~/services';
import teamsClient from '~/services/teamsClient';
import { getDateRange } from '~/utils/analytics';
import { displayFirstError } from '~/utils/validation';

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    teamPlan: {
        type: Object
    },
    teamMessageBalance: {
        type: Number,
    },
    teamSumBalanceAmount: {
        type: Number,
    }
});

const planPrice = computed(() => {
    if (!props.teamPlan) {
        return null;
    }

    return Intl.NumberFormat('en', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(props.teamPlan.price);
});

const error = ref(false);
const loading = ref(true);

const usageByTypeRaw = ref([]);

const usageByType = computed(() => {
    let mms = 0;
    let sms = 0;

    usageByTypeRaw.value.forEach((record) => {
        switch (record['group_id']) {
            case 'mms_message':
                mms += record['values'][0];
                break;
            case 'sms_segment':
                sms += record['values'][0];
                break;
        }
    });

    return { mms, sms };
});

const planDescription = computed(() => {
    const hasRecharge = props.teamPlan.attributes?.recharge;
    const hasRollover = !props.teamPlan.attributes?.no_rollover;
    const isUsageBased = !hasRecharge;

    if (hasRecharge && hasRollover) {
        return `This plan includes ${formatNumber(props.teamPlan.attributes?.messages)} segment credits with segment balance rollover. Unused segment credits will transfer to the next period and the rollover balance resets at the beginning of the calendar year.`;
    }

    if (hasRecharge && !hasRollover) {
        return `This plan includes ${formatNumber(props.teamPlan.attributes?.messages)} segment credits. 
If the balance is completely used during a month, your card on file will immediately be charged $${formatNumber(Math.ceil(props.teamPlan.attributes?.recharge_cost / 100))} and ${formatNumber(props.teamPlan.attributes?.recharge_messages)} segment credits will be added to your balance.`;
    }

    if (isUsageBased) {
        return 'You are on a custom usage-based plan. Please see your individual contract for billing details and rates.';
    }

    return '';
});


const planMessages = computed(() => {
    return props.teamPlan?.attributes?.messages ?? 0;
})

const totalCreditsUsed = computed(() => {
    return (usageByType.value.mms * 2) + usageByType.value.sms;
});

const numberOfRecharges = computed(() => {
    if (!props.teamPlan?.attributes?.recharge) {
        return 0;
    }

    const numberOfRecharges = Math.ceil((totalCreditsUsed.value - planMessages.value) / props.teamSumBalanceAmount);
    return numberOfRecharges < 0 ? 0 : numberOfRecharges;
})

const currentBalanceForNoRollover = computed(() => {
    return planMessages.value + props.teamSumBalanceAmount
})

const formatNumber = (number) => {
    return Intl.NumberFormat('en-US').format(number);
}

onMounted(async () => {
    loading.value = true;
    const currentMonthDates = getDateRange('month_today');

    try {
        const usageByTypeResponse = await analyticsClient.usageTotalByType(teamId, {
            range: [currentMonthDates.start_date, currentMonthDates.end_date]
        });

        usageByTypeRaw.value = usageByTypeResponse.data?.data;
    } catch (e) {
        console.error(e);
        usageByTypeRaw.value = [];
        error.value = true;
    } finally {
        loading.value = false;
    }
})

const billingEmail = ref(Spark.state.currentTeam.billing_email);
const saving = ref(false);
const success = ref(false);
const emailError = ref(false);

const isAdminOrOwner = Spark.isAdminOrOwner;

const validate = useVuelidate({
    email: { required, email }
}, {
    email: billingEmail
})
const saveBillingEmail = async () => {
    if (saving.value) {
        return;
    }
    const isValid = await validate.value.$validate();
    if (!isValid) {
        return;
    }
    success.value = false;
    emailError.value = false;

    try {
        await teamsClient.updateBillingEmail(teamId, { email: billingEmail.value });
    } catch (e) {
        console.error(e);
        emailError.value = true;
    } finally {
        saving.value = false;
    }
}


</script>
