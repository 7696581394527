<template>
    <modal
        v-model:visible="modalShow"
        title="Add Tag"
        submit-text="Save"
        close-text="Cancel"
        @submit="syncTags"
        @hidden="tagsToSync = null"
    >
        <v-select
            :model-value="tagsToSync"
            @update:model-value="addTag"
            :options="tagsOptions"
            label="tag_name"
            :taggable="true"
            @search="searchTags"
            @option:created="addTag"
            placeholder="Select existing tags or create new"
            :clearable="false"
        />
    </modal>
</template>

<script>
import vSelect from 'vue-select';
import { mapMutations, mapState } from 'vuex';
import { contactsClient } from '../../../../../services';
import debounce from '~/utils/debounce';
import Modal from '../../../../general/Modal.vue';
import tagsClient from '../../../../../services/tagsClient';

export default {
    name: 'AddTagModal',
    components: {
        vSelect,
        Modal,
    },
    data() {
        return {
            modalShow: false,
            tagsToSync: null,
            tagsOptions: [],
        }
    },
    mounted() {
        Bus.$on('showAddTagModal', () => {
            this.modalShow = true;
            this.searchTags();
        })
    },
    computed: {
        ...mapState({
            contact: (state) => state.contacts_v3.selectedContact,
        }),
    },
    methods: {
        ...mapMutations({
            'pushTag': 'contacts_v3/pushTag',
        }),
        searchTags: debounce(function(search, loading) {
            const teamId = Spark.state.currentTeam.id;
            loading?.(true)

            tagsClient.tagsGetAll(teamId, { query: search || null, per_page: 15 })
                .then(response => {
                    this.tagsOptions = response.data.data
                })
                .finally(() => {
                    loading?.(false);
                })
        }, 300),
        addTag(newTag) {
            const tagName = typeof newTag === 'string' ? newTag.toLowerCase() : newTag.tag_name.toLowerCase();
            const tag = {
                tag_name: tagName,
                id: null,
            }
            if (this.tagsOptions.findIndex((t) => t.tag_name === tag.tag_name) === -1) {
                this.tagsOptions = [...this.tagsOptions, tag]
            }

            this.tagsToSync = tagName;
        },
        syncTags() {
            if (this.tagsToSync) {
                const teamId = Spark.state.currentTeam.id;
                if (this.contact.tags.findIndex((t) => t.tag_name === this.tagsToSync) === -1) {
                    contactsClient.contactAddTag(teamId, this.contact.id, {
                        tag_name: this.tagsToSync
                    }).then(({data}) => {
                        const tag = {
                            id: data.id,
                            tag_name: data.tag_name,
                        }

                        this.pushTag({contact: this.contact, tag});

                        if (this.tagsOptions.findIndex((t) => t.tag_name === this.tagsToSync) === -1) {
                            this.tagsOptions = [...this.tagsOptions, tag]
                        }
                        this.$nextTick(() => {
                            this.modalShow = false;
                        })
                    }).catch((e) => {
                        console.error(e)
                    })
                } else {
                    this.modalShow = false;
                }
            }
        },
    }
}
</script>
