<template>
    <vx-expandable-card id="tags">
        <template v-slot:header> Tags </template>

        <template v-slot:content>
            <div class="vx-flex vx-gap-2 vx-mb-3 lg:vx-mb-6">
                <vx-selectable size="lg"
                    placeholder=""
                    :options="options"
                    :loading="searching"
                    :taggable="true"
                    @search="search"
                    v-model="name"
                    @option:selected="add()"
                    :error="displayFirstError(validate.name)">
                    <template v-slot:no-options>
                        Search or create a tag.
                    </template>
                </vx-selectable>
                <vx-button @click="add()"
                    data-test="tag-add"
                    size="lg"
                    type="button">
                    <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
            </div>
            <vx-icon-loading v-if="loadingTags"
                spin></vx-icon-loading>
            <vx-alert :closeable="false"
                v-else-if="!tags.length">
                No tags were added to this contact.
            </vx-alert>
            <div v-else>
                <vx-list-animated v-model="tags"></vx-list-animated>
            </div>
        </template>
    </vx-expandable-card>

</template>

<script setup>
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxAlert, VxButton, VxExpandableCard, VxIconLoading, VxListAnimated, VxSelectable } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { maxLength, requiredIf } from '@vuelidate/validators';
import { nextTick, ref } from 'vue';
import { tagsClient } from '~/services';
import debounce from '~/utils/debounce';
import { csvInjection, customAttributeKey, displayFirstError } from '~/utils/validation';
import { useContactDetails } from '../useContactDetails';

const teamId = Spark.state.currentTeam.id;

const { tags, loadingTags } = useContactDetails();

const name = ref('');
const options = ref([]);
const searching = ref(false);
const adding = ref(false);

const search = debounce((search) => {
    searching.value = true;

    tagsClient.tagsAutocomplete(teamId, {
        query: search
    }).then((response) => {
        options.value = response.data.data.map(tag => tag.value);
        searching.value = false;
    })
}, 200);

const validate = useVuelidate({
    name: {
        requiredIf: requiredIf(adding),
        maxLength: maxLength(80),
        format: customAttributeKey,
        csvInjection: csvInjection,
    }
}, {
    name,
}, {
    $autoDirty: true,
});

const add = async () => {
    adding.value = true;
    await nextTick();

    const isValid = await validate.value.$validate();
    if (!isValid) {
        setTimeout(() => {
            adding.value = false;
        }, 3500);
        return;
    }

    if (!tags.value.find(tagName => tagName === name.value)) {
        tags.value.unshift(name.value.toLowerCase());
    }

    name.value = '';
    adding.value = false;
}

</script>
