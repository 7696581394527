<template>
    <div v-if="team" class="vx-max-w-screen-sm vx-flex vx-flex-col vx-gap-4">
        <page-header>Team Profile</page-header>
        <update-team-details v-model:team="team" :isTeamAdmin="props.isTeamAdmin"></update-team-details>

        <template v-if="props.impersonator && props.isTeamAdmin">
            <update-groups-use-corporate-numbers v-if="FEATURES.contact_groups" v-model:team="team" />

            <update-team-dashboard v-model:team="team"></update-team-dashboard>

            <update-estimated-number-throughput :teamId="team.id" />

            <update-team-url :team="team"></update-team-url>
        </template>
    </div>
</template>

<script setup>
import UpdateTeamDetails from './UpdateTeamDetails.vue';
import UpdateTeamDashboard from './UpdateTeamDashboard.vue';
import UpdateTeamUrl from './UpdateTeamUrl.vue';
import UpdateGroupsUseCorporateNumbers from './UpdateGroupsUseCorporateNumbers.vue';
import UpdateEstimatedNumberThroughput from './UpdateEstimatedNumberThroughput.vue';
import PageHeader from '~/components/general/PageHeader.vue';
import { FEATURES } from '~/provides/features';

const team = defineModel('team', {
    type: Object,
    required: true,
});

const props = defineProps({
    isTeamAdmin: {
        type: Boolean,
    },
    impersonator: {
        type: Boolean,
    }
});
</script>
