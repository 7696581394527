import libphonenumber from 'google-libphonenumber';

export const e164Format = (phone) => {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const PNF = libphonenumber.PhoneNumberFormat;

    try {
        return phoneUtil.format(phoneUtil.parse(phone, 'US'), PNF.E164);
    } catch {
        return null;
    }
};

export const isValidPhoneNumber = (phone) => {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

    try {
        return Boolean(phoneUtil.isValidNumber(phoneUtil.parse(phone, 'US')));
    } catch (err) {
        console.error(err);
        return false;
    }
};
