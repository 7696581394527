<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        size="md"
        :padding="false">
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6">
                Launch Playbook Flow
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">
                Confirm you are ready to launch your flow.
            </div>
        </template>

        <div class="vx-p-10">
            <vx-alert v-if="error"
                color="danger"
                :closeable="false">
                {{ error }}
            </vx-alert>
            <vx-alert v-else-if="takingTooLong"
                :closeable="false">
                This is taking longer than expected please check back in 10 min.
            </vx-alert>
            <vx-alert v-else :closeable="false">
                <countdown-progress :max-steps="10" :start-step="startStep" @step="check()"
                    @finish="() => {
                        takingTooLong = !readyForLaunch;
                    }"
                    :completed="readyForLaunch">
                    <template v-if="readyForLaunch">
                        <div class="vx-flex vx-flex-col vx-gap-1">
                            <strong>Ready for Launch</strong>
                            <span>
                                We estimate that <strong>{{ audienceSize === 0 ? 'no' : `~${vxFormatCount(audienceSize)}` }} {{ pluralized('contact', audienceSize, false) }}</strong> will initially enter this playbook flow. You can pause or delete the flow from the flow screen at any point.
                            </span>
                        </div>
                    </template>
                    <template v-else>
                        Preparing your playbook flow for launch...
                    </template>

                    <template v-slot:info="slotProps">
                        <span class="vx-text-sky-500">
                            {{ readyForLaunch ? 'Preparations Complete!' :
                            `Calculating contacts, next update in ${slotProps.countdownSeconds} ${pluralized('second', slotProps.countdownSeconds, false)}.` }}
                        </span>
                    </template>
                </countdown-progress>
            </vx-alert>
        </div>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click.prevent="emit('update:visible', false)"
                    type="button"
                    class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                    size="lg"
                    color="muted">
                    Close
                </vx-button>
                <vx-button @click.prevent="launch()"
                    data-test="launch"
                    :disabled="!!error || !readyForLaunch"
                    :loading="launching"
                    type="button"
                    class="vx-grow"
                    size="lg"
                    color="primary">
                    Launch
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import {
    VxAlert,
    VxButton,
    VxModal,
    vxFormatCount,
} from "@voxie/frontend-components";
import dayjs from "~/utils/dayjs";
import { computed, nextTick, ref, watch } from "vue";
import flowsClient from "~/services/flowsClient";
import CountdownProgress from "../../components/CountdownProgress.vue";
import { getClientTz } from "~/utils/date";
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';
import { useToasted } from '~/composables/useToasted';
import { pluralized } from '~/components/filters';

const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
    flowId: {
        type: String,
    },
    flowStatus: {
        type: String,
    },
    flowCreatedAt: {
        type: String,
    }
});

const emit = defineEmits(['update:visible', 'launched']);

const toasted = useToasted();

const teamId = Spark.state.currentTeam.id;
const launching = ref(false);
const audienceSize = ref(undefined);
const error = ref(undefined);

const readyForLaunch = computed(() => audienceSize.value !== undefined && audienceSize.value !== null);
const takingTooLong = ref(false);
const startStep = ref(1);

watch([() => props.visible, () => props.flowId], async () => {
    await nextTick();

    error.value = undefined;
    audienceSize.value = undefined;

    if (!props.visible) {
        return;
    }

    await check();

    if (readyForLaunch.value) {
        return;
    }

    const diffInSecconds = dayjs().tz(getClientTz()).diff(dayjs(props.flowCreatedAt).tz(getClientTz()), 'second');
    const possibleStep = Math.round(diffInSecconds / 300 * 10);
    takingTooLong.value = possibleStep > 10;

    startStep.value = takingTooLong.value ? 10 : possibleStep || 1
}, {
    immediate: true
});

const launch = async () => {
    if (launching.value) {
        return;
    }

    try {
        await flowsClient.activate(teamId, props.flowId);
        emit('launched');
    } catch (e) {
        error.value = e.response?.data?.message;
        console.error(e);
    }
}

const check = async () => {
    try {
        const response = await flowsClient.check(teamId, props.flowId);
        audienceSize.value = response.data.audience_size;
    } catch (e) {
        audienceSize.value = undefined;

        console.error(e);

        if (e?.response?.status === HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
            return;
        }

        error.value = e.response?.data?.message === 'The audience is still being calculated.' ? undefined : e.response?.data?.message;
    }
}

</script>
