<template>
    <div>
        <div v-if="plans.length > 0">
            <!-- Team Status -->
            <team-status :team="team" :impersonator="isImpersonatorOrDev"></team-status>

            <!-- Trial Expiration Notice -->
            <subscription-notice
                :cashier-path="cashierPath"
                :team-subscription="teamSubscription"
                :team-last-payment-id="teamLastPaymentId"
                :active-subscription="activeSubscription"
                :team="team"
            />

            <!-- New Subscription -->
            <div v-if="needsSubscription && hasImpersonator">
                <subscribe-stripe
                    :team="team"
                    :teams="teams"
                    :plans="plans"
                    :form="form"
                    :cardForm="cardForm"
                    :stripe="stripe"
                    :countriesList="countriesList"
                    :showing-monthly-plans="showingMonthlyPlans"
                    :showing-yearly-plans="showingYearlyPlans"
                    :selected-plan="selectedPlan"
                    :tax-rate="taxRate"
                    @show-monthly-plans="showMonthlyPlans"
                    @show-yearly-plans="showYearlyPlans"
                    @plan-selected="selectPlan"
                    @subscribe="subscribe"
                />
            </div>

            <!-- Update Subscription -->
            <div v-if="subscriptionIsActive && hasImpersonator">
                <update-subscription
                    :team="team"
                    :teams="teams"
                    :plans="plans"
                    :active-subscription="activeSubscription"
                    :showing-monthly-plans="showingMonthlyPlans"
                    :showing-yearly-plans="showingYearlyPlans"
                    @show-monthly-plans="showMonthlyPlans"
                    @show-yearly-plans="showYearlyPlans"
                />
            </div>

            <!-- Resume Subscription -->
            <div v-if="subscriptionIsOnGracePeriod && hasImpersonator">
                <resume-subscription
                    :team="team"
                    :plans="plans"
                    :teams="teams"
                    :active-subscription="activeSubscription"
                    :showing-monthly-plans="showingMonthlyPlans"
                    :showing-yearly-plans="showingYearlyPlans"
                    @show-monthly-plans="showMonthlyPlans"
                    @show-yearly-plans="showYearlyPlans"
                />
            </div>

            <!-- Message Balance -->
            <div class="card card-default" v-show="hasMessageBalance">
                <div class="card-header">Message Balance</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-9">
                            <p>
                                The current message balance is: <b>{{ messageBalance }}</b>.
                            </p>
                        </div>
                        <div class="col-md-3 text-right">
                            <vx-button
                                v-if="isImpersonatorOrDev"
                                color="muted"
                                @click="isMessageBalanceModalVisible = true"
                            >Adjust</vx-button>
                        </div>
                    </div>
                    <modal-adjust-message-balance
                        v-if="isImpersonatorOrDev"
                            v-model="messageBalance"
                            v-model:visible="isMessageBalanceModalVisible"
                    >
                    </modal-adjust-message-balance>
                </div>
            </div>

            <!-- Credits used -->
            <div class="card card-default" v-if="rechargeAndNoRollover">
                <div class="card-header">Credits Used</div>
                <div class="card-body">
                    <div class="row m-0">
                        <div class="col p-0">
                            <vx-alert color="info" :closeable="false">
                                These numbers are not real-time. It can take a few hours for usage to show up here.
                            </vx-alert>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9">
                            <p>
                                Current month to date:
                                <template v-if="totalCreditsUsedMonth === undefined">
                                    <font-awesome-icon
                                        :icon="faSpinner"
                                        :spin="totalCreditsUsedMonth === undefined"
                                    ></font-awesome-icon>
                                </template>
                                <b v-else>{{ totalCreditsUsedMonth || 'N/A' }}</b>
                            </p>
                            <p>
                                Previous month:
                                <template v-if="totalCreditsUsedPreviousMonth === undefined">
                                    <font-awesome-icon
                                        :icon="faSpinner"
                                        :spin="totalCreditsUsedPreviousMonth === undefined"
                                    ></font-awesome-icon>
                                </template>
                                <b v-else>{{ totalCreditsUsedPreviousMonth || 'N/A' }}</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Cancel Subscription -->
            <div v-if="subscriptionIsActive">
                <cancel-subscription
                    :team="team"
                    :form="form"
                    :impersonator="hasImpersonator"
                    :team-id="teamId"
                ></cancel-subscription>
            </div>
        </div>

        <!-- Plan Features Modal -->
        <plan-details :detailingPlan="detailingPlan" />
    </div>
</template>

<script>
import TeamStatus from '~/components/settings/subscription/TeamStatus.vue';
import SubscriptionNotice from './SubscriptionNotice.vue';
import SubscribeStripe from './SubscribeStripe.vue';
import UpdateSubscription from './UpdateSubscription.vue';
import ResumeSubscription from './ResumeSubscription.vue';
import CancelSubscription from './CancelSubscription.vue';
import PlanDetails from '../../modals/PlanDetails.vue';
import { VxButton } from '@voxie/frontend-components';
import ModalAdjustMessageBalance from './ModalAdjustMessageBalance.vue';
import { getDateRange } from '../../../utils/analytics';
import analyticsClient from '../../../services/analyticsClient';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxAlert } from '@voxie/frontend-components';
import dayjs from '~/utils/dayjs';
import axios from '~/services/axios';

export default {
    props: [
        'team',
        'teams',
        'isImpersonatorOrDev',
        'cashierPath',
        'teamSubscription',
        'teamLastPaymentId',
        'teamMessageBalance',
        'countriesList',
        'hasImpersonator',
    ],
    components: {
        TeamStatus,
        SubscriptionNotice,
        SubscribeStripe,
        UpdateSubscription,
        ResumeSubscription,
        CancelSubscription,
        PlanDetails,
        VxButton,
        ModalAdjustMessageBalance,
        FontAwesomeIcon,
        VxAlert
    },
    data() {
        return {
            detailingPlan: null,
            cardElement: null,
            stripe: Spark.stripeKey ? Stripe(Spark.stripeKey, {
                apiVersion: Spark.stripeApiVersion
            }) : null,
            form: new SparkForm({
                use_existing_payment_method: this.hasPaymentMethod() ? '1' : '0',
                stripe_payment_method: '',
                plan: '',
                coupon: null,
                address: '',
                address_line_2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US',
            }),
            cardForm: new SparkForm({
                name: ''
            }),
            plans: [],
            showingMonthlyPlans: true,
            showingYearlyPlans: false,
            selectedPlan: null,
            taxRate: 0,
            isMessageBalanceModalVisible: false,
            messageBalance: this.teamMessageBalance,
            totalCreditsUsedMonth: undefined,
            totalCreditsUsedPreviousMonth: undefined,
            faSpinner
        };
    },
    watch: {
        'currentBillingAddress': function () {
            this.refreshTaxRate(this.form);
        }
    },
    mounted() {
        this.getPlans();
        this.initializeBillingAddress();
        this.loadCurrentMonthCreditsUsed();
        this.loadPreviousMonthCreditsUsed();

        Bus.$on('showPlanDetails', (plan) => {
            this.showPlanDetails(plan);
        });
    },
    methods: {
        async loadCurrentMonthCreditsUsed() {
            const currentMonthDates = getDateRange('month_today');

            try {
                const res = await analyticsClient.usageTotalByType(this.teamId, {
                    range: [currentMonthDates.start_date, currentMonthDates.end_date]
                });

                this.totalCreditsUsedMonth = this.calculateCreditsUsage(res.data?.data);
            } catch (error) {
                this.totalCreditsUsedMonth = null;

                console.error(error);
                this.$toasted.global.platform_error();
            }

        },
        async loadPreviousMonthCreditsUsed() {
            const previousMonth = getDateRange('previous_month');
            try {
                const res = await analyticsClient.usageTotalByType(this.teamId, {
                    range: [previousMonth.start_date, previousMonth.end_date]
                });

                this.totalCreditsUsedPreviousMonth = this.calculateCreditsUsage(res.data?.data);
            } catch (error) {
                this.totalCreditsUsedPreviousMonth = null;

                console.error(error);
                this.$toasted.global.platform_error();
            }

        },
        calculateCreditsUsage(data) {
            if (!data || !Array.isArray(data)) return null;

            let amount = 0;
            data.forEach((record) => {
                switch (record[['group_id']]) {
                    case 'mms_message':
                        amount += record['values'][0] * 2;
                        break;
                    case 'sms_segment':
                        amount += record['values'][0];
                        break;
                }
            });

            return amount;
        },
        refreshTaxRate(form) {
            axios.post('/tax-rate', JSON.parse(JSON.stringify(form)))
                .then(response => {
                    this.taxRate = response.data.rate;
                });
        },
        showMonthlyPlans() {
            this.showingMonthlyPlans = true;

            this.showingYearlyPlans = false;
        },
        showYearlyPlans() {
            this.showingMonthlyPlans = false;

            this.showingYearlyPlans = true;
        },
        hasPaymentMethod() {
            return this.team.card_last_four;
        },
        getPlans() {
            axios.get('/spark/plans')
                .then(response => {
                    this.plans = response.data.filter((billable) => billable.type === 'team')
                });
        },
        initializeBillingAddress() {
            this.form.address = this.team.billing_address;
            this.form.address_line_2 = this.team.billing_address_line_2;
            this.form.city = this.team.billing_city;
            this.form.state = this.team.billing_state;
            this.form.zip = this.team.billing_zip;
            this.form.country = this.team.billing_country || 'US';
        },
        selectPlan(plan) {
            this.selectedPlan = plan;

            this.form.plan = this.selectedPlan.id;
        },
        subscribe() {
            this.cardForm.errors.forget();

            this.form.startProcessing();

            if (this.form.use_existing_payment_method == '1') {
                return this.createSubscription();
            }

            const payload = {
                name: this.cardForm.name,
                address: {
                    line1: this.form.address || '',
                    line2: this.form.address_line_2 || '',
                    city: this.form.city || '',
                    state: this.form.state || '',
                    postal_code: this.form.zip || '',
                    country: this.form.country || '',
                }
            };

            this.generateToken(secret => {
                this.stripe.handleCardSetup(secret, this.cardElement, {
                    payment_method_data: {
                        billing_details: payload
                    }
                }).then(response => {
                    if (response.error) {
                        this.cardForm.errors.set({card: [
                                response.error.message
                            ]});

                        this.form.busy = false;
                    } else {
                        this.createSubscription(response.setupIntent.payment_method);
                    }
                });
            });
        },
        createSubscription(token) {
            this.form.stripe_payment_method = token;

            axios.post(this.urlForNewSubscription, this.form)
                .then(() => {
                    Bus.$emit('updateTeam');
                }).catch(errors => {
                    if (errors.response.status == 400) {
                        window.location = '/' + Spark.cashierPath + '/payment/' + errors.response.data.paymentId + '?redirect=' + this.urlForPlanRedirect;
                    } else {
                        this.form.setErrors(errors.response.data.errors);
                    }
                });
        },
        generateToken(callback){
            return axios.get('/stripe/token').then(response => callback(response.data.clientSecret));
        },
        showPlanDetails(plan) {
            this.detailingPlan = plan;

            $('#modal-plan-details').modal('show');
        },
    },
    computed: {
        urlForNewSubscription() {
            return `/settings/teams/${this.team.id}/subscription`;
        },
        urlForPlanRedirect() {
            return `/settings/teams/${this.team.id}%23/subscription`;
        },
        currentBillingAddress() {
            return this.form.address +
                this.form.address_line_2 +
                this.form.city +
                this.form.state +
                this.form.zip +
                this.form.country
        },
        needsSubscription() {
            return ! this.activeSubscription ||
                (this.activeSubscription.ends_at &&
                dayjs.utc().isAfter(dayjs.utc(this.activeSubscription.ends_at)));
        },
        subscriptionIsActive() {
            return this.activeSubscription && ! this.activeSubscription.ends_at;
        },
        subscriptionIsOnGracePeriod() {
            return this.activeSubscription &&
                this.activeSubscription.ends_at &&
                dayjs.utc().isBefore(dayjs.utc(this.activeSubscription.ends_at));
        },
        activeSubscription() {
            if (!this.team) {
                return;
            }

            const subscription = this.team.subscriptions?.find(
                (subscription) =>
                    subscription.name === 'default'
                    && !['incomplete', 'incomplete_expired', 'past_due', 'unpaid'].includes(subscription.stripe_status)
            );

            return subscription;
        },
        activePlan() {
            const activePlanId = this.activeSubscription?.provider_plan;

            return this.plans?.find((plan) => {
                return plan.id === activePlanId;
            });
        },
        hasMessageBalance() {
            return (this.activePlan?.attributes?.recharge ?? false) && !(this.activePlan?.attributes?.no_rollover ?? false);
        },
        rechargeAndNoRollover() {
            return (this.activePlan?.attributes?.recharge ?? false) && (this.activePlan?.attributes?.no_rollover ?? false);
        },
        teamId() {
            return Spark.state.currentTeam.id;
        }
    }
};
</script>
