<template>
    <vx-chart-categorical
        :loading="loading"
        :error="Boolean(error)"
        :data="dataComputed.data"
        :colors="dataComputed.colors"
        :axis="{
            x: {
                key: 'count',
            },
        }"
    >
        <template v-slot:title>
            <div class="vx-flex vx-justify-between vx-grow">
                <span>
                    Subscribers by Entry Point
                </span>
                <experimental-pill subject="Beta Feedback - Analytics"></experimental-pill>
            </div>
        </template>
        <template v-slot:subtitle>
            <slot name="subtitle"></slot>
        </template>
        <template v-slot:footer>
            <vx-button
                size="xs"
                href="/contacts"
                outline
                color="muted-light"
            >
                Go to Contacts
                <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
            </vx-button>
        </template>

        <template v-slot:explainHeader>Subscribers and Unsubscribers by Entry Point</template>
        <template v-slot:explainContent>
            <p>
                This chart allows you to evaluate which Entry Point produces the highest quantity of Subscribers as well as Unsubscribers.
            </p>
        </template>
    </vx-chart-categorical>
</template>

<script setup>
import {
    VxButton,
    VxChartCategorical,
} from "@voxie/frontend-components";
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import dayjs, { toUTCDateTimeFormat } from '~/utils/dayjs';
import { watch, computed } from 'vue';
import { analyticsClient } from '../../../../services';
import { getClientTz } from '../../../../utils/date';
import { entryPoint } from '~/components/filters';
import { generateLegendColors } from '../../../../utils/analytics';
import ExperimentalPill from '../../../general/ExperimentalPill.vue';
import { useVersionedRequest } from '~/composables/useVersionedRequest';

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
});

const calculateRange = () => {
    const startDate = dayjs(props.startDate).tz(getClientTz());
    const endDate = dayjs(props.endDate).tz(getClientTz());

    return [toUTCDateTimeFormat(startDate), toUTCDateTimeFormat(endDate)];
};

const { loading, response, error, fetchData } = useVersionedRequest();

const dataComputed = computed(() => {
    const dataMapped = (response.value?.data?.data || []).map((item) => ({
        label: entryPoint(item.group_id),
        count: item.values[0],
    }));

    return {
        data: dataMapped,
        colors: generateLegendColors(
            dataMapped.map((item) => item.label)
        ),
    }
});

watch(
    [() => props.startDate, () => props.endDate],
    () => {
       fetchData(analyticsClient.marketingSubscriptionsTotalOptInsByEntryPoint(Spark.state.currentTeam.id, {
            range: calculateRange(),
        })).catch((e) => {
            console.error(e);
        });
    },
    { immediate: true }
);
</script>
