<template>
    <div>
        <vx-selectable
            :size="props.size"
            :modelValue="props.modelValue"
            @update:modelValue="emit('update:modelValue', $event)"
            :reduce="option => option.id"
            :multiple="props.multiple"
            @search="search"
            :placeholder="props.placeholder"
            :options="options"
            :clearable="props.clearable"
            :deselectFromDropdown="true"
            :disabled="props.disabled"
        >
        </vx-selectable>
    </div>
</template>

<script setup>
import { VxSelectable } from '@voxie/frontend-components';
import campaignsClient from '../../../services/campaignsClient';
import { ref, onMounted, inject } from 'vue';
import debounce from '~/utils/debounce';

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    modelValue: {
    },
    disabled: {
        type: Boolean,
    },
    multiple: {
        type: Boolean,
    },
    clearable: {
        type: Boolean,
    },
    placeholder: {
        type: String,
        default: 'Select a campaign',
    },
    size: {
        type: String,
        default: 'md',
    },
    ids: {
        type: Array,
        default: () => ([])
    },
    flowId: {
        type: [String, undefined],
        default: undefined,
    }
});

const FEATURES = inject('FEATURES')

const emit = defineEmits(["update:modelValue"]);

const options = ref([]);

const search = debounce(async (search, loading) => {
    loading?.(true);

    try {
        let searchOptions = [];

        if (props.ids.length) {
            const idsResponse = await campaignsClient.campaignsGetRecords(teamId, {
                sort_field: 'id',
                sort_dir: 'desc',
                ids: props.ids,
                with_trashed: 1,
                flow: FEATURES.flows ? {
                    in_flow: props.flowId ? 1 : 0,
                    flow_id: props.flowId,
                } : undefined
            })
            searchOptions = idsResponse.data.data;
        }

        const response = await campaignsClient.campaignsGetRecords(teamId, {
            sort_field: 'id',
            sort_dir: 'desc',
            query: search,
            flow: FEATURES.flows ? {
                in_flow: props.flowId ? 1 : 0,
                flow_id: props.flowId,
            } : undefined
        });
        options.value = searchOptions.concat(response.data.data).map((campaign) => {
            return {
                ...campaign,
                label: `${campaign.id} - ${campaign.name}`,
            }
        });

    } finally {
        loading?.(false);
    }
}, 300);

onMounted(() => search());
</script>
