import { inject, onMounted, ref } from 'vue';
import { HTTP_UNPROCESSABLE_ENTITY } from '../../../../support/HttpStatuses';
import { useToasted } from '../../../../composables/useToasted';
import openAISettingsClient from '../../../../services/openAISettingsClient';
import { computed } from 'vue';

const defaultData = () => ({ company_pitch: '', settings: { role: '', tone: '' } });

export const useAiConfiguration = () => {
    const FEATURES = inject('FEATURES');
    const toasted = useToasted();

    const teamId = Spark.state.currentTeam.id;

    const data = ref(defaultData());
    const errors = ref({});
    const success = ref(false);
    const submitting = ref(false);
    const loading = ref(false);

    const mount = async () => {
        errors.value = {};
        success.value = false;
        submitting.value = false;
        loading.value = true;

        try {
            const response = (await openAISettingsClient.getConfig(teamId)).data;
            data.value = {
                id: response.id,
                company_pitch: response.company_pitch,
                settings: {
                    role: response.settings?.role || '',
                    tone: response.settings?.tone || '',
                },
            };
        } catch {
            data.value = defaultData();
        }

        loading.value = false;
    };

    onMounted(() => mount());

    const submit = async () => {
        if (submitting.value) {
            return;
        }

        errors.value = {};
        submitting.value = true;

        try {
            const payload = {
                ...data.value,
            };

            if (!FEATURES.message_hub_ai) {
                delete payload.settings;
            }

            if (data.value.id) {
                const response = (await openAISettingsClient.updateConfig(teamId, payload)).data;
                success.value = true;

                return response;
            } else {
                const response = (await openAISettingsClient.createConfig(teamId, payload)).data;
                data.value = response;
                success.value = true;

                return response;
            }
        } catch (error) {
            success.value = false;
            if (error?.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
                const responseErrors = error.response.data.errors;

                Object.keys(responseErrors).map((k) => {
                    errors.value[k] = responseErrors[k][0];
                });
            } else {
                toasted.global.platform_error();
            }

            throw error;
        } finally {
            submitting.value = false;
        }
    };

    const configured = computed(() => {
        if (FEATURES.message_hub_ai) {
            return Boolean(data.value?.id && data.value?.settings?.role && data.value?.settings?.tone);
        }

        return Boolean(data.value?.id);
    });

    return { errors, submitting, loading, submit, mount, data, success, configured };
};
