<template>
    <div class="vx-grow vx-w-full lg:vx-max-w-md">
        <vx-filterable :active="active"
            @toggle="toggleFilterable"
            data-test="flows-filters">
            <template v-slot:placeholder>
                <span class="vx-mr-2">Find</span>
                <vx-badge v-if="filtersCount > 0"
                    data-test="flows-reset"
                    :borders="false"
                    size="xs"
                    @click.stop="reset">
                    {{ filtersCount }} filters
                    <font-awesome-icon :icon="faXmarkCircle" />
                </vx-badge>
            </template>
            <template v-slot:form>
                <form @submit.prevent="emit('apply')">
                    <vx-label class="vx-mb-1">
                        Keyword Search
                    </vx-label>
                    <vx-input autofocus
                        type="text"
                        data-test="flows-query"
                        placeholder="Search"
                        :modelValue="props.modelValue.query"
                        @update:modelValue="
                            updateModelValue('query', $event)
                            "
                        maxlength="256"
                        class="vx-mb-4">
                        <template v-slot:right>
                            <font-awesome-icon class="vx-text-xs"
                                :icon="faMagnifyingGlass" />
                        </template>
                    </vx-input>

                    <div class="vx-flex vx-gap-2">
                        <vx-button data-test="flows-cancel"
                            type="button"
                            color="muted-light"
                            @click="
                                active = false;
                            emit('reset');
                            ">
                            Cancel
                        </vx-button>
                        <vx-button data-test="flows-apply"
                            type="submit"
                            block
                            @click="
                                active = false;
                            emit('apply');
                            ">
                            Apply
                            <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                        </vx-button>
                    </div>
                </form>
            </template>
        </vx-filterable>
    </div>
</template>

<script setup>
import {
    faCircleCheck,
    faMagnifyingGlass,
    faXmarkCircle
} from "@fortawesome/pro-solid-svg-icons";
import {
    VxBadge,
    VxButton,
    VxFilterable,
    VxInput,
    VxLabel
} from "@voxie/frontend-components";
import { computed, ref } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    loading: {
        type: Boolean,
        default: false,
    }
});

const emit = defineEmits(["update:modelValue", "apply", "reset", "new"]);

const active = ref(false);

const toggleFilterable = (event) => {
    active.value = event;
};

const updateModelValue = (key, value) => {
    emit("update:modelValue", {
        ...props.modelValue,
        [key]: value,
    });
};

const filtersCount = computed(
    () => (props.modelValue.query ? 1 : 0)
);

const reset = () => {
    emit("reset");
};
</script>
