import BaseClient from './BaseClient';

class CustomAttributesClient extends BaseClient {
    list(teamId, params) {
        const url = `/api/v3/teams/${teamId}/custom-attributes`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    delete(teamId, customAttributeId) {
        const url = `/api/v3/teams/${teamId}/custom-attributes/${customAttributeId}`;
        return this.makeCoreApiRequest(url, 'delete');
    }

    customAttributesAutocomplete($teamId, params) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/custom-attributes/autocomplete`;

        return this.makeCoreApiRequest(url, method, null, params);
    }

    customAttributesMeta() {
        const url = '/api/custom_attributes_meta';
        return this.makeCoreApiRequest(url);
    }
}

export default new CustomAttributesClient();
