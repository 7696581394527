<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons'

const props = defineProps({
  x: {
    type: Number,
    default: 34,
  },
  y: {
    type: Number,
    default: 10,
  },
  width: {
    type: Number,
    default: 167,
  },
  fill: {
    type: String,
    default: 'vx-fill-violet-300',
  },
  icon: {
    default: faCirclePlus,
  },
  label: {
    type: String,
  },
})

const height = 40
const iconBoxWidth = 64
const radius = 20
</script>

<template>
  <svg :x="props.x" :y="y" overflow="visible">
    <!-- background -->
    <rect
      x="0"
      y="0"
      fill="#fff"
      :width="label ? width : 100"
      :height="height"
      :rx="radius"
      class="vx-drop-shadow-md"
    />

    <!-- icon box -->
    <rect
      x="0"
      y="0"
      fill="#0ff"
      :width="iconBoxWidth"
      :height="height"
      :rx="radius"
      :class="fill"
    />

    <!-- icon box mask -->
    <rect
      :x="iconBoxWidth - 16"
      y="0"
      fill="#fff"
      :width="iconBoxWidth / 3"
      :height="height"
    />

    <font-awesome-icon class="vx-text-black" :icon="icon" width="18" height="18" x="18" :y="11" />

    <!-- label -->
    <text
      v-if="label"
      x="60"
      :y="height * 0.6"
      fill="#000"
      class="vx-text-sm vx-font-normal"
    >
      {{ label }}
    </text>
    <font-awesome-icon
      v-else
      :icon="faCirclePlus"
      width="18"
      :height="height"
      x="64"
      :y="0"
      class="vx-text-black"
    />

    <!-- border -->
    <rect
      x="0"
      y="0"
      :width="label ? width : 100"
      :height="height"
      :rx="radius"
      stroke="#fff"
      stroke-width="2"
    />
  </svg>
</template>
