<template>
    <div id="tlt-chat-messages" class="tlt-chat-messages" @scroll="handleScroll">
        <div v-for="(messageGroup, key) in messageGroups"
             :key="`t-message-group-${key}`"
             class="t-chat-messages-group">
            <span class="thread-full-date">{{ messageGroup.prettyDate }}</span>
            <template v-for="(message, index) in messageGroup.messages">
                <div id="recent-messages-line-wrap" class="recent-messages-line-wrap"
                    :key="`recent-messages-line-${key}-${message.id}-${index}`"
                     v-if="shouldShowNewLine({message, index})">
                    <span class="recent-messages-line"></span>
                    <span class="recent-messages-text">New</span>
                </div>
                <vx-message-cloud
                    v-if="message.body || message.media_url"
                    :key="`t-message-cloud-${message.id}`"
                    :messageId="message.id"
                    :mediaUrl="message.media_url"
                    :mediaContentType="message.media_content_type"
                    :messageBody="message.body"
                    :isInbound="message.direction === 'inbound'"
                    @mediaPlaceholderClick="($event) => mediaExpiredModal = !!$event.isFileExpired"
                >
                    <template v-slot:info>
                        <div v-if="message.body || message.media_url">
                            <span v-if="message.direction === 'outbound'">
                                <span v-if="message.origin_meta.type">{{ message.origin_meta.type }} • {{ message.origin_meta.description }} • </span>
                                <span v-else>Outbound • Ghost • </span>
                            </span>
                            <span v-else>Inbound • </span>
                            <span>{{ messageCreatedAt(message) }}</span>
                        </div>
                    </template>
                </vx-message-cloud>
            </template>
        </div>
        <vx-modal v-model:visible="mediaExpiredModal" class="vx-max-w-lg">
                <template v-slot:header>
                    <div class="vx-flex vx-flex-col vx-items-center vx-justify-center vx-gap-y-2">
                        <font-awesome-icon :icon="faImage" size="2xl" class="vx-text-rose-400"/>
                        <span class="vx-text-3xl vx-font-extrabold vx-text-center">Expired Media</span>
                    </div>
                </template>

                <div class="vx-flex vx-flex-col vx-items-center vx-justify-center vx-gap-y-8">
                    <p class="vx-text-base vx-font-normal vx-text-center vx-text-slate-500">
                        Images and videos are only kept by our MMS provider for a short time. After this time, the media will disappear. Instead of deleting the message entirely, we show a placeholder icon so you can see when the media was sent or received.
                    </p>
                    <div class="vx-flex vx-items-center vx-justify-center vx-w-full">
                        <vx-button
                            @click="mediaExpiredModal = false"
                            size="lg"
                            type="button"
                            block
                            color="primary"
                            class="lg:vx-mt-6 vx-max-w-[269px]"
                        >
                            Got It
                        </vx-button>
                </div>
                </div>
            </vx-modal>
    </div>
</template>
<script>
import { VxMessageCloud } from '@voxie/frontend-components-v3';
import { VxModal, VxButton } from '@voxie/frontend-components';
import { mapState, mapGetters, mapActions } from 'vuex';
import debounce from '~/utils/debounce';
import dayjs from '~/utils/dayjs';
import { faImage } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { toShortDateAtTime } from '~/components/../utils/date';

export default {
    name: 'Messages',
    components: {
        FontAwesomeIcon,
        VxMessageCloud,
        VxModal,
        VxButton,
    },
    inject: ['FEATURES'],
    props: {
        showNewLine: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            isNearBottom: false,
            canLoadMore: false,
            prevScrollPosition: 0,
            mediaExpiredModal: false,
            faImage: faImage,
        };
    },
    mounted() {
        let newLine = $('#recent-messages-line-wrap');
        if (newLine) {
            let offset = newLine.offset();
            let offsetHeight = newLine.outerHeight();
            if (offset && offset.top) {
                this.scrollMessagesTo(offset.top - (offsetHeight || 0) - 250);
            }
        }

        if (this.FEATURES.simple_message_hub && this.activeThread.status !== 'closed') {
            this.changeState({thread: this.activeThread, state: 'closed'});
        }

        setTimeout(() => {
            this.canLoadMore = true;
        }, 1000)
    },
    computed: {
        ...mapGetters({
            'activeThread': 'getActiveThread',
        }),
        ...mapState({
            selectedContact: (state) => state.contacts_v3.selectedContact,
        }),
        messageGroups() {
            const messagesToDay = ([day, group]) => {
                return {
                    fullDate: day,
                    prettyDate: dayjs(day).format('MMMM D, YYYY'),
                    messages: group.sort((mA, mB) => mA.id - mB.id)
                };
            };

            const groups = this.messages.reduce((groups, message) => {
                    const groupKey = dayjs(message.created_at).startOf('day').toString();
                    if (!groups[groupKey]) {
                        groups[groupKey] = [];
                    }

                    groups[groupKey].push(message);

                    return groups;
            }, {});

            return Object.entries(groups).map(messagesToDay).sort((gA, gB) => {
                return new Date(gB.fullDate).getTime() - new Date(gA.fullDate).getTime();
            });
        },
        messages() {
            return this.selectedContact.messages || [];
        },
        inboundUnreadMessages() {
            return this.messages.filter(m => {
                return m.direction === 'inbound' || (m.origin_meta.type === 'Message Hub' && m.origin_meta.id !== Spark.state.user.id)
            }).slice(0, this.activeThread.unread);
        },
        lastUnread() {
            return this.inboundUnreadMessages[this.inboundUnreadMessages.length - 1];
        },
    },
    watch: {
        messageGroups: {
            handler() {
                this.$nextTick(() => {
                    if (this.isNearBottom) {
                        this.scrollMessagesTo(0);
                    }
                });
            },
            deep: true,
        },
    },
    methods: {
        messageCreatedAt(message) {
            return toShortDateAtTime(message.created_at);
        },
        ...mapActions({
            'changeState': 'changeState',
            'loadMoreMessages': 'contacts_v3/loadMoreMessages',
        }),
        isUserNearBottom(el) {
            const threshold = 10;
            return el.scrollTop + threshold >= 0;
        },
        isUserNearTop(el) {
            const threshold = 50;
            const scrollHeight = el.scrollHeight;
            const scrollTop = el.scrollTop;
            const clientHeight = el.clientHeight;

            const scrollPosition = (scrollTop - clientHeight) * -1;

            return scrollPosition + threshold >= scrollHeight
        },
        handleScroll: debounce(function(evt) {
            this._handleScroll(evt)
        }, 300),
        _handleScroll(evt) {
            this.isNearBottom = this.isUserNearBottom(evt.target);
            if (this.isUserNearTop(evt.target) && this.canLoadMore) {
                this.loadMoreMessages({$toasted: this.$toasted})
            }
        },
        scrollMessagesTo(value) {
            let $chatMessages = $('#tlt-chat-messages');

            $chatMessages.scrollTop(value);
        },
        removeNewLine() {
            this.$emit('removeNewLine');
        },
        shouldShowNewLine({message}) {
            return (
                this.showNewLine && this.activeThread && this.lastUnread && message.id === this.lastUnread.id && (message.body || message.media_url)
            )
        },
    },
};
</script>

<style scoped>

</style>
