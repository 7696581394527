<template>
    <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.46854 10.9383H1.53146C1.2934 10.9383 1.16047 11.1896 1.3079 11.3612L5.27644 15.963C5.39003 16.0947 5.60876 16.0947 5.72356 15.963L9.6921 11.3612C9.83953 11.1896 9.7066 10.9383 9.46854 10.9383Z"
            fill="black"
            :fill-opacity="sortDir === 'desc' ? 1 : 0.25"
        />
        <path
            d="M9.6921 7.63879L5.72356 3.03702C5.60997 2.9053 5.39124 2.9053 5.27644 3.03702L1.3079 7.63879C1.16047 7.81039 1.2934 8.06174 1.53146 8.06174H9.46854C9.7066 8.06174 9.83953 7.81039 9.6921 7.63879Z"
            fill="black"
            :fill-opacity="sortDir === 'asc' ? 1 : 0.25"
        />
    </svg>
</template>

<script>
export default {
    props: ['sortDir'],
};
</script>
