<template>
    <div class="card card-default">
        <div class="card-header">Two-Factor Authentication</div>

        <div class="card-body">
            <button class="btn btn-outline-danger" @click="disable" :disabled="form.busy">
                <span v-if="form.busy"> <i class="fa fa-btn fa-spinner fa-spin"></i> Disabling </span>

                <span v-else> Disable Two-Factor Authentication </span>
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';

defineProps({
    user: {
        type: Object,
        required: true
    }
});

const form = ref(new SparkForm({}));

const disable = () => {
    Spark.delete('/settings/two-factor-auth', form.value).then(() => {
        Bus.$emit('updateUser');
    });
};
</script>
