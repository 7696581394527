import BaseClient from './BaseClient';

class FlowsClient extends BaseClient {
    list(teamId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/flows`;

        return this.makeCoreApiRequest(url, method);
    }

    create(teamId, data) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/flows`;

        return this.makeCoreApiRequest(url, method, data);
    }

    createOrUpdate(teamId, data) {
        if (data.flow_id) {
            return this.update(teamId, data.flow_id, data);
        }

        return this.create(teamId, data);
    }

    get(teamId, flowId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/flows/${flowId}`;

        return this.makeCoreApiRequest(url, method);
    }

    update(teamId, flowId, data) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/flows/${flowId}`;

        return this.makeCoreApiRequest(url, method, data);
    }

    getConfig(teamId, flowId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/flows/${flowId}/config`;

        return this.makeCoreApiRequest(url, method);
    }

    saveConfig(teamId, flowId, data) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/flows/${flowId}/config`;

        return this.makeCoreApiRequest(url, method, data);
    }

    check(teamId, flowId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/flows/${flowId}/check`;

        return this.makeCoreApiRequest(url, method);
    }

    activate(teamId, flowId) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/flows/${flowId}/activate`;

        return this.makeCoreApiRequest(url, method);
    }

    pause(teamId, flowId) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/flows/${flowId}/pause`;

        return this.makeCoreApiRequest(url, method);
    }

    unpause(teamId, flowId) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/flows/${flowId}/unpause`;

        return this.makeCoreApiRequest(url, method);
    }

    delete(teamId, flowId) {
        const method = 'delete';
        const url = `/api/v3/teams/${teamId}/flows/${flowId}`;

        return this.makeCoreApiRequest(url, method);
    }
}

export default new FlowsClient();
