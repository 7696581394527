<template>
    <div>
        <div class="c-card promotion-details u-text-small">
            <form class="promotion-details__form" @submit.prevent="submitForm">
                <div class="promotion-details__form__fields form-group row">
                    <div class="col-md-6">
                        <div class="c-field">
                            <label class="c-field__label">Name</label>

                            <input name="name" v-model="form.name" placeholder="Winter 25% Off" class="c-input" type="text">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="c-field">
                            <label class="c-field__label">Message Variable Code</label>

                            <input name="code" v-model="form.code" placeholder="winter_25off" class="c-input" type="text">
                        </div>
                    </div>
                </div>
                <div class="promotion-details__form__buttons form-group row">
                    <div class="col-md-12">
                        <div class="text-right w-100">
                            <button class="c-btn c-btn--success u-ml-small mr-1" type="submit">{{ promotion.id ? 'Update' : 'Create' }} Promotion</button>
                            <button class="c-btn c-btn--secondary" type="button" @click="cancelForm">Close</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <promotion-coupons-import v-if="promotion.id" :promotion="promotion" />

        <promotion-coupons-list v-if="promotion.id"
                                :team-id="teamId"
                                :promotion-id="promotion.id"
        />
    </div>
</template>

<script setup>

import {reactive, ref} from 'vue';
import {promotionsClient} from '../../services';
import Swal from 'sweetalert2';
import PromotionCouponsImport from './PromotionCouponsImport.vue';
import { useToasted } from '../../composables/useToasted';
import PromotionCouponsList from './PromotionCouponsList.vue';

const $toasted = useToasted();

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    promotion: {
        type: Object,
        required: false,
    }
})

const form = reactive({
    name: props.promotion.name || '',
    code: props.promotion.code || '',
    coupon_vendor: props.promotion.coupon_vendor || 'Voxie',
})

const formIsSubmitting = ref(false)

const submitForm = () => {
    formIsSubmitting.value = true;

    if (!validate()) {
        return;
    }

    promotionsClient.savePromotion(teamId, props.promotion.id, form).then(r => {
        formIsSubmitting.value = false;

        Swal.fire({
            title: 'Success!',
            text: props.promotion.id ? 'Promotion was successfully updated' : 'Promotion was successfully created',
            icon: 'success',
        });

        Bus.$emit(
            props.promotion.id ? 'promotionUpdated' : 'promotionCreated',
            {
                ...r.data,
                coupons_count: props.promotion.coupons_count || 0,
                allocated_coupons_count: props.promotion.allocated_coupons_count || 0,
            }
        );

        if (props.promotion.id) {
            Bus.$emit('showPromotionsList');

            return;
        }

        Bus.$emit('promotionEdit', r.data)

    }).catch(() => {
        formIsSubmitting.value = false;

        $toasted.global.platform_error();
    });
}

const cancelForm = () => {
    Bus.$emit("showPromotionsList");
}

const validate = () => {
    const errors = [];

    if (!form.name) {
        errors.push('Name field is required!');
    }

    if (!form.code) {
        errors.push('Message Variable Code field is required!');
    }

    if (errors.length === 0) {
        return true;
    }

    const errorMessage = `<p>We found the following errors:</p><br/>${errors.join("<br/>")}`;

    Swal.fire({
        title: 'Oops..',
        html: errorMessage,
        icon: 'error',
    });

    return false;
};

</script>

<style lang="postcss" scoped>
.promotion-details {
    padding: 26px 26px 11px 26px;
    margin-bottom: 30px;
}

.promotion-details__form__buttons {
    margin-top: 19px;
}
</style>
