<template>
    <div>
        <div class="card card-default">
            <div class="card-header">Create Webhook</div>
            <div class="card-body">
                <form role="form" @submit.prevent="onCreate" id="createWebhookForm">
                    <div class="form-group row">
                        <label for="webhookUrl" class="col-md-4 col-form-label text-md-right">Webhook URL</label>
                        <div class="col-md-6">
                            <input
                                id="webhookUrl"
                                v-model="form.url"
                                type="url"
                                class="form-control"
                                :class="{ 'is-invalid': form.errors.has('url') }"
                                placeholder="http://"
                            >
                            <span class="invalid-feedback" v-show="form.errors.has('url')">
                                {{ form.errors.get('url') }}
                            </span>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="webhookEvent" class="col-md-4 col-form-label text-md-right">Event Type</label>
                        <div class="col-md-6">
                            <select
                                id="webhookEvent"
                                v-model="form.event"
                                class="form-control"
                                name="event"
                                :class="{ 'is-invalid': form.errors.has('event') }"
                            >
                                <option v-for="(option,index) in availableEvents" :value="option.event" :key="index">{{ option.name }}</option>
                            </select>
                            <span class="invalid-feedback" v-show="form.errors.has('event')">
                                {{ form.errors.get('event') }}
                            </span>
                        </div>
                    </div>

                    <div class="form-group row mb-0">
                        <div class="offset-md-4 col-md-6">
                            <button type="submit" class="btn btn-primary" :disabled="form.busy">Create</button>
                        </div>
                    </div>
                </form>
            </div>
        </div><!-- .card -->

        <div class="card card-default" v-if="webhooks.length">
            <div class="card-header">Webhooks</div>
            <div class="table-responsive">
                <table class="table table-borderless">
                    <thead>
                    <tr>
                        <th scope="col">Event Type</th>
                        <th scope="col">Webhook URL</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="webhook in webhooks" :key="webhook.id">
                        <th scope="row">{{ toReadableName(webhook.event) }}</th>
                        <td>{{ webhook.url }}</td>
                        <td class="d-flex align-items-center justify-content-center flex-nowrap">
                            <button type="button" class="btn-reset link-dark" title="Edit" @click.prevent="modalUpdate(webhook)">
                                <svg class="align-text-bottom" width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3.994 12.964l3.106 3.105-4.112.931 1.006-4.036zm9.994-3.764l-5.84 5.921-3.202-3.202 5.841-5.919 3.201 3.2z"/>
                                </svg>
                            </button>
                            <button type="button" class="btn-reset link-dark ml-2" title="Remove" @click.prevent="onDelete(webhook)">
                                <svg class="align-text-bottom" width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5 15.538l-3.592-3.548 3.546-3.587-1.416-1.403-3.545 3.589-3.588-3.543-1.405 1.405 3.593 3.552-3.547 3.592 1.405 1.405 3.555-3.596 3.591 3.55 1.403-1.416z"/>
                                </svg>
                            </button>
                            <div class="modal fade" :id="`modalUpdateWebhook${webhook.id}`" tabindex="-1" role="dialog" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title">Edit Webhook</h4>
                                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                                        </div>
                                        <div class="modal-body">
                                            <form role="form">
                                                <div class="form-group row">
                                                    <label for="updateWebhookUrl" class="col-md-4 col-form-label text-md-right">Webhook URL</label>
                                                    <div class="col-md-6">
                                                        <input
                                                            id="updateWebhookUrl"
                                                            type="text"
                                                            class="form-control"
                                                            name="url"
                                                            v-model="updateForm.url"
                                                            :class="{ 'is-invalid': updateForm.errors.has('url') }"
                                                        >
                                                        <span class="invalid-feedback" v-show="updateForm.errors.has('url')">
                                                            {{ updateForm.errors.get('url') }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="updateWebhookEvent" class="col-md-4 col-form-label text-md-right">Select Event</label>
                                                    <div class="col-md-6">
                                                        <select
                                                            id="updateWebhookEvent"
                                                            class="form-control"
                                                            name="event"
                                                            v-model="updateForm.event"
                                                            :class="{ 'is-invalid': updateForm.errors.has('event') }"
                                                        >
                                                            <option v-for="(option,index) in availableEvents" :value="option.event" :key="index">{{ option.name }}</option>
                                                        </select>
                                                        <span class="invalid-feedback" v-show="updateForm.errors.has('event')">
                                                            {{ updateForm.errors.get('event') }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary" @click="onUpdate(webhook)" :disabled="updateForm.busy">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div><!-- .card -->
    </div>
</template>

<script>
import { webhooksClient } from '../../../services';

export default {
    data() {
        return {
            team_id: Spark.state.currentTeam.id,
            webhooks: [],
            selectedWebhook: null,
            availableEvents: [],
            form: new SparkForm({
                url: '',
                event: '',
            }),
            updateForm: new SparkForm({
                url: '',
                event: '',
            }),
        };
    },

    mounted() {
        this.fetchWebhooks();
        this.fetchAvailableEvents();
    },

    methods: {
        onCreate() {
            webhooksClient
                .createWebhook(this.team_id, this.form)
                .then(() => this.fetchWebhooks())
                .catch(() => this.$toasted.global.platform_error())
                .finally(() => {
                    this.form.url = '';
                    this.form.event = '';
                });
        },

        onUpdate: function (webhook) {
            webhooksClient
                .updateWebhook(this.team_id, webhook.id, this.updateForm)
                .then(() => {
                    this.fetchWebhooks();
                    $(`#modalUpdateWebhook${webhook.id}`).modal('hide');
                    this.updateForm.url = '';
                    this.updateForm.event = '';
                })
                .catch(() => this.$toasted.global.platform_error());
        },

        onDelete(webhook) {
            webhooksClient
                .deleteWebhook(this.team_id, webhook.id)
                .then(() => this.fetchWebhooks())
                .catch(() => this.$toasted.global.platform_error());
        },

        modalUpdate(webhook) {
            this.updateForm.url = webhook.url;
            this.updateForm.event = webhook.event;

            $(`#modalUpdateWebhook${webhook.id}`).modal('show');
        },

        fetchWebhooks() {
            webhooksClient.getWebhooks(this.team_id).then((response) => (this.webhooks = response.data));
        },

        fetchAvailableEvents() {
            webhooksClient.getWebhookEvents(this.team_id).then((response) => (this.availableEvents = response.data));
        },

        toReadableName(className) {
            let readable = '[ Unsupported Event ]';

            this.availableEvents.forEach((option) => {
                if (option.event === className) {
                    readable = option.name;
                }
            });

            return readable;
        },
    },
}
</script>
