<template>
    <div class="vx-flex vx-items-center">
        <vx-dropdown ref="dropdownRef" :teleport="true">
            <vx-button data-test="open-actions" :disabled="props.disabled"
                color="transparent"
                type="button">
                <font-awesome-icon class="vx-text-sm"
                    :icon="faCirclePlus"></font-awesome-icon>
            </vx-button>

            <template v-slot:items>
                <slot></slot>
                <vx-dropdown-item v-if="!props.disableSnippets"
                    data-test="snippets-action"
                    @click.prevent="emit('click:snippets')">
                    <span>
                        <font-awesome-icon :icon="faCubes"
                            class="vx-mr-1 vx-text-sm"></font-awesome-icon>
                        Snippets
                    </span>
                </vx-dropdown-item>
                <vx-dropdown-item @click.prevent="emit('click:variables')">
                    <span>
                        <font-awesome-icon :icon="faCube"
                            class="vx-mr-1 vx-text-sm"></font-awesome-icon>
                        Variables
                    </span>
                </vx-dropdown-item>
                <vx-dropdown-item v-if="!props.disableMedia" @click.prevent="emit('click:media')">
                    <span>
                        <font-awesome-icon :icon="faImage"
                            class="vx-mr-1 vx-text-sm"></font-awesome-icon>
                        Media
                    </span>
                </vx-dropdown-item>
            </template>
        </vx-dropdown>
        <div class="vx-w-px vx-h-4 vx-bg-slate-200"></div>
        <vx-button @click.prevent="emit('click:emojis')"
            :disabled="props.disabled"
            color="transparent"
            type="button">
            <font-awesome-icon class="vx-text-sm"
                :icon="faFaceGrin"></font-awesome-icon>
        </vx-button>
    </div>
</template>

<script setup>
import { faCube, faCubes, faFaceGrin } from '@fortawesome/pro-regular-svg-icons';
import { faCirclePlus, faImage } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxButton, VxDropdown, VxDropdownItem } from '@voxie/frontend-components';
import { computed, ref } from 'vue';

const props = defineProps({
    disabled: Boolean,
    disableSnippets: Boolean,
    disableMedia: Boolean,
});

const dropdownRef = ref();
const open = computed(() => {
    if (!dropdownRef.value) {
        return false;
    }

    return dropdownRef.value.open;
})

const emit = defineEmits(['click:variables', 'click:snippets', 'click:emojis', 'click:media'])

defineExpose({
    open: open,
});
</script>
