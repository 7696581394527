import BaseClient from './BaseClient';

class ScheduledMessagesClient extends BaseClient {
    getTeamScheduledMessages(teamId, params) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/scheduled-messages`;

        return this.makeCoreApiRequest(url, method, null, params);
    }

    getContactScheduledMessages(teamId, contactId, params = {}, config = {}) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/contacts/${contactId}/scheduled-messages`;

        params.status = 'pending';
        params.per_page = 3;

        return this.makeCoreApiRequest(url, method, null, params, config);
    }

    createScheduledMessage(teamId, contactId, data) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/contact/${contactId}/scheduled-messages`;

        return this.makeCoreApiRequest(url, method, data);
    }

    deleteScheduledMessage(teamId, scheduledMessageId) {
        const method = 'delete';
        const url = `/api/v3/teams/${teamId}/scheduled-messages/${scheduledMessageId}`;

        return this.makeCoreApiRequest(url, method);
    }
}

export default new ScheduledMessagesClient();
