import BaseClient from './BaseClient';

class TeamsClient extends BaseClient {
    activate(teamId) {
        const url = `/settings/teams/${teamId}/activate`;
        return this.makeCoreApiRequest(url, 'post');
    }

    suspend(teamId, data) {
        const url = `/settings/teams/${teamId}/suspend`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    getTeamAgents(teamId) {
        const url = `/api/v3/teams/${teamId}/get-agents`;
        return this.makeCoreApiRequest(url);
    }

    getShiftToTextMessage(teamId) {
        const url = `/api/v3/teams/${teamId}/shift-to-text/message`;
        return this.makeCoreApiRequest(url);
    }

    updateShiftToTextMessage(teamId, message) {
        const url = `/api/v3/teams/${teamId}/shift-to-text/message`;
        return this.makeCoreApiRequest(url, 'post', {
            message_content: message,
        });
    }

    getShiftToTextRedirect(teamId) {
        const url = `/api/v3/teams/${teamId}/shift-to-text/redirect`;
        return this.makeCoreApiRequest(url);
    }

    updateShiftToTextRedirect(teamId, redirect) {
        const url = `/api/v3/teams/${teamId}/shift-to-text/redirect`;
        return this.makeCoreApiRequest(url, 'post', { redirect });
    }

    getFeatures(teamId) {
        const url = `/api/v3/teams/${teamId}/features`;
        return this.makeCoreApiRequest(url);
    }

    enableFeature(teamId, featureId) {
        const url = `/api/v3/teams/${teamId}/features/${featureId}`;
        return this.makeCoreApiRequest(url, 'post');
    }

    disableFeature(teamId, featureId) {
        const url = `/api/v3/teams/${teamId}/features/${featureId}`;
        return this.makeCoreApiRequest(url, 'delete');
    }

    adjustMessageBalance(teamId, data) {
        const url = `/settings/teams/${teamId}/adjust-message-balance`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    countShopifyStores(teamId, params) {
        const url = `/api/v3/teams/${teamId}/shopify/count`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    updateBillingEmail(teamId, data) {
        return this.makeCoreApiRequest(`/settings/teams/${teamId}/billing-contact-email-address`, 'put', {
            data,
        });
    }
    updateDetails(teamId, data) {
        return this.makeCoreApiRequest(`/settings/teams/${teamId}/name`, 'put', data);
    }
}

export default new TeamsClient();
