<template>
    <div class="promotion-coupons-import-file">
        <download-csv-template-sample :file-url="csvUrl"></download-csv-template-sample>
        <csv-file-drop-zone ref="csvDropZone" :max-size="20971520" @added="uploadCouponsFile" @error="handleErrorOfUploadCouponsFile"/>

        <div class="promotion-coupons-import-file__list" v-if="importTasksList.length">
            <h2>
                Coupons Imported Tasks
                <i v-if="loadingTasks" class="fa fa-spin fa-circle-o-notch"/>
            </h2>
            <table class="c-table u-mb-small u-border-none u-border-left-zero u-border-right-zero">
                <tbody>
                    <tr class="c-table__row" v-for="task in importTasksList" :key="task.id">
                        <td class="c-table__cell status-col hidden-xs">
                            <div :class="stateCssClasses(task)">{{ task.status }}</div>
                        </td>

                        <td class="c-table__cell hidden-xs">
                            <span>
                            Coupons Import #{{ task.id }}
                            </span>

                            <div class="u-text-mute">File {{ task.file.name }}.{{ task.file.extension }}</div>

                            <small class="u-block u-text-mute" v-if="task.started_at" data-testid="started-date">Started {{ dateTime(task.started_at) }}</small>
                        </td>

                        <td class="c-table__cell u-text-center" data-test="rows-total">
                            <span class="huge-text">{{ task.total || '-' }}</span>
                            <small class="u-text-mute" v-if="task.total">coupons</small>

                            <div v-if="showProgressBar(task)" class="c-progress c-progress--success hidden-xs">
                                <div class="c-progress__bar" :style="progressBarWidth(task)"></div>
                            </div>
                        </td>

                        <td class="c-table__cell u-text-right">
                            <span class="u-color-success">{{ task.processed || 0 }} imported</span>
                            /
                            <span class="u-color-danger">
                                {{ task.errors || 0 }} errors
                            </span>
                            <small v-if="task.completed_at" class="u-block u-text-mute hidden-xs" data-testid="completed-date">
                                {{ task.status === STATUS_FAILED ? 'Failed' : 'Completed' }} {{ dateTime(task.completed_at) }}
                            </small>
                        </td>
                    </tr>
                </tbody>
            </table>
            <pagination-nav
                :page="pagination.page"
                :total="pagination.total"
                :perPage="pagination.perPage"
                :disabled="loadingTasks"
                @update:page="changePage"
            />
        </div>
    </div>
</template>

<script setup>
import { isAuthErrorEcho } from '../../../utils/helpers';
import {reactive, onMounted, ref, nextTick} from 'vue';
import DownloadCsvTemplateSample from '../../general/DownloadCsvTemplateSample.vue'
import CsvFileDropZone from '../../general/CsvFileDropZone.vue'
import PaginationNav from '../../general/PaginationNav.vue';
import Swal from 'sweetalert2';
import { useToasted } from '../../../composables/useToasted';
import { dateTime } from '../../filters';
import { promotionCouponsClient } from '../../../services';
import csvUrl from '@csv/coupons_csv_upload_template.csv';

const props = defineProps({
    promotion: {
        type: Object,
        required: true,
    }
})
const team = Spark.state.currentTeam;

const echoErrors = ref([]);
onMounted(() => {
    loadImportTasks();

    Echo.private(`promotion_coupon_import.${team.id}`)
            .listen('PromotionCouponImportUpdated', ({promotion_coupon_import}) => updateCouponImportTask(promotion_coupon_import))
            .error?.((e) => {
                if (echoErrors.value.length <= 3 && !isAuthErrorEcho(e)) {
                    window?.Bugsnag?.notify(e instanceof Error ? e : new Error(JSON.stringify(e)));
                    echoErrors.value.push(e);
                }
            });
})

const $toasted = useToasted();

const loadingTasks = ref(true);
const importTasksList = ref([]);
const pagination = reactive({
   page: 1,
   total: 0,
   perPage: 0,
});
const loadImportTasks = async () => {
    let tasks = await promotionCouponsClient.getPromotionCouponsImports(team.id, props.promotion.id, { page: pagination.page });

    importTasksList.value = tasks.data.data;
    pagination.total = tasks.data.total;
    pagination.page = tasks.data.current_page;
    pagination.perPage = tasks.data.per_page;

    loadingTasks.value = false;
}

const changePage = (page) => {
    pagination.page = page;

    nextTick(() => {
        loadImportTasks();
    });
}

const showProgressBar = (task)=> {
    return (task.errors + task.processed) < task.total;
};

const progressBarWidth = (task) => {
    let percent = 0;
    const completed = task.errors + task.processed;

    if (completed > 0) {
        percent = (completed / task.total) * 100;
    }

    if (percent > 100) {
        percent = 100;
    }

    return `width:${percent}%;`;
};

const STATUS_PROCESSING = 'processing';
const STATUS_COMPLETED = 'completed';
const STATUS_FAILED = 'failed';
const stateCssClasses = (task) => {
    let type;

    switch (task.status) {
        case STATUS_PROCESSING:
            type = 'warning';
            break;

        case STATUS_COMPLETED:
            type = 'success';
            break;

        case STATUS_FAILED:
            type = 'danger';
            break;

        default:
            type = 'secondary';
    }

    return `c-badge c-badge--${type}`;
};

const updateCouponImportTask = (importTask) => {
    importTasksList.value = importTasksList.value.map((task) => {
        if (task.id !== importTask.id) {
            return task;
        }

        if (task.completed_at !== null) {
            return task;
        }

        return {...task, ...importTask};
    });
}

const csvDropZone = ref();
const handleUploadProgress = (file, progress) => {
      csvDropZone.value.updateProgress(file, progress);
};

const uploadCouponsFile = async (file) => {
    try {
        const {data} = await promotionCouponsClient.generateImportUploadUrl(team.id, props.promotion.id, file.name);
        await promotionCouponsClient.importCsvFileToS3(data.data.url, file.file, progress => {
            handleUploadProgress(file, progress)
        });

        await promotionCouponsClient.importCoupons(team.id, props.promotion.id, data.data.file.id);
        loadImportTasks();
    } catch (e) {
        console.error(e)
        $toasted.global.platform_error()
    }

    csvDropZone.value.removeFile(file);
}

const handleErrorOfUploadCouponsFile = (message) => {
    Swal.fire({
        title: 'Oops...',
        html: message,
        icon: 'error'
    });
}

</script>

