<template>
    <vx-filterable :active="active" @toggle="toggleFilterable">
        <template v-slot:placeholder>
            <span class="vx-mr-2">Search</span>
            <vx-badge v-if="filtersCount > 0" :borders="false" size="xs" @click.stop="reset">
                {{ filtersCount }} filters
                <font-awesome-icon :icon="faXmarkCircle" />
            </vx-badge>
        </template>
        <template v-slot:form>
            <form @submit.prevent="apply" class="vx-space-y-4">
                <div class="vx-space-y-1">
                    <vx-label>
                        Keyword Search
                    </vx-label>

                    <vx-input autofocus
                        type="text"
                        data-test="filters-query"
                        placeholder="Search"
                        maxlength="120"
                        v-model="filters.query">
                        <template v-slot:right>
                            <font-awesome-icon class="vx-text-xs" :icon="faMagnifyingGlass" />
                        </template>
                    </vx-input>
                </div>

                <category-filter-select
                    v-model="filters.category"
                ></category-filter-select>

                <in-flow-filterable-item v-if="FEATURES.flows"
                    v-model="filters.flow"
                ></in-flow-filterable-item>

                <div class="vx-flex vx-gap-2">
                    <vx-button type="button" color="muted-light" @click="cancel">Cancel</vx-button>
                    <vx-button data-test="filters-apply" type="submit" block @click.stop="apply">
                        Apply
                        <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                    </vx-button>
                </div>
            </form>
        </template>
    </vx-filterable>
</template>

<script setup>
import { computed, ref, inject, watch, nextTick } from 'vue';
import { VxBadge, VxLabel, VxButton, VxFilterable, VxInput } from '@voxie/frontend-components';
import { faCircleCheck, faMagnifyingGlass, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import InFlowFilterableItem from '~/components/automation/flows/components/InFlowFilterableItem.vue';
import CategoryFilterSelect from '~/components/general/categories/CategoryFilterSelect.vue';

const FEATURES = inject('FEATURES');

const filters = ref({})
const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});
watch(() => props.modelValue, (modelValue) => {
    filters.value = {...modelValue};
}, {
    deep: true,
    immediate: true,
})
const emit = defineEmits(['update:modelValue', 'apply', 'reset']);

const active = ref(false);

const toggleFilterable = (event) => {
    active.value = event;
}

const isFlowFilterApplied = computed(() => {
    if (!props.modelValue.flow) {
        return false;
    }

    return props.modelValue.flow.in_flow !== undefined || !!(props.modelValue.flow.flow_id)
});
const filtersCount = computed(
    () =>
        (props.modelValue.query ? 1 : 0) +
        (isFlowFilterApplied.value ? 1 : 0) +
        (props.modelValue.category?.length ? 1 : 0)
);

const reset = () => {
    active.value = false;
    emit('reset');
}
const cancel = async () => {
    filters.value = { ...props.modelValue }
    active.value = false;
}

const apply = async () => {
    active.value = false;
    emit('update:modelValue', { ...filters.value });
    await nextTick();
    emit('apply');
}

</script>
