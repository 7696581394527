<template>
    <div>
        <h2>Show Phone Pool: {{ phoneNumberPool.friendly_name }}</h2>
        <p><a @click="close" href="#">close</a></p>
    </div>
</template>


<script>
export default {
    props: ['phoneNumberPool'],

    methods: {
      close(){
        Bus.$emit('phone_number_poolsCancelShow', this.phoneNumberPool.id);
      }
    }
}
</script>
