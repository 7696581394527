<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        size="md"
        :padding="false">
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6">
                Remove Custom Attribute
            </div>
        </template>

        <div class="vx-p-6">
            <vx-alert color="info"
                :closeable="false">Make sure to review your message templates after deleting this!
                <strong>There is no undo</strong>.</vx-alert>
        </div>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="emit('update:visible', false)"
                    type="button"
                    class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                    size="lg"
                    color="muted">
                    Cancel
                </vx-button>
                <vx-button @click="confirm()"
                    data-test="confirm-delete"
                    :loading="deleting"
                    type="button"
                    class="vx-grow"
                    size="lg"
                    color="primary">
                    Confirm
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import { VxButton, VxModal, VxAlert } from '@voxie/frontend-components';
import { ref } from 'vue';
import teamCustomAttributesClient from '~/services/teamCustomAttributesClient';
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';
import { useToasted } from '~/composables/useToasted';

const teamId = Spark.state.currentTeam.id;
const toasted = useToasted();

const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
    customAttributeKey: {
        type: [String, undefined],
        required: true,
    }
});

const emit = defineEmits(['update:visible', 'deleted']);
const deleting = ref(false);

const confirm = async () => {
    if (deleting.value) {
        return;
    }

    try {
        deleting.value = true;
        await teamCustomAttributesClient.deleteCustomAttribute(teamId, props.customAttributeKey);
        emit('update:visible', false);
        emit('deleted', props.customAttributeKey);
    } catch (e) {
        console.error(e);
        if (e?.response?.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
            return;
        }

        toasted.error(e.response?.data?.message || 'Something went wrong.');
    } finally {
        deleting.value = false;
    }
}

</script>
