<template>
    <card :title="title">
        <div class="alert alert-info mb-3">For some feature flags, once enabled they cannot be disabled. Please make
            sure you have confirmed the requirement and usage for a feature flag for this team prior to enabling.
        </div>

        <div v-for="group in groups" :key="group.type" data-test="feature-group">
            <div
                data-test="feature-group-heading"
                class="row justify-content-between align-items-center font-weight-bold"
                :style="{ cursor: 'pointer' }"
            >
                <div class="col-sm-6">{{ group.name }}</div>
            </div>
            <ul class="plan-feature-list p-0 row mb-2">
                <li class="col-md-12" v-if="!group.features.length && group.type === 'beta'">
                    No new beta features at this time. Check back later!
                </li>
                <li class="col-md-12" v-if="!group.features.length && group.type === 'alpha'">
                    No new alpha features at this time. Check back later!
                </li>
                <li class="col-md-6" v-for="feature in group.features" :key="feature.id">
                    <team-feature-item :team="team" :feature="feature"></team-feature-item>
                </li>
            </ul>
        </div>
    </card>
</template>

<script>
    import {teamsClient} from '../../../../services';
    import Card from '../../../general/Card.vue';
    import TeamFeatureItem from './TeamFeatureItem.vue';

    export default {
        name: 'TeamFeatures',
        components: { Card, TeamFeatureItem },
        props: ['team', 'title'],
        data() {
            return {
                groups: [],
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            getList() {
                teamsClient.getFeatures(this.team.id)
                    .then((response) => {
                        this.groupFeatures(response.data.data)
                    })
                    .catch(() => {
                        this.$toasted.global.platform_error();
                    });
            },
            groupFeatures(features) {
                this.groups = [
                    {
                        name: 'General Availability',
                        type: 'ga',
                        features: features.filter(feature => feature.type === 'ga'),
                    },
                    {
                        name: 'Beta',
                        type: 'beta',
                        features: features.filter(feature => feature.type === 'beta'),
                    },
                    {
                        name: 'Alpha',
                        type: 'alpha',
                        features: features.filter(feature => feature.type === 'alpha'),
                    },
                    {
                        name: 'Internal',
                        type: 'internal',
                        features: features.filter(feature => feature.type === 'internal'),
                    }
                ].filter((group) => group.features.length || group.type === 'beta' || group.type === 'alpha')
            }
        },
    };
</script>
<style lang="postcss" scoped>
.plan-feature-list li {
  line-height: 20px;
  margin-bottom: 15px;
}
</style>
