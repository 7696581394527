<template>
    <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8 2xl:vx-max-w-screen-xl"
        data-test="contacts-list">
        <page-header>
            Contacts
        </page-header>

        <contact-list-filters
            v-model="fetchParams"
            @reset="resetFilters"
            @apply="applyFilters"></contact-list-filters>

        <div v-if="loading && !list.length" class="vx-text-center vx-py-10">
            <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
        </div>

        <template v-if="!loading && !list.length">
            <vx-box color="info"
                v-if="!isEqual(defaultFilters(), fetchParams)"
                class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                <div class="vx-w-64 vx-mx-auto vx-text-center">
                    No results for current search, try adjusting your filters.
                </div>
                <vx-button type="button"
                    color="secondary"
                    @click="resetFilters">
                    Reset All <font-awesome-icon :icon="faCircleX" />
                </vx-button>
            </vx-box>
            <vx-box color="info"
                v-else-if="!list.length"
                class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                No contacts found.
                <vx-button href="/contacts/new">
                    New Contact <font-awesome-icon :icon="faPlusCircle" />
                </vx-button>
            </vx-box>
        </template>

        <vx-table v-if="list.length"
            class="xl:vx-grid-cols-[repeat(7,auto)]"
            rowBreakpoint="xl">
            <vx-table-row isHeader>
                <div class="xl:vx-col-span-2">
                    Name
                </div>
                <div>
                    Phone
                </div>
                <div>
                    Marketing
                </div>
                <div>
                    Transactional
                </div>
                <div>
                    Added
                </div>
            </vx-table-row>

            <contact-row v-for="(contact, index) in list"
                :modelValue="list[index]"
                :key="list[index].id"
                @deleted="removeFromList($event)"></contact-row>

        </vx-table>

        <div v-if="fetchParams.cursor"
            class="vx-mt-8">
            <vx-button
                @click="loadData()"
                color="muted-dark"
                size="xl"
                class="vx-grow-0"
                data-test="load-more-btn"
                :loading="loading">
                Load More
                <font-awesome-icon :icon="faSpinner"></font-awesome-icon>
            </vx-button>
        </div>
    </div>
</template>
<script setup>
import { faCircleX, faPlusCircle, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxBox,
    VxButton,
    VxTable,
    VxTableRow,
    VxIconLoading
} from '@voxie/frontend-components';
import { onMounted } from 'vue';
import PageHeader from '~/components/general/PageHeader.vue';
import { isEqual } from '~/utils/helpers';
import contactsClient from '../../../services/contactsClient';
import ContactListFilters from './ContactListFilters.vue';
import ContactRow from './ContactRow.vue';
import { useCursorPagination } from '../../../composables/useCursorPagination';
import { isLargeTeam } from '~/utils/team';

const teamId = Spark.state.currentTeam.id;

const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

let defaultFilters = () => ({
    'filter[search]': '',
    per_page: 10,
    cursor: null,
    'filter[marketing_subscription_status]': undefined,
    'filter[tags.tag_name]': undefined,
    sort_field: 'id',
    sort_dir: 'desc',
    sort: '-id',
});

const resetFilters = () => {
    fetchParams.value = defaultFilters();

    loadData();
};

const applyFilters = () => {
    fetchParams.value = {
        ...fetchParams.value,
        cursor: null,
    }

    loadData();
}

onMounted(() => {
    resetFilters();
});

const removeFromList = (segmentId) => {
    list.value = list.value.filter((item) => item.id != segmentId);

    if (!list.value.length) {
        loadData();
    }
};

const loadData = () => {
    const ascDesc = fetchParams.value.sort_dir === 'desc' ? '-' : '';
    fetchParams.value.sort = `${ascDesc}${fetchParams.value.sort_field}`;

    const params = { ...fetchParams.value };

    if (isLargeTeam()) {
        params['filter[phone]'] = params['filter[search]'];
        delete params['filter[search]']
    }

    fetchRecords(contactsClient.searchContacts(teamId, params));
};

</script>
