<template>
    <vx-expandable-card>
        <template v-slot:header> Name </template>
        <template v-slot:headerSubtitle> Create a name for this campaign so you can find it later. </template>
        <template v-slot:content>
            <vx-input
                data-test="name-input"
                autofocus
                :modelValue="props.modelValue"
                @update:modelValue="emit('update:modelValue', $event)"
                size="lg"
                placeholder="Name"
                :error="props.error"
            ></vx-input>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxExpandableCard, VxInput } from '@voxie/frontend-components';

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
        default: '',
    },
    error: {
        type: [String, Array],
    }
});

const emit = defineEmits(["update:modelValue"]);
</script>
