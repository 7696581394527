<template>
    <vx-dialog class="scheduled-message-modal" :visible="this.visible" @close="toggleModal">
        <template v-slot:header>
            <span v-if="userIsEditing">Edit Scheduled Message</span>
            <span v-else>New Scheduled Message</span>
        </template>
        <template v-slot:message>
            <p class="mb-3" style="visibility: hidden">
                Select one of your campaign's segments and then select a contact within that segment.
            </p>

            <div class="form-group mt-3">
                <b>Phone Number</b>

                <template v-if="!userIsEditing">
                    <phone-number-input ref="phoneNumberSearch"
                        placeholder="Enter a contact name or number"
                        id="contact_id"
                        :new-on-empty="false"
                        :key="phoneNumberSelectedKey"
                    />
                    <p class="mt-2" v-show="errors.has('contact_id')"><vx-text :color="'danger'" :level="2" :secondary="true">{{ errors.get('contact_id') }}</vx-text></p>
                </template>
                <template v-else>
                    <vx-input :model-value="contactLabel" disabled></vx-input>
                </template>
            </div>

            <div class="form-group mt-3">
                <message-builder
                    v-model="messageBody"
                    v-model:mediaUrl="media_url"
                    v-model:mediaContentType="media_content_type"
                    preview
                ></message-builder>

                <p class="mt-2" v-show="errors.has('body')"><vx-text :color="'danger'" :level="2" :secondary="true">Body field is required.</vx-text></p>

                <div class="mt-3 vx-flex vx-items-center vx-justify-center vx-flex-col">
                    <div class="vx-text-center">
                        {{ sendAtFormatted }}
                    </div>
                    <vx-date-picker v-model="sendAt" :rules="{ minutes: { interval: 5 } }" :timezone="timezone" :minDate="minDate" :maxDate="maxDate"></vx-date-picker>
                </div>

                <p class="mt-2" v-show="errors.has('send_at')">
                    <vx-text :color="'danger'" :level="2" :secondary="true">
                        {{ errors.get('send_at') }}
                    </vx-text>
                </p>
            </div>

        </template>
        <template v-slot:buttons>
            <div class="vx-flex vx-justify-between vx-items-center mt-5">
                <vx-button
                    @click="cancel"
                    class="ds-v2-modal__button-cancel"
                    appearance="transparent-dark"
                    size="small"
                >
                    Cancel
                </vx-button>
                <vx-button type="button"
                    appearance="primary-dark"
                    size="large"
                    @click="checkMessage"
                >
                    Schedule
                </vx-button>
            </div>
        </template>
    </vx-dialog>

</template>

<script>
import dayjs from '~/utils/dayjs';
import PhoneNumberInput from '../../general/PhoneNumberInput.vue';
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import {
    VxButton,
    VxDialog,
    VxInput,
    VxText
} from '@voxie/frontend-components-v3'
import { VxDatePicker } from '@voxie/frontend-components';
import scheduledMessagesClient from '../../../services/scheduledMessagesClient';
import { getClientTz, roundToNearest5, formatTz } from '../../../utils/date';
import { ScheduleMessagesMinMaxDates } from '../../../constants/scheduleMessages';

export default {
    name: "ScheduledMessageModal",
    components: {
        PhoneNumberInput,
        MessageBuilder,
        VxButton,
        VxDialog,
        VxDatePicker,
        VxText,
        VxInput
    },
    inject: ['FEATURES'],
    props: {
        scheduledMessage: {
            type: Object
        },
        visible: {type: Boolean}
    },
    emits: ['visible', 'scheduledMessageEdited', 'newScheduledMessageCreated'],

    data() {
        return {
            teamId: Spark.state.currentTeam.id,
            messageBody: '',
            media_url: null,
            media_content_type: null,
            contactId: null,
            phoneNumberSelectedKey: 0,
            sendAt: roundToNearest5(dayjs().tz(getClientTz()).add(1, 'h')),
            errors: new SparkFormErrors(),
            showDatePickers: true,
        }
    },

    watch: {
        scheduledMessage: function(newScheduledMessage) {
            if (newScheduledMessage !== null && newScheduledMessage !== undefined) {
                this.messageBody = this.scheduledMessage.body;
                this.media_url = this.scheduledMessage.media_url;
                this.media_content_type = this.scheduledMessage.media_content_type;
                this.contactId = newScheduledMessage.contact_id;

                this.sendAt = dayjs.utc(newScheduledMessage.send_at).tz(getClientTz()).toDate();
            }
        }
    },

    computed: {
        minDate() {
            return ScheduleMessagesMinMaxDates.MIN_DATE;
        },
        maxDate() {
            return ScheduleMessagesMinMaxDates.MAX_DATE;
        },

        userIsEditing() {
            return this.scheduledMessage !== null && this.scheduledMessage !== undefined;
        },

        timezone() {
            return getClientTz();
        },

        sendAtFormatted() {
            const timezone = dayjs(this.sendAt).tz(getClientTz()).format('z');

            return dayjs(this.sendAt).tz(getClientTz()).format("MMM DD, YYYY [at] h:mm a") +
                ' ' +
                formatTz(timezone)
        },

        contactLabel() {
            return this.scheduledMessage.contact.phone + ' - ' + this.scheduledMessage.contact.first_name + ' ' + this.scheduledMessage.contact.last_name;
        }
    },

    methods: {
        toggleModal() {
            this.$emit('update:visible', !this.visible)
        },

        sendMessage() {
            let data = {
                body: this.messageBody,
                contact_id: this.contactId,
                send_at: dayjs.tz(this.sendAt, getClientTz()).utc(),
            };

            if (this.media_url !== null && this.media_content_type !== null) {
                data.media_url = this.media_url;
                data.media_content_type = this.media_content_type;
            }

            scheduledMessagesClient.createScheduledMessage(
                this.teamId,
                this.contactId,
                data,
            ).then(() => {
                this.$emit('newScheduledMessageCreated');

                this.clearData();
                this.toggleModal();
            }).catch(error => {
                console.log('error', error);
                if (error.response.status === 422) {
                    this.errors.set(error.response.data.errors);
                } else {
                    this.$toasted.global.platform_error();

                }
            })
        },

        checkIfFormHasErrors() {
            let errors = {};

            if (this.contactId === null) {
                errors.contact_id = [
                    'The contact id field is required.'
                ];
            }

            if (this.sendAt === null) {
                errors.send_at = [
                    'The send at field is required.'
                ];
            }

            if (this.sendAt !== null && dayjs.utc().isSameOrAfter(dayjs.utc(this.sendAt))) {
                errors.send_at = [
                    'The send at field cannot be set in the past.'
                ];
            }

            if (this.messageBody === '' && this.media_url === null) {
                errors.body = [
                    'The body field is required.'
                ]
            }

            if (Object.keys(errors).length > 0) {
                this.errors.set(errors);
                return true;
            }

            return false;
        },

        clearData() {
            this.errors.forget();
            this.media_url = null;
            this.media_content_type = null;

            if (!this.userIsEditing) {
                this.phoneNumberSelectedKey += 1;
                this.messageBody = '';
                this.sendAt = roundToNearest5(dayjs().tz(getClientTz()).add(1, 'h'));
            } else {
                this.messageBody = this.scheduledMessage.body;
                this.sendAt = dayjs.utc(this.scheduledMessage.send_at).tz(getClientTz()).toDate();
            }
        },

        cancel() {
            this.clearData();
            this.toggleModal();
        },

        checkMessage() {
            if (this.checkIfFormHasErrors()) {
                return;
            }

            if(!this.userIsEditing) {
                this.sendMessage();
                return;
            }

            this.editMessage();
        },

        editMessage() {
            let data = {
                id: this.scheduledMessage.id,
                team_id: this.teamId,
                user_id: Spark.state.user.id,
                contact_id: this.contactId,
                body: this.messageBody,
                send_at: dayjs.tz(this.sendAt, getClientTz()).utc(),
                enqueued_at: this.scheduledMessage.enqueued_at,
            }

            if (this.media_url !== null) {
                data.media_url = this.media_url;
                data.media_content_type = this.media_content_type;
            }

            this.$emit('scheduledMessageEdited', data);

            this.clearData();
            this.toggleModal();
        }
    },

    mounted() {
        Bus.$on('select-contact-contact_id-changed', (id) => {
            if (id !== 'new') {
                this.contactId = id;
            }

        });
    }
}
</script>

<style>
.scheduled-message-modal {
    z-index: 1;
}
.scheduled-message-modal .vx-dialog__modal {
    overflow-y: visible;
}
</style>
