<template>
    <api-delivery-card :icon="faFingerprint">
        <template v-slot:title>
            API Auth
        </template>

        <template v-slot:headerRight>
            <vx-popover v-if="props.authConfig?.auth_config_id && props.authConfig?.friendly_name" hover :arrow="true"
                placement="top">
                <vx-button target="_blank"
                    :disabled="!isAdminOrOwner"
                    :href="`/settings/integrations/auth-configs/${props.authConfig.auth_config_id}`"
                    size="sm" color="muted-light">Edit <font-awesome-icon
                        :icon="faArrowUpRight" /></vx-button>
                <template v-if="!isAdminOrOwner" v-slot:content>
                    You do not have permission to edit.
                </template>
            </vx-popover>
        </template>

        <template v-slot:content>
            <div v-if="props.authConfig === undefined" class="vx-w-full vx-flex vx-items-center vx-justify-center">
                <vx-icon-loading spin></vx-icon-loading>
            </div>
            <div v-else-if="!props.delivery || (props.delivery.auth_config_id && props.authConfig === null)"
                class="vx-w-full vx-p-4 vx-bg-slate-50 vx-rounded-[10px] vx-justify-start vx-items-center vx-gap-3 vx-inline-flex">
                <font-awesome-icon :icon="faGhost" class="vx-text-slate-500" />
                <div class="vx-text-sky-950 vx-text-sm vx-font-normal">Auth Missing</div>
                <vx-badge v-if="props.delivery?.auth_config_id" color="slate">
                    {{ props.delivery?.auth_config_id.slice(-6) }}
                </vx-badge>
            </div>
            <template v-else-if="props.delivery && !props.delivery.auth_config_id">
                <div class="vx-text-slate-500 vx-text-xl vx-font-normal">None</div>
            </template>

            <template v-else-if="props.authConfig?.auth_config_id && props.authConfig?.friendly_name">
                <div class="vx-justify-start vx-items-center vx-gap-4 vx-inline-flex vx-break-all vx-flex-wrap">
                    <vx-badge v-if="props.authConfig.auth_config_id" color="slate">
                        {{ props.authConfig.auth_config_id.slice(-6) }}
                    </vx-badge>
                    <div class="vx-text-slate-500 vx-text-xl vx-font-normal">{{ props.authConfig?.friendly_name }}</div>
                </div>
            </template>
        </template>
    </api-delivery-card>
</template>
<script setup>
import ApiDeliveryCard from './ApiDeliveryCard.vue';
import { VxBadge, VxButton, VxIconLoading, VxPopover } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFingerprint, faArrowUpRight, faGhost } from '@fortawesome/pro-solid-svg-icons';

const props = defineProps({
    authConfig: {
        type: Object,
        required: false,
    },
    delivery: {
        type: Object,
        required: false,
    }
});

const isAdminOrOwner = Spark.isAdminOrOwner;

</script>
