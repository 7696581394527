<template>
    <div>
        <shift-to-text-outbound-message />
        <shift-to-text-sounds></shift-to-text-sounds>
        <shift-to-text-redirect />
    </div>
</template>

<script>
import ShiftToTextOutboundMessage from './ShiftToTextOutboundMessage.vue';
import ShiftToTextRedirect from './ShiftToTextRedirect.vue';
import ShiftToTextSounds from './ShiftToTextSounds.vue';
export default {
    components: {
        ShiftToTextOutboundMessage,
        ShiftToTextRedirect,
        ShiftToTextSounds
    },
    methods: {}
};
</script>
