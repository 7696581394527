<template>
    <div>
        <vx-modal :visible="props.visible"
            @update:visible="emit('update:visible', $event)"
            :stacked="formModal"
            :padding="false"
            size="md">
            <template v-slot:header>
                <div class="vx-px-6 vx-pt-6">
                    Snippets
                </div>
            </template>
            <template v-slot:subheader>
                <div class="vx-px-6 vx-pb-4">
                    Reusable snippets of content and variables to speed up your workflow.
                </div>
                <div class="vx-px-6">
                    <vx-input v-model="search"
                        size="lg"
                        placeholder="Search">
                        <template v-slot:right>
                            <font-awesome-icon class="vx-text-slate-800"
                                :icon="faMagnifyingGlass"></font-awesome-icon>
                        </template>
                    </vx-input>
                </div>
            </template>

            <div class="vx-pt-2 vx-px-6 vx-pb-6">
                <div v-if="loading"
                    class="vx-py-36 vx-text-center">
                    <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
                </div>
                <template v-else-if="!snippets?.length && !loading">
                    <div class="vx-pt-4 vx-text-center">
                        No results found
                    </div>
                </template>
                <div v-else
                    class="vx-flex vx-flex-col">
                    <div v-for="snippet in snippets"
                        data-test="snippet-item"
                        :key="'snippet-' + snippet.id"
                        tabindex="0"
                        @click.prevent="select(snippet)"
                        @keydown.space.prevent="select(snippet)"
                        @keydown.enter.prevent="select(snippet)"
                        class="vx-cursor-pointer vx-p-4 vx-rounded-xl hover:vx-bg-sky-100 focus:vx-bg-sky-100">
                        <div class="vx-text-slate-700 vx-text-sm vx-font-extrabold">{{ snippet.title }}</div>
                        <div class="vx-text-slate-500 vx-text-sm vx-font-normal">{{ snippet.body }}</div>
                    </div>
                </div>
            </div>

            <template v-slot:footer
                v-if="!loading">
                <div class="vx-flex vx-justify-end">
                    <vx-button @click.prevent="formModal = true"
                        type="button"
                        data-test="new-snippet-btn"
                        color="primary">New Snippet <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                    </vx-button>
                </div>
            </template>
        </vx-modal>

       <message-builder-new-snippet v-model:visible="formModal" @created="select" :disableMedia="props.disableMedia"></message-builder-new-snippet>
    </div>
</template>

<script setup>
import { faCirclePlus, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxButton, VxInput, VxModal, VxIconLoading } from '@voxie/frontend-components';
import debounce from '~/utils/debounce';
import { ref, watch } from 'vue';
import { useToasted } from '../../../../composables/useToasted';
import snippetsClient from '../../../../services/snippetsClient';
import MessageBuilderNewSnippet from './MessageBuilderNewSnippet.vue';

const toasted = useToasted();

const props = defineProps({
    visible: Boolean,
    disableMedia: Boolean,
});
const emit = defineEmits(['update:visible', 'selected']);

const formModal = ref(false);
const teamId = Spark.state.currentTeam.id;

const loading = ref(true);
const search = ref('');

const snippets = ref([]);

const select = (snippet) => {
    emit('selected', snippet);
    emit('update:visible', false);
}

watch([search, () => props.visible], debounce(async () => {
    if (!props.visible) {
        return;
    }

    try {
        const response = await snippetsClient.getSnippets(teamId, {
            per_page: 15,
            query: search.value,
            sort_field: 'title',
            sort_dir: 'asc',
        });
        snippets.value = response.data.data
    } catch (error) {
        console.error(error);
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
}, 300), { immediate: true })


</script>
