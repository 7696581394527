<template>
    <vx-expandable-card>
        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-4">
                <div>
                    <vx-label size="md">Group</vx-label>
                    <select-group placeholder="-"
                        v-model="model"
                        size="lg"
                        :clearable="true">
                    </select-group>
                </div>
            </div>
        </template>
    </vx-expandable-card>
</template>
<script setup>
import {
    VxExpandableCard,
    VxLabel,
} from '@voxie/frontend-components';
import SelectGroup from '~/components/general/SelectGroup.vue';

const model = defineModel({
    type: String,
    required: false,
});
</script>
