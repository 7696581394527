<template>
    <vx-chart-record
        :loading="loading"
        :error="Boolean(error)"
        :percentage="true"
        :data="data || []"
    >
        <template v-slot:title>
            <slot name="title">
                <div class="vx-flex vx-justify-between vx-grow">
                    <span>
                        Message Replies by Campaign &rarr; Top {{ data?.length || 10 }}
                    </span>
                    <experimental-pill subject="Beta Feedback - Analytics"></experimental-pill>
                </div>
            </slot>
        </template>
        <template v-slot:subtitle>
            <slot name="subtitle"></slot>
        </template>
        <template v-slot:footer>
            <vx-button
                href="/messaging/campaigns"
                outline
                color="muted-light"
                size="xs"
            >
                Go to Campaigns <font-awesome-icon :icon="faArrowRight" />
            </vx-button>
        </template>
        <template v-slot:explainHeader>Message Replies</template>
        <template v-slot:explainContent>
            <p>
                In the Context of Marketing Campaigns, reply percentage of the messages delivered relative to the messages delivered during the selected period.
            </p>
        </template>
    </vx-chart-record>
</template>
<script setup>
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxButton, VxChartRecord } from '@voxie/frontend-components';
import dayjs, { toUTCDateTimeFormat } from '~/utils/dayjs';
import { watch } from 'vue';
import { analyticsClient, campaignsClient } from '../../../../services';
import { getClientTz, toShortDateTime } from '../../../../utils/date';
import ExperimentalPill from '../../../general/ExperimentalPill.vue';
import { stringifyQueryString } from '../../../../utils/string';
import { useVersionedRequest } from '~/composables/useVersionedRequest';

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
});

const calculateRange = () => {
    const startDate = dayjs(props.startDate).tz(getClientTz());
    const endDate = dayjs(props.endDate).tz(getClientTz());

    return [toUTCDateTimeFormat(startDate), toUTCDateTimeFormat(endDate)];
};

const { loading, response: data, error, fetchData } = useVersionedRequest();

const loadData = async () => {
    const params = {
        range: calculateRange(),
    };

    const res = await analyticsClient.marketingCampaignsMessageReplyRateByCampaign(Spark.state.currentTeam.id, params);
    const campaigns = await campaignsClient.campaignsGetRecords(Spark.state.currentTeam.id, {
        ids: res.data.data.map((item) => item.group_id),
        with_trashed: 1,
    }).then((res) => res.data.data);

    return res.data.data.map((item) => {
        const campaign = campaigns.find(campaign => campaign.id == item.group_id);

        return {
            key: item.group_id,
            title: campaign?.name || `Campaign - ${item.group_id}`,
            subtitle: campaign?.created_at ? `Created: ${toShortDateTime(campaign.created_at)}` : undefined,
            value: item.values[0],
            href: `/analytics/marketing-campaigns?${stringifyQueryString({ campaignId: item.group_id, startDate: props.startDate, endDate: props.endDate })}`
        }
    });
}

watch(
    [() => props.startDate, () => props.endDate],
    () => {
        fetchData(loadData()).catch((e) => {
            console.error(e);
        });
    },
    { immediate: true }
);
</script>
