import BaseClient from './BaseClient';

class MessageMediaClient extends BaseClient {
    uploadMedia(teamId, formData, onUploadProgress) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/upload/media`;
        const extraConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        };

        return this.makeCoreApiRequest(url, method, formData, null, extraConfig);
    }
}

export default new MessageMediaClient();
