<template>
    <div>
        <playbook-form-card :icon="faMessage">
            <template v-slot:header>
                Messages
            </template>


                <playbook-predefined-question :modelValue="{ message: props.modelValue.start_message }" @update:modelValue="emitValue('start_message', $event.message)">
                    <template v-slot:label>
                        Start
                    </template>
                    <template v-slot:hint>
                        We recommend you to keep an explicit consent phrase such as <span class="vx-text-italic">“Reply STOP to cancel”</span>.
                    </template>
                </playbook-predefined-question>

                <playbook-predefined-question :modelValue="{ message: props.modelValue.help_message }" @update:modelValue="emitValue('help_message', $event.message)">
                    <template v-slot:label>
                        Help
                    </template>
                </playbook-predefined-question>

        </playbook-form-card>
    </div>
</template>

<script setup>
import PlaybookFormCard from '../PlaybookFormCard.vue';
import { faMessage } from '@fortawesome/pro-solid-svg-icons';
import PlaybookPredefinedQuestion from './PlaybookPredefinedQuestion.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => ({})
    },
});

const emit = defineEmits(['update:modelValue']);

const emitValue = (key, value) => {
    emit('update:modelValue', {
        ...props.modelValue,
        [key]: value,
    })
}

</script>
