<template>
    <div class="threads-list position-relative">
        <div class="threads-list__header">
            <div class="ttl-row">
                <div class="ttl-cell-default-group flex-grow-2">
                    <div v-if="!FEATURES.simple_message_hub" class="threads-list__checkbox">
                        <vx-checkbox v-model="selectAllThreadsModel" @change="handleSelectAllThreadsChange"/>
                    </div>
                    <div class="ttl-cell-default">
                        <threads-table-cell-sorter
                            :threads-cnt="0"
                        >
                            Contact
                        </threads-table-cell-sorter>
                    </div>
                </div>
                <div class="ttl-cell-default-group">
                    <div v-if="showOwner && !FEATURES.simple_message_hub" class="ttl-cell-default align-items-center">
                        <threads-table-cell-sorter
                            :threads-cnt="0"
                        >
                            Owner
                        </threads-table-cell-sorter>
                    </div>
                    <div class="ttl-cell-default align-items-end text-nowrap" @click="changeDirection()">
                        <threads-table-cell-sorter
                            :threads-cnt="threads.length"
                            :sort-dir="props.forward === 'true' ? 'asc' : 'desc'"
                        >
                            Last Message
                        </threads-table-cell-sorter>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="isSearching && !threads.length && !loadingThreads"
            class="u-height-100 u-flex"
        >
            <p class="no-contacts">No results found.</p>
        </div>
        <transition-group
            name="flip-list"
            tag="div"
            id="ttl-body"
            :duration="{ enter: 1000, leave: 0 }"
            :class="{'ttl-body': true, 'ttl-empty-body': !threads.length && !loadingThreads}">
            <template v-if="loadingThreads">
                <div
                    v-for="index in 5"
                    :key="index"
                    class="ttl-row ttl-body-row ttl-body-row-is-loading"
                    data-testid="thread-loading-row"
                >
                    <div class="ttl-cell-default-group flex-grow-2">
                        <div v-if="!FEATURES.simple_message_hub" class="threads-list__checkbox">
                            <div class="checkbox-placeholder">
                                <skeletor size="16" />
                            </div>
                        </div>
                        <div class="ttl-cell-thread-message ttl-cell-default">
                            <skeletor
                                class="text-placeholder"
                                width="100%"
                                height="20px"
                            />
                            <skeletor
                                class="text-placeholder"
                                width="100%"
                                height="20px"
                            />
                            <skeletor
                                class="text-placeholder d-flex d-sm-none"
                                width="100%"
                                height="12px"
                            />
                        </div>
                    </div>
                    <div class="ttl-cell-default-group">
                        <div
                            class="ttl-cell-default ttl-cell-thread-owner align-items-center">
                            <div>
                                <skeletor
                                    circle
                                    size="36"
                                />
                            </div>
                        </div>
                        <div class="ttl-cell-default ttl-cell-thread-time align-items-center text-nowrap">
                            <skeletor
                                width="100%"
                                height="20px"
                            />
                            <span class="ttl-unread-cnt-wrp">
                                <skeletor
                                    class="ml-2 mr-2"
                                    width="30px"
                                    height="18px"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div
                    v-for="thread in threads"
                    :key="`thread-${thread.contact_id}`"
                    :class="getThreadsTableClassList(thread)"
                    data-testid="thread">
                    <div class="ttl-cell-default-group flex-grow-2 ttl-cell-thread-message__wrapper">
                        <div v-if="!FEATURES.simple_message_hub" class="threads-list__checkbox">
                            <vx-checkbox :modelValue="isThreadSelected(thread)" @update:modelValue="selectThread(thread)"/>
                        </div>
                        <div class="ttl-cell-default ttl-cell-thread-message"
                             @click="activateThread(thread)">
                            <span class="ttl-cell-thread-customer-name">{{ thread.contact_name || phoneNumberNational(thread.contact_phone) }}</span>
                            <span class="ttl-cell-thread-message-body">{{ thread.last_message_content }}</span>
                            <span v-if="!FEATURES.simple_message_hub && getAgent(thread)" class="ttl-cell-thread-message-assignee mobile">{{ getAgent(thread).name || ''}}</span>
                        </div>
                    </div>
                    <div class="ttl-cell-default-group" @click="activateThread(thread)">
                        <div v-if="!FEATURES.simple_message_hub && showOwner && getAgent(thread)"
                            class="ttl-cell-default ttl-cell-thread-owner align-items-center">
                            <span
                                class="vx-avatar vx-avatar--size-1"
                                style="background: #091832; color: var(--color-white);"
                                data-toggle="tooltip"
                                data-placement="top"
                                :title="getAgent(thread).name"
                            >
                                {{ getInitials(getAgent(thread)) }}
                            </span>
                        </div>
                        <div class="ttl-cell-default ttl-cell-thread-time align-items-center text-nowrap">
                            <span class="text" v-if="!FEATURES.simple_message_hub">{{ shortDate(thread.last_message_at) }}</span>
                            <div v-else class="ttl-cell-thread-time__action">
                                <span class="text">{{ shortDate(thread.last_message_at) }}</span>
                                <vx-button
                                    v-if="thread.status !== 'open'"
                                    @click.stop.prevent="markAsUnread(thread)"
                                    size="medium"
                                    appearance="transparent-primary-outline"
                                >
                                    Mark as unread
                                </vx-button>
                            </div>
                            <span class="ttl-unread-cnt-wrp">
                                <template v-if="FEATURES.simple_message_hub">
                                    <vx-badge
                                     v-if="thread.status === 'open'"
                                    color="primary"
                                    >
                                    </vx-badge>
                                </template>
                                <template v-else>
                                    <vx-badge
                                        v-if="parseInt(thread.unread)"
                                        color="primary"
                                    >
                                        {{ thread.unread >= 10 ? `9+` : thread.unread  }}
                                    </vx-badge>
                                </template>
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </transition-group>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed, watch, onMounted, onUnmounted, inject } from 'vue';
import { shortDate, phoneNumberNational } from '../../filters';
import { useToasted } from '../../../composables/useToasted';

import ThreadsTableCellSorter from './ThreadsTableCellSorter.vue';
import { VxCheckbox, VxBadge, VxButton } from '@voxie/frontend-components-v3';
import { Skeletor } from 'vue-skeletor';

const props = defineProps({
    forward: {
        type: String,
        default: 'true'
    },
    isSearching: {
        type: Boolean
    },
});
const emit = defineEmits(['loadMoreThreads', 'directionChanged']);
const store = useStore();
const $toasted = useToasted();
const FEATURES = inject('FEATURES');

const selectAllThreadsModel = ref(false);
const threads = computed(() => store.getters.getThreads);
const handleSelectAllThreadsChange = () => {
    store.commit('setActiveThread', null);
    if (selectAllThreadsModel.value) {
        store.commit('setSelectedThreads', threads.value);
    } else {
        store.commit('setSelectedThreads', []);
    }
};
Bus.$on('threadStatusChanged', () => {
    selectAllThreadsModel.value = false;
    handleSelectAllThreadsChange();
});
const selectedThreads = computed(() => store.state.threads.selectedThreads);
const isThreadSelected = (thread) => {
    return !!selectedThreads.value.find((t) => t.contact_id === thread.contact_id);
};
const selectThread = (thread) => {
    selectAllThreadsModel.value = false;
    store.commit('setActiveThread', null);
    if (isThreadSelected(thread)) {
        store.commit('pullSelectedThread', thread);
    } else {
        store.commit('pushSelectedThread', thread);
    }
};

const activeThreadsInbox = computed(() => store.state.threads.activeThreadsInbox);
watch(activeThreadsInbox, () => {
    selectAllThreadsModel.value = false;
});

const setActiveThread = (thread) => {
    selectAllThreadsModel.value = false;
    store.commit('setSelectedThreads', []);
    store.commit('setActiveThread', thread);
}

const activateThread = async (thread) => {
    setActiveThread(thread);
};


const markAsUnread = (thread) => {
  store.dispatch("changeState", { thread: thread, state: 'open' });
};

const changeDirection = () => {
    if(threads.value.length === 0) return false;

    const forward = props.forward === 'true' ? 'false' : 'true';

    emit('directionChanged', forward);
};

const handleScroll = () => {
    const bodyElem = window.$('#ttl-body');
    const scrollTop = bodyElem.scrollTop();
    const scrollHeight = bodyElem.prop('scrollHeight');
    const height = bodyElem.outerHeight();
    const threshold = 100;
    if (scrollTop + height >= scrollHeight - threshold) {
       emit('loadMoreThreads')
    }
}
onMounted(() => {
  window.$('#ttl-body').on('scroll', handleScroll);
});
onUnmounted(() => {
  window.$('#ttl-body').off('scroll', handleScroll);
});

const activeThread = computed(() => store.getters.getActiveThread);
watch(activeThread, (thread, old) => {
    if (!thread) {
        store.commit('contacts_v3/unselectContact');
    } else if (!old?.contact_id || (old.contact_id !== thread.contact_id && thread.contact_id)) {
        store
            .dispatch('contacts_v3/loadConversation', { contactId: thread.contact_id, $toasted, thread })
    }
});
const getThreadsTableClassList = (thread) => {
    return {
        'ttl-row': true,
        'ttl-body-row': true,
        'thread-active': activeThread.value && activeThread.value.contact_id === thread.contact_id || isThreadSelected(thread),
    };
};

const agents = computed(() => store.state.threads.agents);
const getAgent = (thread) => thread
    && thread.assignee_id
    && agents.value.find(a => a.id === thread.assignee_id)
    || null;
const getInitials = (agent) => {
    if (!agent.name) return '';
    let names = agent.name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

const showOwner = computed(() => ['all', 'open'].includes(activeThreadsInbox.value));
const loadingThreads = computed(() => store.state.threads.loadingThreads);
</script>
<style lang="postcss" scoped>
.threads-list__header {
    background: white;
    border-bottom: 1px solid #f0f2f5;
}

.threads-list__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 44px;
}

/* TODO: Remove after lib updates */
.threads-list__checkbox .vx-checkbox {
    margin: 0;
    padding: 0;
    min-width: 16px;
}

.ttl-body-row {
    padding: 10px 0;
}

.ttl-body-row.thread-active {
    background: #e2f1f9;
}

.ttl-cell-default {
    padding: 0;
}

.ttl-cell-thread-customer-name {
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #4b4b4d;
    margin: 0 0 3px 0;
}

.ttl-cell-thread-message__wrapper {
    overflow: hidden;
}

.ttl-cell-thread-message {
    overflow: hidden;
}

.ttl-cell-thread-message-body {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #787879;
    max-width: 100%;
}

.ttl-cell-thread-time .text {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    margin-right: 12px;
}

.ttl-unread-cnt-wrp {
    display: flex;
    align-items: center;
}

/* TODO: Rmove after lib updates */
.vx-badge {
    background: var(--color-primary);
    color: white;
    border-radius: 10px;
    padding: 0 8px;
    min-height: 16px;
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-contacts {
    margin: auto;
}

.checkbox-placeholder {
    padding-left: 10px;
    padding-right: 10px;
}

.text-placeholder {
    margin-bottom: 4.5px;
}

.vue-skeletor {
    background-color: #ccc;
}
</style>
