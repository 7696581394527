<template>
    <a :href="route" @click.prevent="click" :class="['c-dropdown__item dropdown-item', disabled ? 'disabled' : '']" data-router="client-route">
        <slot></slot>
    </a>
</template>

<script>
import clientRoutes from './../../mixins/client-routes';
import scrollTo from './../../mixins/scroll-to';

    export default {

        mixins: [
            clientRoutes,
            scrollTo
        ],

        props: {
            event: {
                type: String
            },

            payload: {
                //type: Number
            },

            message: {
                type: String,
                default() {
                    return ''
                }
            },

            route: {
                type: String,
                default() {
                    return ''
                }
            },

            disabled: {
                type: Boolean,
                default: false
            }
        },

        emits: ['action'],

        methods: {
            click(){
                if (this.message && !window.confirm(this.message)) {
                    return;
                }

                this.$emit('action');
                if (this.event) {
                    this.emitEvent(this.event, this.payload);
                }
                this.showRouteInUrl();
            },

            emitEvent(event, payload){
                if(payload instanceof Array){
                    Bus.$emit(this.event, ...this.payload);
                } else {
                    Bus.$emit(this.event, this.payload);
                }
            },

            showRouteInUrl(){
                if(this.route){
                    this.showClientRoute(this.route);
                } else {
                    this.clearClientRoute();
                }
            }
        }
    }
</script>
