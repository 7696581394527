export const availableHttpMethods = () => {
    return [
        {
            method: 'POST',
            color: 'violet',
        },
        {
            method: 'PUT',
            color: 'amber',
        },
        {
            method: 'PATCH',
            color: 'orange',
        },
        {
            method: 'DELETE',
            color: 'rose',
        },
    ];
};

export const colorForHttpMethod = (method) => {
    return availableHttpMethods().find((option) => option.method === method?.toUpperCase())?.color || 'slate';
};
