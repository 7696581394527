<template>
    <div v-if="showNotification" class="notification-message">
        <div class="notification-message__content">
            <p class="notification-message__type" v-text="messageTitle"></p>
            <p class="notification-message__message" v-text="messageBody"></p>
        </div>
        <div class="notification-message_mute" @click="toggleMute">
            <i class="fa" :class="[isAudioMuted ? 'fa-bell-slash' : 'fa-bell']"></i>
        </div>
        <div class="notification-message__close" @click="dismissNotification"></div>
    </div>
</template>

<script>
import { isAuthErrorEcho } from '../../utils/helpers';
import notificationAudio from '@audio/popsound.mp3';

export default {

    data() {
        return {
            showNotification: false,
            isAudioMuted: false,
            audio: '',
            messageUrl: '',
            messageTitle: '',
            messageBody: '',
            echoErrors: [],
        };
    },

    mounted() {
        this.requestNotificationsPermissions();
        this.listenForEchoEvents();
        this.loadAudioFile();
    },

    methods: {

        requestNotificationsPermissions() {
            if (! window.Notification) {
                return;
            }

            if (window.Notification.permission !== 'default') {
                return;
            }

            window.Notification.requestPermission();
        },

        listenForEchoEvents() {
            Echo
                .private(`messages.${Spark.state.currentTeam.id}`)
                .listen('MessageCreated', event => {
                    if (event.message?.archived || event.message.direction === 'outbound') {
                        return;
                    }

                    this.notify(event);
                })
                .listen('ScheduledMessageEnqueued', (event) => {
                    this.notify(event);
                }).error?.((e) => {
                    if (this.echoErrors.length <= 3 && !isAuthErrorEcho(e)) {
                        window?.Bugsnag?.notify(e instanceof Error ? e : new Error(JSON.stringify(e)));
                        this.echoErrors.push(e);
                    }
                });
        },

        loadAudioFile() {
            this.audio = new Audio(
                notificationAudio
            );
            this.audio.load();
        },

        notify(event) {
            const contact = (event.contact.first_name && event.contact.last_name)
                ? `${event.contact.first_name} ${event.contact.last_name}`
                : event.contact.phone;

            this.messageTitle = `New message from ${contact}`;
            this.messageBody = event.message.body || '';
            this.messageUrl = `/messaging/messages/${event.contact.id}`;

            document.hasFocus() ? this.displayPageNotification() : this.displayBrowserNotification();
            this.playAudio();

            window.Bus.$emit('unread-message-count');
        },

        displayBrowserNotification() {
            if (! window.Notification || window.Notification.permission !== 'granted') {
                return;
            }

            new window.Notification(this.messageTitle, {
                body: this.messageBody,
                requireInteraction: true,
            });
        },

        displayPageNotification() {
            this.showNotification = true;

            setTimeout(this.dismissNotification, 6000);
        },

        playAudio() {
            if (this.isAudioMuted) {
                return;
            }

            this.audio.play().catch(() => {
                // The user has not interacted with the DOM yet, therefore the audio didn't play.
            });
        },

        toggleMute() {
            (this.isAudioMuted)
                ? window.localStorage.removeItem('audio-muted')
                : window.localStorage.setItem('audio-muted', 'true');

            this.isAudioMuted = ! this.isAudioMuted;
        },

        dismissNotification() {
            this.showNotification = false;
        },
    },
};
</script>

<style>
    .notification-message {
        right: 15px;
        text-align: left;
        padding: 15px 0;
        background-color: #fff;
        max-width: 500px;
        position: absolute;
        box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
        z-index: 10000;
        top: 15px;
    }

    .notification-message:before {
        background-color: #1bb934;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
    }

    .notification-message__type {
        color: #3e3e3e;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 8px;
    }

    .notification-message__message {
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 0;
        color: #878787;
    }

    .notification-message__content {
        padding-left: 30px;
        padding-right: 60px;
    }

    .notification-message__close {
        position: absolute;
        right: 22px;
        top: 10px;
        overflow: hidden;
        width: 14px;
        cursor: pointer;
        height: 14px;
    }

    .notification-message__close::before, .notification-message__close::after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -1px;
        background: #878787;
    }

    .notification-message__close::before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .notification-message__close::after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .notification-message__content a {
        text-decoration: none;
        outline: none;
        display: block;
    }

    .notification-message_mute {
        cursor: pointer;
        width: 14px;
        height: 14px;
        display: block;
        right: 22px;
        position: absolute;
        top: 35px;
    }

    .notification-message_mute .fa {
        vertical-align: top;
    }
</style>
