<template>
    <div
        class="vx-flex vx-flex-col vx-items-end vx-gap-2"
    >
        <div
            class="vx-inline-block vx-origin-bottom-right vx-rounded-2xl vx-border vx-border-white/60 vx-bg-slate-100/70 vx-p-2 vx-pb-0 vx-shadow-md vx-backdrop-blur-md vx-transition-all"
            :class="{
                'vx-delay-100 vx-ease-in-out vx-duration-300': open,
                'vx-scale-[.98] vx-opacity-0 vx-translate-y-4 vx-ease-in vx-duration-150':
                    !open,
            }"
        >
            <div
                class="vx-max-w-[240px] vx-rounded-lg vx-bg-white vx-px-4 vx-py-8 vx-shadow sm:vx-p-8"
            >
                <div class="vx-mb-1 vx-flex vx-gap-2">
                    <h2
                        class="vx-text-base vx-font-bold"
                        id="vx-pop-up__title"
                    >
                        Keep in Touch
                    </h2>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="24"
                        viewBox="0 0 512 512"
                        class="vx-self-start"
                    >
                    <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z"/>
                    </svg>
                </div>
                <p class="vx-mb-4 vx-text-xs vx-text-neutral-500">
                    Would you like to receive special offers via text?
                </p>
                <form>
                    <label class="vx-mb-2 vx-block">
                        <span
                            class="vx-block vx-text-xs vx-font-semibold vx-text-slate-700"
                        >
                            First Name *
                        </span>
                        <input
                            type="text"
                            class="vx-mt-1 vx-appearance-none vx-block vx-w-full vx-rounded-sm vx-border vx-border-solid vx-border-neutral-300 vx-bg-white vx-px-3 vx-py-2 vx-text-base invalid:vx-border-rose-500 invalid:vx-text-rose-600 focus:vx-border-sky-500 focus:vx-outline-none focus:vx-ring-1 focus:vx-ring-sky-500 focus:invalid:vx-border-rose-500 focus:invalid:vx-ring-rose-500 disabled:vx-border-neutral-200 disabled:vx-bg-neutral-50 disabled:vx-text-neutral-500"
                        />
                    </label>

                    <label class="vx-mb-4 vx-block">
                        <span
                            class="vx-block vx-text-xs vx-font-semibold vx-text-slate-700"
                        >
                            Phone Number *
                        </span>
                        <input
                            type="text"
                            class="vx-mt-1 vx-appearance-none vx-block vx-w-full vx-rounded-sm vx-border vx-border-solid vx-border-neutral-300 vx-bg-white vx-px-3 vx-py-2 vx-text-base invalid:vx-border-rose-500 invalid:vx-text-rose-600 focus:vx-border-sky-500 focus:vx-outline-none focus:vx-ring-1 focus:vx-ring-sky-500 focus:invalid:vx-border-rose-500 focus:invalid:vx-ring-rose-500 disabled:vx-border-neutral-200 disabled:vx-bg-neutral-50 disabled:vx-text-neutral-500"
                        />
                    </label>

                    <button
                        role="button"
                        @click="prevent"
                        class="vx-font-sans vx-h-10 vx-w-full vx-rounded-md vx-bg-neutral-800 vx-text-xs vx-text-white vx-transition hover:vx-bg-neutral-600 active:vx-scale-95 active:vx-opacity-80 disabled:vx-scale-100 disabled:vx-cursor-not-allowed disabled:vx-opacity-60 disabled:hover:vx-bg-neutral-800"
                    >
                        Sign Up
                    </button>
                </form>
            </div>
            <div
                class="vx-translate-y-[-2%] vx-py-2 vx-text-center vx-font-sans vx-text-xs vx-text-neutral-600"
            >
                Powered by <span class="vx-font-bold">Voxie</span>
            </div>
        </div>

        <button
            class="vx-group vx-relative vx-h-14 vx-w-14 vx-cursor-pointer vx-overflow-hidden vx-rounded-2xl vx-border vx-text-white vx-shadow-md vx-backdrop-blur-md vx-transition-all vx-duration-300 active:vx-scale-95 active:vx-opacity-80"
            @click="open = !open"
            :class="{
                'vx-border-neutral-800 vx-bg-neutral-800': open,
                'vx-bg-slate-100/70 vx-border-white/60': !open,
            }"
        >
            <svg
                width="24"
                height="28"
                viewBox="0 0 24 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="vx-absolute vx-left-1/2 vx-top-1/2 vx-mt-[2px] -vx-translate-x-1/2 -vx-translate-y-1/2 vx-fill-neutral-800 vx-transition-all vx-duration-300 group-hover:vx-fill-neutral-600 group-active:vx-fill-neutral-800"
                :class="{
'vx-scale-[3]': open
                }"
            >
                <path
                    d="M18.6953 0.981445H5.3076C4.61052 0.981445 3.92027 1.12142 3.27626 1.39339C2.63225 1.66535 2.04711 2.06398 1.55424 2.5665C1.06137 3.06901 0.670436 3.66558 0.403757 4.32213C0.137078 4.97868 -0.00011896 5.68235 7.73939e-08 6.39295V18.1556C7.73939e-08 19.5906 0.559192 20.9668 1.55456 21.9815C2.54993 22.9962 3.89994 23.5662 5.3076 23.5662V27.0993L9.8294 23.5662H18.6926C20.1003 23.5662 21.4503 22.9962 22.4457 21.9815C23.441 20.9668 24.0002 19.5906 24.0002 18.1556V6.39295C24.0005 4.9583 23.4418 3.58228 22.4469 2.56748C21.4521 1.55267 20.1027 0.98218 18.6953 0.981445Z"
                ></path>
            </svg>

            <span
                class="vx-font-thin vx-text-2xl vx-absolute vx-left-1/2 vx-top-1/2 -vx-translate-x-1/2 -vx-translate-y-1/2 vx-transition-all"
                :class="{ 'vx-opacity-0': !open }"
            >&times;</span>
        </button>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const open = ref(true);
</script>
