<template>
    <div class="vx-space-y-8 md:vx-space-y-12 vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8">
        <step-form-heading :icon="faFileArrowDown">
            New Export

            <template v-slot:description>
                <span class="vx-text-sm vx-text-slate-500">Export all of your contacts as a CSV.</span>
            </template>
        </step-form-heading>

        <vx-alert v-if="!isExportAllowed" :closeable="false" shadow class="vx-mx-auto vx-max-w-sm">
            You don't have permission to export contacts.
        </vx-alert>
        <vx-alert v-else-if="success" shadow :closeable="false" color="success" class="vx-mx-auto vx-max-w-2xl">
            <template v-slot:icon>
                <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
            </template>
            <div class="vx-flex vx-items-center vx-justify-between">
                <span>Export started successfully. We'll email you when it's ready.</span>
                <vx-button href="/contacts/export/new" size="sm" color="success">
                    New Export
                    <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                </vx-button>
            </div>
        </vx-alert>

        <vx-sticky-nav v-else :sections="[
            { id: 'included-fields', label: 'Included Fields' },
            { id: 'custom-attributes', label: 'Custom Attributes' },
        ]">
            <div class="vx-max-w-screen-md vx-w-full vx-mx-auto vx-flex vx-flex-col vx-gap-2 md:vx-gap-8">
                <included-fields id="included-fields"></included-fields>
                <custom-attributes id="custom-attributes" ref="customAttributesRef"></custom-attributes>
                <vx-sticky-footer>
                    <vx-button href="/contacts" class="vx-w-20" size="lg" color="muted">
                        Cancel
                    </vx-button>
                    <vx-button @click="submit()" data-test="submit-button" :disabled="submiting" class="vx-grow" size="lg"
                        color="primary" :loading="submiting">
                        Start Export
                        <font-awesome-icon :icon="faCirclePlay"></font-awesome-icon>
                    </vx-button>
                </vx-sticky-footer>
            </div>
        </vx-sticky-nav>
    </div>
</template>

<script setup>
import { VxButton, VxStickyNav, VxStickyFooter, VxAlert } from '@voxie/frontend-components';
import { ref } from 'vue';
import StepFormHeading from '~/components/general/StepFormHeading.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleCheck, faFileArrowDown, faCirclePlay, faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import contactsClient from '~/services/contactsClient';
import { useToasted } from '~/composables/useToasted';
import IncludedFields from './components/IncludedFields.vue';
import CustomAttributes from './components/CustomAttributes.vue'

const isExportAllowed = Spark.isAdminOrOwner || Spark.roleOnCurrentTeam === 'manager';
const teamId = Spark.state.currentTeam.id;

const toasted = useToasted();

const submiting = ref(false);
const success = ref(false);
const customAttributesRef = ref(null);

const submit = async () => {
    if (submiting.value) {
        return;
    }

    submiting.value = true;

    try {
        const attributes = await customAttributesRef.value.submit();
        await contactsClient.contactsExport(teamId, null, {
            'custom_attribute_keys': attributes,
        });

        success.value = true;
    } catch (error) {
        console.error(error)
        toasted.global.platform_error();
    } finally {
        submiting.value = false;
    }
}
</script>
