<template>
    <contact-details :contactId="props.contactId">
        <div class="vx-col-span-12 lg:vx-col-span-10 vx-flex vx-flex-col vx-w-full vx-justify-center vx-gap-2 md:vx-gap-2">
            <vx-box class="vx-bg-slate-50 vx-flex vx-flex-col vx-max-w-screen-sm vx-p-8 vx-text-xl" color="info">
                <h2 class="vx-text-xl vx-font-extrabold vx-leading-7 vx-text-slate-700">
                    Campaigns
                </h2>
                <p class="vx-text-sm vx-font-sans vx-text-slate-500 lg:vx-text-base">
                    Campaigns of this contact.
                </p>
            </vx-box>

            <div v-if="loading && !list.length" class="vx-text-center vx-py-10">
                <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
            </div>

            <template v-else-if="!loading && list.length === 0">
                <vx-box color="info" class="vx-mt-8 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                    There are no campaigns for this contact.
                </vx-box>
            </template>
            <template v-else>
                <vx-table v-if="list.length" class="xl:vx-grid-cols-6" rowBreakpoint="xl">
                    <vx-table-row isHeader>
                        <div>
                            ID
                        </div>
                        <div class="xl:!vx-col-span-2">
                            Name
                        </div>
                        <div>
                            Status
                        </div>
                        <div>
                            Audience Type
                        </div>
                        <div>
                            Added at
                        </div>
                    </vx-table-row>
                    <contact-campaign-row v-for="(item, index) in list"
                        v-model="list[index]"
                        :key="item.id"></contact-campaign-row>
                </vx-table>

                <div v-if="list.length" class="vx-mt-8 vx-flex vx-justify-between vx-w-full vx-gap-10">
                    <vx-button v-if="fetchParams.cursor" @click="loadData()" data-test="load-more-btn" color="muted-dark" size="xl"
                               class="vx-grow-0" :loading="loading">
                        Load More
                        <font-awesome-icon :icon="faSpinner"></font-awesome-icon>
                    </vx-button>
                </div>
            </template>
        </div>
    </contact-details>
</template>

<script setup>

import ContactDetails from '../ContactDetails.vue';
import { VxBox, VxButton, VxIconLoading, VxTable, VxTableRow } from '@voxie/frontend-components';
import contactsClient from '../../../../services/contactsClient';
import { onMounted } from 'vue';
import ContactCampaignRow from './ContactCampaignRow.vue';
import { useCursorPagination } from '../../../../composables/useCursorPagination';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const props = defineProps({
    contactId: {
        type: Number,
        required: true,
    },
});

const defaultFilters = () => {
    return {
        cursor: null,
        per_page: 15,
    }
};

onMounted(() => {
    fetchParams.value = defaultFilters();
    loadData();
});

const loadData = () => {
    fetchRecords(contactsClient.listCampaigns(Spark.state.currentTeam.id, props.contactId, fetchParams.value));
};
</script>

