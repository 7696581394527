import MessageMediaClient from '../services/messageMediaClient';
import scheduledMessagesClient from '../services/scheduledMessagesClient';

export default {
    async editScheduledMessage(data, toasted) {
        let newMediaUrl = null;

        if (data.media_url !== null && data.media !== undefined) {
            newMediaUrl = await this.reloadMedia(data.media_url, data.media_content_type, data.team_id, toasted);

            if (newMediaUrl === null) {
                return;
            }
        }

        await this.deleteAndCreateNewScheduledMessage(data, toasted);
    },
    async deleteAndCreateNewScheduledMessage(data, toasted) {
        try {
            await Promise.all([
                scheduledMessagesClient.deleteScheduledMessage(data.team_id, data.id),
                scheduledMessagesClient.createScheduledMessage(data.team_id, data.contact_id, data),
            ]);
        } catch (error) {
            toasted.global.platform_error();
            console.error(error);
        }
    },
    async reloadMedia(url, contentType, teamId, toasted) {
        let file = await this.fetchFileFromUrl(url, contentType, toasted);

        if (file === null) {
            return null;
        }

        return await this.uploadFile(file, teamId, toasted);
    },
    async fetchFileFromUrl(url, contentType, toasted) {
        let file = null;
        const urlExtension = this.getUrlExtension(url);

        await fetch(url)
            .then(async (res) => {
                const blob = await res.blob();
                file = new File([blob], 'newFile.' + urlExtension, { type: contentType });
            })
            .catch((error) => {
                toasted.global.platform_error();
                console.error(error);
            });

        return file;
    },
    async uploadFile(file, teamId, toasted) {
        try {
            const promise = new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append('file', file);

                MessageMediaClient.uploadMedia(teamId, formData, {})
                    .then((response) => {
                        resolve(response.data.url);
                    })
                    .catch(() => {
                        reject();
                    });
            });

            return await promise;
        } catch (error) {
            toasted.global.platform_error();
            console.error(error);
            return null;
        }
    },
    getUrlExtension(url) {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    },
};
