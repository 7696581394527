import BaseClient from './BaseClient';
import fileDownload from 'js-file-download';
import axios from '~/services/axios';

class ShiftToTextClient extends BaseClient {
    sound(teamId, type) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/shift-to-text/sounds/${type}`;
        return this.makeCoreApiRequest(url, method);
    }

    uploadSoundUrl(teamId, type, file) {
        const mimeType = file.type;

        const method = 'post';
        const url = `/api/v3/teams/${teamId}/shift-to-text/sounds/${type}/upload-url`;
        return this.makeCoreApiRequest(url, method, {
            mime_type: mimeType,
        });
    }

    uploadSound(teamId, type, file) {
        const mimeType = file.type;

        return this.uploadSoundUrl(teamId, type, file).then((response) => {
            const url = response.data.url;
            return axios
                .put(url, file, {
                    headers: {
                        'Content-Type': mimeType,
                    },
                })
                .then(() => this.createSound(teamId, type));
        });
    }

    createSound(teamId, type) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/shift-to-text/sounds/${type}`;

        return this.makeCoreApiRequest(url, method);
    }

    sounds(teamId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/shift-to-text/sounds`;
        return this.makeCoreApiRequest(url, method);
    }

    /**
     * Returns the download url of a .zip file containing all the voxie templates recordings
     * @param teamId
     * @returns {Promise<AxiosResponse<any>>}
     */
    templates(teamId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/shift-to-text/sounds/templates`;
        return this.makeCoreApiRequest(
            url,
            method,
            {},
            {},
            {
                responseType: 'arraybuffer',
            }
        ).then((response) => {
            const blob = new Blob([response.data], {
                type: 'application/octet-stream',
            });
            return fileDownload(blob, 'shift-to-text-templates.zip');
        });
    }

    import(teamId, file) {
        return this.importUrl(teamId).then((response) => {
            const url = response.data.url;
            return axios
                .put(url, file, {
                    headers: {
                        'Content-Type': 'application/zip',
                    },
                })
                .then(() => this.importReady(teamId));
        });
    }

    importUrl(teamId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/shift-to-text/sounds/import/url`;
        return this.makeCoreApiRequest(url, method);
    }

    importReady(teamId) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/shift-to-text/sounds/import`;
        return this.makeCoreApiRequest(url, method);
    }

    export(teamId) {
        const method = 'get';
        const url = `/api/v3/teams/${teamId}/shift-to-text/sounds/export`;
        return this.makeCoreApiRequest(
            url,
            method,
            {},
            {},
            {
                responseType: 'arraybuffer',
            }
        ).then((response) => {
            const blob = new Blob([response.data], {
                type: 'application/octet-stream',
            });
            return fileDownload(blob, 'shift-to-text-export-recordings.zip');
        });
    }
}

export default new ShiftToTextClient();
