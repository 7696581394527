import BaseClient from './BaseClient';

class ImportsClient extends BaseClient {
    list(teamId, importType, params) {
        return this.makeCoreApiRequest(`${this.apiBase}/teams/${teamId}/imports/${importType}`, 'GET', null, params);
    }
    listErrors(teamId, importType, importId, params) {
        return this.makeCoreApiRequest(
            `${this.apiBase}/teams/${teamId}/imports/${importType}/${importId}/errors`,
            'GET',
            null,
            params
        );
    }
}

export default new ImportsClient({
    apiBase: window.Spark.apiBase,
});
