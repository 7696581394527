<template>
    <div>
        <vx-selectable
            :multiple="true"
            :no-tags="true"
            @search="search"
            placeholder="Select a segment"
            :options="options"
            v-model="segments"
            :clearable="true"
            :dropdownShouldOpen="dropdownShouldOpen"
            :deselectFromDropdown="true"
            :relativeDropdown="true"
            :closeOnSelect="false"
            :clearSearchOnSelect="false"
            @update:modelValue="emit('update:modelValue', $event)"
            :disabled="props.disabled"
            :size="props.size"
        >
            <template v-slot:option="{ option }">
                <segments-selectable-row
                    :segment="option"
                    :contactsCount="contactsCount[option.id]?.count"
                    :selected="isSelected(option)"
                >
                </segments-selectable-row>
            </template>
        </vx-selectable>

        <div
            v-if="segments.length"
            class="vx-border vx-border-solid vx-border-slate-300 vx-rounded-lg vx-mt-6"
        >
            <div
                data-test="who-segments-selected"
                v-for="segment in segments"
                :key="segment.id"
                tabindex="0"
                role="button"
                class="vx-appearance-none vx-border-x-0 vx-border-t-0 last:vx-border-b-0 vx-border-b vx-border-slate-300 vx-border-solid  vx-flex vx-items-center vx-justify-between vx-px-4 vx-text-slate-700 vx-text-sm lg:vx-h-12"
                @click="remove(segment)"
                @keydown.enter="remove(segment)"
                @keydown.space="remove(segment)"
            >
                <segments-selectable-row
                    :disabled="props.disabled"
                    :segment="segment"
                    :contactsCount="contactsCount[segment.id]?.count"
                    :selected="isSelected(segment)"
                >
                </segments-selectable-row>
            </div>
        </div>
    </div>
</template>

<script setup>
import { VxSelectable } from '@voxie/frontend-components';
import segmentsClient from '../../../services/segmentsClient';
import { ref, onMounted, watch, inject } from 'vue';
import debounce from '~/utils/debounce';
import SegmentsSelectableRow from './SegmentsSelectableRow.vue';
import {
    AUDIENCE_TYPE_MARKETING, AUDIENCE_TYPE_TRANSACTIONAL, AUDIENCE_TYPE_ACQUISITION, AUDIENCE_TYPE_RE_ACQUISITION
} from '~/constants/campaign';
import dayjs from '~/utils/dayjs';

const FEATURES = inject('FEATURES')

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    modelValue: {
        type: Array,
        required: false,
        default: () => [],
    },
    audienceType: {
        type: String,
        default: undefined,
        validator: (audienceType) => [AUDIENCE_TYPE_MARKETING, AUDIENCE_TYPE_TRANSACTIONAL, AUDIENCE_TYPE_ACQUISITION, AUDIENCE_TYPE_RE_ACQUISITION].includes(audienceType)
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'md'
    },
    flowId: {
        type: [String, undefined],
        default: undefined,
    }
});
const emit = defineEmits(["update:modelValue"]);

watch(
    () => props.modelValue,
    (newModelValue) => {
        segments.value = newModelValue;
    }
);

watch(
    () => props.audienceType,
    () => {
        search();
    }
);

const segments = ref(props.modelValue);

const contactsCount = ref({})

const options = ref([]);

const isSelected = (segment) => {
    return Boolean(segments.value.find((item) => item.id == segment.id));
};

const remove = async (segment) => {
    if (props.disabled) return;

    segments.value = segments.value.filter((item) => item.id != segment.id);
    emit("update:modelValue", segments.value);
};

const fetchContactsCountFor = (segmentId) => {
    if (contactsCount.value[segmentId] &&
        (contactsCount.value[segmentId]?.expiresAt?.isAfter(dayjs()) ||
        contactsCount.value[segmentId]?.loading)
    ) {
        return;
    }

    contactsCount.value = {
        ...contactsCount.value,
        [segmentId]: {
            loading: true,
        },
    }

    segmentsClient.getSegmentContactsCount(segmentId, teamId)
        .then(({ data }) => {
            contactsCount.value = {
                ...contactsCount.value,
                [segmentId]: {
                    count: data.count,
                    expiresAt: dayjs().add(120, 'seconds'),
                    loading: false,
                }
            }
        }).catch(() => {
            contactsCount.value = {
                ...contactsCount.value,
                [segmentId]: {
                    loading: false,
                }
            }
        });
}

const search = debounce(async (search, loading) => {
    loading?.(true);

    const params = {
        sort: 'id',
        per_page: 15,
        query: search,
        filter: {
            audience_type: props.audienceType,
            flow: FEATURES.flows ? {
                in_flow: props.flowId ? 1 : 0,
                flow_id: props.flowId,
            } : undefined
        },
    };

    try {
        const response = await segmentsClient.getSegments(teamId, params);
        options.value = response.data.data.map((segment) => {
            return {
                ...segment,
                label: `${segment.id} - ${segment.name}`,
            }
        });

        options.value.forEach(segment => fetchContactsCountFor(segment.id));
    } finally {
        loading?.(false);
    }
}, 300);

const dropdownShouldOpen = (VueSelect) => {
    return VueSelect.search.length > 0 || VueSelect.open;
};

onMounted(() => search());
</script>
