import BaseClient from './BaseClient';

class AdminPhoneNumbersClient extends BaseClient {
    search(teamId, payload) {
        const url = `/api/admin/teams/${teamId}/search/phone-numbers`;
        return this.makeCoreApiRequest(url, 'get', null, payload);
    }

    create(teamId, data) {
        const url = `/api/admin/teams/${teamId}/phone-numbers`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    update(teamId, phoneNumberId, data) {
        const url = `/api/admin/teams/${teamId}/phone-numbers/${phoneNumberId}`;
        return this.makeCoreApiRequest(url, 'put', data);
    }

    delete(teamId, phoneNumberId) {
        const url = `/api/admin/teams/${teamId}/phone-numbers/${phoneNumberId}`;
        return this.makeCoreApiRequest(url, 'delete');
    }

    host(teamId, data) {
        const url = `/api/admin/teams/${teamId}/phone-numbers/host`;
        return this.makeCoreApiRequest(url, 'post', data);
    }
}

export default new AdminPhoneNumbersClient();
