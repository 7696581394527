<template>
    <div class="vx-flex vx-flex-col vx-gap-4">
        <div>
            <vx-label size="md">Notification Type</vx-label>

            <vx-selectable size="lg"
                v-model="data.notification_type"
                :options="['email', 'sms']"
                :error="displayFirstError(validate.notification_type)"
                :clearable="false">
            </vx-selectable>
        </div>

        <div>
            <vx-label size="md"
                v-if="!data.notification_type">Send To</vx-label>
            <vx-label size="md"
                v-if="data.notification_type == 'email'">Email</vx-label>
            <vx-label size="md"
                v-if="data.notification_type == 'sms'">Phone</vx-label>
            <vx-input size="lg"
                data-test="notification-address"
                type="text"
                v-model="data.notification_address"
                :error="displayFirstError(validate.notification_address)" />
        </div>
    </div>
</template>
<script setup>
import { VxSelectable, VxInput, VxLabel } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { displayFirstError } from '~/utils/validation';

const data = defineModel();

const validate = useVuelidate({
    notification_address: {
        required,
    },
    notification_type: {
        required,
    },
}, data, {
    $autoDirty: true,
})
</script>

