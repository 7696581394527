<template>
    <div class="card card-default">
        <div class="card-header">Send Invitation</div>

        <div class="card-body">
            <!-- Success Message -->
            <div class="alert alert-success" v-if="form.successful">The invitation has been sent!</div>

            <form role="form" v-if="canInviteMoreTeamMembers">
                <!-- Email Address -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Email Address</label>

                    <div class="col-md-6">
                        <input
                            type="email"
                            class="form-control"
                            name="email"
                            v-model="form.email"
                            :class="{ 'is-invalid': form.errors.has('email') }"
                        />
                        <span class="invalid-feedback" v-if="hasTeamMembersLimit">
                            {{ ` You currently have ${remainingTeamMembers} invitation(s) remaining.` }}
                        </span>
                        <span class="invalid-feedback" v-show="form.errors.has('email')">
                            {{ form.errors.get('email') }}
                        </span>
                    </div>
                </div>

                <!-- Role -->
                <div class="form-group row" v-if="roles.length > 0">
                    <label class="col-md-4 col-form-label text-md-right">Role</label>

                    <div class="col-md-6">
                        <select
                            class="form-control"
                            v-model="form.role"
                            :class="{ 'is-invalid': form.errors.has('role') }"
                        >
                            <option v-for="(role, index) in roles" :key="index" :value="role.value">
                                {{ role.text }}
                            </option>
                        </select>
                        <span class="invalid-feedback" v-show="form.errors.has('role')">
                            {{ form.errors.get('role') }}
                        </span>
                    </div>
                </div>

                <!-- Send Invitation Button -->
                <div class="form-group row mb-0">
                    <div class="offset-md-4 col-md-6">
                        <button type="submit" class="btn btn-primary" @click.prevent="send" :disabled="form.busy">
                            <span v-if="form.busy"> <i class="fa fa-btn fa-spinner fa-spin"></i> Sending </span>

                            <span v-else> Send Invitation </span>
                        </button>
                    </div>
                </div>
            </form>

            <div v-else>
                <span class="text-danger">
                    Your current plan doesn't allow you to invite more members, please upgrade your subscription.
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '~/services/axios';

export default {
    props: ['team', 'defaultRole'],
    emits: ['updateInvitations'],

    /**
     * The component's data.
     */
    data() {
        return {
            plans: [],

            roles: [],

            form: new SparkForm({
                email: '',
                role: this.defaultRole,
            }),
        };
    },

    computed: {
        /**
         * Get the active subscription instance.
         */
        activeSubscription() {
            if (!this.team) {
                return;
            }

            return this.team?.subscriptions?.find((subscription) => subscription.name === 'default');
        },

        /**
         * Get the active plan instance.
         */
        activePlan() {
            return this.plans?.find((plan) => {
                    return plan.id === this.activeSubscription?.provider_plan;
                });
        },

        /**
         * Check if there's a limit for the number of team members.
         */
        hasTeamMembersLimit() {
            if (!this.activePlan) {
                return false;
            }

            return !!this.activePlan.attributes.teamMembers;
        },

        /**
         *
         * Get the remaining team members in the active plan.
         */
        remainingTeamMembers() {
            return this.activePlan ? this.activePlan.attributes.teamMembers - this.$parent.team.users.length : 0;
        },

        /**
         * Check if the user can invite more team members.
         */
        canInviteMoreTeamMembers() {
            if (!this.hasTeamMembersLimit) {
                return true;
            }

            return this.remainingTeamMembers > 0;
        },
    },

    /**
     * The component has been created by Vue.
     */
    created() {
        this.getPlans();

        this.getRoles();

        Bus.$on(`sparkFormUpdated.${this.form.sparkFormId}`, this.$forceUpdate);
    },

    methods: {
        /**
         * Send a team invitation.
         */
        send() {
            Spark.post(`/settings/teams/${this.team.id}/invitations`, this.form)
                .then(() => {
                    this.form.email = '';
                    this.form.role = Spark.defaultRole;

                    this.$emit('updateInvitations');
                })
                .catch(() => {
                    this.$toasted.global.platform_error();
                });
        },

        /**
         * Get all the plans defined in the application.
         */
        getPlans() {
            axios.get('/spark/plans').then((response) => {
                this.plans = response.data;
            });
        },

        /**
         * Get the available member roles.
         */
        getRoles() {
            axios.get(`/settings/teams/roles`).then((response) => {
                this.roles = response.data;
            });
        },
    },
};
</script>
