<template>
    <auth-box class="vx-max-w-[480px]">
        <form @submit="submitting = true"
            method="POST"
            action="/password/reset"
            class="vx-flex vx-flex-col vx-gap-4">

            <input name="token" :value="token" type="hidden">
            <input name="email" :value="params.email" type="hidden">

            <div>
                <vx-label>
                    New Password
                </vx-label>
                <vx-input :error="props.errors?.length ? props.errors : undefined" required type="password" autofocus name="password" id="password" size="lg"></vx-input>
            </div>
            <div>
                <vx-label>
                    Confirm Password
                </vx-label>
                <vx-input required type="password" autofocus name="password_confirmation" id="password_confirmation" size="lg"></vx-input>
            </div>

            <div class="vx-flex vx-flex-col vx-gap-2 vx-mt-4 md:vx-flex-row-reverse">
                <vx-button :loading="submitting" class="md:vx-flex-grow" type="submit" color="primary" size="lg">
                    Change Password <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>

                <vx-button color="muted" size="lg" href="/login">
                    Cancel
                </vx-button>
            </div>
        </form>
    </auth-box>
</template>
<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AuthBox from './AuthBox.vue';
import { VxLabel, VxInput, VxButton } from '@voxie/frontend-components';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { computed, ref } from 'vue';
import { useUrlSearchParams, useBrowserLocation } from '@vueuse/core'

const props = defineProps({
    errors: Array,
});

const submitting = ref(false);

const params = useUrlSearchParams('history', {
    removeNullishValues: true,
    removeFalsyValues: true,
});

const location = useBrowserLocation();

const token = computed(() => {
    const segments = location.value.pathname.split('/');
    return segments[segments.length - 1];
});

</script>
