<template>
    <div class="vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8 2xl:vx-max-w-screen-xl">
        <page-header>Import Contacts</page-header>

        <help-block id="imports" type="success">
            Upload a group of contacts you'd like to store
            and message within Voxie. Every piece of data
            you upload about a contact as a column header
            will be stored as a custom variable and can be
            later used to filter or target that contact.
        </help-block>

        <contacts-import-list-v3 ref="importListV3" ></contacts-import-list-v3>
    </div>
</template>

<script>
import HelpBlock from '../../general/HelpBlock.vue';
import ContactsImportListV3 from './ContactsImportListV3.vue';
import PageHeader from '~/components/general/PageHeader.vue';
import clientRoutes from './../../../mixins/client-routes';
import scrollTo from './../../../mixins/scroll-to';

export default {
    components: {
        HelpBlock,
        ContactsImportListV3,
        PageHeader
    },

    mixins: [
        clientRoutes,
        scrollTo,
    ],

    data() {
        return {
            teamId: Spark.state.currentTeam.id,
        };
    },
};
</script>
