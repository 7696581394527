<template>
    <page-container v-if="!loading">
        <template v-if="!success">
            <step-form-heading v-if="!props.apiDestinationId" :icon="faBookAtlas">
                <experimental-pill class="-vx-mt-10 -vx-mr-4 vx-mb-2" type="alpha"></experimental-pill>
                <div>
                    New API Destination
                </div>

                <template v-slot:description>
                    Connect Voxie to another service for tighter integrations to your other services.
                </template>
            </step-form-heading>
            <page-header v-if="props.apiDestinationId" class="vx-mb-6 lg:vx-mb-12">
                <template v-slot:subtitle>
                    <a href="/settings/integrations#/api-destinations"
                        class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                        <font-awesome-icon class="vx-mr-1" :icon="faArrowLeft"></font-awesome-icon>
                        All API Destinations
                    </a>
                </template>

                {{ data.friendly_name }}
            </page-header>
            <div :class="{
                'vx-grid vx-grid-cols-12': props.apiDestinationId,
            }">
                <api-destination-sidebar v-if="props.apiDestinationId" :apiDestinationId="props.apiDestinationId"></api-destination-sidebar>
                <vx-sticky-nav :sections="[
                    { id: 'name', label: 'Name' },
                    { id: 'endpoint', label: 'Endpoint' },
                    { id: 'method', label: 'Method' },
                    { id: 'auth', label: 'API Auth' },
                ]" class="vx-col-span-12" :class="{
    'lg:vx-col-span-10': !!props.apiDestinationId,
}" :navClasses="`${props.apiDestinationId ? 'vx-hidden' : ''}`">

                    <form @submit.prevent="handleSubmit()" id="api-destination-form"
                        class="vx-flex vx-flex-col vx-mx-auto vx-justify-center vx-gap-2 md:vx-gap-8">

                        <vx-expandable-card id="name" class="vx-max-w-screen-sm vx-w-full" :class="{
                            'vx-mx-auto': !props.apiDestinationId,
                        }">
                            <template v-slot:content>
                                <api-destination-form-name v-model="data.friendly_name"
                                    :error="errors?.friendly_name"></api-destination-form-name>
                            </template>
                        </vx-expandable-card>
                        <vx-expandable-card id="endpoint" class="vx-max-w-screen-sm vx-w-full" :class="{
                            'vx-mx-auto': !props.apiDestinationId,
                        }">
                            <template v-slot:content>
                                <api-destination-form-endpoint v-model="data.uri_template" :disabled="!!data.id"
                                    :error="errors?.uri_template"></api-destination-form-endpoint>
                                    <vx-button v-if="props.apiDestinationId"
                                        :href="`/settings/integrations/api-destinations/new?duplicateFromId=${props.apiDestinationId}`"
                                        color="muted"
                                        size="sm"
                                        class="vx-mt-4"
                                    >
                                        Make a copy <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                                    </vx-button>
                            </template>
                        </vx-expandable-card>
                        <vx-expandable-card id="method" class="vx-max-w-screen-sm vx-w-full" :class="{
                            'vx-mx-auto': !props.apiDestinationId,
                        }">
                            <template v-slot:content>
                                <api-destination-form-http-method v-model="data.http_method"
                                    :error="errors?.http_method"></api-destination-form-http-method>
                            </template>
                        </vx-expandable-card>
                        <vx-expandable-card id="auth" class="vx-max-w-screen-sm vx-w-full" :class="{
                            'vx-mx-auto': !props.apiDestinationId,
                        }">
                            <template v-slot:content>
                                <api-destination-form-auth v-model="data.auth_config_id"
                                    :error="errors?.auth_config_id"></api-destination-form-auth>
                            </template>
                        </vx-expandable-card>

                        <vx-sticky-footer class="vx-max-w-screen-sm vx-w-full" :class="{
                            'vx-mx-auto': !props.apiDestinationId,
                        }">
                            <vx-button href="/settings/integrations#/api-destinations" class="vx-w-20" size="lg"
                                color="muted">
                                Cancel
                            </vx-button>
                            <vx-button form="api-destination-form" type="submit" class="vx-grow" size="lg" color="primary"
                                :loading="submitting">
                                {{ props.apiDestinationId ? 'Update' : 'Create' }} API Destination
                            </vx-button>
                        </vx-sticky-footer>
                    </form>
                </vx-sticky-nav>
            </div>
        </template>
        <template v-else>
            <success-box>
                Your API Destination has been {{ props.apiDestinationId ? 'updated' : 'created' }}!

                <template v-slot:action>
                    <vx-button size="lg" href="/settings/integrations#/api-destinations" color="secondary">
                        View All API Destinations
                        <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                    </vx-button>
                </template>
            </success-box>
        </template>
    </page-container>
</template>

<script setup>
import {
    VxStickyNav,
    VxStickyFooter,
    VxButton,
    VxExpandableCard
} from "@voxie/frontend-components";
import StepFormHeading from '../../../../general/StepFormHeading.vue';
import { faBookAtlas, faArrowRight, faArrowLeft, faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ExperimentalPill from "../../../../general/ExperimentalPill.vue";
import ApiDestinationFormName from './ApiDestinationFormName.vue';
import ApiDestinationFormEndpoint from './ApiDestinationFormEndpoint.vue';
import ApiDestinationFormHttpMethod from './ApiDestinationFormHttpMethod.vue';
import ApiDestinationFormAuth from './ApiDestinationFormAuth.vue';
import ApiDestinationSidebar from '../ApiDestinationSidebar.vue';
import SuccessBox from '../../../../general/SuccessBox.vue';
import PageHeader from '../../../../general/PageHeader.vue';
import PageContainer from '../../../../general/PageContainer.vue';
import { useApiDestinationForm } from "./useApiDestinationForm";
import { onMounted } from "vue";
import { useUrlSearchParams } from '@vueuse/core';

const props = defineProps({
    apiDestinationId: {
        type: String,
        required: false,
        default: undefined,
    }
});

const { data, errors, submit, submitting, loading, success, duplicate } = useApiDestinationForm();

const handleSubmit = async () => {
    try {
        await submit();
    } catch (e) {
        console.error(e);
    }
}

const queryParams = useUrlSearchParams();

onMounted(async () => {
    if (props.apiDestinationId) {
       data.value.id = props.apiDestinationId;
    }

    if (queryParams.duplicateFromId) {
        duplicate(queryParams.duplicateFromId);
    }
});
</script>
