<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        size="md"
        :padding="false"
        >
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6 vx-flex vx-items-center vx-gap-2">
                <span>
                    AI Configuration
                </span>
                <experimental-pill type="beta"
                    class="-vx-mt-1"></experimental-pill>
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">
                Configure how you'd like the AI to work below. The more information you add, the better it will be.
            </div>
        </template>

        <div v-if="loading" class="vx-text-center vx-py-10">
            <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
        </div>
        <form v-else @submit.prevent="handleSubmit()"
            id="ai-configuration-modal"
            class="vx-flex vx-flex-col vx-gap-4 vx-py-4 vx-px-5 lg:vx-py-7 lg:vx-px-10">

            <ai-configuration-company-pitch v-model="data.company_pitch" :error="errors?.company_pitch"></ai-configuration-company-pitch>
            <template v-if="FEATURES.message_hub_ai">
                <ai-configuration-role v-model="data.settings.role"
                        :error="errors['settings.role']"></ai-configuration-role>
                <ai-configuration-tone v-model="data.settings.tone"
                :error="errors['settings.tone']"></ai-configuration-tone>
                </template>
        </form>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="emit('update:visible', false)"
                    type="button"
                    class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                    size="lg"
                    color="muted">
                    Cancel
                </vx-button>
                <vx-button type="submit"
                    form="ai-configuration-modal"
                    :loading="submitting"
                    class="vx-grow"
                    size="lg"
                    color="primary">
                    Save
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import {
    VxModal,
    VxButton,
    VxIconLoading,
} from "@voxie/frontend-components";
import ExperimentalPill from "../../../general/ExperimentalPill.vue";
import AiConfigurationCompanyPitch from './AiConfigurationCompanyPitch.vue';
import AiConfigurationRole from './AiConfigurationRole.vue';
import AiConfigurationTone from './AiConfigurationTone.vue';
import { useAiConfiguration } from './useAiConfiguration'; 
import { watch, inject } from "vue";

const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:visible', 'saved']);

const FEATURES = inject('FEATURES');

const { errors, submitting, loading, submit, data, mount } = useAiConfiguration();

const handleSubmit = async () => {
    try {
        emit('saved', await submit());
        emit('update:visible', false)
    } catch (e) {
        console.error(e);
    }
}

watch(() => props.visible, async () => {
    if (props.visible) {
        mount();
    }
}, {
    immediate: true,
});
</script>
