<template>
    <input-list placeholder="Enter custom attribute key" :maxValues="maxKeys" :loading="loading"
        :validationRules="validationRules" :modelValue="attributes" @update:modelValue="($event) => {
            attributes = $event.map(attr => ({...attr, value: attr.value.toLowerCase()}));
        }"
        @search="search"
        :options="options"
        >
        <template v-slot:header>
            <div class="vx-flex vx-flex-col vx-items-start vx-gap-2">
                <vx-badge color="violet" size="xs">
                    <span class="vx-pb-[1.5px] vx-text-xs vx-leading-snug">Optional</span>
                    <font-awesome-icon :icon="faPencilSlash" size="xs" />
                </vx-badge>
                Custom Attributes
            </div>
        </template>
        <template v-slot:subheader>
            <span class="vx-text-sm vx-text-slate-500">
                You can add up to {{ maxKeys }} custom attributes.
                You can configure the default attributes in your
                <a class="vx-text-slate-500 vx-underline" href="/settings/teams/1#/contacts-exports" target="_blank">team
                    settings</a>.
            </span>
        </template>
        <template v-slot:empty>
            No custom attributes selected.
        </template>
        <template v-slot:max-values>
            <span v-if="attributes.length < maxKeys" class="vx-px-1 vx-text-teal-600">
                {{ `${attributes.length}/${maxKeys} attributes used` }}
            </span>
            <span v-else class="vx-px-1 vx-text-rose-600">
                You've reached the {{ maxKeys }} attribute limit.
            </span>
        </template>
    </input-list>
</template>
<script setup>
import { VxBadge } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPencilSlash } from '@fortawesome/pro-solid-svg-icons';
import { ref } from 'vue';
import { helpers, maxLength, required } from '@vuelidate/validators';
import { csvInjection, customAttributeKey, reservedCustomAttributesKeys } from '~/utils/validation';
import contactsClient from '~/services/contactsClient';
import { randomString } from '~/utils/string';
import { useToasted } from '~/composables/useToasted';
import InputList from '~/components/general/InputList.vue';
import debounce from '~/utils/debounce';
import { customAttributesClient } from '~/services';

const options = ref([]);
const toasted = useToasted();

const teamId = Spark.state.currentTeam.id;
const maxKeys = 20;
const attributes = ref([]);

const loading = ref(false);
const getConfig = async () => {
    if (loading.value) {
        return;
    }

    loading.value = true;

    try {
        const response = await contactsClient.getContactsExportConfig(teamId);

        attributes.value = response.data?.settings?.custom_attribute_keys.map((attr) => ({
            id: randomString(20),
            value: attr,
        })) || [];
    } catch (e) {
        console.error(e);
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
}

getConfig();

const validationRules = ref({
    required,
    maxLength: maxLength(80),
    csvInjection,
    customAttributeKey,
    reservedCustomAttributesKeys,
    distinct: helpers.withMessage(({
        $model
    }) => `Custom attribute "${$model}" has already been added`, (value) => !attributes.value.find((attr) => attr.value === value.trim()))
});

const submit = async () => {
    return attributes.value.map((attr) => attr.value);
}

const search = debounce((query) => {
    customAttributesClient.customAttributesAutocomplete(teamId, {
        query: query,
    }).then(response => {
        options.value = response.data.data;
    })
}, 300)


defineExpose({
    submit,
});
</script>
