<template>
    <vx-select
        class="phone-number-input"
        placeholder="Enter Phone Number"
        :model-value="selectedContact"
        :loading="loadingData"
        :filterable="false"
        :options="options"
        @search="onSearch"
        @update:model-value="onChange"
        :clearable="false"
    >
        <template #no-options="{ search }">
            <div class="phone-number-input__no-options">
                <template v-if="!newOnEmpty && search">
                    Sorry, no matching options.
                </template>
                <template v-else>
                    Start typing to search contacts
                </template>
            </div>
        </template>
        <template #option="{ option }">
            <div class="phone-number-input__option-text" v-if="option.name">{{ option.name }}</div>
            <div class="phone-number-input__option-text">{{ option.phone }}</div>
        </template>
        <template #selected-option="{ option }">
            <div class="phone-number-input__option-text">{{ option.label }}</div>
        </template>
    </vx-select>
</template>
<script setup>
import { ref } from 'vue';
import { VxSelect } from '@voxie/frontend-components-v3';
import debounce from '~/utils/debounce';
import { contactsClient } from '../../services';
import { isLargeTeam } from '~/utils/team';

const props = defineProps({
    id: [String, Number],
    segment: Object,
    newOnEmpty: {
        type: Boolean,
        default: true
    },
});

const selectedContact = ref(null);
const onChange = (op) => {
    selectedContact.value = op;
    if (op instanceof Object) {
        if (op.value != undefined) {
            selectedContact.value = op;
            Bus.$emit(
                `select-contact-${props.id}-changed`,
                op.value,
                op.label,
            );
        }
    } else if (op == null) {
        Bus.$emit(
            `select-contact-${props.id}-changed`,
            null,
            null,
            null
        );
    }
};

const options = ref([]);
const runSearch = (loading, search) => {
    const teamId = Spark.state.currentTeam.id;
    const params = {};
    const searchQuery = search.trim();
    if (searchQuery) {
        if (isLargeTeam()) {
            params['filter[phone]'] = searchQuery;
        } else {
            params['filter[search]'] = searchQuery;
        }
    }
    if (props.segment) {
        params['include'] = 'segments';
        params['filter[segments.id]'] = props.segment.id;
    }
    contactsClient.searchContacts(teamId, params)
        .then(response => {
            let searchResults = [];
            if (response.data.data) {
                response.data.data.forEach(el => {
                    const firstName = el.first_name || '';
                    const lastName = el.last_name || '';
                    const name = lastName ? `${firstName} ${lastName}` : firstName;
                    const label = name ? `${el.phone} - ${name}` : el.phone;
                    const resultObj = {
                        value: el.id,
                        name,
                        phone: el.phone,
                        label,
                    };
                    searchResults.push(resultObj);
                });
            }
            if (!searchResults.length && props.newOnEmpty) {
                searchResults.push({
                    value: 'new',
                    phone: searchQuery,
                    label: searchQuery
                });
            }
            options.value = searchResults;
            loading(false);
        });
};

const debouncedSearch = debounce(runSearch, 350);
const onSearch = (search, loading) => {
    loading(true);
    debouncedSearch(loading, search);
};

const loadingData = ref(false);
</script>

<style lang="postcss">
.phone-number-input__no-options {
  padding-top: 23px;
  padding-bottom: 43px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #374151;
}
.phone-number-input__option-text {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #374151;
}
</style>
