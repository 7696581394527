<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        size="lg"
        :padding="false">
        <template v-slot:header>
            <div class="vx-pt-6 vx-px-6 vx-flex vx-items-center vx-gap-2">
                <span>
                    {{ data.auth_config_id ? 'Edit' : 'New' }}  API Auth
                </span>
                <experimental-pill type="alpha"
                    class="-vx-mt-1"></experimental-pill>
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">This will {{ data.auth_config_id ? 'update' : 'create' }}  and select a new API Auth.</div>
        </template>

        <form @submit.prevent="handleSubmit()"
            autocomplete="off"
            id="auth-config-modal-form"
            class="vx-flex vx-flex-col vx-gap-4 vx-py-4 vx-px-5 lg:vx-py-7 lg:vx-px-10">

            <auth-config-form-name ref="nameRef" v-model="data.friendly_name" :error="errors?.friendly_name"></auth-config-form-name>
            <auth-config-form-headers
                :defaultTab="defaultTab"
                :authConfigId="props.authConfigId"
                v-model:name="data.header_name"
                v-model:value="data.header_value"
                :error="errors?.header_name || errors?.header_value">
            </auth-config-form-headers>
        </form>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click="emit('update:visible', false)"
                    type="button"
                    class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                    size="lg"
                    color="muted">
                    Cancel
                </vx-button>
                <vx-button type="submit"
                    form="auth-config-modal-form"
                    :loading="submitting"
                    class="vx-grow"
                    size="lg"
                    color="primary">
                    {{ data.auth_config_id ? 'Update' : 'Create and Select' }} API Auth <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import {
    VxModal,
    VxButton,
} from "@voxie/frontend-components";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import ExperimentalPill from "../../../../general/ExperimentalPill.vue";
import AuthConfigFormName from './AuthConfigFormName.vue';
import AuthConfigFormHeaders from './AuthConfigFormHeaders.vue';
import { useAuthConfigForm } from "./useAuthConfigForm";
import { ref, watch } from "vue";

const props = defineProps({
    visible: {
        type: Boolean,
        default: false,
    },
    authConfigId: {
        type: String,
        required: false,
        default: undefined,
    }
});

const emit = defineEmits(['update:visible', 'upserted']);

const nameRef = ref();

const { data, errors, submit, submitting } = useAuthConfigForm();

const handleSubmit = async () => {
    try {
        emit('upserted', await submit());
        emit('update:visible', false)
    } catch (e) {
        console.error(e);
    }
}

const defaultTab = ref('basic');

watch(() => props.visible, async () => {
    if (props.visible) {
        data.value.auth_config_id = props.authConfigId;
        defaultTab.value = props.authConfigId ? 'custom' : 'basic';
    }
}, {
    immediate: true,
});
</script>
