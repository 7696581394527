import BaseClient from './BaseClient';

class AiClient extends BaseClient {
    quickBlastSuggestions(teamId, params) {
        const url = `/api/v3/teams/${teamId}/ai/quick-blast`;
        return this.makeCoreApiRequest(url, 'post', params);
    }
    quickBlastFollowUp(teamId, campaignId) {
        const url = `/api/v3/teams/${teamId}/ai/quick-blast/${campaignId}/follow-up`;
        return this.makeCoreApiRequest(url, 'post');
    }
    messageHubSuggestions(teamId, params) {
        const url = `/api/v3/teams/${teamId}/ai/message`;
        return this.makeCoreApiRequest(url, 'post', params);
    }
}

export default new AiClient();
