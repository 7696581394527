<template>
    <div>
        <div class="vx-flex vx-items-center vx-justify-between vx-mb-4">
            <div class="vx-text-slate-700 vx-font-extrabold">
                API Destination
            </div>
            <experimental-pill type="alpha"></experimental-pill>
        </div>
        <vx-alert v-model:visible="upsertedSuccessfully"
            color="success"
            class="vx-mb-4">
            <template v-slot:icon>
                <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
            </template>
            API Destination saved and selected.
        </vx-alert>
        <div v-if="loading || apiDestinations.length"
            class="vx-flex vx-flex-col vx-gap-2 lg:vx-flex-row">
            <vx-selectable placeholder="Select an API Destination"
                v-model="apiDestination"
                :options="apiDestinations"
                :loading="loading"
                label="friendly_name"
                :clearable="false"
                :error="apiDestinationError"
                size="lg"></vx-selectable>
            <vx-popover v-if="apiDestination?.id"
                class="vx-w-full lg:vx-w-auto"
                hover
                :arrow="true"
                placement="top">
                <vx-button block
                    :disabled="!isAdminOrOwner"
                    @click.prevent="showModalForm = true;"
                    type="button"
                    color="muted"
                    size="lg"
                    outline>Edit <font-awesome-icon :icon="faPen"></font-awesome-icon></vx-button>
                <template v-if="!isAdminOrOwner"
                    v-slot:content>
                    You do not have permission to edit.
                </template>
            </vx-popover>
            <vx-popover hover
                class="vx-w-full lg:vx-w-auto"
                :arrow="true"
                placement="top">
                <vx-button block
                    :disabled="!isAdminOrOwner"
                    @click.prevent="addNewApiDestination()"
                    type="button"
                    color="muted"
                    size="lg">Add New <font-awesome-icon :icon="faPlus"></font-awesome-icon></vx-button>
                <template v-if="!isAdminOrOwner"
                    v-slot:content>
                    You do not have permission to create.
                </template>
            </vx-popover>
        </div>
        <div v-else>
            <p v-if="!isAdminOrOwner"
                class="vx-text-sm vx-mb-4 vx-text-slate-700">You do not have permission to create an API Destination.
                Contact an admin for your account to update your permissions or create the API Destination for you.</p>
            <vx-button :disabled="!isAdminOrOwner"
                @click.prevent="addNewApiDestination()"
                type="button"
                color="muted"
                size="lg"
                block>Add New <font-awesome-icon :icon="faPlus"></font-awesome-icon></vx-button>
        </div>

        <div v-if="apiDestination"
            class="vx-mt-6 vx-flex vx-flex-col vx-gap-y-4">
            <div>
                <div class="vx-text-slate-700 vx-font-extrabold">
                    URL Variables
                </div>
                <div class="vx-text-slate-500 vx-text-sm">
                    If you need help adding URL variables <a class="vx-underline"
                        href="https://support.voxie.com"
                        target="_blank"
                        rel="noopener noreferrer">check out this documentation</a>.
                </div>
            </div>
            <div>
                <div class="vx-font-bold vx-text-base">Endpoint</div>
                <div class="vx-font-mono vx-break-words">{{ apiDestination.uri_template }}</div>
            </div>
            <div v-for="(uriBinding, index) in uriBindings"
                :key="uriBinding.key">
                <div class="vx-font-mono">{{ uriBinding.key }}</div>
                <vx-input v-model="uriBindings[index].value"
                    @blur="emitData()"
                    size="lg">
                    <template v-slot:box>
                        <font-awesome-icon class="vx-text-slate-800 vx-text-opacity-30"
                            :icon="faCodeSimple"></font-awesome-icon>
                    </template>
                </vx-input>
            </div>
        </div>
        <div v-if="apiDestination"
            class="vx-bg-slate-50 vx-rounded-2xl vx-mt-6 vx-p-4 lg:vx-px-6">
            <div class="vx-flex vx-items-center vx-justify-between vx-mb-0">
                <div class="vx-text-slate-700 vx-font-extrabold vx-flex vx-items-center vx-gap-2">
                    <font-awesome-icon :icon="faInboxOut"
                        class="vx-text-base"></font-awesome-icon>
                    Payload
                </div>
                <div class="vx-flex vx-items-center vx-gap-2">
                    <experimental-pill type="alpha"></experimental-pill>
                    <vx-button size="sm"
                        color="muted"
                        @click="payloadModal = true">
                        <font-awesome-icon :icon="faCircleQuestion"></font-awesome-icon>
                    </vx-button>
                </div>
            </div>
            <div class="vx-text-slate-500 vx-mb-4">
                We use <a class="vx-underline"
                    href="https://jmespath.org/"
                    target="_blank"
                    rel="noopener noreferrer">JMESPath</a> to query and transform data. Modify the payload to be sent to the
                API below.
            </div>
            <div>
                <vx-code-editor v-model="payloadTemplate"
                    :placeholder="placeholder"></vx-code-editor>
            </div>
        </div>

        <api-destination-modal-form v-model:visible="showModalForm"
            :apiDestinationId="apiDestination?.id"
            @upserted="upsertAndSelect($event)"></api-destination-modal-form>

        <vx-modal v-model:visible="payloadModal"
            :padding="false"
            size="lg">
            <template v-slot:header>
                <div class="vx-pt-6 vx-px-6 vx-flex vx-items-center vx-gap-2">
                    Sample Event Data
                </div>
            </template>
            <template v-slot:subheader>
                <div class="vx-px-6">The following data is available for you to use in the payload.</div>
            </template>

            <div class="vx-p-6">
                <vx-code-editor :modelValue="payload"
                    disabled></vx-code-editor>
            </div>
        </vx-modal>
    </div>
</template>

<script setup>
import { VxAlert, VxButton, VxInput, VxCodeEditor, VxSelectable, VxPopover, VxModal } from '@voxie/frontend-components';
import { computed, onMounted, ref, watch } from 'vue';
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';
import apiDestinationsClient from '../../../../services/apiDestinationsClient';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleCheck, faCircleQuestion, faCodeSimple, faInboxOut, faPen, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useToasted } from '../../../../composables/useToasted';
import { uniqBy } from '~/utils/helpers';
import ApiDestinationModalForm from '~/components/settings/integrations/api-destinations/form/ApiDestinationModalForm.vue';

const toasted = useToasted();

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    event: String,
});

const emit = defineEmits(['update:modelValue']);

const isAdminOrOwner = Spark.isAdminOrOwner;

const showModalForm = ref(false);

const placeholder = `{
  "exampleKey.$": "contact.first_name"
}`;

const loading = ref(false);
const apiDestinations = ref([]);

const teamId = Spark.state.currentTeam.id;

const apiDestination = ref(null)
const apiDestinationError = ref(null)
const uriBindings = ref([])
const payloadTemplate = ref('')

const mounted = ref(false);

const emitData = () => {
    const data = {
        team_id: teamId,
        api_destination_id: apiDestination.value?.id,
        uri_bindings: uriBindings.value.reduce((bindings, binding) => ({
            ...bindings,
            [binding.key]: binding.value,
        }), {}),
        payload_template: payloadTemplate.value
    };
    emit('update:modelValue', data);
};

const upsertedSuccessfully = ref(false);
const upsertAndSelect = (event) => {
    upsertedSuccessfully.value = true;
    apiDestinations.value.unshift(event);
    apiDestinations.value = uniqBy(apiDestinations.value, 'id');
    apiDestination.value = event;
}

const addNewApiDestination = () => {
    showModalForm.value = true
    apiDestination.value = null;
}

watch(apiDestination, () => {
    apiDestinationError.value = null;
});

watch([apiDestination, uriBindings, payloadTemplate], () => {
    if (mounted.value) {
        emitData();
    }
})

const computeUriBindings = () => {
    uriBindings.value = apiDestination.value?.uri_variables?.reduce((bindings, variable) => [...bindings, {
        key: variable,
        value: props.modelValue?.uri_bindings?.[variable] || ''
    }], []) || [];
}

watch(apiDestination, () => computeUriBindings())

onMounted(async () => {
    if (props.modelValue?.api_destination_id) {
        try {
            apiDestination.value = (await apiDestinationsClient.get(teamId, props.modelValue?.api_destination_id)).data;
            apiDestinations.value = [apiDestination.value]
        } catch (e) {
            console.error(e);
            apiDestinationError.value = 'The previously connect API Destination record is missing. Select a new one.';
        }
    }

    payloadTemplate.value = props.modelValue?.payload_template || '';

    try {
        loading.value = true;
        apiDestinations.value = apiDestinations.value.concat((await apiDestinationsClient.list(teamId, {
            per_page: 50,
            sort_dir: 'desc',
        })).data.data)
        apiDestinations.value = uniqBy(apiDestinations.value, 'id');

        mounted.value = true;
    } catch (e) {
        console.error(e)
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
});
const samplePayload = {
    "team": {
        "id": 1,
        "name": "Acme",
        "custom_attributes": {
            "store_name": "Steve's Store"
        }
    },
    "contact": {
        "id": 2,
        "phone": "+15555551234",
        "email": "dave@example.com",
        "first_name": "Dave",
        "last_name": "Campbell",
        "time_zone": "America/New_York",
        "entry_point": "legacy",
        "groups": ["acme"],
        "tags": ["foo", "bar"],
        "custom_attributes": {
            "fav_car": "Lambo"
        },
        "subscriptions": {
            "transactional": "unknown",
            "marketing": "opt_out"
        }
    },
    "campaign": {
        "id": 3,
        "name": "Quick Blast 3",
        "status": "scheduled",
        "audience_type": "marketing",
        "duration_type": "one_time"
    },
    "group": {
        "id": "acme"
    },
    "tag": {
        "name": "likes_lambos"
    },
    "subscription": {
        "type": "marketing",
        "status": "opt_in"
    },
    "dynamic_field": {
        "type": "company",
        "choices": "null",
        "custom_attribute_key": "extracted_field"
    },
    "custom_attribute": {
        "key": "fav_car",
        "value": "Lambo"
    },
    "message": {
        "id": 4,
        "to": "+15555551234",
        "from": "42420",
        "type": "sms",
        "content": "Hi there, buy my product!",
        "direction": "outbound",
        "status": "delivered",
        "origin_type": "campaign_message",
        "origin_id": 5
    },
    "short_url": {
        "short_code": "qwerty",
        "long_url": "https://example.com/"
    },
    "conversion": {
        "id": "2WcaM2IlGetmK9O6cFbFtZafq7d",
        "type": "purchase",
        "customer_phone": "+15555551234",
        "customer_email": "dave@example.com",
        "discount_codes": ["VOXIE20"],
        "order_value": 2000,
        "order_net_value": 1800,
        "order_category": null,
        "items": [
            {
                "value": 500,
                "net_value": 450,
                "quantity": 1,
                "name": "Acme Product",
                "sku": "1253",
                "category": null,
                "vendor_id": "7cd94a687dd3ae8e",
            },
            {
                "value": 1500,
                "net_value": 1350,
                "quantity": 1,
                "name": "Great Product",
                "sku": "73945",
                "category": null,
                "vendor_id": "ac91a0a650ad4831",
            }
        ],
        "vendor_name": "shopify",
        "vendor_customer_id": "cd4ac16e8bf3c074e891a0e5",
        "vendor_order_id": "b0fd3849598d9728f20015bc",
    }
};

const payloadModal = ref(false);
const payload = computed(() => {
    const data = {
        team: samplePayload.team,
        contact: samplePayload.contact,
    };

    if (['contact.campaign.completed', 'campaign.contact.filtered'].includes(props.event)) {
        data.campaign = samplePayload.campaign;
    }

    if (['contact.group.added', 'contact.group.removed'].includes(props.event)) {
        data.tag = samplePayload.group;
    }

    if ([
        'contact.marketing_subscription_opt_in',
        'contact.marketing_subscription_opt_out',
        'contact.transactional_subscription_opt_in',
        'contact.transactional_subscription_opt_out'
    ].includes(props.event)) {
        data.subscription = samplePayload.subscription;
    }

    if (['contact.tag.added', 'contact.tag.removed'].includes(props.event)) {
        data.tag = samplePayload.tag;
    }

    if (['contact.custom_attribute.created', 'contact.custom_attribute.updated'].includes(props.event)) {
        data.custom_attribute = samplePayload.custom_attribute;
    }

    if (['conversion.created'].includes(props.event)) {
        data.conversion = samplePayload.conversion;
    }

    if (['message.created', 'message.inbound', 'message.outbound', 'message.short_url.clicked', 'message.dyn_field.extract_success', 'message.dyn_field.extract_failure'].includes(props.event)) {
        data.message = samplePayload.message;
    }

    if (['message.dyn_field.extract_success', 'message.dyn_field.extract_failure'].includes(props.event)) {
        data.dynamic_field = samplePayload.dynamic_field;
    }

    if (['message.short_url.clicked'].includes(props.event)) {
        data.short_url = samplePayload.short_url;
    }

    return JSON.stringify(data, null, 4);
});
</script>

