<template>
    <flow-resource-state-alert v-if="segment?.flow_id"
        :resourceName="segment.name"
        :success="false"
        :editing="true"
        :flowId="segment.flow_id"
        class="vx-mt-6 !vx-mb-12"
    ></flow-resource-state-alert>
    <segment-contacts
        v-if="!loading"
        :segment="segment"
        @close="goToList()"
    ></segment-contacts>
</template>

<script setup>
import { ref } from 'vue';
import SegmentContacts from '../SegmentContacts.vue';
import segmentsClient from '../../../../services/segmentsClient';
import { useToasted } from '../../../../composables/useToasted';
import FlowResourceStateAlert from '~/components/automation/flows/components/FlowResourceStateAlert.vue';

const props = defineProps({
    segmentId: {
        type: Number,
        required: false,
    },
});

const teamId = Spark.state.currentTeam.id;

const segment = ref({});
const loading = ref(false);

const toasted = useToasted();

const fetchSegment = () => {
    loading.value = true;
    segmentsClient
        .getSegment(teamId, props.segmentId)
        .then((res) => {
            segment.value = res.data;
        })
        .catch((error) => {
            console.error(error);
            toasted.global.platform_error();
        })
        .finally(() => {
            loading.value = false
        });
};

fetchSegment();

const goToList = () => {
    window.location.href = "/contacts/segments";
};
</script>
