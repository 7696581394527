export const types = [
    {
        type: 'intro',
        content: 'Intro',
    },
    {
        type: 'number_prompt',
        content: 'Number Prompt',
    },
    {
        type: 'enter_number',
        content: 'Enter Number',
    },
    {
        type: 'text_sent',
        content: 'Text Sent',
    },
    {
        type: 'is',
        content: 'Is',
    },
    {
        type: 'one',
        content: 'One',
    },
    {
        type: 'two',
        content: 'Two',
    },
    {
        type: 'three',
        content: 'Three',
    },
    {
        type: 'four',
        content: 'Four',
    },
    {
        type: 'five',
        content: 'Five',
    },
    {
        type: 'six',
        content: 'Six',
    },
    {
        type: 'seven',
        content: 'Seven',
    },
    {
        type: 'eight',
        content: 'Eight',
    },
    {
        type: 'nine',
        content: 'Nine',
    },
    {
        type: 'zero',
        content: 'Zero',
    },
];

export const findSoundByType = (sounds, type) => sounds.find((sound) => sound.type === type);

export const parseSounds = (sounds) => {
    return types.map((type, index) => {
        const sound = findSoundByType(sounds, type.type);
        const soundPaths = sound?.path?.split('/') || [];
        const filename = soundPaths[soundPaths.length - 1];

        return {
            order: index + 1,
            filename,
            ...sound,
            ...type,
        };
    });
};
