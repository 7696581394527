import { teamRolesOptions } from './roles';

export const NAMEID_EMAIL_ADDRESS = 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress';
export const NAMEID_ENCRYPTED = 'urn:oasis:names:tc:SAML:2.0:nameid-format:encrypted';
export const NAMEID_ENTITY = 'urn:oasis:names:tc:SAML:2.0:nameid-format:entity';
export const NAMEID_KERBEROS = 'urn:oasis:names:tc:SAML:2.0:nameid-format:kerberos';
export const NAMEID_PERSISTENT = 'urn:oasis:names:tc:SAML:2.0:nameid-format:persistent';
export const NAMEID_TRANSIENT = 'urn:oasis:names:tc:SAML:2.0:nameid-format:transient';
export const NAMEID_UNSPECIFIED = 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified';
export const NAMEID_WINDOWS_DOMAIN_QUALIFIED_NAME =
    'urn:oasis:names:tc:SAML:1.1:nameid-format:WindowsDomainQualifiedName';
export const NAMEID_X509_SUBJECT_NAME = 'urn:oasis:names:tc:SAML:1.1:nameid-format:X509SubjectName';

export const NAMEID_FORMATS = [
    {
        label: 'Email Address',
        value: NAMEID_EMAIL_ADDRESS,
    },
    {
        label: 'Encrypted',
        value: NAMEID_ENCRYPTED,
    },
    {
        label: 'Entity',
        value: NAMEID_ENTITY,
    },
    {
        label: 'Kerberos',
        value: NAMEID_KERBEROS,
    },
    {
        label: 'Persistent',
        value: NAMEID_PERSISTENT,
    },
    {
        label: 'Transient',
        value: NAMEID_TRANSIENT,
    },
    {
        label: 'Unspecified',
        value: NAMEID_UNSPECIFIED,
    },
    {
        label: 'Windows Domain Qualified Name',
        value: NAMEID_WINDOWS_DOMAIN_QUALIFIED_NAME,
    },
    {
        label: 'X509 Subject Name',
        value: NAMEID_X509_SUBJECT_NAME,
    },
];

export const SSO_TEAM_USER_ROLES = teamRolesOptions(['owner', 'billing', 'member']);

export const ROLE_MAPPING_ROOT_TEAM = 'root_team';
export const ROLE_MAPPING_ALL_TEAMS = 'all_teams';
export const ROLE_MAPPING_GROUP_ASSIGNMENT = 'group_assignment';

export const ROLE_MAPPING_MODES = [
    {
        label: 'Root Team',
        value: ROLE_MAPPING_ROOT_TEAM,
    },
    {
        label: 'All Teams',
        value: ROLE_MAPPING_ALL_TEAMS,
    },
    {
        label: 'Group Assignment',
        value: ROLE_MAPPING_GROUP_ASSIGNMENT,
    },
];
