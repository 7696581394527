<template>
    <div v-if="loading"
        class="vx-text-center vx-py-10">
        <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
    </div>
    <div v-else
        class="vx-space-y-8 md:vx-space-y-12 vx-container vx-mx-auto vx-px-3 vx-pb-32 md:vx-px-8">
        <page-header v-if="props.phoneNumberId"
            class="vx-mb-6 lg:vx-mb-12">
            <template v-slot:subtitle>
                <a :href="`/settings/teams/${teamId}#/phone-numbers`"
                    class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                    <font-awesome-icon class="vx-mr-1"
                        :icon="faArrowLeft"></font-awesome-icon>
                    All Phone Numbers
                </a>
            </template>

            {{ phoneNumberNational(data.phone_number) }}
        </page-header>
        <step-form-heading v-else
            :icon="faPhone">
            New Phone Number

            <template v-slot:description>
                Find a new phone number to use with Voxie
            </template>
        </step-form-heading>

        <div :class="{
            'vx-grid vx-grid-cols-12': props.phoneNumberId
        }">
            <page-sidebar v-if="props.phoneNumberId"
                appearance="nested"
                :links="[
                    { href: `/settings/teams/${teamId}/phone-numbers/${props.phoneNumberId}`, label: 'Details' },
                ]"></page-sidebar>
            <vx-sticky-nav :sections="[
                { id: 'search', label: 'Search' },
                { id: 'sending', label: 'Sending' },
                FEATURES.contact_groups ? { id: 'group', label: 'Group' } : undefined,
                { id: 'voice', label: 'Voice' },
            ].filter(section => Boolean(section))"
                class="vx-col-span-12"
                :class="{ 'lg:vx-col-span-10': props.phoneNumberId, }"
                :navClasses="`${success || props.phoneNumberId ? 'vx-hidden' : ''}`">

                <div class="vx-flex vx-flex-col vx-w-full vx-max-w-screen-sm vx-justify-center vx-gap-2 md:vx-gap-8"
                    :class="{
                        'vx-mx-auto': !props.phoneNumberId,
                    }">

                    <vx-alert v-if="success"
                        color="success"
                        :closeable="false"
                        shadow>
                        <template v-slot:icon>
                            <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                        </template>
                        <div class="vx-flex vx-items-center vx-justify-between">
                            <span><strong>Phone Number</strong> {{ props.phoneNumberId ? 'updated' : 'created' }} successfully</span>
                            <vx-button :href="`/settings/teams/${teamId}#/phone-numbers`"
                                size="sm"
                                color="success">Back to
                                Phone Numbers <font-awesome-icon
                                    :icon="faSquareArrowUpLeft"></font-awesome-icon></vx-button>
                        </div>
                    </vx-alert>

                    <vx-alert v-if="error"
                        color="danger"
                        :closeable="false"
                        shadow>{{ error }}</vx-alert>

                    <team-phone-number-form-search v-if="!props.phoneNumberId" id="search"
                        v-model="selectedNumber"></team-phone-number-form-search>

                    <team-phone-number-form-sending id="sending"
                        v-model="data.use_for_sending"></team-phone-number-form-sending>

                    <team-phone-number-form-group v-if="FEATURES.contact_groups" id="group"
                        v-model="data.group_id"></team-phone-number-form-group>

                    <team-phone-number-form-voice id="voice"
                        v-model="data"></team-phone-number-form-voice>

                    <vx-alert v-if="error"
                        color="danger"
                        :closeable="false"
                        shadow>{{ error }}</vx-alert>

                    <vx-sticky-footer>
                        <vx-button :href="`/settings/teams/${teamId}#/phone-numbers`"
                            class="vx-w-20"
                            size="lg"
                            color="muted">
                            Cancel
                        </vx-button>
                        <vx-button @click="submit()"
                            :loading="submitting"
                            data-test="submit-button"
                            class="vx-grow"
                            size="lg"
                            color="primary">
                            Save
                        </vx-button>
                    </vx-sticky-footer>
                </div>
            </vx-sticky-nav>
        </div>
    </div>
</template>

<script setup>

import {
    VxAlert,
    VxButton,
    VxIconLoading, VxStickyFooter,
    VxStickyNav,
} from '@voxie/frontend-components';
import { inject, onMounted, ref } from 'vue';
import StepFormHeading from '~/components/general/StepFormHeading.vue';
import { faArrowLeft, faCircleCheck, faPhone, faSquareArrowUpLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import TeamPhoneNumberFormSearch from './TeamPhoneNumberFormSearch.vue';
import TeamPhoneNumberFormSending from './TeamPhoneNumberFormSending.vue';
import TeamPhoneNumberFormGroup from './TeamPhoneNumberFormGroup.vue';
import TeamPhoneNumberFormVoice from './TeamPhoneNumberFormVoice.vue';
import { useToasted } from '../../../../../composables/useToasted';
import adminPhoneNumbersClient from '../../../../../services/adminPhoneNumbersClient';
import { watch } from 'vue';
import { HTTP_INTERNAL_SERVER_ERROR, HTTP_NOT_FOUND } from '~/support/HttpStatuses';
import useVuelidate from '@vuelidate/core';
import PageHeader from '~/components/general/PageHeader.vue';
import PageSidebar from '~/components/general/PageSidebar.vue';
import { phoneNumberNational } from '~/components/filters';
import phoneNumbersClient from '~/services/phoneNumbersClient';

const FEATURES = inject('FEATURES');

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    phoneNumberId: {
        type: Number,
        required: false,
    }
})

const selectedNumber = ref();

const defaultData = () => ({
    phone_number: '',
    use_for_sending: false,
    group_id: FEATURES.contact_groups ? null : undefined,
    inbound_call_handler: 'core',
    voice_message_text: '',
    forward_voice_to: '',
    forward_voice_send_digits: '',
});

const data = ref(defaultData());

watch(selectedNumber, () => {
    data.value.phone_number = selectedNumber.value?.phone_number;
}, {
    deep: true,
})

const error = ref();
const success = ref(false);
const loading = ref(false);
const submitting = ref(false);
const toasted = useToasted();
const validate = useVuelidate();

onMounted(async () => {
    if (props.phoneNumberId) {
        try {
            loading.value = true;
            const response = await phoneNumbersClient.showPhoneNumber(teamId, props.phoneNumberId);
            data.value = response.data;
        } finally {
            loading.value = false;
        }
    }
})

const submit = async () => {
    // when creating enforce phone number selection
    if (!props.phoneNumberId && (!selectedNumber.value?.phone_number || !selectedNumber.value?.iso_country)) {
        error.value = "Search a phone number and select it before saving";
        return;
    }

    const isValid = await validate.value.$validate();

    if (!isValid) {
        error.value = 'Please check form data';
        return;
    }

    error.value = '';

    if (submitting.value) {
        return;
    }

    submitting.value = true;

    try {
        let phoneNumberId = props.phoneNumberId;

        if (!phoneNumberId) {
            // purchase number when not created yet
            const purchaseResponse = await adminPhoneNumbersClient.create(
                teamId,
                { phone_number: selectedNumber.value.phone_number, country: selectedNumber.value.iso_country }
            );

            phoneNumberId = purchaseResponse.data.id;
        }

        await adminPhoneNumbersClient.update(teamId, phoneNumberId, data.value);

        success.value = true;

        validate.value.$reset();

        if (!props.phoneNumberId) {
            error.value = '';
            selectedNumber.value = {};
            data.value = defaultData();
        }
    } catch (e) {
        if (
            !e?.response?.status ||
            e?.response?.status >= HTTP_INTERNAL_SERVER_ERROR ||
            e?.response?.status === HTTP_NOT_FOUND
        ) {
            toasted?.global.platform_error();
        } else {
            error.value = e?.response?.data?.message || 'Something went wrong.';
            toasted?.error(error.value);
        }
    } finally {
        submitting.value = false;
    }
}
</script>
