<template>
    <vx-modal :padding="false"
        :title="modalTitle"
        v-model:visible="visible"
        @submit="submit"
        size="md"
        :stacked="stacked"
        :preventClosing="preventClosing">
        <template v-if="!success"
            v-slot:header>
            <div class="vx-pt-6 vx-px-6 vx-pb-4">
                {{ model.id ? 'Update' : 'New' }} Snippet
            </div>
        </template>

        <div v-if="success">
            <div class="vx-my-24 vx-text-center">
                <font-awesome-icon :icon="faCircleCheck"
                    size="xl"
                    class="vx-text-emerald-500"></font-awesome-icon>
                <h3 class="vx-mt-2 vx-text-slate-700 vx-text-xl vx-font-bold">
                    Snippet {{ model.id ? 'Updated' : 'Created' }}
                </h3>
            </div>
        </div>
        <div v-else
            class="vx-p-6">
            <div class="vx-mb-3">
                <vx-label>Title</vx-label>
                <vx-input v-model="model.title"
                    type="text"
                    size="lg"
                    :error="displayFirstError(validate.title)" />
            </div>
            <div>
                <vx-label>Message</vx-label>
                <message-builder ref="messageBuilderRef"
                    v-model="model.body"
                    v-model:mediaUrl="model.media_url"
                    v-model:mediaContentType="model.media_content_type"
                    disableSnippets
                    testable
                    :disableMedia="props.disableMedia"
                    :error="displayFirstError(validate.body)"></message-builder>
            </div>
        </div>
        <template v-if="!success"
            v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 vx-justify-between lg:vx-flex-row">

                <vx-button color="muted"
                    size="lg"
                    @click="visible = false">
                    Cancel
                </vx-button>

                <vx-button class="vx-grow"
                    :loading="submitting"
                    @click.prevent="submit()"
                    color="primary"
                    data-test="submit-button"
                    size="lg"
                    type="button">
                    {{ model.id ? 'Update' : 'Create' }}
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import { VxModal, VxLabel, VxInput, VxButton } from '@voxie/frontend-components';
import { actionsClient, snippetsClient } from '../../../services';
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';
import MessageBuilder from '~/components/general/message-builder/MessageBuilder.vue';
import { useToasted } from '~/composables/useToasted';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { computed, ref, watch } from 'vue';
import debounce from '~/utils/debounce';
import useVuelidate from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import { displayFirstError } from '~/utils/validation';

const teamId = Spark.state.currentTeam.id;

const toasted = useToasted();

const visible = defineModel('visible');
const model = defineModel();
const success = ref(false)

const submitting = ref(false)
const emit = defineEmits(['saved'])
const props = defineProps({
    disableMedia: {
        type: Boolean,
        default: false,
    },
})

const messageBuilderRef = ref();

const modalTitle = (model.value.id ? 'Update ' : 'New ') + 'Snippet';

const stacked = computed(() => {
    if (!messageBuilderRef.value) {
        return false;
    }
    return messageBuilderRef.value.modalOpen;
});

const preventClosing = ref(false);

watch([
    () => messageBuilderRef.value?.emojisPicker,
    () => messageBuilderRef.value?.actionsDropdown,
],
    debounce(() => {
        preventClosing.value = messageBuilderRef.value?.emojisPicker || messageBuilderRef.value?.actionsDropdown;
    }, 150)
);

watch(visible, (visible) => {
    if (!visible) {
        model.value = {
            id: null,
            title: '',
            body: '',
        }
        validate.value.$reset();
    }
    success.value = false;
});

const validate = useVuelidate({
    title: {
        required,
        maxLength: maxLength(120)
    },
    body: {
        required,
        maxLength: maxLength(1600)
    }
}, model)

const submit = async () => {
    const isValid = await validate.value.$validate();
    if (submitting.value || !isValid) {
        return;
    }
    submitting.value = true;

    const request = (model.value.id
        ? snippetsClient.updateSnippet(teamId, model.value.id, model.value)
        : snippetsClient.createSnippet(teamId, model.value));

    request.then(({ data }) => {
        if (!model.value.id) {
            actionsClient.send('snippet_created');
        }
        success.value = true;

        emit('saved', data);
    }).catch((e) => {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
            return;
        }

        toasted.error(e.response?.data?.message || 'Something went wrong.');
    }).finally(() => {
        submitting.value = false;
    });
}
</script>
