<template>
    <vx-expandable-card id="custom-attributes" class="vx-max-w-screen-sm">
        <template v-slot:header> Custom Attributes </template>
        <template v-slot:headerSubtitle> Metadata that can be used in other resources. </template>

        <template v-slot:content>
            <div class="vx-flex vx-flex-col vx-gap-2 vx-mb-3 lg:vx-flex-row lg:vx-mb-6">
                <div class="lg:vx-w-60">
                    <vx-label>Key</vx-label>
                    <vx-input size="lg" v-model="key" @keypress.enter="focusValue()"
                        :error="displayFirstError(validate.key)" data-test="key"></vx-input>
                </div>
                <div class="vx-flex vx-grow vx-gap-2">
                    <div class="vx-grow">
                        <vx-label>Value</vx-label>
                        <vx-input ref="valueEl" size="lg" v-model="value" @keypress.enter="add()"
                            :error="displayFirstError(validate.value)" data-test="value"></vx-input>
                    </div>
                    <div class="vx-pt-7 lg:vx-pt-6">
                        <vx-button @click="add()" data-test="custom-attribute-add" size="lg" type="button">
                            <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                        </vx-button>
                    </div>
                </div>
            </div>
            <vx-icon-loading v-if="loading" spin></vx-icon-loading>
            <vx-alert :closeable="false" v-else-if="!customAttributes.length">
                No custom attributes were added to this team.
            </vx-alert>
            <div v-else>
                <vx-list-animated v-model="customAttributes" value="key" label="key" :removeable="false" @remove="(item) => {
                        removeModal = true;
                        customAttributeKeyToDelete = item.key
                    }">
                    <template v-slot:item="{ item }">
                        <div class="vx-flex vx-flex-col vx-items-start vx-gap-2 vx-py-3 lg:vx-flex-row">
                            <div class="vx-font-bold vx-break-all lg:vx-w-60 lg:vx-font-normal">{{ item.key }}</div>
                            <div class="vx-break-all lg:vx-w-[17rem]">{{ item.value }}</div>
                        </div>
                    </template>
                </vx-list-animated>
            </div>
            <team-custom-attribute-delete v-if="customAttributeKeyToDelete" :visible="!!customAttributeKeyToDelete"
                @update:visible="($event) => {
                    if (!$event) customAttributeKeyToDelete = undefined
                }"
                :customAttributeKey="customAttributeKeyToDelete" @deleted="onDeleted"></team-custom-attribute-delete>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { nextTick, ref } from 'vue';
import teamCustomAttributesClient from '~/services/teamCustomAttributesClient';
import { useToasted } from '~/composables/useToasted';
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxAlert, VxButton, VxExpandableCard, VxIconLoading, VxInput, VxLabel, VxListAnimated } from '@voxie/frontend-components';
import useVuelidate from '@vuelidate/core';
import { maxLength, requiredIf } from '@vuelidate/validators';
import { csvInjection, teamCustomAttributeKey, teamCustomAttributeKeyFirstLetter, customAttributeValue, displayFirstError } from '~/utils/validation';
import TeamCustomAttributeDelete from './TeamCustomAttributeDelete.vue';

const teamId = Spark.state.currentTeam.id;
const toasted = useToasted();

const loading = ref(false);
const adding = ref(false);

const key = ref('');
const value = ref('');
const customAttributes = ref([]);
const customAttributeKeyToDelete = ref();

const valueEl = ref();
const focusValue = async () => {
    await nextTick();
    valueEl.value.field?.focus();
}

const validate = useVuelidate({
    key: {
        requiredIf: requiredIf(adding),
        maxLength: maxLength(40),
        format: teamCustomAttributeKey,
        teamCustomAttributeKeyFirstLetter: teamCustomAttributeKeyFirstLetter,
    },
    value: {
        requiredIf: requiredIf(adding),
        maxLength: maxLength(350),
        format: customAttributeValue,
        csvInjection: csvInjection,
    },
}, {
    key,
    value,
}, {
    $autoDirty: true,
});

const getList = async () => {
    if (loading.value) {
        return
    }

    loading.value = true;

    try {
        const response = await teamCustomAttributesClient.getAllCustomAttributes(teamId)
        customAttributes.value = response.data.data.sort((a, b) => (a.key > b.key ? 1 : -1));
    } catch (error) {
        console.error(error);
        toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
}

const add = async () => {
    adding.value = true;
    await nextTick();

    const isValid = await validate.value.$validate();

    if (!isValid) {
        setTimeout(() => {
            adding.value = false;
        }, 3500);
        return;
    }

    const attribute = {
        key: key.value.toLowerCase(),
        value: value.value,
    }

    try {
        const existingIndex = customAttributes.value.findIndex((custtomAttribute) => custtomAttribute.key === attribute.key);
        if (existingIndex !== -1) {
            await teamCustomAttributesClient.updateCustomAttribute(teamId, attribute.key, { value: attribute.value });
            customAttributes.value.splice(existingIndex, 1);
        } else {
            await teamCustomAttributesClient.createCustomAttribute(teamId, attribute);
        }

        customAttributes.value.unshift(attribute);
        key.value = '';
        value.value = '';
    } catch (error) {
        if (error?.response?.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
            return;
        }

        toasted.error(error?.response?.data?.message || 'Something went wrong.');
    } finally {
        adding.value = false;
    }
}

const onDeleted = (key) => {
    customAttributes.value = customAttributes.value.filter(item => item.key != key);
}

getList();
</script>
