<template>
    <analytics-stat-card :loading="loading"
        :error="Boolean(error)"
        :percentage="dataComputed.percentage"
        :value="dataComputed.number?.value"
        :color="dataComputed.percentage < 0 ? 'bad' : 'good'"
        :direction="dataComputed.percentage < 0 ? 'down' : 'up'">
        <template v-slot:title>
            <slot name="title">
                {{ title }}
            </slot>
        </template>
        <template v-slot:subtitle>
            <slot name="subtitle"></slot>
        </template>

        <span>{{ dataComputed.number?.label || '' }}</span>

        <template v-slot:footer>
            <vx-button href="/messaging/messagehub"
                size="xs"
                outline
                color="muted-light">
                Go to Message Hub
                <font-awesome-icon :icon="faArrowRight"></font-awesome-icon>
            </vx-button>
        </template>

        <template v-slot:explainHeader>{{ title }}</template>
        <template v-slot:explainContent>
            <p>
                <strong>{{ title }}</strong><br />
                <template v-if="props.type === null">
                    Gross Count of Messages sent by Voxie during the selected period in the context of Marketing Campaigns.
                </template>
                <template v-else>
                    Gross Count of {{ props.type.toUpperCase() }} Messages sent during the selected period.
                </template>
            </p>

            <p>
                <strong>{{ title}} %</strong><br />
                <template v-if="props.type === null">
                    Gross Percent of unique Contacts that have been messaged relative to the previous period.
                </template>
                <template v-else>
                    Gross Percent of {{ props.type.toUpperCase() }} Messages sent relative to the previous period.
                </template>
            </p>
        </template>
    </analytics-stat-card>
</template>
<script setup>
import AnalyticsStatCard from '../../partial/AnalyticsStatCard.vue';
import { VxButton, vxFormatCount } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { calculateDifference, computeComparisonRange } from '../../../../utils/analytics';
import { computed, watch } from 'vue';
import { analyticsClient } from '~/services';
import { useVersionedRequest } from '~/composables/useVersionedRequest';

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        default: null,
    }
});

const { loading, response, error, fetchData } = useVersionedRequest();

const title = computed(() => props.type !== null ? `${props.type.toUpperCase()} Messages Sent` : `Messages Sent`);
const dataComputed = computed(() => {
    const responseData = response.value?.data?.data || [];
    const values = props.type ? (responseData.find((group) => group.group_id == props.type)?.values || []) :
        responseData.reduce((sum, group) => group.values.map((value, index) => sum[index] + value), [0, 0])

    if (!values.length) {
        return {
            number: undefined,
            percentage: undefined,
        }
    }

    const number = values.length >= 2 ? values[1] : values[0];
    const percentage = values.length >= 2 ? calculateDifference(values) : undefined;

    return {
        number: {
            value: number,
            label: vxFormatCount(number),
        },
        percentage: percentage,
    }
});

watch([() => props.startDate, () => props.endDate], () => {
    const params = {
        range: computeComparisonRange({ start: props.startDate, end: props.endDate })
    }

    fetchData(analyticsClient.outboundMessagesTotalMessagesSentByType(Spark.state.currentTeam.id, params))
        .catch((e) => {
            console.error(e);
        });
}, { immediate: true });

</script>
