<template>
    <div>
        <div class="c-card u-p-small">

            <div class="row">
                <div class="col-6">
                    <div class="c-field">
                        <label class="c-field__label">Name</label>
                        <input v-model="phoneNumberPool.friendly_name" class="c-input" type="text">
                    </div>
                </div>

                <div class="col-6">
                    <div class="c-field">
                        <label class="c-field__label">Type</label>
                        <select v-model="phoneNumberPool.type" class="c-input" v-if="!phoneNumberPool.id">
                            <option v-for="type in types" :key="type.id" :value="type.id">{{ type.label }}</option>
                        </select>
                        <select disabled v-model="phoneNumberPool.type" class="c-input" v-if="phoneNumberPool.id">
                            <option v-for="type in types" :key="type.id" :value="type.id">{{ type.label }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row u-mt-medium u-mb-small">
                <div class="col-12">
                    <hr />
                </div>
            </div>


            <div class="row ">
                <div class="col-12">
                    <a v-if="phoneNumberPool.id" href="#" class="pull-right u-ml-small c-btn c-btn--success" @click.prevent="update">
                        Update Phone Pool
                        <i v-if="formIsSubmitting" class="fa fa-spin fa-circle-o-notch"></i>
                    </a>

                    <a v-if="!phoneNumberPool.id" href="#" class="pull-right u-ml-small c-btn c-btn--info" @click.prevent="create">
                        Create Phone Pool
                        <i v-if="formIsSubmitting" class="fa fa-spin fa-circle-o-notch"></i>
                    </a>

                    <a href="#" class="c-btn c-btn--secondary pull-right" @click="close">Cancel</a>
                </div>
            </div>

        </div><!-- eo c-card -->


    </div>
</template>

<script>
export default {
    props: ['phoneNumberPool'],

    data() {
        return {
            formIsSubmitting: false,
            types: [
                { id: 'long_code', label: 'Long Code' },
                { id: 'short_code', label: 'Short Code' },
            ],
        };
    },

    created(){
        Bus.$on('phone_number_poolsResourceBusy', (busy) => {
            this.formIsSubmitting = busy;
        });
    },

    beforeUnmount() {
        Bus.$off('phone_number_poolsResourceBusy');
    },

    mounted() {
        if (!this.phoneNumberPool.type) {
            this.phoneNumberPool.type = 'long_code';
        }
    },

    methods: {
        close() {
            if (this.phoneNumberPool.id) {
                Bus.$emit('phone_number_poolsCancelEdit', this.phoneNumberPool.id);
            } else {
                Bus.$emit('phone_number_poolsCancelNew');
            }
        },

        update() {
            if (!this.validate()) return;
            this.formIsSubmitting = true;
            Bus.$emit('phone_number_poolsUpdate', this.phoneNumberPool.id, { friendly_name: this.phoneNumberPool.friendly_name });
        },

        create() {
            if (!this.validate()) return;
            this.formIsSubmitting = true;
            Bus.$emit('phone_number_poolsCreate', {
                'friendly_name': this.phoneNumberPool.friendly_name,
                'type': this.phoneNumberPool.type,
            });
        },

        validate() {
            return true;
        }
    }
}
</script>
