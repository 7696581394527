import BaseClient from './BaseClient';

class ShopifyClient extends BaseClient {
    getStore(teamId) {
        const url = `/api/v3/teams/${teamId}/shopify`;
        return this.makeCoreApiRequest(url);
    }

    createStore(teamId, data) {
        const url = `/api/v3/teams/${teamId}/shopify`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    updateStore(teamId, storeId, data) {
        const url = `/api/v3/teams/${teamId}/shopify/${storeId}`;
        return this.makeCoreApiRequest(url, 'put', data);
    }

    deleteStore(teamId, storeId) {
        const url = `/api/v3/teams/${teamId}/shopify/${storeId}`;
        return this.makeCoreApiRequest(url, 'delete');
    }

    healthCheck(teamId, storeId) {
        const url = `/api/v3/teams/${teamId}/shopify/${storeId}/health-check`;
        return this.makeCoreApiRequest(url, 'get');
    }
}

export default new ShopifyClient();
