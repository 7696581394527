<template>
    <vx-table-row>
        <vx-table-row-item>
            <template v-slot:label> Key: </template>
            <div class="vx-break-all">
                {{ props.modelValue.key }}
            </div>
        </vx-table-row-item>

        <vx-table-row-item class="vx-leading-tight xl:!vx-col-span-3">
            <template v-slot:label> Value: </template>
            <div class="vx-font-bold vx-text-slate-700 vx-break-all xl:vx-py-2">
                {{ props.modelValue.value }}
            </div>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Added at: </template>

            <vx-popover hover
                        :arrow="true"
                        placement="top"
                        :key="dateTimeKey">
                {{ fromNow(props.modelValue.created_at) }}
                <template v-slot:content>
                    {{ shortDateTimeTzSpread(props.modelValue.created_at) }}
                </template>
            </vx-popover>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Updated at: </template>

            <vx-popover hover
                        :arrow="true"
                        placement="top"
                        :key="dateTimeKey">
                {{ fromNow(props.modelValue.updated_at) }}
                <template v-slot:content>
                    {{ shortDateTimeTzSpread(props.modelValue.updated_at) }}
                </template>
            </vx-popover>
        </vx-table-row-item>

    </vx-table-row>
</template>

<script setup>
import {
    VxPopover,
    VxTableRow,
    VxTableRowItem,
} from "@voxie/frontend-components";
import { fromNow, shortDateTimeTzSpread } from '~/utils/date';
import { useInterval } from '@vueuse/core';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

const dateTimeKey = useInterval(60000);
</script>
