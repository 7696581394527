<template>
    <page-sidebar class="md:vx-col-span-4" :links="links" :bold="false"></page-sidebar>
</template>


<script setup>
import { computed, inject } from 'vue';
import { faRightToBracket, faCircleUser, faStore, faUsers, faPuzzlePiece, faCode, faPieChart, faPeopleGroup, faCircleCheck, faLockKeyhole, faKeySkeletonLeftRight, faClone, faRefresh, faCreditCard, faShoppingCart, faRectangleHistoryCircleUser, faLightbulbOn, faFingerprint, faBookAtlas, faContactBook, faMemoCircleInfo, faPhone, faBadgePercent, faPhoneOffice, faGrid2Plus } from '@fortawesome/pro-solid-svg-icons';
import PageSidebar from '~/components/general/PageSidebar.vue'

const createsAdditionalTeams = Spark.createsAdditionalTeams;
const usesApi = Spark.usesApi;
const isTeamOwner = Spark.ownsTeam;
const isAdminOrOwner = Spark.isAdminOrOwner;
const isImpersonatorOrDev = Spark.isImpersonating || Spark.isDeveloper
const isServiceRepresentative = Spark.isServiceRepresentative;

const user = Spark.state.user;

const teamId = Spark.state.currentTeam.id;
const isBrand = Spark.state.currentTeam.organization_type === 'brand';
const organizationId = Spark.state.currentTeam.organization_id;

const FEATURES = inject('FEATURES');

const links = computed(() => {
    const yourSettings = {
        label: 'Settings',
        links: [
            {
                href: `/settings#/profile`,
                label: 'Profile',
                icon: faCircleUser
            },
            {
                href: `/settings#/teams`,
                label: 'Teams',
                icon: faUsers
            }
        ],
    };

    if (!user?.isUsingSingleSignOn) {
        yourSettings.links.push({
            href: `/settings#/security`,
            label: 'Security',
            icon: faLockKeyhole
        });
    }

    if (usesApi && !user?.isUsingSingleSignOn) {
        yourSettings.links.push({
            href: `/settings#/api`,
            label: 'API',
            icon: faKeySkeletonLeftRight,
        });
    }

    if (isImpersonatorOrDev) {
        yourSettings.links.push({
            href: `/settings#/cloning`,
            label: 'Cloning',
            icon: faClone,
        });
    }


    const teamSettings = {
        label: 'Team Settings',
        links: [
            {
                href: `/settings/teams/${teamId}#/owner`,
                label: 'Team Profile',
                icon: faCircleUser
            },
        ],
    };

    if (isTeamOwner && organizationId) {
        if (isBrand) {
            teamSettings.links.push({
                href: `/settings/teams/${teamId}#/organizations-invites`,
                label: 'Subaccounts',
                icon: faStore
            });
        }

        if (FEATURES.single_sign_on) {
            teamSettings.links.push({
                href: `/settings/teams/${teamId}#/organizations-sso-settings`,
                label: 'Account SSO',
                icon: faRightToBracket
            });
        }
    }

    if (!user?.isUsingSingleSignOn) {
        teamSettings.links.push({
            href: `/settings/teams/${teamId}#/membership`,
            label: isAdminOrOwner ? 'Users' : 'Membership',
            icon: faUsers,
        });
    }

    if (!isServiceRepresentative) {
        teamSettings.links.push({
            href: `/settings/teams/${teamId}#/phone-numbers`,
            label: 'Phone Numbers',
            icon: faPhone,
        })
        teamSettings.links.push({
            href: `/message_sources/phone_number_pools`,
            label: 'Phone Pools',
            icon: faPhoneOffice,
        })
    }

    if (isAdminOrOwner) {
        teamSettings.links.push({
            href: `/settings/teams/${teamId}#/customattributes`,
            label: 'Custom Attributes',
            icon: faPuzzlePiece,
        });
    }

    if (FEATURES.webhooks_ui) {
        teamSettings.links.push({
            href: `/settings/teams/${teamId}#/webhooks`,
            label: 'Webhooks',
            icon: faCode,
        });
    }

    if (isImpersonatorOrDev) {
        teamSettings.links.push({
            href: `/settings/teams/${teamId}#/feature`,
            label: 'Features',
            icon: faCircleCheck,
        });
    }

    if (FEATURES.attribution_ui) {
        teamSettings.links.push({
            href: `/settings/teams/${teamId}#/analytics`,
            label: 'Analytics',
            icon: faPieChart,
        });
    }

    teamSettings.links.push({
        href: `/settings/teams/${teamId}#/contacts-exports`,
        label: 'Contacts Exports',
        icon: faContactBook,
    });

    if (createsAdditionalTeams) {
        teamSettings.links.push({
            href: `/settings#/teams`,
            label: 'View All Teams',
            icon: faPeopleGroup,
        });
    }

    if (FEATURES.contact_groups) {
        teamSettings.links.push({
            href: `/settings/teams/${teamId}#/groups`,
            label: 'Groups',
            icon: faGrid2Plus,
        })
    }

    const allLinks = [
        yourSettings,
        teamSettings,
    ];

    if (isAdminOrOwner) {
        const integrations = {
            label: 'Integrations',
            links: [
                {
                    href: '/settings/integrations#/shopify',
                    label: 'Shopify',
                    icon: faShoppingCart,
                },
                {
                    href: '/settings/integrations#/contact-collector',
                    label: 'Contact Collector',
                    icon: faRectangleHistoryCircleUser,
                },
            ]
        };

        if (FEATURES.promotions && !isServiceRepresentative) {
            integrations.links.push({
                href: '/message_sources/promotions',
                label: 'Promotions',
                icon: faBadgePercent,
            });
        }

        if (FEATURES.salesforce_integration) {
            integrations.links.push({
                href: '/settings/integrations#/salesforce',
                label: 'Salesforce',
                icon: faShoppingCart,
            });
        }

        if (FEATURES.message_hub_ai || FEATURES.quick_blast_ai) {
            integrations.links.push({
                href: '/settings/integrations#/ai-integration',
                label: 'AI Configuration',
                icon: faLightbulbOn,
            });
        }

        if (FEATURES.api_destinations) {
            integrations.links.push({
                href: '/settings/integrations#/auth-configs',
                label: 'API Auth',
                icon: faFingerprint,
            });

            integrations.links.push({
                href: '/settings/integrations#/api-destinations',
                label: 'API Destinations',
                icon: faBookAtlas,
            });
        }

        if (FEATURES.shift_to_text) {
            integrations.links.push({
                href: `/settings/teams/${teamId}#/shift-to-text`,
                label: 'Shift to Text',
                icon: faPuzzlePiece,
            });
        }

        allLinks.push(integrations);

        const billing = {
            label: 'Billing',
            links: [

                {
                    href: `/settings/teams/${teamId}#/payment-method`,
                    label: 'Payment Method',
                    icon: faCreditCard,
                },
                {
                    href: `/settings/teams/${teamId}#/invoices`,
                    label: 'Invoices',
                    icon: faShoppingCart,
                }
            ]
        }

        if (isImpersonatorOrDev) {
            billing.links.unshift({
                href: `/settings/teams/${teamId}#/subscription`,
                label: 'Subscription',
                icon: faRefresh,
            });
        }

        billing.links.unshift({
            href: `/settings/teams/${teamId}#/plan-information`,
            label: 'Plan Information',
            icon: faMemoCircleInfo,
        })

        allLinks.push(billing);
    }

    return allLinks;
})

</script>
