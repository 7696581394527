<template>
    <div class="d-flex flex-column flex-grow-1 overflow-auto">
        <section class="d-flex flex-column">
            <search-input
                v-model.trim="searchText"
                placeholder="Search Snippets"
                maxlength="120"
                @keyup.down="$refs.snippetList.focus()"
                @update:modelValue="searchSnippets"
            />
        </section>
        <span class="line" />
        <section class="list">
            <div v-if="isLoading" class="text-center flex-grow-1 pt-2">
                <loading-resource />
            </div>
            <div
                v-else-if="!snippets.length"
                class="d-flex align-items-center justify-content-center border-top w-100 h-100"
            >
                <p>No results found :(</p>
            </div>
            <ul
                v-else
                ref="snippetList"
                class="snippets-list"
                tabindex="0"
                role="listbox"
                :aria-activedescendant="`snippet_${selectedSnippet.id}`"
                @focus="onFocus"
                @keydown.up.down="onKeydown"
                @keydown.enter="onEnter"
            >
                <li
                    v-for="snippet in snippets"
                    :id="`snippet_${snippet.id}`"
                    :key="snippet.id"
                    role="option"
                    :class="{ 'bg-gray-100': selectedSnippet.id === snippet.id }"
                    @click="onClick(snippet)"
                    @mousemove="selectedSnippet = snippet"
                    @mouseleave="selectedSnippet = snippet"
                >
                    <span class="title">{{ snippet.title }}</span>
                    <span>{{ snippet.body }}</span>
                </li>
            </ul>
        </section>
        <footer v-if="allowCreate">
            <span class="line"></span>
            <div class="action-buttons-wrapper">
                <button class="action-button flex-grow-1" @click.prevent="snippetModalShow = true">
                    <span class="icon"
                        ><svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.33473 0.982422H3.66509C3.60557 0.982422 3.57581 1.01218 3.57581 1.07171V4.57617H0.250174C0.190651 4.57617 0.160889 4.60593 0.160889 4.66546V5.3351C0.160889 5.39463 0.190651 5.42439 0.250174 5.42439H3.57581V8.92885C3.57581 8.98837 3.60557 9.01814 3.66509 9.01814H4.33473C4.39426 9.01814 4.42402 8.98837 4.42402 8.92885V5.42439H7.75017C7.8097 5.42439 7.83946 5.39463 7.83946 5.3351V4.66546C7.83946 4.60593 7.8097 4.57617 7.75017 4.57617H4.42402V1.07171C4.42402 1.01218 4.39426 0.982422 4.33473 0.982422Z"
                                fill="#139EDE"
                            ></path>
                        </svg>
                    </span>
                    <span class="text"> Snippet </span>
                </button>
            </div>
        </footer>
        <modal-snippet v-model:visible="snippetModalShow" v-model="newSnippet" @saved="newSnippetCreated" :disableMedia="disableMedia"/>
    </div>
</template>

<script>
import debounce from '~/utils/debounce';
import SearchInput from '../../../general/SearchInput.vue';
import LoadingResource from '../../../general/LoadingResource.vue';
import { snippetsClient } from '../../../../services';
import ModalSnippet from '../../../snippets/components/ModalSnippet.vue';

export default {
    components: { ModalSnippet, SearchInput, LoadingResource },
    emits: ['select'],
    props: {
        allowCreate: {
            type: Boolean,
            default: true,
        },
        disableMedia: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            snippetModalShow: false,
            isLoading: true,
            searchText: '',
            snippets: [],
            selectedSnippet: {},
            newSnippet: {
                id: null,
                title: '',
                body: '',
                media_url: '',
                media_content_type: '',
            },
        };
    },
    mounted() {
        this.fetchSnippets();
    },
    methods: {
        newSnippetCreated(snippet) {
            this.snippets.push({ ...snippet });
            this.$nextTick(() => {
                const { snippetList } = this.$refs;
                if (snippetList?.scrollTo) {
                    snippetList.scrollTo({
                        top: snippetList.scrollHeight,
                        behavior: 'smooth',
                    });
                }
            });
        },

        fetchSnippets(search) {
            this.isLoading = true;
            const teamId = Spark.state.currentTeam.id;
            snippetsClient
                .getSnippets(teamId, {
                    per_page: 15,
                    query: search,
                    sort_field: 'title',
                    sort_dir: 'asc',
                })
                .then((r) => {
                    this.snippets = r.data.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        searchSnippets: debounce(function () {
            this.fetchSnippets(this.searchText || undefined);
        }, 500),

        onClick(snippet) {
            this.$emit('select', {
                value: snippet.body,
                media_url: snippet.media_url,
                media_content_type: snippet.media_content_type,
            });
        },

        onFocus() {
            this.selectedSnippet = this.snippets[0];
        },

        onKeydown(event) {
            if (this.selectedSnippet.id === undefined) {
                this.selectedSnippet = this.snippets[0] || {};
                return;
            }

            let index = this.snippets.findIndex((t) => t.id === this.selectedSnippet.id);

            if (event.key === 'ArrowUp' && typeof this.snippets[index - 1] !== 'undefined') {
                this.selectedSnippet = this.snippets[index - 1];
            }

            if (event.key === 'ArrowDown' && typeof this.snippets[index + 1] !== 'undefined') {
                this.selectedSnippet = this.snippets[index + 1];
            }
        },

        onEnter() {
            if (this.selectedSnippet.id !== undefined) {
                this.$emit('select', {
                    value: this.selectedSnippet.body,
                    media_url: this.selectedSnippet.media_url,
                    media_content_type: this.selectedSnippet.media_content_type,
                });
            }
        },
    },
};
</script>
