<template>
    <page-container>
        <page-header v-if="props.apiDestinationId" class="vx-mb-6 lg:vx-mb-12">
            <template v-slot:subtitle>
                <a href="/settings/integrations#/api-destinations"
                    class="!vx-no-underline !vx-text-sky-600 vx-text-base vx-font-normal">
                    <font-awesome-icon class="vx-mr-1" :icon="faArrowLeft"></font-awesome-icon>
                    All API Destinations
                </a>
            </template>

            Test
        </page-header>
        <div class="vx-grid vx-gap-6 vx-grid-cols-1 lg:vx-grid-cols-12">
            <api-destination-sidebar :apiDestinationId="props.apiDestinationId"
                :sectionLinks="sideBarLinks"></api-destination-sidebar>
            <div class="vx-col-span-12 lg:vx-col-span-10">
                <h2 class="vx-text-slate-800 vx-text-3xl vx-font-extrabold vx-mb-4">
                    API Delivery
                </h2>

                <div class="vx-flex vx-flex-col vx-gap-14">
                    <div class="vx-grid vx-gap-6 vx-grid-cols-1 lg:vx-grid-cols-12">
                        <api-delivery-id-card class="lg:vx-col-span-6" :delivery-id="props.apiDeliveryId" />

                        <api-delivery-request-metadata-card class="lg:vx-col-span-6" :request="request" />

                        <api-delivery-request-payload-card class="lg:vx-col-span-12" :request="request" />

                        <api-delivery-automation-card class="lg:vx-col-span-6" :automation-rule="automationRule" />

                        <api-delivery-auth-config-card class="lg:vx-col-span-6" :auth-config="authConfig"
                            :delivery="delivery" />
                    </div>
                    <api-delivery-attempt-list v-if="delivery" :delivery="delivery" />
                </div>
            </div>
        </div>
    </page-container>
</template>
<script setup>
import ApiDeliveryAutomationCard from './cards/ApiDeliveryAutomationCard.vue';
import ApiDeliveryAuthConfigCard from './cards/ApiDeliveryAuthConfigCard.vue';
import ApiDeliveryIdCard from './cards/ApiDeliveryIdCard.vue';
import ApiDeliveryRequestPayloadCard from './cards/ApiDeliveryRequestPayloadCard.vue';
import ApiDeliveryRequestMetadataCard from './cards/ApiDeliveryRequestMetadataCard.vue';
import ApiDeliveryAttemptList from './attempts/ApiDeliveryAttemptList.vue';

import PageHeader from '../../../../../general/PageHeader.vue';
import PageContainer from '../../../../../general/PageContainer.vue';
import ApiDestinationSidebar from "../../ApiDestinationSidebar.vue";
import { useApiDeliveryDetails } from './useApiDeliveryDetails'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

const props = defineProps({
    apiDestinationId: {
        type: String,
        required: true,
    },
    apiDeliveryId: {
        type: String,
        required: true,
    },
});

const { delivery, request, automationRule, authConfig } = useApiDeliveryDetails('api_destination', props.apiDestinationId, props.apiDeliveryId);

const sideBarLinks = [
    {
        href: `/settings/integrations/api-destinations/${props.apiDestinationId}/deliveries/${props.apiDeliveryId}`,
        label: props.apiDeliveryId.slice(-6),
        icon: null,
    },
]

</script>
