<template>
    <div>
        <vx-collapsible v-show="showScheduledMessagesBox"
            :toggle-msg="showScheduledMessages ? 'Hide Scheduled Messages' : 'Show Scheduled Messages'" :height="210"
            @toggle="showScheduledMessages = $event">
            <div class="vx-flex vx-flex-col vx-gap-1 vx-px-1">
                <div class="vx-flex vx-flex-col-reverse vx-flex-1 vx-overflow-y-auto vx-py-2">
                    <div v-for="(messageGroup, key) in messageGroups" :key="key" class="t-chat-messages-group">
                        <span class="vx-flex vx-items-center vx-justify-center vx-text-slate-600 vx-text-xs vx-my-4">{{
                messageGroup.prettyDate }}</span>
                        <template v-for="message in messageGroup.messages">
                            <vx-message-cloud v-if="message.body || message.media_url" :key="message.id"
                                :messageId="message.id" :mediaUrl="message.media_url"
                                :mediaContentType="message.media_content_type" :messageBody="message.body"
                                :isInbound="message.direction === 'inbound'"
                                @mediaPlaceholderClick="($event) => mediaExpiredModal = !!$event.isFileExpired"
                                :is-scheduled="true" @edit="openEditModal(message)">
                                <template v-slot:info>
                                    {{ message.user ? `User - ${message.user?.name} -` : '' }} scheduled for
                                    {{ formatMessageTime(message.send_at) }}
                                </template>
                            </vx-message-cloud>
                        </template>
                    </div>
                </div>
                <vx-button v-if="fetchParams.cursor" @click="loadMessages()" color="muted-dark" size="sm"
                    class="vx-grow sm:vx-grow-0">
                    Load More
                    <font-awesome-icon :icon="faSpinner" :spin="loading"></font-awesome-icon>
                </vx-button>
            </div>
        </vx-collapsible>
        <scheduled-message-modal v-model:visible="showScheduledModal" @scheduledMessageEdited="editScheduledMessage"
            :scheduled-message="selectedScheduledMessage" class="!vx-items-baseline"></scheduled-message-modal>
    </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import { VxCollapsible } from '@voxie/frontend-components-v3';
import scheduledMessagesClient from '~/services/scheduledMessagesClient';
import dayjs from '~/utils/dayjs';
import { isAuthErrorEcho } from '~/utils/helpers';
import { useToasted } from '~/composables/useToasted';
import { VxMessageCloud } from '@voxie/frontend-components-v3';
import { formatMessageTime } from '~/components/filters';
import { VxButton } from '@voxie/frontend-components';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ScheduledMessages from '~/utils/scheduledMessages';
import ScheduledMessageModal from '~/components/scheduled-messages/modals/ScheduledMessageModal.vue';

const props = defineProps({
    contact: {
        type: Object,
        required: true,
    }
});

const $toasted = useToasted();
const teamId = Spark.state.currentTeam.id;
const defaultFilters = () => {
    return {
        cursor: undefined,
        sort_dir: 'desc',
    }
};

const messages = ref([]);
const messageGroups = computed(() => {
    const messagesToDay = ([day, group]) => {
        return {
            fullDate: day,
            prettyDate: dayjs(day).format('MMMM D, YYYY'),
            messages: group.sort((mA, mB) => mA.id - mB.id)
        };
    };

    const groups = messages.value.reduce((groups, message) => {
        const groupKey = dayjs(message.created_at).startOf('day').toString();
        if (!groups[groupKey]) {
            groups[groupKey] = [];
        }

        groups[groupKey].push(message);

        return groups;
    }, {});

    return Object.entries(groups).map(messagesToDay).sort((gA, gB) => {
        return new Date(gB.fullDate).getTime() - new Date(gA.fullDate).getTime();
    });
});

const showScheduledMessagesBox = computed(() => messages.value.length);
const showScheduledMessages = ref(false);

const fetchParams = ref(defaultFilters());

const loading = ref(false);
const loadMessages = async () => {

    if (loading.value) {
        return;
    }

    loading.value = true;

    try {
        const response = await scheduledMessagesClient.getContactScheduledMessages(teamId, props.contact.id, {
            ...fetchParams.value,
        });

        messages.value =
            !fetchParams.value.cursor
                ? response.data.data
                : [...messages.value, ...response.data.data];

        // set next cursor for subsequent request
        const nextPageParams = new URLSearchParams(response.data.next_page_url?.split("?")?.[1]);

        fetchParams.value = {
            ...fetchParams.value,
            cursor: nextPageParams.get("cursor")
        }
    } catch (e) {
        console.log(e);
        $toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
};

const showScheduledModal = ref(false);
const selectedScheduledMessage = ref(null);
const openEditModal = (scheduledMessage) => {
    selectedScheduledMessage.value = {
        ...scheduledMessage,
        contact: {
            first_name: props.contact.first_name || '',
            last_name: props.contact.last_name || '',
            phone: props.contact.phone,
        }
    };
    showScheduledModal.value = true;
}

const editScheduledMessage = async (data) => {
    await ScheduledMessages.editScheduledMessage(data, $toasted);

    // Remove old message since it will come in ScheduledMessageCreated listener.
    messages.value = messages.value.filter((m) => m.id !== data.id);
    selectedScheduledMessage.value = null;
};

loadMessages();

const echoErrors = [];
Echo.private(`scheduled_messages.${teamId}`)
    .listen('ScheduledMessageCreated', ({ contact, scheduled_message }) => {
        if (contact.id === props.contact.id) {
            messages.value.push(scheduled_message);
        }
    })
    .listen('ScheduledMessageEnqueued', ({ contact, scheduled_message }) => {
        if (contact.id === props.contact.id) {
            messages.value = messages.value.filter((m) => m.id !== scheduled_message.id);
        }
    })
    .error?.((e) => {
        if (echoErrors.length <= 3 && !isAuthErrorEcho(e)) {
            window?.Bugsnag?.notify(e instanceof Error ? e : new Error(JSON.stringify(e)));
            echoErrors.push(e);
        }
    });
</script>
