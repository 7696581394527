<template>
    <vx-modal :visible="props.visible" @update:visible="updateVisible" :padding="false" size="sm">
        <template v-if="!success" v-slot:header>
            <div class="vx-px-12 vx-pt-12">
                Test Phone Number
                <small
                    class="vx-block vx-text-sm vx-text-slate-500 vx-font-sans"
                >
                    Enter a single phone number to receive this message.
                </small>
            </div>
        </template>

        <div class="vx-font-sans vx-pt-4 vx-px-12 vx-pb-12">
            <template v-if="!success">
                <vx-input
                    v-model="phoneNumber"
                    size="lg"
                    @keyup.enter="sendMessage"
                    :error="error"
                    @input="error = ''"
                ></vx-input>

                <vx-button
                    :loading="sending"
                    :disabled="!phoneNumber"
                    block
                    type="button"
                    size="lg"
                    color="primary"
                    class="vx-mt-4 lg:vx-mt-6"
                    @click="sendMessage"
                    data-test="test-send"
                >
                    Send Test
                    <font-awesome-icon :icon="faFlaskVial"></font-awesome-icon>
                </vx-button>
            </template>
            <template v-else>
                <div class="vx-my-14 vx-text-center">
                    <font-awesome-icon
                        :icon="faCircleCheck"
                        size="xl"
                        class="vx-text-emerald-500"
                    ></font-awesome-icon>
                    <h3 class="vx-mt-2 vx-text-slate-700 vx-text-xl vx-font-bold">
                        Test Sent
                    </h3>
                </div>
                <vx-button
                    block
                    type="button"
                    size="lg"
                    color="muted"
                    @click="updateVisible(false)"
                >
                    Close
                </vx-button>
            </template>
        </div>
    </vx-modal>
</template>

<script setup>
import { ref } from 'vue';
import { VxModal, VxButton, VxInput } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFlaskVial, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import contactsClient from '~/services/contactsClient';
import messagesClient from '~/services/messagesClient';
import actionsClient from '~/services/actionsClient';
import libphonenumber from 'google-libphonenumber';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const PNF = libphonenumber.PhoneNumberFormat;

const props = defineProps({
    visible: {
        type: Boolean,
        required: true,
    },
    mediaUrl: {
        type: String,
        required: false,
    },
    mediaContentType: {
        type: String,
        required: false,
    },
    bodyMessage: {
        type: String,
        required: true,
    },
});

const emit = defineEmits(["update:visible"]);

const teamId = Spark.state.currentTeam.id;
const sending = ref(false);
const phoneNumber = ref("");
const success = ref(false);
const error = ref("");

const updateVisible = (visible) => {
    emit("update:visible", visible);

    if (!visible) {
        success.value = false;
        phoneNumber.value = "";
        error.value = "";
    }
};

const sendMessage = async () => {
    const formattedPhoneNumber = e164Format(phoneNumber.value);

    if (
        !formattedPhoneNumber?.trim()?.length ||
        !isValidPhoneNumber(formattedPhoneNumber)
    ) {
        error.value = `Not a valid phone number.`;
        return;
    }

    if (sending.value) {
        return;
    }

    try {
        sending.value = true;
        const contact = await lookupContact(formattedPhoneNumber);

        const data = {
            contact_id: contact.id,
            body: props.bodyMessage,
        };

        if (props.mediaUrl && props.mediaContentType) {
            data.media_url = props.mediaUrl;
            data.media_content_type = props.mediaContentType;
        }

        // sends message
        await messagesClient.createV3(teamId, data);

        success.value = true;
    } catch (e) {
        console.error(e);
        error.value = "Could not send a test message at this moment.";
    } finally {
        sending.value = false;
    }
};

const isValidPhoneNumber = (phoneNumber) => {
    try {
        return Boolean(
            phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber, "US"))
        );
    } catch (err) {
        return false;
    }
};

const e164Format = (phoneNumber) => {
    try {
        return phoneUtil.format(phoneUtil.parse(phoneNumber, "US"), PNF.E164);
    } catch {
        return null;
    }
};

const lookupContact = async (phoneNumber) => {
    try {
        return await fetchContact(phoneNumber);
    } catch (e) {
        return await createContact(phoneNumber);
    }
};

const fetchContact = async (phoneNumber) => {
    try {
        const response = await contactsClient.searchContacts(teamId, {
            "filter[phone]": phoneNumber,
        });

        if (
            response.data?.data?.length === 1 &&
            response.data.data[0].phone === phoneNumber
        ) {
            return response.data.data[0];
        }
    } catch (e) {
        console.error(e);
    }

    throw new Error(
        `It was not possible to fetch a contact with the phone number ${phoneNumber}.`
    );
};

const createContact = async (phoneNumber) => {
    try {
        const response = await contactsClient.contactSave(teamId, {
            phone: phoneNumber,
        });
        actionsClient.send("contact_added");
        return response.data;
    } catch (e) {
        console.error(e);
        throw new Error(
            `It was not possible to create a new contact with the phone number ${phoneNumber}.`
        );
    }
};
</script>
