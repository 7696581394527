<template>
    <div v-if="flowId && !flow"
        class="vx-text-center vx-py-10">
        <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
    </div>
    <playbook-form v-else-if="isPlaybook"
        :flow="flow"></playbook-form>
    <custom-flow-form v-else
        :flow="flow"></custom-flow-form>
</template>

<script setup>
import PlaybookForm from './playbook/forms/PlaybookForm.vue';
import CustomFlowForm from './custom-flow/CustomFlowForm.vue';
import { computed, ref } from 'vue';
import flowsClient from '~/services/flowsClient';
import { VxIconLoading } from '@voxie/frontend-components';

const props = defineProps({
    flowId: {
        type: [String, undefined],
        default: undefined,
    },
});

const flow = ref(null);

const params = new URLSearchParams(window.location.search);
const isPlaybook = computed(() => Boolean(params.get('playbook') || flow.value?.playbook));

if (props.flowId) {
    flowsClient.get(Spark.state.currentTeam.id, props.flowId)
        .then(r => flow.value = r.data);
}
</script>
