import BaseClient from './BaseClient';

class ThreadsClient extends BaseClient {
    threadsGetAll($teamId, params, config = {}) {
        return this.makeCoreApiRequest(`${this.apiBase}/teams/${$teamId}/threads`, 'get', null, params, config);
    }

    threadsListUsingLink(link) {
        return this.makeCoreApiRequest(link);
    }

    threadsState($teamId, $contactId, $state) {
        let method = 'post';
        let url = `${this.apiBase}/teams/${$teamId}/contacts/${$contactId}/thread/${
            $state === 'open' ? 'open' : 'close'
        }`;

        return this.makeCoreApiRequest(url, method);
    }

    threadsGetSummary($teamId, config = {}) {
        let method = 'get';
        let url = `${this.apiBase}/teams/${$teamId}/threads/summary`;

        return this.makeCoreApiRequest(url, method, null, undefined, config);
    }

    threadsAssignToSelf($teamId, $contactId) {
        let method = 'post';
        let url = `${this.apiBase}/teams/${$teamId}/contacts/${$contactId}/thread/claim`;

        return this.makeCoreApiRequest(url, method);
    }

    threadsAssignTo($teamId, $contactId, $claim) {
        let method = 'post';
        let url = `${this.apiBase}/teams/${$teamId}/contacts/${$contactId}/thread/assign`;

        return this.makeCoreApiRequest(url, method, $claim);
    }

    threadsUnclaim($teamId, $contactId) {
        let method = 'post';
        let url = `${this.apiBase}/teams/${$teamId}/contacts/${$contactId}/thread/unclaim`;

        return this.makeCoreApiRequest(url, method);
    }

    threadsCheckpointSave($teamId, $contactId) {
        let method = 'post';
        let url = `${this.apiBase}/teams/${$teamId}/contacts/${$contactId}/thread/checkpoint`;

        return this.makeCoreApiRequest(url, method);
    }

    threadsCheckpointDelete($teamId, $contactId, userId) {
        let method = 'delete';
        let url = `${this.apiBase}/teams/${$teamId}/contacts/${$contactId}/thread/checkpoint`;

        return this.makeCoreApiRequest(url, method, {
            user_id: userId,
        });
    }

    threadsCheckpointGet($teamId, $contactId) {
        let method = 'get';
        let url = `${this.apiBase}/teams/${$teamId}/contacts/${$contactId}/thread/checkpoint`;

        return this.makeCoreApiRequest(url, method);
    }
}

export default new ThreadsClient({
    apiBase: window.Spark.apiBase,
});
