<template>
    <div v-if="props.resourceType === 'automation'"
        class="vx-flex vx-gap-2 vx-items-center">
        <div>
            <font-awesome-icon :icon="publishedAutomatedActions(props.resource) ? faToggleLargeOff : faToggleLargeOn" />
        </div>
        <div class="vx-text-slate-800 vx-text-sm vx-leading-tight">
            {{ publishedAutomatedActions(props.resource) ? 'Active' : 'Inactive' }}
            <vx-badge :borders="false"
                :color="props.color ? 'amber' : 'slate'"
                size="xs"
                class="!vx-font-extrabold">
                {{ `${publishedAutomatedActions(props.resource)}/${props.resource.automated_actions?.length || props.resource.actions_count || 0}` }}
            </vx-badge>
        </div>
    </div>
    <div v-else-if="props.resourceType === 'campaign'"
        class="vx-flex vx-gap-2 vx-items-center">
        <div>
            <font-awesome-icon :class="props.color ? props.iconColor : ''"
                :icon="['draft', 'paused', 'archived'].includes(props.resource.status) ? faToggleLargeOff : faToggleLargeOn" />
        </div>
        <div class="vx-text-slate-800 vx-text-sm vx-leading-tight">
            {{ generalStatusLabel(props.resource.status) }}
        </div>
    </div>
    <div v-else-if="props.resourceType === 'segment'">
        <template
            v-if="props.segmentContactsCount !== null && props.segmentContactsCount !== undefined">
            {{ vxFormatCount(props.segmentContactsCount) }}
        </template>
        <template v-else>
            <vx-icon-loading spin />
        </template>
    </div>
    <div v-else>
        Unknown
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faToggleLargeOff, faToggleLargeOn } from '@fortawesome/pro-solid-svg-icons';
import { VxBadge, VxIconLoading, vxFormatCount } from '@voxie/frontend-components';
import { generalStatusLabel } from '~/components/filters';

const props = defineProps({
    resourceType: String,
    resource: Object,
    color: Boolean,
    iconColor: String,
    segmentContactsCount: Number,
});

const publishedAutomatedActions = (automation) => automation.automated_actions?.filter(action => action.status === "published")?.length || automation.live_actions_count || 0

</script>
