<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        :stacked="stacked"
        :preventClosing="preventClosing"
        :background="false"
        :padding="false"
        size="md">
        <template v-slot:header>
            <div class="vx-px-6 vx-pt-6">
                New Snippet
            </div>
        </template>
        <template v-slot:subheader>
            <div class="vx-px-6">
                This will create a new message snippet.
            </div>
        </template>

        <form id="create-snippet"
            @submit.prevent="submit"
            class="vx-p-6">

            <vx-label>Title</vx-label>
            <vx-input v-model="data.title"
                :error="errors.title"
                @update:modelValue="errors.title = ''"
                size="lg"
                placeholder="Snippet name">
            </vx-input>

            <vx-label class="vx-mt-4">Message</vx-label>
            <message-builder ref="messageBuilderRef"
                v-model="data.body"
                v-model:mediaUrl="data.media_url"
                v-model:mediaContentType="data.media_content_type"
                :error="errors.body"
                @update:modelValue="errors.body = ''"
                placeholder="Snippet message"
                preview
                :disableMedia="props.disableMedia"
                :disableSnippets="true"></message-builder>
        </form>

        <template v-slot:footer>
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <vx-button @click.prevent="emit('update:visible', false)"
                    color="muted"
                    size="lg"
                    type="button">
                    Cancel
                    <font-awesome-icon :icon="faCircleX"></font-awesome-icon>
                </vx-button>
                <vx-button form="create-snippet"
                    type="submit"
                    color="primary"
                    size="lg"
                    class="vx-grow"
                    :loading="submitting">
                    Save
                    <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                </vx-button>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import { VxButton, VxInput, VxLabel, VxModal } from '@voxie/frontend-components';
import { computed, reactive, ref, watch } from 'vue';
import { useToasted } from '../../../../composables/useToasted';
import snippetsClient from '../../../../services/snippetsClient';
import actionsClient from '../../../../services/actionsClient';
import MessageBuilder from '../MessageBuilder.vue';
import debounce from '~/utils/debounce';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleX, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';

const toasted = useToasted();

const props = defineProps({
    visible: Boolean,
    disableMedia: Boolean,
});

const emit = defineEmits(['update:visible', 'created']);

const submitting = ref(false);
const teamId = Spark.state.currentTeam.id;

const messageBuilderRef = ref();
const stacked = computed(() => {
    if (!messageBuilderRef.value) {
        return false;
    }
    return messageBuilderRef.value.modalOpen;
});

const preventClosing = ref(false);

watch([
        () => messageBuilderRef.value?.emojisPicker,
        () => messageBuilderRef.value?.actionsDropdown
    ],
    debounce(() => {
        preventClosing.value = messageBuilderRef.value?.emojisPicker || messageBuilderRef.value?.actionsDropdown;
    }, 150)
)

const data = ref({
    title: '',
    media_url: '',
    media_content_type: '',
    body: '',
})

const errors = reactive({
    title: '',
    body: '',
})

const clearData = () => {
    data.value = {
        title: '',
        body: '',
    }
}

const submit = async () => {
    if (submitting.value) {
        return;
    }
    if (!data.value.title.trim().length) {
        errors.title = 'Snippet name is required.';
        return;
    }
    if (!data.value.body.trim().length) {
        errors.body = 'Snippet message is required.';
        return;
    }
    try {
        submitting.value = true;
        const response = await snippetsClient.createSnippet(teamId, data.value);
        actionsClient.send('snippet_created');
        emit('created', response.data);
        emit('update:visible', false);
        clearData();
    } catch (e) {
        console.error(e);

        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
            return;
        }

        toasted.error(e.response?.data?.message || 'Something went wrong.');
    } finally {
        submitting.value = false;
    }
}
</script>
