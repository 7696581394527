<template>
    <card title="Attribution Settings">
        <div class="form-group row">
            <label class="col-md-4 col-form-label text-md-right">Attribution Window in Days</label>

            <div class="col-md-4">
                <select class="form-control" name="attribution_window" v-model="form.attribution_window">
                    <option v-for="n in attributionDays" :value="n" :key="n">{{ n }}</option>
                </select>
            </div>
        </div>

        <div class="row">
            <span class="col-md-4 col-form-label text-md-right">Include in Attribution:</span>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label text-md-right">Campaign Messages</label>

            <div class="col-md-6">
                <v-switch
                    id="campaign_message"
                    :checked="campaign_message"
                />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label text-md-right">User Messages</label>

            <div class="col-md-6">
                <v-switch
                    id="user_message"
                    :checked="user_message"
                />
            </div>
        </div>

        <div class="form-group row" v-if="FEATURES.promotions">
            <label class="col-md-4 col-form-label text-md-right">Promotions</label>

            <div class="col-md-6">
                <v-switch
                    id="promotion"
                    :checked="promotion"
                />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-md-4 col-form-label text-md-right">Discount Codes</label>

            <div class="col-md-6">
                <v-switch
                    id="discount_code"
                    :checked="discount_code"
                />
            </div>
        </div>
    </card>
</template>

<script>
import Card from '../../../general/Card.vue';
import vSwitch from '../../../general/Switch.vue';

export default {
    components: {
        Card,
        vSwitch,
    },
    inject: ['FEATURES'],
    props: {
        form: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            attributionDays: 60,
            campaign_message: false,
            user_message: false,
            promotion: false,
            discount_code: false,
        };
    },
    mounted() {
        this.campaign_message = this.form.attribution_types.includes('campaign_message');
        this.user_message = this.form.attribution_types.includes('user_message');
        this.promotion = this.form.attribution_types.includes('promotion');
        this.discount_code = this.form.attribution_types.includes('discount_code');

        Bus.$on(`switch-campaign_message-changed`, (value) => this.handleCheckbox('campaign_message', value));
        Bus.$on(`switch-user_message-changed`, (value) => this.handleCheckbox('user_message', value));
        Bus.$on(`switch-promotion-changed`, (value) => this.handleCheckbox('promotion', value));
        Bus.$on(`switch-discount_code-changed`, (value) => this.handleCheckbox('discount_code', value));
    },
    methods: {
        handleCheckbox(name, value) {
            this[name] = value;

            if (value) {
                this.form.attribution_types = [ ...this.form.attribution_types, name ];
            } else {
                this.form.attribution_types = this.form.attribution_types.filter(el => el !== name);
            }
        }
    },
};
</script>
