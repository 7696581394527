<template>
    <div>
        <team-users-controls
            v-model="fetchParams"
            @apply="applyFilters"
            @reset="resetFilters"
            @add="emit('add')"
            :loading="loading"></team-users-controls>

        <page-loading v-if="loading && !list.length"></page-loading>

        <template v-if="!loading && !list.length">
            <vx-box color="info"
                v-if="!isEqual(defaultFilters(), fetchParams)"
                class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                <div class="vx-w-64 vx-mx-auto vx-text-center">
                    No results for current search, try adjusting your filters.
                </div>
                <vx-button type="button"
                    color="secondary"
                    @click="resetFilters">
                    Reset All <font-awesome-icon :icon="faCircleX" />
                </vx-button>
            </vx-box>
            <vx-box color="info"
                v-else-if="!list.length"
                class="vx-mt-4 vx-flex vx-flex-col vx-px-8 vx-py-20 vx-gap-6 vx-items-center vx-text-xl vx-text-slate-500">
                No Users yet.
                <vx-button v-if="isAdminOrOwner" @click="emit('add')">
                    Add User <font-awesome-icon :icon="faPlusCircle" />
                </vx-button>
            </vx-box>
        </template>

        <vx-table v-if="list.length" class="xl:vx-grid-cols-3-auto" rowBreakpoint="xl">
            <vx-table-row isHeader>
                <div>
                    Name
                </div>
                <div>
                    Team Role
                </div>
            </vx-table-row>

            <team-active-user-row
                v-for="(item, index) in list"
                v-model="list[index]"
                :key="list[index].id"
                @updated="emit('updated')"
                @deleted="removeFromList($event)">
            </team-active-user-row>
        </vx-table>

        <div v-if="list.length" class="vx-mt-8 vx-flex vx-justify-between vx-w-full vx-gap-10">
            <vx-button
                v-if="fetchParams.cursor"
                @click="loadData()"
                color="muted-dark"
                size="xl"
                data-test="load-more-btn"
                class="vx-grow-0"
                :loading="loading">
                Load More
            </vx-button>
            <div class="vx-text-lg vx-grow-0 vx-ml-auto vx-font-normal vx-text-slate-600">
                {{ list.length }}/{{ totalCount }}
            </div>
        </div>
    </div>
</template>
<script setup>
import PageLoading from '~/components/general/PageLoading.vue'
import TeamActiveUserRow from './TeamActiveUserRow.vue';
import TeamUsersControls from './TeamUsersControls.vue';
import { usersClient } from '~/services';
import { VxBox, VxButton, VxTable, VxTableRow } from '@voxie/frontend-components';
import { faPlusCircle, faCircleX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useCursorPagination } from '~/composables/useCursorPagination';
import { onMounted, ref } from 'vue';
import { isEqual } from '~/utils/helpers';

const teamId = Spark.state.currentTeam.id;
const isAdminOrOwner = Spark.isAdminOrOwner;

const emit = defineEmits(['add', 'updated']);

const totalCount = ref(0);
const defaultFilters = () => ({
    cursor: null,
    sort_dir: 'desc',
    sort_field: 'id',
    role: null,
    query: null,
});

const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const resetFilters = () => {
    fetchParams.value = defaultFilters();

    loadData();
};

const applyFilters = () => {
    fetchParams.value = {
        ...fetchParams.value,
        cursor: null,
    }

    loadData();
}

const loadData = () => {
    fetchRecords(usersClient.listUsers(teamId, { ...fetchParams.value }));
    fetchTotalCount();
};
const fetchTotalCount = async () => {
    try {
        const response = await usersClient.usersCount(teamId, { ...fetchParams.value });
        totalCount.value = response.data?.count || 0;
    } catch (e) {
        console.error(e);
    }
}

const removeFromList = (itemId) => {
    list.value = list.value.filter((item) => item.id != itemId);
    totalCount.value = totalCount.value - 1;
    if (!list.value.length) {
        loadData();
    }
};

onMounted(() => {
    resetFilters();
});

</script>
