<template>
    <div class="vx-mb-4 vx-flex vx-flex-wrap vx-items-center vx-justify-between vx-gap-4 vx-w-full">
        <h2 class="vx-text-xl vx-font-sans vx-font-medium vx-leading-7 vx-m-0">Past Import Tasks</h2>

        <a class="btn btn-primary" href="/contacts/import/new">New Import</a>
    </div>

    <div class="row">
        <div v-if="list.length > 0" class="col-12 vx-pt-3">
            <table
                class="c-table u-mb-small u-border-none u-border-left-zero u-border-right-zero"
            >
                <tbody>
                    <contact-import-row-v3
                        v-for="item in list"
                        :key="item.id"
                        :item="item"
                    />
                </tbody>
            </table>
            <div v-if="fetchParams.cursor" class="text-center">
                <button
                    :disabled="loading"
                    @click="loadData()"
                    role="button"
                    data-test="load-more-btn"
                    class="d-inline-block c-pagination__control u-text-dark"
                >
                    Load more
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import ContactImportRowV3 from './ContactImportRowV3.vue';
import { onMounted } from 'vue';
import importsClient from '../../../services/importsClient';
import { useCursorPagination } from '../../../composables/useCursorPagination';

const { list, loading, fetchParams, fetchRecords } = useCursorPagination();

const defaultFilters = () => ({
    cursor: null,
    forward: false,
});

onMounted(() => {
    fetchParams.value = defaultFilters();
    loadData();
});

const loadData = () => {
    fetchRecords(importsClient.list(Spark.state.currentTeam.id, 'contact', {...fetchParams.value}));
};
</script>
