import BaseClient from './BaseClient';

/**
 * @deprecated
 */
class AutomationsClient extends BaseClient {
    updateStatusOfAutomationActions(url, status) {
        const data = { status: status };
        return this.makeCoreApiRequest(url, 'put', data);
    }
}

export default new AutomationsClient();
