<template>
    <contact-details :contactId="props.contactId">
        <div class="vx-col-span-12 lg:vx-col-span-10 vx-flex vx-flex-col vx-w-full vx-justify-center vx-gap-2 md:vx-gap-2">
            <vx-box class="vx-bg-slate-50 vx-flex vx-flex-col vx-max-w-screen-sm vx-p-8 vx-text-xl" color="info">
                <h2 class="vx-text-xl vx-font-extrabold vx-leading-7 vx-text-slate-700">
                    Subscriptions
                </h2>
                <p class="vx-text-sm vx-font-sans vx-text-slate-500 lg:vx-text-base">
                    Subscriptions of this contact.
                </p>
            </vx-box>

            <div v-if="loading && !list.length" class="vx-text-center vx-py-10">
                <vx-icon-loading class="vx-w-9 vx-h-9 vx-animate-loading-spin"></vx-icon-loading>
            </div>

            <template v-else>
                <vx-table v-if="list.length" class="xl:vx-grid-cols-5" rowBreakpoint="xl">
                    <vx-table-row isHeader>
                        <div>
                            Audience Type
                        </div>
                        <div>
                            Status
                        </div>
                        <div class="xl:!vx-col-span-2">
                            Reason
                        </div>
                        <div>
                            Updated at
                        </div>
                    </vx-table-row>
                    <contact-custom-attribute-row v-for="(item, index) in list"
                        v-model="list[index]"
                        :key="item.id"></contact-custom-attribute-row>
                </vx-table>
            </template>
        </div>
    </contact-details>
</template>

<script setup>
import ContactDetails from '../ContactDetails.vue';
import { VxBox, VxIconLoading, VxTable, VxTableRow } from '@voxie/frontend-components';
import ContactCustomAttributeRow from './ContactSubscriptionRow.vue';
import { useContactDetails } from '../useContactDetails';

const { onMountedContact, subscriptions: list, loadingSubscriptions: loading, getSubscriptions, contact } = useContactDetails();

const props = defineProps({
    contactId: {
        type: Number,
        required: true,
    },
});

onMountedContact(async() => {
    await getSubscriptions();
    setSubscriptions();
});

const setSubscriptions = () => {
    const types = ['marketing', 'transactional'];

    types.forEach((type) => {
        const hasSubscription = !!list.value.find(subscription => subscription.type === type);

        if (!hasSubscription) {
            list.value.push({
                type: type,
                status: 'unknown',
                reason_text: '-',
                updated_at: contact.value.created_at
            });
        }
    })
};
</script>

