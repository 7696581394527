<template>
    <page-controls>
        <template v-slot:left>
            <div class="vx-grow @3xl/controls:vx-max-w-md">
                <vx-filterable :active="active"
                    @toggle="toggleFilterable">
                    <template v-slot:placeholder>
                        <span class="vx-mr-2">Search</span>
                        <vx-badge v-if="filtersCount > 0"
                            :borders="false"
                            size="xs"
                            @click.stop="reset">
                            {{ filtersCount }} filters
                            <font-awesome-icon :icon="faXmarkCircle" />
                        </vx-badge>
                    </template>
                    <template v-slot:form>
                        <form @submit.prevent="emit('apply')"
                            class="vx-space-y-4">
                            <div class="vx-space-y-1">
                                <vx-label>
                                    Keyword Search
                                </vx-label>

                                <vx-input autofocus
                                    type="text"
                                    placeholder="Search"
                                    maxlength="80"
                                    :modelValue="props.modelValue.query"
                                    @update:modelValue="updateModelValue('query', $event)">
                                    <template v-slot:right>
                                        <font-awesome-icon class="vx-text-xs"
                                            :icon="faMagnifyingGlass" />
                                    </template>
                                </vx-input>
                            </div>

                            <div class="vx-flex vx-gap-2">
                                <vx-button type="button"
                                    color="muted-light"
                                    @click="active = false; reset()">Cancel</vx-button>
                                <vx-button data-test="filters-apply"
                                    type="submit"
                                    block
                                    @click.stop="active = false; emit('apply')">
                                    Apply
                                    <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                                </vx-button>
                            </div>
                        </form>
                    </template>
                </vx-filterable>
            </div>
        </template>
        <template v-slot:right>
            <vx-button class="vx-w-full"
                color="muted-dark"
                size="lg"
                @click="informationModal = true">
                <span class="vx-block @3xl/controls:vx-hidden">Overview</span>
                <font-awesome-icon :icon="faQuestionCircle" />
            </vx-button>

            <vx-modal v-model:visible="informationModal"
                class="vx-max-w-lg">
                <template v-slot:header>Tags</template>
                <p class="vx-mb-2 vx-text-base">
                    A tag is a description for a collection of contacts,
                    categorized by their interests, preferences, information
                    or upload criteria Tags can be used to create audiences
                    to message. Use tags to send campaigns to specific groups
                    based on specific shared attributes.
                </p>
            </vx-modal>
        </template>
    </page-controls>
</template>
<script setup>
import { faCircleCheck, faQuestionCircle, faMagnifyingGlass, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxButton, VxBadge, VxLabel, VxModal, VxFilterable, VxInput } from '@voxie/frontend-components';
import { ref, computed } from 'vue';
import PageControls from '~/components/general/PageControls.vue';

const informationModal = ref(false);
const active = ref(false);

const toggleFilterable = (event) => {
    active.value = event;
}

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});


const emit = defineEmits(['update:modelValue', 'apply', 'reset'])

const updateModelValue = (key, value) => {
    emit("update:modelValue", {
        ...props.modelValue,
        [key]: value,
    });
};

const filtersCount = computed(() => (props.modelValue.query ? 1 : 0));

const reset = () => {
    emit('reset')
}
</script>
