import BaseClient from './BaseClient';

class ActionsClient extends BaseClient {
    send(actionId) {
        const teamId = Spark.state.currentTeam.id;
        const url = `/api/v3/teams/${teamId}/actions/${actionId}`;
        return this.makeCoreApiRequest(url, 'post');
    }
}

export default new ActionsClient();
