import BaseClient from './BaseClient';

class TeamCustomAttributesClient extends BaseClient {
    getList(teamId) {
        const url = `/api/v3/teams/${teamId}/team-custom-attributes`;
        return this.makeCoreApiRequest(url);
    }

    getAllCustomAttributes(teamId) {
        const url = `/api/v3/teams/${teamId}/team-custom-attributes`;
        return this.makeCoreApiRequest(url);
    }

    createCustomAttribute(teamId, data) {
        const url = `/api/v3/teams/${teamId}/team-custom-attributes`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    updateCustomAttribute(teamId, customAttributeKey, data) {
        const url = `/api/v3/teams/${teamId}/team-custom-attributes/${encodeURIComponent(customAttributeKey)}`;
        return this.makeCoreApiRequest(url, 'put', data);
    }

    deleteCustomAttribute(teamId, customAttributeKey) {
        const url = `/api/v3/teams/${teamId}/team-custom-attributes/${encodeURIComponent(customAttributeKey)}`;
        return this.makeCoreApiRequest(url, 'delete');
    }
}

export default new TeamCustomAttributesClient();
