import BaseClient from './BaseClient';

class AutomationRulesClient extends BaseClient {
    getAutomationRulesMetadata(teamId) {
        const url = `/api/v3/teams/${teamId}/automation-rules/metadata`;
        return this.makeCoreApiRequest(url);
    }

    search(teamId, params) {
        const url = `/api/v3/teams/${teamId}/search/automation-rules`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    get(teamId, automationRuleId) {
        const url = `/api/v3/teams/${teamId}/automation-rules/${automationRuleId}`;
        return this.makeCoreApiRequest(url, 'get');
    }

    createOrUpdate(teamId, data) {
        if (data.id) {
            return this.update(teamId, data.id, data);
        }

        return this.create(teamId, data);
    }

    update(teamId, automationRuleId, data) {
        const url = `/api/v3/teams/${teamId}/automation-rules/${automationRuleId}`;
        return this.makeCoreApiRequest(url, 'put', data);
    }

    create(teamId, data) {
        const url = `/api/v3/teams/${teamId}/automation-rules`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    delete(teamId, automationRuleId) {
        const url = `/api/v3/teams/${teamId}/automation-rules/${automationRuleId}`;
        return this.makeCoreApiRequest(url, 'delete');
    }

    dependencies(teamId, automationRuleId) {
        const url = `/api/v3/teams/${teamId}/automation-rules/${automationRuleId}/dependencies`;
        return this.makeCoreApiRequest(url, 'get');
    }

    updateActionsStatus(teamId, automationRuleId, status) {
        return this.makeCoreApiRequest(
            `/api/v3/teams/${teamId}/automation-rules/${automationRuleId}/actions/update-status`,
            'put',
            {
                status: status,
            }
        );
    }

    automationRulesCount($teamId, params) {
        const method = 'get';
        const url = `/api/v3/teams/${$teamId}/search/automation-rules/count`;

        return this.makeCoreApiRequest(url, method, null, params);
    }
}

export default new AutomationRulesClient();
