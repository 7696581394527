<template>
    <vx-modal :visible="props.visible"
        @update:visible="emit('update:visible', $event)"
        :padding="false"
        size="md">
        <template v-slot:header>
            <div class="vx-px-6 vx-pt-6">
                <slot name="header">
                    Preview Message
                </slot>
            </div>
        </template>

        <template v-if="$slots.subheader" v-slot:subheader>
            <div class="vx-px-6">
                <slot name="subheader"></slot>
            </div>
        </template>

        <div class="vx-p-6">
            <div
                class="vx-p-4 vx-whitespace-pre-line vx-bg-white vx-shadow vx-text-slate-700 vx-text-sm vx-font-normal vx-rounded-[16px] vx-rounded-bl vx-overflow-y-auto">
                <slot name="body">
                    {{ props.message.body }}
                </slot>
            </div>
            <div v-if="props.message.mediaUrl"
                class="vx-mt-2 vx-p-4 vx-bg-white vx-shadow vx-rounded-[16px] vx-rounded-bl vx-max-w-[208px] vx-max-h-[500px]">
                <img :src="props.message.mediaUrl"
                    class="vx-w-full vx-block vx-object-cover vx-rounded vx-rounded-b-[4px]" />
            </div>
        </div>

        <template v-slot:footer
            v-if="$slots.footer">
            <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                <slot name="footer"></slot>
            </div>
        </template>
    </vx-modal>
</template>

<script setup>
import { VxModal } from '@voxie/frontend-components';

const props = defineProps({
    visible: Boolean,
    message: {
        type: Object,
        required: true,
        default: () => ({})
    },
});

const emit = defineEmits(['update:visible']);
</script>
