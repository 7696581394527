<template>
    <div class="vx-font-sans vx-relative">
        <div class="
        vx-relative
        vx-font-sans
        vx-bg-white
        vx-transition
        vx-border
        vx-border-solid
        vx-rounded-xl
        focus-within:vx-outline-none
        focus-within:vx-ring-2
        focus-within:vx-ring-blue-500
        focus-within:vx-ring-offset-2
        focus-within:vx-ring-offset-slate-50
    "
            :class="{
                'vx-border-slate-300': !isDisabled && !isInvalid && !props.floating,
                'vx-text-neutral-500 vx-cursor-not-allowed vx-pointer-events-none': isDisabled,
                'vx-border-neutral-200': isDisabled && !props.floating,
                'vx-text-rose-600 focus-within:!vx-ring-rose-500': isInvalid,
                'vx-shadow vx-border-white': props.floating,
            }">
            <textarea :value="props.modelValue"
                :maxlength="props.maxlength"
                @input="emit('update:modelValue', $event.target.value)"
                @blur="cursorLocation = $event.target.selectionEnd; emit('blur', $event);"
                @keydown.meta.enter="submit"
                @keydown.ctrl.enter="submit"
                ref="textarea"
                :placeholder="props.placeholder"
                :disabled="isDisabled"
                class="
                    vx-px-4
                    vx-pt-4
                    vx-bg-white
                    vx-font-sans
                    vx-text-inherit
                    vx-text-base
                    vx-min-h-[40px]
                    vx-max-h-[50vh]
                    vx-rounded-xl
                    vx-resize-none
                    vx-overflow-auto
                    vx-border-0
                    vx-w-full
                    focus:vx-outline-none
                "
                :class="{
                    'vx-text-slate-800': !isDisabled,
                    'vx-text-slate-400': isDisabled,
                }" />
            <div class="vx-w-32 vx-mx-4 vx-mt-2"
                v-if="props.mediaUrl">
                <message-builder-media-preview :mediaUrl="props.mediaUrl"
                    :mediaContentType="props.mediaContentType"
                    @remove="removeMedia()"
                    removeable></message-builder-media-preview>
            </div>
            <div class="vx-p-2 vx-grid vx-grid-cols-2 md:vx-flex md:vx-justify-between md:vx-items-center">
                <message-builder-actions ref="actionsRef"
                    class="vx-order-2"
                    @click:variables="variablesModal = true"
                    @click:snippets="snippetsModal = true"
                    @click:emojis="emojisPicker = true"
                    @click:media="mediaModal = true"
                    :disabled="isDisabled"
                    :disableSnippets="props.disableSnippets"
                    :disableMedia="props.disableMedia"
                    >
                    <slot name="actions"></slot>
                </message-builder-actions>
                <div
                    class="vx-text-xs vx-text-slate-500 vx-col-span-2 vx-order-1 vx-text-center vx-mb-2 md:vx-mb-0 md:vx-order-2">
                    <message-builder-numbers :body="props.modelValue"
                        :mediaUrl="props.mediaUrl"></message-builder-numbers>
                </div>
                <div class="vx-flex vx-items-center vx-order-3 vx-justify-self-end">
                    <vx-dropdown v-if="!props.preview && !props.testable">
                        <vx-button type="button" :disabled="isDisabled"
                            color="muted-light">
                            <font-awesome-icon :icon="faEllipsis"
                                class="vx-text-sm"></font-awesome-icon>
                        </vx-button>

                        <template v-slot:items>
                            <vx-dropdown-item @click.prevent="scheduleModal = true">
                                <span>
                                    <font-awesome-icon :icon="faCalendar"
                                        class="vx-mr-1 vx-text-sm"></font-awesome-icon>
                                    Schedule
                                </span>
                            </vx-dropdown-item>
                            <vx-dropdown-item :disabled="!props.modelValue"
                                @click.prevent="previewModal = true">
                                <span>
                                    <font-awesome-icon :icon="faEye"
                                        class="vx-mr-1 vx-text-sm"></font-awesome-icon>
                                    Preview
                                </span>
                            </vx-dropdown-item>
                        </template>
                    </vx-dropdown>

                    <vx-button v-if="!props.preview && !props.testable"
                        type="button"
                        :disabled="isDisabled"
                        data-test="send-button"
                        class="vx-ml-2"
                        @click.prevent="submit">
                        Send
                        <font-awesome-icon :icon="faPaperPlane"
                            class="vx-text-sm"></font-awesome-icon>
                    </vx-button>

                    <vx-button v-if="props.preview"
                        type="button"
                        size="sm"
                        :disabled="isDisabled || !props.modelValue"
                        class="vx-ml-2"
                        @click.prevent="previewModal = true"
                        color="muted">
                        Preview
                        <font-awesome-icon :icon="faEye"
                            class="vx-text-sm"></font-awesome-icon>
                    </vx-button>
                    <vx-button v-if="props.testable"
                        type="button"
                        size="sm"
                        :disabled="isDisabled || !props.modelValue"
                        class="vx-ml-2"
                        @click.prevent="testModal = true"
                        color="muted">
                        Send Test
                        <font-awesome-icon :icon="faFlaskVial"
                            class="vx-text-sm"></font-awesome-icon>
                    </vx-button>
                </div>
            </div>
            <div v-if="$slots.alert"
                class="vx-absolute vx-left-0 vx-top-0 vx-w-full vx-h-full vx-flex vx-items-center vx-justify-center">
                <div>
                    <slot name="alert"></slot>
                </div>
            </div>
        </div>
        <div v-if="props.error || $slots.error"
            class="vx-text-xs vx-text-rose-800 vx-mt-2">
            <slot name="error"></slot>
            {{ props.error }}
        </div>

        <vx-alert v-if="droppedSnippetMedia" class="vx-mt-2" color="info" :closeable="false">
            The snippet attachment has been dropped because attachments are not supported here.
        </vx-alert>

        <message-preview-modal v-model:visible="previewModal"
            :message="{
                body: props.modelValue,
                mediaUrl: props.mediaUrl,
            }">
            <template v-slot:header>
                <slot name="previewHeader"></slot>
            </template>
            <template v-slot:subheader>
                <slot name="previewSubheader"></slot>
            </template>
            <template v-slot:body>
                <slot name="previewBody"></slot>
            </template>
            <template v-if="!props.preview"
                v-slot:footer>
                <vx-button type="button" @click.prevent="previewModal = false"
                    size="lg"
                    color="muted">
                    Cancel
                    <font-awesome-icon :icon="faCircleX"></font-awesome-icon>
                </vx-button>
                <vx-button type="button" @click.prevent="submit"
                    size="lg"
                    color="primary"
                    class="lg:vx-grow">
                    Send
                    <font-awesome-icon :icon="faPaperPlane"></font-awesome-icon>
                </vx-button>
            </template>
            <template v-else
                v-slot:footer>
                <vx-button type="button" @click.prevent="previewModal = false"
                    size="lg"
                    block
                    color="primary">
                    Ok
                </vx-button>
            </template>
        </message-preview-modal>
        <message-builder-snippets v-if="!props.disableSnippets"
            v-model:visible="snippetsModal"
            :disableMedia="props.disableMedia"
            @selected="selectedSnippet($event)"></message-builder-snippets>
        <message-builder-swap-snippet-media v-if="!props.disableSnippets && !props.disableMedia"
            :snippet="swapSnippetMediaModal"
            :currentMediaUrl="props.mediaUrl"
            :currentMediaContentType="props.mediaContentType"
            @close="swapSnippetMediaModal = undefined"
            @no="selectedSnippet($event)"
            @yes="selectedSnippet($event, true)"></message-builder-swap-snippet-media>
        <message-builder-schedule v-model:visible="scheduleModal"
            @schedule="schedule"></message-builder-schedule>
        <message-builder-variables v-model:visible="variablesModal"
            @selected="insertContent($event)"></message-builder-variables>
        <message-builder-emojis class="vx-absolute vx-bottom-14 vx-left-14"
            v-model:visible="emojisPicker"
            @selected="insertContent($event.native);"></message-builder-emojis>
        <message-builder-media v-model:visible="mediaModal"
            @update:visible="!$event && textarea.focus()"
            :mediaUrl="props.mediaUrl"
            @update:mediaUrl="emit('update:mediaUrl', $event)"
            :mediaContentType="props.mediaContentType"
            @update:mediaContentType="emit('update:mediaContentType', $event)">
        </message-builder-media>
        <message-builder-test
            v-model:visible="testModal"
            :bodyMessage="props.modelValue"
            :mediaUrl="props.mediaUrl"
            :mediaContentType="props.mediaContentType"
        ></message-builder-test>
    </div>
</template>

<script setup>
import { faCalendar, faEllipsis, faEye } from '@fortawesome/pro-regular-svg-icons';
import { faCircleX, faFlaskVial, faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxAlert, VxButton, VxDropdown, VxDropdownItem } from '@voxie/frontend-components';
import { computed, nextTick, ref, useSlots, watch } from 'vue';
import MessageBuilderNumbers from './MessageBuilderNumbers.vue';
import MessageBuilderActions from './MessageBuilderActions.vue';
import MessagePreviewModal from './modals/MessagePreviewModal.vue';
import MessageBuilderSchedule from './modals/MessageBuilderSchedule.vue';
import MessageBuilderVariables from './modals/MessageBuilderVariables.vue';
import MessageBuilderSnippets from './modals/MessageBuilderSnippets.vue';
import MessageBuilderSwapSnippetMedia from './modals/MessageBuilderSwapSnippetMedia.vue';
import MessageBuilderEmojis from './modals/MessageBuilderEmojis.vue';
import MessageBuilderMedia from './modals/MessageBuilderMedia.vue';
import MessageBuilderMediaPreview from './modals/MessageBuilderMediaPreview.vue';
import MessageBuilderTest from './modals/MessageBuilderTest.vue';


const slots = useSlots();

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    mediaUrl: {
        type: String,
    },
    placeholder: {
        type: String,
    },
    mediaContentType: {
        type: String,
    },
    disabled: {
        type: Boolean,
    },
    error: {
        type: String,
    },
    floating: {
        type: Boolean,
    },
    preview: {
        type: Boolean,
        default: false,
    },
    testable: {
        type: Boolean,
        default: false,
    },
    disableSnippets: {
        type: Boolean,
        default: false,
    },
    disableMedia: {
        type: Boolean,
        default: false,
    },
    maxlength: {
        type: [ String, Number ],
    }
});
const emit = defineEmits(['blur', 'update:modelValue', 'update:mediaUrl', 'update:mediaContentType', 'selected:snippet', 'schedule', 'submit']);

const actionsRef = ref();
const actionsDropdown = computed(() => {
    if (!actionsRef.value) {
        return false;
    }

    return actionsRef.value.open;
})
const textarea = ref();
const cursorLocation = ref(props.modelValue.length);

const submit = (ev) => {
    ev.preventDefault();
    emit('submit');
}

const emojisPicker = ref(false);
const mediaModal = ref(false);
const scheduleModal = ref(false);
const snippetsModal = ref(false);
const variablesModal = ref(false);

const insertContent = async (content) => {
    if (!textarea.value) {
        emit('update:modelValue', props.modelValue + content);
        return;
    }

    const start = textarea.value.value.substring(0, cursorLocation.value);
    const end = textarea.value.value.substring(cursorLocation.value);

    emit('update:modelValue', start + content + end);

    await nextTick();

    textarea.value.selectionStart = cursorLocation.value + content.length;
    textarea.value.selectionEnd = cursorLocation.value + content.length;

    cursorLocation.value = textarea.value.selectionStart;

    textarea.value.focus();
}

const droppedSnippetMedia = ref(false);
const swapSnippetMediaModal = ref(undefined);

const selectedSnippet = async (snippet, replace) => {
    if (replace == undefined &&
        !props.disableMedia &&
        snippet.media_url && props.mediaUrl
        && props.mediaUrl !== snippet.media_url
    ) {
        swapSnippetMediaModal.value = snippet;
        return;
    }

    emit('update:modelValue', snippet.body);
    await nextTick();
    if (!props.disableMedia && snippet.media_url) {
        emit('update:mediaContentType', snippet.media_content_type);
        await nextTick();
        emit('update:mediaUrl', snippet.media_url);
    }
    emit('selected:snippet', snippet);

    droppedSnippetMedia.value = props.disableMedia && Boolean(snippet.media_url);
    await nextTick();
    textarea.value.focus();
}

const removeMedia = async () => {
    emit('update:mediaUrl');
    await nextTick();
    emit('update:mediaContentType')
}

const isDisabled = computed(() => {
    return props.disabled || slots.alert;
})

const isInvalid = computed(() => {
    return props.error || slots.error?.().length || props.modelValue.length >= 16000;
});

const testModal = ref(false);
const previewModal = ref(false);

const schedule = (scheduleAt) => {
    emit('schedule', scheduleAt);
    nextTick(textarea.value.focus);
}

const resize = () => {
    if (!textarea.value) {
        return;
    }

    textarea.value.style.height = 'auto'; // Make sure we do not decrease height on typing.
    textarea.value.style.height = textarea.value.scrollHeight + 2 + 'px';
}

watch(() => props.modelValue, () => {
    nextTick().then(resize)
}, {
    immediate: true,
});

const modalOpen = computed(() => {
    return previewModal.value || variablesModal.value || mediaModal.value || scheduleModal.value || snippetsModal.value || testModal.value || Boolean(swapSnippetMediaModal.value)
})

defineExpose({
    scheduleModal: scheduleModal,
    emojisPicker: emojisPicker,
    actionsDropdown: actionsDropdown,
    modalOpen: modalOpen,
    textarea: textarea,
});
</script>
