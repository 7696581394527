import BaseClient from './BaseClient';

class AdminCloningClient extends BaseClient {
    getTeams() {
        const url = `/api/admin/teams`;
        return this.makeCoreApiRequest(url);
    }

    cloneResources(data) {
        const url = '/api/admin/clone-resources';
        return this.makeCoreApiRequest(url, 'post', data);
    }

    getClonableAutomations(teamId) {
        const url = `/api/admin/teams/${teamId}/cloneable/automations`;
        return this.makeCoreApiRequest(url);
    }

    getClonableSegments(teamId) {
        const url = `/api/admin/teams/${teamId}/cloneable/segments`;
        return this.makeCoreApiRequest(url);
    }

    getClonableCampaigns(teamId) {
        const url = `/api/admin/teams/${teamId}/cloneable/campaigns`;
        return this.makeCoreApiRequest(url);
    }

    getClonableSnippets(teamId) {
        const url = `/api/admin/teams/${teamId}/cloneable/snippets`;
        return this.makeCoreApiRequest(url);
    }

    getClonableTeamCustomAttributes(teamId) {
        const url = `/api/admin/teams/${teamId}/cloneable/team-custom-attributes`;
        return this.makeCoreApiRequest(url);
    }
}

export default new AdminCloningClient();
