<template>
    <div :class="`c-alert c-alert--${appearance.type} alert`">
        <i :class="`c-alert__icon fa fa-${appearance.icon}-circle`"></i>
        {{ text }}
    </div>
</template>

<script>
const typesMap = {
    'warning': {
        type: 'warning',
        icon: 'exclamation',
    },
    'danger': {
        type: 'danger',
        icon: 'times',
    },
    'success': {
        type: 'success',
        icon: 'checkmark'
    },
};

export default {
    props: {
        text: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'warning',
        },
    },
    computed: {
        appearance() {
            return typesMap[this.type];
        },
    },
};
</script>
