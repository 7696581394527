<template>
    <vx-table-row>
        <vx-table-row-title>
            {{ item.friendly_name }}
        </vx-table-row-title>

        <vx-table-row-item>
            <template v-slot:label> Method: </template>

            <vx-badge size="xs"
                :color="colorForHttpMethod(item.http_method)">
                {{ item.http_method }}
            </vx-badge>
        </vx-table-row-item>
        <vx-table-row-item contentClasses="vx-max-w-full vx-overflow-hidden">
            <template v-slot:label> Hostname: </template>

            <vx-popover hover
                :arrow="true"
                placement="top"
                class="[&>div:first-child]:vx-truncate vx-w-full"
                >
                {{ item.uri_hostname }}
                <template v-slot:content>
                    {{ item.uri_hostname }}
                </template>
            </vx-popover>
        </vx-table-row-item>
        <vx-table-row-item>
            <template v-slot:label> Auth: </template>
                <div v-if="item.auth_config_id" class="vx-flex vx-items-center vx-gap-2">
                    <vx-popover hover
                        :arrow="true"
                        placement="top">
                            <vx-badge size="xs" color="slate">{{ item.auth_config_id?.slice(-6) }}</vx-badge>
                            <template v-slot:content>
                                {{ item.auth_config_id }}
                            </template>
                    </vx-popover>
                    <div v-if="config?.friendly_name" class="vx-truncate xl:vx-whitespace-normal xl:vx-line-clamp-2">
                        {{ config?.friendly_name }}
                    </div>
                    <vx-icon-loading spin v-if="config === undefined"></vx-icon-loading>
                    <vx-popover v-if="config === null"
                        hover
                        :arrow="true"
                        placement="top">
                        <span>
                            <font-awesome-icon :icon="faCircleInfo"></font-awesome-icon>
                            Not Found
                        </span>
                        <template v-slot:content>
                            An auth config was used but the name couldn't be found.
                        </template>
                    </vx-popover>
                </div>
                <div v-else> None </div>
        </vx-table-row-item>
        <vx-table-row-item>
            <template v-slot:label> Automations: </template>

            <vx-popover hover
                :arrow="true"
                placement="top">
                {{ item.live_automation_count }} / {{ item.automation_count }}
                <template v-slot:content>
                    Automations: {{ item.live_automation_count }} active / {{ item.automation_count }} total
                </template>
            </vx-popover>
        </vx-table-row-item>
        <vx-table-row-item>
            <template v-slot:label> Created at: </template>

            <vx-popover hover
                :arrow="true"
                placement="top"
                :key="createdAtKey">
                {{ fromNow(item.created_at) }}
                <template v-slot:content>
                    {{ shortDateTime(item.created_at) }}
                </template>
            </vx-popover>
        </vx-table-row-item>

        <vx-table-row-buttons>
            <vx-dropdown placement="right">
                <vx-button type="button"
                    color="muted"
                    outline
                    class="vx-w-[45px]">
                    <font-awesome-icon :icon="faEllipsis" />
                </vx-button>

                <template v-slot:items>
                    <vx-dropdown-item :href="`/settings/integrations/api-destinations/${props.item.id}/deliveries`">
                        API Deliveries
                        <font-awesome-icon :icon="faSquareTerminal"></font-awesome-icon>
                    </vx-dropdown-item>

                    <vx-popover class="vx-w-full"
                        hover
                        :arrow="true"
                        placement="left">
                        <vx-dropdown-item :disabled="!isAdminOrOwner" :href="`/settings/integrations/api-destinations/new?duplicateFromId=${props.item.id}`">
                                Duplicate
                            <font-awesome-icon :icon="faCopy"></font-awesome-icon>
                        </vx-dropdown-item>
                        <template v-if="!isAdminOrOwner" v-slot:content>
                            You do not have permission to duplicate.
                        </template>
                    </vx-popover>

                    <vx-popover class="vx-w-full"
                        hover
                        :arrow="true"
                        placement="left">
                        <vx-dropdown-item data-test="api-destination-delete" :disabled="!!item.live_automation_count || !isAdminOrOwner" @click="deleteDestination()">
                                Delete
                            <font-awesome-icon :icon="faTrash"></font-awesome-icon>
                        </vx-dropdown-item>
                        <template v-if="!!item.live_automation_count || !isAdminOrOwner" v-slot:content>
                            <div class="vx-font-normal vx-w-40">
                                <template v-if="!!item.live_automation_count">
                                    Cannot delete if being used in Automations.
                                </template>
                                <template v-else-if="!isAdminOrOwner">
                                    You do not have permission to delete.
                                </template>
                            </div>
                        </template>
                    </vx-popover>
                </template>
            </vx-dropdown>

            <vx-popover
                :hover="false"
                :arrow="true"
                placement="left">
                <vx-button
                    :disabled="!isAdminOrOwner"
                    :href="`/settings/integrations/api-destinations/${props.item.id}`"
                    block
                    color="muted"
                    outline>
                    Edit <font-awesome-icon :icon="faFilePen" />
                </vx-button>
                <template v-if="!isAdminOrOwner" v-slot:content>
                    You do not have permission to edit.
                </template>
            </vx-popover>
        </vx-table-row-buttons>

    </vx-table-row>
</template>

<script setup>
import {
    faFilePen,
    faTrash,
    faEllipsis,
    faSquareTerminal,
    faCircleInfo,
    faCopy,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxButton,
    VxTableRow,
    VxTableRowItem,
    VxTableRowTitle,
    VxTableRowButtons,
    VxDropdown,
    VxDropdownItem,
    VxBadge,
    VxPopover,
    VxIconLoading,
} from "@voxie/frontend-components";
import { ref, watch } from 'vue';
import apiDestinationsClient from '../../../../services/apiDestinationsClient';
import { fromNow, shortDateTime } from '../../../../utils/date';
import { useToasted } from '../../../../composables/useToasted';
import { colorForHttpMethod } from './apiDestinationHelper';
import { useAuthConfigs } from "./useAuthConfigs";
import { useInterval } from '@vueuse/core';

const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;
const isAdminOrOwner = Spark.isAdminOrOwner;

const authConfigs = useAuthConfigs();
const config = ref(undefined);

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(["deleted"]);

const deleteDestination = async () => {
    if (
        !window.confirm(
            "Are you sure? There is no undo."
        )
    ) {
        return;
    }

    try {
        await apiDestinationsClient.delete(teamId, props.item.id);
        emit("deleted", props.item.id);
    } catch (error) {
        console.error(error);
        toasted.global.platform_error();
    }
};

watch(() => props.item.auth_config_id, async (authConfigId) => {
    try {
        if (!authConfigId) {
            config.value = null;
            return;
        }
        config.value = await authConfigs.get(teamId, props.item.auth_config_id);
    } catch {
        config.value = null;
    }
}, {
    immediate: true,
});

const createdAtKey = useInterval(60000);

</script>
