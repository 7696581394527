<template>
    <div data-test="update-billing-contact" class="card card-default">
        <div
            class="card-header"
            data-test="card-header"
        >
            Billing Contact
        </div>

        <div class="card-body">
            <!-- Information Message -->
            <div class="alert alert-info" data-test="info-alert">
                <template v-if="canUpdate">
                    Here you may set a custom billing contact email address.
                </template>
                <template v-else>
                    Billing contact email address may only be changed by the team owner.
                </template>
            </div>

            <!-- Success Message -->
            <div
                v-if="form.successful"
                class="alert alert-success"
                data-test="success-alert"
            >
                Your billing contact has been updated!
            </div>

            <!-- Error Message -->
            <div
                v-else-if="form.errors.has('form')"
                class="alert alert-danger"
                data-test="error-alert"
            >
                {{ form.errors.get('form') }}
            </div>

            <!-- Billing Contact -->
            <form role="form">
                <div class="form-group row">
                    <label class="col-md-4 col-form-label text-md-right">Email Address</label>

                    <div class="col-md-6">
                        <input
                            v-model="form.email"
                            class="form-control"
                            :class="[{'is-invalid': form.errors.has('email')}, {'cursor-not-allowed': !canUpdate}]"
                            :disabled="!canUpdate"
                            name="email"
                            type="text"
                        />
                        <span
                            v-show="form.errors.has('email')"
                            class="invalid-feedback"
                            data-test="email-control-error"
                        >
                            {{ form.errors.get('email') }}
                        </span>
                    </div>
                </div>

                <!-- Update Button -->
                <div
                    v-if="canUpdate"
                    class="form-group row mb-0"
                >
                    <div class="offset-md-4 col-md-8 text-right">
                        <button
                            class="btn btn-primary"
                            :disabled="form.busy"
                            type="submit"
                            @click.prevent="update"
                        >
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: ['team', 'ownsTeam', 'isTeamAdmin'],

    /**
     * The component's data.
     */
    data() {
        return {
            form: new SparkForm({
                email: this.team.billing_email,
            }),
        };
    },

    created() {
        Bus.$on(`sparkFormUpdated.${this.form.sparkFormId}`, this.$forceUpdate);
    },

    methods: {
        /**
         * Update the billing contact email address.
         */
        update() {
            Spark.put(this.urlForUpdate, this.form);
        },
    },

    computed: {
        /**
         * Get the URL for the billing contact email address method update.
         */
        urlForUpdate() {
            return `/settings/teams/${this.team.id}/billing-contact-email-address`;
        },
        canUpdate() {
            return this.ownsTeam || this.isTeamAdmin;
        },
    },
};
</script>
