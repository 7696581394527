import authConfigsClient from '../../../../../../services/authConfigsClient';
import automationRulesClient from '../../../../../../services/automationRulesClient';

import customDeliveriesClient from '../../../../../../services/customDeliveriesClient';

import { onMounted, ref } from 'vue';
import { useToasted } from '../../../../../../composables/useToasted';

export const useApiDeliveryDetails = (originType, originId, deliveryId) => {
    const toasted = useToasted();

    const teamId = Spark.state.currentTeam.id;

    const delivery = ref(undefined);
    const loadingDelivery = ref(false);

    const automationRule = ref(undefined);
    const loadingAutomation = ref(false);

    const authConfig = ref(undefined);
    const loadingAuthConfig = ref(false);

    const request = ref(undefined);
    const loadingRequest = ref(false);

    const getDelivery = async () => {
        if (loadingDelivery.value) {
            return;
        }

        loadingDelivery.value = true;

        try {
            const response = await customDeliveriesClient.get(teamId, originType, originId, deliveryId);
            delivery.value = response.data;
        } catch (error) {
            console.error(error);
            toasted.global.platform_error();
        } finally {
            loadingDelivery.value = false;
        }
    };

    const getRequest = async () => {
        if (loadingRequest.value) {
            return;
        }

        loadingRequest.value = true;

        try {
            if (!delivery.value) {
                throw new Error('Delivery missing');
            }

            const response = await customDeliveriesClient.getRequest(teamId, originType, originId, deliveryId);
            request.value = response.data;
        } catch (error) {
            console.error(error);
            toasted.global.platform_error();
            request.value = null;
        } finally {
            loadingRequest.value = false;
        }
    };

    const getAuthConfig = async () => {
        if (loadingAuthConfig.value) {
            return;
        }

        loadingAuthConfig.value = true;

        try {
            if (!delivery.value) {
                throw new Error('Delivery missing');
            }

            if (!delivery.value?.auth_config_id) {
                authConfig.value = null;
                return;
            }

            const authConfigResponse = await authConfigsClient.get(teamId, delivery.value?.auth_config_id);

            authConfig.value = authConfigResponse.data;
        } catch (error) {
            console.error(error);
            toasted.global.platform_error();

            authConfig.value = null;
        } finally {
            loadingAuthConfig.value = false;
        }
    };

    const getAutomation = async () => {
        if (loadingAutomation.value) {
            return;
        }

        loadingAutomation.value = true;

        try {
            if (!delivery.value) {
                throw new Error('Delivery missing');
            }

            const automatedActionId = delivery.value?.delivery_source?.replace('automated_action:', '');
            if (!automatedActionId) {
                throw new Error('Automated action id missing');
            }

            const response = await automationRulesClient.search(teamId, {
                automated_action_ids: [automatedActionId],
            });

            automationRule.value =
                response.data.data.find((rule) =>
                    rule?.automated_actions?.find((action) => Number(action.id) === Number(automatedActionId))
                ) || null;
        } catch (error) {
            console.error(error);
            toasted.global.platform_error();

            automationRule.value = null;
        } finally {
            loadingAutomation.value = false;
        }
    };

    onMounted(async () => {
        await getDelivery();
        getRequest();
        getAuthConfig();
        getAutomation();
    });

    return {
        delivery,
        automationRule,
        authConfig,
        request,
    };
};
