<template>
    <div>
        <vx-label>Collected Data Type</vx-label>
        <vx-selectable size="lg"
            v-model="data.data_type"
            :options="['email']"
            :clearable="false">
        </vx-selectable>
    </div>
</template>

<script setup>
import { VxSelectable, VxLabel } from '@voxie/frontend-components';
const data = defineModel();

if (data.value.data_type == undefined) {
    data.value.data_type = 'email';
}
</script>

