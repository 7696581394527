<template>
    <div>
        <vx-expandable-card>
            <template v-slot:header>
                <div class="vx-flex vx-items-center">
                    <span>SSO Configuration</span>
                    <experimental-pill class="vx-ml-2"></experimental-pill>
                </div>
            </template>
            <template v-slot:content>
               <div class="vx-flex vx-flex-col vx-items-start vx-gap-2 sm:vx-flex-row sm:vx-items-center sm:vx-gap-8">
                    <span class="vx-text-sm">
                        Require all non-owner users to login via single sign-on, across all teams associated with this brand.
                    </span>
                    <vx-toggle size="sm" :modelValue="isUsingSingleSignOn" @update:modelValue="(requireSso) => {
                        updateConfig(requireSso);
                    }" :loading="loading" />
               </div>
            </template>
        </vx-expandable-card>
    </div>
</template>
<script setup>
import { VxExpandableCard, VxToggle } from '@voxie/frontend-components';
import { singleSignOnClient } from '../../../../../services';
import { ref } from 'vue';
import { useToasted } from '../../../../../composables/useToasted';
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';

const $toasted = useToasted();

const props = defineProps({
    team: {
        type: Object,
        required: true,
    },
});

const isUsingSingleSignOn = ref(false);
const loading = ref(false);

const getConfig = async () => {
    if (loading.value) {
        return;
    }

    loading.value = true;

    try {
        const response = await singleSignOnClient.getConfig(props.team.id);
        isUsingSingleSignOn.value = !!response?.data?.require_sso;
    } catch (e) {
        console.error(e);
        $toasted.global.platform_error();
    } finally {
        loading.value = false;
    }
};

const updateConfig = async (requireSso) => {
    if (loading.value) {
        return;
    }

    loading.value = true;
    isUsingSingleSignOn.value = requireSso;

    try {
        await singleSignOnClient.updateConfig(props.team.id, { require_sso: requireSso });
    } catch (e) {
        console.error(e);
        $toasted.global.platform_error();

        isUsingSingleSignOn.value = !requireSso;
    } finally {
        loading.value = false;
    }
};

getConfig();

</script>
