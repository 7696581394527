<template>
    <vx-selectable placeholder="data type"
        label="label"
        :getOptionLabel="(option) => option.label.toLowerCase()"
        :modelValue="props.modelValue"
        :options="dataTypes"
        @update:modelValue="emit('update:modelValue', $event)"
        :reduce="dataType => dataType.value"
        data-test="data-type"
        :clearable="false">
        </vx-selectable>
</template>

<script setup>
import { VxSelectable } from '@voxie/frontend-components';
import { computed, nextTick, watch } from 'vue';

const base_data_types = [
    { value: "text", label: "Text" },
    { value: "number", label: "Numeric" },
    { value: "date", label: "Date" },
];

const props = defineProps({
    filter: {
        type: Object,
        required: true,
    },
    modelValue: {
        required: true,
    },
})

const dataTypes = computed(() => {
    switch (props.filter.attribute_name) {
        case 'contacts.custom_attributes.value':
        case 'contacts.custom_attributes.key_value':
        case 'contacts.custom_attributes.exclude_key_value':
            return base_data_types;
        case 'contacts.created_at':
        case 'contacts.updated_at':
        case 'contacts.custom_attributes.created_at':
        case 'contacts.custom_attributes.updated_at':
            return [{ value: "date", label: "Date" }];
        case 'contacts.phone':
            return [{ value: "text", label: "Phone" }];
        case 'contacts.email':
            return [{ value: "text", label: "Email" }];
        default:
            return [{ value: "text", label: "Text" }];
    }
});

const emit = defineEmits(['update:modelValue', 'lengthChanged']);

watch(() => props.filter.data_type, async () => {
    await nextTick();

    if (!props.modelValue) {
        emit('update:modelValue', dataTypes.value[0].value)
    }
}, {
    immediate: true, // act as onMounted
});

watch(dataTypes, (dataTypes) => {
    emit('lengthChanged', dataTypes.length)
}, {
    immediate: true,
});

</script>
