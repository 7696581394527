<template>
    <vx-expandable-card id="subscriptions">
        <template v-slot:header> Subscription Status </template>
        <template v-slot:headerSubtitle> The contact’s preference for the type of messages they wish to receive.
        </template>

        <template v-slot:content>
            <vx-icon-loading v-if="loadingSubscriptions"
                spin></vx-icon-loading>
            <div v-else
                class="vx-flex vx-flex-col vx-gap-4 lg:vx-gap-8 lg:vx-grid lg:vx-grid-cols-2">
                <div data-test="marketing-wrapper">
                    <vx-label>Marketing</vx-label>
                    <vx-radio-selection v-model="marketingStatus">
                        <vx-radio-selection-item value="unknown" :disabled="existingMarketingStatus !== 'unknown'">No Preference</vx-radio-selection-item>
                        <vx-radio-selection-item value="opt_in">Opt-in</vx-radio-selection-item>
                        <vx-radio-selection-item value="opt_out">Opt-out</vx-radio-selection-item>
                    </vx-radio-selection>
                </div>
                <div data-test="transactional-wrapper">
                    <vx-label>Transactional</vx-label>
                    <vx-radio-selection v-model="transactionalStatus">
                        <vx-radio-selection-item value="unknown" :disabled="existingTransactionalStatus !== 'unknown'">No Preference</vx-radio-selection-item>
                        <vx-radio-selection-item value="opt_in">Opt-in</vx-radio-selection-item>
                        <vx-radio-selection-item value="opt_out">Opt-out</vx-radio-selection-item>
                    </vx-radio-selection>
                </div>
            </div>
        </template>
    </vx-expandable-card>
</template>

<script setup>
import { VxExpandableCard, VxIconLoading, VxLabel, VxRadioSelection, VxRadioSelectionItem } from '@voxie/frontend-components';
import { useContactDetails } from '../useContactDetails';

const {
    loadingSubscriptions,
    marketingStatus,
    transactionalStatus,
    existingMarketingStatus,
    existingTransactionalStatus,
} = useContactDetails();
</script>
