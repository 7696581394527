export default {
    methods: {
        /**
         * Scroll page to element selector w/ duration
         */
        scrollToElement(element, duration = 1000, offset = -90) {
            const $elem = $(element);
            if ($elem.length) {
                $('html, body').animate(
                    {
                        scrollTop: $elem.offset().top + offset,
                    },
                    duration
                );
            }
        },

        scrollElementToElement(inSelector, toSelector, duration = 1000, offset = -90) {
            $(inSelector).animate(
                {
                    scrollTop: $(toSelector).offset().top + offset,
                },
                duration
            );
        },

        /**
         * Scroll to the very top of the page
         */
        scrollToPageTop() {
            this.scrollToElement('#spark-app');
        },
    },
    mounted() {
        $(window).on('scroll wheel touchstart keyup', function () {
            $('html, body').stop();
        });
    },
};
