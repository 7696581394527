<template>
    <div class="vx-font-sans vx-bg-white vx-shadow vx-border vx-border-solid vx-border-white vx-rounded-[16px] vx-flex vx-flex-col"
        data-test="flow-row">
        <div class="vx-flex vx-py-2 vx-items-center vx-px-4 vx-border-b vx-border-x-0 vx-border-t-0 vx-border-solid vx-rounded-t-[16px] vx-relative vx-justify-between"
            :class="{
                'vx-bg-teal-50 vx-border-teal-100': props.modelValue.status === 'active',
                'vx-bg-slate-50 vx-border-slate-100': props.modelValue.status !== 'active',
            }">
            <div>
                <vx-badge :borders="false"
                    :color="(props.modelValue.status === 'draft' && 'amber') ||
                        (props.modelValue.status === 'paused' && 'danger') ||
                        (props.modelValue.status === 'active' && 'success')
                        "
                    data-test="flow-status">
                    <template v-if="props.modelValue.status === 'draft'">Draft</template>
                    <template v-else-if="props.modelValue.status === 'paused'">Paused</template>
                    <template v-else-if="props.modelValue.status === 'active'">Live</template>
                </vx-badge>

                <template v-if="props.modelValue.playbook">
                    <vx-button v-if="props.modelValue.status == 'draft' || props.modelValue.status == 'paused'"
                        color="transparent"
                        size="sm"
                        @click="emit('launch', props.modelValue.flow_id)"
                        data-test="launch">
                        Launch
                        <font-awesome-icon :icon="faRocketLaunch"></font-awesome-icon>
                    </vx-button>

                    <vx-button v-else-if="props.modelValue.status === 'active'"
                        color="transparent"
                        size="sm"
                        @click="emit('pause', props.modelValue.flow_id)"
                        data-test="pause">
                        Pause
                        <font-awesome-icon :icon="faPause"></font-awesome-icon>
                    </vx-button>
                </template>
            </div>
            <div class="vx-flex vx-items-center vx-gap-6">
                <div class="vx-text-xs vx-font-semibold"
                    :class="{
                        'vx-text-teal-500 ': props.modelValue.status === 'active',
                        'vx-hidden sm:vx-block': Boolean(props.modelValue.playbook),
                    }">
                    {{ props.modelValue.playbook ? 'Playbook' : 'Custom Flow' }}
                </div>
                <div v-if="props.modelValue.playbook"
                    class="vx-flex vx-items-center vx-gap-6">
                    <div v-for="index in 4"
                        :key="index"
                        class="-vx-translate-y-3  vx-flex-col vx-items-center -vx-space-y-2"
                        :class="{
                            'vx-hidden sm:vx-flex': index === 3,
                            'vx-flex': index !== 3,
                        }">
                        <div
                            class="vx-relative vx-h-9 vx-w-[4px] vx-rounded-full vx-bg-gradient-to-b vx-from-slate-50 vx-to-slate-600 vx-shadow-[0_2px_5px_#00000022]">
                        </div>
                        <div
                            class="vx-aspect-square vx-w-2.5 vx-rounded-full vx-bg-slate-500 vx-shadow-[0_1px_2px_#000000bb_inset]">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="vx-px-6 vx-py-6 vx-flex vx-flex-col vx-rounded-b-[16px] vx-relative vx-gap-4 vx-flex-grow lg:vx-flex-row lg:vx-items-center lg:vx-py-4">
            <div class="">
                <h3 class="vx-block vx-text-lg vx-font-bold vx-text-slate-800 vx-truncate vx-leading-normal vx-m-0"
                    data-test="flow-name">
                    {{ props.modelValue.name }}
                </h3>
                <div class="vx-text-xs vx-text-slate-500">
                    <div class="vx-line-clamp-3 lg:vx-line-clamp-2">
                        {{ props.modelValue.notes }}
                    </div>
                    <div v-if="props.modelValue.playbook"
                        class="vx-mt-2 xl:vx-mt-1">
                        <strong>Playbook:</strong> {{ playbookName(props.modelValue.playbook) }}
                    </div>
                </div>
            </div>
            <div v-if="props.modelValue.playbook"
                class="vx-w-40 vx-mx-auto vx-shrink-0 lg:vx-mx-0 lg:vx-w-32">
                <component v-if="playbookArt(props.modelValue.playbook)"
                    :is="playbookArt(props.modelValue.playbook)"></component>
            </div>

            <div class="vx-flex vx-gap-2 vx-mt-auto lg:vx-my-0 lg:vx-ml-auto lg:vx-grow-0">
                <vx-dropdown teleport
                    placement="left">
                    <vx-button class="vx-w-11"
                        type="button"
                        color="muted"
                        outline
                        size="md">
                        <font-awesome-icon :icon="faEllipsis" />
                    </vx-button>

                    <template v-slot:items>
                        <vx-dropdown-item @click.prevent="removeModal = true"
                            type="button"
                            data-test="remove-flow">
                            Delete <font-awesome-icon :icon="faTrashCan"></font-awesome-icon>
                        </vx-dropdown-item>
                    </template>
                </vx-dropdown>

                <vx-button color="muted"
                    :outline="true"
                    :href="'/automation/flows/' + props.modelValue.flow_id + (!props.modelValue.playbook ? '/resources' : '')"
                    class="vx-grow">
                    Edit
                    <font-awesome-icon :icon="faFilePen"></font-awesome-icon>
                </vx-button>
            </div>
        </div>

        <flow-delete v-model:visible="removeModal" :flowId="props.modelValue.flow_id" @deleted="emit('deleted')"></flow-delete>
    </div>
</template>

<script setup>
import {
    faEllipsis, faFilePen,
    faPause,
    faRocketLaunch,
    faTrashCan
} from '@fortawesome/pro-solid-svg-icons';
import { VxBadge, VxButton, VxDropdown, VxDropdownItem } from '@voxie/frontend-components';
import { playbookArt, playbookName } from './playbook/playbook';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FlowDelete from './FlowDelete.vue';
import { ref } from 'vue';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
});

const removeModal = ref(false);

const emit = defineEmits(['launch', 'pause', 'deleted']);
</script>
