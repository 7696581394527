<template>
    <vx-date-picker :mode="props.mode"
        class="!vx-p-0"
        :timezone="timezone"
        :minDate="minDate"
        :model-value="date"
        :rules="rules"
        @update:model-value="emitModelChange($event)"
    >
    </vx-date-picker>
</template>

<script>
export default {
    name: 'date-time-picker',
};
</script>

<script setup>
import { VxDatePicker } from '@voxie/frontend-components';
import { computed, ref, watch } from 'vue';
import dayjs from '~/utils/dayjs';

const props = defineProps({
    modelValue: {
        default: null,
        required: true,
        validator(value) {
            return (
                value === null ||
                value instanceof Date ||
                typeof value === 'string' ||
                value instanceof dayjs
            );
        },
    },
    config: {
        type: Object,
        default: () => ({}),
    },
    mode: {
        default: 'dateTime',
    }
});

const timezone = computed(() => props.config?.timeZone);
const minDate = computed(() => props.config?.minDate?.toDate?.() || props.config?.minDate);
const format = computed(() => props.config?.format)
const rules = ref({});

watch(() => props.config?.stepping, () => {
    const stepping = props.config?.stepping;
    if (!stepping) {
        rules.value = undefined;
        return;
    }

    rules.value = {
        minutes: { interval: stepping },
    }
}, { immediate: true })

const date = computed(() => {
    if (props.modelValue === null || props.modelValue === undefined) {
        return undefined;
    } else if (typeof props.modelValue === 'string') {
        let parsed;

        if (timezone.value && format.value) {
            parsed = dayjs.tz(props.modelValue, format.value, timezone.value);
        } else if (format.value) {
            parsed = dayjs(props.modelValue, format.value);
        } else {
            parsed = dayjs(props.modelValue);
        }

        return parsed.isValid() ? parsed.toDate() : undefined;
    }

    return props.modelValue.toDate ? props.modelValue.toDate() : props.modelValue
})

const emit = defineEmits(['update:modelValue']);

const emitModelChange = (event) => {
    if (!event) {
        return;
    }

    if (timezone.value && format.value) {
        emit('update:modelValue', dayjs(event).tz(timezone.value).format(format.value));
    } else if (format.value) {
        emit('update:modelValue', dayjs(event).format(format.value));
    } else {
        emit('update:modelValue', event);
    }
}

</script>
