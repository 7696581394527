<template>
    <div v-if="flow" class="vx-max-w-screen-md vx-w-full vx-mb-2 md:vx-mb-8 vx-mx-auto">
        <vx-alert v-if="success && !editing"
            color="success"
            :closeable="false"
            shadow>
            <template v-slot:icon>
                <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
            </template>
            <div class="vx-flex vx-items-center vx-justify-between">
                <span><strong>{{ props.resourceName }}</strong> Created successfully</span>
                <vx-button :href="flowLink"
                    size="sm"
                    color="success">Back to Flow<font-awesome-icon
                        :icon="faSquareArrowUpLeft"></font-awesome-icon></vx-button>
            </div>
        </vx-alert>
        <vx-alert v-else-if="success && editing"
            color="success"
            :closeable="false"
            shadow>
            <template v-slot:icon>
                <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
            </template>
            <div class="vx-flex vx-items-center vx-justify-between">
                <span>Updated successfully</span>
                <vx-button :href="flowLink"
                    size="sm"
                    color="success">Back to Flow<font-awesome-icon
                        :icon="faSquareArrowUpLeft"></font-awesome-icon></vx-button>
            </div>
        </vx-alert>
        <vx-alert v-else-if="!editing"
            color="info"
            :closeable="false"
            shadow>
            <template v-slot:icon>
                <font-awesome-icon :icon="faSlidersSimple"></font-awesome-icon>
            </template>
            <div class="vx-flex vx-items-center vx-justify-between">
                <span><strong>Creating in</strong> {{ flow.name }}</span>
                <vx-button :href="flowLink"
                    size="sm"
                    color="info">Cancel<font-awesome-icon :icon="faSquareArrowUpLeft"></font-awesome-icon></vx-button>
            </div>
        </vx-alert>
        <vx-alert v-else-if="!success && editing"
            color="slate"
            :closeable="false"
            shadow>
            <template v-slot:icon>
                <font-awesome-icon :icon="faSlidersSimple"></font-awesome-icon>
            </template>
            <div class="vx-flex vx-items-center vx-justify-between">
                <span><strong>Part of Flow:</strong> {{ flow.name }}</span>
                <vx-button :href="flowLink"
                    size="sm"
                    color="muted-dark">Back to Flow<font-awesome-icon
                        :icon="faSquareArrowUpLeft"></font-awesome-icon></vx-button>
            </div>
        </vx-alert>
    </div>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleCheck, faSlidersSimple, faSquareArrowUpLeft } from '@fortawesome/pro-solid-svg-icons';
import {
    VxAlert,
    VxButton
} from "@voxie/frontend-components";
import { onMounted, ref, computed } from "vue";
import flowsClient from "~/services/flowsClient";

const teamId = Spark.state.currentTeam.id;

const props = defineProps({
    flowId: {
        type: String,
        required: true,
    },
    flowSection: {
        type: Number,
    },
    success: Boolean,
    editing: Boolean,
    resourceName: String
})

const flow = ref();

onMounted(async () => {
    flow.value = (await flowsClient.get(teamId, props.flowId)).data;
});

const flowLink = computed(() => '/automation/flows/' + props.flowId + '/resources' + (props.flowSection !== undefined ? '?flow_section=' + props.flowSection : ''))
</script>
