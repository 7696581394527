import BaseClient from './BaseClient';

class SnippetsClient extends BaseClient {
    // paginated list
    getSnippets(teamId, params) {
        const url = `/api/v3/teams/${teamId}/snippets`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    getSnippetsCount(teamId, params) {
        const url = `/api/v3/teams/${teamId}/snippets/count`;
        return this.makeCoreApiRequest(url, 'get', null, params);
    }

    createSnippet(teamId, data) {
        const url = `/api/v3/teams/${teamId}/snippets`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    updateSnippet(teamId, snippetId, data) {
        const url = `/api/v3/teams/${teamId}/snippets/${snippetId}`;
        return this.makeCoreApiRequest(url, 'put', data);
    }

    deleteSnippet(teamId, snippetId) {
        const url = `/api/v3/teams/${teamId}/snippets/${snippetId}`;
        return this.makeCoreApiRequest(url, 'delete');
    }
}

export default new SnippetsClient();
