/**
 * Initialize the Spark form extension points.
 */
Spark.forms = {
    register: {},
    updateContactInformation: {},
    updateTeamMember: {},
};

/**
 * Load the SparkForm helper class.
 */
import './form';

/**
 * Define the SparkFormError collection class.
 */
import './errors';

/**
 * Add additional HTTP / form helpers to the Spark object.
 */
import http from './http';
$.extend(Spark, http);
