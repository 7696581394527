<template>
    <div ref="filterDropdownRef" class="threads-actions-filter-drop-down-v2 actions dropdown">
        <div ref="outsideElementRef" class="outside-element"></div>
        <vx-icon
            name="filter-filled"
            :size="16"
            class="threads-actions-filter-drop-down-v2__filter-icon action-button dropdown-toggle no-border-mobile"
            :class="{ 'threads-actions-filter-drop-down-v2__filter-icon-active': isFilteringEnabled }"
            id="filtersDropDown"
            data-display="static"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            @click="onFilterIconClick"
        ></vx-icon>
        <modal-dropdown-menu
            id="filterDropdown"
            extra-class="dropdown-filters threads-actions-filter-drop-down-v2__filters"
            modal-title="Filter"
            submit-btn-text="Apply"
            @submit="applyFilters"
            @reset="resetFilters"
        >
            <form>
                <h5 class="d-none d-sm-block font-weight-bold small text-uppercase text-gray-500">Filter Builder</h5>
                <template v-if="FEATURES.simple_message_hub">
                    <div class="form-group pt-2">
                        <label for="status" class="text-sm tracking-wide text-gray-800 mb-1">Unread Status</label>
                        <v-select
                            v-model="status"
                            id="status"
                            placeholder="Search..."
                            label="label"
                            :options="statuses"
                        />
                    </div>
                </template>
                <template v-else>
                    <div v-if="!['unassigned', 'me'].includes(activeThreadsInbox)" class="form-group pt-2">
                        <label for="filterAssignees" class="text-sm tracking-wide text-gray-800 mb-1">Assignee</label>
                        <v-select
                            v-model="filterAssignees"
                            id="filterAssignees"
                            placeholder="Search..."
                            label="name"
                            :options="agents"
                        />
                    </div>
                    <div class="form-group">
                        <label for="filterCampaigns" class="text-sm tracking-wide text-gray-800 mb-1">Campaign</label>
                        <v-select
                            v-model="filterCampaigns"
                            id="filterCampaigns"
                            placeholder="Search..."
                            :options="campaigns"
                            @search="searchCampaigns"
                        />
                    </div>
                    <div class="form-group pt-2">
                        <label for="filterTags" class="text-sm tracking-wide text-gray-800 mb-1">Tag</label>
                        <v-select
                            v-model="filterTags"
                            id="filterTags"
                            placeholder="Search..."
                            label="tag_name"
                            :options="tags"
                            @search="searchTags"
                        />
                    </div>
                </template>
            </form>
        </modal-dropdown-menu>
    </div>
</template>

<script setup>
import { ref, computed, watch, inject } from 'vue';
import { useStore } from 'vuex';
import ModalDropdownMenu from '../../../general/ModalDropdownMenu.vue';
import VSelect from 'vue-select';
import { VxIcon } from '@voxie/frontend-components-v3';
import debounce from '~/utils/debounce';
import tagsClient from '../../../../services/tagsClient';
import campaignsClient from '../../../../services/campaignsClient';

defineProps({
    isFilteringEnabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['applyFilters']);
const FEATURES = inject('FEATURES');
const store = useStore();

const teamId = Spark.state.currentTeam.id;

const outsideElementRef = ref();
const filterDropdownRef = ref();

const filterAssignees = ref(null);
const filterTags = ref(null);
const filterCampaigns = ref(null);
const status = ref(null);

const campaigns = ref([]);
const tags = ref([]);

const agents = computed(() => store.state.threads.agents);
const activeThreadsInbox = computed(() => store.state.threads.activeThreadsInbox);

const statuses = [
    {
        label: 'Unread - Oldest on Top',
        value: {
            status: 'open',
            forward: 'true',
        },
    },
    {
        label: 'Unread - Newest on Top',
        value: {
            status: 'open',
            forward: 'false',
        },
    }
]

const filterClear = () => {
    filterAssignees.value = null;
    filterTags.value = null;
    filterCampaigns.value = null;
    status.value = null;
};

const applyFilters = () => {
    if (FEATURES.simple_message_hub) {
        emit('applyFilters', {
            forward: status.value?.value?.forward || 'false',
            status: status.value?.value?.status || null,
        });
    } else {
        emit('applyFilters', {
            assignee: filterAssignees.value?.id || null,
            tag: filterTags.value?.tag_name.toLowerCase() || null,
            campaign: filterCampaigns.value?.id || null,
        });
    }
};

const resetFilters = () => {
    filterClear();
    applyFilters();
}

watch(activeThreadsInbox, filterClear);

// Insure elements relying on outside click can handle their callbacks
const onFilterIconClick = () => {
    if (!filterDropdownRef.value.classList.contains('show')) {
        outsideElementRef.value.click();
    }
};

const searchTags = debounce((search, loading) => {
    loading?.(true)

    tagsClient.tagsGetAll(teamId, { query: search || null, per_page: 15 })
        .then(response => {
            tags.value = response.data.data;
        })
        .finally(() => {
            loading?.(false);
        })
}, 300);

searchTags();

const searchCampaigns = debounce((search, loading) => {
    loading?.(true)

    campaignsClient.campaignsGetRecords(teamId, { query: search || null, per_page: 15 })
        .then(response => {
            campaigns.value = response.data.data.map((option) => {
                return {
                    ...option,
                    label: `${option.id} - ${option.name}`
                }
            });
        })
        .finally(() => {
            loading?.(false);
        })
}, 300);

searchCampaigns();

</script>

<style lang="postcss">
.threads-actions-filter-drop-down-v2 .outside-element {
  display: none;
}
.threads-actions-filter-drop-down-v2 form {
  font-family: var(--font-open-sans);
}
.threads-actions-filter-drop-down-v2 form h5 {
  font-family: inherit;
}
.threads-actions-filter-drop-down-v2 form .vs__selected {
  font-size: 14px;
}
.threads-actions-filter-drop-down-v2 form .vs__dropdown-option,
.threads-actions-filter-drop-down-v2 form .vs__no-options {
  font-family: var(--font-open-sans);
  font-size: 14px;
}
.threads-actions-filter-drop-down-v2__filter-icon {
  display: table-row;
  color: var(--fc-color-gray-40);
  cursor: pointer;
}
.threads-actions-filter-drop-down-v2__filter-icon:hover {
  color: var(--primary);
  opacity: 0.8;
}
.threads-actions-filter-drop-down-v2__filter-icon-active svg {
  fill: var(--primary);
}
.threads-actions-filter-drop-down-v2__filters {
  margin-top: 0px;
  right: 0px;
  border-radius: var(--radius-sm) !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
}
@media (min-width: 576px) {
  .threads-actions-filter-drop-down-v2__filters {
    margin-top: 6px;
    right: -9px;
  }
}
.threads-actions-filter-drop-down-v2 .vs__actions {
  display: flex;
  gap: 8px;
}
.threads-actions-filter-drop-down-v2 .vs__actions .vs__clear {
  margin-right: 0;
  transform: translateY(-1px);
}
</style>
