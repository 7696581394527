<template>
    <vx-badge class="contact-status" :color="contactStatusBadgeColorV2(status)">
        {{ contactStatusLabelV2(status) }}
    </vx-badge>
</template>

<script setup>
import { contactStatusBadgeColorV2, contactStatusLabelV2 } from '../../../utils/statuses-mappers-v2';
import { VxBadge } from '@voxie/frontend-components-v3';
import { computed } from 'vue';

const props = defineProps({
    subscriptions: {
        type: Array,
        required: true,
    },
});

const status = computed(() => {
    for (let i = 0; i < props.subscriptions.length; i++) {
        if (props.subscriptions[i].type === 'marketing') {
            return props.subscriptions[i].status
        }
    }

    return 'unknown'
})
</script>

<style lang="postcss">
.contact-status {
  border-radius: 10px;
  padding: 2px 10px;
  font-weight: var(--weight-regular);
  font-size: 0.75rem;
}
</style>
