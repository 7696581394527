<template>
    <div class="vx-flex vx-w-full"
        :class="{
        }"
        @dragenter="dragEnter"
        @dragleave="dragLeave"
        @dragover="dragOver"
        @dragend="dragEnd">
        <div class="vx-pt-5 vx-pr-1 vx-shrink-0">
            <vx-button color="transparent"
                size="sm"
                type="button"
                class="!vx-cursor-move section-handle">
                <font-awesome-icon :icon="faGripDotsVertical"></font-awesome-icon>
            </vx-button>
        </div>
        <div class="vx-px-6 vx-py-4 vx-rounded-2xl vx-border-b-solid vx-border-b vx-border-white vx-flex vx-grow vx-flex-col vx-transition-all"
            :class="{
                'vx-bg-slate-300 vx-bg-opacity-30 vx-shadow-inner': props.open,
                'vx-bg-white vx-shadow': !props.open,
            }">
            <div class="vx-flex vx-items-center vx-justify-between vx-gap-4">
                <div class="vx-flex vx-items-center vx-text-slate-800">
                    <font-awesome-icon :icon="faBookmark"></font-awesome-icon>
                    <div class="vx-ml-3 vx-mr-4 vx-font-extrabold">{{ props.section.name }}</div>
                    <!-- hard coded name until renaming is enabled -->
                    <vx-badge color="slate"
                        size="sm">{{ props.section.resources?.length || 0 }}</vx-badge>
                </div>
                <div class="vx-grow">
                    <div class="vx-h-0.5 vx-bg-amber-400 vx-rounded vx-w-full vx-opacity-0 vx-transition"
                        :class="{
                            'vx-opacity-100': over && !props.open && resourceDragging.oldSectionIndex != null && resourceDragging.oldSectionIndex !== props.flowSection
                        }"></div>
                </div>
                <div class="vx-flex vx-gap-2">
                    <vx-dropdown>
                        <vx-button type="button"
                            size="md"
                            outline
                            color="muted-dark">
                            <font-awesome-icon :icon="faEllipsis"></font-awesome-icon>
                        </vx-button>

                        <template v-slot:items>
                            <vx-dropdown-item @click.prevent="rename = true"
                                type="button">
                                Rename <font-awesome-icon :icon="faPenField"></font-awesome-icon>
                            </vx-dropdown-item>
                            <vx-popover class="vx-w-full"
                                hover
                                :arrow="true"
                                placement="top">
                                <vx-dropdown-item @click.prevent="removeSection"
                                    :disabled="sections.length === 1"
                                    type="button"
                                    data-test="remove-section">
                                    Delete <font-awesome-icon :icon="faTrashCan"></font-awesome-icon>
                                </vx-dropdown-item>
                                <template v-if="sections.length === 1" v-slot:content>
                                    At least one section is required.
                                </template>
                            </vx-popover>
                        </template>
                    </vx-dropdown>
                    <vx-button @click="emit('update:open', !props.open)"
                        data-test="open-resources"
                        type="button"
                        size="md"
                        color="muted-dark">
                        <font-awesome-icon :icon="props.open ? faChevronUp : faChevronDown"></font-awesome-icon>
                    </vx-button>
                </div>
            </div>

            <div v-if="!props.open && props.section.resources.length"
                class="vx-mt-4 vx-flex vx-items-center">
                <div v-for="(resource, resourceIndex) in props.section.resources"
                    :key="'bar.' + resource.type + '.' + resource.id"
                    :style="{ width: `calc(${( 1 / props.section.resources.length)*100}% + 4px)` }"
                    class="vx-h-3 vx-border-2 vx-border-white vx-border-solid vx-rounded-sm"
                    :class="{
                        'vx-bg-amber-300': resource.type === 'automation',
                        'vx-bg-sky-300': resource.type === 'campaign',
                        'vx-bg-violet-300': resource.type === 'segment',
                        '-vx-ml-1': resourceIndex !== 0,
                    }"
                    >
                </div>
            </div>

            <animate-height :height="props.open ? 'auto' : 0">
                <div class="vx-mt-4 vx-space-y-4 vx-pr-2">
                    <div v-if="!props.section.resources.length"
                        class="vx-text-black vx-pt-11 vx-px-4 vx-bg-slate-200 vx-rounded-2xl vx-text-center vx-border vx-border-slate-400 vx-justify-between vx-items-center vx-flex-col vx-flex vx-border-dashed vx-gap-4">
                        <font-awesome-icon class="vx-text-2xl"
                            :icon="faSquareDashedCirclePlus"></font-awesome-icon>
                        <div>Drag items to this section.</div>
                        <div class="vx-mb-6 vx-mt-2 vx-h-0.5 vx-bg-amber-400 vx-rounded vx-w-full vx-opacity-0 vx-transition"
                            :class="{
                                'vx-opacity-100': over && resourceDragging.oldSectionIndex != null && resourceDragging.oldSectionIndex !== props.flowSection
                            }"></div>
                    </div>
                    <draggable v-else
                        tag="div"
                        :itemKey="resource => resource.type + '.' + resource.id"
                        handle=".resource-handle"
                        :emptyInsertThreshold="100"
                        :animation="200"
                        :group="{ name: 'resources' }"
                        :list="props.section.resources"
                        class="vx-space-y-4"
                        ghostClass="vx-opacity-30"
                        dragClass="resource-dragging"
                        @change="emit('draggable:change', $event)">

                        <template v-slot:item="{ element }">
                            <custom-flow-resource :resource="element"
                                :flowSection="props.flowSection"
                                @remove="removeResources = [element]"></custom-flow-resource>
                        </template>
                    </draggable>

                    <div
                        class="vx-pl-6 vx-pr-4 vx-py-4 vx-bg-slate-200 vx-rounded-2xl vx-border vx-border-slate-400 vx-justify-between vx-items-center vx-flex-col vx-flex vx-border-dashed vx-gap-2 lg:vx-flex-row">
                        <div class="vx-text-black">Add a resource:</div>
                        <div class="vx-flex vx-gap-2">
                            <vx-button @click="addResource = true"
                                type="button"
                                color="muted-dark">
                                Existing
                                <font-awesome-icon :icon="faMagnifyingGlass"></font-awesome-icon>
                            </vx-button>
                            <vx-dropdown>
                                <vx-button type="button"
                                    color="secondary">
                                    New
                                    <font-awesome-icon :icon="faCirclePlus"></font-awesome-icon>
                                </vx-button>
                                <template v-slot:items>
                                    <vx-dropdown-item
                                        :href="'/contacts/segments/new?flow_id=' + props.flowId + '&flow_section=' + props.flowSection"
                                        data-test="add-segment">
                                        Segment <font-awesome-icon :icon="faContactBook"></font-awesome-icon>
                                    </vx-dropdown-item>
                                    <vx-dropdown-item
                                        :href="'/messaging/campaigns/new?flow_id=' + props.flowId + '&flow_section=' + props.flowSection"
                                        data-test="add-campaign">
                                        Campaign <font-awesome-icon :icon="faMessages"></font-awesome-icon>
                                    </vx-dropdown-item>
                                    <vx-dropdown-item
                                        :href="'/automation/event/new?flow_id=' + props.flowId + '&flow_section=' + props.flowSection"
                                        data-test="add-automations">
                                        Automations <font-awesome-icon :icon="faRobot"></font-awesome-icon>
                                    </vx-dropdown-item>
                                </template>
                            </vx-dropdown>
                        </div>
                    </div>
                </div>
            </animate-height>
        </div>

        <custom-flow-resource-add v-model:visible="addResource"
            :flowSection="props.flowSection"></custom-flow-resource-add>
        <custom-flow-resource-remove :visible="!!removeResources.length"
            @update:visible="removeResources = []"
            @removed="() => {
                if (removingSection) {
                    removingSection = false;
                    emit('remove');
                }
            }"
            :resources="removeResources"></custom-flow-resource-remove>

        <custom-flow-section-rename v-if="sections[props.flowSection]" v-model:visible="rename" v-model="sections[props.flowSection].name"></custom-flow-section-rename>
    </div>
</template>

<script setup>

import { faBookmark, faChevronDown, faChevronUp, faCirclePlus, faContactBook, faEllipsis, faGripDotsVertical, faMagnifyingGlass, faMessages, faPenField, faRobot, faSquareDashedCirclePlus, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VxBadge, VxButton, VxDropdown, VxDropdownItem, VxPopover } from '@voxie/frontend-components';
import { ref, watch } from 'vue';
import AnimateHeight from 'vue-animate-height';
import Draggable from "vuedraggable";
import { useToasted } from '~/composables/useToasted';
import { HTTP_INTERNAL_SERVER_ERROR } from '~/support/HttpStatuses';
import CustomFlowResource from './CustomFlowResource.vue';
import CustomFlowResourceRemove from './CustomFlowResourceRemove.vue';
import CustomFlowSectionRename from './CustomFlowSectionRename.vue';
import CustomFlowResourceAdd from './custom-flow-resource-add/CustomFlowResourceAdd.vue';
import { useCustomFlow } from './useCustomFlow';

const props = defineProps({
    open: {
        type: Boolean,
        default: false,
    },
    flowId: {
        type: String,
        required: true,
    },
    section: {
        type: Object,
        required: true,
    },
    flowSection: {
        type: Number,
        required: true,
    },
});

const toasted = useToasted();

const emit = defineEmits(['remove', 'update:open', 'draggable:change']);

const rename = ref(false);

const over = ref(false);
const overCounter = ref(0);
const { resourceDragging, saveSections, sections } = useCustomFlow();

// custom drag handling for a resource between sections
watch(() => resourceDragging.oldSectionIndex, (oldSectionIndex) => {
    if (isNaN(oldSectionIndex)) {
        over.value = false;
        overCounter.value = 0;
    }
})

const dragEnter = () => {
    over.value = true
    overCounter.value++;
};

const dragLeave = () => {
    overCounter.value--;

    if (overCounter.value <= 0) {
        over.value = false;
    }
};

const dragOver = () => {
    resourceDragging.newSectionIndex = props.flowSection;
}

const dragEnd = async () => {
    const resource = resourceDragging.resource;
    const oldSectionIndex = resourceDragging.oldSectionIndex;
    const newSectionIndex = resourceDragging.newSectionIndex;

    if (!resource) {
        return;
    }

    if (oldSectionIndex === newSectionIndex || (sections.value[newSectionIndex].open && !props.section.resources.length)) {
        return;
    }

    sections.value = sections.value.map((section, sectionIndex) => {
        if (oldSectionIndex === sectionIndex) {
            section.resources = section.resources.filter(existing => !(existing.type === resource.type && existing.id === resource.id))
        } else if (newSectionIndex === sectionIndex) {
            section.resources.push(resource);
        }

        return section;
    })

    try {
        await saveSections(sections.value);
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
        } else {
            toasted.error(e.response?.data?.message || 'Something went wrong.');
        }
    }
}

const addResource = ref(false);
const removeResources = ref([]);

const removingSection = ref(false);
const removeSection = async () => {
    if (props.section.resources.length) {
        removingSection.value = true;
        removeResources.value = [...props.section.resources]
    } else {
        emit('remove');
    }
}
</script>
