import BaseClient from './BaseClient';
import axios from '~/services/axios';

class PromotionCouponsClient extends BaseClient {
    getPromotionCoupons(teamId, promotionId, params) {
        return this.makeCoreApiRequest(
            `/api/v3/teams/${teamId}/promotions/${promotionId}/promotion-coupons`,
            'get',
            null,
            params
        );
    }

    /**
     * Returns a pre-signed S3 URL to upload a CSV file with coupons.
     *
     * @param teamId
     * @param promotionId
     * @param fileName This name will be displayed to the used
     * @param tags
     * @returns {Promise<AxiosResponse<any>>}
     */
    generateImportUploadUrl(teamId, promotionId, fileName) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/promotions/${promotionId}/promotion-coupons/import/url`;

        return this.makeCoreApiRequest(url, method, {
            name: fileName,
        });
    }

    /**
     * Uploads a CSV file with coupons to be imported.
     *
     * @param preSignedS3Url
     * @param file
     * @param uploadProgressHandler
     * @returns {Promise<AxiosResponse<any>>}
     */
    importCsvFileToS3(preSignedS3Url, file, uploadProgressHandler) {
        return axios.put(preSignedS3Url, file, {
            headers: {
                'Content-Type': 'text/csv',
            },
            onUploadProgress: (progressEvent) => {
                uploadProgressHandler(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
        });
    }

    /**
     * Starts import process.
     *
     * @param teamId
     * @param promotionId
     * @param fileId
     * @returns {Promise<AxiosResponse<any>>}
     */
    importCoupons(teamId, promotionId, fileId) {
        const method = 'post';
        const url = `/api/v3/teams/${teamId}/promotions/${promotionId}/promotion-coupons/import/${fileId}`;

        return this.makeCoreApiRequest(url, method);
    }

    getPromotionCouponsImports(teamId, promotionId, params) {
        return this.makeCoreApiRequest(
            `/api/v3/teams/${teamId}/promotions/${promotionId}/promotion-coupons/import`,
            'get',
            null,
            params
        );
    }

    getAllocatedTotal(teamId, promotionId, params) {
        return this.makeCoreApiRequest(
            `/api/v3/teams/${teamId}/promotions/${promotionId}/promotion-coupons/allocated-total`,
            'get',
            null,
            params
        );
    }
}

export default new PromotionCouponsClient();
