<template>
    <vx-table-row class="vx-relative vx-pl-12">
        <vx-table-row-title>
            <div class="vx-w-2 sm:vx-w-3 vx-min-h-[30px] vx-mr-3 vx-rounded-full vx-absolute vx-left-4 vx-top-4 vx-bottom-4 md:vx-left-6" :class="status.color"></div>

            <div class="vx-text-sm vx-font-normal">
                {{ status.label }}
            </div>
        </vx-table-row-title>
        <vx-table-row-item>
            <template v-slot:label> Completed: </template>

            <vx-popover hover :arrow="true" placement="top" :key="createdAtKey">
                {{ fromNow(props.item.created_at) }}
                <template v-slot:content>
                    {{ shortDateTime(props.item.created_at) }}
                </template>
            </vx-popover>

        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Duration: </template>

            {{ formatDuration(props.item.duration_ms) }}

        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Attempt ID: </template>

            <vx-popover hover :arrow="true" placement="top">
                <vx-badge size="xs" color="slate">{{ props.item.attempt_id?.slice(-6) }}</vx-badge>
                <template v-slot:content>
                    {{ props.item.attempt_id }}
                </template>
            </vx-popover>
        </vx-table-row-item>

        <vx-table-row-buttons>
            <vx-button v-if="isResponseSupported" color="muted-light" size="sm" class="vx-col-span-12"
                @click="showResponse = !showResponse">
                Response
                <font-awesome-icon :icon="showResponse ? faCaretUp : faCaretDown" />
            </vx-button>
        </vx-table-row-buttons>
    </vx-table-row>

    <div class="vx-pl-12 md:vx-pl-16 vx-relative vx-col-span-full vx-py-1">
        <div
            v-if="nextItem || showResponse"
            class=" vx-w-px vx-absolute vx-top-0 vx-left-5 vx-ml-px sm:vx-ml-0 sm:vx-left-6 md:vx-left-8"
            :class="[
                !nextItem || status.code === nextItemStatus?.code || !showResponse ? status.lineColor : status.gradient,
                {
                    'vx-h-full': nextItem,
                    'vx-h-10': !nextItem,
                    'vx-opacity-0': !nextItem && !showResponse,
                    'vx-transition': !nextItem
                }
            ]"
        ></div>

        <div class="vx-absolute vx-w-5 vx-h-5 vx-left-[11px] vx-top-10 vx-rounded-full vx-border-4 vx-border-solid vx-border-slate-200 md:vx-left-[22px] sm:vx-left-[14px] vx-transition" :class="[status.lineColor, {'vx-opacity-0': !nextItem && !showResponse}]"></div>

        <animate-height :height="showResponse ? 'auto' : 0" :animate-opacity="true">
            <api-delivery-response-card :response="response" class="vx-my-2"></api-delivery-response-card>
        </animate-height>
    </div>
</template>

<script setup>
import {
    faCaretDown,
    faCaretUp
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxButton,
    VxTableRow,
    VxTableRowItem,
    VxTableRowButtons,
    VxBadge,
    VxPopover,
VxTableRowTitle,
} from "@voxie/frontend-components";
import { useInterval } from '@vueuse/core';
import { fromNow, shortDateTime, formatDuration } from '../../../../../../../utils/date';
import { computed, ref, watch } from "vue";
import customDeliveriesClient from '../../../../../../../services/customDeliveriesClient';
import ApiDeliveryResponseCard from "../cards/ApiDeliveryResponseCard.vue";
import AnimateHeight from "vue-animate-height";

const teamId = Spark.state.currentTeam.id;

const statuses = [
    {
        color: 'vx-bg-sky-200',
        lineColor: 'vx-bg-sky-400',
        gradient: 'vx-bg-gradient-to-b vx-from-sky-400 vx-from-40% vx-via-indigo-400 vx-to-rose-400',
        label: 'Success',
        code: null
    },
    {
        color: 'vx-bg-rose-400',
        lineColor: 'vx-bg-rose-400',
        gradient: 'vx-bg-gradient-to-b vx-from-rose-400 vx-from-40% vx-via-indigo-400 vx-to-sky-400',
        label: 'Redirection Error',
        code: 'redirection_error'
    },
    {
        color: 'vx-bg-rose-400',
        lineColor: 'vx-bg-rose-400',
        gradient: 'vx-bg-gradient-to-b vx-from-rose-400 vx-from-40% vx-via-indigo-400 vx-to-sky-400',
        label: 'Too Many Requests Error',
        code: 'too_many_requests_error'
    },
    {
        color: 'vx-bg-rose-400',
        lineColor: 'vx-bg-rose-400',
        gradient: 'vx-bg-gradient-to-b vx-from-rose-400 vx-from-40% vx-via-indigo-400 vx-to-sky-400',
        label: 'Client Error',
        code: 'client_error'
    },
    {
        color: 'vx-bg-rose-400',
        lineColor: 'vx-bg-rose-400',
        gradient: 'vx-bg-gradient-to-b vx-from-rose-400 vx-from-40% vx-via-indigo-400 vx-to-sky-400',
        label: 'Server Error',
        code: 'server_error'
    },
    {
        color: 'vx-bg-rose-400',
        lineColor: 'vx-bg-rose-400',
        gradient: 'vx-bg-gradient-to-b vx-from-rose-400 vx-from-40% vx-via-indigo-400 vx-to-sky-400',
        label: 'Network Error',
        code: 'network_error'
    },
    {
        color: 'vx-bg-rose-400',
        lineColor: 'vx-bg-rose-400',
        gradient: 'vx-bg-gradient-to-b vx-from-rose-400 vx-from-40% vx-via-indigo-400 vx-to-sky-400',
        label: 'Timeout Error',
        code: 'timeout_error'
    },
    {
        color: 'vx-bg-rose-400',
        lineColor: 'vx-bg-rose-400',
        gradient: 'vx-bg-gradient-to-b vx-from-rose-400 vx-from-40% vx-via-indigo-400 vx-to-sky-400',
        label: 'Auth Config Error',
        code: 'auth_config_error'
    },
    {
        color: 'vx-bg-rose-400',
        lineColor: 'vx-bg-rose-400',
        gradient: 'vx-bg-gradient-to-b vx-from-rose-400 vx-from-40% vx-via-indigo-400 vx-to-sky-400',
        label: 'Internal Error',
        code: 'internal_error'
    },
];

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    nextItem: {
        type: Object,
        required: false,
    }
});

const status = computed(() => statuses.find((s) => s.code === props.item.reason_code) || {
    color: 'vx-bg-slate-200',
    lineColor: 'vx-bg-slate-400',
    gradient: 'vx-bg-slate-400',
    label: 'Unknown',
    code: props.item?.reason_code || `unknown`
});

const nextItemStatus = computed(() => statuses.find((s) => s.code === props.nextItem?.reason_code) || null);

const createdAtKey = useInterval(60000);

const isResponseSupported = computed(() => [null, 'redirection_error', 'client_error', 'server_error'].includes(props.item.reason_code));
const showResponse = ref(false);
const response = ref(undefined);
const responseLoading = ref(false);

const loadResponse = async () => {
    if (responseLoading.value || !isResponseSupported.value) {
        return;
    }

    responseLoading.value = true;
    try {
        response.value = (await customDeliveriesClient.getAttemptResponse(
            teamId,
            props.item.origin_type,
            props.item.origin_id,
            props.item.delivery_id,
            props.item.attempt_id
        )).data;
    } catch (error) {
        console.error(error);
        response.value = null;
    } finally {
        responseLoading.value = false;
    }
}

watch(() => showResponse.value, (newVal) => {
    if (newVal && response.value === undefined) {
        // delay or it will a layout shift will happen as the response card is animated in
        setTimeout(() => {
            loadResponse();
        }, 300);
    }
});

</script>
