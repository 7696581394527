<template>
  <vx-box
    color="white"
    :disabled="!hasValue && !props.loading"
    class="vx-flex vx-flex-col vx-justify-between vx-p-6 vx-relative"
  >
    <div v-if="$slots.title || $slots.subtitle" class="vx-pb-3">
      <div class="vx-flex vx-justify-between vx-items-center"
       :class="hasValue || props.loading ? 'vx-text-slate-800' : 'vx-text-slate-500'"
      >
        <span class="vx-text-base vx-font-bold vx-truncate" :title="$slots.title() && getSlotChildrenText($slots.title())">
            <slot name="title"></slot>
        </span>
        <experimental-pill subject="Beta Feedback - Analytics"></experimental-pill>
    </div>
      <div class="vx-text-xs vx-leading-none vx-text-slate-400">
        <template v-if="props.loading">
          Loading...
        </template>
        <template v-else-if="!props.loading && !hasValue && !props.error">
          No data yet
        </template>
        <template v-else-if="!props.loading && props.error">
          Error
        </template>
        <slot v-else-if="$slots.subtitle" name="subtitle"></slot>
      </div>
    </div>

    <div class="vx-flex vx-items-center vx-justify-start vx-gap-3 vx-transition vx-delay-150 vx-duration-300 vx-will-change-transform"
      :class="{
        'vx-pointer-events-none vx-opacity-0 motion-safe:vx-translate-y-4': !hasValue || props.loading || props.error
      }"
    >
      <span @click="copyValue()" class="vx-min-h-[36px] vx-cursor-pointer vx-text-3xl vx-font-light vx-text-slate-600" data-test="number" :title="`Click to copy - ${props.value}`">
        <slot></slot>
      </span>
      <div v-if="hasPercentage"
        class="vx-flex vx-items-center vx-gap-1 vx-text-3xl leading-9 vx-font-normal" :class="colorClass"
        data-test="percentage">
        <template v-if="percentageFormated === '0.00%'">
            <div class="vx-inline-block vx-w-5 vx-h-0.5 vx-mr-2 vx-bg-slate-400"></div>
            <span class="vx-text-slate-400 vx-font-light vx-text-sm">No change</span>
        </template>
        <template v-else>
          <span v-if="props.direction">
          <template v-if="props.direction === 'up'">
            &uarr;
          </template>
          <template v-else>
            &darr;
          </template>
        </span>
        <span :class="{
          'vx-font-light': props.color && props.color === 'bad'
        }">{{ percentageFormated }}</span>
        </template>
      </div>
    </div>
    <div
      class="vx-pt-3 vx-flex vx-justify-between vx-transition vx-delay-150 vx-duration-300"
      :class="{
        'vx-pointer-events-none vx-opacity-0': !hasValue || props.loading || props.error
      }"
    >
      <div>
        <slot name="footer"></slot>
      </div>
      <div v-if="$slots.explainHeader">
        <vx-button @click="explainModal = true" size="xs" color="muted-light" type="button">
            <font-awesome-icon :icon="faCircleQuestion"></font-awesome-icon>
        </vx-button>
      </div>
    </div>
    <div
      class="vx-pointer-events-none vx-absolute vx-inset-x-5 vx-bottom-5 vx-overflow-clip"
    >
      <div
        class="vx-flex vx-gap-1.5 vx-transition vx-duration-500 vx-ease-out"
        :class="{
          'vx-opacity-0': hasValue && !props.loading
        }"
      >
        <div
          v-for="item in 96"
          :key="item"
          class="vx-flex vx-h-14 vx-min-w-[8px] vx-origin-bottom vx-rounded-t-full vx-transition vx-duration-300 vx-will-change-transform"
          :class="[
            item % 7
            ? item % 3
            ? 'vx-translate-y-8'
            : 'vx-translate-y-4'
            : '',
            props.loading ? 'vx-bg-sky-200' : 'vx-bg-slate-200',
            props.loading &&
            'motion-safe:vx-animate-[pulse_1.3s_infinite]',
            hasValue && !props.loading && '!vx-translate-y-full',
          ]"
          :style="
            item % 7
            ? item % 5
            ? 'animation-delay: 0s'
            : 'animation-delay: .2s'
            : 'animation-delay: .4s'
          "
          />
        </div>
    </div>

    <vx-modal size="sm" v-if="$slots.explainHeader" v-model:visible="explainModal">
        <template v-slot:header>
            <slot name="explainHeader"></slot>
        </template>

        <slot name="explainContent"></slot>
    </vx-modal>
  </vx-box>
</template>
<script setup>
import { computed, ref } from 'vue';
import { VxBox, VxModal, VxButton } from '@voxie/frontend-components';
import { formatPercentage } from '~/components/filters';
import ExperimentalPill from '~/components/general/ExperimentalPill.vue';
import { getSlotChildrenText } from '../../../utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';

const props = defineProps({
  percentage: {
    type: Number,
  },
  color: {
    type: String,
  },
  direction: {
    type: String,
  },
  value: {
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  }
});

const explainModal = ref(false);

const hasPercentage = computed(() => props.percentage !== null && props.percentage !== undefined);
const hasValue = computed(() => props.value !== null && props.value !== undefined && !props.error);

const percentageFormated = computed(() => {
  return formatPercentage(Math.abs(props.percentage));
});

const colorClass = computed(() => {
  if (!props.color || !['good', 'bad'].includes(props.color)) return 'vx-text-slate-600';

  return props.color === 'good' ? 'vx-text-sky-500' : 'vx-text-slate-400'
})


const copyValue = () => {
  window.navigator.clipboard.writeText(props.value);
}
</script>
