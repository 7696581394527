<template>
    <analytics-stat-card
        :loading="loading"
        :error="Boolean(error)"
        :value="data">
            <template v-slot:title>
                <slot name="title">Active Campaigns</slot>
            </template>
            <template v-slot:subtitle>
                <slot name="subtitle"></slot>
            </template>

            <span>{{ data }}</span>

            <template v-slot:footer>
                <vx-button href="/messaging/campaigns" size="xs" outline color="muted-light">
                    Go to Campaigns
                    <font-awesome-icon
                        :icon="faArrowRight"
                    ></font-awesome-icon>
                </vx-button>
            </template>

            <template v-slot:explainHeader>Active Campaigns</template>
            <template v-slot:explainContent>
                <p>
                    Number of Marketing Campaigns that have been Live Campaigns in the selected period of time.
                </p>
            </template>
        </analytics-stat-card>
</template>
<script setup>
import { analyticsClient } from '../../../../services';
import { watch } from 'vue';
import AnalyticsStatCard from '../../partial/AnalyticsStatCard.vue';
import { VxButton } from '@voxie/frontend-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { getClientTz } from '../../../../utils/date';
import dayjs, { toUTCDateTimeFormat } from '~/utils/dayjs';
import { useVersionedRequest } from '~/composables/useVersionedRequest';

const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
});

const calculateRange = () => {
    const startDate = dayjs(props.startDate).tz(getClientTz());
    const endDate = dayjs(props.endDate).tz(getClientTz());

    return [toUTCDateTimeFormat(startDate), toUTCDateTimeFormat(endDate)];
};

const { loading, response: data, error, fetchData } = useVersionedRequest();

watch(
    [() => props.startDate, () => props.endDate],
    () => {

        fetchData(
            analyticsClient.marketingCampaignsTotalActive(Spark.state.currentTeam.id, { range: calculateRange() })
                .then((res) => {
                    return Array.isArray(res.data?.values) ? res.data.values[0] : undefined;
                })
        ).catch((e) => {
            console.error(e)
        });

    },
    { immediate: true }
);
</script>
