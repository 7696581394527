<template>
    <div>
        <!-- Reversed toggle logic -->
        <toggle-action-data :model-value="!data.use_event_tag"
            @update:modelValue="data.use_event_tag = !$event"
            :open="!data.use_event_tag"
            class="vx-mb-2">
            <template v-slot:label>
                Use different <strong>tag</strong> from the trigger event?
            </template>

            <vx-label>Select a Tag</vx-label>
            <select-tags-v2 size="lg"
                v-model="data.tag_name"
                :error="displayFirstError(validate.tag_name)">
            </select-tags-v2>
        </toggle-action-data>

        <use-event-contact-action-data v-model="data"></use-event-contact-action-data>
    </div>
</template>

<script setup>
import { VxLabel } from '@voxie/frontend-components';
import SelectTagsV2 from '~/components/general/SelectTagsV2.vue';
import UseEventContactActionData from './UseEventContactActionData.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf } from '@vuelidate/validators';
import { displayFirstError } from '~/utils/validation';
import ToggleActionData from './ToggleActionData.vue';

const data = defineModel();

if (data.value.use_event_tag == undefined) {
    data.value.use_event_tag = false;
}

const validate = useVuelidate({
    tag_name: {
        requiredIf: helpers.withMessage('Tag is required when using tag different from event', requiredIf(() => !data.value.use_event_tag))
    }
}, data, {
    $autoDirty: true,
})
</script>
