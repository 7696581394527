<template>
    <vx-toast-manager position="bottom-right"></vx-toast-manager>
</template>
<script setup>
import { getCurrentInstance, onMounted } from 'vue';
import { VxToastManager } from '@voxie/frontend-components-v3';

const app = getCurrentInstance();

onMounted(() => {
    const $toasted = app?.appContext.config.globalProperties.$toasted;

    $toasted?.register('saved_successfully', 'Saved successfully.', { type: 'success' });
    $toasted?.register('platform_error', 'There was an error communicating with the platform.', {
        type: 'error',
    });
});
</script>
<style lang="postcss">
.vx-toast__close-btn-wrapper button {
  background: transparent;
  color: inherit;
  border: none;
}

.vx-toast__content {
  color: inherit;
}
</style>
