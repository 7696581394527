import BaseClient from './BaseClient';
import axiosCacheInterceptor from './axiosCacheInterceptor';

const axiosCacheInterceptorRequestConfig = {
    cache: {
        interpretHeader: false,
        cacheTakeover: false,
        methods: ['post'],
    },
};

class AnalyticsClient extends BaseClient {
    marketingSubscriptionsTotalByStatus(teamId) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/current/marketing-subscriptions/total-by-status`;

        return this.makeCoreApiRequest(url, 'post', null, null, axiosCacheInterceptorRequestConfig);
    }

    marketingSubscriptionsTotalOptIns(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-subscriptions/total-opt-ins`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingSubscriptionsTotalOptOuts(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-subscriptions/total-opt-outs`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingSubscriptionsTotalOptOutsByEntryPoint(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-subscriptions/total-opt-outs-by-entry-point`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsMessageReplyRate(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/message-reply-rate`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsMessageReplyRateByCampaign(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/message-reply-rate-by-campaign`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsTotalMessagesSentByCampaign(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/total-messages-sent-by-campaign`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsTotalMessagesSent(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/total-messages-sent`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsClickThroughRateByCampaign(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/click-through-rate-by-campaign`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsClickThroughRate(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/click-through-rate`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingSubscriptionsTotalOptInsByEntryPoint(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-subscriptions/total-opt-ins-by-entry-point`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsTotalActive(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/total-active`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsOptOutRate(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/opt-out-rate`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsOptOutRateByCampaign(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/opt-out-rate-by-campaign`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsTotalContactsMessaged(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/total-contacts-messaged`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsTotalContactsMessagedByCampaign(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/total-contacts-messaged-by-campaign`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsDeliveryRate(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/delivery-rate`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    marketingCampaignsDeliveryRateByCampaign(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/marketing-campaigns/delivery-rate-by-campaign`;

        return this.makeCoreApiRequest(url, 'post', params);
    }

    outboundMessagesTotalMessagesSentByType(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/outbound-messages/total-messages-sent-by-type`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    outboundMessagesDeliveryRate(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/outbound-messages/delivery-rate`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    outboundMessagesTotalContactsMessaged(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/outbound-messages/total-contacts-messaged`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    async contactsTotalCreated(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/contacts/total-created`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    async contactsTotalCreatedByEntryPoint(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/contacts/total-created-by-entry-point`;
        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    async contactsTotalActiveEntryPoints(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/current/contacts/total-active-entry-points`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }

    usageTotalByType(teamId, params) {
        const url = `${this.apiBase}/teams/${teamId}/statistics/time-series/usage/total-by-type`;

        return this.makeCoreApiRequest(url, 'post', params, null, axiosCacheInterceptorRequestConfig);
    }
}

export default new AnalyticsClient({
    client: axiosCacheInterceptor,
    apiBase: window.Spark.apiBase,
});
