<template>
    <tr class="c-table__row">
        <td class="c-table__cell center hidden-xs hidden-sm">
            <input v-model="contact.selected" id="checkbox1" name="checkboxes" type="checkbox">
        </td>

        <td class="hidden-xs hidden-sm c-table__cell c-table__cell--img o-media">
            <contact-avatar :contact="contact"></contact-avatar>

            <div class="o-media__body">
                {{ contact.first_name || 'Anonymous' }} {{ contact.last_name || 'User' }}
            </div>
        </td>

        <td class="c-table__cell identity-cell">
            <span class="u-block u-text-mute" v-if="contact.phone">
                <i class="fa fa-mobile-phone fa-lg"></i>
                <span class="u-text-xsmall">{{ contact.phone }}</span>
            </span>
            <span class="u-block u-text-mute" v-if="contact.email">
                <i class="fa fa-envelope-o"></i>
                <span class="u-text-xsmall">{{ contact.email }}</span>
            </span>
        </td>

        <td class="c-table__cell center">
            <span class="u-block huge-text center">
                <i v-if="loadingCompetedCount" class="fa fa-spin fa-circle-o-notch" />
                <template v-else-if="competedCount !== null">
                    {{ competedCount }}
                </template>
            </span>
        </td>

        <td class="c-table__cell center">

            <button
                v-if="!belongsToCampaign && !pending"
                @click.prevent="add"
                class="c-btn c-btn--success"
                :disabled="readOnly"
            >Add</button>
            <button
                v-if="belongsToCampaign && !pending"
                @click.prevent="remove"
                class="c-btn c-btn--warning"
                :disabled="readOnly"
            >Remove</button>

            <a v-if="pending" class="c-btn c-btn--success is-disabled" href="#">
                Wait
                <i class="fa fa-spin fa-circle-o-notch"></i>
            </a>
        </td>
    </tr>
</template>

<script>
    import { contactsClient } from '../../services';
    import ContactAvatar from '../../components/contacts/ContactAvatar.vue';

    export default
    {
        props: {
            contact: Object,
            campaign: Object,
            readOnly: {
                type: Boolean,
                default: false,
            },
        },

        components: {
            ContactAvatar,
        },

        inject: ['FEATURES'],

        data() {
            return {
                pending: false,
                competedCount: null,
                loadingCompetedCount: true,
            }
        },

        created() {
            Bus.$on(`campaignContactsPending-${this.contact.id}`, (pending) => {
                this.pending = pending;
            });

            Bus.$on('campaignContactsClearPending', () => {
                this.pending = false;
            });

            contactsClient.contactsCompletedCount(this.campaign.team_id, this.campaign.id, this.contact.id)
                .then(({ data }) => {
                    this.competedCount = data.count;
                })
                .finally(() => {
                    this.loadingCompetedCount = false;
                });
        },
        methods: {
            add() {
                this.pending = true;
                Bus.$emit("campaignContactsAdd", this.contact.id, this.campaign.id);
            },

            remove() {
                this.pending = true;
                Bus.$emit("campaignContactsRemove", this.contact.id, this.campaign.id);
            }
        },

        computed: {
            belongsToCampaign() {
                return this.contact.campaigns.filter(c => c.id === this.campaign.id).length > 0;
            },
        }
    }
</script>
