<template>
    <vx-table-row>
        <vx-table-row-title>
            {{ phoneNumberNational(model.phone_number) }}
        </vx-table-row-title>

        <vx-table-row-item>
            <template v-slot:label> Number Type: </template>
            {{ NUMBER_TYPES[model.number_type] || '-' }}
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Country: </template>
            {{ countryEmoji(model.iso_country) }} {{ model.iso_country }}
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Voice: </template>

            <template v-if="model.inbound_call_handler === 'shift_to_text'">
                Shift to Text
            </template>
            <template v-else-if="model.forward_voice_to">
                Forward
            </template>
            <template v-else-if="model.voice_message_text">
                Say Message
            </template>
        </vx-table-row-item>

        <vx-table-row-item>
            <template v-slot:label> Sending: </template>

            <font-awesome-icon data-test="used-for-sending-check"
                :icon="faCircleCheck"
                v-if="model.use_for_sending"></font-awesome-icon>
        </vx-table-row-item>

        <vx-table-row-item v-if="FEATURES.contact_groups">
            <template v-slot:label> Group: </template>

            <div v-if="model.group_id">
                <vx-icon-loading v-if="group === undefined"
                    spin></vx-icon-loading>
                <template v-else>
                    <div v-if="group?.name"
                        class="vx-line-clamp-1 vx-font-bold"
                        :title="group.name">{{ group.name }}</div>
                    <div class="vx-break-all vx-text-slate-500">{{ model.group_id }}</div>
                </template>
            </div>
            <div v-else>-</div>
        </vx-table-row-item>

        <vx-table-row-buttons v-if="isImpersonatingOrDeveloper">
            <vx-dropdown placement="right">
                <vx-button data-test="dropdown"
                    type="button"
                    color="muted"
                    outline
                    class="vx-w-[45px]">
                    <font-awesome-icon :icon="faEllipsis" />
                </vx-button>

                <template v-slot:items>
                    <vx-dropdown-item v-if="FEATURES.contact_groups"
                        data-test="assign-to-group"
                        @click="assignToGroupModal = true">
                        Assign to Group
                        <font-awesome-icon :icon="faGrid2Plus"></font-awesome-icon>
                    </vx-dropdown-item>
                    <vx-dropdown-item data-test="delete"
                        @click="deletePhoneNumber()">
                        Delete
                        <font-awesome-icon :icon="faTrash"></font-awesome-icon>
                    </vx-dropdown-item>
                </template>
            </vx-dropdown>

            <vx-button :href="`/settings/teams/${teamId}/phone-numbers/${model.id}`"
                data-test="edit"
                color="muted"
                outline>
                Edit <font-awesome-icon :icon="faFilePen" />
            </vx-button>
        </vx-table-row-buttons>

        <vx-modal v-if="FEATURES.contact_groups" v-model:visible="assignToGroupModal"
            size="md"
            :padding="false">
            <template v-slot:header>
                <div class="vx-p-6">
                    Confirmation
                </div>
            </template>

            <div class="vx-p-6 lg:vx-p-10">
                <select-group v-model="groupToAssignTo" :relativeDropdown="true" :clearable="true" size="lg"></select-group>
            </div>

            <template v-slot:footer>
                <div class="vx-flex vx-flex-col-reverse vx-gap-4 lg:vx-flex-row">
                    <vx-button @click="assignToGroupModal = false"
                        type="button"
                        class="vx-grow lg:vx-w-20 lg:vx-grow-0"
                        size="lg"
                        color="muted">
                        Cancel
                    </vx-button>
                    <vx-button @click="assignToGroup()"
                        data-test="confirm-assign-to-group"
                        :loading="assigningToGroup"
                        type="button"
                        block
                        size="lg"
                        color="primary">
                        Confirm
                    </vx-button>
                </div>
            </template>
        </vx-modal>

    </vx-table-row>
</template>

<script setup>
import {
    faCircleCheck,
    faEllipsis,
    faFilePen,
    faGrid2Plus,
    faTrash
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    VxModal,
    VxButton,
    VxDropdown,
    VxDropdownItem,
    VxTableRow,
    VxTableRowButtons,
    VxTableRowItem,
    VxTableRowTitle,
    VxIconLoading,
} from "@voxie/frontend-components";
import { inject, onMounted, ref } from "vue";
import { phoneNumberNational } from "~/components/filters";
import { useToasted } from '~/composables/useToasted';
import { NUMBER_TYPES } from "~/constants/phoneNumbers";
import adminPhoneNumbersClient from "~/services/adminPhoneNumbersClient";
import groupsClient from "~/services/groupsClient";
import { HTTP_INTERNAL_SERVER_ERROR } from "~/support/HttpStatuses";
import { countryEmoji } from "~/utils/string";
import SelectGroup from "~/components/general/SelectGroup.vue";

const FEATURES = inject('FEATURES');
const toasted = useToasted();
const teamId = Spark.state.currentTeam.id;

const isImpersonatingOrDeveloper = Spark.isImpersonating || Spark.isDeveloper;

const model = defineModel({
    type: Object,
    required: true,
})

const group = ref(undefined);
const emit = defineEmits(['deleted']);

const fetchGroup = async () => {
    if (!(FEATURES.contact_groups && model.value.group_id)) {
        return;
    }
    if (!model.value.group_id) {
        group.value = null;
        return;
    }
    group.value = undefined;
    try {
        const { data } = await groupsClient.showGroup(teamId, model.value.group_id);
        group.value = data;
    } catch (e) {
        group.value = null;
    }
}

onMounted(() => {
    groupToAssignTo.value = model.value.group_id;
    fetchGroup();
});

const assignToGroupModal = ref(false);
const groupToAssignTo = ref(null);
const assigningToGroup = ref(false);

const assignToGroup = async () => {
    if (assigningToGroup.value) {
        return;
    }

    assigningToGroup.value = true;
    try {
        await adminPhoneNumbersClient.update(teamId, model.value.id, {
            group_id: groupToAssignTo.value,
        });

        model.value.group_id = groupToAssignTo.value;
        fetchGroup();

        assignToGroupModal.value = false;
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
        } else {
            toasted.error(e?.response?.data?.message || 'Something went wrong.');
        }
    } finally {
        assigningToGroup.value = false;
    }
}

const deletePhoneNumber = async () => {
    if (!window.confirm('Are you sure? There is no undo.')) return;

    try {
        await adminPhoneNumbersClient.delete(teamId, model.value.id);
        emit('deleted', model.value.id);
    } catch (e) {
        console.error(e);
        if (!e?.response?.status || e.response.status >= HTTP_INTERNAL_SERVER_ERROR) {
            toasted.global.platform_error();
        } else {
            toasted.error(e.response?.data?.message || 'Something went wrong.');
        }
    }
};

</script>
