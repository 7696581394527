import BaseClient from './BaseClient';

class SalesforceClient extends BaseClient {
    getCredentials(teamId) {
        const url = `/api/v3/teams/${teamId}/salesforce/credentials`;
        return this.makeCoreApiRequest(url);
    }

    getConfig(teamId) {
        const url = `/api/v3/teams/${teamId}/salesforce/config`;
        return this.makeCoreApiRequest(url);
    }

    createConfig(teamId, data) {
        const url = `/api/v3/teams/${teamId}/salesforce/config`;
        return this.makeCoreApiRequest(url, 'post', data);
    }

    updateConfig(teamId, data) {
        const url = `/api/v3/teams/${teamId}/salesforce/config`;
        return this.makeCoreApiRequest(url, 'put', data);
    }
}

export default new SalesforceClient();
